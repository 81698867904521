import { createSlice } from "@reduxjs/toolkit";
import { objectDeepClone } from "../../../utils/helper";

const initialState = {
    data: null,
    guestsetting: {},
    setting: {}
};

export const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setGuestSetting: (state, {payload}) => {
      state.guestsetting = payload
    },
    setSetting: (state, {payload}) => {
      let _payload = objectDeepClone(payload)
      if (_payload.hasOwnProperty('publicKey')) delete _payload['publicKey']
      if (_payload.hasOwnProperty('password')) delete _payload['password']
      state.guestsetting = _payload
      state.setting = objectDeepClone(payload)
    },
  },
});

export const { setGuestSetting, setSetting } = SettingSlice.actions;
export default SettingSlice.reducer;
