import { Info } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import API from '../../../../api';
import Loading from '../../../../components/Loader/Loading';
import MyReactTooltip from '../../../../components/ReactTooltip';
import { getToast } from '../../../../components/Toast';
import { capitalizeSentence, isValidURL, objectDeepClone, slugify, throwServerError } from '../../../../utils/helper';

export default function SDKModal(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [disableSave, setDisableSave] = useState(false)
    const [categories, setCategories] = useState([])
    const [data, setData] = useState({
        categoryname: '', description: '', download: '', 
        sdkIcon: '', overviewUrl: '', title: ''
    })
    const [errors, setErrors] = useState({})

    const getListOfSdkCategory = () => {
		setIsLoading(true);
		API.getAllSdkCategory()
        .then((response) => {
            setIsLoading(false);
            const data = response.data?.data || []
            let _categories = data?.map(o => ({label: o.categoryname, value: o.categoryname}))
            setCategories(objectDeepClone(_categories))
        })
        .catch((error) => {
            setIsLoading(false);
            setCategories([])
            throwServerError(error)
        });
	};

    useEffect(() => {
        if (props.isEdit){
            const _data = objectDeepClone(props.data)

            setData({
                categoryname: _data.categoryname, description: _data.description, download: _data.download,
                sdkIcon: _data.sdkIcon, overviewUrl: _data.overviewUrl, title: _data.title,
            })
        }
		getListOfSdkCategory();
	}, []);

    function fileUpload(e, name){
        API.uploadImage(e.target.files[0])
        .then(res => {
            console.log('res', res)
            setData({
                ...data, [name]: res.file?.url || ''
            })
            setErrors({...errors, [name]: false})
        }).catch(err => {
            console.log(err)
        })
    }

    function handleSubmit(){
        setDisableSave(true)
        setErrors({})
        let _data = objectDeepClone(data)

        let tempErrors = {}

        Object.keys(_data).forEach((eachKey) => {
            if (!_data[eachKey]) tempErrors[eachKey] = true
        })
        setErrors(tempErrors)

        console.log('tempErrors', tempErrors)
        if (Object.keys(tempErrors).length) {
            setDisableSave(false)
            return
        }

        if (!_data.overviewUrl && isValidURL(_data.overviewUrl)) {
            getToast({
                statusType: 'ERROR', message: 'Entered URL is invalid'
            })
            setDisableSave(false)
            return
        }

        if (props.isEdit){
            _data['category'] = slugify(_data.categoryname)
            API.updateSdk(props.data._id, objectDeepClone(_data))
            .then(res => {
                setDisableSave(false)
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        } else {
            _data['category'] = slugify(_data.categoryname)
            API.addSdk(objectDeepClone(_data))
            .then(res => {
                setDisableSave(false)
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} SDK</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Cancel
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 58px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                {isLoading
                ? <Loading />
                : <div className='p-6px' onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSubmit()
                        e.preventDefault()
                    }
                }}>
                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Category <span className='text-danger'>*</span> </p>

                        <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            className="v1select mt-1" placeholder="Select Category" options={categories}
                            value={data.categoryname ? {label: data.categoryname, value: data.categoryname} : null} 
                            onChange={(e) => {
                                setData({...data, categoryname: e.value})
                                setErrors({...errors, categoryname: false})
                            }}
                        />

                        {errors.categoryname && <p className='text-danger fs-10px mt-1'>
                            Category Name is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Title <span className='text-danger'>*</span> </p>

                        <input type={'text'} className='from-control v1-form-control w-100 mt-2px' value={data.title}
                            onChange={(e) => {
                                setData({...data, title: e.target.value})
                                setErrors({...errors, title: e.target.value.trim.length > 0})
                            }} placeholder='Enter title' />
                        
                        {errors.title && <p className='text-danger fs-10px mt-1'>
                            Title is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Overview URL <span className='text-danger'>*</span> </p>

                        <input type={'text'} className='from-control v1-form-control w-100 mt-2px' value={data.overviewUrl}
                            onChange={(e) => {
                                setData({...data, overviewUrl: e.target.value})
                                setErrors({...errors, overviewUrl: e.target.value?.trim.length > 0})
                            }} placeholder='Enter Overview URL' />
                        
                        {errors.overviewUrl && <p className='text-danger fs-10px mt-1'>
                            URL is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Upload Downloadable Assets <span className='text-danger'>*</span> </p>

                        <input type={'file'} id='downloadAssets' className='d-none' 
                            onChange={(e) => fileUpload(e, 'download')} />
                        <label className='d-flex align-items-center v1-border-1 mt-4px justify-content-between' htmlFor='downloadAssets'>
                            {data.download ?
                            <p className='text-200 px-2 text-truncate fs-12px'>
                                {data.download}
                            </p>
                            : <p className='text-400 px-2 text-truncate fs-12px'>
                                Attach downloadable assets
                            </p>}
                            <div className='btn-md-v1 br-tl-0px br-bl-0px'>
                                <p className='px-3 py-1 text-300 fs-12px'>Browse</p>
                            </div>
                        </label>

                        {errors.download && <p className='text-danger fs-10px mt-1'>
                            Downloadable Asset is required
                        </p>}
                    </div>

                    <div className='mb-3 w-100'>
                        <div className='d-flex align-items-center w-100'>
                            <p className='fs-12px fw-400 text-300'>SDK Icon <span className='text-danger'>*</span></p>
                            <Info color='#e4e4e4' className='ms-1 cursor' size={13} 
                                data-tip='Ideal resolution - 190 x 200' data-for='iconRestriction' />

                            <MyReactTooltip id="iconRestriction" />
                        </div>

                        <input type={'file'} id='uploadSDKIcon' className='d-none'
                            onChange={(e) => fileUpload(e, 'sdkIcon')} />
                        <label className='d-flex align-items-center v1-border-1 mt-4px justify-content-between' htmlFor='uploadSDKIcon'>
                            {data.sdkIcon ?
                            <p className='text-200 px-2 text-truncate fs-12px'>
                                {data.sdkIcon}
                            </p>
                            : <p className='text-400 px-2 text-truncate fs-12px'>
                                Upload SDK Icon
                            </p>}
                            <div className='btn-md-v1 br-tl-0px br-bl-0px'>
                                <p className='px-3 py-1 text-300 fs-12px'>Browse</p>
                            </div>
                        </label>

                        {errors.sdkIcon && <p className='text-danger fs-10px mt-1'>
                            SDK Icon is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Description <span className='text-danger'>*</span> </p>

                        <textarea className='from-control v1-form-textarea w-100 mt-2px' rows={4} value={data.description}
                            onChange={(e) => {
                                setData({...data, description: e.target.value})
                                setErrors({...errors, description: e.target.value?.trim.length > 0})
                            }} placeholder='Enter Overview URL' />
                    
                        {errors.description && <p className='text-danger fs-10px mt-1'>
                            Description is required
                        </p>}
                    </div>
                </div>}
            </Modal.Body>
        </Modal>
    )
}
