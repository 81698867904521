import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import API from '../../../../api';
import Loading from '../../../../components/Loader/Loading';
import { throwServerError } from '../../../../utils/helper';
import { getToast } from '../../../../components/Toast';
import { objectDeepClone } from '@apiwiz/oas/services';

export default function AddSwaggerModal(props) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        swaggerId: "", swaggerName: "", revision: "", oas: ""
    })
    const [swaggers2, setSwaggers2] = useState([])
    const [swaggers3, setSwaggers3] = useState([])
    const [revisions, setRevisions] = useState([])
    const [disableSave, setDisableSave] = useState(false)

    useEffect(() => {
        getSwaggers2Data()
        // getSwaggers3Data()

        if (props.isEdit){
            let _data = objectDeepClone(props.data || {})
            setData({
                swaggerId: _data.swaggerId, swaggerName: _data.swaggerName, 
                revision: _data.revision, oas: _data.oas
            })
        }
    }, [props.data])

    const getSwaggers2Data = () => {
        setLoading(true)
        API.getPublishedSwaggers({oas: '2.0'}).then(res => {
            let _temp = res.data?.data || []

            console.log('_temp', _temp)
            let _swaggers2 = []
            let _swaggers3 = []

            _temp.forEach(o => {
                let firstItem = o.items?.length ? o.items[0] : {}
                if (firstItem.swaggerVersion === "2.0"){
                    _swaggers2.push({
                        label: o.title, value: firstItem?.swaggerId || ''
                    })
                }
                else if (firstItem.swaggerVersion === "3.0"){
                    _swaggers3.push({
                        label: o.title, value: firstItem?.swaggerId || ''
                    })
                }
            })

            setSwaggers2(_swaggers2 || [])
            setSwaggers3(_swaggers3 || [])
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const getSwaggerRevisions = (_id, oas) => {
        API.getSwaggerRevisions(_id, oas === 'OAS 2.0' ? {oas: '2.0'} : {oas: '3.0'})
        .then(res => {
            let _revisionsData = res.data?.data || []
            let _newRevisionData = []
            _revisionsData.forEach(o => {
                if (o.status === "Publish" || o.status === "Approved") {
                    _newRevisionData.push({label: `Revision - ${o.revision}`, value: o.revision})
                }
            })
            setRevisions(_newRevisionData || [])
        }).catch(err => {
            throwServerError(err)
        })
    }

    const getSwaggers3Data = () => {
        setLoading(true)
        API.getPublishedSwaggers({oas: '3.0'}).then(res => {
            let _temp = res.data?.data || []
            let _newArr = []
            _temp.forEach(o => {
                let firstItem = o.items?.length ? o.items[0] : {}
                _newArr.push({
                    label: o.title, value: firstItem?.swaggerId || ''
                })
            })
            setSwaggers3(_newArr)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const oasOptions = [
        {label: 'OAS 2.0', value: 'OAS 2.0'},
        {label: 'OAS 3.0', value: 'OAS 3.0'},
    ]

    const handleSubmit = () => {
        if (!data.oas || !data.swaggerId || !data.revision){
            getToast({
                statusType: 'ERROR', message: 'Please select all the required details'
            })
            return
        }

        let json = objectDeepClone(data)

        if (props.isEdit){
            API.updateSwaggerToGuide(props.mainData?.guide?._id, props.mainData?.version?._id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Swagger attached successfully'
                })
                props.callback()
                props.onHide()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.addSwaggerToGuide(props.mainData?.guide?._id, props.mainData?.version?._id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Swagger attached successfully'
                })
                props.callback()
                props.onHide()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md"
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} Swagger</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(70vh)" }}
                className="overflow-scroll noscrollbar"
            >
                {loading 
                ? <Loading />
                : <div className='p-12px'  onKeyDown={(e) => {
                        setIsCtrl(['Control', 'Meta'].includes(e.key))
                        if (isCtrl && e.key?.toLowerCase() === 's') {
                            handleSubmit()
                            e.preventDefault()
                        }
                    }}>
                    <p className='text-300 fs-14px fw-400 mb-16px'>
                        User can select swaggers which are Published in OAS 2 and OAS 3 Design Studio
                    </p>

                    <div className='mb-3'>
                        <p className='text-200 fw-400 fs-11px mb-1'>Select OAS <span className='text-danger'>*</span> </p>
                        <Select className='v1select'
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            options={oasOptions} value={{label: data.oas, value: data.oas}}
                            onChange={(e) => setData({...data, oas: e.value, swaggerId: '', swaggerName: '', revision: ''})}
                        />
                    </div>

                    <div className='mb-3'>
                        <p className='text-200 fw-400 fs-11px mb-1'>Select Swagger <span className='text-danger'>*</span> </p>
                        <Select className='v1select'
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            options={data.oas === 'OAS 2.0' ? swaggers2 : (data.oas === 'OAS 3.0' ? swaggers3 : [])}
                            value={{label: data.swaggerName, value: data.swaggerId}}
                            onChange={(e) => {
                                setData({...data, swaggerName: e.label, swaggerId: e.value, revision: ''})
                                getSwaggerRevisions(e.value, data.oas)
                            }}
                        />
                    </div>

                    <div className='mb-3'>

                        <p className='text-200 fw-400 fs-11px mb-1'>Select Revision <span className='text-danger'>*</span> </p>
                        <Select className='v1select'
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            options={revisions} value={data.revision ? {label: `Revision - ${data.revision}`, value: data.revision} : {}}
                            onChange={(e) => setData({...data, revision: e.value})}
                        />
                    </div>
                </div>}
            </Modal.Body>
        </Modal>
    )
}
