import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '../../context/ThemeContext'
import { Eye, EyeSlash } from 'phosphor-react'
import { useDispatch } from 'react-redux'
import API from '../../api'
import { encryptPassword, objectDeepClone, throwServerError } from '../../utils/helper'
import Loading from '../../components/Loader/Loading'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

export default function ResetPassword() {
    const {id} = useParams()
    const {currentLayoutObject} = useSelector(state => state.layout)
    const {theme} = useTheme()
    const [showPass, setshowPass] = useState(false)
    const [showPass2, setshowPass2] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [password, setPassword] = useState('')
    const [loginData, setLoginData] = useState({
		disableSignup: false,
		disableLogin: false,
		loginMethod: [],
	});
    const [isDisabled, setIsDisabled] = useState(false)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const {guestsetting} = useSelector(state => state.setting)

    useEffect(() => {
        setloading(true)
        setLoginData(objectDeepClone(guestsetting));
        verifyToken()
	}, [guestsetting]);

    const verifyToken = () => {
        API.getResetPassword(id)
        .then(res => {
            setloading(false)
        }).catch(err => {
            throwServerError(err)
            navigate(`/`)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let temp = {}

        const regex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g

        if (!password.trim().length){
            temp['password'] = 'Password is Required'
        }
        else if (password.trim()?.length < 8){
            temp['password'] = 'Password should be minimum of 8 characters'
        }
        else if(!regex.test(password)){
            temp['password'] = "Password must include uppercase, lowercase, numbers & special characters(!, @, &, %, +)"
        }

        if (password !== confirmPassword){
            temp['confirmPassword'] = 'Both Passwords should match'
        }

        setErrors(temp)
        if (Object.keys(temp).length) return

        setIsDisabled(true)
        let submitObj = {
            password, confirm_password: confirmPassword
        }

        submitObj.password = encryptPassword(password)
        submitObj.confirm_password = encryptPassword(confirmPassword)

        API.resetPassword(submitObj, id)
        .then(res => {
            navigate(`/reset-success`)
            setIsDisabled(false)
        }).catch(err => {
            throwServerError(err)
            setIsDisabled(false)
        })

    }

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            { loading
            ? <Loading />
            : <form className='loginContainer h-100' onSubmit={handleSubmit}>
                <div className='mb-20px text-center'>
                    <div className='mb-20px text-center'>
                        <img src={theme === 'theme--light' ? currentLayoutObject.logo : currentLayoutObject.darklogo}
                            alt='Logo' height={50} width={'100%'} className='d-block mx-auto'
                            style={{objectFit: 'contain'}} />
                    </div>
                    <div className='mb-32px'>
                        <h3 className='titleText'>Reset password</h3>
                        <p className='descText'>
                            Change password to manage your account.
                        </p>
                    </div>
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Password<span className='text-danger'>*</span></p>
                    <div className='position-relative'>
                        <input type={showPass ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                            placeholder='Enter your password' value={password}
                            onChange={(e) => {
                                let _str = e.target.value
                                setPassword(_str)
                                setErrors({...errors, password: _str.trim().length < 1 ? 'Password is Required' : false})
                            }} />
                        <div className='eyeIconPass cursor' onClick={() => setshowPass(curr => !curr)}>
                            {showPass
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                    </div>
                    {errors.password && <p className='text-danger fs-12px mt-1'>
                        {errors.password}
                    </p>}
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Confirm Password<span className='text-danger'>*</span></p>
                    <div className='position-relative'>
                        <input type={showPass2 ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                            placeholder='Enter your password' value={confirmPassword}
                            onChange={(e) => {
                                let _str = e.target.value
                                setConfirmPassword(_str)
                                setErrors({...errors, confirmPassword: _str.trim().length < 1 ? 'Confirm Password is Required' : false})
                            }} />
                        <div className='eyeIconPass cursor' onClick={() => setshowPass2(curr => !curr)}>
                            {showPass2
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                    </div>
                    {errors.confirmPassword && <p className='text-danger fs-12px mt-1'>
                        {errors.confirmPassword}
                    </p>}
                </div>

                <button type='submit' disabled={isDisabled} className='border-0 gradientBtnInAuth mb-16px'>
                    Submit
                </button>

                {!loginData.disableLogin && <div className='dontHaveAnAccount'>
                    <Link to={`/register`}>
                        Remember your password? <span>Sign In</span>
                    </Link>
                </div> }
            </form>}
        </div>
    )
}
