import { useSelector } from "react-redux";
import dummyUser from "../../../../assets/Forum/images/dummy-profile.png";
import { PostIcon } from "./PostIcon";
import moment from "moment";
import EditorJSOutput from "../../../../components/EditorJSOutput";
import { useEffect, useState } from "react";
import { objectDeepClone } from "../../../../utils/helper";
import { useNavigate } from "react-router";
import ForumAPI from "../../../../api/forum";
import { getToast } from "../../../../components/Toast";
import { PlaceHolderProfile } from "../PlaceHolderProfile";
import { EditDeleteDropdown } from "../EditDeleteDropdown";
import { EditPostModal } from "../EditPostModal";
import { PostIconV2 } from "./PostIconV2";

export const Post = ({ postData, ...props }) => {
  const {
    currentLayoutObject: { siteTemplateType },
  } = useSelector((state) => state.layout);

  // used for post preview, no click actions should be there
  const { previewMode, setShowEditModal, onDeleteSuccess } = props;

  const { currentUser } = useSelector((state) => state.user);

  const [postContent, setpostContent] = useState(null);
  const [showReadMore, setShowReadMore] = useState(false);
  const [votes, setVotes] = useState([]);
  // const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();

  const isUserVoted = () => {
    return votes.includes(currentUser._id);
  };

  useEffect(() => {
    if (postData && postData.post) {
      if (!previewMode && postData.post?.content?.data?.blocks?.length > 5) {
        const _postContent = objectDeepClone(postData.post?.content?.data);
        _postContent.blocks = _postContent.blocks.slice(0, 5);
        console.log({ _postContent });
        setpostContent(_postContent);
        setShowReadMore(true);
      } else {
        const _postContent = objectDeepClone(postData.post?.content?.data);
        setpostContent(_postContent);
        setShowReadMore(false);
      }

      setVotes(postData.post?.votes);
    }
  }, [postData]);

  const updatePostUpvote = () => {
    ForumAPI.togglePostReaction({ postId: postData._id })
      .then((res) => {
        console.log("update upvote res: ", res);

        let _votes = objectDeepClone(votes);

        const userAlreadyReacted = _votes.includes(currentUser._id);
        if (userAlreadyReacted) {
          _votes = _votes.filter((id) => id !== currentUser._id);
          setVotes(_votes);
        } else {
          _votes = [..._votes, currentUser._id];
          setVotes(_votes);
        }
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to update Upvotes",
        });
      });
  };

  return (
    <>
      <div className="post-view-card">
        <div className="post-meta-tags__container">
          <div className="post-meta-info__container">
            {postData?.createdBy?.userImage ? (
              <img
                src={postData?.createdBy?.userImage}
                className="user-profile"
              />
            ) : (
              <PlaceHolderProfile />
            )}
            <div className="user-name--creation-time__container">
              <p className="user-name">{postData?.createdBy?.fullName} </p>
              <p className="post-creation-time ">
                •{moment.utc(postData?.cts).format("MMMM DD,YYYY")} at{" "}
                {moment.utc(postData?.cts).format("HH:MM A")}
              </p>
            </div>
            {currentUser?.username === postData?.createdBy?.username && (
              <EditDeleteDropdown
                editCallback={() => {
                  setShowEditModal({
                    show: true,
                    postData,
                  });
                }}
                deleteCallback={() => {
                  onDeleteSuccess({ postId: postData._id });
                }}
              />
            )}
          </div>
          <div className="post-tags__container">
            {postData?.tags?.map((tag) => (
              <div className="tag">{tag}</div>
            ))}
          </div>
        </div>
        <div className="post-content__container">
          <div
            className="post-title"
            onClick={() => {
              // TODO: Check if the user is logged in to view post details
              if (!previewMode) {
                navigate(`/forum/post/${postData._id}`);
              }
            }}
          >
            {postData?.title}
          </div>
          <div className="post-content">
            <EditorJSOutput applyTheme data={postContent} />
            {showReadMore && (
              <p
                className="read-more-btn"
                onClick={() => {
                  navigate(`/forum/post/${postData._id}`);
                }}
              >
                ...Read More
              </p>
            )}
          </div>
        </div>

        <div className="post-upvotes-reply-count__container">
          <div
            className="upvote-count"
            onClick={() => {
              if (!previewMode) {
                updatePostUpvote();
              }
            }}
          >
            <PostIconV2
              type={"upvote"}
              state={isUserVoted() ? "filled" : "default"}
            />
            <p className="count-value">{votes?.length} Upvotes</p>
          </div>
          <div
            className="reply-count"
            onClick={() => {
              if (!previewMode) {
                navigate(`/forum/post/${postData._id}`);
              }
            }}
          >
            <PostIconV2 type={"reply"} state={"default"} />
            <p className="count-value">{postData?.replyCount} Replies</p>
          </div>
        </div>
      </div>
    </>
  );
};
