import React, { useEffect, useState } from 'react'
import { EnvelopeSimple } from 'phosphor-react'
import { Link } from 'react-router-dom'

export default function RegisterSuccess() {

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            <div className='loginContainer h-100'>
                <div className='mb-20px d-flex align-items-center justify-content-center'>
                    <div className='p-18px br-8px borderChange'>
                        <EnvelopeSimple size={25} color='var(--content-color)' />
                    </div>
                </div>

                <div className='mb-24px'>
                    <h3 className='titleText v2'>Great! Check your email</h3>
                    <p className='descText v2'>
                        We've send you a verification link, say goodbye to bulky input fields
                    </p>
                </div>

                {<div  className='dontHaveAnAccount text-center cursor'>
                    <Link className='fw-700' to='/resend-token'>Resend Token</Link>
                </div>}
            </div>
        </div>
    )
}
