import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setSecurities, setTryoutOperationSecurity } from "../../../app/Features/Swagger/SwaggerSlice";
import { debounceFn, objectDeepClone } from "../../../utils/helper";
import { Input } from "./Inputs";
import { X } from "phosphor-react";


const onInput = ({ value }) => {
    window.dispatch(setSecurities({ prop: "bearer", value }))
    setTimeout(() => {
        window.dispatch(setTryoutOperationSecurity())
    }, 200);
}
const onInputDebounceFn = debounceFn(onInput, 200);


const Bearer = (props) => {
    const { securities } = useSelector((state) => state.swagger);

    const [accessToken, setAccessToken] = useState('');
    
    useEffect(() => {
        setAccessToken(securities.bearer?.accessToken || '')
    }, [securities])

    const onInput = ({value}) => {
        setAccessToken(value)
        let _bearer = objectDeepClone(securities?.bearer)
        _bearer.accessToken = value;
        onInputDebounceFn({ value: _bearer })
    }

    return (
        <div>
            <Input
              label={"Access Token"}
              value={accessToken || ""}
              onChange={(e) => {
                onInput({ value: e.target.value });
              }}
            />
        </div>
    )
}

export default Bearer;