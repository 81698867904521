import React, { useMemo, useState } from 'react'
import CustomNavbar from '../../../Dashboards/components/CustomNavbar'
import { Link, useNavigate } from 'react-router-dom'
import GlobalIcons from '../../../../components/Icons/GlobalIcons'
import { CloudLightning, Eye, EyeSlash, Pencil, PencilSimple, Power, Upload } from 'phosphor-react'
import Select from 'react-select'
import countryList from '../../../../countries.json'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { confirm, encryptPassword, throwServerError } from '../../../../utils/helper'
import API from '../../../../api'
import { useDispatch } from 'react-redux'
import { resetAuthenticatedState } from '../../../../app/Features/Users/UserSlice'
import { getCookie, removeCookie } from '../../../../utils/cookie'
import { objectDeepClone } from '@apiwiz/oas/services'
import { getToast } from '../../../../components/Toast'

export default function ProfilePage() {
    const [editMode, setEditMode] = useState(false)
    const {currentUser} = useSelector((state) => state.user);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [showPass2, setShowPass2] = useState(false)

    const [data, setData] = useState({
        fullName:'', email: '', username: '', userImage: '', country: '', billing: {
            phoneNumber: '', billingAddress: '', zipCode: '', billingCurrency: {}
        }, password: '', confirm_password: ''
    })

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [currencies, setCurrencies] = useState([])

    const dispatch = useDispatch()

    function fileUpload(e){
        setLoading(true)
        API.uploadImage(e.target.files[0])
        .then(res => {
            setData({...data, userImage: res.file?.url || ''})
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    useEffect(()=>{
        
        if(currentUser){
            setData({
                ...data,
                fullName: currentUser.fullName || '',
                email: currentUser.email || '',
                username: currentUser.username || '',
                userImage: currentUser.userImage || '',
                country: currentUser.country || '',
                billing: currentUser.billing || {
                    phoneNumber: '', billingAddress: '', zipCode: '', 
                    billingCurrency: {}
                },
            })
            getCurrencies()
        } else {
            navigate('/login')
        }
    },[currentUser])

    const getCurrencies = () => {
        API.getMasterCurrencies({status: 'PUBLISH'})
        .then(res => {
            let _data = res.data?.data || []
            if (_data.length > 0) _data = _data[0].currencies || []

            let _modifiedData = []
            let labels = []

            _data.forEach((each) => {
                each['currencyId'] = each.id 
                if (labels.includes(each.currencyName)) return
                if (each.hasOwnProperty('id')) delete each.id
                _modifiedData.push({...each})
                labels.push(each.currencyName)
            })
            
            setCurrencies(_modifiedData)
        }).catch(err => {
            // throwServerError(err)
        })
    }

    const handleCancel = () => {
        setData({
            ...data,
            fullName: currentUser.fullName || '',
            email: currentUser.email || '',
            username: currentUser.username || '',
            userImage: currentUser.userImage || '',
            country: currentUser.country || '',
            billing: currentUser.billing || {
                phoneNumber: '', billingAddress: '', zipCode: '', billingCurrency: {}
            },
        })
        setNewPassword('')
        setConfirmPassword('')
        setEditMode(false)
    }

    const options = useMemo(() => {
        let _temp = countryList
        _temp = _temp.map(o => ({label: o.label, value: o.label}))
        return _temp
    }, [])

    function handleDeactivate(){
        let msg = "Are you sure you want to deactivate the account?";
        if (confirm(msg)) {
            const id = currentUser._id;
            API.updateStatus({id, status: "deactivated"})
            .then(response => {
               
                dispatch(resetAuthenticatedState())
                if (getCookie('itorix-token')) removeCookie('itorix-token')
                if (getCookie('itorix-token-data'))removeCookie('itorix-token-data')

                window.location.href = '/login'
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    const requiredKeys = ['fullName', 'email', 'username', 'userImage', 'country']

    function handleSave(e){
        e.preventDefault();
        const _data = objectDeepClone(data)
        let isValid = true

        requiredKeys.forEach((each) => {
            if (!_data[each]){
                isValid = false
                getToast({statusType: 'ERROR', message: `${each === 'fullName' ? 'Full Name' : each === 'userImage' ? 'User Image' : each} is required`})
                return
            }
        })

        if (!isValid) return

        if (_data.billing && _data.billing.hasOwnProperty('_id')) {
            delete _data.billing['_id']
        }
        if (!_data.billing.billingAddress){
            getToast({statusType: 'ERROR', message: `Billing Address is required`})
            return
        }

        if (!_data.billing.billingCurrency || !_data.billing.billingCurrency?.currencyName){
            getToast({statusType: 'ERROR', message: `Billing Currency is required`})
            return
        }

        if ((confirmPassword && newPassword) && (confirmPassword === newPassword)){
            _data['password'] = encryptPassword(newPassword)
            _data['confirm_password'] = confirmPassword
        }

        API.updateUserDetails(objectDeepClone(_data))
        .then(response => {
            getToast({statusType:"SUCCESS",message: 'Saved' })
            setEditMode(false)
        })
        .catch(error => {
            throwServerError(error)
        })
    }

    return (
        <div className='w-100vw h-100 bg-currentBackground'>
            <CustomNavbar />

            <div className='mt-80px container w-100 py-2'>
                <div className='d-flex align-items-center mb-3'>
                    <Link className='text-primary-color fw-500 fs-14px' to='/my-account'>Your Account</Link>
                    <p className='mx-2 text-consumer-400'>/</p>
                    <p className='text-primary-color fw-500 fs-14px'>My Profile</p>
                </div>

                <div className='d-block mx-auto mt-4 py-32px' style={{maxWidth: 500}}>
                    <p className='text-content-color fs-18px fw-500 text-center'>Profile Picture</p>

                    <div className='mt-4 mb-2 dfaic justify-content-center w-100'>
                        { data.userImage 
                        ? <img src={data.userImage} style={{borderRadius: '50%'}} height="200px" width="200px" />
                        : <GlobalIcons type="emptyImage"></GlobalIcons>}
                    </div>

                    {editMode && <div className='dfaic justify-content-center'>

                        <label htmlFor={`uploadProfilev2`}
                            className='cursor dfaic br-4px w-fit justify-content-center px-20px py-11px mt-4'
                            style={{border: '0.5px solid var(--content-color)'}}>
                            <Upload size={12} />
                            <p className='fw-500 ms-2 fs-12px consumer-text-300'>{loading ? 'Uploading' : 'Upload'} Image </p>
                        </label>
                        <input type={'file'} id='uploadProfilev2' className='d-none'
                            onChange={(e) => fileUpload(e)} />
                    </div>}

                    <div className='mt-4'>
                        <div className='dfaic justify-content-between'>
                            <p className='grey-subtext fs-16px fw-500 mb-10px'>User Information</p>

                            {!editMode && <div className='cursor' onClick={() => setEditMode(true)}>
                                <PencilSimple size={14} />
                            </div>}
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-content-color'>Full Name <span className='text-danger'>*</span></p>
                            {editMode 
                            ? <input value={data.fullName} onChange={(e) => setData({...data, fullName: e.target.value})}
                                placeholder='Enter your full name' required
                                className="from-control v1-form-control mt-1 v1-form-control-public w-100"
                            />
                            : <p className='text-consumer-400 fs-16px fw-400'>{data.fullName}</p>}
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-content-color'>User Name <span className='text-danger'>*</span></p>
                            {editMode
                            ? <input value={data.username} onChange={(e) => setData({...data, username: e.target.value})}
                                placeholder='Enter your user name' required
                                className="from-control v1-form-control mt-1 v1-form-control-public w-100"
                            />
                            : <p className='text-consumer-400 fs-16px fw-400'>{data.username}</p>}
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-content-color'>Email <span className='text-danger'>*</span></p>
                            {editMode 
                            ? <input type='email' value={data.email} onChange={(e) => setData({...data, email: e.target.value})}
                                placeholder='Enter your email' required
                                className="from-control v1-form-control mt-1 v1-form-control-public w-100"
                            />
                            : <p className='text-consumer-400 fs-16px fw-400'>{data.email}</p>}
                        </div>

                        <div className='mb-3 mainSelect'>
                            <p className='fs-12px fw-400 text-content-color'>Country</p>
                            {editMode 
                            ? <Select options={options}
                                className='mt-1 v1select'
                                value={{label: data.country, value: data.country}} 
                                onChange={(e) => setData({...data, country: e.value})}
                                classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }} />
                            : <p className='text-consumer-400 fs-16px fw-400'>{data.country || '-'}</p>}
                        </div>

                        {<>
                            <p className='grey-subtext fs-16px fw-500 mb-2'>Billing Information</p>

                            <div className='mb-4 mainSelect'>
                                <p className='text-content-color fs-14px fw-400'>Preferred Currency for Billing <span className='text-danger'>*</span></p>
                                {editMode
                                ? <Select options={currencies?.map((each) => ({...each, label: `${each.currencyName} (${each.currencyCode})`, value: each.code}))} 
                                    className='mt-1 v1select' 
                                    value={data.billing.billingCurrency} 
                                    onChange={(e) => setData({...data, billing: {...(data.billing || {}), billingCurrency: e} })}
                                    classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }} />
                                : <p className='text-consumer-400 fs-16px fw-400'>{data.billing.billingCurrency?.label ? data.billing.billingCurrency?.label : '-'}</p>}
                            </div>

                            <div className='mb-3'>
                                <p className='text-content-color fs-14px fw-400'>Billing address <span className='text-danger'>*</span> </p>
                                {editMode
                                ? <textarea value={data.billing.billingAddress || ''} 
                                    onChange={(e) => setData({...data, billing: {...(data.billing || {}), billingAddress: e.target.value} })}
                                    rows={3} placeholder='Enter your billing address'
                                    className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
                                />
                                : <p className='text-consumer-400 fs-16px fw-400'>{data.billing?.billingAddress || '-'}</p>}
                            </div>

                            <div className='mb-3'>
                                <p className='text-content-color fs-14px fw-400'>Zip / Postal Code <span className='text-danger'>*</span> </p>
                                {editMode
                                ? <input value={data.billing.zipCode || ''} 
                                    onChange={(e) => setData({...data, billing: {...(data.billing || {}), zipCode: e.target.value} })}
                                    placeholder='Enter your zip/postcal code'
                                    className="from-control v1-form-control mt-1 v1-form-control-public w-100"
                                />
                                : <p className='text-consumer-400 fs-16px fw-400'>{data.billing?.zipCode || '-'}</p>}
                            </div>

                            <div className='mb-4'>
                                <p className='text-content-color fs-14px fw-400'>Telephone No <span className='text-danger'>*</span> </p>
                                {editMode
                                ? <input value={data.billing.phoneNumber || ''} 
                                    onChange={(e) => setData({...data, billing: {...(data.billing || {}), phoneNumber: e.target.value} })}
                                    placeholder='Enter your telephone number'
                                    className="from-control v1-form-control mt-1 v1-form-control-public w-100"
                                />
                                : <p className='text-consumer-400 fs-16px fw-400'>{data.billing?.phoneNumber || '-'}</p>}
                            </div>
                        </>}

                        {editMode && <>
                            <p className='grey-subtext fs-16px fw-500 mb-3'>
                                Login Information
                            </p>

                            <div className='mb-3'>
                                <p className='text-content-color fs-14px fw-400'>Enter new password (Optional)</p>
                                <div className='position-relative mt-1'>
                                    <input type={showPass ? 'text' : 'password'} className='form-control v1-form-control-public v1-form-control mt-1'
                                        placeholder='Enter your password' value={newPassword}
                                        autocomplete="new-password"
                                        onChange={(e) => {
                                            let _str = e.target.value
                                            setNewPassword(_str)
                                        }} />
                                    <div className='eyeIconPass cursor' onClick={() => setShowPass(curr => !curr)}>
                                        {showPass
                                            ? <EyeSlash color='var(--primary-color)' size={16} />
                                            : <Eye color='var(--primary-color)' size={16} />}
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <p className='text-content-color fs-14px fw-400'>Confirm new password</p>
                                <div className='position-relative mt-1'>
                                    <input type={showPass2 ? 'text' : 'password'} className='form-control v1-form-control-public v1-form-control mt-1'
                                        placeholder='Enter your password' value={confirmPassword}
                                        autocomplete="new-password"
                                        onChange={(e) => {
                                            let _str = e.target.value
                                            setConfirmPassword(_str)
                                        }} />
                                    <div className='eyeIconPass cursor' onClick={() => setShowPass2(curr => !curr)}>
                                        {showPass2
                                            ? <EyeSlash color='var(--primary-color)' size={16} />
                                            : <Eye color='var(--primary-color)' size={16} />}
                                    </div>
                                </div>
                            </div>
                        </>}

                        {editMode
                        ? <div className='mb-3 dfaic justify-content-between'>
                            <div>

                            </div>

                            <div className='dfaic gap-12px'>
                                <div className='cursor px-16px py-10px br-6px bg-primary-color'
                                    style={{border: '1px solid var(--primary-color)'}} onClick={handleSave}>
                                    <p className='text-white fw-600 fs-16px'>Save</p>
                                </div>
                                <div className='cursor px-16px py-10px br-6px custom-bordr-1'
                                    onClick={handleCancel}>
                                    <p className='text-content-color fw-600 fs-16px'>Cancel</p>
                                </div>
                            </div>
                        </div>
                        : <div className='dfaic justify-content-center'>
                            <div className='dfaic cursor gap-8px px-20px py-11px custom-bordr-1 br-4px w-fit' onClick={handleDeactivate}>
                                <Power size={12} className='text-consumer-300' />
                                <p className='text-consumer-300 fs-12px fw-500'>Deactivate Account</p>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
