import { CaretDown } from "phosphor-react";
import BootstrapDropdown from "../../BootstrapDropdown";

export function Input(props) {
  return (
    <div className="d-flex flex-column gap-4px py-4px">
      <div className="fs-12px text-theme-content-subtle d-flex gap-2px">
        {props.label}{" "}
        {props.required ? <span className="text-danger">*</span> : null}
      </div>
      <div className="br-4px bg-theme-primary-subtle border-theme-primary-subtle d-flex justify-content-between h-32px align-items-center pl-8px pr-4px">
        <input
          className={`h-32px lh-24px input-transparent fs-14px text-theme-content-color mt--2px ${
            props.inText ? "w--90px" : "w--14px"
          }`}
          disabled={!!props.disabled}
          value={props.value}
          onChange={props.onChange || null}
        />
        {props.inText ? (
          <div className="theme-data-type-chip text-truncate">
            {props.inText}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function BorderWithLabel(props) {
  return (
    <div className="position-relative border-theme-base-200 br-4px">
      <div className="position-absolute ab-t--10px ab-l-8px fs-12px text-theme-content-subtle bg-theme-base px-6px">
        {props.label}
        {""} {props.required ? <span className="text-danger">*</span> : null}
      </div>
      {props.children}
    </div>
  );
}

export function MultiDropdown(props) {
  return (
    <div className="d-flex flex-column gap-4px py-4px">
      <div className="fs-12px text-theme-content-subtle d-flex gap-2px">
        {props.label}{" "}
        {props.required ? <span className="text-danger">*</span> : null}
      </div>
      <div className="br-4px bg-theme-primary-subtle border-theme-primary-subtle d-flex justify-content-between h-32px align-items-center pl-8px pr-4px">
        <BootstrapDropdown
          className="theme-specific w-100"
          options={(props.options || [])}
          autoClose="outside"
        >
          <div className="h-100 cursor d-flex align-items-center justify-content-between">
            <div className="fs-14px fw-400" >{props.value || ""}</div>
            <div>
              <CaretDown size={16} weight="bold" color="var(--primary-color)" />
            </div>
          </div>
        </BootstrapDropdown>
      </div>
    </div>
  );
}
