import { objectDeepClone } from '@apiwiz/oas/services';
import { ArrowSquareOut } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import API from '../../../api';
import { getRatePlanValue } from '../../../utils/helper';

export default function RatePlanModal(props) {
    const [data, setData] = useState({})
    const [guideData, setGuideData] = useState({})

    useEffect(() => {
        let _data = objectDeepClone(props.data || {})
        console.log('_data', _data)
        setData(objectDeepClone(_data))
        getGuideProductBundles(_data?.ratePlan?.name)
    }, [props.data])

    const getGuideProductBundles = (_name) => {
        API.getGuideProductBundles(_name)
        .then(res => {
            setGuideData(res.data.data)
        }).catch(err => {
            console.log('err', err)
        })
    }
    
    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px homeModalForLghtMode'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-content-subtle fw-500 fs-16px'>
                        {data?.ratePlan?.name}
                    </p>

                    <div className='d-flex align-items-center'>
                        <div className='text-content-subtle fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div>
                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Rate Plan Name</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.ratePlan?.name}
                        </p>
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Product Bundle</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.productBundle?.name}
                        </p>
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Rate Plan Type</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.ratePlan?.type}
                        </p>
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Audience</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.ratePlan?.audience}
                        </p>
                    </div>

                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <p className='fs-12px fw-400 text-content-subtle'>Start Date</p>
                            <p className='fs-14px fw-500 text-content-color mt-1px'>
                                {data?.ratePlan?.startDate?.split(" ")[0]}
                            </p>
                        </div>

                        {data?.ratePlan?.hasEndDate && <div className='col-md-6'>
                            <p className='fs-12px fw-400 text-content-subtle'>End Date</p>
                            <p className='fs-14px fw-500 text-content-color mt-1px'>
                                {data?.ratePlan?.endDate?.split(" ")[0]}
                            </p>
                        </div>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Organization Name</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.ratePlan?.organization}
                        </p>
                    </div>

                    {data?.ratePlan?.visibleToPortals && <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Rate Plan</p>

                        {getRatePlanValue({ rateplan: data?.ratePlan }).map(
                            (pricing) => (
                              <div className="bg-base-100 px-18px py-12px mt-1">
                                <p className='text-content-color fs-12px'>{pricing}</p>
                              </div>
                            )
                        )}
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
