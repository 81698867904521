import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { getPaginationList } from "../../utils/helper";

//pagination obj - {total: 0, offset: 0, pageSize: 0}
const Pagination = ({ pagination, cb, className }) => {
  const totalPageslist = Math.ceil(pagination.total / pagination.pageSize);
  const pagesList = getPaginationList(pagination.offset, totalPageslist);

  return (
    <>
      <ul
        className={`pagination mt-1 justify-content-center ${className || ""}`}
        style={{
          fontSize: "14px",
        }}
      >
        <li
          className={`${
            pagination.offset === 1 && "d-none"
          } me-4 cursor`}
        >
          <MdKeyboardArrowLeft onClick={() => cb(pagination.offset - 1)} />
        </li>

        {pagesList &&
          pagesList.map((page, i) => (
            <div
              key={i}
              className={`cursor me-4 ${
                page === pagination.offset && "pagination-active px-2"
              }`}
            >
              {page === pagination.offset ? (
                <span>{page}</span>
              ) : page === "..." ? (
                <span>{page}</span>
              ) : (
                page !== "..." &&
                page !== pagination.offset && (
                  <span onClick={() => cb(page)}>{page}</span>
                )
              )}
            </div>
          ))}

        <li
          className={`cursor ${
            pagination.offset ===
              Math.ceil(pagination.total / pagination.pageSize) &&
            "disabled text-muted d-none"
          }`}
        >
          <MdKeyboardArrowRight onClick={() => cb(pagination.offset + 1)} />
        </li>
      </ul>
    </>
  );
};

export default Pagination;
