import { CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import CaretDown from "../components/CaretDown";
import { getParamType, getParamExample } from "@apiwiz/oas/helper";
import { setTryoutLoad, setTryoutParamsValue } from "../../../app/Features/Swagger/SwaggerSlice";
import { debounceFn } from "../../../utils/helper";

const updateParameter = (payload) => {
  window.dispatch(setTryoutParamsValue(payload))
}

const parameterDebounceFn = debounceFn(updateParameter)

const onParamChange = (payload) => {
  window.dispatch(setTryoutLoad(true))
  parameterDebounceFn(payload);
}


function ParameterInput(props) {
  const {param} = props;
  const getCurrValue = () => {
    if (param.__value !== undefined) return param.__value;
    return getParamExample(param);
  };

  const [value, setValue] = useState()
  useEffect(() => {
    setValue(getCurrValue())
  }, [param])

  const getRequiredValue = () => {
    return param.__required || (typeof param.required === "boolean" && param.required)
  }

  const onChange = (e) => {
    let val = e.target.value;
    setValue(val)
    if (props.onChange) props.onChange(val);
}

  return (
    <div className="d-flex flex-column gap-4px py-12px pl-31px param-card in-tryout">
      <div className="fs-12px text-theme-content-subtle d-flex gap-2px param-card-label">
        {param.name}{" "}
        {getRequiredValue() ? (
          <span className="text-danger">*</span>
        ) : null}
      </div>
      <div className="br-4px bg-theme-primary-subtle border-theme-primary-subtle d-flex justify-content-between h-32px align-items-center px-12px">
        {param.type === "file" ? (
          <input type="file"
          className="bg-transparent border-none outline-none fs-12px lh-14px w--64px"
          onChange={onChange}
        />
        ) : (
          <input
          className="h-32px lh-24px bg-transparent border-none outline-none fs-14px w--64px text-theme-content-color"
          value={value} onChange={onChange}
        />
        )}
        <div className="theme-data-type-chip">{getParamType(param)}</div>
      </div>
    </div>
  );
}

export default function ParameterConfig(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="pl-10px pr-6px py-6px bg-theme-base br-4px d-flex flex-column gap-4px">
      <div
        className={`cursor d-flex justify-content-between align-items-center w-100 pr-6px br-4px h-34px ${
          open ? "" : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`d-flex align-items-center gap-4px`}>
          {!open ? (
            <CaretRight
              color="var(--primary-color)"
              size={13}
              className="mr-4px"
            />
          ) : (
            <CaretDown />
          )}
          <div
            className={`text-theme-primary fs-13px lh-21px h-21px ${
              open ? "fw-600" : ""
            }`}
          >
            {props.title}
          </div>
        </div>
      </div>
      {open ? (
        <div>
          {(props.params || []).map((el, index) => {
            return <ParameterInput param={el} onChange={(value) => {
              onParamChange({prop: props.field, index, value})
            }} />;
          })}
        </div>
      ) : null}
    </div>
  );
}
