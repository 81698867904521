import { WarningOctagon } from 'phosphor-react'
import React, { useState } from 'react'
import ReportIssueModal from './ReportIssueModal'

export default function ReportIssue({concernText, data, handleClick=null, allInvoices=[]}) {
    const [showModal, setshowModal] = useState(false)

    return (    
        <>
            <div className='strokes-800-border cursor px-6px py-4px br-8px gap-4px dfaic jcc'
                onClick={() => {
                    if (handleClick) handleClick() 
                    else setshowModal(true)
                }}>
                <WarningOctagon size={16} className='text-consumer-300' />
                <p className='fw-600 fs-14px text-consumer-300'>Report Issue</p>
            </div>

            {showModal && <ReportIssueModal concernText={concernText} show={showModal}
                onHide={() => setshowModal(false)} data={data} allInvoices={allInvoices} />}
        </>
    )
}
