import axios from "axios";

import APIS from "../service/config";
import { getCookie } from "../utils/cookie";
import paginationLimit from "../utils/pagination-limit";
import { logoutClientSide } from "../utils/helper";
const { API, MONETIZATION } = APIS;

let headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

if (getCookie("itorix-token")) {
  headers["authorization"] = `Bearer ${getCookie("itorix-token")}`;
}

let api = axios.create({
  baseURL: API,
  headers: headers,
});

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			logoutClientSide();
		}
		return Promise.reject(error);
	}
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    initHeaders(token) {
      let header = api.defaults.headers;
      if (token) {
        header["authorization"] = `Bearer ${token}`;
      } else {
        if (header.hasOwnProperty("authorization")) {
          delete header["authorization"];
        }
      }
    },
    // --------------------- Apiwiz Monetization V2 ---------------------

    getMonetizationApps(params){
      return api.get('/v2/monetization/devportal/apps', {params: {...params}})
    },

    deleteMonetizedApp(id){
      return api.delete(`/v2/monetization/devportal/app/${id}`)
    },
    
    getSubscribedPackages(params){
      return api.get('/v2/monetization/devportal/subscription', {params: {...params}})
    },

    getSubscribedPackagesV2(params){
      return api.get('/v2/monetization/devportal/subscription/all', {params: {...params}})
    },

    addSubscription(body){
      return api.post('/v2/monetization/devportal/subscription', {
        subscribe: body
      })
    },

    updateSubscription(id, params, body){
      return api.put(`/v2/monetization/devportal/subscription/${id}`, {
        subscribe: body
      }, {
        params
      })
    },

    getSubscriptionDetails({id, developerEmail}){
      return api.get(`/v2/monetization/devportal/subscription/${id}?developerEmail=${developerEmail}`)
    },

    deleteSubscription(id, developerEmail){
      return api.delete(`/v2/monetization/devportal/subscription/${id}?developerEmail=${developerEmail}`)
    },

    addPackageToSubscription({id, developerEmail, packageId}){
      return api.patch(`${MONETIZATION.DEVELOPER_PORTAL}/subscription/${id}/package`, {}, {
        params: {
          developerEmail, packageId
        }
      })
    },
  
    removePackageFromSubscription({id, developerEmail, packageId}){
      return api.patch(`${MONETIZATION.DEVELOPER_PORTAL}/subscription/${id}/package/${packageId}?developerEmail=${developerEmail}`, {})
    },

    getAllPackages(params){
      return api.get("/v2/monetization/package", {params: {...params}})
    },

    createApp(body){
      return api.post("/v2/monetization/devportal/app", body)
    },

    getAppById(appId){
      return api.get(`/v2/monetization/devportal/app/${appId}`)
    },

    updateAppStatus(id, status, developerEmail = '', productAppId = ''){
      let url = `/v2/monetization/devportal/app/${id}/status?status=${status}`
      if (developerEmail) url += `&developerEmail=${developerEmail}`
      if (productAppId) url += `&productAppId=${productAppId}`
      
      return api.put(url)
    },

    getPackageById(id) {
      return api.get(`/v2/monetization/package/${id}`)
    },

    getPackageOverallRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/package/revenue', {params: {...params}})
    },

    getPackageResourceMeteringRule(params) {
      return api.get('/v2/monetization/devportal/stats/package/resource/metering-rule/revenue', {params: {...params}})
    },

    getDailyPackageRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/package/revenue/daily', {params: {...params}})
    },

    getPackageResourcesRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/package/resources/revenue', {params: {...params}})
    },

    getAppOverallRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/app/revenue', {params: {...params}})
    },

    getAppResourcesRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/app/package/resources/revenue', {params: {...params}})
    },

    getDailyAppRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/app/revenue/daily', {params: {...params}})
    },

    getAppPackagesRevenue(params) {
      return api.get('/v2/monetization/devportal/stats/app/packages/revenue', {params: {...params}})
    },

    getAssociatedApps(params) {
      return api.get(`/v2/monetization/devportal/package/${params.packageId}/apps`, {params: {developerEmail: params.developerId, expand: true}})
    },

    fetchResourceMeteringRules(params) {
      return api.get(`v2/monetization/devportal/stats/package/resource/metering-rule/revenue`, {params: {...params}})
    },

    fetchOverallFromApp(params) {
      return api.get("v2/monetization/devportal/stats/app/package/revenue", {params: {...params}})
    },

    fetchResourceFromApp(params) {
      return api.get("v2/monetization/devportal/stats/app/package/resources/revenue", {params: {...params}})
    },
    
    fetchDailyRevenueFromApp(params) {
      return api.get("v2/monetization/devportal/stats/app/package/revenue/daily", {params: {...params}})
    },

    fetchAppResourceMeteringRules(params) {
      return api.get(`v2/monetization/devportal/stats/app/package/resource/metering-rule/revenue`, {params: {...params}})
    },

    fetchMonetizedAppTransactionLog(params) {
      return api.get(`v2/monetization/devportal/stats/app/transactions`, {params: {...params}})
    },

    fetchMonetizedAppTransactionLogTotal(params) {
      return api.get(`v2/monetization/devportal/stats/app/transactions/total`, {params: {...params}})
    },

    fetchComplianceRuntimeCatalog(params) {
      return api.get(`v2/compliance/reports/runtime-catalog`, {params: {...params}})
    },

    decryptComplianceRuntimeCatalog(params) {
      return api.post(`v2/compliance/reports/decrypt`, {} ,{params: {...params}})
    },

    fetchMeteringRuleById(packageId, resourceId, meteringRuleId) {
      return api.get(`v2/monetization/package/${packageId}/resource/${resourceId}/metering-rule/${meteringRuleId}`)
    },

    getRateCardById(rateCardId) {
      return api.get(`v2/monetization/rate-card/${rateCardId}`)
    }

    // --------------------- Apiwiz Monetization V2 Ends ---------------------

};
