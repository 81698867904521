import React, { useEffect, useState } from 'react'
import GetIcon from '../../../components/GetIcon'

export default function CustomCard1({data, fromEditor}) {

    return (
        <div className={'position-relative'}>
            {data.imageUrl && <div className='overlayImageFromCard'>
                <img src={data.imageUrl} alt={data.heading} className='h-100 w-100' />
            </div>}
            <div className='container'>
                <div className={`bg-currentBackground zIndex-3 row m-0 px-0px py-48px`}> 
                    <div className='position-relative zIndex-4'>
                        {data.tagName && <div className='titleTagPartner mb-17px d-block mx-auto'>
                            <p className='text-theme-base fs-16px fw-600 text-center'>{data.tagName}</p>
                        </div>}
                        <p className='fs-36px fw-600 text-content-color text-center' style={{lineHeight: '110%'}}>
                            {data.heading}
                        </p>
                        {data.description && <p className='fs-22px fw-400 text-content-subtle text-center mt-24px' style={{lineHeight: '130%'}}>
                            {data.description}
                        </p>}
                    </div>

                    <div className='eachCardWrapper mt-52px'>

                        {data.showGradients && <div className='gradientBackgroundAnimation'></div>}
                        {data.cards?.map((eachCard, i) => <div 
                            className={`eachLandingCardv2 ${data.showBorder ? 'hover-effects' : 'no-hover-effects'}`} key={i}>
                            <div>
                                <GetIcon type={eachCard.icon} size={52} weight={eachCard.iconWeight}
                                    color={data.fillPrimaryColor ? 'var(--primary-color)' : `var(--content-color)`} />
                                
                                <div className='mt-24px mb-16px'>
                                    <p className='fs-26px fw-700 text-content-color text-truncate-2'>
                                        {eachCard.title}
                                    </p>
                                </div>

                                <div className=''>
                                    <p className='fs-20px fw-400 text-content-subtle text-truncate-10' 
                                        style={{lineHeight: '130%'}} title={eachCard.description}>
                                        {eachCard.description}
                                    </p>
                                </div>
                            </div>

                            {(eachCard.primaryCTA.label || eachCard.secondaryCTA.label) && 
                            <div className='d-flex align-items-center flex-wrap gap-8px mt-70px'>
                                {eachCard.primaryCTA.label && <a href={eachCard.primaryCTA?.url || '#'} target='_blank'>
                                    <div className='primaryCTAButton'>
                                        <p className='fs-16px'>{eachCard.primaryCTA?.label || ''}</p>
                                    </div>
                                </a>}

                                {eachCard.secondaryCTA.label && <a href={eachCard.secondaryCTA?.url || '#'} target='_blank'>
                                    <div className='cursor mx-2'>
                                        <p className='fs-16px px-2 text-content-subtle'>
                                            {eachCard.secondaryCTA?.label || ''}
                                        </p>
                                    </div>
                                </a>}
                            </div>}
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}
