
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import { useSelector } from 'react-redux';
import UserIcons from '../../../../components/Icons/UserIcons';
import { confirm } from '../../../../utils/helper';


export default function DeatilsModal(props){

    return  <Modal
    {...props}
    size="md me-0 mt-0 mb-0"
    contentClassName='br-0px'
    className='custom-modal-v1 v1--styles'
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header className='p-3 br-0px'>
    <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
                <p className="mb-0 text-300 fw-500 fs-14px">
                    Contact Details
                </p>
            </div>

            <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' onClick={props.onHide}>
                Close
            </div>
        </div>
    </Modal.Header>
    <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar"
    >
                <div className=''>
                    <div className='d-flex w-100'>
                        <p className='text-200 fs-14px w-40'>Full Name</p>
                        <p className='text-300 fs-14px w-30'>{props.data.fullName}</p>
                    </div>
                    <div className='d-flex w-100 mt-3'>
                        <p className='text-200 fs-14px w-40'>Job Title</p>
                        <p className='text-300 fs-14px w-30'>{props.data.jobTitle}</p>
                    </div>
                    <div className='d-flex w-100 mt-3'>
                        <p className='text-200 fs-14px w-40'>Company</p>
                        <p className='text-300 fs-14px w-30'>{props.data.company}</p>
                    </div>
                    <div className='d-flex w-100 mt-3'>
                        <p className='text-200 fs-14px w-40'>Business Email</p>
                        <p className='text-300 fs-14px w-30'>{props.data.businessEmail}</p>
                    </div>
                    <div className='d-flex w-100 mt-3'>
                        <p className='text-200 fs-14px w-40'>Message</p>
                        <p className='text-300 fs-14px w-50 text-break'>{props.data.message}</p>
                    </div>
                </div>
            
    </Modal.Body>
</Modal>
}