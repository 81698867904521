import React from 'react'
import { useTheme } from '../../../context/ThemeContext'
import { getThemeBasedImage } from '../../../utils/helper'

export default function CustomHero1({data, fromEditor}) {
    const {theme} = useTheme()

    return (
        <div className={`${data.showBackground ? 'bg-base-200' : 'bg-currentBackground'}`}>
            <div className={fromEditor ? 'w-80 d-block mx-auto' : 'container'}>
                <div className={`customHero1 customHero1${data.imagePlacement || 'right'} m-0`}>
                    <div className={`leftContent my-auto py-5 ps-0 pe-5 firstRow mobileTextCenter ${data.imagePlacement === 'center' ? 'text-center-all' : ''}`}>
                        {data.tagName && <p className='text-content-subtle fs-16px fw-600 mb-14px' style={{lineHeight: '150%'}}>
                            {data.tagName || ''}
                        </p>}
                        <p className='text-content-color fs-60px fw-600' style={{lineHeight: '120%'}}>
                            {data.heading || ''}
                        </p>
                        <p className='fs-20px fw-400 text-content-subtle mt-4'>
                            {data.description}
                        </p>

                        <div className='mt-4 d-flex align-items-center gap-20px mobile-jcc'>
                            {data.secondaryCTA?.label && <a href={data.secondaryCTA?.url || '#'} target='_blank'>
                                <div className={`secondaryCTAButton ${data.secondaryCTA?.applyColor ? 'darkVersion' : ''}`}>
                                    <p className='fs-16px'>{data.secondaryCTA?.label || ''}</p>
                                </div>
                            </a>}

                            <a href={data.primaryCTA?.url || '#'} target='_blank'>
                                <div className='primaryCTAButton'>
                                    <p className='fs-16px'>{data.primaryCTA?.label || ''}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='rightContent px-0'>
                        <img src={data.imageUrl} alt='Hero Logo' 
                            className={`${theme === 'theme--dark' ? 'd-none' : 'd-block'}`}
                            style={{height: 640, width: '100%', maxWidth: 670 ,objectFit: 'contain'}}/>
                        <img src={data.imageUrlDark} alt='Hero Logo' 
                            className={`${theme === 'theme--light' ? 'd-none' : 'd-block'}`}
                            style={{height: 640, width: '100%', maxWidth: 670 ,objectFit: 'contain'}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
