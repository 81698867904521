import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomNavbar from "../Dashboards/components/CustomNavbar";
import CustomFooter from "../Dashboards/components/CustomFooter";
import {
  ArrowSquareOut,
  BagSimple,
  CaretRight,
  Clipboard,
  Cloud,
  CreditCard,
  Eye,
  FilePlus,
  GridFour,
  MagnifyingGlass,
  PencilSimple,
  Plus,
  Rows,
  Trash,
} from "phosphor-react";
import Select from "react-select";
import API from "../../api";
import monetizationAPI from "../../api/monetization";
import {
  formatCtsDate,
  formatCtsTime,
  throwServerError,
  copyToClipboard,
  sleep,
  confirm,
  showSuccessMessage,
} from "../../utils/helper";
import ProductsModal from "./components/ProductsModal";
import { objectDeepClone } from "@apiwiz/oas/services";
import Loading from "../../components/Loader/Loading";
import BuyNowModal from "./components/BuyNowModal";
import MyReactTooltip from "../../components/ReactTooltip";
import PurchaseModal from "./components/PurchaseModal";
import { getToast } from "../../components/Toast";
import GlobalIcons from "../../components/Icons/GlobalIcons";
import { ApigeeApp } from "./components/ApigeeApp";
import { KongApp } from "./components/KongApp";
import { AzureApp } from "./components/AzureApp";
import { AppDetailsModal } from "./components/AppDetailsModal";
import { AzureAppDetails } from "./components/AzureAppDetailsModal";
import { KongAppDetails } from "./components/KongAppDetailsModal";
import Pagination from "../../components/Pagination";
import { GATEWAYS } from "../../service/constants";
import { GenericAppRow } from "./components/GenericAppRow";
import { GenericAppDetails } from "./components/GenericAppDetailsModal";
import UpdateAppModal from "./components/UpdateAppModal";

const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

export default function ProductBundles({ isApps }) {
  const { isAuthenticated } = useSelector((state) => state.user);
  const [viewType, setViewType] = useState("grid");
  const [isLoading, setIsLoading] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [type, setType] = useState({});
  const [data, setData] = useState([]);
  const [copied, setCopied] = useState("");
  const [pagination, setPagination] = useState({
    offset: 1,
    pageSize: 12,
  });
  const [deleteLoading, setDeleteLoading] = useState({});

  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    if (searchStr) {
      const searchFilteredData = data.filter(
        (app) =>
          app.id?.toLowerCase().includes(searchStr.toLowerCase()) ||
          app.name?.toLowerCase().includes(searchStr.toLowerCase())
      );

      setPagination((prev) => ({
        ...prev,
        offset: 1,
        total: searchFilteredData?.length,
      }));

      // console.log("total data: ", searchFilteredData.length)
    } else {
      setPagination((prev) => ({
        ...prev,
        offset: 1,
        total: data.length,
      }));
    }
  }, [searchStr]);

  const [productsModal, setProductsModal] = useState({
    isOpen: false,
    data: {},
  });
  const [buyNowModal, setBuyNowModal] = useState({
    isOpen: false,
    data: {},
    isCreate: false,
  });
  const [purchaseModal, setPurchaseModal] = useState({
    isOpen: false,
    data: {},
    isEdit: false,
  });

  const [appDetailsModal, setAppDetailsModal] = useState({
    isOpen: false,
    data: {},
    type: "",
  });

  const [updateAppModal, setUpdateAppModal] = useState({
    isOpen: false,
    data: {}
  });

  const handleCopy = async (_text) => {
    setCopied(_text);
    copyToClipboard(_text);
    await sleep(1000);
    setCopied("");
  };

  const navigate = useNavigate();

  useEffect(() => {
    // getOrgs();
    getAllApps();
  }, [isAuthenticated]);

  const handleAppDelete = (obj) => {
    if (!confirm("Are you sure to delete selected app?")) return;
    if (obj.type === "kong") {
      setDeleteLoading(prev => ({...prev, [obj.id]: true}));
      monetizationAPI
        .deleteKongApp(obj.id)
        .then((res) => {
          getToast({
            statusType: "SUCCESS",
            message: "App deleted successfully",
          });
          getAllApps();
          setDeleteLoading(prev => ({...prev, [obj.id]: false}));
        })
        .catch((err) => {
          throwServerError(err);
          setDeleteLoading(prev => ({...prev, [obj.id]: false}));
        });
    } else if (obj.type === "azure") {
      const appName = obj.name;
      const connectorId = obj.connectorId;
      setDeleteLoading(prev => ({...prev, [obj.name]: true}));
      monetizationAPI
        .deleteAzureApp(appName, connectorId)
        .then((res) => {
          getToast({
            statusType: "SUCCESS",
            message: "App deleted successfully",
          });
          getAllApps();
          setDeleteLoading(prev => ({...prev, [obj.name]: false}));
        })
        .catch((err) => {
          throwServerError(err);
          setDeleteLoading(prev => ({...prev, [obj.name]: false}));
        });
    } else if (obj.type === GATEWAYS.NGINX){
      setDeleteLoading(prev => ({...prev, [obj._id]: true}));
      API.deleteNginxApp({id: obj._id})
      .then(res => {
        let _data = objectDeepClone(data);
        _data = _data.filter(el => el._id !== obj._id);
        setData(_data);
        showSuccessMessage("App Deleted successfully");
        setDeleteLoading(prev => ({...prev, [obj._id]: false}));
      })
      .catch(err => {
        throwServerError(err);
        setDeleteLoading(prev => ({...prev, [obj._id]: false}));
      })
    }
    else if (obj.type === AWS_GATEWAY){
      setDeleteLoading(prev => ({...prev, [obj._id]: true}));
      API.deleteAwsApp({id: obj._id})
      .then(res => {
        let _data = objectDeepClone(data);
        _data = _data.filter(el => el._id !== obj._id);
        setData(_data);
        showSuccessMessage("App Deleted successfully");
        setDeleteLoading(prev => ({...prev, [obj._id]: false}));
      })
      .catch(err => {
        throwServerError(err);
        setDeleteLoading(prev => ({...prev, [obj._id]: false}));
      })
    }
    else {
      //TODO: Apigee App should have organisation to delete app.
      setDeleteLoading(prev => ({...prev, [obj.name]: true}));
      API.deleteApp(obj.org, obj.name)
        .then((res) => {
          getToast({
            statusType: "SUCCESS",
            message: "App deleted successfully",
          });
          getAllApps();
          setDeleteLoading(prev => ({...prev, [obj.name]: false}));
        })
        .catch((err) => {
          throwServerError(err);
          setDeleteLoading(prev => ({...prev, [obj.name]: false}));
        });
    }
  };

  /**
   * new codes
   */
  const getAllApps = () => {
    setIsLoading(true);
    monetizationAPI
      .getAllApps()
      .then((res) => {
        setIsLoading(false);

        const _data = (res?.data?.data || []).map(el => {
          if([GATEWAYS.NGINX, AWS_GATEWAY].includes(el.type))
          {
            el.appId = el._id;
            el.createdBy = el.developerEmail;
            el.cts = el.createdAt;
            el.mts = el.updatedAt;
          }
          return el;
        });

        setData(_data);
        const total = _data.length;
        const pageSize = 12;
        setPagination({
          total,
          pageSize,
          offset: 1,
        });
      })
      .catch((err) => {
        setData([]);
        throwServerError(err);
        setIsLoading(false);
      });
  };

  const paginatedData = useMemo(() => {
    const searchFilteredData = data.filter(
      (app) =>
        app.id?.toLowerCase().includes(searchStr.toLowerCase()) ||
        app.name?.toLowerCase().includes(searchStr.toLowerCase())
    );

    const indexOfLastPost = pagination.offset * pagination.pageSize;
    const indexOfFirstPost = indexOfLastPost - pagination.pageSize;
    const currentPosts = searchFilteredData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    return currentPosts;
  }, [data, pagination, searchStr])

  const searchFilteredValues = (data) => {
    const searchFilteredData = data.filter(
      (app) =>
        app.id?.toLowerCase().includes(searchStr.toLowerCase()) ||
        app.name?.toLowerCase().includes(searchStr.toLowerCase())
    );

      return searchFilteredData
  }

  const updateAppCb = ({appId, description}) => {
    if(!description) return;
    let _data = objectDeepClone(data);
    let _app = _data.find(el => el._id === appId);
    if(_app) _app.description = description;
    setData(_data);
  }

  return (
    <div className="w-100vw bg-currentBackground">
      <CustomNavbar />

      <div className="mt-62px container productBundleSection">
        <div
          className="w-100 bg-theme-base h-100 py-32px"
          style={{ minHeight: "100vh" }}
        >
          {isLoading ? (
            <div className="mt-5">
              <Loading />
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <p className="fs-24px fw-600 text-content-color">
                  {isApps ? "My Apps" : "Product Bundles"}
                </p>

                <div className="dfaic">
                  <div
                    className="dfaic px-16px py-6px mr-8px cursor"
                    onClick={() => navigate(`/purchases`)}
                  >
                    <BagSimple
                      color="var(--content-color)"
                      className="mr-4px"
                    />
                    <p className="text-content-color fs-12px fw-500">
                      View All Purchases
                    </p>
                  </div>
                  <div className="dfaic me-3">
                    <div className='searchSection mb-8px'>
                      <MagnifyingGlass color='#667085' size={15} />

                      <input type='text' placeholder="Search Apps"
                          value={searchStr}
                          onChange={(evt) => {
                            setSearchStr(evt.target.value);
                          }} disabled={isLoading} /> 
                    </div>
                  </div>

                  <div className="dfaic cursor">
                    <div
                      className={`${
                        viewType === "list" ? "bg-base-200" : "bg-base-color"
                      } px-2px`}
                      onClick={() => setViewType("list")}
                    >
                      <Rows
                        weight="light"
                        color="var(--content-subtle)"
                        size={32}
                      />
                    </div>
                    <div
                      className={`${
                        viewType === "grid" ? "bg-base-200" : "bg-base-color"
                      } px-2px`}
                      onClick={() => setViewType("grid")}
                    >
                      <GridFour
                        weight="light"
                        color="var(--content-subtle)"
                        size={32}
                      />
                    </div>
                  </div>

                  <div
                    className={`primaryCTAButton br-4px dfaic px-12px py-9px ms-2 ${
                      isApps ? "" : "d-none"
                    }`}
                    onClick={() =>
                      setBuyNowModal({ isOpen: true, data: {}, isCreate: true })
                    }
                  >
                    <Plus color="#e4e4e4" size={14} className="mr-4px" />
                    <p className="fs-14px fw-600 text-white">Create</p>
                  </div>
                </div>
              </div>

              {isApps ? (
                viewType === "grid" ? (
                  <>
                    <div className="mt-3 apps-grid-view__container">
                      {/**
                       * This data will be a combined app data now: create apigee, azure and kong card componentd
                       */}
                      {data &&
                        paginatedData.map((each, i) => {
                          let _type = each.type;
                          if(each.type === GATEWAYS.NGINX || each.type === AWS_GATEWAY) _type = 'generic';
                          switch (_type) {
                            case "apigee":
                              return (
                                <ApigeeApp
                                  data={each}
                                  setPurchaseModal={setPurchaseModal}
                                  setAppDetailsModal={setAppDetailsModal}
                                  handleCopy={handleCopy}
                                  setBuyNowModal={setBuyNowModal}
                                  copied={copied}
                                  handleAppDelete={handleAppDelete}
                                  key={i}
                                />
                              );
                            case "kong":
                              return (
                                <KongApp
                                  data={each}
                                  setPurchaseModal={setPurchaseModal}
                                  setAppDetailsModal={setAppDetailsModal}
                                  handleCopy={handleCopy}
                                  setBuyNowModal={setBuyNowModal}
                                  copied={copied}
                                  handleAppDelete={handleAppDelete}
                                  key={i}
                                />
                              );
                            case "azure":
                              return (
                                <AzureApp
                                  data={each}
                                  setPurchaseModal={setPurchaseModal}
                                  setAppDetailsModal={setAppDetailsModal}
                                  handleCopy={handleCopy}
                                  setBuyNowModal={setBuyNowModal}
                                  copied={copied}
                                  handleAppDelete={handleAppDelete}
                                  key={i}
                                />
                              );
                            case 'generic':
                              return (
                                <GenericAppRow
                                  data={each}
                                  setPurchaseModal={setPurchaseModal}
                                  setAppDetailsModal={setAppDetailsModal}
                                  handleCopy={handleCopy}
                                  setUpdateAppModal={setUpdateAppModal}
                                  copied={copied}
                                  handleAppDelete={handleAppDelete}
                                  key={i}
                                />
                              );
                          }
                        })}
                    </div>
                    {data && data.length > 0 && (
                      <Pagination
                        className="py-2"
                        pagination={{
                          pageSize: pagination?.pageSize,
                          offset: pagination?.offset,
                          total: pagination.total,
                        }}
                        cb={(val) => {
                          setPagination((prev) => ({
                            ...prev,
                            offset: val,
                          }));
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div className="mt-3">
                    <div
                      className="row px-12px py-8px mb-1"
                      style={{ borderBottom: "1px solid var(--base-100)" }}
                    >
                      <div className="col-md-3">
                        <p className="text-content-subtle fs-10px fw-500">
                          Name
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p className="text-content-subtle fs-10px fw-500 pl-34px">
                          Status
                        </p>
                      </div>
                      <div className="col-md-3 text-center">
                        <p className="text-content-subtle fs-10px fw-500">
                          App ID
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p className="text-content-subtle text-center fs-10px fw-500">
                          Created By / Created At
                        </p>
                      </div>
                      <div className="col-md-2 dfaic"></div>
                    </div>

                    {data && searchFilteredValues(data)?.map((each, i) => {
                      let _type = each.type;
                      if(each.type === GATEWAYS.NGINX || each.type === AWS_GATEWAY) _type = 'generic';
                      switch (_type) {
                        case "apigee":
                          return (
                            <ApigeeApp
                              data={each}
                              setPurchaseModal={setPurchaseModal}
                              setAppDetailsModal={setAppDetailsModal}
                              isRow
                              handleCopy={handleCopy}
                              setBuyNowModal={setBuyNowModal}
                              copied={copied}
                              handleAppDelete={handleAppDelete}
                              deleteLoading={deleteLoading[each.name] || false}
                              key={i}
                            />
                          );
                        case "kong":
                          return (
                            <KongApp
                              data={each}
                              setPurchaseModal={setPurchaseModal}
                              setAppDetailsModal={setAppDetailsModal}
                              isRow
                              handleCopy={handleCopy}
                              setBuyNowModal={setBuyNowModal}
                              copied={copied}
                              handleAppDelete={handleAppDelete}
                              deleteLoading={deleteLoading[each.id] || false}
                              key={i}
                            />
                          );
                        case "azure":
                          return (
                            <AzureApp
                              data={each}
                              setPurchaseModal={setPurchaseModal}
                              setAppDetailsModal={setAppDetailsModal}
                              isRow
                              handleCopy={handleCopy}
                              setBuyNowModal={setBuyNowModal}
                              copied={copied}
                              handleAppDelete={handleAppDelete}
                              deleteLoading={deleteLoading[each.name] || false}
                              key={i}
                            />
                          );
                        case "generic":
                          return (
                            <GenericAppRow
                              data={each}
                              setPurchaseModal={setPurchaseModal}
                              setAppDetailsModal={setAppDetailsModal}
                              isRow
                              handleCopy={handleCopy}
                              setUpdateAppModal={setUpdateAppModal}
                              copied={copied}
                              handleAppDelete={handleAppDelete}
                              deleteLoading={deleteLoading[each._id] || false}
                              key={i}
                            />
                          );
                      }
                    })}
                  </div>
                )
              ) : viewType === "grid" ? (
                <div className="mt-3 d-flex flex-wrap align-items-center justify-content-center gap-24px">
                  {data.map((each, i) => (
                    <div className={`productCard Purple bg-base-color`} key={i}>
                      <div className="productCardHeader">
                        <div className="large-icon">
                          <CreditCard weight="thin" size={120} color="white" />
                        </div>
                      </div>
                      <div className="productCardBody">
                        <div className="small-icon surface-850">
                          <Cloud size={24} color="var(--content-color)" />
                        </div>

                        <div className="px-12px pb-12px pt-24px">
                          <p className="fw-600 fs-16px text-content-color text-truncate">
                            {each.productBundle?.name || ""}
                          </p>
                          <p
                            className="fw-400 fs-14px text-content-subtle text-truncate-2"
                            style={{ height: 42 }}
                          >
                            {each.productBundle?.description || ""}
                          </p>

                          <div className="d-flex align-items-center justify-content-between mt-3">
                            <div
                              className="btn-md-v1 fs-12px text-primary-color fw-500"
                              style={{ background: "transparent" }}
                              onClick={() =>
                                setProductsModal({
                                  isOpen: true,
                                  data: objectDeepClone(each),
                                })
                              }
                            >
                              {each?.productBundle?.products?.length || 0}{" "}
                              Products{" "}
                              <ArrowSquareOut
                                color="var(--primary-color)"
                                className="ms-1"
                              />
                            </div>

                            <div
                              className="primaryCTAButton px-12px py-6px"
                              style={{ height: 32, borderRadius: 4 }}
                              onClick={() =>
                                setBuyNowModal({
                                  isOpen: true,
                                  data: objectDeepClone(each),
                                  isCreate: false,
                                })
                              }
                            >
                              <div className="d-flex align-items-center">
                                <p className="fs-12px text-white fw-500 me-1">
                                  Buy Now
                                </p>
                                <CaretRight size={14} color="white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-3">
                  <div
                    className="row px-12px py-8px mb-1"
                    style={{ borderBottom: "1px solid var(--base-100)" }}
                  >
                    <div className="col-md-3">
                      <p className="text-content-subtle fs-10px fw-500">
                        App Name
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="text-content-subtle fs-10px fw-500 pl-34px">
                        Status
                      </p>
                    </div>
                    <div className="col-md-2 text-center">
                      <p className="text-content-subtle fs-10px fw-500">
                        App ID
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="text-content-subtle text-center fs-10px fw-500">
                        Created By / Created At
                      </p>
                    </div>
                    <div className="col-md-3"></div>
                  </div>

                  {data?.map((each) => (
                    <div
                      className="row p-12px"
                      style={{ borderBottom: "1px solid var(--base-100)" }}
                    >
                      <div className="col-md-3 my-auto">
                        <p className="text-content-color fs-14px fw-500">
                          {each.productBundle?.name}
                        </p>
                      </div>
                      <div className="col-md-2 my-auto">
                        <div
                          className={`global-status-tag ${each.productBundle?.status?.toLowerCase()} w-fit text-capitalize`}
                        >
                          <p
                            className={`fw-500 fs-12px text-300 status-text px-3`}
                          >
                            {each.productBundle?.status}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <div
                          className="br-4px bg-base-100 px-8px py-5px d-flex align-items-center cursor justify-content-between"
                          onClick={() => handleCopy(each.productBundle?.id)}
                          // data-tip={copied == each.productBundle?.id ? "Copied" : "Copy to Clipboard"}
                          // data-for={each.productBundle?.id}
                        >
                          <p className="text-content-subtle fs-10px fw-500 text-truncate me-1">
                            {each.productBundle?.id}
                          </p>
                          {copied == each.productBundle?.id ? (
                            <FilePlus color="var(--content-subtle)" size={14} />
                          ) : (
                            <Clipboard
                              color="var(--content-subtle)"
                              size={14}
                            />
                          )}

                          <MyReactTooltip id={each.productBundle?.id} />
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <p className="text-content-subtle fs-12px fw-500 text-center">
                          {each.productBundle?.createdUser || ""}
                        </p>
                        <p className="text-content-subtle fs-11px fw-500 text-center">
                          {formatCtsTime(each.productBundle?.createdDate)} -{" "}
                          {formatCtsDate(each.productBundle?.createdDate)}
                        </p>
                      </div>
                      <div className="col-md-3 dfaic justify-content-between my-auto">
                        <div
                          className="bg-secondary-200 btn px-18px py-10px fs-11px text-primary-color fw-500"
                          style={{ border: "1px solid var(--base-100)" }}
                          onClick={() =>
                            setProductsModal({
                              isOpen: true,
                              data: objectDeepClone(each),
                            })
                          }
                        >
                          {each?.productBundle?.products?.length || 0} Products{" "}
                          <ArrowSquareOut
                            color="var(--primary-color)"
                            className="ms-1 mb-2px"
                          />
                        </div>

                        <div
                          className="primaryCTAButton px-12px py-6px"
                          style={{ height: 32, borderRadius: 4 }}
                          onClick={() =>
                            setBuyNowModal({
                              isOpen: true,
                              data: objectDeepClone(each),
                              isCreate: false,
                            })
                          }
                        >
                          <div className="d-flex align-items-center">
                            <p className="fs-12px text-white fw-500 me-1">
                              Buy Now
                            </p>
                            <CaretRight size={14} color="white" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {productsModal.isOpen && (
        <ProductsModal
          data={productsModal.data}
          show={productsModal.isOpen}
          onHide={() => setProductsModal({ isOpen: false, data: {} })}
        />
      )}

      {buyNowModal.isOpen && (
        <BuyNowModal
          selectedOrg={type.value}
          isUpdate={buyNowModal.isUpdate || false}
          data={buyNowModal.data}
          show={buyNowModal.isOpen}
          isCreate={buyNowModal.isCreate}
          onHide={() =>
            setBuyNowModal({
              isOpen: false,
              data: {},
              isCreate: false,
              isUpdate: false,
            })
          }
          onCreateCallback={getAllApps}
        />
      )}

      {updateAppModal.isOpen && (
        <UpdateAppModal
          data={updateAppModal.data}
          onHide={() =>
            setUpdateAppModal({
              isOpen: false,
              data: {}
            })
          }
          cb={({description}) => {
            updateAppCb({appId: updateAppModal?.data?._id, description})
          }}
        />
      )}
      
      {/**
       * Only for apigee app purchases
       */}
      {purchaseModal.isOpen && (
        <PurchaseModal
          selectedOrg={type.value}
          data={purchaseModal.data}
          show={purchaseModal.isOpen}
          isEdit={purchaseModal.isEdit}
          onHide={() =>
            setPurchaseModal({ isOpen: false, data: {}, isEdit: false })
          }
        />
      )}

      {appDetailsModal.isOpen && appDetailsModal.type === "Apigee" && (
        <AppDetailsModal
          onModalClose={() => {
            setAppDetailsModal({
              isOpen: false,
              data: {},
              type: "",
            });
          }}
          appData={appDetailsModal.data}
          statusChangeCallback={getAllApps}
        />
      )}
      {appDetailsModal.isOpen && appDetailsModal.type === "Azure" && (
        <AzureAppDetails
          onModalClose={() => {
            setAppDetailsModal({
              isOpen: false,
              data: {},
              type: "",
            });
          }}
          appData={appDetailsModal.data}
          statusChangeCallback={getAllApps}
        />
      )}
      {appDetailsModal.isOpen && appDetailsModal.type === "Kong" && (
        <KongAppDetails
          onModalClose={() => {
            setAppDetailsModal({
              isOpen: false,
              data: {},
              type: "",
            });
          }}
          appData={appDetailsModal.data}
          statusChangeCallback={getAllApps}
        />
      )}
      {appDetailsModal.isOpen && [GATEWAYS.NGINX, AWS_GATEWAY].includes(appDetailsModal.type) && (
        <GenericAppDetails
          onModalClose={() => {
            setAppDetailsModal({
              isOpen: false,
              data: {},
              type: "",
            });
          }}
          app={appDetailsModal.data}
        />
      )}
      <CustomFooter />
    </div>
  );
}
