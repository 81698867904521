import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import { BodyLayout } from '../../../layout/Base'
import { CaretRight, Notebook} from "phosphor-react";
import { getPermissionByCrud, throwServerError } from '../../../utils/helper';
import { useDispatch } from 'react-redux';
import { resetCurrentLayout, setCurrentLayoutObject } from '../../../app/Features/Layout/LayoutSlice';
import Loading from '../../../components/Loader/Loading';
import { useSelector } from 'react-redux';
import API from '../../../api';
import { objectDeepClone } from '@apiwiz/oas/services';
import RenderPrimaryTab from './components/RenderPrimaryTab';
import availableLayout from './components/availableLayouts.json'
import { getToast } from '../../../components/Toast';
import RenderSecondaryTab from './components/RenderSecondaryTab';
import RenderTertiaryTab from './components/RenderTertiary';

export default function ManagePromotions() {
    const {currentLayoutObject} = useSelector(state => state.layout)

    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [loading, setLoading] = useState(false)
    const [selectedTab, setSelectedTab] = useState({id: 'primary'})
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const { firstRoute } = useSelector((state) => state.user);
    const [promotions, setPromotions] = useState({
        primary: availableLayout.promotionContents.primary,
        secondary: availableLayout.promotionContents.secondary,
        tertiary: availableLayout.promotionContents.tertiary
    })
    const [data, setData] = useState({})

    useEffect(() => {
        setTitle("Promotions")
        setHasHeader(true)
        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Manage Sections'}
        ])

        dispatch(resetCurrentLayout())

        getData()
    }, [])

    const handleSave = () => {
        let _data = objectDeepClone(promotions)
        let _primObj = objectDeepClone(_data.primary || {})
        if (!_primObj.heading || !_primObj.description || !_primObj.primaryCTA?.label
            || !_primObj.primaryCTA?.url || !_primObj.imageUrl){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the details in Primary section'
            })
            return
        }

        let _secondObj = objectDeepClone(_data.secondary || {})
        if (!_secondObj.heading || !_secondObj.description || !_secondObj.imageUrl){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the details in Secondary section'
            })
            return
        }

        let thirdObj = objectDeepClone(_data.tertiary || {})
        if (!thirdObj.heading || !thirdObj.description || !thirdObj.tag){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the details in Tertiary section'
            })
            return
        }

        const json = objectDeepClone(currentLayoutObject)
        json['promotions'] = objectDeepClone(promotions)

        API.putLayoutDetails(json)
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Layout updated successfully'
            })
            dispatch(setCurrentLayoutObject(objectDeepClone(json)))
            getData()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const handleCancel = () => {
        const _data = objectDeepClone(data)
        if (_data.promotions && 
            (_data.promotions.primary && Object.keys(_data.promotions.primary || {}).length > 0) &&
            (_data.promotions.secondary && Object.keys(_data.promotions.secondary || {}).length > 0) &&
            (_data.promotions.tertiary && Object.keys(_data.promotions.tertiary || {}).length > 0) 
        ){
            setPromotions(objectDeepClone(_data.promotions))
        }
        else {
            setPromotions(objectDeepClone({
                primary: availableLayout.promotionContents.primary,
                secondary: availableLayout.promotionContents.secondary,
                tertiary: availableLayout.promotionContents.tertiary
            }))
        }
        setIsEdit(false)
    }

    const getData = () => {
        setLoading(true)
        API.getLayoutDetails()
        .then(res => {
            setLoading(false)
            setSelectedTab({id: 'primary'})
            let _data = objectDeepClone(res.data?.layout || {})
            setData(objectDeepClone(_data))
            if (_data.promotions && 
                (_data.promotions.primary && Object.keys(_data.promotions.primary || {}).length > 0) &&
                (_data.promotions.secondary && Object.keys(_data.promotions.secondary || {}).length > 0) &&
                (_data.promotions.tertiary && Object.keys(_data.promotions.tertiary || {}).length > 0) 
            ){
                setPromotions(objectDeepClone(_data.promotions))
            }
            else {
                setPromotions(objectDeepClone({
                    primary: availableLayout.promotionContents.primary,
                    secondary: availableLayout.promotionContents.secondary,
                    tertiary: availableLayout.promotionContents.tertiary
                }))
            }

            console.log('_data', _data)
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    /* ACL Start */
    const {currentUser} = useSelector(state => state.user)
    const {permission} = useSelector(state => state.user)

    let _permission = permission.filter(o => o.name === 'manage-site-global')
    _permission = _permission.length ? (_permission[0].crud || []) : []
    const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
    /* ACL END */

    return (
        <BodyLayout>
            <div className='fadeIn d-flex align-items-center w-100 h-100'>
                {loading 
                ? <Loading />
                : <>
                    <div className='w-35 h-100'>
                        <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between br-tl-4px br-tr-4px'>
                            <div className='d-flex align-items-center'>
                                <Notebook size={18} color="#E4E4E4" />
                                <p className='ms-2 fs-16px fw-500 text-200'>Promotions</p>
                            </div>

                            <div style={{height: 41}}></div>
                        </div>
                        <div className='surface-900 p-8px overflow-scroll noscrollbar' 
                            style={{height: 'calc(100% - 61px)'}}>
                            <div className={`${selectedTab.id === 'primary' && 'v1-border-blue mb-4px' } surface-base px-20px py-16px d-flex align-items-center justify-content-between cursor hover-surface-875 mb-2px`}
                                onClick={() => setSelectedTab({id: 'primary'})}>
                                <p className='text-300 fs-14px fw-600'>Primary Section</p>
                                
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <CaretRight size={14} color='#E4E4E4' />
                                    </div>
                                </div>
                            </div>

                            <div className={`${selectedTab.id === 'secondary' && 'v1-border-blue mb-4px' } surface-base px-20px py-16px d-flex align-items-center justify-content-between cursor hover-surface-875 mb-2px`}
                                onClick={() => setSelectedTab({id: 'secondary'})}>
                                <p className='text-300 fs-14px fw-600'>Secondary Section</p>
                                
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <CaretRight size={14} color='#E4E4E4' />
                                    </div>
                                </div>
                            </div>

                            <div className={`surface-base px-20px py-16px d-flex align-items-center justify-content-between cursor hover-surface-875 ${selectedTab.id === 'tertiary' && 'v1-border-blue mt-4px' }`}
                                onClick={() => setSelectedTab({id: 'tertiary'})}>
                                <p className='text-300 fs-14px fw-600'>Tertiary Section</p>
                                
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <CaretRight size={14} color='#E4E4E4' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ms-2 w-64 h-100'>
                        <div className='surface-875 d-flex align-items-center justify-content-between px-12px py-10px br-tl-4px br-tr-4px'>
                            <div className='d-flex align-items-center'>
                                <Notebook size={18} color="#E4E4E4" />
                                <p className='ms-2 fs-16px fw-500 text-200'>Preview</p>
                            </div>

                            { isEdit
                            ? <div className='d-flex align-items-center'>
                                <p className='fw-500 fs-12px text-300 cursor'
                                    onClick={handleCancel}> Cancel</p>

                                <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn ms-3'
                                    onClick={handleSave}>
                                    <p className='fs-12px fw-500 text-300'>Save</p>
                                </div>
                            </div>
                            : <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn'
                                onClick={() => setIsEdit(true)}>
                                <p className='fs-12px fw-500 text-300'>Edit</p>
                            </div>}
                        </div>
                        
                        <div className='surface-900 p-24px overflow-scroll noscrollbar' style={{height: 'calc(100% - 55px)'}}>
                            {
                                loading 
                                    ? <Loading />
                                : (selectedTab.id === 'primary' 
                                    ? <RenderPrimaryTab isEdit={isEdit} data={promotions.primary}
                                        setLoading={setLoading} setData={(val) => {
                                            setPromotions(prev => ({...prev, primary: val}))
                                        }} />
                                : selectedTab.id === 'secondary'
                                    ? <RenderSecondaryTab isEdit={isEdit} data={promotions.secondary}
                                        setLoading={setLoading} setData={(val) => {
                                            setPromotions(prev => ({...prev, secondary: val}))
                                        }} />
                                : selectedTab.id === 'tertiary' 
                                    ? <RenderTertiaryTab isEdit={isEdit} data={promotions.tertiary}
                                        setLoading={setLoading} setData={(val) => {
                                            setPromotions(prev => ({...prev, tertiary: val}))
                                        }} />
                                : null)
                            }
                        </div>
                    </div>
                </>}
            </div>
        </BodyLayout>
    )
}
