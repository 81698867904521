import {
  CaretRight,
  Clipboard,
  Eye,
  FilePlus,
  PencilSimple,
  Trash,
} from "phosphor-react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import {
  formatCtsDateWithoutUTC,
  formatCtsTimeWithoutUTC,
  objectDeepClone,
} from "../../../utils/helper";
import MyReactTooltip from "../../../components/ReactTooltip";
import { toLower } from "lodash";
import { APP_STATUS } from "../../../service/constants";
import ContainerLoader from "../../../components/Loader/ContainerLoader";

export const GenericAppRow = (props) => {
  const {
    data,
    setAppDetailsModal,
    isRow,
    handleCopy,
    setUpdateAppModal,
    copied,
    handleAppDelete,
    deleteLoading
  } = props;

  return (
    <>
      {isRow ? (
        <div
          className="row p-12px"
          style={{ borderBottom: "1px solid var(--base-100)" }}
        >
          <div className="col-md-3 my-auto">
            <p className="text-content-color fs-14px fw-500"> {data.name}</p>
          </div>
          <div className="col-md-2 my-auto">
            <div
              className={`global-status-tag ${data.status.toLowerCase()} w-fit text-capitalize`}
            >
              <p className={`fw-500 fs-12px text-300 status-text px-3`}>
                {data.status}
              </p>
            </div>
          </div>
          <div className="col-md-3 my-auto">
            {
              data.status === APP_STATUS.APPROVED ? (
                <div
                  className="br-4px bg-base-100 px-8px py-5px d-flex align-items-center cursor justify-content-between"
                  onClick={() => handleCopy(data.appId)}
                >
                  <p className="text-content-subtle fs-10px fw-500 text-truncate me-1">
                    {data.appId}
                  </p>
                  {copied == data.appId ? (
                    <FilePlus color="var(--content-subtle)" size={14} />
                  ) : (
                    <Clipboard color="var(--content-subtle)" size={14} />
                  )}

                  <MyReactTooltip id={data.appId} />
                </div>
              ) : null
            }
          </div>
          <div className="col-md-2 my-auto">
            <p className="text-content-subtle fs-12px fw-500 text-center">
              {data.createdBy || ""}
            </p>
            <p className="text-content-subtle fs-11px fw-500 text-center">
              {formatCtsTimeWithoutUTC(data?.cts)} -{" "}
              {formatCtsDateWithoutUTC(data?.cts)}
            </p>
          </div>
          <div className="col-md-2 dfaic justify-content-end my-auto">
            <div className="dfaic gap-8px">
              <div
                className="cursor apps-row-action-btn"
                onClick={() => {
                  setAppDetailsModal({
                    isOpen: true,
                    data: objectDeepClone(data),
                    type: data.type,
                  });
                }}
              >
                <Eye color="var(--content-color)" size={14} />
              </div>

              <div
                className="cursor apps-row-action-btn"
                onClick={() =>
                  setUpdateAppModal({
                    isOpen: true,
                    data: objectDeepClone(data)
                  })
                }
              >
                <PencilSimple color="var(--content-color)" size={14} />
              </div>

              <div
                className="cursor apps-row-action-btn"
                onClick={() => {
                  if(deleteLoading) return;
                  handleAppDelete(data);
                }}
              >
                {deleteLoading ? <ContainerLoader variant="danger" size="sm" /> : <Trash color="#E5484D" size={14} />}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`productCard Purple bg-base-color`}>
          <div className="productCardHeader">
            <div className="large-icon">
              <GlobalIcons type={"apps-credit-card"} />
            </div>
          </div>
          <div className="productCardBody">
            <div className="px-12px pb-12px pt-24px">
              <p
                className="fw-600 fs-16px text-content-color text-truncate cursor-pointer"
                onClick={(evt) => {
                  evt.stopPropagation();
                  setUpdateAppModal({
                    isOpen: true,
                    data: objectDeepClone(data)
                  });
                }}
              >
                {data.name}
              </p>
              <p
                className="fw-400 fs-14px text-content-subtle text-truncate-2"
                style={{ height: 42 }}
              >
                {data?.description || ""}
              </p>

              <div className="d-flex align-items-center justify-content-end mt-3">
                {/* <div className="fs-12px text-primary-color fw-500">
                  Transactions: {data.transactions}
                </div> */}
                <div className="d-flex align-items-center gap-4px">

                  <div
                    className="primaryCTAButton px-12px py-6px"
                    style={{ height: 32, borderRadius: 4 }}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setAppDetailsModal({
                          isOpen: true,
                          data: objectDeepClone(data),
                          type: data.type,
                        });
                      }}
                    >
                      <p className="fs-12px fw-500 me-1">Details</p>
                      <CaretRight size={14} color="var(--content-subtle)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
