import { baseAPI } from "."
import config from "../service/config"


export default {
    createAppInDb(body, params) {
		return baseAPI.post(config.DEVELOPER_APP.CREATE, body, {params: {...params}})
	},

    getDeveloperAppById(id) {
        return baseAPI.get(config.DEVELOPER_APP.CREATE + `/${id}`)
    },

    getDeveloperApps(params) {
        return baseAPI.get(config.DEVELOPER_APP.GET, {params: {...params}})
    },

    updateDeveloperApp(appId, params, body={}) {
        return baseAPI.put(config.DEVELOPER_APP.CREATE + `/${appId}`, body, {params: {...params}})
    },

    deleteDeveloperApp(appId,params) {
        return baseAPI.delete(config.DEVELOPER_APP.CREATE + `/${appId}`, {params: {...params}})
    }
}