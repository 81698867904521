import React from "react";
import ContainerLoader from "../components/Loader/Loading";
import { useTheme } from "../context/ThemeContext";

export const HeaderLayout = ({children}) => {
    return (
        <div className="w-100" style={{height: 'var(--header-height)' }}>
            {children}
        </div>
    )
}

export const BodyLayout = ({children, scrollable, consumeFull, className, style, bottomSpaceMinus}) => {
    let _style = {
        height: scrollable || consumeFull ? 'inherit' : `calc(100vh - var(--header-height) - ${bottomSpaceMinus || 18}px)`
    }
    if(!consumeFull) _style['paddingBottom'] = 12
    _style={..._style, ...style}
    return (
        <div className={`${className || ''} w-100`} style={_style}>
            {children}
        </div>
    )
}

export const MainLayout = ({children, noGap}) => {
    return (
        <div className="vh-100 text-200" 
            style={{
                overflow: "hidden",
                width: `calc(100vw - var(--navbar-width) ${noGap ? '' : '- var(--navbar-body-gap) - 12px'})`, 
                marginLeft: `calc(var(--navbar-width) ${noGap ? '' : '+ var(--navbar-body-gap)'})`
            }}>
            <div className="h-100 w-100 text-200">
                {children}
            </div>
        </div>
    )
}

const Base = (props) => {
    const { theme } = useTheme();
    const { children, className="", loading, margin } = props

    return (
        <div className="vh-100 vw-100 base overflow-scroll noscrollbar">
            <div className={`${theme === "light" ? "text-dark" : "text-light"} ${className} h-100`}>
                {loading ? <ContainerLoader className="vh-100" /> : (
                    children
                )}
            </div>
        </div>
    );
};

export default Base;
