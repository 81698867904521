import CustomNavbar from "../Dashboards/components/CustomNavbar";
import CustomFooter from "../Dashboards/components/CustomFooter";
import Select from 'react-select';
import { formatCtsDateWithoutUTC,formatCtsTimeWithoutUTC, isJSON } from "../../utils/helper";
import { MapPin,ArrowUpRight } from "phosphor-react";
import PaginationV2 from "../../components/PaginationV2";
import { useEffect, useState } from "react";
import API from "../../api";
import { throwServerError } from "../../utils/helper";
import Loading from "../../components/Loader/Loading";
import { EmptyState } from "../../components/EmptyState";


export default function Events(){
    const [isLoading,setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState({
        offset: 1,
        total: 10,
        pageSize: 6
    })
    const [events,setEvents] = useState([])

    useEffect(() => {
        getEventsData()

        API.trackSessions('EVENTS')
        .then(res => console.log('res', res))
        .catch(err => console.log('err', err))
    }, [])

    const getEventsData = (curr=1) => {
        setIsLoading(true)
        API.getAllEventList(curr)
        .then(res => {
            setEvents(res.data.data || [])
            setIsLoading(false)
            let _paging = res.data.paging
            setCurrentPage({
                ...currentPage, total: _paging.total, pageSize: _paging.limit
            })
        }).catch(err => {
            throwServerError(err)
            setIsLoading(false)
        })
    }


    function handlePageChange(_pageNumber){
        getEventsData(_pageNumber)
    }

   

    return <div className='h-100vh w-100vw bg-currentBackground'>
        <CustomNavbar />

        {isLoading
        ? <Loading />
        : <div className='p-32px mt-62px d-flex justify-content-center h-fit bg-currentBackground w-100'>
            <div className="w-85">
                <p className="fw-600 fs-32px text-content-color text-center">Events</p>
                <p className="fs-16px fw-400 text-center mt-1">View all our upcoming events here</p>
                <div className="d-flex justify-content-start d-none">
                    <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                        className="v1select mt-3 h-auto w-20"
                        options={[]} 
                        onChange={(e) => {}}
                        value={{}} 
                />
                </div>
                <div className="d-flex flex-wrap align-items-center gap-24px justify-content-center mt-3 py-24px">
                    {events.length > 0
                    ? events.map((each)=>{
                        return <div className='eventMainCard v2'>
                        <img src={each.bannerimage} alt='Banner thumbnail' />

                        <p className='mt-2 eventTimings'>
                            {formatCtsDateWithoutUTC(each.date)} • {formatCtsTimeWithoutUTC(each.date)} UTC
                        </p>

                        <p className='mt-2 eventTitle text-truncate'>
                            {each.name}
                        </p>

                        <div style={{height: 72}}>
                            <p className='eventDescription'>{isJSON(each.description) ? '' : each.description}</p>
                        </div>

                        <div className='d-flex align-items-center mt-16px mb-16px'>
                            <MapPin color='var(--content-color)' size={14} className='me-2' />

                            <p className='eventLocation text-truncate' style={{width: 290}}
                                title={each.address || 'Online'}>{each.address || 'Online'}</p>
                        </div>

                        <a href={each.link} target='_blank' rel="noreferrer"
                            className='eventMainButton cursor'>
                            <p>View Event</p>
                            <ArrowUpRight color='var(--primary-color)' className='ms-2' size={14} />
                        </a>
                    </div>
                    })
                    : <div className='mt-5'>
                        <EmptyState />

                        <p className='ps-5 text-content-color text-center'>No Events Found</p>
                    </div>}
                    {(events.length > 0 && !isLoading) && <div className='w-100 h-8 mt-5'>
                        <PaginationV2 pagination={{
                            offset:currentPage.offset, total: currentPage.total, pageSize: currentPage.pageSize
                        }} cb={handlePageChange} />
                    </div>} 
                </div>
            </div>
        </div>}

        <CustomFooter />
    </div>
}