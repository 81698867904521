import React from 'react'
import GlobalIcons from '../Icons/GlobalIcons'

export default function Searchbar({value, onChange, placeholder, inputClassName}) {
    return (
        <div className='d-flex align-items-center w-100 searchBar'>
            <input placeholder={placeholder || ""} type={'text'} className={`form-control v1-form-control ${inputClassName}`}
                value={value} onChange={(e) => {
                   if(onChange) onChange(e.target.value)
                }}/>
            <div className='searchIcon'>
                <GlobalIcons type={'search'} />
            </div>
        </div>
    )
}
