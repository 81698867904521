import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomCard1 from './components/CustomCard1'
import CustomFooter from './components/CustomFooter'
import CustomHero1 from './components/CustomHero1'
import CustomImage1 from './components/CustomImage1'
import CustomLogo1 from './components/CustomLogo1'
import CustomNavbar from './components/CustomNavbar'
import API from '../../api'
import { useDispatch } from 'react-redux'
import { setCurrentUser, setFirstRoute, setMenus, setMonetizationEnabled, setPermissions } from '../../app/Features/Users/UserSlice'
import Loading from '../../components/Loader/Loading'
import { formatClearCtsDateWithoutUTC, formatCtsDateWithoutUTC, formatCtsTimeWithoutUTC, formatDate, isJSON, objectDeepClone, throwServerError } from '../../utils/helper'
import { ArrowUpRight, CaretLeft, CaretRight, MapPin } from 'phosphor-react'
import CustomCard2 from './components/CustomCard2'
import CustomImage2 from './components/CustomImage2'
import CustomHero2 from './components/CustomHero2'
import CustomImage3 from './components/CustomImage3'
import CustomCard3 from './components/CustomCard3'
import CustomHero3 from './components/CustomHero3'
import { DEFAULTS } from '../../service/constants'

export function Dashboard() {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const {firstRoute, isAuthenticated, currentUser, data} = useSelector(state => state.user)
    const {contents} = currentLayoutObject
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([])

    const scrollToStart = useRef()
    const scrollToEnd = useRef()

    const handleStartScroll = () => scrollToStart.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) 
    const handleEndScroll = () => scrollToEnd.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) 

    function getSidebarDetails(){
        dispatch(setMenus())

        API.getPermissions()
        .then(response => {
            let permissions = objectDeepClone(response.data.data.permissions);
            let roles = objectDeepClone(response.data.data.roles)
            const createObj = (o) => {
                return {
                    name: o.object,
                    crud: permissions.filter(n => n.object === o.object).map(n => {
                        return {
                            ...n,
                            roles: n.roles.map(r => {
                                let obj = {
                                role: r.code,
                                enabled: r.code === 'super-admin' ? true : r.enabled,
                                object: n.object,
                                action: n.action
                            }
                            return obj
                        })
                        }
                    })
                }
            }
            let perm = permissions.map(createObj);

            const uniquePerm = perm.filter((thing, index) => {
                const _thing = JSON.stringify(thing);
                return index === perm.findIndex(o => {
                    return JSON.stringify(o) === _thing;
                });
            }).sort((a,b) => a.name<b.name ? -1 : a.name>b.name ? 1 : 0)
            setLoading(false)
            dispatch(setPermissions(objectDeepClone(uniquePerm)))
        })
        .catch(err => {
            setLoading(false)
        })

        // API.getSidebarData()
        // .then(res => {
        //   let current = res.data.data
        //   current = current.length ? current[0] : {}
        //   dispatch(setMenus(current.data || []))
        //   setLoading(false)
        // }).catch(err => {
        //   setLoading(false)
        // })
    }

    const getUserDetails = () => {
        if ((data && data._id)){
            API.getProfile(data._id)
            .then(res => {
                let _currentUser = objectDeepClone(res.data?.data || {})
                if (_currentUser?.userRole === 'super-admin' || _currentUser?.role === 'super-admin'){
                    dispatch(setFirstRoute(`/admin/dashboard`))
                }
                dispatch(setCurrentUser(res.data.data))
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    useEffect(() => {
        if (currentLayoutObject.showEvents){
            getEventsData()
        } else {
            setEvents([])
        }
    }, [currentLayoutObject])

    const getEventsData = (curr) => {
        API.getAllEventList(1)
        .then(res => {
            setEvents(res.data.data || [])
            setLoading(false)
        }).catch(err => {
            throwServerError(err)
            setLoading(false)
        })
    }

    const getMonetizationInfo = () => {
        API.getAllCategories()
        .then(({data: res}) => {
            dispatch(setMonetizationEnabled(true))
        }).catch(err => {
            dispatch(setMonetizationEnabled(false))
            console.log('err', err)
            // throwServerError(err)
        })
    }

    useEffect(() => {
        if (currentUser && !currentUser.userRole){
            getUserDetails()
        }

        if (isAuthenticated && firstRoute === '/'){
            getSidebarDetails()
        } else {
            setLoading(false)
        }

        getMonetizationInfo()

    }, [])

    return (
        <div className='bg-base-color h-100vh w-100vw overflow-scroll noscrollbar'>
            { loading
            ? <Loading />
            : <>
                <CustomNavbar />

                <div className='mt-62px'>
                    {
                        contents?.map((eachContent, i) => {
                            if (eachContent.id === 'hero-section-1') return <CustomHero1 key={i} data={eachContent.payload} />
                            if (eachContent.id === 'hero-section-2') return <CustomHero2 key={i} data={eachContent.payload} />
                            if (eachContent.id === 'hero-section-3') return <CustomHero3 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'logos-section-1') return <CustomLogo1 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'cards-section-1') return <CustomCard1 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'cards-section-2') return <CustomCard2 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'cards-section-3') return <CustomCard3 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'images-section-1') return <CustomImage1 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'images-section-2') return <CustomImage2 key={i} data={eachContent.payload} />
                            else if (eachContent.id === 'images-section-3') return <CustomImage3 key={i} data={eachContent.payload} />
                        })
                    }
                </div>

                {events.length > 0 && <div className='container'>
                    <div className='bg-base-color px-0px py-48px w-100'>
                        <div className='d-flex align-items-center justify-content-between w-100'>
                            <p className='upcomingEVentsText ps-2'>{currentLayoutObject.eventTitle || DEFAULTS.EVENT_TITLE}</p>
                            <div className='d-flex align-items-center gap-10px'>
                                <div onClick={handleStartScroll} className='br-8px border-theme-secondary py-10px px-8px cursor-pointer'><CaretLeft color='var(--primary-color)'/></div>
                                <div onClick={handleEndScroll} className='br-8px border-theme-secondary py-10px px-8px cursor-pointer'><CaretRight color='var(--primary-color)'/></div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center noscrollbar mt-24px py-24px ps-3'
                            style={{overflowX: 'scroll', scrollBehavior: "smooth"}}>
                            <div ref={scrollToStart}></div> 
                            {events.map((each) => <div className='eventMainCard v2 me-3'>
                                <img src={each.bannerimage} alt='Banner thumbnail' />

                                <p className='mt-16px eventTimings text-uppercase'>
                                    {formatClearCtsDateWithoutUTC(each.date)} (UTC)
                                </p>

                                <p className='mt-16px eventTitle text-truncate'>
                                    {each.name}
                                </p>

                                <div style={{height: 72}}>
                                    <p className='eventDescription'>{
                                        isJSON(each.description) ? '' : each.description
                                    }</p>
                                </div>

                                <div className='d-flex align-items-center mt-16px mb-16px'>
                                    <MapPin color='var(--content-color)' size={14} className='me-2' />

                                    <p className='eventLocation text-truncate' title={each.address || 'Online'}
                                        style={{width: 270}}>{each.address || 'Online'}</p>
                                </div>

                                <a href={each.link} target='_blank' rel="noreferrer"
                                    className='eventMainButton cursor'>
                                    <p>View Event</p>
                                    <ArrowUpRight color='var(--primary-color)' className='ms-2' size={14} />
                                </a>
                            </div>)}
                            <div ref={scrollToEnd}></div> 
                        </div>
                    </div>
                </div>}

                <CustomFooter />
            </>}
        </div>
    )
}
