import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { ArrowUpRight, File, X } from 'phosphor-react';
import { ROUTE_NAMES, SRC_PARAM_VALUES, URL_PARAMS } from '../../../../service/constants';
import { constructFilterResources } from '../../../../utils/helper';

export default function APIReferenceListModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            contentClassName='homeModalForLghtMode'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body className="overflow-scroll noscrollbar min-h-30vh br-4px p-0">
                <div className='dfaic justify-content-between px-12px py-8px'>
                    <div className='dfaic'>
                        <p className='fs-16px fw-500 text-primary-color'>API Reference List</p>
                    </div>

                    <div className='cursor text-primary-color fs-12px fw-500'
                        onClick={props.onHide}>
                            <X size={20} fontWeight={700} />
                        </div>
                </div>

                <div className='mt-12px d-flex flex-column p-8px gap-12px'>
                    {Object.keys(props.data).map(key => {
                        let el = props.data[key];
                        return (
                            <div className='p-16px br-8px cursor dfaic justify-content-between' 
                                style={{border: '1px solid var(--base-200)'}}
                                onClick={() => {
                                    
                                    let filterParams = constructFilterResources({resources: el.resources})
                                    let url = `${window.location.origin}/${ROUTE_NAMES.API_REFERENCE}/${el.oasVersion}/${el.swaggerId}/${el.swaggerRevision}`
                                    if(filterParams) url+=`?${URL_PARAMS.SRC}=${SRC_PARAM_VALUES.PACKAGE}&${URL_PARAMS.API_REFERENCE}=${filterParams}`
                                    window.open(url, "_blank")
                                }}>
                                <div className='dfaic'>
                                    <File size={32} className='text-gray-subtext me-2' />
                                    <p className='fw-500 fs-14px text-content-color'>
                                        {el.swaggerName}
                                    </p>
                                </div>

                                <div className='dfaic'>
                                    <p className='fw-500 fs-14px text-content-color'>
                                        {(el.resources || []).length} {(el.resources || []).length === 1 ? 'API': 'APIs'}
                                    </p>

                                    <ArrowUpRight size={20} className='text-gray-subtext ms-2' />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
        </Modal>
    )
}
