import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import API from '../../../../api'
import { setLayoutObjects } from '../../../../app/Features/Layout/LayoutSlice'
import Loading from '../../../../components/Loader/Loading'
import { getToast } from '../../../../components/Toast'
import { objectDeepClone, throwServerError } from '../../../../utils/helper'
import ImageCard from './ImageCard'

export default function BrandingSettings() {
    const { currentLayoutObject, backupCurrentLayout } = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        logo: '', darklogo: '', favicon: '', squarelogo: ''
    })

    const handleSave = () => {
        if (!data.logo) {
            getToast({statusType: 'ERROR', message: 'Logo is required'})
            return
        }
        if (!data.darklogo) {
            getToast({statusType: 'ERROR', message: 'Dark Mode Logo is required'})
            return
        }
        if (!data.favicon) {
            getToast({statusType: 'ERROR', message: 'Favicon is required'})
            return
        }
        if (!data.squarelogo) {
            getToast({statusType: 'ERROR', message: 'Square Logo is required'})
            return
        }

        setLoading(true)
        API.putLayoutDetails({...objectDeepClone(data)})
        .then(res => {
            setLoading(false)
            getToast({
                statusType: 'SUCCESS', message: 'Branding Settings updated successfully'
            })
            document.getElementById('favicon-logo').setAttribute('href', data.favicon)
            dispatch(setLayoutObjects(objectDeepClone(data)))
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    const handleCancel = () => {
        let _backupCurrentLayout = objectDeepClone(backupCurrentLayout)
        let _data = {
            logo: _backupCurrentLayout.logo,
            darklogo: _backupCurrentLayout.darklogo,
            favicon: _backupCurrentLayout.favicon,
            squarelogo: _backupCurrentLayout.squarelogo,
        }
        dispatch(setLayoutObjects(objectDeepClone(_data)))
    }

    useEffect(() => {
        setData({
            logo: currentLayoutObject.logo,
            darklogo: currentLayoutObject.darklogo,
            favicon: currentLayoutObject.favicon,
            squarelogo: currentLayoutObject.squarelogo,
        })

    }, [currentLayoutObject])
    
    return (
        <>
            <div className='surface-875 px-16px py-8px v1-border-bottom-1 br-tr-4px br-tl-4px'>
                <p className='fw-600 fs-16px text-200'>Branding Settings</p>
                <p className='text-400 fs-12px fw-400 my-1'>
                    Customize how your organization looks across web
                </p>
            </div>
            <div className='p-3 surface-900 br-br-4px br-bl-4px'>
                {/* Logo */}
                <div className='d-flex align-items-center justify-content-between mb-16px'>
                    <div className='w-50'>
                        <p className='fw-600 fs-14px text-400 mb-1'>Light Mode Logo</p>
                        <p className='fw-400 fs-12px text-400'>
                            An image that identifies your organization. Use Rectangle image at least 180px
                            by 36px. The image size should be under 1mb
                        </p>
                    </div>
                    
                    <ImageCard imgUrl={data.logo} name='logo'
                        setUrl={(url) => setData({...data, logo: url})} />
                </div>

                {/* DarkMode Logo */}
                <div className='d-flex align-items-center justify-content-between mb-16px'>
                    <div className='w-50'>
                        <p className='fw-600 fs-14px text-400 mb-1'>Dark Mode Logo</p>
                        <p className='fw-400 fs-12px text-400'>
                            A dark mode image that identifies your organization. Use Rectangle image at least 180px
                            by 36px. The image size should be under 1mb
                        </p>
                    </div>
                    
                    <ImageCard imgUrl={data.darklogo} name='DarkLogo'
                        setUrl={(url) => setData({...data, darklogo: url})} />
                </div>

                {/* Favicon */}
                <div className='d-flex align-items-center justify-content-between mb-16px'>
                    <div className='w-50'>
                        <p className='fw-600 fs-14px text-400 mb-1'>Favicon</p>
                        <p className='fw-400 fs-12px text-400'>
                            An Icon associated with your developer portal. Typically displayed in the address bar of a browser.
                            Use Square shaped images of ideal resolution 16x16 pixels or 32x32 pixels
                        </p>
                    </div>
                    
                    <ImageCard imgUrl={data.favicon} name='favicon'
                        setUrl={(url) => setData({...data, favicon: url})} />
                </div>

                {/* Square mode Logo */}
                <div className='d-flex align-items-center justify-content-between mb-16px'>
                    <div className='w-50'>
                        <p className='fw-600 fs-14px text-400 mb-1'>Square Logo</p>
                        <p className='fw-400 fs-12px text-400'>
                            A Square sized image to display on dashboard. Use square image at least 30px
                            by 30px. The image size should be under 1mb
                        </p>
                    </div>
                    
                    <ImageCard imgUrl={data.squarelogo} name='squareLogo'
                        setUrl={(url) => setData({...data, squarelogo: url})} />
                </div>

                <div className='d-flex align-items-center justify-content-end mt-3'>
                    <p className='fw-500 fs-12px text-300' onClick={handleCancel}>Cancel</p>

                    { loading
                    ? <div className='ms-3'><Loading size='sm' /></div>
                    : <div className='btn-md-v1 fw-500 fs-12px text-300 ms-3' onClick={handleSave}>
                        Save
                    </div>}
                </div>
            </div>
        </>
    )
}
