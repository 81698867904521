import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router'
import { BodyLayout } from '../../../layout/Base'
import GetIcon from '../../../components/GetIcon'
import { confirm, getPermissionByCrud } from '../../../utils/helper'
import Connectors from './components/Connectors'
import AccountConfiguration from './components/AccountConfiguration'
import ActivityLog from './components/ActivityLog/index'
import CustomDateRange from './components/ActivityLog/CustomDateRange'
import SMTPConfiguration from './components/SMTPConfiguration'

export default function Settings() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const {firstRoute} = useSelector(state => state.user)
    let settingItems = []

    /* ACL Start */
    const { currentUser} = useSelector((state) => state.user);
    const {permission} = useSelector(state => state.user)

    // Account Configuration
    let _accountPermission = permission.filter(o => o.name === 'setting')
    _accountPermission = _accountPermission.length ? (_accountPermission[0].crud || []) : []
    const accountUpdate = getPermissionByCrud(currentUser, _accountPermission, 'update')
    if (accountUpdate) settingItems.push('Account Configuration')

    // SMTP Configuration
    let _smtpPermission = permission.filter(o => o.name === 'smtp')
    _smtpPermission = _smtpPermission.length ? (_smtpPermission[0].crud || []) : []
    const smtpList = getPermissionByCrud(currentUser, _smtpPermission, 'list')
    if (smtpList) settingItems.push('SMTP Configuration')

    // Activity log
    let _activityPermission = permission.filter(o => o.name === 'activity')
    _activityPermission = _activityPermission.length ? (_activityPermission[0].crud || []) : []

    const activityLogList = getPermissionByCrud(currentUser, _activityPermission, 'list')
    if (activityLogList) settingItems.push('Activity Log')

    // Connectors
    let _connectorPermission1 = permission.filter(o => o.name === 'aws-artifactory')
    _connectorPermission1 = _connectorPermission1.length ? (_connectorPermission1[0].crud || []) : []

    let _connectorPermission2 = permission.filter(o => o.name === 'gcs-artifactory')
    _connectorPermission2 = _connectorPermission2.length ? (_connectorPermission2[0].crud || []) : []


    const connector1Read = getPermissionByCrud(currentUser, _connectorPermission1, 'read')
    const connector2Read = getPermissionByCrud(currentUser, _connectorPermission2, 'read')
    if (connector1Read && connector2Read) settingItems.push('Connectors')
    /* ACL END */

    const [selected, setSelected] = useState(settingItems.length ? settingItems[0] : 'Activity Log')
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setTitle("Account Settings")
        setHasHeader(true)
        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Settings'}
        ])
    }, [])

    const handleClick = (item) => {
        if (item === selected) return

        if (isEdit && !confirm("Any changes will be lost? are you sure to continue?")) return

        setSelected(item)
        setIsEdit(false)
    }
    
    return (
        <BodyLayout>
            <div className='fadeIn w-100 h-100 gap-10px d-flex align-items-center justify-content-between'>
                <div className='w-20 surface-900 br-10px h-100'>
                    <div className='px-16px py-13px surface-875 br-tl-10px br-tr-10px v1-border-bottom-1'>
                        <p className='text-100 fs-18px fw-600'>Settings</p>
                    </div>

                    <div className='p-8px'>
                        {settingItems?.map((each, i) => 
                        <div className={`hover-surface-875 d-flex align-items-center px-8px py-12px br-2px ${selected === each ? 'surface-850' : 'surface-900'}`} key={i} 
                            onClick={() => handleClick(each)}>
                            <GetIcon type={each} size={13} color={'#e4e4e4'} />
                            <p className='text-50 fs-14px fw-500 ps-2'>
                                {each}
                            </p>
                        </div>)}
                    </div>
                </div>

                {settingItems.length > 0 ? <div className='w-80 surface-900 br-10px h-100'>
                    {/* <div className='px-16px py-9px surface-875 d-flex align-items-center justify-content-between br-tl-10px br-tr-10px v1-border-bottom-1'>
                        <p className='text-100 fs-18px fw-600'>{selected}</p>

                        { (selected === 'Account Configuration' || selected === 'SMTP Configuration')
                        ? (isEdit
                            ? <div className='d-flex align-items-center'>
                                <p className='fw-500 fs-12px text-300 cursor' onClick={() => setIsEdit(false)}> Cancel</p>

                                <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn ms-3'>
                                    <p className='fs-12px fw-500 text-300'>Save</p>
                                </div>
                            </div>
                            : <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn'
                                onClick={() => setIsEdit(true)}>
                                <p className='fs-12px fw-500 text-300'>Edit</p>
                            </div>)
                        : <div style={{height: 35}}></div>}
                    </div> */}

                    <div style={{height: `calc(100%)`}}>
                        {
                            selected === 'Connectors'
                            ? <Connectors />
                            : selected === 'Account Configuration' 
                            ? <AccountConfiguration /> 
                            : selected === 'SMTP Configuration'
                            ? <SMTPConfiguration />
                            : <ActivityLog /> 
                        }
                    </div>
                </div>
                : <div className='w-80 surface-900 br-10px h-100'>
                
                </div>}
            </div>
        </BodyLayout>
    )
}
