import { X } from "phosphor-react";
import Modal from "react-bootstrap/Modal";
import { useTheme } from "../../../../context/ThemeContext";
import { useEffect, useState } from "react";
import monetizationV2 from "../../../../api/monetizationV2";
import { convertExpressionToGraph, labelize, throwServerError } from "../../../../utils/helper";
import { Editor } from "@monaco-editor/react";
import { objectDeepClone } from "@apiwiz/oas/services";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import MeteringGroup from "../components/MeteringGroup";
import MeteringElement from "../components/MeteringElement";
import RateCard from "../components/RateCard";
import MyReactTooltip from "../../../../components/ReactTooltip";
import { Tab, Tabs } from "react-bootstrap";

const TxnTabsList = [
    { label: "Body", value: "requestBody" },
    { label: "Body", value: "responseBody" },
    { label: "Header Param", value: "headerParams" },
    { label: "Query Param", value: "queryParams" },
    { label: "Form Param", value: "formParams" },
    { label: "Path Param", value: "pathParams" },
];

const TransactionTabs = (props) => {
    const { data, tab } = props;
    const [activeTab, setActiveTab] = useState(tab);

    const handleOnClick = (tab) => {
        setActiveTab(tab.value);
        props.handleTabOnChange(tab.value);
    };

    return (
        <div className="d-flex justify-content-start align-items-center txnHisotry-tabs-container overflow-scroll noscrollbar">
            {TxnTabsList.map((tab, ind) =>
                data.hasOwnProperty(tab.value) ? (
                    <span
                        key={ind}
                        onClick={() => handleOnClick(tab)}
                        style={{ borderImageSource: "none", height: "44px", paddingBottom: "12px" }}
                        className={`text-nowrap py-12px px-24px txnHisotry-tab fs-12px fw-500 ${activeTab === tab.value ? "activeTab v1-active-border-bottom text-consumer-50 surface-consumer-900" : "surface-consumer-875 text-consumer-400"}`}
                    >
                        {tab.label}
                    </span>
                ) : null,
            )}
        </div>
    );
};

const TransactionEditor = (props) => {
    const { data } = props;
    const { theme } = useTheme();

    const monocoEditorOption = {
        wrappingIndent: "indent",
        wordWrap: true,
        minimap: { enabled: false },
        readOnly: true,
    };
    return (
        <div className="kong-editor-bg-transparent w-100" style={{ height: "350px" }}>
            <Editor width="100%" height="100%" value={data} language={"json"} options={monocoEditorOption} theme={theme === "theme--dark" ? "vs-dark" : "light"} />
        </div>
    );
};

export default function TransactionDetailModal(props) {
    const { data } = props;
    const { theme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [txnData, setTxnData] = useState({});
    const [activeTab, setActiveTab] = useState("Traffic");
    const MAIN_TABS = ["Traffic", "Rule Satisfied", "Rate Card"];

    const [decrypted, setDecrypted] = useState(false);
    const [reqActiveTab, setReqActiveTab] = useState(TxnTabsList[0]);
    const [resActiveTab, setResActiveTab] = useState(TxnTabsList[0]);
    const [reqEditorData, setReqEditorData] = useState("");
    const [resEditorData, setResEditorData] = useState("");

    const [meteringData, setMeteringData] = useState({})
    const [blocksJson, setBlocksJson] = useState({})

    const handleStringify = (value) => {
        try {
            return JSON.stringify(JSON.parse(value), null, "\t");
        } catch (error) {
            console.log(error, "Error");
            return value;
        }
    };

    const handleReqTabOnChange = (data, value) => {
        if (data.request.hasOwnProperty(value))
            setReqEditorData(value === "requestBody" && data.request[value] ? handleStringify(data.request[value]) : JSON.stringify(data.request[value], null, "\t"));
    };
    const handleResTabOnChange = (data, value) => {
        if (data.response.hasOwnProperty(value))
            setResEditorData(value === "responseBody" && data.response[value] ? handleStringify(data.response[value]) : JSON.stringify(data.response[value], null, "\t"));
    };

    const getData = (data) => {
        if (data && Object.keys(data).length)
            TxnTabsList.forEach((el) => {
                if (data.request.hasOwnProperty(el.value)) {
                    handleReqTabOnChange(data, el.value);
                    setReqActiveTab(el.value);
                }
                if (data.response.hasOwnProperty(el.value)) {
                    handleResTabOnChange(data, el.value);
                    setResActiveTab(el.value);
                }
            });
    };

    const handleDecrypt = () => {
        setLoading(true);
        setDecrypted(true);
        setReqEditorData("");
        setResEditorData("");
        monetizationV2.decryptComplianceRuntimeCatalog({requestID: props.data?.transactionId})
            .then(({data: {data: res}}) => {
                setLoading(false);
                setTxnData(res);
                getData(res.complianceCheckDTO);
            })
            .catch((err) => {
                setLoading(false);
                setDecrypted(false);
                throwServerError(err);
            });
    };

    const getTransactionData = () => {

        setLoading(true)
        monetizationV2.fetchComplianceRuntimeCatalog({transactionId: data?.transactionId})
        .then(({data: {data: res}}) => {
            setLoading(false)
            setTxnData(res);
            getData(res.complianceCheckDTO);
        })
        .catch((err) => {
            setLoading(false)
            throwServerError(err)
        })

        if (props.data.meteringId){
            monetizationV2.fetchMeteringRuleById(props.data.packageId, props.data.resourceId, props.data.meteringId)
            .then(({data: {data: res}})  => {
                setMeteringData(objectDeepClone(res))

                if (res && res.condition){
                    let _json = convertExpressionToGraph(res.condition.expression || "", res.condition.placeHolders || [])
                    setBlocksJson(_json)
                }
                console.log('-- res', res)
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            monetizationV2.getRateCardById(props.data.rateCardId)
            .then(({data: {data: res}})  => {
                console.log('res', res)
                setMeteringData(objectDeepClone({
                    ruleName: '', ruleSummary: '',
                    rateCard: [res]
                }))
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    useEffect(() => {
        console.log(props.data)

        getTransactionData()
    }, [])
    
    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName="br-0px homeModalForLghtMode"
            className="custom-modal-v1 v1--styles"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="p-3 br-0px surface-consumer-975 d-flex flex-column" style={{borderBottom: 'none'}}>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 text-theme-content-color fw-600 fs-16px me-2">{props.data?.transactionId}</p>
                        {!decrypted ? (
                            <div onClick={handleDecrypt} className="cursor border-1px border-strokes-600 br-4px surface-consumer-850 py-10px px-24px fs-12px fw-500 text-consumer-300">
                                Decrypt
                            </div>
                        ) : null}
                    </div>

                    <div
                        onClick={props.onHide}
                        style={{ border: "0.5px solid" }}
                        className="px-6px cursor br-4px border-strokes-800 surface-consumer-850"
                    >
                        <X size={16} color={theme === "theme--light" ? "#464646" : "#C2C2C2"} />
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body style={{ height: "calc(100vh - 60px)" }} className="consumer-tabs p-0 br-0 overflow-scroll noscrollbar">
                {loading ? (
                    <ContainerLoader className="w-100 h-100" variant="theme-primary"/>
                ) : (
                    <Tabs
                        className="consumer-tabs surface-consumer-875"
                        activeKey={activeTab}
                        id="gateway-select-tabs"
                        onSelect={(tab)=>setActiveTab(tab)}
                    >
                        <Tab
                            tabClassName="tabs-btn-2 consumer-tabs"
                            className=""
                            title={MAIN_TABS[0]}
                            eventKey={MAIN_TABS[0]}
                        >
                            {txnData && txnData.complianceCheckDTO && Object.keys(txnData && txnData.complianceCheckDTO).length ? (
                                <div className="p-8px d-flex align-items-center gap-8px">
                                    <div className="w-50 br-8px">
                                        <div className="surface-consumer-850 py-16px pr-8px pl-16px d-flex align-items-center gap-4px br-tr-4px br-tl-4px">
                                            <div className={`v2-text-${txnData?.complianceCheckDTO?.request?.verb.toUpperCase() || "get"} fs-12px fw-600`}>
                                                {txnData?.complianceCheckDTO?.request?.verb || ""}
                                            </div>
                                            <div className="fs-12px text-consumer-200 fw-500 truncate-sm" data-for="txn" data-tip={txnData?.complianceCheckDTO?.request?.path || ""}>
                                                {txnData?.complianceCheckDTO?.request?.path || ""}
                                            </div>
                                            <div style={{ lineHeight: "22px" }}>
                                                <MyReactTooltip id={"txn"} />
                                            </div>
                                        </div>
                                        <TransactionTabs
                                            data={txnData.complianceCheckDTO.request}
                                            tab={reqActiveTab}
                                            handleTabOnChange={(value) => handleReqTabOnChange(txnData.complianceCheckDTO, value)}
                                        />
                                        <TransactionEditor data={reqEditorData} />
                                    </div>
                                    <div className="w-50 br-8px">
                                        <div className="surface-consumer-850 py-16px pr-8px pl-16px fs-13px fw-500 text-consumer-100 br-tr-8px br-tl-8px">Response</div>
                                        <TransactionTabs
                                            data={txnData.complianceCheckDTO.response}
                                            tab={resActiveTab}
                                            handleTabOnChange={(value) => handleResTabOnChange(txnData.complianceCheckDTO, value)}
                                        />
                                        <TransactionEditor data={resEditorData} />
                                    </div>
                                </div>
                            ) : null}
                        </Tab>
                        <Tab
                            className="w-100"
                            tabClassName="tabs-btn-2 consumer-tabs"
                            title={MAIN_TABS[1]}
                            eventKey={MAIN_TABS[1]}
                            style={{height: "calc(100% - 46px)"}}
                        >
                            <div className="p-8px h-100 w-100">
                                <div className="dfaic border-1px border-strokes-600 br-4px w-100" style={{height: '95%'}}>
                                    <div className="surface-consumer-875 br-4 h-100 py-12px pl-12px" style={{width: '30%'}}>
                                        <div className="mb-3">
                                            <p className="text-consumer-300 fw-400 fs-12px">Rule Name</p>
                                            <p className="text-consumer-200 fw-500 fs-14px">
                                                {meteringData.ruleName || '-'}
                                            </p>
                                        </div>
                                        <div className="mb-3">
                                            <p className="text-consumer-300 fw-400 fs-12px">Rule Description</p>
                                            <p className="text-consumer-200 fw-500 fs-14px">
                                                {meteringData.ruleSummary || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="overflow-scroll noscrollbar h-100 position-relative" style={{width: '70%'}}>
                                        <div style={{position: 'absolute',overflow: 'hidden'}} className="w-100 h-100 surface-consumer-base">
                                            <svg width="100%" height="100%">
                                                <pattern id="pattern-circles" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
                                                    <circle id="pattern-circle" cx="5" cy="5" r="1.6257413380501518" 
                                                        fill={theme === 'theme--light' ?  "var(--light-surface-consumer-850)" : "var(--dark-surface-consumer-850)"}></circle>
                                                </pattern>
                                                
                                                <rect  id="rect" x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)"></rect>
                                            </svg>
                                        </div>
                                        <div className="meteringPlayground noscrollbar p-2 d-flex" style={{ marginTop: 60 }}>
                                            {blocksJson._type === "group" && <MeteringGroup data={blocksJson} viewOnly />}

                                            {blocksJson._type === "element" && <MeteringElement data={blocksJson} viewOnly />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab
                            tabClassName="tabs-btn-2 consumer-tabs"
                            title={MAIN_TABS[2]}
                            eventKey={MAIN_TABS[2]}
                        >
                            <div className="p-8px h-100 w-100">
                                {meteringData.rateCard?.map((eachRateCard) => (
                                    <div key={eachRateCard.id} className="mb-3 d-flex gap-8px">
                                        <div className="py-12px pl-12px d-flex flex-column gap-8px w-30 border-1px border-strokes-600 br-4px surface-consumer-875">
                                            <div className="d-flex flex-column gap-4px">
                                                <p className="text-consumer-300 fw-400 fs-12px">Rate Card Name</p>
                                                <p className="text-consumer-200 fw-500 fs-14px">
                                                    {eachRateCard.name || '-'}
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-4px">
                                                <p className="text-consumer-300 fw-400 fs-12px">Rate Card Description</p>
                                                <p className="text-consumer-200 fw-500 fs-14px">
                                                    {eachRateCard.description || '-'}
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-4px">
                                                <p className="text-consumer-300 fw-400 fs-12px">Charging Model</p>
                                                <p className="text-consumer-200 fw-500 fs-14px">
                                                    {labelize(eachRateCard.type)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="p-12px d-flex flex-column gap-8px w-70 border-1px border-strokes-600 br-4px surface-consumer-875">
                                            <RateCard data={eachRateCard} fromModal />  
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    </Tabs>
                )}
            </Modal.Body>
        </Modal>
    );
}
