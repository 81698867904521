import { useEffect, useMemo, useRef, useState } from "react";
import CustomFooter from "../Dashboards/components/CustomFooter";
import CustomNavbar from "../Dashboards/components/CustomNavbar";
import { ApplePodcastsLogo, MagnifyingGlass } from "phosphor-react";
import GUIDE_API from "../../api/guides";
import GUIDE_CATEGORY_API from "../../api/guideCategory";
import { objectDeepClone, throwServerError } from "../../utils/helper";
import { ROUTE_NAMES, STATUS } from "../../service/constants";
import PaginationV2 from "../../components/PaginationV2";
import Searchbar from "../../components/Searchbar/theme";
import { useNavigate } from "react-router";
import API from "../../api";
import { EmptyState } from "../../components/EmptyState";

export default function GuideList(props) {
  const paging = useRef({ pageSize: 12, offset: 1, total: 0 });
  const [publishedGuides, setPublishedGuides] = useState([]);
  const [categories, setCategories] = useState([]);
  const selectedCategory = useRef(null);
  const searchtext = useRef("");
  const navigate = useNavigate();
  const getPublishedGuides = () => {
    GUIDE_API.getGuides({
      currentPage: paging.current.offset,
      status: STATUS.published,
      pageLimit: paging.current.pageSize,
      category: selectedCategory.current,
      searchtext: searchtext.current,
      deprecated: false
    })
      .then((res) => {
        let _paging = {
          ...paging.current,
          total: res.paging?.total || 0,
        };
        paging.current = _paging;
        setPublishedGuides(res.data || []);
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const getCategoryList = () => {
    GUIDE_CATEGORY_API.getAllGuideCategory()
      .then((response) => {
        if (response.data.data) {
          let _res = objectDeepClone(response.data.data);
          setCategories(_res);
        } else {
          setCategories([]);
        }
      })
      .catch((error) => {
        throwServerError(error);
        setCategories([]);
      });
  };

  const onSelectCategory = (el) => {
    selectedCategory.current = el.categoryname;
    getPublishedGuides();
  };
  useEffect(() => {
    getPublishedGuides();
    getCategoryList();

    API.trackSessions("GUIDES")
      .then((res) => console.log("res", res))
      .catch((err) => console.log("err", err));
  }, []);

  const noExtraPage = useMemo(() => {
    return paging.current.total <= paging.current.pageSize;
  }, [paging.current]);
  return ( 
    <div className="w-100 h-100 guide">
      <CustomNavbar />
      <div className="mt-62px container">
        <div className="d-flex justify-content-center guide-body hideLeftDivInMobile bg-theme-base">
          {/* Left Panel */}
          <div className="guide-left-panel border-right-theme-base-200 py-32px pr-32px">
            <div className="pb-32px">
              <div className='searchSection mb-32px'>
                <MagnifyingGlass color='#667085' size={15} />

                <input type='text' placeholder='Search'
                    onChange={(e) => {
                        searchtext.current = e.target.value;
                        getPublishedGuides();
                    }} />
              </div>
              {/* <Searchbar
                className={"h-44px pl-8px"}
                inputClassName={"fs-16px lh-24px"}
                placeholder={"Search"}
                onChange={(val) => {
                  searchtext.current = val;
                  getPublishedGuides();
                }}
              /> */}
            </div>
            <div className="d-flex flex-column gap-20px">
              <div className="fs-14px lh-20px fw-600 text-theme-primary">
                Guide categories
              </div>
              <div className="d-flex flex-column gap-4px h-30px">
                {categories.map((el) => {
                  return (
                    <div
                      onClick={() => {
                        onSelectCategory(el);
                      }}
                      className={`py-8px pl-16px pr-10px cursor fw-600 fs-16px ${
                        selectedCategory.current === el.categoryname
                          ? "text-theme-primary"
                          : ""
                      }`}
                    >
                      {el.categoryname}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Content Panel */}
          <div className="d-flex justify-content-center guide-content-panel p-32px w-100">
            <div className="d-flex flex-column guide-content gap-24px">
              <div className="d-flex flex-column gap-4px align-items-center">
                <div className="fs-32px fw-600 lh-38px">Guides</div>
                <div className="fs-16px lh-28px">
                  Explore popular guides and review common references that can
                  help during your API integration.
                </div>
              </div>
              {/* Cards Content */}
              <div className="cards-content pb-16px">
                {publishedGuides?.length > 0 ? (
                  publishedGuides.map((el) => {
                    return (
                      <div
                        onClick={() => {
                          navigate(`/${ROUTE_NAMES.GUIDES}/${el.slug}`);
                        }}
                        className="guide-card p-16px d-flex flex-column gap-12px"
                      >
                        <div className="d-flex gap-12px align-items-center">
                          <div>
                            <ApplePodcastsLogo
                              size={32}
                              color="var(--primary-color)"
                            />
                          </div>
                          <div className="text-truncate h-26px py-4px px-8px fs-12px bg-theme-secondary-200 text-theme-primary br-12px">
                            {el.category}
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-8px">
                          <div className="lh-32px fs-20px fw-700">
                            {el.name}
                          </div>
                          <div className="fs-14px lh-20px text-theme-content-subtle text-truncate-4">
                            {el.description}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="mt-5">
                    <EmptyState />

                    <p className="ps-5 text-content-color text-center">
                      No Guides Found
                    </p>
                  </div>
                )}
              </div>
              {/* <div className="d-flex justify-content-center guide-container"></div> */}
              <div className="d-flex flex-column justify-content-center">
                {/* <div className="guide-responsive-container h-2px bg-theme-secondary-200"></div> */}
                {noExtraPage ? null : (
                  <div className="pt-20px border-top-theme-secondary-200 _2px">
                    <PaginationV2
                      cb={(e) => {
                        paging.current.offset = e;
                        getPublishedGuides();
                      }}
                      pagination={paging.current}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
