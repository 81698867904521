import { useEffect, useState } from "react";
import Searchbar from "../../../../components/Searchbar";
import { BodyLayout } from "../../../../layout/Base";
import Loading from "../../../../components/Loader/Loading";
import { useOutletContext } from "react-router";
import { CaretRight } from "phosphor-react";
import API from "../../../../api";
import { confirm, handleServerSideError, objectDeepClone, throwServerError } from "../../../../utils/helper";
import { getToast } from "../../../../components/Toast";
import { useSelector } from "react-redux";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";

export default function AccessControlList(){

    const [searchValue,setSearchValue] = useState('')
    const [loading,setLoading] = useState(false)
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [permissions, setPermissions] = useState([])
    const [ backupPermissions, setBackupPermissions ] = useState([])
    const [ roles, setRoles ] = useState([])
    const [selectedRole, setSelectedRole] = useState('')
    const [ edit , setEdit ] = useState(false);
    const {firstRoute} = useSelector(state => state.user)

    const [buttonDisable, setButtonDisable] = useState(false)
    const [subLoading, setSubLoading] = useState(false)

    useEffect(()=>{
        setTitle("Users")
        setHasHeader(true)
        setNavigation([
            {name: 'Dashboard', link : firstRoute},
            {name: 'Access Control List'},
        ])
        getPermissionList();
    },[])

    const getPermissionList = () => {
        setLoading(true)
        setEdit(false)
        API.getPermissions()
        .then(response => {
            let permissions = objectDeepClone(response.data.data.permissions);
            let roles = objectDeepClone(response.data.data.roles)
            const createObj = (o) => {
               return {
                   name: o.object,
                   crud: permissions.filter(n => n.object === o.object).map(n => {
                      return {
                          ...n,
                          roles: n.roles.map(r => {
                              let obj = {
                                role: r.code,
                                enabled: r.code === 'super-admin' ? true : r.enabled,
                                object: n.object,
                                action: n.action
                            }
                            return obj
                        })
                      }
                   })
               }
            }
            let perm = permissions.map(createObj);

            const uniquePerm = perm.filter((thing, index) => {
                const _thing = JSON.stringify(thing);
                return index === perm.findIndex(o => {
                    return JSON.stringify(o) === _thing;
                });
            }).sort((a,b) => a.name<b.name ? -1 : a.name>b.name ? 1 : 0)

            setPermissions(objectDeepClone(uniquePerm))
            setBackupPermissions(objectDeepClone(uniquePerm))
            setRoles(roles)
            if (!selectedRole) setSelectedRole(roles[0].code)
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
            handleServerSideError(err)
            setLoading(false)
        })
    }

    function hasActionValue(objectsArray, value) {
        for (let i = 0; i < objectsArray.length; i++) {
            if (objectsArray[i].action === value) {
                return true;
            }
        }
        return false;
    }

    function hasRole(objectsArray, value, role){
        for (let i = 0; i < objectsArray.length; i++) {
            if (objectsArray[i].action === value) {
                const arr = objectsArray[i].roles?.filter(o => (o.role === role && o.enabled));
                if (arr.length) return arr[0]
                else return false
            }
        }
        return false;
    }

    function handleToggle(id, value, role){
        let _temp = objectDeepClone(permissions)

        let _newCrudArr = []

        _temp[id].crud.forEach(each => {
            if (each.action === value){
                let _newRoles = []

                each.roles.forEach(eachRole => {
                    if (eachRole.role === role){
                        eachRole.enabled = !eachRole.enabled
                        _newRoles.push(eachRole)
                    } else {
                        _newRoles.push(eachRole)
                    }
                })

                _newCrudArr.push({...each, roles: _newRoles})
            } else {
                _newCrudArr.push(each)
            }
        })

        _temp[id]['crud'] = objectDeepClone(_newCrudArr)

        setPermissions(objectDeepClone(_temp))
    }

    const handleClick = (item) => {
        if (selectedRole === item.code) return
        if (!edit) {
            setSubLoading(true)
            setSelectedRole(item.code)
            setTimeout(() => {
                setSubLoading(false)
            }, 500);
            return
        }

        if (!confirm("Any changes made will be lost, click ok to continue?")) return
        setSubLoading(true)
        setPermissions(objectDeepClone(backupPermissions))
        setSelectedRole(item.code)
        setEdit(false)
        setTimeout(() => {
            setSubLoading(false)
        }, 500);
    }
    
    const handleSave = () => {
        setButtonDisable(true)
        let arr = [];
        let _permissions = objectDeepClone(permissions)
        _permissions.map((p) => {
            p.crud.map((c) => {
                c.roles.map((r) => {
                    arr.push(r);
                });
            });
        });

        _permissions = arr.filter(el => el.role === selectedRole);

        API.updatePermissions({
            permissions: objectDeepClone(_permissions)
        }).then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Changes saved successfully'
            })
            setButtonDisable(false)
            getPermissionList()
        }).catch(err => {
            setButtonDisable(false)
            throwServerError(err)
        })
    }

    return (
    <BodyLayout>
        <div className='fadeIn d-flex px-0 h-100 v1apiCatalog-Body'>
            <div className='col-md-3 px-0 '>
                <div className='w-100 h-100 surface-900 v1-border-01 br-10px v1apiCatalog-Body--filterSection overflow-auto noscrollbar'>
                    <div className="surface-875 p-3 v1-border-bottom-05">
                        <p className="fs-18px fw-600 text-50 mb-1">Role</p>
                        <p className="fs-12px fw-400 text-400">Filter through roles created in User Roles</p>
                    </div>
                    <div className="pt-12px px-2 pb-2">
                        <div className='v1apiCatalog-Body--filterSection-body mt-1'>
                            {
                                roles.map((item)=>{
                                    return <div className={`d-flex justify-content-between align-items-center br-2px p-8px cursor hover-surface-850 mb-2 ${selectedRole === item.code ? 'surface-850' : ''}`}
                                        onClick={() => handleClick(item)}>
                                        <p className="fw-400 fs-16px text-300">{item.name}</p>
                                        <CaretRight size={14} color="#E4E4E4"/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-md-9 h-100 ps-2 pe-0'>
                <div className="surface-900">
                    <div className='v1apiCatalog-Body--cardsTitle surface-875 d-flex align-items-center v1-border-bottom-05 py-8 br-tl-8px br-tr-8px p-3 pr-0'>
                        <div className="w-50 surface-875 br-tl-8px">
                            <p className="fs-18px fw-600 text-50 mb-1">Feature List</p>
                            <p className="fs-12px fw-400 text-400">Give access to features on the basis of roles </p>
                        </div>

                        <div className="ml-45px w-40 mr-25px">
                            <Searchbar className='w-100' value={searchValue}
                                onChange={(val) => setSearchValue(val)}
                            />
                        </div>
                        {(selectedRole !== 'super-admin' && <div className="w-10 d-flex align-items-center justify-content-end ml-40px">
                            { !edit 
                                ? <div className="surface-850 py-7px px-4 v1-border-03 br-5px cursor-pointer" onClick={()=>{setEdit(true)}}>
                                    <p className="fs-12px fw-500 text-300">Edit</p>
                                </div>
                                : <div className='d-flex align-items-center ps-3'>
                                    <p className='fw-500 fs-12px text-300 cursor' onClick={() => {
                                        if (!confirm('Are you sure, all the changes will be lost? ')) return
                                        setEdit(false);
                                        setPermissions(objectDeepClone(backupPermissions))
                                    }}> Cancel</p>

                                    <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn ms-3'
                                        onClick={() => buttonDisable ? null : handleSave()}>
                                        <p className='fs-12px fw-500 text-300'>{buttonDisable ? 'Saving...' : 'Save'}</p>
                                    </div>
                                </div>}
                        </div>)}
                    </div>
                    <div className='surface-900 v1apiCatalog-Body--cardsSectionWrapper noscrollbar px-2 py-2'>
                        <div className='surface-875 mx-0 d-flex p-2 mt-1 ps-3'>
                            <div className="w-46 fs-10px fw-500 text-400">
                                Features
                            </div>
                            <div className="w-9 fs-10px fw-500 text-400">
                                Sync
                            </div>
                            <div className="w-9 fs-10px fw-500 text-400">
                                List
                            </div>
                            <div className="w-9 fs-10px fw-500 text-400">
                                Create
                            </div>
                            <div className="w-9 fs-10px fw-500 text-400">
                                Read
                            </div>
                            <div className="w-9 fs-10px fw-500 text-400">
                                Delete
                            </div>
                            <div className="w-9 fs-10px fw-500 text-400">
                                Update
                            </div>
                        </div>
                        <div className="tableBody overflow-scroll" style={{height:"calc(100vh - 220px)"}}>
                            {
                                subLoading
                                ? <Loading />
                                : permissions.map((eachPermission, i) => {
                                    if (searchValue && !eachPermission.name.includes(searchValue.toLowerCase())) return
                                    return (<div className="w-100 d-flex align-items-center p-16px v1-border-bottom-1 surface-900"
                                        key={eachPermission.name}>
                                        <div className="w-46">
                                            <p className="fw-500 fs-12px text-50">{eachPermission.name || ''}</p>
                                        </div>

                                        <div className="w-9">
                                            { hasActionValue(eachPermission.crud, 'sync')
                                                ? (
                                                    <div className="pl-3px cursor"
                                                        onClick={() => !edit ? null : handleToggle(i, 'sync', selectedRole)}>
                                                        {
                                                            hasRole(eachPermission.crud, 'sync', selectedRole)
                                                            ? <GlobalIcons type={!edit ? 'form-check-view' : 'form-check'} />
                                                            : <GlobalIcons type={!edit ? 'form-uncheck-view' : 'form-uncheck'} />
                                                        }
                                                    </div>
                                                )
                                                : <p className="pl-12px"> - </p>
                                            }
                                        </div>

                                        <div className="w-9">
                                            { hasActionValue(eachPermission.crud, 'list')
                                                ? (
                                                    <div className="pl-1px cursor"
                                                        onClick={() => !edit ? null : handleToggle(i, 'list', selectedRole)}>
                                                        {
                                                            hasRole(eachPermission.crud, 'list', selectedRole)
                                                            ? <GlobalIcons type={!edit ? 'form-check-view' : 'form-check'} />
                                                            : <GlobalIcons type={!edit ? 'form-uncheck-view' : 'form-uncheck'} />
                                                        }
                                                    </div>
                                                )
                                                : <p className="pl-10px"> - </p>
                                            }
                                        </div>

                                        <div className="w-9">
                                            { hasActionValue(eachPermission.crud, 'create')
                                                ? (
                                                    <div className="pl-9px cursor"
                                                        onClick={() => !edit ? null : handleToggle(i, 'create', selectedRole)}>
                                                        {
                                                            hasRole(eachPermission.crud, 'create', selectedRole)
                                                            ? <GlobalIcons type={!edit ? 'form-check-view' : 'form-check'} />
                                                            : <GlobalIcons type={!edit ? 'form-uncheck-view' : 'form-uncheck'} />
                                                        }
                                                    </div>
                                                )
                                                : <p className="pl-18px"> - </p>
                                            }
                                        </div>

                                        <div className="w-9">
                                            { hasActionValue(eachPermission.crud, 'read')
                                                ? (
                                                    <div className="pl-6px cursor"
                                                        onClick={() => !edit ? null : handleToggle(i, 'read', selectedRole)}>
                                                        {
                                                            hasRole(eachPermission.crud, 'read', selectedRole)
                                                            ? <GlobalIcons type={!edit ? 'form-check-view' : 'form-check'} />
                                                            : <GlobalIcons type={!edit ? 'form-uncheck-view' : 'form-uncheck'} />
                                                        }
                                                    </div>
                                                )
                                                : <p className="pl-14px"> - </p>
                                            }
                                        </div>

                                        <div className="w-9">
                                            { hasActionValue(eachPermission.crud, 'delete')
                                                ? (
                                                    <div className="pl-11px cursor"
                                                        onClick={() => !edit ? null : handleToggle(i, 'delete', selectedRole)}>
                                                        {
                                                            hasRole(eachPermission.crud, 'delete', selectedRole)
                                                            ? <GlobalIcons type={!edit ? 'form-check-view' : 'form-check'} />
                                                            : <GlobalIcons type={!edit ? 'form-uncheck-view' : 'form-uncheck'} />
                                                        }
                                                    </div>
                                                )
                                                : <p className="pl-19px"> - </p>
                                            }
                                        </div>

                                        <div className="w-9">
                                            { hasActionValue(eachPermission.crud, 'update')
                                                ? (
                                                    <div className="pl-13px cursor"
                                                        onClick={() => !edit ? null : handleToggle(i, 'update', selectedRole)}>
                                                        {
                                                            hasRole(eachPermission.crud, 'update', selectedRole)
                                                            ? <GlobalIcons type={!edit ? 'form-check-view' : 'form-check'} />
                                                            : <GlobalIcons type={!edit ? 'form-uncheck-view' : 'form-uncheck'} />
                                                        }
                                                    </div>
                                                )
                                                : <p className="pl-20px"> - </p>
                                            }
                                        </div>
                                    </div>)
                                })
                            }

                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </BodyLayout>
)
}