import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setSecurities, setTryoutOperationSecurity } from "../../../app/Features/Swagger/SwaggerSlice";
import { debounceFn, objectDeepClone } from "../../../utils/helper";
import { Input } from "./Inputs";

const onInput = ({ value }) => {
    window.dispatch(setSecurities({ prop: "basic", value }))
    setTimeout(() => {
        window.dispatch(setTryoutOperationSecurity())
    }, 200);
}
const onInputDebounceFn = debounceFn(onInput, 200);

const Basic = (props) => {
    const { securities } = useSelector((state) => state.swagger);

    const [username, setUsername] = useState(securities.basic?.username || '');
    const [password, setPassword] = useState(securities.basic?.password || '');

    const onChange = ({username, password}) => {
        let basic = objectDeepClone(securities.basic)
        if(username) basic.username = username;
        if(password) basic.password = password;
        onInputDebounceFn({ value: basic })
    }

    return (
        <>
            <div className="d-flex flex-column gap-8px">
                <Input
                label={"Username"}
                value={username || ""}
                onChange={(e) => {
                    setUsername(e.target.value)
                    onChange({username: e.target.value});
                }}
                />
                <Input
                label={"Password"}
                value={password || ""}
                onChange={(e) => {
                    setPassword(e.target.value);
                    onChange({password: e.target.value});
                }}
                />
            </div>
            {/* <div className="d-flex align-items-center justify-content-end gap-8px">
                <div className="d-flex align-items-center">
                    <div
                        className="linter-preview px-2 py-1 cursor"
                        onClick={onSubmit}
                    >
                        Submit
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Basic;