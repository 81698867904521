import {
  CaretRight,
  Check,
  Clipboard,
  Eye,
  FilePlus,
  PencilSimple,
  Trash,
} from "phosphor-react";
import {
  confirm,
  copyToClipboard,
  formatCtsDateWithoutUTC,
  formatCtsTimeWithoutUTC,
  getConsumerUsageAppId,
  getDeveloperAppCreatedAt,
  getDeveloperAppCreatedByUser,
  labelize,
  showSuccessMessage,
  throwServerError,
} from "../../../../utils/helper";
import { APP_STATUS } from "../../../../service/constants";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import { useNavigate } from "react-router";
import { useState } from "react";
import API from "../../../../api";
import { getUuidV4, sleep } from "@apiwiz/oas/services";

export const AppListRow = (props) => {
  const { data, allowDelete } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState("");

  const handleAppDelete = () => {
    if (!confirm("Are you sure, you want to delete this app?")) return;
    if(loading) return;
    setLoading(true);

    API.deleteDeveloperApp(data._id, { gatewayType: data.gateway })
      .then((res) => {
        showSuccessMessage("App deleted successfully");
        props.parentCallback()
      })
      .catch((err) => {
        setLoading(false);
        throwServerError(err);
      });
  };

  const handleCopy = async (_text) => {
    setCopied(_text);
    copyToClipboard(_text);
    showSuccessMessage("Copied Successfully!")
    await sleep(1000);
    setCopied("");
  };


  const appId = getConsumerUsageAppId({app:data})
  const createdAt = getDeveloperAppCreatedAt({app: data});
  const createdBy = getDeveloperAppCreatedByUser({app: data});

  return (
    <div
      className="row p-12px"
      style={{ borderBottom: "1px solid var(--base-100)" }}
    >
      <div className="col-md-3 my-auto">
        <p className="text-content-color fs-14px fw-500"> {data.name}</p>
      </div>
      <div className="col-md-2 my-auto">
        <div
          className={`app-badges ${labelize(
            data.status
          )} w-fit text-capitalize`}
        >
          <div className={`fw-500 fs-12px status-text px-3`}>
            {labelize(data.status)}
          </div>
        </div>
      </div>
      <div className="col-md-3 my-auto">
        {data.status === APP_STATUS.APPROVED ? (
          <div
            className="br-4px bg-base-100 px-8px py-5px d-flex align-items-center cursor justify-content-between"
            onClick={() => handleCopy(appId)}
          >
            <p className="text-content-subtle fs-10px fw-500 text-truncate me-1">
              {appId}
            </p>
            {copied == appId ? (
              <Check color="var(--content-subtle)" size={14} />
            ) : (
              <Clipboard color="var(--content-subtle)" size={14} />
            )}
          </div>
        ) : null}
      </div>
      <div className="col-md-2 my-auto">
        <p className="text-content-subtle fs-12px fw-500 text-center">
          {createdBy || ""}
        </p>
        <p className="text-content-subtle fs-11px fw-500 text-center">
          {formatCtsTimeWithoutUTC(createdAt)} - {" "}
          {formatCtsDateWithoutUTC(createdAt)}
        </p>
      </div>
      <div className="col-md-2 dfaic justify-content-end my-auto">
        <div className="dfaic gap-8px">
          <div
            className="cursor apps-row-action-btn"
            onClick={() => {
              navigate(`/monetization/app/${data._id}`);
            }}
          >
            <Eye color="var(--content-color)" size={14} />
          </div>

          {/* <div
            className="cursor apps-row-action-btn"
            onClick={() =>
              setUpdateAppModal({
                isOpen: true,
                data: objectDeepClone(data),
              })
            }
          >
            <PencilSimple color="var(--content-color)" size={14} />
          </div> */}

          {allowDelete && data.status !== APP_STATUS.DELETED ? (
            <div
              className="cursor apps-row-action-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleAppDelete(data);
              }}
            >
              {loading ? (
                <ContainerLoader variant="danger" size="sm" />
              ) : (
                <Trash color="#E5484D" size={14} />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
