import React from 'react'

export default function CustomSecondaryTab({data, readOnly=false}) {

    return (
        <div className='br-8px m-0 secondaryTabSection overflow-hidden w-100 d-flex align-items-center position-relative'>
            <div className='w-43' style={{zIndex: 3}}>
                <img src={data.imageUrl} alt='packageImage' style={{left: 0, top: 0, maxWidth: '40%', objectFit: 'contain'}}
                    className='h-95 position-absolute' />
            </div>

            <div className='w-50 pe-12px'>
                <p className='fs-18px fw-500 text-white'
                    style={{lineHeight: `130%`}}>{data.heading || ''}</p>

                <p className='fs-18px fw-500 text-white mt-20px'
                    style={{lineHeight: `130%`}}>{data.description || ''}</p>
            </div>
        </div>
    )
}
