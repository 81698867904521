import Pagination from '../../../components/Pagination'
import { ArrowSquareOut, PencilSimple } from 'phosphor-react'
import EmptyContainer from '../../../components/EmptyContainer'
import { SRC_PARAM_VALUES, URL_PARAMS } from '../../../service/constants'
import { constructFilterResourcesv2, formatCtsDate, formatCtsTime, getPermissionByCrud } from '../../../utils/helper'
import { useSelector } from 'react-redux'

export default function ProductBody({data, handleEdit, pagination, getData}) {

    const { permission, currentUser } = useSelector((state) => state.user)

    let manageProduct = permission.filter(o => o.name === 'manage-product')
    manageProduct = manageProduct.length ? (manageProduct[0].crud || []) : []
    const updateProduct = getPermissionByCrud(currentUser, manageProduct,"update");

    return (
        <div className='h-100'>
            <div className='row py-8px px-12px mx-0 surface-875'>
                <div className='col-md-5'>
                    <p className='text-400 fs-10px fw-500'>Name</p>
                </div>
                <div className='col-md-4'>
                    <p className='text-400 fs-10px fw-500'>Swagger Name</p>
                </div>
                <div className='col-md-2 d-none'>
                    <p className='text-400 fs-10px fw-500'>No of Resources</p>
                </div>
                <div className='col-md-1 d-none'>
                    <p className='text-400 fs-10px fw-500'>Created</p>
                </div>
                <div className='col-md-1'>

                </div>
            </div>

            <div className='overflow-scroll noscrollbar' style={{height: 'calc(100vh - 57px - 46px - 31px - 12px - 16px - 75px - 30px)'}}>
                {data && data.length === 0
                ? <div className=''>
                    <EmptyContainer message='No Data found'
                        className='d-flex align-items-center flex-column mt-5' />
                </div>
                : data.map((each, i) => {
                    return (
                        <div className='p-12px row mx-0' key={i}
                            style={{borderBottom: '1px solid #40434E'}}>
                            <div className='col-md-5 my-auto'>
                                <div>
                                    <p className='text-300 fs-14px fw-500'>{each.name}</p>
                                    <p className='text-truncate-2 fs-12px text-400'>{each?.description || '-'}</p>
                                </div>
                            </div>
                            <div className='col-md-4 my-auto d-flex align-items-center flex-wrap gap-2px'>
                                {(each.swagger && each.swagger.length) ? (
                                    <>
                                        {each.swagger.map((swagger, ind) => ind < 2 && (
                                            <div className='surface-850 px-10px py-4px br-4px w-fit'>
                                                <p className='fw-400 fs-11px text-400'>{swagger?.swaggerName}</p>
                                            </div>
                                        ))}
                                        {each.swagger.length > 2 && (
                                            <div className='surface-850 px-10px py-4px br-4px w-fit'>
                                                <p className='fw-400 fs-11px text-400'>+{each.swagger.length - 2}</p>
                                            </div>
                                        )}
                                    </>
                                ) : <p className='fw-400 fs-12px text-400 ps-1'>-</p>}
                            </div>
                            {/* <div className='col-md-2 my-auto'>
                                {(each.metadata && each.metadata.swagger) 
                                    ? <a href={`/api-references/${each.metadata.swagger.swaggerVersion}/${each.metadata.swagger.swaggerId}/${each.metadata.swagger.revision}?${URL_PARAMS.SRC}=${SRC_PARAM_VALUES.PACKAGE}&${URL_PARAMS.API_REFERENCE}=${filterParams}`}
                                        target='_blank' rel="noreferrer"><div className='dfaic surface-850 cursor jcc gap-6px px-6px py-4px br-4px w-fit'>
                                        <p className='fw-400 fs-12px text-400'>{each.metadata.swagger.resources.filter(o => o.selected)?.length || 0}</p>
                                        <ArrowSquareOut size={14} color='#E4E4E4'
                                            style={{marginBottom: 2}} />
                                    </div></a>
                                : <p className='fw-400 fs-12px text-400 ps-1'>-</p>}
                            </div> */}
                            <div className='d-none col-md-2 my-auto'>
                                <p className='text-300 fs-14px fw-500'>
                                    {formatCtsTime(each.updatedAt)} - {formatCtsDate(each.updatedAt)}
                                </p>
                            </div>
                            <div className='col-md-3 my-auto'>
                                <div className='dfaic justify-content-between w-100'>
                                    <div></div>
                                    {updateProduct ? (
                                        <div className="d-flex btn-md-850 h-32px gap-8px px-8px w-fit"
                                            onClick={() => handleEdit(each)}>
                                            <div style={{ marginTop: -3 }}>
                                                <PencilSimple size={14} />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            {data && data.length > 0 && pagination && Object.keys(pagination).length && (
                <Pagination
                    className="py-2"
                    pagination={{
                        pageSize: pagination?.limit || 10, 
                        offset: pagination.prev ? pagination.prev + 1 : 1,
                        total: pagination.total,
                    }}
                    cb={(val) => getData({ page: val })}
                />
            )}
        </div>
    )
}
