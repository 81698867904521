import { useTheme } from "../../context/ThemeContext";
import NavbarIcons from "../Icons/NavbarIcons";
import MyReactTooltip from "../ReactTooltip";

export const NavigationTab = (props) => {
  const { theme } = useTheme();

  const { tabPermission, activeTab } = props;

  const getSlug = (item) => {
    return `admin/${item.slug}`
  }

  if (tabPermission.slug === "THEME_TOGGLER") {
    if (theme === "theme--light") {
      return <NavbarIcons type={"moon"} />;
    }
    return <NavbarIcons type={"sun"} />;
  } else {
    return (
      <>
        <div
          className={`navItems-wrapper ${activeTab === getSlug(tabPermission) && "active"}`}
        >
          <NavbarIcons type={tabPermission.slug} />
          <span className="navItems-active"></span>
        </div>
        {!tabPermission.subModule || !tabPermission.subModule.length ? (
            <MyReactTooltip placement={"right"} id={props.id}>
              {tabPermission.id}
            </MyReactTooltip>
          ) : null}
      </>
    );
  }
};

/*
    case "HOME":
      return (
        <ToolTip align={"right"} name="Home">
          <div
            className={`navItems-wrapper ${activeTab === "HOME" && "active"}`}
          >
            <HouseLine width={28} height={24} className="navItems-icon" />
            <span className="navItems-active"></span>
          </div>
        </ToolTip>
      );

      case "PROFILE":
      if (theme === "light" && state === "active") {
        icon = ProfileIcon["light-active"];
      } else if (theme === "dark" && state === "active") {
        icon = ProfileIcon["dark-active"];
      } else if (theme === "light") {
        icon = ProfileIcon.light;
      } else if (theme === "dark") {
        icon = ProfileIcon.dark;
      }
      return (
        <ToolTip align={"right"} name="Profile">
          <img
            src={icon}
            {...props}
            style={{ width: "56px", height: "48px" }}
          />
        </ToolTip>
      );
*/
