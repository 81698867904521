import axios from "axios";

import APIS from "../service/config";
import { getCookie } from "../utils/cookie";
import paginationLimit from "../utils/pagination-limit";
const { API, GUIDE } = APIS;

let headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

if (getCookie("itorix")) {
    headers["authorization"] = `Bearer ${getCookie("itorix")}`;
}


let api = axios.create({
  baseURL: API,
  headers: headers,
});

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  initHeaders(token) {
    let header = api.defaults.headers;
    if (token) {
      header["authorization"] = `Bearer ${token}`;
    } else {
      if (header.hasOwnProperty("authorization")) {
        delete header["authorization"];
      }
    }
  },

  getGuides({currentPage, status, pageLimit, category, searchtext, deprecated}) {
    let limit = paginationLimit();
    if (pageLimit) {
      limit = pageLimit;
    }
    return new Promise((resolve, reject) => {
      let url = GUIDE.get + `?page=${currentPage}&limit=${limit}`;
      if (status) url += `&status=${status}`;
      if (category) url += `&category=${encodeURIComponent(category)}`;
      if (searchtext) url += `&searchtext=${encodeURIComponent(searchtext)}`;
      if (deprecated !== undefined) url += `&depreacted=${deprecated}`;
      api
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteGuide(guideId) {
    return new Promise((resolve, reject) => {
      api
        .delete(GUIDE.get + "/" + `${guideId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createGuide(payload) {
    return new Promise((resolve, reject) => {
      api
        .post(GUIDE.get, payload)
        .then((response) => {
          if (response.data.data.slug) {
            resolve(response.data.data.slug);
          } else reject(false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateGuide(payload, selectedGuideId) {
    return new Promise((resolve, reject) => {
      api
        .put(`${GUIDE.get}/${selectedGuideId}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createVersion(guideId, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`${GUIDE.get}/${guideId}${GUIDE.versions}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  cloneVersion(guideId, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`${GUIDE.get}/${guideId}${GUIDE.versions}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateVersion(guideId, versionId, payload) {
    return new Promise((resolve, reject) => {
      api
        .put(`${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteVersion(guideId, versionId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getGuideDetails(queryParams, status) {
    let url = `${GUIDE.get}/${queryParams.guideName}`;
    if (queryParams.hasOwnProperty("version")) {
      url = url + `/v/${queryParams.version}`;
    }
    let _headers = {}
    if(status) _headers['status'] = status;
    return new Promise((resolve, reject) => {
      api
        .get(url, {
          headers: {
            ..._headers 
          }
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createPage(guideId, versionId, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}${GUIDE.pages}`,
          payload
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePage(guideId, versionId, pageId, payload) {
    return new Promise((resolve, reject) => {
      api
        .put(
          `${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}${GUIDE.pages}/${pageId}`,
          payload
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deletePage(guideId, versionId, pageId) {
    return new Promise((resolve, reject) => {
      api
        .delete(
          `${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}${GUIDE.pages}/${pageId}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadImage(file) {
    let formData = new FormData();
    formData.append("file", file);
    return new Promise((resolve, reject) => {
      api
        .post(`${GUIDE.upload}`, formData)
        .then((response) => {
          let json = {};
          if (response.data) {
            const fileURL = response.data.data.url;
            delete response.data.data.url;
            json = {
              success: 1,
              file: {
                url: fileURL,
                ...response.data,
              },
            };
          }
          resolve(json);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDocumentContent(documentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`${GUIDE.get}${GUIDE.documents}/${documentId}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendFeedback(payload) {
    return new Promise((resolve, reject) => {
      api
        .post(GUIDE.feedback, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reorderPage(guideId, versionId, pageId, payload) {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/guides/${guideId}/versions/${versionId}/pages/${pageId}`,
          payload
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  defaultGuide() {
    return new Promise((resolve, reject) => {
      api
        .get(GUIDE.default)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
