import { useLocation, useOutletContext } from "react-router";
import { Post } from "../Post";
import { EmptyState } from "../../../../components/EmptyState";

export const PostListing = () => {
  const { allPosts, setShowEditModal, onDeleteSuccess, postsListingRef } =
    useOutletContext();
  const location = useLocation();
  return (
    <>
      {allPosts &&
        allPosts.length > 0 &&
        allPosts.map((post) => (
          <Post
            postData={post}
            setShowEditModal={setShowEditModal}
            onDeleteSuccess={onDeleteSuccess}
          />
        ))}
      {allPosts && allPosts.length === 0 && (
        <EmptyState
          style={{
            margin: "0 auto",
          }}
        />
      )}
      {location.pathname === "/forum" && (
        <div className="post-listing-load-more" ref={postsListingRef}></div>
      )}
    </>
  );
};
