import { Browsers } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setContents } from '../../../../app/Features/Layout/LayoutSlice'
import { getToast } from '../../../../components/Toast'
import { confirm, objectDeepClone } from '../../../../utils/helper'
import CustomCard2 from '../../../Dashboards/components/CustomCard2'
import AddScenarioBtn from '../../../../components/AddScenarioBtn'
import ImageCard from './ImageCard'

export default function RenderCard2({setIsEdit, sectionIndex}) {
    const {currentLayoutObject, backupCurrentLayout} = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const [isCtrl, setIsCtrl] = useState(false)

    useEffect(() => {
        if (currentLayoutObject.contents && currentLayoutObject.contents[sectionIndex]){
            setData(currentLayoutObject.contents[sectionIndex].payload || {})
        }
    }, [])

    const handleCancel = () => {
        if (!confirm("Are you sure? any new data might be lost in this section?")) return

        let _prevObj = objectDeepClone(backupCurrentLayout)
        setData(_prevObj.contents[sectionIndex].payload || {})
    }

    const handleSave = () => {
        let _data = objectDeepClone(data)
        let invalidValues = []

        _data.cards.forEach(each => {
            if (!each.title || !each.designation || !each.imgUrl || !each.quote){
                invalidValues.push(each)
            }
        })

        if (!_data.heading){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the required details'
            })
            return
        }

        _data.isEdit = false
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
        setData(objectDeepClone(_data))
    }

    const handleEdit = () => {
        let _data = objectDeepClone(data)
        _data.isEdit = true 
        setIsEdit(true)
        setData(objectDeepClone(_data))
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
    }

    const handleChange = (_id, type, val) => {
        let _data = objectDeepClone(data)
        _data.cards[_id][type] = val 
        setIsEdit(true)
        setData(objectDeepClone(_data))
    }

    const handleAddNewCard = () => {
        let _data = objectDeepClone(data)
        _data.cards.push({
            "imgUrl": "https://via.placeholder.com/143x143",
            "title": "John Doe",
            "designation": "Software Developer, XYZ",
            "quote": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonmummy nibh euismod tincidunt ut laoreet dolore magnatincidunt ut laoreet dolore magna.Lorem ipsumLorem ipsum dolor sit amet, consectetuer"
        })
        setIsEdit(true)
        setData(objectDeepClone(_data))
    }


    return (
        <div className='' onKeyDown={(e) => {
            setIsCtrl(['Control', 'Meta'].includes(e.key))
            if (isCtrl && e.key?.toLowerCase() === 's') {
                handleSave()
                e.preventDefault()
            }
        }}>
            <div className='surface-base p-16px overflow-scroll noscrollbar' style={{zoom: 0.4, height: 680}}>
                <CustomCard2 data={data} fromEditor />
            </div>

            <div className='surface-875 d-flex align-items-center justify-content-between p-12px mt-3'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Content</p>
                </div>
                {data.isEdit
                ? <div className='d-flex align-items-center'>
                    <p className='text-300 fs-12px fw-500 me-3 cursor'
                        onClick={handleCancel}>Cancel</p>

                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleSave}>
                        Save
                    </div>
                </div>
                : <div>
                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleEdit}>
                        Edit
                    </div>
                </div>}
            </div>

            <div className='surface-base br-4px p-16px w-100 h-70vh overflow-scroll noscrollbar'>
                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Tag Name</p>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.tagName}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, tagName: e.target.value})} />
                </div>

                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Hero Heading <span className='text-danger'>*</span></p>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.heading}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, heading: e.target.value})} />
                </div>

                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Slides Per View</p>
                    <div className='dfaic mt-1'>
                        <div className="form-check me-4">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="On Site" disabled={!data.isEdit}
                                checked={data.slidesToShow === 2} onClick={() => setData({...data, slidesToShow: 2})} />
                            <label className="form-check-label text-100 fs-14px fw-400" for="On Site">
                                2 Slides
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="Virtual" disabled={!data.isEdit}
                                checked={data.slidesToShow === 1} onClick={() => setData({...data, slidesToShow: 1})} />
                            <label className="form-check-label text-100 fs-14px fw-400" for="Virtual">
                                1 Slide
                            </label>
                        </div>
                    </div>
                </div>

                {data.cards?.map((eachCard, i) => (<div className='mb-3 surface-850 p-16px br-4px' key={i}>
                    <p className='fw-500 fs-14px text-200'>Card Detail {i+1}</p>
                    <div className='mt-2'>
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='fs-12px fw-400 text-300'>Name <span className='text-danger'>*</span></p>

                                        <p className='fs-12px fw-400 text-400'>{eachCard.title?.length || 0} / 20</p>
                                    </div>
                                    <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.title}
                                        onChange={(e) => {
                                            if (e.target.value?.length < 21){
                                                handleChange(i, 'title', e.target.value)
                                            }
                                        }} disabled={!data.isEdit} />
                                </div>
                                
                                <div className='mb-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='fs-12px fw-400 text-300'>Content <span className='text-danger'>*</span></p>

                                        <p className='fs-12px fw-400 text-400'>{eachCard.quote?.length || 0} / 270</p>
                                    </div>
                                    <textarea type={'text'} className='form-control v1-form-textarea mt-1' value={eachCard.quote}
                                        onChange={(e) => {
                                            if (e.target.value?.length < 271){
                                                handleChange(i, 'quote', e.target.value)
                                            }
                                        }} rows={5} disabled={!data.isEdit} />
                                </div>

                                <div className='mb-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='fs-12px fw-400 text-300'>Designation <span className='text-danger'>*</span></p>

                                        <p className='fs-12px fw-400 text-400'>{eachCard.designation?.length || 0} / 40</p>
                                    </div>
                                    <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.designation}
                                        onChange={(e) => {
                                            if (e.target.value?.length < 41){
                                                handleChange(i, 'designation', e.target.value)
                                            }
                                        }} disabled={!data.isEdit} />
                                </div>
                            </div>

                            <div className='col-md-6 my-auto'>
                                <ImageCard imgUrl={eachCard.imageUrl} name='imageLogo' 
                                    width={'100%'} isEdit={data.isEdit}
                                    setUrl={(url) => handleChange(i, 'imageUrl', url)} />
                            </div>
                        </div>

                    </div>
                </div>))}

                {data.isEdit && <AddScenarioBtn type={'New Card'}
                    onClick={() => handleAddNewCard()} className='w-100' />}
            </div>
        </div>
    )
}
