import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import GlobalIcons from "../Icons/GlobalIcons";

export default function TagNames({data,limit,modalTitle}){
    const [tags,setTags] = useState([])
    const [plusTags,setPlusTags] = useState([])
    const [extraModalShow,setExtraModalShow] = useState(false);

    function handleExtraModal(){
        setExtraModalShow((prev)=>!prev)
    }

    useEffect(()=>{
        transformData()
    }, [data])

    function transformData(){
        let temp = []
        let temp1 = []
        let new_limit = limit > data.length ? data.length : limit;
        for(let i=0; i<=new_limit-1; i++ ){
            temp.push(data[i])
        }
        setTags(temp)
        for(let i=new_limit; i<data.length;i++){
            temp1.push(data[i])    
        }
        setPlusTags(temp1)
    }

    return(
        <>
        <div className="d-flex flex-wrap">
            {
                tags.map((item)=>{
                    return <div className="surface-850 fs-12px text-400 fw-400 me-2 br-4 p-1-6 me-1 w-fit mb-2 p-4px px-1 br-4px ">
                        {item}
                    </div>
                })
            }
            {plusTags.length>0 && <div className="surface-850 fs-12px text-400 fw-400 me-2 br-4 p-1-6 p-4px px-1 w-fit mb-2 br-4px cursor-pointer" onClick={handleExtraModal}>+ {plusTags.length}</div>}
        </div>
        {
            extraModalShow && <Modal
            show={extraModalShow}
            onHide={handleExtraModal}
            size="md"
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <p className='mb-0 fw-500 text-50 fs-16px'>
                        {modalTitle}
                    </p>
                    <div className='cursor-pointer px-3' onClick={handleExtraModal}>
                        <GlobalIcons type={"cancel"}/>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='p-4 pt-2 overflow-scroll' style={{height: '280px'}}>
                    {data?.map((eachRow, i) => (
                        <div className='v1-border-bottom-1 py-3 ps-1' key={i}>
                            <div className='text-100 text-truncate fs-14px fw-400 surface-850 br-3 me-1 mb-1 w-fit p-1 px-2 br-3px' style={{maxWidth: '440px'}}>
                                {eachRow || ''}
                            </div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
        }
        </>
    )
}