import { Plus } from 'phosphor-react'
import React, { useState } from 'react'
import API from '../../../../api'
import Loading from '../../../../components/Loader/Loading'
import { throwServerError } from '../../../../utils/helper'
import defaultImage from './defaultImage.png'
import { getToast } from '../../../../components/Toast'
import { getUuidV4 } from '@apiwiz/oas/services'

export default function ImageCard({imgUrl, setUrl, name, width, fileSizeRestriction=10,
    isEdit = true, canBeDeleted = false, handleDelete}) {

    const [loading, setLoading] = useState(false)

    function fileUpload(e){
        setLoading(true)
        const file = e.target.files[0]

        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
            if (fileSizeInMB <= fileSizeRestriction) {
                API.uploadImage(file)
                .then(res => {
                    setUrl(res.file?.url || '')
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                }).catch(err => {
                    setLoading(false)
                    throwServerError(err)
                })
            } else {
              getToast({
                statusType: 'ERROR',
                message: `File size exceeds the limit: Upload an image with a maximum size of ${fileSizeRestriction}MB.`
              })
            }
          }
    }

    const __id = getUuidV4()

    return (
        <div className='v1-border-1 surface-base p-12px br-4px' id={`image-card-${name}${__id}`}>
            <img src={imgUrl || defaultImage} alt='' height={160} width={width ? width : 360} 
                style={{objectFit: 'contain'}} />

            {isEdit && <div className='d-flex align-items-center pt-12px'>
                <input type={'file'} id={`downloadAssets${name}${__id}`} className='d-none' 
                    onChange={(e) => fileUpload(e)}  accept="image/*" />
                { loading 
                ? <Loading size='sm' />
                : <label htmlFor={`downloadAssets${name}${__id}`}
                    className='cursor d-flex align-items-center justify-content-center surface-850 w-100 v1-border-05 px-16px py-12px br-4px'>
                    <Plus size={14} color='#e4e4e4' className='me-2' />
                    <p className='fw-500 fs-12px text-300'>Change Image</p>
                </label>}

                {canBeDeleted && <div className='cursor ms-2 d-flex align-items-center justify-content-center surface-850 w-100 v1-border-05 px-16px py-12px br-4px'
                    onClick={() => handleDelete()}>
                    <p className='fw-500 fs-12px text-300'>Remove Image</p>
                </div>}
            </div>}
        </div>
    )
}
