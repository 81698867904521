import htmlParser from "html-react-parser";

const Image = ({ data, style, config }) => {
  if (!data || !data.file || !data.file.url) return null;
  if (!style || typeof style !== "object") style = {};

  const classes = {
    stretched: data.stretched,
    withBackground: data.withBackground,
    withBorder: data.withBorder,
  };

  const classNames = (classes) => {
    return Object.entries(classes)
      .filter(([key, value]) => value)
      .map(([key, value]) => `output-image-tool--${key}`)
      .join(" ");
  };

  const imageClasses = classNames(classes);
  return (
    <div className="output-cdx-block ">
      <div className="output-image-tool">
        <div className={`${imageClasses} output-image-tool__image`}>
          <img
            className="output-image-tool__image-picture"
            src={data.file.url}
            alt={data.caption || ""}
          />
        </div>
        <div className="output-image-tool__caption-text">
          {htmlParser(data.caption)}
        </div>
      </div>
    </div>
  );
};

export default Image;
