import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { throwServerError } from '../../../../utils/helper';
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';

export default function InviteUserModal(props) {

    const [email,setEmail] = useState("");
    const [touched,setTouched] = useState(false);

    function handleOnSave(){
        setTouched(true)
        if(!email){
            return
        }

        //TODO


        // let _error = validateAllowedEmailDomains(email, setting.form.allowedDomains)
		// 	if(_error)
		// 	{
		// 		setEmailErrBottom(-12);
		// 		dispatch(handleManageUserFormError({...errors, email: _error}));
		// 		return;
		// 	}
        let form = {
            email:email
        }
		API.inviteUser(form)
				.then((response) => {
					// props.getListOfUser('',1,"active");
					props.onHide();
                    props.callback()
					getToast({statusType: 'SUCCESS', message: 'Email sent successfully'})
			})
			.catch((error) => throwServerError(error));
    }

    const [isCtrl, setIsCtrl] = useState(false)
    
    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 text-300 fw-500 fs-16px">
                            Invite User
                        </p>
                    </div>

                    <div className="d-flex align-items-center">
                        <div
                            onClick={props.onHide}
                            className="text-300 fw-400 fs-12px mr-14px cursor-pointer"
                        >
                            Cancel
                        </div>
                        <div
                            onClick={handleOnSave}
                            className="btn-md-v1 text-200 fw-400 fs-12px surface-850"
                        >
                            Save
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
               <Form className="d-flex flex-column w-100" onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleOnSave()
                        e.preventDefault()
                    }
                }}>
                    <div>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-2">
                                Email <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) =>
                                    setEmail(e.target.value)
                                }
                                className="v1-form-control"
                            />
                            {touched && !email  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Email is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}