import React, { useEffect, useState } from 'react'
import defaultImage from './verification-failed.png'

export default function InvalidToken() {
    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            <div className='loginContainer h-100'>
                <div className='mb-20px d-flex align-items-center justify-content-center'>
                    <div className='p-18px'>
                        <img src={defaultImage} height={200} width={300}
                            alt='Invalid token' />
                    </div>
                </div>

                <div className='mb-24px'>
                    <h3 className='titleText v2'>
                        Sorry! Invalid Token
                    </h3>
                    <p className='descText v2'>
                        Your token has been expired.
                    </p>
                </div>

                <a href='/resend-token' type='button' className='border-0 gradientBtnInAuth mb-16px'>
                    Request New Token
                </a>
            </div>
        </div>
    )
}
