import { BodyLayout } from "../../../../layout/Base";
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router'
import UserStatusCard from "../components/UserStatusCard";
import { Tab, Tabs } from "react-bootstrap";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import UserTable from "../components/UserTable";
import Searchbar from "../../../../components/Searchbar";
import API from "../../../../api";
import Loading from "../../../../components/Loader/Loading";
import { setCompanies, setPartners } from "../../../../app/Features/Users/UserSlice";
import { useSelector,useDispatch } from "react-redux";
import Pagination from "../../../../components/Pagination";
import InviteUserModal from "../components/InviteUserModal";
import { getToast } from "../../../../components/Toast";
import { resetAuthenticatedState,getAllUserRoles,getAllGroups } from "../../../../app/Features/Users/UserSlice";
import { confirm, throwServerError,getPermissionsArray, handleServerSideError, objectDeepClone, labelize, getPermissionByCrud } from "../../../../utils/helper";
import EditUserModal from "../components/EditUserModal";
import EmptyContainer from "../../../../components/EmptyContainer";
import { useSearchParams } from "react-router-dom";
import DeleteUsers from "../components/DeleteUsers";

export default function ManageUsers(){
    const [searchParams, setSearchParams] = useSearchParams();
    const isEmail = searchParams.get("email")

    const {setNavigation, setTitle, setHasHeader} = useOutletContext()

    const [activeTab, setActiveTab] = useState('active');
    const [searchString, setSearchString] = useState(isEmail ? isEmail : '');

    const [loading,setLoading] = useState(true);
    const [userListLoading,setUserListLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [data,setData] = useState([]);
    const [paging,setPaging] = useState({});
    const [searchData,setSearchData] = useState([]);
    const { partnersList: partners,currentUser,permissionData, companiesList } = useSelector((state) => state.user);
    const [analyticData,setAnalyticData] = useState('')
    const dispatch = useDispatch();
    const [showInviteUserModal,setInviteUserShow] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const [editModal, setEditModal] = useState({
        isOpen: false, data: {}
    })
    const {permission} = useSelector(state => state.user)
    const { firstRoute } = useSelector((state) => state.user);
    const [deleteModal, setDeleteModal] = useState({ isOpen: false })
    const navigate = useNavigate()

    useEffect(() => {
        setTitle("Users")
        setHasHeader(true)
        setNavigation([
            {name: 'Dashboard', link : firstRoute},
            {name: 'Users', link : '/users'},
        ])
        const userRole = currentUser?.role || currentUser?.userRole;
        if (userRole !== "super-admin") {
            const userPermissions = getPermissionsArray(
                permissionData,
                "user",
                userRole
            );
            setUserPermissions(userPermissions);
        }
       
        if(!partners.length) getPartnersList()
        if(!companiesList.length) fetchCompaniesList()
        getUserAnalytics()
    }, [])

    const handlePaginationChange = (offset) => {
        setPagination(offset)
        if(searchString && activeTab==="Part-4"){
            getListOfUser(searchString,offset);
        }else{
            getListOfUser('',offset,activeTab);
        }    
    };

    useEffect(()=>{
        if(searchString){
            setActiveTab('Part-4')
        }else{
            setPagination(1)
            setActiveTab('active')
        }
    },[searchString])

    useEffect(()=>{
        // if(!searchString){
        //     getListOfUser('', 1 , activeTab !== "Part-4" ? activeTab : '')
        // }else if(searchString && !(searchData.length)){
        //     getListOfUser('', 1 , activeTab)
        // }
        // else {
        //     setUserListLoading(false)
        // }
        if(!searchString){
            getListOfUser('', 1 , activeTab !== "Part-4" ? activeTab : '')
        }
        else if(searchString && activeTab=="Part-4"){
            getListOfUser(searchString, 1 , activeTab)

        }else{
            getListOfUser('', 1 , activeTab !== "Part-4" ? activeTab : '')
            // setUserListLoading(false)
        }
    },[activeTab])


    const getPartnersList = () => {
        // setIsLoading(true)
        API.listPartners()
        .then(res => {
            dispatch(setPartners(res.data.data));
            // setIsLoading(false);
        })
        .catch(err => {
            handleServerSideError(err)
        })
    }
    const fetchCompaniesList = () => {
        // setIsLoading(true)
        API.getCompaniesList()
        .then(res => {
            dispatch(setCompanies(res.data.data));
            // setIsLoading(false);
        })
        .catch(err => {
            handleServerSideError(err)
        })
    }

    const handleLogout = () => {
        // dispatch(resetAuthenticatedState())
        // if (getCookie('itorix-token')) removeCookie('itorix-token')
        // if (getCookie('itorix-token-data'))removeCookie('itorix-token-data')

        // if(localStorage.getItem("ssoLogin")){
		// 	const cutoffIndex = guestsetting?.authEndpointUrl?.indexOf("/token?")
		// 	const _url = guestsetting?.authEndpointUrl?.slice(0, cutoffIndex)
		// 	const origin = window.location.origin
		// 	localStorage.setItem("sso-logout-source", `${origin}/login`)
			
        //     navigate(`${_url}/logout`)
		// }

        navigate('/logout')
    }

    const getRoleData = () => {
		API.getAllRoles(1)
			.then((response) => {
				dispatch(getAllUserRoles(response.data));
			})
			.catch((error) => throwServerError(error));
	};

	const getGroupData = () => {
		API.getAllGroups(1)
			.then((response) => {
				dispatch(getAllGroups(response.data));
			})
			.catch((error) => throwServerError(error));
	};

    const getListOfUser = (searchStr,currentPage,status) => {
		setUserListLoading(true)
		API.getAllUser(currentPage,searchStr,status)
			.then((response) => {
				
				// dispatch(getAllUser(response.data));
                if(!searchStr){
                    setData(response.data.data)
                }else{
                    setSearchData(response.data.data)
                }
                getRoleData();
				getGroupData();
                setPaging(response.data.paging)
				setUserListLoading(false)
			})
			.catch((error) => {
				setUserListLoading(false)
				// handleServerSideError(error);
			});
	};

    function searchOnChange(val){
        setSearchString(val)
        let timer = null
		timer = setTimeout(() => {
			clearTimeout(timer)
            if(val){
			  getListOfUser(val,1)
            }
		},300)
    }

    function getUserAnalytics(){
        setLoading(true);
        API.getUserAnalytics()
        .then((response) => {
            setAnalyticData(response.data.data)
            setLoading(false)
        })
        .catch((error) => {
            // handleServerSideError(error);
            setLoading(false)
        });
    }

    function getPercent(current,previous){
        if (previous === 0) return current * 100
        if (current === 0 ) return -100
        return parseInt(((current-previous)/previous) * 100)
    }

    function handleInviteUserModal(){
        setInviteUserShow((showInviteUserModal)=>!showInviteUserModal)
    }

    function handleEditUserModal(index){
        // console.log('first', first)
        if (activeTab === 'Part-4'){
            setEditModal({
                isOpen: true, data: objectDeepClone(searchData[index])
            })
        } else {
            setEditModal({
                isOpen: true, data: objectDeepClone(data[index])
            })
        }
    }

    function handleDeleteUser(index){
        let msg = "Are you sure you want to delete this user";

		if (confirm(msg)) {
            let curr = activeTab === 'Part-4' ? searchData[index] : data[index] 
			API.deleteUser(curr._id)
				.then((response) => {
					getListOfUser(searchString,pagination,activeTab);
					getToast({statusType: 'SUCCESS', message: 'User deleted successfully'})
					if (curr._id === currentUser._id) {
						handleLogout();
					}
				})
			.catch((error) => throwServerError(error));
		}
    }

    const handleResetPassword = (event, index) => {
		const user = activeTab === 'Part-4' ? searchData[index] : data[index]
		let obj = { email: user.email };

		event.preventDefault();

		API.forgotPassword(obj)
			.then((res) => {
				getToast({statusType: 'SUCCESS', message: 'Please check email...'});
			})
			.catch((error) => {
				if (error.response) {
					if (Object.keys(error.response.data.errors).length > 0) {
					} else {
						throwServerError(error);
					}
				} else {
					if (error.message) {
						throwServerError(error.message);
					}
				}
			});
	};

    const handleLockUser = (event, index, status) => {
		const message = `Are you sure you want to ${
			status === "locked" ? "lock" : "activate"
		} this user?`;
        console.log('activeTab', activeTab)

		if (confirm(message)) {
            let curr = activeTab === 'Part-4' ? searchData[index] : data[index] 
			API.updateStatus({ id: curr._id, status: status })
				.then((response) => {
					// setShowPrompt(false);
					if (currentUser._id === curr._id) {
						handleLogout()
					} else {
						getListOfUser(searchString,pagination,activeTab);
                        getToast({statusType: 'SUCCESS', message: `User successfully ${status}`})
					}
				})
				.catch((error) => {
                    //check message
					throwServerError(error)
				});
		}
	};

    let _permission = permission.filter(o => o.name === labelize('manage'))
    _permission = _permission.length ? (_permission[0].crud || []) : []

    const showCreateButton = getPermissionByCrud(currentUser, _permission,"create");
	const showEdit = getPermissionByCrud(currentUser, _permission,"update");
	const showDelete = getPermissionByCrud(currentUser, _permission,"delete");
	const showList = getPermissionByCrud(currentUser, _permission,"list");

    function handleSearchClose(){
        setSearchString('')
        setUserListLoading(true)
    }

    if (!showList) return
    
    return (
        <BodyLayout>
            <div className="fadeIn h-100 w-100">
                {loading ? <Loading/> : <>
                <div className="topPanel">
                    <div className="d-flex justify-content-between gap-16px">
                        <UserStatusCard title={"SignUps"} count={analyticData?.signUps?.currentMonth} percent={getPercent(analyticData?.signUps?.currentMonth,analyticData?.signUps?.previousMonth)} isMonthCompare={true} positive={analyticData?.signUps?.currentMonth>analyticData?.signUps?.previousMonth}/>
                        <UserStatusCard title={"Active Users"} count={analyticData?.activeUsers?.currentMonth} isMonthCompare={true} percent={getPercent(analyticData?.activeUsers?.currentMonth,analyticData?.activeUsers?.previousMonth)} positive={analyticData?.activeUsers?.currentMonth>analyticData?.activeUsers?.previousMonth}/>
                        <UserStatusCard title={"Inactive Users"} count={analyticData?.inactiveUsers?.currentMonth} percent={10} positive={analyticData?.inactiveUsers?.currentMonth>analyticData?.inactiveUsers?.previousMonth}/>
                    </div>

                </div>
                <div className="bottomPanel mt-10px" style={{height: `calc(100% - 235px)`}}>

                    <div className="surface-875 py-8px px-3 d-flex justify-content-between align-items-center v1-border-bottom-05">
                        <div>
                            <p className="fs-18px fw-600">Manage User</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="mr-13px w-21vw">
                                <Searchbar value={searchString} onChange={searchOnChange} placeholder={"Search by full name or email"} />
                            </div>
                            {showCreateButton && <div className='btn v1-btn-primary p-2' onClick={handleInviteUserModal}>
                                <GlobalIcons type={'plus'} />
                                <p className='text-200 ps-2 fs-14px'>Invite User</p>
                            </div>}
                            {showDelete && <div className="ms-2 v1-border-1 surface-850 pr-20px py-10px btn d-flex align-items-center" onClick={() => setDeleteModal({ isOpen: true })}>
                                <GlobalIcons type={"trash"} />
                                <p className="text-300 fw-500 fs-12px ms-2">Delete Users</p>
                            </div>}
                        </div>
                    </div>
                    { <div className="surface-875 userTableWrapper" style={{height: `calc(100% - 60px)`}}>
                        <Tabs activeKey={activeTab} id="resources-tab" className=""
                            onSelect={(key) => {if(key!==activeTab)setUserListLoading(true); setActiveTab(key) }}>
                                <Tab tabClassName="tabs-btn-2"  className="h-100"
                                    eventKey={'active'} title={`Approved ${activeTab==="active" && !userListLoading ?'('+paging.total+')':''}`}>
                                    {userListLoading?<div className="h-100 d-flex justify-content-center align-items-center"><Loading/></div>: data.length>0?<UserTable showEdit={showEdit} showDelete={showDelete} data={data} partners={partners} handleDeleteUser={handleDeleteUser} handleResetPassword={handleResetPassword} handleLockUser={handleLockUser} handleEditUserModal={handleEditUserModal}/>:<div className="h-41vh d-flex justify-content-center align-items-center"><EmptyContainer/></div>}
                                </Tab>
                                <Tab tabClassName="tabs-btn-2" className="h-100"
                                    eventKey={'pending-activation'} title={`Pending Activation ${activeTab==="pending-activation" && !userListLoading?'('+paging.total+')':''}`}>
                                    {userListLoading?<div className="h-100 d-flex justify-content-center align-items-center"><Loading/></div>:data.length>0?<UserTable showEdit={showEdit} showDelete={showDelete} data={data} partners={partners} handleDeleteUser={handleDeleteUser} handleResetPassword={handleResetPassword} handleLockUser={handleLockUser}  handleEditUserModal={handleEditUserModal}/>:<div className="h-41vh d-flex justify-content-center align-items-center"><EmptyContainer/></div>}
                                </Tab>
                                <Tab tabClassName="tabs-btn-2" className="h-100"

                                    eventKey={'pending-verification'} title={`Pending Verification ${activeTab==="pending-verification"  && !userListLoading?'('+data.length+')':''}`}>

                                    {userListLoading?<div className="h-100 d-flex justify-content-center align-items-center"><Loading/></div>:data.length>0?<UserTable showEdit={showEdit} showDelete={showDelete} data={data} partners={partners} handleDeleteUser={handleDeleteUser} handleResetPassword={handleResetPassword} handleLockUser={handleLockUser}  handleEditUserModal={handleEditUserModal}/>:<div className="h-41vh d-flex justify-content-center align-items-center"><EmptyContainer/></div>}
                                </Tab>
                                <Tab tabClassName="tabs-btn-2" className="h-100"
                                    eventKey={'locked'} title={`Locked ${activeTab === "locked"  && !userListLoading? '('+paging.total+')' : ''}`}>
                                    {userListLoading?<div className="h-100 d-flex justify-content-center align-items-center"><Loading/></div>:data.length > 0 ? <UserTable showEdit={showEdit} showDelete={showDelete} data={data} partners={partners} handleDeleteUser={handleDeleteUser} 
                                        handleResetPassword={handleResetPassword} handleLockUser={handleLockUser} 
                                        handleEditUserModal={handleEditUserModal}/>
                                    : <div className="h-41vh d-flex justify-content-center align-items-center">
                                        <EmptyContainer/>
                                    </div>}
                                </Tab>
                                {searchString ?
                                        <Tab tabClassName="tabs-btn-2" 
                                        eventKey={'Part-4'} title={
                                        <div className="d-flex justify-content-between"><p className="ps-2">{`Search Results (${searchData.length})`}</p><div className="cursor-pointer pe-2" onClick={handleSearchClose}><GlobalIcons type="cross"/></div></div>
                                        }>
                                        {searchData.length>0?<UserTable showEdit={showEdit} showDelete={showDelete} data={searchData} 
                                            partners={partners} handleDeleteUser={handleDeleteUser} 
                                            handleResetPassword={handleResetPassword} 
                                            handleLockUser={handleLockUser}  
                                            handleEditUserModal={handleEditUserModal}/>
                                        : <div className="h-41vh d-flex justify-content-center align-items-center">
                                            <EmptyContainer/>
                                        </div>}
                                        </Tab>:
                                    <></>
                                }
                        </Tabs>
                        {data.length>0?<Pagination className='py-2' pagination={{
                            pageSize: 10,
                            offset: pagination,
                            total: paging.total,
                            }} cb={(val) => handlePaginationChange(val)} />:<></>}
                    </div>}
                </div>
                </>}
            </div>
            {
                showInviteUserModal && <InviteUserModal
                    show={showInviteUserModal}
                    onHide={handleInviteUserModal}
                    getListOfUser={getListOfUser}
                    callback={() => setActiveTab("pending-verification")}
                />

            }
            {
                editModal.isOpen && <EditUserModal
                    getListOfUser={() => {
                        getListOfUser('',1,"active");
                        setSearchString('')
                        setSearchData([])
                        setActiveTab('active')
                    }}
                    show={editModal.isOpen}
                    onHide={() => setEditModal({isOpen: false, data: {}})}
                    user={editModal.data}
                />
            }

            {
                deleteModal.isOpen && <DeleteUsers show={deleteModal.isOpen}
                    onHide={() => setDeleteModal({ isOpen: false })}
                    parentCallback={getUserAnalytics}
                />
            }
        </BodyLayout>
    )

}