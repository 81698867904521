import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { isElTruncated } from "../../utils/helper";

const MyReactTooltip = (props) => {
  const [hide, setHide] = useState(false);
  let _style = {}
  if(props.style) _style=props.style

  let cls = props.className || ''
  let offset = props.offset || {}

  if(props.arrowTop && props.offsetTop && 
    props.placement && props.placement.includes('top-left'))
  {
    cls += ` place-top-${props.arrowTop}`;
    offset = {...offset, top: props.offsetTop}
  }

  useEffect(() => {
    if(props.checkTruncate && props.elRef)
    {
      if(props.elRef.current && !isElTruncated(props.elRef.current)) setHide(true);
    }
  }, [props.elRef?.current])

  if(hide) return null;

  
  return (
    <ReactTooltip id={props.id} effect="solid" place={props.placement || 'bottom'} 
      textColor={"white"} style={{..._style}} offset={offset} className={cls}>
        {props.children}
    </ReactTooltip>
  );
};

export default MyReactTooltip;
