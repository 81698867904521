import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "./context/ThemeContext";
import { BrowserRouter as Router } from "react-router-dom";
import { AutoLogout } from './components/AutoLogout';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <Router>
        <AutoLogout />
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);

