import React from "react";
import { Dropdown } from "react-bootstrap";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="h-100"
  >
    {children}
  </div>
));

const BootstrapDropdown = (props) => {
  let options = props.options || [];

  return (
    <Dropdown align={props.align || "start"} className={props.className || ""} 
      autoClose={props.autoClose}>
      <Dropdown.Toggle as={CustomToggle}>{props.children}</Dropdown.Toggle>
      <Dropdown.Menu 
        className={`${props.menuClassName || ''} ${
          props.showCaretTop ? "show-caret-top" : ""
        } my-bootstrap-dropdown-menu`}
      >
        {options.map((el) => {
          return (
            <Dropdown.Item 
              onClick={el.onSelect || null}
              className={`my-bootstrap-dropdown-item d-flex align-items-center ${(props.value && props.value === el.value) ? "active" : ""} ${props.defaultClass}`}
              href="javascript:void(0)"
            >
              {el.icon || null}
              <div className={props.isWrap ? 'wrapDropdownOptions text-truncate' : ''}>{el.label}</div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BootstrapDropdown;
