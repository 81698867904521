import React from 'react'
import RectangleGradient from './gradient-mask.png'

export default function CustomPrimaryTab({data, readOnly=false}) {
    console.log('data', data)
    return (
        <div className='br-8px m-0 primaryTabSection overflow-hidden w-100 d-flex align-items-end position-relative'>
            <div className='w-60 p-32px'
                style={{position: 'relative', zIndex: 3}}>
                <p className='fs-32px fw-500 text-clip-gradient'>{data.heading || ''}</p>

                <p className='fs-14px fw-500 text-clip-gradient mt-12px'>{data.description || ''}</p>

                {data.primaryCTA?.label?.length > 0 && (readOnly
                ? <div className='mt-24px br-8px w-fit py-10px px-18px bg-white cursor'
                    style={{boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06)'}}>
                    <p className='fs-16px text-primary-color fw-600'>
                        {data.primaryCTA?.label || ''}
                    </p>
                </div>
                : <a target='_blank' href={data.primaryCTA?.url || ''}>
                    <div className='mt-24px br-8px w-fit py-10px px-18px bg-white cursor'
                        style={{boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06)'}}>
                        <p className='fs-16px text-primary-color fw-600'>
                            {data.primaryCTA?.label || ''}
                        </p>
                    </div>
                </a>)}
            </div>
            <div className='w-40' style={{zIndex: 3}}>
                <img src={data.imageUrl} alt='packageImage' style={{right: 20, bottom: 0, maxWidth: '45%'}}
                    className='h-84 position-absolute' />
            </div>

            <div>
                <img src={RectangleGradient} style={{right: -10, bottom: 0}}
                    className='w-55 h-100 position-absolute' alt='' />
            </div>
        </div>
    )
}
