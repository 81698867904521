import React from "react";
import { useEffect, useState, useRef } from "react";
import { Chart, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement);

const createRadialGradient3 = (ctx, area, color1, color2) => {
  const centerX = (area.left + area.right) / 2;
  const centerY = (area.top + area.bottom) / 2;

  const r = Math.min(
    (area.right - area.left) / 2,
    (area.bottom - area.top) / 2
  );

  const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
  gradient.addColorStop(0.5, color2);
  gradient.addColorStop(1, color1);

  return gradient;
}

const getTotal = (data) => {
  const total = data.reduce((acc, curr) => acc + (curr.value), 0);
  return total;
}

const updateChartData = (data) => {
  const total = getTotal(data);
  return {
    maintainAspectRatio: false,
    responsive: false,
    datasets: [
      {
        label: data.map(x => x.name),
        actualData: data.map(x => x.value),
        data: data.map(x => {
          let percent = (x.value / total) * 100;
          if (percent === 0) return 0;
          else if (percent < 5) return 5;
          return percent;
        }),
        backgroundColor: function (context) {
          let c = data[context.dataIndex]?.color;
          if (!c) {
            return;
          }

          if (typeof c === 'string') return c;
          let stepColors = [];
          if (c.start) stepColors.push(c.start);
          if (c.middle) stepColors.push(c.middle);
          if (c.end) stepColors.push(c.end);
          if (stepColors.length === 0){
            stepColors.push("grey")
            stepColors.push("white")
          }

          return createRadialGradient3(context.chart.ctx, context.chart.chartArea, ...stepColors);
        },
      },
    ]
  }
}

const CountDoughnutChart = (props) => {
  const [data, setData] = useState(null);
  const chartRef = useRef(null);

  /*
  const plugins = [{
    beforeDraw: function (chart) {
      if(chart.options.centerText.display)
      {
        var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
        ctx.restore();
        let fontSize = 12;
        ctx.font = `${fontSize}px`;
        ctx.textBaseline = "top";
        ctx.textColor = '#fff'
        var text = chart.options.centerText.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height / 2) - fontSize;
        ctx.fillStyle = '#fff';
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  }]
  */

  const options = {
    plugins: {
      tooltip: {
        enabled: false
    },
    cutout: props.cutout || 80,
    responsive: true,
    maintainAspectRatio: true,
    borderWidth: 0
    /*
    centerText: {
      display: props.isCatalog,
      text: getTotal(props.series)
    }
    */
  }
}


  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    //setData(chartData);
  }, []);

  useEffect(() => {
    setData(updateChartData(props.series));
  }, [props.updateChart])

  return (
    <div className={`h-100 position-relative ${props.className || ''}`}>
      <div className={`position-absolute w-100 d-flex justify-content-center align-items-center`} style={{ height: `120%` }}>
          <div className={`d-flex justify-content-center align-items-center fs-10px`}>{getTotal(props.series)}</div>
        </div>
      {data && <Doughnut className="h-100 position-absolute"
        ref={chartRef} data={data} options={options}></Doughnut>}
    </div>
  );
};

export default CountDoughnutChart;
