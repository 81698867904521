import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "../../../../api";
import { setSetting } from "../../../../app/Features/Settings/SettingSlice";
import {
  encryptPassword,
  objectDeepClone,
  throwServerError,
} from "../../../../utils/helper";
import { ArrowsClockwise } from "phosphor-react";
import MyReactTooltip from "../../../../components/ReactTooltip";
import Select from "react-select";
import {
  setGroups,
  setRoles,
} from "../../../../app/Features/Layout/LayoutSlice";
import Creatable from "react-select/creatable";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import { getToast } from "../../../../components/Toast";
import Loading from "../../../../components/Loader/Loading";

export default function AccountConfiguration(props) {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({});
  const [roles, setRoleOptions] = useState([]);
  const [groups, setGroupOptions] = useState([]);
  const [partnerOptions, setPartnersOptions] = useState([]);
  const [backupData, setBackupData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const loginMethod = [
    { value: "native", label: "Native" },
    { value: "apiwiz", label: "Apiwiz" },
    { value: "other", label: "Other" },
  ];

  const activationType = [
    { value: "auto", label: "auto" },
    { value: "manual", label: "manual" },
  ];

  useEffect(() => {
    getData();
    getRoles();
  }, []);

  const getData = () => {
    setIsEdit(false);
    setIsLoading(true);
    API.getSettingsData()
      .then((res) => {
        let data = objectDeepClone(res.data.data);
        setIsLoading(false);
        setData(data);
        setBackupData(objectDeepClone(data));
        dispatch(setSetting(data));
        if(data.jsessionId) getPartners();
      })
      .catch((err) => {
        setIsLoading(false);
        throwServerError(err);
      });
  };

  const handleCancel = () => {
    setData(objectDeepClone(backupData));
    setIsEdit(false);
    setNewPassword("");
  };

  const getPartners = () => {
    API.getPartnerGroups(1)
      .then((res) => {
        let _data = objectDeepClone(res.data.data || []);
        let _groupOptions = _data.map((o) => ({
          label: o.partnerName,
          value: o.partnerName,
        }));
        setGroupOptions(objectDeepClone(_groupOptions));
        setPartnersOptions(_data.reduce((acc, curr) => (acc[curr.id]={...curr, label: curr.partnerDisplayName, value: curr.id}, acc), {}))
        // dispatch(setGroups(_data || []))
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const getRoles = () => {
    API.getAllRoles()
      .then((res) => {
        let _data = objectDeepClone(res.data.data || []);
        _data = _data.map((o) => ({ label: o.code, value: o.code }));
        setRoleOptions(objectDeepClone(_data));
        dispatch(setRoles(_data || []));
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const Labelize = (_keyName) => {
    switch (_keyName) {
      case "activationType":
        return "Activation Type";
      case "activityLogExpiry":
        return "Activity Log Expiry";
      case "apiKey":
        return "API Key";
      case "authEndpointUrl":
        return "Auth Endpoint Url";
      case "defaultUserGroups":
        return "Default user groups";
      case "defaultUserRole":
        return "Default user role";
      case "idleTimeout":
        return "Idle Timeout";
      case "itorixapplicationEndpoint":
        return "Apiwiz Application Endpoint";
      case "jsessionId":
        return "Jsession Id";
      case "loginMethod":
        return "Login method";
      case "maxAttempts":
        return "Maximum Attempts input field";
      case "publicKey":
        return "Public key";
      case "publicUserRole":
        return "Public User Role";
      case "username":
        return "Username";
      case "workspaceId":
        return "Workspace Id";

      default:
        break;
    }
  };

  const handleSave = () => {
    /*
    if (newPassword && newPassword.length < 8) {
      getToast({
        statusType: "ERROR",
        message: "Password should be minimum of 8 characters",
      });
      return;
    }
    const regex =
      /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g;
    if (newPassword && !regex.test(newPassword)) {
      getToast({
        statusType: "ERROR",
        message:
          "Password must include uppercase, lowercase, numbers & special characters(!, @, &, %, +)",
      });
      return;
    }
    */

    let isValid = true;
    let notRequiredFields = ['jsessionId', 'openApiPartner', 'allowedDomains', 'password']

    for(let each in data)
    {
      if(notRequiredFields.includes(each)) continue;
      if (!isValid) return;
      let eachValue = data[each];

      if (each === "loginMethod" && !eachValue.length) {
        getToast({
          statusType: "ERROR",
          message: `${Labelize(each)} is required`,
        });
        isValid = false;
      } else if (each === "defaultUserGroups" && !eachValue.length) {
        getToast({
          statusType: "ERROR",
          message: `${Labelize(each)} is required`,
        });
        isValid = false;
      } else if (
        typeof eachValue === "string" &&
        eachValue.trim().length === 0
      ) {
        getToast({
          statusType: "ERROR",
          message: `${Labelize(each)} is required`,
        });
        isValid = false;
      }
    }

    if (!isValid) return;
    let json = {};
    if (newPassword) json["password"] = encryptPassword(newPassword);
    else json["password"] = "";

    json["activationType"] = data.activationType;
    json["allowedDomains"] = data.allowedDomains;
    json["openApiPartner"] = data.openApiPartner;
    json["apiKey"] = data.apiKey;
    json["authEndpointUrl"] = data.authEndpointUrl;
    json["defaultUserGroups"] = data.defaultUserGroups;
    json["defaultUserRole"] = data.defaultUserRole;
    json["disableLogin"] = data.disableLogin;
    json["disableSignup"] = data.disableSignup;
    json["forumUrl"] = data.forumUrl;
    json["idleTimeout"] = data.idleTimeout;
    json["itorixapplicationEndpoint"] = data.itorixapplicationEndpoint;
    json["jsessionId"] = data.jsessionId;
    json["loginMethod"] = data.loginMethod;
    json["maxAttempts"] = data.maxAttempts;
    json["mfaEnabled"] = data.mfaEnabled;
    json["publicKey"] = data.publicKey;
    json["publicUserRole"] = data.publicUserRole;
    json["username"] = data.username;
    json["workspaceId"] = data.workspaceId;

    API.updateSetting(json)
      .then((res) => {
        getData();
        getToast({
          statusType: "SUCCESS",
          message: "Account Configuration updated successfuly",
        });
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const [isCtrl, setIsCtrl] = useState(false);

  const refreshJsessionId = () => {
    API.refreshSession()
      .then((res) => {
        getToast({
          statusType: "SUCCESS",
          message: "JsessionId Refreshed successfully",
        });
        getData();
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  return (
    <div className="fadeIn h-100">
      <div className="px-16px py-9px surface-875 d-flex align-items-center justify-content-between br-tl-10px br-tr-10px v1-border-bottom-1">
        <p className="text-100 fs-18px fw-600">Account Configuration</p>

        {isEdit ? (
          <div className="d-flex align-items-center">
            <p
              className="fw-500 fs-12px text-300 cursor"
              onClick={handleCancel}
            >
              {" "}
              Cancel
            </p>

            <div
              className="px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn ms-3"
              onClick={handleSave}
            >
              <p className="fs-12px fw-500 text-300">Save</p>
            </div>
          </div>
        ) : (
          <div
            className="px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn"
            onClick={() => setIsEdit(true)}
          >
            <p className="fs-12px fw-500 text-300">Edit</p>
          </div>
        )}
      </div>

      <div className="w-70 h-91 mx-auto overflow-scroll noscrollbar px-14px py-32px">
        {isLoading ? (
          <Loading />
        ) : (
          <div
            onKeyDown={(e) => {
              setIsCtrl(["Control", "Meta"].includes(e.key));
              if (isCtrl && e.key?.toLowerCase() === "s") {
                handleSave();
                e.preventDefault();
              }
            }}
          >
            <p className="text-200 fw-600 fs-16px">Portal Settings</p>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Apiwiz Application Endpoint:{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="v1-form-control form-control mt-1"
                    value={data.itorixapplicationEndpoint}
                    onChange={(e) =>
                      setData({
                        ...data,
                        itorixapplicationEndpoint: e.target.value,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Apiwiz Application Endpoint :
                    </p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.itorixapplicationEndpoint || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    JSession ID:
                  </p>
                  <div className="mt-1 w-100 d-flex align-items-center justify-content-between">
                    <input
                      type="text"
                      className="v1-form-control form-control w-90"
                      value={data.jsessionId}
                      onChange={(e) => {
                        setData({...data, jsessionId: e.target.value})
                      }}
                    />
                    <div
                      className="v1-border-1 br-4px surface-850 px-11px py-7px w-fit cursor"
                      data-tip="Refresh JSessionId"
                      data-for="jsessionId"
                      onClick={() => refreshJsessionId()}
                    >
                      <ArrowsClockwise size={14} color="#e4e4e4" />
                    </div>
                    <MyReactTooltip id="jsessionId" />
                  </div>
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">JSession ID :</p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.jsessionId || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Forum URL: <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="v1-form-control form-control mt-1"
                    value={"/forum"}
                    disabled
                    onChange={(e) =>
                      setData({ ...data, forumUrl: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Forum URL :</p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.forumUrl || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Other Auth Endpoint URL:{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="v1-form-control form-control mt-1"
                    value={data.authEndpointUrl}
                    onChange={(e) =>
                      setData({ ...data, authEndpointUrl: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Other Auth Endpoint URL :
                    </p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.authEndpointUrl || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Login Methods: <span className="text-danger">*</span>
                  </p>
                  <Select
                    className="v1select mt-1"
                    options={loginMethod}
                    isMulti
                    onChange={(e) => {
                      setData({ ...data, loginMethod: e.map((o) => o.value) });
                    }}
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    value={data.loginMethod?.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Login Methods :</p>
                  </div>
                  <div className="w-65 d-flex align-items-center gap-6px">
                    {data.loginMethod?.map((each) => (
                      <div
                        className="surface-850 px-12px py-3px w-fit"
                        style={{ borderRadius: 200 }}
                        key={each}
                      >
                        <p className="text-200 fs-14px fw-500">{each || ""}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Workspace ID: <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="v1-form-control form-control mt-1"
                    value={data.workspaceId}
                    onChange={(e) =>
                      setData({ ...data, workspaceId: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Workspace ID :</p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.workspaceId || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">Allowed Domains: </p>
                  <Creatable
                    className="v1select mt-1"
                    options={data.allowedDomains?.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                    isMulti
                    onChange={(e) => {
                      setData({
                        ...data,
                        allowedDomains: e.map((o) => o.value),
                      });
                    }}
                    value={data.allowedDomains?.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Allowed Domains :</p>
                  </div>
                  <div className="w-65 d-flex align-items-center gap-6px">
                    {data.allowedDomains?.length > 0 ? (
                      data.allowedDomains?.map((each) => (
                        <div
                          className="surface-850 px-12px py-3px w-fit"
                          style={{ borderRadius: 200 }}
                          key={each}
                        >
                          <p className="text-200 fs-14px fw-500">
                            {each || ""}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className="text-300 fs-13px">No Domains configured</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">Open API Partner: </p>
                  <Select
                    className="v1select mt-1"
                    options={Object.values(partnerOptions)}
                    onChange={(selected) => {
                      setData({ ...data, openApiPartner: selected.value });
                    }}
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    value={partnerOptions[data.openApiPartner] || null}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Open API Partner :
                    </p>
                  </div>
                  <div className="w-65 d-flex align-items-center gap-6px">
                    <p className="fw-400 fs-13px text-200">
                      {partnerOptions[data.openApiPartner] ? partnerOptions[data.openApiPartner].label : "No Partner Configured"}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <p className="text-200 fw-600 fs-16px mt-5">User Settings</p>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Default Login Username:{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="v1-form-control form-control mt-1"
                    value={data.username}
                    onChange={(e) =>
                      setData({ ...data, username: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Default Login Username :
                    </p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.username || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {isEdit && (
              <div className="mt-3">
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Login Password (Enter to Change):{" "}
                    {newPassword && <span className="text-danger">*</span>}
                  </p>
                  <input
                    type="password"
                    className="v1-form-control form-control mt-1"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Default User Group for new users:{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <Select
                    className="v1select mt-1"
                    options={groups}
                    isMulti
                    onChange={(e) => {
                      setData({
                        ...data,
                        defaultUserGroups: e.map((o) => o.value),
                      });
                    }}
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    value={data.defaultUserGroups?.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Default User Group for new users :
                    </p>
                  </div>
                  <div className="w-65 d-flex align-items-center gap-6px">
                    {data.defaultUserGroups?.map((each) => (
                      <div
                        className="surface-850 px-12px py-3px w-fit"
                        style={{ borderRadius: 200 }}
                        key={each}
                      >
                        <p className="text-200 fs-14px fw-500">{each || ""}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Default User Role: <span className="text-danger">*</span>
                  </p>
                  <Select
                    className="v1select mt-1"
                    options={roles}
                    onChange={(e) => {
                      setData({ ...data, defaultUserRole: e.value });
                    }}
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    value={{
                      label: data.defaultUserRole,
                      value: data.defaultUserRole,
                    }}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Default User Role :
                    </p>
                  </div>
                  <div className="w-65 d-flex align-items-center gap-6px">
                    <div
                      className="surface-850 px-12px py-3px w-fit"
                      style={{ borderRadius: 200 }}
                    >
                      <p className="text-200 fs-14px fw-500 text-capitalize">
                        {data.defaultUserRole || ""}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Activation Type: <span className="text-danger">*</span>
                  </p>
                  <Select
                    className="v1select mt-1"
                    options={activationType}
                    onChange={(e) => {
                      setData({ ...data, activationType: e.value });
                    }}
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    value={{
                      label: data.activationType,
                      value: data.activationType,
                    }}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Activation Type :</p>
                  </div>
                  <div className="w-65 d-flex align-items-center gap-6px">
                    <div
                      className="surface-850 px-12px py-3px w-fit"
                      style={{ borderRadius: 200 }}
                    >
                      <p className="text-200 fs-14px fw-500 text-capitalize">
                        {data.activationType || ""}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Public User Role: <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="v1-form-control form-control mt-1"
                    value={data.publicUserRole}
                    onChange={(e) =>
                      setData({ ...data, publicUserRole: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Public User Role :
                    </p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.publicUserRole || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <p className="text-200 fw-600 fs-16px mt-5">Other Settings</p>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Public Key: <span className="text-danger">*</span>
                  </p>
                  <textarea
                    type="text"
                    className="v1-form-textarea form-control mt-1"
                    value={data.publicKey}
                    onChange={(e) =>
                      setData({ ...data, publicKey: e.target.value })
                    }
                    rows={4}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Public Key :</p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.publicKey || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    API Key: <span className="text-danger">*</span>
                  </p>
                  <textarea
                    type="text"
                    className="v1-form-textarea form-control mt-1"
                    value={data.apiKey}
                    onChange={(e) =>
                      setData({ ...data, apiKey: e.target.value })
                    }
                    rows={3}
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">API Key :</p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.apiKey || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Idle Timeout (minutes):{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="number"
                    className="v1-form-control form-control mt-1"
                    value={data.idleTimeout}
                    onChange={(e) =>
                      setData({ ...data, idleTimeout: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">Idle Timeout :</p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.idleTimeout || ""} minutes
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Maximum Login Attempts:{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="number"
                    className="v1-form-control form-control mt-1"
                    value={data.maxAttempts}
                    onChange={(e) =>
                      setData({ ...data, maxAttempts: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Maximum Login Attempts :
                    </p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.maxAttempts || ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {isEdit ? (
                <div>
                  <p className="fs-12px text-300 fw-400">
                    Activity Log Expiry (days):{" "}
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="number"
                    className="v1-form-control form-control mt-1"
                    value={data.activityLogExpiry}
                    onChange={(e) =>
                      setData({ ...data, activityLogExpiry: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex aign-items-center w-100">
                  <div className="w-35">
                    <p className="fs-14px fw-500 text-300">
                      Activity Log Expiry :
                    </p>
                  </div>
                  <div className="w-65">
                    <p className="fw-400 fs-14px text-200">
                      {data.activityLogExpiry || ""} days
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`mt-3 d-flex align-items-center w-fit ${
                isEdit && "cursor"
              }`}
              onClick={() =>
                isEdit
                  ? setData({ ...data, disableSignup: !data.disableSignup })
                  : null
              }
            >
              {data.disableSignup ? (
                <GlobalIcons type={isEdit ? "form-check" : "form-check-view"} />
              ) : (
                <GlobalIcons
                  type={isEdit ? "form-uncheck" : "form-uncheck-view"}
                />
              )}
              <p className="text-300 fs-14px fw-500 ms-2">
                Disable Sign Up & Forgot Password
              </p>
            </div>

            <div
              className={`mt-3 d-flex align-items-center w-fit ${
                isEdit && "cursor"
              }`}
              onClick={() =>
                isEdit
                  ? setData({ ...data, disableLogin: !data.disableLogin })
                  : null
              }
            >
              {data.disableLogin ? (
                <GlobalIcons type={isEdit ? "form-check" : "form-check-view"} />
              ) : (
                <GlobalIcons
                  type={isEdit ? "form-uncheck" : "form-uncheck-view"}
                />
              )}
              <p className="text-300 fs-14px fw-500 ms-2">Disable Login</p>
            </div>

            <div
              className={`mt-3 d-flex align-items-center w-fit ${
                isEdit && "cursor"
              }`}
              onClick={() =>
                isEdit
                  ? setData({ ...data, mfaEnabled: !data.mfaEnabled })
                  : null
              }
            >
              {data.mfaEnabled ? (
                <GlobalIcons type={isEdit ? "form-check" : "form-check-view"} />
              ) : (
                <GlobalIcons
                  type={isEdit ? "form-uncheck" : "form-uncheck-view"}
                />
              )}
              <p className="text-300 fs-14px fw-500 ms-2">
                Enable Multi Factor Authentication
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
