import { Modal } from "react-bootstrap";
import { useEffect,useState } from "react";
import { objectDeepClone } from "@apiwiz/oas/services";
import Loading from "../../../components/Loader/Loading";
import Select from 'react-select'
import MyReactTooltip from "../../../components/ReactTooltip";
import API from "../../../api";
import { Info } from "phosphor-react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { getToast } from "../../../components/Toast";
import { confirm, throwServerError } from "../../../utils/helper";

export default function AddUseCaseModal(props){

    const [data, setData] = useState({
        title:'',imageUrl:'',url:'',tags:[], description: ''
    })
    const [isLoading, setIsLoading]=useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [errors, setErrors] = useState({});
    const [tags,setTags] = useState([]);


    const handleServerSideError = (error) => {
        if (error.response) {
            if (Object.keys(error.response.data.errors).length > 0) {
                
            } else {
                getToast({statusType: 'ERROR', message: error.response.data.message})
            }
        } else {
            getToast({statusType: 'ERROR', message: error.message})
        }
    }

    function fileUpload(e, name){
        API.uploadImage(e.target.files[0])
        .then(res => {
            setData({
                ...data, [name]: res.file?.url || ''
            })
            setErrors({...errors, [name]: false})
        }).catch(err => {
            throwServerError(err)
        })
    }

    useEffect(() => {
        if (props.isEdit){
            const _data = objectDeepClone(props.data)

            setData({
                url: _data.url, title: _data.title, imageUrl:_data.imageUrl,id:_data._id, tags:_data.tags.map((tag) => {
                    return { value: tag, label: tag};
                })
            })
        }
		// getListOfSdkCategory();
        getTagsList()
	}, []);

    function handleSubmit(){
        let _tags = data.tags.map((tag) => tag.value)
        if (!data.imageUrl || !data.url || !data.title || !_tags.length || !data.description){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the required details'
            })
            return
        }
        let form = {
            imageUrl: data.imageUrl, url: data.url, tags: _tags, title: data.title,
            description: data.description
        }
		if (props.isEdit) {
            let id = data.id;
            API.updateProduct(id, form)
                .then((response) => {
                    props.callback()
                    getToast({statusType: 'SUCCESS', message: "Use case updated successfully"})
                })
                .catch((error) => handleServerSideError(error));
		}else {
            API.addProduct(form)
                .then((response) => {
                    props.callback()
                    getToast({statusType: 'SUCCESS', message: "Use case added successfully"})
                })
                .catch((error) => handleServerSideError(error));
		}
    }

    const getTagsList = () => {
		API.getAllTags(1)
			.then((response) => {
				if (response.data.data) {
					setTags(
						response.data.data.map((tag) => {
							return { value: tag.name, label: tag.name };
						})
					);
				}
			})
			.catch((error) => {
            // handleServerSideError(error)
            }
        );
	};

    function tagsOnchange(e){
        setData({...data, tags: e})
    }

    function handleDelete(){
        const id = props.data._id
        const msg = "Are you sure you want to delete?";
        if (confirm(msg)) {
            API.deleteProduct(id)
                .then((response) => {
                    getToast({statusType: 'SUCCESS', message: "Use case deleted successfully"})
                    props.callback()
                })
                .catch((error) => handleServerSideError(error));
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} Usecase</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Cancel
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 58px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                {isLoading
                ? <Loading />
                : <div className='p-6px' onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSubmit()
                        e.preventDefault()
                    }
                }}>

                    <div className="d-flex flex-column align-items-center">
                        <p className="mb-3">Profile Picture</p>
                        {data.imageUrl ?<div>
                            <img src={data.imageUrl} height="200px" width="200px" />
                        </div>:<div>
                            <GlobalIcons type="emptySquareImage"></GlobalIcons>
                        </div>}
                       
                        <label htmlFor={`uploadProfile`}
                            className='cursor d-flex align-items-center justify-content-center py-2 btn-md-v1 mt-4'>
                            <GlobalIcons type="upload" />
                            <p className='fw-500 fs-12px text-300'>Upload Image</p>
                        </label>
                        
                        <input type={'file'} id='uploadProfile' className='d-none' 
                            onChange={(e) => fileUpload(e,"imageUrl")} />
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Title <span className='text-danger'>*</span> </p>

                        <input type={'text'} className='from-control v1-form-control w-100 mt-2px' value={data.title}
                            onChange={(e) => {
                                setData({...data, title: e.target.value})
                                setErrors({...errors, title: e.target.value.trim.length > 0})
                            }} placeholder='Enter title' />
                        
                        {errors.title && <p className='text-danger fs-10px mt-1'>
                            Title is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'> URL <span className='text-danger'>*</span> </p>

                        <input type={'text'} className='from-control v1-form-control w-100 mt-2px' value={data.url}
                            onChange={(e) => {
                                setData({...data, url: e.target.value})
                                setErrors({...errors, url: e.target.value?.trim.length > 0})
                            }} placeholder='Enter Overview URL' />
                        
                        {errors.overviewUrl && <p className='text-danger fs-10px mt-1'>
                            URL is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Description <span className='text-danger'>*</span> </p>

                        <textarea className='from-control v1-form-textarea w-100 mt-2px' rows={4} value={data.description}
                            onChange={(e) => {
                                setData({...data, description: e.target.value})
                                setErrors({...errors, description: e.target.value?.trim.length > 0})
                            }} placeholder='Enter Overview URL' />
                    
                        {errors.description && <p className='text-danger fs-10px mt-1'>
                            Description is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Tags<span className='text-danger'>*</span> </p>

                        <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            className="v1select h-fit mt-1" placeholder="Select Tag" options={tags}
                            value={data.tags} onChange={(e) => {tagsOnchange(e)}} isMulti
                        />
                        {errors.categoryname && <p className='text-danger fs-10px mt-1'>
                            Tags are required
                        </p>}
                    </div>

                    {(props.isEdit && props.getDeletePermission) && <div className='btn fs-12px text-danger br-6px'
                        onClick={handleDelete}
                        style={{position: 'absolute', bottom: 10, left: 10, border: '1px solid #e5484d'}}>
                        Delete
                    </div>}
                </div>}
            </Modal.Body>
        </Modal>
}