import Editor from "@monaco-editor/react";
import { useTheme } from "../../../context/ThemeContext";
import CaretDown from "../components/CaretDown";
import { CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFormattedJsonString, objectDeepClone } from "@apiwiz/oas/services";
import { monocoEditorOption } from "../../../service/constants";

export default function ResponseBodyParam(props) {
  let { format } = props;
  const {tryoutOperation} = useSelector(state => state.swagger);
  const [open, setOpen] = useState(true);
  const { theme } = useTheme();

  return (
    <div className="pl-10px pr-6px py-6px bg-theme-base br-4px d-flex flex-column gap-4px">
      <div
        className={`cursor d-flex justify-content-between align-items-center w-100 pr-6px br-4px h-34px ${
          open ? "" : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`d-flex align-items-center gap-4px`}>
          {!open ? (
            <CaretRight
              color="var(--primary-color)"
              size={13}
              className="mr-4px"
            />
          ) : (
            <CaretDown />
          )}
          <div
            className={`text-theme-primary fs-13px lh-21px h-21px ${
              open ? "fw-600" : ""
            }`}
          >
            Body Parameters
          </div>
        </div>
      </div>
      {open ? (
        <div className="param-body-example">
          <Editor
            width="100%"
            height="300px"
            language={format || "json"}
            theme={theme === "theme--dark" ? "vs-dark" : "vs-light"}
            options={{
              ...monocoEditorOption,
              readOnly: true
            }}
            value={getFormattedJsonString((tryoutOperation.__result||{}).body || {})}
          />
        </div>
      ) : null}
    </div>
  );
}
