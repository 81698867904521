import { Form, Modal } from "react-bootstrap";
import UserIcons from "../../../components/Icons/UserIcons";
import Select from "react-select";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import moment from "moment";
import { useEffect, useState } from "react";

export const EditKongUsersModal = (props) => {
  const { userData, onSave, runtime, ..._props } = props;
  const [runtimeOptions, setRuntimeOptions] = useState([])
  const [runtimesEnabled, setRuntimesEnabled] = useState({})


  useEffect(() => {
    const options = runtime?.runtimes?.map((runtime) => ({
      label: runtime.name,
      value: runtime.name,
    })) || [];

    setRuntimeOptions(options)

    const _enabled = userData?.runtimes?.reduce(
      (acc, curr) => ({ ...acc, [curr]: true }),
      {}
    )|| {};
    setRuntimesEnabled(_enabled)

  }, []);


  return (
    <Modal
      {..._props}
      size="md me-0 mt-0 mb-0"
      contentClassName="br-0px"
      className="custom-modal-v1 v1--styles"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-3 br-0px">
        <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <p className="mb-0 text-300 fw-500 fs-16px">Update User</p>
          </div>

          <div className="d-flex align-items-center">
            <div
              onClick={props.onHide}
              className="text-300 fw-400 fs-14px mr-14px cursor-pointer"
            >
              Cancel
            </div>
            <div
              onClick={(e) => {
                // handleOnSave(e);
                const updatedRuntimes = Object.keys(runtimesEnabled).filter(
                  (runtime) => runtimesEnabled[runtime]
                );

                onSave({
                  runtimes: { runtimes: updatedRuntimes },
                  userId: userData._id,
                });
              }}
              className="btn-md-v1 text-200 fw-400 fs-14px surface-850"
            >
              Save
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar p-12px"
      >
        <div className="p-3 surface-875 br-4px d-flex justify-content-between align-items-center">
          <div className="d-flex gap-16px align-items-center">
            <div
              className="user-profile-picture w-34px h-34px br-20px"
              style={{
                background: "#D9D9D9",
              }}
            ></div>
            <div className="text-200 fw-400">
              <p className="fs-16px">{userData.fullName}</p>
              <p className="fs-12px">{userData.email}</p>
            </div>
          </div>
          <div className="text-300 fs-12px fw-500">
            {moment.utc(userData.updatedAt).format("HH:MM A - MM/DD/YYYY")}
          </div>
        </div>

        <div className="p-3 surface-875 br-4px mt-3">
          <div className="d-flex flex-column gap-12px">
            <div>
              <p className="text-200 fs-16px fw-400">Kong Runtimes</p>
              <p className="text-400 fs-10px fw-400">
                Select runtime that you want to associate to a particular
                user.
              </p>
            </div>

            <p className="fw-400 fs-12px text-300">
              Select Runtimes <span className="text-danger">*</span>
            </p>
            {(runtimeOptions || []).map(el => {
              return (
                <div
                  onClick={(evt) => {
                    evt.stopPropagation();
                    console.log({ runtimesEnabled})
                    setRuntimesEnabled((prev) => ({
                      ...prev,
                      [el.label]: !prev[el.label],
                    }));
                  }}
                  className="cursor p-8px gap-8px d-flex align-items-center"
                >
                  <GlobalIcons
                    type={
                      runtimesEnabled[el.label]
                        ? "form-check"
                        : "form-uncheck"
                    }
                  />
                  <div className="d-flex flex-column gap-4px">
                    <div className="fw-500 fs-14px text-200">
                      {el.label}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
