import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';

export default function CenteredModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Centered Modal</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(60vh)" }}
                className="overflow-scroll noscrollbar"
            >

            </Modal.Body>
        </Modal>
    )
}
