import GlobalStatusTag from "../../../../components/GlobalStatusTag"
import TagNames from "../../../../components/TagNames"
import GlobalIcons from "../../../../components/Icons/GlobalIcons"
import UserIcons from "../../../../components/Icons/UserIcons"
import MyReactTooltip from "../../../../components/ReactTooltip"
import { replacePartnerIdWithDisplayName, toPascalCase, unslugifyText } from "../../../../utils/helper"
import { useEffect, useState } from "react"
import EmptyContainer from "../../../../components/EmptyContainer"
import { LockKeyOpen, LockSimpleOpen } from "phosphor-react"

export default function UserTable({ data, partners, handleDeleteUser, handleResetPassword, 
    handleLockUser, handleEditUserModal, showEdit, showDelete }){

    function getFirstLetterOfString(text){
        if (!text) return ''
        return text.charAt(0)
    }

    const [partnersList,setPartnersList] = useState([])

    useEffect(()=>{
        setPartnersList(partners)
    },partners)


    return <>
        <div className='surface-875 mx-0 d-flex p-2 mt-2 ps-3'>
            <div className="w-25 fs-12px fw-500 text-400">
                Name
            </div>
            <div className="w-40 fs-12px fw-500 text-400">
                Partner Groups
            </div>
            <div className="w-35 fs-12px fw-500 text-400">
                Role
            </div>
        </div>

        <div className='overflow-scroll' style={{height: `calc(100% - 40px)`}}>
            {data.length > 0 ? 
                data.map((item,index) => (
                    <div className='surface-900 mx-0 d-flex p-2 ps-3 py-3 userCard--tableRow v1-border-bottom-1 hover-surface-850' key={item.userId}>
                        <div className='w-25 my-auto'>
                            <div className='d-flex'>
                                <div className='my-auto'>
                                    <div className='userCircle surface-850'>
                                        <p className='fw-400 fs-14px text-300 text-uppercase'>
                                        {getFirstLetterOfString(item.fullName)}
                                        </p>
                                    </div>
                                </div>
                                <div className='pl-8px d-flex align-items-center'>
                                    <div className='me-2'>
                                        <div className="d-flex">
                                            <p className='text-200 fs-14px fw-500 text-truncate'>
                                                {toPascalCase(item.fullName)} {item.status === "deleted" && <span className="fs-12px fw-500 text-danger">(deleted)</span>}
                                            </p>
                                            <p className="12px fw-500 ms-2">
                                                <GlobalStatusTag type={item.loginMethod}/>
                                            </p>
                                        </div>
                                        <p className='text-400 fs-12px fw-400 text-truncate'>
                                            {item.email}
                                        </p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className='w-40 d-flex align-items-center justify-content-start'>
                            <div className="w-75">
                                {replacePartnerIdWithDisplayName(item.userGroups, partnersList).length>0?<TagNames data={replacePartnerIdWithDisplayName(item.userGroups, partnersList)} limit={7} modalTitle={"Partner Groups"}/>:<></>}
                            </div>
                        </div>
                        <div className='w-20 my-auto'>
                            <p className="fs-14px fw-500 text-200">{unslugifyText(item.userRole)}</p>
                        </div>
                        <div className='w-15 my-auto px-0 show-hover'>
                            <div className={`${item.status === "deleted" ? "d-none" : "d-flex" } align-items-center justify-content-end pe-3`}>
                                {showEdit ? (
                                    <div className='cursor pe-1' data-tip="Edit User" data-for={"edit"} onClick={()=>{handleEditUserModal(index)}}>
                                        <div className='btn-sm-v1 me-2'>
                                            <GlobalIcons type={'pencil'} />
                                        </div>
                                        <MyReactTooltip id={"edit"} />
                                    </div>
                                ) : null}
                                {showEdit && item.status === 'locked' ? (
                                    <div className='cursor pe-1' data-tip="Unlock User" data-for={"locked2active"} onClick={(e)=>{handleLockUser(e,index,"active")}}>
                                        <div className='btn-sm-v1 me-2'>
                                            <LockKeyOpen color="#e4e4e4" />
                                        </div>
                                        <MyReactTooltip id={"locked2active"} />
                                    </div>
                                ) : null}
                                {showEdit && item.status === 'active' ? (
                                    <div className='cursor pe-1' data-tip="Lock User" data-for={"lock"} onClick={(e)=>{handleLockUser(e,index,"locked")}}>
                                        <div className='btn-sm-v1 me-2'>
                                            <UserIcons type={"lock"} />
                                        </div>
                                        <MyReactTooltip id={"lock"} />
                                    </div>
                                ) : null}
                                {showEdit && item.status === 'pending-activation' ? (
                                    <div className='cursor pe-1' data-tip="Activate User" data-for={"pending-activation2active"} onClick={(e)=>{handleLockUser(e,index,"active")}}>
                                        <div className='btn-sm-v1 me-2'>
                                            <LockKeyOpen color="#e4e4e4" />
                                        </div>
                                        <MyReactTooltip id={"pending-activation2active"} />
                                    </div>
                                ) : null}
                                {showEdit && item.status === 'active' ? (
                                    <div className='cursor pe-1' data-tip="Reset Password" data-for={"reset"} onClick={(e)=>{handleResetPassword(e,index)}}>
                                        <div className='btn-sm-v1 me-2'>
                                            <UserIcons type={'resetPassword'} />
                                        </div>
                                        <MyReactTooltip id={"reset"} />
                                    </div>
                                ) : null}
                                {showDelete ? (
                                    <div className='cursor pe-1' data-tip="Delete User" data-for={"delete"} onClick={()=>{handleDeleteUser(index)}}>
                                        <div className='btn-sm-v1 me-2'>
                                            <UserIcons type={"bin"} />
                                        </div>
                                        <MyReactTooltip id={"delete"} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                </div>
                ))
            : (
                <EmptyContainer/>
            ) 
        
        }
        </div>
    </>
}