import { useEffect, useMemo, useState } from "react";
import SettingsConfig from "../Tryout/SettingsConfig";
import ParameterConfig from "../Tryout/ParameterConfig";
import { useDispatch } from "react-redux";
import { objectDeepClone } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { replaceScheme } from "@apiwiz/oas/services";
import { getRequestBodySample } from "@apiwiz/oas/helper";
import { BADGE_DELIMITER } from "../../../service/constants";
import {
  setTryoutBodyParamsValue,
  setTryoutConfig,
  setTryoutResult,
  setTryoutServers,
} from "../../../app/Features/Swagger/SwaggerSlice";
import ContainerLoader from "../../Loader/ContainerLoader";
import axios from "axios";
import BodyParameterConfig from "../Tryout/BodyParameterConfig";
import ResponseHeaderParams from "../Tryout/ResponseHeaderParams";
import ResponseBodyParam from "../Tryout/ResponseBodyParam";
import { statusCodeSpec } from "@apiwiz/oas/constants";
import { getToast } from "../../Toast";
import { Lock, LockSimple } from "phosphor-react";
import { checkAuthorised } from "@apiwiz/oas";
import { getSecuritiesTabsForOperation } from "@apiwiz/oas";

export default function DocView(props) {
  const dispatch = useDispatch();
  const [running, setRunning] = useState(false);
  const [operationSecurities, setOperationSecurities] = useState({});
  const [isAuthorised, setIsAuthorised] = useState(false);

  const [config, setConfig] = useState({
    reqContentType: null,
    resContentType: null,
    server: null,
    scheme: null,
  });
  const [variables, setVariables] = useState(null);
  const [configOptions, setConfigOptions] = useState({
    reqContentTypes: [],
    resContentTypes: [],
    servers: [],
    schemes: ["https", "http"],
  });
  const {
    selectOpLoading,
    tryoutOperation,
    tryoutDataLoad,
    tryoutHasErrors,
    axiosOptions,
    securities
  } = useSelector((state) => state.swagger);

  const servers = useMemo(() => {
    if (!tryoutOperation || !tryoutOperation.servers) return [];
    let _servers = objectDeepClone(tryoutOperation.servers);
    return _servers.map((x, index) => ((x.index = index), x));
  }, [tryoutOperation]);

  const content = useMemo(() => {
    if (!tryoutOperation || !tryoutOperation.requestBody) return null;
    return tryoutOperation.requestBody.content || null;
  }, [tryoutOperation]);

  const contentDescription = useMemo(() => {
    if (!tryoutOperation || !tryoutOperation.requestBody) return "";
    return tryoutOperation.requestBody.description;
  }, [tryoutOperation]);

  const headerParameters = useMemo(() => {
    return tryoutOperation.headerParams;
  }, [tryoutOperation]);

  const isHeaderParamAvail = useMemo(() => {
    return headerParameters && headerParameters.length;
  }, [headerParameters]);

  const queryParameters = useMemo(() => {
    return tryoutOperation.queryParams;
  }, [tryoutOperation]);

  const isQueryParamAvail = useMemo(() => {
    return queryParameters && queryParameters.length;
  }, [queryParameters]);

  const pathParameters = useMemo(() => {
    return tryoutOperation.pathParams;
  }, [tryoutOperation]);

  const isPathParamAvail = useMemo(() => {
    return pathParameters && pathParameters.length;
  }, [pathParameters]);

  const cookieParameters = useMemo(() => {
    return tryoutOperation.cookieParams;
  }, [tryoutOperation]);

  const isCookieParamAvail = useMemo(() => {
    return cookieParameters && cookieParameters.length;
  }, [cookieParameters]);

  const formParameters = useMemo(() => {
    return tryoutOperation.formParams;
  }, [tryoutOperation]);

  const isFormParamAvail = useMemo(() => {
    return formParameters && formParameters.length;
  }, [formParameters]);

  const updateConfig = ({ prop, value, __config = null }) => {
    let _config = __config;
    if (!_config) {
      _config = objectDeepClone(config);
    }

    if (prop) _config[prop] = value;

    setConfig(_config);
    dispatch(setTryoutConfig({ config: _config }));

    if (
      prop &&
      prop === "reqContentType" &&
      content &&
      tryoutOperation.__bodyValue
    ) {
      dispatch(
        setTryoutBodyParamsValue({
          value: getRequestBodySample(tryoutOperation.requestBody, value),
        })
      );
    }
  };

  const onVariablesSubmit = (variables) => {
    let _variables = objectDeepClone(variables);

    let _servers = objectDeepClone(servers);
    _servers[config.server.index].variables = _variables;
    let _computedUrl = _servers[config.server.index].url;
    let _computedUrlHtml = _servers[config.server.index].url;
    for (let field in _variables) {
      let value = _variables[field].__value || _variables[field].default;
      _computedUrl = _computedUrl.replace(`{${field}}`, value);
      _computedUrlHtml = _computedUrlHtml.replace(
        `{${field}}`,
        `${BADGE_DELIMITER.START}${value}${BADGE_DELIMITER.END}`
      );
    }
    _servers[config.server.index].__computedUrl = _computedUrl;
    _servers[config.server.index].__computedUrlHtml = _computedUrlHtml;

    configOptions.servers = _servers;

    updateConfig({
      prop: "server",
      value: _servers[config.server.index],
    });
    dispatch(setTryoutServers({ servers: _servers }));
  };

  const onSchemeChange = ({ value, __config = null }) => {
    let scheme = value;
    let _config = __config;
    if (!_config) _config = objectDeepClone(config);
    let _server = _config.server;
    if (_server && _server.url) {
      console.log(_server, "_server");
      _server.url = replaceScheme({ url: _server.url, scheme });
      if (_server.__computedUrl)
        _server.__computedUrl = replaceScheme({
          url: _server.__computedUrl,
          scheme,
        });
      if (_server.__computedUrlHtml)
        _server.__computedUrlHtml = replaceScheme({
          url: _server.__computedUrlHtml,
          scheme,
        });
      _config.server = _server;
    }

    let _serverOptions = objectDeepClone(configOptions.servers);
    for (let s of _serverOptions) {
      if (s && s.url) {
        s.url = replaceScheme({ url: s.url, scheme });
        if (s.__computedUrl)
          s.__computedUrl = replaceScheme({
            url: s.__computedUrl,
            scheme,
          });
        if (s.__computedUrlHtml)
          s.__computedUrlHtml = replaceScheme({
            url: s.__computedUrlHtml,
            scheme,
          });
      }
    }
    configOptions.servers = _serverOptions;

    _config.scheme = scheme;
    updateConfig({ __config: _config });
  };

  useEffect(() => {
    let consumes = tryoutOperation.__consumes;
    if (!consumes || !consumes.length) consumes = ["application/json"];
    let produces = tryoutOperation.__produces;
    if (!produces || !produces.length) produces = ["application/json"];
    configOptions.reqContentTypes = consumes;
    configOptions.resContentTypes = produces;
    configOptions.servers = servers;

    let schemesAvailable =
      tryoutOperation.__schemes || tryoutOperation.schemes || [];
    if (!schemesAvailable.length) {
      let _schemes = new Set();
      for (let s of servers) {
        if (s && s.url) _schemes.add(s.url.split(":")[0]);
      }
      schemesAvailable = [..._schemes];
    }

    if (!schemesAvailable.length) schemesAvailable = ["https"];
    configOptions.schemes = schemesAvailable;

    let _config = objectDeepClone(config);
    if (consumes && consumes.length)
      _config.reqContentType = _config.reqContentType || consumes[0];
    if (produces && produces.length)
      _config.resContentType = _config.resContentType || produces[0];
    if (servers && servers.length)
      _config.server = _config.server || objectDeepClone(servers[0]);

    onSchemeChange({
      value: objectDeepClone(_config.scheme || configOptions.schemes[0]),
      __config: objectDeepClone(_config),
    });

    if (!tryoutOperation.__bodyValue) {
      dispatch(
        setTryoutBodyParamsValue({
          value: getRequestBodySample(
            tryoutOperation.requestBody,
            _config.reqContentType
          ),
        })
      );
    }
    setConfigOptions(configOptions);
  }, []);

  const playTryout = () => {
    if (running || tryoutDataLoad) return;

    if (tryoutHasErrors && tryoutHasErrors.length) {
      getToast({
        statusType: 'ERROR', message: tryoutHasErrors[0]
      });
      return;
    }

    if(Object.keys(operationSecurities).length){
      if(!isAuthorised){
        getToast({
          statusType: 'ERROR', message: "Please authenticate and play tryout"
        });
        return;
     }
    }

    let axiosPromise = null;
    if (["get", "delete", "head", "options"].includes(axiosOptions.apiMethod)) {
      axiosPromise = axios[axiosOptions.apiMethod](
        axiosOptions.url,
        axiosOptions.axiosConfig
      );
    }

    if (["post", "put", "patch"].includes(axiosOptions.apiMethod)) {
      axiosPromise = axios[axiosOptions.apiMethod](
        axiosOptions.url,
        axiosOptions.data,
        axiosOptions.axiosConfig
      );
    }

    setRunning(true);
    axiosPromise
      .then((res) => {
        setRunning(false);

        let result = {
          statusCode: res.status,
          headers: res.headers,
          body: res.data,
        };
        dispatch(setTryoutResult(result));
      })
      .catch((err) => {
        setRunning(false);
        if (err.response) {
          let result = {
            statusCode: err.response.status,
            headers: err.response.headers,
            body: err.response.data,
          };
          dispatch(setTryoutResult(result));
        }
      });
  };

  useEffect(() => {
    let operationSecuritiesTabs = getSecuritiesTabsForOperation({operationSecurities: tryoutOperation.__security, storedSecurities: securities})
    setOperationSecurities(operationSecuritiesTabs);
  }, [tryoutOperation]);


  useEffect(() => {
    setIsAuthorised(checkAuthorised({operationSecurities: operationSecurities, storedSecurities: securities}));
  }, [operationSecurities, securities])

  return (
    <div className={`${props.className} border-left-theme-secondary-200 bg-theme-surface-875 px-16px py-8px d-flex flex-column gap-10px`}>
      {/* Settings */}
      <div>
        <SettingsConfig
          configOptions={configOptions}
          config={config}
          updateConfig={updateConfig}
          onSchemeChange={onSchemeChange}
          onVariablesSubmit={onVariablesSubmit}
        />
      </div>
      {/* Header Params */}
      {isHeaderParamAvail ? (
        <div>
          <ParameterConfig
            title="Header Parameters"
            field="headerParams"
            params={headerParameters}
          />
        </div>
      ) : null}
      {isPathParamAvail ? (
        <div>
          <ParameterConfig
            title="Path Parameters"
            field="pathParams"
            params={pathParameters}
          />
        </div>
      ) : null}
      {isQueryParamAvail ? (
        <div>
          <ParameterConfig
            title="Query Parameters"
            field="queryParams"
            params={queryParameters}
          />
        </div>
      ) : null}
      {isCookieParamAvail ? (
        <div>
          <ParameterConfig
            title="Cookie Parameters"
            field="cookieParams"
            params={cookieParameters}
          />
        </div>
      ) : null}
      {isFormParamAvail ? (
        <div>
          <ParameterConfig
            title="Form Parameters"
            field="formParams"
            params={formParameters}
          />
        </div>
      ) : null}

      {content ? (
        <div>
          <BodyParameterConfig value={tryoutOperation.__bodyValue} />
        </div>
      ) : null}
      {/* Tryout btn  */}
      <div>
        <div
          onClick={playTryout}
          className={`${
            tryoutDataLoad || running ? "cursor-not-allowed" : "cursor"
          } h-38px d-flex justify-content-center align-items-center w-100 fs-16px text-50 bg-theme-primary br-4px border-theme-secondary-200`}
        >
          {tryoutDataLoad || running ? (
            <ContainerLoader variant="theme-base" size="sm" />
          ) : (tryoutHasErrors?.length || (Object.keys(operationSecurities).length && !isAuthorised))
              ? <p> <LockSimple weight="fill" /> Try Out</p>
              : (
                "Try Out"
              )}
        </div>
      </div>
      {tryoutOperation.__result ? (
        <>
          <div className="fw-500 lh-28px h-28px fs-16px mb-12px">Responses</div>
          {tryoutOperation.__result.statusCode ? (
            <div className="d-flex gap-6px align-items-center h-22px">
            <div
              className={`status-group-chip px-6px h-22px _${tryoutOperation.__result.statusCode.toString()[0]}XX status-group-chip br-4px fs-14px fw-500`}
            >
              {tryoutOperation.__result.statusCode}
            </div>
            <div className="fs-14px"> {statusCodeSpec[tryoutOperation.__result.statusCode]?.message || ""}</div>
          </div>
          ) : null}
          <div className="fs-14px text-theme-content-subtle fw-400">
            {statusCodeSpec[tryoutOperation.__result.statusCode]?.description || ""}
          </div>
          <ResponseHeaderParams />
          <ResponseBodyParam />
        </>
      ) : null}
    </div>
  );
}
