import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router'
import { throwServerError, unslugifyText } from '../../utils/helper';
import { BookOpen, CalendarBlank, File, Nut, PuzzlePiece } from 'phosphor-react';
import API from '../../api';
import { useState } from 'react';
import Loading from '../../components/Loader/Loading';

const Morning = () => (
    <span dangerouslySetInnerHTML={{__html: 
        `
        <?xml version="1.0" encoding="UTF-8"?>
            <svg class="morning-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 260 249" style="enable-background:new 0 0 260 249;" xml:space="preserve">
                    <style type="text/css">
                        .st0{fill:#92E3FE;}
                        .st1{fill:#C2F0FF;}
                        .st2{fill:#F3FBFF;}
                        .st3{fill:#DAF5FF;}
                        .st4{fill:#E85F28;}
                        .st5{fill:#9E4445;}
                        .st6{fill:#FF8C00;}
                        .st7{fill:#0B3D3F;}
                        .st8{fill:none;stroke:#E85F28;stroke-width:2.2975;}
                        .st9{fill:none;stroke:#0B3D3F;stroke-width:3.4463;stroke-linecap:round;}
                        .st10{fill:none;stroke:#9E4445;stroke-width:2.2975;stroke-linecap:round;}
                        .st11{fill:#FFF1AE;}
                        .st12{fill:none;stroke:#E85F28;stroke-width:2.2975;stroke-linecap:round;}
                        .st13{fill:#00A0B9;}
                        .st14{fill:#FFFFFF;}
                        .st15{fill:#0F757F;}
                        .st16{fill:#BDABBD;}
                        .st17{fill:#EAD3E4;}
                        .st18{fill:#FFDA8E;}
                        .st19{fill:#EADCE6;}
                        .st20{fill:#FF9A00;}
                        .st21{fill:#1E4455;}
                        .st22{fill:#FF4900;}
                        .st23{fill:#F9C0B2;}
                        .st24{fill:#C18421;}
                        .st25{fill:#E5B053;}
                    </style>
                    <g class="back">
                        <circle id="Oval" class="st0" cx="137.4" cy="114" r="112.6"/>
                        <path id="Path" class="st1" d="M249.3,101.6c-4.8-43-33.9-79.5-74.8-93.7S88.2,4,57.7,34.7s-40.8,76.1-26.4,117l0,0
                            c0,0,32.6-12.3,47.6-19.2c14.9-6.9-72.4,12.6-24.1-18.4s187.2-82.7,151.6-47.1s-100.2,71.2-65.6,65.5
                            C173.4,127,241.8,104.1,249.3,101.6z"/>
                        <path id="Path_1_" class="st2" d="M32.8,72.4C50,89.3,77.6,89,94.5,71.9S111.2,27,94,10.1C66.1,21.8,44,44.2,32.8,72.4L32.8,72.4z"
                            />
                        <path id="Path_69_" class="st3" d="M187.1,13c0,0-83,36.8-65.7,23s24.1-18.4,24.1-18.4s13.8-16.1-13.8-4.6s-6.9-9.2-4.6-11.5
                            C129.3-0.8,168.9,1.5,187.1,13z"/>
                    </g>
                    <g class="back-1">
                        <path id="Path_2_" class="st4" d="M131.7,114c0,0,6.8-14.9,10.3-24.1s13.8-9.2,12.6,8c-1.1,17.2,3.4,39.1-14.9,54L131.7,114z"/>
                        <path id="Path_3_" class="st5" d="M154.1,119.2c0,0-15.5,17.8-29.3,8.6L148,152L154.1,119.2z"/>
                        <path id="Path_4_" class="st4" d="M223.2,217.1c0,0,19.1-2,19.1-21.5s-10.1,0-19,2.3s7.8-10.3,8.9-23c1.1-12.6-6.7-12.6-19,2.3
                            c-12.3,14.9-24.5,29.2-42.4,35.3L223.2,217.1z"/>
                        <path id="Path_5_" class="st5" d="M204,193.3c0,0-6.4,23.5,19.2,23.8l-93.7,8.9L204,193.3z"/>
                        <path id="Path_6_" class="st6" d="M148.5,139.3c0,0,24.6-88.5,43.5-70.1s-17.9,102.2-17.9,102.2s29-33.3,42.4-47.1
                            c13.4-13.8,15.6,43.7-3.3,62s-39.1,27.6-68.1,32.2L148.5,139.3z"/>
                        <path id="Path_7_" class="st7" d="M205.2,160.3c0,0,17.7-3.5,16,3.5C219.4,170.9,205.2,160.3,205.2,160.3z"/>
                        <path id="Path_8_" class="st7" d="M210.9,133.9c0,0,17.7-3.5,16,3.5C225.1,144.5,210.9,133.9,210.9,133.9z"/>
                        <path id="Path_9_" class="st8" d="M187.9,77.3c0,0-32.7,86.5-41.6,127.7"/>
                        <path id="Path_10_" class="st5" d="M137.3,184.3c0,0-68-39.3-84.4-69.7s-23.9,27.9-6.3,52s-6.3,16.5-13.8,7.6s-18.9-1.3-11.4,16.5
                            s39.1,36.5,92.5,24c0,0,26.1,4.1,44.2-13.8S137.3,184.3,137.3,184.3z"/>
                        <path id="Path_11_" class="st7" d="M209.8,180.9c-6.9,8-13.8-2.7-13.8-2.7S216.7,172.9,209.8,180.9"/>
                        <path id="Path_12_" class="st9" d="M184.5,213.2c0,0,9.2-63.5,47.1-108.3"/>
                        <path id="Path_13_" class="st4" d="M53,114.6c-16.4-30.4-23.9,27.9-6.3,52s-6.3,16.5-13.8,7.6s-18.9-1.3-11.4,16.5
                            c4.5,10.6,17.5,21.5,38.6,26C80.2,206,94.5,183.5,95.9,157C78.4,144,60.4,128.4,53,114.6z"/>
                        <path id="Path_14_" class="st10" d="M77.5,182.9c4.8-9.8,4.4-21.4-1-30.9"/>
                        <path id="Path_15_" class="st10" d="M47,121.2c0,0,8.8,52.9,61.1,90.1"/>
                        <path id="Path_16_" class="st9" d="M39.7,161.1c0,0,20.7,11.5,23,48.2"/>
                        <circle id="Oval_1_" class="st7" cx="83.4" cy="189.8" r="5.7"/>
                        <path id="Path_17_" class="st9" d="M86.3,212.4c0.2-7.2-0.8-14.5-2.9-21.4"/>
                        <path id="Path_18_" class="st7" d="M68.5,180.9c-6.9,8-13.8-2.7-13.8-2.7S75.4,172.9,68.5,180.9"/>
                        <path id="Path_43_" class="st4" d="M14.5,147.3c0,0,8.5-1.1,10.6,6.9s-4.8,8-8.3,5.7C13.3,157.7,9.9,151.9,14.5,147.3z"/>
                        <path id="Path_54_" class="st6" d="M202.9,114c0,0-4.6-9.2,0-16.1c4.6-6.9,11.5-6.9,10.3,0C212.1,104.8,205.2,123.2,202.9,114z"/>
                        <path id="Path_55_" class="st11" d="M232.7,134.7c0,0,0-11.5,3.4-10.3c3.4,1.1,5.7,5.7,2.3,11.5C235,141.6,229.3,145,232.7,134.7z"
                            />
                        <path id="Path_56_" class="st4" d="M241.9,180.7c0,0-2.3-8,6.9-14.9c9.2-6.9,9.2,6.9,6.9,9.2C253.4,177.2,241.9,180.7,241.9,180.7z
                            "/>
                        
                            <ellipse id="Oval_12_" transform="matrix(0.4811 -0.8766 0.8766 0.4811 -5.1477 301.7047)" class="st6" cx="252.3" cy="155.2" rx="5.2" ry="2.3"/>
                        <line id="Path_57_" class="st12" x1="180.7" y1="96.8" x2="173" y2="85.3"/>
                        <line id="Path_58_" class="st12" x1="177.5" y1="105.6" x2="187.4" y2="103.1"/>
                        <line id="Path_59_" class="st12" x1="173.9" y1="115.7" x2="170.1" y2="106.6"/>
                        <line id="Path_60_" class="st12" x1="171.7" y1="122.1" x2="180.5" y2="120.3"/>
                        <path id="Path_64_" class="st7" d="M221.9,118.6c0,0,25.7-6.9,16.6,1.1C229.3,127.8,226.1,123.2,221.9,118.6z"/>
                        <path id="Path_65_" class="st7" d="M188.6,198.2c0,0,21.7-14.9,14.2-5.3C195.3,202.6,188.6,198.2,188.6,198.2z"/>
                        <path id="Path_66_" class="st7" d="M225.6,112.4c0,0-8.9-15.6-10.1-7.6S225.6,112.4,225.6,112.4z"/>
                        <path id="Path_67_" class="st7" d="M46.7,166.6l-9.2,6C37.5,172.6,49,183.5,46.7,166.6z"/>
                        <path id="Path_68_" class="st7" d="M59.3,188.4l-9.2,6C50.1,194.4,61.6,205.3,59.3,188.4z"/>
                        <circle id="Oval_14_" class="st7" cx="36.3" cy="157.7" r="5.7"/>
                        <path id="Path_70_" class="st10" d="M42,141.6c0,0,3.4,20.6,21.8,21.8"/>
                        <path id="Path_71_" class="st7" d="M15.6,131.3c0,0,10.3-3.4,9.2,4.6C23.7,143.9,15.6,131.3,15.6,131.3z"/>
                        <path id="Path_72_" class="st13" d="M248.6,199c0,0-4.8-8.4,7.3-13.5C268,180.5,248.6,199,248.6,199z"/>
                        <polygon id="Path_77_" class="st14" points="140.8,106 138.5,110.6 140.8,115.2 143.1,110.6 	"/>
                        <polygon id="Path_78_" class="st14" points="144.3,96.8 143.1,99.1 144.3,101.4 145.4,99.1 	"/>
                    </g>
                    <g class="footer-plate">
                        <ellipse id="Oval_2_" class="st15" cx="135.7" cy="228.9" rx="120" ry="17.2"/>
                        <path id="Path_19_" class="st13" d="M32.5,220.1c7.7,8.2,50.5,14.6,102,14.6c52.5,0,95.9-6.5,102.4-14.9c-21.3-4.8-58.7-8-101.3-8
                            C91.8,211.7,53.4,215,32.5,220.1z"/>
                        <path id="Path_40_" class="st16" d="M98.3,216.5l-11.2-0.2c0,0,1.1-10.3,5.6-9.2C97.1,208.2,98.3,216.5,98.3,216.5z"/>
                        <path id="Path_45_" class="st17" d="M86.8,216.3c0,0,8-1.9,8-7.8C94.9,208.4,89.1,200.2,86.8,216.3z"/>
                        <path id="Path_47_" class="st7" d="M86.8,216.3c0,0,20.1-0.7,18.1,0.2C102.9,217.4,92.6,218.6,86.8,216.3z"/>
                        <ellipse id="Oval_6_" class="st13" cx="127.1" cy="236.9" rx="27.6" ry="3.4"/>
                        <ellipse id="Oval_7_" class="st13" cx="165" cy="245.6" rx="18.4" ry="2.9"/>
                        <ellipse id="Oval_8_" class="st13" cx="51.2" cy="243.3" rx="23" ry="4"/>
                        <ellipse id="Oval_9_" class="st13" cx="12.8" cy="241" rx="12.1" ry="1.7"/>
                        <ellipse id="Oval_10_" class="st13" cx="47.2" cy="227.8" rx="13.2" ry="3.4"/>
                        <ellipse id="Oval_11_" class="st13" cx="219.5" cy="230.6" rx="19" ry="2.9"/>
                    </g>
                <g class="bg-stuff">
                        <ellipse id="Oval_3_" class="st15" cx="138" cy="221.4" rx="62.6" ry="6.3"/>
                    <path id="Path_41_" class="st15" d="M184.9,233.9c0,0-6.7-20-1.5-13.3c5.2,6.7,4.5,6.7,4.5,6.7s2.7-18.7,3.2-9.7
                        c0.2,6.5-0.4,13-1.7,19.4L184.9,233.9z"/>
                    <path id="Path_42_" class="st13" d="M204.7,215c0,0,2.8-20.6,5.1-11.4c2.3,9.2,1.1,8,1.1,8s5.7-10.3,5.7-4.6
                        c0,5.7-3.4,11.5-3.4,11.5L204.7,215z"/>
                    <path id="Path_48_" class="st18" d="M83.4,234.6c0,0,3.4-1.1,5.7,0C89.1,234.6,90.3,236.9,83.4,234.6z"/>
                    <path id="Path_52_" class="st18" d="M73.1,226.6c0,0,3.4-1.1,5.7,0C78.8,226.6,80,228.9,73.1,226.6z"/>
                    <polygon id="Path_76_" class="st14" points="46.6,199 44.3,203.6 46.6,208.2 48.9,203.6 "/>
                    <polygon id="Path_79_" class="st14" points="158.1,228.9 154.6,222 158.6,216.6 161.4,221.4 "/>
                    <polygon id="Path_80_" class="st14" points="173.2,211.7 170.9,207 173,201.3 175.3,204.8 "/>
                </g>
                    <g class="bird">
                        <path id="Path_20_" class="st18" d="M163.4,204.2l5.7,12.3c0,0,12.8,5.7,8.2,4.7l-9.2-1.9c0,0,0.9,8.5-0.9,4.7l-1.8-3.8
                            c0,0-9.2,6.6-6.4,2.8c1.7-2.3,3.9-4.2,6.4-5.7l-5.5-11.6L163.4,204.2z"/>
                        <path id="Path_21_" class="st14" d="M94.2,125.9c0,0-24.1-10.7-30.4-27.3c-6.7-18.1-11.2,6.4-7.5,15.3c3.7,8.9,3.7,7.7,3.7,7.7
                            s-11.2-8.9-8.7-1.3s18.7,17.9,18.7,17.9s-17.4-1.3-13.7,7.7c3.1,7.5,12.4,24.3,38.5,25.6L94.2,125.9z"/>
                        <path id="Path_22_" class="st19" d="M94.2,125.9c-2.7-1.2-5.3-2.6-7.8-4.2l0,0c0,0-10.7,21-8,46.2c5.3,2.1,10.8,3.2,16.5,3.4
                            L94.2,125.9z"/>
                        <path id="Path_23_" class="st14" d="M140.7,132.4c0,0-10.1-5.7-8.9-23c1.1-17.2,2.2-29.9-10-32.2s-21.2,13.8-26.8,31
                            c-5.6,17.2-22.3,74.7,19,99.9s93.8-26.4,98.3-54c0,0,20.1-3.4,6.7-9.2c0,0,7.8-10.3,3.3-12.6c-4.5-2.3-8.9,3.4-8.9,3.4
                            s4.5-20.7-2.2-18.4c-6.7,2.3-5.6,18.4-5.6,18.4S166.4,150.8,140.7,132.4z"/>
                        <path id="Path_24_" class="st14" d="M122.6,140.4c0,0,8.7,35.9,48,25.4c39.3-10.5,21.8-18,13.1-20.9c0,0,25.9-10.5,13.7-11.2
                            c0,0-5-0.8-13.7,0.7c0,0,17.4-3,13.1-7.5s-48,3-72.7,7.5C124.1,134.4,121.1,134.4,122.6,140.4z"/>
                        <path id="Path_25_" class="st5" d="M111.7,85.3c0,0-4.5-17.2,7.8-25.3s32.4-1.1,27.9,4.6c-4.5,5.7-7.8,4.6-15.6,2.3
                            c0,0,6.7,11.5-2.2,8S115,73.8,111.7,85.3z"/>
                        <path id="Path_26_" class="st19" d="M124.9,146.6c0,0,3.4,18.5,31,21.1c27.6,2.6,42.2-12.4,42.2-12.4s-20.4,34.4-48,29.9
                            C122.5,180.7,124.4,146.9,124.9,146.6z"/>
                        <path id="Path_27_" class="st19" d="M176.4,146.4c0,0,17.4,0.1,24-10.4L176.4,146.4z"/>
                        <path id="Path_28_" class="st19" d="M183.7,134.4c0,0,7.3-4.5,10.2-4.5S189.5,134.4,183.7,134.4z"/>
                        <path id="Path_29_" class="st20" d="M102.7,99.1l-9-4.6c-0.6-0.3-1.3-0.1-1.7,0.3c-0.4,0.5-0.5,1.2-0.2,1.8l2.7,4.7l-4.3,0.6
                            c-0.6,0.1-1.1,0.6-1.2,1.2c-0.1,0.6,0.2,1.3,0.7,1.6l8.4,4.7C98.3,109.5,102.7,107.1,102.7,99.1z"/>
                        <ellipse id="Oval_4_" class="st21" cx="113.9" cy="97.9" rx="3.4" ry="3.4"/>
                        <ellipse id="Oval_5_" class="st14" cx="112.8" cy="96.8" rx="1.1" ry="1.1"/>
                        <path id="Path_30_" class="st19" d="M168.9,205c-0.2,3.7-2.7,6.9-6.4,7.8c-3.7,1.1-7.6,1.1-11.3,0L168.9,205z"/>
                        <path id="Path_31_" class="st18" d="M118.8,204.2l-5.7,12.3c0,0-12.8,5.7-8.2,4.7l9.2-1.9c0,0-0.9,8.5,0.9,4.7l1.8-3.8
                            c0,0,9.2,6.6,6.4,2.8c-1.7-2.3-3.9-4.2-6.4-5.7l5.5-11.6L118.8,204.2z"/>
                        <path id="Path_32_" class="st19" d="M111.1,205c0.2,3.7,2.7,6.9,6.4,7.8c3.7,1.1,7.6,1.1,11.3,0L111.1,205z"/>
                        <path id="Path_33_" class="st19" d="M198.7,180.5c-69.2,50.3-107.4,0.2-107.5,0c4.2,11.6,12.2,21.4,22.7,27.7
                            C143.8,226.5,179.5,204.5,198.7,180.5z"/>
                        <path id="Path_34_" class="st19" d="M213.3,135.9l-3.4,4.6c0,0-2.2-18.4,1.1-18.4S213.3,135.9,213.3,135.9z"/>
                        <path id="Path_35_" class="st19" d="M212.2,146.2c0,0,8.9-13.8,8.9-10.3s-4.5,9.2-4.5,9.2s7.8,2.3,4.5,3.4
                            C217.7,149.6,212.2,146.2,212.2,146.2z"/>
                        <path id="Path_36_" class="st14" d="M132.4,119.2c0,0,12.7,5.1,7.2,5.1h-8.9L132.4,119.2z"/>
                        <path id="Path_37_" class="st14" d="M89.1,131c0,0-9.8,14.1-7.5,12.9c1.2-0.6,2.4-1.4,3.4-2.3c0,0-4.8,16.6,2.1,7.1L89.1,131z"/>
                        <path id="Path_38_" class="st22" d="M98.3,108.3c0,0-10.1,5.7-10.1,10.3c0.1,3,1.3,5.8,3.4,8C91.6,126.7,94.9,111.7,98.3,108.3z"/>
                        <ellipse id="Oval_13_" class="st23" cx="119" cy="106" rx="5.7" ry="2.3"/>
                        <path id="Path_61_" class="st19" d="M112.1,85.3c0,0,6-9.9,18.5-1.5c0,0-1.3-6.5-9.3-6.5C115.6,77.3,113.3,80.7,112.1,85.3z"/>
                        <path id="Path_62_" class="st18" d="M104.1,110.6c0,0,5.7,2.3,3.4,3.4C105.2,115.2,104.1,110.6,104.1,110.6z"/>
                        <path id="Path_63_" class="st18" d="M107.5,116.3c0,0-3.4,0-2.3,1.1l1.1,1.1L107.5,116.3z"/>
                        <path id="Path_81_" class="st22" d="M119.5,60c-11.1,7.3-8.5,22-7.9,24.8l0.2,0.2c0.1-0.2,0.1-0.4,0.2-0.7c-0.3-2.7-0.4-10.2,7-14
                            c6.5-3.3,13-0.2,13.8-1.4c-0.6-1.1-1.1-2.1-1.1-2.1l0.4,0.1c-0.4-0.6-0.9-1.4-1.7-2.4c0,0,0-3.4,6.9,0c6.9,3.4,9.5-4,9.5-4
                            C143,56.5,128.9,53.9,119.5,60z"/>
                        <path id="Path_82_" class="st5" d="M88.2,120.3c0,0,6.7-9.8,10.1-12.1c0,0-4.6,12.6-6.9,18.4C91.4,126.7,88.5,123.2,88.2,120.3z"/>
                    </g>
                    <g class="food">
                    <path id="Path_86_" class="st24" d="M76.5,224.3c0,0,3.4-1.1,5.7,0C82.3,224.3,83.4,226.6,76.5,224.3z"/>
                        <path id="Path_39_" class="st16" d="M45.8,222c0,0,7.8-20.7,22.3-19.5c14.5,1.1,17.9,18.4,13.4,19.5C77,223.2,45.8,222,45.8,222z"
                            />
                        <path id="Path_44_" class="st17" d="M57.3,222.4c10.5-1.9,18.9-7.2,22.2-13.9c-2.8-3.5-7-5.6-11.4-5.9
                            C53.6,201.3,45.8,222,45.8,222S50.9,222.2,57.3,222.4z"/>
                        <path id="Path_46_" class="st7" d="M81.1,222c0,0,12.6-1.1,6.9,1.1S45.5,222,45.5,222H81.1z"/>
                        <path id="Path_49_" class="st18" d="M54.7,234.6c0,0,3.4-1.1,5.7,0C60.4,234.6,61.6,236.9,54.7,234.6z"/>
                        <path id="Path_50_" class="st18" d="M69.6,235.8c0,0,3.4-1.1,5.7,0C75.4,235.8,76.5,238.1,69.6,235.8z"/>
                        <path id="Path_51_" class="st18" d="M63.9,227.8c0,0,3.4-1.1,5.7,0C69.6,227.8,70.8,230,63.9,227.8z"/>
                        <polygon id="Path_53_" class="st18" points="60.4,234.6 62.7,229.1 66.8,227.8 72.5,224.4 75.4,223.2 80,223.2 81.1,227.8 
                            84.5,231.2 87.1,236.4 75.4,235.8 64.6,235.7 	"/>
                        <path id="Path_73_" class="st7" d="M162.7,234.6c0,0,71.2-3.4,66.6,0S194.8,239.2,162.7,234.6z"/>
                        <path id="Path_74_" class="st16" d="M162.7,234.9c0,0,11.8-31.1,33.6-29.4c21.8,1.7,26.9,27.6,20.2,29.4
                            C209.7,236.6,162.7,234.9,162.7,234.9z"/>
                        <path id="Path_75_" class="st17" d="M180,235.4c15.8-2.9,28.4-10.8,33.4-21c-4.2-5.3-10.5-8.5-17.2-8.9
                            c-21.8-1.7-33.6,29.4-33.6,29.4S170.4,235.1,180,235.4z"/>
                        <path id="Path_83_" class="st24" d="M76.5,227.8c0,0,3.4-1.1,5.7,0C82.3,227.8,83.4,230,76.5,227.8z"/>
                        <path id="Path_84_" class="st24" d="M83.4,234.6c0,0,3.4-1.1,5.7,0C89.1,234.6,90.3,236.9,83.4,234.6z"/>
                        <path id="Path_85_" class="st24" d="M81.1,231.2c0,0,3.4-1.1,5.7,0C86.8,231.2,88,233.5,81.1,231.2z"/>
                        <path id="Path_87_" class="st25" d="M69.6,228.9c0,0,3.4-1.1,5.7,0C75.4,228.9,76.5,231.2,69.6,228.9z"/>
                        <path id="Path_88_" class="st25" d="M65,231.2c0,0,3.4-1.1,5.7,0C70.8,231.2,71.9,233.5,65,231.2z"/>
                        <path id="Path_89_" class="st25" d="M70.8,233.5c0,0,3.4-1.1,5.7,0C76.5,233.5,77.7,235.8,70.8,233.5z"/>
                        <path id="Path_90_" class="st24" d="M74.2,231.2c0,0,3.4-1.1,5.7,0C80,231.2,81.1,233.5,74.2,231.2z"/>
                    </g>
                </svg>
        `
    }}></span>
    
    
);

const Afternoon = () => (
    <span dangerouslySetInnerHTML={{__html: 
        `
        <?xml version="1.0" encoding="UTF-8"?>
            <svg class="afternoon-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 260 256" style="enable-background:new 0 0 260 256;" xml:space="preserve">
                    <style type="text/css">
                        .st0{fill:#FFE7B3;}
                        .st1{fill:#FFD89C;}
                        .st2{fill:#EFB66A;}
                        .st3{fill:#FFFFFF;}
                        .st4{fill:#0B3D3F;}
                        .st5{fill:none;stroke:#0B3D3F;stroke-width:3.4361;stroke-linecap:round;}
                        .st6{fill:#13D1B5;}
                        .st7{opacity:0.36;}
                        .st8{fill:#2FEAA2;}
                        .st9{fill:#00A0B9;}
                        .st10{fill:#E85F28;}
                        .st11{fill:#9E4445;}
                        .st12{fill:#BDABBD;}
                        .st13{fill:#EAD3E4;}
                        .st14{fill:#7A292D;}
                        .st15{fill:#1C3463;}
                        .st16{fill:#365991;}
                        .st17{fill:#AD2D2D;}
                        .st18{fill:#D64A1E;}
                        .st19{fill:#FFB000;}
                        .st20{fill:#FEFEFE;}
                        .st21{stroke:#000000;stroke-width:1.1454;}
                        .st22{fill:#FBD7D1;}
                        .st23{fill:#DD7814;}
                        .st24{fill:#4983D6;}
                    </style>
                    <g class="back">
                        <ellipse id="Oval" class="st0" cx="137.8" cy="116.3" rx="116" ry="115.1"/>
                        <path id="Path_1_" class="st1" d="M202.4,46.6c20.1-14.2-13.3-16.2-26.1-16.4c11.3,0,22.5-1.4,33.5-4.2
                            c-31-24.3-72.3-31.3-109.6-18.6S34.6,51,24.9,89.2c8.4-1.5,16.5-3.9,24.4-7c-1.8,0.8-10.3,5.3-26.8,24.3c-0.2,0.2-0.3,0.4-0.5,0.6
                            c-0.2,2-0.3,4-0.3,6c11.5,6.4,86.5-15.8,101.4-23.3c17.6-8.8-32-23.5-32-23.5c24.2,2.4,55.4-7.5,55.4-7.5l-21.3,12.4
                            C110.8,90.9,177.7,63.9,202.4,46.6z"/>
                        <path id="Path_4_" class="st2" d="M83.2,208c0,0,33.1-119.7,51.2-87.1s13.9,75.8,23.2,60.6c9.2-15.1,15.9-81,23.4-54.5
                            c7.6,26.5,3.5,88.1,3.5,88.1L71.8,228.6L83.2,208z"/>
                        <path id="Path_5_" class="st3" d="M136.3,95l88-46.4c0,0-5.7,1.1-15.2,2.8c0,0-64.8-40.6-54.7-19.7c10,20.9,22.6,24.1,22.6,24.1
                            s-26.1-14.7-25.1-2.7s18.1,10.7,18.1,10.7s-14.1,2-17.1,8.7c0,0-2.5,4.4-1.2,8.2L136.3,95z"/>
                        <path id="Path_6_" class="st2" d="M238.5,229.4c0,0,38.4-34.7,6.6-45.1s-45.7,37.9-45.7,37.9L238.5,229.4z"/>
                        <path id="Path_47_" class="st0" d="M34.7,63.4c0,0,129.6-30.5,97.2-34.4l-32.3-3.9l43.8-23.8C143.4,1.3,74.3-8,34.7,63.4z"/>
                    </g>
                    <g class="back-1">
                        <path id="Path_2_" class="st4" d="M207.6,170.7c0,0-7-32.4-18.2-18.3c-11.2,14.2-28.1,63.7-28.1,63.7l18.5-0.4L207.6,170.7z"/>
                        <path id="Path_3_" class="st4" d="M89.7,175.1c0,0,12.3-6.4,11.4,5.8c-0.5,8.1-0.3,16.3,0.4,24.4l-25.4-14.9L89.7,175.1z"/>
                        <path id="Path_7_" class="st5" d="M183.6,130.7c0,0-13.9,61.2-12.8,89.5"/>
                        <path id="Path_9_" class="st6" d="M195,169.2c0,0,18.3-66.2,32.6-52.4c14.3,13.8-13.4,76.5-13.4,76.5s21.8-24.9,31.8-35.2
                            s11.7,32.7-2.5,46.4s-45.2,20.4-66.9,23.8L195,169.2z"/>
                        <g id="Group" transform="translate(188.986784, 124.845815)" class="st7">
                            <path id="Path_10_" class="st4" d="M31.3,1.1c-0.1,5.7-1.9,11.1-3.7,16.5c-1.8,5.4-3.6,10.4-5.5,15.6C18.2,43.6,13.9,53.9,9.2,64
                                c-2.7,5.7-5.5,11.4-8.3,17c-0.5,1,1,1.9,1.5,0.9C12.7,61.8,21.5,41,28.8,19.6c2-6,4.1-12.1,4.2-18.5C33,0,31.3,0,31.3,1.1
                                L31.3,1.1z"/>
                        </g>
                        <circle id="Oval_1_" class="st4" cx="218.9" cy="183.3" r="7.7"/>
                        <path id="Path_12_" class="st8" d="M18.5,185.4c0,0-3.1,10.4-5.4,3.9c-2.3-6.5,3.9-19.2-1.6-18.6s-27.3,47.7,10.2,63.3l22.4-11.3
                            L18.5,185.4z"/>
                        <path id="Path_13_" class="st8" d="M17.9,110.9c0,0-10.6,86.5,17.3,117.1l67.5-2.6c-10.6-15.1-18.3-32.1-22.7-50
                            c-7.4-30-10.3-39.6-14.2-18.2s-7,38.6-14.8,12.6S34.4,85.9,27.2,89.2S17.9,110.9,17.9,110.9z"/>
                        <g id="Group_1_" transform="translate(25.198238, 101.792952)" class="st7">
                            <path id="Path_14_" class="st4" d="M0.4,0.9c-1.8,38.9,5.9,77.7,22.3,113.1c0.5,1,1.9,0.1,1.5-0.9C7.9,78,0.4,39.5,2.2,0.9
                                C2.2-0.3,0.5-0.3,0.4,0.9L0.4,0.9z"/>
                        </g>
                        <path id="Path_15_" class="st9" d="M106.6,217.2c0,0,5.8-18.7-7.9-24.8s-26.5-2.7-21,4.2c5.5,6.9,4.2,15.4-3.5,6.6
                            c-7.7-8.7-5.8-18.6-7.8-28.9c-2.1-10.4-3.3-42.5-15.1-27.5c-11.9,15-9.3,51.1-5,58.4s-11.2,10.7-11.2,0.4s3-31.6-6.9-17.8
                            c-9.9,13.9-13.2,37.4,6.9,48.2C55.2,246.8,106.6,217.2,106.6,217.2z"/>
                        <g id="Group_2_" transform="translate(52.687225, 147.753304)" class="st7">
                            <path id="Path_16_" class="st4" d="M0.4,1.1C3,19.4-0.6,38.2,3.9,56.3C5,61,6.7,65.5,9,69.6c0.5,1,2,0.1,1.5-0.9
                                C1.6,52.8,3.4,34,3.1,16.5C3.1,11.2,2.7,5.9,2,0.6C1.8-0.5,0.2,0,0.4,1.1L0.4,1.1z"/>
                        </g>
                        <path id="Path_17_" class="st9" d="M145.2,220.7c0,0-1.5-8.4,6.9-19c8.4-10.6,26.8-20.7,24.1-13.2c-2.7,7.5-15,17.1-7.6,16.8
                            s9.7-14.6,17.7-19.3c8.1-4.8,12.2,19.5,2.7,34L145.2,220.7z"/>
                        <path id="Path_18_" class="st6" d="M115.8,217c-0.4-3.9-1.4-7.7-3.1-11.3c-2.8-5.8-0.7,1.4-2.8,3.3c-2.1,1.9-9-13.5-11.6-9.3
                            c-2.6,4.2-5.8,11.4-5.8,18.3C92.5,224.7,115.8,217,115.8,217z"/>
                        <path id="Path_22_" class="st9" d="M243.4,146.8c0,0-3.9-0.5,0-12.7c3.9-12.3,7.9-11.9,10.4-4.4
                            C256.3,137.3,243.4,159.5,243.4,146.8z"/>
                        <path id="Path_30_" class="st6" d="M241.7,121.4c0,0-4.7,0.2-3.1-11.1s6.4-8.8,6.4-4.6S246.7,117.3,241.7,121.4z"/>
                        <path id="Path_31_" class="st6" d="M3.7,169.9c0,0-4.7,0.2-3.1-11.1S7,150,7,154.2C7,158.4,8.8,165.9,3.7,169.9z"/>
                        <g id="Group_3_" transform="translate(213.039648, 147.607930)" class="st7">
                            <path id="Path_54_" class="st4" d="M1.9,5.6C5.2,5,8.4,3.6,11.1,1.7c0.4-0.2,0.5-0.7,0.3-1.1c-0.2-0.4-0.7-0.5-1.1-0.3
                                C7.7,2.1,4.7,3.4,1.5,3.9C1.2,4,1,4.3,0.9,4.6c-0.1,0.3,0,0.6,0.2,0.8C1.4,5.6,1.7,5.7,1.9,5.6L1.9,5.6z"/>
                        </g>
                        <g id="Group_4_" transform="translate(202.731278, 158.061674)" class="st7">
                            <path id="Path_55_" class="st4" d="M0.3,1.1C0.9,4.7,2,8.1,3.6,11.2c0.5,1,2,0.1,1.5-0.9C3.6,7.3,2.5,4.1,1.9,0.7
                                C1.8,0.2,1.3,0,0.9,0.1S0.1,0.7,0.3,1.1L0.3,1.1z"/>
                        </g>
                        <g id="Group_5_" transform="translate(201.585903, 178.678414)" class="st7">
                            <path id="Path_56_" class="st4" d="M1.7,5.6l7.1-3.3C9.1,2.1,9.3,1.6,9,1.2C8.8,0.8,8.3,0.6,7.9,0.8L0.8,4.1
                                C0.4,4.4,0.3,4.9,0.5,5.3C0.8,5.6,1.3,5.8,1.7,5.6L1.7,5.6z"/>
                        </g>
                        <g id="Group_6_" transform="translate(20.616740, 170.660793)" class="st7">
                            <path id="Path_57_" class="st4" d="M1,1.6c4.1,3.3,8.5,6.2,13,8.8c0.4,0.2,0.9,0,1.1-0.4c0.2-0.4,0.1-0.9-0.2-1.1
                                C10.5,6.4,6.2,3.6,2.2,0.4C1.4-0.3,0.1,0.9,1,1.6L1,1.6z"/>
                        </g>
                        <g id="Group_7_" transform="translate(27.488987, 133.863436)" class="st7">
                            <path id="Path_58_" class="st4" d="M2.1,12c2-3.6,4.1-7.1,6.3-10.6C9,0.4,7.5-0.5,6.9,0.5c-2.2,3.5-4.3,7-6.3,10.6
                                C0.1,12.1,1.6,12.9,2.1,12L2.1,12z"/>
                        </g>
                        <g id="Group_8_" transform="translate(20.616740, 131.718062)" class="st7">
                            <path id="Path_59_" class="st4" d="M1,1.9l4.3,4.3c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.9,0-1.2L2.2,0.7C1.8,0.4,1.3,0.4,1,0.7
                                C0.6,1,0.6,1.6,1,1.9L1,1.9z"/>
                        </g>
                        <line id="Path_61_" class="st5" x1="115.8" y1="217" x2="120.1" y2="195.1"/>
                        <circle id="Oval_11_" class="st4" cx="120.4" cy="193.7" r="4.7"/>
                        <path id="Path_62_" class="st4" d="M117.7,206.9c0,0-9.8-2.9-7.4-5.1C112.8,199.6,118.8,201.5,117.7,206.9z"/>
                        <path id="Path_63_" class="st4" d="M116.8,211.5c0,0,6.8-8.5,8.5-3.4C127,213.1,116.8,211.5,116.8,211.5z"/>
                    <path id="Path_11_" class="st5" d="M208.5,212.2c4.6-8.6,7.9-17.9,9.7-27.5"/>
                    <path id="Path_27_" class="st4" d="M213.9,198.7c0,0,20.1-5.4,13,0.9C219.8,205.9,213.9,198.7,213.9,198.7z"/>
                            <path id="Path_28_" class="st4" d="M211.6,206.3c0,0,16.9,2,12.6,4.7C219.9,213.7,211.6,206.3,211.6,206.3z"/>
                    <path id="Path_29_" class="st4" d="M213.3,201.8c0,0-6.1-9.2-9.5-6S213.3,201.8,213.3,201.8z"/>
                    </g>
                    <g class="footer_plate">
                        <ellipse id="Oval_2_" class="st10" cx="134.1" cy="234" rx="119.7" ry="17.2"/>
                        <ellipse id="Oval_3_" class="st11" cx="132.7" cy="228" rx="99.8" ry="11.2"/>
                        <ellipse id="Oval_4_" class="st11" cx="200" cy="244.2" rx="32.4" ry="5.8"/>
                        <ellipse id="Oval_5_" class="st11" cx="167.9" cy="253" rx="12" ry="2.1"/>
                        <ellipse id="Oval_6_" class="st10" cx="144.2" cy="238.9" rx="19.1" ry="3.1"/>
                    <ellipse id="Oval_7_" class="st4" cx="137.8" cy="220.4" rx="55.3" ry="3.6"/>
                    </g>
                    <polygon id="Path_23_" class="st3 star" points="155.9,194.4 153.6,198.9 155.9,203.5 158.2,198.9 "/>
                    <polygon id="Path_24_" class="st3 star" points="197,204.3 194.7,208.9 197,213.5 199.3,208.9 "/>
                    <polygon id="Path_25_" class="st3 star" points="33.9,219.4 31.6,224 33.9,228.6 36.2,224 "/>
                    <polygon id="Path_26_" class="st3 star" points="209.8,48.4 207.6,53 209.8,57.6 212.1,53 "/>
                    <g class="footer-plate-circle">
                        <path id="Path_19_" class="st12" d="M193,227.1c0,0,7.8-20.6,22.3-19.5c14.5,1.1,17.8,18.3,13.4,19.5
                            C224.2,228.3,193,227.1,193,227.1z"/>
                        <path id="Path_20_" class="st13" d="M204.5,227.5c10.5-1.9,18.8-7.2,22.2-13.9c-2.8-3.5-6.9-5.6-11.4-5.9
                            c-14.5-1.1-22.3,19.5-22.3,19.5S198.1,227.3,204.5,227.5z"/>
                        <path id="Path_21_" class="st14" d="M228.2,227.1c0,0,12.6-1.1,6.9,1.1c-5.7,2.3-42.4-1.1-42.4-1.1H228.2z"/>
                        <path id="Path_48_" class="st12" d="M37.8,237.6c0,0,11.1-29.3,31.6-27.7s25.3,26,18.9,27.7C82,239.3,37.8,237.6,37.8,237.6z"/>
                        <path id="Path_49_" class="st13" d="M54.1,238.1c14.9-2.7,26.8-10.2,31.5-19.8c-4-4.9-9.9-8-16.2-8.4
                            c-20.6-1.6-31.6,27.7-31.6,27.7S45,237.9,54.1,238.1z"/>
                        <path id="Path_50_" class="st14" d="M87.8,237.6c0,0,17.9-1.6,9.8,1.6s-60.2-1.6-60.2-1.6H87.8z"/>
                        <path id="Path_51_" class="st12" d="M109.6,241.5c0,0,4.4-11.6,12.5-10.9c8.1,0.6,10,10.3,7.5,10.9
                            C127.1,242.1,109.6,241.5,109.6,241.5z"/>
                        <path id="Path_52_" class="st13" d="M116,241.7c5.9-1.1,10.5-4,12.4-7.8c-1.6-2-3.9-3.2-6.4-3.3c-8.1-0.6-12.5,10.9-12.5,10.9
                            S112.5,241.6,116,241.7z"/>
                        <path id="Path_53_" class="st14" d="M129.3,241.5c0,0,7.1-0.6,3.8,0.6s-23.8-0.6-23.8-0.6H129.3z"/>
                    <circle id="Oval_9_" class="st11" cx="252.4" cy="191.8" r="7.1"/>
                    <circle id="Oval_10_" class="st10" cx="251.5" cy="174.5" r="4.4"/>
                    </g>
                    <g class="bird">
                        <path id="Path_32_" class="st15" d="M98.6,87.7c0,0-106.5,35.9-87.5,38.9c19.1,3.1,58.5-11.6,58.5-11.6s-32.8,21.4-14.6,16
                            c18.2-5.3,27.3-11,27.3-11s-12.4,14.2-1.7,11.3l6.5-2.3c0,0-11.8,6.5-6.9,6.9c5,0.4,38.9-2.2,38.9-2.2l33.5-28.7L98.6,87.7z"/>
                        <path id="Path_33_" class="st15" d="M83.8,146.6c0,0-30.8-1.5-49.6,7.2c-18.8,8.7,38.2,2.1,38.2,2.1s-35.3,7.5-32.5,12.1
                            c2.8,4.6,39.4-6.1,39.4-6.1s-26.3,17.5-37.1,21.5c-10.8,4.1,6.9,9.9,47.5-18.1c0,0,28.8,24.2,71.7,3.8s59.9-52.5,57-69.3
                            c-2.1-12.5-12.5-24.9-26.1-23.1c-13.6,1.8-23.7,13.2-29.4,26.7S83.8,146.6,83.8,146.6z"/>
                        <path id="Path_34_" class="st16" d="M192.4,76.7c-13.6,1.9-23.7,13.2-29.4,26.7c-4.5,10.7-51.5,31.5-70.9,39.7
                            c22.4,5.7,50,3.8,77-7.1c20.2-8.1,37.2-20.1,49.6-33.8c0-0.8-0.1-1.6-0.3-2.5C216.5,87.3,206,74.9,192.4,76.7z"/>
                        <path id="Path_35_" class="st17" d="M203.3,78.4c-0.3-0.1-12-3.7-6,13c6.1,16.9-13.9,34.3-13.9,34.3l32.7-8.4l0,0
                            c2.5-6.6,3.3-12.7,2.5-17.4C217,90.9,211.3,82.1,203.3,78.4z"/>
                        <path id="Path_36_" class="st18" d="M218.2,98c-0.1-0.4-0.2-0.8-0.3-1.2l-0.1-0.3c-0.1-0.5-0.3-1-0.5-1.5c0-0.1-0.1-0.2-0.1-0.3
                            c-0.1-0.4-0.3-0.8-0.5-1.2c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.5-0.4-1-0.6-1.4c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.4-0.9-0.7-1.3l-0.1-0.3
                            c-0.5-0.9-1.1-1.9-1.7-2.7l-0.1-0.2c-0.3-0.4-0.6-0.8-0.9-1.2v-0.1c-0.3-0.4-0.7-0.9-1.1-1.3l0,0c-0.3-0.4-0.7-0.8-1.1-1.1
                            l-0.1-0.1c-0.8-0.8-1.6-1.5-2.5-2.2l-0.1-0.1c-0.9-0.7-1.8-1.3-2.7-1.8l0,0c-0.5-0.3-1-0.5-1.4-0.7c-0.3-0.1-12-3.7-6,13
                            c6.1,16.9-13.9,34.3-13.9,34.3l11.5-2.9c8.8-5.8,16.9-12.6,24.1-20.4c0-0.1,0-0.1,0-0.2c0-0.3,0-0.6-0.1-0.8V101
                            c0-0.4-0.1-0.7-0.1-1.1c-0.1-0.4-0.2-1-0.3-1.5C218.2,98.2,218.2,98.1,218.2,98z"/>
                        <path id="Path_37_" class="st19" d="M210.4,89c0,0,11.8-11.6,11.1-8.3c-0.7,2.6-1.6,5.1-2.7,7.5c0,0,9.9-2.6,7.2,0.3
                            c-2.7,2.9-10.6,8.1-10.6,8.1S212.1,97.1,210.4,89z"/>
                        <path id="Path_38_" class="st3" d="M216,117.3c-15.7,1-38.5,9-61.5,22.8c-18.6,11.1-33.9,23.8-43.5,35.3
                            c12.5,3.2,29.9,3.7,50.5-6.2C192.2,154.5,209.6,134,216,117.3z"/>
                        <path id="Path_60_" class="st3" d="M203.1,137c0,0-3,13.9-4,11.6l-1-2.4l-5.6,1.4L203.1,137z"/>
                        <path id="Path_39_" class="st20" d="M151.3,142c6.1-1.6,12.1-3.6,18-5.9c10.1-4,19.7-9.2,28.6-15.4c-13,3.7-28.1,10.3-43.3,19.3
                            C153.4,140.7,152.3,141.4,151.3,142z"/>
                        <path id="Path_40_" class="st15" d="M104.9,137.7c0,0-1.8,14.4,60.7-18.1l-41.7,9.4L104.9,137.7z"/>
                        <path id="Path_41_" class="st16" d="M166.4,118.6c-3.1-9.3-3.2-19.3-0.3-28.6c5-15.6,8.5-20.2,1.3-24.1
                            C160.2,62.1,68.1,55,26.7,84.8s66.1-2,66.1-2S44,100.5,54.3,106s58.4-2.9,58.4-2.9s-23.7,17.8-10.4,15.8c10-1.6,19.7-4.9,28.6-9.7
                            c0,0-31.3,20.6-14.7,16.3c8.4-2.1,16.7-4.7,24.8-7.6C141,118,98.8,137.4,166.4,118.6z"/>
                        <path id="Path_42_" class="st21" d="M202.6,90.4c-0.2,0-0.4-0.1-0.5-0.3c0-0.1-1.5-2.5-1.1-4.3c0.1-0.6,0.5-1.2,1.1-1.5
                            c0.5-0.4,1.2-0.5,1.8-0.3c1.5,0.5,2.4,2.7,2.5,3c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.2-0.5-0.4
                            c-0.2-0.5-0.9-2-1.7-2.3c-0.3-0.1-0.6,0-0.8,0.1c-0.3,0.2-0.5,0.5-0.5,0.8c0,1.2,0.3,2.4,1,3.4c0.2,0.3,0.1,0.6-0.2,0.8
                            C202.8,90.4,202.7,90.4,202.6,90.4z"/>
                        
                            <ellipse id="Oval_8_" transform="matrix(0.9278 -0.3731 0.3731 0.9278 -21.7982 80.5148)" class="st22" cx="197.1" cy="96.6" rx="3.9" ry="1.5"/>
                        <path id="Path_43_" class="st23" d="M218.8,88.2l-1.5,3.1c0,0,8.9-2.9,8.2-4.1C224.7,85.9,218.8,88.2,218.8,88.2z"/>
                        <path id="Path_44_" class="st24" d="M88.6,74c42.3-2.9,76.5-6.8,77.3-8.6c-1.4-0.4-2.9-0.7-4.3-0.9c-10.6-0.6-39.5,0.5-73.5,2.9
                            c-8.8,0.6-17.3,1.3-25.2,1.9c-7.4,1.8-14.7,4.3-21.7,7.3C54.3,76.1,70.7,75.3,88.6,74z"/>
                        <path id="Path_45_" class="st24" d="M163.1,103.5c0,0,20.7-14,29.3-26.7C192.4,76.7,173.9,76.1,163.1,103.5z"/>
                        <path id="Path_46_" class="st24" d="M107.4,81c0,0,37.5-4.4,47.6-6.5c10.1-2.1-12.2,14.1-27.2,12.9C112.8,86.3,107.4,81,107.4,81z"
                            />
                    </g>
                </svg>
        `
    }}></span>
    
    
);

const Evening = () => (
    <span dangerouslySetInnerHTML={{__html: 
        `
        <?xml version="1.0" encoding="utf-8"?>
            <svg class="evening-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 260 249" style="enable-background:new 0 0 260 249;" xml:space="preserve">
                <style type="text/css">
                    .st0{fill:#B3BAFF;}
                    .st1{fill:#A683EA;}
                    .st2{opacity:0.8;fill:#CAD0FC;enable-background:new    ;}
                    .st3{opacity:0.4;fill:#FFFFFF;enable-background:new    ;}
                    .st4{fill:#FFFFFF;}
                    .st5{fill:#E0D2F9;}
                    .st6{fill:#2E2554;}
                    .st7{fill:#FD7C98;}
                    .st8{opacity:0.36;}
                    .st9{fill:#0B3D3F;}
                    .st10{fill:#B33651;}
                    .st11{opacity:0.3;}
                    .st12{opacity:0.3;fill:none;stroke:#0B3D3F;stroke-width:1.7257;stroke-linecap:round;enable-background:new    ;}
                    .st13{fill:#8471F4;}
                    .st14{fill:#5648AA;}
                    .st15{fill:#FC81B1;}
                    .st16{fill:#BD6134;}
                    .st17{fill:#BE6235;}
                    .st18{fill:#F29E81;}
                    .st19{fill:#703525;}
                    .st20{opacity:0.32;fill:#703525;enable-background:new    ;}
                    .st21{fill:#FFCC68;}
                    .st22{fill:#FCE170;}
                    .st23{fill:#FF5F27;}
                    .st24{fill:#D1400D;}
                    .st25{fill:#FFAB15;}
                    .st26{fill:#BDABBD;}
                    .st27{fill:#EAD3E4;}
                </style>
                <g class='back'>
                    <ellipse id="Oval" class="st0" cx="134.9" cy="115.7" rx="116.6" ry="115.7"/>
                    <path id="Path" class="st1" d="M251.3,108.6c-0.2-3.5-0.6-6.8-1.1-10.2l0,0c0,0-41.7,128.8-120.4,130.8l4.8,2.1
                        c2.5,0,4.9-0.1,7.4-0.2C206.3,227.2,255.2,172.4,251.3,108.6z"/>
                    <path id="Path_1_" class="st2" d="M202.3,62.8c0-16.2-3.1-32.3-9.1-47.3C173.3,4.1,150.6-1.2,127.8,0.2
                        C63.5,4.2,14.6,59,18.6,122.8c1.4,22.7,9.4,44.4,23.2,62.5c38.2,10.5,79.1,2.6,110.6-21.5S202.3,102.4,202.3,62.8z"/>
                    <path id="Path_2_" class="st3" d="M193.2,15.5c-9.7-5.6-20.2-9.7-31.1-12.3c8,2.5,15.8,5.9,23.1,10.1c17.4,43.4,9.6,93-20.5,128.8
                        s-77.5,52.3-123.3,42.7l0.2,0.3c46.5,12.8,96.3-1.9,128.4-37.8S211.1,60.3,193.2,15.5z"/>
                    <circle id="Oval_1_" class="st4" cx="64" cy="131.9" r="13.8"/>
                    <circle id="Oval_2_" class="st5" cx="64" cy="133.8" r="13.8"/>
                    <path id="Path_3_" class="st4" d="M167.3,60.2L0,107.3c0,0,25.6-67.9,53.6-55.9S81.7,84.3,94,74.9s25.9-21.2,39.7-19.5
                        S140.4,63.3,167.3,60.2z"/>
                    <path id="Path_5_" class="st4" d="M249.2,134.8L26.2,203c0,0,29.9-79.1,62.5-65.1s60.8,24.9,75.2,14s30.2-24.7,46.3-22.7
                        C226.2,131.3,217.7,138.4,249.2,134.8z"/>
                    <path id="Path_68_" class="st4" d="M199,81.9l32.7-8.6c-6-0.4-11.7-2.7-16.3-6.6C208,60.3,199,81.9,199,81.9z"/>
                    <path id="Path_4_" class="st6" d="M61.7,190.8c0,0,47.8-99.2,63.9-72.7c10,16.6,19,33.9,26.9,51.6c0,0,38.5-46.9,41.9-22.1
                        c3.3,24.8,3.3,28.5,3.3,28.5s38.3-47.2,38.6-13s-32.1,64.4-32.1,64.4L61.7,190.8z"/>
                </g>
                <g class='back-1'>
                    <path id="Path_6_" class="st7" d="M157.7,223.5c0,0,53.3-150.4,70.2-134S212,180.6,212,180.6s25.9-29.7,37.8-42s13.9,38.9-3,55.2
                        s-34.8,24.6-60.7,28.6L157.7,223.5z"/>
                    <g id="Group" transform="translate(173.716814, 106.991150)" class="st8">
                        <path id="Path_7_" class="st9" d="M42.2,1.7c-0.2,7.8-2.6,15.2-5,22.5s-5,14.4-7.7,21.5C24.2,60,18.3,74,12,87.8
                            c-3.6,7.7-7.3,15.4-11.3,23c-0.5,1,1,1.9,1.5,0.9c14.1-27.3,26.1-55.7,36-84.8c2.7-8.1,5.6-16.4,5.8-25.1c0-0.5-0.4-0.9-0.9-0.9
                            S42.2,1.3,42.2,1.7L42.2,1.7z"/>
                    </g>
                    <g id="Group_1_" transform="translate(207.079646, 138.053097)" class="st8">
                        <path id="Path_8_" class="st9" d="M1.2,7.4c4.5-0.8,8.7-2.6,12.4-5.3c0.9-0.6,0-2.1-0.9-1.5C9.2,3.2,5.1,5,0.7,5.8
                            C-0.4,5.9,0.1,7.6,1.2,7.4L1.2,7.4z"/>
                    </g>
                    <g id="Group_2_" transform="translate(192.123894, 151.858407)" class="st8">
                        <path id="Path_9_" class="st9" d="M0.2,2C1,6.8,2.6,11.4,4.8,15.7c0.5,1,2,0.1,1.5-0.9C4.1,10.6,2.7,6.2,1.9,1.6
                            C1.7,0.5,0,0.9,0.2,2z"/>
                    </g>
                    <path id="Path_10_" class="st10" d="M220.4,219.6c0,0,27.2-14.6,15.6-14.7c-11.6-0.1-16.1,2.5-16.1,2.5s20.5-36.7,2.3-30.9
                        s-20,33.5-20,33.5s-12.8-28.4-14.6-15.8c-1.9,12.6,5.3,39.4,5.3,39.4L220.4,219.6z"/>
                    <circle id="Oval_13_" class="st6" cx="216.4" cy="183" r="7.8"/>
                    <path id="Path_61_" class="st6" d="M205.9,213.7c-0.6,0-1.2-0.3-1.5-0.9c-0.3-0.5-0.3-1.2,0-1.7c4.6-8.5,7.8-17.6,9.5-27
                        c0.2-0.9,1.1-1.6,2-1.4s1.6,1.1,1.4,2c-1.8,9.8-5.2,19.3-9.9,28.1C207.1,213.4,206.5,213.7,205.9,213.7L205.9,213.7z"/>
                    <path id="Path_62_" class="st6" d="M211.4,198.5c0,0,20.1-5.4,13,0.9C217.3,205.7,211.4,198.5,211.4,198.5z"/>
                    <path id="Path_63_" class="st6" d="M209,206c0,0,16.9,2,12.7,4.7S209,206,209,206z"/>
                    <path id="Path_64_" class="st6" d="M210.8,201.5c0,0-6.1-9.3-9.5-6S210.8,201.5,210.8,201.5z"/>
                    <path id="Path_11_" class="st7" d="M19.7,185c0,0-3.1,10.5-5.4,3.9s3.9-19.2-1.6-18.7s-27.4,47.9,10.3,63.6l22.5-11.4L19.7,185z"/>
                    <path id="Path_12_" class="st7" d="M19.1,110.2c0,0-10.7,86.9,17.4,117.6l67.8-2.6C93.7,210,86,193,81.5,175
                        c-7.5-30.1-10.4-39.7-14.3-18.3s-7,38.8-14.9,12.7S35.7,85.1,28.5,88.5C21.4,91.8,19.1,110.2,19.1,110.2z"/>
                    <g id="Group_3_" transform="translate(27.610619, 101.088496)" class="st11">
                        <path id="Path_13_" class="st9" d="M0.4,0.9c-1.8,39.1,5.9,78,22.4,113.5c0.5,1,2,0.1,1.5-0.9C8,78.3,0.4,39.7,2.2,0.9
                            C2.2-0.3,0.5-0.3,0.4,0.9L0.4,0.9z"/>
                    </g>
                    <g id="Group_4_" transform="translate(23.008850, 170.265487)" class="st11">
                        <path id="Path_14_" class="st9" d="M1,1.6c4.1,3.3,8.5,6.2,13.1,8.8c0.4,0.2,0.9,0,1.1-0.4s0.1-0.8-0.2-1
                            C10.5,6.4,6.2,3.6,2.2,0.4C1.4-0.3,0.1,0.9,1,1.6L1,1.6z"/>
                    </g>
                    <g id="Group_5_" transform="translate(29.911504, 133.300885)" class="st11">
                        <path id="Path_15_" class="st9" d="M2.1,12c2-3.6,4.1-7.2,6.3-10.7C9,0.4,7.5-0.4,6.9,0.5C4.7,4,2.6,7.5,0.6,11.1
                            C0.1,12.1,1.6,13,2.1,12L2.1,12z"/>
                    </g>
                    <g id="Group_6_" transform="translate(23.008850, 131.150442)" class="st11">
                        <path id="Path_16_" class="st9" d="M1,1.9l4.3,4.3c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.9,0-1.2L2.2,0.7C1.8,0.4,1.3,0.4,1,0.7
                            C0.6,1,0.6,1.6,1,1.9L1,1.9z"/>
                    </g>
                    <path id="Path_17_" class="st10" d="M108.3,217c0,0,5.9-18.8-7.9-24.9c-13.8-6.1-26.6-2.7-21.1,4.2s4.2,15.4-3.5,6.6
                        s-5.8-18.7-7.9-29.1c-2.1-10.4-3.3-42.7-15.2-27.6c-11.9,15.1-9.3,51.4-5,58.7s-11.2,10.8-11.2,0.4c0-10.4,3-31.8-6.9-17.8
                        s-13.3,37.6,6.9,48.4S108.3,217,108.3,217z"/>
                    <path id="Path_18_" class="st12" d="M57.2,155.8c0,0-4.7,36.4,7.6,60"/>
                    <path id="Path_24_" class="st7" d="M6.5,167.6c0,0,7.4-7.8,4.5-15.2s-4.2-2.4-4.5,3.5C6.2,161.7,6.5,167.6,6.5,167.6z"/>
                    <path id="Path_25_" class="st10" d="M236.3,182.1c0,0,7.4-7.8,4.5-15.2c-2.9-7.5-4.2-2.4-4.5,3.5
                        C236,176.2,236.3,182.1,236.3,182.1z"/>
                    <path id="Path_57_" class="st7" d="M58.9,132.7c0,0-3.8-4.5,0-14s7.2-4.5,7.2,1.2S60.4,141.2,58.9,132.7z"/>
                    <path id="Path_69_" class="st7" d="M236.2,102c0,0-1.9-15.7,2.7-13.8C243.4,90.1,236.2,102,236.2,102z"/>
                    <line id="Path_70_" class="st12" x1="50.2" y1="172.6" x2="56.5" y2="179.7"/>
                    <line id="Path_71_" class="st12" x1="58" y1="194.1" x2="64" y2="184.3"/>
                    <path id="Path_72_" class="st13" d="M244.3,205.3c0,0,7.3-13.6,10.4-9.9C257.6,199,244.3,205.3,244.3,205.3z"/>
                    <path id="Path_73_" class="st13" d="M9,140c0,0-7.1-7.8-4.9-14.2s7.3-7.2,6.9-0.5C10.6,130.2,10,135.1,9,140L9,140z"/>
                    <circle id="Oval_14_" class="st6" cx="71.9" cy="181.2" r="7.8"/>
                    <path id="Path_75_" class="st6" d="M82.4,211.9c-0.6,0-1.2-0.3-1.5-0.9c-4.8-8.8-8.1-18.3-9.9-28.1c-0.1-0.6,0.1-1.2,0.6-1.6
                        c0.5-0.4,1.1-0.5,1.7-0.3s1,0.7,1.1,1.3c1.8,9.4,5,18.6,9.5,27c0.5,0.8,0.2,1.9-0.6,2.4C83,211.8,82.7,211.9,82.4,211.9z"/>
                    <path id="Path_76_" class="st6" d="M77,196.7c0,0-20.1-5.4-13,0.9S77,196.7,77,196.7z"/>
                    <path id="Path_77_" class="st6" d="M79.3,204.2c0,0-16.9,2-12.7,4.7C70.9,211.7,79.3,204.2,79.3,204.2z"/>
                    <path id="Path_78_" class="st6" d="M77.6,199.7c0,0,6.1-9.2,9.5-6C90.6,196.9,77.6,199.7,77.6,199.7z"/>
                </g>
                <g class="footer_plate">
                    <ellipse id="footer_plate-1" class="st14" cx="131.3" cy="231.5" rx="120.2" ry="17.3"/>
                    <ellipse id="Oval_4_" class="st13" cx="129.8" cy="224.4" rx="100.2" ry="11.3"/>
                    <rect id="Rectangle" x="120.8" y="202" class="st13" width="31.5" height="17"/>
                </g>
                <g class="bg-element">
                    <polygon id="Path_21_" class="st6" points="160.5,198.5 196.2,182.1 157.3,208.6 "/>
                    <path id="Path_22_" class="st15" d="M178.7,190.1c0,0-11-1.6-3.5-11.7C175.3,178.5,184.3,179.8,178.7,190.1z"/>
                    <path id="Path_29_" class="st16" d="M151.9,162.1L151.9,162.1c-20.4,20.4-35.3,8.2-41.6,0.5c-0.3,2.4-0.5,4.7-0.4,7.1
                        c0,1.9,0.1,3.7,0.3,5.5c10.7,9.4,21.1,12,21.1,12c5-1.2,10.9-4.7,16.1-8.9L151.9,162.1z"/>
                </g>
                <g class="bird">
                    <path id="Path_19_" class="st6" d="M165.6,179.7l-68.9,5.5l2.4,34L86,227.6l46.7-1.5c0,0-9.8-25.5-2.6-16.4
                        c7.2,9.2,10.8,14.5,10.8,14.5s4.1-17.6,5-9.7c0.6,4.1,0.6,8.2,0,12.2l29.4-0.9l-14.9-7.5L165.6,179.7z"/>
                    <path id="Path_20_" class="st13" d="M96.7,185.2l68.9-5.5C165.6,179.7,132.6,198.9,96.7,185.2z"/>
                    <polygon id="Path_23_" class="st13" points="104.3,187.6 104.3,214.3 105.4,185.9 	"/>
                    <path id="Path_26_" class="st17" d="M159.2,57.5l-51.4-3.2c-14.9,7.6-25,22.4-25,39.4c0,24.8,21.5,45,48,45s48-20.1,48-45
                        C178.8,78.8,171.1,65.7,159.2,57.5z"/>
                    <path id="Path_27_" class="st16" d="M161.5,59c-1.5-2-3.1-3.8-5-5.4l-1.8,0.6h-46.6C96.9,64.3,90,80.5,88,94.1
                        c-11.9,79.3,43.2,93.2,43.2,93.2c9.9-2.3,23.3-13.8,28.3-21.9c11.8-19,11.4-21.7,14.4-43.7C176.7,101.4,174.5,76.5,161.5,59z"/>
                    <path id="Path_28_" class="st18" d="M131.2,134.9c-11.8,0-21.3,15.6-21.3,34.8c0,1.9,0.1,3.7,0.3,5.5c10.7,9.4,21.1,12,21.1,12
                        c6.6-1.6,14.9-7.2,21.1-13.2c0.1-1.4,0.2-2.8,0.2-4.2C152.4,150.5,142.9,134.9,131.2,134.9z"/>
                    <path id="Path_30_" class="st19" d="M98.2,125c-4.6-0.5-9-2.1-12.9-4.6c0,0,0.6,9.8-1.3,6.8s-2.1-8.8-2.1-8.8s-1.5,9.6-2.9,4.7
                        S64.6,97,86.4,73.9L98.2,125z"/>
                    <path id="Path_31_" class="st19" d="M96.8,170.2c0,0,3.2,10.8,0,7.5S81,151.4,82,128.6L96.8,170.2z"/>
                    <path id="Path_32_" class="st19" d="M84.4,119.7c0,0-11.4,10.1-9,11.1s9-3.9,9-3.9V119.7z"/>
                    <path id="Path_33_" class="st19" d="M87.8,118.4c0,0,20.4,18.7,18.7,27.8c-1.7,9.1-9.2-6.2-9.2-6.2s27.3,45.3,18.1,44.9
                        c-9.2-0.4-25.9-20.2-30.7-38.7C84.6,146.1,76.9,118.3,87.8,118.4z"/>
                    <path id="Path_34_" class="st16" d="M171.5,70.7c0,0,17.6-16.6,16.5-11.1s-6.6,10.5-6.6,10.5s22,5.2,11.3,4.9
                        c-10.6-0.3-13.4-0.7-13.4-0.7s12.8,7.3,7.3,7.1s-8-1.4-8-1.4l-8.2-9.9L171.5,70.7z"/>
                    <path id="Path_35_" class="st16" d="M93.4,67.6c0,0-17.6-16.6-16.5-11.1S83.5,67,83.5,67s-22,5.2-11.3,4.9s13.4-0.7,13.4-0.7
                        s-12.8,7.3-7.3,7.1s8.1-1.4,8.1-1.4l8.2-9.9L93.4,67.6z"/>
                    <path id="Path_36_" class="st19" d="M126.2,113.3c0,0,1.2,10.7,4.4,14.6c0,0,4.2-8.6,4.6-15.6l-4.2-12.2L126.2,113.3z"/>
                    <path id="Path_37_" class="st20" d="M100.7,73.3c-2.3,0-4.5,0.3-6.7,0.9c-2.9,6.3-4.9,13-6,19.9c-1.9,11.5-2.2,23.1-0.9,34.6
                        c4,2.7,8.8,4.1,13.6,4.1c14.9,0,26.9-13.3,26.9-29.8S115.5,73.3,100.7,73.3z"/>
                    <path id="Path_38_" class="st20" d="M161.3,73.3c2.3,0,4.5,0.3,6.7,0.9c2.9,6.3,4.9,13,6,19.9c1.9,11.5,2.2,23.1,0.9,34.6
                        c-4,2.7-8.8,4.1-13.6,4.1c-14.9,0-26.9-13.3-26.9-29.8S146.5,73.3,161.3,73.3z"/>
                    <ellipse id="Oval_5_" class="st21" cx="104.3" cy="96.1" rx="26.9" ry="29.8"/>
                    <ellipse id="Oval_6_" class="st22" cx="111.6" cy="95.9" rx="19.6" ry="21.3"/>
                    <ellipse id="Oval_7_" class="st21" cx="158.1" cy="96.1" rx="26.9" ry="29.8"/>
                    <path id="Path_39_" class="st19" d="M131.1,94.6c0,0-0.9-30.1-23.5-40.4s0,2.3,0,2.3s-9.8-4.8-17.3-1.4
                        c-7.5,3.3,11.6,8.1,19.6,11.2C117.3,69.2,128.6,75.8,131.1,94.6z"/>
                    <path id="Path_40_" class="st19" d="M131.2,94.6c0,0,0.9-30.1,23.5-40.4s0,2.3,0,2.3s9.8-4.8,17.3-1.4s-11.6,8.1-19.6,11.2
                        C145,69.2,133.7,75.8,131.2,94.6z"/>
                    <ellipse id="Oval_8_" class="st22" cx="150.8" cy="95.9" rx="19.6" ry="21.3"/>
                    <ellipse id="Oval_9_" class="st9" cx="114.2" cy="98.9" rx="5.7" ry="8"/>
                    <ellipse id="Oval_10_" class="st4" cx="115.6" cy="97.4" rx="1.7" ry="2.7"/>
                    <ellipse id="Oval_11_" class="st9" cx="144.2" cy="98.9" rx="5.7" ry="8"/>
                    <ellipse id="Oval_12_" class="st4" cx="142.8" cy="97.4" rx="1.7" ry="2.7"/>
                    <path id="Path_41_" class="st23" d="M136.4,104.8c0,0-4.8-9.4-10.2,0c0,0-0.3,6.5,4.4,10.1C133.7,112.3,135.7,108.7,136.4,104.8
                        L136.4,104.8z"/>
                    <path id="Path_42_" class="st24" d="M136.4,104.8c0,0-2.5,4.9-5.3,6.2c0,0-2.9-1.2-4.8-6.2c-0.3,3.9,1.4,7.7,4.4,10.1
                        C130.7,114.9,136,110.3,136.4,104.8z"/>
                    <path id="Path_43_" class="st19" d="M164.2,125c11.9-3.1,20.8-14.9,20.8-29c0-13.9-8.6-25.6-20.3-28.9l0,0
                        c12.6,5,19.2,20.6,16.7,36.6C179.4,116.5,168.5,122.9,164.2,125z"/>
                    <path id="Path_44_" class="st19" d="M184.7,100.1c0,0,2.3,12.5,0.8,10.9c-0.9-0.9-1.6-2-1.9-3.3c0,0-2.6,10.9-2.9,8.1
                        c-0.2-1.6-0.2-3.3,0-4.9L184.7,100.1z"/>
                    <path id="Path_45_" class="st19" d="M164.7,170.2c0,0-3.2,10.8,0,7.5s15.8-26.3,14.8-49.1L164.7,170.2z"/>
                    <path id="Path_46_" class="st19" d="M177.1,119.7c0,0,11.4,10.1,8.9,11.1s-8.9-3.9-8.9-3.9V119.7z"/>
                    <path id="Path_47_" class="st19" d="M173.6,118.4c0,0-20.4,18.7-18.7,27.7s9.2-6.2,9.2-6.2s-27.3,45.3-18.1,45
                        c9.2-0.4,25.9-20.2,30.7-38.7C176.8,146.1,184.6,118.3,173.6,118.4z"/>
                    <path id="Path_48_" class="st18" d="M107.6,54.2h47.1C154.7,54.2,131.5,69.5,107.6,54.2z"/>
                    <path id="Path_49_" class="st18" d="M171.5,70.7c0,0,8.1-8.1,14.6-11.8C186,58.9,182.1,69.4,171.5,70.7z"/>
                    <path id="Path_50_" class="st18" d="M181.4,70.1l6,1.4h0.1l5.5,1.8C193,73.2,185.5,73.6,181.4,70.1z"/>
                    <path id="Path_51_" class="st18" d="M93.4,67.6c0,0-7.5-7.4-13.2-11C74.5,53,84.1,66.6,93.4,67.6z"/>
                    <path id="Path_52_" class="st18" d="M83.5,67c0,0-7.5,1.7-10.6,2.9C72.9,69.8,80.4,71.4,83.5,67z"/>
                    <path id="Path_53_" class="st19" d="M77.4,78.1l8.9-4.2l-3.1,3.7C81.3,78.1,79.3,78.3,77.4,78.1L77.4,78.1z"/>
                    <path id="Path_54_" class="st19" d="M178.2,76.2c0,0,11.4,4.7,9,5c-2.2,0.1-4.4-0.1-6.6-0.6L178.2,76.2z"/>
                    <path id="Path_55_" class="st25" d="M130.1,188.2c0,0-6.3-10.4-8.9-7.9l-2.6,2.5c0,0-7.8-8.5-9.2-2.9c-1.4,5.6,4.9,18.4,6.3,15.9
                        s0-11.5,0-11.5s4.4,16.6,5,9.9s1.2-10.5,1.2-10.5L130.1,188.2z"/>
                    <path id="Path_56_" class="st25" d="M131.3,188.2c0,0,6.3-10.4,9-7.9l2.7,2.5c0,0,7.8-8.5,9.3-2.9c1.4,5.6-4.9,18.4-6.3,15.9
                        c-1.4-2.5,0-11.5,0-11.5s-4.4,16.6-5,9.9c-0.6-6.8-1.2-10.5-1.2-10.5L131.3,188.2z"/>
                <path id="Path_74_" class="st6" d="M186.8,196.4c0,0-9.4-6.9-10.8-2.2C174.6,199,186.8,196.4,186.8,196.4z"/>
                </g>
                <g class="footer-plate-circle">
                    <path id="Path_58_" class="st26" d="M154.6,235.8c0,0,6.4-17,18.4-16.1s14.7,15.1,11,16.1S154.6,235.8,154.6,235.8z"/>
                    <path id="Path_59_" class="st27" d="M164.1,236.1c8.7-1.6,15.6-5.9,18.3-11.5c-2.3-2.9-5.7-4.6-9.4-4.9
                        c-12-0.9-18.4,16.1-18.4,16.1S158.8,235.9,164.1,236.1z"/>
                    <path id="Path_60_" class="st6" d="M183.6,235.8c0,0,10.4-0.9,5.7,0.9c-4.7,1.9-35-0.9-35-0.9H183.6z"/>
                    <path id="Path_65_" class="st26" d="M190.7,220.9c0,0,4.2-11.1,11.9-10.4c7.8,0.6,9.5,9.8,7.2,10.4
                        C207.4,221.5,190.7,220.9,190.7,220.9z"/>
                    <path id="Path_66_" class="st27" d="M196.9,221.1c5.6-1,10.1-3.9,11.9-7.5c-1.5-1.9-3.7-3-6.1-3.2c-7.8-0.6-11.9,10.4-11.9,10.4
                        S193.4,221,196.9,221.1z"/>
                    <path id="Path_67_" class="st6" d="M209.6,220.9c0,0,6.8-0.6,3.7,0.6s-22.7-0.6-22.7-0.6H209.6z"/>
                    <path id="Path_79_" class="st26" d="M50.2,233.1c0,0,11.1-29.4,31.8-27.8c20.7,1.6,25.4,26.2,19.1,27.8
                        C94.7,234.8,50.2,233.1,50.2,233.1z"/>
                    <path id="Path_80_" class="st27" d="M66.7,233.6c15-2.8,26.9-10.3,31.6-19.8c-4-5-9.9-8-16.3-8.4c-20.7-1.6-31.8,27.8-31.8,27.8
                        S57.5,233.4,66.7,233.6z"/>
                    <path id="Path_81_" class="st6" d="M100.5,233.1c0,0,18-1.6,9.8,1.6s-60.5-1.6-60.5-1.6H100.5z"/>
                </g>
            </svg>
        `
    }}></span>
    
    
);

export default function LandingPage() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const { currentUser, filteredMenu } = useSelector((state) => state.user);
    const { guestsetting } = useSelector((state) => state.setting)
    const {currentLayoutObject} = useSelector((state) => state.layout)

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setTitle("Home")
        setHasHeader(true)
        setNavigation([
            {name: 'Dashboard'},
        ])

        getLandingAnalytics()
    }, [])

    const getLandingAnalytics = () => {
        setLoading(true)
        API.getLandingAnalytics()
        .then(res => {
            setData(res.data?.data || {})
            setLoading(false)
        }).catch(err => {
            throwServerError(err)
            setLoading(false)
        })
    }

    const time = (moment().format("HH") >= "05" && moment().format("HH:mm") <= "11:59") 
        ? 'Morning'
        : (moment().format("HH") >= "12" && moment().format("HH:mm") <= "15:59") 
        ? 'Afternoon'
        : 'Evening'

    const navigate = useNavigate()
    return (
        <div className='fadeIn d-block surface-850 br-4px p-20px mx-auto h-89vh w-75 overflow-scroll noscrollbar'>
            <div className='row mx-0 h-40'>
                <div className='col-md-6 ps-0 h-100'>
                    <div className={`${time}-time h-100 br-4px surface-875 position-relative d-flex align-items-start justify-content-between`}>
                        {time === 'Evening' && <div className='animationContainer'>
                            <div className="stars"></div>
                            <div className="twinkleMask"></div>
                            <div className="twinkleMask2"></div>
                            <div className="clouds"></div>
                        </div>}

                        {time === 'Afternoon' && <div className='afternoonAnimation'></div>}
                        {time === 'Morning' && <div className='morningAnimation'></div>}
                        <div className='d-flex justify-content-between flex-column h-86 ml-16px mt-16px'
                            style={{zIndex: 6}}>
                            <div>
                                <p className='fs-30px fw-600 text-200'>
                                    Good {time},
                                </p>

                                <p className='text-capitalize fs-20px fw-500 text-100'>
                                    {currentUser.username}
                                </p>
                            </div>

                            <div>
                                <p className='fs-11px fw-400 text-200'>
                                    Workspace
                                </p>

                                <p className='text-uppercase fs-16px text-100 fw-500'>
                                    {guestsetting.workspaceId}
                                </p>
                            </div>
                        </div>

                        <div className='dayNightBomma'>
                            {time === 'Morning'
                            ? <Morning />
                            : time === 'Afternoon'
                            ? <Afternoon />
                            : <Evening /> }
                        </div>
                    </div>
                </div>
                <div className='col-md-6 pe-0 h-100'>
                    <div className='bg-primary-color br-4px d-flex justify-content-between flex-column w-100 h-100 p-16px'>
                        <p className='text-100 fs-30px fw-600'>{unslugifyText(currentUser.role || currentUser?.userRole)} user</p>

                        <div>
                            <p className='text-200 fs-24px fw-500 mb-14px'>
                                Welcome to {currentLayoutObject?.sitename || ''}
                            </p>

                            <p className='text-300 fs-14px fw-400 mb-1'>
                                Welcome to the {currentLayoutObject?.sitename || ''}. 
                                You'll find comprehensive guides and documentation to help you start working with our APIs as quickly as possible. Let's jump right in!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='surface-875 mt-20px w-100 h-57 p-24px'>
                <p className='fs-18px text-200 fw-500'>Total Numbers</p>

                {loading
                ? <Loading />
                : <div className='mt-26px'>
                    <div className='row mx-0'>
                        <div className='col-md-6 ps-0'>
                            <div className='px-18px py-16px dfaic justify-content-between surface-base br-10px' style={{height: 130}}>
                                <div className='dfaic'>
                                    <BookOpen weight='thin' size={44} color='#e4e4e4' className='mb-2' />

                                    <div className='ml-16px mt-1'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>Guides</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {data.guides || 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px h-fit'
                                    onClick={() => navigate(`/api-guides`)}>
                                    <p className='fs-12px text-300 fw-500'>View Guides</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 px-0'>
                            <div className='surface-base br-10px px-18px py-16px dfaic justify-content-between'
                                style={{height: 130}}>
                                <div className='dfaic'>
                                    <CalendarBlank weight='thin' size={44} color='#e4e4e4' />

                                    <div className='ml-16px mt-1'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>Events</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {data.events || 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px h-fit'
                                    onClick={() => navigate(`/events`)}>
                                    <p className='fs-12px text-300 fw-500'>View Events</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mx-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <div className='surface-base br-10px px-18px py-16px dfaic justify-content-between'
                                style={{height: 130}}>
                                <div className='dfaic'>
                                    <Nut weight='thin' size={44} color='#e4e4e4' className='mb-2' />

                                    <div className='ml-16px mt-1'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>SDKs</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {data.sdks || 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px h-fit'
                                    onClick={() => navigate(`/sdk-tools`)}>
                                    <p className='fs-12px text-300 fw-500'>View SDKs</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 px-0'>
                            <div className='surface-base br-10px px-18px py-16px dfaic justify-content-between'
                                style={{height: 130}}>
                                <div className='dfaic'>
                                    <PuzzlePiece weight='thin' size={44} color='#e4e4e4' />

                                    <div className='ml-16px mt-2'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>Usecases</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {data.useCases || 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px h-fit'
                                    onClick={() => navigate(`/usecases`)}>
                                    <p className='fs-12px text-300 fw-500'>View Usecases</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
