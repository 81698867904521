import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import API from '../../../../api';
import { setSetting } from '../../../../app/Features/Settings/SettingSlice';
import { encryptPassword, getPermissionByCrud, objectDeepClone, throwServerError } from '../../../../utils/helper';
import { getToast } from '../../../../components/Toast';
import Loading from '../../../../components/Loader/Loading';
import { useNavigate } from 'react-router';

export default function SMTPConfiguration (props){

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false)
    const [data, setData] = useState({})
    const [backupData, setBackupData] = useState({})
    const [errors, setErrors] = useState({})
    const [newPassword, setNewPassword] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])
    
    const getData = () => {
        setLoading(true)
        setIsEdit(false)
        API.getSmtp()
        .then(res => {
            setLoading(false)
            setData(objectDeepClone(res.data.data))
            setBackupData(objectDeepClone(res.data.data))
            dispatch(setSetting(res.data.data));
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    const handleCancel = () => {
        setData(objectDeepClone(backupData))
        setIsEdit(false)
        setNewPassword('')
    }

    const handleSave = () => {

        let _tempErrors  = {}
        if (!data.hostName) _tempErrors['hostName'] = true 
        if (!data.from) _tempErrors['from'] = true 
        if (!data.port) _tempErrors['port'] = true 
        if (!data.username) _tempErrors['username'] = true 
        setErrors(_tempErrors)
        if (Object.keys(_tempErrors).length) return

        /**
         * Email password validation not required here
         */
        // if (newPassword && newPassword.length < 8){
        //     getToast({
        //       statusType: 'ERROR', message: 'Password should be minimum of 8 characters'
        //     })
        //     return
        // }
          
        // const regex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g
        // if(newPassword && !regex.test(newPassword)){
        //     getToast({
        //       statusType: 'ERROR', message: "Password must include uppercase, lowercase, numbers & special characters(!, @, &, %, +)"
        //     })
        //     return
        // }

        let json = {}
        if (newPassword) json['password'] = encryptPassword(newPassword)
        else json['password'] = ''

        json['from'] = data.from
        json['hostName'] = data.hostName
        json['password'] = json.password
        json['port'] = data.port
        json['username'] = data.username

        API.updateSmtp(json)
        .then(res => {
            getData()
            getToast({
                statusType: 'SUCCESS', message: 'SMTP Configuration updated successfully'
            })
        }).catch(err => {
            throwServerError(err)
        })
    }

     /* ACL Start */
     const {currentUser} = useSelector(state => state.user)
     const {permission} = useSelector(state => state.user)
 
     let _permission = permission.filter(o => o.name === 'smtp')
     _permission = _permission.length ? (_permission[0].crud || []) : []
     const getListPermission = getPermissionByCrud(currentUser, _permission, 'list')
     const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
     /* ACL END */

    const [isCtrl, setIsCtrl] = useState(false)
    return (
        <div className='fadeIn h-100'>
            <div className='px-16px py-9px surface-875 d-flex align-items-center justify-content-between br-tl-10px br-tr-10px v1-border-bottom-1'>
                <p className='text-100 fs-18px fw-600'>SMTP Configuration</p>

                {getUpdatePermission ? (isEdit
                    ? <div className='d-flex align-items-center'>
                        <p className='fw-500 fs-12px text-300 cursor' onClick={handleCancel}> Cancel</p>

                        <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn ms-3'
                        onClick={handleSave}>
                            <p className='fs-12px fw-500 text-300'>Save</p>
                        </div>
                    </div>
                    : <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn'
                        onClick={() => setIsEdit(true)}>
                        <p className='fs-12px fw-500 text-300'>Edit</p>
                    </div>)

                    : <div style={{height: 33}}></div>
                }
            </div>

            <div className='w-70 px-14px h-91 mx-auto overflow-scroll noscrollbar py-32px'>
                {loading
                ? <Loading />
                : <div onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSave()
                        e.preventDefault()
                    }
                }}>
                    <div className='mt-3'>
                        <p className='fs-12px text-300 fw-400'>Host Name: <span className='text-danger'>*</span></p>
                        <input type='text' className='v1-form-control form-control mt-1' value={data.hostName}
                            disabled={!isEdit}
                            onChange={(e) => {
                                let _str = e.target.value
                                setData({...data, hostName: _str})
                                setErrors({...errors, hostName: _str.length ? false : true})
                            }} />
                        {errors.hostName && <p className='text-danger fs-12px mt-1'>Host Name is required</p>}
                    </div>

                    <div className='mt-3'>
                        <p className='fs-12px text-300 fw-400'>Port: <span className='text-danger'>*</span></p>
                        <input type='number' className='v1-form-control form-control mt-1' 
                            value={data.port} disabled={!isEdit} onChange={(e) => {
                                let _str = e.target.value
                                setData({...data, port: _str})
                                setErrors({...errors, port: _str.length ? false : true})
                            }} />
                        {errors.port && <p className='text-danger fs-12px mt-1'>Port is required</p>}
                    </div>

                    <div className='mt-3'>
                        <p className='fs-12px text-300 fw-400'>Username: <span className='text-danger'>*</span></p>
                        <input type='text' className='v1-form-control form-control mt-1' 
                            value={data.username} disabled={!isEdit} onChange={(e) => {
                                let _str = e.target.value
                                setData({...data, username: _str})
                                setErrors({...errors, username: _str.length ? false : true})
                            }} />
                        {errors.username && <p className='text-danger fs-12px mt-1'>User Name is required</p>}
                    </div>

                    {isEdit && <div className='mt-3'>
                    <div>
                        <p className='fs-12px text-300 fw-400'>Login Password (Enter to Change): {newPassword && <span className='text-danger'>*</span>}</p>
                        <input type='password' className='v1-form-control form-control mt-1' value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                    </div>}

                    <div className='mt-3'>

                        <p className='fs-12px text-300 fw-400'>From Mail: <span className='text-danger'>*</span></p>
                        <input type='text' className='v1-form-control form-control mt-1' 
                            value={data.from} disabled={!isEdit}
                            onChange={(e) => {
                                let _str = e.target.value
                                setData({...data, from: _str})
                                setErrors({...errors, from: _str.length ? false : true})
                            }} />
                        {errors.from && <p className='text-danger fs-12px mt-1'>From mail is required</p>}
                    </div>
                </div>}
            </div>
        </div>
    );
};

