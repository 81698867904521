export const PostIconConfigV2 = {
  upvote: {
    default: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1265_71994)">
          <path
            d="M2.5 9.375L10 1.875L17.5 9.375H13.75V16.25C13.75 16.4158 13.6842 16.5747 13.5669 16.6919C13.4497 16.8092 13.2908 16.875 13.125 16.875H6.875C6.70924 16.875 6.55027 16.8092 6.43306 16.6919C6.31585 16.5747 6.25 16.4158 6.25 16.25V9.375H2.5Z"
            stroke="var(--content-subtle)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1265_71994">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    filled: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0773 8.61407C17.0301 8.72828 16.95 8.82591 16.8472 8.8946C16.7444 8.9633 16.6236 8.99998 16.5 9H13.375V15.25C13.375 15.5815 13.2433 15.8995 13.0089 16.1339C12.7745 16.3683 12.4565 16.5 12.125 16.5H5.875C5.54348 16.5 5.22554 16.3683 4.99112 16.1339C4.7567 15.8995 4.625 15.5815 4.625 15.25V9H1.5C1.37632 9.0001 1.25539 8.9635 1.15252 8.89483C1.04964 8.82616 0.96946 8.72851 0.922115 8.61425C0.87477 8.49998 0.862392 8.37424 0.886548 8.25294C0.910705 8.13163 0.970309 8.02023 1.05782 7.93282L8.55782 0.432816C8.61586 0.374706 8.68479 0.328606 8.76067 0.297153C8.83654 0.265701 8.91787 0.249512 9 0.249512C9.08214 0.249512 9.16347 0.265701 9.23934 0.297153C9.31522 0.328606 9.38415 0.374706 9.44219 0.432816L16.9422 7.93282C17.0296 8.02027 17.0891 8.13168 17.1131 8.25294C17.1372 8.3742 17.1247 8.49988 17.0773 8.61407Z"
          fill="var(--content-subtle)"
        />
      </svg>
    ),
    hover: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0773 8.61407C17.0301 8.72828 16.95 8.82591 16.8472 8.8946C16.7444 8.9633 16.6236 8.99998 16.5 9H13.375V15.25C13.375 15.5815 13.2433 15.8995 13.0089 16.1339C12.7745 16.3683 12.4565 16.5 12.125 16.5H5.875C5.54348 16.5 5.22554 16.3683 4.99112 16.1339C4.7567 15.8995 4.625 15.5815 4.625 15.25V9H1.5C1.37632 9.0001 1.25539 8.9635 1.15252 8.89483C1.04964 8.82616 0.96946 8.72851 0.922115 8.61425C0.87477 8.49998 0.862392 8.37424 0.886548 8.25294C0.910705 8.13163 0.970309 8.02023 1.05782 7.93282L8.55782 0.432816C8.61586 0.374706 8.68479 0.328606 8.76067 0.297153C8.83654 0.265701 8.91787 0.249512 9 0.249512C9.08214 0.249512 9.16347 0.265701 9.23934 0.297153C9.31522 0.328606 9.38415 0.374706 9.44219 0.432816L16.9422 7.93282C17.0296 8.02027 17.0891 8.13168 17.1131 8.25294C17.1372 8.3742 17.1247 8.49988 17.0773 8.61407Z"
          fill="var(--primary-color)"
        />
      </svg>
    ),
  },
  reply: {
    default: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1820_105890)">
          <path
            d="M2.47336 13.7313C2.39295 13.7543 2.30784 13.7554 2.22686 13.7344C2.14589 13.7135 2.07199 13.6712 2.01285 13.6121C1.9537 13.5529 1.91146 13.479 1.8905 13.3981C1.86955 13.3171 1.87064 13.232 1.89367 13.1516L2.56008 10.818C1.91264 9.63018 1.71496 8.24884 2.00325 6.92713C2.29155 5.60542 3.04652 4.43188 4.12978 3.62159C5.21305 2.8113 6.55204 2.41854 7.90136 2.5153C9.25069 2.61206 10.5199 3.19185 11.4765 4.14842C12.4331 5.10498 13.0129 6.37424 13.1096 7.72356C13.2064 9.07288 12.8136 10.4119 12.0033 11.4951C11.193 12.5784 10.0195 13.3334 8.6978 13.6217C7.37608 13.91 5.99475 13.7123 4.80695 13.0649L2.47336 13.7313Z"
            stroke="var(--content-subtle)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.19238 13.7414C7.46317 14.5101 7.89765 15.2109 8.46581 15.7952C9.03396 16.3795 9.72222 16.8335 10.483 17.1258C11.2438 17.418 12.059 17.5416 12.8723 17.4879C13.6855 17.4342 14.4774 17.2046 15.1932 16.8149L17.5268 17.4813C17.6072 17.5043 17.6923 17.5054 17.7733 17.4844C17.8542 17.4635 17.9281 17.4212 17.9873 17.3621C18.0464 17.3029 18.0887 17.229 18.1096 17.1481C18.1306 17.0671 18.1295 16.982 18.1064 16.9016L17.44 14.568C17.8947 13.7341 18.1308 12.7987 18.1264 11.8489C18.1219 10.8992 17.8771 9.96599 17.4146 9.13641C16.9522 8.30682 16.2872 7.60785 15.4817 7.10463C14.6762 6.60142 13.7564 6.31037 12.808 6.25861"
            stroke="var(--content-subtle)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1820_105890">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    hover: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1820_105896)">
          <path
            d="M2.47336 13.7312C2.39295 13.7543 2.30784 13.7553 2.22686 13.7344C2.14589 13.7134 2.07199 13.6712 2.01285 13.612C1.9537 13.5529 1.91146 13.479 1.8905 13.398C1.86955 13.3171 1.87064 13.232 1.89367 13.1515L2.56008 10.8179C1.91264 9.63015 1.71496 8.24881 2.00325 6.9271C2.29155 5.60539 3.04652 4.43185 4.12978 3.62156C5.21305 2.81127 6.55204 2.41851 7.90136 2.51527C9.25069 2.61203 10.5199 3.19182 11.4765 4.14839C12.4331 5.10495 13.0129 6.37421 13.1096 7.72353C13.2064 9.07285 12.8136 10.4119 12.0033 11.4951C11.193 12.5784 10.0195 13.3333 8.6978 13.6216C7.37608 13.9099 5.99475 13.7123 4.80695 13.0648L2.47336 13.7312Z"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.19238 13.7414C7.46317 14.5101 7.89765 15.2109 8.46581 15.7952C9.03396 16.3795 9.72222 16.8335 10.483 17.1258C11.2438 17.418 12.059 17.5416 12.8723 17.4879C13.6855 17.4342 14.4774 17.2046 15.1932 16.8149L17.5268 17.4813C17.6072 17.5043 17.6923 17.5054 17.7733 17.4844C17.8542 17.4635 17.9281 17.4212 17.9873 17.3621C18.0464 17.3029 18.0887 17.229 18.1096 17.1481C18.1306 17.0671 18.1295 16.982 18.1064 16.9016L17.44 14.568C17.8947 13.7341 18.1308 12.7987 18.1264 11.8489C18.1219 10.8992 17.8771 9.96599 17.4146 9.13641C16.9522 8.30682 16.2872 7.60785 15.4817 7.10463C14.6762 6.60142 13.7564 6.31037 12.808 6.25861"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1820_105896">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
};
