import { ArrowDownLeft, ArrowUpLeft, ArrowUpRight, MapPin, Plus } from 'phosphor-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router'
import API from '../../../api'
import { formatCtsTime, formatCtsTimeWithoutUTC, formatDate, getPermissionByCrud, objectDeepClone, throwServerError } from '../../../utils/helper'
import EmptyContainer from '../../../components/EmptyContainer'
import EventModal from './EventModal'
import Loading from '../../../components/Loader/Loading'
import { debounce } from 'lodash';
import { getToast } from '../../../components/Toast'
import { useDispatch } from 'react-redux'
import { setEventTitle, setShowEventsMain } from '../../../app/Features/Layout/LayoutSlice'
import { DEFAULTS } from '../../../service/constants'

export default function Events() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const {firstRoute} = useSelector(state => state.user)
    const {currentLayoutObject} = useSelector(state => state.layout)
    const [activeTab, setActiveTab] = useState('Upcoming')
    const [currentPage, setCurrentPage] = useState(1)
    const [eventsData, setEventsData] = useState({})
    const [eventsModal, setEventsModal] = useState({
        isOpen: false, isEdit: false, data: {}
    })
    const [showEvents, setShowEvents] = useState(currentLayoutObject.showEvents);
    const [evntTitle, setEvntTitle] = useState(currentLayoutObject.eventTitle || DEFAULTS.EVENT_TITLE);
    const [loading, setLoading] = useState(false)
    const [pastEventsData, setPastEventsData] = useState({})

    useEffect(() => {
        setTitle("Events")
        setHasHeader(true)
        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Manage Events'}
        ])

        getEvents(currentPage)
        getPastEvents(currentPage)
    }, [])

    const dispatch = useDispatch()
    const toggleEventStatus = useCallback(
        debounce((value) => {
            API.putLayoutDetails({showEvents: value})
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Changes saved successfully'
                })
                dispatch(setShowEventsMain(value))
            }).catch(err => {
                throwServerError(err)
            })
        }, 1000),
        [],
    )

    const evntTitleDebounceFn = useCallback(
        debounce((value) => {
            API.putLayoutDetails({eventTitle: value})
            .then(res => {
                dispatch(setEventTitle(value))
            }).catch(err => {
                throwServerError("Error while updating Event title!")
            })
        }, 1000),
        [],
    )

    const transformData = (_data, isPast = false) => {
        const groupedData = {};
        _data.forEach(each => {
            const dateLabel = formatDate(each.date, false);
            if (!groupedData[dateLabel]) {
                groupedData[dateLabel] = [];
            }
            
            groupedData[dateLabel].push(each); 
        }) 

        setLoading(false)
        if (isPast){
            setPastEventsData(objectDeepClone(groupedData))
        } else {
            setEventsData(objectDeepClone(groupedData))
        }
    }

    const getEvents = (curr) => {
        setLoading(true)
        API.getAllEventList(curr)
        .then(res => {
            transformData(res.data.data || [])
        }).catch(err => {
            throwServerError(err)
            setLoading(false)
        })
    }

    const getPastEvents = (curr) => {
        API.fetchAllPastEvent(curr)
        .then(res => {
            transformData(res.data.data || [], true)
        }).catch(err => {
            throwServerError(err)
        })
    }

     /* ACL Start */
     const {currentUser} = useSelector(state => state.user)
     const {permission} = useSelector(state => state.user)
 
     let _permission = permission.filter(o => o.name === 'event')
     _permission = _permission.length ? (_permission[0].crud || []) : []
     const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
     const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
     const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')
     /* ACL END */

    return (
        <div className='fadeIn'>
            <div className='tabs-container w-100 custom-tab-container surface-875 position-relative'>
                <Tabs activeKey={activeTab} id="resources-tab"
                    onSelect={(key) => { setActiveTab(key) }}>
                    <Tab tabClassName="tabs-btn-2" eventKey={'Upcoming'} 
                        title={`Upcoming (${eventsData && Object.keys(eventsData).length > 0 ? Object.keys(eventsData).length : 0})`}>
                        {loading ?
                        <div className='w-100' style={{height: 'calc(100vh - 130px)'}}>
                            <Loading />
                        </div>
                        : <div className='w-100 p-12px' style={{height: 'calc(100vh - 130px)'}}>
                            {(Object.keys(eventsData).length > 0 && getCreatePermission)
                            && <div className='d-flex align-items-center surface-850 p-12px v1-border-1 br-4px'>
                                <div className='me-2'>
                                    <div className={`d-flex align-items-center`}>
                                        <label class="switch blue-varient me-2">
                                            <input
                                                checked={showEvents}
                                                onClick={() => {
                                                    toggleEventStatus(!showEvents)
                                                    setShowEvents(curr => !curr)
                                                }}
                                                type="checkbox"
                                                className="cursor" />
                                            <div></div>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <p className='text-200 fs-14px fw-600'>Show in Landing Page</p>
                                    <p className='text-400 fs-12px fw-500 mt-2px'>
                                        Users can select if they want to show the events details in their developer portal landing page
                                    </p>
                                </div>
                            </div>}

                            {showEvents ? (
                                    <div className='d-flex flex-column mt-16px col-6'>
                                        <p className='fw-400 fs-12px text-300'>Title in Landing Page<span className='text-danger'>*</span></p>
                                        <div>
                                            <input type='text' className='form-control v1-form-control mt-1'
                                            value={evntTitle}
                                            onChange={(e) => {
                                                setEvntTitle(e.target.value)
                                                evntTitleDebounceFn(e.target.value);
                                            }}
                                                placeholder='Enter Title in Landing Page' />
                                        </div>
                                    </div>
                                ) : null}

                            <div className='mt-3 px-12px py-8px surface-875 d-flex align-items-center'>
                                <div className='w-8'>
                                    <p className='fw-500 fs-10px text-400'>Time</p>
                                </div>
                                <div className='w-25'>
                                    <p className='fw-500 fs-10px text-400'>Date</p>
                                </div>
                                <div className='w-50'>
                                    <p className='fw-500 fs-10px text-400'>Events</p>
                                </div>
                            </div>

                            <div className='mt-40px'>
                                {Object.keys(eventsData).length > 0
                                ? Object.keys(eventsData).map((eachKey, i) => {
                                    const eachValue = eventsData[eachKey]

                                    return (
                                    <div className='eventSubWrapper'>
                                        <div className='p-8px surface-850 br-200px w-fit eachDate'>
                                            <p className='fw-400 fs-12px text-100'>
                                                {eachKey}
                                            </p>
                                        </div>
                                        {eachValue.map((each) => <div key={each._id} className='eachEvent mb-4'>
                                            <div className='centerSvg'>
                                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="4" cy="4" r="3.625" fill="#242529" stroke="#7A7A7A" stroke-width="0.75"/>
                                                </svg>
                                            </div>
                                            <p className='eventTime text-200 fs-12px fw-400'>
                                                {formatCtsTimeWithoutUTC(each.date)}
                                            </p>

                                            <div className='py-12px px-18px d-flex justify-content-between'>
                                                <div className='w-51'>
                                                    <p className='text-capitalize fw-400 fs-14px text-400 mb-2'>
                                                        {eachKey} • {formatCtsTimeWithoutUTC(each.date)} UTC
                                                    </p>
                                                    <p className='text-truncate fw-600 fs-16px text-300 mb-1'>
                                                        {each.name}
                                                    </p>
                                                    <div className='d-flex align-items-center'>
                                                        <MapPin size={12} color='#c2c2c2' className='me-2' />
                                                        <p className='fs-14px fw-500 text-400 eventAdd'>{each.address || 'Online'}</p>
                                                    </div>

                                                    {getUpdatePermission && <div className='surface-850 d-flex align-items-center px-10px py-8px br-2px w-fit mt-2 cursor'
                                                        onClick={() => setEventsModal({isOpen: true, isEdit: true, data: objectDeepClone(each)})}>
                                                        <p className='text-300 fs-14px fw-600'>Manage</p>

                                                        <ArrowUpRight size={12} color='#e4e4e4' className='ms-2' />
                                                    </div>}
                                                </div>

                                                <div className='w-48'>
                                                    <img src={each.bannerimage} alt='Event Banner' height={120} width={200}
                                                        style={{objectFit: 'cover'}} />
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>)
                                })
                                : <div className='w-100 h-50vh d-flex align-items-center justify-content-center'>
                                    <EmptyContainer message='No Upcoming events found' />
                                </div>}
                            </div>
                        </div>}
                    </Tab>
                    <Tab tabClassName="tabs-btn-2" eventKey={'Past'} 
                        title={`Past (${pastEventsData && Object.keys(pastEventsData).length > 0 ? Object.keys(pastEventsData).length : 0})`}>
                        <div className='w-100 p-12px' style={{height: 'calc(100vh - 130px)'}}>

                            <div className='mt-3 px-12px py-8px surface-875 d-flex align-items-center'>
                                <div className='w-8'>
                                    <p className='fw-500 fs-10px text-400'>Time</p>
                                </div>
                                <div className='w-20'>
                                    <p className='fw-500 fs-10px text-400'>Date</p>
                                </div>
                                <div className='w-25'>
                                    <p className='fw-500 fs-10px text-400'>Events</p>
                                </div>
                            </div>

                            <div className='mt-40px'>
                                {Object.keys(pastEventsData).length > 0
                                ? Object.keys(pastEventsData).map((eachKey, i) => {
                                    const eachValue = pastEventsData[eachKey]

                                    return (<div className='eventSubWrapper'>
                                        <div className='p-8px surface-850 br-200px w-fit eachDate'>
                                            <p className='fw-400 fs-12px text-100'>
                                                {eachKey}
                                            </p>
                                        </div>
                                        {eachValue.map((each) => <div key={each._id} className='eachEvent mb-4'>
                                            <div className='centerSvg'>
                                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="4" cy="4" r="3.625" fill="#242529" stroke="#7A7A7A" stroke-width="0.75"/>
                                                </svg>
                                            </div>
                                            <p className='eventTime text-200 fs-12px fw-400'>
                                                {formatCtsTimeWithoutUTC(each.date)}
                                            </p>

                                            <div className='py-12px px-18px d-flex justify-content-between'>
                                                <div className='w-51'>
                                                    <p className='text-capitalize fw-400 fs-14px text-400 mb-2'>
                                                        {eachKey} • {formatCtsTimeWithoutUTC(each.date)} UTC
                                                    </p>
                                                    <p className='text-truncate fw-600 fs-16px text-300 mb-1'>
                                                        {each.name}
                                                    </p>
                                                    <div className='d-flex align-items-center'>
                                                        <MapPin size={12} color='#c2c2c2' className='me-2' />
                                                        <p className='fs-14px fw-500 text-400 eventAdd'>{each.address || 'Online'}</p>
                                                    </div>

                                                    {getUpdatePermission && <div className='surface-850 d-flex align-items-center px-10px py-8px br-2px w-fit mt-2 cursor'
                                                        onClick={() => setEventsModal({isOpen: true, isEdit: true, data: objectDeepClone(each)})}>
                                                        <p className='text-300 fs-14px fw-600'>Manage</p>

                                                        <ArrowUpRight size={12} color='#e4e4e4' className='ms-2' />
                                                    </div>}
                                                </div>

                                                <div className='w-48'>
                                                    <img src={each.bannerimage} alt='Event Banner' height={120} width={200}
                                                        style={{objectFit: 'cover'}} />
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>)
                                })
                                : <div className='w-100 h-50vh d-flex align-items-center justify-content-center'>
                                    <EmptyContainer message='No Upcoming events found' />
                                </div>}
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                {getCreatePermission && <div className='position-absolute cursor d-flex align-items-center justify-content-between px-16px py-8px bg-primary br-5px'
                    style={{right: 8, top: 5}} onClick={() => setEventsModal({
                        isOpen: true, isEdit: false, data: {}
                    })}>
                    <Plus size={14} className='me-1' color='#E4E4E4' />
                    <p className='text-300 fs-12px fw-500'>Create Event</p>
                </div>}
            </div>

            {eventsModal.isOpen && <EventModal show={eventsModal.isOpen}
                callback={() => {getEvents(1);setCurrentPage(1);getPastEvents(1)}}
                getDeletePermission={getDeletePermission}
                onHide={() => setEventsModal({
                    isOpen: false, isEdit: false, data: {}
                })} isEdit={eventsModal.isEdit} data={eventsModal.data} />}
        </div>
    )
}
