import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomNavbar from "../Dashboards/components/CustomNavbar";
import CustomFooter from "../Dashboards/components/CustomFooter";
import { MagnifyingGlass } from "phosphor-react";
import API from "../../api";
import { debounceFn, getLocationBasePath, getMenuName, throwServerError } from "../../utils/helper";
import { getUuidV4, objectDeepClone } from "@apiwiz/oas/services";
import GlobalIcons from "../../components/Icons/GlobalIcons";
import APIReferenceCard from "./APIReferenceCard";
import ContainerLoader from "../../components/Loader/ContainerLoader";
import { useSelector } from "react-redux";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import MyReactTooltip from "../../components/ReactTooltip";
import { v4 } from "uuid";
import { useNavigate } from "react-router";
import { PRODUCTS_MAPPER_DISPLAY_NAME } from "../../service/constants";
import api from "../../api";
import { useSearchParams } from "react-router-dom";
import { EmptyState } from "../../components/EmptyState";
import { setSetting } from "../../app/Features/Settings/SettingSlice";
import { useDispatch } from "react-redux";

const InputCheckbox = (props) => {
    const elRef = useRef(null);
    let isDisabled = props.disabled
    let fieldPaddingTop = (props.fieldPadding || {}).top
    fieldPaddingTop = isNaN(Number(fieldPaddingTop)) ? 2 : fieldPaddingTop;
    let fieldMarginTop = (props.fieldMargin || {}).top
    fieldMarginTop = isNaN(Number(fieldMarginTop)) ? 0 : fieldMarginTop;
    return (
    <div onClick={() => {
      if(isDisabled) return
      if(props.onChange)
      {
        props.onChange({target: {checked: props.value ? false : true}})
      }
    }} className={`w-100 cursor d-flex align-items-center gap-8px py-8px pl-16px pr-10px ${props.value ? "border-left-theme-primary" : ""} ${ isDisabled ? '' : 'cursor'} ${props.containerClassName || ''}`}>
        <div className="mt--1px w-20px d-flex align-items-center">{props.value ? <ImCheckboxChecked size={16} color="var(--primary-color)" /> : <ImCheckboxUnchecked size={18} color="var(--content-subtle)" />}</div>
        <div ref={elRef} data-tip data-for={props.id} className={`w--36px text-truncate fs-16px fw-500 ${props.value ? "text-theme-primary" : ""} ${props.fieldClassName || ''}`}>
            {props.field}
        </div>
        <MyReactTooltip
            elRef={elRef}
            checkTruncate
            id={props.id}
            className="max-w-100-important"
          >
            <div className="px-8px py-4px text-nowrap">
              {props.field}
            </div>
          </MyReactTooltip>
    </div>)
}

export default function APIReferenceHome({isOpenApi}) {
    //const [partners, setPartners] = useState([]);

    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const leftPanelRef = useRef(null);
    const rightPanelRef = useRef(null);
    const [borderHeight, setBorderHeight] = useState(0);
    const [pageTitle, setPageTitle] = useState("");

    const { currentUser, isAuthenticated } = useSelector((state) => state.user);
    const {currentLayoutObject} = useSelector(state => state.layout);

    /*Astrum code*/
    const [loading, setLoading] = useState(true);
    const [filterLoading, setFilterLoading] = useState(false);

    const [data, setData] = useState([])
    const dataBackup = useRef([]);
    const [searchVal, setSearchVal] = useState('');
    const [fSearchVal, setFSearchVal] = useState('');

    const [filters, setFilters] = useState({
        product: {
            label: 'Products',
            store: new Set(),
            list: []
        },
        partner: {
            label: 'Partners',
            store: new Set(),
            list: [],
        }
    });


    /*
    const getProducts = async (partners) => {
        let _filters = {...filters}
        
        setFilterLoading(true);


        try {
            let products = await api.getAllCatalogProducts({partners});
            if(!(products.data && products.data.data && products.data.data.length)) {
                setFilterLoading(false);
                return;
            }
            let _list = products.data.data.map(x => x[PRODUCTS_MAPPER_DISPLAY_NAME.KONG] || x[PRODUCTS_MAPPER_DISPLAY_NAME.AZURE] || x[PRODUCTS_MAPPER_DISPLAY_NAME.APIGEE]);
            _list = [...new Set(_list)];
            _filters.product.list = _list.filter(Boolean).map(x => ({name: x, _id: v4()}));
            _filters.product.store = new Set();

            setFilters(_filters);
            setFilterLoading(false);
        }
        catch(err)
        {
            throwServerError(err);
            setFilterLoading(false);
        }
        
    }
    */

    const applyFilters = () => {
        if(!filters.product.store.size && !filters.partner.store.size)
        {
            return objectDeepClone(dataBackup.current);
        }
        let _data = dataBackup.current.filter(x => {
            for(let y of x.products) if(filters.product.store.has(y)) return true;
            for(let y of x.partners) if(filters.partner.store.has(y)) return true;
        })

        return _data;
    }

    useEffect(() => {
        let _dataBackup = applyFilters();
        if (!searchVal) {
            setData(objectDeepClone(_dataBackup))
            return
        }

        let _searchtext = searchVal;
        let regExp = new RegExp(_searchtext, "i");
        let finalArr = _dataBackup.filter(
            (x) => x.title.search(regExp) !== -1
        );
        setData(finalArr)
    }, [searchVal])

    const updateFilter = () => {
        setData(applyFilters());
    }

    const resetFilters = () => {
        for(let f in filters)
        {
            filters[f].store = new Set();
        }
        updateFilter();
        setSearchVal('');
    }

    const resetFiltersDebounceFn = useMemo(() => {
        return debounceFn(resetFilters)
    }, [fSearchVal])

    const cardFiltersComputed = useMemo(() => {
        resetFiltersDebounceFn();
        if (!fSearchVal) return filters;
        let _searchtext = fSearchVal;
        let regExp = new RegExp(_searchtext, "i");

        let _cardFilters = objectDeepClone(filters)
        for(let k in _cardFilters)
        {
            _cardFilters[k].list = _cardFilters[k].list.filter(x => x.name.search(regExp) !== -1)
        }

        return _cardFilters;
    }, [fSearchVal])


    /*Astrum code*/

    const reCalcBorderHeight = () => {
        setTimeout(() => {
            if (leftPanelRef.current && rightPanelRef.current) {
                setBorderHeight(
                    Math.max(
                        leftPanelRef.current.clientHeight,
                        rightPanelRef.current.clientHeight
                    )
                );
            }
        });
    };

    useEffect(() => {
        if(currentUser && Object.keys(currentUser).length){
            reCalcBorderHeight();

            API.trackSessions("REFERENCE")
                .then((res) => console.log("res", res))
                .catch((err) => console.log("err", err));
        }
        else {
            setLoading(false);
        }
    }, [currentUser]);

    useEffect(() => {
        reCalcBorderHeight();
    }, [data])
    useEffect(() => {
        reCalcBorderHeight();
    }, [filters])

    const navigate = useNavigate()

    const getPartners = (openApiPartnerId) => {
        setLoading(true);
        API.listPartners()
            .then((res) => {
                let temp = objectDeepClone(res.data.data || []);

                /**
                Fetch Data for Open API's
                */
                if(isOpenApi)
                {
                    let openBankingId = temp.find(o => o._id === openApiPartnerId)
                    openBankingId = openBankingId ? openBankingId._id : '';
                    if(!openBankingId)
                    {
                        navigate('/');
                        return;
                    }

                    //getProducts(openBankingId);
                    getSwaggers({partners: [openBankingId]});

                    return
                }

                let partners = [];

                if(isAuthenticated)
                {
                    partners.push(...(currentUser.userGroups || []))
                    //User Grouped partners list will be fetched directly inside Developer Portal API call
                    //getProducts();
                }
                else {
                    //For Anonymous users
                    partners.push(...temp.filter((o) => o.isDefault).map((o) => o._id));
                    //getProducts(partners.join(','))
                }
                let _partnersObj = temp.reduce((acc, curr) => (acc[curr._id]=curr, acc), {});

                let _filters = {...filters}
                _filters.partner.list = partners.map(x => (_partnersObj[x] || {}).partnerDisplayName || null).filter(Boolean).map(x => ({name: x, _id: v4()}));
                
                getSwaggers({partners, filtersCopy: _filters});
                
                // setFilters(_filters);
            })
            .catch((err) => {
                throwServerError(err);
                setLoading(false);
            });
    };

    const getSwaggers = ({partners, filtersCopy}) => {
        setLoading(true);

        let params = {};
        params.partnerNames = partners.join(",")
        API.getPublishedSwaggers({}, {...params})
            .then((res) => {
                let arr = [];
                let _data = objectDeepClone(res.data?.data || []);
                
                let _filters = {...(filtersCopy || filters)};
                _filters.product.store = new Set();
                let _productsList = new Set();
                
                for (let a of _data) {
                    let obj = {};
                    let item = a.items[0];
                    obj.title = a.title || item.title;
                    obj.swaggerId = item.swaggerId;
                    obj.swaggerDescription = item.swaggerDescription;
                    obj.swaggerVersion = item.swaggerVersion;
                    obj.teams = item.teams || [];
                    obj.products = item.products || [];
                    obj.partners = item.partners || [];

                    for(let p of obj.products) _productsList.add(p);

                    let version = item.versions[0];
                    let url = version.url;
                    let urlObj = url.split("/");
                    obj.resources = version.resources;
                    obj.url = url;
                    obj.versionName = version.name;
                    obj.revision = urlObj[urlObj.length - 1];
                    arr.push(obj);
                }
                let _sourceCatalogList = objectDeepClone(arr);
                _sourceCatalogList = _sourceCatalogList.sort((a, b) => {
                    const _a = a.title ? a.title.toUpperCase().trim() : "";
                    const _b = b.title ? b.title.toUpperCase().trim() : "";
                    if (_a < _b) return -1;
                    if (_a > _b) return 1;
                    return 0;
                });

                let _preSwaggers = searchParams.get("swaggers")
                
                if (_preSwaggers){
                    let _idealSwaggers = _preSwaggers.split(",")
                   _sourceCatalogList = _sourceCatalogList.filter(o => _idealSwaggers.includes(o.swaggerId))
                }

                setData(_sourceCatalogList);

                _filters.product.list = [..._productsList].filter(Boolean).map(x => ({name: x, _id: v4()}));
                setFilters(_filters);

                dataBackup.current = _sourceCatalogList;
                reCalcBorderHeight();
                setLoading(false);
            })
            .catch((err) => {
                let _filters = {...(filtersCopy || filters)};
                _filters.product.store = new Set();
                _filters.product.list = [];
                setFilters(_filters);

                setData([]);
                setLoading(false);
                throwServerError(err);
            });
    };

    useEffect(() => {
        let url = getLocationBasePath();
        if (url) {
            url = `/${url}`;
            let pageTitle = getMenuName({
                menus: currentLayoutObject.headerMenu,
                url,
            });
            if (!pageTitle)
                pageTitle = getMenuName({
                menus: currentLayoutObject.footerMenu,
                url,
                });
            if (pageTitle) setPageTitle(pageTitle);
        }

        setLoading(true);
        API.getSettingsData()
        .then((res) => {
            let data = objectDeepClone(res?.data?.data);
            dispatch(setSetting(data));

            if (data.jsessionId) {
                getPartners(data.openApiPartner);
            }
            else {
                navigate('/');
                setLoading(false)
            }
        })
        .catch((err) => {
            throwServerError(err);
            setLoading(false);
        });
    }, [isOpenApi])

    return (
        <div className="w-100vw bg-currentBackground">
            <CustomNavbar />

            <div className="container mt-62px d-flex align-items-start min-h-100vh">
                {loading ? (
                    <ContainerLoader
                        variant="theme-primary"
                        className="w-100 h-100 min-h-100vh"
                    />
                ) : (
                    <>
                        <div className="d-flex justify-content-center hideLeftDivInMobile guide-body bg-theme-base h-100" style={{position: 'sticky', top: '70px'}}>
                            <div
                                ref={leftPanelRef}
                                className="d-flex flex-column guide-left-panel sdksLeftPanel pt-32px h-100"
                                style={{width: 320}}
                            >
                                <div className="searchSection mb-32px">
                                    <MagnifyingGlass color="#667085" size={15}/>

                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) =>
                                            setSearchVal(e.target.value)
                                        }
                                    />
                                </div>

                                {filterLoading ? <ContainerLoader className="w-100 h-100" /> : (
                                    <div className="overflow-scroll" style={{height: 'calc(100vh - 200px)'}}>
                                        {Object.keys(cardFiltersComputed).map(key => {
                                            if(!cardFiltersComputed[key].list.length || (key === 'partner' && isOpenApi)) return null
                                            return (
                                                <div>
                                                    <p className="subTitle mt-2 mb-1 fs-14px fw-600 lh-20px">{filters[key].label}</p>
                                                    {cardFiltersComputed[key].list.length ? cardFiltersComputed[key].list.map(x => {
                                                        return (
                                                            <div>
                                                            <InputCheckbox value={filters[key].store.has(x.name)} 
                                                            id={x._id}
                                                            field={x.name} showEverytime
                                                            onChange={(e) => {
                                                                if(searchVal) setSearchVal('')
                                                                if(e.target.checked) filters[key].store.add(x.name)
                                                                else filters[key].store.delete(x.name)
                                                                updateFilter()}} />
                                                        </div>
                                                        )
                                                    }) : null}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="bg-theme-base-200 w-1px hideOnMobile" style={{height: borderHeight}}></div>
                        <div
                            ref={rightPanelRef}
                            className="d-flex justify-content-center guide-content-panel py-32px w-100"
                        >
                            <div className="w-100 h-100">
                                <div className="d-flex flex-column gap-4px align-items-center mb-24px">
                                    <div className="fs-32px fw-600 lh-38px">
                                        {pageTitle}
                                    </div>
                                    <div className="fs-16px lh-28px mobileTextCenter">
                                        Explore popular OAS and see
                                        whats possible with API details
                                        and tryout
                                    </div>
                                </div>
                                <div className="w-100 h-100 d-flex justify-content-center">
                                    {data.length ? (
                                        <div
                                            className="h-100 d-flex card-alignment flex-wrap mx-auto gap-24px"
                                        >
                                            {data.map((eachData) => (
                                                <APIReferenceCard data={eachData}/>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="d-flex flex-column align-items-center mt-36px">
                                            <EmptyState />
                                            <p className="fs-14px">No APIs found</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>      
                    </>
                )}
            </div>

            <CustomFooter />
        </div>
    );
}