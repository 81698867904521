import React from 'react'

export default function MyProgressBar({value, classsName = ''}) {
    const mainValue = parseInt(value) > 100 ? 100 : parseInt(value)
    return (
        <div className={`${classsName} w-100 myProgressBar`}>
            <div className={`w-${mainValue} filledProgress`}></div>
        </div>
    )
}
