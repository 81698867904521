import React from 'react'
import PartnerSlider from '../../../components/InfiniteLooper'

export default function CustomLogo1({data}) {
    return (
        <div className='bg-currentBackground m-0 pb-5 pt-3'>
            <p className='fs-18px fw-500 text-content-color text-center'>
                {data.heading}
            </p>

            <div className='d-flex align-items-center noscrollbar mt-36px' style={{overflowX: 'scroll', overflowY: 'hidden'}}>
                {data.logos?.length > 3
                ? <PartnerSlider speed="40" direction="left">
                    {data.logos.map(((each, i) => 
                        <div key={i} className='mx-5'>
                            <img src={each.imageUrl} alt='' height={60} />
                        </div>
                    ))}
                </PartnerSlider>
                : <>
                    {data.logos?.map(((each, i) => 
                        <div key={i} className='mx-5'>
                            <img src={each.imageUrl} alt='' height={60} />
                        </div>
                    ))}
                </>}
            </div>
        </div>
    )
}
