import React, {useState, useEffect, useRef} from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import API from '../../../../api'
import { setLayoutObjects } from '../../../../app/Features/Layout/LayoutSlice'
import GlobalIcons from '../../../../components/Icons/GlobalIcons'
import Loading from '../../../../components/Loader/Loading'
import { getToast } from '../../../../components/Toast'
import { objectDeepClone, setColor, throwServerError } from '../../../../utils/helper'
import OverallStyles from '../../../../styles-config.json'
import { useTheme } from '../../../../context/ThemeContext'
import useOutsideClick from '../../../../hooks/useOutsideClick'

export default function ColorSettings() {
    const {theme} = useTheme()
    const { currentLayoutObject, backupCurrentLayout } = useSelector(state => state.layout)
    const [loading, setLoading] = useState(false)

    const menuRef = useRef(null)

    useEffect(() => {
        setSelectedColor(currentLayoutObject.siteTemplateType || 'Purple Magic')
    }, [currentLayoutObject])
    
    const [openDialog, setOpenDialog] = useState(false)
    const reservedColors = ['Purple Magic', 'Lemonade', 'Coffee', 'Lagoon', 'Winter', 'Crimson', 'Neutral']
    const [selectedColor, setSelectedColor] = useState('Purple Magic')
    const dispatch = useDispatch()
    const handleCancel = () => {
        setSelectedColor(backupCurrentLayout.siteTemplateType || 'Purple Magic')
    }

    useOutsideClick(menuRef, () => {
        setOpenDialog(false)
    });

    const handleSave = () => {
        setLoading(true)
        API.putLayoutDetails({siteTemplateType: selectedColor})
        .then(res => {
            setLoading(false)
            getToast({
                statusType: 'SUCCESS', message: 'Color Settings updated successfully'
            })

            let currentTheme = OverallStyles[selectedColor || 'Purple Magic'][theme]
            Object.keys(currentTheme).forEach(eachVariable => {
                setColor(eachVariable, currentTheme[eachVariable])
            })
            setColor('--currentBackground', theme === 'light' ? "white" : 'var(--base-color)')

            dispatch(setLayoutObjects({siteTemplateType: selectedColor}))
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    return (
        <>
            <div className='surface-875 px-16px py-8px mt-4 v1-border-bottom-1 br-tr-4px br-tl-4px'>
                <p className='fw-600 fs-16px text-200'>Color Settings</p>
                <p className='text-400 fs-12px fw-400 my-1'>
                    Select theme colour that will be displayed in your Developer Portal 
                </p>
            </div>
            <div className='p-3 surface-900 br-br-4px br-bl-4px'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='w-50'>
                        <div className='text-400 fs-14px fw-600'>Theme</div>
                        <div className='fs-12px text-400 fw-400 mt-4px'>
                            Select a pre-made customized theme which will be displayed in your Developer Portal home page. 
                        </div>
                        <div className='fs-12px fw-400 text-400 mt-16px'>
                            Can’t find the theme you like for your organization ?. We can help you customize according to your needs by contacting support
                        </div>
                    </div>

                    <div className='position-relative'>
                        <div className='v1-border-1 surface-850 br-4 mt-1 cursor d-flex align-items-center justify-content-between py-10px px-14px w-25vw'
                            onClick={() => setOpenDialog(true)}>
                            <div className='d-flex align-items-center'>
                                <div className={`colorGradientfor ${selectedColor}`}></div>
                                <p className='text-300 fs-12px fw-400 ms-2'>{selectedColor}</p>
                            </div>

                            <div className='surface-875 v1-border-05 br-4 cursor px-8px br-4px py-4px'>
                                <GlobalIcons type={'up-and-down-arrow'} />
                            </div>
                        </div>

                        {openDialog && <div className='surface-875 v1-border-1 br-4 mt-2 px-16px p-12px position-absolute w-100'
                            style={{top: 56}} ref={menuRef}>
                            <p className='fw-600 fs-12px text-400 mb-1'>
                                Select a below preset
                            </p>

                            {reservedColors.map((eachColor, i) => <div 
                                className='mb-2 d-flex align-items-center p-10px cursor hover-surface-850' 
                                key={i} onClick={() => {
                                    setSelectedColor(eachColor)
                                    setOpenDialog(false)
                                }}>
                                <div className={`colorGradientfor ${eachColor}`}></div>
                                <p className='text-300 fs-12px fw-400 ms-2'>{eachColor}</p>
                            </div>)}
                        </div>}
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end mt-3'>
                    <p className='fw-500 fs-12px text-300'
                        onClick={handleCancel}>Cancel</p>

                    {loading 
                    ? <div className='ms-3'><Loading size='sm' /></div>
                    : <div className='btn-md-v1 fw-500 fs-12px text-300 ms-3'
                        onClick={handleSave}>
                        Save
                    </div>}
                </div>
            </div>
        </>
    )
}
