
const setPages = (groups, pages) => {
  let _groups = [];
  for (let el of groups) {
    let page = el.page ? pages[el.page] || {} : el;
    if (page.pages && page.pages.length)
      page.pages = setPages(page.pages, pages);
    _groups.push(page);
  }
  return _groups;
};

export function getGroupList(pages) {
  let pageList = Object.values(pages);
  if (!pageList.length) return [];
  let _groups = pageList.filter((el) => el.type === "group");
  let _initPage = pageList.find((el) => el.slug === "/");
  let _initPages = (_initPage || {}).pages || [];
  delete (_initPage || {}).pages;
  _initPage.noDelete = true;
  let _gettingStarted = {
    title: "Getting Started",
    noDelete: true,
    pages: [
      _initPage,
      ..._initPages
        .map((el) => {
          if (pages[el.page].type === "group") return null;
          return el;
        })
        .filter((x) => x),
    ],
  };

  _groups.unshift(_gettingStarted);

  _groups = setPages(_groups, pages);

  return _groups;
}
