import axios from "axios";

import APIS from "../service/config";
import { getCookie } from "../utils/cookie";
import paginationLimit from "../utils/pagination-limit";
const { API, MONETIZATION } = APIS;

let headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

if (getCookie("itorix-token")) {
  headers["authorization"] = `Bearer ${getCookie("itorix-token")}`;
}

let api = axios.create({
  baseURL: API,
  headers: headers,
});

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  initHeaders(token) {
    let header = api.defaults.headers;
    if (token) {
      header["authorization"] = `Bearer ${token}`;
    } else {
      if (header.hasOwnProperty("authorization")) {
        delete header["authorization"];
      }
    }
  },

//   TODO: Update below endpoints with v2 endpoints 

  // get all product bundles
  getProductBundles({ org, pagination }) {
    return api.get(
      `${MONETIZATION.GET_PRODUCT_BUNDLES}/${org}?pagination=${pagination}`
    );
  },

  // purchase rate plan using wallet

  purchaseRatePlanUsingWallet(params) {
    return api.post(`${MONETIZATION.PURCHASE_APP}`, params);
  },

  //  view all purchase history of app

  getPurchaseHistoryOfApp({ appId }) {
    return api.get(`${MONETIZATION.GET_PURCHASES}/${appId}`);
  },

  getAllPurchases({ org }) {
    return api.get(`${MONETIZATION.PURCHASE_HISTORY}/${org}`);
  },

  cancelPurchase({ appId, purchaseId }) {
    return api.delete(
      `monetization/apps/${appId}/purchase/${purchaseId}`
    );
  },

  getAllApps() {
    return api.get("v2/all-apps");
  },

  getAllProductBundles(){
    return  api.get("v2/all-product-bundles")
  },
  // azure, kong
  getAllProducts(params){
    let partners = "";
    if(params?.partners) partners = params.partners 
    let _url = `v2/all-products`
    if(partners)
    {
      _url += `?partners=${partners}`
    }
    return api.get(_url)
  },

  getGuidesByProductId(id){
    return api.get(`/guides/product/${id}`)
  },

  getAllPurchases(){
    return api.get("v2/all-purchases")
  },
  createAzureApp(fields){
    return api.post("v2/azure/apps", {
      ...fields
    })
  },
  deleteAzureApp(appName, connectorId){
    return api.delete(`v2/azure/apps/${appName}`, {
      headers: {
        ...headers,
        connectorId
      }
    })
  },

  createKongApp(fields){
    return api.post("v2/kong/apps", {
      ...fields
    })
  },

  updateKongApp(fields, appId){
    return api.put(`v2/kong/app/${appId}`, {
      ...fields
    })
  },

  deleteKongApp(appId){
    return api.delete(`v2/kong/app/${appId}`)
  },
  

  updateApp({ organization, params, statusQuery }) {
    return api.put(
      `monetization/build-app/${organization}/apps/${params.name}?status=${statusQuery}`,
      params
    );
  },

  getAppBilling({ appId, transactions }) {
    return api.get(`monetization/apps/${appId}/billing`, {
      headers: {
        transactions,
      },
    });
  },

  getGuideByProductBundle({ productBundle }) {
    return api.post(`/guides/productBundle`, {
      productBundle,
    });
  },

  getGuideByProduct({ product }) {
    return api.post(`/guides/product`, {
      product,
    });
  },
  getWalletBalance({ appId }) {
    return api.get(`monetization/wallet/apps/${appId}`);
  },

  getTransactionByAppId({ appId }) {
    return api.get(`monetization/transactions/apps/${appId}`);
  },

  rechargeWallet({ appId, topup }) {
    return api.post(`monetization/wallet/apps/${appId}`, {
      topup,
    });
  },

  updateProductStatus({ appName, productName, consumerKey, action }) {
    return api.put(
      `monetization/apps/${appName}/products/${productName}?action=${action}`,
      {
        consumerKey,
      }
    );
  },

  	
	getAllApigeeApps(){
		return api.get("/v2/all-apigee-apps")
	},

  getKongApps(){
    return api.get("/v2/kong/apps")
  },

  getAzureApps(){
    return api.get('/v2/azure/apps')
  },

  updateAzureAppStatus({appName, status, connectorId}){
    return api.put(`/v2/azure/apps/${appName}/status/${status}`, {}, {
      headers: {
        ...headers,
        connectorId
      }
    })
  },

  updateKongStatus(appId, fields){
    return api.put(`/v2/kong/app/${appId}`, {
      ...fields
    })
  },

  getProductDetails(params){
		return api.get(`/v2/all-product-details`, {params: {...params}})
	},

  getProductDetailsById(productId){
		return api.get(`/v2/product-details/${productId}`)
	},

  syncProductDetails(type){
    return api.get(`/v2/product/sync-${type}`)
  },

	postProductDetails(data){
		return api.post(`/v2/product-details`, data)
	},

	updateProductDetails(id, data){
		return api.put(`/v2/product-details/${id}`, data)
	},

};
