import React, { useEffect, useRef, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { pdfComponents } from '../../../../service/constants'
import moment from "moment";
import { NumberFormatter, numberToWord, pluralize } from "../../../../utils/helper";
import { Package } from "phosphor-react";

//  595 x 842 pixels
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    interFont: {
        normal: `${window.location.origin}/font-inter-regular.ttf`,
        bold: `${window.location.origin}/font-inter-bold.ttf`,
        italics: `${window.location.origin}/font-inter-thin.ttf`,
        bolditalics: `${window.location.origin}/font-inter-extra-bold.ttf`,
    },
    Roboto: {
        normal: `${window.location.origin}/roboto-mono-light-italic.ttf`,
        italics: `${window.location.origin}/roboto-mono-light-italic.ttf`,
    },
};

pdfMake.tableLayouts = {
    myTableLayout: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            }
            return i === node.table.headerRows ? 0.4 : 0.1;
        },
        vLineWidth: function (i) {
            return 0;
        },
        hLineColor: function (i) {
            return i === 1 ? "#aaa" : "#fff";
        },
        paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
            return i === 0 ? 0 : 8;
        },
        fillColor: function (i, node) {
            const _i = i - 1;
            if (_i === -1 || _i % 2 === 0) return null;
            return "#F4F5F5";
        },
    },
    successLayout: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            }
            return 0;
        },
        vLineWidth: function (i) {
            return 0;
        },
        hLineColor: function (i) {
            return "#fff";
        },
        paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
            return i === node.table.widths.length - 1 ? 0 : 8;
        },
        fillColor: function (i, node) {
            return "#E5FBEB";
        },
    },
    failureLayout: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            }
            return 0;
        },
        vLineWidth: function (i) {
            return 0;
        },
        hLineColor: function (i) {
            return "#fff";
        },
        paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
            return i === node.table.widths.length - 1 ? 0 : 8;
        },
        fillColor: function (i, node) {
            return "#FFE5E5";
        },
    },
};


export default function InvoicePDF() {
    const iframeRef = useRef();
    const [searchParams] = useSearchParams();
    const {currentUser} = useSelector(state => state.user)

    const formatData = (_data) => {
        console.log('_data', _data)

        let _total = 0
        let packageCount = 0

        let _invoices = []

        // let invoiceHashmap = {}

        Object.keys(_data.invoiceData)?.forEach((_) => {
            const eachVal = _data.invoiceData[_]
            packageCount += (eachVal.length || 0)

            if (eachVal.length === 0) return

            _invoices.push({
                layout: 'noBorders', 
                table: {
                    headerRows: 0,
                    widths: [ 240, 90, '*' ],

                    body: [
                    [ 
                        pdfComponents.paragraph({text: eachVal[0]?.appName || '', bold: true, color: '#333', fontSize: 11, margin: [10, 10, 0, -10]}), 
                        pdfComponents.paragraph({text: `${pluralize(eachVal.length, 'Package')}`, bold: true, color: '#245530', fontSize: 8.5, margin: [0, 10, 0, -10]}),
                        pdfComponents.paragraph({text: `${(eachVal.reduce((acc, curr) => acc + parseInt(curr.totalAmount), 0))?.toLocaleString()} ${currentUser.billing?.billingCurrency?.currencyCode || ''}`, bold: true, color: '#333', fontSize: 11, styles: {alignment: 'right'}, margin: [0, 10, 10, -10]}), 
                    ],
                    ]
                },
                margin: [0,-10,0,15]
            })

            let bodies = []

            eachVal.forEach((each) => {
                _total += parseInt(each.totalAmount || 0)
                bodies.push([ 
                    pdfComponents.paragraph({text: each.packageName, bold: false, color: '#333', fontSize: 11, margin: [12, 5]}), 
                    pdfComponents.paragraph({text: `${(each.totalAmount || 0)?.toLocaleString()} ${currentUser.billing?.billingCurrency?.currencyCode || ''}`, bold: false, color: '#333', fontSize: 11, styles: {alignment: 'right'}, margin: [12, 5]}), 
                ])
            })

            _invoices.push({
                layout: {
                    hLineWidth: function (i, node) {
                        // if (i === 0 || i === node.table.body.length) {
                        // 	return 0;
                        // }
                        // return (i === node.table.headerRows) ? 2 : 1;
                        return 0
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                    hLineColor: function (i) {
                        return i === 1 ? 'black' : '#aaa';
                    },
                    paddingLeft: function (i) {
                        return i === 0 ? 0 : 8;
                    },
                    paddingRight: function (i, node) {
                        return (i === node.table.widths.length - 1) ? 0 : 8;
                    },
                    fillColor: function (i, node) {
                        return (i % 2 === 0) ? '#EDF0F7' : '#FAFBFE';
                    }
                }, 
                table: {
                    headerRows: 0,
                    widths: [ 300, '*' ],
            
                    body: [
                        ...bodies,
                    ]
                },
                margin: [0,0,0,30]
            })

            _invoices.push({
                layout: {
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    hLineColor: function (i, node){
                        return '#CAD0DB'
                    },
                    vLineWidth: function(i, node){
                        return 0;
                    }
                }, 
                table: {
                  widths: [ 223, 71, 71, 130 ],
                  headerRows: 1,
          
                  body: [
                    [ 
                        pdfComponents.paragraph({
                            text: 'Total(In Words)', bold: false,
                            fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7],
                        }), 
                        pdfComponents.paragraph({
                            text: 'No. of Apps', 
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: 'No. of Packages', 
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: 'Amount', styles: {alignment: 'right'},
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                    ],

                    [ 
                        pdfComponents.paragraph({
                            text: `${numberToWord(_total)}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white']},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: `${pluralize(Object.keys(_data.invoiceData).length, 'App')}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white']},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: `${pluralize(packageCount, 'Package')}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white']},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: `${(_total || 0)?.toLocaleString()} ${currentUser.billing?.billingCurrency?.currencyCode || ''}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white'], alignment: 'right'},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                    ],
                  ]
                }
            })
        })

        // console.log('invoiceHashmap', invoiceHashmap)
        let docDefinition = {
            content: [
                pdfComponents.paragraph({text: 'Invoice', bold: true, fontSize: 16, margin: [0, 0, 0, 6]}),
                {
                    layout: 'noBorders', 
                    table: {
                      headerRows: 0,
                      widths: [ '*', 'auto', '*' ],
              
                      body: [
                        [ 
                            pdfComponents.paragraph({text: 'Bill Amount', bold: false, fontSize: 11, margin: [0, 10, 0, -10]}), 
                            '',
                            pdfComponents.paragraph({text: 'Invoice Date', bold: false, fontSize: 11, styles: {alignment: 'right'}, margin: [0, 10, 0, -10]}), 
                        ],
                        [ 
                            pdfComponents.paragraph({text: `${_total?.toLocaleString()} ${currentUser.billing?.billingCurrency?.currencyCode || ''}`, bold: true, fontSize: 20, margin: [0, 10, 0, 0]}), 
                            '',
                            pdfComponents.paragraph({text: `${moment().month(_data.month - 1).format("MMMM")} ${_data.year}`, bold: true, fontSize: 11, styles: {alignment: 'right'}, margin: [0, 10, 0, 0]}),
                        ],
    
                        [ 
                            pdfComponents.paragraph({text: 'Invoice Id', color: '#666', bold: false, fontSize: 11, margin: [0, 4, 0, -10]}), 
                            '',
                            pdfComponents.paragraph({text: 'Quantity', color: '#666', bold: false, fontSize: 11, styles: {alignment: 'right'}, margin: [0, 4, 0, -10]}), 
                        ],
                        [ 
                            pdfComponents.paragraph({text: _data.invoiceId, bold: true, fontSize: 11, margin: [0, 10, 0, 0]}), 
                            '',
                            pdfComponents.paragraph({text: [
                                {text: `${pluralize(Object.keys(_data.invoiceData).length, 'App')}`, color: '#464646', bold: true, },
                                {text: ` (${pluralize(packageCount, 'Package')})`, color: '#252525'}
                            ], fontSize: 11, styles: {alignment: 'right'}, 
                            margin: [0, 10, 0, 0]}),
                        ],
                        // [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ],
                        // [ { text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4' ]
                      ]
                    },
                    margin: [0,0,0,14]
                },
                {
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0) ? 0 : 0.5;
                        },
                        vLineWidth: function(i, node){
                            return 0;
                        }
                    }, 
                    table: {
                      widths: [ 510 ],
                      headerRows: 1,
              
                      body: [
                        [ 
                            pdfComponents.paragraph({
                                text: 'Billing Information', 
                                styles: {borderColor: ['white', 'white', 'white', '#CAD0DB']},
                                bold: true, fontSize: 11, color: '#252525', margin: [0, 10, 0, 1]
                            }), 
                        ],
                      ]
                    }
                },
                {
                    layout: 'noBorders', 
                    table: {
                      headerRows: 0,
                      widths: [ 510 ],
              
                      body: [
                        [
                            pdfComponents.paragraph({
                                text: 'Consumer',
                                color: '#252525',
                                fontSize: 11,
                                bold: true,
                                margin: [6, 10, 0, 0]
                            })
                        ],
                        [
                            pdfComponents.paragraph({
                                text: _data.developer?.username,
                                color: '#252525',
                                fontSize: 11,
                                margin: [6, 0, 0, 0]
                            }) , 
                        ],
                        [
                            pdfComponents.paragraph({
                                text: 'Email',
                                color: '#252525',
                                fontSize: 11,
                                bold: true,
                                margin: [6, 8, 0, 0]
                            })
                        ],
                        [
                            pdfComponents.paragraph({
                                text: _data.developer?.email,
                                color: '#252525',
                                fontSize: 11,
                                margin: [6, 0, 0, 0]
                            }) , 
                        ],
    
                        [
                            pdfComponents.paragraph({
                                text: 'Billing Address',
                                color: '#252525',
                                fontSize: 11,
                                bold: true,
                                margin: [6, 8, 0, 0]
                            })
                        ],
                        [
                            pdfComponents.paragraph({
                                text: currentUser.billing?.billingAddress || '',
                                color: '#252525',
                                fontSize: 11,
                                margin: [6, 0, 0, 0]
                            }) , 
                        ]
                    ]
                    },
                    margin: [0,0,0,14]
                },
    
                pdfComponents.paragraph({text: 'App Details', color: '#333', fontSize: 12, bold: true, margin: [0,0,0,0]}),
                pdfComponents.horizontalLine({lineWidth: 0.1}),

                ..._invoices
            ],
            defaultStyle: {
                font: "interFont",
            },
            pageMargins: [36, 30, 36, 30],
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download(`${currentUser.username}_invoice_${moment().month(_data.month - 1).format("MMM")}${_data.year}`, () => {
            setTimeout(() => {
                window.close();
            }, 200);
        });

        pdfDocGenerator.getDataUrl((dataUrl) => {
            const targetElement = iframeRef.current;
            const iframe = document.createElement("iframe");
            iframe.style = "height: 100%;width: 100%;";
            iframe.src = dataUrl;
            if (targetElement) targetElement.appendChild(iframe);
        });

    }
    

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        formatData({
            "developer": {
              "email": "akash.k@itorix.com",
              "username": "akash k"
            },
            "invoiceData": {
              "4a813e5a8c654d138f98daadded0635h": [
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "1607.67"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "96746.3"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "231007.8"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "3075.27"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "379379.13"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "365441.89"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "4493.95"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "331248.38"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "4579.56"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "5056.53"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "3222.03"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "217128.09"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "1705.51"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "104756.76"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "1583.21"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "70767.22"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "1754.43"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "72704.46"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727166",
                  "packageName": "PetStore-5",
                  "totalAmount": "1729.97"
                },
                {
                  "appName": "sample_app",
                  "appId": "4a813e5a8c654d138f98daadded0635h",
                  "developerId": "akash.k@itorix.com",
                  "packageId": "651bc64d906b924c07727165",
                  "packageName": "PetStore-4",
                  "totalAmount": "102673.08"
                }
              ]
            },
            "month": 10,
            "year": 2023,
            "invoiceId": "ef24c57830094439a46953cea1c37315",
        })
        // setPdfLoading({
        //     apiLoading: true,
        //     graphLoading: true,
        // });
        // setChartData(tempData);
    };

    return (
        <div className='w-100vw bg-currentBackground h-100vh'>
            <div className='mt-80px container'>
                <div id="iframeContainer" ref={iframeRef} style={{ height: "100vh", width: "90vw" }}></div>
            </div>
        </div>
    );
}
