import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetAuthenticatedState } from '../../app/Features/Users/UserSlice'
import { getCookie, removeCookie } from '../../utils/cookie'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import API from '../../api'

export default function SSOLogout() {
    const { guestsetting : { workspaceId }} = useSelector((state) => state.setting)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        if(workspaceId){
            if (localStorage.getItem("ssoLogin")) {
                dispatch(resetAuthenticatedState());
    
                localStorage.removeItem("ssoLogin");
                API.logOutWithSSO()
                .then(res => {
                    // navigate('/login')
                }).catch(err => {
                    console.log('err', err)
                })
                // const redirectUrl = localStorage.getItem("sso-logout-source")
          
                // if(redirectUrl){
                //     localStorage.removeItem("sso-logout-source")
                //     window.location.href = `https://${workspaceId}.apiwiz.io/sso/logout?redirect=${redirectUrl}`
                
                // } else {
                //     window.location.href = `https://${workspaceId}.apiwiz.io/sso/logout`
                // }
    
            } else{
                // window.location.href = `https://${workspaceId}.apiwiz.io/sso/logout`
            }
        }
    }, [workspaceId])
    
    return (
        <div className='h-100vh w-100vw'>
            <p className='text-center'>Logging you out...</p>
        </div>
    )
}
