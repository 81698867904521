import { ArrowLeft, ArrowRight } from "phosphor-react";
import { useState } from "react";
import { getPaginationList } from "../../utils/helper";
import GlobalIcons from "../Icons/GlobalIcons";

export default function PaginationV2({ pagination, cb }){
    const totalPageslist = Math.ceil(pagination.total / pagination.pageSize);
    const pagesList = getPaginationList(pagination.offset, totalPageslist);

    if(pagination.total <= pagination.pageSize) return null;

    return <div className="d-flex align-items-center justify-content-between paginationV2">
        <div>
            { pagination.offset !== 1 && <div className="d-flex paginationPrevious p-2 px-2 br-4px align-items-center cursor-pointer"
                onClick={() => cb(pagination.offset - 1)} >
                <ArrowLeft color="var(--content-color)" />
                <p className="ms-2 fw-600 fs-14px me-1">Previous</p>
            </div>}
        </div>
        <div>
            <div className="d-flex">
                {pagesList.map((item,index)=><div className={`cursor-pointer individualPage px-3 py-2 d-flex align-items-center justify-content-center ${pagination.offset === item ? "active":""}`} 
                    onClick={(e)=>{cb(item)}}><p>{item}</p></div>)}
            </div>

        </div>
        <div>
            { !(pagination.offset === Math.ceil(pagination.total / pagination.pageSize)) && <div className="d-flex paginationPrevious p-2 px-2 br-4px align-items-center cursor-pointer" 
                onClick={() => cb(pagination.offset + 1)} >
                <p className="me-2 fw-600 fs-14px ms-1">Next</p>
                <ArrowRight color="var(--content-color)" />
            </div>}
        </div>
    </div>
}