import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import API from '../../../api';
import Loading from '../../../components/Loader/Loading';

export default function BillingDetails(props) {
    const [data, setData] = useState({})
    const [loading, setloading] = useState(true)

    useEffect(() => {
        console.log('props.data', props.data)
        getData()
    }, [])

    const getData = () => {
        setloading(true)
        API.getAppTransactions(props.data.appId).then(res => {
            const { data } = res.data
            let transaction = 0

            if(!data.length) {
                transaction = 0
            } else{
                const totalRuns = data[0].totalRuns
                transaction = totalRuns
            }
            
            API.getBillingAPps(props.data.appId, transaction)
            .then(res => {
                setloading(false)
                setData(res.data?.data || {})
            }).catch(err => {
                setloading(false)
            })
        })
        .catch(err => {
            setloading(false)
        })
    }
    
    return (
        <Modal
            {...props}
            size="md"
            contentClassName='homeModalForLightModev2'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-content-color fw-500 fs-16px'>Billing Details</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-content-color fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(25vh)" }}
                className="overflow-scroll noscrollbar"
            >
                {loading
                ? <Loading />
                : <div className=''>
                    <div className='mb-3'>
                        <p className='text-content-color fs-14px fw-400'>Number of Transactions: {data.transaction || 0}</p>
                    </div>

                    <div className='mb-3 d-flex align-items-center'>
                        <p className='text-content-color fs-14px fw-400'>Start Date: </p>
                        <p className='text-content-color fs-14px fw-500 ml-4px'>{data.startDate || ''}</p>

                        <div className='mx-2 mb-4px'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 8H13.5" stroke="var(--content-color)" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9 3.5L13.5 8L9 12.5" stroke="var(--content-color)" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <p className='text-content-color fs-14px fw-400'>End Date: </p>
                        <p className='text-content-color fs-14px fw-500 ml-4px'>{data.endDate || ''}</p>
                    </div>

                    <div className='mt-3 bg-base-color p-12px dfaic justify-content-between'>
                        <div>
                            <p className='fs-12px fw-400 text-content-color'>Billed Amount</p>
                            <p className='fs-14px fw-500 text-content-color mt-2px'>${data.billedAmount}</p>
                        </div>

                        <div className='d-flex align-items-center br-4px gap-8px px-16px py-6px cursor'
                            style={{border: '0.5px solid var(--content-color)'}}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.70312 6.01562L7 8.3125L9.29688 6.01562" stroke="var(--content-color)" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 2.1875V8.3125" stroke="var(--content-color)" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.8125 8.3125V11.375C11.8125 11.491 11.7664 11.6023 11.6844 11.6844C11.6023 11.7664 11.491 11.8125 11.375 11.8125H2.625C2.50897 11.8125 2.39769 11.7664 2.31564 11.6844C2.23359 11.6023 2.1875 11.491 2.1875 11.375V8.3125" stroke="var(--content-color)" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <p className='fs-12px fw-500 text-content-color'>Invoice</p>
                        </div>
                    </div>
                </div>}
            </Modal.Body>
        </Modal>
    )
}
