import React from 'react'

export default function StarRating({rating=0, onClick=null}) {

    const getImage = (id, _rating) => {
        if ((id + 1) > rating && onClick === null){
            return "star-half.svg"
        } else {
            return "star-full.svg"
        }
    }

    return (
        <div className='dfaic gap-6px'>
            {new Array(5).fill(1).map((_, index) => {
                if (onClick === null && (index + 0.4) > rating) return
                if (onClick !== null && (index === rating || index > rating)) return <img
                    height={16} width={16} alt='' className={'cursor'}
                    onClick={() => onClick !== null ? onClick(index+1) : {}}
                    src={`/assets/ratings/star-unfilled.svg`}
                />
                return (
                <img
                    height={16} width={16} alt='' className={onClick === null ? '' : 'cursor'}
                    onClick={() => onClick !== null ? onClick(index+1) : {}}
                    src={`/assets/ratings/${getImage(index, rating)}`}
                />
                );
            })}
        </div>
    )
}
