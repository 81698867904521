import React, { useEffect, useRef, useState } from 'react'
import CustomNavbar from '../Dashboards/components/CustomNavbar'
import CustomFooter from '../Dashboards/components/CustomFooter'
import { useNavigate, useParams } from 'react-router';
import API from '../../api';
import { throwServerError } from '../../utils/helper';
import EditorJSOutput from '../../components/EditorJSOutput';
import moment from 'moment';
import Loading from '../../components/Loader/Loading';

export default function EachUsecase() {
    const { id } = useParams();
    const [data, setData] = useState([])
    const bodyContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getCurrentUsecase()
    }, [])

    const getCurrentUsecase = () => {
        setIsLoading(true)
        API.getEachProduct(id).then(res => {
            setData(res.data?.data || {})
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            throwServerError(err)
        })
    }

    const navigate = useNavigate()

    return (
        <div className='w-100vw bg-currentBackground'>
            <CustomNavbar />

            <div className='h-100 mt-70px'>
                <div className="container pt-16px h-100vh">
                    {isLoading 
                    ? <Loading />
                    : <>
                        <div className="d-flex justify-content-between h-37px" style={{padding: "0 162px"}}>
                            <div className="d-flex fs-13px gap-5px">
                                <div className="text-theme-accent cursor" onClick={() => navigate(`/usecases`)}>
                                    All Usecases
                                </div>
                                <div>{` / `}</div>
                                <div>{data.title}</div>
                            </div>
                            <div className="fs-13px text-theme-content-subtle">
                                Last modified:{" "}
                                {moment(
                                    data.updatedAt,
                                    "YYYY-MM-DDTHH:mm:ss.sssZ"
                                ).fromNow()}
                            </div>
                        </div>
                        <div
                        ref={bodyContainerRef}
                        className="h--37px overflow-scroll-y noscrollbar" style={{padding: "0 162px"}}
                        >
                            <EditorJSOutput applyTheme data={data.content} />
                        </div>
                    </>}
                </div>
            </div>

            <CustomFooter />
        </div>
    )
}
