import { Percent } from "phosphor-react"
import UserIcons from "../../../../components/Icons/UserIcons"
import Select from 'react-select';

export default function UserStatusCard({title,count,percent,positive,isMonthCompare}){
    return <div className="userCard surface-875 p-24px v1-border-01 br-8px">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <div className="mr-17px">
                    <UserIcons type={title=="SignUps"?"download-right":title=="Active Users"?"people":title=="Inactive Users"?"circlePerson":""}/>
                </div>
                <div>
                    {title}
                </div>
            </div>
            <div>
            </div>
        </div>
        <div className="mt-24px">
        <p className="fw-600 fs-36px">{parseInt(count).toLocaleString()}</p>
        {isMonthCompare && <div className="d-flex mt-16px">
                <div className="d-flex">
                    <div className="mr-3px mt-2px">
                        {positive?<UserIcons type="smUp"/>:<UserIcons type="smDown"/>}
                    </div>
                    <p className={`fs-14px mt-1 ${positive ? "grass-09":"red-11"}`}>{percent}%</p>
                </div>
                <p className="ms-2 fs-14px mt-1">vs last month</p>
            </div>} 
        </div>

    </div>
}