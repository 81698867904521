import { createSlice } from "@reduxjs/toolkit";
import {
  getOperationListFromSwagger,
  getSelectedOperation,
  setOperationSecurity,
  getSwaggerGlobalSecurities,
  searchOperations,
  setTryoutAxiosOptions,
  filterResourcesFromOperations,
  flattenOperations,
  extractApiMethodsCount
} from "@apiwiz/oas";
import { OAS_SPEC_VERSION, SRC_PARAM_VALUES } from "../../../service/constants";
import { debounceFn, getFilterResources, objectDeepClone } from "../../../utils/helper";
import _ from 'lodash';

const setTryoutAxiosDebounceFn = debounceFn(() => {
  window.dispatch(swaggerSlice.actions.setTryoutAxios());
}, 500);

const stopOperationLoading = () => {
  setTimeout(() => {
    window.dispatch(
      swaggerSlice.actions.setSwaggerFields({
        field: "selectOpLoading",
        value: false,
      })
    );
  }, 500);
};

const initialState = {
  swagger: null,
  metadata: null,
  info: {},
  source: {
    operations: {},
  },
  currEditorView: "",
  operations: {},
  operationPlainList: [],
  apiMethodsCount: {},
  selectedOperation: null,
  tags: [],
  filter: {
    tag: null,
    search: "",
  },
  securities: {
    apiKey: {},
    basic: {},
    bearer: {},
    oauth2: {},
    openIdConnect: {},
  },
  tryoutOperation: null,

  axiosOptions: {},
  tryoutHasErrors: [],
  tryoutDataLoad: false,
  editorSwagger: {},
  currentOperation: {},
  currentSelectedSwagger: {},
  oas: OAS_SPEC_VERSION.OAS3,
  advancedView: false,
  selectOpLoading: false,
  yAxisScroll: false
};

export const swaggerSlice = createSlice({
  name: "swagger",
  initialState,
  reducers: {
    resetSwagger: (state) => {
      for (let key in initialState) state[key] = initialState[key];
    },
    resetTryout: (state) => {
      [
        "tryoutOperation",
        "axiosOptions",
        "tryoutHasErrors",
        "tryoutDataLoad",
      ].forEach((field) => {
        state[field] = initialState[field];
      });
    },
    setEditor: (state, { payload }) => {
      state.editorSwagger = payload;
    },
    setSwaggerFields(state, action) {
      let { field, value } = action.payload;
      state[field] = value;
    },
    setSwaggerFilterProp(state, action){
      let {prop, value} = action.payload;
      let _filter = objectDeepClone(state.filter)
      _filter[prop] = value;
      state.filter = _filter;
    },

    setCurrentSelectedSwagger: (state, { payload }) => {
      state.currentSelectedSwagger = payload;
    },

    setCurrEditorView: (state, { payload }) => {
      state.currEditorView = payload;
    },
    setSwaggerFromEditor: (state, { payload }) => {
      //TODO: handle x-metadata
      // payload = formatSwagger3(payload);
      state.editorSwagger = payload;
    },
    setAdvancedView(state, action) {
      state.advancedView = action.payload;
    },
    /*
    setNoEditor(state, action){
      state.noEditor = action.payload;
    },
    */
    setSwagger: (state, { payload }) => {
      let _swagger;

      if (payload.swagger && payload.swagger.constructor === Object)
        _swagger = payload.swagger;
      else _swagger = payload;

      if (_swagger.info) state.info = { ..._swagger.info };

      state.swagger = _swagger;
      let _oas = _swagger.hasOwnProperty('openapi') ? '3.0' : '2.0'
      state.oas = _oas
    },

    setOas: (state, { payload }) => {
      state.oas = payload;
    },

    setOperationFromSwagger: (state, action) => {
      const { payload } = action;
      const { code, currentOp, first, filterParam, srcParam } = payload;
      let { operations } = getOperationListFromSwagger({
        swagger: code,
      });
      if(filterParam)
      {
        let _filterResources = getFilterResources({ encoded: filterParam});

        /**
         * Below code added by Deva.
         * Basepath will get appended to path at the time of Package creation => resource selection step itself
         * This code block will handle removal of basePath from path value before sending for filtering the resource list
         */
        if(srcParam === SRC_PARAM_VALUES.PACKAGE)
        {
          let _basePath = code.basePath;
          if(!_basePath)
          {
            let servers = code.servers || [];
            let resource = _filterResources[0] || {};
            for(let server of servers)
            {
              _basePath = server['x-basePath'] || "";
              if(_basePath && (resource.path||"").startsWith(_basePath)) break;
            }
          }

          if(_basePath) _filterResources = _filterResources.map(el => ({...el, path: el.path.replace(_basePath, "")}))
        }
        if(_filterResources.length) operations = filterResourcesFromOperations({operations, resources: _filterResources})
      }
      let {list, tags} = flattenOperations({operations})
      state.tags = tags;
      state.operationPlainList = list;
      state.operations = operations;
      state.source.operations = objectDeepClone(operations);
      state.apiMethodsCount = extractApiMethodsCount({list});

      if (first && list.length) {
        action.payload = list[0];
        swaggerSlice.caseReducers.setSelectedOperation(state, action);
      }

      if (
        !first &&
        currentOp &&
        Object.keys(currentOp).length > 0 &&
        operations[currentOp.tag] &&
        operations[currentOp.tag][currentOp.index]
      ) {
        action.payload = operations[currentOp.tag][currentOp.index];
        swaggerSlice.caseReducers.setSelectedOperation(state, action);
      }

      /* For future use - in case if v find any issues.
      if (
        currentOp &&
        Object.keys(currentOp).length > 0 &&
        operations[currentOp.tag] &&
        operations[currentOp.tag][currentOp.index]
      ) {
        action.payload = operations[currentOp.tag][currentOp.index];
        swaggerSlice.caseReducers.setSelectedOperation(state, action);
      }
      */

      // let cpTag = state.currentOperation.tag
      // let cpInd = state.currentOperation.index
      // console.log(operations[cpTag][cpInd]);
    },
    searchOperation: (state, { payload }) => {
      const { text } = payload;
      state.operations = searchOperations({
        searchText: text,
        operations: state.source.operations,
      });
      /*
      let regExp = new RegExp(text, "i");
      let objRef = objectDeepClone(state.source.operations);
      if (text) {
        let result = {};
        for (let o in objRef) {
          if (o && o.search(regExp) != -1) {
            result[o] = objRef[o];
          } else {
            if (!result[o]) result[o] = [];
            for (let a of objRef[o]) {
              if (
                a.apiMethod.search(regExp) != -1 ||
                a.endpoint.search(regExp) != -1 ||
                a.summary.search(regExp) != -1
              ) {
                result[o].push(a);
              }
            }
            if (!result[o].length) delete result[o];
          }
        }
        for (let tag in result) {
          for (let i in result[tag]) {
            result[tag][i].index = i;
          }
        }

        result = calcPrevNextLogic(result);
        state.operations = result;
      } else {
        for (let tag in objRef) {
          for (let i in objRef[tag]) {
            objRef[tag][i].index = i;
          }
        }
        objRef = calcPrevNextLogic(objRef);
        state.operations = objRef;
      }
      */
    },
    setSelectedOperation: (state, action) => {
      let payload = action.payload;
      if (payload && Object.keys(payload).length > 0) {
        state.selectOpLoading = true;
        stopOperationLoading();
        let { operation } = getSelectedOperation({
          swaggerJson: state.swagger,
          oas: state.oas,
          selectedOp: payload,
          securities: state.securities,
        });
        let { tag, index } = operation;
        state.selectedOperation = operation;
        state.operations[tag][index] = operation;
        let [sTag, sIndex] = operation.__id;
        state.source.operations[sTag][sIndex] = operation;
        swaggerSlice.caseReducers.setTryout(state, {payload: operation});
      } else state.selectedOperation = payload;

      /*
      if (payload && Object.keys(payload).length > 0) {
        state.selectOpLoading = true;
        stopOperationLoading();
        let operation = objectDeepClone(payload);
        let { tag, index, api } = operation;

        state.currentOperation = {tag, index};
        localStorage.setItem("currentOp", JSON.stringify({ tag, index }));

        let swagger = objectDeepClone(state.swagger);

        if (isOAS3(state.oas)) {
          if (!operation.requestBody) {
            let requestBody = api["requestBody"];
            if (requestBody) {
              requestBody = extractFromSwagger({ swagger, value: requestBody });

              operation.requestBody = objectDeepClone(requestBody);

              let __consumes = [];
              if (requestBody.content) {
                __consumes = Object.keys(requestBody.content);
              }
              operation.__consumes = __consumes;
            }
          }

          //TODO: show toast if no parameters exists
          if (!operation.parameters) {
            let parameters = api["parameters"];
            parameters = extractFromSwagger({ swagger, value: parameters });

            if (parameters) {
              operation.parameters = objectDeepClone(parameters);
              operation.parameters = operation.parameters.map((x) => {
                if (x.properties && x.properties.__fromRef) {
                  return x.properties[
                    Object.keys(x.properties).filter(
                      (x) => !x.startsWith("__")
                    )[0]
                  ];
                }
                return x;
              });
            }
          }

          if (!operation.responses) {
            let responses = api["responses"];
            responses = extractFromSwagger({ swagger, value: responses });

            operation.responses = objectDeepClone(responses);

            let __produces = new Set();
            for (let statusCode in responses) {
              let content = responses[statusCode].content;
              if (content) {
                for (let mimeType of Object.keys(content))
                  __produces.add(mimeType);
              }
            }

            operation.__produces = [...__produces];
          }

          if (!operation.servers) {
            let _servers = swagger.servers;
            if (_servers && _servers.length) {
              operation.servers = objectDeepClone(_servers);
            }
          }
        }

        if (isOAS2(state.oas)) {
          if (!operation.requestBody || !operation.parameters) {
            let consumes = api.consumes;
            if (!consumes || !consumes.length)
              consumes =
                state.swagger.consumes && state.swagger.consumes.length
                  ? state.swagger.consumes
                  : ["application/json"];

            operation.__consumes = consumes;

            let parameters = api["parameters"] || [];
            parameters = extractFromSwagger({ swagger, value: parameters });
            parameters = objectDeepClone(parameters);
            parameters = parameters.map((x) => {
              if (x.properties && x.properties.__fromRef) {
                return x.properties[
                  Object.keys(x.properties).filter(
                    (x) => !x.startsWith("__")
                  )[0]
                ];
              }
              return x;
            });
            if (parameters && parameters.length) {
              let bodyParams = parameters.filter((x) => x.in === "body");
              let otherParams = parameters.filter((x) => x.in !== "body");
              if (!operation.requestBody && bodyParams.length) {
                let schema = {};
                let __paramDetails = [];
                for (let body of bodyParams) {
                  if (!schema.properties) schema.properties = {};
                  let bSchema = body.schema;
                  delete body.schema;
                  schema = bSchema;
                  __paramDetails.push(body);
                }
                let requestBody = {
                  content: {},
                };

                for (let consume of consumes) {
                  requestBody.content[consume] = { schema, __paramDetails };
                }
                operation.requestBody = objectDeepClone(requestBody);
              }
              if (!operation.parameters && otherParams.length) {
                let parameters = otherParams;
                operation.parameters = objectDeepClone(parameters);
              }
            }
          }

          if (!operation.responses) {
            let responses = api["responses"];
            responses = extractFromSwagger({ swagger, value: responses });
            responses = objectDeepClone(responses);

            //format responses for ref from #/responses/*
            for (let res in responses) {
              if (
                responses[res].properties &&
                responses[res].properties.__fromRef
              ) {
                let refName = Object.keys(responses[res].properties).filter(
                  (x) => !x.startsWith("__")
                )[0];
                if (responses[res].properties[refName].schema) {
                  responses[res] = { ...responses[res].properties[refName] };
                }
              }
            }

            let produces = api.produces;
            if (!produces || !produces.length) {
              produces =
                state.swagger &&
                  state.swagger.produces &&
                  Array.isArray(state.swagger.produces) &&
                  state.swagger.produces.length
                  ? state.swagger.produces
                  : ["application/json"];
            }

            operation.__produces = produces;

            let _responses = {};
            for (let status in responses) {
              if (!_responses[status]) _responses[status] = {};
              if (responses[status].description) {
                _responses[status].description = responses[status].description;
                delete responses[status].description;
              }

              if (responses[status].headers) {
                _responses[status].headers = responses[status].headers;
                delete responses[status].headers;
              }
              _responses[status].content = {};
              for (let mimeType of produces) {
                _responses[status].content[mimeType] = responses[status];
              }
            }

            operation.responses = objectDeepClone(_responses);
          }

          if (!operation.servers) {
            operation.servers = [];
            let _xservers = swagger["x-servers"];
            if (_xservers && _xservers.length) {
              for (let server of _xservers) {
                let _url = mergeURL(server.url, swagger["basePath"]);
                operation.servers.push({
                  ...server,
                  url: _url,
                  schemes: swagger.schemes,
                });
              }
            }

            if (!operation.servers.length) {
              let host = swagger["host"];
              let _basepath = mergeURL(host, swagger["basePath"]);
              operation.servers.push({
                url: `https://${_basepath}`,
                schemes: swagger.schemes,
              });
            }
          }
        }

        if (!operation.__schemes) {
          operation.__schemes = swagger.schemes;
        }

        if (operation.requestBody && operation.requestBody.content) {
          for (let mimeType in operation.requestBody.content) {
            if (operation.requestBody.content[mimeType].schema) {
              if (!operation.requestBody.content[mimeType].schema.__sample) {
                let __sample = {};
                try {
                  let _schema = objectDeepClone(
                    operation.requestBody.content[mimeType].schema
                  );
                  let contentType = getContentTypeFormat(mimeType);
                  let type = "json";
                  if (contentType === "xml") type = "xml";
                  __sample = extractExample({
                    swagger,
                    value: objectDeepClone(_schema),
                    type,
                  });
                } catch (err) {
                  __sample = null;
                }
                operation.requestBody.content[mimeType].schema.__sample =
                  __sample;
              }
            }
          }
        }

        if (operation.responses) {
          for (let status in operation.responses) {
            if (operation.responses[status].content) {
              for (let mimeType in operation.responses[status].content) {
                if (operation.responses[status].content[mimeType].schema) {
                  if (
                    !operation.responses[status].content[mimeType].schema
                      .__sample
                  ) {
                    let __sample = null;
                    try {
                      let _schema = objectDeepClone(
                        operation.responses[status].content[mimeType].schema
                      );
                      let contentType = getContentTypeFormat(mimeType);
                      let type = "json";
                      if (contentType === "xml") type = "xml";
                      __sample = extractExample({
                        swagger,
                        value: objectDeepClone(_schema),
                        type,
                      });
                    } catch (err) {
                      __sample = null;
                    }
                    operation.responses[status].content[
                      mimeType
                    ].schema.__sample = __sample;
                  }
                }
              }
            }
          }
        }

        if (!operation.headerParams)
          operation.headerParams = getHeaderParams(operation);
        if (!operation.queryParams)
          operation.queryParams = getQueryParams(operation);
        if (!operation.pathParams) {
          let pathParams = getPathParams(operation);
          if (pathParams && pathParams.length) {
            operation.pathParams = [];
            let duplicateParams = {};
            for (let param of pathParams) {
              if (!duplicateParams[param.name])
                operation.pathParams.push(param);
              duplicateParams[param.name] = true;
            }
          }
        }
        if (!operation.cookieParams)
          operation.cookieParams = getCookieParams(operation);
        if (!operation.formParams)
          operation.formParams = getFormParams(operation);

        if (api.security) {
          operation.__security = {};
          let globalSecurities =
            swagger.securityDefinitions ||
            (swagger.components && swagger.components.securitySchemes) ||
            {};
          for (let security of api.security) {
            let keys = Object.keys(security);
            for (let key of keys) {
              let globalSecurity = globalSecurities[key];
              if (globalSecurity) {
                globalSecurity.securityName = key;
                let type = globalSecurity.scheme || globalSecurity.type;
                if (type === "oauth2") {
                  if (!operation.__security[type])
                    operation.__security[type] = [];
                  if (globalSecurity.flows) {
                    for (let flow in globalSecurity.flows) {
                      let flowSecurity = globalSecurity.flows[flow];
                      flowSecurity.flow = flow;
                      operation.__security[type].push(flowSecurity);
                    }
                  } else {
                    operation.__security[type].push(globalSecurity);
                  }
                }

                if (type === "apiKey") {
                  if (!operation.__security[type]) operation.__security[type] = [];
                  operation.__security[type].push(globalSecurity);
                }

                if (type === "bearer") {
                  operation.__security[type] = [{ bearer: true }];
                }

                if (type === "basic") {
                  operation.__security[type] = { basic: true };
                }
              }
            }
          }
        }
        
        _setOperationSecurity(state, operation);

        state.selectedOperation = operation;
        state.operations[tag][index] = operation;
        let [sTag, sIndex] = operation.__id;
        state.source.operations[sTag][sIndex] = operation;
      } else state.selectedOperation = payload;
      */
    },
    setSelectedOperationSecurity(state) {
      if (state.selectedOperation) {
        let operation = objectDeepClone(state.selectedOperation);

        operation = setOperationSecurity({
          operation,
          securities: state.securities,
        });

        state.selectedOperation = operation;
        let { tag, index } = operation;
        state.operations[tag][index] = operation;
        let [sTag, sIndex] = operation.__id;
        state.source.operations[sTag][sIndex] = operation;
      }
    },
    setTryoutOperationSecurity(state) {
      if (state.tryoutOperation) {
        let operation = objectDeepClone(state.tryoutOperation);

        operation = setOperationSecurity({
          operation,
          securities: state.securities,
        });
        state.tryoutOperation = operation;
        setTryoutAxiosDebounceFn();

      }
    },
    setInitGlobalSecurities(state, action) {
      let globalSecurities = getSwaggerGlobalSecurities({
        swagger: action.payload,
      });
      /*
      let swagger = action.payload;
      let securities = swagger.securityDefinitions || (swagger.components && swagger.components.securitySchemes) || {};
      let apiKey = {};
      let basic = {};
      let bearer = {};
      let oauth2 = {};
      let openIdConnect = {};

      for (let name in securities) {
        let security = objectDeepClone(securities[name])
        if (security.type === 'apiKey') {
          apiKey[name] = security;
        }
        if ((security.scheme || security.type) === 'basic') {
          basic = {
            username: '',
            password: ''
          }
        }
        if ((security.scheme || security.type) === 'bearer') {
          bearer = {
            accessToken: ''
          }
        }
        if (security.type === 'oauth2') {
          if (security.flow) {
            if (!oauth2[security.flow]) {
              oauth2[security.flow] = security;
            }

            let _scopes = oauth2[security.flow].scopes;
            if (!_scopes) _scopes = security.scopes;
            if (!_scopes) _scopes = {};
            for (let s in _scopes) {
              oauth2[security.flow].scopes[s] = { description: _scopes[s], checked: false }
            }
            oauth2[security.flow].authorizationUrl = security.authorizationUrl;
            oauth2[security.flow].tokenUrl = security.tokenUrl;
          }

          if (security.flows) {
            for (let flow in security.flows) {
              if (!oauth2[flow]) {
                oauth2[flow] = security.flows[flow];
              }

              let _scopes = oauth2[flow].scopes;
              if (!_scopes) _scopes = security.flows[flow].scopes;
              if (!_scopes) _scopes = {};
              for (let s in _scopes) {
                oauth2[flow].scopes[s] = { description: _scopes[s], checked: false }
              }
              oauth2[flow].flow = flow;
              oauth2[flow].authorizationUrl = security.flows[flow].authorizationUrl;
              oauth2[flow].tokenUrl = security.flows[flow].tokenUrl;
            }
          }
        }
        if (security.type === 'openIdConnect') { }
      }
      */
      state.securities = globalSecurities;
    },
    setSecurities(state, action) {
      let { prop, value } = action.payload;
      state.securities[prop] = value;
      // state.tryoutDataLoad = true;
    },
    setTryoutAxios(state) {
      let _tryoutOperation = objectDeepClone(state.tryoutOperation);
      if(!_tryoutOperation.__config) _tryoutOperation.__config = {};
      let _options = setTryoutAxiosOptions({
        operation: _tryoutOperation,
        securities: state.securities,
      });
      if (!_options) {
        state.tryoutDataLoad = false;
        state.axiosOptions = objectDeepClone({});
        return;
      }
      state.tryoutHasErrors = [];
      if (_options.error) {
        state.tryoutHasErrors.push(_options.error);
        state.tryoutDataLoad = false;
        state.axiosOptions = objectDeepClone({});
        return;
      }
      state.axiosOptions = _options;
      state.tryoutDataLoad = false;
      /*
      let _operation = objectDeepClone(state.tryoutOperation);
      if (!_operation) return;
      state.tryoutHasErrors = [];
      state.axiosOptions = {};
    
      let config = _operation.__config;
      //let security = _operation.__security;
    
      if (!config.server) {
        //throw error popup saying no error to try
        state.tryoutHasErrors.push('Server Url is not selected');
        state.tryoutDataLoad = false;
        return;
      }
      let url = config.server.url;
      let endpoint = _operation.endpoint;
      state.axiosOptions.requestUrl = mergeURL(url, endpoint);
      if (config.server.variables) {
        if (!config.server.__computedUrl) {
          //throw error popup saying to please fill server variables.
          state.tryoutHasErrors.push('Please fill Server variables.');
          state.tryoutDataLoad = false;
          return;
        }
        url = config.server.__computedUrl;
      }
    
      let bodyData = _operation.__bodyValue;
      let headerParams = _operation.headerParams || [];
      let queryParams = _operation.queryParams || [];
      let cookieParams = _operation.cookieParams || [];
      let pathParams = _operation.pathParams || [];
      let formParams = _operation.formParams || [];
    
      let apiMethod = _operation.apiMethod;
      */

      /*
      //Adding API Key Security Layer
      if (security && security.apiKey) {
        for (let apiKey of security.apiKey) {
          let securityName = apiKey.securityName;
          let param = state.securities.apiKey[securityName];
          if (param.__value) {
            if (param.in === 'header') {
              headerParams.push({ name: param.name, __value: param.__value })
            }
    
            if (param.in === 'cookie') {
              cookieParams.push({ name: param.name, __value: param.__value })
            }
    
            if (param.in === 'query') {
              queryParams.push({ name: param.name, __value: param.__value })
            }
          }
        }
      }
      */

      /*
      if (pathParams && pathParams.length) {
        for (let param of pathParams) {
          let value = param.__value
          if (!value) {
            value = getParamExample(param);
          }
          if (!value) {
            //throw error to get path param input
            state.tryoutHasErrors.push('Please fill all path variables.');
            state.tryoutDataLoad = false;
            return;
          }
          endpoint = endpoint.replace(`{${param.name}}`, value)
        }
      }
    
      url = mergeURL(url, endpoint)
    
    
      let params = []
      if (queryParams && queryParams.length) {
        params = queryParams.reduce((acc, curr) => {
          let value = curr.__value;
          if (!value) {
            value = getParamExample(curr);
          }
          if (value) acc.push(`${curr.name}=${value}`);
          return acc;
        }, []);
      }
    
      if (params.length) url += `?${params.join('&')}`
    
      let headers = {}
      if (headerParams && headerParams.length) {
        headers = headerParams.reduce((acc, curr) => {
          let value = curr.__value;
          if (!value) {
            value = getParamExample(curr);
          }
          if (value) acc[curr.name] = value;
          return acc;
        }, {});
      }
    
      if (!headers['Content-Type']) {
        headers['Content-Type'] = config.reqContentType;
      }
    
      if (!headers['Accept']) {
        headers['Accept'] = config.resContentType;
      }
    
      if (cookieParams && cookieParams.length) {
        let cookie = cookieParams.reduce((acc, curr) => {
          let value = curr.__value
          if (!value) {
            value = getParamExample(curr);
          }
          if (value) acc += `${curr.name}=${value};`
          return acc
        }, '')
    
        if (cookie) {
          headers.Cookie = cookie
        }
      }
    
      let data = null
    
      if (formParams && formParams.length) {
        const form = new FormData();
        const _form = [];
        for (let param of formParams) {
          let value = param.__value;
          if (!value) {
            value = getParamExample(param)
          }
    
          if (value) {
            form.append(param.name, value);
            _form.push([param.name, value]);
          }
        }
        if (_form.length) data = form;
      }
    
      if (bodyData) {
        let isJson = checkIfValidJSON(bodyData);
        if (isJson) {
          data = JSON.parse(bodyData);
        }
        else if (bodyData.constructor === String) {
          data = bodyData;
        }
      }
    
    
      let auth = null;
    
      let security = _operation.__security;
      if (security) {
        */
      /*
        //Adding OAuth2 Authentication Security Layer
        if (security.oauth2 && Object.keys(state.securities.oauth2).length) {
          for (let flowSecurity of security.oauth2) {
            let flow = flowSecurity.flow;
            let globalSecurity = state.securities.oauth2[flow];
            if (globalSecurity && globalSecurity.accessToken) {
              headers['Authorization'] = `Bearer ${globalSecurity.accessToken}`
            }
          }
        }
    
        //Adding Bearer Authentication Security Layer
        if (security.bearer && Object.keys(state.securities.bearer).length && state.securities.bearer.accessToken) {
          headers['Authorization'] = `Bearer ${state.securities.bearer.accessToken}`
        }
        */

      /*
        //Adding Basic Authentication Security Layer
        if ((security.basic)
          && Object.keys(state.securities.basic).length
          && state.securities.basic.username
          && state.securities.basic.password) {
          auth = {
            username: state.securities.basic.username,
            password: state.securities.basic.password
          }
        }
      }
    
    
      let axiosConfig = { headers };
      if (auth) axiosConfig['auth'] = auth;
    
      state.axiosOptions = {
        ...state.axiosOptions,
        url,
        headers,
        axiosConfig,
        apiMethod,
        data
      }
    
      state.tryoutDataLoad = false;
      */
    },
    setTryoutLoad(state, action) {
      state.tryoutDataLoad = action.payload;
    },
    setTryout(state, action) {
      let operation = action.payload;
      let _operation = objectDeepClone(operation);
      state.tryoutOperation = _operation;
      setTryoutAxiosDebounceFn();
    },
    setTryoutParamsValue(state, action) {
      let { prop, index, value } = action.payload;
      let _params = objectDeepClone(state.tryoutOperation[prop]);
      _params[index].__value = value;
      state.tryoutOperation[prop] = _params;
      setTryoutAxiosDebounceFn();
    },
    setTryoutBodyParamsValue(state, action) {
      let { value } = action.payload;
      let _op = objectDeepClone(state.tryoutOperation);
      _op.__bodyValue = value;
      state.tryoutOperation = _op;
      setTryoutAxiosDebounceFn();
    },
    setTryoutServers(state, action) {
      let { servers } = action.payload;
      let _op = objectDeepClone(state.tryoutOperation);
      _op.servers = servers;
      state.tryoutOperation = _op;
      setTryoutAxiosDebounceFn();
    },
    setTryoutConfig(state, action) {
      let { config } = action.payload;
      let _op = objectDeepClone(state.tryoutOperation);
      _op.__config = config;
      state.tryoutOperation = _op;
      setTryoutAxiosDebounceFn();
    },
    setTryoutResult(state, action) {
      let result = action.payload;
      let _op = objectDeepClone(state.tryoutOperation);
      _op.__result = result;
      state.tryoutOperation = _op;
    },
    setCurrentOperation: (state, { payload }) => {
      state.currentOperation = payload;
    },
  },
});

export const {
  setCurrentOperation,
  setSwagger,
  setCurrEditorView,
  setOas,
  setSwaggerFields,
  setSwaggerFilterProp,
  setAdvancedView,
  setSwaggerFromEditor,
  setOperationFromSwagger,
  setInitGlobalSecurities,
  searchOperation,
  setSelectedOperation,
  setSelectedOperationSecurity,
  setTryoutOperationSecurity,
  setEditor,
  setSecurities,
  resetSwagger,
  resetTryout,
  setCurrentSelectedSwagger,

  setTryoutLoad,
  setTryout,
  setTryoutServers,
  setTryoutConfig,
  setTryoutBodyParamsValue,
  setTryoutParamsValue,
  setTryoutResult,
} = swaggerSlice.actions;
export default swaggerSlice.reducer;
