import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Example from "./Example";
import {
  getParamExample,
  getParamType,
  getParamRangeText,
  getParamEnums,
  getParamPattern,
} from "@apiwiz/oas/helper";
import PropertyDescription from "./PropertyDescription";
import { CaretRight } from "phosphor-react";
import CaretDown from "./CaretDown";

const Parameters = ({ title, parameter, isResponse }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        className={`cursor d-flex justify-content-between w-100 py-6px pr-6px pl-10px br-4px h-34px ${
          open ? "border-left-theme-secondary bg-theme-primary-subtle" : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`d-flex align-items-center gap-4px`}>
          {!open ? (
            <CaretRight
              color="var(--primary-color)"
              size={13}
              className="mr-4px"
            />
          ) : (
            <CaretDown />
          )}
          <div className={`text-theme-primary fs-13px lh-21px h-21px ${open ? "fw-600" : ""}`}>
            {title}
          </div>
        </div>
      </div>
      {open ? (
        <div className="mb-16px">
          {Object.keys(parameter).map((param, field) => (
            <PropertyDescription
              field={isResponse ? param : parameter[param].name}
              type={getParamType(parameter[param])}
              example={getParamExample(parameter[param])}
              description={parameter[param].description}
              showExpandOrCollapse={false}
              showChildren={false}
              rangeText={getParamRangeText(parameter[param])}
              enums={getParamEnums(parameter[param])}
              pattern={getParamPattern(parameter[param])}
              required={
                parameter[param].__required ||
                (typeof parameter[param].required === "boolean" &&
                  parameter[param].required)
              }
            />
          ))}
        </div>
      ) : null}
    </div>
  );

  /*
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center my-2">
        <div
          className="fw-bold text-200"
          style={{ fontSize: advancedView ? "16px" : "14px" }}
        >
          {title}
        </div>
        <div className="d-flex justify-content-center swagger-btn-parent">
          <p
            onClick={handleExampleChange}
            className={`text-200 swagger-btn example ${
              exampleScheme === "Example" ? "active" : ""
            } cursor`}
          >
            Example
          </p>
          <p
            onClick={handlechemeChange}
            className={`swagger-btn text-200 scheme ${
              exampleScheme === "Schema" ? "active" : ""
            } cursor`}
          >
            Schema
          </p>
        </div>
      </div>
      <div>
        {exampleScheme === "Example"
          ? Object.keys(parameter).map((param, index) => (
              <div key={index}>
                <div className="d-flex">
                  <p className="text-50 o-05" style={{ fontSize: "12px" }}>
                    {parameter[param]?.name || param}
                  </p>
                  <span>
                    {parameter[param].__required && (
                      <sup className="text-danger">*</sup>
                    )}
                  </span>
                </div>
                <Example
                  key={index}
                  sample={getParamExample(parameter[param])}
                  dataType={getParamType(parameter[param])}
                  contentType={"text/plain"}
                />
              </div>
            ))
          : Object.keys(parameter).map((param, field) => (
              <div
                className={`${advancedView ? "param-row no-sibling" : ""}`}
                key={field}
              >
                <PropertyDescription
                  field={isResponse ? param : parameter[param].name}
                  type={
                    parameter[param].schema
                      ? parameter[param].schema.type ||
                        parameter[param].type ||
                        ""
                      : parameter[param].type
                  }
                  description={parameter[param].description}
                  showExpandOrCollapse={false}
                  showChildren={false}
                  rangeText={getParamRangeText(parameter[param])}
                  enums={getParamEnums(parameter[param])}
                  pattern={getParamPattern(parameter[param])}
                  required={
                    parameter[param].__required ||
                    (typeof parameter[param].required === "boolean" &&
                      parameter[param].required)
                  }
                />
              </div>
            ))}
      </div>
    </div>
  );
  */
};

export default Parameters;
