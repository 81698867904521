import { useMemo, useState } from "react";
import { PostIconConfig } from "./PostIconConfig";
import { useSelector } from "react-redux";
import { PostIconConfigV2 } from "./PostIconConfigV2";

export const PostIconV2 = ({ type, state, ...props }) => {
  const [hovered, setHovered] = useState(false);

  const {
    currentLayoutObject: { siteTemplateType },
  } = useSelector((state) => state.layout);

  const icon = useMemo(() => {
    return state === "filled"
      ? PostIconConfigV2[type]?.filled
      : PostIconConfigV2[type]?.default;
  }, [type, state]);

  const hoverIcon = PostIconConfigV2 ? PostIconConfigV2[type]?.hover : null;

  return (
    <div
      onMouseOver={() => {
        if (state === "filled" || state === "default") {
          setHovered(true);
        }
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
      className="post-icon"
      {...props}
    >
      {hovered ? hoverIcon : icon}
    </div>
  );
};
