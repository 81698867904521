export const ROUTE_NAMES = {
  GUIDES: "api-guides",
  API_REFERENCE: "api-references",
  USECASES: 'usecases',
  OPEN_API: 'open-api'
}

export const OAS_SPEC_VERSION = {
  OAS2: "2.0",
  OAS3: "3.0",
};

export const GUIDE_VIEWS = {
  DOC_VIEW: "Try Out",
  CODE_VIEW: "Code Snippet",
};

export const BADGE_DELIMITER = {
  START: "<///START>",
  END: "<///END>",
};

export const PATH_METHOD_DELIMITER = "@@@///@@@";

export const monocoEditorOption = {
  wrappingIndent: "indent",
  wordWrap: true,
  minimap: {
    enabled: false,
  },
  fontSize: 12,
  scrollBeyondLastLine: false,
  renderLineHighlight: "none",
  padding: { top: 12, bottom: 12 },
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
};

export const LANGUAGES = {
  Javascript: "javascript",
  CSS: "css",
  C: "c",
  "C++": "cpp",
  "C#": "csharp",
  HTML: "html",
  Java: "java",
  JSON: "json",
  PHP: "php",
  Python: "python",
  Powershell: "shell",
  Ruby: "ruby",
  SQL: "sql",
  YAML: "yaml",
};

export const GUIDE_STATUS = {
    published: "Published",
    draft: "Draft",
    search: "Search Results"
}


export const STATUS = {
    published: "published",
    draft: "draft",
    search: "Search Results"
}


export const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}


export const VIEW_OPTIONS = {
    list: "list",
    grid: "grid"
}

export const COLOR_API_METHODS = {
  get: '#297C3B', 
  post: '#006ADC', 
  put: '#0078A1',
  option: '#9E8CFC', 
  patch: '#BF7AF0', 
  delete: '#CD2B31'
}


export const PRODUCTS_MAPPER_KEY = {
  AZURE: "name",
  KONG: "productName",
  APIGEE: "name",
  APIGEE_BUNDLE: "apigeeProductBundleId",
  NGINX: "id",
  AWS: "id"
}

export const PRODUCTS_MAPPER_DISPLAY_NAME = {
  AZURE: "displayName",
  KONG: "productName",
  APIGEE: "displayName",
  NGINX: "name",
  AWS: "name"
}

export const DEFAULTS = {
  EVENT_TITLE: "Upcoming Events!"
}


export const GATEWAYS = {
  NGINX: "nginx",
  AWS: "aws",
  APIGEE: "apigee",
  AZURE: "azure",
  KONG: "kong",
  AXWAY: "axway"
}

export const APP_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REVOKED: "revoked",
  DELETED: 'deleted'
}

export const URL_PARAMS = {
  API_REFERENCE:'f',
  SRC: "src"
}

export const SRC_PARAM_VALUES = {
  PRODUCT: "product",
  PACKAGE: "package"
}

export const MonetizationPackageStatus = {
  PUBLISH: "PUBLISH",
  REVIEW: "REVIEW",
  RETIRED: "RETIRED",
  DRAFT:"DRAFT",
}

export const MonetizationTrend = {
  TrendUp: 'trend-up',
  TrendDown: 'trend-down',
  TrendMiddle: 'trend-middle',
}

export const MonetizationGradientColors = [
  "linear-gradient(39deg, #B2F6CB 0%, #51B7E9 100%)",
  "linear-gradient(315deg, #5990DC 0%, #ABFAFF 100%)",
  "linear-gradient(279deg, #4B73FF 0%, #87F8FF 100%)",
  "linear-gradient(134deg, #AFE3EE 1.83%, #6378E5 100%)",
  "linear-gradient(285deg, #7F74FF 0%, #D9ACFC 100%)",
  "linear-gradient(285deg, #CA4BFF 1.07%, #FFB6F3 99.36%)",
  "linear-gradient(285deg, #FF4BD7 1.07%, #FFB6B6 100%)",
  "linear-gradient(285deg, #FF547E 0.91%, #FFB39B 83.48%)",
  "linear-gradient(266deg, #FFB966 6.65%, #FFCACA 93.35%)",
  "linear-gradient(135deg, #FAEE80 0%, #5D9631 100%)"
]

export const LayoutViews = {
  LIST: "list",
  GRID: "grid"
}

export const RateCardType = {
  FIXED_RATE : "FIXED_RATE",
  UNIT_BASED : "UNIT_BASED",
  TIER : "TIER"
}

export const RangeType = {
  FIXED_RATE : "FIXED_RATE",
  UNIT_BASED : "UNIT_BASED",
}

export const TierRateType = {
  VOLUME : "VOLUME",
  GRADUATED : "GRADUATED"
}

export const reservedMonetizationKeys = [
  'request.requestHeaders.',
  'request.requestBody.',
  'request.requestQueryParams.',
  'response.responseBody.',
  'response.',
  'request.requestFormParams.'
]

export const AppStatus = {
  Approved: "APPROVED",
  Pending: "PENDING",
  Deleted: "DELETED",
  Rejected: "REJECTED"
}

export const pdfComponents = {
  horizontalLine: ({ lineWidth = 0.5, margins = [0, -10, 0, 0], styles = {} } = {}) => {
      return {
          table: {
              widths: ["*"],
              body: [[" "], [" "]],
          },
          layout: {
              hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : lineWidth;
              },
              vLineWidth: function (i, node) {
                  return 0;
              },
          },
          margin: margins,
          ...styles,
      };
  },
  pageTitle: ({ title = "", description = "", margins = [0, 0, 0, 0], textStyle = {} }) => {
      return {
          columns: [
              {
                  text: title,
                  color: "#464249",
                  fontSize: 21,
                  alignment: "left",
                  bold: true,
                  width: 400,
                  characterSpacing: -0.5,
                  ...textStyle,
              },
              {
                  text: description,
                  alignment: "right",
                  bold: false,
                  color: "#838383",
                  fontSize: 9,
                  width: 120,
                  margin: [0, 5, 4, 0],
              },
          ],
          margin: margins,
      };
  },
  paragraph: ({ text = "", fontSize = 13, color = "#4D4D4D", bold = false, characterSpacing = -0.2, lineHeight = 1.15, margin = [0, 10, 0, 10], styles = {} }) => {
      return {
          text,
          fontSize,
          color,
          bold,
          characterSpacing,
          lineHeight,
          margin,
          ...styles,
      };
  },
  pageBreak: ({ afterOrBefore = "after" } = {}) => {
      return { text: "", pageBreak: afterOrBefore };
  },
  addMargin: ({ margin = [0, 10, 0, 10] } = {}) => {
      return { text: "", margin };
  },
  addCanvas: ({ padding = 8, background = "#E3EEF4", color = "#BBD8E7", height = 180, width = 520, borderRadius = 4, xStart = 0, yStart = 0 } = {}) => {
      return {
          canvas: [
              {
                  type: "rect",
                  x: xStart,
                  y: yStart,
                  w: width,
                  h: height,
                  r: borderRadius,
                  color: background,
              },
              {
                  type: "rect",
                  x: xStart + padding,
                  y: yStart + padding,
                  w: width - 2 * padding,
                  h: height - 2 * padding,
                  r: borderRadius,
                  lineColor: color,
                  dash: { length: 4, space: 3 },
              },
          ],
      };
  },
  addCanvasv2: ({ background = "#EDF6FF", height = 36, width = 230, xStart = 0, yStart = 5, color = "white", radius = 4 } = {}) => {
      return {
          canvas: [
              {
                  type: "rect",
                  x: xStart,
                  y: yStart,
                  w: width,
                  h: height,
                  r: radius,
                  color: background,
              },
              {
                  type: "rect",
                  x: xStart - 1,
                  y: yStart - 1,
                  w: width + 2,
                  h: height + 2,
                  r: radius,
                  lineColor: color,
              },
          ],
      };
  },
  renderInStack: ({ stackArr = [], styles = {} } = {}) => {
      return {
          stack: [...stackArr],
          ...styles,
      };
  },
  renderInList: ({ items = [], type = "ol" } = {}) => {
      if (type !== "ol" && type !== "ul") type = "ol";
      return {
          [type]: [...items],
      };
  },
  renderInColumns: ({ columns = [], columnGap = 10, styles = {} }) => {
      return {
          columns,
          columnGap,
          ...styles,
      };
  },
  homeTitle: ({ title = "", description = "", margins = [0, 0, 0, 0] }) => {
      return {
          columns: [
              {
                  text: title,
                  color: "#2A004A",
                  fontSize: 60,
                  alignment: "left",
                  width: 400,
                  characterSpacing: -1.8,
                  bold: true,
              },
              {
                  text: description,
                  alignment: "right",
                  bold: false,
                  color: "#838383",
                  fontSize: 9,
                  width: 120,
                  margin: [0, 5, 0, 0],
              },
          ],
          margin: margins,
      };
  },
};

export const concernTexts = {
  CURRENCY: 'CURRENCY',
  PACKAGE: 'PACKAGE',
  RATEPLAN: 'RATEPLAN',
  BILLING: 'BILLING'
}