import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetAuthenticatedState } from '../../app/Features/Users/UserSlice'
import { getCookie, removeCookie } from '../../utils/cookie'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import API from '../../api'

export default function Logout() {
    const {guestsetting} = useSelector((state) => state.setting)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(resetAuthenticatedState())

        if (getCookie('itorix-token')) removeCookie('itorix-token')
        if (getCookie('itorix-token-data'))removeCookie('itorix-token-data')

        if(localStorage.getItem("ssoLogin")){
			const cutoffIndex = guestsetting?.authEndpointUrl?.indexOf("/token?")
			const _url = guestsetting?.authEndpointUrl?.slice(0, cutoffIndex)
			const origin = window.location.origin

            console.log('origin', origin)
            console.log('_url', _url)
			localStorage.setItem("sso-logout-source", `${origin}/login`)
            window.location.href = `${_url}/logout`

            API.logOutWithSSO()
            .then(res => {
                navigate('/login')
            }).catch(err => {
                console.log('err', err)
            })
		}

        else navigate('/login')
    }, [])
    
    return (
        <div>

        </div>
    )
}
