
import { ReactComponent as DocSvg } from '../../assets/images/empty-chart-doc.svg';
import { ReactComponent as OkSvg } from '../../assets/images/empty-chart-ok.svg';
import { useTheme } from '../../context/ThemeContext';

import EmptyFolderDark from '../../assets/images/empty-table-dark.png';
const EmptyContainer = (props) => {
    const {showFolder = true} = props
    let style={}
    if(props.style) style= {...props.style}

    let cls = ''
    if(props.className) cls = props.className

    let _emptyFolderStyle={}
    if(showFolder)
    {
        _emptyFolderStyle={width: props.imageWidth || 96, height: props.imageHeight || 96}
    }

    return (
        <div style={style} className={`empty-container ${cls} w-100 d-flex flex-column align-items-center justify-content-center`}>
            {!props.showOnlyText ? (
            <div style={{..._emptyFolderStyle}} className={''}>
                <div className='h-100'>
                    {showFolder ? <img src={EmptyFolderDark} alt=''
                        width={_emptyFolderStyle.width || 0} height={_emptyFolderStyle.height || 0} /> : (
                        <>
                            <DocSvg />
                            {!props.hideOk ? <OkSvg /> : null}
                        </>
                    )}
                </div>
            </div>) : <div className='only-text'></div>}
            <div className='fs-12px fw-500 mt-3 pl-6px text-300 text-center'>{props.message || "Data not found"}</div>

        </div>
    );
}

export default EmptyContainer