import { getToast } from "../components/Toast";
import moment from "moment";
import { getCookie, removeCookie } from "./cookie";
import { parse, stringify } from 'flatted';
import YAML from "js-yaml";
import { toLower } from "lodash";
import {compressToEncodedURIComponent, decompressFromEncodedURIComponent} from "lz-string"
import { getUuidV4 } from "@apiwiz/oas/services";
import { APP_STATUS, AppStatus, GATEWAYS, RateCardType, TierRateType, reservedMonetizationKeys } from "../service/constants";
export function getEnv(name) {
  // @ts-ignore
  const url = window?.env?.[name] || process.env?.[name];
  return url;
}

export function joinUrlPaths(paths) {
  let _url = "";
  for (let p of paths) {
    if (_url.endsWith("/") && p.startsWith("/")) _url += p.substring(1);
    else _url += p;
  }
  return _url;
}

export const getSessionSharing = (
  { email, nonce, timestamp, signature, forumUrl, redirectUrl },
  isLog
) => {
  let baseUrl = `${forumUrl}/sessions/`;
  const isAbsolute = redirectUrl.includes("http");
  const redirect = isAbsolute
    ? redirectUrl
    : window.location.origin + redirectUrl;
  let url = isLog
    ? baseUrl +
      `create?continue=${redirect}&nonce=${nonce}&email=${email}&timestamp=${timestamp}&signature=${signature}&debug=false`
    : baseUrl + `destroy?continue=${window.location.origin}&debug=false`;
  window.onbeforeunload = null;
  window.location.href = url;
};

export const encryptPassword = (password) => {
  const JSEncrypt = require("jsencrypt").default;
  const encrypt = new JSEncrypt({});
  encrypt.setPublicKey(getEnv("PUBLIC_KEY"));
  return encrypt.encrypt(password);
};

function generateRandomText() {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomText = "";

  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomText += characters[randomIndex];
  }

  return randomText;
}

export const generateIdByText = (_text) => {
  const trimmedText = _text.trim();
  const className = trimmedText.replace(/[^a-zA-Z0-9]+/g, "-");
  const lowercaseClassName = className.toLowerCase();
  const randomText = generateRandomText();
  return `${lowercaseClassName}-${randomText}`;
};

export const objectDeepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const objectDeepCloneFlatted = (obj) => {
  return parse(stringify(obj));
};


export function debounceFn(fn, intervalTime = 1000) {
  let timeoutId;

  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
    }, intervalTime);
  };
}


export const setColor = (property, newColor) => {
  document.documentElement.style.setProperty(property, newColor);
};

export function extractErrorMessage(err) {
  console.log(err);
  if (typeof err === "string") return err;
  let _message = err?.response?.data?.message;
  if (_message) return _message;

  _message = err?.message;
  if (_message) return _message;

  _message = err?.response?.data?.message;
  if (_message) return _message;

  return "Internal Server Error.";
}

export const throwServerError = (err) => {
  getToast({
    statusType: "ERROR",
    message: extractErrorMessage(err),
  });
};

export const showSuccessMessage = (message) => {
  getToast({
      statusType: "SUCCESS",
      message: message,
  });
}

export function formatCtsTime(value,) {
  if (value) {
    return moment(value).utc().format("h:mm A");
  }
}

export function formatCtsTimeWithoutUTC(value, format="h:mm A") {
  if (value) {
    return moment(value).format(format);
  }
}

export function formatCtsDate(value) {
  if (value) {
    return moment(value).utc().format("MM/DD/YYYY");
  }
}

export function formatCtsDateWithoutUTC(value) {
  if (value) {
    return moment(value).format("MM/DD/YYYY");
  }
}

export function formatClearCtsDateWithoutUTC(value) {
  if (value) {
    let _text = moment(value).format("llll")
    let _new = ''
    _text.split(" ").forEach((each, i) => {
      if (i === 3) {
        _new += `${each} • `
      } else {
        _new += `${each} `
      }
    })
    return _new;
  }
}

export function alert(text) {
  return window.alert(text);
}

export function confirm(text) {
  return window.confirm(text);
}

export function copyToClipboard(data) {
  let text = objectDeepClone(data);
  navigator.clipboard.writeText(text);
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function getPaginationList(c, m) {
  var current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

export function capitalizeSentence(sentence) {
  const words = sentence.split(' ');

  const capitalizedWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  return capitalizedWords.join(' ');
}

export function slugify(text) {
  if (!text) return ''
  return text
    .toString()
    .toLowerCase()
    .replace(/\./g, '-')
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export const getFixedPercentage = (numerator = 0, denominator, fixed=2) => {
  const val = (numerator / denominator) * 100;
  if (isNaN(val)) return 0;
  return val.toFixed(fixed)?.toString()?.replace(".00", "");
};

export function isValidURL(url) {
  // Regular expression pattern to match a valid URL
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // Protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (e.g. 192.168.0.1)
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // Fragment locator

  // Check if the URL matches the pattern
  return pattern.test(url);
}
export function toPascalCase(string) {
  const words = string.match(/[a-z]+/gi);
  if (!words) return "";
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join(" ");
}

export const replacePartnerIdWithDisplayName = (userGroups, partners) => {
  let map = {};
  for (let p of partners) map[p._id] = p;
  return userGroups
    .map((x) => {
      let partner = map[x];
      if (partner) return partner.partnerDisplayName;

      return null;
    })
    .filter((x) => x !== null);
};

export const getPermissionsArray = (data, name, userRole) => {
  if (data) {
    let newData = [...data?.permissions];
    newData = newData
      ?.filter((o) => o.object === name)
      .map((o) => {
        return { ...o, roles: o.roles.filter((r) => r.code === userRole)[0] };
      });
    return newData;
  }
};

export const getUserGroupOptions = (partners) => {
  return partners.map((x) => ({ value: x._id, label: x.partnerDisplayName }));
};

export const partnerIdToSelectValues = (userGroups, partners) => {
  let map = {};
  for (let p of partners) map[p._id] = p;

  return userGroups
    .map((x) => {
      let partner = map[x];
      if (partner) return { value: x, label: partner.partnerDisplayName };

      return { value: x };
    })
    .filter((x) => x.label !== undefined);
};

export function logoutClientSide() {
  if (getCookie("itorix-token")) removeCookie("itorix-token");
  if (getCookie("itorix-token-data")) removeCookie("itorix-token-data");

  window.location.href = "/login";
}

export function getFirstLetterOfString(text) {
  if (!text) return "";
  return text.charAt(0);
}

export function validateHeaderMenu(_array, isHeaders = true) {
  let stillInEdit = false;

  _array.forEach((each) => {
    if (each.isEdit) stillInEdit = true;
    if (each.hasOwnProperty("isEdit")) delete each["isEdit"];

    each.submenu.forEach((subEach, i) => {
      if (subEach.isEdit) stillInEdit = true;
      if (subEach.hasOwnProperty("isEdit")) delete each.submenu[i]["isEdit"];
    });
  });

  return {
    error: stillInEdit
      ? `Please save unsaved changes in ${isHeaders ? "header" : "footer"}`
      : null,
    data: _array,
  };
}

export function validateSocialData(_array) {
  let stillInEdit = false;

  _array.forEach((each) => {
    if (each.isEdit) stillInEdit = true;
    if (each.hasOwnProperty("isEdit")) delete each["isEdit"];
  });

  return {
    error: stillInEdit
      ? "Please save unsaved changes in Social Icon Details of footer"
      : null,
    data: _array,
  };
}

export function validateContentsData(_array) {
  let stillInEdit = false;
  let errorIn = "";

  _array.forEach((each) => {
    if (stillInEdit) return;
    if (each.payload?.isEdit) {
      stillInEdit = true;
      errorIn = each.title;
      return;
    }
    if (each.payload.hasOwnProperty("isEdit")) delete each.payload["isEdit"];
  });

  return {
    error: stillInEdit
      ? `Please save unsaved changes in ${errorIn} Section`
      : null,
    data: _array,
  };
}
export function parseEditorJSContent(content) {
  if (content.blocks) {
    content.blocks = content.blocks.map((el) => {
      if (el.type === "table") {
        if (el.data.withHeadings === undefined) el.data.withHeadings = true;
        if (
          el.data &&
          el.data.content &&
          el.data.content.constructor === Array
        ) {
          el.data.content = el.data.content.map((c) => {
            if (c && c.constructor === Array && c.length)
              return c.map((x) => {
                if (x && x.constructor === Object) return x.data || "";
                return x;
              });
            return c;
          });
        }
        return el;
      }

      return el;
    });
  }
  return content;
}

export function formatDate(date, todayYesterday = true) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (todayYesterday){
    if (isSameDay(new Date(date), today)) {
      return 'Today';
    } else if (isSameDay(new Date(date), yesterday)) {
      return 'Yesterday';
    } else {
      return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }
  } else {
    return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
}

function isSameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validateAllowedEmailDomains(email, allowedDomains){
  //validate allowed domains
  if(email && allowedDomains && allowedDomains.length)
  {
    let s = email.split('@')
    if(s.length>1)
    {
      let emailDomain = s[s.length-1].toLowerCase()
      let _allowedDomains = allowedDomains.map(x => x.toLowerCase())
      if(!_allowedDomains.find(x => x === emailDomain ))
      {
        return `User account validation failed. Domain name ${emailDomain} is not allowed.`;
      }
    }
  }

  return null;
}

export const handleServerSideError = (error) => {
  if (error.response) {
      if (Object.keys(error.response.data.errors).length > 0) {
          for(const key of Object.keys(error.response.data.errors)){
              getToast({statusType:"ERROR",message: error.response.data.errors[key] })
          }
      } else {
          getToast({statusType:"ERROR",message: error.response.data.message })
      }
  } else {
      getToast({statusType:"ERROR",message: error.message })
  }
}

export const getPermissionByCrud = (currentUser, appPermission, name) => {
  const userRole = currentUser?.role || currentUser?.userRole;
  let filtered = appPermission?.find((o) => o.action === name)?.roles
  if (filtered && filtered.length){
    return filtered.find((o) => (o.role === userRole) && o.enabled)
  } else {
    return false
  }
};

export const labelize = (_type="") => {
  if (_type === RateCardType.FIXED_RATE) return "Flat Rate";
  if (_type === RateCardType.UNIT_BASED) return "Unit Based";
  if (_type === RateCardType.TIER) return "Tier Rate";
  if (_type === TierRateType.GRADUATED) return "Graduated Pricing";
  if (_type === TierRateType.VOLUME) return "Volumn Pricing";

  if(_type.toUpperCase() === AppStatus.Approved || _type.toLowerCase() === 'active') return "Active"
  if(_type.toUpperCase() === AppStatus.Pending || _type.toLowerCase() === 'submitted') return "Pending"
  if(_type === AppStatus.Deleted) return "Deleted"
  if(_type === APP_STATUS.DELETED) return "Deleted"
  if(_type === AppStatus.Rejected) return "Rejected"
  if(_type.toUpperCase() === 'REVOKE' || _type.toLowerCase() === 'suspended') return "Revoke"

  switch (_type) {
    case 'contact-information':
      return 'contact-us'
    case 'settings':
      return 'setting'
    case 'apps':
      return 'app-approval'
    case 'app-approval':
      return 'app-approval'
    case 'manage-gateways':
      return 'organization'
    case 'events':
      return 'event'
    case 'sdks':
      return 'sdk'
    case 'usecases':
      return 'products'
    case 'manage':
      return 'user'
    case 'configure':
      return 'template'
    case 'guides':
      return 'guides'
    case 'dashboard':
      return 'dashboard'
    case 'packages':
      return 'packages'
    case 'package-categories':
      return 'package-categories'

    case GATEWAYS.APIGEE:
      return "Apigee"
    case GATEWAYS.KONG:
      return "Kong"
    case GATEWAYS.AZURE:
      return "Azure"
    case GATEWAYS.NGINX:
      return "Nginx"
    case GATEWAYS.AWS:
      return "AWS"
  
    default:
      return _type;
  }
}
export const formatEditorText = (text) => {
  let _text = text.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/\\"/g, `"`)
  if(_text.startsWith('"')) _text = _text.substring(1)
  if(_text.endsWith('"')) _text = _text.substring(0, _text.length-1);
  return _text;
}

export const calHeight = (arrayOfIds) => {
  let sum = 0;
  arrayOfIds.forEach((el) => {
    if (document.getElementById(el)) {
      sum = sum + document.getElementById(el).offsetHeight;
    }
  });
  return sum;
};


export function isJSON(_json){
  try {
      JSON.parse(_json)
      return true
  } catch (error) {
      return false
  }
}

export function unslugifyText(slug) {
  // Replace hyphens or underscores with spaces
  let text = slug?.replace(/[-_]/g, ' ');
  
  // Capitalize the first letter of each word
  text = text?.replace(/\b\w/g, (match) => match.toUpperCase());
  
  return text;
}

export function isElTruncated(el){
  return el.offsetWidth < el.scrollWidth
}

export function getLocationBasePath(){
  let pathnames = window.location.pathname.split('/').filter(Boolean);
  if(pathnames.length) return pathnames[0];
}

export function getMenuName({menus, url}){
  for(let menu of (menus || []))
  {
    if(menu.url === url) return menu.name;
    let _menuName = "";
    if((menu.submenu||[]).length) _menuName = getMenuName({menus: menu.submenu, url})
    if(_menuName) return _menuName;
  }
  return "";
}

const rateCardPricings = ({ rateCard }) => {
  const { chargingModel } = rateCard;

  switch (chargingModel) {
    case "FLATRATE":
      const { flatRate } = rateCard;
      return [`${flatRate}$ / call`];
    case "VOLUMEBANDED":
      const { volumeBundles } = rateCard;

      const ratesVolumeBanded = volumeBundles.reduce(
        (acc, curr, currentIndex) => {
          if (currentIndex < volumeBundles.length - 1) {
            return [...acc, `> ${curr.usage} + calls: ${curr.rate}$ / call`];
          } else {
            return [
              ...acc,
              `More Than ${curr.usage} + calls: ${curr.rate}$ / call`,
            ];
          }
        },
        []
      );

      return ratesVolumeBanded;

    case "BUNDLES":
      const { bundles } = rateCard;

      const ratesBundles = bundles.reduce((acc, curr, currentIndex) => {
        if (currentIndex < bundles.length - 1) {
          return [...acc, `> ${curr.usage} + calls: ${curr.rate}$ / call`];
        } else {
          return [
            ...acc,
            `More Than ${curr.usage} + calls: ${curr.rate}$ / call`,
          ];
        }
      }, []);

      return ratesBundles;

    default:
      return [];
  }
};

const revenueSharePricings = ({ revenueShare }) => {
  const { sharingModel } = revenueShare;

  switch (sharingModel) {
    case "FIXED":
      const { fixedSharePercentage } = revenueShare;

      return [`${fixedSharePercentage}% share`];

    case "FLEXIBLE":
      const { revenueShareBands } = revenueShare;

      const ratesRevenueShare = revenueShareBands.reduce(
        (acc, curr, currentIndex) => {
          if (currentIndex < revenueShareBands.length - 1) {
            return [...acc, `> ${curr.usage} + calls: ${curr.rate}$ / call`];
          } else {
            return [
              ...acc,
              `More Than ${curr.usage} + calls: ${curr.rate}$ / call`,
            ];
          }
        },
        []
      );

      return ratesRevenueShare;
  }
};

const feesOnlyPricings = ({ costModel }) => {
  const { baseFee, billingPeriod, billingPeriodUnit } = costModel;

  return [`${baseFee}$ Base / ${billingPeriod} ${billingPeriodUnit}`];
};

export const getRatePlanValue = ({ rateplan }) => {
  const { type } = rateplan;

  switch (type) {
    case "RATECARD":
      const { rateCard } = rateplan;
      return rateCardPricings({ rateCard });

    case "REVSHARE":
      const { revenueShare } = rateplan;

      return revenueSharePricings({ revenueShare });

    case "REVSHARE_RATECARD":
      const { rateCard: _rateCard } = rateplan;
      const { revenueShare: _revenueShare } = rateplan;

      return [
        ...rateCardPricings({ rateCard: _rateCard }),
        ...revenueSharePricings({ revenueShare: _revenueShare }),
      ];

    case "FEESONLY":
      const { costModel } = rateplan;
      return feesOnlyPricings({ costModel });

    default:
      return [];
  }
};

export function getStartDateTimeStamp(date){
  return new Date(moment(new Date(date)).startOf('day').toISOString())
}

export function getEndDateTimeStamp(date){
  return new Date(moment(new Date(date)).endOf('day').toISOString())
}

export function downloadObjectAsJson(exportName, exportObj) {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj, 0, 4));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function downloadObjectAsYaml(exportName, exportObj){
  const dataStr = "data:text/yaml;charset=utf-8," + encodeURIComponent(exportObj);
	const downloadAnchorNode = document.createElement("a");
	downloadAnchorNode.setAttribute("href", dataStr);
	downloadAnchorNode.setAttribute("download", exportName + ".yaml");
	document.body.appendChild(downloadAnchorNode); // required for firefox
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
}

export function getYAML(json) {
  let originalStr = JSON.stringify(json);
  let yamlString;
  try {
    yamlString = YAML.safeDump(YAML.safeLoad(originalStr), {
      lineWidth: -1, // don't generate line folds
    });
  } catch (e) {
    return;
  }
  return yamlString;
}

export function getThemeBasedImage(theme, dataObj){
  if (theme === 'theme--dark' && dataObj.hasOwnProperty('imageUrlDark')){
    return dataObj.imageUrlDark
  }
  return dataObj.imageUrl
}

function intersection(arr1, arr2) {
  if (!arr1?.length || !arr2?.length) return []
  return arr1.filter(item => arr2?.includes(item));
}


export function allowMenu({menu, isAuthenticated, currentUser}){
  if(!isAuthenticated) return menu.visibleToAnonymous;
  let menuRoles = menu?.visibleToRoles || [];
  if(menuRoles.length && !menuRoles.includes(currentUser?.userRole)) return false;
  let partnersMenu = menu?.userGroup || [];
  if(partnersMenu.length && !intersection(partnersMenu, currentUser?.userGroups).length) return false;
  return true;
}

export function azureStatusConversion(status) {
  if(!status) return "Pending";
  let _status = toLower(status)
  if(_status === 'submitted') return "Pending";
  if(_status === 'suspended') return "Revoke";
  if(_status === 'active') return "Approved";
  return status;
}

export function apigeeStatusConversion(status){
  if(!status) return "Pending";
  let _status = toLower(status);
  if(_status === 'approve') return "approved";
  return _status;
}

export function compressUrlParams({text}){
  return compressToEncodedURIComponent(text)
}

export function constructFilterResources({resources}){
  if(!resources || !resources.length) return "";
  return compressUrlParams({text: JSON.stringify(resources.map(el => ({p: el.path || el.resourcePath, m: el.method || el.resourceMethod})))})
}

export function constructFilterResourcesv2({resources}){
  if(!resources || !resources.length) return "";
  return compressUrlParams({text: JSON.stringify(resources.map(el => ({p: el.path, m: el.method})))})
}

export function getFilterResources({encoded}){
  if(!encoded) return [];
  try {
    let jsonStr = decompressFromEncodedURIComponent(encoded);
    if(!jsonStr) return [];
    let json = JSON.parse(jsonStr);
    if(!Array.isArray(json)) return [];
    return json.map(el => ({path: el.p, method: el.m}));
  }
  catch(err) {
    return [];
  }
}

export function getRandomElementFromArray(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export function formatNumber(num, shorten = false) {
  if (num < 1000) {
      return num.toString();
  } else if (num < 1000000) {
      return (num / 1000).toFixed(1)?.toString()?.replace(".0", "") + "k";
  } else {
      return (num / 1000000).toFixed(1)?.toString()?.replace(".0", "") + (shorten ? " M" : " Million");
  }
}

export const arrayToObject = (key, arr) => {
  return arr.reduce(function (map, obj) {
      map[obj[key]] = obj;
      return map;
  }, {});
};

export const getDaysInAMonth = (date) => {
  var daysInMonth = moment(date).daysInMonth();

  return Array.from({length: daysInMonth}, (_, i) => i + 1).map((x) => x.toString())
}


const getParamTypeAndKey = (_data) => {

  if (!_data) return {ptype: '', key: '', _dataType: ''}

  let validObj = {ptype: '', key: _data.key}
  reservedMonetizationKeys.forEach((eachKey) => {
      if (validObj.ptype) return
      
      if (_data.key && _data.key.includes(eachKey)){
          validObj = {
              ptype: eachKey, 
              key: _data.key.replace(eachKey, ''),
              _dataType: _data.valueDataType
          }
      }
  })

  return validObj
}

export function Labelizev2(str) {
  if (str === 'EQUAL_TO') return 'equals'
  if (str === 'NOT_EQUAL_TO') return 'not equals'
  if (str === 'GREATER_THAN') return 'is greater than'
  if (str === 'LESSER_THAN') return 'is less than'
  if (str === 'GREATER_THAN_EQUAL_TO') return 'greater than or ='
  if (str === 'LESSER_THAN_EQUAL_TO') return 'less than or ='
  if (str === 'REGEX') return 'matches'
  if (str === 'CONTAINS') return 'contains'
  if (str === 'PRESENT') return 'is present'
  if (str === 'ABSENT') return 'is absent'

  if (str === 'equals') return 'EQUAL_TO'
  if (str === 'not equals') return 'NOT_EQUAL_TO'
  if (str === 'is greater than') return 'GREATER_THAN'
  if (str === 'is less than') return 'LESSER_THAN'
  if (str === 'greater than or =') return 'GREATER_THAN_EQUAL_TO'
  if (str === 'less than or =') return 'LESSER_THAN_EQUAL_TO'
  if (str === 'matches') return 'REGEX'
  if (str === 'contains') return 'CONTAINS'
  if (str === 'is present') return 'PRESENT'
  if (str === 'is absent') return 'ABSENT'

  if (str === 'CURRENCY') return 'Currency Change'
  if (str === 'PACKAGE') return 'Package Details'
  if (str === 'RATEPLAN') return 'Rateplan Details'
  if (str === 'BILLING') return 'Billing Details'
}

export const MonetizeDateFn = (data) => {
  const currentMonth =  moment(data).month()+1;
  const currentYear = moment(data).year()
  const timestamp = moment(data).format('x')
  return {
      currentMonth,
      currentYear,
      timestamp,
      monthInWords: moment(data).format('MMMM'),
      previousTimestamp: moment(data).subtract(1,'months').format('x'),
      lastMonth: moment(data).subtract(1,'months').endOf('month').month()+1,
      lastMonthYear: currentMonth === 1 ? moment(data).subtract(1,'years').endOf('year').year() : currentYear
  }
}

function getStringBetweenParentheses(inputString) {
  let depth = 0;
  let result = '';
  for (let char of inputString) {
    if (char === '(') {
      depth++;
      if (depth > 1) {
        result += char;
      }
    } else if (char === ')') {
      depth--;
      if (depth === 0) {
        return result;
      } else {
        result += char;
      }
    } else if (depth > 0) {
      result += char;
    }
  }
  return null; // No matching parentheses found
}

export function convertExpressionToGraph(expression, placeholders){
  let json = {}
  function expandExpression(string = ''){
      console.log('expression', expression)
      let _str = string.trim()
      if (_str[0] === '('){
          let parenthesisString = getStringBetweenParentheses(_str)
  
          let difference = _str.substring(parenthesisString.length + 2 || 0)
          let andIndex = difference.indexOf("&&")
          let orIndex = difference.indexOf("||")
          console.log('parenthesisString', parenthesisString)
          console.log('difference', difference)
          console.log('andIndex, orIndex', andIndex, orIndex)
          
          console.log(parenthesisString, andIndex, orIndex, difference)
          json = {
              _id: getUuidV4(),
              _type: "group",
              _data: subExpandExpression(parenthesisString),
              _orData: [],
              _andData: {},
              _condition: ""
          }

          if (!difference){
              json.data = {
                  _id: getUuidV4(),
                  _type: "group",
                  _data: subExpandExpression(parenthesisString),
                  _orData: [],
                  _andData: {},
                  _condition: ""
              };
          }
  
          if (andIndex > -1 && (andIndex < orIndex || orIndex === -1)){
              let strings = difference.split(/\s&&\s/)
              if (!strings[0]) strings.splice(0, 1)
              console.log('strings', strings)
              let _firstStr = strings[0]
              _firstStr = _firstStr.trim()
  
              if (_firstStr[0] === '{' && _firstStr[_firstStr.length - 1] === '}'){
                  const numberWithinBraces = _firstStr.match(/{(\d+)}/);
                  let number = parseInt(numberWithinBraces[1]);
                  let _ph = placeholders[number]
                  let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
                  json._andData = {
                      _id: getUuidV4(),
                      _type: "element",
                      _data: {
                          _paramType: ptype || '',
                          _dataType,
                          _key: key || '',
                          _value: _ph.value 
                      },
                      _condition: Labelizev2(_ph.rule),
                      _andData: subExpandExpression(strings.slice(1).join(" && ")),
                      _orData: []
                  }
              } 
              console.log('_firstStr', _firstStr)
              if (_firstStr[0] === '('){
                  console.log(strings.join(" && "))
                  json._andData = subExpandExpression(strings.join(" && "))
              }
          }

          if (orIndex > -1 && (orIndex < andIndex || andIndex === -1)){
              let strings = difference.split('||')
              if (!strings[0].trim()) strings.splice(0, 1)
              let arr = []

              strings.forEach((each) => {
                  arr.push(subExpandExpression(each))
              })

              json._orData = arr
          }
      }
  
      let andIndex = _str.indexOf('&&')
      let orIndex = _str.indexOf('||')
  
      if (_str[0] === '{'){
          if (andIndex > -1 && (andIndex < orIndex || orIndex === -1)){
              let strings = _str.split(/\s&&\s/)
              let _firstStr = strings[0]
              _firstStr = _firstStr.trim()
              console.log('sss', strings, _firstStr)

              const numberWithinBraces = _firstStr.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
              let _ph = placeholders[number]
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)

              json = {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: subExpandExpression(strings.slice(1).join(" && ")),
                  _orData: []
              }
          }
          if (andIndex === -1 && orIndex === -1 && _str[_str.length - 1] === '}'){
              const numberWithinBraces = _str.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
              let _ph = placeholders[number]
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)

              json = {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: {},
                  _orData: []
              }
          }

          if (orIndex > -1 && (orIndex < andIndex || andIndex === -1)){
              let strings = _str.split('||')
              let _firstStr = strings[0]
              _firstStr = _firstStr.trim()
              console.log('sss', strings, _firstStr)
  
              const numberWithinBraces = _firstStr.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
  
              let _arr = []
              strings.forEach((each, i) => {
                if (i > 0){
                    let _tempStr = each.trim()
                    _arr.push(subExpandExpression(_tempStr))
                }
              })
  
              let _ph = placeholders[number]
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
  
              json = {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: {},
                  _orData: _arr
              }
          }
      }
      else if (andIndex > -1){
          let strings = _str.split(/\s&&\s/)
          let _firstStr = strings[0]
          _firstStr = _firstStr.trim()
          if (_firstStr[0] === '{' && _firstStr[_firstStr.length - 1] === '}'){
              const numberWithinBraces = _firstStr.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
              let _ph = placeholders[number]
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
              json = {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: subExpandExpression(strings.slice(1).join(" && ")),
                  _orData: []
              }
          } 
      }
      else if (orIndex > -1){
          let strings = _str.split("||")
          let _firstStr = strings[0]
          _firstStr = _firstStr.trim()
  
          if (_firstStr[0] === '{' && _firstStr[_firstStr.length - 1] === '}'){
              const numberWithinBraces = _firstStr.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
              let _ph = placeholders[number]
              let _arr = []
              strings.forEach((each, i) => {
                  if (i > 0){
                      let _tempStr = each.trim()
                      _arr.push(subExpandExpression(_tempStr))
                  }
              })
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
              json = {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: {},
                  _orData: _arr
              }
          } 
      }
  }
  
  function subExpandExpression(string = '') {
      let _str = string.trim()
  
      console.log('_str 111', _str)
      if (_str[0] === '('){
          let parenthesisString = getStringBetweenParentheses(_str) || ''
          let difference = _str.substring(parenthesisString.length + 2 || 0) || ''
  
          let andIndex = difference.indexOf("&&")
          let orIndex = difference.indexOf("||")

          console.log('parenthesisString', parenthesisString)
          console.log('difference', difference)
          console.log('andIndex, orIndex', andIndex, orIndex)

          if (!difference){
              return {
                  _id: getUuidV4(),
                  _type: "group",
                  _data: subExpandExpression(parenthesisString),
                  _orData: [],
                  _andData: {},
                  _condition: ""
              };
          }
  
          if (andIndex > -1 && (andIndex < orIndex || orIndex === -1)){
              difference = difference.trim()
              if (difference.startsWith("&&")) difference = difference.replace("&&", "")
              return {
                  _id: getUuidV4(),
                  _type: "group",
                  _data: subExpandExpression(parenthesisString),
                  _orData: [],
                  _andData: subExpandExpression(difference),
                  _condition: ""
              };
          }

          if ((andIndex === -1 && orIndex === -1) && parenthesisString.indexOf("||") > -1){
              let strings = parenthesisString.split("||")
              let _firstStr = strings[0]
              _firstStr = _firstStr.trim()
              if (_firstStr[0] === '{' && _firstStr[_firstStr.length - 1] === '}'){
                  const numberWithinBraces = _firstStr.match(/{(\d+)}/);
                  let number = parseInt(numberWithinBraces[1]);
                  let _ph = placeholders[number]
      
                  let _arr = []
                  strings.forEach((each, i) => {
                      if (i > 0){
                          let _tempStr = each.trim()
                          _arr.push(subExpandExpression(_tempStr))
                      }
                  })
                  let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
                  return {
                      _id: getUuidV4(),
                      _type: "element",
                      _data: {
                          _paramType: ptype || '',
                          _dataType,
                          _key: key || '',
                          _value: _ph.value 
                      },
                      _condition: Labelizev2(_ph.rule),
                      _andData: {},
                      _orData: _arr
                  }
              } 
          }

          if ((andIndex === -1 && orIndex === -1) && parenthesisString.indexOf("&&") > -1){
              let strings = parenthesisString.split("&&")
              let _firstStr = strings[0]
              _firstStr = _firstStr.trim()

              let _temp = {
                  _id: getUuidV4(),
                  _type: "group",
                  _data: {},
                  _condition: "",
                  _andData: {},
                  _orData: []
              }

              if (_firstStr[0] === '{' && _firstStr[_firstStr.length - 1] === '}'){
                  const numberWithinBraces = _firstStr.match(/{(\d+)}/);
                  let number = parseInt(numberWithinBraces[1]);
                  let _ph = placeholders[number]
                  let {ptype, key, _dataType} = getParamTypeAndKey(_ph)

                  _temp._data = {
                      _id: getUuidV4(),
                      _type: "element",
                      _data: {
                          _paramType: ptype || '',
                          _dataType,
                          _key: key || '',
                          _value: _ph.value 
                      },
                      _condition: Labelizev2(_ph.rule),
                      _andData: subExpandExpression(strings.slice(1).join(" && ")),
                      _orData: []
                  }
                  return _temp
              } 
          }

          if (orIndex > -1 && (orIndex < andIndex || andIndex === -1)){

              difference = difference.trim()
              if (difference.startsWith("||")) difference = difference.replace("||", "")
              difference = difference.trim()

              console.log(difference, difference.length)
              let tempStrings = difference.split(') || (')
              let arr = []
              if (tempStrings.length > 1){
                  tempStrings.forEach((each, i) => {
                      if (i === 0) {
                          arr.push(subExpandExpression(`${each})`))
                      }
                      else if (i === tempStrings.length - 1){
                          arr.push(subExpandExpression(`(${each}`))
                      } else {
                          arr.push(subExpandExpression(`(${each})`))
                      }
                  })
              }
              else if (difference.startsWith('(')){
                  arr.push(subExpandExpression(difference))
              }
              else {
                  difference.split("||").forEach((each) => {
                      console.log(each)
                      arr.push(subExpandExpression(each))
                  })
              }
              console.log(arr)
              return {
                  _id: getUuidV4(),
                  _type: "group",
                  _data: subExpandExpression(parenthesisString),
                  _orData: arr,
                  _andData: {},
                  _condition: ""
              };
          }
      }
  
      let andIndex = _str.indexOf('&&')
      let orIndex = _str.indexOf('||')
  
      if (andIndex === -1 && orIndex === -1){
          if (_str[0] === '{' && _str[_str.length - 1] === '}'){
              const numberWithinBraces = _str.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
              let _ph = placeholders[number]
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
              return {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: {},
                  _orData: []
              }
          }
      }
      if (orIndex > -1 && (orIndex < andIndex || andIndex === -1)){
          let strings = _str.split("||")
          let _firstStr = strings[0]
          _firstStr = _firstStr.trim()
          if (_firstStr[0] === '{' && _firstStr[_firstStr.length - 1] === '}'){
              const numberWithinBraces = _firstStr.match(/{(\d+)}/);
              console.log(`number _firsStr`, _firstStr, numberWithinBraces);
              let number = parseInt(numberWithinBraces[1]);
              console.log('number, placeholders', number, placeholders)
              let _ph = placeholders[number]
  
              let _arr = []
              strings.forEach((each, i) => {
                  if (i > 0){
                      let _tempStr = each.trim()
                      _arr.push(subExpandExpression(_tempStr))
                  }
              })
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)
              return {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: {},
                  _orData: _arr
              }
          } 
      }
      if (andIndex > -1 && (andIndex < orIndex || orIndex === -1)){
          let strings = _str.split(/\s&&\s/)
          let _firstStr = strings[0]
          _firstStr = _firstStr.trim()
          console.log('sssssss', strings, _firstStr)
          if (_firstStr[0] === '{'){
              const numberWithinBraces = _firstStr.match(/{(\d+)}/);
              let number = parseInt(numberWithinBraces[1]);
              let _ph = placeholders[number]
              let {ptype, key, _dataType} = getParamTypeAndKey(_ph)

              return {
                  _id: getUuidV4(),
                  _type: "element",
                  _data: {
                      _paramType: ptype || '',
                      _dataType,
                      _key: key || '',
                      _value: _ph.value 
                  },
                  _condition: Labelizev2(_ph.rule),
                  _andData: subExpandExpression(strings.slice(1).join(" && ")),
                  _orData: []
              }
          } 
      }

      if (_str.startsWith('{') && _str.endsWith('}')){
          const numberWithinBraces = _str.match(/{(\d+)}/);
          let number = parseInt(numberWithinBraces[1]);
          let _ph = placeholders[number]
          let {ptype, key} = getParamTypeAndKey(_ph)
          return {
              _id: getUuidV4(),
              _type: "element",
              _data: {
                  _paramType: ptype || '',
                  _dataType: 'String',
                  _key: key || '',
                  _value: _ph.value 
              },
              _condition: Labelizev2(_ph.rule),
              _andData: {},
              _orData: []
          }
      }

      return {}
  }

  expandExpression(expression)
  return json
}

export const NumberFormatter = (num, digits=2, convert=1, skipSymbol=false) => {
  try{
      if(num === "" || num === null || num === undefined || num.constructor === Boolean) return num;

      let _num;
      if(convert)
      _num = parseFloat(num * convert).toFixed(digits);
      else
      _num = parseFloat(num).toFixed(digits);

      const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "B" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" },
      ];
  
      if(_num<1e5 || skipSymbol)
      return parseFloat(_num).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
          .slice()
          .reverse()
          .find((item) => _num >= item.value);
      return item ? (_num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  catch(err)
  {
      return num;
  }
};
export const pluralize = (num, str) => {
  if (num === 1){
    return `1 ${str}`
  }
  return `${num} ${str}s`
}

export const numberToWord = (num) => {
  var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

  function inWords (num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
    return str;
  }

  return inWords(parseInt(num))
}

export const defaultCurrencyFn = (currency) => {
  return currency?.currencySymbol || currency?.currencyCode || ""
}

export const getTimeOfTheData = (data) => {
    if(data && Object.values(data)) {
      if(data.hasOwnProperty("updatedAt")){
        return moment(data.updatedAt).format("DD/MM/YYYY")
      }
      else if (data.hasOwnProperty("createdAt")){
        return moment(data.createdAt).format("DD/MM/YYYY")
      }
    }
}


export const getConsumerUsageAppId = ({app}) => {
  if(app.gateway === GATEWAYS.APIGEE) return app.meta?.apigee?.appId || "";
  if(app.gateway === GATEWAYS.AZURE) return app.meta?.azure?.id || "";
  if(app.gateway === GATEWAYS.KONG) return app.meta?.kong?.id || "";
  if(app.gateway === GATEWAYS.AWS) return app?._id || "";
  if(app.gateway === GATEWAYS.NGINX) return app?._id || "";

  return "";
}

export const getDeveloperAppCreatedAt = ({app}) => {
  if(app.gateway === GATEWAYS.APIGEE) return app.meta?.apigee?.createdAt || "";
  if(app.gateway === GATEWAYS.AZURE) return app.meta?.azure?.cts || app.meta?.azure?.createdAt || "";
  if(app.gateway === GATEWAYS.KONG) return app.meta?.kong?.cts || "";
  if(app.gateway === GATEWAYS.AWS) return app?.createdAt || "";
  if(app.gateway === GATEWAYS.NGINX) return app?.createdAt || "";
  if(app.gateway === GATEWAYS.AXWAY) return app?.meta?.axway?.product?.meta?.axway?.cts || "";
  return "";
}


export const getDeveloperAppCreatedByUser = ({app}) => {
  if(app.gateway === GATEWAYS.APIGEE) return app.meta?.apigee?.createdByUser || app.meta?.apigee?.createdBy || "";
  if(app.gateway === GATEWAYS.AZURE) return app?.developerEmail || "";
  if(app.gateway === GATEWAYS.KONG) return app.meta?.kong?.createdUserName || "";
  if(app.gateway === GATEWAYS.AWS) return app?.developerEmail || "";
  if(app.gateway === GATEWAYS.NGINX) return app?.developerEmail || "";
  if(app.gateway === GATEWAYS.AXWAY) return app?.meta?.axway?.product?.meta?.axway?.createdUserName || "";

  return "";
}

export const labelizeWithPlural = (count, _str) => {
  if (count === 1) return `1 ${_str}`;
  else return `${count} ${_str}s`;
};