import {useState, useEffect} from "react";
import { PencilSimple, Plus } from "phosphor-react";
import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import { useTheme } from "../../../../context/ThemeContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getPermissionByCrud, throwServerError } from "../../../../utils/helper";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import { EmptyState } from "../../../../components/EmptyState";
import { useSelector } from "react-redux";
import monetizationV2 from "../../../../api/monetizationV2";
import NewSubscriptionModal from "./NewSubscriptionModal";
import { objectDeepClone } from "@apiwiz/oas/services";

export default function Subscriptions() {
    const { theme } = useTheme()
    const navigate = useNavigate();
    const { currentUser, permission } = useSelector((state) => state.user)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [newSubscriptionModal, setNewSubscriptionModal] = useState({
        isOpen: false, isEdit: false, data: {}
    })
    const [pagination, setPagination] = useState({
        pageSize: 10, offset: 1
    })
    
    let _monetizationPermission = permission.filter(o => o.name === 'subscription')
    _monetizationPermission = _monetizationPermission.length ? (_monetizationPermission[0].crud || []) : []
    const listSubs = getPermissionByCrud(currentUser, _monetizationPermission,"read");
    const createSubs = getPermissionByCrud(currentUser, _monetizationPermission,"create");
    const deleteSubs = getPermissionByCrud(currentUser, _monetizationPermission,"delete");

    useEffect(() => {
        getData()
    }, [])

    const pluralize = (num, str) => {
        if (num === 1){
          return `1 ${str} is`
        }
        return `${num} ${str}s are`
      }
    
    const getData = () => {
        setLoading(true)
        monetizationV2.getSubscribedPackagesV2({
            developerEmail: currentUser.email
        }).then(({data: res}) => {
            setLoading(false)

            let temp = []

            if(res.data && res.data.length) {
                temp = res.data.map((subs) => {
                    let expiringPackages = 0, expiredPackages = 0;
                    for (let subsPackage of subs.packages) {
                        if(subsPackage.endDate) {

                            const oneDay = 24 * 60 * 60 * 1000;
                            const startingDate = new Date();
                            const endingDate = new Date(subsPackage.endDate);
                            
                            const diffDays = Math.round(Math.abs((startingDate - endingDate) / oneDay));
                            if(diffDays <= 30 && startingDate < endingDate) {
                                ++expiringPackages;
                            }
                            else if (startingDate > endingDate) {
                                ++expiredPackages
                            }
                        }
                    }
                    return {
                        ...subs,
                        expiringPackages,
                        expiredPackages
                    }
                })
            }

            console.log(temp,'temp');

            setData(temp)
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }
    
    return (
        <div className="w-100vw bg-currentBackground">
            <CustomNavbar />

            <div className="my-60px container w-100 min-h-100vh">
                <div className="d-none d-md-flex align-items-center gap-4px mt-60px pt-60px">
                    <Link to="/my-account"><p className="fs-14px text-theme-primary fw-500 cursor">My Account / </p></Link>
                    <p className="fs-14px text-theme-primary fw-500">Subscriptions</p>
                </div>

                <div className="mt-32px w-100 ">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <p className="text-theme-content-color fw-600 fs-28px">Subscriptions</p>
                        <div className="d-none align-items-center gap-16px">
                            {createSubs ? (
                                <button style={{width: 186}} className="br-8px cursor bg-theme-primary p-10px d-flex align-items-center justify-content-center gap-6px border-none" 
                                    onClick={() => setNewSubscriptionModal({isOpen: true, isEdit: false, data: {}})}>
                                    <Plus color={`${theme === 'theme--light' ? 'white' : "#171C1B"}`} size={18} />
                                    <p className="fs-14px text-theme-base fw-600">Create Subscription</p>
                                </button>
                            ) : null}
                        </div>
                    </div>

                    {loading ? (
                        <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh" />
                    ) : <div className="dfaic flex-wrap  mt-44px card-grid-wrapper" style={{gap: "25px 20px"}}>
                        {data.length === 0
                        ? <div className="h-100 w-100 mt-5 d-flex justify-content-center flex-column align-items-center">
                            <EmptyState />
                            <p className="ps-5 text-content-color text-center">No Subscriptions Found</p>
                        </div>
                        : data.map((each, i) => (
                            <div
                                key={each.id} className="cursor show-on-hover position-relative br-8px bg-theme-base p-16px v1-border-storkes-800-01 gap-16px d-flex flex-column justify-content-between"
                                onClick={() => navigate(`/monetization/subscriptions/${each.id}`)}
                                style={{maxWidth: "424px",minHeight: "204px", boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06)"}}
                            >
                                <div className="gap-16px d-flex flex-column">
                                    <div className="w-90 d-flex justify-content-center bg-theme-primary mx-auto" style={{height: "3px"}}></div>
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <p className="text-theme-content fs-24px fw-600 text-truncate-1">{each?.subscriptionName}</p>
                                        <div className="hoverItem"
                                            onClick={(e) => {
                                                setNewSubscriptionModal({isOpen: true, isEdit: true, data: objectDeepClone(each)})
                                                e.stopPropagation()
                                            }}>
                                            <PencilSimple size={20} />
                                        </div>
                                    </div>
                                    <p className="grey-subtext fs-16px fw-400 text-truncate-2">
                                        {each?.subscriptionDescription}
                                    </p>
                                </div>
                                <div className="d-flex flex-column gap-2px">
                                    {each?.expiringPackages ? (
                                        <div className="d-flex align-items-center gap-4px">
                                            <div style={{width: 12, height: 12, backgroundColor: "#FFB224", borderRadius: "50%"}}></div>
                                            <p className="fs-14px fw-600" style={{color: "#FFB224"}}>{pluralize(each?.expiringPackages, "package")} expiring soon</p>
                                        </div>
                                    ) : null}
                                    {each?.expiredPackages ? (
                                        <div className="d-flex align-items-center gap-4px">
                                            <div style={{width: 12, height: 12, backgroundColor: "#EB9091", borderRadius: "50%"}}></div>
                                            <p className="fs-14px fw-600" style={{color: "#E5484D"}}>{pluralize(each?.expiredPackages, "package")} expired</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>

            {newSubscriptionModal.isOpen && <NewSubscriptionModal show={newSubscriptionModal.isOpen}
                isEdit={newSubscriptionModal.isEdit} data={newSubscriptionModal.data} callback={getData}
                onHide={() => setNewSubscriptionModal({isOpen: false, isEdit: false, data: {}})} />}
        </div>
    );
}