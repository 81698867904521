import React from 'react'
import { NumberFormatter, labelize } from '../../../../utils/helper'
import { RateCardType } from '../../../../service/constants'
import { useSelector } from 'react-redux'

export default function RateCard({data, currentCurrency={}}) {

    return (
        <>
            <div className={`w-100 border-strokes-600-01 d-flex flex-column align-items-center justify-content-center`}>
                <div className="w-100 d-flex align-items-center justify-content-center gap-6px p-8px">
                    <p className="text-consumer-300 fs-14px fw-400">Charging Modal:</p>
                    <p className="text-consumer-200 fs-14px fw-500">{labelize(data.type)}</p>
                </div>
                {data.type === RateCardType.FIXED_RATE || data.type === RateCardType.UNIT_BASED 
                ? (
                    data?.currency?.[0].amount
                        ? <div className="p-8px text-center bg-base-100 w-100 fs-14px text-consumer-300 fw-400">{NumberFormatter(data?.currency?.[0].amount, 2, currentCurrency.unitAmount)} {currentCurrency.currencyCode || ''}</div>
                        : <div className="p-8px text-center bg-base-100 w-100 fs-14px text-consumer-300 fw-400">-</div>
                    
                ) : (
                    data.range &&
                    data.range.length > 0 &&
                    data.range.map((_range, ind) => (
                        <div className={`p-8px text-center ${ind % 2 ? "bg-base-100" : ""} w-100 fs-14px text-consumer-300 fw-400 d-flex align-items-center justify-content-center gap-8px`}>
                            <div className="w-60 d-flex justify-content-end fs-15px">
                                {ind > 0
                                    ? `from ${data.range?.[ind - 1]?.to + 1 || ""} ${ind === data.range.length - 1 ? "to ∞" : `API call up to ${_range.to} calls`}`
                                    : `Calls Upto ${_range.to} calls`}{" "}
                                :
                            </div>
                            { _range?.currency[0]?.amount
                                ? <div className="w-40 d-flex justify-content-start fw-500 text-content-color">{NumberFormatter(_range?.currency[0]?.amount, 2, currentCurrency.unitAmount)} {currentCurrency.currencyCode || ''}</div>
                                : <div className="w-40 d-flex justify-content-start fw-500 text-content-color">-</div>
                            }
                        </div>
                    ))
                )}
            </div>
            <div className={`w-100 border-strokes-600-01 d-flex flex-column align-items-center justify-content-center`}>
                <div className="p-8px w-100 dfaic justify-content-center text-consumer-300 fs-14px fw-400">Additional Fee</div>
                {data.type === RateCardType.FIXED_RATE || data.type === RateCardType.UNIT_BASED ? (
                    data?.currency?.[0].flatFee
                        ? <div className="p-8px text-center bg-base-100 w-100 fs-14px text-consumer-300 fw-400">{NumberFormatter(data?.currency?.[0].flatFee, 2, currentCurrency.unitAmount)} {currentCurrency.currencyCode || ''}</div>
                        : <div className="p-8px text-center bg-base-100 w-100 fs-14px text-consumer-300 fw-400">-</div>
                ) : (
                    data.range &&
                    data.range.length > 0 &&
                    data.range.map((_range, ind) => (
                        <div className={`p-8px text-center ${ind % 2 ? "" : "bg-base-100"} w-100 fs-14px text-consumer-300 fw-400  d-flex align-items-center justify-content-center gap-8px`}>
                            <div className="w-60 d-flex justify-content-end fs-15px">
                                {ind > 0
                                    ? `from ${data.range?.[ind - 1]?.to + 1 || ""} ${ind === data.range.length - 1 ? "to ∞" : `API call up to ${_range.to} calls`}`
                                    : `Calls Upto ${_range.to} calls`}
                                :
                            </div>
                            { _range?.currency[0]?.flatFee
                                ? <div className="w-40 d-flex justify-content-start fw-500 text-content-color">{NumberFormatter(_range?.currency[0]?.flatFee, 2, currentCurrency.unitAmount)} {currentCurrency.currencyCode || ''}</div>
                                : <div className="w-40 d-flex justify-content-start fw-500 text-content-color">-</div>
                            }
                        </div>
                    ))
                )}
            </div>
        </>
    )
}