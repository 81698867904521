import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  convertSnippetToLang,
  getSnippetsFromAxiosOptions,
} from "@apiwiz/oas/index";
import Editor from "@monaco-editor/react";
import { monocoEditorOption } from "../../../service/constants";
import { useTheme } from "../../../context/ThemeContext";
import { DownloadSimple } from "phosphor-react";
import { CodeGenerationModal } from "../components/CodeGenerationModal";

let tabs = [
  {
    title: "CURL",
    icon: "/assets/icons/curl.svg",
    lang: "shell",
  },
  {
    title: "JAVA",
    icon: "/assets/icons/java.svg",
    lang: "java",
  },
  {
    title: "PHP",
    icon: "/assets/icons/php.png",
    lang: "php",
  },
  {
    title: "NODE JS",
    icon: "/assets/icons/node_js.svg",
    lang: "javascript",
  },
  {
    title: "C#",
    icon: "/assets/icons/c_sharp.svg",
    lang: "csharp",
  },
  {
    title: "JS",
    icon: "/assets/icons/js.svg",
    lang: "javascript",
  },
];
export default function CodeView(props) {
  const { axiosOptions, selectedOperation, tryoutHasErrors } = useSelector(
    (state) => state.swagger
  );
  const { theme } = useTheme();
  const snippets = useMemo(() => {
    if (!axiosOptions || !Object.keys(axiosOptions).length) return {};
    return getSnippetsFromAxiosOptions({ axiosOptions });
  }, [axiosOptions]);

  const [codeGenerator,setCodeGenerator] = useState(false);

  function handleCodeGenerator(){
    setCodeGenerator((prev)=>!prev)
  }


  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className={`${props.className} border-left-theme-secondary-200 bg-theme-surface-875 p-16px`}>
      <div className="bg-theme-base">
        {/* Tabs */}
        <div className="d-flex justify-content-around gap-2px">
          {tabs.map((el) => {
            return (
              <div
                onClick={() => {
                  setActiveTab(el);
                }}
                className={`${
                  activeTab.title === el.title
                    ? "border-top-theme-primary"
                    : "bg-theme-surface-900 border-theme-primary-subtle"
                } cursor w-100 px-12px py-6px d-flex flex-column gap-4px align-items-center justify-content-center br-t-4px`}
              >
                <div className="d-flex justify-content-center alogn-items-center">
                  <img src={el.icon} />
                </div>
                <div
                  className={`${
                    activeTab.title === el.title
                      ? "fw-600"
                      : "text-theme-content-subtle fw-500"
                  } fs-10px`}
                >
                  {el.title}
                </div>
              </div>
            );
          })}
        </div>

        {/* Tab Content */}
        <div className="d-flex code-view-editor">
          {tryoutHasErrors && tryoutHasErrors.length ? (
            <div className="d-flex justify-content-center align-items-center h-150px w-100 fs-14px text-theme-content-subtle">
              {tryoutHasErrors[0]}
            </div>
          ) : <Editor
            width="100%"
            height={571}
            language={activeTab.lang}
            theme={theme === "theme--dark" ? "vs-dark" : "vs-light"}
            options={{ ...monocoEditorOption, readOnly: true}}
            value={convertSnippetToLang({ snippets, lang: activeTab.lang })}
          />}
        </div>
      </div>
      <div className="w-100">
        <button onClick={()=>{setCodeGenerator(true)}} className="cursor h-38px br-4px border-theme-primary d-flex justify-content-center align-items-center gap-4px bg-theme-base mt-3 p-2 py-2 w-100 text-primary-color"><DownloadSimple size={16} color="var(--primary-color)"/><p className="fs-14px ms-2 fw-500">Download Server or Client Side Code</p></button>
      </div>
      {
        codeGenerator && <CodeGenerationModal
        show={codeGenerator}
        onHide={handleCodeGenerator}
        backdrop={"static"}
       />
      }
    </div>
  );
}
