import { ArrowSquareOut, CaretRight, Cube } from "phosphor-react";
import { GATEWAYS, PRODUCTS_MAPPER_DISPLAY_NAME } from "../../../service/constants";

const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

const getName = ({data}) => {
  if(data.type === AWS_GATEWAY) return data[PRODUCTS_MAPPER_DISPLAY_NAME.AWS];

  return data?.name ? data?.name : data?.productName ? data?.productName : ""
}

export const ProductsCard = ({ data, productDetailAction, buyNowAction, setProductDetails }) => {
  return (
    <div className={`productCardv2`}>
      <div className="productCardBody">
        <p className="fs-20px fw-600 text-content-color">
          {data.name}
        </p>
        <p className="fw-400 fs-12px grey-subtext mt-16px text-truncate-3" style={{ height: 70 }}>
          {data?.description || "-"}
        </p>

        <div className="mt-3">
          <div
            className="d-flex align-items-center justify-content-center gap-8px secondaryCTAButton py-18px w-100"
            style={{ height: 32, borderRadius: 4 }}
            onClick={() => {
              console.log("data: ", data);
              // productDetailAction();
              setProductDetails({ data, isOpen: true })
            }}
          >
            <p className="fs-13px fw-600 text-center">Details</p>
          </div>
        </div>
      </div>
    </div>
  );
};
