import { useEffect, useState } from "react";
import { getRatePlanValue, objectDeepClone } from "../../../utils/helper";
import { ArrowSquareOut } from "phosphor-react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import monetizationAPI from "../../../api/monetization";
import {
  PRODUCTS_MAPPER_KEY,
  ROUTE_NAMES,
} from "../../../service/constants";

export const ProductDetailsModal = ({
  onModalClose,
  selectedProductBundle,
}) => {
  const { name, description, termsOfUse } = selectedProductBundle.productBundle;

  const [associatedGuides, setAssociatedGuides] = useState([]);

  // admin/manage-guides/guide?params={"guideName":"new-guide"}

  const { specs } = selectedProductBundle;

  useEffect(() => {
    let id = (selectedProductBundle?.productBundle || {})[PRODUCTS_MAPPER_KEY.APIGEE_BUNDLE];
    if (id) {
      monetizationAPI
        .getGuidesByProductId(id)
        .then((res) => {
          let data = res?.data?.data || [];
          setAssociatedGuides(data);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  }, []);

  return (
    <div
      className="product-details-modal__container"
      onClick={(evt) => {
        evt.stopPropagation();
        onModalClose();
      }}
    >
      <div
        className="product-details-modal-body"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-header">
          <div className="modal-title">{name}</div>
          <div className="modal-action-btns__container">
            <button
              className="action-btn close-btn primary"
              onClick={onModalClose}
            >
              <GlobalIcons type={"close"} />
            </button>
          </div>
        </div>
        <div className="modal-content">
          <div className="product-details-values-card">
            <div className="input-group__container">
              <p className="label">Product Bundle</p>
              <p className="input-value">{name}</p>
            </div>
            <div className="input-group__container">
              <p className="label">Bundle Description</p>
              <p className="input-value">{description} </p>
            </div>

            {associatedGuides.length ? (
              <>
                <div className="input-group__container">
                  <p className="label">Docs</p>
                </div>
                <div className="d-flex flex-column gap-8px external-links__container">
                  {associatedGuides.map((el) => {
                    return (
                      <div
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/${ROUTE_NAMES.GUIDES}/${el.slug}`,
                            "_blank"
                          );
                        }}
                        className="external-link cursor"
                      >
                        {el.name}
                        <ArrowSquareOut
                          color="var(--content-subtle)"
                          className="cursor-pointer"
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}

            {termsOfUse ? (
              <div className="external-links__container">
                <div
                  className="external-link"
                  onClick={() => {
                    window.open(termsOfUse, "_blank");
                  }}
                >
                  <p className="title">Terms of Use</p>
                  <ArrowSquareOut size={14} color="var(--content-subtle)" />
                </div>
              </div>
            ) : null}

            <div className="input-group__container">
              <p className="label">Rate Plan</p>
              <div className="purchase-cards-container">
                {selectedProductBundle?.ratePlans
                  .filter((ratePlan) => ratePlan.visibleToPortals)
                  .map((ratePlan) => {
                    return (
                      //   <div className="purchase-card">
                      //     <div className="purchase-plans-details__container m-0">
                      <>
                        {getRatePlanValue({
                          rateplan: ratePlan,
                        }).map((pricing) => (
                          <div className="purchase-plan-detail">{pricing}</div>
                        ))}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {Object.keys(specs).length > 0 && (
            <div className="product-swaggers__container">
              <div className="header-container">
                <div className="heading">Products</div>
                <div className="heading">Swagger</div>
              </div>
              {Object.entries(specs).map(([key, value]) => (
                <div className="product-swaggers-row">
                  <>
                    <div className="product-name-container">{key}</div>
                    <div className="w-50 external-links__container d-flex flex-wrap gap-8px">
                      {value?.map((swagger) => (
                        <div onClick={() => {

                        }} className="external-link cursor w-100">
                          {swagger.swaggerName}
                          <ArrowSquareOut
                            size={14}
                            color="var(--content-subtle)"
                          />
                        </div>
                      ))}
                    </div>
                    
                  </>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
