import { Browsers } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setContents } from '../../../../app/Features/Layout/LayoutSlice'
import SelectIcon from '../../../../components/SelectIcon'
import { getToast } from '../../../../components/Toast'
import { confirm, objectDeepClone } from '../../../../utils/helper'
import CustomCard1 from '../../../Dashboards/components/CustomCard1'
import AddScenarioBtn from '../../../../components/AddScenarioBtn'
import GlobalIcons from '../../../../components/Icons/GlobalIcons'
import ImageCard from './ImageCard'

export default function RenderCard1({isEdit, setIsEdit, setLoading, sectionIndex}) {
    const {currentLayoutObject, backupCurrentLayout} = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const [isCtrl, setIsCtrl] = useState(false)

    useEffect(() => {
        if (currentLayoutObject.contents && currentLayoutObject.contents[sectionIndex]){
            setData(currentLayoutObject.contents[sectionIndex].payload || {})
        }
    }, [])

    const handleCancel = () => {
        if (!confirm("Are you sure? any new data might be lost in this section?")) return

        let _prevObj = objectDeepClone(backupCurrentLayout)
        setData(_prevObj.contents[sectionIndex].payload || {})
    }

    const handleSave = () => {
        let _data = objectDeepClone(data)
        let invalidValues = []

        _data.cards.forEach(each => {
            if (!each.icon || !each.title || !each.description){
                invalidValues.push(each)
            }
            else if (each.primaryCTA.label && !each.primaryCTA.url){
                invalidValues.push(each)
            }
            else if (each.secondaryCTA.label && !each.secondaryCTA.url){
                invalidValues.push(each)
            }
        })

        if (!_data.heading || invalidValues.length){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the required details'
            })
            return
        }

        _data.isEdit = false
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
        setData(objectDeepClone(_data))
    }

    const handleEdit = () => {
        let _data = objectDeepClone(data)
        _data.isEdit = true 
        setIsEdit(true)
        setData(objectDeepClone(_data))
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
    }
    
    const handleChange = (_id, type, val, _innerType = null) => {
        let _data = objectDeepClone(data)
        if (_innerType){
            _data.cards[_id][_innerType][type] = val
        } else {
            _data.cards[_id][type] = val 
        }
        setIsEdit(true)
        setData(objectDeepClone(_data))
    }

    const handleAddNewCard = () => {
        let _data = objectDeepClone(data)
        _data.cards.push({
            "icon": "book",
            "iconWeight": "regular",
            "title": `Card Title ${(_data.cards?.length + 1) || 1}`,
            "fillPrimaryColor": true,
            "showBorder": true,
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lacinia, urna sit amet sodales volutpat, orci tortor condimentum lacus, a vestibulum odio dui eu purus. Phasellus ut purus mattis, ultricies mi vel, fermentum massa. Curabitur tempus arcu tincidunt, sollicitudin risus dictum, ornare lectu",
            "primaryCTA": {
                "url": "/demo",
                "label": "Demo"
            },
            "secondaryCTA": {
                "url": "/signup",
                "label": "Sign Up"
            }
        })
        setIsEdit(true)
        setData(objectDeepClone(_data))
    }


    return (
        <div className='' onKeyDown={(e) => {
            setIsCtrl(['Control', 'Meta'].includes(e.key))
            if (isCtrl && e.key?.toLowerCase() === 's') {
                handleSave()
                e.preventDefault()
            }
        }}>
            <div className='surface-base p-16px overflow-scroll noscrollbar' style={{zoom: 0.5, height: 600}}>
                <CustomCard1 data={data} fromEditor />
            </div>

            <div className='surface-875 d-flex align-items-center justify-content-between p-12px mt-3'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Content</p>
                </div>
                {data.isEdit
                ? <div className='d-flex align-items-center'>
                    <p className='text-300 fs-12px fw-500 me-3 cursor'
                        onClick={handleCancel}>Cancel</p>

                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleSave}>
                        Save
                    </div>
                </div>
                : <div>
                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleEdit}>
                        Edit
                    </div>
                </div>}
            </div>

            <div className='surface-base br-4px p-16px w-100 h-70vh overflow-scroll noscrollbar'>
                <div className='mb-3 row mx-0'>

                    <div className='col-md-7'>
                        <div className='mb-3'>
                            <p className='text-300 fs-12px fw-400'>Tag Name</p>
                            <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.tagName}
                                disabled={!data.isEdit} onChange={(e) => setData({...data, tagName: e.target.value})} />
                        </div>

                        <div className='mb-3'>
                            <p className='text-300 fs-12px fw-400'>Hero Heading <span className='text-danger'>*</span></p>
                            <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.heading}
                                disabled={!data.isEdit} onChange={(e) => setData({...data, heading: e.target.value})} />
                        </div>

                        <div className='mb-3'>
                            <p className='text-300 fs-12px fw-400'>Hero Description </p>
                            <textarea type={'text'} rows={5}
                                className='from-control v1-form-textarea mt-1 w-100' value={data.description}
                                disabled={!data.isEdit} onChange={(e) => setData({...data, description: e.target.value})} />
                        </div>
                    </div>

                    <div className='col-md-5 my-auto'>
                        <p className='text-300 fs-12px fw-400 mb-1'>Background Image (Optional) </p>
                        <ImageCard imgUrl={data.imageUrl} name='imageLogo' width={'100%'} 
                            isEdit={data.isEdit} setUrl={(url) => setData({...data, imageUrl: url})} />
                    </div>
                </div>

                {data.isEdit && <div className='row mx-0 mb-3'>
                    <div className='col-md-4 ps-0 w-fit dfaic cursor'
                        onClick={() => setData({...data, showGradients: !data.showGradients})}>
                        <GlobalIcons type={data.showGradients ? 'form-check' : 'form-uncheck'} />
                        <p className='text-300 fs-12px fw-400 ps-2'> Show Background Gradient </p>
                    </div>

                    <div className='col-md-4 w-fit dfaic cursor'
                        onClick={() => setData({...data, fillPrimaryColor: !data.fillPrimaryColor})}>
                        <GlobalIcons type={data.fillPrimaryColor ? 'form-check' : 'form-uncheck'} />
                        <p className='text-300 fs-12px fw-400 ps-2'> Fill Primary Color </p>
                    </div>

                    <div className='col-md-4 w-fit dfaic cursor'
                        onClick={() => setData({...data, showBorder: !data.showBorder})}>
                        <GlobalIcons type={data.showBorder ? 'form-check' : 'form-uncheck'} />
                        <p className='text-300 fs-12px fw-400 ps-2'> Show Border (hover effects) </p>
                    </div>
                </div>}

                {data.cards?.map((eachCard, i) => (<div className='mb-3 surface-850 p-16px br-4px' key={i}>
                    <p className='fw-500 fs-14px text-200'>Card Detail {i+1}</p>

                    <p className='fw-400 fs-14px text-300 mt-2 mb-3'>Select Icon</p>

                    <SelectIcon val={eachCard.icon} disabled={!data.isEdit} weight={eachCard.iconWeight}
                        setVal={(_icon) => handleChange(i, 'icon', _icon)} />

                    {data.isEdit && <div className='mt-1 w-fit dfaic cursor' 
                        onClick={() => handleChange(i, 'iconWeight', eachCard.iconWeight === 'fill' ? 'regular' : 'fill')}>
                        <GlobalIcons type={eachCard.iconWeight === 'fill' ? 'form-check' : 'form-uncheck'} />
                        <p className='text-300 fs-12px fw-400 ps-2'> Fill Icon </p>
                    </div>}

                    <div className='mt-4'>
                        <div className='mb-3'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p className='fs-12px fw-400 text-300'>Card Title <span className='text-danger'>*</span></p>

                                <p className='fs-12px fw-400 text-400'>{eachCard.title?.length || 0} / 30</p>
                            </div>
                            <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.title}
                                onChange={(e) => {
                                    if (e.target.value?.length < 31){
                                        handleChange(i, 'title', e.target.value)
                                    }
                                }} disabled={!data.isEdit} />
                        </div>

                        <div className='mb-3'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p className='fs-12px fw-400 text-300'>Card Description <span className='text-danger'>*</span></p>

                                <p className='fs-12px fw-400 text-400'>{eachCard.description?.length || 0} / 270</p>
                            </div>
                            <textarea type={'text'} className='form-control v1-form-textarea mt-1' value={eachCard.description}
                                onChange={(e) => {
                                    if (e.target.value?.length < 271){
                                        handleChange(i, 'description', e.target.value)
                                    }
                                }} rows={4} disabled={!data.isEdit} />
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-300'>Primary CTA Label {eachCard.primaryCTA.label && <span className='text-danger'>*</span>}</p>
                            <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.primaryCTA.label}
                                onChange={(e) => handleChange(i, 'label', e.target.value, 'primaryCTA')} disabled={!data.isEdit} />
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-300'>Primary CTA Url {eachCard.primaryCTA.label && <span className='text-danger'>*</span>}</p>
                            <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.primaryCTA.url}
                                onChange={(e) => handleChange(i, 'url', e.target.value, 'primaryCTA')} disabled={!data.isEdit} />
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-300'>Secondary CTA Label {eachCard.secondaryCTA.label && <span className='text-danger'>*</span>}</p>
                            <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.secondaryCTA.label}
                                onChange={(e) => handleChange(i, 'label', e.target.value, 'secondaryCTA')} disabled={!data.isEdit} />
                        </div>

                        <div className='mb-3'>
                            <p className='fs-12px fw-400 text-300'>Secondary CTA Url {eachCard.secondaryCTA.label && <span className='text-danger'>*</span>}</p>
                            <input type={'text'} className='form-control v1-form-control mt-1' value={eachCard.secondaryCTA.url}
                                onChange={(e) => handleChange(i, 'url', e.target.value, 'secondaryCTA')} disabled={!data.isEdit} />
                        </div>
                    </div>
                </div>))}

                {data.isEdit && <AddScenarioBtn type={'New Card'}
                    onClick={() => handleAddNewCard()} className='w-100' />}
            </div>
        </div>
    )
}
