import React from "react";
import { Accordion } from "react-bootstrap";
import { useTheme } from "../../context/ThemeContext";
import { ArrowSquareOut, CaretRight } from "phosphor-react";
import { constructFilterResources } from "../../utils/helper";
import { ROUTE_NAMES, SRC_PARAM_VALUES, URL_PARAMS } from "../../service/constants";

const SwaggerAccordion = (props) => {
    const { data } = props;
    const { theme } = useTheme()
    return (
        <Accordion defaultActiveKey="0" alwaysOpen className={`v1-accordion swagger-accordion d-flex flex-column ${data && data.length ? "my-2": ""} gap-10px`}>
            {data.map((swagger, parentId) => (
                <Accordion.Item eventKey={parentId} key={parentId}>
                    <Accordion.Header className="surface-consumer-850 d-flex align-items-center w-100 justify-content-between">
                        <div className="d-flex align-items-center gap-8px">
                            <CaretRight size={16} color={theme === "theme--light" ? "#464646" : "#C2C2C2"} className="arrow"/>
                            <p className="text-theme-content-color fs-14px fw-500 ">{swagger?.swaggerName}</p>
                            {(swagger.swaggerId && swagger.oasVersion && swagger.revision) ? (
                                <div 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log(swagger?.oasVersion, typeof swagger?.oasVersion);
                                        let filterParams = constructFilterResources({resources: swagger.resources})
                                        let url = `${window.location.origin}/${ROUTE_NAMES.API_REFERENCE}/${swagger?.oasVersion}/${swagger.swaggerId}/${swagger.revision}`
                                        if(filterParams) url+=`?${URL_PARAMS.SRC}=${SRC_PARAM_VALUES.PACKAGE}&${URL_PARAMS.API_REFERENCE}=${filterParams}`
                                        window.open(url, "_blank")
                                    }}
                                >
                                    <ArrowSquareOut size={14} color={theme==="theme--light" ? "#464646" : "#E4E4E4"} style={{marginBottom: 2}} />
                                </div>
                            ) : null}

                        </div>
                    </Accordion.Header>

                    <Accordion.Body className="p-12px surface-consumer-875">
                        <div className="gap-2px d-flex flex-column">
                            <div className="d-flex justify-content-start align-items-center w-100 p-8px">
                                <div className="w-50 text-consumer-400 fs-10px fw-500">Name / Method</div>
                                <div className="w-50 text-consumer-400 fs-10px fw-500">Description</div>
                            </div>
                            {swagger.resources && swagger.resources.length ? (
                                swagger.resources.map((resource) => (
                                    <div className="d-flex w-100 align-items-center justify-content-start p-8px border-strokes-600">
                                        <div className="dfaic gap-12px w-50">
                                            <p
                                                className={`text-truncate text-center text-uppercase br-8px mt-3px p-8px fs-12px w-80px fw-500 text-consumer-200 api-method-tag ${resource?.method?.toLowerCase()}`}
                                            >
                                                {resource?.method?.toUpperCase()}
                                            </p>

                                            <div style={{ width: 280 }}>
                                                <p className="fs-14px text-consumer-100 fw-500 text-truncate">{resource?.name || '-'}</p>
                                                <p className="fs-12px text-consumer-400 fw-400 text-truncate">{resource?.path || '-'}</p>
                                            </div>
                                        </div>

                                        <div className="dfaic gap-24px w-50">
                                            <p className="text-consumer-400 fs-12px fw-400 text-truncate-2">{resource?.description || '-'}</p>
                                        </div>
                                    </div>
                                ))
                            ) : null}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default SwaggerAccordion;
