import { useCallback, useEffect, useState } from "react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import API from "../../../api";
import { getToast } from "../../../components/Toast";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import { EditKongUsersModal } from "./EditKongUsersModal";
import { useNavigate } from "react-router";
import { EditAzureUsersModal } from "./EditAzureUsersModal";

export const AzureUsersTable = ({ data, pagination, getData, resourceGroups, search }) => {
  const [showUpdatemodal, setShowUpdateModal] = useState(false);
  const [currentEditableUser, setCurrentEditableUser] = useState(null);
  const navigate = useNavigate();

  const onUpdateUserResourceGroup = ({ resourceGroups, userId, connectorIds }) => {

    API.updateAzureUser({ data: {
      connectorIds
    }, userId })
      .then((res) => {
        const currentPage = pagination.prev ? pagination.prev + 1 : 1;
        getData({ page: currentPage, search });
        getToast({
          statusType: "SUCCESS",
          message: "User & Resource Groups successfully updated",
        });

        // Close modal
        setShowUpdateModal(false);
        setCurrentEditableUser(null);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to update user resource Groups",
        });
      });
  };

  return (
    <>
      {resourceGroups && resourceGroups.resourceGroups?.length > 0 ? (
        <>
          {/* Header columns  */}
          <div className="surface-875 p-6px row mx-0">
            <div className="col-md-3 p-0">
              <p className="text-400 fs-12px fw-500">Name</p>
            </div>
            <div className="col-md-3 p-0">
              <p className="text-400 fs-12px fw-500">User Name</p>
            </div>
            <div className="col-md-3 p-0">
              <p className="text-400 fs-12px fw-500">Resource Groups</p>
            </div>
            <div className="col-md-2 p-0">
              <p className="text-400 fs-12px fw-500">Registered</p>
            </div>
            <div className="col-md-1 p-0">
              <p className="text-400 fs-12px fw-500"></p>
            </div>
          </div>
          {/* Table data  */}
          <div className="h-72vh overflow-scroll noscrollbar">
            {/* Row item  */}
            {data &&
              data.length > 0 &&
              data.map((row) => (
                <div
                  className="row mx-0 p-14px v1-border-bottom-1 hover-surface-875"
                  key={row._id}
                >
                  <div className="col-md-3 p-0">
                    <p className="text-200 fs-14px fw-500 text-truncate">
                      {row.fullName}
                    </p>
                  </div>
                  <div className="col-md-3 p-0">
                    <p className="text-200 fs-14px fw-500 text-truncate">
                      {row.username}
                    </p>
                  </div>
                  <div className="col-md-3 p-0">
                    <div className="w-100 d-flex flex-wrap align-items-center gap-16px">
                      {row.azureConnectors && row.azureConnectors.length > 0 ? (
                        row.azureConnectors.map((resource) => (
                          <span className="text-200 fs-14px fw-500 text-truncate chips">
                            {resource.resourceGroup}
                          </span>
                        ))
                      ) 
                      /** Removed default resource group from Azure, Since azure needs every user asoocaition
                       * so super-admin he has to assocaite event though it is default
                       */
                      // : 
                      //  resourceGroups.default ? (
                      //   <span className="text-200 fs-14px fw-500 text-truncate chips">
                      //     {resourceGroups.default} <small>(default)</small>
                      //   </span>
                      // )
                       : (
                        "--"
                      )}
                    </div>
                  </div>
                  <div className="col-md-2 p-0">
                    <p className="text-200 fs-14px fw-500 text-truncate">
                      {moment.utc(row.updatedAt).format("HH:MM A - MM/DD/YYYY")}
                    </p>
                  </div>
                  <div className="col-md-1 p-0">
                    <div
                      className="btn-sm-v1 v1-border-2 me-3"
                      onClick={() => {
                        setShowUpdateModal(true);
                        setCurrentEditableUser(row);
                      }}
                    >
                      <GlobalIcons type={"pencil-v2"} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {data && data.length > 0 && (
            <Pagination
              className="py-2"
              pagination={{
                pageSize: pagination.limit,
                offset: pagination.prev ? pagination.prev + 1 : 1,
                total: pagination.total,
              }}
              cb={(val) => getData({ page: val, search })}
            />
          )}

          {showUpdatemodal && (
            <EditAzureUsersModal
              show={showUpdatemodal}
              userData={currentEditableUser}
              resourceGroups={resourceGroups}
              onHide={() => {
                setShowUpdateModal(false);
                setCurrentEditableUser(null);
              }}
              onSave={onUpdateUserResourceGroup}
            />
          )}
        </>
      ) : (
        <div className="h-72vh overflow-scroll noscrollbar">
          <div className="d-flex flex-column align-items-center mt-84px gap-40px">
            <GlobalIcons type={"empty-data"} />

            <div className="d-flex flex-column align-items-center gap-16px">
              <p className="text-200 fw-500 fs-24px">Data not synced</p>
              <p className="text-400 fs-14px fw-400 text-center">
                Sync Azure Resource Group data from View Gateway <br /> Details
                to assign user to them.
              </p>

              <div
                className="btn v1-btn-secondary p-2"
                onClick={(evt) => {
                  navigate(
                    "/admin/manage-gateways/gateway-details?gateway=Azure"
                  );
                }}
              >
                <p className="text-200 ps-2">Sync Resource Group</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
