import API from '../../../../api'
import Select from 'react-select'
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProductModal from './ProductModal';
import { useState, useEffect } from "react";
import { getUuidV4 } from '@apiwiz/oas/services';
import { Link, useNavigate } from 'react-router-dom';
import { getToast } from '../../../../components/Toast';
import { GATEWAYS } from '../../../../service/constants';
import monetizationAPI from '../../../../api/monetization'
import Loading from '../../../../components/Loader/Loading';
import monetizationV2 from "../../../../api/monetizationV2";
import { EmptyState } from "../../../../components/EmptyState";
import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import MonetizationIcons from '../../../../components/Icons/MonetizationIcons';
import { getPermissionByCrud, objectDeepClone, showSuccessMessage, throwServerError } from "../../../../utils/helper";
import { CaretLeft, CaretRight, ClipboardText, FolderNotch, Package } from "phosphor-react";
import { ArrowSquareOut } from "phosphor-react";
import { setCurrentUser } from '../../../../app/Features/Users/UserSlice';
import { useDispatch } from 'react-redux';
import { resetDeveloperApp } from '../../../../app/Features/DeveloperApp/DeveloperAppSlice';

const withSubs = getUuidV4();
const withOutSubs = getUuidV4();

const IntermediateStepOption = [
    {
        id: withOutSubs,
        label: "Create Without Subscription",
        desc: "App created without subscription won't be monetized. Additionally, it's readily available in the API Gateway, for future integration.",
        icon: "without-subs-icon"
    }
]

const CreateApp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [productLoader, setProductLoader] = useState(false)
    const [subLoading, setSubLoading] = useState(false)
    const [productsList, setProductsList] = useState([]);
    const { currentUser, permission } = useSelector((state) => state.user);
    const [isSubmitClick, setIsSubmitClick] = useState(false);
    const [showSelectSubscription, setShowSelectSubscription] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [payload, setPayload] = useState({});
    const [currencies, setCurrencies] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState({})
    const [packages, setPackages] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [multiProducts, setMultiProducts] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)

    const [productDetails, setProductDetails] = useState({data: null, isOpen: false})
    const [intermediateStep, setIntermediateStep] = useState(IntermediateStepOption[0])
    const handleStepIncrement = () =>  setActiveStep(prev => ++prev)

    /** ACL */
    let subscription = permission.filter((o) => o.name === "subscription");
    subscription = subscription.length ? subscription[0].crud || [] : [];
    const listSubs = getPermissionByCrud(currentUser, subscription, "read");
    /** ACL */

    const handleOnChange = (key, value) => {
        let temp = objectDeepClone(payload);
        temp[key] = value;
        setPayload(temp);
    };

    const handleProductViewClick = (e, product) => {
        e.stopPropagation();
        setProductDetails({isOpen: true, data: product})
    }

    const handleProceed = (value) => {
        if(btnLoading) return;

        if(activeStep === 1) {
            if(!(selectedProduct && Object.keys(selectedProduct).length)){
                throwServerError("Please select a product")
                return
            }
            handleStepIncrement()
        }
        else if (activeStep === 2) {
            if(validAppDetailsForm()) {
                if(selectedProduct.enableMonetization) {
                    handleStepIncrement()
                } else {
                    createDeveloperApp(selectedProduct, payload)
                }
            }
        }
        else if(activeStep === 3) {
            
            if (!selectedSubscription.id){
                throwServerError("Please select a subscription before creating an app.")
                return 
            }

            createDeveloperApp(selectedProduct, payload)
        }
    }

    const validAppDetailsForm = () => {
        setIsSubmitClick(true);
        if (!payload.appName || !payload.appDescription) return;

        // if(!currentUser?.billing?.billingAddress && !payload.address) return;
        // if(!currentUser?.billing?.billingCurrency && !payload.billingCurrency) return;
        return true;
    }

    const getCurrencies = () => {
        API.getMasterCurrencies({status: 'PUBLISH'})
        .then(res => {
            let _data = res.data?.data || []
            if (_data.length > 0) _data = _data[0].currencies || []
            
            let _modifiedData = []
            let labels = []

            _data.forEach((each) => {
                each['currencyId'] = each.id 
                if (labels.includes(each.currencyName)) return
                if (each.hasOwnProperty('id')) delete each.id
                _modifiedData.push({...each})
                labels.push(each.currencyName)
            })
            
            setCurrencies(_modifiedData)
        }).catch(err => {
            // throwServerError(err)
        })
    }

    const getData = () => {
        monetizationV2.getSubscribedPackagesV2({
            offset: 1, 
            expand: true, 
            developerEmail: currentUser.email
        }).then(res => {
            setSelectedSubscription({})
            setSubscriptions(res.data.data || [])
            setPackages([])
        }).catch(err => {
            // throwServerError(err)
        })
    }

    const getAllProducts = () => {
        setProductLoader(true)
        monetizationAPI
          .getProductDetails({expand: true})
          .then(({data: res}) => {
            setProductLoader(false)
            console.log('res', res)
            if (res?.data) {
              setProductsList(res?.data);
            }
          })
          .catch((err) => {
            setProductLoader(false)
            setProductsList([]);
            throwServerError(err);
          });
      };

    const handleSelect = (_subscription) => {
        setSubLoading(true)
        monetizationV2.getSubscriptionDetails({
            id: _subscription.id,
            developerEmail: currentUser.email
        }).then(res => {
            setSubLoading(false)
            let _selected = objectDeepClone(res.data.data)
            console.log(_selected,'_selected');
            setSelectedSubscription(objectDeepClone(_selected))
            setPackages(_selected.packages || [])

            if(!_selected?.packages && !Object.hasOwnProperty("packages")){
                getToast({
                    statusType: "WARNING",
                    message: "No packages exists inside this subscription",
                });
            }
        }).catch(err => {
            setPackages([])
            setSubLoading(false)
            throwServerError(err)
        })
    }

    const getUserDetails = () => {
        if ((currentUser && currentUser._id)){
            API.getProfile(currentUser._id)
            .then(res => {
                dispatch(setCurrentUser(res.data.data))
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    // Temporary solution to handle Kong Multi Product for Tonik
    const handleProductSelection = (product) => {
        if((selectedProduct && Object.keys(selectedProduct).length && selectedProduct.gateway !== product.gateway)) return;

        if(product.gateway !== GATEWAYS.KONG) {
            setSelectedProduct(product)
            // handleStepIncrement()
        } 
        else {
            let temp = objectDeepClone(multiProducts)
            let foundInd = multiProducts.findIndex(x => x._id === product._id)
            if(foundInd > -1) {
                temp.splice(foundInd, 1)
            } else {
                temp.push(product)
            }

            if(!temp.length) {
                setSelectedProduct({})
            } else {
                setSelectedProduct(temp[0])
            }
            console.log(temp,'temp');
            setMultiProducts(temp)
        }
    }

    const createDeveloperApp = (chosenProduct, payload) => {
        const gatewayType = chosenProduct.gateway
        const productId = chosenProduct._id
        const productMetaData = chosenProduct.meta[gatewayType]

        if(payload.address){
            let _billing = {
                billingAddress: '', billingCurrency: {}
            }

            if(currentUser.billing && Object.keys(currentUser.billing).length) {
                _billing = objectDeepClone(currentUser.billing)
                delete _billing?._id
            }

            let _data = {
                fullName: currentUser.fullName || '',
                email: currentUser.email || '',
                username: currentUser.username || '',
                userImage: currentUser.userImage || '',
                country: currentUser.country || '',
                password: currentUser.password || "",
                billing: {
                    ..._billing,
                    billingAddress: payload.address,
                    billingCurrency: payload.billingCurrency,
                }
            }
            API.updateUserDetails(objectDeepClone(_data))
            .then(response => {
                getUserDetails()
            })
            .catch(error => {
                setLoading(false)
                throwServerError(error)
            })

            console.log(_data);
        }

        let body = {}
        // Creates app in Kong Gateway
        if (gatewayType === GATEWAYS.KONG) {
            let status = "Pending"
            if(multiProducts && multiProducts.length === 1) {
                status = multiProducts[0].meta[gatewayType].appApproval === "Automatic" ? "Approved" : "Pending"
            }

            let productsDto = [], multipleProductIds = []
            for (const it of multiProducts) {
                const metadata = it.meta[gatewayType]
                productsDto.push({
                    productName: metadata.productName,
                    productId: it.productId,
                    services: metadata.services,
                    runtime: metadata.services[0].runtime,
                    workspace: metadata.services[0].workspace,
                })
                multipleProductIds.push(it._id)
            }
            body = {
                status,
                name: payload.appName,
                description: payload.appDescription,
                productsDto: productsDto,
                userEmail: currentUser.email,
                fullName: currentUser.fullName,
                subscriptionId: selectedSubscription.id,
                multipleProductIds: multipleProductIds
            }
        } 
          
        // Creates app in Azure Gateway
        if (gatewayType === GATEWAYS.AZURE) {
            const azureConnectors = currentUser.azureConnectors;
            if (!azureConnectors.length) {
                throwServerError(
                    "Selected Product is not associated to this User!"
                );
                return;
            }
            setBtnLoading(true)
            const azureUserId = azureConnectors.find(
                (connector) => connector.connectorId === productMetaData.connectorId
            )?.azureUserId;

            body = {
                name: payload.appName,
                productName: productMetaData.name,
                ownerId: azureUserId,
                connectorId: productMetaData.connectorId,
                description: payload.appDescription,
                subscriptionId: selectedSubscription.id,
                productId: productId
            }
        }
  
        // Creates app in APIGEE Gateway
        if(gatewayType === GATEWAYS.APIGEE){
            body = {
                apiProducts: [productMetaData.name],
                attributes: [],
                description: payload.appDescription,
                name: payload.appName,
                keyExpiresIn: "-1",
                organization: productMetaData.org,
                productId: productId,
                subscriptionId: selectedSubscription.id
            };
        }

        // Creates app in Dev portal DB
        if(gatewayType === GATEWAYS.NGINX){
            body = {
                productId,
                name: payload.appName,
                description: payload.appDescription,
                connectorId: productMetaData.connectorId,
                productName: productMetaData.name,
                subscriptionId: selectedSubscription.id
            };
        }

        // Creates app in Dev portal DB
        if(gatewayType === GATEWAYS.AWS){
            body = {
                name: payload.appName,
                productName: productMetaData.name,
                description: payload.appDescription,
                connectorId: productMetaData.connectorId,
                usagePlanId: productMetaData.usagePlanId,
                subscriptionId: selectedSubscription.id,
                productId: productId
            };
        }


        // Creates app for axway
        if(gatewayType === GATEWAYS.AXWAY){
            let _chosenProduct = objectDeepClone(chosenProduct)
            _chosenProduct.apis = chosenProduct?.meta?.axway?.apis || []
            body = {
                name: payload.appName,
                phone: currentUser?.billing?.phoneNumber || "",
                description: payload.appDescription,
                organizationId: productMetaData.organizationId,
                email: currentUser.email,
                product: _chosenProduct,
                connectorId:productMetaData.connectorId,
                connectorName:productMetaData.connectorName,
                productId: productId,
            }
        }

        console.log('body', body)

        setBtnLoading(true)
        API.createAppInDb(body, { gatewayType })
        .then(({data: res}) => {
            setBtnLoading(false)
            showSuccessMessage("App created successfully")
            dispatch(resetDeveloperApp());
            navigate("/apps")
        })
        .catch((err) => {
            setBtnLoading(false)
            throwServerError(err)
        });
        return;
    }

    useEffect(() => {
        getData()
        getCurrencies()
        getAllProducts()
    }, []);

    return (
        <div className="w-100vw h-100vh bg-currentBackground">
            <CustomNavbar />

            <div className="my-80px container w-100">
                {loading ? (
                    <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh"/>
                ) : (
                    <>
                        <div className="d-flex align-items-center gap-4px">
                            <Link to="/my-account">
                                <p className="fs-14px text-theme-primary fw-500 cursor">My Account / </p>
                            </Link>
                            <Link to="/apps">
                                <p className="fs-14px text-theme-primary fw-500">My Apps /</p>
                            </Link>
                            <p className="fs-14px text-theme-primary fw-500">Create App</p>
                        </div>

                        <div className="mt-32px d-flex align-items-start mx-auto" style={{ width: "calc(100% - 40%)" }}>
                            <div onClick={() => {
                                setPackages([])
                                setSelectedProduct({})
                                setMultiProducts([])
                                setSelectedSubscription({});
                                setActiveStep(1);
                            }}
                                className={`d-flex flex-column gap-12px align-items-center w-50 middle-line-right ${
                                    activeStep === 1 ? "step1" : ""
                                }`}
                            >
                                <div className="br-8px border-theme-base-200 py-9px px-10px w-fit step2-icon">
                                    <Package size={20} />
                                </div>
                                <div className="cursor d-flex flex-column align-items-center px-3 justify-content-center w-100">
                                    <p className="text-theme-content-color fs-14px fw-600">Select Product</p>
                                    <p className="text-consumer-300 fs-14px fw-400 text-center">
                                        Select products that you want to include in your App
                                    </p>
                                </div>
                            </div>
                            <div onClick={activeStep > 1 ? () => {
                                setPackages([])
                                setSelectedSubscription({});
                                setActiveStep(2);
                            } : null}
                                className={`d-flex flex-column gap-12px align-items-center w-50 ${selectedProduct?.enableMonetization ? "middle-line-right" : ""} ${
                                    activeStep > 1 ? "o-1" : "o-05"
                                }`}
                            >
                                <div className="br-8px border-theme-base-200 py-9px px-10px w-fit">
                                    <ClipboardText size={20} />
                                </div>
                                <div className="cursor d-flex flex-column align-items-center px-3 justify-content-center w-100">
                                    <p className="text-theme-content-color fs-14px fw-600">App Details</p>
                                    <p className="text-consumer-300 fs-14px fw-400 text-center">Please provide App name and description</p>
                                </div>
                            </div>
                            {
                                selectedProduct?.enableMonetization ? (
                                    <div className={`d-flex flex-column gap-12px align-items-center w-50 ${
                                            activeStep === 3 ? "o-1" : "o-05"
                                        }`}
                                    >
                                        <div className="br-8px border-theme-base-200 py-9px px-10px w-fit step2-icon">
                                            <Package size={20} />
                                        </div>
                                        <div className="cursor d-flex flex-column align-items-center justify-content-center w-100">
                                            <p className="text-theme-content-color fs-14px fw-600">Select Subscription</p>
                                            <p className="text-consumer-300 fs-14px px-2 fw-400 text-center">
                                                Select subscription that you want to include in your App
                                            </p>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>

                        { activeStep === 1
                        ? (
                            <div className="d-flex flex-column w-100">
                                <div
                                    className="card-grid-wrapper w-71 gap-20px mt-10px overflow-scroll mx-auto noscrollbar pt-4 v2"
                                    style={{ height: `${selectedProduct?.enableMonetization ? "calc(100vh - 320px)" : "calc(100vh - 300px)"}`}}
                                >
                                    {!productLoader ? (
                                        (productsList && productsList.length > 0) ? (
                                            productsList.map((each) => (<div className={`cursor productCardMonetization ${selectedProduct && Object.keys(selectedProduct).length && selectedProduct?.gateway !== each.gateway ? "o-05 cursor-not-allowed" : "o-1"} 
                                                ${selectedProduct._id === each._id && 'active'} ${multiProducts.length && multiProducts.find((x)=>x._id === each._id) ? "active" : ""}`} key={each.id} onClick={()=>handleProductSelection(each)}>
                                                <p className='fs-24px text-content-color fw-600'>{each.productName || each.name }</p>
                                                <p style={{height: 72}} className='fs-16px mt-14px grey-subtext fw-400 text-truncate-3'>
                                                    {each.description || '-'}
                                                </p>
                                                <div onClick={(e)=>handleProductViewClick(e, each)} className='cursor border-strokes-800 border-1px cursor br-8px py-10px px-16px gap-10px d-flex align-items-center justify-content-center'>
                                                    <p className='fw-600 fs-16px text-theme-content-color'>View Details</p>
                                                </div>
                                            </div>))
                                        ) : (
                                            <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                                <EmptyState />
                                                <p className="ps-5 text-content-color text-center">No Products Found</p>
                                            </div>
                                        )
                                    ) : <ContainerLoader variant="theme-primary" className="w-100 h-100" />}
                                </div>
                            </div>
                        )
                        : activeStep === 2 ? (
                            <>
                                <div className="d-flex flex-column mx-auto overflow-scroll noscrollbar" 
                                style={{ width: "calc(100% - 52%)", height: selectedProduct?.enableMonetization ? "calc(100vh - 310px)" : "calc(100vh - 290px)" }}>
                                    <div className="mb-3 mt-40px">
                                        <p className="fs-14px fw-400 text-content-color">
                                            App Name <span className="text-danger">*</span>
                                        </p>
                                        <input
                                            required
                                            value={payload.appName}
                                            onChange={(e) => handleOnChange("appName", e.target.value)}
                                            placeholder="Enter your App Name"
                                            className="from-control v1-form-control mt-1 v1-form-control-public w-100"
                                        />
                                        {isSubmitClick && !payload.appName ? (
                                            <p className="text-danger fs-10px" style={{marginTop: -6}}>
                                                App Name is Required
                                            </p>
                                        ) : null}
                                    </div>
                                    {!currentUser?.billing?.billingAddress ? (
                                        <>
                                            <div className='d-none mb-4 mainSelect'>
                                                <p className='text-content-color fs-14px fw-400'>Preferred Currency for Billing <span className='text-danger'>*</span></p>
                                                <Select options={currencies?.map((each) => ({...each, label: `${each.currencyName} (${each.currencyCode})`, value: each.code}))} 
                                                    className='mt-1 v1select' 
                                                    value={payload.billingCurrency} 
                                                    onChange={(e) => handleOnChange('billingCurrency', e)}
                                                    classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }} />
                                                {isSubmitClick && !payload.billingCurrency ? (
                                                    <Form.Text className="text-danger fs-10px">
                                                        Billing Currency is Required
                                                    </Form.Text>
                                                ) : null}
                                            </div>
                                            <div className="d-none mb-3">
                                                <p className="text-content-color fs-14px fw-400">
                                                    Billing address <span className="text-danger">*</span>{" "}
                                                </p>
                                                <textarea
                                                    value={payload.address}
                                                    onChange={(e) => handleOnChange("address", e.target.value)}
                                                    rows={3}
                                                    placeholder="Enter your billing address"
                                                    className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
                                                />
                                                {isSubmitClick && !payload.address ? (
                                                    <Form.Text className="text-danger fs-10px">
                                                        Billing Address is Required
                                                    </Form.Text>
                                                ) : null}
                                            </div>
                                        </>
                                    ) : null}
                                    <div className="mb-3">
                                        <p className="text-content-color fs-14px fw-400">
                                            Description<span className="text-danger">*</span>{" "}
                                        </p>
                                        <textarea
                                            value={payload.appDescription} rows={3}
                                            placeholder="Enter your App Description"
                                            className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
                                            onChange={(e) => handleOnChange("appDescription", e.target.value)}
                                        />
                                        {isSubmitClick && !payload.appDescription ? (
                                            <p className="text-danger fs-10px" style={{marginTop: -6}}>Description is Required</p>
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        ) :
                        activeStep === 3 ? (
                            <div
                                className="w-60 mx-auto mt-10px overflow-scroll noscrollbar pt-4"
                                style={{ height: "calc(100vh - 320px)"}}
                            >
                                {subLoading ? <Loading />
                                : (packages && packages.length > 0
                                    ? <div className='w-100 h-100'>
                                        <div className='px-8px pt-10px pb-18px dfaic gap-14px cursor'
                                            onClick={() => setPackages([])}
                                            style={{borderBottom: '0.5px solid var(--content-color)'}}>
                                            <CaretLeft size={24} className='text-consumer-50' />
                                            <p className='fs-16px fw-500 text-consumer-200'>{selectedSubscription.subscriptionName}</p>
                                        </div>
                                        <div className='mt-2 overflow-scroll noscrollbar'
                                            style={{height: `calc(100% - 60px)`}}>
                                            {packages.length === 0
                                            ? <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                                <EmptyState />
                                                <p className="ps-5 text-content-color text-center">No Packages Found</p>
                                            </div>
                                            : packages.map((each, i) => <div className={`cursor dfaic pl-8px pr-14px pt-14px pb-14px`}
                                                key={each.id}>
                                                <div className='w-100 d-flex align-items-center justify-content-between gap-12px show-on-hover'>
                                                    <div className='dfaic gap-12px'>
                                                        <Package size={24} className='text-consumer-300' />
                                                        <div>
                                                            <p className='fs-14px text-consumer-200 fw-500'>{each.packageName}</p>
                                                            <p className='mt-6px fs-12px fw-400 text-consumer-200'>
                                                                {each.packageDescription}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Link className='hoverItem' target='_blank' to={`/monetization/packages/${each.packageId}`}>
                                                        <ArrowSquareOut size={14} color="var(--content-subtle)" className="cursor-pointer" />
                                                    </Link>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                    : <div className='w-100'>
                                        {subscriptions.length === 0
                                        ? <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                            <EmptyState />
                                            <p className="ps-5 text-content-color text-center">No Subscriptions Found</p>
                                        </div>
                                        : subscriptions.map((each) => 
                                            <div className={`cursor show-on-hover dfaic justify-content-between p-8px mb-16px w-100 subscribedPackages ${each.id === selectedSubscription.id ? "active": ""}`}
                                                onClick={() => handleSelect(each)}>
                                                <div className={`dfaic gap-12px w-100`}>
                                                    <FolderNotch size={24} className='text-consumer-300' />
                                                    <div>
                                                        <p className='fs-14px text-consumer-200 fw-500'>{each.subscriptionName}</p>
                                                        <p className='mt-6px fs-12px fw-400 text-consumer-200'>
                                                            {each.subscriptionDescription}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='hoverItem'>
                                                    <CaretRight size={20} className='text-consumer-300' />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : null}

                        <div
                            onClick={handleProceed}
                            style={{width: "calc(100vw - 50%)"}}
                            className="cursor mx-auto br-8px mx-auto p-10px bg-theme-primary fw-600 text-theme-base fs-16px text-center"
                        >
                            {btnLoading ? "Loading...": 
                            (!selectedProduct?.enableMonetization && activeStep === 2) || (activeStep === 3) ? 
                            "Create App" : "Proceed"}
                        </div>
                    </>
                )}
            </div>

            {productDetails.isOpen ? (
                <ProductModal
                    show={productDetails.isOpen}
                    data={productDetails.data}
                    onHide={()=> setProductDetails(prev => ({...prev, isOpen: false}))}
                />
            ) : null}
        </div>
    );
};

export default CreateApp;
