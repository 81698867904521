import React from 'react'

export default function ComponentsPage() {
    return (
        <div className='p-5'>
            <table class="table table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Information</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Styling Library</td>
                        <td colSpan={2}>Bootstrap</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Chart Library</td>
                        <td colSpan={2}>Chartjs</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
