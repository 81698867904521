import React, { useEffect, useRef, useState } from "react";

let captcha_value = "";
let captcha_number = "";
let backgroundColor_value = "";
let fontColor_value = "";
let charMap_value = "";

export const LoadCaptchaEngine = ({
  characterCount,
  backgroundColor = "white",
  fontColor = "black",
  charMap = "",
  }) => {
  backgroundColor_value = backgroundColor;
  fontColor_value = fontColor;
  captcha_number = characterCount;
  charMap_value = charMap;

  let captchaResult = "";
  let charSet =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  if (charMap === "upper") {
    charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  } else if (charMap === "numbers") {
    charSet = "0123456789";
  } else if (charMap === "special_char") {
    charSet = "~`!@#$%^&*()_+-=[]{}|:'<>,.?/";
  }

  let length = parseInt(characterCount);

  for (let i = 0, n = charSet.length; i < length; ++i) {
    captchaResult += charSet.charAt(Math.floor(Math.random() * n));
  }


  let captcha = captchaResult;

  captcha_value = captcha;

  let canvas = document.getElementById("captcha-text-canvas");

  let y = 70;


  const newCanvas = document.createElement("canvas")
  let ctx = newCanvas.getContext("2d")
  newCanvas.setAttribute("id", "captcha-text-canvas")

  ctx.fillStyle = "#F4F5F5";
  const width = 270
  const height = 42

  ctx.clearRect(0, 0, width, height);
  ctx.fillRect(0, 0, width, height);

  ctx.textBaseline = "middle";
  ctx.font = "italic 40px Arial";
  ctx.fillStyle = fontColor;
  ctx.textAlign = "start";

  let num = 0;
  for (let i = 0; i < length; i++) {
    let nextLetterStart = 30 * num;
    num++;

    ctx.fillText(captchaResult[i], nextLetterStart + 10, y);
  }

  canvas.parentNode.replaceChild(newCanvas, canvas)

};

export const CanvasTemplate = ({
  matched,
  reloadColor,
  characterCount,
  children,
}) => {
  const ref = useRef();

  useEffect(() => {
    LoadCaptchaEngine({ characterCount });
  }, []);

  return (
    <div>
      <canvas id="captcha-text-canvas" ref={ref} />
      <div className="captcha-reload-btn__container">
        {!matched && <a
          id="captcha-reload-text"
          style={{ color: reloadColor ? reloadColor : "blue" }}
          onClick={() => LoadCaptchaEngine({ characterCount })}
        >
          {children}
        </a>}
      </div>
    </div>
  );
};

export const validateCaptcha = (userValue, reload = true) => {
  if (userValue != captcha_value) {
    if (reload == true) {
    }

    return false;
  } else {
    return true;
  }
};

export const LoadCanvasTemplate = ({
  reloadText,
  reloadColor,
  characterCount,
  children,
  matched
}) => {
  let reload_text = "";
  let reload_color = "";

  if (reloadText) {
    reload_text = reloadText;
    // setReloadText(reloadText);
  }

  if (reloadColor) {
    reload_color = reloadColor;
    // setReloadColor(reloadColor);
  }

  const canvasRef = useRef();

  return (
    <CanvasTemplate
      matched={matched}
      reloadText={reload_text}
      reloadColor={reload_color}
      characterCount={characterCount}
    >
      {children}
    </CanvasTemplate>
  );
};
