import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '../../context/ThemeContext'
import { Eye, EyeSlash } from 'phosphor-react'
import { useDispatch } from 'react-redux'
import API from '../../api'
import { encryptPassword, throwServerError, validateEmail } from '../../utils/helper'
import { resetAuthenticatedState } from '../../app/Features/Users/UserSlice'
import { getToast } from '../../components/Toast'
import { Link } from 'react-router-dom'

export default function ForgotPassword() {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const {theme} = useTheme()

    const [email, setEmail] = useState('')

    const [isDisabled, setIsDisabled] = useState(false)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        
        let temp = {}
        if (!email.trim().length){
            temp['email'] = 'Email is Required'
        }
        else if (!validateEmail(email)){
            temp['email'] = 'Email format is Invalid'
        }

        setErrors(temp)
        if (Object.keys(temp).length) return

        setIsDisabled(true)
        let submitObj = {
            email
        }

        if (window.location.pathname === '/resend-token'){
            API.resendToken(submitObj)
            .then(res => {
                setEmail('')
                getToast({
                    statusType: 'SUCCESS', message: 'Please check mail...'
                })
                setIsDisabled(false)
            }).catch(err => {
                throwServerError(err)
                dispatch(resetAuthenticatedState())
                setIsDisabled(false)
            })
        }
        else {
            API.forgotPassword(submitObj)
            .then(res => {
                setEmail('')
                getToast({
                    statusType: 'SUCCESS', message: 'Please check mail...'
                })
                setIsDisabled(false)
            }).catch(err => {
                console.log('err', err)
                throwServerError(err)
                dispatch(resetAuthenticatedState())
                setIsDisabled(false)
            })
        }

    }

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            <form className='loginContainer h-100' onSubmit={handleSubmit}>
                <div className='mb-20px'>
                    <img src={theme === 'theme--light' ? currentLayoutObject.logo : currentLayoutObject.darklogo}
                        alt='Logo' height={50} className='d-block mx-auto'
                        style={{objectFit: 'contain'}} />
                </div>

                <div className='mb-32px'>
                    <h3 className='titleText'>{
                        window.location.pathname === '/resend-token'
                        ? 'Resend Token'
                        : 'Recover password'
                    }</h3>
                    <p className='descText'>
                        Instructions will be sent to you.
                    </p>
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Email<span className='text-danger'>*</span></p>
                    <input type='text' className='form-control v1-form-control mt-1' value={email}
                        placeholder='Enter your email' onChange={(e) => {
                            let _str = e.target.value
                            setEmail(_str)
                            setErrors({...errors, email: _str.trim().length < 1 ? 'Email is Required' : false})
                        }} />
                    {errors.email && <p className='text-danger fs-12px mt-1'>
                        {errors.email}
                    </p>}
                </div>

                <button disabled={isDisabled} className='border-0 gradientBtnInAuth mb-28px'>
                    Send Reset Link
                </button>

                {<div  className='dontHaveAnAccount'>
                    <Link to={`/login`}>
                        Remember your password? <span>Sign In</span>
                    </Link>
                </div>}
            </form>
        </div>
    )
}
