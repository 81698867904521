import React, { useState, useRef, useEffect } from 'react';
import { CalendarPlus , CaretDown, CaretUp} from 'phosphor-react'
import Dropdown from '../../../../../components/Dropdown';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
// import { DateRange } from 'react-date-range';
import DatePicker from "react-datepicker";
const CustomDateRange = ({setLoading, setRawLogsData, setFilterUsersList, getActivityLogData, startDate, setStartDate, endDate, setEndDate, setSelectedDropdownrange, selectedDropdownRange, sevenDaysBack, thirtyDaysBack, dropdownList}) => {
    const menuRef = useRef(null);
    const calendarRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [showDatePicker , setShowDatePicker] = useState(false)
    const currentDate = new Date()


    useOutsideClick(menuRef, () => {
        setShowMenu(false)
    });
    useOutsideClick(calendarRef, () => {
        setShowDatePicker(false)
    });

    

    useEffect(()=>{
        setShowMenu(false)
        switch(selectedDropdownRange){
            case 'Today':
                setStartDate(new Date())
                setEndDate(new Date())
                setLoading(true)
                setFilterUsersList([])
                setRawLogsData([])
                let apiParams = {start: new Date(), currentPage: 1, usersList: [] }
                getActivityLogData(apiParams)
                break;

            case 'Past 7 days':
                console.log('7 days change')
                setStartDate(sevenDaysBack)
                setEndDate(new Date())
                setFilterUsersList([])
                setRawLogsData([])
                getActivityLogData({start: sevenDaysBack, end: new Date(), currentPage: 1, usersList: [] })

                break;

            case 'Past 30 days':
                setStartDate(thirtyDaysBack)
                setEndDate(new Date())
                setFilterUsersList([])
                setRawLogsData([])
                getActivityLogData({start: thirtyDaysBack, end: new Date(), currentPage: 1, usersList: [] })
                break;
            default:
                setShowDatePicker(true)
                break;
        }

    },[selectedDropdownRange])

    const formatDateObj = (inputDate) => {
        const dateArr =  inputDate.toLocaleDateString('en-us',{ day: "2-digit", month: "short", year: "numeric" }).replace(',','').split(" ")
        return dateArr[1].concat(` ${dateArr[0]}`).concat(` ${dateArr[2]}`)
    }


    
    return (
        <>
             <div className='d-flex cursor-pointer position-relative'>
                <div onClick={()=>setShowMenu(true)} 
                className={`d-flex position-relative w-max px-6px surface-850  br-tl-4px br-bl-4px v1-border-1pt5 d-flex justify-content-center align-items-center gap-6px`}>
                    <CalendarPlus className='color-800' size={16} />
                    <p className='fs-14px'>{selectedDropdownRange}</p>
                    {!showMenu 
                    ? <CaretDown size={16} />
                    : <CaretUp size={16} />}
                   {showMenu && <Dropdown list={dropdownList} menuRef={menuRef} setSelected={setSelectedDropdownrange} styles={'w-max h-max pos-calendardd surface-850 w-fit v1-border-1 p-3px'} />}
                </div> 
                <div onClick={()=>setShowDatePicker(true)} style={{borderLeft: "none"}} className='surface-850 w-max px-6px fs-14px h-36px br-br-4px br-tr-4px v1-border-1pt5 d-flex align-items-center justify-content-center gap-6px'>
                    <p>{formatDateObj(startDate)}</p>
                    {(endDate && endDate.toDateString() != startDate.toDateString()) && <>
                        <p className='color-800'>to</p>
                    <p>{formatDateObj(endDate)}</p>
                    </>}
                 
                </div> 
                <div className='position-absolute pos-picker zIndex-100' ref={calendarRef}>
                {showDatePicker && 
                <DatePicker 
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    maxDate={Date.now()}
                    inline
                    onChange={(dates) => {
                        let [start, end] = dates;
                        // if(!end){
                        //     end = start
                        // }
                        if(start === currentDate && end === currentDate){
                            setSelectedDropdownrange('Today')
                        }
                        else if(start === sevenDaysBack && end === currentDate){
                            setSelectedDropdownrange('Past 7 days')
                        }else if(start === sevenDaysBack && end === currentDate){
                            setSelectedDropdownrange('Past 30 days')                            
                        }else{
                            setSelectedDropdownrange('Custom Range')                            
                            setStartDate(start);
                            setEndDate(end);  
                            setLoading(true)
                            setFilterUsersList([])
                            let apiParams = {start, end, currentPage: 1, usersList: [] }
                            // if(start !== end){
                            //     apiParams.end = end
                            // }
                            setRawLogsData([])
                            getActivityLogData(apiParams)  
                        }
                      }}
                     />}
                </div>
            </div>
        </>
    );
};

export default CustomDateRange;