import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useSelector } from "react-redux";
import GUIDE_CATEGORY_API from '../../../api/guideCategory';
import { confirm, throwServerError } from "../../../utils/helper";
import { objectDeepClone } from "@apiwiz/oas/services";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { getToast } from "../../../components/Toast";
import Loading from "../../../components/Loader/Loading";
import API from "../../../api";
import monetizationAPI from "../../../api/monetization";
import { GATEWAYS, PRODUCTS_MAPPER_DISPLAY_NAME, PRODUCTS_MAPPER_KEY } from "../../../service/constants";
import { startCase } from "lodash";

const AWS_GATEWAYS = GATEWAYS.AWS

export default function AddOrUpdateModal(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [categoryData, setCategoryData] = useState([])
  const {currentUser} = useSelector(state => state.user)
  const [errors, setErrors] = useState({})

  const [data, setData] = useState({
    category: '', deprecated: false, description: '', name: '', owner: {
      email: '', name: ''
    }, product: "", productBundle: "", status: "draft"
  })

  const [productBundles, setProductBundles] = useState({});
  const [products, setProducts] = useState({});

  useEffect(() => {
    getCategories()

    if (props.isEdit){
      let _data = objectDeepClone(props.data)
      setData({
        ..._data,
        owner: { email: currentUser.email, name: currentUser.fullName },
      })
    } else {
      setData({
        ...data, owner: { email: currentUser.email, name: currentUser.fullName }
      })
    }
    getAllProducts();
    getAllProductBundles();
  }, []);


  const getAllProducts = () => {
    monetizationAPI
      .getAllProducts()
      .then((res) => {
        if (res?.data?.data) {
          let _data = (res?.data?.data || []).map(el => {
            if(el.type === GATEWAYS.NGINX)
            {
              return {
                label: el[PRODUCTS_MAPPER_DISPLAY_NAME.NGINX],
                value: el[PRODUCTS_MAPPER_KEY.NGINX],
                type: el.type
              }
            }
            if(el.type === AWS_GATEWAYS)
            {
              return {
                label: el[PRODUCTS_MAPPER_DISPLAY_NAME.AWS],
                value: el[PRODUCTS_MAPPER_KEY.AWS],
                type: el.type
              }
            }
            return {
              label: el[PRODUCTS_MAPPER_DISPLAY_NAME.APIGEE] || el[PRODUCTS_MAPPER_DISPLAY_NAME.AZURE] || el[PRODUCTS_MAPPER_DISPLAY_NAME.KONG],
              value: el[PRODUCTS_MAPPER_KEY.KONG] || el[PRODUCTS_MAPPER_KEY.AZURE] || el[PRODUCTS_MAPPER_KEY.APIGEE],
              type: el.type,
            }
          }).reduce((acc, curr) => (acc[curr.value] = curr, acc), {})
          setProducts(_data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setProducts([]);
      });
  };

  const getAllProductBundles = () => {
    monetizationAPI
      .getAllProductBundles()
      .then((res) => {
        if (res?.data?.data) {
          let _data = (res?.data?.data || []).map(el => {
            let pb = el.productBundle || {};
            return {
              label: pb.name,
              value: pb[PRODUCTS_MAPPER_KEY.APIGEE_BUNDLE]
            }
          }).reduce((acc, curr) => (acc[curr.value] = curr, acc), {})
          setProductBundles(_data);
        }
      })
      .catch((err) => {
        setProductBundles([]);
      });
  };

  /*
  const getAppData = (value) => {
		setIsLoading(true);
		API.getProductBundles(value)
			.then((response) => {

				if (response?.data?.data?.response) {
					setBundleOptions(response?.data?.data?.response);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setBundleOptions([]);
        throwServerError(err)
				setIsLoading(false);
			});
	};
  */
  const getCategories = () => {
    GUIDE_CATEGORY_API.getAllGuideCategory()
    .then((response) => {
      setIsLoading(false);
      if (response.data.data) {
        let _res = objectDeepClone(response.data.data)
        setCategoryData(_res.map(o => ({label: o.categoryname, value: o.categoryname})))
      } else {
        setCategoryData([])
      }
    })
    .catch((error) => {
        setIsLoading(false);
        throwServerError(error)
        setCategoryData([])
    });
  }

  const statusOptions = [
    {label: 'Draft', value: 'draft'}, {label: 'Published', value: 'published'}
  ]

  const handleDelete = () => {
    if (!confirm("Are you sure to delete selected guide")) return
    
    GUIDE_CATEGORY_API.deleteGuide(props.data._id)
    .then(res => {
      getToast({
        statusType: 'SUCCESS', message: 'Guide deleted successfully'
      })
      props.onHide()
      props.callback()
    }).catch(err => {
      throwServerError(err)
    })
  }

  const cleanExistingData = (data) => {
    delete data._id;
    delete data._rowClassName;
    delete data.created;
    delete data.createdAt;
    delete data.createdBy;
    delete data.details;
    delete data.updatedAt;
    delete data.updatedBy;

  }

  const handleSave = () => {
    let tempErrors = {}

    if (!data.name){
      tempErrors['name'] = 'Guide Name is required'
    }
    if (!data.category){
      tempErrors['category'] = "Category name is required"
    }
    if (!data.description){
      tempErrors['description'] = "Description is required"
    }

    setErrors(tempErrors)

    if (Object.keys(tempErrors).length) return

    setIsLoading(true)
    let json = objectDeepClone(data)
    
    if (props.isEdit){
      cleanExistingData(json);
      GUIDE_CATEGORY_API.updateGuide(json, props.data._id)
      .then(res => {
        getToast({
          statusType: 'SUCCESS', message: 'Guide updated successfully'
        })
        props.onHide()
        props.callback()
        setIsLoading(false)
      }).catch(err => {
        throwServerError(err)
        setIsLoading(false)
      })
    } else {
      GUIDE_CATEGORY_API.createGuide(json)
      .then(res => {
        getToast({
          statusType: 'SUCCESS', message: 'Guide created successfully'
        })
        setIsLoading(false)
        props.onHide()
        props.callback()
      }).catch(err => {
        throwServerError(err)
        setIsLoading(false)
      })
    }
  }

  const getProductsOptions = () => {
    let options = [];
    let _productBundles = Object.values(productBundles)
    if(_productBundles.length)
    {
      options.push({__header: true, label: "Apigee Product Bundles"})
      options.push(..._productBundles)
    }

    let map = {};
    let _products = Object.values(products);
    for(let p of _products)
    {
      if(!map[p.type]) map[p.type] = [];
      map[p.type].push(p)
    }

    for(let type in map)
    {
      let _label = '';
      if(type === AWS_GATEWAYS) _label = `${type} Products`;
      else _label = `${startCase(type)} Products`
      options.push({__header: true, label: _label})
      options.push(...(map[type] || []))
    }

    return options;
  }

  const [isCtrl, setIsCtrl] = useState(false)
  return (
    <Modal
      {...props}
      size="md me-0 mt-0 mb-0"
      contentClassName="br-0px"
      className="custom-modal-v1 v1--styles"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-3 br-0px">
        <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <p className="mb-0 text-300 fw-500 fs-16px">{props.isEdit ? "Update" : "Add"} Guide</p>
          </div>

          <div className="d-flex align-items-center">
            <div
              onClick={props.onHide}
              className="text-300 fw-400 fs-14px mr-14px cursor-pointer"
            >
              Cancel
            </div>
            { isLoading
            ? <Loading size='sm' />
            : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3' onClick={handleSave}>
              Save
            </div>}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar"
      >
        <div onKeyDown={(e) => {
          setIsCtrl(['Control', 'Meta'].includes(e.key))
          if (isCtrl && e.key?.toLowerCase() === 's') {
              handleSave()
              e.preventDefault()
          }
        }}>
          <div className="mb-3">
            <p className="fw-400 fs-12px text-300">Category <span className="text-danger">*</span></p>

            <Select className="v1select mt-1" placeholder='Select Category' 
              classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
              options={categoryData} value={{label: data.category, value: data.category}}
              onChange={(e) => {
                setData({...data, category: e.value})
                setErrors({...errors, category: false})
              }} />

            {errors.category && <p className="text-danger fs-12px mt-1">
              {errors.category}
            </p>}
          </div>

          <div className="mb-3">
            <p className="fw-400 fs-12px text-300">Name <span className="text-danger">*</span></p>

            <input className="v1-form-control form-control mt-1" value={data.name}
              onChange={(e) => {
                let _str = e.target.value 
                setData({...data, name: _str})
                setErrors({...errors, name: _str.length == 0 ? 'Guide Name is required' : false})
              }} />
            
            {errors.name && <p className="text-danger fs-12px mt-1">
              {errors.name}
            </p>}
          </div>

          <div className="mb-3">
            <p className="fw-400 fs-12px text-300">Status <span className="text-danger">*</span></p>

            <Select className="v1select mt-1" placeholder='Select Status' 
              classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
              options={statusOptions} 
              value={{value: data.status, label: data.status === 'published' ? 'Published' : 'Draft'}}
              onChange={(e) => setData({...data, status: e.value})} />
          </div>

          <div className="mb-3 d-none">
            <p className="fw-400 fs-12px text-300">Products</p>

            <Select className="v1select mt-1" 
              classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
              options={getProductsOptions()}
              value={null}
              placeholder={productBundles[data.product]?.label || products[data.product]?.label || `Select Products`} 
              formatOptionLabel={(inherit) => inherit.__header ? (
                  <div className="px-8px py-12px d-flex align-items-center surface-850" style={{margin: '-8px -12px'}}>
                    {inherit.label}
                  </div>
                ) : (
                  <div onClick={() => setData({...data, product: inherit.value})}
                  className="cursor p-2-8 gap-8px d-flex align-items-center justify-content-between br-3 mt-4px"
                  style={{ padding: "2px 8px" }}
                >
                  <div className="d-flex flex-column gap-4px">
                    <div
                      className="fw-500 fs-14px"
                    >
                      {inherit.label}
                    </div>
                  </div>
                </div>
                )
              }/>
          </div>

          <div className="mb-3">
            <p className="fw-400 fs-12px text-300">Description <span className="text-danger">*</span></p>

            <textarea rows={4} className="v1-form-textarea form-control mt-1" value={data.description}
              onChange={(e) => {
                let _str = e.target.value
                setData({...data, description: _str})
                setErrors({...errors, description: _str.length == 0 ? 'Description is required' : false})
              }} />
            
            {errors.description && <p className="text-danger fs-12px mt-1">
              {errors.description}
            </p>}
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="cursor w-fit d-flex align-items-center" onClick={() => setData({
                ...data, deprecated: !data.deprecated
              })}>
                <GlobalIcons type={data.deprecated ? 'form-check' : 'form-uncheck'} />
                <p className="fs-14px fw-400 text-100 ml-4px">Deprecate</p>
              </div>
            </div>
          </div>
        </div>

        {(props.isEdit && props.getDeletePermission) && <div className='btn fs-12px text-danger br-4px' onClick={handleDelete}
          style={{position: 'absolute', bottom: 20, left: 20, border: '1px solid #e5484d'}}>
          Delete
        </div>}
      </Modal.Body>
    </Modal>
  );
}
