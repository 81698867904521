import React from 'react'
import CustomNavbar from '../../../Dashboards/components/CustomNavbar'
import RandomSvgComponent from '../packages/RandomSvgComponent'
import { useBlocker, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getPermissionByCrud } from '../../../../utils/helper'

export default function Account() {
    const { permission, currentUser, monetizationEnabled } = useSelector(state => state.user)

    /** ACL */
    let subscription = permission.filter((o) => o.name === "subscription");
    subscription = subscription.length ? subscription[0].crud || [] : [];
    const listSubs = getPermissionByCrud(currentUser, subscription, "read");
    /** ACL */

    return (
        <div className='w-100vw bg-currentBackground h-100vh'>
            <CustomNavbar />

            <div className='mt-80px'>
                <div className='container' style={{maxWidth: 1000}}>
                    <p className='fs-28px fw-600 text-content-color text-center'>Your Account</p>

                    <div className='dfaic justify-content-start flex-wrap gap-30px mt-4'>
                        {listSubs ? (
                            monetizationEnabled 
                            ? <Link to={'/monetization/subscriptions'}>
                                <div className='myAccountCard'>
                                    <RandomSvgComponent index='subscription' />

                                <p className='text-content-color fs-18px fw-500 mt-3'>Subscriptions</p>

                                    <p className='fs-14px fw-500 grey-subtext my-1'>
                                        Manage packages and purchases
                                    </p>
                                </div>
                            </Link>
                            : <div className='myAccountCard o-05' style={{cursor: 'not-allowed'}}
                                title='Monetization Not Enabled'>
                                <RandomSvgComponent index='subscription' />

                                <p className='text-content-color fs-18px fw-500 mt-3'>Subscriptions</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Manage packages and purchases
                                </p>
                            </div>
                        ) : null}

                        <Link to={'/apps'}>
                            <div className='myAccountCard'>
                                <RandomSvgComponent index='myApps' />

                                <p className='text-content-color fs-18px fw-500 mt-3'>My Apps</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Create and Manage Apps that has been created
                                </p>
                            </div>
                        </Link>

                        {/* <Link to={'/apps'}>
                            <div className='myAccountCard'>
                                <RandomSvgComponent index='myApps' />

                                <p className='text-content-color fs-18px fw-500 mt-3'>My Apps from Products</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Create and Manage Apps created from Products
                                </p>
                            </div>
                        </Link> */}
                        
                        {monetizationEnabled 
                        ? <Link to='/my-account/profile'>
                            <div className='myAccountCard'>
                                <RandomSvgComponent index='loginSecurity' />

                                <p className='text-content-color fs-18px fw-500 mt-2'>Login and Security</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Manage login,name and Mobile number
                                </p>
                            </div>
                        </Link>
                        : <Link to='/admin/profile-page'>
                            <div className='myAccountCard'>
                                <RandomSvgComponent index='loginSecurity' />

                                <p className='text-content-color fs-18px fw-500 mt-2'>Login and Security</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Manage login,name and Mobile number
                                </p>
                            </div>
                        </Link>}

                        {monetizationEnabled ? <Link to='/my-invoices'>
                            <div className='myAccountCard'>
                                <RandomSvgComponent index='invoices' />

                                <p className='text-content-color fs-18px fw-500 mt-2'>Invoices</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Get Information about Invoices and Billing
                                </p>
                            </div>
                        </Link> : <div className='myAccountCard o-05' style={{cursor: 'not-allowed'}}
                                title='Monetization Not Enabled'>
                            <RandomSvgComponent index='invoices' />

                            <p className='text-content-color fs-18px fw-500 mt-2'>Invoices</p>

                            <p className='fs-14px fw-500 grey-subtext my-1'>
                                Get Information about Invoices and Billing
                            </p>
                        </div>}

                        <Link to='/contact-us'>
                            <div className='myAccountCard'>
                                <RandomSvgComponent index='contactUs' />

                                <p className='text-content-color fs-18px fw-500 mt-2'>Contact Us</p>

                                <p className='fs-14px fw-500 grey-subtext my-1'>
                                    Contact APIwiz for any enquiry
                                </p>
                            </div>
                        </Link>

                        <div className='myAccountCard border-0' style={{cursor: 'default'}}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
