import { useNavigate } from "react-router";
import Loading from "../../../../components/Loader/Loading";

export const MobileSearchOverlay = ({
  searchText,
  setSearchText,
  setSuggestions,
  setSuggestionloading,
  onSearchChange,
  setShowSearchSuggestions,
  onClearSearch,
  suggestionLoading,
  suggestions,
}) => {
  const navigate = useNavigate();

  return (
    <div className={`mobile-search-overlay__container py-12px px-16px`}>
      <div className="d-flex align-items-center gap-16px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="close-search-icon"
          onClick={(evt) => {
            evt.stopPropagation();
            onClearSearch();
          }}
        >
          <g clip-path="url(#clip0_1429_102882)">
            <path
              d="M20.25 12H3.75"
              stroke="var(--content-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 5.25L3.75 12L10.5 18.75"
              stroke="var(--content-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1429_102882">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div className="forum-search-input__container-mobile">
          <input
            className="forum-search-input"
            autoFocus
            value={searchText}
            onChange={(evt) => {
              setSearchText(evt.target.value);
              setSuggestions([]);
              if (evt.target.value.length > 0) {
                setSuggestionloading(true);
                setShowSearchSuggestions(true);
                onSearchChange(evt.target.value);
              } else {
                setShowSearchSuggestions(false);
                setSuggestions([]);
                setSuggestionloading(false);
              }
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            className="close-search-icon"
            onClick={(evt) => {
              evt.stopPropagation();
              onClearSearch();
            }}
          >
            <path
              d="M10.9375 3.0625L3.0625 10.9375"
              stroke="#785C8A"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.9375 10.9375L3.0625 3.0625"
              stroke="#785C8A"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="search-suggestion-dropdown">
        {suggestionLoading && <Loading />}
        {!suggestionLoading &&
          suggestions &&
          suggestions.map((suggestion) => (
            <div
              className="suggestion-item"
              data-value={suggestion.title}
              key={suggestion._id}
              onClick={(evt) => {
                evt.stopPropagation();
                /**
                 * Suggestion id is real post id
                 */
                navigate(`/forum/post/${suggestion._id}`);
                onClearSearch();
              }}
            >
              {suggestion.title}
            </div>
          ))}
        {!suggestionLoading &&
          suggestions &&
          suggestions.length === 0 &&
          searchText.length > 0 && (
            <p className="no-results">Search results not found</p>
          )}
      </div>
    </div>
  );
};
