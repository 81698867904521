import { useNavigate } from "react-router";
import { NotFoundIcon } from "./Components/NotFoundIcon";

export const NotFoundPage = () => {
    const navigate = useNavigate()
  return (
    <div className="page-404__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="118"
        height="99"
        viewBox="0 0 118 99"
        fill="none"
        className="not-found__top-right"
      >
        <path
          d="M114.551 98.5361C109.677 98.5361 104.805 97.2801 100.436 94.7695L14.3295 45.2761C5.54016 40.2241 0.120117 30.8588 0.120117 20.7201V0.000136082H117.667V98.3641C116.632 98.4788 115.591 98.5361 114.551 98.5361Z"
          fill="var(--base-200)"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="167"
        height="92"
        viewBox="0 0 167 92"
        fill="none"
        className="not-found__bottom-left"
      >
        <path
          d="M166.424 91.333H0V25.8997L37.8797 4.12621C42.2495 1.61421 47.1219 0.358475 51.9943 0.358475C56.8672 0.358475 61.7396 1.61421 66.1093 4.12621L152.215 53.6185C161.005 58.6715 166.424 68.0357 166.424 78.1742V91.333Z"
          fill="var(--base-200)"
        />
      </svg>
      <div className="content">
        <NotFoundIcon />
        <h1 className="title">Page Not Found</h1>
        <p className="desc">
        Uh-oh!, coding maestro!! Seems like you've stumbled upon a URL that doesn't exist.
        </p>
        <button className="cta-btn" onClick={() => {
            navigate("/")
        }}>GO HOME</button>
      </div>
    </div>
  );
};
