import { objectDeepClone } from '@apiwiz/oas/services';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Loading from '../../../../components/Loader/Loading';
import { getToast } from '../../../../components/Toast';
import API from '../../../../api';
import { throwServerError } from '../../../../utils/helper';
import { DotsSixVertical } from 'phosphor-react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function ReOrderModal(props) {
    const [data, setData] = useState([])
    const [dragStart, setDragStart] = useState(false)
    const [parentId, setParentId] = useState('')
    const [disableSave, setDisableSave] = useState(false)

    useEffect(() => {
        let pageList = []

        let guideDetails = objectDeepClone(props.guideDetails)

        let pages = objectDeepClone(guideDetails.version.pages)

        pages = Object.keys(pages).map(key => {
            return pages[key]
        })
        pages.forEach(el => {
            const found = el.pages.find(v => v.page === props.data.id)
            if(found) {
                setParentId(el.id)
                const parentPage = objectDeepClone(guideDetails.version.pages[el.id])
                parentPage.pages.forEach(p => {
                    const page = pages.find(pg => pg.id === p.page && pg.id !== 'x-static-document')
                    if (page) {
                        const json = {
                            title: page.title,
                            id: page.id,
                            document: page.document
                        }
                        pageList.push(json)
                    }
                })
            }
        })
        setData(pageList)
        console.log('pageList', pageList)
    }, [])

    const handleDrop = (droppedItem) => {

        if (!droppedItem.destination) return;
        setDragStart(false)

        var updatedList = objectDeepClone(data);
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setData(objectDeepClone(updatedList))

        console.log('data', data, droppedItem)
        let newIndex = droppedItem.destination.index - 1
        let keyName = droppedItem.destination.index === data.length - 1 ? 'after' : 'before'
        let page = newIndex === data.length - 1 
            ? data[data.length - 2] 
            : data[newIndex + 1]

        let payload = {
            parent: parentId,
        }

        payload[keyName] = page.id
        API.reorderPage(props.guideDetails?.guide?._id, 
            props.guideDetails?.version?._id, props.data.id, payload)
        .then(response => {
            getToast({
                statusType: 'SUCCESS', message: 'Order changed successfully'
            })
            props.callback()
            props.onHide()
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Reorder pages</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div>
                    <div className='mb-3'>
                        <p className='fs-11px text-300 fw-400'>
                            List of pages
                        </p>

                        <p className='fs-10px text-300 fw-400 fs-italic'>
                            Please note the changes are auto saved on drag end.
                        </p>
                    </div>

                    <DragDropContext onDragEnd={handleDrop} onDragStart={() => setDragStart(true)}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {data.map((each, i) => 
                                    <Draggable key={each.id} draggableId={`section-${each.title}-${each.id}`} 
                                        index={i}>
                                        {(provided) => (
                                        <div key={each.id} className='v1-border-1 px-4 py-3 cursor dfaic' ref={provided.innerRef} 
                                            {...provided.dragHandleProps} {...provided.draggableProps}>
                                            <DotsSixVertical className='me-3' color='white' />
                                            <p className='fs-12px text-200 fw-500'>{each.title}</p>
                                        </div>)}
                                    </Draggable>
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Modal.Body>
        </Modal>
    )
}
