import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hideSideNavbar: false
};

export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setHideSideNavbar(state, action){
      state.hideSideNavbar = action.payload;
    },
  },
});

export const { setHideSideNavbar } = AppSlice.actions;
export default AppSlice.reducer;
