import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { validateAllowedEmailDomains } from '../../../../utils/validators';
import { objectDeepClone, throwServerError } from '../../../../utils/helper';
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { getUserGroupOptions,partnerIdToSelectValues,getFirstLetterOfString} from '../../../../utils/helper';
import { formatCtsTime,formatMtsDate,formatCtsDate } from '../../../../utils/helper';

export default function EditUserModal(props) {
    const [email,setEmail] = useState("");
    const [touched,setTouched] = useState(false);
    const [name,setName] = useState("");
    const [username,setUsername] = useState("")
    const [role,setRole] = useState()
    const [group,setGroups] = useState([])
    const [userCompany,setUserCompany] = useState([])
    const { partnersList: partners,roles, companiesList} = useSelector((state) => state.user);

    useEffect(()=>{
        let _user = objectDeepClone(props.user)
        setName(_user.fullName)
        setEmail(_user.email)
        setUsername(_user.username)
        setRole({
            value: _user.userRole,
            label:_user.userRole.replace("-"," "),
        })
        setGroups(partnerIdToSelectValues(_user.userGroups, partners))
        setUserCompany(_user?.associatedCompany || {})
    },[])

    function handleOnSave(event){
        setTouched(true)
        event.preventDefault();
        if(!name || !email || !username || !role.value){
            return 
        }
        let obj = {
			fullName: name,
			username: username,
			email: email,
			userGroups: group.map(item=>item.value),
			userRole: role.value,	
            associatedCompany: userCompany || {}
		};
        API.updateUser(props.user._id, obj)
            .then((response) => {
                props.getListOfUser('',1,"active");
                props.onHide();
				getToast({statusType: 'SUCCESS', message: 'User successfully updated'})
            })
            .catch((error) => throwServerError(error));
    }

    function handleRoleChange(e){
        setRole(e)
    }

    function handleGroupChange(e){
        setGroups(e)
    }

    const [isCtrl, setIsCtrl] = useState(false)
    
    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
            <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 text-300 fw-500 fs-16px">
                            Edit User
                        </p>
                    </div>

                    <div className="d-flex align-items-center">
                        <div
                            onClick={props.onHide}
                            className="text-300 fw-400 fs-12px mr-14px cursor-pointer"
                        >
                            Cancel
                        </div>
                        <div
                            onClick={(e)=>{handleOnSave(e)}}
                            className="btn-md-v1 text-200 fw-400 fs-12px surface-850"
                        >
                            Save
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
               <Form className="d-flex flex-column w-100"  onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleOnSave(e)
                        e.preventDefault()
                    }
                }}>
                    <div className='mt-1 mb-3 p-3 surface-875 d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            {props.user.imgSrc?<div className='br-40px surface-850 text-200 mr-10px p-3'>
                            </div>:<div className='userCircle surface-850 mr-10px'>
                                    <p className='fw-400 fs-14px text-300 text-uppercase'>
                                    {getFirstLetterOfString(props.user.fullName)}
                                    </p>
                            </div>}
                            <div className='text-200 fs-16px fw-400'>
                                    {props.user.fullName}
                            </div>
                        </div>
                        <div>
                            <p className='text-200 fs-14px'>{`${formatCtsTime(props.user.updatedAt)} - ${formatCtsDate(props.user.updatedAt)} (UTC)`}</p>
                        </div>
                    </div>
                    <div className='surface-875 p-3'>
                        <p className='fs-16px fw-400 text-200 mb-1'>User Data</p>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your email"
                                value={name}
                                onChange={(e) =>
                                    setName(e.target.value)
                                }
                                className="v1-form-control"
                            />
                            {touched && !name  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Name is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Username <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your email"
                                value={username}
                                onChange={(e) =>
                                    setUsername(e.target.value)
                                }
                                className="v1-form-control"
                            />
                            {touched && !username  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Username is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Email <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) =>
                                    setEmail(e.target.value)
                                }
                                className="v1-form-control"
                            />
                            {touched && !email  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Email is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Login Method <span className="text-danger">*</span>
                            </Form.Label>
                            <p className='text-200 fs-14px fw-500'>{props.user.loginMethod || ''}</p>
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Groups 
                            </Form.Label>
                            <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                className="v1select mt-1 h-fit"
                                placeholder="Select Groups"
                                options={getUserGroupOptions(partners)}
                                isMulti
                                defaultValue={partnerIdToSelectValues(props.user.userGroups, partners)}
                                onChange={(e)=>{handleGroupChange(e)}}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Company 
                            </Form.Label>
                            <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                isClearable
                                className="v1select mt-1 h-fit"
                                placeholder="Select Company"
                                value={userCompany && Object.keys(userCompany).length ? userCompany : null}
                                options={companiesList.map((x) => ({...x, label: x.companyName, value: x.companyName}))}
                                onChange={setUserCompany}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Role <span className="text-danger">*</span>
                            </Form.Label>
                            <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                className="v1select mt-1"
                                placeholder="Select Resource"
                                defaultValue={{
                                    value: props.user.userRole,
                                    label: props.user.userRole.replace("-"," "),
                                }}
                                value={role}
                                options={roles.map((option) => {
                                    return { value: option.code, label: option.name};
                                })}
                                onChange={(e)=>{handleRoleChange(e)}}
                            />
                            {touched && !role?.value  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Role is required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}