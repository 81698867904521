import React from 'react'
import CustomNavbar from '../../../Dashboards/components/CustomNavbar'
import { Cube, TrendUp } from 'phosphor-react'
import RandomSvgComponent from './RandomSvgComponent'
import { formatCtsTimeWithoutUTC, getRandomElementFromArray, throwServerError } from '../../../../utils/helper'
import { useEffect } from 'react'
import { useState } from 'react'
import API from '../../../../api'
import Loading from '../../../../components/Loader/Loading'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { objectDeepClone } from '@apiwiz/oas/services'
import { EmptyState } from '../../../../components/EmptyState'
import RenderPrimaryTab from '../../../AdminPages/Configure/components/RenderPrimaryTab'
import CustomPrimaryTab from '../../../Dashboards/components/CustomPrimaryTab'
import CustomSecondaryTab from '../../../Dashboards/components/CustomSecondaryTab'
import CustomTertiaryTab from '../../../Dashboards/components/CustomTertiaryTab'

export default function AllPackages() {
    const {currentLayoutObject} = useSelector(state => state.layout)

    const [categories, setCategories] = useState([])
    const [categoriesLoading, setCategoriesLoading] = useState(false)
    const [recentPackages, setRecentPackages] = useState([])
    const [popularPackages, setPopularPackages] = useState([])
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const {currentUser} = useSelector(state => state.user)

    useEffect(() => {
        getAllCategories()
        getRecentPackages()
        getPopularPackages()
    }, [])

    const getAllCategories = () => {
        setCategoriesLoading(true)
        API.getAllCategories({expand: true, developerEmail: currentUser.email})
        .then(res => {
            setCategories(res.data?.data || [])
            setCategoriesLoading(false)
        }).catch(err => {
            setCategories([])
            throwServerError(err)
            setCategoriesLoading(false)
        })
    }

    const getPopularPackages = (_offset, _pageSize) => {
        setLoading(true)
        const params = {
            expand: false, 
            offset: 1,
            pageSize: 3,
            developerEmail: currentUser.email
        }
        API.getPopularPackages(params)
        .then(res => {
            const _data = objectDeepClone(res.data?.data || {})

            if (_data.data && _data.data?.length > 0){
                console.log(_data.data)
                setPopularPackages(objectDeepClone(_data.data || []))
            } else {
                setPopularPackages([])
            }
            setLoading(false)
        }).catch(err => {
            throwServerError(err)
            setLoading(false)
            setPopularPackages([])
        })
    }

    const getRecentPackages = (_offset, _pageSize) => {
        setLoading2(true)
        const params = {
            expand: false, 
            offset: 1,
            pageSize: 3,
            developerEmail: currentUser.email
        }
        API.getRecentPackages(params)
        .then(res => {
            const _data = objectDeepClone(res.data?.data || {})

            if (_data.data && _data.data?.length > 0){
                console.log(_data.data)
                setRecentPackages(objectDeepClone(_data.data || []))
            } else {
                setRecentPackages([])
            }
            setLoading2(false)
        }).catch(err => {
            throwServerError(err)
            setLoading2(false)
            setRecentPackages([])
        })
    }

    const navigate = useNavigate()
    
    return (
        <div className='w-100vw bg-currentBackground'>
            <CustomNavbar />

            <div className='mt-80px container w-100 py-2'>
                {currentLayoutObject.promotions?.primary?.heading && <div className='mobile-dnone mb-4 dfaic gap-16px w-100'>
                    <div className='w-50'>
                        <CustomPrimaryTab data={currentLayoutObject.promotions.primary} />
                    </div>
                    <div className='w-50 dfaic gap-16px flex-column'>
                        <CustomSecondaryTab data={currentLayoutObject.promotions.secondary} />

                        <div className='dfaic w-100 gap-16px'>
                            <div className='exploreAllCategories position-relative overflow-hidden br-16px py-16px px-24px w-50'>
                                <div className='position-absolute' style={{left: -90, top: -40, opacity: 0.2}}>
                                    <RandomSvgComponent index={2} />
                                </div>

                                <div className='d-flex justify-content-between h-100 flex-column zIndex-3 position-relative'>
                                    <p className='text-white fs-22px fw-600'>Explore All Categories</p>

                                    <div className='px-24px py-6px viewallButton w-fit cursor'
                                        onClick={() => navigate(`/monetization/packages`)}>
                                        <p className='fw-600 fs-14px text-white'>View All</p>
                                    </div>
                                </div>

                                <div className='position-absolute'
                                    style={{left: 0, bottom: 0}}>
                                    <svg width="318" height="129" viewBox="0 0 318 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M-59 173.141C-58.8262 150.311 -25.7277 105.484 105.276 108.822C269.03 112.994 337 121.512 337 0" stroke="white" stroke-width="0.347673"/>
                                    </svg>
                                </div>
                            </div>
                            <div className='w-50'>
                                <CustomTertiaryTab data={currentLayoutObject.promotions.tertiary} />
                            </div>
                        </div>
                    </div>
                </div>}

                <div className='mb-3'>
                    <div className='dfaic justify-content-between w-100'>
                        <p className='fs-28px fw-600 text-content-color'>Popular Packages</p>

                        <Link to={`/monetization/packages`} className='fw-600 fs-16px text-primary-color'>
                            View All
                        </Link>
                    </div>

                    <div className='dfaic mt-3 flex-wrap gap-20px mb-5'>
                        {loading
                        ? <div className='w-100' style={{height: 300}}>
                            <Loading />
                        </div>
                        : (popularPackages.length === 0
                            ? <div className='w-100 my-5 dfaic jcc flex-column'>
                                <EmptyState />
                                <p className="ps-5 text-content-color text-center">
                                    No Popular Packages Found
                                </p>
                            </div>
                            : popularPackages.map((each, i) => (
                                <Link to={`/monetization/packages/${each.id}`}>
                                    <div className={`packageCardv1 hover-box-shadow`} key={i}>
                                        <div className={`topSection bg-color-${i} overflow-hidden`}>
                                            <div className='iconSection'>
                                                <svg width="123" height="124" viewBox="0 0 123 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.2">
                                                        <path opacity="0.2" d="M77.6051 62.5281V112.375C76.9291 112.372 76.2647 112.206 75.6732 111.891L31.4014 88.5632C30.7693 88.2302 30.2416 87.7399 29.8734 87.1435C29.5053 86.547 29.3102 85.8664 29.3086 85.1726V38.847C29.3088 38.301 29.4288 37.7612 29.6608 37.2631L77.6051 62.5281Z" fill="white"/>
                                                        <path d="M29.6602 37.2579L77.6045 62.5229L125.549 37.2579" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M79.537 12.1095L123.809 35.4467C124.441 35.7797 124.969 36.27 125.337 36.8664C125.705 37.4629 125.9 38.1435 125.902 38.8373V85.1629C125.9 85.8567 125.705 86.5374 125.337 87.1338C124.969 87.7302 124.441 88.2205 123.809 88.5536L79.537 111.891C78.9446 112.203 78.2803 112.366 77.6051 112.366C76.9299 112.366 76.2656 112.203 75.6732 111.891L31.4014 88.5536C30.7693 88.2205 30.2416 87.7302 29.8734 87.1338C29.5053 86.5374 29.3102 85.8567 29.3086 85.1629V38.8373C29.3102 38.1435 29.5053 37.4629 29.8734 36.8664C30.2416 36.27 30.7693 35.7797 31.4014 35.4467L75.6732 12.1095C76.2656 11.7975 76.9299 11.6339 77.6051 11.6339C78.2803 11.6339 78.9446 11.7975 79.537 12.1095Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M77.6045 62.5279V112.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='bottomSection surface-consumer-900 p-16px'>
                                            <div className='p-16px'>
                                                {/* {each.subscriberCount > 0 
                                                ? <div className='dfaic'>
                                                    <TrendUp size={20} color='#17B26A' />
                                                    <p className='fs-14px fw-500 ms-2' style={{color: '#17B26A', marginBottom: 5}}>{each.subscriberCount || 0} {each.subscriberCount === 1 ? 'user' : 'users'}</p>
                                                </div>
                                                : <p className='fs-14px fw-500 text-content-subtle'>
                                                    No Subscriptions Yet
                                                </p>} */}

                                                <p className='fs-20px fw-600 text-content-color mt-1'>{each.packageName}</p>

                                                <div style={{height: 65}}>
                                                    <p className='fs-16px fw-400 text-truncate-3 grey-subtext'>
                                                        {each.summary || ''}
                                                    </p>
                                                </div>

                                                <p className='text-primary-color fs-14px fw-700 mt-12px text-center'>
                                                    ends on {formatCtsTimeWithoutUTC(each.endDate || '', 'DD MMM YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                </div>

                <div className='mb-3'>
                    <div className='dfaic justify-content-between w-100'>
                        <p className='fs-28px fw-600 text-content-color'>New Packages</p>

                        <Link to={`/monetization/packages`} className='fw-600 fs-16px text-primary-color'>
                            View All
                        </Link>
                    </div>

                    <div className='dfaic mt-3 flex-wrap gap-20px mb-5'>
                        {loading2 
                        ? <div className='w-100' style={{height: 300}}>
                            <Loading />
                        </div>
                        : ( recentPackages.length === 0
                            ? <div className='w-100 my-5 dfaic jcc flex-column'>
                                <EmptyState />
                                <p className="ps-5 text-content-color text-center">
                                    No Latest Packages Found
                                </p>
                            </div>
                            : recentPackages.map((each, i) => (
                            <Link to={`/monetization/packages/${each.id}`}>
                                <div className={`packageCardv1 hover-box-shadow`} key={i}>
                                    <div className={`topSection bg-color-${i} overflow-hidden`}>
                                        <div className='iconSection'>
                                            <svg width="123" height="124" viewBox="0 0 123 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.2">
                                                    <g clip-path="url(#clip0_4811_4221)">
                                                        <path opacity="0.2" d="M41.8749 55.338V86.0889C41.4509 86.0872 41.0342 85.9845 40.6633 85.7901L12.8975 71.3992C12.5011 71.1938 12.1701 70.8913 11.9392 70.5233C11.7083 70.1554 11.586 69.7355 11.585 69.3075V40.729C11.5851 40.3921 11.6603 40.0592 11.8058 39.7518L41.8749 55.338Z" fill="white"/>
                                                        <path d="M11.8057 39.7488L41.8747 55.335" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M72.1648 40.723C72.1637 40.295 72.0414 39.8751 71.8105 39.5071C71.5796 39.1392 71.2487 38.8367 70.8522 38.6313L43.0865 24.2344C42.715 24.0419 42.2983 23.941 41.8749 23.941C41.4514 23.941 41.0348 24.0419 40.6633 24.2344L12.8975 38.6313C12.5011 38.8367 12.1701 39.1392 11.9392 39.5071C11.7083 39.8751 11.586 40.295 11.585 40.723V69.3015C11.586 69.7295 11.7083 70.1494 11.9392 70.5174C12.1701 70.8853 12.5011 71.1878 12.8975 71.3932L19.826 75.2925C20.1975 75.485 20.6142 75.5859 21.0376 75.5859C21.4611 75.5859 21.8777 75.485 22.2492 75.2925" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M41.875 55.338V73.4652" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <g clip-path="url(#clip1_4811_4221)">
                                                        <path opacity="0.2" d="M101.613 86.0775V116.828C101.189 116.827 100.773 116.724 100.402 116.53L72.6358 102.139C72.2393 101.933 71.9084 101.631 71.6775 101.263C71.4466 100.895 71.3243 100.475 71.3232 100.047V71.4685C71.3234 71.1317 71.3986 70.7987 71.5441 70.4914L101.613 86.0775Z" fill="white"/>
                                                        <path d="M71.5439 70.4884L101.613 86.0745L131.682 70.4884" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M102.825 54.974L130.59 69.3708C130.987 69.5763 131.318 69.8788 131.549 70.2467C131.78 70.6146 131.902 71.0345 131.903 71.4625V100.041C131.902 100.469 131.78 100.889 131.549 101.257C131.318 101.625 130.987 101.927 130.59 102.133L102.825 116.53C102.453 116.722 102.037 116.823 101.613 116.823C101.19 116.823 100.773 116.722 100.402 116.53L72.6358 102.133C72.2393 101.927 71.9084 101.625 71.6775 101.257C71.4466 100.889 71.3243 100.469 71.3232 100.041V71.4625C71.3243 71.0345 71.4466 70.6146 71.6775 70.2467C71.9084 69.8788 72.2393 69.5763 72.6358 69.3708L100.402 54.974C100.773 54.7815 101.19 54.6806 101.613 54.6806C102.037 54.6806 102.453 54.7815 102.825 54.974Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M101.613 86.0775V116.828" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <g clip-path="url(#clip2_4811_4221)">
                                                        <path opacity="0.2" d="M72.1004 38.5739V69.3248C71.6765 69.3231 71.2598 69.2204 70.8889 69.026L43.1231 54.6351C42.7266 54.4297 42.3957 54.1272 42.1648 53.7593C41.9339 53.3913 41.8116 52.9714 41.8105 52.5434V23.9649C41.8107 23.628 41.8859 23.2951 42.0314 22.9877L72.1004 38.5739Z" fill="white"/>
                                                        <path d="M42.0312 22.9847L72.1003 38.5709L102.169 22.9847" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M73.312 7.47033L101.078 21.8672C101.474 22.0726 101.805 22.3751 102.036 22.743C102.267 23.111 102.389 23.5309 102.39 23.9589V52.5374C102.389 52.9655 102.267 53.3853 102.036 53.7533C101.805 54.1212 101.474 54.4237 101.078 54.6291L73.312 69.026C72.9406 69.2185 72.5239 69.3194 72.1004 69.3194C71.677 69.3194 71.2603 69.2185 70.8889 69.026L43.1231 54.6291C42.7266 54.4237 42.3957 54.1212 42.1648 53.7533C41.9339 53.3853 41.8116 52.9655 41.8105 52.5374V23.9589C41.8116 23.5309 41.9339 23.111 42.1648 22.743C42.3957 22.3751 42.7266 22.0726 43.1231 21.8672L70.8889 7.47033C71.2603 7.27783 71.677 7.17694 72.1004 7.17694C72.5239 7.17694 72.9406 7.27783 73.312 7.47033Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M72.1006 38.5739V69.3249" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <g clip-path="url(#clip3_4811_4221)">
                                                        <path opacity="0.2" d="M54.9813 84.4711V90.8091C54.9813 94.991 47.8082 98.4411 38.4297 99.0707C41.8421 100.295 46.248 101.032 51.0562 101.032C61.8944 101.032 70.6817 97.2864 70.6817 92.6677C70.6817 88.6205 63.9404 85.2471 54.9813 84.4711Z" fill="white"/>
                                                        <path opacity="0.2" d="M35.356 89.8797C46.1948 89.8797 54.9815 86.135 54.9815 81.5158C54.9815 76.8965 46.1948 73.1519 35.356 73.1519C24.5171 73.1519 15.7305 76.8965 15.7305 81.5158C15.7305 86.135 24.5171 89.8797 35.356 89.8797Z" fill="white"/>
                                                        <path d="M35.356 89.8797C46.1948 89.8797 54.9815 86.135 54.9815 81.5158C54.9815 76.8965 46.1948 73.1519 35.356 73.1519C24.5171 73.1519 15.7305 76.8965 15.7305 81.5158C15.7305 86.135 24.5171 89.8797 35.356 89.8797Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M15.7305 81.5157V90.8089C15.7305 95.4277 24.5178 99.1728 35.356 99.1728C46.1941 99.1728 54.9815 95.4277 54.9815 90.8089V81.5157" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M27.5059 89.1826V98.4758" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M54.9813 84.4711C63.9404 85.2471 70.6817 88.6205 70.6817 92.6677C70.6817 97.2864 61.8944 101.032 51.0562 101.032C46.248 101.032 41.8421 100.295 38.4297 99.0707" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M31.4316 99.0056V101.961C31.4316 106.58 40.219 110.325 51.0571 110.325C61.8953 110.325 70.6826 106.58 70.6826 101.961V92.6676" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M58.9072 100.334V109.628" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M43.2061 89.1826V109.628" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_4811_4221">
                                                <rect width="80.7731" height="76.4964" fill="white" transform="translate(1.48828 16.7641)"/>
                                                </clipPath>
                                                <clipPath id="clip1_4811_4221">
                                                <rect width="80.7731" height="76.4964" fill="white" transform="translate(61.2266 47.5037)"/>
                                                </clipPath>
                                                <clipPath id="clip2_4811_4221">
                                                <rect width="80.7731" height="76.4964" fill="white" transform="translate(31.7139)"/>
                                                </clipPath>
                                                <clipPath id="clip3_4811_4221">
                                                <rect width="62.8016" height="59.4764" fill="white" transform="translate(11.8057 62.0001)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='bottomSection surface-consumer-900 p-16px'>
                                        <div className='p-16px h-100'>
                                            {/* {each.subscriberCount > 0 
                                            ? <div className='dfaic'>
                                                <TrendUp size={20} color='#17B26A' />
                                                <p className='fs-14px fw-500 ms-2' style={{color: '#17B26A', marginBottom: 5}}>{each.subscriberCount || 0} {each.subscriberCount === 1 ? 'user' : 'users'}</p>
                                            </div>
                                            : <p className='fs-14px fw-500 text-content-subtle'>
                                                No Subscriptions Yet
                                            </p>} */}

                                            <p className='fs-20px fw-600 text-content-color mt-1'>{each.packageName}</p>

                                            <div style={{height: 65}}>
                                                <p className='fs-16px fw-400 text-truncate-3 grey-subtext'>
                                                    {each.summary || ''}
                                                </p>
                                            </div>

                                            <p className='text-primary-color fs-14px fw-700 mt-12px text-center'>
                                                ends on {formatCtsTimeWithoutUTC(each.endDate || '', 'DD MMM YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            )))}
                    </div>
                </div>

                <div className='mb-3'>
                    <div className='dfaic justify-content-between w-100'>
                        <p className='fs-28px fw-600 text-content-color'>Categories</p>
                    </div>

                    {categoriesLoading
                    ? <div className='w-100' style={{height: 300}}>
                        <Loading />
                    </div>
                    : (categories.length === 0
                        ? <div className='w-100 my-5 dfaic jcc flex-column'>
                            <EmptyState />
                            <p className="ps-5 text-content-color text-center">
                                No Categories Found
                            </p>
                        </div>
                        : <div className='dfaic mt-3 flex-wrap gap-20px mb-5'>
                            {categories.map((each, i) => (
                            <Link to={`/monetization/packages?category=${each.name}`}>
                                <div className={`categoryCardv1 hover-box-shadow bg-color-${getRandomElementFromArray([0,1,2])}`} key={each.id}>
                                    <div className='iconplace'>
                                        <RandomSvgComponent index={getRandomElementFromArray([0,1,2,3])} />
                                    </div>
                                    <p className='text-white text-capitalize fs-32px fw-700'>
                                        {each.name}
                                    </p>
                                </div>
                            </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
