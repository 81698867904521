import { useSelector } from "react-redux";
import { MagnifyingGlass } from "phosphor-react";
import LeftArrow from "../../../../assets/Forum/icons/themed-left-arrow.svg";
import { useNavigate } from "react-router";
import { ForumIcons } from "../ForumIcons";
import { ThemeToggler } from "../../../../components/ThemeToggler";
import { useTheme } from "../../../../context/ThemeContext";
import { useState } from "react";
import ForumAPI from "../../../../api/forum";
import { debounceFn } from "../../../../utils/helper";
import Loading from "../../../../components/Loader/Loading";
import { MobileSearchOverlay } from "./MobileSearchOverlay";

export const Navbar = (props) => {
  const { currentLayoutObject } = useSelector((state) => state.layout);
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { setShowMobileMenu } = props;
  const [showSearchBar, setShowSearchbar] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionLoading, setSuggestionloading] = useState(false);

  const onSearchChange = debounceFn((searchText) => {
    setSuggestionloading(true);
    ForumAPI.getSearchSuggestions({ search: searchText })
      .then((res) => {
        setSuggestionloading(false);
        setSuggestions(res.data);
      })
      .catch((err) => {
        setSuggestionloading(false);
        console.log("search suggestions err: ", err);
      });
  }, 1000);

  const onClearSearch = () => {
    setShowSearchbar(false);
    setSearchText("");
    setShowSearchSuggestions(false);
    setSuggestions([])
    setSuggestionloading(false)
  };

  console.log("suggestionLoading: ", suggestionLoading)

  return (
    <div className="navbar__container">
      <div className="site-logo">
        <img
          src={
            theme === "theme--light"
              ? currentLayoutObject.logo || ""
              : currentLayoutObject.darklogo || ""
          }
          alt=""
        />
        {/* <p className="forum-text">Forum</p> */}
      </div>
      <div className="auth-btns__container">
        <div className={`forum-search-bar ${showSearchBar ? "active" : ""}`}>
          <div
            className={`forum-search-input__container ${
              showSearchBar ? "active" : ""
            }`}
          >
            {showSearchBar && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  className="close-search-icon"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onClearSearch();
                  }}
                >
                  <path
                    d="M10.9375 3.0625L3.0625 10.9375"
                    stroke="#785C8A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.9375 10.9375L3.0625 3.0625"
                    stroke="#785C8A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  className="forum-search-input"
                  autoFocus={showSearchBar}
                  value={searchText}
                  onChange={(evt) => {
                    setSearchText(evt.target.value);
                    setSuggestions([]);
                    if (evt.target.value.length > 0) {
                      setSuggestionloading(true);
                      setShowSearchSuggestions(true);
                      onSearchChange(evt.target.value);
                    } else {
                      setShowSearchSuggestions(false);
                      setSuggestions([])
                      setSuggestionloading(false)
                    }
                  }}
                />
                <button className="search-btn">Search</button>
                <MobileSearchOverlay
                  searchText={searchText}
                  setSearchText={setSearchText}
                  setSuggestions={setSuggestions}
                  setSuggestionloading={setSuggestionloading}
                  onSearchChange={onSearchChange}
                  setShowSearchSuggestions={setShowSearchSuggestions}
                  onClearSearch={onClearSearch}
                  suggestionLoading={suggestionLoading}
                  suggestions={suggestions}
                />
              </>
            )}
          </div>
          {showSearchSuggestions && (
            <div className="search-suggestion-dropdown">
              {suggestionLoading && <Loading />}
              {!suggestionLoading &&
                suggestions &&
                suggestions.map((suggestion) => (
                  <div
                    className="suggestion-item"
                    data-value={suggestion.title}
                    key={suggestion._id}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      /**
                       * Suggestion id is real post id
                       */
                      navigate(`/forum/post/${suggestion._id}`);
                      onClearSearch();
                    }}
                  >
                    {suggestion.title}
                  </div>
                ))}
              {!suggestionLoading &&
                suggestions &&
                suggestions.length === 0 &&
                searchText.length > 0 && (
                  <p className="no-results">Search results not found</p>
                )}
            </div>
          )}

          {!showSearchBar && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              className="search-icon"
              onClick={() => {
                setShowSearchbar(true);
              }}
            >
              <g clip-path="url(#clip0_3157_31989)">
                <path
                  d="M15.1667 21.8333C18.8485 21.8333 21.8333 18.8485 21.8333 15.1667C21.8333 11.4848 18.8485 8.5 15.1667 8.5C11.4848 8.5 8.5 11.4848 8.5 15.1667C8.5 18.8485 11.4848 21.8333 15.1667 21.8333Z"
                  stroke="var(--content-subtle)"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.8809 19.8809L24.5 24.5"
                  stroke="var(--content-subtle)"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3157_31989">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>

        <ThemeToggler />

        {/* <button className="search-icon">
          <MagnifyingGlass width={"32px"} height={"32px"} />
        </button> */}

        {!isAuthenticated && (
          <>
            <button className="login-btn">Log In</button>
            <button className="register-btn">Register</button>
          </>
        )}
        <button
          className="dev-portal-redirect-btn"
          onClick={() => {
            navigate("/");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2033_111313)">
              <path
                d="M16.875 10H3.125"
                stroke="var(--primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.75 4.375L3.125 10L8.75 15.625"
                stroke="var(--primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2033_111313">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="matrix(0 1 -1 0 20 0)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>Go to Dev Portal</p>
        </button>
        <button
          className="hamburger-menu"
          onClick={() => {
            setShowMobileMenu((prevState) => !prevState);
          }}
        >
          <ForumIcons type={"hamburger"} />
        </button>
      </div>
    </div>
  );
};
