import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { BodyLayout } from '../../../layout/Base'
import { ArrowDown, ArrowUp, BookOpen, CalendarBlank, File, Lightning, Nut, SquaresFour, Users } from 'phosphor-react'
import Select from 'react-select'
import MyProgressBar from '../../../components/ProgressBar'
import API from '../../../api'
import { getPermissionByCrud, throwServerError } from '../../../utils/helper'
import Loading from '../../../components/Loader/Loading'
import countryList from '../../../countries.json'
import ReactCountryFlag from "react-country-flag"
import { useSelector } from 'react-redux'
import { objectDeepClone } from '@apiwiz/oas/services'

export default function Dashboardv2() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [data, setData] = useState({})
    const [selectedFilter, setSelectedFilter] = useState('Overall')

    const [loading, setloading] = useState(true)
    useEffect(() => {
        setTitle("Dashboard")
        setHasHeader(true)
        setNavigation([
            {name: 'Dashboard'},
        ])

        getData()
    }, [])

    const getData = () => {
        setloading(true)
        API.getDashboardDetails()
        .then(res => {

            setloading(false)
            let _data = objectDeepClone(res.data?.data || {})
            _data['totalSessions'] = 0

            _data.topPages?.forEach(each => {
                _data['totalSessions'] += (each.sessions || 0)
            })
            setData(_data)
        }).catch(err => {
            setloading(false)
            throwServerError(err)
        })
    }
    
    const getValue = (_type) => {
        switch (_type) {
            case 'Last 3 months':
                return 'last3Months';
            
            case 'Last 6 months':
                return 'last6Months';
            
            case 'Last 12 months':
                return 'last12Months';
        
            default:
                return 'total';
        }
    }

    const getCountryCode = (_countryName) => {
        if (_countryName === "USA") return 'US'
        let _filtered = countryList.filter(o => o.label === _countryName)
        if (_filtered.length) return _filtered[0].value 
        else return ''
    }

    const calculatePercentage = (_curr=0, _total=0) => {
        if (_total === 0 || _curr === 0) return 0

        let _perc = ((_curr)/_total)*100
        return parseFloat(_perc).toFixed(1)
    }

    const options = ['Overall','Last 3 months','Last 6 months', 'Last 1 year']

    function getPercent(current,previous){
        if (previous === 0) return current * 100
        if (current === 0 ) return -100
        return parseInt(((current-previous)/previous) * 100)
    }

    function m(n, d=2){
        let x = ('' + n).length
        let p = Math.pow
        d = p(10,d)
        x -= x%3
        return Math.round(n*d/p(10,x))/d + " kMGTPE"[x/3]
    }

    const navigate = useNavigate()

    /* ACL Start */
    const {currentUser} = useSelector(state => state.user)
    const {permission} = useSelector(state => state.user)

    let _permission = permission.filter(o => o.name === 'dashboard')
    _permission = _permission.length ? (_permission[0].crud || []) : []
    const getReadPermission = getPermissionByCrud(currentUser, _permission, 'read')
    /* ACL END */

    // if (!getReadPermission) navigate('/admin/profile-page')

    return (
        <BodyLayout>
            <div className='fadeIn h-100 w-100 overflow-scroll noscrollbar'>
                {loading
                ? <Loading />
                : <>
                    <div className='w-100 surface-850 p-16px br-4px'>
                        <p className='fw-600 fs-18px text-100'>Traffic Overview</p>

                        <div className='d-flex align-items-center gap-24px w-100 mt-2'>
                            <div className='w-32 p-24px surface-875 v1-border-1 br-8px'>
                                <div className='d-flex align-items-center mb-18px'>
                                    <div className='surface-950 br-28px p-2' style={{border: '8px solid #2F3135'}}>
                                        <Users size={24} color='white' weight='bold' />
                                    </div>
                                    <p className='fw-600 ml-18px fs-16px text-200'>Total Active Users</p>
                                </div>

                                <h3 className='fs-36px fw-600 text-200'>
                                    {objectDeepClone(data).activeUsers?.currentMonth || ''}
                                </h3>

                                <div className='mt-16px d-flex align-items-center'>
                                    {getPercent(objectDeepClone(data).activeUsers?.currentMonth || 0, objectDeepClone(data).activeUsers?.previousMonth || 0) > 0
                                    ? <>
                                        <ArrowUp size={12} color='#46A758' weight='bold' />
                                        <p className='ps-1 fw-500 fs-14px text-success'>
                                            {getPercent(objectDeepClone(data).activeUsers?.currentMonth || 0, objectDeepClone(data).activeUsers?.previousMonth || 0)}%
                                        </p>
                                    </>
                                    : <>
                                        <ArrowDown size={12} color='red' weight='bold' />
                                        <p className='ps-1 fw-500 fs-14px text-danger'>
                                            {getPercent(objectDeepClone(data).activeUsers?.currentMonth || 0, objectDeepClone(data).activeUsers?.previousMonth || 0)}%
                                        </p>
                                    </>}
                                    <p className='text-400 fs-14px fw-500 ms-2'>vs last month</p>
                                </div>
                            </div>

                            <div className='w-32 p-24px surface-875 v1-border-1 br-8px d-none'>
                                <div className='d-flex align-items-center mb-18px'>
                                    <div className='surface-950 br-28px p-2' style={{border: '8px solid #2F3135'}}>
                                        <SquaresFour size={24} color='white' weight='bold' />
                                    </div>
                                    <p className='fw-600 ml-18px fs-16px text-200'>Total Apps</p>
                                </div>

                                <h3 className='fs-36px fw-600 text-200'>2,420</h3>

                                <div className='mt-16px d-flex align-items-center'>
                                    <ArrowDown size={12} color='#E5484D' weight='bold' />
                                    <p className='ps-1 fw-500 fs-14px text-danger'>
                                        10%
                                    </p>
                                    <p className='text-400 fs-14px fw-500 ms-2'>vs last month</p>
                                </div>
                            </div>

                            <div className='w-32 p-24px surface-875 v1-border-1 br-8px d-none'>
                                <div className='d-flex align-items-center mb-18px'>
                                    <div className='surface-950 br-28px p-2' style={{border: '8px solid #2F3135'}}>
                                        <Lightning size={24} color='white' weight='bold' />
                                    </div>
                                    <p className='fw-600 ml-18px fs-16px text-200'>Recent Logins</p>
                                </div>

                                <h3 className='fs-36px fw-600 text-200'>2,420</h3>

                                <div className='mt-16px d-flex align-items-center'>
                                    <ArrowUp size={12} color='#46A758' weight='bold' />
                                    <p className='ps-1 fw-500 fs-14px text-success'>
                                        100%
                                    </p>
                                    <p className='text-400 fs-14px fw-500 ms-2'>vs last month</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-16px d-flex align-items-center justify-content-between w-100'>
                        <div className='w-49 p-24px dashboardCard'>
                            <div className='d-flex align-items-center justify-content-between mb-28px'>
                                <p className='fw-500 fs-18px text-200'>Total Numbers</p>

                                <Select className='v1select w-24' options={options.map(o => ({label: o, value: o}))}
                                    value={{label: selectedFilter, value: selectedFilter}}
                                    onChange={(e) => setSelectedFilter(e.value)} />
                            </div>

                            <div className='surface-base br-10px mb-4px px-18px py-16px d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <BookOpen color='#C2C2C2' size={34} />

                                    <div className='ml-16px'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>Guides</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {objectDeepClone(data).guidesCount ? objectDeepClone(data).guidesCount[getValue(selectedFilter)] || 0 : 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px'
                                    onClick={() => navigate(`/admin/guides`)}>
                                    <p className='fs-12px text-300 fw-500'>View Details</p>
                                </div>
                            </div>

                            <div className='surface-base br-10px mb-4px px-18px py-16px d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <Nut color='#C2C2C2' size={34} />

                                    <div className='ml-16px'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>SDKs</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {objectDeepClone(data).sdkCount ? objectDeepClone(data).sdkCount[getValue(selectedFilter)] || 0 : 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px'
                                    onClick={() => navigate(`/admin/sdks`)}>
                                    <p className='fs-12px text-300 fw-500'>View Details</p>
                                </div>
                            </div>

                            <div className='surface-base br-10px mb-4px px-18px py-16px d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <CalendarBlank color='#C2C2C2' size={34} />

                                    <div className='ml-16px'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>Events</p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {objectDeepClone(data).eventsCount ? objectDeepClone(data).eventsCount[getValue(selectedFilter)] || 0 : 0}
                                        </p>
                                    </div>
                                </div>

                                <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px'
                                    onClick={() => navigate(`/admin/events`)}>
                                    <p className='fs-12px text-300 fw-500'>View Details</p>
                                </div>
                            </div>

                            <div className='surface-base br-10px mb-4px px-18px py-16px d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <File color='#C2C2C2' size={34} />

                                    <div className='ml-16px'>
                                        <p className='text-400 fs-18px fw-400' style={{lineHeight: '17px'}}>API References <span className='fs-11px'>(Overall)</span></p>
                                        <p className='text-200 fs-24px fw-500'>
                                            {objectDeepClone(data).ApiReferenceCount?.total || 0}
                                        </p>
                                    </div>
                                </div>

                                {/* <div className='px-20px py-11px cursor surface-850 v1-border-1 br-4px'>
                                    <p className='fs-12px text-300 fw-500'>View Details</p>
                                </div> */}
                            </div>
                        </div>

                        <div className='w-50 p-24px dashboardCard'>
                            <p className='fs-18px fw-500 text-200 mb-25px'>Top Pages</p>

                            {objectDeepClone(data).topPages?.map((eachPage, i) => <div className='mb-20px' key={eachPage._id}>
                                <p className='text-300 fw-500 fs-14px text-capitalize'>{eachPage.page}</p>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='w-80'>
                                        <MyProgressBar value={parseInt((eachPage.sessions / data.totalSessions) * 100)} />
                                    </div>
                                    <p className='text-300 fs-14px fw-500'>{eachPage.sessions > 999 ? m(eachPage.sessions) : eachPage.sessions} sessions</p>
                                </div>
                            </div>)}
                        </div>
                    </div>

                    <div className='mt-16px d-flex align-items-center justify-content-between w-100'>
                        <div className='w-33 surface-875 br-4px p-24px h-450px'>
                            <p className='fs-18px fw-500 text-200 mb-23px'>Top Guides</p>

                            <div className='row mx-0 surface-850 px-12px py-8px'>
                                <div className='col-md-8'>
                                    <p className='text-400 fs-10px fw-500'>Name</p>
                                </div>
                                <div className='col-md-4'>
                                    <p className='text-400 fs-10px fw-500'>Sessions</p>
                                </div>
                            </div>
                            {objectDeepClone(data)?.topGuides?.splice(0,8)?.map((each) => <div className='row mx-0 px-12px py-8px v1-border-bottom-custom'>
                                <div className='col-md-8'>
                                    <p className='text-50 fs-14px fw-500 text-truncate'>{each?.guideName}</p>
                                </div>
                                <div className='col-md-4'>
                                    <p className='text-50 fs-14px fw-400 text-truncate ms-2'>
                                        {each.sessions > 999 ? m(each.sessions) : each.sessions}
                                    </p>
                                </div>
                            </div>)}
                        </div>
                        <div className='w-32 surface-875 br-4px p-24px h-450px'>
                            <p className='fs-18px fw-500 text-200 mb-23px'>Top SDKs</p>

                            <div className='row mx-0 surface-850 px-12px py-8px'>
                                <div className='col-md-8'>
                                    <p className='text-400 fs-10px fw-500'>Name</p>
                                </div>
                                <div className='col-md-4'>
                                    <p className='text-400 fs-10px fw-500'>Downloads</p>
                                </div>
                            </div>
                            {objectDeepClone(data)?.topSdks?.splice(0,8)?.map((each) => {
                                if (!each.sdk?.title) return
                                return (<div className='row mx-0 px-12px py-8px v1-border-bottom-custom'>
                                    <div className='col-md-8'>
                                        <p className='text-50 fs-14px fw-500 text-truncate'>{each?.sdk?.title || '(Deleted SDK)'}</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p className='text-50 fs-14px fw-400 text-truncate ms-2'>
                                            {each.downloads > 999 ? m(each.downloads) : each.downloads}
                                        </p>
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className='w-33 surface-875 br-4px p-24px h-450px'>
                            <p className='fs-18px fw-500 text-200 mb-23px'>Top References</p>

                            <div className='row mx-0 surface-850 px-12px py-8px'>
                                <div className='col-md-8'>
                                    <p className='text-400 fs-10px fw-500'>Name</p>
                                </div>
                                <div className='col-md-4'>
                                    <p className='text-400 fs-10px fw-500'>Sessions</p>
                                </div>
                            </div>
                            {objectDeepClone(data)?.topApiReferences?.splice(0,8)?.map((each) => <div className='row mx-0 px-12px py-8px v1-border-bottom-custom'>
                                <div className='col-md-8'>
                                    <p className='text-50 fs-14px fw-500 text-truncate'>{each?.swaggerName || ''}</p>
                                </div>
                                <div className='col-md-4'>
                                    <p className='text-50 fs-14px fw-400 text-truncate ms-2'>
                                        {each.sessions > 999 ? m(each.sessions) : each.sessions}
                                    </p>
                                </div>
                            </div>)}
                        </div>
                    </div>

                    <div className='mt-16px br-4px v1-border-1 surface-875 w-100 p-24px'>
                        <p className='text-200 fs-18px fw-500'>Developer Demographics</p>

                        <p className='text-200 fw-600 fs-36px mt-1 mb-3'>{objectDeepClone(data).demographics?.totalUsers || 0}</p>

                        {objectDeepClone(data).demographics?.data?.map((eachCountry , i) => <div className='d-flex align-items-start mb-3 w-100'>
                            <div className='w-4 d-flex justify-content-start pt-2'>
                                <ReactCountryFlag 
                                    countryCode={getCountryCode(eachCountry.country)} 
                                    svg
                                    style={{
                                        borderRadius: '100%',
                                        objectFit: 'cover',
                                        width: '2em',
                                        height: '2em',
                                    }}/>
                            </div>
                            <div className='w-95'>
                                <p>{eachCountry.country}</p>
                                <div className='d-flex align-items-center'>
                                    <MyProgressBar classsName='me-3'
                                        value={calculatePercentage(eachCountry.count, objectDeepClone(data).demographics.totalUsers)} />
                                    <p className='fs-14px text-300 fw-500'>
                                        {calculatePercentage(eachCountry.count, objectDeepClone(data).demographics.totalUsers)}%
                                    </p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </>}
            </div>
        </BodyLayout>
    )
}
