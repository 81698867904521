import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { getCookie } from "../utils/cookie"

export const PrivateRoute = ({ children }) => {
  const location = useLocation();

  return getCookie("itorix-token")  ? (
    children
  ) : (
    <Navigate to={`/login?redirect=${location.pathname}`} replace />
  );
};
