import React, {useState, useEffect} from 'react'
import CustomNavbar from '../Dashboards/components/CustomNavbar'
import CustomFooter from '../Dashboards/components/CustomFooter'
import { MagnifyingGlass } from 'phosphor-react'
import API from '../../api'
import { throwServerError } from '../../utils/helper'
import defaultImage from './default-sdk.png'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loader/Loading'
import PaginationV2 from '../../components/PaginationV2'
import { EmptyState } from '../../components/EmptyState'

export default function SDKs() {

    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('All')
    const [currentPage, setCurrentPage] = useState({
        offset: 1,
        total: 10,
        pageSize: 10
    })
    const [data, setData] = useState([])
    const [searchVal, setSearchVal] = useState('')

    const getListOfSdkCategory = () => {
		setIsLoading(true);
		API.getAllSdkCategory()
        .then((response) => {
            setIsLoading(false);
            const data = response.data?.data || []
            setCategories(data)
        })
        .catch((error) => {
            setIsLoading(false);
            setCategories([])
            throwServerError(error)
        });
	};

    useEffect(() => {
        getListOfSdkCategory()
        getListOfSdks()

        API.trackSessions('SDK')
        .then(res => console.log('res', res))
        .catch(err => console.log('err', err))
    }, [])
    
    const getListOfSdks = (page = 1) => {
        setIsLoading(true)
        API.getAllSdk(page)
        .then(response => {
            setIsLoading(false)
            if (response.data.data) {
                let allData = []
                response.data.data.map(o => {
                    o.Items.map(n => {
                        allData.push(n)
                    })
                });
                setData(allData)

                let _paging = response.data.paging

                setCurrentPage({
                    ...currentPage, total: _paging.total, pageSize: _paging.limit
                })
            }
        })
        .catch(error => {
            setIsLoading(false)
            throwServerError(error)
        })
    }

    const handlePageChange = (_pageNumber) => {
        if (selectedCategory === 'All'){
            getListOfSdks(_pageNumber)
        } else {
            getFilteredListOfSDKs(`filterCategory=${selectedCategory}`, _pageNumber)
        }
    }

    const getFilteredListOfSDKs = (category, page = 1) => {
        setIsLoading(true)
        API.filterSdk(category, page)
        .then(response => {
            setIsLoading(false)
            if (response.data.data) {
                let allData = []
                response.data.data.map(o => {
                    o.Items.map(n => {
                        allData.push(n)
                    })
                });
                setData(allData)

                let _paging = response.data.paging
                setCurrentPage({
                    ...currentPage, total: _paging.total, pageSize: _paging.limit
                })
            }
        })
        .catch(error => {
            setIsLoading(false)
            throwServerError(error)
        })
    }

    const handleClick = (each) => {
        if (each.categoryslug === selectedCategory) return

        setCurrentPage({
            ...currentPage, offset: 1
        })
        setSelectedCategory(each.categoryslug)

        if (each.categoryslug === 'All'){
            getListOfSdks(1)
        } else {
            getFilteredListOfSDKs(`filterCategory=${each.categoryslug}`, 1)
        }
    }

    const handleDownload = (each) => {
        const {download: url} = each

        const a = document.createElement("a");
        a.href = url;
        a.target = '_blank'
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        API.downloadSDK(each)
        .then(res => {
            console.log('res', res)
        }).catch(err => {
            console.log('err', err)
        })
    }

    const getFilteredData = (_data) => {
        let newArr = []
        _data.forEach(eachSDK => {
            let regExp = new RegExp(searchVal, "i");
            if ((eachSDK.title?.search(regExp) === -1) && (eachSDK.description?.search(regExp) === -1)) return
            newArr.push(eachSDK)
        })

        return newArr
    }

    return (
        <div className="w-100 h-100 guide">
            <CustomNavbar />

            <div className='container mt-62px'>
                <div className="d-flex sdksLeftPanel justify-content-center guide-body hideLeftDivInMobile bg-theme-base">
                    <div className="guide-left-panel border-right-theme-base-200 py-32px pr-32px">
                        <div className='searchSection mb-32px'>
                            <MagnifyingGlass color='#667085' size={15} />

                            <input type='text' placeholder='Search' value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)} />
                        </div>

                        <p className='subTitle'>SDK Categories</p>

                        <div className='categoryListSDK mt-24px'>
                            <div className={`eachCategory ${selectedCategory === 'All' ? 'active' : ''}`}
                                onClick={() => handleClick({categoryslug: 'All'})}>
                                All
                            </div>
                            {categories.map((eachCategory, i) => (<div className={`eachCategory ${selectedCategory === eachCategory.categoryslug ? 'active' : ''}`}
                                key={eachCategory._id} onClick={() => handleClick(eachCategory)}>
                                {eachCategory.categoryname || ''}
                            </div>))}
                        </div>
                    </div>

                    <div className="d-flex justify-content-center guide-content-panel p-32px w-100">
                        <div>
                            <div className="d-flex flex-column gap-4px align-items-center mb-4">
                                <div className="fs-32px fw-600 lh-38px">SDKs</div>
                                <div className="fs-16px lh-28px mobileTextCenter">
                                    Explore popular SDKs and download sdk assets that can
                                    help during your API integration.
                                </div>
                            </div>

                            <div className='w-100 h-92 sdkCardMainWrapper'>
                                { isLoading 
                                ? <Loading />
                                : (getFilteredData(data).length > 0 
                                ? getFilteredData(data).map((eachSDK => {
                                    
                                    return (
                                        <div className='sdkCardMain' key={eachSDK._id}>
                                            <div className='imageSection'>
                                                <img src={eachSDK.sdkIcon || defaultImage} alt='SDK Icon' />
                                            </div>

                                            <div className='detailsSection'>
                                                <div className='categoryBadge'>
                                                    {eachSDK.categoryname || eachSDK.category?.replaceAll('-', ' ')}
                                                </div>

                                                <p className='eachSDKTitle'>{eachSDK.title}</p>

                                                <div className='eachSDKDescription'>
                                                    <p className='text-truncate-3'>{eachSDK.description || ''}</p>
                                                </div>

                                                <div className='d-flex align-items-center justify-content-between cursor w-100 mt-22px'>
                                                    <div className='w-48 br-4px bg-primary-color px-20px py-11px mobileTextCenter'>
                                                        <a href={eachSDK.overviewUrl} target='_blank'
                                                            rel='noreferrer' className='text-base-color fw-500 btnTextt mobileTextCenter'>Overview</a>
                                                    </div>

                                                    <div className='w-48 br-4px bg-primary-color px-20px py-11px cursor' onClick={() => handleDownload(eachSDK)}>
                                                        <p className='text-base-color fw-500 btnTextt mobileTextCenter'>Download</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })) : <div className=''>
                                    <EmptyState />

                                    <p className='ps-5 text-content-color text-center'>No SDKs Found</p>
                                </div>)}
                            </div>
                            {(data.length > 0 && !isLoading) && <div className='w-100 h-8 mt-2'>
                                <PaginationV2 pagination={{
                                    offset:currentPage.offset, total: currentPage.total, pageSize: currentPage.pageSize
                                }} cb={handlePageChange} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <CustomFooter />
        </div>
    )
}
