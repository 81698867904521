import { CaretDown } from 'phosphor-react'
import React, { useState } from 'react'
import { defaultCurrencyFn, pluralize } from '../../../../utils/helper'
import { useSelector } from 'react-redux'

export default function AppAccordion({data}) {
    const {currentUser} = useSelector(state => state.user)

    const [open, setOpen] = useState(false)

    return (
        <div className='mb-2'>
            <div className='row mx-0 p-10px surface-consumer-875 cursor'
                onClick={() => setOpen(curr => !curr)}>
                <div className='col-md-5'>
                    <p className='fs-14px fw-500 text-consumer-300 text-truncate'>
                        {data[0]?.appName}
                    </p>
                </div>

                <div className='col-md-3 my-auto'>
                    <div className='bg-secondary-color br-10px w-fit' style={{padding: '1px 8px'}}>
                        <p className='fs-10px text-white fw-500 text-center'>
                            {pluralize(data.length, 'Package')}
                        </p>
                    </div>
                </div>

                <div className='col-md-2 my-auto'>
                    <p className='text-consumer-300 fs-14px fw-500'>
                        {defaultCurrencyFn(currentUser.billing?.billingCurrency)} {(data.reduce((acc, curr) => acc + parseInt(curr.totalAmount), 0))?.toLocaleString()}
                    </p>
                </div>

                <div className='col-md-2 my-auto pe-0'>
                    <p className='text-blue-11 fs-12px fw-400 d-flex align-items-center gap-4px'>Details <CaretDown size={12} /> </p>
                </div>
            </div>

            {open && <div>
                {data.map(((eachPackage, i) => <div className='row custom-border-bottom-1 mx-0 p-10px surface-consumer-base' key={i}>
                    <div className='col-md-8'>
                        <p className='fs-14px fw-400 text-consumer-300 text-truncate'>
                            {eachPackage?.packageName}
                        </p>
                    </div>
                    <div className='col-md-4'>
                        <p className='fs-14px fw-400 text-consumer-300 text-truncate'>
                            {(eachPackage.totalAmount || 0)?.toLocaleString()} {currentUser.billing?.billingCurrency?.currencyCode || ''}
                        </p>
                    </div>
                </div>))}
            </div>}
        </div>
    )
}
