import Select from 'react-select'
import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useState} from 'react'
import { getToast } from '../../../components/Toast';
import monetization from '../../../api/monetization';
import { objectDeepClone } from '@apiwiz/oas/services';
import { CaretDown, CaretRight, Trash } from 'phosphor-react';
import AddScenarioBtn from '../../../components/AddScenarioBtn';
import TimelineWizard from '../../../components/TimelineWizard';
import GlobalIcons from '../../../components/Icons/GlobalIcons';
import { showSuccessMessage, throwServerError } from '../../../utils/helper';
import { useSelector } from 'react-redux';
import { GATEWAYS } from '../../../service/constants';

const SwaggerComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const { swagger, handleOnResourceClick, selectedResources, handleSelectAll } = props;

    let selectAllEnable = false
    if(selectedResources[swagger.swaggerId] && Object.keys(selectedResources[swagger.swaggerId])?.length && (Object.keys(selectedResources[swagger.swaggerId])?.length === swagger?.resources?.length)) {
        selectAllEnable = true
    }

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-center w-100 p-12px surface-850'>
                <div className='dfaic gap-8px cursor' onClick={()=>setIsOpen(prev => !prev)}>
                    {isOpen ? <CaretDown size={16} color='#E4E4E4'/> : <CaretRight size={16} color='#E4E4E4'/>}
                    <p className='fw-500 fs-14px text-50'>{swagger?.label || swagger?.swaggerName}</p>

                    {swagger.oasVersion ? (
                        <div className='px-6px py-4px br-38px' style={{background: swagger.oasVersion === "2.0" ? '#08354c' : '#301A3A'}}>
                            <p className='text-100 fw-600 fs-10px'>OAS {swagger.oasVersion}</p>
                        </div>
                    ) : null}
                </div>
                <div className='cursor mr-4px' onClick={() => handleSelectAll(swagger)}>
                    <GlobalIcons type={selectAllEnable ? 'form-check' : 'form-uncheck'} />
                </div>
            </div>
            {isOpen && swagger?.resources?.map((each, i) => {
                const swaggerId = swagger.swaggerId;
                const resKey = `${each.method}.${each.path}`
                let checkEnabled = false;

                if(selectedResources?.[swaggerId]?.hasOwnProperty(resKey)){
                    checkEnabled = true
                }

                return (
                    <div className='px-16px py-8px mb-1 dfaic justify-content-between surface-875 br-4px' key={i}>
                        <div className='dfaic gap-12px'>
                            <div className={`v2-dark-status-code ${each.method.toUpperCase()} text-uppercase px-10px py-6px br-4px`} style={{width: 61}}>
                                <p className='text-center fw-500 fs-11px text-200'>{each.method.toUpperCase()}</p>
                            </div>
    
                            <div style={{width: 280}}>
                                <p className='fs-14px text-100 fw-500 text-truncate'>{each?.operationId || "-"}</p>
                                <p className='fs-12px text-400 fw-400 text-truncate'>
                                    {each?.path || "-"}
                                </p>
                            </div>
                        </div>
    
                        <div className='dfaic gap-24px'>
                            <p className='text-400 fs-12px fw-400 text-truncate-2' style={{width: 290}}>
                                {each?.summary || "-"}
                            </p>
                            <div className='cursor'
                                onClick={() => handleOnResourceClick(swagger, each)}>
                                <GlobalIcons type={checkEnabled ? 'form-check' : 'form-uncheck'} />
                            </div>
                        </div>
                    </div>  
                )
            })}
        </div>
    )
}

export default function AddProductModal(props) {
    const [steps] = useState(['General Details', 'Select Swagger']);
    const [activeStep, setActiveStep] = useState(0)
    const [data, setData] = useState({
        description: '',
        displayName: '',
    })
    const [swaggerOptions, setSwaggerOptions] = useState([])
    const [guideOptions, setGuideOptions] = useState([])
    const [selectedGuides, setSelectedGuides] = useState([])

    const [selectedSwaggers, setSelectedSwaggers] = useState([])
    const [selectedResources, setSelectedResources] = useState({})
    const { monetizationEnabled } = useSelector(state => state.user)

    const handleSelectAll = (swagger) => {
        let _selectedRes = objectDeepClone(selectedResources)
        const _swaggerId = swagger.swaggerId

        if(_selectedRes.hasOwnProperty(_swaggerId) && (Object.keys(selectedResources[swagger.swaggerId])?.length === swagger?.resources?.length)) {
            delete _selectedRes[_swaggerId]
        } else {
            for (const resource of swagger.resources) {
                const resKey = `${resource.method}.${resource.path}`
                _selectedRes = {
                    ..._selectedRes,
                    [_swaggerId] : {
                        ..._selectedRes?.[_swaggerId],
                        [resKey] : resource
                    }
                }
            }
        }

        console.log(_selectedRes,"_selectedRes");
        setSelectedResources(_selectedRes)
    }

    const handleOnResourceClick = (swagger, resource) => {
        let _selectedRes = objectDeepClone(selectedResources)
        const _swaggerId = swagger.swaggerId
        const resKey = `${resource.method}.${resource.path}`

        if(swagger && _selectedRes && _selectedRes.hasOwnProperty(_swaggerId)) {
            if (_selectedRes?.[_swaggerId]?.hasOwnProperty(resKey)){
                delete _selectedRes[_swaggerId][resKey]
            } else {
                _selectedRes[_swaggerId] = {
                    ..._selectedRes[_swaggerId],
                    [resKey] : resource
                }
            }
        } else {
            _selectedRes = {
                ..._selectedRes,
                [_swaggerId] : {
                    [resKey] : resource
                }
            }
        }

        console.log(_selectedRes,'_selectedRes');
        setSelectedResources(_selectedRes)
    }

    const handleItemOnClick = (val) => {
        setActiveStep(steps.indexOf(val))
    }

    useEffect(() => {
        if (props.isEdit){
            let _data = objectDeepClone(props.data)
            setData({
                description: _data.description || '',
                displayName: _data.displayName || '',
                enableMonetization: _data.enableMonetization
            })
            if(_data.attachedGuides && _data.attachedGuides.length) {
                setSelectedGuides(_data.attachedGuides || [])
            }
            if(_data.swagger && _data.swagger.length) {
                let editResource = {}, swagger = []
                for (const _swagger of _data.swagger) {
                    const swaggerId = _swagger.swaggerId

                    for (const resource of _swagger.resources) {
                        const resKey = `${resource.method}.${resource.path}`
                        editResource = {
                            ...editResource,
                            [swaggerId] : {
                                ...editResource?.[swaggerId],
                                [resKey]: resource
                            }
                        }
                    }

                    _swagger.resources = props?.catalogs?.find((x) => x.swaggerId === swaggerId)?.resources || _swagger?.resources || []
                    _swagger.label = _swagger.swaggerName
                    _swagger.value = _swagger.swaggerId
                    swagger.push(_swagger)
                }

                console.log(swagger,editResource,'swagger');
                setSelectedSwaggers(swagger)
                setSelectedResources(editResource)
            }
        }
        setSwaggerOptions(objectDeepClone(props.catalogs || []))
        setGuideOptions(objectDeepClone(props.guides || []))
    }, [])

    const handleNext = () => {
        if (!data.displayName){
            getToast({
                statusType: 'ERROR', message: 'Product Display Name is required'
            })
            return
        }
        if (!data.description){
            getToast({
                statusType: 'ERROR', message: 'Product description is required'
            })
            return
        }
        setActiveStep(1)
    }

    const handleSave = () => {
        // if (!selectedResources && !Object.keys(selectedResources).length){
        //     throwServerError("Please select atleast one resource")
        //     return
        // }

        // if(selectedSwaggers.length !== Object.keys(selectedResources).length) {
        //     throwServerError("Select atleast one resource per swagger")
        //     return;
        // }

        // for (const swaggerId of Object.keys(selectedResources)) {
        //     if(!Object.keys(selectedResources[swaggerId]).length) {
        //         throwServerError("Select atleast one resource per swagger")
        //         return;
        //     }
        // }

        let swaggerData = objectDeepClone(selectedSwaggers).map((swagger) => {
            const _swaggerId = swagger.swaggerId
            let resources = []

            swagger.resources.map((resource) => {
                const resKey = `${resource.method}.${resource.path}`
                if(selectedResources?.[_swaggerId]?.[resKey]) {
                    resources.push(selectedResources?.[_swaggerId]?.[resKey])
                }
            })

            swagger.resources = resources
            return swagger
        })

        let _dataObj = {
            displayName: data.displayName,
            description: data.description,
            attachedGuides: objectDeepClone(selectedGuides),
            swagger: swaggerData,
            enableMonetization: data.enableMonetization
        }

        console.log('props.data', props.data)
        if (props.data && props.data._id){
            monetization
            .updateProductDetails(props.data._id, objectDeepClone(_dataObj))
            .then(res => {
                props.onHide()
                props.callback()
                showSuccessMessage("Successfully updated product details")
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            monetization
            .postProductDetails(objectDeepClone(_dataObj))
            .then(res => {
                props.onHide()
                props.callback()
                getToast({
                    statusType: 'SUCCESS', message: 'Successfully updated product details'
                })
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} Product</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Cancel
                        </div>

                        {activeStep === 0
                        ? <div className='br-4px surface-850 px-16px py-8px cursor'
                            style={{border: '0.5px solid #40434E'}} onClick={handleNext}>
                            <p className='fs-12px text-300 fw-500'>Next</p>
                        </div>
                        : <div className='br-4px surface-850 px-16px py-8px cursor'
                            style={{border: '0.5px solid #40434E'}} onClick={handleSave}>
                            <p className='fs-12px text-300 fw-500'>Save</p>
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div className='pt-4'>
                    <TimelineWizard className="custom" 
                        items={steps} 
                        activeItem={steps[activeStep]} 
                        handleItemOnClick={handleItemOnClick} />
                    
                    {activeStep === 0
                        ? <div className='mt-3'>
                            <div className='mb-3'>
                                <p className='fs-12px text-300 fw-400 mb-1'>Name <span className='text-danger'>*</span> </p>
                                <input type='text' className='form-control v1-form-control' disabled
                                    placeholder='Enter product name' value={props.data.name || props.data.productName} />
                            </div>

                            <div className='mb-3'>
                                <p className='fs-12px text-300 fw-400 mb-1'>Display Name <span className='text-danger'>*</span> </p>
                                <input type='text' className='form-control v1-form-control' onChange={(e)=> setData({...data, displayName: e.target.value})}
                                    placeholder='Enter product display name' value={data.displayName} />
                            </div>

                            <div className='mb-3'>
                                <p className='fs-12px text-300 fw-400 mb-1'>Description <span className='text-danger'>*</span> </p>
                                <textarea className='from-control v1-form-textarea w-100 mt-2px' value={data.description}
                                    rows={4} placeholder='Enter product description' onChange={(e) => setData({...data, description: e.target.value})} />
                            </div>

                            <div className='mb-3'>
                                <p className='fs-12px text-300 fw-400 mb-1'>Select Guides </p>
                                <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                    className="v1select mt-1" placeholder="Attach guides to product" onChange={(e) => setSelectedGuides(e)}
                                    value={selectedGuides.length > 0 ? selectedGuides : null} isMulti
                                    options={guideOptions.map(o => ({...o, label: o.name, value: o._id}))}
                                />
                            </div>

                            {props?.data?.gateway !== GATEWAYS.KONG ? (
                                <div className='mb-3'>
                                    <div onClick={() => monetizationEnabled ? setData({...data, enableMonetization: !data.enableMonetization}) : null} 
                                    className={`${monetizationEnabled ? "cursor" : "o-05 cursor-not-allowed"} d-flex align-items-center  gap-8px`}>
                                        <GlobalIcons type={data.enableMonetization ? 'form-check' : 'form-uncheck'} />
                                        <div className='d-flex flex-column gap-2px'>
                                            <p className='fw-500 fs-14px text-100'>Enable Subscriptions</p>
                                            <p className='fw-400 fs-12px text-400'>On checking this option, developers are required to select a subscription during the app creation process for this product.</p>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className='px-16px py-18px surface-875 d-none'
                                style={{border: '1px solid rgba(9, 30, 66, 0.13)', borderLeft: '4px solid #31343C'}}>
                                <p className='text-200 fw-600 fs-14px'>Attributes</p>

                                <div>
                                    <AddScenarioBtn className='w-100 mt-2' type={'Attributes'} />
                                </div>

                                <div className='row mx-0 mt-3'>
                                    <div className='col-md-5 ps-0'>
                                        <p className='fs-12px fw-400 text-300'>Name</p>
                                    </div>
                                    <div className='col-md-7'>
                                        <p className='fs-12px fw-400 text-300'>Value</p>
                                    </div>
                                </div>

                                {[1,1].map((each) => <div className='row mx-0 mt-2'>
                                    <div className='col-md-5 ps-0'>
                                        <input type='text' className='form-control v1-form-control' />
                                    </div>
                                    <div className='col-md-7 dfaic gap-12px justify-content-between pe-0'>
                                        <input type='text' className='form-control v1-form-control' />

                                        <div className='surface-850 py-5px px-10px br-4px' style={{border: '0.5px solid #3F434E'}}>
                                            <Trash size={16} color='#E4E4E4' />
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    : <div className='mt-3'>
                        <div className='mb-3'>
                            <p className='text-300 fs-12px fw-400 mb-1'>Select Swagger <span className='text-danger'>*</span> </p>
                            <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                formatOptionLabel={(props) => <div className='dfaic gap-8px'>
                                    <p className='fw-500 fs-14px text-50'>{props?.label || props?.swaggerName}</p>

                                    {props.oasVersion ? (
                                        <div className='px-6px py-4px br-38px' style={{background: props.oasVersion === "2.0" ? '#08354c' : '#301A3A'}}>
                                            <p className='text-100 fw-600 fs-10px'>OAS {props.oasVersion}</p>
                                        </div>
                                    ) : null}
                                </div>}
                                isMulti
                                value={selectedSwaggers}
                                onChange={(e) => setSelectedSwaggers(e)}
                                className="v1select mt-1 h-auto" placeholder="Select Swagger"
                                options={swaggerOptions.map(o => ({...o, label: o.swaggerName, value: o.swaggerId}))}
                            />
                        </div>

                        {<div className='d-none surface-875 px-16px py-8px mb-1 dfaic justify-content-between'>
                            <p className='fs-10px text-400 fw-500'>Name / Method</p>
                            {objectDeepClone(selectedSwaggers.resources || []).filter(o => !o.selected).length > 0
                            ? <p className='cursor fs-10px text-50 fw-600'
                                onClick={() => {
                                    let _ss = objectDeepClone(selectedSwaggers.resources || [])
                                    _ss = _ss.map(o => ({...o, selected: true})) 
                                    setSelectedSwaggers({...selectedSwaggers, resources: objectDeepClone(_ss)})
                                }}>
                                Select All
                            </p>
                            : <p className='cursor fs-10px text-50 fw-600'
                                onClick={() => {
                                    let _ss = objectDeepClone(selectedSwaggers.resources || [])
                                    _ss = _ss.map(o => ({...o, selected: false})) 
                                    setSelectedSwaggers({...selectedSwaggers, resources: objectDeepClone(_ss)})
                                }}>
                                Deselect All
                            </p>}

                        </div>}

                        <div className='d-flex flex-column gap-10px'>
                        {selectedSwaggers && selectedSwaggers.length ? (
                            selectedSwaggers.map((swagger) => (
                                swagger?.swaggerId &&
                                <SwaggerComponent swagger={swagger} selectedResources={selectedResources} 
                                    handleOnResourceClick={handleOnResourceClick} handleSelectAll={handleSelectAll}/>
                            ))
                        ) : null}
                        </div>
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
