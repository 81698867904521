import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Loading from '../../../components/Loader/Loading';
import { objectDeepClone } from '@apiwiz/oas/services';
import Select from 'react-select';
import { getRatePlanValue, throwServerError } from '../../../utils/helper';
import { Plus, Trash, Wallet } from 'phosphor-react';
import { getToast } from '../../../components/Toast';
import API from '../../../api';

export default function PurchaseModal(props) {
    const [data, setData] = useState({})
    const [walletData, setWalletData] = useState({})
    const [productsData, setProductsData] = useState([])
    const [loading, setLoading] = useState(true)
    const [rechargeState, setRechargeState] = useState({
        isOpen: false, amount: 0
    })

    useEffect(() => {
        let _data = objectDeepClone(props.data || {})
        console.log('_data', _data)
        setData(objectDeepClone(_data))

        getData(_data.appId)
    }, [])

    const getData = (_id) => {
        API.getAppWallet(_id)
        .then(res => {
            const {data} = res.data
            setWalletData(objectDeepClone(data))
        }).catch(err => {
            setLoading(false)
        })

        API.getAppPurchases(_id)
        .then(res => {
            const {data} = res.data
            setProductsData(objectDeepClone(data))
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const handleSave = () => {
        setLoading(true)
        API.postAppPurchases(data.appId, {topup: rechargeState.amount})
        .then(res => {
            getData(data.appId)
            setLoading(false)
        }).catch(err => {
            setWalletData({...walletData, balance: walletData.balance + rechargeState.amount})
            setLoading(false)
        })
        setRechargeState({isOpen: false, amount: 0})
    }

    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='br-0px homeModalForLghtMode'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-content-subtle fw-500 fs-16px'>{data.name || ''}</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-content-subtle fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                {loading ? <Loading/>:<div className=''>

                    <div className='w-100 p-12px bg-secondary-200 dfaic justify-content-between br-4px mb-3'>
                        <div className='dfaic'>
                            <Wallet color='var(--content-color)' size={30} />

                            <div className='ms-2'>
                                <p className='fs-14px fw-400 text-content-color mb-2px'>Wallet Balance</p>

                                <p className='fs-16px fw-500 text-content-color'>
                                    ${parseFloat(walletData.balance || 0).toFixed(2)}
                                </p>
                            </div>
                        </div>

                        {!rechargeState.isOpen
                        ? <div className='cursor bg-base-color text-content-subtle px-16px py-6px br-4px fw-500 fs-12px text-300 px-3'
                            onClick={() => setRechargeState({amount: 0, isOpen: true})}>
                            Recharge
                        </div>
                        : <div className='dfaic'>
                            <input type='number'  value={rechargeState.amount} 
                                onChange={(e) => setRechargeState({...rechargeState, amount: e.target.value})}
                                className='form-control v1-form-control v1-form-control-public' />
                            <div className='mx-3 cursor bg-base-100 text-content-subtle px-16px py-6px br-4px fw-500 fs-12px text-300 px-3'
                                onClick={() => setRechargeState({amount: 0, isOpen: false})}>
                                Cancel
                            </div>
                            <div className='cursor bg-primary-color text-white px-16px py-6px br-4px fw-500 fs-12px text-300 px-3'
                                onClick={handleSave}>
                                Save
                            </div>
                        </div>}
                    </div>

                    {productsData.length > 0
                    ? null 
                    : <p className='fs-14px text-content-color fw-400'>
                        No Products Found 
                    </p>}
                </div>}
            </Modal.Body>
        </Modal>
    )
}
