import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Loading from '../../../../components/Loader/Loading';
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import { throwServerError } from '../../../../utils/helper';
import GlobalIcons from '../../../../components/Icons/GlobalIcons';
import { confirm } from '../../../../utils/helper';

export default function GCSConnectorModal(props) {
    const [bucketName, setBucketName] = useState('')
    const [projectid, setProjectid] = useState('')
    const [key, setKey] = useState('')
    const [isDefault, setIsDefault] = useState(false)

    const [disableSave, setDisableSave] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (props.isEdit && props.data){
            let _data = props.data || {}

            setBucketName(_data.bucketName || '')
            setProjectid(_data.projectId || '')
            setIsDefault(_data.isDefault || false)
        }
    }, [props.data])

    function handleDelete(){
        if (!confirm("Are you sure to delete this connector's data?")) return
        API.deleteGcsDetails()
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Deleted GCS details successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            // throwServerError(err)
        })

    }
    
    const handleSubmit = () => {
        let tempErrors = {}
        if (!bucketName){
            tempErrors['bucketName'] = 'Bucket Name is required'
        }
        if (!projectid){
            tempErrors['projectid'] = 'Project Id is required'
        }
        if (!key.name){
            tempErrors['key'] = 'Key upload is required'
        }

        setErrors(tempErrors)

        if (Object.keys(tempErrors).length) return

        var _formData = new FormData()
        _formData.append("file", key)

        let headers = {
            bucketName : bucketName, projectId : projectid, isDefault
        }

        let body = _formData

        if (props.isEdit){
            API.updateGCSDetails(body, headers)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Updated GCS details successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.postGCSDetails(body, headers)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'GCS details added successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} GCS Connector</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSubmit()
                        e.preventDefault()
                    }
                }}>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Bucket Name <span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control' placeholder='Enter bucket name'
                            value={bucketName} onChange={(e) => {
                                let _str = e.target.value 
                                setBucketName(_str)
                                setErrors({...errors, bucketName: _str.length > 0 ? false : 'Bucket Name is Required'})
                            }} />
                        
                        {errors.bucketName && <p className='text-danger mt-1 fs-11px'>
                            {errors.bucketName}
                        </p>}
                    </div>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Project Key <span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control' placeholder='Enter Project Key'
                            value={projectid} onChange={(e) => {
                                let _str = e.target.value
                                setProjectid(_str)
                                setErrors({...errors, projectid: _str.length > 0 ? false : 'Project Key is Required'})
                            }} />

                        {errors.projectid && <p className='text-danger mt-1 fs-11px'>
                            {errors.projectid}
                        </p>}
                    </div>

                    {console.log('key', key)}
                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Key <span className='text-danger'>*</span> </p>

                        <input type={'file'} id='downloadAssets' className='d-none' 
                            onChange={(e) => setKey(e.target.files[0])} />
                        <label className='d-flex align-items-center v1-border-1 mt-4px justify-content-between' htmlFor='downloadAssets'>
                            {key.name ?
                            <p className='text-200 px-2 text-truncate fs-12px'>
                                {key.name}
                            </p>
                            : <p className='text-400 px-2 text-truncate fs-12px'>
                                Upload key file
                            </p>}
                            <div className='btn-md-v1 br-tl-0px br-bl-0px'>
                                <p className='px-3 py-1 text-300 fs-12px'>Browse</p>
                            </div>
                        </label>

                        {errors.key && <p className='text-danger fs-10px mt-1'>
                            Key File is required
                        </p>}
                    </div>

                    <div className='d-flex align-items-center cursor' onClick={() => setIsDefault(!isDefault)}>
                        <GlobalIcons type={isDefault ? 'form-check' : 'form-uncheck'} />
                        <p className='fw-400 fs-14px text-100 ps-1'>Make it Default</p>
                    </div>
                    {(props.isEdit && props.getDeletePermissionGCS) && <div className='btn fs-12px text-danger br-6px'
                        onClick={handleDelete}
                        style={{position: 'absolute', bottom: 10, left: 10, border: '1px solid #e5484d'}}>
                        Delete
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
