import { Browsers, Plus } from 'phosphor-react'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setFooterMenu, setSocialMenu } from '../../../../app/Features/Layout/LayoutSlice'
import { getToast } from '../../../../components/Toast'
import { objectDeepClone, throwServerError, validateHeaderMenu, validateSocialData } from '../../../../utils/helper'
import EachRow, { EachSocialRow } from './EachFooterRow'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import API from '../../../../api'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import GetIcon from '../../../../components/GetIcon'

export default function RenderFooter({isEdit, setIsEdit, setLoading, callback}) {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const data = currentLayoutObject.footerMenu || []
    const socialData = currentLayoutObject.social || []
    const [showLogos, setShowLogos] = useState(false)
    const menuRef = useRef(null)

    useOutsideClick(menuRef, () => {
        setShowLogos(false)
    });

    const [dragStart, setDragStart] = useState(false)
    const [dragStart2, setDragStart2] = useState(false)

    const dispatch = useDispatch()

    const addMainItem = () => {
        if (data.length > 5){
            getToast({
                statusType: 'ERROR', message: 'You can only add 6 footer links'
            })
            return
        }
        let _overall = objectDeepClone(data || [])
        _overall.push({
            name: 'Title',
            url: '',
            urlType: '',
            submenu: [],
            visibleToRoles: '', 
            userGroup: '',
            isEdit: true
        })
        setIsEdit(true)
        dispatch(setFooterMenu(_overall))
    }

    const handleDelete = (ind) => {
        setLoading(true)
        let _data = objectDeepClone(currentLayoutObject.footerMenu)
        if (_data.length === 1){
            setLoading(false)
            getToast({statusType: 'ERROR', message: 'Atleast one footer value should be there'})
            return
        }

        if (ind < _data.length) {
            _data.splice(ind, 1)
        }
        dispatch(setFooterMenu(objectDeepClone(_data)))
        setIsEdit(true)
        setTimeout(() => {
            setLoading(false)
        }, 100);
    }

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        setDragStart(false)

        var updatedList = objectDeepClone(data);
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        dispatch(setFooterMenu(objectDeepClone(updatedList)))

        let {data: filteredData} = validateHeaderMenu(updatedList, false)

        API.putLayoutDetails({footerMenu: filteredData})
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Sequence updated successfully'
            })
            callback()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const handleSocialIconsDrop = (droppedItem) => {
        if (!droppedItem.destination) return;

        setDragStart2(false)

        var updatedList = objectDeepClone(socialData);
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        dispatch(setSocialMenu(objectDeepClone(updatedList)))

        let {data: filteredData} = validateSocialData(updatedList)

        API.putLayoutDetails({social: filteredData})
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Sequence updated successfully'
            })
            callback()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const socialIcons = ['twitter', 'facebook', 'linkedin', 'github', 'youtube', 'medium', 'reddit', 'instagram']

    const addSocial = (type) => {
        let _data = objectDeepClone(socialData)

        if (_data.filter(o => o.type === type).length){
            getToast({
                statusType: 'ERROR', message: `${type} is already added`
            })
            return
        }
        if (_data.length > 4){
            getToast({
                statusType: 'ERROR', message: 'Cannot add more than 5 Social Links'
            })
            return
        } 

        _data.push({
            type, isEdit: true, url: ''
        })

        setShowLogos(false)
        dispatch(setSocialMenu(objectDeepClone(_data)))
        setIsEdit(true)
    }

    const handleSocialDelete = (_id) => {
        let _data = objectDeepClone(socialData)
        _data.splice(_id, 1)
        dispatch(setSocialMenu(objectDeepClone(_data)))
    }
    
    return (
        <div className='h-100 overflow-scroll'>
            <div className='surface-base br-6px p-16px w-100 mb-3'>
                <div className='bg-base-100 d-flex align-items-start justify-content-between py-92px px-26px row mx-0'>
                    <div className='col-md-3 px-0'>
                        <img src={currentLayoutObject.logo || ''} alt='logo' width={100} 
                            style={{maxHeight: 40, objectFit: 'contain'}} />
                        
                        <div className='show-border mt-10px mb-12px'></div>
                        <div className='v1-border-top-1 fs-12px fw-400 text-content-color font-jost'>
                            © 2023 APIwiz
                        </div>
                        <div className='d-flex align-items-center mt-14px flex-wrap gap-8px'>
                            {socialData.map((each, i) => 
                                <GetIcon type={each.type} className='me-2' size={25}
                                    color={'var(--content-color)'} weight='fill' /> )}
                        </div>
                    </div>
                    {data.map((each, i) => <div className='col-md-3' key={i}>
                        <p className='fs-20px fw-600 text-content-color font-jost'> {each.name || ''}</p>
                        
                        <div className='mt-2'>
                            {(each.submenu && each.submenu.length > 0) && each.submenu.map((eachSubmenu, _i) => <div key={i} className='my-4px'>
                                <p className='fs-14px text-content-color fw-400 font-jost'>{eachSubmenu.name || ''}</p>
                            </div>)}
                        </div>
                    </div>)}
                </div>
            </div>

            <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Footer Content</p>
                </div>

                <div className='surface-850 px-12px py-8px d-flex align-items-center v1-border-1 btn br-5px'
                    onClick={() => addMainItem()}>
                    <p className='text-300 fs-12px fw-500'>Add Items</p>
                    <Plus size={12} className='ml-10px' color="#E4E4E4" />
                </div>
            </div>
            <DragDropContext onDragEnd={handleDrop}
                onDragStart={() => setDragStart(true)}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                    <div className='surface-base p-16px h-75 overflow-scroll noscrollbar'
                        {...provided.droppableProps} ref={provided.innerRef}>
                        { data.map((eachHeader, i) => (
                            <EachRow key={i} parentEdit={isEdit} parentSetEdit={setIsEdit} 
                                handleDelete={() => handleDelete(i)} headerIndex={i}
                                xDragStart={dragStart} />
                        ))}
                    </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className='mt-3'>
                <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                        <p className='text-200 fs-16px fw-500'>Social Icon Details</p>
                    </div>

                    <div className='surface-850 px-12px py-8px d-flex align-items-center v1-border-1 btn br-5px position-relative'>
                        <p className='text-300 fs-12px fw-500' onClick={() => setShowLogos(curr => !curr)}>Add Items</p>
                        <Plus size={12} className='ml-10px' color="#E4E4E4" />

                        {showLogos && <div className='surface-875 v1-border-1 px-8px py-12px position-absolute w-15vw br-4px overflow-scroll noscrollbar h-20vh'
                            style={{right: 0, top: 38}} ref={menuRef}>
                            
                            {socialIcons.map((eachIcon, i) => <div className='hover-surface-850 py-10px px-14px d-flex align-items-center'
                                key={i} onClick={() => addSocial(eachIcon)}>
                                <GetIcon type={eachIcon} color='#D0D5DD' weight='fill'
                                    className='mr-16px mt-1px' size={20} />
                                <p className='text-200 fs-14px fw-500 text-capitalize'>{eachIcon}</p>
                            </div>)}
                        </div>}
                    </div>
                </div>

                <DragDropContext onDragEnd={handleSocialIconsDrop}
                    onDragStart={() => setDragStart2(true)}>
                    <Droppable droppableId="social-icon-container">
                        {(provided) => (
                        <div className='surface-base p-16px h-30vh overflow-scroll noscrollbar'
                            {...provided.droppableProps} ref={provided.innerRef}>
                            
                            {socialData.map((each, i) => <EachSocialRow parentEdit={isEdit} parentSetEdit={setIsEdit}
                                key={i} xDragStart={dragStart2} handleDelete={() => handleSocialDelete(i)} socialIndex={i} />)}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}
