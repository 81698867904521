import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../../context/ThemeContext';
import OverallStyles from '../../../styles-config.json'
import { allowMenu, objectDeepClone, setColor } from '../../../utils/helper';
import { MoonStars, SunDim } from 'phosphor-react';
import { getCookie, removeCookie } from '../../../utils/cookie';
import { resetAuthenticatedState } from '../../../app/Features/Users/UserSlice';

export default function CustomNavbar() {
    const {theme, setTheme} = useTheme()
    const {isAuthenticated} = useSelector(state => state.user)
    const { firstRoute, currentUser } = useSelector((state) => state.user);
    const {currentLayoutObject} = useSelector((state) => state.layout)
    const {guestsetting} = useSelector((state) => state.setting)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        // dispatch(resetAuthenticatedState())
        // if (getCookie('itorix-token')) removeCookie('itorix-token')
        // if (getCookie('itorix-token-data'))removeCookie('itorix-token-data')

        // if(localStorage.getItem("ssoLogin")){
		// 	const cutoffIndex = guestsetting?.authEndpointUrl?.indexOf("/token?")
		// 	const _url = guestsetting?.authEndpointUrl?.slice(0, cutoffIndex)
		// 	const origin = window.location.origin
		// 	localStorage.setItem("sso-logout-source", `${origin}/login`)
		// 	window.location.href = `${_url}/logout`           
		// }
        // else{
        //     navigate('/logout')
        // }
        navigate('/logout')
    }

    const handleOnChange = () => {
        console.log('called')
        const _data = objectDeepClone(currentLayoutObject)
        let _newTheme = theme === "theme--light" ? "theme--dark" : "theme--light"
        let currentTheme = OverallStyles[_data.siteTemplateType || 'Purple Magic'][_newTheme]

        Object.keys(currentTheme).forEach(eachVariable => {
            setColor(eachVariable, currentTheme[eachVariable])
        })
        setColor('--currentBackground', _newTheme === 'theme--light' ? "white" : 'var(--base-color)')

        setTheme({
            type: "TOGGLE_THEME",
            payload: { theme: _newTheme},
        })
    }


    return (
        <>
            <Navbar expand="lg" className='customNavbar'>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={theme === 'theme--light' ? (currentLayoutObject.logo || '') : (currentLayoutObject.darklogo || '')} alt='Logo goes here'
                            style={{objectFit: 'contain', maxWidth: 280, maxHeight: 40}} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="align-items-center justify-content-end entireNavbarContainer">
                            {currentLayoutObject.headerMenu?.map(((each, _i) => {
                                if(!allowMenu({menu: each, isAuthenticated, currentUser})) return null;

                                if (each.submenu && each.submenu.length){
                                    let _submenus = each.submenu.filter(el => allowMenu({menu: el, isAuthenticated, currentUser}))
                                    if(!_submenus.length) return null;
                                    return (<NavDropdown title={each.name} id={each.name} key={_i} className='customNavbarDropdowns'>
                                        {_submenus.map(((eachSub, i) => 
                                            (eachSub.type === 'internal') 
                                            ? <div className='px-16px py-5px hover-effect-links'>
                                                <Link to={eachSub.url || "#"} className='text-content-color' style={{fontSize: '0.9rem'}}>
                                                    {eachSub.name}
                                                </Link>
                                            </div>
                                            : <NavDropdown.Item href={eachSub.url || "#"} className='noArrowIcon'
                                                target={'_blank'} key={i}>
                                                {eachSub.name}
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>)
                                } else {
                                    return (
                                        each.type === 'internal' ? 
                                        <div className='mobileMenuMargin'>
                                            <Link to={each.url || '#'} className='text-content-color fs-15px' key={_i}>
                                                {each.name}
                                            </Link>
                                        </div>
                                        : <Nav.Link href={each.url || '#'} target={'_blank'} key={_i}>
                                            {each.name}
                                        </Nav.Link>)
                                }
                            }))}

                            {isAuthenticated 
                            ? <NavDropdown title="Profile" className='customNavbarDropdowns mt-0px'>
                                <Link to={`/my-account`} className='dropdown-item text-dark'
                                    style={{padding: '0.5rem 1rem'}}>
                                    My Profile
                                </Link>
                                <Link to={firstRoute} className='dropdown-item text-dark'
                                    style={{padding: '0.5rem 1rem'}}>
                                    My Dashboard
                                </Link>
                                <NavDropdown.Item className='noArrowIcon' href="#" onClick={handleLogout}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                            : (!(guestsetting.disableLogin) && <Link to='/login' className='ms-4 navbarBtnPrimary'>Log In</Link>)}

                            <div className='darkModeToggle position-relative ms-3' onClick={() => handleOnChange()}>
                                {theme === 'theme--light'
                                ? <MoonStars className='sunIcon' size={20} color='var(--base-color)' />
                                : <SunDim className='moonIcon' size={20} color='var(--base-color)' />}
                                <input type="checkbox" className='' id="dark-mode"
                                    checked={theme === 'theme--dark'} 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}/>
                                <label htmlFor="dark-mode"></label>
                                <div className="background"></div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
