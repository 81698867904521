import API from "../../../../api";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { objectDeepClone } from "@apiwiz/oas/services";
import { Check, DotsThreeVertical, Minus } from "phosphor-react";
import monetizationV2 from "../../../../api/monetizationV2";
import { formatCtsDateWithoutUTC, getDeveloperAppCreatedAt, getTimeOfTheData, labelize } from "../../../../utils/helper";
import { APP_STATUS, MonetizationGradientColors } from "../../../../service/constants";
import BootstrapDropdown from "../../../../components/BootstrapDropdown";

const MonetizeAppCard = (props) => {
    const { data } = props;
    const { currentUser } = useSelector(state => state.user)

    const navigate = useNavigate()
    const handleNavigate = () => navigate(`/monetization/app/${data._id}`)

    const handleAppDelete = (e) => {
        if(props.handleAppDelete)
            props.handleAppDelete(data);
    }

    const handleSelect = (e) => {
        e.stopPropagation()
        console.log('data', data)

        API.getAppMapperById(data.name)
        .then(({data: mapperResponse}) => {
            monetizationV2.getMonetizationApps({
                developerEmail: currentUser.email,
                offset: 1,
                expand: true,
                pageSize: 20
            }).then(({data: res}) => {
                let _arr = objectDeepClone(res.data || [])
                _arr = _arr.filter(o => o.id === mapperResponse.data.monetizationAppId)
                if (_arr.length > 0){
                    navigate(`/monetization/app/${_arr[0].id}?appId=${_arr[0].appId}`)
                }
            }).catch(err => {
                console.log('err', err)
            })
        }).catch(err => {
            console.log('err', err)
            handleNavigate()
        })

        // if(props.handleAppDelete)
        // props.handleAppDelete(data);
    }

    return (
        <div className="cursor p-4px br-16px" onClick={()=> handleNavigate()} 
            style={{ background: MonetizationGradientColors[data.name.length % 10] , boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06)"}}>
            <div className="d-flex flex-column gap-10px py-16px px-12px br-11px bg-theme-base h-100 bg-theme-base" style={{boxShadow: '0px 0px 4px 0px rgba(255, 255, 255, 0.25)'}}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className={`card-info fw-500 app-badges ${labelize(data.status)} w-fit`}>{labelize(data.status)}</div>
                    {/* {showDelete ? (
                        <BootstrapDropdown
                            options={[
                                data?.status?.toLowerCase() === APP_STATUS.APPROVED ? { label: "View Detail", onSelect: (e) => handleNavigate(e) } : null,
                                { label: "Delete App", onSelect: (e) => handleAppDelete(e) }
                            ].filter((x) => x)}
                            align="end"
                            showCaretTop
                            menuClassName="ab-r-4px-important h-fit"
                        >
                            <div className="cursor">
                                <DotsThreeVertical size={24} />
                            </div>
                        </BootstrapDropdown>
                    ) : null} */}
                </div>
                <div className="card-title fw-600 text-theme-content-color text-truncate"
                    title={data.name || data?.appName || '-'}>{data.name || data?.appName || '-'}</div>
                <div className="card-desc fw-400 text-gray-subtext text-truncate-3">{data.description || data?.appDescription || '-'}</div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="text-theme-content card-info fw-600">Created on {formatCtsDateWithoutUTC(getDeveloperAppCreatedAt({app: data}))}</div>
                    <div className="text-theme-content card-info fw-600 d-flex align-items-cente gap-4px">
                        {data.monetizationAppId ? <>
                        <div>
                            <Check size={16} color={"#297C3B"} />
                        </div>
                        <div className="fs-14px fw-600">With Subscription</div></>: 
                        <><div>
                            <Minus size={16} color={"#F1A10D"} />
                        </div>
                        <div className="fs-14px fw-600">Without Subscription</div></>}
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonetizeAppCard;
