import { CaretDown as CaretDownSvg, CaretRight, Gear } from "phosphor-react";
import { useState } from "react";
import CaretDown from "../components/CaretDown";
import BootstrapDropdown from "../../BootstrapDropdown";
import { objectDeepClone } from "@apiwiz/oas/services";
import ConfigureAuth from "../ConfigureAuth";
import ManageVariablesModal from "./ManageVariablesModal";
import { BADGE_DELIMITER } from "../../../service/constants";

function SettingsInput(props) {
  let { field, required } = props;
  const [value, setValue] = useState("");
  const onChange = (e) => {
    let val = e.target.value;
    setValue(val);
  };
  return (
    <div className="h-32px d-flex settings-input">
      <div className="settings-input-label fs-11px">
        {field} {required ? <span className="text-danger">*</span> : null}
      </div>
      <input
        className="settings-input-value"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

const HtmlBadgeField = (props) => {
  let value = props.value || '';
  let arr = value.split(BADGE_DELIMITER.START)
  let objArr = []
  for(let a of arr)
  {
      let bArr = a.split(BADGE_DELIMITER.END)
      if(bArr.length>1) 
      {
          objArr.push({value: bArr[0], isBadge: true})
          objArr.push({value: bArr[1]})
      }
      else if(bArr.length === 1)
      {
          objArr.push({value: bArr[0]})
      }
  }
  return objArr.map(el => {
    return <span className={`${el.isBadge ? 'py-0 px-4px mx-2px fs-11px bg-theme-primary text-theme-base br-4px text-nowrap' : ''}`}>{el.value}</span>
})
}

function ComputedInput(props) {
  let { field } = props;
  return (
    <div className="h-32px d-flex settings-input">
      <div className="settings-input-label fs-11px">
        {field}
      </div>
      <div className="settings-input-value d-flex align-items-center overflow-scroll-x noscrollbar">
        <HtmlBadgeField value={props.value} />
      </div>
    </div>
  );
}




function SettingsDropdown(props) {
  let { field, required } = props;

  const onSelect = (el) => {
    if (el.value !== props.value && props.onSelected) props.onSelected(el);
  };

  let _style = {}
  if(props.labelWidth)
  {
    _style = {width: `calc(25% + ${props.labelWidth || 0}px)`}
  }

  return (
    <div className={`min-h-32px d-flex settings-input ${props.className || ""}`}>
      <div className="settings-input-label fs-11px" style={{..._style}}>
        {field} {required ? <span className="text-danger">*</span> : null}
      </div>
      <BootstrapDropdown
        className="theme-specific settings-input-value"
        options={(props.options || []).map(
          (el) => ((el.onSelect = () => onSelect(el)), el)
        )} isWrap
        value={props.value || ""}
      >
        <div className="w-100 h-100 cursor d-flex align-items-center justify-content-between">
          <div className="w--24px text-break">{props.value || ""}</div>
          <div>
            <CaretDownSvg
              size={16}
              weight="bold"
              color="var(--primary-color)"
            />
          </div>
        </div>
      </BootstrapDropdown>
    </div>
  );
}

export default function SettingsConfig(props) {
  const [open, setOpen] = useState(true);
  let serverConfig = props.config.server || {}; 
  return (
    <div className="border-left-theme-secondary pl-10px pr-6px py-6px bg-theme-primary-subtle br-4px d-flex flex-column gap-4px">
      <div
        className={`cursor d-flex justify-content-between align-items-center w-100 pr-6px br-4px h-34px ${
          open ? "" : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`d-flex align-items-center gap-4px`}>
          {!open ? (
            <CaretRight
              color="var(--primary-color)"
              size={13}
              className="mr-4px"
            />
          ) : (
            <CaretDown />
          )}
          <div
            className={`text-theme-primary fs-13px lh-21px h-21px ${
              open ? "fw-600" : ""
            }`}
          >
            {"Settings"}
          </div>
        </div>
      </div>
      {/* Setting Content */}
      {open ? (
        <div className="d-flex flex-column gap-13px">
          <ConfigureAuth />
          {/* <SettingsInput field={"Auth Key"} required /> */}
          <SettingsDropdown
            field={"Scheme"}
            value={props.config.scheme || ""}
            onSelected={(selected) => {
              if (props.onSchemeChange)
                props.onSchemeChange({
                  value: selected.value,
                });
            }}
            options={props.configOptions.schemes.map((el) => ({
              label: el,
              value: el,
            }))}
            required
          />

          <SettingsDropdown
            field={"Request Type"}
            value={props.config.reqContentType || ""}
            onSelected={(selected) => {
              if (props.updateConfig)
                props.updateConfig({
                  prop: "reqContentType",
                  value: selected.value,
                });
            }}
            options={props.configOptions.reqContentTypes.map((el) => ({
              label: el,
              value: el,
            }))}
            required
          />

          <SettingsDropdown
            field={"Response Type"}
            value={props.config.resContentType || ""}
            onSelected={(selected) => {
              if (props.updateConfig)
                props.updateConfig({
                  prop: "resContentType",
                  value: selected.value,
                });
            }}
            options={props.configOptions.resContentTypes.map((el) => ({
              label: el,
              value: el,
            }))}
            required
          />

          <div className={`d-flex gap-6px align-items-center w-100`}>
            <SettingsDropdown
              field={"Server"}
              value={serverConfig.url || ""}
              className={`${serverConfig.variables ? "w--24px" : "w-100" }`}
              labelWidth={serverConfig.variables ? 6 : 0}
              onSelected={(selected) => {
                delete selected.value;
                delete selected.label;
                if (props.updateConfig)
                  props.updateConfig({
                    prop: "server",
                    value: objectDeepClone(selected),
                  });
              }}
              options={props.configOptions.servers.map((el) => ({
                ...el,
                label: el.url,
                value: el.url,
              }))}
              required
            />
            {serverConfig.variables ? <ManageVariablesModal onSubmit={props.onVariablesSubmit || null} url={serverConfig.url} variables={serverConfig.variables} /> : null}
          </div>
          {serverConfig.__computedUrlHtml ? (
            <ComputedInput field="Computed Url" value={serverConfig.__computedUrlHtml || ""} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
