import { objectDeepClone } from '@apiwiz/oas/services';
import { ArrowSquareOut } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import API from '../../../api';
import { getRatePlanValue } from '../../../utils/helper';

export default function ProductsModal(props) {
    const [data, setData] = useState({})
    const [guideData, setGuideData] = useState({})

    useEffect(() => {
        let _data = objectDeepClone(props.data || {})
        console.log('_data', _data)
        setData(objectDeepClone(_data))
        getGuideProductBundles(_data?.productBundle?.name)
    }, [props.data])

    const getGuideProductBundles = (_name) => {
        API.getGuideProductBundles(_name)
        .then(res => {
            setGuideData(res.data.data)
        }).catch(err => {
            console.log('err', err)
        })
    }
    
    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px homeModalForLghtMode'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-content-subtle fw-500 fs-16px'>
                        {data?.productBundle?.name}
                    </p>

                    <div className='d-flex align-items-center'>
                        <div className='text-content-subtle fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div>
                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Product Bundle</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.productBundle?.name}
                        </p>
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-content-subtle'>Bundle Description</p>
                        <p className='fs-14px fw-500 text-content-color mt-1px'>
                            {data?.productBundle?.description}
                        </p>
                    </div>

                    <div className='mb-3 d-flex align-items-center w-100 justify-content-between'>
                        <a href={`/api-guides/${guideData.slug}`} target='_blank' 
                            className='w-49 bg-base-100 p-12px cursor dfaic justify-content-between'>
                            <p className='fw-500 fs-14px text-content-color'>View Docs</p>

                            <ArrowSquareOut size={14} color='var(--content-subtle)' />
                        </a>

                        <a href={data.productBundle?.termsOfUse} target='_blank' 
                            className='w-49 bg-base-100 p-12px cursor dfaic justify-content-between'>
                            <p className='fw-500 fs-14px text-content-color'>Terms Of Use</p>

                            <ArrowSquareOut size={14} color='var(--content-subtle)' />
                        </a>
                    </div>

                    <div className='mb-4'>
                        <p className='fs-12px fw-400 text-content-subtle'>Rate Plan</p>
                        <div className="mt-1">
                            {data?.ratePlans
                            ?.filter((ratePlan) => ratePlan.visibleToPortals)
                            ?.map((ratePlan) => {
                                return (
                                    <div className="px-12px py-8px bg-base-100 mb-1px">
                                        {getRatePlanValue({
                                            rateplan: ratePlan,
                                        }).map((pricing) => (
                                            <div className="fs-14px fw-400 text-content-subtle text-center">
                                                {pricing}
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='row mx-0 pb-2' style={{borderBottom: '1px solid var(--base-100)'}}>
                        <div className='col-md-4'>
                            <p className='fs-12px fw-400 text-content-subtle'>Products</p>
                        </div>
                        <div className='col-md-4'>
                            <p className='fs-12px fw-400 text-content-subtle'>Swagger</p>
                        </div>
                        <div className='col-md-4'></div>
                    </div>

                    {Object.keys(data?.specs || {})?.map((eachKey, i) => (<div className='row mx-0 py-16px' key={i}
                        style={{borderBottom: '1px solid var(--base-100)'}}>
                        <div className='col-md-4 my-auto'>
                            <p className='fs-12px fw-400 text-content-color'>
                                {eachKey}
                            </p>
                        </div>
                        <div className='col-md-4 d-flex flex-wrap'>
                            {data.specs[eachKey].map(eachSwagger => <p className='text-content-color fs-12px bg-base-100 p-4px'>
                                {eachSwagger.swaggerName}
                            </p>)}
                        </div>
                        <div className='col-md-4 my-auto'>
                            <a href={`/api-guides/${guideData.slug}`} target='_blank' 
                                className='ms-3 w-fit bg-secondary-200 cursor px-12px py-4px dfaic justify-content-between'>
                                <p className='fs-14px text-content-color fw-400 me-2'>Docs</p>

                                <ArrowSquareOut size={14} color='var(--content-color)' />
                            </a>
                        </div>
                    </div>))}
                </div>
            </Modal.Body>
        </Modal>
    )
}
