import { useEffect, useState } from "react";
import { DefaultIcon } from "../DefaultIcon";
import ForumAPI from "../../../../api/forum";
import Loading from "../../../../components/Loader/Loading";
import { useLocation } from "react-router";

export const PopularTagsList = ({
  selectedTags,
  setSelectedtags,
  setCurrentOffset,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [popularTags, setPopularTags] = useState([]);
  const [loading, setloading] = useState(false);
  const location = useLocation();

  const getPopularTags = () => {
    setloading(true);
    ForumAPI.getPopularTags()
      .then((res) => {
        setloading(false);
        setPopularTags(res.data);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPopularTags();
  }, []);

  const isTagSelected = (tag) => selectedTags.includes(tag);

  return (
    <>
      <div
        className="collapsible-btn"
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      >
        <DefaultIcon type={collapsed ? "caret-right" : "caret-down"} />

        <p className="collapsible-btn-text">Popular Tags</p>
      </div>
      {!collapsed && (
        <>
          {loading ? (
            <Loading />
          ) : (
            <div className="popular-tags-list__container">
              {!loading &&
                popularTags &&
                popularTags.length > 0 &&
                popularTags.map(({ tag }) => (
                  <div
                    className={`popular-tag ${
                      isTagSelected(tag) ? "selected" : ""
                    }`}
                    onClick={() => {
                      if (location.pathname === "/forum") {
                        const newTagList = new Set(selectedTags);
                        newTagList.add(tag);
                        setSelectedtags([...newTagList]);
                        setCurrentOffset(1);
                      }
                    }}
                  >
                    {tag}

                    {/* Themed close button  */}
                    <>
                      {isTagSelected(tag) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            if (location.pathname === "/forum") {
                              const newTagList = selectedTags.filter(
                                (tagName) => tagName !== tag
                              );
                              setSelectedtags(newTagList);
                            }
                          }}
                        >
                          <g clip-path="url(#clip0_2467_26819)">
                            <path
                              d="M9.375 2.625L2.625 9.375"
                              stroke="var(--primary-color)"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.375 9.375L2.625 2.625"
                              stroke="var(--primary-color)"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2467_26819">
                              <rect width="12" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </>
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
