import { WarningOctagon, X } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { Labelizev2, throwServerError } from '../../../utils/helper';
import { getToast } from '../../../components/Toast';
import API from '../../../api';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { objectDeepClone } from '@apiwiz/oas/services';
import { concernTexts } from '../../../service/constants';

export default function ReportIssueModal(props) {
    const {concernText} = props
    const {currentUser} = useSelector((state) => state.user);
    const [selectedInvoice, setSelectedInvoice] = useState(null)

    const [concernMessage, setConcernMessage] = useState('')
    const handleSave = () => {
        if (props.concernText === concernTexts.BILLING && !selectedInvoice){
            getToast({
                statusType: 'ERROR', message: 'Select a invoice for reconcilation request'
            })
            return
        }

        if (!concernMessage){
            getToast({
                statusType: 'ERROR', message: 'Concern message is required'
            })
            return
        }

        let _body = props.concernText === concernTexts.BILLING ? {
            invoiceId: selectedInvoice.invoiceId,
            invoiceRevisionId: selectedInvoice.id
        } : objectDeepClone(props.data || {})

        API.createConcern({
            ..._body, concernType: concernText, concernAbout: concernText, concernMessage
        }, currentUser.email).then(res => {
            getToast({
                statusType: 'SUCCESS', message: `Concern raised successfully for ${Labelizev2(concernText)}`
            })
            props.onHide()
        }).catch(err => {
            throwServerError(err)
        })
    }

    useEffect(() => {
        let _invocies = objectDeepClone(props.allInvoices)
        let selectedInvoice = null;
        if (props.data && props.data.invoiceId){
            selectedInvoice = _invocies.filter(o => o.invoiceId === props.data.invoiceId)
            if (selectedInvoice && selectedInvoice.length) selectedInvoice = selectedInvoice[0]
        }
        if (selectedInvoice){
            selectedInvoice = {...selectedInvoice, label: `${moment().month(selectedInvoice.month - 1).format("MMMM")} ${selectedInvoice.year}`, value: selectedInvoice.invoiceId}
        }
        setSelectedInvoice(selectedInvoice)
    }, [])
    

    return (
        <Modal
            {...props}
            size="lg"
            contentClassName='homeModalForLghtMode br-8px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body
                style={{ height: "calc(27vh)" }}
                className="overflow-scroll noscrollbar br-8px"
            >
                <div>
                    <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                        <p className='mb-0 text-consumer-200 fw-500 fs-24px'>
                            {props.concernText === concernTexts.BILLING ? 'Reconcilation Request' : 'Report Issue'}
                        </p>
                    </div>

                    {
                        props.concernText === concernTexts.BILLING ? <div className='row mx-0 mt-18px '>
                            <div className='col-md-3 pe-0 my-auto'>
                                <p className='fs-13px text-content-color fw-500'>Select Invoice</p>
                            </div>
                            <div className='col-md-9 mainSelect'>
                                <Select options={props.allInvoices?.map((eachInvoice) => ({...eachInvoice, label: `${moment().month(eachInvoice.month - 1).format("MMMM")} ${eachInvoice.year}`, value: eachInvoice.invoiceId}))} 
                                    className='mt-1 v1select' 
                                    value={selectedInvoice} 
                                    onChange={(e) => setSelectedInvoice(e)}
                                    classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }} />
                            </div>
                        </div> : <div className='row mx-0 mt-18px '>
                            <div className='col-md-3 pe-0 my-auto'>
                                <p className='fs-13px text-content-color fw-500'>Concern About</p>
                            </div>
                            <div className='col-md-9'>
                                <input
                                    required disabled value={Labelizev2(concernText)} style={{height: 34}}
                                    placeholder="Enter short message related to your concern"
                                    className="from-control v1-form-control v1-form-control-public w-100"
                                    />
                            </div>
                        </div>
                    }

                    <div className='row mx-0 mt-4'>
                        <div className='col-md-3 pe-0 my-auto'>
                            <p className='fs-13px text-content-color fw-500 mb-2'>Message</p>
                        </div>
                        <div className='col-md-9'>
                            <textarea rows={2}
                                required value={concernMessage}
                                onChange={(e) => setConcernMessage(e.target.value)}
                                placeholder="Enter short message related to your concern"
                                className="from-control v1-form-textarea v1-form-control-public w-100"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='dfaic justify-content-between'>
                    <div></div>

                    <div className='dfaic gap-24px'>
                        <p className='fw-600 text-consumer-200 fs-14px cursor' onClick={props.onHide}>
                            Cancel
                        </p>

                        <div className='strokes-800-border v2 cursor px-6px py-4px br-8px gap-4px dfaic jcc'
                            onClick={handleSave}>
                            <WarningOctagon size={16} className='text-consumer-300' />
                            <p className='fw-600 fs-14px text-consumer-300'>Report Issue</p>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
