import React, {useState, useEffect} from 'react'
import { useOutletContext } from 'react-router'
import { BodyLayout } from '../../../layout/Base'
import BrandingSettings from './components/BrandingSettings'
import ColorSettings from './components/ColorSettings'
import DataSettings from './components/DataSettings'
import { useSelector } from 'react-redux'

export default function ManageSite() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const { firstRoute } = useSelector((state) => state.user);

    useEffect(() => {
        setTitle("Theme")
        setHasHeader(true)
        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Manage Themes'}
        ])

    }, [])

    return (
        <BodyLayout>
            <div className='fadeIn d-block mx-auto h-100 w-70 overflow-scroll noscrollbar'>
                <BrandingSettings />

                <ColorSettings />

                <DataSettings />
            </div>
        </BodyLayout>
    )
}
