import { useEffect, useState } from "react";
import API from "../../../api/monetization";
import { ProductApprovalRow } from "./ProductApprovalRow";
import { objectDeepClone } from "../../../utils/helper";
import GlobalIcons from "../../../components/Icons/GlobalIcons";

export const CredentialAccordian = (props) => {
  const {
    credentials,
    selectedApp,
    statusChangeCallback,
    onModalClose,
    isAzure,
    isKong,
  } = props;

  const [collapsed, setCollapsed] = useState(true);

  const { consumerKey, apiProducts: _apiProducts } = credentials || {};

  const [apiProducts, setApiProducts] = useState(_apiProducts);

  const updateProductStatus = ({
    action,
    appName,
    productName,
    consumerKey,
  }) => {
    API.updateProductStatus({
      action,
      appName,
      productName,
      consumerKey,
    })
      .then((res) => {
        if (statusChangeCallback) {
          statusChangeCallback();
        }
        // onModalClose()

        let _apiProducts = objectDeepClone(apiProducts);
        _apiProducts = _apiProducts?.map((product) =>
          product.apiproduct === productName
            ? { ...product, status: `${action}d` }
            : product
        );
        setApiProducts(_apiProducts);

        return res;
      })
      .catch((err) => {
        console.log("update product status err: ", err);
        return err;
      });
  };

  useEffect(() => {
    if (_apiProducts) {
      setApiProducts(_apiProducts);
    } else if (isAzure) {
      const _apiProducts = [
        {
          apiproduct: selectedApp.productName,
          status: "Approved",
        },
      ];
      setApiProducts(_apiProducts);
    } else if (isKong) {
      const _apiProducts = [
        {
          apiproduct: selectedApp.productsDto.productName,
          status: "Approved",
        },
      ];
      setApiProducts(_apiProducts);
    }
  }, [selectedApp]);

  return (
    <div className="credential-accordion__container">
      <div
        className="credential-accordian-header"
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      >
        <GlobalIcons type={collapsed ? "caret-top" : "caret-right"} />

        <div className="d-flex flex-column gap-6px">
          <p className="consumer-details m-0">
            <span style={{ fontWeight: 500 }}>Consumer Key:</span>{" "}
            <span>{consumerKey ? consumerKey : ""}</span>
          </p>
          {credentials?.consumerSecret ? (
            <p className="consumer-details m-0">
              <span style={{ fontWeight: 500 }}>Consumer Secret:</span>{" "}
              <span>{credentials?.consumerSecret || ""}</span>
            </p>
          ) : null}
        </div>

        <div className="products-count-label">
          <p className="text-content-color">
            <span>{apiProducts ? apiProducts?.length : 0}</span> Products
          </p>
        </div>
      </div>

      {!collapsed && (
        <div className="credential-accordian-contents">
          <div className="accordian-content-header">
            <p className="m-0 products-header">Products</p>
            <p className="m-0 status-header">Status</p>
          </div>

          {apiProducts &&
            apiProducts?.map((product) => (
              <ProductApprovalRow
                product={product}
                consumerKey={consumerKey}
                selectedApp={selectedApp}
                updateProductStatus={updateProductStatus}
                key={product.apiproduct}
                isAzure={isAzure}
                isKong={isKong}
              />
            ))}
        </div>
      )}
    </div>
  );
};
