export const DefaultIcon = ({ type }) => {
  return (
    <>
      {type === "home" && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1805_8106)">
            <path
              d="M9.5 13V9.99999C9.5 9.86738 9.44732 9.74021 9.35355 9.64644C9.25979 9.55267 9.13261 9.49999 9 9.49999H7C6.86739 9.49999 6.74021 9.55267 6.64645 9.64644C6.55268 9.74021 6.5 9.86738 6.5 9.99999V13C6.5 13.1326 6.44732 13.2598 6.35355 13.3535C6.25979 13.4473 6.13261 13.5 6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3535C2.55268 13.2598 2.5 13.1326 2.5 13V7.22124C2.50001 7.15156 2.51458 7.08265 2.54278 7.01894C2.57098 6.95522 2.61218 6.8981 2.66375 6.85124L7.66375 2.12999C7.75581 2.0462 7.87582 1.99976 8.00031 1.99976C8.1248 1.99976 8.24481 2.0462 8.33687 2.12999L13.3369 6.85124C13.3884 6.8981 13.4296 6.95522 13.4578 7.01894C13.486 7.08265 13.5006 7.15156 13.5006 7.22124V13C13.5006 13.1326 13.4479 13.2598 13.3542 13.3535C13.2604 13.4473 13.1332 13.5 13.0006 13.5H10C9.86739 13.5 9.74021 13.4473 9.64645 13.3535C9.55268 13.2598 9.5 13.1326 9.5 13Z"
              stroke="var(--primary-color)"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1805_8106">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      {type === "caret-down" && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1811_107448)">
            <path
              d="M13 6L8 11L3 6"
              stroke={`var(--primary-color)`}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1811_107448">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      {type === "caret-right" && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1829_107521)">
            <path
              d="M6 3L11 8L6 13"
              stroke={`var(--primary-color)`}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1829_107521">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="matrix(0 -1 1 0 0 16)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
      {type === "plus" && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1730_106582)">
            <path
              d="M3.125 10H16.875"
              stroke={`var(--primary-color)`}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 3.125V16.875"
              stroke={`var(--primary-color)`}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1730_106582">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
