import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

export const AutoLogout = ({ children }) => {
  let timer = null;

  const { guestsetting } = useSelector((state) => state.setting)
  const {idleTimeout} = guestsetting

  const { isAuthenticated } = useSelector(state => state.user)

  const _idleTimeout = idleTimeout ?  idleTimeout : 15
  
  const navigate = useNavigate()

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const logoutAction = () => {
    if(isAuthenticated){
      window.alert(`Logging out due to no activity.`)

      navigate(`/logout`)
    }
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();

      Object.values(events).forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });

      logoutAction();
    }, _idleTimeout * 60 * 1000);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, [idleTimeout, guestsetting]);

  return children;
};
