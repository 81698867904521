import { Browsers } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setContents } from '../../../../app/Features/Layout/LayoutSlice'
import { getToast } from '../../../../components/Toast'
import { confirm, objectDeepClone } from '../../../../utils/helper'
import ImageCard from './ImageCard'
import CustomHero3 from '../../../Dashboards/components/CustomHero3'
import GlobalIcons from '../../../../components/Icons/GlobalIcons'

export default function RenderHero3({isEdit, setIsEdit, setLoading, sectionIndex}) {
    const {currentLayoutObject, backupCurrentLayout} = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [data, setData] = useState({})

    useEffect(() => {
        if (currentLayoutObject.contents && currentLayoutObject.contents[sectionIndex]){
            setData(currentLayoutObject.contents[sectionIndex].payload || {})
        }
    }, [])

    const handleCancel = () => {
        if (!confirm("Are you sure? any new data might be lost in this section?")) return

        let _prevObj = objectDeepClone(backupCurrentLayout)
        setData(_prevObj.contents[sectionIndex].payload || {})
    }

    const handleSave = () => {
        let _data = objectDeepClone(data)
        if (!_data.heading || !_data.description || !_data.primaryCTA?.label || !_data.primaryCTA.url){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the required details'
            })
            return
        }
        if (!_data.imageUrl || !_data.imageUrlDark){
            getToast({
                statusType: 'ERROR', message: 'Please give both light and dark mode images'
            })
            return
        }
        _data.isEdit = false
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
        setData(objectDeepClone(_data))
    }

    const handleEdit = () => {
        let _data = objectDeepClone(data)
        _data.isEdit = true 
        setIsEdit(true)
        setData(objectDeepClone(_data))
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
    }
    
    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <div className='' onKeyDown={(e) => {
            setIsCtrl(['Control', 'Meta'].includes(e.key))
            if (isCtrl && e.key?.toLowerCase() === 's') {
                handleSave()
                e.preventDefault()
            }
        }}>
            <div className='surface-base p-16px w-100'>
                <div className='bg-currentBackground overflow-scroll noscrollbar row m-0' style={{zoom: 0.4, height: 700}}>
                    <CustomHero3 data={data} fromEditor />
                </div>
            </div>

            <div className='surface-875 d-flex align-items-center justify-content-between p-12px mt-3'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Content</p>
                </div>
                {data.isEdit
                ? <div className='d-flex align-items-center'>
                    <p className='text-300 fs-12px fw-500 me-3 cursor'
                        onClick={handleCancel}>Cancel</p>

                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleSave}>
                        Save
                    </div>
                </div>
                : <div>
                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleEdit}>
                        Edit
                    </div>
                </div>}
            </div>

            <div className='surface-base br-4px p-16px w-100 h-70vh overflow-scroll noscrollbar'>
                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Tag </p>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.tagName}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, tagName: e.target.value})} />
                </div>
                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Hero Heading <span className='text-danger'>*</span></p>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.heading}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, heading: e.target.value})} />
                </div>

                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Hero Description <span className='text-danger'>*</span></p>
                    <textarea type={'text'} rows={4}
                        className='from-control v1-form-textarea mt-1 w-100' value={data.description}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, description: e.target.value})} />
                </div>

                <div className='surface-850 p-16px mb-3'>
                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Primary CTA Text <span className='text-danger'>*</span></p>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100'
                            value={data.primaryCTA?.label} 
                            disabled={!data.isEdit} 
                            onChange={(e) => setData({...data, primaryCTA: {...data.primaryCTA, label: e.target.value}})} />
                    </div>

                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Primary CTA URL <span className='text-danger'>*</span></p>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100' 
                            value={data.primaryCTA?.url} 
                            disabled={!data.isEdit} 
                            onChange={(e) => setData({...data, primaryCTA: {...data.primaryCTA, url: e.target.value}})} />
                    </div>

                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Secondary CTA Text </p>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100' 
                            value={data.secondaryCTA?.label} 
                            disabled={!data.isEdit} 
                            onChange={(e) => setData({...data, secondaryCTA: {...data.secondaryCTA, label: e.target.value}})} />
                    </div>

                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Secondary CTA URL </p>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100' 
                            value={data.secondaryCTA?.url} 
                            disabled={!data.isEdit} 
                            onChange={(e) => setData({...data, secondaryCTA: {...data.secondaryCTA, url: e.target.value}})} />
                    </div>

                    {data.secondaryCTA?.label && <div className='mb-3 w-fit dfaic cursor'
                        onClick={() => setData({...data, secondaryCTA: {
                            ...data.secondaryCTA, applyColor: !data.secondaryCTA?.applyColor }
                        })}>
                        <GlobalIcons type={data.secondaryCTA?.applyColor ? 'form-check' : 'form-uncheck'} />
                        <p className='text-300 fs-12px fw-400 ps-2'> Apply Background </p>
                    </div>}
                </div>

                <div className='row mx-0'>
                    <div className='col-md-6'>
                        <p className='text-300 fs-12px fw-400 mb-1'>Light Mode Image <span className='text-danger'>*</span> </p>
                        <ImageCard imgUrl={data.imageUrl} name='heroLogo' width={'100%'} isEdit={data.isEdit}
                            setUrl={(url) => setData({...data, imageUrl: url})} />
                    </div>
                    <div className='col-md-6'>
                        <p className='text-300 fs-12px fw-400 mb-1'>Dark Mode Image <span className='text-danger'>*</span> </p>
                        <ImageCard imgUrl={data.imageUrlDark} name='heroLogo' width={'100%'} isEdit={data.isEdit}
                            setUrl={(url) => setData({...data, imageUrlDark: url})} />
                    </div>
                </div>
            </div>

            <div className='surface-850 p-3 m-3'>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Count </p>

                        <p className='text-400 fs-12px fw-400'>{data.panelSection?.heading?.length} / 5</p>
                    </div>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.panelSection?.heading}
                        disabled={!data.isEdit} onChange={(e) => {
                            if (e.target.value?.length > 5) return
                            setData({...data, panelSection: {
                                ...data.panelSection, heading: e.target.value
                            }})
                        }} />
                </div>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Text </p>

                        <p className='text-400 fs-12px fw-400'>{data.panelSection?.description?.length} / 15</p>
                    </div>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.panelSection?.description}
                        disabled={!data.isEdit} onChange={(e) => {
                            if (e.target.value?.length > 15) return
                            setData({...data, panelSection: {
                                ...data.panelSection, description: e.target.value
                            }})
                        }} />
                </div>

                <div className='row'>
                    <div className='row mx-0'>
                        <div className='col-md-4'>
                            <p className='text-300 fs-12px fw-400 mb-1'>Image URL 1 <span className='text-danger'>*</span> </p>
                            <ImageCard imgUrl={data.panelSection?.imageUrl1} name='heroLogo' width={'100%'} isEdit={data.isEdit}
                                setUrl={(url) => setData({...data, panelSection: {
                                    ...data.panelSection, imageUrl1: url
                                }})} />
                        </div>
                        <div className='col-md-4'>
                            <p className='text-300 fs-12px fw-400 mb-1'>Image URL 2 <span className='text-danger'>*</span> </p>
                            <ImageCard imgUrl={data.panelSection?.imageUrl2} name='heroLogo' 
                                width={'100%'} isEdit={data.isEdit}
                                setUrl={(url) => setData({...data, panelSection: {
                                    ...data.panelSection, imageUrl2: url
                                }})} />
                        </div>
                        <div className='col-md-4'>
                            <p className='text-300 fs-12px fw-400 mb-1'>Image URL 3 <span className='text-danger'>*</span> </p>
                            <ImageCard imgUrl={data.panelSection?.imageUrl3} name='heroLogo' width={'100%'} isEdit={data.isEdit}
                                setUrl={(url) => setData({...data, panelSection: {
                                    ...data.panelSection, imageUrl3: url
                                }})} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
