import { useRef, useState } from "react";

import { LoadCanvasTemplate, validateCaptcha } from "./MyCaptcha";

const Captcha = ({ matched, setMatched }) => {
  const [userInput, setuserInput] = useState("");
  const [trialCount, settrialCount] = useState(0);

  const captchaCheckHandler = (evt) => {
    evt.preventDefault()
    let user_captcha = userInput;
    settrialCount(prev => prev+ 1)

    if (validateCaptcha(user_captcha) == true) {
      setMatched(true);
    } else {
      setMatched(false);
    }
  };

  return (
    <div className="mt-4 w-50">
      <div className="mt-3 mb-3 w-100">
        <div className="mt-3 captcha-text-container">
          <LoadCanvasTemplate
            characterCount={8}
            reloadColor="red"
            reloadText={"reload"}
            matched={matched}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.25" y="0.25" width="31.5" height="31.5" rx="3.75" fill="white"/>
                <path d="M19.0125 14.2312H22.0125V11.2312" stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.1125 12.1125C12.6227 11.6015 13.2287 11.1961 13.8958 10.9195C14.5628 10.6429 15.2779 10.5006 16 10.5006C16.7221 10.5006 17.4372 10.6429 18.1042 10.9195C18.7713 11.1961 19.3773 11.6015 19.8875 12.1125L22.0125 14.2312" stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.9875 17.7688H9.9875V20.7688" stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.8875 19.8875C19.3773 20.3985 18.7713 20.8039 18.1042 21.0805C17.4372 21.3571 16.7221 21.4994 16 21.4994C15.2779 21.4994 14.5628 21.3571 13.8958 21.0805C13.2287 20.8039 12.6227 20.3985 12.1125 19.8875L9.9875 17.7688" stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="0.25" y="0.25" width="31.5" height="31.5" rx="3.75" stroke="#B8B8B8" stroke-width="0.5"/>
            </svg>
          </LoadCanvasTemplate>
        </div>

        <div className="mt-3">
          <div className="captcha-input-container">
            <input
              placeholder="Enter Captcha"
              id="user_captcha_input"
              name="user_captcha_input"
              className={`form-control w-70 v1-form-control ${!matched && trialCount > 0 ? "error m-0" : ""}`}
              type="text"
              style={{width: 310}}
              value={userInput}
              onChange={(e) => {
                setuserInput(e.target.value);
              }}
              readOnly={matched}
            />
            <button
              className={`check-captcha-btn btn w-100 fs-12px ${matched ? "matched" : ""}`}
              onClick={captchaCheckHandler}
              disabled={matched}
            >
              {
                matched ? 'Verified' : "Check"
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Captcha;
