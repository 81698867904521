import { Line } from "react-chartjs-2";
import { useTheme } from "../../context/ThemeContext";
import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { NumberFormatter, debounceFn, defaultCurrencyFn } from "../../utils/helper";
import { Chart as ChartJS, CategoryScale, PointElement, Filler, LineElement, LinearScale, Title, Tooltip, Legend } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export function LineChart(props) {
    const { currentUser } = useSelector((state) => state.user)
    const { theme } = useTheme();
    const barChartRef = useRef(null);
    const [data, setData] = useState(null);
    const tooltipRef = useRef(null);

    const hideTooltip = (el) => {
        el.style.display = "none";
    }

    const hideTooltipDebounceFn = useCallback(debounceFn(hideTooltip,200), []);
    
    const generateTooltipHTML = (context) => {
        const tooltipEl = tooltipRef.current;
        const tooltipModel = context.tooltip;
    
        if (tooltipModel.opacity === 0) {
          hideTooltipDebounceFn(tooltipEl);
          return;
        }

        if(props.customTooltip) props.customTooltip({tooltipEl, tooltipModel})
        
      };

    const options = {
        animationEnabled: true,
        animation: {
            duration: 1000,
            easing: "linear",
        },
        interaction: {
            intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: props?.showTooltip || false,
                external: props?.customTooltip ? generateTooltipHTML : null,
                position: "average",
                xAlign: "left",
                yAlign: "center",
                bodyAlign: "center",
                titleAlign: "center",
                titleFont: {
                    font: {
                        size: 24,
                    },
                },
                caretPadding: -8,
                padding: 8,
                titleMarginBottom: 8,
                displayColors: false,
            },
            legend: {
                display: false,
            },
            chartAreaBorder: {
                borderColor: theme == "theme--light" ? "#B8B8B8" : "#363943",
                borderWidth: 1,
            },
            title: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                backgroundColor: '#E5FBEB',
                borderColor: '#E5FBEB',
            },
            point: {
                backgroundColor: '#E5FBEB',
                hoverBackgroundColor: '#E5FBEB',
                pointStyle: 'circle',
                hoverRadius: 5
            },
        },
        scales: {
            x: {
                title: {
                    display: props?.xAxisTitle ? true : false,
                    color: theme == "theme--light" ? `#272727` : "#C2C2C2",
                    text: props?.xAxisTitle || null,
                },
                grid: {
                    display: true,
                    color: theme === "theme--light" ? "#D8DEEB" : "#363943",
                    borderColor: theme === "theme--light" ? "#D8DEEB" : "#363943",
                },
                ticks: {
                    color: theme === "theme--light" ? "#666" : "#c2c2c2",
                    display: true,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                title: {
                    display: props?.yAxisTitle ? true : false,
                    color: theme === "theme--light" ? `#272727` : "#C2C2C2",
                    text: props?.yAxisTitle || "",
                },
                grid: {
                    display: true,
                    color: theme === "theme--light" ? "#D8DEEB" : "#363943",
                    borderColor: theme === "theme--light" ? "#D8DEEB" : "#363943",
                },
                ticks: {
                    color: theme === "theme--light" ? "#666" : "#c2c2c2",
                    stepSize: props?.stepSize || null,
                    callback: function(label, index, labels) {
                        return props.currencyInYAxisLabel ? `${defaultCurrencyFn(currentUser?.billing?.billingCurrency)} ${NumberFormatter(label)}` : label;
                    }
                },
            },
        },
    };

    const updateChartData = (data) => {
        return {
            labels: props.labels,
            datasets: data.map((x, i) => {
                return {
                    customData: x.customData || {},
                    label: x.name,
                    data: x.value,
                    tension: 0.4,
                    fill: x?.options.fill || false,
                    borderColor: x?.options.borderColor || null,
                    radius: x?.options.radius || null,
                    backgroundColor: x.options.bgColor
                        ? function (context) {
                              const chart = context.chart;
                              const { ctx, chartArea } = chart;

                              if (!chartArea) {
                                  return;
                              }

                              const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                              gradient.addColorStop(0.5, x.options.bgStart);
                              gradient.addColorStop(1, x.options.bgEnd);

                              return gradient;
                          }
                        : x.options.borderColor,
                };
            }),
        };
    };

    useEffect(() => {
        const chart = barChartRef.current;

        if (!chart) {
            return;
        }
    }, []);

    useEffect(() => {
        setData(updateChartData(props.data));
    }, [props.data]);

    return data ? (
        <div className="position-relative h-100">
            <Line options={options} data={data} />
            <div ref={tooltipRef} className="tooltip-container" style={{ display: 'none' }}></div>
        </div>
    ) : null;
}
