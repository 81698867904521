import { Navbar } from "./Components/Navbar";
import { CategoryList } from "./Components/CategoryList";
import { DefaultIcon } from "./Components/DefaultIcon";
import { PopularTagsList } from "./Components/PopularTagsList";
import { useEffect, useRef, useState } from "react";
import { NewCategoryModal } from "./Components/NewCategoryModal";
import { CreatePostModal } from "./Components/CreatePostModal";
import { useSelector } from "react-redux";
import ForumAPI from "../../api/forum";
import { Outlet, useLocation, useNavigate } from "react-router";
import { getToast } from "../../components/Toast";
import Loading from "../../components/Loader/Loading";
import { PlaceHolderProfile } from "./Components/PlaceHolderProfile";
import { EditPostModal } from "./Components/EditPostModal";
import { objectDeepClone } from "../../utils/helper";
import { ForumIcons } from "./Components/ForumIcons";
import GlobalIcons from "../../components/Icons/GlobalIcons";
import { Plus } from "phosphor-react";
import { useObserver } from "../../utils/customHooks";
import API from "../../api";
import { ThemeToggler } from "../../components/ThemeToggler";

export const ForumLanding = () => {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState({
    show: false,
    postData: null,
  });

  const [categoryData, setCategoryData] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.user);
  const [allPosts, setAllPosts] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(1);
  const [postPagination, setPostPagination] = useState({});
  const [postDataLoading, setPostDataloading] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const location = useLocation();
  const [activeTabType, setActiveTabType] = useState("ALL");
  const [sortedOrder, setSortedOrder] = useState("Latest");
  const [infiniteLoader, setInfiniteLoader] = useState(false);
  const [selectedTags, setSelectedtags] = useState([]);

  const postsListingRef = useRef();

  const isScrollToBottom = useObserver(postsListingRef);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const getCategoryData = () => {
    setCategoryLoading(true);
    ForumAPI.getAllCategory()
      .then((res) => {
        setCategoryLoading(false);
        setCategoryData(res);
      })
      .catch((err) => {
        setCategoryLoading(false);
        console.log(err);
      });
  };

  const getAllPosts = ({ offset = currentOffset }) => {
    if (offset < 2) {
      setPostDataloading(true);
    } else {
      setInfiniteLoader(true);
    }
    ForumAPI.getPosts({ offset, filter: sortedOrder })
      .then((res) => {
        if (offset < 2) {
          setPostDataloading(false);
        } else {
          setInfiniteLoader(false);
        }
        if (offset > 1) {
          setAllPosts((prev) => [...prev, ...res.data.data]);
        } else {
          setAllPosts(res.data.data);
        }
        const { page, pageSize, total, totalPages } = res.data;
        setPostPagination({
          page,
          pageSize,
          total,
          totalPages,
        });
      })
      .catch((err) => {
        if (offset < 2) {
          setPostDataloading(false);
        } else {
          setInfiniteLoader(false);
        }
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch posts, Try again later!",
        });
      });
  };

  const getTrendingPosts = () => {
    setPostDataloading(true);
    ForumAPI.getPostsByFilter({ offset: 1, filter: "Trending" })
      .then((res) => {
        setPostDataloading(false);
        console.log("trending posts: ", res.data);
        setAllPosts(res.data.data);
        const { page, pageSize, total, totalPages } = res.data;
        setPostPagination({
          page,
          pageSize,
          total,
          totalPages,
        });
      })
      .catch((err) => {
        setPostDataloading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch posts, Try again later!",
        });
      });
  };

  const getPostsByCategory = () => {
    setPostDataloading(true);
    ForumAPI.getPostsByCategory({ category: activeCategory })
      .then((res) => {
        setPostDataloading(false);
        setAllPosts(res.data.data);
        const { page, pageSize, total, totalPages } = res.data;
        setPostPagination({
          page,
          pageSize,
          total,
          totalPages,
        });
      })
      .catch((err) => {
        setPostDataloading(false);
        console.log(err);
      });
  };

  const getPostsByTags = () => {
    setPostDataloading(true);
    ForumAPI.getPostsByTags({
      tags: selectedTags.join(","),
      offset: currentOffset,
    })
      .then((res) => {
        setPostDataloading(false);
        setAllPosts(res.data.data);
        const { page, pageSize, total, totalPages } = res.data;
        setPostPagination({
          page,
          pageSize,
          total,
          totalPages,
        });
      })
      .catch((err) => {
        setPostDataloading(false);
        console.log(err);
      });
  };

  const onEditSuccess = ({ updatedData }) => {
    let _updatedPosts = objectDeepClone(allPosts);
    _updatedPosts = _updatedPosts.map((post) =>
      post._id === updatedData._id ? updatedData : post
    );
    setAllPosts(_updatedPosts);
  };

  const onDeleteSuccess = ({ postId }) => {
    ForumAPI.deletePost({ postId })
      .then((res) => {
        let _updatedPosts = objectDeepClone(allPosts);
        _updatedPosts = _updatedPosts.filter((post) => post._id !== postId);
        setAllPosts(_updatedPosts);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Delete Post",
        });
      });
  };

  useEffect(() => {
    getCategoryData();
    if (localStorage.getItem("active_category")) {
      setActiveCategory(localStorage.getItem("active_category"));
    } else {
      getAllPosts({ offset: currentOffset });
    }

    API.trackSessions('FORUM')
    .then(res => console.log('res', res))
    .catch(err => console.log('err', err))
  }, []);

  useEffect(() => {
    if (
      currentOffset <= postPagination.totalPages &&
      !activeCategory &&
      !selectedTags.length
    ) {
      getAllPosts({ offset: currentOffset });
    }
  }, [currentOffset]);

  useEffect(() => {
    getAllPosts({ offset: 1 });
  }, [sortedOrder]);

  useEffect(() => {
    if (activeCategory) {
      getPostsByCategory();
      setActiveTabType("ALL");
    } else {
      getAllPosts({ offset: currentOffset });
    }
  }, [activeCategory]);

  const onCategoryDelete = ({ id }) => {
    setShowCategoryModal({ show: false });
    ForumAPI.deleteCategory({ id })
      .then((res) => {
        getCategoryData();
      })
      .catch((err) => {
        console.log("error: ", err);
        const { response } = err;
        getToast({
          statusType: "ERROR",
          message: response.data.message,
        });
      });
  };

  useEffect(() => {
    console.log("isScrolledToBottom: ", isScrollToBottom);
    if (isScrollToBottom) {
      if (currentOffset < postPagination.totalPages) {
        setCurrentOffset((prev) => prev + 1);
      }
    }
  }, [isScrollToBottom]);

  useEffect(() => {
    if (selectedTags && selectedTags?.length > 0) {
      console.log("selectedTags: ", selectedTags);
      getPostsByTags();
    } else {
      getAllPosts({ offset: currentOffset });
    }
  }, [selectedTags]);

  return (
    <>
      <div className="bg-currentBackground overflow-scroll noscrollbar forumPageSection">
        <Navbar setShowMobileMenu={setShowMobileMenu} />
        <div className="forum-page-content  forum-v1--styles">
          <div className={`sidenav__container ${showMobileMenu ? "show" : ""}`}>
            <div className="sidenav noscrollbar">
              <div className="mobile-menu-close-btn__container">
                <div
                  className="mobile-menu-close-btn"
                  onClick={() => {
                    setShowMobileMenu(false);
                  }}
                >
                  <GlobalIcons type={"close"} />
                </div>
                <ThemeToggler />
              </div>
              <div
                className="home-menu-item"
                onClick={() => {
                  navigate("/forum");
                  setActiveCategory(null);
                  setCurrentOffset(1);
                  localStorage.removeItem("active_category");
                  setShowMobileMenu(false);
                }}
              >
                <DefaultIcon type={"home"} />
                Home
              </div>
              <CategoryList
                data={categoryData}
                loading={categoryLoading}
                setAllPosts={setAllPosts}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                setShowMobileMenu={setShowMobileMenu}
                setShowCategoryModal={setShowCategoryModal}
                deleteCallback={onCategoryDelete}
                setSelectedtags={setSelectedtags}
              />

              {currentUser && currentUser.userRole === "super-admin" && (
                <div
                  className="create-category-cta"
                  onClick={() => {
                    setShowCategoryModal({
                      show: true,
                    });
                    setShowMobileMenu(false);
                  }}
                >
                  <DefaultIcon type={"plus"} />
                  Create Category
                </div>
              )}
              <PopularTagsList
                selectedTags={selectedTags}
                setSelectedtags={setSelectedtags}
                setCurrentOffset={setCurrentOffset}
              />
            </div>
            {location.pathname === "/forum" && (
              <button
                className="post-creation-btn-cta lg"
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                <div>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5007 1.96317L1.30698 4.83427C1.22021 4.85799 1.14286 4.90789 1.08547 4.97716C1.02807 5.04643 0.993431 5.13172 0.986257 5.22139C0.979083 5.31106 0.999727 5.40077 1.04538 5.47828C1.09102 5.5558 1.15946 5.61736 1.24136 5.65458L5.92261 7.86942C6.01431 7.91189 6.08795 7.98553 6.13042 8.07723L8.34526 12.7585C8.38248 12.8404 8.44404 12.9088 8.52156 12.9545C8.59908 13.0001 8.68878 13.0208 8.77845 13.0136C8.86813 13.0064 8.95341 12.9718 9.02268 12.9144C9.09196 12.857 9.14185 12.7796 9.16558 12.6929L12.0367 2.49911C12.0584 2.42472 12.0598 2.34585 12.0406 2.27077C12.0214 2.19568 11.9823 2.12714 11.9275 2.07234C11.8727 2.01754 11.8042 1.97848 11.7291 1.95928C11.654 1.94007 11.5751 1.94142 11.5007 1.96317V1.96317Z"
                      stroke="var(--base-color)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.06482 7.93525L8.53669 5.46338"
                      stroke="var(--base-color)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                Post
              </button>
            )}
          </div>

          <div className="forum-content__container">
            {location.pathname === "/forum" && (
              <div className="forum-post-type-tab__container">
                <div
                  className={`tab ${activeTabType === "ALL" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTabType("ALL");
                    setCurrentOffset(1);
                  }}
                >
                  All
                </div>
                <div
                  className={`tab ${
                    activeTabType === "POPULAR" ? "active" : ""
                  }`}
                  onClick={() => {
                    setActiveTabType("POPULAR");
                    getTrendingPosts({ offset: 1 });
                    setSelectedtags([]);
                  }}
                >
                  Popular
                </div>
                <div
                  className="tab sort-btn"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setSortedOrder((prev) =>
                      prev === "Latest" ? "Oldest" : "Latest"
                    );
                  }}
                >
                  <ForumIcons
                    type={
                      sortedOrder === "Oldest"
                        ? "sort_descending"
                        : "sort_ascending"
                    }
                  />
                </div>
              </div>
            )}
            <div className="posts__container noscrollbar">
              {location.pathname === "/forum" && (
                <div
                  className="post-creation-input-cta mt-5"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                >
                  {currentUser?.userImage ? (
                    <img src={currentUser.userImage} className="user-profile" />
                  ) : (
                    <PlaceHolderProfile />
                  )}

                  <div className="dummy-post-input" disabled>
                    Post in Forum
                  </div>
                </div>
              )}
              {location.pathname === "/forum" && (
                <div
                  className="mobile-post-creation-cta"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                >
                  <Plus color="var(--base-color)" />
                  Post in Forum
                </div>
              )}
              <CreatePostModal
                show={showCreateModal}
                onHide={() => {
                  setShowCreateModal(false);
                }}
                categoryData={categoryData}
                getAllPosts={getAllPosts}
              />
              <EditPostModal
                show={showEditModal}
                onHide={() => {
                  setShowEditModal({
                    show: false,
                    postData: null,
                  });
                }}
                onEditSuccess={onEditSuccess}
              />
              {postDataLoading && <Loading />}
              {!postDataLoading && (
                <Outlet
                  context={{
                    allPosts,
                    setShowEditModal,
                    onDeleteSuccess,
                    setAllPosts,
                    postsListingRef,
                  }}
                />
              )}

              {infiniteLoader && <Loading />}
            </div>
          </div>
        </div>
      </div>
      <NewCategoryModal
        show={showCategoryModal}
        onHide={() => {
          setShowCategoryModal({
            show: false,
          });
        }}
        getCategoryData={getCategoryData}
      />
    </>
  );
};
