import React, { useEffect, useState } from 'react'
import { BodyLayout } from '../../../layout/Base'
import { useOutletContext } from 'react-router'
import { useSelector } from 'react-redux'
import { ArrowSquareOut, Eye, EyeSlash } from 'phosphor-react'
import API from '../../../api'
import { throwServerError } from '../../../utils/helper'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import moment from 'moment'
import GlobalIcons from '../../../components/Icons/GlobalIcons'
import MyReactTooltip from '../../../components/ReactTooltip'

export default function MyApps() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const {firstRoute} = useSelector(state => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [orgData, setOrgData] = useState([])
    const {currentUser} = useSelector(state => state.user)
    const [type, setType] = useState({})
    const [appEnv, setAppEnv] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState([])
    const [productData, setProductData] = useState([])

    useEffect(() => {
        setTitle("Events")
        setHasHeader(true)
        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Manage Apps'}
        ])
    }, [])

    const getOrgs = () => {
		setIsLoading(true);
		API.getAllOrgs(1)
        .then((response) => {
            if (response.data.data) {
                setOrgData(response.data.data);
                let defaultOrg = "";
                response.data.data.forEach((el) => {
                    if (el.organisationDefault === "1") {
                        defaultOrg = el.organisationName;
                    }
                });
                if (currentUser.organization.length > 0) {
                    defaultOrg = currentUser.organization[0];
                    setType({
                        value: defaultOrg,
                        label: defaultOrg,
                    });
                } else {
                    setType({
                        value: defaultOrg,
                        label: defaultOrg + " (default)",
                    });
                }
                var matchedOrg = response.data.data.find(
                    (e) => e.organisationName === defaultOrg
                );
                setAppEnv(matchedOrg.environments[0]);
                getAppData(defaultOrg);
            }
            // setIsLoading(false)
        })
        .catch((err) => {
            throwServerError(err);
            //addToast(err.message, { appearance: 'error' })
            setIsLoading(false);
        });
	};

    const getAppData = (value) => {
		setIsLoading(true);
		API.getBuildApps(value, currentPage)
			.then((response) => {
				if (response.data.data && response.data.data.app) {
					setData(response.data.data.app);
				} else if (response.data.data) {
					setData(response.data.data);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setData([]);
                throwServerError(err)
				setIsLoading(false);
			});
	};

    const getProducts = () => {
		API.getProducts()
        .then((response) => {
            if (response.data.data && response.data.data) {
                setProductData(
                    response.data.data.map((o) => {
                        return {
                            value: o,
                            label: o,
                        };
                    })
                );
            }
        })
        .catch((err) => {
            throwServerError(err);
        });
	};

    useEffect(() => {
		getOrgs();
		getProducts();
	}, []);

    const handleOrgType = (selected) => {
		setType({
			value: selected.value,
			label: selected.label,
		});
		var matchedOrg = orgData.find((e) => e.organisationName === selected.value);
		setAppEnv(matchedOrg.environments[0]);
		getAppData(selected.value);
	};

    return (
        <BodyLayout>
            <div className='fadeIn h-100 w-100'>
                <div className='surface-875 py-12px px-16px w-100 d-flex align-items-center justify-content-between'>
                    <h3 className='fw-600 fs-18px text-100'>My Apps</h3>

                    <div className='d-flex align-items-center'>
                        <div style={{width: 200}} className='me-2'>
                            <Select className='v1select' 
                                value={type}
                                closeMenuOnSelect={true}
                                placeholder={`Select Organization`}
                                onChange={handleOrgType}
                                options={
                                    currentUser?.organization?.length > 0
                                        ? currentUser?.organization?.map((o) => {
                                            return { value: o, label: o };
                                        })
                                        : [type].map((o) => {
                                            return {
                                                value: o.value, label: o.value + " (default)",
                                            };
                                        })
                                } />
                        </div>
                        <div className='cursor d-flex align-items-center justify-content-between px-16px py-8px bg-primary br-5px'>
                            <p className='text-300 fs-12px fw-500'>Create App</p>
                        </div>
                    </div>
                </div>

                <div className='surface-900 w-100 p-8px overflow-scroll noscrollbar' style={{height: 'calc(100% - 60px)'}}>
                    <div className='row mx-0 surface-875 p-6px mb-1'>
                        <div className='col-md-3'>
                            <p className='fw-500 fs-10px text-400 pl-6px'>Name</p>
                        </div>
                        <div className='col-md-2'>
                            <p className='fw-500 fs-10px text-400 pl-40px'>Status</p>
                        </div>
                        <div className='col-md-3'>
                            <p className='fw-500 fs-10px text-400 text-center'>APP ID</p>
                        </div>
                        <div className='col-md-2'>
                            <p className='fw-500 fs-10px text-400 text-center'>Created By</p>
                        </div>
                        <div className='col-md-2'>
                        </div>
                    </div>


                    {data.map((each, i) => <div className='row mx-0 v1-border-bottom-1 p-12px' key={i}>
                        {console.log('each', each)}
                        <div className='col-md-3 my-auto'>
                            {/* <Link
                                to={{
                                    pathname: "/admin/gateway/apps-details",
                                    query: {
                                        org: type.value,
                                        appName: each.name,
                                        appEnv: appEnv,
                                    },
                                }}
                            > */}
                                <p className='fw-500 fs-14px text-300'>
                                    {each.name}
                                </p>
                            {/* </Link> */}
                        </div>
                        <div className='col-md-2 mx-auto my-auto'>
                            <div className={`global-status-tag ${each.status} w-fit`}>
                                <p className={`fw-500 fs-12px text-300 status-text px-3`}>{each.status}</p>
                            </div>
                        </div>
                        <div className='col-md-3 my-auto'>
                            <p className='fw-500 fs-12px text-300 text-center'>
                                {each.appId}
                            </p>
                        </div>
                        <div className='col-md-2'>
                            <p className='fw-500 fs-12px text-300 text-center'>
                                {each.createdByUser || ""}
                            </p>
                            <p className='fw-500 fs-11px text-400 text-center'>
                                {moment.utc(each.createdAt).format("HH:mm A - DD/MM/YYYY")}
                            </p>
                        </div>
                        <div className='col-md-2'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div className='btn-sm-v1 v1-border-2 me-3' data-tip='View' data-for='View'>
                                    <Eye color='#E4E4E4' size={16} />
                                    <MyReactTooltip id={"View"} />
                                </div>
                                <div className='btn-sm-v1 v1-border-2 me-3' data-tip='Edit' data-for='Edit'>
                                    <GlobalIcons type={'pencil-v2'} />
                                    <MyReactTooltip id={"Edit"} />
                                </div>

                                <div className='btn-sm-v1 v1-border-2' data-tip='Delete' data-for='Delete'>
                                    <GlobalIcons type={'trash'} />
                                    <MyReactTooltip id={"Delete"} />
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </BodyLayout>
    )
}