import Select from "react-select";
import Creatable from "react-select/creatable";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import { useEffect, useRef, useState } from "react";
import ForumAPI from "../../../../api/forum";
import EditorJS from "../../../../components/EditorJS";
import { getToast } from "../../../../components/Toast";

export const CreateReplyModal = ({ show, onHide, postToReply, onReplyCreate }) => {
  const [replyData, setReplyData] = useState(null)
  const editorInstance = useRef(null);

  const onPostReply = (data) => {
    ForumAPI.replyToPost({ postId: postToReply._id, data })
    .then(res => {
        console.log("reply post res: ", res)
        onReplyCreate()
        onClear()
        setReplyData(null)
    })
    .catch(err  => {
        getToast({
            statusType: "ERROR",
            message: "Unable to reply to post"
        })
    })
  }

  const onModalClose = () => {
    onHide();
    onClear()
    setReplyData(null)
  };

  const setEditorInstance = (core) => {
    editorInstance.current = core;
    editorInstance.current.height = "100%";
  };

  const onSave = async  () => {
    if(editorInstance.current){
        let doc = await editorInstance.current.save()
        setReplyData(doc)
        console.log("saved reply: ", doc)
        const data = {
            content: {
                data: doc
            }
        }

        onPostReply(data)
    }
  }

  const onClear = async () => {
    if (editorInstance.current) {
      try {
        editorInstance.current.clear();

        let doc = await editorInstance.current.save();
        console.log("saved doc: ", doc);
      } catch (err) {
        console.log(err);
      }
    }
  };


  return (
    <div className={`create-post-modal__container ${show ? "open" : ""}`}>
      <div className="create-post-modal-header">
        <p>Replying to {postToReply?.createdBy?.fullName}</p>
        <GlobalIcons
          type={"close"}
          className="cursor-pointer"
          onClick={onModalClose}
        />
      </div>
      <div className="create-post-modal-textarea overflow-scroll-y noscrollbar">
        <EditorJS
          data={replyData}
          setEditorInstance={setEditorInstance}
          className="post-content-area"
        />
      </div>
      <div className="create-post-modal-cta__container">
        <button className="primary-cta" onClick={onSave}>
          Post
        </button>
        <button className="secondary-cta" onClick={onClear}>
          Clear All
        </button>
      </div>
    </div>
  );
};
