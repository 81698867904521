import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Navigation } from 'swiper/modules';


export default function CustomCard2({data, fromEditor}) {
    const [swiperRef, setSwiperRef] = useState(null);

    return (
        <div className='container'>
            <div className='bg-currentBackground row m-0 px-0px py-48px'>
                <div>
                    {data.tagName && <div className='titleTagPartner mb-17px d-block mx-auto'>
                        <p className='text-theme-base fs-16px fw-600 text-center'>{data.tagName}</p>
                    </div>}
                    <p className='fs-36px fw-600 text-content-color text-center' style={{lineHeight: '110%'}}>
                        {data.heading}
                    </p>
                </div>

                <div className={`testimonialWrapper mt-52px slidesToShow${data.slidesToShow}`}
                    style={(window.screen.availWidth < 800 || data.slidesToShow === 1) ? {height: 400} : {}}>
                    <Swiper autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }} modules={[Autoplay, Navigation]}
                        slidesPerView={window.screen.availWidth < 800 ? 1 : (data.slidesToShow || 2)}
                        onSwiper={setSwiperRef} grabCursor={true} 
                        navigation={true}>
                        {data.cards?.map((eachCard, i) => 
                        <SwiperSlide>
                            <div className='testimonialCard bg-base-100 ms-4 pl-16px pt-16px br-14px' key={i}
                                >
                                <div className='position-relative p-36px'>
                                    <p className='quoteStart'>“</p>
                                    <p className='fs-18px fw-500 text-content-color text-truncate-5' 
                                        style={{lineHeight: '170%'}} title={eachCard.quote}>
                                        {eachCard.quote}
                                    </p>
                                    <p className='quoteEnd'>”</p>
                                </div>

                                <div className='row mx-0 pl-36px pt-36px position-relative mt-18px'>
                                    <div className='col-md-6 pb-4 ps-0'>
                                        <p className='fw-700 fs-22px text-content-color'
                                            style={{lineHeight: '130%'}}>{eachCard.title}</p>
                                        <p className='fw-500 fs-14px text-content-color'
                                            style={{lineHeight: '180%'}}>{eachCard.designation}</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='imageCircleGrid'>
                                            <img src={eachCard.imageUrl} className='h-85 w-80'
                                                style={{objectFit: 'cover'}} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>)}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
