import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setSelectedOperation,
  setSwaggerFilterProp,
} from "../../../app/Features/Swagger/SwaggerSlice";
import { objectDeepClone } from "../../../utils/helper";
import { ChatDots, Download, X } from "phosphor-react";
import { EmptyState } from "../../EmptyState";
import ChangeLogModal from "./ChangeLogModal";
import { SpecificationModal } from "../components/SpecificationModal";
import { DownloadSimple } from "phosphor-react";
import { useSearchParams } from "react-router-dom";
import { SRC_PARAM_VALUES, URL_PARAMS } from "../../../service/constants";

export default function LeftPanel(props) {
  const dispatch = useDispatch();
  const {
    operationPlainList,
    selectedOperation,
    info,
    filter: swaggerFilter,
    tags,
    metadata,
    swagger
  } = useSelector((state) => state.swagger);

  const { filter } = useSelector((state) => state.swagger);

  const [changeLogModal, setChangeLogModal] = useState(false)
  const handleChangeLogModal = () => setChangeLogModal(prev => !prev)
  const [codeSpecification,setCodeSpecification]=useState(false);
  const [searchParams] = useSearchParams()


  function handleCodeSpecification(){
    setCodeSpecification((prev)=>!prev)
  }

  const list = useMemo(() => {
    let _list = objectDeepClone(operationPlainList);
    let selectedTag = filter.tag;
    let searchText = filter.search || "";
    let searchRegExp = new RegExp(searchText, "i");
    if (!selectedTag && !searchText) return _list;

    if (searchText) {
      _list = _list.filter((el) => {
        return el.__listTitle.search(searchRegExp) >= 0;
      });
    }

    if (!selectedTag) return _list;

    let _newList = [];
    for (let el of _list) {
      if ((el.api.tags || []).includes(selectedTag)) {
        _newList.push(el);
      }
    }
    return _newList;
  }, [operationPlainList, filter]);

  if (props.fromGuide && (!operationPlainList || !operationPlainList.length))
    return null;
  if (!operationPlainList || !operationPlainList.length) {
    return (
      <div className="h-100 position-relative">
        <div className="position-absolute" style={{left: -42}}>
        <EmptyState />
        <p className="fs-14px text-center">No paths available</p>
        </div>
      </div>
    );
  }
  return (
    <div className="d-flex flex-column gap-8px pr-8px showDownload">
      <div className="d-flex justify-content-between align-items-center">
        <p className="fw-600 fs-14px text-truncate">{`${metadata?.swaggerName}${
          swaggerFilter.tag ? ` > ${swaggerFilter.tag}` : ""
        }`}</p>
        {![SRC_PARAM_VALUES.PRODUCT, SRC_PARAM_VALUES.PACKAGE].includes(searchParams.get(URL_PARAMS.SRC)) ? (
          <div
          onClick={()=>{setCodeSpecification(true)}}
          className="cursor d-flex align-items-center justify-content-center gap-4px border-theme-base-200 br-38px h-24px px-5px showDownloadChild"
          >
            <DownloadSimple size={16} color="var(--content-subtle)" />
            <div className="text-theme-content-subtle fs-12px fw-500">Download</div>
          </div>
        ) : null}
      </div>
      <div>
        {list.map((el) => {
          return (
            <div
              className={`h-33px py-6px px-10px left-list-item cursor ${
                selectedOperation &&
                selectedOperation.endpoint === el.endpoint &&
                selectedOperation.apiMethod === el.apiMethod
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                dispatch(setSelectedOperation(el));
                if (props.onListSelected) props.onListSelected(el);
              }}
            >
              <div className="text-truncate fs-13px fw-400 summary">
                {el.__listTitle}
              </div>
              <div
                className={`text-truncate text-center text-uppercase h-20px br-38px px-6px pt-1px fs-12px w-80px fw-500 api-method-tag ${el.apiMethod}`}
              >
                {el.apiMethod}
              </div>
            </div>
          );
        })}
      </div>
      {tags.length ? (
        <div className="mt-8px d-flex flex-column gap-8px">
          <div className="fw-600 fs-14px">FILTER BY API TAGS</div>
          <div className="d-flex flex-wrap column-gap-6px row-gap-12px">
            {tags.map((tag) => {
              return (
                <div
                  onClick={() => {
                    dispatch(setSwaggerFilterProp({ prop: "tag", value: tag }));
                  }}
                  className={`cursor d-flex justify-content-center align-items-center gap-4px py-4px px-8px br-16px fs-14px fw-600 bg-theme-secondary-200 ${
                    swaggerFilter.tag === tag
                      ? "border-theme-primary text-theme-primary"
                      : "text-theme-content-subtle"
                  }`}
                >
                  <div>{tag}</div>
                  {swaggerFilter.tag === tag ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          setSwaggerFilterProp({ prop: "tag", value: null })
                        );
                      }}
                    >
                      <X weight="bold" />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {!props.fromGuide &&
      info &&
      (info.contact?.email || info.termsOfService || info.license?.url) ? (
        <div>
          <div className="h-64px"></div>

          {info.contact?.email ? (
            <div
              onClick={() => {
                window.open(
                  `mailto:${info.contact?.email || ""}?subject=${
                    info.title || ""
                  }`
                );
              }}
              className="d-flex justify-content-center align-items-center h-34px p-8px text-theme-content-subtle gap-6px br-6px border-theme-base-200 cursor"
            >
              <ChatDots size={16} />
              <div className="fs-14px fw-600">CONTACT US</div>
            </div>
          ) : null}
          <div className="d-flex justify-content-center gap-8px align-items-center h-42px fs-14px fw-500 text-theme-content-subtle">
            {!info.termsOfService ? (
              <div
                onClick={() => {
                  window.open(info.termsOfService || "");
                }}
                className="swagger-info-link cursor fs-10px text-nowrap"
              >
                Terms Of Service
              </div>
            ) : null}
            <div className="swagger-info-dot"></div>
            {info.license?.url ? (
              <div
                onClick={() => {
                  window.open(info.license?.url || "");
                }}
                className="swagger-info-link cursor fs-10px text-nowrap"
              >
                License Terms
              </div>
            ) : null}
            <div className="swagger-info-dot"></div>
            <div
                onClick={handleChangeLogModal}
                className="swagger-info-link cursor fs-10px text-nowrap"
              >
                Change Log
              </div>
          </div>
        </div>
      ) : null}

      <div className="h-64px"></div>

      {changeLogModal && (
        <ChangeLogModal
          show={changeLogModal}
          onHide={handleChangeLogModal}
        />
      )}
      {
        codeSpecification && <SpecificationModal
        show={codeSpecification}
        onHide={handleCodeSpecification}
        backdrop={"static"}
        swagger={swagger}
      />
      }
    </div>

  );
}
