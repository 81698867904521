import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import API from '../../api';
import GlobalIcons from '../../components/Icons/GlobalIcons';
import useOutsideClick from '../../hooks/useOutsideClick';
import { HeaderLayout, MainLayout } from '../../layout/Base'
import NotificationModal from './NotificationModal';
import Sidebar from '../../layout/Sidebar';
import Loading from '../../components/Loader/Loading';
import { throwServerError } from '../../utils/helper';

export default function AdminPages() {
    
    const [navigation, setNavigation] = useState([]);
    const [title, setTitle] = useState('')
    const [showMenu, setShowMenu] = useState(false)
    const menuRef = useRef(null)
    const [hasHeader, setHasHeader] = useState(true);
    const {isAuthenticated, currentUser} = useSelector(state => state.user)
    const [loading, setLoading] = useState(true)
    const [notificationsData, setNotificationsData] = useState([])
    const [notificationModal, setNotificationModal] = useState(false)
    const [monetizationNotifications, setMonetizationNotifications] = useState([])

    const navigate = useNavigate()

    useOutsideClick(menuRef, () => {
        setShowMenu(false)
    });

    useEffect(() => {
        if (!isAuthenticated){
            navigate('/')
        } else {
            setLoading(false)
            getNotifications()
        }
        document.body.style.backgroundColor = "var(--dark-surface-base)";
        return () => {
            document.body.style.backgroundColor = "var(--currentBackground)";
        }
    }, [])

    const getNotifications = () => {
        API.getNotifications({type: 'ACTIVATE USER'})
        .then(res => {
            setNotificationsData(res.data?.data || [])
        }).catch(err => {
            setNotificationsData([])
        })

        API.getMonetizationNotifications(currentUser.email)
        .then(({data: res}) => {
            setMonetizationNotifications(res.data || [])
        }).catch(err => {
            setMonetizationNotifications([])
            // throwServerError(err)
        })
    }

    const handleLogout = () => {
        navigate('/logout')
    }

    return (
        <div className='surface-base'>
            { loading
            ? <div className='w-100vw h-100vh'>
                <Loading />
            </div>
            : <>
                <Sidebar />
                <MainLayout noGap>
                    <div className='w-100 surface-950 h-100'>
                        {hasHeader && (<HeaderLayout>
                            <div className='d-flex align-items-center justify-content-between w-100 p-16px pb-0'>
                                {navigation.length > 1 ? (
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        <div className="header-2">
                                            <div className="text-50 fw-600 fs-18px">{title || ''}</div>
                                            <div className="d-flex align-items-center">
                                                {navigation.map((el, index) => {
                                                    return (
                                                        <>
                                                            {el.link ? (
                                                                <Link to={el.link}>
                                                                    <div className="header-nav-menu cursor-pointer text-300 fs-11px fw-400" >
                                                                        {el.name}
                                                                    </div>
                                                                </Link>
                                                            ) : (
                                                                <div className="header-nav-menu text-300 fs-11px fw-400">
                                                                    {el.name}
                                                                </div>
                                                            )}
                                                            {index < navigation.length - 1 ? (
                                                                <svg className='ml-5px mr-5px' width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.34374 4.49984C4.3432 4.37557 4.29414 4.25642 4.20702 4.16781L1.08202 1.04281C0.99396 0.954745 0.874525 0.905273 0.749989 0.905273C0.625453 0.905273 0.506018 0.954745 0.417958 1.04281C0.329898 1.13087 0.280426 1.2503 0.280426 1.37484C0.280426 1.49937 0.329898 1.61881 0.417958 1.70687L3.21093 4.49984L0.417958 7.29281C0.329898 7.38087 0.280426 7.5003 0.280426 7.62484C0.280426 7.74937 0.329898 7.86881 0.417958 7.95687C0.506018 8.04493 0.625453 8.0944 0.749989 8.0944C0.874525 8.0944 0.99396 8.04493 1.08202 7.95687L4.20702 4.83187C4.29414 4.74325 4.3432 4.62411 4.34374 4.49984Z" fill="#E4E4E4"/>
                                                                </svg>                                                
                                                            ) : null}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='header-2'>
                                    <div className="text-50 fw-600 fs-18px">{title || ''}</div>
                                </div>}

                                {navigation.length > 0 && <div className='d-flex align-items-center'>
                                    <Link to={`/`} className='py-11px px-20px d-flex align-items-center cursor-pointer surface-850 v1-border-1 mr-18px br-6px'
                                        style={{whiteSpace: 'nowrap'}}>
                                        <GlobalIcons type={'left-arrow'} />
                                        <p className='text-300 fs-12px fw-500 ps-2'>Back to Portal</p>
                                    </Link>
                                    <div className='mr-28px cursor-pointer position-relative'
                                        onClick={() => setNotificationModal(true)}>
                                        <GlobalIcons type={'notification'} />
                                        
                                        {notificationsData.count > 0 && <div className='bg-red-05 px-8px py-4px br-200 position-absolute notificationBadge'>
                                            <p className='text-300 fs-8px'>{notificationsData.count + monetizationNotifications.length}</p>
                                        </div>}
                                    </div>

                                    <div className='position-relative' ref={menuRef}>
                                        <div onClick={() => setShowMenu((curr) => !curr)}
                                            className='cursor-pointer d-flex'>
                                            <GlobalIcons type={'profile'} />

                                            { !showMenu 
                                            ? <div className='ps-1'>
                                                <GlobalIcons type={'down-arrow-sm'} />
                                            </div>
                                            : <div style={{transform: 'rotate(180deg)'}} className='ps-1'>
                                                <GlobalIcons type={'down-arrow-sm'} />
                                            </div>}
                                        </div>

                                        {showMenu && <div className='position-absolute w-10vw surface-base w-fit v1-border-1 profileDropdown p-3px'
                                            style={{zIndex: 10}}>
                                            <Link to={`/my-account`}>
                                                <div className='px-20px py-8px hover-surface-850'>
                                                    <p className={'text-nowrap cursor-pointer text-300 fs-14px'}>My Account</p>
                                                </div>
                                            </Link>
                                            <div className='px-20px py-8px hover-surface-850' onClick={() => handleLogout()}>
                                                <p className='cursor-pointer fs-14px text-300'>Logout</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>}
                            </div>
                        </HeaderLayout>)}

                        <div className={hasHeader ? `p-16px` : 'h-100'}>
                            <Outlet context={{ setNavigation, setTitle, setHasHeader }} />
                        </div>
                    </div>

                    {notificationModal && <NotificationModal show={notificationModal} onHide={() => setNotificationModal(false)}
                        callback={getNotifications} data={notificationsData} monetizationNotifications={monetizationNotifications} />}
                </MainLayout>
            </>}
        </div>
    )
}
