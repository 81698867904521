import CustomFooter from "../Dashboards/components/CustomFooter";
import CustomNavbar from "../Dashboards/components/CustomNavbar";
import monetizationAPI from "../../api/monetization";
import { useState } from "react";
import { throwServerError } from "../../utils/helper";
import { useEffect } from "react";
import { ProductsCard } from "./components/ProductsCard";
import Loading from "../../components/Loader/Loading";
import { ProductBundleCard } from "./components/ProductBundlesCard";
import { ProductDetailsModal } from "./components/ProductBundleDetailsModal";
import { AzureProductDetailsModal } from "./components/AzureProductDetailsModal";
import { KongProductDetailsModal } from "./components/KongProductDetailsModal";
import { NginxProductDetailsModal } from "./components/NginxProductDetailsModal";
import { ProductBundleBuyNowModal } from "./components/ProductBundleBuyNowModal";
import { ProductBuyNowModal } from "./components/ProductBuyNowModal";
import ContainerLoader from "../../components/Loader/ContainerLoader";
import { ApigeeProductDetailsModal } from "./components/ApigeeProductDetailsModal";
import { MagnifyingGlass } from "phosphor-react";
import { GATEWAYS } from "../../service/constants";
import { AwsProductDetailsModal } from "./components/AwsProductDetailsModal";
import { objectDeepClone } from "@apiwiz/oas/services";
import ProductModal from "./v2/Apps/ProductModal";
const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

const TABS = {
  PRODUCTS: "Products",
};

export const Products = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productBundles, setProductBundles] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS.PRODUCTS);
  const [selectedProductBundle, setSelectedProductBundle] = useState(null);
  const [showProductBunldeDetailModal, setShowProductBundleDetailModal] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductDetailModal, setShowProductDetailModal] = useState({
    isOpen: false,
    type: "",
  });
  const [showBundleBuyModal, setShowBundleBuyModal] = useState(false);
  const [showProductBuyModal, setShowProductBuyModal] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [productDetails, setProductDetails] = useState({data: null, isOpen: false})

  useEffect(() => {
    getAllProducts();
    // getAllProductBundles();
  }, []);

  const getGatewayProducts = (_arr) => {
    const onlyIds = objectDeepClone(_arr).map(o => o.productId)

    monetizationAPI
    .getProductDetails()
    .then((res) => {
        if (res?.data?.data) {
            setProducts(res?.data?.data)
        } else {
          setProducts([])
        }
        setIsLoading(false);
    })
    .catch((err) => {
      setProducts([])
      throwServerError(err);
      setIsLoading(false);
    });
  }

  console.log('products', products)

  const getAllProducts = () => {
    setIsLoading(true);
    monetizationAPI.getProductDetails({expand: true})
    .then(({data: res}) => {
        setProducts(objectDeepClone(res.data))
        setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
      throwServerError(err)
    })
  };

  const getAllProductBundles = () => {
    setIsLoading(true);
    monetizationAPI
      .getAllProductBundles()
      .then((res) => {
        if (res?.data?.data) {
          setProductBundles(res?.data?.data);
          if (!products.length && activeTab === null)
            setActiveTab(TABS.PRODUCT_BUNDLES);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setProductBundles([]);
        throwServerError(err);
        setIsLoading(false);
      });
  };

  const getFilteredProducts = (products, searchStr) => {
    if (!searchStr) return products

    const filteredData = products.filter(
      (product) =>
        product?.name?.toLowerCase().includes(searchStr.toLowerCase()) ||
        product?.productName?.toLowerCase().includes(searchStr.toLowerCase())
    );

    return filteredData;
  };

  const getFilteredBundles = (bundles) => {
    const filteredBundles = bundles.filter((bundle) =>
      bundle.productBundle?.name
        ?.toLowerCase()
        .includes(searchStr.toLowerCase())
    );
    return filteredBundles;
  };

  return (
    <div className="w-100vw bg-currentBackground">
      <CustomNavbar />
      <div className="mt-62px container productBundleSection">
        <div
          className="w-100 bg-theme-base h-100 py-32px"
          style={{ minHeight: "100vh" }}
        >
          {isLoading && <ContainerLoader className="h-100vh w-100" />}
          {!isLoading && (
            <>
              <div className="products-tab-switch__container">
                <div className="dfaic">
                </div>

                <div className="searchSection mb-8px">
                  <MagnifyingGlass color="#667085" size={15} />

                  <input
                    type="text"
                    placeholder={`Search ${activeTab || ""}`}
                    value={searchStr}
                    onChange={(evt) => {
                      setSearchStr(evt.target.value);
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="mt-3 apps-grid-view__container">
                {products.length > 0 &&
                  getFilteredProducts(products, searchStr).map((product) => (
                    <ProductsCard
                      data={product}
                      setProductDetails={setProductDetails}
                      buyNowAction={() => {
                        setSelectedProduct(product);
                        setShowProductBuyModal(true);
                      }}
                      productDetailAction={() => {
                        setSelectedProduct(product);
                        setShowProductDetailModal({
                          isOpen: true,
                          type: product.type,
                        });
                      }}
                    />
                  ))}

                {/* {activeTab === TABS.PRODUCT_BUNDLES &&
                  productBundles &&
                  getFilteredBundles(productBundles).map((bundle) => (
                    <ProductBundleCard
                      data={bundle}
                      buyNowAction={() => {
                        setSelectedProductBundle(bundle);
                        setShowBundleBuyModal(true);
                      }}
                      productDetailAction={() => {
                        setSelectedProductBundle(bundle);
                        setShowProductBundleDetailModal(true);
                      }}
                    />
                  ))} */}
              </div>
            </>
          )}
        </div>
      </div>
      <CustomFooter />

      {productDetails.isOpen ? (
          <ProductModal
              show={productDetails.isOpen}
              data={productDetails.data}
              onHide={()=> setProductDetails(prev => ({...prev, isOpen: false}))}
          />
      ) : null}

      {showProductBunldeDetailModal && (
        <ProductDetailsModal
          selectedProductBundle={selectedProductBundle}
          onModalClose={() => {
            setSelectedProductBundle(null);
            setShowProductBundleDetailModal(false);
          }}
        />
      )}

      {showProductDetailModal.isOpen &&
        showProductDetailModal.type === "apigee" && (
          <ApigeeProductDetailsModal
            selectedProduct={selectedProduct}
            onModalClose={() => {
              setSelectedProduct(null);
              setShowProductDetailModal({
                isOpen: false,
                type: "",
              });
            }}
          />
        )}

      {showProductDetailModal.isOpen &&
        showProductDetailModal.type === "azure" && (
          <AzureProductDetailsModal
            selectedProduct={selectedProduct}
            onModalClose={() => {
              setSelectedProduct(null);
              setShowProductDetailModal({
                isOpen: false,
                type: "",
              });
            }}
          />
        )}

      {showProductDetailModal.isOpen &&
        showProductDetailModal.type === "kong" && (
          <KongProductDetailsModal
            selectedProduct={selectedProduct}
            onModalClose={() => {
              setSelectedProduct(null);
              setShowProductDetailModal({
                isOpen: false,
                type: "",
              });
            }}
          />
        )}

      {showProductDetailModal.isOpen &&
        showProductDetailModal.type === GATEWAYS.NGINX && (
          <NginxProductDetailsModal
            selectedProduct={selectedProduct}
            onModalClose={() => {
              setSelectedProduct(null);
              setShowProductDetailModal({
                isOpen: false,
                type: "",
              });
            }}
          />
        )}

      {showProductDetailModal.isOpen &&
        showProductDetailModal.type === AWS_GATEWAY && (
          <AwsProductDetailsModal
            selectedProduct={selectedProduct}
            onModalClose={() => {
              setSelectedProduct(null);
              setShowProductDetailModal({
                isOpen: false,
                type: "",
              });
            }}
          />
        )}

      {showBundleBuyModal && (
        <ProductBundleBuyNowModal
          onHide={() => {
            setSelectedProductBundle(null);
            setShowBundleBuyModal(false);
          }}
          data={selectedProductBundle}
          show={true}
        />
      )}

      {showProductBuyModal && (
        <ProductBuyNowModal
          onHide={() => {
            setSelectedProduct(null);
            setShowProductBuyModal(false);
          }}
          data={selectedProduct}
          show={true}
        />
      )}
    </div>
  );
};
