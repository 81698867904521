import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '../../context/ThemeContext'
import { Eye, EyeSlash } from 'phosphor-react'
import { useDispatch } from 'react-redux'
import API from '../../api'
import { encryptPassword, objectDeepClone, throwServerError, validateEmail } from '../../utils/helper'
import { resetAuthenticatedState, setToken } from '../../app/Features/Users/UserSlice'
import Loading from '../../components/Loader/Loading'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

export default function LoginWithAPIwiz() {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const {theme} = useTheme()

    const [showPass, setshowPass] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginData, setLoginData] = useState({
		disableSignup: false,
		disableLogin: false,
		loginMethod: [],
	});
    const [isDisabled, setIsDisabled] = useState(false)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const {guestsetting} = useSelector(state => state.setting)

    useEffect(() => {
        setloading(true)
		if (!guestsetting.loginMethod?.includes("apiwiz") || guestsetting.disableLogin){
            navigate(`/`)
        }
        setLoginData(objectDeepClone(guestsetting));
        setloading(false)
	}, [guestsetting]);

    const handleSubmit = (e) => {
        e.preventDefault()

        let temp = {}
        if (!username.trim().length){
            temp['username'] = 'Username is Required'
        }

        if (!password.trim().length){
            temp['password'] = 'Password is Required'
        }

        setErrors(temp)
        if (Object.keys(temp).length) return

        setIsDisabled(true)
        let submitObj = {
            loginId: username, password, workspaceId: loginData.workspaceId
        }
        submitObj.password = encryptPassword(submitObj.password)

        API.itorixLogin(submitObj)
        .then(res => {
            dispatch(setToken({data: res.data.data}));
            window.location.href = '/'
            setIsDisabled(false)
        }).catch(err => {
            throwServerError(err)
            dispatch(resetAuthenticatedState())
            setIsDisabled(false)
        })

    }

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            { loading
            ? <Loading />
            : <form className='loginContainer h-100' onSubmit={handleSubmit}>
                <div className='mb-20px text-center'>
                    <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9669 0L13.1768 10.6871L26.6134 31.8868H40L19.9669 0Z" fill="#D8D8D8"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9669 0L13.1768 10.6871L26.6134 31.8868H40L19.9669 0Z" fill="url(#paint0_linear_1627_7)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7393 10.7784L19.9476 21.3679L13.1768 10.6855L19.9669 0L26.7393 10.7784Z" fill="#1DE3D1"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7393 37.4104L19.9476 47.9998L13.1768 37.3175L19.9669 26.6304L26.7393 37.4104Z" fill="url(#paint1_linear_1627_7)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 31.8867H13.1767L19.9233 21.3469L13.1767 10.687L0 31.8867Z" fill="url(#paint2_linear_1627_7)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1627_7" x1="28.9097" y1="28.3968" x2="19.8842" y2="13.6265" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00C2FF"/>
                                <stop offset="1" stop-color="#005AF9"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1627_7" x1="19.958" y1="26.6304" x2="19.958" y2="47.9998" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FF16A6"/>
                                <stop offset="1" stop-color="#FFBF27"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_1627_7" x1="7.44734" y1="31.8867" x2="19.6762" y2="12.5484" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#E300F6"/>
                                <stop offset="1" stop-color="#5213D2"/>
                            </linearGradient>
                        </defs>
                    </svg>

                </div>

                <div className='mb-32px'>
                    <h3 className='titleText'>Log in with APIwiz</h3>
                    <p className='descText'>
                        Enter your workspace credentials
                    </p>
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Workspace<span className='text-danger'>*</span></p>
                    <input type='text' disabled className='form-control v1-form-control mt-1' 
                        value={loginData.workspaceId} />
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Username<span className='text-danger'>*</span></p>
                    <input type='text' className='form-control v1-form-control mt-1' value={username}
                        placeholder='Enter your username' onChange={(e) => {
                            let _str = e.target.value
                            setUsername(_str)
                            setErrors({...errors, username: _str.trim().length < 1 ? 'Username is Required' : false})
                        }} />
                    {errors.username && <p className='text-danger fs-12px mt-1'>
                        {errors.username}
                    </p>}
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Password<span className='text-danger'>*</span></p>
                    <div className='position-relative'>
                        <input type={showPass ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                            placeholder='Enter your password' value={password}
                            onChange={(e) => {
                                let _str = e.target.value
                                setPassword(_str)
                                setErrors({...errors, password: _str.trim().length < 1 ? 'Password is Required' : false})
                            }} />
                        <div className='eyeIconPass cursor' onClick={() => setshowPass(curr => !curr)}>
                            {showPass
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                    </div>
                    {errors.password && <p className='text-danger fs-12px mt-1'>
                        {errors.password}
                    </p>}
                </div>

                <button type='submit' disabled={isDisabled} className='border-0 gradientBtnInAuth mb-16px'>
                    Login with APIwiz
                </button>

                {!loginData.disableSignup && <div className='dontHaveAnAccount'>
                    <Link to={`/register`}>
                        Don't have an account? <span>Sign up</span>
                    </Link>
                </div> }
            </form>}
        </div>
    )
}
