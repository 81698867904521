import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import AdminPages from "../pages/AdminPages";
import ComponentsPage from "../pages/Misc/ComponentsPage";
import { getCookie } from "../utils/cookie";
import { v4 as uuidv4 } from "uuid";
import AdminDashboard from "../pages/AdminPages/Dashboard"
import ManageSection from "../pages/AdminPages/Configure/ManageSection";
import {SDKs as ManageSDKs} from "../pages/AdminPages/SDKs";
import ManageGiudes from "../pages/AdminPages/ManageGuides";
import EachGuide from "../pages/AdminPages/ManageGuides/editGuide";
import ManageSite from "../pages/AdminPages/Configure/ManageSite";
import ManageUsers from "../pages/AdminPages/ManageUsers/ManageUsers";
import ManageAttributes from "../pages/AdminPages/ManageUsers/ManageAttributes";
import GatewayDetails from "../pages/AdminPages/GatewayDetails";
import AssociateUsers from "../pages/AssociateUsers";
import AccessControlList from "../pages/AdminPages/ManageUsers/AccessControlList";
import {Dashboard as MainDashboard} from "../pages/Dashboards";
import Guides from "../pages/Guides";
import ViewGuides from "../pages/Guides/view";
import Loginv2 from "../pages/Authentication/Loginv2";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import LoginWithAPIwiz from "../pages/Authentication/LoginWithAPIwiz";
import ContactInformation from "../pages/AdminPages/ContactInformation"
import ContactUs from "../pages/AdminPages/ContactInformation/Components/ContactUs";
import Register from "../pages/Authentication/Register";
import RegisterSuccess from "../pages/Authentication/RegisterSuccess";
import Profile from "../pages/AdminPages/Profile";
import Events from "../pages/AdminPages/Events";
import Logout from "../pages/Authentication/Logout";
import { ForumLanding } from "../pages/ForumPages";
import { SinglePostPage } from "../pages/ForumPages/SinglePostPage";
import SDKs from "../pages/SDKs";
import Settings from "../pages/AdminPages/Settings";
import { PrivateRoute } from "./PrivateRoute";
import Dashboardv2 from "../pages/AdminPages/Dashboard/Dashboardv2";
import { PostListing } from "../pages/ForumPages/Components/PostListing";
import Activate from "../pages/Authentication/Activate";
import InvalidToken from "../pages/Authentication/InvalidToken";
import Apps from "../pages/AdminPages/Apps";
import EventsDashboard from "../pages/Events";
import { ROUTE_NAMES } from "../service/constants";
import APIReferenceHome from "../pages/References";
import APIReferenceView from "../pages/References/view";
import Usecases from "../pages/AdminPages/Usecases";
import EachUseCase from "../pages/AdminPages/Usecases/EachUseCase";
import UsecasesHome from "../pages/Usecases";
import EachUsecase from "../pages/Usecases/EachUsecase";
import ValidToken from "../pages/Authentication/ValidToken";
import { NotFoundPage } from "../pages/404Page";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ResetSuccess from "../pages/Authentication/ResetSuccess";
import ProductBundles from "../pages/Monetization/ProductBundles";
import AllPurchases from "../pages/Monetization/AllPurchases";
// import { Products } from "../pages/Monetization/Products";
import AllPackages from "../pages/Monetization/v2/packages/AllPackages";
import PackagesGroup from "../pages/Monetization/v2/packages";
import MyApps from "../pages/AdminPages/Apps/MyApps";
import LandingPage from "../pages/AdminPages/LandingPage";
import SSOLogin from "../pages/Authentication/SSOLogin";
import SSOLogout from "../pages/Authentication/SSOLogout";
import { Products } from "../pages/Monetization/Products";
import PackageDetails from "../pages/Monetization/v2/packages/PackageDetails";
import Subscriptions from "../pages/Monetization/v2/Subscriptions";
import SubscribedPackageDetail from "../pages/Monetization/v2/Subscriptions/SubscribedPackageDetail";
import AllApps from "../pages/Monetization/v2/Apps";
import AppDetail from "../pages/Monetization/v2/Apps/AppDetail";
import CreateApp from "../pages/Monetization/v2/Apps/CreateApp";
import AccountPage from '../pages/Monetization/v2/Account'
import Invoices from "../pages/Monetization/v2/Invoices";
import ProfilePage from "../pages/Monetization/v2/Account/ProfilePage";
import InvoicePDF from "../pages/Monetization/v2/Invoices/InvoicePDF";
import ManagePromotions from "../pages/AdminPages/Configure/ManagePromotions";
import SubscriptionPackages from "../pages/Monetization/v2/Subscriptions/SubscriptionPackages";
import MyProducts from "../pages/AdminPages/Products";

const OpenRoutes = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {isAuthenticated} = useSelector(state => state.user);
    const navigate = useNavigate()

  
    if(getCookie('itorix-token') && isAuthenticated) 
    { 
      
        if(searchParams.get("redirect")){
            navigate(searchParams.get("redirect"), {
                replace: true
            })
        }else {
            return <Navigate to={'/'} />
        }
      
    } 
  
    return props.children;
}

export default function Routing() {

    return (
        <div className="d-flex align-items-start w-100">
            <>
                <Routes>
                    <Route path="/login" element={<OpenRoutes> <Loginv2 /> </OpenRoutes>} />
                    <Route path="/sso-failure" element={<OpenRoutes> <Loginv2 /> </OpenRoutes>} />
                    <Route path="/sso-login" element={<OpenRoutes> <SSOLogin /> </OpenRoutes>} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/sso-logout" element={<SSOLogout />} />
                    <Route path="/register" element={<OpenRoutes> <Register /> </OpenRoutes>} />
                    <Route path="/invite-user/:id" element={<OpenRoutes> <Register isInviteUser /> </OpenRoutes>} />
                    <Route path="/activate/:id" element={<OpenRoutes> <Activate /> </OpenRoutes>} />
                    <Route path="/invalidToken" element={<OpenRoutes> <InvalidToken /> </OpenRoutes>} />
                    <Route path="/validToken" element={<OpenRoutes> <ValidToken /> </OpenRoutes>} />
                    <Route path="/register-success" element={<OpenRoutes> <RegisterSuccess /> </OpenRoutes>} />
                    <Route path="/reset-success" element={<OpenRoutes> <ResetSuccess /> </OpenRoutes>} />
                    <Route path="/forgot-password" element={<OpenRoutes> <ForgotPassword /> </OpenRoutes>} />
                    <Route path="/resend-token" element={<OpenRoutes> <ForgotPassword /> </OpenRoutes>} />
                    <Route path="/login-itorix" element={<OpenRoutes> <LoginWithAPIwiz /> </OpenRoutes>} />
                    <Route path="/reset-password/:id" element={<OpenRoutes> <ResetPassword /> </OpenRoutes>} />
                    <Route path="/components" element={<ComponentsPage />} />
                    <Route path="/sdk-tools" element={<SDKs />} />
                    <Route path="/admin" element={<AdminPages /> } children={[
                        <Route path="/admin/" element={<LandingPage />} key={uuidv4()} />,
                        <Route path="/admin/dashboard/v2" element={<AdminDashboard />} key={uuidv4()} />,
                        <Route path="/admin/dashboard" element={<Dashboardv2 />} key={uuidv4()} />,
                        <Route path="/admin/configure/sections" element={<ManageSection />} key={uuidv4()} />,
                        <Route path="/admin/configure/promotions" element={<ManagePromotions />} key={uuidv4()} />,
                        <Route path="/admin/configure/themes" element={<ManageSite />} key={uuidv4()} />,
                        <Route path="/admin/sdks" element={<ManageSDKs />} key={uuidv4()} />,
                        <Route path="/admin/guides" element={<ManageGiudes />} key={uuidv4()} />,
                        <Route path="/admin/guides/:id" element={<EachGuide />} key={uuidv4()} />,
                        <Route path="/admin/manage/users" element={<ManageUsers />} key={uuidv4()} />,
                        <Route path="/admin/manage/attributes" element={<ManageAttributes />} key={uuidv4()} />,   
                        <Route path="/admin/manage-gateways/gateway-details" element={<GatewayDetails />} key={uuidv4()} />,
                        <Route path="/admin/manage-gateways/associate-users" element={<AssociateUsers />} key={uuidv4()} />,
                        <Route path="/admin/manage/access-control-list" element={<AccessControlList />} key={uuidv4()} />,
                        <Route path="/admin/contact-information" element={<ContactInformation />} key={uuidv4()} />,  
                        <Route path="/admin/profile-page" element={<Profile/>} key={uuidv4()} />,      
                        <Route path="/admin/events" element={<Events />} key={uuidv4()} />,     
                        <Route path="/admin/settings" element={<Settings />} key={uuidv4()} />,     
                        <Route path="/admin/app-approval" element={<Apps />} key={uuidv4()} />,
                        <Route path="/admin/manage-gateways/products" element={<MyProducts />} key={uuidv4()} />,
                        <Route path="/admin/apps" element={<MyApps />} key={uuidv4()} />,
                        <Route path="/admin/usecases" element={<Usecases />} key={uuidv4()} />,
                        <Route path="/admin/usecases/:id" element={<EachUseCase />} key={uuidv4()} />,
                    ]} />
                    <Route path="/" element={<MainDashboard />} />
                    <Route path="/contact-us" element={<ContactUs />} key={uuidv4()} />,
                    <Route path={`/${ROUTE_NAMES.GUIDES}`} element={<Guides />} />
                    <Route path={`/${ROUTE_NAMES.GUIDES}/*`} element={<ViewGuides />} />,
                    <Route path={`/${ROUTE_NAMES.API_REFERENCE}`} element={<APIReferenceHome />} />,
                    <Route path={`/${ROUTE_NAMES.OPEN_API}`} element={<APIReferenceHome isOpenApi />} />,
                    <Route path={`/${ROUTE_NAMES.API_REFERENCE}/:oas/:swaggerId/:revision`} element={<APIReferenceView />} />,
                    <Route path={`/${ROUTE_NAMES.USECASES}`} element={<UsecasesHome />} />,
                    <Route path={`/${ROUTE_NAMES.USECASES}/:id`} element={<EachUsecase />} />,
                    <Route path="/events" element={<EventsDashboard/>}/>,
                    <Route path="/monetization/product-bundles" element={<ProductBundles />}/>,
                    <Route path="/monetization/purchases" element={<AllPurchases />}/>,
                    <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>}/>,
                    
                    {/* <Route path="/apps" element={<PrivateRoute><ProductBundles isApps /></PrivateRoute>}/>, */}
                    <Route path="/purchases" element={<PrivateRoute><AllPurchases /></PrivateRoute>}/>,

                    {/* Apiwiz Monetization Routes */}
                    <Route path="/monetization/subscriptions" element={<PrivateRoute><Subscriptions /></PrivateRoute>}/>,
                    <Route path="/monetization/subscriptions/:id" element={<PrivateRoute><SubscriptionPackages /></PrivateRoute>}/>,
                    <Route path="/monetization/package-detail/:packageId" element={<PrivateRoute><SubscribedPackageDetail /></PrivateRoute>}/>,
                    <Route path="/apps" element={<PrivateRoute><AllApps /></PrivateRoute>}/>,
                    <Route path="/monetization/app/:id" element={<PrivateRoute><AppDetail /></PrivateRoute>}/>,
                    <Route path="/monetization/create-app" element={<PrivateRoute><CreateApp /></PrivateRoute>}/>,
                    <Route path="/monetization/home" element={<PrivateRoute><AllPackages /></PrivateRoute>}/>,
                    <Route path="/monetization/packages" element={<PrivateRoute><PackagesGroup /></PrivateRoute>}/>,
                    <Route path="/monetization/packages/:id" element={<PrivateRoute><PackageDetails /></PrivateRoute>}/>,
                    <Route path="/my-account" element={<PrivateRoute><AccountPage /></PrivateRoute>}/>,
                    <Route path="/my-account/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>}/>,
                    <Route path="/my-invoices" element={<PrivateRoute><Invoices /></PrivateRoute>}/>,
                    <Route path="/my-invoices/details" element={<PrivateRoute><InvoicePDF /></PrivateRoute>}/>,
                    {/* End of Apiwiz Monetization Routes */}

                    {/* Form Routes */}
                    <Route path="/forum" element={<PrivateRoute><ForumLanding /></PrivateRoute> } 
                        children={[
                            <Route path="/forum" element={<PrivateRoute><PostListing /></PrivateRoute>} />,
                            <Route path="/forum/post/:postId" element={<PrivateRoute><SinglePostPage /></PrivateRoute>} />
                        ]}
                    />

                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </>
        </div>
    )
}