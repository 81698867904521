import EmptyContainer from "../EmptyContainer";
import ContainerLoader from "../Loader/ContainerLoader";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import { SORT_ORDERS } from "../../service/constants";
import Pagination from "../Pagination";
import { useEffect, useRef } from "react";

const MyTable = (props) => {
  let style = {};
  if (props.style) style = { ...props.style };

  const handleSort = (col) => {
    if (col.sortKey && props.handleSort) {
      let _sortOrder = col.sortOrder || SORT_ORDERS.ASC;
      if (_sortOrder === SORT_ORDERS.ASC) _sortOrder = SORT_ORDERS.DESC;
      else _sortOrder = SORT_ORDERS.ASC;
      props.handleSort(col.sortKey, _sortOrder);
    }
  };

  return (
    <div style={style} className="h-100 pt-12px px-4px overflow-scroll-y noscrollbar">
      <table className="my-table w-100">
        <thead>
          {props.cols.map((col) => {
            return (
              <th className={`${col.headerClassName} fs-12px text-400`}>
                <div
                  onClick={() => {
                    handleSort(col);
                  }}
                  className={`${
                    col.headerDivClassName
                  } d-flex align-items-center ${
                    col.sortKey && props.handleSort ? "cursor" : ""
                  }`}
                >
                  {col.sortKey ? (
                    <div>
                      {col.sortOrder === SORT_ORDERS.ASC && (
                        <TbSortAscending style={{ marginRight: 2 }} size={12} />
                      )}
                      {col.sortOrder === SORT_ORDERS.DESC && (
                        <TbSortDescending
                          style={{ marginRight: 2 }}
                          size={12}
                        />
                      )}
                    </div>
                  ) : null}
                  <div>{col.headerName}</div>
                </div>
              </th>
            );
          })}
        </thead>
        {!props.loading && (
          <tbody>
            {props.rows.map((row) => {
              return (
                <tr onClick={props.onRowClick ? () => {props.onRowClick(row)} : null} className={`${row._rowClassName || ""} p-12px`}>
                  {props.cols.map((col) => {
                    return (
                      <td className={`${col.colClassName || ""}`}>
                        <div className={`${col.className} `}>
                          {col.emptyCol ? (
                            <div className="d-flex gap-8px justify-content-end pr-16px">{props.rowActions.map(el => {
                                return <div onClick={el.action ? (e)=> {
                                  e.stopPropagation();
                                  el.action(row)
                                } : null}>{el.btn}</div>
                            })}</div>
                          ) : col.children && col.children.length ? (
                            <div className={`d-flex flex-column gap-4px`}>
                              {col.children.map((child) => {
                                return (
                                  <div className={child.className}>
                                    {row[col.dataKey][child.dataKey]}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className={`truncate-lg`}>
                              <div
                                className={`${
                                  col.dataKey === "resource"
                                    ? "truncate-xs"
                                    : ""
                                } text-truncate`}
                                data-for={col.id}
                                data-tip={row[col.dataKey]}
                              >
                                {row[col.dataKey]}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>

      {props.loading || !props.rows.length ? (
        <div className="tfoot">
          {props.loading ? (
            <ContainerLoader className="w-100" />
          ) : (
            <EmptyContainer showFolder message="No data found" />
          )}
        </div>
      ) : null}
      {props.pagination && props.pagination.total > props.pagination.pageSize ? (
        <div className="mt-16px">
          <Pagination
            cb={props.onPageChanged}
            pagination={props.pagination}
          />{" "}
        </div>
      ) : null}

      {props.paginationv2 ? (
        <div className="d-flex align-items-center justify-content-center gap-8px my-table-2-pagination-cont">
          <div
            onClick={() =>
              props.paginationv2.offset - 1 && props.onPreviousPage()
            }
            className={`text-300 fw-500 fs-12px v1-border-05 br-4 surface-850 p-8-16 cursor transisition-all-ease-in-out-05 ${
              !(props.paginationv2.offset - 1) ? "o-05" : ""
            }`}
          >
            Previous
          </div>
          <div
            onClick={() =>
              props.showNxtBtn && !props.nxtLoader && props.onNextPage()
            }
            className={`text-300 fw-500 fs-12px v1-border-05 br-4 surface-850 p-8-16 cursor transisition-all-ease-in-out-05 ${
              !props.showNxtBtn || props.nxtLoader ? "o-05" : ""
            }`}
          >
            Next
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MyTable;
