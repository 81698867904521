import { createContext, useContext, useEffect, useReducer } from "react";

const ThemeContext = createContext();

const themeReducer = (prevTheme, { type, payload }) => {
  switch (type) {
    case "TOGGLE_THEME":
      if (payload) {
        return payload.theme;
      } else {
        return prevTheme === "theme--light" ? "theme--dark" : "theme--light";
      }
    default:
      return prevTheme;
    }
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useReducer(
    themeReducer,
    localStorage.getItem("Theme") ? localStorage.getItem("Theme") : "theme--light"
  );

  useEffect(() => {
    const Theme = localStorage.getItem("Theme");
    if (Theme) {
      setTheme({ type: "TOGGLE_THEME", payload: { theme: Theme } });
    }
    if (Theme === "theme--dark") {
      document.documentElement.classList.add("theme--dark");
    } else {
      document.documentElement.classList.add("theme--light");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("Theme", theme);
    if (theme === "theme--dark") {
      document.documentElement.classList.add("theme--dark");
      document.documentElement.classList.remove("theme--light");
    } else {
      document.documentElement.classList.remove("theme--dark");
      document.documentElement.classList.add("theme--light");
    }
  });

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export const useTheme = () => useContext(ThemeContext);
