import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loader/Loading";
import { objectDeepClone } from "@apiwiz/oas/services";
import Select from "react-select";
import { getRatePlanValue, throwServerError } from "../../../utils/helper";
import { Plus, Trash } from "phosphor-react";
import { getToast } from "../../../components/Toast";
import API from "../../../api";
import monetizationAPI from "../../../api/monetization";
import { useSelector } from "react-redux";
import { GATEWAYS, PRODUCTS_MAPPER_DISPLAY_NAME, PRODUCTS_MAPPER_KEY } from "../../../service/constants";

const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

export const ProductBuyNowModal = (props) => {
  const [disableSave, setDisableSave] = useState(false);
  const [data, setData] = useState({});

  const [ratePlans, setRatePlans] = useState(null);
  const [ratePlanOptions, setRatePlanOptions] = useState([]);
  const [selectedRatePlan, setSelectedRatePlan] = useState(null);
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [productBundleOptions, setProductBundleOptions] = useState([]);
  const [productBundleList, setProductBundleList] = useState(null);
  const [chosenProductBundle, setChosenProductBundle] = useState(null);
  const [productsList, setProductsList] = useState(null);
  const [chosenProduct, setChosenProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    let _data = objectDeepClone(props.data || {});
    console.log("_data", _data);
    setChosenProduct(_data);

    setData(objectDeepClone(_data));
  }, []);

  useEffect(() => {
    if (ratePlans && ratePlans.length) {
      const options = ratePlans.reduce(
        (acc, curr) => [...acc, { value: curr, label: curr }],
        []
      );

      setRatePlanOptions(options);
    }
  }, [ratePlans]);

  /**
   *
   * Product Bundles for all orgs and products
   */
  const getProductBundles = () => {
    monetizationAPI
      .getAllProductBundles()
      .then((response) => {
        const { data } = response.data;
        console.log("bundle data", data);
        setProductBundleList(data);

        const options = data.map((bundle) => ({
          label: bundle.productBundle?.name,
          value: bundle,
        }));

        setProductBundleOptions(options);
        getProducts();
      })
      .catch((err) => {
        console.log("getProductBundles err: ", err);
      });
  };

  const getProducts = () => {
    monetizationAPI
      .getAllProducts()
      .then((res) => {
        const { data } = res.data;
        const _data = data.map((product) => ({ ...product, isProduct: true }));

        setProductsList(_data);

        const options = _data.map((product) => ({
          label: product?.name ? product.name : product.productName,
          value: product,
          isProduct: product.isProduct,
        }));

        setProductBundleOptions((prev) => [...prev, ...options]);
      })
      .catch((err) => {
        console.log("getProducts err: ", err);
      });
  };

  const handleChange = (_val, _id, type) => {
    let _attributes = objectDeepClone(attributes);

    _attributes[_id][type] = _val;
    setAttributes(objectDeepClone(_attributes));
  };

  const handleDelete = (_id) => {
    let _attributes = objectDeepClone(attributes);
    _attributes.splice(_id, 1);
    setAttributes(objectDeepClone(_attributes));
  };

  const handleAdd = () => {
    let _attributes = objectDeepClone(attributes);
    _attributes.push({
      name: "",
      value: "",
    });
    setAttributes(objectDeepClone(_attributes));
  };

  const handleSubmit = () => {
    // different flow for Kong and Azure

    if (!appName || !description) {
      getToast({
        statusType: "ERROR",
        message: "Please fill all the required details",
      });
      return;
    }
    setLoading(true);

    if (chosenProduct.type === GATEWAYS.NGINX) {
      let _data = {
        description: description,
        name: appName,
        connectorId: chosenProduct.connectorId,
        productId: chosenProduct[PRODUCTS_MAPPER_KEY.NGINX],
        productName: chosenProduct[PRODUCTS_MAPPER_DISPLAY_NAME.NGINX]
      };
      setLoading(true);
      API.createNginxApp(_data)
        .then((res) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
        })
        .catch((err) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        });
      return;
    }

    if (chosenProduct.type === AWS_GATEWAY) {
      let _data = {
        description: description,
        name: appName,
        connectorId: chosenProduct.connectorId,
        usagePlanId: chosenProduct.usagePlanId,
        productName: chosenProduct[PRODUCTS_MAPPER_DISPLAY_NAME.AWS]
      };
      setLoading(true);
      API.createAwsApp(_data)
        .then((res) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
        })
        .catch((err) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        });
      return;
    }
    
    if (chosenProduct.type === "kong") {
      setDisableSave(true);
      monetizationAPI
        .createKongApp({
          name: appName,
          description,
          productsDto: {
            ...chosenProduct.services[0],
          },
          userEmail: currentUser.email,
        })
        .then((res) => {
          setDisableSave(false);
          setLoading(false);
          console.log("kong app res: ", res);
          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
        })
        .catch((err) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        });
      return;
    }
    if (chosenProduct.type === "azure") {
      const azureConnectors = currentUser.azureConnectors;
      if (!azureConnectors.length) {
        throwServerError("Selected Product is not associated to this User!");
        return;
      }

      const azureUserId = azureConnectors.find(
        (connector) => connector.connectorId === chosenProduct.connectorId
      )?.azureUserId;

      if (!azureUserId) {
        throwServerError("Selected Product is not associated to this User!");
        return;
      }

      monetizationAPI
        .createAzureApp({
          name: appName,
          productName: chosenProduct.name,
          ownerId: azureUserId,
          connectorId: chosenProduct.connectorId,
          description,
        })
        .then((res) => {
          setDisableSave(false);
          setLoading(false);

          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
        })
        .catch((err) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        });
      return;
    }

    if (chosenProduct.type === "apigee") {
      let _data = {
        apiProducts: [chosenProduct.name],
        attributes: attributes,
        description: description,
        name: appName,
        keyExpiresIn: "-1",
        organization: chosenProduct.Organization,
      };
      API.addBuildApps(_data)
        .then((res) => {
          setDisableSave(false);
          setLoading(false);

          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
        })
        .catch((err) => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        });
      return;
    }
  };

  return (
    <Modal
      {...props}
      size="md me-0 mt-0 mb-0"
      contentClassName="br-0px homeModalForLghtMode"
      className="custom-modal-v1 v1--styles"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-3 br-0px">
        <div className="ps-1 d-flex align-items-center justify-content-between w-100">
          <p className="mb-0 text-content-subtle fw-500 fs-16px">
            {props.isUpdate ? "Update App" : "App Creation"}
          </p>

          <div className="d-flex align-items-center">
            <div
              className="text-content-subtle fs-12px fw-400 me-3 cursor-pointer"
              onClick={() => {
                props.onHide();
              }}
            >
              Cancel
            </div>

            {disableSave ? (
              <Loading size="sm" />
            ) : (
              <div
                className="cursor bg-base-color text-content-subtle px-16px py-6px br-4px fw-500 fs-12px text-300 px-3"
                onClick={handleSubmit}
                style={{ border: "0.5px solid var(--content-subtle)" }}
              >
                {props.isUpdate
                  ? "Save"
                  : chosenProductBundle
                  ? "Buy Now"
                  : "Create"}
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar"
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="">
            <div className="mb-3 mainSelect">
              <p className="fs-12px fw-400 text-content-subtle">Product</p>
              {chosenProduct && (
                <p className="fs-14px fw-500 text-content-color mt-1px">
                  {chosenProduct?.name
                    ? chosenProduct?.name
                    : chosenProduct?.productName
                    ? chosenProduct?.productName
                    : ""}
                </p>
              )}
            </div>

            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">
                App Name <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                value={appName}
                disabled={props.isUpdate}
                onChange={(e) => setAppName(e.target.value)}
                className="from-control v1-form-control mt-1 v1-form-control-public w-100"
              />
            </div>

            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">
                Description <span className="text-danger">*</span>
              </p>
              <textarea
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
              />
            </div>
            {chosenProduct?.type === "apigee" && (
              <div
                className="mb-3"
                style={{
                  border: "1px solid var(--base-100)",
                  borderLeft: "0px",
                }}
              >
                <div
                  className="px-16px py-18px"
                  style={{ borderLeft: "5px solid var(--primary-color)" }}
                >
                  <p className="fs-14px fw-600 text-content-color">Attribute</p>

                  <div
                    className="dfaic justify-content-center px-24px py-10px br-4px mt-2 cursor"
                    style={{ border: "1px dashed var(--secondary-100)" }}
                    onClick={handleAdd}
                  >
                    <Plus color="var(--secondary-100)" size={16} />
                    <p className="text-content-color fs-14px ms-2 fw-500">
                      Add Attribute
                    </p>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-5">
                      <p className="fs-12px fw-500 text-content-subtle">Name</p>
                    </div>
                    <div className="col-md-5">
                      <p className="fs-12px fw-500 text-content-subtle">
                        Value
                      </p>
                    </div>
                  </div>

                  {attributes?.map((each, i) => (
                    <div className="row mt-2">
                      <div className="col-md-5 pe-0">
                        <input
                          type="text"
                          className="form-control v1-form-control v1-form-control-public"
                          value={each.name}
                          onChange={(e) =>
                            handleChange(e.target.value, i, "name")
                          }
                        />
                      </div>
                      <div className="col-md-5 pe-0">
                        <input
                          type="text"
                          className="form-control v1-form-control v1-form-control-public"
                          value={each.value}
                          onChange={(e) =>
                            handleChange(e.target.value, i, "value")
                          }
                        />
                      </div>
                      <div className="col-md-2 my-auto">
                        <div
                          className="ms-3 cursor"
                          onClick={() => handleDelete(i)}
                        >
                          <Trash color="var(--content-subtle)" size={18} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
