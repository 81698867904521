import { useEffect, useState } from "react";
import { DefaultIcon } from "../DefaultIcon";
import Loading from "../../../../components/Loader/Loading";
import { useNavigate } from "react-router";
import { EditDeleteDropdown } from "../EditDeleteDropdown";
import { useSelector } from "react-redux";

export const CategoryList = ({
  data,
  loading,
  setAllPosts,
  activeCategory,
  setActiveCategory,
  setShowMobileMenu,
  setShowCategoryModal,
  deleteCallback,
  setSelectedtags,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);

  const onCategoryClicked = (categoryName) => {
    localStorage.setItem("active_category", categoryName);
    setActiveCategory(categoryName);
    navigate("/forum");
    setSelectedtags((prev) => (prev.length === 0 ? prev : []));
    setShowMobileMenu(false);
  };

  useEffect(() => {
    if (localStorage.getItem("active_category")) {
      setActiveCategory(localStorage.getItem("active_category"));
    }
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            className="collapsible-btn"
            onClick={() => {
              setCollapsed((prev) => !prev);
            }}
          >
            <DefaultIcon type={collapsed ? "caret-right" : "caret-down"} />

            <p className="collapsible-btn-text">Forum Categories</p>
          </div>
          {!collapsed && (
            <div className="category-list__container">
              {data &&
                data.data?.length > 0 &&
                data.data.map((category) => (
                  <div
                    className={`category-list-item ${
                      activeCategory === category.name ? "active" : ""
                    }`}
                    onClick={() => onCategoryClicked(category.name)}
                  >
                    {category.name}
                    {currentUser && currentUser.userRole === "super-admin" && (
                      <EditDeleteDropdown
                        editCallback={() => {
                          setShowCategoryModal({
                            show: true,
                            editMode: true,
                            category,
                          });
                        }}
                        deleteCallback={() => {
                          deleteCallback({ id: category._id });
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
