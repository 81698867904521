import { createSlice } from "@reduxjs/toolkit";
import { objectDeepClone } from "../../../utils/helper";

const initialState = {
    data: {
        blocks: []
    },
    guide: null,
    pages: {},
    groups: [],
    showPage: false,
    tableOfContents: [],
    filter: {
        search: ""
    }
};

export const GuideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    setGuideProps: (state, {payload}) => {
        let {props, value} = payload;
        state[props] = value;
    },
    setMultiGuideProps: (state, {payload}) => {
        for(let {props, value} of payload)
        {
            state[props] = value
        }
    },
    setGuideFilter: (state, {payload}) => {
        let {prop, value} = payload;
        let _filter = objectDeepClone(state.filter);
        _filter[prop] = value;
        state.filter = _filter;
    }
  },
});

export const { setGuideProps, setMultiGuideProps, setGuideFilter } = GuideSlice.actions;
export default GuideSlice.reducer;
