import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loader/Loading";
import { showSuccessMessage, throwServerError } from "../../../utils/helper";
import API from "../../../api";
import { GATEWAYS } from "../../../service/constants";
const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

export default function UpdateAppModal(props) {
  let app = (props.data || {});
  const [appName, setAppName] = useState(app.name);
  const [description, setDescription] = useState(app.description);
  const [loading, setLoading] = useState(false);

  const saveNginxApp = () => {
    return new Promise((resolve, reject) => {
      API.updateNginxApp({id: app._id, body: {description: description}})
      .then(res => {
        resolve(res);
        return res;
      })
      .catch(err => {
        reject(err);
        return;
      })
    })
  }

  const saveAwsApp = () => {
    return new Promise((resolve, reject) => {
      API.updateAwsApp({id: app._id, body: {description: description}})
      .then(res => {
        resolve(res);
        return res;
      })
      .catch(err => {
        reject(err);
        return;
      })
    })
  }
  const handleSubmit = async() => {
    setLoading(true);
    try {
      let updatedApp = null;
      if(app.type === GATEWAYS.NGINX) {
        let res = await saveNginxApp();
        updatedApp = res?.data?.data;
      }

      if(app.type === AWS_GATEWAY) {
        let res = await saveAwsApp();
        updatedApp = res?.data?.data;
      }
      
      if(updatedApp) props.cb(updatedApp);
      props.onHide();
      setLoading(false);
      showSuccessMessage("App updated successfully!!")
    }
    catch(err) {
      throwServerError(err);
      setLoading(false)
    }
  };

  return (
    <Modal
      {...props}
      show={true}
      size="md me-0 mt-0 mb-0"
      contentClassName="br-0px homeModalForLghtMode"
      className="custom-modal-v1 v1--styles"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-3 br-0px">
        <div className="ps-1 d-flex align-items-center justify-content-between w-100">
          <p className="mb-0 text-content-subtle fw-500 fs-16px">
            Update App
          </p>

          <div className="d-flex align-items-center">
            <div
              className="text-content-subtle fs-12px fw-400 me-3 cursor-pointer"
              onClick={() => {
                props.onHide();
              }}
            >
              Cancel
            </div>

            {loading ? (
              <Loading size="sm" />
            ) : (
              <div
                className="cursor bg-base-color text-content-subtle px-16px py-6px br-4px fw-500 fs-12px text-300 px-3"
                onClick={handleSubmit}
                style={{ border: "0.5px solid var(--content-subtle)" }}
              >
                Save
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar"
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="">
            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">
                App Name <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                value={appName}
                disabled={true}
                onChange={(e) => setAppName(e.target.value)}
                className="from-control v1-form-control mt-1 v1-form-control-public w-100"
              />
            </div>

            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">
                Description <span className="text-danger">*</span>
              </p>
              <textarea
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
