import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import StarRating from './StarRating';
import API from '../../../../api';
import { useSelector } from 'react-redux';
import { throwServerError } from '../../../../utils/helper';
import { getToast } from '../../../../components/Toast';

export default function WriteReviewModal(props) {
    const [rating, setRating] = useState(0)
    const [review, setReview] = useState('')
    const {currentUser} = useSelector(state => state.user)

    useEffect(() => {
        if (props.isEdit){
            setRating(props.data?.rating || 0)
            setReview(props.data?.comment || '')
        }
    }, [])
    

    const postReview = () => {
        if (!rating || !review) return

        if (props.isEdit){
            API.updateReview(props.data?.id, {
                review: {
                    packageId: props.packageData?.id || '',
                    packageName: props.packageData?.packageName || '',
                    rating, comment: review,
                    developerEmail: currentUser.email || '',
                    developerName: currentUser.fullName || ''
                }
            }).then(res => {
                props.onHide()
                getToast({
                    statusType: 'SUCCESS', message: 'Rating updated successfully'
                })
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.addReview({
                review: {
                    packageId: props.packageData?.id || '',
                    packageName: props.packageData?.packageName || '',
                    rating, comment: review,
                    developerEmail: currentUser.email || '',
                    developerName: currentUser.fullName || ''
                }
            }).then(res => {
                props.onHide()
                getToast({
                    statusType: 'SUCCESS', message: 'Rating added successfully'
                })
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            contentClassName='homeModalForLghtMode'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body
                style={{ height: "calc(30vh)" }}
                className="overflow-scroll noscrollbar br-4px"
            >
                <div>
                    <div className='dfaic justify-content-between'>
                        <div className='dfaic'>
                            <p className='fs-12px fw-700 text-content-subtle me-2'>Add Rating:</p>
                            <StarRating rating={rating} onClick={(val) => setRating(val)} />
                        </div>

                        <div className='dfaic gap-16px'>
                            <p className='cursor text-content-subtle fs-14px fw-500'
                                onClick={props.onHide}>Cancel</p>

                            <div className={`bg-primary-color px-18px py-4px br-4px ${(!rating || !review) ? 'o-05 cursor-disabled' : 'cursor'}`}
                                onClick={postReview}>
                                <p className='text-center fs-14px fw-500 text-base-color'>{
                                    props.isEdit ? 'Update' : 'Post'
                                }</p>
                            </div>
                        </div>
                    </div>

                    <div className='mt-12px'>
                        <p className='fs-12px fw-400 text-content-subtle'>Write a Review</p>
                        <textarea
                            rows={5} placeholder='Write review here...' value={review}
                            onChange={(e) => setReview(e.target.value)}
                            className="h-100 mt-2 w-100 input-transparent p-2 bg-primary-subtle resize-none text-content-color fs-14px fw-400"
                        ></textarea>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
