import { DotsSixVertical, PencilSimple, Plus, Trash } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  setFooterMenu,
  setSocialMenu,
} from "../../../../app/Features/Layout/LayoutSlice";
import {
  confirm,
  isValidURL,
  objectDeepClone,
  throwServerError,
  validateHeaderMenu,
} from "../../../../utils/helper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import API from "../../../../api";
import { getToast } from "../../../../components/Toast";
import Loading from "../../../../components/Loader/Loading";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";

function EachSubRow({
  data,
  parentEdit,
  setData,
  parentSetEdit,
  handleDelete,
  parentHandleCancel,
  index,
}) {
  const [showChildren, setShowChildren] = useState(data.isEdit);
  const { roles, groups } = useSelector((state) => state.layout);
  const [errors, setErrors] = useState({});

  const setIsEdit = (val) => {
    setData({ ...data, isEdit: val });
  };
  const getComputedRoles = roles.map((o) => ({ label: o.name, value: o.code }));

  const getComputedRoleValues = (_roles) => {
    let allRoles = objectDeepClone(getComputedRoles || []);
    let result = allRoles.filter((o) => _roles.includes(o.value));
    return result;
  };

  const getComputedGroups = groups.map((o) => ({
    label: o.partnerName,
    value: o._id,
  }));

  const getComputedGroupValues = (_groups) => {
    let allGroups = objectDeepClone(getComputedGroups || []);
    let result = allGroups.filter((o) => _groups.includes(o.value));
    return result;
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setShowChildren(true);
    setIsEdit(true);
    parentSetEdit(true);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    parentHandleCancel();
  };

  const handleSave = (e) => {
    console.log("called");
    e.stopPropagation();
    let tempErrors = {};
    let _data = objectDeepClone(data);
    if (!_data.name) {
      tempErrors["name"] = "Footer Name is Required";
    } else if (_data.name.length < 3 || _data.name.length > 20) {
      tempErrors["name"] = "Footer name should be of length 3 to 20";
    }

    if (!_data.url) {
      tempErrors["url"] = "URL is Required";
    } else if (_data.type === "external" && !isValidURL(_data.url)) {
      tempErrors["url"] = "URL is not valid";
    }

    if (!_data.type) tempErrors["type"] = "URL Type is Required";

    setErrors(objectDeepClone(tempErrors));

    if (Object.keys(tempErrors).length) {
      return;
    } else {
      setData({ ...data, isEdit: false });
    }
  };

  const [isCtrl, setIsCtrl] = useState(false);

  return (
    <Draggable
      key={index}
      draggableId={`section-${data.name}-${index}`}
      index={index}
      isDragDisabled={parentEdit}
    >
      {(provided) => (
        <div
          className="my-6px"
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          onKeyDown={(e) => {
            setIsCtrl(["Control", "Meta"].includes(e.key));
            if (isCtrl && e.key?.toLowerCase() === "s") {
              handleSave(e);
              e.preventDefault();
            }
          }}
        >
          <div
            className={`d-flex align-items-center justify-content-between pr-12px pl-24px py-12px hover-surface-900 w-100 cursor ${
              data.isEdit ? "surface-900" : "surface-base"
            }`}
            onClick={() => setShowChildren((curr) => !curr)}
          >
            <div className="d-flex align-items-center">
              {!parentEdit && (
                <DotsSixVertical
                  size={16}
                  color="#f1f1f1"
                  className="mr-14px"
                />
              )}
              <p className="fs-14px fw-500 text-200">{data.name}</p>
            </div>

            {!data.isEdit ? (
              <div className="pe-2 d-flex align-items-center">
                <div className="mr-16px" onClick={handleEdit}>
                  {" "}
                  <PencilSimple />{" "}
                </div>
                <div onClick={handleDelete}>
                  <Trash />
                </div>
              </div>
            ) : (
              <div className="pe-2 d-flex align-items-center">
                <p
                  className="text-300 fs-12px fw-500 me-3"
                  onClick={handleCancel}
                >
                  Cancel
                </p>

                <div
                  className="btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500"
                  onClick={handleSave}
                >
                  Save
                </div>
              </div>
            )}
          </div>

          {showChildren && data.isEdit && (
            <div className="surface-base px-24px py-16px">
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-300 fs-12px fw-400">
                    Footer Name <span className="text-danger">*</span>
                  </p>
                  <p className="text-400 fs-12px fw-400">
                    {data.name?.length || 0} / 20
                  </p>
                </div>
                <input
                  className="form-control v1-form-control mt-1"
                  value={data.name}
                  disabled={!data.isEdit}
                  placeholder="Enter Footer Name"
                  onChange={(e) => {
                    let _str = e.target.value || "";
                    if (_str.length < 21) setData({ ...data, name: _str });
                    setErrors({
                      ...errors,
                      name: _str.length > 0 ? false : "Footer Name is Required",
                    });
                  }}
                />
                {errors.name && (
                  <p className="text-danger fs-12px mt-1">{errors.name}</p>
                )}
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <p className="text-300 fs-12px fw-400">
                    URL <span className="text-danger">*</span>
                  </p>
                  <input
                    className="form-control v1-form-control mt-1"
                    value={data.url}
                    disabled={!data.isEdit}
                    placeholder="Enter URL"
                    onChange={(e) => {
                      setData({ ...data, url: e.target.value });
                      setErrors({
                        ...errors,
                        url:
                          e.target.value.length > 0 ? false : "URL is Required",
                      });
                    }}
                    list="dataListForUrls"
                  />
                  {errors.url && (
                    <p className="text-danger fs-12px mt-1">{errors.url}</p>
                  )}
                </div>

                <datalist id="dataListForUrls">
                  <option value="/api-guides" />
                  <option value="/api-references" />
                  <option value="/contact-us" />
                  <option value="/events" />
                  <option value="/forum" />
                  <option value="/apps" />
                  <option value="/monetization/product-bundles" />
                  <option value="/monetization/purchases" />
                  <option value="/open-api" />
                  <option value="/sdk-tools" />
                  <option value="/usecases" />
                </datalist>

                <div className="col-md-6">
                  <p className="text-300 fs-12px fw-400">
                    URL Type <span className="text-danger">*</span>
                  </p>
                  <Select
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    className="v1select mt-1"
                    isDisabled={!data.isEdit}
                    value={
                      data.type
                        ? {
                            label:
                              data.type === "internal"
                                ? "Internal"
                                : "External",
                            value: data.type,
                          }
                        : {}
                    }
                    options={[
                      { label: "Internal", value: "internal" },
                      { label: "External", value: "external" },
                    ]}
                    onChange={(e) => {
                      setData({ ...data, type: e.value });
                      setErrors({ ...errors, type: false });
                    }}
                  />
                  {errors.type && (
                    <p className="text-danger fs-12px mt-1">{errors.type}</p>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <p className="text-300 fs-12px fw-400">Permission Role</p>
                  <Select
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    className="v1select mt-1 h-auto"
                    isDisabled={!data.isEdit}
                    options={getComputedRoles}
                    isMulti
                    onChange={(e) =>
                      setData({
                        ...data,
                        visibleToRoles: e?.map((o) => o.value),
                      })
                    }
                    value={getComputedRoleValues(data.visibleToRoles)}
                  />
                </div>

                <div className="col-md-6">
                  <p className="text-300 fs-12px fw-400">User Group</p>
                  <Select
                    classNames={{
                      option: (state) =>
                        state.isFocused
                          ? "focused"
                          : state.isSelected
                          ? "selected"
                          : "",
                    }}
                    className="v1select mt-1 h-auto"
                    isDisabled={!data.isEdit}
                    options={getComputedGroups}
                    isMulti
                    onChange={(e) =>
                      setData({ ...data, userGroup: e?.map((o) => o.value) })
                    }
                    value={getComputedGroupValues(data.userGroup)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="d-flex">
                    <div
                      onClick={() => {
                        setData({
                          ...data,
                          visibleToAnonymous: !data.visibleToAnonymous,
                        });
                      }}
                      className="d-flex align-items-center gap-6px cursor"
                      style={{ marginLeft: -2 }}
                    >
                      {data.visibleToAnonymous ? (
                        <GlobalIcons type={"form-check"} />
                      ) : (
                        <GlobalIcons type={"form-uncheck"} />
                      )}
                      <span className="text-300 fs-13px">
                        Visible to non logged-in user
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}

export default function EachRow({
  parentEdit,
  parentSetEdit,
  xDragStart,
  handleDelete,
  headerIndex,
}) {
  const { currentLayoutObject, backupCurrentLayout } = useSelector(
    (state) => state.layout
  );
  const footerMenu = currentLayoutObject.footerMenu || [];
  const data = footerMenu.length > headerIndex ? footerMenu[headerIndex] : {};
  const dispatch = useDispatch();

  const setData = (val) => {
    let _overall = objectDeepClone(footerMenu || []);
    _overall[headerIndex] = val;
    dispatch(setFooterMenu(_overall));
  };

  const [showChildren, setShowChildren] = useState(data.isEdit);

  useEffect(() => {
    if (xDragStart) setShowChildren(false);
  }, [xDragStart]);

  const setIsEdit = (val) => {
    setData({ ...data, isEdit: val });
  };
  const [errors, setErrors] = useState({});
  const { roles, groups } = useSelector((state) => state.layout);

  const getComputedRoles = roles.map((o) => ({ label: o.name, value: o.code }));
  const [subLoading, setSubLoading] = useState(false);

  const getComputedRoleValues = (_roles) => {
    let allRoles = objectDeepClone(getComputedRoles || []);
    let result = allRoles.filter((o) => _roles.includes(o.value));
    return result;
  };

  const getComputedGroups = groups.map((o) => ({
    label: o.partnerName,
    value: o._id,
  }));

  const getComputedGroupValues = (_groups) => {
    let allGroups = objectDeepClone(getComputedGroups || []);
    let result = allGroups.filter((o) => _groups.includes(o.value));
    return result;
  };

  const handleSubchildEdit = (val, id) => {
    let _data = objectDeepClone(data);
    _data.submenu[id] = objectDeepClone(val);
    setData(objectDeepClone(_data));
  };

  const handleSubChildDelete = (id) => {
    if (!confirm("Are you sure to delete selected subfooter? ")) return;
    parentSetEdit(true);
    let _data = objectDeepClone(data);
    if (id < _data.submenu.length) _data.submenu.splice(id, 1);
    setData(objectDeepClone(_data));
  };

  const handleSubChildCancel = (id) => {
    if (!confirm("Are you sure all the changes made will be lost? ")) return;
    let _data = objectDeepClone(data);
    let _backupData = objectDeepClone(backupCurrentLayout.footerMenu || []);
    let _backupHeader =
      headerIndex < _backupData.length ? _backupData[headerIndex] : null;
    if (_backupHeader && id < _backupHeader.submenu.length) {
      _data.submenu[id] = objectDeepClone(_backupHeader.submenu[id]);
    } else {
      _data.submenu.splice(id, 1);
    }
    setData(objectDeepClone(_data));
  };

  const handleAdd = (e) => {
    e.stopPropagation();
    setShowChildren(true);
    setIsEdit(true);
    parentSetEdit(true);
    setData({
      ...data,
      submenu: [
        ...(data.submenu || []),
        {
          name: `URL ${data.submenu?.length || 1}`,
          url: "",
          isEdit: true,
          type: "",
          visibleToRoles: "",
          userGroup: "",
        },
      ],
    });
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setShowChildren(true);
    setIsEdit(true);
    parentSetEdit(true);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    let tempErrors = {};
    let _data = objectDeepClone(data);
    if (!_data.name) {
      tempErrors["name"] = "Footer Name is Required";
    } else if (_data.name.length < 3 || _data.name.length > 20) {
      tempErrors["name"] = "Footer name should be of length 3 to 20";
    }

    if (!_data.url) {
      tempErrors["url"] = "URL is Required";
    } else if (_data.type === "external" && !isValidURL(_data.url)) {
      tempErrors["url"] = "URL is not valid";
    }

    if (!_data.type) tempErrors["type"] = "URL Type is Required";

    setErrors(objectDeepClone(tempErrors));

    if (Object.keys(tempErrors).length) {
      return;
    } else {
      setData({ ...data, isEdit: false });
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (!confirm("Are you sure all the changes made will be lost? ")) return;
    let _data = objectDeepClone(footerMenu);
    let _backupData = objectDeepClone(backupCurrentLayout.footerMenu || []);

    if (headerIndex < _backupData.length) {
      _data[headerIndex] = objectDeepClone(_backupData[headerIndex]);
    } else {
      handleDelete(headerIndex);
      return;
    }

    setData(objectDeepClone(_data[headerIndex]));
  };

  const handleDrop = (droppedItem) => {
    if (parentEdit) return;

    let _data = objectDeepClone(data);
    var updatedList = objectDeepClone(_data.submenu);

    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    let _json = objectDeepClone(footerMenu);
    _json[headerIndex].submenu = objectDeepClone(updatedList);

    dispatch(setFooterMenu(objectDeepClone(_json)));
    setData(objectDeepClone(_json[headerIndex]));

    let { data: filteredData } = validateHeaderMenu(_json);

    setSubLoading(true);
    API.putLayoutDetails({ footerMenu: filteredData })
      .then((res) => {
        getToast({
          statusType: "SUCCESS",
          message: "Sequence updated successfully",
        });
        setSubLoading(false);
      })
      .catch((err) => {
        throwServerError(err);
        setSubLoading(false);
      });
  };

  const [isCtrl, setIsCtrl] = useState(false);

  return (
    <div
      className={xDragStart ? "mb-2" : "v1-border-1 mb-2 br-6px"}
      onKeyDown={(e) => {
        setIsCtrl(["Control", "Meta"].includes(e.key));
        if (isCtrl && e.key?.toLowerCase() === "s") {
          handleSave(e);
          e.preventDefault();
        }
      }}
    >
      <Draggable
        key={headerIndex}
        draggableId={`section-${data.name}-${headerIndex}`}
        isDragDisabled={parentEdit}
        index={headerIndex}
      >
        {(provided) => (
          <div
            className="show-on-hover h-53px br-6px d-flex align-items-center justify-content-between px-12px py-16px surface-875 w-100 cursor"
            onClick={() => setShowChildren((curr) => !curr)}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className="d-flex align-items-center">
              {!parentEdit && (
                <DotsSixVertical
                  size={16}
                  color="#f1f1f1"
                  className="mr-15px"
                />
              )}
              <p className="fs-14px fw-500 text-200">{data.name}</p>
            </div>

            {!data.isEdit ? (
              <div className="hoverItem pe-2 d-flex align-items-center">
                <div className="mr-16px" onClick={handleAdd}>
                  {" "}
                  <Plus />{" "}
                </div>

                <div className="mr-16px" onClick={handleEdit}>
                  {" "}
                  <PencilSimple />{" "}
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                >
                  <Trash />
                </div>
              </div>
            ) : (
              <div className="pe-2 d-flex align-items-center">
                <p
                  className="text-300 fs-12px fw-500 me-3"
                  onClick={handleCancel}
                >
                  Cancel
                </p>

                <div
                  className="btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500"
                  onClick={handleSave}
                >
                  Save
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>

      {showChildren && data.isEdit && (
        <div className="surface-base px-24px pt-16px pb-0px">
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <p className="text-300 fs-12px fw-400">
                Footer Name <span className="text-danger">*</span>
              </p>
              <p className="text-400 fs-12px fw-400">
                {data.name?.length || 0} / 20
              </p>
            </div>
            <input
              className="form-control v1-form-control mt-1"
              value={data.name}
              disabled={!data.isEdit}
              placeholder="Enter Footer Name"
              onChange={(e) => {
                let _str = e.target.value || "";
                if (_str.length < 21) setData({ ...data, name: _str });
                setErrors({
                  ...errors,
                  name: _str.length > 0 ? false : "Footer Name is Required",
                });
              }}
            />
            {errors.name && (
              <p className="text-danger fs-12px mt-1">{errors.name}</p>
            )}
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <p className="text-300 fs-12px fw-400">
                URL <span className="text-danger">*</span>
              </p>
              <input
                className="form-control v1-form-control mt-1"
                value={data.url}
                disabled={!data.isEdit}
                placeholder="Enter URL"
                onChange={(e) => {
                  setData({ ...data, url: e.target.value });
                  setErrors({
                    ...errors,
                    url: e.target.value.length > 0 ? false : "URL is Required",
                  });
                }}
                list="dataListForUrls"
              />
              {errors.url && (
                <p className="text-danger fs-12px mt-1">{errors.url}</p>
              )}
            </div>

            <datalist id="dataListForUrls">
              <option value="/api-guides" />
              <option value="/api-references" />
              <option value="/contact-us" />
              <option value="/events" />
              <option value="/forum" />
              <option value="/apps" />
              <option value="/monetization/product-bundles" />
              <option value="/monetization/purchases" />
              <option value="/open-api" />
              <option value="/sdk-tools" />
              <option value="/usecases" />
            </datalist>
            
            <div className="col-md-6">
              <p className="text-300 fs-12px fw-400">
                URL Type <span className="text-danger">*</span>
              </p>
              <Select
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "focused"
                      : state.isSelected
                      ? "selected"
                      : "",
                }}
                className="v1select mt-1"
                isDisabled={!data.isEdit}
                onChange={(e) => {
                  setData({ ...data, type: e.value });
                  setErrors({ ...errors, type: false });
                }}
                value={
                  data.type
                    ? {
                        label:
                          data.type === "internal" ? "Internal" : "External",
                        value: data.type,
                      }
                    : {}
                }
                options={[
                  { label: "Internal", value: "internal" },
                  { label: "External", value: "external" },
                ]}
              />
              {errors.type && (
                <p className="text-danger fs-12px mt-1">{errors.type}</p>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <p className="text-300 fs-12px fw-400">Permission Role</p>
              <Select
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "focused"
                      : state.isSelected
                      ? "selected"
                      : "",
                }}
                className="v1select mt-1 h-auto"
                isDisabled={!data.isEdit}
                options={getComputedRoles}
                isMulti
                onChange={(e) =>
                  setData({ ...data, visibleToRoles: e?.map((o) => o.value) })
                }
                value={getComputedRoleValues(data.visibleToRoles)}
              />
            </div>

            <div className="col-md-6">
              <p className="text-300 fs-12px fw-400">User Group</p>
              <Select
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "focused"
                      : state.isSelected
                      ? "selected"
                      : "",
                }}
                className="v1select mt-1 h-auto"
                isDisabled={!data.isEdit}
                options={getComputedGroups}
                isMulti
                onChange={(e) =>
                  setData({ ...data, userGroup: e?.map((o) => o.value) })
                }
                value={getComputedGroupValues(data.userGroup)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="d-flex">
                <div
                  onClick={() => {
                    setData({
                      ...data,
                      visibleToAnonymous: !data.visibleToAnonymous,
                    });
                  }}
                  className="d-flex align-items-center gap-6px cursor"
                  style={{ marginLeft: -2 }}
                >
                  {data.visibleToAnonymous ? (
                    <GlobalIcons type={"form-check"} />
                  ) : (
                    <GlobalIcons type={"form-uncheck"} />
                  )}
                  <span className="text-300 fs-13px">
                    Visible to non logged-in user
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {subLoading ? (
        <div style={{ height: `${data.submenu.length * 54}px` }}>
          <Loading />
        </div>
      ) : (
        <DragDropContext onDragEnd={handleDrop}>
          <StrictModeDroppable droppableId="list-inner-container">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={
                  !xDragStart
                    ? {}
                    : {
                        height: `${
                          !showChildren ? 0 : data.submenu.length * 54
                        }px`,
                      }
                }
              >
                {showChildren &&
                  data.submenu &&
                  data.submenu.length > 0 &&
                  data.submenu.map((eachSubMenu, i) => (
                    <EachSubRow
                      key={i}
                      data={eachSubMenu}
                      parentEdit={parentEdit}
                      setData={(val) => handleSubchildEdit(val, i)}
                      parentSetEdit={parentSetEdit}
                      handleDelete={() => handleSubChildDelete(i)}
                      index={i}
                      parentHandleCancel={() => handleSubChildCancel(i)}
                    />
                  ))}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      )}
    </div>
  );
}

export function EachSocialRow({
  parentEdit,
  parentSetEdit,
  xDragStart,
  handleDelete,
  socialIndex,
}) {
  const { currentLayoutObject, backupCurrentLayout } = useSelector(
    (state) => state.layout
  );
  const social = currentLayoutObject.social || [];
  const data = social.length > socialIndex ? social[socialIndex] : {};
  const dispatch = useDispatch();

  const setData = (val) => {
    let _overall = objectDeepClone(social || []);
    _overall[socialIndex] = val;
    dispatch(setSocialMenu(_overall));
  };

  const [showChildren, setShowChildren] = useState(data.isEdit);

  useEffect(() => {
    if (xDragStart) setShowChildren(false);
  }, [xDragStart]);

  const setIsEdit = (val) => {
    setData({ ...data, isEdit: val });
  };
  const [errors, setErrors] = useState({});

  const handleEdit = (e) => {
    e.stopPropagation();
    setShowChildren(true);
    setIsEdit(true);
    parentSetEdit(true);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    let tempErrors = {};
    let _data = objectDeepClone(data);

    if (!_data.url) {
      tempErrors["url"] = "URL is Required";
    } else if (_data.type === "external" && !isValidURL(_data.url)) {
      tempErrors["url"] = "URL is not valid";
    }

    setErrors(objectDeepClone(tempErrors));

    if (Object.keys(tempErrors).length) {
      return;
    } else {
      setData({ ...data, isEdit: false });
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (!confirm("Are you sure all the changes made will be lost? ")) return;
    let _data = objectDeepClone(social);
    let _backupData = objectDeepClone(backupCurrentLayout.social || []);

    if (socialIndex < _backupData.length) {
      _data[socialIndex] = objectDeepClone(_backupData[socialIndex]);
      setData(objectDeepClone(_data[socialIndex]));
    } else {
      handleDelete(socialIndex);
      return;
    }
  };

  const [isCtrl, setIsCtrl] = useState(false);

  return (
    <div
      className={xDragStart ? "mb-2" : "v1-border-1 mb-2 br-6px"}
      onKeyDown={(e) => {
        setIsCtrl(["Control", "Meta"].includes(e.key));
        if (isCtrl && e.key?.toLowerCase() === "s") {
          handleSave(e);
          e.preventDefault();
        }
      }}
    >
      <Draggable
        key={socialIndex}
        draggableId={`social-${data.name}-${socialIndex}`}
        isDragDisabled={parentEdit}
        index={socialIndex}
      >
        {(provided) => (
          <div
            className="show-on-hover h-53px br-6px d-flex align-items-center justify-content-between px-12px py-16px surface-875 w-100 cursor"
            onClick={() => setShowChildren((curr) => !curr)}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className="d-flex align-items-center">
              {!parentEdit && (
                <DotsSixVertical
                  size={16}
                  color="#f1f1f1"
                  className="mr-15px"
                />
              )}
              <p className="fs-14px fw-500 text-200 text-capitalize">
                {data.type}
              </p>
            </div>

            {!data.isEdit ? (
              <div className="hoverItem pe-2 d-flex align-items-center">
                <div className="mr-16px" onClick={handleEdit}>
                  {" "}
                  <PencilSimple />{" "}
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                >
                  <Trash />
                </div>
              </div>
            ) : (
              <div className="pe-2 d-flex align-items-center">
                <p
                  className="text-300 fs-12px fw-500 me-3"
                  onClick={handleCancel}
                >
                  Cancel
                </p>

                <div
                  className="btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500"
                  onClick={handleSave}
                >
                  Save
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>

      {showChildren && data.isEdit && (
        <div className="surface-base px-24px pt-16px pb-0px">
          <div className="row mb-3">
            <div className="col-md-12">
              <p className="text-300 fs-12px fw-400">
                URL<span className="text-danger">*</span>
              </p>
              <input
                className="form-control v1-form-control mt-1"
                value={data.url}
                disabled={!data.isEdit}
                placeholder="Enter URL"
                onChange={(e) => {
                  setData({ ...data, url: e.target.value });
                  setErrors({
                    ...errors,
                    url: e.target.value.length > 0 ? false : "URL is Required",
                  });
                }}
              />
              {errors.url && (
                <p className="text-danger fs-12px mt-1">{errors.url}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
