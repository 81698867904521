import { objectDeepClone } from '@apiwiz/oas/services';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Loading from '../../../../components/Loader/Loading';
import GUIDE_CATEGORY_API from '../../../../api/guideCategory';
import { getToast } from '../../../../components/Toast';
import { throwServerError } from '../../../../utils/helper';

export default function PageModal(props) {

    const [data, setData] = useState({
        description: '', parent: '', status: 'Draft', title: '', type: 'document', content: {}
    })
    const [disableSave, setDisableSave] = useState(false)

    useEffect(() => {
        if (props.isEdit){
            console.log('props.data', props.data)
            let _data = objectDeepClone(props.data)
            setData({
                title: _data.title, description: _data.description, 
                status: _data.status === 'draft' ? 'Draft' : 'Published'
            })
        } else {
            setData({...data, parent: props.parentId || ''})
        }
    }, [])
    
    const [errors, setErrors] = useState({})
    const statusOptions = [
        {label: 'Draft', value: 'draft'},
        {label: 'Published', value: 'published'}
    ]

    const handleSubmit = () => {
        let tempErrors = {}

        if (!data.title) tempErrors['title'] = true 
        if (!data.description) tempErrors['description'] = true 

        setErrors(tempErrors)

        if (Object.keys(tempErrors).length) return

        setDisableSave(true)

        if (props.isEdit){
            let json = objectDeepClone(data)
            json.status = json.status.toLowerCase()
            GUIDE_CATEGORY_API.updatePage(props.mainData?.guide?._id, 
            props.mainData?.version?._id, props.data.id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Page updated successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        } else {
            let json = objectDeepClone(data)
            json.status = json.status.toLowerCase()
            GUIDE_CATEGORY_API.createPage(props.mainData?.guide?._id, props.mainData?.version?._id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'New Paged added successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} Page</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div onKeyDown={(e) => {
                        setIsCtrl(['Control', 'Meta'].includes(e.key))
                        if (isCtrl && e.key?.toLowerCase() === 's') {
                            handleSubmit()
                            e.preventDefault()
                        }
                    }}>
                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Title <span className='text-danger'>*</span> </p>
                        <input type='text' className='form-control v1-form-control mt-1'
                            value={data.title} onChange={(e) => {
                                let _str = e.target.value 
                                setData({...data, title: _str })
                                setErrors({...errors, title: _str.length === 0})
                            }} />
                        {errors.title && <p className='text-danger fs-11px mt-1'>
                            Title is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Status <span className='text-danger'>*</span> </p>
                        <Select className='v1select mt-1' value={{label: data.status, value: data.status?.toLowerCase()}}
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            options={statusOptions} onChange={(e) => {
                                let _str = e.label 
                                setData({...data, status: _str })
                            }} />
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Description <span className='text-danger'>*</span> </p>
                        <textarea className='form-control v1-form-textarea mt-1'
                            value={data.description} rows={4}
                            onChange={(e) => {
                                let _str = e.target.value 
                                setData({...data, description: _str })
                                setErrors({...errors, description: _str.length === 0})
                            }} />
                        {errors.description && <p className='text-danger fs-11px mt-1'>
                            Description is required
                        </p>}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
