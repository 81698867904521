import React from 'react'

export default function CustomImage1({data, className}) {
    return (
        <div className={`${data.showBackground ? 'bg-base-100' : 'bg-currentBackground'}`}>
            <div className='container'>
                <div className={`customImage2Wrapper px-0px ${data.showBackground ? 'py-48px' : 'py-81px'} m-0`}>
                    <div className='customImage2 w-100 dfaic mx-auto h-100'>
                        <div className='leftPanel mx-0 px-5'>
                            <img src={data.imageUrl} className='d-block mx-auto'
                                style={{objectFit: 'contain', width: '100%', maxWidth: 522, maxHeight: 474}} alt='' />
                        </div>

                        <div className='rightPanel mx-0 pe-4'>
                            <div>
                                {data.tagName && <div className='titleTagPartner mb-17px'>
                                    <p className='text-theme-base fs-16px fw-600 text-center'>{data.tagName}</p>
                                </div>}
                                <p className='fw-600 fs-32px text-content-color' style={{lineHeight: '125%'}}>
                                    {data.heading}
                                </p>

                                <p className='fw-400 mt-24px fs-20px text-content-subtle' style={{lineHeight: '150%'}}>
                                    {data.description}
                                </p>
                            </div>

                            {(data.metrics && data.metrics?.length > 0) && <div className='d-flex align-items-center gap-40px flex-wrap mt-70px'>
                                {data.metrics?.map((eachMetric, i) => <div key={i} style={{width: 140}}>
                                    <p className='fs-40px fw-600 text-primary-color text-uppercase'
                                        style={{lineHeight: '110%'}}>{eachMetric.key}</p>
                                    <p className='fs-18px fw-400 text-content-color'>
                                        {eachMetric.value}
                                    </p>
                                </div>)}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
