import { createSlice } from "@reduxjs/toolkit";
import { removeCookie, setCookie } from "../../../utils/cookie";
import API from "../../../api";
import GUIDE_API from "../../../api/guides";
import GUIDE_CATEGORY_API from "../../../api/guideCategory";
import { getSessionSharing, objectDeepClone } from "../../../utils/helper";

const menus = [
  {
      "id": "Dashboard",
      "slug": "dashboard",
      "sequenceId": 1,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "Guides",
      "slug": "guides",
      "sequenceId": 2,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "Manage Portal",
      "slug": "configure",
      "sequenceId": 3,
      "isDisabled": false,
      "subModule": [
      {
          "id": "Sections",
          "slug": "sections",
          "isDisabled": false
      },
      {
        "id": "Promotions",
        "slug": "promotions",
        "isDisabled": false
      },
      {
          "id": "Themes",
          "slug": "themes",
          "isDisabled": false
      }
      ]
  },
  {
      "id": "Users",
      "slug": "manage",
      "sequenceId": 4,
      "isDisabled": false,
      "subModule": [
      {
          "id": "Manage Users",
          "slug": "users",
          "isDisabled": false
      },
      {
          "id": "Manage Attributes",
          "slug": "attributes",
          "isDisabled": false
      },
      {
          "id": "Access Control List",
          "slug": "access-control-list",
          "isDisabled": false
      }
      ]
  },
  {
      "id": "Use Cases",
      "slug": "usecases",
      "sequenceId": 5,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "SDKs",
      "slug": "sdks",
      "sequenceId": 6,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "Events",
      "slug": "events",
      "sequenceId": 7,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "Manage Gateway",
      "slug": "manage-gateways",
      "sequenceId": 8,
      "isDisabled": false,
      "subModule": [
      {
          "id": "Gateway details",
          "slug": "gateway-details",
          "isDisabled": false
      },
      {
          "id": "Associate Users for Gateways",
          "slug": "associate-users",
          "isDisabled": false
      },
      {
        "id": "Manage Products",
        "slug": "products",
        "isDisabled": false
    },
      ]
  },
  {
      "id": "App Approval",
      "slug": "app-approval",
      "sequenceId": 9,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "Settings",
      "slug": "settings",
      "sequenceId": 10,
      "isDisabled": false,
      "subModule": []
  },
  {
      "id": "Enquiries",
      "slug": "contact-information",
      "sequenceId": 11,
      "isDisabled": false,
      "subModule": []
  }
]

const initialState = {
    data: null,
    isAuthenticated: false,
    token: null,
    showPassword: "password",
    permissionData: null,
    menus: [],
    permission: [],
    currentUser: {},
    partnersList:[],
    roles:[],
    groups:[],
    permissionsData:[],
    firstRoute: `/admin`,
    isAnimating: false,
    filteredMenu: [],
    companiesList: [],
    monetizationEnabled: false
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMonetizationEnabled: (state, {payload}) => {
      state.monetizationEnabled = payload
    },
    initApiHeader(token){
      API.initHeaders(token)
      GUIDE_API.initHeaders(token)
      GUIDE_CATEGORY_API.initHeaders(token)
    },
    setToken: (state, {payload}) => {
      const { data } = payload
      const { token, forumLoginPayload } = data
      state.isAuthenticated = true
      state.data = objectDeepClone(data.user)
      state.token = token

      UserSlice.caseReducers.initApiHeader(token);

      // TODO:
      // getSessionSharing({
      //     forumUrl: '',
      //     email: forumLoginPayload.body.email,
      //     nonce: forumLoginPayload.body.nonce,
      //     timestamp: forumLoginPayload.body.timestamp,
      //     signature: forumLoginPayload.signature,
      //     redirectUrl: ''
      // }, true)

      // window.location.href = '/'
      setCookie('itorix-token', token);
      setCookie('itorix-token-data', JSON.stringify(data || {}));
    },
    setAuthenticatedState: (state, {payload}) => {
      state.isAuthenticated = true
      const {token, data} = payload
      state.data = data
      state.token = token;
      UserSlice.caseReducers.initApiHeader(token);
    },
    resetAuthenticatedState: (state) => {
      state.isAuthenticated = false
      state.data = null
      state.token = null
    },
    setCurrentUser: (state, {payload}) => {
      state.currentUser = payload
    },
    setPartners:(state,{payload}) => {
      state.partnersList = payload
    },
    setCompanies:(state,{payload}) => {
      state.companiesList = payload
    },
    setPermissions:(state,{payload}) => {
      state.permission = payload
    },
    getAllUserRoles:(state,{payload}) => {
      state.roles=payload.data
    },
    getAllGroups:(state,{payload}) => {
      state.groups=payload.data
    },
    setFirstRoute:(state,{payload}) => {
      state.firstRoute = payload
    },
    setFilteredMenu:(state, {payload}) => {
      state.filteredMenu = payload
    },
    setMenus:(state, {payload}) => {
      // state.menus = payload

      // let _temp = objectDeepClone(payload)
      // let found = false
      // _temp.forEach(element => {
      //     if (element.isDisabled || found) return

      //     if (element.subModule && element.subModule.length){
      //       found = `/admin/${element.slug}/${element.subModule[0].slug}`
      //     } else {
      //       found = `/admin/${element.slug}`
      //     }
      // });
      // state.firstRoute = found

      state.menus = menus
    }
  },
});

export const { setToken, resetAuthenticatedState, setAuthenticatedState, setPartners, setCompanies, setCurrentUser,
  getAllUserRoles,getAllGroups, setMenus, setPermissions, setFirstRoute, setFilteredMenu, setMonetizationEnabled } = UserSlice.actions;
export default UserSlice.reducer;
