import { CaretLeft, CaretRight, FolderNotch, FolderPlus, Package, X } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import monetizationV2 from "../../../../api/monetizationV2";
import { useSelector } from 'react-redux';
import { throwServerError } from '../../../../utils/helper';
import Loading from '../../../../components/Loader/Loading';
import { objectDeepClone } from '@apiwiz/oas/services';
import { EmptyState } from '../../../../components/EmptyState';
import { getToast } from '../../../../components/Toast';
import { useParams } from 'react-router';

export default function AddToSubscription(props) {
    const [subscriptions, setSubscriptions] = useState([])
    const [packages, setPackages] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState({})
    const [loading, setLoading] = useState(false)
    const { currentUser } = useSelector((state) => state.user)
    const {id} = useParams()

    useEffect(() => {
        getData()
    }, [])
    
    const getData = () => {
        setLoading(true)
        monetizationV2.getSubscribedPackages({
            offset: 1, 
            expand: true, 
            developerEmail: currentUser.email
        }).then(res => {
            setLoading(false)
            setSelectedSubscription({})
            setSubscriptions(res.data.data || [])
            setPackages([])
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    const handleSelect = (_subscription) => {
        setLoading(true)
        monetizationV2.getSubscriptionDetails({
            id: _subscription.id,
            developerEmail: currentUser.email
        }).then(res => {
            setLoading(false)
            let _selected = objectDeepClone(res.data.data)
            setSelectedSubscription(objectDeepClone(_selected))
            setPackages(_selected.packages || [])
        }).catch(err => {
            setPackages([])
            setLoading(false)
            throwServerError(err)
        })
    }

    const handleSave = () => {
        if (!selectedSubscription.subscriptionName){
            getToast({
                statusType: 'ERROR', message: 'Select a subscription first'
            })
            return
        }
        setLoading(true)
        monetizationV2.addPackageToSubscription({
            id: selectedSubscription.id,
            packageId: id,
            developerEmail: currentUser.email
        }).then(res => {
            setLoading(false)
            getToast({
                statusType: 'SUCCESS', message: `Successfully added package to your subscription - ${selectedSubscription.subscriptionName}`
            })
            props.onHide()
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    return (
        <Modal
            {...props}
            size="lg"
            contentClassName='homeModalForLghtMode br-8px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 surface-consumer-875 br-tl-8px br-tr-8px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-consumer-100 fw-500 fs-18px'>Add to subscription</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-consumer-300 fs-12px fw-500 me-1 cursor-pointer br-3px px-8px py-5px' 
                            onClick={props.onHide} style={{border: '0.5px solid var(--content-color)'}}>
                            <X />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(52vh)" }}
                className="p-0"
            >
                <div className='px-8px py-10px h-100'>
                    {loading
                    ? <Loading />
                    : (selectedSubscription.subscriptionName 
                    ? <div className='h-100'>
                        <div className='px-8px pt-10px pb-18px dfaic gap-14px cursor'
                            onClick={() => {setSelectedSubscription({});setPackages([])}}
                            style={{borderBottom: '0.5px solid var(--content-color)'}}>
                            <CaretLeft size={24} className='text-consumer-50' />
                            <p className='fs-16px fw-500 text-consumer-200'>{selectedSubscription.subscriptionName}</p>
                        </div>
                        <div className='mt-2 overflow-scroll noscrollbar'
                            style={{height: `calc(100% - 60px)`}}>
                            {packages.length === 0
                            ? <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                <EmptyState />
                                <p className="ps-5 text-content-color text-center">No Packages Found</p>
                            </div>
                            : packages.map((each, i) => <div className={`cursor dfaic pl-8px pr-14px pt-14px pb-14px`}
                                key={each.id}>
                                <div className='dfaic gap-12px'>
                                    <Package size={24} className='text-consumer-300' />
                                    <div>
                                        <p className='fs-14px text-consumer-200 fw-500'>{each.packageName}</p>
                                        <p className='mt-6px fs-12px fw-400 text-consumer-200'>
                                            {each.packageDescription}
                                        </p>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    : <div className='overflow-scroll noscrollbar' 
                        style={{height: `calc(100% - 20px)`}}>
                        {subscriptions.length === 0
                        ? <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                            <EmptyState />
                            <p className="ps-5 text-content-color text-center">No Subscriptions Found</p>
                        </div>
                        : subscriptions.map((each) => <div className='show-on-hover dfaic justify-content-between pl-8px pr-14px pt-8px pb-20px'
                            onClick={() => handleSelect(each)}>
                            <div className='dfaic gap-12px'>
                                <FolderNotch size={24} className='text-consumer-300' />
                                <div>
                                    <p className='fs-14px text-consumer-200 fw-500'>{each.subscriptionName}</p>
                                    <p className='mt-6px fs-12px fw-400 text-consumer-200'>
                                        {each.subscriptionDescription}
                                    </p>
                                </div>
                            </div>

                            <div className='hoverItem'>
                                <CaretRight size={20} className='text-consumer-300' />
                            </div>
                        </div>)}
                    </div>)}
                </div>
            </Modal.Body>
            <Modal.Footer className='border-custom-top-600'>
                <div className='w-100 dfaic justify-content-between'>
                    <div className='dfaic gap-12px cursor border-custom-600 br-4px surface-consumer-base py-8px px-16px'
                        onClick={props.openNewModel}>
                        <FolderPlus size={24} weight='bold' className='text-consumer-300' />
                        <p className='text-consumer-300 fs-16px fw-600'>
                            New Subscription
                        </p>
                    </div>

                    <div className='dfaic gap-16px'>
                        <div className='px-12px'>
                            <p className='cursor fs-16px fw-600 text-primary-color'
                                onClick={() => props.onHide()}>Cancel</p>
                        </div>
                        <div className='px-24px py-10px br-8px bg-primary-color cursor'
                            onClick={handleSave}>
                            <p className='fs-16px fw-600 text-white'>Add</p>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
