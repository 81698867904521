import React, { useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { MdArrowLeft } from "react-icons/md";
import { NavigationTab } from "../components/NavigationTab";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const SidebarItem = ({ id, pathName, permission, enabledPermission }) => {
  const navigate = useNavigate();
  const navRef = useRef();
  const {currentUser} = useSelector(state => state.user)

  const checkNotAllowed = (menu) => {
    if ((menu.id === "Access Control List") && (currentUser.userRole !== 'super-admin' && currentUser.userRole !== 'admin')) return true
    if(menu.isDisabled) return 'coming-soon';
    return ''; 
  }
  

  const navigateToRoute = (menu, path) => {
    if (!checkNotAllowed(menu)) {
      navigate( `/admin/` + path);
    }
  };

  return (
    <>
      <Dropdown className="sidebar-dropdown" alignRight
        onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
        <Dropdown.Toggle className="sidebar-dropdown-toggle"
          onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
          <div
            className={`${checkNotAllowed(permission) ? 'not-allowed' : ''} p-0 cursor bg-in`}
            onClick={() => {
              if (!(permission.subModule?.length) && !checkNotAllowed(permission)) {
                navigateToRoute(permission, permission.slug)
              }
            }}
            ref={navRef}
            data-tip data-for={id}
          >
            <NavigationTab
              id={id}
              tabPermission={permission}
              activeTab={pathName}
            />
            {permission.subModule && (
              <MdArrowLeft
                style={{ zIndex: 20000 }}
                size={32}
                className="sidebar-dropdown-icon"
              />
            )}
          </div>
        </Dropdown.Toggle> 
        {permission.subModule?.length && (
          <Dropdown.Menu className="sidebar-dropdown-menu noafter" show={true}>
            <div className="sidebar-dropdown-menu-title">{permission.id}</div>
            <div className="sidebar-dropdown-menu-line"></div>
            {permission.subModule.map((menu, index) => (
              <div
                onClick={() => {
                  if(!checkNotAllowed(menu)) navigateToRoute(menu, permission.slug + "/" + menu.slug)
                }}
                className={`${checkNotAllowed(menu) ? 'not-allowed' : ''} p-0 sidebar-dropdown-menu cursor w-100 d-flex justify-content-between align-items-center`}
              >
                <div key={index} className="sidebar-dropdown-menu-item">
                  {menu.id}
                </div>

                {checkNotAllowed(menu) && (
                  <div className={`${checkNotAllowed(menu)} btn-overlay text-nowrap me-2`}>
                    { 
                      // !menu.enabled ? 'Upgrade' : (!menu.access || !menu.access.length) ? 'No Access' : 
                      menu.isDisabled ? 'Coming Soon' : ''}
                  </div>
                )}
              </div>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

export default SidebarItem;