import { useEffect, useMemo, useRef, useState } from "react";
import sampleBlocks from "../../service/sample-blocks.json";
import {
  debounceFn,
  getLocationBasePath,
  getMenuName,
  objectDeepClone,
  parseEditorJSContent,
  showSuccessMessage,
  throwServerError,
} from "../../utils/helper";
import EditorJSOutput from "../../components/EditorJSOutput";
import GUIDE_API from "../../api/guides";
import API from "../../api";
import { getGroupList } from "../../utils/guide-page";
import {
  BookOpen,
  FilePdf,
  LinkSimple,
  CaretRight,
  CaretDown,
  X,
  MagnifyingGlass,
  CaretCircleRight,
  CaretCircleLeft,
} from "phosphor-react";
// import { GoLinkExternal, GoPrimitiveDot } from "react-icons/go";
import { GoDotFill } from "react-icons/go";
import { FiExternalLink } from "react-icons/fi";
import {
  setInitGlobalSecurities,
  setOperationFromSwagger,
  setSelectedOperation,
  setSwagger,
  setSwaggerFields,
  setSwaggerFilterProp,
} from "../../app/Features/Swagger/SwaggerSlice";
import { useDispatch } from "react-redux";
import CatalogLeftPanelList from "../../components/Catalog/LeftPanel";
import { useSelector } from "react-redux";
import {
  setGuideFilter,
  setGuideProps,
  setMultiGuideProps,
} from "../../app/Features/Guide/GuideSlice";
import CatalogContent from "../../components/Catalog/RightPanel/content";
import CustomNavbar from "../Dashboards/components/CustomNavbar";
import CustomFooter from "../Dashboards/components/CustomFooter";
import _ from "lodash";
import { GUIDE_VIEWS, ROUTE_NAMES } from "../../service/constants";
import CatalogDocView from "../../components/Catalog/RightPanel/docView";
import ContainerLoader from "../../components/Loader/ContainerLoader";
import CatalogCodeView from "../../components/Catalog/RightPanel/codeView";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { getOasSerialNumberFromMetadata } from "@apiwiz/oas/helper";

function PageList(props) {
  let { src, list, order = 0 } = props;
  return list.map((page, index) => {
    let _childPages = page.pages || [];
    let _currSkeleton = `${props.skeleton}.${index}`;
    return (
      <>
        <div
          className={`lh-16px d-flex align-items-center py-6px pr-10px gap-4px cursor left-nav ${
            page.type === "link" ? "left-nav-link" : ""
          } ${page.active ? "active" : ""} ${
            _childPages.length ? "parent" : ""
          }`}
          onClick={() => {
            if (props.onPageClick) props.onPageClick(_currSkeleton);
          }}
          style={{ paddingLeft: order * 12 + 8 }}
        >
          <div className="mt--2px">
            {_childPages.length ? (
              !page.toggle ? (
                <CaretRight color="var(--content-color)" size={12} />
              ) : (
                <CaretDown color="var(--content-color)" size={12} />
              )
            ) : order ? (
              <div className="px-2px">
                <GoDotFill fill="var(--accent-color)" size={8} />
              </div>
            ) : (
              <div className="w-12px"></div>
            )}
          </div>
          <div className="fs-13px d-flex justify-content-between w-100">
            <div>{page.title}</div>
            {page.type === "link" ? (
              <div className="mt--3px">
                <FiExternalLink />
              </div>
            ) : null}
          </div>
        </div>
        {page.toggle && _childPages.length ? (
          <PageList
            src={src}
            list={_childPages}
            order={order + 1}
            skeleton={`${_currSkeleton}.pages`}
            onPageClick={props.onPageClick}
          />
        ) : null}
      </>
    );
  });
}

export default function View(props) {
  const {
    data,
    pages,
    groups: srcGroups,
    showPage,
    tableOfContents,
    guide,
    filter: guideFilter,
  } = useSelector((state) => state.guide);
  const { selectedOperation, selectOpLoading, metadata } = useSelector(
    (state) => state.swagger
  );
  const { currentLayoutObject } = useSelector((state) => state.layout);
  const [selectedView, setSelectedView] = useState(GUIDE_VIEWS.DOC_VIEW);
  const [selectedAnchorId, setSelectedAnchorId] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const bodyContainerRef = useRef(null);
  const [parentMenuName, setParentMenuName] = useState("API guides");

  const searchTextAvail = (regExp, page) => {
    if ((page.title || "").search(regExp) >= 0) return page;

    let _pages = [];
    for (let x of page.pages || []) {
      let _isPageAvail = searchTextAvail(regExp, x);
      if (_isPageAvail) _pages.push(_isPageAvail);
    }

    if (_pages.length) {
      page.pages = _pages;
      return page;
    }

    return null;
  };

  const groups = useMemo(() => {
    let searchText = guideFilter.search;
    if (!searchText) return objectDeepClone(srcGroups);

    let searchRegExp = new RegExp(searchText, "i");
    let _src = [];
    for (let group of objectDeepClone(srcGroups)) {
      let _pageAvail = searchTextAvail(searchRegExp, group);
      if (_pageAvail) _src.push(_pageAvail);
    }
    return _src;
  }, [srcGroups, guideFilter]);

  const fetchDocument = (docId) => {
    GUIDE_API.fetchDocumentContent(docId)
      .then((res) => {
        let content = res.content || { blocks: [] };
        let tableOfContents = res.tableOfContents || [];
        dispatch(
          setMultiGuideProps([
            {
              props: "data",
              value: parseEditorJSContent(objectDeepClone(content)),
            },
            {
              props: "tableOfContents",
              value: tableOfContents,
            },
          ])
        );
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const getPageBySlug = (groups, slugs) => {
    if (!slugs.length) return;
    let currSlug = slugs[0];
    for (let g of groups) {
      if (currSlug === g.slug) {
        if (g.pages && g.pages.length) {
          slugs.splice(0, 1);
          g.toggle = true;
        } else {
          return g;
        }
      }
      if (g.pages && g.pages.length && slugs.length) {
        let currPage = getPageBySlug(g.pages, slugs);
        if (currPage) return currPage;
      }
    }
  };

  const getSwagger = (swagger) => {
    if (!swagger.swaggerId) return;
    API.getSwagger({
      swaggerId: swagger.swaggerId,
      revision: swagger.revision,
      params: { oas: getOasSerialNumberFromMetadata(swagger) },
    })
      .then((res) => {
        let _swagger = (res.data || {}).data || null;
        if (_swagger) {
          dispatch(setSwagger(_swagger));
          dispatch(setOperationFromSwagger({ code: _swagger }));
          dispatch(setInitGlobalSecurities(_swagger));
        }
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const fetchGuide = () => {
    let slugs = (params["*"] || "").split("/");
    if (!slugs.length) return;
    let guideSlug = slugs.splice(0, 1)[0];

    GUIDE_API.getGuideDetails({
      guideName: guideSlug,
    }, "published")
      .then((data) => {
        let _guide = (data || {}).guide || {};
        let _currentVersion = (data || {}).version || {};
        let _pages = _currentVersion.pages || {};
        let __pages = objectDeepClone(_pages);
        let _groups = getGroupList(objectDeepClone(_pages));
        let _currPage = _groups[0].pages[0];
        if (slugs.length) {
          let currPage = getPageBySlug(_groups, slugs);
          if (currPage) _currPage = currPage;
        }

        _currPage.active = true;
        setCurrentPage(objectDeepClone(_currPage));
        dispatch(
          setMultiGuideProps([
            { props: "pages", value: __pages },
            { props: "groups", value: _groups },
            { props: "showPage", value: true },
            { props: "guide", value: _guide },
          ])
        );
        fetchDocument(_currPage.document);
        if (_currentVersion.swagger) {
          dispatch(
            setSwaggerFields({
              field: "metadata",
              value: _currentVersion.swagger,
            })
          );
          getSwagger(_currentVersion.swagger);
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  useEffect(() => {
    fetchGuide();
    let url = getLocationBasePath();
    if (url) {
      url = `/${url}`;
      let parentMenuName = getMenuName({
        menus: currentLayoutObject.headerMenu,
        url,
      });
      if (!parentMenuName)
        parentMenuName = getMenuName({
          menus: currentLayoutObject.footerMenu,
          url,
        });
      if (parentMenuName) setParentMenuName(parentMenuName);
    }

    /*
    dispatch(setSwagger(acmeBankAccounts));
    dispatch(setOperationFromSwagger({ code: acmeBankAccounts }));
    dispatch(setInitGlobalSecurities(acmeBankAccounts));
    */
  }, []);

  const clearActive = (_groups) => {
    for (let el of _groups) {
      if (el.active) el.active = false;
      if (el.pages && el.pages.length) clearActive(el.pages);
    }
  };

  /*
  const setParentActive = (_groups) => {
    let isChildActive = false;
    for (let el of _groups) {
      //below condition to handle child active state check;
      if (el.active) return true;

      //below condition to set parent active state
      if (el.pages && el.pages.length)
        isChildActive = setParentActive(el.pages);
      if (isChildActive) {
        el.active = true;
        return true;
      }
    }

    return false;
  };
  */

  const updateSrcGroups = (groups, currPage) => {
    for(let p of groups)
    {
      if(p.id === currPage.id)
      {
        p.active = currPage.active;
        p.toggle = currPage.toggle;
        return;
      }
      if(p.pages && p.pages.length)
      {
        updateSrcGroups(p.pages, currPage)
      }
    }
  }

  const onPageClick = (skeleton) => {
    let skeletons = skeleton.split(".");
    let _groups = objectDeepClone(groups);
    let currPage = _groups;
    let _slugs = [];
    for (let s of skeletons) {
      currPage = currPage[s];
      if (currPage.slug) _slugs.push(currPage.slug);
    }

    if (currPage.type === "link") {
      window.open(currPage.url, "_blank");
      return;
    }

    if (currPage.pages && currPage.pages.length)
      currPage.toggle = !currPage.toggle;
    else {
      _slugs = _slugs.filter((x) => (x, x !== "/"));
      currPage.active = true;
      setCurrentPage(objectDeepClone(currPage));
      dispatch(setSelectedOperation(null));
      dispatch(setGuideProps({ props: "showPage", value: true }));
      setSelectedAnchorId(null);
      fetchDocument(currPage.document);
      navigate(
        `/${ROUTE_NAMES.GUIDES}/${guide.slug}${
          _slugs.length ? `/${_slugs.join("/")}` : ""
        }`
      );
    }

    let _srcGroups = objectDeepClone(srcGroups);
    clearActive(_srcGroups);
    updateSrcGroups(_srcGroups, currPage)

    dispatch(setGuideProps({ props: "groups", value: _srcGroups }));
  };

  const onSelectAnchorId = (id) => {
    setSelectedAnchorId(id);
    let el = document.getElementById(id);
    const y = el.getBoundingClientRect().top - 80;
    window.scrollTo({top: y, behavior: "smooth"});
  };

  const onSearchChange = (e) => {
    dispatch(setSwaggerFilterProp({ prop: "search", value: e.target.value }));
    dispatch(setGuideFilter({ prop: "search", value: e.target.value }));
  };

  const onSearchChangeDebounceFn = debounceFn(onSearchChange);

  const [openSidebar, setOpenSidebar] = useState(false)
  return (
    <div className="vw-100 d-flex flex-column view-guide bg-currentBackground">
      <CustomNavbar />
      {/* This will be replaced by Main layout which is yet to be created */}
      <div className={`w-100 mt-62px bg-theme-base`}>
        {/* Body component */}
        <div className="container">
          <div className="w-100 h-100 d-flex position-relative guide-body pb-2">
            <div className="sidebarToggler"
              style={openSidebar ? {right: 0, top: 22} : {}}
              onClick={() => setOpenSidebar(curr => !curr)}>
              { openSidebar 
                ? <CaretCircleLeft size={20} color="var(--primary-color)" />
                : <CaretCircleRight size={20} color="var(--primary-color)" />}
            </div>
            {/* Left Side Panel */}
            <div className={`w-22 border-right-theme-base-200 pb-32px pt-16px pr-32px d-flex flex-column gap-16px fixedLeftBar noscrollbar ${openSidebar ? 'isOpened' : '' }`}>
              <div className="pr-32px">
                <div className="d-flex align-items-center bg-theme-base-100 br-4px py-5px px-8px gap-6px text-theme-content-subtle h-32px">
                  <MagnifyingGlass size={14} weight="bold" />
                  <input
                    onChange={onSearchChangeDebounceFn}
                    placeholder="Search"
                    className="input-transparent fs-14px fw-400 lh-21px"
                  />
                </div>
              </div>
              <div className="pr-32px">
                {groups.map((group, index) => {
                  return (
                    <div className="d-flex flex-column gap-8px">
                      <div className="fw-600 fs-14px text-uppercase h-18px">
                        {group.title}
                      </div>
                      <div className="d-flex flex-column gap-2px">
                        <PageList
                          src={pages}
                          list={group.pages}
                          skeleton={`${index}.pages`}
                          onPageClick={onPageClick}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <CatalogLeftPanelList
                fromGuide
                onListSelected={() => {
                  let _groups = objectDeepClone(srcGroups);
                  clearActive(_groups);
                  dispatch(
                    setMultiGuideProps([
                      { props: "showPage", value: false },
                      { props: "groups", value: _groups },
                    ])
                  );
                }}
              />
            </div>

            {showPage ? (
              <>
                <div className="w-58 centerPanelBetweenLeftRight pt-16px px-86px h-100">
                  {/* Navbar */}
                  <div className="d-flex justify-content-between h-37px gap-6px mobileFlexWrap">
                    {guide ? (
                      <div className="d-flex fs-13px gap-5px">
                        <div
                          className="cursor text-truncate"
                          onClick={() => {
                            navigate(`/${ROUTE_NAMES.GUIDES}`);
                          }}
                        >
                          {parentMenuName}
                        </div>
                        <div>{` / `}</div>
                        <div className="text-truncate">{guide.name}</div>
                        {currentPage ? (
                          <>
                            <div>{` / `}</div>
                            <div className="text-theme-accent text-truncate">
                              {currentPage.title}
                            </div>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                    {currentPage ? (
                      <div className="fs-13px text-theme-content-subtle">
                        Last modified:{" "}
                        {moment(
                          currentPage.updatedAt,
                          "YYYY-MM-DDTHH:mm:ss.sssZ"
                        ).fromNow()}
                      </div>
                    ) : null}
                  </div>
                  <div
                    ref={bodyContainerRef}
                    className="h--37px overflow-scroll-y noscrollbar"
                  >
                    <EditorJSOutput applyTheme data={data} />
                  </div>
                </div>
                <div className="w-20 fixedRightBar d-flex flex-column gap-12px guide-toc">
                  {/* height gap for right panel */}
                  <div className="h-65px"></div>

                  <div className="fs-13px">
                    <div className="d-flex align-items-center gap-4px h-33px pr-10px pl-16px cursor">
                      <div>
                        <LinkSimple size={16} />
                      </div>
                      <div onClick={() => {
                        navigator.clipboard.writeText(window.location.href)
                        showSuccessMessage("Link copied.")
                      }}>Share this Page</div>
                    </div>
                    {/* <div className="d-flex align-items-center gap-4px h-33px pr-10px pl-16px cursor">
                      <div>
                        <FilePdf size={16} />
                      </div>
                      <div>Export as PDF</div>
                    </div> */}
                  </div>
                  {tableOfContents && tableOfContents.length ? (
                    <div className="border-left-theme-base-200 py-8px d-flex flex-column gap-16px">
                      <div className="pl-16px d-flex align-items-center gap-4px fs-13px fw-600">
                        <div className="mt--2px">
                          <BookOpen size={16} />
                        </div>
                        <div>GETTING STARTED</div>
                      </div>
                      <div className="d-flex flex-column fs-12px gap-1px right-nav-section">
                        {tableOfContents.map((el) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  onSelectAnchorId(el.anchorId);
                                }}
                                className={`h-22px pl-16px d-flex align-items-center cursor ${
                                  selectedAnchorId === el.anchorId
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <div className="text-truncate">{el.title}</div>
                              </div>
                              {(el.chapters || []).map((c) => {
                                return (
                                  <div
                                    onClick={() => {
                                      onSelectAnchorId(c.anchorId);
                                    }}
                                    className={`h-22px pl-16px pr-8px d-flex align-items-center cursor ${
                                      selectedAnchorId === c.anchorId
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="h-6px w-6px br-50 bg-theme-primary"></div>
                                    <div className="text-truncate w--24px pl-8px">
                                      {c.title}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
            {selectedOperation ? (
              <>
                {/* API Reference Main body */}
                <div className="w-44 px-32px pt-16px h-100">
                  <div className="d-flex justify-content-between h-37px">
                    <div className="d-flex fs-13px gap-5px">
                      <div
                        className="cursor text-truncate"
                        onClick={() => {
                          navigate(`/${ROUTE_NAMES.GUIDES}`);
                        }}
                      >
                        {parentMenuName}
                      </div>
                      <div>{` / `}</div>
                      <div className="text-truncate">API References</div>
                      <div>{` / `}</div>
                      <div className="text-theme-accent text-truncate">{metadata?.swaggerName}</div>
                    </div>
                  </div>
                  <div className="mt-16px h--53px">
                    <CatalogContent fromGuide />
                  </div>
                </div>
                {/* API reference doc view/code view */}
                <div className="w-34">
                  {/* Tabs */}
                  <div className="d-flex h-48px w-100 rhs-tabs cursor">
                    {[GUIDE_VIEWS.DOC_VIEW, GUIDE_VIEWS.CODE_VIEW].map((el) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedView(el);
                          }}
                          className={`d-flex justify-content-center align-items-center w-50 ${
                            selectedView === el ? "active" : ""
                          }`}
                        >
                          {el}
                        </div>
                      );
                    })}
                  </div>
                  {/* Doc View */}
                  {selectOpLoading ? (
                    <ContainerLoader
                      variant="theme-primary"
                      className="h-200px"
                    />
                  ) : (
                    <>
                      {selectedView === GUIDE_VIEWS.DOC_VIEW ? (
                        <CatalogDocView className="h--48px"/>
                      ) : null}
                      {selectedView === GUIDE_VIEWS.CODE_VIEW ? (
                        <CatalogCodeView className="h--48px"/>
                      ) : null}
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
