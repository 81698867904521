import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Loading from '../../../../components/Loader/Loading';
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import { throwServerError } from '../../../../utils/helper';
import GlobalIcons from '../../../../components/Icons/GlobalIcons';
import { confirm } from '../../../../utils/helper';
import { Eye, EyeSlash } from 'phosphor-react';

export default function JFROGConnectorModal(props) {
    const [isDefault, setIsDefault] = useState(false)

    const [disableSave, setDisableSave] = useState(false)
    const [errors, setErrors] = useState({})

    const [jfrogToken,setJfrogToken] = useState('');
    const [jfrogHostname,setJfrogHostname] = useState('')
    const [jfrogRepository,setJfrogRepository] = useState('')
    const [showToken, setShowToken] = useState(false)

    useEffect(() => {
        if (props.isEdit && props.data){
            let _data = props.data || {}

            setJfrogToken(_data.JFROG_TOKEN || '')
            setJfrogHostname(_data.JFROG_HOSTNAME || '')
            setJfrogRepository(_data.JFROG_REPOSITORY || '')
            setIsDefault(_data.isDefault || false)
        }
    }, [props.data])

    function handleDelete(){
        if (!confirm("Are you sure to delete this connector's data?")) return
        API.deleteJFROGDetails()
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Deleted JFROG details successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            // throwServerError(err)
        })

    }
    
    const handleSubmit = () => {
        let tempErrors = {}
        if (!jfrogToken){
            tempErrors['jfrogToken'] = 'JFROG token is required'
        }
        if (!jfrogRepository){
            tempErrors['jfrogRepository'] = 'JFROG repository is required'
        }
        if (!jfrogHostname){
            tempErrors['jfrogHostname'] = 'JFROG hostname is required'
        }

        setErrors(tempErrors)

        if (Object.keys(tempErrors).length) return

        let json = {
            JFROG_TOKEN:jfrogToken,
            JFROG_HOSTNAME:jfrogHostname,
            JFROG_REPOSITORY:jfrogRepository,
            isDefault:isDefault
        }

        if (props.isEdit){
            API.updateJFROGDetails(json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Updated JFROG details successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.postJFROGDetails(json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'JFROG details added successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)
    
    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} JFROG Connector</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSubmit()
                        e.preventDefault()
                    }
                }}>
                    <div className='mb-3 position-relative'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Token<span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control pe-5' placeholder='Enter Token'
                            value={jfrogToken} onChange={(e) => {
                                let _str = e.target.value 
                                setJfrogToken(_str)
                                setErrors({...errors, jfrogToken: _str.length > 0 ? false : 'JFROG token is Required'})
                            }} 
                            type={showToken ? 'text' : 'password'}
                            />

                        <div className='eyeIconPass cursor' onClick={() => setShowToken(curr => !curr)}>
                            {showToken
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                        
                        {errors.jfrogToken && <p className='text-danger mt-1 fs-11px'>
                            {errors.jfrogToken}
                        </p>}
                    </div>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Hostname<span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control' placeholder='Enter Hostname'
                            value={jfrogHostname} onChange={(e) => {
                                let _str = e.target.value
                                setJfrogHostname(_str)
                                setErrors({...errors, jfrogHostname: _str.length > 0 ? false : 'JFROG hostname is Required'})
                            }} />

                        {errors.jfrogHostname && <p className='text-danger mt-1 fs-11px'>
                            {errors.jfrogHostname}
                        </p>}
                    </div>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Repository<span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control' placeholder='Enter Repository'
                            value={jfrogRepository} onChange={(e) => {
                                let _str = e.target.value
                                setJfrogRepository(_str)
                                setErrors({...errors, jfrogRepository: _str.length > 0 ? false : 'JFROG respository is Required'})
                            }} />

                        {errors.jfrogRepository && <p className='text-danger mt-1 fs-11px'>
                            {errors.jfrogRepository}
                        </p>}
                    </div>
                    <div className='d-flex align-items-center cursor' onClick={() => setIsDefault(!isDefault)}>
                        <GlobalIcons type={isDefault ? 'form-check' : 'form-uncheck'} />
                        <p className='fw-400 fs-14px text-100 ps-1'>Make it Default</p>
                    </div>

                  
                    {(props.isEdit && props.getDeletePermissionJFROG) && <div className='btn fs-12px text-danger br-6px'
                        onClick={handleDelete}
                        style={{position: 'absolute', bottom: 10, left: 10, border: '1px solid #e5484d'}}>
                        Delete
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
