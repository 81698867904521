import { useState, useEffect } from "react";

/**
 * TimelineWizard
 * items -> Array of items which will be iterated to show the list
 * activeItem -> It is the active tab that you will manage from parent component
 * handleItemOnClick -> It is a callback fuction which run on item click
 */

const TimelineWizard = (props) => {
    const { items, activeItem, className, texbreak = false, disabledItems = [], allSuccess = false } = props;
    const totalItems = items.length;
    const [progressWidth, setProgressWidth] = useState(allSuccess ? 100 : 0);
    const [numberOfActiveItems, setNumberOfActiveItems] = useState(0);

    const handleItemOnClick = (item, index) => {
        if (allSuccess) {
            props.handleItemOnClick(item);
            return;
        }
        if (disabledItems.includes(item)) return;

        if (props?.noLimit) {
            props.handleItemOnClick(item);
        } else if (index <= numberOfActiveItems && props.handleItemOnClick) {
            props.handleItemOnClick(item);
        }
    };

    useEffect(() => {
        if (!allSuccess) {
            let _activeItems = items.indexOf(activeItem);
            let _progress = totalItems > 1 ? (_activeItems / (totalItems - 1)) * 100 : 0;
            setProgressWidth(_progress);
            setNumberOfActiveItems(_activeItems - 1);
        }
    }, [activeItem]);

    return (
        <div className="time-wizard-overlay w-100">
            <div className={`timeline-wizard ${className}`}>
                <div className="timeline-wizard-progress" style={{ width: `${progressWidth}%` }}></div>
                <div className="timeline-wizard-items">
                    {items.map((item, index) => (
                        <div
                            onClick={() => handleItemOnClick(item, index)}
                            key={index}
                            className={`timeline-wizard-item cursor ${item === activeItem ? "blue-border" : ""} ${allSuccess ? "active" : index <= numberOfActiveItems ? "active" : ""}`}
                        >
                            <div className={`timeline-wizard-content ${disabledItems.includes(item) && numberOfActiveItems > 2 && "o-05"} ${texbreak ? "text-break" : "text-nowrap"}`}>{item}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimelineWizard;
