import React from 'react'
import GlobalIcons from '../../../components/Icons/GlobalIcons'
import DefaultImage from "../SDKs/assets/default-sdk.png";
import { Link } from "react-router-dom";

export default function Gridcard({data, onClick, getUpdatePermission, editOnClick}) {
    return (
        <div className='v1-border-1 surface-875 boxShadow1 useCaseCard d-flex flex-column h-fit'>
            <div className='w-98'>
                <img src={data.imageUrl ? data.imageUrl : DefaultImage} alt='' />
            </div>

            <div className='w-98 pt-2'>
                <div className='d-flex align-items-center justify-content-between mb-16px'>
                    <div className='d-flex align-items-center'>
                        {data?.tags?.length>1?<><div className='violet-background violet-11 px-8px py-4px br-12px fs-12px fw-500 w-fit'>
                            {data.tags[0] || ''}
                        </div>
                        <div className='violet-background violet-11 px-8px py-4px br-12px fs-12px fw-500 w-fit ms-2'>
                            +{data.tags.length - 1 || ''}
                        </div>
                        </>:<div className='violet-background violet-11 px-8px py-4px br-12px fs-12px fw-500 w-fit'>
                            {data.tags[0] || ""}
                        </div>}
                    </div>
                    {getUpdatePermission && <div className='btn' onClick={editOnClick}>
                        <GlobalIcons type={'pencil-v2'}/>
                    </div>}
                </div>
                <h3 className='text-200 fs-20px fw-700 text-truncate'>
                    {data.title || ''}
                </h3>

                <div className='mt-8px mb-20px' style={{height: 60}}>
                    <p className='fs-14px fw-400 text-200 text-truncate-3'>{data.description || ''}</p>
                </div>

                <div className='d-flex align-items-center'>
                    {getUpdatePermission && <Link to={`/admin/usecases/${data._id}`} className="w-100">
                        <div
                            target={'_blank'} className='px-20px py-9px surface-850 v1-border-1 cursor w-100 d-flex justify-content-center align-items-center br-6px'>
                            <p className='text-300 fs-12px fw-500'>Edit Contents</p>
                        </div>
                    </Link>}
                </div>
            </div>
        </div>
    )
}
