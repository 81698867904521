import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import API from "../../../../api";
import { getToast } from "../../../../components/Toast";

export default function ContactUs(){
    const [fullName,setfullName] = useState('')
    const [touched,setTouched] = useState(false);
    const [jobTitle,setjobTitle] = useState('');
    const [businessEmail,setBusinessEmail] = useState('');
    const [company,setCompany] = useState('')
    const [message,setMessage] = useState('')

    const handleServerSideError = (error) => {
        if (error.response) {
            if (error.response.data.message) {
                getToast({statusType: 'ERROR', message: error.response.data.message})
            }
        } else {
            getToast({statusType:"ERROR",message: error.message })
        }
    }

    function handleSave(event){
        event.preventDefault();

        setTouched(true)
        if(!fullName || !jobTitle || !businessEmail || !company || !message){
            return
        }
        let form = {
            businessEmail: businessEmail,
            company : company,
            fullName : fullName,
            jobTitle : jobTitle,
            message : message
        }
        API.addContact(form)
        .then(response => {
            getToast({statusType: 'SUCCESS', message: 'Your contact form is submitted admin will contact you!'})
            setfullName('');
            setjobTitle('');
            setCompany('');
            setBusinessEmail('');
            setMessage('');
            setTouched(false)
        })
        .catch(error => {
            handleServerSideError(error)
            setTouched(false)
        })
    }


    return <div className="h-100vh w-100">
        <CustomNavbar/>
        <div className="contactUsSection mt-62px d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                <p className="contactUs">Contact Us</p>
                <p className="getInTouch">Get In Touch</p>
                <p className="contactDesc">We’d love to hear from you. Please fill out this form.</p>
            </div>
            <div className="w-100 mt-3">
                <Form className="d-flex flex-column w-100 mt-3 justify-content-center align-items-center"
                    onSubmit={handleSave}>
                    <div className='w-32'>
                        <div className="d-flex justify-content-between w-100">
                            <Form.Group className="mb-2 w-48 me-3" controlId="formBasicEmail1">
                                <Form.Label className="fw-500 fs-12px mb-1 formLabell">
                                    Full Name<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your full name"
                                    value={fullName}
                                    onChange={(e) =>
                                        setfullName(e.target.value)
                                    }
                                    className="purple-form-control"
                                />
                                {touched && !fullName  ? (
                                <Form.Text className="text-danger text-lowercase fs-10px">
                                    Full Name is Required
                                </Form.Text>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="mb-2 w-48" controlId="formBasicEmail1">
                                <Form.Label className="fw-500 fs-12px mb-1 formLabell">
                                    Job Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your job title"
                                    value={jobTitle}
                                    onChange={(e) =>
                                        setjobTitle(e.target.value)
                                    }
                                    className="purple-form-control"
                                />
                                {touched && !jobTitle  ? (
                                <Form.Text className="text-danger text-lowercase fs-10px">
                                    Job Title is Required
                                </Form.Text>
                                ) : null}
                            </Form.Group>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <Form.Group className="mb-2 w-48" controlId="formBasicEmail1">
                                <Form.Label className="fw-500 fs-12px mb-1 formLabell">
                                Business Email<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Business email"
                                    value={businessEmail}
                                    onChange={(e) =>
                                        setBusinessEmail(e.target.value)
                                    }
                                    className="purple-form-control"
                                />
                                {touched && !businessEmail  ? (
                                <Form.Text className="text-danger text-lowercase fs-10px">
                                    Business Email is Required
                                </Form.Text>
                                ) : null}
                            </Form.Group>

                            <Form.Group className="mb-2 w-48" controlId="formBasicEmail1">
                            <Form.Label className="fw-400 fs-12px mb-1 formLabell">
                                Company<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="company"
                                value={company}
                                onChange={(e) =>
                                    setCompany(e.target.value)
                                }
                                className="purple-form-control"
                            />
                            {touched && !company  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Company is Required
                            </Form.Text>
                            ) : null}
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="fw-400 fs-12px mb-1 formLabell">
                                Message<span className="text-danger">*</span>
                            </Form.Label>
                            <textarea rows={4} className='form-control purple-form-control' placeholder='Type your Message' value={message} onChange={(e)=>{setMessage(e.target.value)}}/>
                            {touched && !message  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Message is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        
                    </div>

                    <button className="border-0 w-32 gradientBtnInAuth mt-32px" type='submit'>
                        Send Message
                    </button>
                </Form>
            </div>
            
        </div>
    </div>
}