import React from 'react'

function capitalizeFirstLetter(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const GlobalStatusTag = ({type, className}) => {
  type = type || ""
  return (
    <div className={`global-status-tag ${type.toLowerCase()} ${className}`}>
        <span className='status-text'>{capitalizeFirstLetter(type)}</span>
    </div>
  )
}

export default GlobalStatusTag