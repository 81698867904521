import React from 'react'
import CustomNavbar from '../../../Dashboards/components/CustomNavbar'
import { Link, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { TrendUp } from 'phosphor-react'
import { useEffect } from 'react'
import API from '../../../../api'
import { formatCtsTimeWithoutUTC, getPermissionByCrud, labelize, throwServerError } from '../../../../utils/helper'
import Loading from '../../../../components/Loader/Loading'
import { useSelector } from 'react-redux'
import PaginationV2 from '../../../../components/PaginationV2'
import { objectDeepClone } from '@apiwiz/oas/services'
import { EmptyState } from '../../../../components/EmptyState'


export default function PackagesGroup() {
    const [searchParams] = useSearchParams()
    const _category = searchParams.get('category') 
    const [selectedCategory, setSelectedCategory] = useState(_category ? _category : 'All')
    
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [packages, setPackages] = useState([])
    const {currentUser, permission} = useSelector(state => state.user)
    const [pagination, setPagination] = useState({
        offset: 1, 
        pageSize: 10,
        total: 1
    })
    const [categoriesLoading, setCategoriesLoading] = useState(false)

    /* ACL Start */
    let _permission = objectDeepClone(permission).filter(o => o.name === labelize('packages'))
    let _permissionv2 = objectDeepClone(permission).filter(o => o.name === labelize('package-categories'))

    _permission = _permission.length ? (_permission[0].crud || []) : []
    _permissionv2 = _permissionv2.length ? (_permissionv2[0].crud || []) : []

    const getListPermission = getPermissionByCrud(currentUser, _permission, 'list')
    const getTagListPermission = getPermissionByCrud(currentUser, _permissionv2, 'list')
    /* ACL END */

    useEffect(() => {
        getAllCategories()
    }, [])
    
    const getAllCategories = () => {
        setCategoriesLoading(true)
        API.getAllCategories({expand: true, developerEmail: currentUser.email})
        .then(res => {
            console.log('res', res.data)
            setCategories(res.data?.data || [])
            setCategoriesLoading(false)
        }).catch(err => {
            setCategories([])
            throwServerError(err)
            setCategoriesLoading(false)
        })
    }
    
    useEffect(() => {
        if (selectedCategory === 'All'){
            setPagination({...pagination, offset: 1})
            getRecentPackages(1, pagination.pageSize)
        } else {
            setPagination({...pagination, offset: 1})
            getCategoryPackages(selectedCategory, 1, pagination.pageSize)
        }
    }, [selectedCategory])

    const getRecentPackages = (_offset, _pageSize) => {
        setLoading(true)
        const params = {
            expand: false, 
            offset: _offset,
            pageSize: _pageSize,
            developerEmail: currentUser.email
        }
        API.getRecentPackages(params)
        .then(res => {
            const _data = objectDeepClone(res.data?.data || {})

            if (_data.pagination){
                setPagination({
                    total: _data.pagination.total || 0,
                    offset: _data.pagination.offset || 1,
                    pageSize: _data.pagination.pageSize || 10,
                })
            }
            if (_data.data && _data.data?.length > 0){
                console.log(_data.data)
                setPackages(objectDeepClone(_data.data || []))
            } else {
                setPackages([])
            }

            console.log('_data', _data)

            setLoading(false)
        }).catch(err => {
            throwServerError(err)
            setLoading(false)
            setPackages([])
        })
    }

    const getCategoryPackages = (_category, _offset, _pageSize) => {
        setLoading(true)
        const params = {
            expand: false, 
            offset: _offset,
            pageSize: _pageSize,
            developerEmail: currentUser.email
        }

        API.getPackagesByCategory(_category, params)
        .then(res => {
            const _data = objectDeepClone(res.data?.data || {})

            if (_data.pagination){
                setPagination({
                    total: _data.pagination.total || 0,
                    offset: _data.pagination.offset || 1,
                    pageSize: _data.pagination.pageSize || 10,
                })
            }
            if (_data.data && _data.data?.length > 0){
                setPackages(objectDeepClone(_data.data || []))
            } else{
                setPackages([])
            }

            console.log('_data', _data)
            setLoading(false)
        }).catch(err => {
            throwServerError(err)
            setLoading(false)
            setPackages([])
        })
    }
    
    return (
        <div className='w-100vw bg-currentBackground'
            style={{minHeight: '100vh'}}>
            <CustomNavbar />

            {(getListPermission && getTagListPermission) && <div className='mt-80px container w-100 pt-2 mb-5'>
                <div className='d-flex align-items-center mb-4'>
                    <Link className='text-primary-color fw-500 fs-14px' to='/monetization/home'>Home</Link>
                    <p className='mx-2 text-consumer-400'>/</p>
                    <p className='text-primary-color fw-500 fs-14px'>All Packages</p>
                </div>

                <p className='fs-28px fw-600 text-content-color'>All Packages</p>

                <div className='my-18px dfaic flex-wrap gap-12px'>
                    <div className='filterClass' onClick={() => setSelectedCategory('All')}
                        style={selectedCategory === 'All' ? {borderColor: 'var(--primary-color)'} : {}}>
                        {selectedCategory === 'All' && <div className='h-7px w-7px' 
                            style={{background: 'var(--primary-color)', borderRadius: '100%'}}></div>}
                        <p className='fw-500 fs-16px text-content-color'>All</p>
                    </div>

                    {categoriesLoading
                    ? <div className='mx-2'><Loading size='sm' /></div>
                    : categories.map((each) => <div className={`filterClass`} key={each.id}
                        onClick={() => setSelectedCategory(each.name)} 
                        style={selectedCategory === each.name ? {borderColor: 'var(--primary-color)'} : {}}>
                        {selectedCategory === each.name && <div className='h-7px w-7px' 
                            style={{background: 'var(--primary-color)', borderRadius: '100%'}}></div>}
                        <p className='fw-500 fs-16px text-content-color'>{each.name}</p>
                    </div>)}
                </div>

                {loading 
                ? <div className='my-5'>
                    <Loading />
                </div>
                : <div className='dfaic flex-wrap gap-20px mt-24px'>
                    { packages.length === 0 
                    ? <div className='w-100 my-5 dfaic jcc flex-column'>
                        <EmptyState />
                        <p className="ps-5 text-content-color text-center">
                            No Packages Found
                        </p>
                    </div>
                    : packages.map((eachPackage, i) => (
                        <Link to={`/monetization/packages/${eachPackage.id}`}>
                            <div className={`packageCardv1 hover-box-shadow`} key={eachPackage.id}>
                                <div className={`topSection bg-color-${i}`}>
                                    <div className='iconSection'>
                                        <svg width="123" height="124" viewBox="0 0 123 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.2">
                                                <path opacity="0.2" d="M77.6051 62.5281V112.375C76.9291 112.372 76.2647 112.206 75.6732 111.891L31.4014 88.5632C30.7693 88.2302 30.2416 87.7399 29.8734 87.1435C29.5053 86.547 29.3102 85.8664 29.3086 85.1726V38.847C29.3088 38.301 29.4288 37.7612 29.6608 37.2631L77.6051 62.5281Z" fill="white"/>
                                                <path d="M29.6602 37.2579L77.6045 62.5229L125.549 37.2579" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M79.537 12.1095L123.809 35.4467C124.441 35.7797 124.969 36.27 125.337 36.8664C125.705 37.4629 125.9 38.1435 125.902 38.8373V85.1629C125.9 85.8567 125.705 86.5374 125.337 87.1338C124.969 87.7302 124.441 88.2205 123.809 88.5536L79.537 111.891C78.9446 112.203 78.2803 112.366 77.6051 112.366C76.9299 112.366 76.2656 112.203 75.6732 111.891L31.4014 88.5536C30.7693 88.2205 30.2416 87.7302 29.8734 87.1338C29.5053 86.5374 29.3102 85.8567 29.3086 85.1629V38.8373C29.3102 38.1435 29.5053 37.4629 29.8734 36.8664C30.2416 36.27 30.7693 35.7797 31.4014 35.4467L75.6732 12.1095C76.2656 11.7975 76.9299 11.6339 77.6051 11.6339C78.2803 11.6339 78.9446 11.7975 79.537 12.1095Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M77.6045 62.5279V112.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className='bottomSection surface-consumer-900 p-16px'>
                                    <div className='p-16px h-100'>
                                        {/* {eachPackage.subscriberCount > 0 
                                            ? <div className='dfaic'>
                                                <TrendUp size={20} color='#17B26A' />
                                                <p className='fs-14px fw-500 ms-2' style={{color: '#17B26A', marginBottom: 5}}>{eachPackage.subscriberCount || 0} {eachPackage.subscriberCount === 1 ? 'user' : 'users'}</p>
                                            </div>
                                            : <p className='fs-14px fw-500 text-content-subtle'>
                                                No Subscriptions Yet
                                            </p>} */}

                                        <p className='fs-20px fw-600 mt-1 text-content-color text-truncate'>{eachPackage.packageName || ''}</p>

                                        <div style={{height: 65}}>
                                            <p className='fs-16px fw-400 text-truncate-3 grey-subtext'>
                                                {eachPackage.summary || ''}
                                            </p>
                                        </div>

                                        <p className='text-primary-color fs-14px fw-700 mt-12px text-center'>
                                            ends on {formatCtsTimeWithoutUTC(eachPackage.endDate || '', 'DD MMM YYYY')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>}

                {packages && packages.length > 0 && (
                    <div className='mt-5'>
                        <PaginationV2
                            pagination={{
                                pageSize: pagination?.pageSize,
                                offset: pagination?.offset,
                                total: pagination.total,
                            }}
                            cb={(val) => {
                                setPagination((prev) => ({
                                    ...prev,
                                    offset: val,
                                }));
                                getRecentPackages(val, pagination.pageSize)
                            }}
                        />
                    </div>
                )}
            </div>}
        </div>
    )
}
