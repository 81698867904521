import Select from "react-select";
import Creatable from "react-select/creatable";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import { useEffect, useRef, useState } from "react";
import ForumAPI from "../../../../api/forum";
import EditorJS from "../../../../components/EditorJS";
import { getToast } from "../../../../components/Toast";

export const EditReplyModal = ({
  show,
  onHide,
  postToReply,
  onReplyEditSuccess,
}) => {
  const [replyData, setReplyData] = useState(null);
  const editorInstance = useRef(null);

  useEffect(() => {
    if (show.show) {
      console.log("old reply data: ", show.reply);
      const _replyData = show.reply.content.data;
      setReplyData(_replyData);
    }
  }, [show]);

  const onReplyUpdate = (data) => {
    console.log("updated reply: ", data)
    ForumAPI.updateReply({
      postId: postToReply._id,
      replyId: show.reply._id,
      data,
    })
      .then((res) => {
        onReplyEditSuccess({ replyId: show.reply._id, data: res.data });
        onHide()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onModalClose = () => {
    onHide();
    setReplyData(null);
  };

  const setEditorInstance = (core) => {
    editorInstance.current = core;
    editorInstance.current.height = "100%";
  };

  const onSave = async () => {
    if (editorInstance.current) {
      let doc = await editorInstance.current.save();
      setReplyData(doc);
      console.log("saved reply: ", doc);
      const data = {
        content: {
          data: doc,
        },
      };

      onReplyUpdate(data);
    }
  };

  return (
    <div className={`create-post-modal__container ${show.show ? "open" : ""}`}>
      <div className="create-post-modal-header">
        <p>Replying to {postToReply?.createdBy?.fullName}</p>
        <GlobalIcons
          type={"close"}
          className="cursor-pointer"
          onClick={onModalClose}
        />
      </div>
      <div className="create-post-modal-textarea overflow-scroll-y noscrollbar">
        {replyData && (
          <EditorJS
            data={replyData}
            setEditorInstance={setEditorInstance}
            className="post-content-area"
          />
        )}
      </div>
      <div className="create-post-modal-cta__container">
        <button className="primary-cta" onClick={onSave}>
          Update
        </button>
        <button className="secondary-cta" onClick={() => {}}>
          Clear All
        </button>
      </div>
    </div>
  );
};
