import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import API from '../../../../api';
import AddScenarioBtn from '../../../../components/AddScenarioBtn';
import GlobalIcons from '../../../../components/Icons/GlobalIcons';
import Loading from '../../../../components/Loader/Loading';
import { getToast } from '../../../../components/Toast';
import { confirm, formatCtsDate, formatCtsTime, getPermissionByCrud, labelize, objectDeepClone, slugify, throwServerError } from '../../../../utils/helper';
import { useSelector } from 'react-redux';

export default function CategoryModal(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [backupData, setBackupData] = useState([])
    const [paging, setPaging] = useState({})

    /* ACL Start */
    const {currentUser} = useSelector(state => state.user)
    const {permission} = useSelector(state => state.user)

    let _permission = permission.filter(o => o.name === 'sdk-category')
    _permission = _permission.length ? (_permission[0].crud || []) : []
    const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
    const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
    const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')
    /* ACL END */

    const getListOfSdkCategory = () => {
		setIsLoading(true);
		API.getAllSdkCategory()
			.then((response) => {
				setIsLoading(false);
				if (response.data.data) {
                    let _res = objectDeepClone(response.data.data)
					setData(_res.map(o => ({...o, isEdit: false})));
                    setBackupData(objectDeepClone(response.data.data || []))
					setPaging(response.data.paging);
				} else {
                    setData([])
                    setBackupData([])
                    setPaging({})
                }
			})
			.catch((error) => {
                setIsLoading(false);
                throwServerError(error)
                setData([])
                setBackupData([])
                setPaging({})
            });
	};

    useEffect(() => {
		getListOfSdkCategory();
	}, []);

    const changeEditStatus = (_id) => {
        let _data = objectDeepClone(data)
        _data[_id].isEdit = true 
        setData(objectDeepClone(_data))
    }

    const resetData = (_id) => {
        let _data = objectDeepClone(data)
        let _mainData = objectDeepClone(backupData)

        if (_id < _mainData.length){
            _data[_id] = _mainData[_id]
            _data[_id].isEdit = false
        } else {
            _data.splice(_id, 1)
        }
        setData(objectDeepClone(_data))
    }

    const handleChange = (_val, _id) => {
        let _data = objectDeepClone(data)
        _data[_id].categoryname = _val 
        _data[_id].categoryslug = slugify(_val) 
        setData(objectDeepClone(_data))
    }

    const handleSave = (_id) => {
        let _data = objectDeepClone(data)
        let _obj = _data[_id]

        if (!_obj.categoryname || !_obj.categoryslug){
            getToast({statusType: 'ERROR', message: 'Both Category Name and Slug are required'})
            return
        }

        if (_obj._id){
            API.updateSdkCategory(_obj._id, {
                categoryname: _obj.categoryname,
                categoryslug: _obj.categoryslug
            }).then(res => {
                _data[_id] = objectDeepClone(res.data.data)
                setBackupData(objectDeepClone(_data))
                _data[_id]['isEdit'] = false 
                setData(objectDeepClone(_data))
                getToast({
                    statusType: 'SUCCESS', message: 'Category updated successfully'
                })
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.addSdkCategory({
                categoryname: _obj.categoryname,
                categoryslug: _obj.categoryslug
            }).then(res => {
                _data[_id] = objectDeepClone(res.data.data)
                setBackupData(objectDeepClone(_data))
                _data[_id]['isEdit'] = false 
                setData(objectDeepClone(_data))
                getToast({
                    statusType: 'SUCCESS', message: 'Category created successfully'
                })
            }).catch(err => {
                throwServerError(err)
            })
        }

    }

    const handleAddCategory = () => {
        let _data = objectDeepClone(data)
        _data.push({
            categoryname: '',
            categoryslug: '',
            isEdit: true
        })
        setData(objectDeepClone(_data))
    }

    const handleDelete = (_id) => {
        if (!confirm("Are you sure to delete selected category")) return

        let _data = objectDeepClone(data)
        let _obj = _data[_id]
        API.deleteSdkCategory(_obj._id)
        .then(res => {
            _data.splice(_id, 1)
            setBackupData(objectDeepClone(_data))
            setData(objectDeepClone(_data))
        }).catch(err => {
            throwServerError(err)
        })
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="lg"
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='px-2 py-16px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Manage SDK Categories</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        {/* <div className='btn surface-850 br-4 px-20px py-11px v1-border-2'>
                            <p className='text-300 fs-12px fw-500'>Save</p>
                        </div> */}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='surface-875 p-8px'>
                    <div className='row mb-2 surface-900 px-12px py-6px mx-0'>
                        <div className='col-md-6 px-0'>
                            <p className='text-400 fs-10px fw-500'>Category Name</p>
                        </div>

                        <div className='col-md-6 px-0'>
                            <p className='text-400 fs-10px fw-500'>Created By</p>
                        </div>
                    </div>
                    {getCreatePermission && <AddScenarioBtn type={'Category'} className='w-100' onClick={handleAddCategory} />}

                    <div className='overflow-scroll noscrollbar h-60vh mt-2'>
                        { isLoading 
                        ? <Loading />
                        : data.map((each, i) => <>
                            {each.isEdit
                            ? <div className='row surface-850 p-12px v1-border-bottom-2'  onKeyDown={(e) => {
                                setIsCtrl(['Control', 'Meta'].includes(e.key))
                                if (isCtrl && e.key?.toLowerCase() === 's') {
                                    handleSave(i)
                                    e.preventDefault()
                                }
                            }}>
                                <div className='col-md-5'>
                                    <p className='text-300 fs-10px fw-400'>Category Name <span className='text-danger'>*</span> </p>
                                    <input type={'text'} className='form-control v1-form-control mt-2px'
                                        value={each.categoryname} onChange={(e) => handleChange(e.target.value, i)} />
                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-4 px-0'>
                                    <p className='text-300 fs-10px fw-400'>Category Slug <span className='text-danger'>*</span> </p>
                                    <input type={'text'} className='form-control v1-form-control mt-2px' disabled
                                        value={each.categoryslug} />
                                </div>
                                <div className='col-md-2 my-auto ps-4'>
                                    <div className='d-flex align-items-center justify-content-center align-items-center mt-14px'>
                                        {getUpdatePermission && <div className='v1-border-05 br-4px px-9px mx-6px pt-3px pb-6px cursor'
                                            onClick={() => handleSave(i)}>
                                            <GlobalIcons type={'tick'} />
                                        </div>}
                                        {getUpdatePermission && <div className='v1-border-05 br-4px px-9px mx-6px pt-3px pb-6px cursor'
                                            onClick={() => resetData(i)}>
                                            <GlobalIcons type={'cross'} />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            : <div className='row mx-0 p-12px surface-900 v1-border-bottom-2' key={i}>
                                <div className='col-md-6 px-0 my-auto'>
                                    <p className='text-300 fs-14px fw-500 text-truncate'>
                                        {each.categoryname || ''}
                                    </p>
                                </div>
                                <div className='col-md-4 px-0'>
                                    <p className='text-300 fs-14px fw-500 mb-1px'>{each.createdBy?.username || '-'}</p>
                                    <p className='text-400 fs-11px fw-400'>{formatCtsTime(each?.updatedAt)} - {formatCtsDate(each?.updatedAt)} (UTC)</p>
                                </div>
                                <div className='col-md-2 px-0'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {getUpdatePermission && <div className='btn-sm-v1 v1-border-2 me-3'
                                            onClick={() => changeEditStatus(i)}>
                                            <GlobalIcons type={'pencil-v2'} />
                                        </div>}

                                        {getDeletePermission && <div className='btn-sm-v1 v1-border-2'
                                            onClick={() => handleDelete(i)}>
                                            <GlobalIcons type={'trash'} />
                                        </div>}
                                    </div>
                                </div>
                            </div>}
                        </>)}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
