import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import { useSelector } from 'react-redux';
import UserIcons from '../../../../components/Icons/UserIcons';
import { confirm } from '../../../../utils/helper';

export default function RoleModal(props) {

    const [code,setCode] = useState("");
    const [touched,setTouched] = useState(false);
    const [name,setName] = useState("");
    const [description,setDescription] = useState("")
    const { roles} = useSelector((state) => state.user);

    useEffect(()=>{
        if(props.isEdit){
            let _role = roles[props.index]
            setCode(_role.code)
            setName(_role.name)
            setDescription(_role.description)
        }
    },[])

    const handleServerSideError = (error) => {
        if (error.response) {
            if (error.response.data.message) {
                getToast({statusType: 'ERROR', message:error.response.data.message })
            }
        } else {
            getToast({statusType: 'ERROR', message:error.message })
        }
    }

    function handleDeleteRole(){
        let msg = "Are you sure you want to delete this role?";
        if (confirm(msg)) {
            API.deleteUserRole(roles[props.index]._id)
                .then(response => {
                    props.getRoles()
                    getToast({statusType: 'SUCCESS', message: 'Role successfully deleted'})
                })
                .catch(error => handleServerSideError(error))
        }
    }

    function handleOnSave(event){
        setTouched(true)
        event.preventDefault();
        if(!name || !code || !description){
            return 
        }
        let obj = {
			name: name,
			description: description,
			code: code,
		};

        if (['guest', 'super admin', 'admin'].includes(name.trim().toLowerCase())){
            getToast({
                statusType: 'ERROR', message: 'Super Admin, Admin and Guest role names are reserved.'
            })
            return
        }
        if (['guest', 'super-admin', 'admin'].includes(code.trim().toLowerCase())){
            getToast({
                statusType: 'ERROR', message: 'Super-Admin, Admin and Guest code names are reserved.'
            })
            return
        }
        if(props.isEdit){
            API.updateRole(roles[props.index]._id, obj)
                .then(response => {
                    props.getRoles()
                    props.onHide()
                    getToast({statusType: 'SUCCESS', message: 'Role successfully updated'})
                })
                .catch(error => {
                    if (error.response) {
                        if (Object.keys(error.response.data.errors).length > 0) {
                            
                        } else {
                           
                            getToast({statusType: 'ERROR', message: error.response.data.message})
                        }
                    } else {
                        if (error.message) {
                            getToast({statusType: 'ERROR', message: error.message})
                        }
                    }
                })
        }else{
            API.createRole(obj)
            .then(response => {
                    props.getRoles()
                    props.onHide()
                    getToast({statusType: 'SUCCESS', message: 'Role successfully added'})
                })
            .catch(error => {
                    if (error.response) {
                        if (Object.keys(error.response.data.errors).length > 0) {
    
                        } else {
                            getToast({statusType: 'ERROR', message: error.response.data.message})
                        }
                        
                    } else {
                        if (error.message) {
                            getToast({statusType: 'ERROR', message: error.message})
                        }
                    }
                })
        } 
    }
    
    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 text-300 fw-500 fs-16px">
                            {props.isEdit?"Edit Role":"Add Role"}
                        </p>
                    </div>

                    <div className="d-flex align-items-center">
                        <div
                            onClick={props.onHide}
                            className="text-300 fw-400 fs-14px mr-14px cursor-pointer"
                        >
                            Cancel
                        </div>
                        <div
                            onClick={(e)=>{handleOnSave(e)}}
                            className="btn-md-v1 text-200 fw-400 fs-14px surface-850"
                        >
                            Save
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
               <Form className="d-flex flex-column w-100"  onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleOnSave(e)
                        e.preventDefault()
                    }
                }}>
                    <div className=''>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) =>
                                    setName(e.target.value)
                                }
                                className="v1-form-control"
                            />
                            {touched && !name  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Name is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Code <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Code"
                                value={code}
                                onChange={(e) =>
                                    setCode(e.target.value)
                                }
                                className="v1-form-control"
                            />
                            {touched && !code  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Code is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicEmail1">
                            <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                Description <span className="text-danger">*</span>
                            </Form.Label>
                            <textarea rows={4} className='form-control v1-form-textarea' placeholder='Type your description' value={description} onChange={(e)=>{setDescription(e.target.value)}}/>
                            {touched && !description  ? (
                            <Form.Text className="text-danger text-lowercase fs-10px">
                                Description is Required
                            </Form.Text>
                            ) : null}
                        </Form.Group>
                        
                    </div>
                    {(props.isEdit && props.showDelete) && <div className='h-50vh d-flex align-items-end'>
                        <div className='d-flex align-items-center v1-border-red px-2 py-1 br-5px' onClick={handleDeleteRole}>
                            <div className='d-flex align-items-center mr-10px'>
                                <UserIcons type="redBin"/>
                            </div>
                            <p className='red-11'>Delete Role</p>
                        </div>
                            
                    </div>}
                </Form>
            </Modal.Body>
        </Modal>
    )
}