import { objectDeepClone } from '@apiwiz/oas/services';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import makeAnimated from "react-select/animated"
import Loading from '../../../../components/Loader/Loading';
import { getToast } from '../../../../components/Toast';
import API from '../../../../api';
import { throwServerError } from '../../../../utils/helper';

const animatedComponents = makeAnimated()

export default function ChangeParentModal(props) {
    const [data, setData] = useState([])
    const [selectedId, setSelectId] = useState('')
    const [parentId, setParentId] = useState('')
    const [disableSave, setDisableSave] = useState(false)

    const convertDataForSelect = (dt, tag) => {
        if (dt) {
            return dt.filter(option => option.type === tag)
             .map(o => { return { value: o.id, label: o.title } })
        } else return []
    }

    useEffect(() => {
        let currentPage = props.activeDocument.id

        let guideDetails = objectDeepClone(props.guideDetails)

        let pages = objectDeepClone(guideDetails.version.pages)

        pages = Object.keys(pages).map(key => {
            return pages[key]
        })
        pages.forEach((el, index) => {
            const found = el.pages.find(v => v.page === props.data.id)
            if(found) {
                pages.splice(index, 1)
            }
        })
        let filteredData
        if(currentPage && props.data.id) {
            let currentPageData = objectDeepClone(guideDetails.version.pages[props.data.id])
            filteredData = pages.filter(e => e.id !== 'x-static-document' && e.id !== props.data.id) // e.id !== rootPage && 
            // filteredData = pages.filter(e => e.id !== selectedChangeParentPage && !e._nestedChild)
            if (currentPageData.pages && currentPageData.pages.length > 0) {
                currentPageData.pages.forEach(el => {
                    const found = pages.find(e => e.id === el.page)
                    if (found) {
                        const index = pages.findIndex(f => f.id === found.page)
                        pages.splice(index, 1)
                    }
                })
                filteredData = objectDeepClone(pages)
            }
        }

        const options = [
            { label: 'pages', options: convertDataForSelect(filteredData, 'document') },
            { label: 'group', options: convertDataForSelect(filteredData, 'group') },
        ]

        setData(options) 

    }, [])

    const onChangeParent = (select) => {
        setParentId(select.value)
        setSelectId(select)
    }

    const handleSubmit = () => {
        if (!parentId){
            getToast({
                statusType: 'ERROR', message: 'Select a parent before saving'
            })
            return
        }

        API.putGuideDetails(props.guideDetails?.guide?._id, 
        props.guideDetails?.version?._id, props.data.id, parentId)
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Parent changed successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const [isCtrl, setIsCtrl] = useState(false)
    
    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Change Parent</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div className='p-16px' onKeyDown={(e) => {
                        setIsCtrl(['Control', 'Meta'].includes(e.key))
                        if (isCtrl && e.key?.toLowerCase() === 's') {
                            handleSubmit()
                            e.preventDefault()
                        }
                    }}>
                    <Select
                        classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                        className='v1select' closeMenuOnSelect={true} components={animatedComponents}
                        value={selectedId} placeholder={`Select Parent`} options={data}
                        isMulti={false} onChange={select => onChangeParent(select)}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}
