import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import ForumAPI from "../../../../api/forum";
import { getToast } from "../../../../components/Toast";

export function NewCategoryModal({ show, onHide, getCategoryData }) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const maxNameLength = 40;

  const createCategory = () => {
    setLoading(true);
    ForumAPI.createCategory({
      data: {
        name,
        icon: ".",
      },
    })
      .then((res) => {
        setLoading(false);
        onHide();
        setName("");
        getCategoryData();
      })
      .catch((err) => {
        onHide();
        setName("");
        getToast({
          statusType: "ERROR",
          message: "Unable to create category, try again later!",
        });
      });
  };

  const updateCategory = ({ id }) => {
    setLoading(true);
    ForumAPI.updateCategory({ id, data: { name } })
    .then((res) => {
      setLoading(false);
      onHide();
      setName("");
      getCategoryData();
    })
    .catch(err => {
      const { response } = err
      onHide();
      setName("");
      getToast({
        statusType: "ERROR",
        message: response.data.message,
      });
    })
  };

  useEffect(() => {
    if (show.show) {
      if (show.editMode && show.category) {
        setName(show.category.name);
      }
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show.show}
        onHide={onHide}
        className="post-category-modal"
        dialogClassName="post-category-modal-dialog"
        contentClassName="post-category-modal-content"
      >
        <Modal.Header className="post-category-modal-header">
          <GlobalIcons
            type={"close"}
            onClick={onHide}
            className="modal-close"
          />
          <p className="post-category-modal-title">Name this Category</p>
        </Modal.Header>
        <Modal.Body className="post-category-modal-body">
          <div className="input-group__container">
            <div className="input-label">Category Name</div>
            <input
              className="input-box"
              placeholder="Eg: API Design"
              value={name}
              onChange={(evt) => {
                if (evt.target.value.length <= maxNameLength) {
                  setName(evt.target.value);
                }
              }}
              spellCheck={false}
              autoComplete={false}
              autoFocus
            />
            <div className="input-warning">
              {name.length}/{maxNameLength} Characters
            </div>
          </div>
          <button
            className="primary-cta"
            onClick={(evt) => {
              if (!loading) {
                if(show.editMode){
                  updateCategory({ id: show.category._id })
                }else{
                  createCategory();
                }
              } else {
                evt.preventDefault();
              }
            }}
          >
            {show.editMode
              ? loading
                ? "Updating..."
                : "Update Category"
              : loading
              ? "Creating..."
              : " Create Category"}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
