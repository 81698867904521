import { ArrowsOutSimple, ChatDots, X } from "phosphor-react";
import { useSelector } from "react-redux";
import Parameters from "../components/Parameters";
import RequestParameterList from "../components/RequestParamList";
import { v4 as uuidv4 } from "uuid";
import MyReactTooltip from "../../ReactTooltip";
import { useDispatch } from "react-redux";
import { setSwaggerFields } from "../../../app/Features/Swagger/SwaggerSlice";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { statusCodeSpec } from "@apiwiz/oas/constants";
import BodyParameter from "../components/BodyParameter";
import ThemeCenteredModal from "../../Modals/ThemeCenteredModal";
import ReviewModal from "../ReviewModal";
import API from "../../../api";
import { getOasSerialNumberFromMetadata } from "@apiwiz/oas/helper";
import { showSuccessMessage, throwServerError } from "../../../utils/helper";
import ContainerLoader from "../../Loader/ContainerLoader";
import { useSearchParams } from "react-router-dom";
import { SRC_PARAM_VALUES, URL_PARAMS } from "../../../service/constants";

const _titleId = uuidv4();
const _endpointId = uuidv4();

export default function Content(props) {
  const { selectedOp, yAxisScroll, responses, selectedOpResponses } =
    useSelector((state) => {
      let selectedOp = state.swagger.selectedOperation || {};
      let selectedOpResponses = selectedOp.responses || {};
      return {
        selectedOp,
        yAxisScroll: state.swagger.yAxisScroll,
        responses: Object.keys(selectedOpResponses).reduce((acc, curr) => {
          let _group = `${`${curr}`[0]}XX`;
          if (!acc[_group]) acc[_group] = [];
          acc[_group].push(curr);
          return acc;
        }, {}),
        selectedOpResponses,
      };
    });
  const { metadata } = useSelector((state) => state.swagger);
  const { currentUser, isAuthenticated } = useSelector((state) => state.user);
  const tabs = useMemo(() => {
    return ["1XX", "2XX", "3XX", "4XX", "5XX"].filter((el) => responses[el]);
  }, [responses]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [selectedStatusGroup, setSelectedStatusGroup] = useState("2XX");
  const dispatch = useDispatch();
  const _titleRef = useRef(null);
  const _endpointRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [searchParams] = useSearchParams()

  const getIsSubscribed = () => {
    API.getIsSubscribed({
      swaggerId: metadata.swaggerId,
      emailId: currentUser.email,
    })
      .then((res) => {
        let _data = res.data?.data || {};
        setIsSubscribed(_data.isSubscribed);
      })
      .catch((err) => {
        setIsSubscribed(false);
      });
  };

  const subscribe = () => {
    if (subscribeLoading) return;
    let body = {
      emailId: currentUser.email,
      swaggerId: metadata.swaggerId,
      swaggerName: metadata.swaggerName,
      name: currentUser.username,
      oas: getOasSerialNumberFromMetadata(metadata),
      type: "DEVELOPER",
    };
    setSubscribeLoading(true);
    API.subscribeSwagger({ data: body })
      .then((res) => {
        showSuccessMessage("Subscribed successfully!");
        setIsSubscribed(true);
        setSubscribeLoading(false);
      })
      .catch((err) => {
        throwServerError(err);
        setSubscribeLoading(false);
      });
  };
  const unsubscribe = () => {
    if (subscribeLoading) return;

    let body = {
      emailId: currentUser.email,
      swaggerId: metadata.swaggerId,
    };
    setSubscribeLoading(true);

    API.unsubscribeSwagger({
      data: body,
      swaggerId: body.swaggerId,
      emailId: body.emailId,
    })
      .then((res) => {
        showSuccessMessage("Unsubscribed successfully!");
        setIsSubscribed(false);
        setSubscribeLoading(false);

      })
      .catch((err) => {
        throwServerError(err);
        setSubscribeLoading(false);

      });
  };
  useEffect(() => {
    if (!props.fromGuide && isAuthenticated) {
      getIsSubscribed();
    }
  }, []);

  /*
  const onScroll = () => {
    if (!yAxisScroll) {
      dispatch(setSwaggerFields({ field: "yAxisScroll", value: true }));
      setTimeout(() => {
        dispatch(setSwaggerFields({ field: "yAxisScroll", value: false }));
      }, 500);
    }
  };

  const onScrollDebounceFn = useCallback(debounceFn(onScroll, 500), []);

  
  useEffect(() => {
    document.body.onscroll = onScrollDebounceFn
    return () => {
        document.body.onscroll = null
    }
  }, [])
  */

  return (
    <div className="d-flex flex-column gap-16px h-100 overflow-scroll-y noscrollbar">
      {/* Heading */}
      <div>
        <div className="d-flex justify-content-between">
          <div
            ref={_titleRef}
            className="fw-600 fs-32px h-38px lh-38px text-truncate"
            data-tip
            data-for={_titleId}
          >
            {selectedOp.summary}
          </div>
          <MyReactTooltip
            elRef={_titleRef}
            checkTruncate
            id={_titleId}
            className="max-w-100-important"
          >
            <div className="px-8px py-4px text-nowrap">
              {selectedOp.summary}
            </div>
          </MyReactTooltip>
          {[SRC_PARAM_VALUES.PACKAGE].includes(searchParams.get(URL_PARAMS.SRC)) || isSubscribed === null ? null : (
            <div className="d-flex pt-6px align-items-center">
              {isSubscribed ? (
                <div
                  onClick={unsubscribe}
                  className="cursor bg-danger fs-12px px-8px d-flex align-items-center br-4px text-theme-base h-24px w-87px"
                >
                  {subscribeLoading ? <ContainerLoader size="sm" variant="theme-base" className="w-100" /> : "Unsubscribe" }
                </div>
              ) : (
                <div
                  onClick={subscribe}
                  className="cursor bg-theme-primary fs-11px px-8px d-flex align-items-center br-4px text-theme-base h-24px w-72px"
                >
                  {subscribeLoading ? <ContainerLoader size="sm" variant="theme-base" className="w-100" /> : "Subscribe" }
                </div>
              )}
            </div>
          )}
        </div>
        {selectedOp.api?.name ? (
          <div className="fs-16px h-28px lh-28px text-truncate">
            {selectedOp.api.name || ""}
          </div>
        ) : null}
      </div>
      {/* Meta Details */}
      <div className="d-flex justify-content-between gap-8px">
        <div className="d-flex gap-8px w--96px">
          <div
            className={`text-truncate text-center text-uppercase h-20px br-38px mt-3px px-6px pt-1px fs-12px w-80px fw-500 api-method-tag ${selectedOp.apiMethod}`}
          >
            {selectedOp.apiMethod}
          </div>
          <div
            ref={_endpointRef}
            className="fs-16px text-truncate"
            data-tip
            data-for={_endpointId}
          >
            {selectedOp.endpoint}
          </div>
          <MyReactTooltip
            checkTruncate
            elRef={_endpointRef}
            id={_endpointId}
            className="max-w-100-important"
          >
            <div className="px-8px py-4px text-nowrap">
              {selectedOp.endpoint}
            </div>
          </MyReactTooltip>
        </div>
        {![SRC_PARAM_VALUES.PACKAGE].includes(searchParams.get(URL_PARAMS.SRC)) ? <ReviewModal /> : null}
      </div>
      {/* <div className="h--122px overflow-scroll-y noscrollbar"> */}
      <div>
        {/* Parameters list heading*/}
        <p className="fs-14px fw-400">
          {selectedOp.description || "-"}
        </p>
        <div className="fw-500 lh-28px h-28px fs-16px mb-12px">Parameters</div>

        {/* Parameters */}
        <RequestParameterList />

        {tabs.length ? (
          <>
            {/* Responses */}
            <div className="fw-500 lh-28px h-28px fs-16px mb-12px">
              Responses
            </div>

            {/* Response Tabs */}
            <div className="mb-12px">
              {/* Tabs */}
              <div className="d-flex gap-2px align-items-center h-32px status-color-group">
                {tabs.map((el) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedStatusGroup(el);
                      }}
                      className={`cursor w-100px h-100 d-flex justify-content-center align-items-center ${
                        selectedStatusGroup === el
                          ? "bg-theme-surface-875 border-top-theme-primary _1-5px"
                          : "bg-theme-surface-900 border-theme-primary-subtle "
                      }  _${el} br-tl-4px br-tr-4px`}
                    >
                      <div
                        className={`fs-14px ${
                          selectedStatusGroup === el ? "fw-600" : "fw-500"
                        }`}
                      >
                        {el}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Content */}
              <div className="bg-theme-surface-875 min-h-60px d-flex flex-column gap-6px p-6px fs-14px">
                {(responses[selectedStatusGroup] || []).map((el) => {
                  return (
                    <div className="d-flex flex-column gap-6px p-8px br-4px bg-theme-base response-status-card">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-6px align-items-center">
                          <div
                            className={`px-8px py-4px _${selectedStatusGroup} status-group-chip br-4px`}
                          >
                            {el}
                          </div>
                          <div>
                            {selectedOpResponses[el]?.description ||
                              statusCodeSpec[el]?.message ||
                              ""}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            let _response = selectedOpResponses[el];
                            if (
                              _response?.properties &&
                              _response?.properties?.constructor === Object
                            ) {
                              let children = Object.keys(
                                _response?.properties || {}
                              );
                              if (children.length) {
                                _response = _response?.properties[children[0]];
                              }
                            }
                            setSelectedResponse({
                              response: _response,
                              statusCodeSpec: statusCodeSpec[el],
                            });
                          }}
                          className="expand-action bg-theme-primary-subtle p-2px h-24px w-24px br-2px cursor"
                        >
                          <ArrowsOutSimple
                            className="h-100 w-100"
                            color="var(--primary-color)"
                            size={16}
                          />
                        </div>
                      </div>
                      <div>{statusCodeSpec[el]?.description || ""}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <ThemeCenteredModal
        show={!!selectedResponse}
        onHide={() => {
          setSelectedResponse(null);
        }}
        size="md"
        className="vertical-stretch-modal"
        bodyClassName={`d-flex flex-column gap-8px h-100`}
      >
        <div className="px-10px py-6px d-flex justify-content-between align-items-center">
          <div className="d-flex gap-6px align-items-center fs-14px">
            <div
              className={`px-8px py-4px _${selectedStatusGroup} status-group-chip br-4px`}
            >
              {selectedResponse?.statusCodeSpec?.code}
            </div>
            <div>
              {selectedResponse?.response?.description ||
                selectedResponse?.statusCodeSpec?.message ||
                ""}
            </div>
          </div>
          <div
            className="cursor"
            onClick={() => {
              setSelectedResponse(null);
            }}
          >
            <X />
          </div>
        </div>
        <div
          // onScroll={onScrollDebounceFn}
          className="px-12px pt-4px pb-12px d-flex flex-column gap-12px h--41px overflow-scroll-y noscrollbar"
        >
          <div className="fs-14px text-theme-content-subtle">
            {selectedResponse?.statusCodeSpec?.description || ""}
          </div>
          <div>
            {selectedResponse?.response?.headers ? (
              <Parameters
                title="Header Parameters"
                isResponse={true}
                parameter={selectedResponse?.response?.headers}
              />
            ) : null}
          </div>
          <div>
            {selectedResponse?.response?.content ? (
              <BodyParameter content={selectedResponse?.response?.content} />
            ) : null}
          </div>
        </div>
      </ThemeCenteredModal>
    </div>
  );
}
