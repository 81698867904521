import { createSlice } from "@reduxjs/toolkit";
import { objectDeepClone } from "../../../utils/helper";

const initialState = {
    groups: [],
    roles: [],
    currentLayoutObject: {
      "headerMenu": [],
      "footerMenu": [],
      "contents": [],
      "social": [],
      "promotions": {
        primary: {},
        secondary: {},
        tertiary: {}
      },
      "description": "",
      "favicon": "",
      "title": "",
      "footerCopyright": "",
      "logo": "",
      "metaDescription": "",
      "metaTitle": "",
      "siteTemplateType": "",
      "darklogo": "",
      "sitename": "",
      "squarelogo": "",
      "showEvents": false
    },
    backupCurrentLayout: {
      "headerMenu": [],
      "footerMenu": [],
      "contents": [],
      "social": [],
      "promotions": {
        primary: {},
        secondary: {},
        tertiary: {}
      },
      "description": "",
      "favicon": "",
      "title": "",
      "footerCopyright": "",
      "logo": "",
      "metaDescription": "",
      "metaTitle": "",
      "siteTemplateType": "",
      "darklogo": "",
      "sitename": "",
      "squarelogo": "",
      "showEvents": false
    }
};

export const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setGroups(state, action){
      state.groups = action.payload;
    },
    setRoles(state, action){
      state.roles = action.payload;
    },
    setCurrentLayoutObject(state, action){
      state.currentLayoutObject = objectDeepClone(action.payload);
      state.backupCurrentLayout = objectDeepClone(action.payload)
    },
    setHeaderMenu(state, action){
      state.currentLayoutObject['headerMenu'] = action.payload;
    },
    setFooterMenu(state, action){
      state.currentLayoutObject['footerMenu'] = action.payload;
    },
    resetCurrentLayout(state){
      state.currentLayoutObject = objectDeepClone(state.backupCurrentLayout)
    },
    setLayoutObjects(state, {payload}){
      let _payload = payload || {}
      Object.keys(_payload).forEach((eachKey) => {
        let eachValue = _payload[eachKey]
        state.currentLayoutObject[eachKey] = eachValue
      })
    },
    setSocialMenu(state, action){
      state.currentLayoutObject['social'] = action.payload;
    },
    setContents(state, action){
      const {id, xData} = action.payload
      state.currentLayoutObject['contents'][id].payload = xData;
    },
    setOverallContents(state, action){
      state.currentLayoutObject['contents'] = action.payload;
    },
    setShowEventsMain(state, action){
      state.currentLayoutObject.showEvents = action.payload
    },
    setEventTitle(state, action) {
      state.currentLayoutObject.eventTitle = action.payload
    }
  },
});

export const { setGroups, setRoles, setCurrentLayoutObject, setHeaderMenu, setSocialMenu, resetCurrentLayout, 
  setLayoutObjects, setFooterMenu, setContents, setOverallContents, setShowEventsMain, setEventTitle } = LayoutSlice.actions;
export default LayoutSlice.reducer;
