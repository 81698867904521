import { Browsers } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setContents } from '../../../../app/Features/Layout/LayoutSlice'
import { getToast } from '../../../../components/Toast'
import { confirm, objectDeepClone } from '../../../../utils/helper'
import ImageCard from './ImageCard'
import PartnerSlider from "../../../../components/InfiniteLooper"
import AddScenarioBtn from '../../../../components/AddScenarioBtn'

export default function RenderLogo1({isEdit, setIsEdit, setLoading, sectionIndex}) {
    const {currentLayoutObject, backupCurrentLayout} = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [data, setData] = useState({})

    useEffect(() => {
        if (currentLayoutObject.contents && currentLayoutObject.contents[sectionIndex]){
            setData(currentLayoutObject.contents[sectionIndex].payload || {})
        }
    }, [])

    const handleCancel = () => {
        if (!confirm("Are you sure? any new data might be lost in this section?")) return

        let _prevObj = objectDeepClone(backupCurrentLayout)
        setData(_prevObj.contents[sectionIndex].payload || {})
    }

    const handleSave = () => {
        let _data = objectDeepClone(data)
        if (!_data.heading){
            getToast({
                statusType: 'ERROR', message: 'Heading is required for this section'
            })
            return
        }
        if (_data.logos.length < 3){
            getToast({
                statusType: 'ERROR', message: 'Atleast 2 logos are required for this section'
            })
            return
        }

        _data.isEdit = false
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
        setData(objectDeepClone(_data))
    }

    const handleEdit = () => {
        let _data = objectDeepClone(data)
        _data.isEdit = true 
        setIsEdit(true)
        setData(objectDeepClone(_data))
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
    }

    const handleUrl = (_url, _id) => {
        let _data = objectDeepClone(data)
        _data.logos[_id]['imageUrl'] = _url 
        setData(objectDeepClone(_data))
    }

    const handleAdd = () => {
        setIsEdit(true)
        let _data = objectDeepClone(data)
        if (!_data.hasOwnProperty('logos')) _data['logos'] = []
        _data.logos.push({
            imageUrl: ''
        })
        setData(objectDeepClone(_data))
    }

    const handleDelete = (_id) => {
        let _data = objectDeepClone(data)
        _data.logos.splice(_id, 1)
        setData(objectDeepClone(_data))
    }
    const [isCtrl, setIsCtrl] = useState(false)
    
    return (
        <div className='' onKeyDown={(e) => {
            setIsCtrl(['Control', 'Meta'].includes(e.key))
            if (isCtrl && e.key?.toLowerCase() === 's') {
                handleSave()
                e.preventDefault()
            }
        }}>
            <div className='surface-base p-16px'>
                <div className='bg-currentBackground m-0 pb-5 pt-2'>
                    <p className='fs-14px fw-500 gray-500 text-center'>
                        {data.heading}
                    </p>

                    <div className='d-flex align-items-center noscrollbar mt-28px' style={{overflowX: 'scroll', overflowY: 'hidden'}}>
                        {data.logos?.length > 3
                        ? <PartnerSlider speed="40" direction="left">
                            {data.logos.map(((each, i) => 
                                <div key={i} className='mx-4'>
                                    <img src={each.imageUrl} alt='' height={30} />
                                </div>
                            ))}
                        </PartnerSlider>
                        : <>
                            {data.logos?.map(((each, i) => 
                                <div key={i} className='mx-4'>
                                    <img src={each.imageUrl} alt='' height={30} />
                                </div>
                            ))}
                        </>}
                    </div>
                </div>
            </div>

            <div className='surface-875 d-flex align-items-center justify-content-between p-12px mt-3'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Content</p>
                </div>
                {data.isEdit
                ? <div className='d-flex align-items-center'>
                    <p className='text-300 fs-12px fw-500 me-3 cursor'
                        onClick={handleCancel}>Cancel</p>

                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleSave}>
                        Save
                    </div>
                </div>
                : <div>
                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleEdit}>
                        Edit
                    </div>
                </div>}
            </div>

            <div className='surface-base br-4px p-16px w-100 h-70vh overflow-scroll noscrollbar'>
                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Hero Heading <span className='text-danger'>*</span></p>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.heading}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, heading: e.target.value})} />
                </div>

                <div className='row my-3'>
                    {data.logos?.map((eachLogo, i) => <div className='col-md-6 mb-3' key={i}>
                        <ImageCard imgUrl={eachLogo.imageUrl} name={`logo${i}`} canBeDeleted
                            handleDelete={() => handleDelete(i)}
                            width={'100%'} isEdit={data.isEdit} setUrl={(url) => handleUrl(url, i)} />
                    </div>)}
                </div>

                {data.isEdit && <AddScenarioBtn type={'Logo'} className='w-100' onClick={handleAdd} />}
            </div>
        </div>
    )
}
