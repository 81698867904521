import React from 'react'
import MeteringGroup from './MeteringGroup'

export default function MeteringElement({data}) {
    const Labelize = (_type) => {
        if (_type === 'equals') return '='
        if (_type === 'not equals') return '!='
        if ( _type === 'greater than or =') return '>='
        if (_type === 'is greater than') return '>'
        if (_type === 'is less than') return '<'
        if (_type === 'less than or =') return '<='
        return _type
    }

    return (
        <div>
            <div className={`${data._orData?.length > 0 && ''}`} id={data._id}>
                <div className={'meteringPlayground-block-v3'}>
                    <div className='position-relative w-fit surface-consumer-875 br-4px py-6px px-8px custom-bordr-1' 
                        style={{borderColor: 'var(--primary-color)'}}>
                        <div className='w-fit'>
                            <div className='dfaic gap-8px' style={{height: 20}}>
                                <p className='text-content-color fs-12px fw-400'>
                                    {data._data?._paramType || ''}{data._data?._key || ''}
                                </p>
                                <p className='text-content-color fs-12px fw-400' style={{whiteSpace: 'nowrap'}}>
                                    {Labelize(data._condition)}
                                </p>
                                {data._data?._value && <div className='bg-primary-color br-4px px-6px py-2px'>
                                    <p className='text-center fs-12px text-white fw-400'>{data._data?._value}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                {data._orData?.map((each, i) => {
                    if (each._type === 'group') {
                        return ( 
                            <>
                                <div className='m-2'>
                                    <p className='fw-500 fs-12px text-content-color'>OR</p>
                                </div>
                                <MeteringGroup data={each} isChild isLast={i === data._orData.length - 1}  parentData={data} />
                            </>) 
                    } 
                    if (each._type === 'element') {
                        return ( <>
                            <div className='m-2'>
                                <p className='fw-500 fs-12px text-content-color'>OR</p>
                            </div>
                            <MeteringElement data={each} isChild isLast={i === data._orData.length - 1} parentData={data}  />
                        </>) 
                    }
                })}
            </div>

            {data._andData?._type && <div>
            <div className='my-2 ms-3'>
                <p className='fw-500 fs-12px text-content-color'>AND</p>
            </div>

            {(data._andData?._type === 'group') && <MeteringGroup data={data._andData} />}
            {(data._andData?._type === 'element') && <MeteringElement data={data._andData} />}
            </div>}
        </div>
    )
}
