/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import { getEnv } from "../utils/helper";

export default {
	API: getEnv("API_URL"),
	VUE_APP_URL: getEnv("VUE_APP_URL")
		? getEnv("VUE_APP_URL") + "/portal-api-catalog" : ".itorix.com/portal-api-catalog",
	VUE_APP_SCHEME: getEnv("VUE_APP_SCHEME") ? getEnv("VUE_APP_SCHEME") : "http://",
	API_CATALOG_URL: getEnv("API_CATALOG_URL"),
	USER: {
		userLogin: "/auth/login",
		updateAttempt: "/auth/update-attempts",
		resendOTP: "/auth/resend-otp",
		loginMFA: "/auth/login-mfa",
		registerUser: "/auth/register",
		forgotPassword: "/auth/forget-password",
		resetPassword: "/auth/reset/",
		userLogout: "/auth/logout",
		activateUser: "/auth/activate/",
		resendToken: "/auth/resend-token",
		itorixLogin: "/auth/login-itorix",
		getProfile: "/v2/profile",
		verifyResetPasswordToken: "/auth/reset/",
		upload: "/upload",
		sso: "/auth/login-sso",
		ssoPermission: "/ssopermission",
		checkEmail: "/v2/auth/check-email",
		checkUsername: "/v2/auth/check-username"
	},
	USER_ROLE: {
		create: "/user-role/create",
		update: "/user-role/",
		getAll: "/v2/user-role",
		getSingleRecord: "/user-role/",
		delete: "/user-role/",
	},
	USER_GROUP: {
		create: "/user-group/create",
		update: "/user-group/",
		getAll: "/user-group",
		getSingleRecord: "/user-group/",
		delete: "/user-group/",
	},
	MANAGE_USER: {
		createNew: "/user/create",
		update: "/user/",
		getAll: "/v2/user",
		deleteMultiple: "/v2/delete-users",
		getSingleRecord: "/user/",
		delete: "/user/",
		inviteUser: "/auth/invite-user",
	},
	SETTING: {
		createUpdate: "/setting/update",
		getUserSetting: "/setting",
		refreshSession: "/setting/refreshjsessionid",
		getLoginSetting: "/guestsetting",
	},
	SMTP: {
		createUpdate: "/smtp/update",
		get: "/smtp",
	},
	ACTIVITY: {
		getLogs: "/v2/activity-log",
	},
	EVENTS: {
		create: "/event/create",
		update: "/event/",
		getAll: "/event",
		getSingleRecord: "/event/",
		delete: "/event/",
		download: "/calendar-download/",
	},
	GUIDE: {
		get: "/guides",
		create: "/guide/create",
		update: "/guide/",
		getSingleRecord: "/event/",
		delete: "/event/",
		getAllParent: "/parent-guide",
		upload: "/upload",
		versions: "/versions",
		pages: "/pages",
		documents: "/documents",
		feedback: "/feedback/create",
		default: "/guides/default",
		metadata: "/v2/guides/link-metadata"
	},
	GUIDE_CATEGORY: {
		URL: "/guide-category",
		ALL: "/guide-category-all",
		CREATE: "/guide-category/create",
	},
	APIGEE: {
		SYNC: "/v2/sync-org",
		ORG: "/v2/org",
		SETDEFAULTORG: "/v2/org-setdefault",
		APIGEE_USERS: "/v2/apigee-user",
		ADD_USER: "/user/create",
		GET_ORG_LIST: "/v2/list-user-org",
		APP_URL: "/build-app",
		PRODUCT: "/product",
		APP_DETAILS: "/organizations",
	},
	KONG: {
		SYNC: '/v2/kong-sync-runtimes',
		GET_RUNTIMES: '/v2/kong-runtimes',
		SETDEFAULT_RUNTIME: '/v2/runtime-setdefault',
		KONG_USER: '/v2/kong-user'
	},
	AZURE: {
		SYNC: '/v2/azure-sync-resources',
		GET_RESOURCES: '/v2/azure-resources',
		SETDEFAULT_RESOURCE: '/v2/resource-group-setdefault',
		AZURE_USER: '/v2/azure-user'

	},
	NGINX: {
		SYNC: "/v2/nginx-sync-runtimes",
		GET_RUNTIMES: "/v2/nginx-runtimes",
		SETDEFAULT_RUNTIME: "/v2/nginx-runtime-setdefault",
		NGINX_USER: "/v2/nginx-user",
		BUILD_APP: "/v2/nginx/app/create",
		APPS: "/v2/nginx/apps",
		USER_APPS: "/v2/nginx/user-apps",
		CREDENTIALS: "/v2/nginx/credentials"
	},
	AWS: {
		SYNC: "/v2/aws-gateway/sync-runtimes",
		GET_RUNTIMES: "/v2/aws-gateway/runtimes",
		SETDEFAULT: "/v2/aws-gateway/setdefault",
		USER: "/v2/aws-gateway/user",
		BUILD_APP: "/v2/aws-gateway/app/create",
		APPS: "/v2/aws-gateway/apps",
		USER_APPS: "/v2/aws-gateway/user-apps",
		CREDENTIALS: "/v2/aws-gateway/credentials"
	},
	MONETIZATION: {
		APPS: "/monetization/apps",
		TRANSACTIONS: "/monetization/transactions/apps",
		GET_PRODUCT_BUNDLES: "/monetization/product-bundle",
		PURCHASE_APP: "/monetization/purchase-app",
		WALLET: '/monetization/wallet/apps',
		GET_PURCHASES: "/monetization/apps/purchase",
		BUILD_APP: "/monetization/build-app",
		PURCHASE_HISTORY: "/monetization/purchase-history",
		MASTER_CURRENCY: "/v2/monetization/currency",
		PACKAGE: '/v2/monetization/package',
		DEVELOPER_PORTAL: '/v2/monetization/devportal',
		BASE: '/v2/monetization'
	},
	PROFILE: {
		UPDATE: "/v2/profile-update",
		UPDATE_STATUS: "/user/update-status",
	},
	PRODUCTS: {
		URL: "/products",
	},
	PARTNER_GROUPS:{
		GET: "/v2/partner-groups",
		SYNC_PARTNERS: "/v2/sync-partners",
		LIST_PARTNERS: "/v2/list-partners"
	},
	COMPANIES: {
		GET: "/v2/list-companies",
		SYNC_COMPANIES: "/v2/sync-companies",
	},
	API_REFRENCE: {
		URL: "/api-reference",
	},
	HEADER: {
		create: "/header-navbar/create",
		update: "/header-navbar/",
		delete: "/header-navbar/",
		getAllBasedOnTemplate: "/header-navbar/",
		getSingle: "/header-navbar/",
		getParentBasedOnTemplate: "/parent-navbar/",
		getChildNavbarBasedOnTemplateAndParent: "/child-navbar/",
	},
	FOOTER: {
		create: "/footer-navbar/create",
		updateBasedOnId: "/footer-navbar/",
		deleteBasedOnId: "/footer-navbar/",
		getAllBasedOnTemplate: "/footer-navbar/",
		getParentBasedOnTemplate: "/footer-parent-navbar/",
		getChildBasedOnTemplateAndId: "/footer-child-navbar/",
	},
	GLOBAL_SITE: {
		get: "/manage-site-global",
		create: "/manage-site-global/update",
	},
	TEMPLATE: {
		create: "/template/update",
		getTemplateOnContent: "/template/",
	},
	ACL: {
		getPermission: "/user-permissions",
		updatePermission: "/user-permissions",
		headerPermission: "/site-navigation-header-permission",
		footerPermission: "/site-navigation-footer-permission",
	},
	BANNER: {
		URL: "/banners",
	},
	TAGS: {
		URL: "/tags",
	},
	SDK: {
		URL: "/sdk",
		CREATE: "/sdk/create",
		SDK_DOWNLOAD: "/sdk/download",
		SDK_DOWNLOAD_V2: '/v2/sdk-download'
	},
	SDK_CATEGORY: {
		URL: "/sdk-category",
		CREATE: "/sdk-category/create",
	},
	CONTACT: {
		URL: "/contact-us",
	},
	CONFIG: {
		publicKey: getEnv("PUBLIC_KEY"),
	},
	LAYOUT: {
		URL: "/user-layout"
	},
	SIDEBAR: {
		GET: '/v2/navmenu'
	},
	NOTIFICATIONS:{
		GET: `/v2/notification`,
		MARK_AS_READ: `/v2/notification/mark-read`,
		MARK_ALL_AS_READ: `/v2/notification/mark-read-all`
	},
	AWS_ARTIFACTORY:{
		GET: `/v2/aws-artifactory`
	},
	GCS_ARTIFACTORY: {
		GET: `/v2/gcs-artifactory`
	},
	JFROG_ARTIFACTORY: {
		GET: "/v2/jfrog-artifactory"
	},
	PORTAL_DASHBOARD:{
		GET: `/v2/portal-dashboard`
	},
	FORUM: {
		CATEGORY: {
			GET: '/v2/forum/category',
			CREATE: '/v2/forum/category',
			UPDATE_CATEGORY: '/v2/forum/category',
			DELETE_CATEGORY: '/v2/forum/category',
			GET_BY_NAME: '/v2/forum/category/name'
		},
		TAGS: {
			POST: '/v2/forum/posts/tag',
			GET: '/v2/forum/posts/tag'
		},
		POST: {
			CREATE: "/v2/forum/post",
			GET: "/v2/forum/post"
		}
	},
	SWAGGER: {
		V2_BASE: `/v2/swaggers`,
		CATALOG_SWAGGERS: `/catalog/swaggers`
	},
	DEVELOPER_APP: {
		GET: "/v2/developer/apps",
		CREATE: "/v2/developer/app"
	},
	AXWAY: {
		SYNC: "/v2/axway-gateway/sync-runtimes",
		GET_RUNTIMES: "/v2/axway-gateway/runtimes",
		SETDEFAULT: "/v2/axway-gateway/setdefault",
		USER: "/v2/axway-gateway/user",
		BUILD_APP: "/v2/axway-gateway/app/create",
		APPS: "/v2/axway-gateway/apps",
		USER_APPS: "/v2/axway-gateway/user-apps",
		CREDENTIALS: "/v2/axway-gateway/credentials"
	},

};