import { useState, useEffect, forwardRef } from "react";
import { MonetizeDateFn, NumberFormatter, arrayToObject, confirm, copyToClipboard, defaultCurrencyFn, getDaysInAMonth, labelize, pluralize, showSuccessMessage, sleep, throwServerError } from "../../../../utils/helper";
import MonetizeTrend, { MonetizeTrendv2 } from "../components/MonetizeTrend";
import { ArrowDown, ArrowSquareOut, CaretDown, CaretRight, Copy, CurrencyCircleDollar, FilePlus, Info, Trash, TrendUp } from "phosphor-react";
import { LineChart } from "../../../../components/Charts/LineChart";
import MonetizationIcons from "../../../../components/Icons/MonetizationIcons";
import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import { useNavigate, useParams } from "react-router";
import monetizationV2 from "../../../../api/monetizationV2";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import { useSelector } from "react-redux";
import MonthYearPicker from "../components/MonthYearPicker";
import { Link } from "react-router-dom";
import moment from "moment";
import MyReactTooltip from "../../../../components/ReactTooltip";
import { getUuidV4, objectDeepClone } from "@apiwiz/oas/services";
import API from "../../../../api";
import { AppDetailsModal } from "../../components/AppDetailsModal";
import { AzureAppDetails } from "../../components/AzureAppDetailsModal";
import { KongAppDetails } from "../../components/KongAppDetailsModal";
import { GenericAppDetails } from "../../components/GenericAppDetailsModal";
import { APP_STATUS, GATEWAYS } from "../../../../service/constants";
import SwaggerAccordion from "../../../../components/SwaggerAccordion";
import monetization from "../../../../api/monetization";
import { resetDeveloperApp } from "../../../../app/Features/DeveloperApp/DeveloperAppSlice";
import { useDispatch } from "react-redux";
import PaginationV2 from "../../../../components/PaginationV2";
import TransactionDetailModal from "./TransactionDetailModal";
import monetizationAPI from "../../../../api/monetization"

const _uuid = getUuidV4()
const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

const RevenueData = [
    {
        name: "Daily Usage",
        value: [],
        options: { fill: true, borderColor: "#63C174", radius: 0, bgColor: true, bgStart: "#0FCA7A", bgEnd: "rgba(15, 202, 122, 0.00)" },
    },
];

const TransactionLogCompnent = forwardRef((props, ref) => {
    const { value, onClick } = props
    return (
        <div onClick={onClick} className="h-100 w-max-content surface-consumer-850 br-4px p-12px d-flex align-items-center justify-content-center cursor border-theme-base-200 gap-7px">
            <div className="text-consumer-400 fs-16px fw-500 text-nowrap">{value ? <>{moment(value).format("MMMM") + " " + moment(value).format("yyyy")}</> : ''}</div>
            <CaretDown size={16} />
        </div>
    );
});


export default function AppDetail(){
    const dispatch = useDispatch()
    const [copied, setCopied] = useState('')
    // const _queryPrams = new URLSearchParams(search);
    // const appId = _queryPrams.get("appId");
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [monetizeAppDetails, setMonetizeAppDetails] = useState({})

    const [developerApp, setDeveloperApp] = useState({})
    const [productDetails, setProductDetails] = useState({})
    const [appCreds, setAppCreds] = useState({ consumerKey: "", consumerSecret: ""})
    const [multiProductDetails, setMultiProductDetails] = useState([])

    const [productLoader, setProductLoader] = useState(false)
    const [credsLoader, setCredsLoader] = useState(false)
    
    const { currentUser } = useSelector((state) => state.user)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentCurrency, setCurrentCurrency] = useState({})
    const currency = currentUser?.billing?.billingCurrency?.currencySymbol || currentUser?.billing?.billingCurrency?.currencyCode || ''
    const [numberOfDayInMonth, setNumberOfDayInMonth] = useState(getDaysInAMonth(new Date()));

    const [InfoType, setInfoType] = useState([
        {label: "General Details", id: getUuidV4(), type: 'gd'},
        {label: "Advanced Details", id: getUuidV4(), type: 'ad'},
        {label: "Transaction Logs", id: getUuidV4(), type: 'tl'}
    ])
    const [detailType, setDetailType] = useState(InfoType[0])


    const [txnLogList, setTxnLogList] = useState([])
    const [txnLoader, setTxnLoader] = useState(false)
    const [txnLogDate, setTxnLogDate] = useState(new Date())
    const tooltipText = `Transaction charged from Default Ratecard`
    const [txnLogModal, setTnxLogModal] = useState({isOpen: false, data: {}})
    const [txnPagination, setTxnPagination] = useState({total: 10, offset: 1, pageSize: 10})

    const handleCopy = async (_text) => {
        setCopied(_text)
        copyToClipboard(_text)
        await sleep(1000)
        setCopied('')
    }

    const calcPercentage = (current, total) => {
        if(!current) return 0;
        return ((current / total) * 100).toFixed(2) + " %";
    }

    const [loaders, setLoaders] = useState({
        overall: false,
        chart: false,
        packageMetric: false
    })
    const [metricData, setMetricData] = useState({
        overall: "",
        chart: RevenueData,
        packageMetric: {},
    })
    const [appDetailsModal, setAppDetailsModal] = useState({
        isOpen: false,
        data: {},
        type: "",
    });
    const [packages, setPackages] = useState([])

    const handleTabOnClick = (tab) => {
        setDetailType(tab)
        if(tab.type === 'ad') {
            fetchMetricData(MonetizeDateFn(selectedDate), monetizeAppDetails.appId);
        } 
        else if (tab.type === "tl") {
            fetchTransactionLog(MonetizeDateFn(selectedDate), txnPagination.offset)
            fetchTransactionLogTotal(MonetizeDateFn(selectedDate))
        }
    }

    const handleTxnLogDateOnChange = (date) => {
        setTxnLogDate(date);
        fetchTransactionLog(MonetizeDateFn(date), txnPagination.offset);
    };

    const fetchTransactionLog = (date, offset=1) => {
        setTxnLoader(true)
        
        let params = { month: date.currentMonth, year: date.currentYear, developerId: currentUser.email, appId: monetizeAppDetails.appId, offset, pageSize: txnPagination.pageSize};
        monetizationV2.fetchMonetizedAppTransactionLog(params)
        .then(({data: res}) => {
            setTxnLoader(false)
            setTxnLogList(res.data)
        })
        .catch((err) => {
            setTxnLoader(false)
            throwServerError(err)
        })
    }
    const fetchTransactionLogTotal = (date) => {
        let params = { month: date.currentMonth, year: date.currentYear, developerId: currentUser.email, appId: monetizeAppDetails.appId};
        monetizationV2.fetchMonetizedAppTransactionLogTotal(params)
        .then(({data: res}) => {
            console.log(res.data,"res.data");
            setTxnPagination((prev) => ({...prev, total: res.data}))
        })
        .catch((err) => {
            throwServerError(err)
        })
    }

    const handlePaginationOnChange = (offset) => {
        setTxnPagination((prev) => ({...prev, offset}))
        fetchTransactionLog(MonetizeDateFn(selectedDate), offset)
    }

    const fetchMetricData = (date, appId) => {
        setLoaders((prev) => ({...prev, overall: true, packageMetric: true, chart: true}))
        let params = { month: date.currentMonth, year: date.currentYear, developerId: currentUser.email, appId };
        monetizationV2.getAppOverallRevenue(params)
        .then(({data: res}) => {
            setLoaders((prev) => ({...prev, overall: false}))
            setMetricData((prev) => ({...prev, overall: res.data}))
        })
        .catch((err)=>{
            setLoaders((prev) => ({...prev, overall: false}))
            throwServerError(err)
        })
        monetizationV2.getDailyAppRevenue(params)
        .then(({data: res}) => {
            setLoaders((prev) => ({...prev, chart: false}))

            let revenueData = [];
            
            if (res.data && res.data.length > 0) {
                const maxDate = Math.max.apply(Math, res.data.map(o => o.day));
                let _sortedData = res.data.sort((a, b) => a.day - b.day); 
                _sortedData = _sortedData.map((x) => ({...x, totalAmount: parseFloat(x.totalAmount * currentCurrency?.unitAmount || 0)}))
                
                let preFillData=[], hashMap = arrayToObject('day', _sortedData)
                for (const date of getDaysInAMonth(selectedDate)) {
                    if(!(date > maxDate)) {
                        if(hashMap.hasOwnProperty(date)) preFillData.push(hashMap[date])
                        else preFillData.push({totalAmount: 0, metered: 0, unMetered: 0, day: date})
                    }
                }
                revenueData = preFillData.map(((sum) => (value) =>{
                    sum += value.totalAmount;
                    return {sum, change: value.totalAmount}
                })(0));
            }
            
            revenueData = { ...RevenueData[0], value: revenueData.map(el => el.sum), customData: {change: revenueData.map(el => el.change)} };
            setMetricData((prev) => ({...prev, chart: [revenueData]}))
        })
        .catch((err)=>{
            setLoaders((prev) => ({...prev, chart: false}))
            throwServerError(err)
        })

        monetizationV2.getAppPackagesRevenue(params)
        .then(({data: res}) => {
            setLoaders((prev) => ({...prev, overall: false}))
            console.log(res.data,'data');
            let _data = {};
            if(res.data && res.data.length){
                _data = arrayToObject("packageId", res.data)
            }
            setMetricData((prev) => ({...prev, packageMetric: _data}))
            setMonetizeAppDetails(prev => ({...prev, packages: objectDeepClone(res.data)}))
        })
        .catch((err)=>{
            setLoaders((prev) => ({...prev, overall: false}))
            throwServerError(err)
        })
    }

    const handleDateOnChange = (date) => {
        setNumberOfDayInMonth(getDaysInAMonth(date))
        setSelectedDate(date);
        fetchMetricData(MonetizeDateFn(date), monetizeAppDetails.appId);
    };

    const tooltipInnerHtml = ({changeVal, changeText, totalText}) => {
        let html = "";
        if(![null, undefined].includes(changeVal))
        {
            html += `<div class="d-flex align-items-center justify-content-center text-consumer-400 fs-12px fw-400 gap-2px">
                <div>Change: </div>
                <div class="text-consumer-400 text-nowrap">+ ${changeText}</div>
            </div>`;
        }  
        html += `<div class="d-flex align-items-center justify-content-center text-consumer-100 fs-12px fw-500 gap-2px ">
                <div>Total: </div>
                <div class="text-consumer-100">${totalText}</div>
            </div>`;
        return html
    }

    const tooltipForRevenue = (tooltipItem) => {
        return tooltipInnerHtml({
            changeVal: (tooltipItem.dataset.customData?.change||[])[tooltipItem.dataIndex],
            changeText: `${currency} ${(tooltipItem.dataset.customData?.change||[])[tooltipItem.dataIndex]}`,
            totalText: `${currency} ${tooltipItem.dataset.data[tooltipItem.dataIndex]}`
        })
    }

    const customTooltip = (fn, {tooltipEl, tooltipModel}) => {
        const tooltipData = tooltipModel.dataPoints;

        tooltipEl.style.display = 'block';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${tooltipModel.caretX}px`;
        tooltipEl.style.top = `${tooltipModel.caretY - 84}px`;
      
        let tooltipHTML = '<div class="surface-consumer-base br-4px border-strokes-600-01">';
        tooltipHTML += `<div class="h-28px p-8px surface-consumer-900 d-flex align-items-center justify-content-center text-consumer-100 fs-12px br-tl-4px br-tr-4px fw-400 ">${tooltipModel.title} ${moment(selectedDate).format("MMM")}</div>`;
        tooltipHTML += `<div class="py-5px px-8px d-flex flex-column gap-6px">`;
        for (const tooltipItem of tooltipData) {
            if(fn) {
                tooltipHTML += fn(tooltipItem)
            }
        }
        tooltipHTML += `</div>`;
        tooltipHTML += '</div>';
      
        tooltipEl.innerHTML = tooltipHTML;
    }

    const handleAppDelete = () => {
        if(!confirm("Are you sure, you want to delete this app?")) return;
        setLoading(true);
        if(developerApp.gateway==="axway"){
            API.deleteDeveloperApp(developerApp._id, { connectorId: developerApp.meta[developerApp.gateway].connectorId })
            .then((res) => {
                showSuccessMessage("App deleted successfully")
                navigate(-1)
                dispatch(resetDeveloperApp())
            })
            .catch((err) => {
                setLoading(false)
                throwServerError(err)
            })
        }else{
            API.deleteDeveloperApp(developerApp._id, { gatewayType: developerApp.gateway })
            .then((res) => {
                showSuccessMessage("App deleted successfully")
                navigate(-1)
                dispatch(resetDeveloperApp())
            })
            .catch((err) => {
                setLoading(false)
                throwServerError(err)
            })
        }
    }

    const getAppCredentials = (data) => {

        if(data.status !== APP_STATUS.APPROVED) return;

        // Only one app can be created per product
        if (data.gateway === GATEWAYS.APIGEE) {
            const { credentials } = data.meta[data.gateway];
            setAppCreds({ consumerKey: credentials?.[0]?.consumerKey, consumerSecret: credentials?.[0]?.consumerSecret})
        }
        else if (data.gateway === GATEWAYS.KONG) {
            const metaData = data.meta[data.gateway];
            setAppCreds({ consumerKey: metaData?.clientId, consumerSecret: metaData?.secret})
        }
        else if (data.gateway === GATEWAYS.AXWAY) {
            const metaData = data.meta[data.gateway];
            setAppCreds({ consumerKey: metaData?.clientId, consumerSecret: metaData?.clientSecret})
        }
        else if (data.gateway === GATEWAYS.AZURE) {
            const metaData = data.meta[data.gateway];
            setAppCreds({ consumerKey: metaData?.key, consumerSecret: ""})
        }
        else if (data.gateway === GATEWAYS.AWS) {
            setCredsLoader(true)
            API.getAwsCredentials({id: data._id})
            .then(({data: res}) => {
                setCredsLoader(false)
                setAppCreds({ consumerKey: res.data.apiKey, consumerSecret: ""})
            })
            .catch((err) => {
                setCredsLoader(false)
                throwServerError(err)
            })
        }
        else if (data.gateway === GATEWAYS.NGINX) {
            setCredsLoader(true)
            API.getNginxCredentials({id: data._id})
            .then(({data: res}) => {
                setCredsLoader(false)
                const { consumerKey } = res.data
                setAppCreds({ consumerKey, consumerSecret: ""})
            })
            .catch((err) => {
                setCredsLoader(false)
                throwServerError(err)
            })
        }
    }

    const getMonetizationData = (monetizeId) => {
        setLoading(true)
        monetizationV2.getAppById(monetizeId)
        .then(({data: appResponse}) => {
            setLoading(false)
            setMonetizeAppDetails(appResponse.data)

            if(appResponse.data && appResponse.data.appId) {
                fetchMetricData(MonetizeDateFn(selectedDate), appResponse.data.appId)
            }

            if (appResponse.data && appResponse.data.subscriptionId){
                API.getDeveloperSubscription(appResponse.data.subscriptionId, {
                    developerEmail: currentUser.email
                }).then(({data: res}) => {
                    let temp = res?.data?.packages?.map((x) => {
                        const currentDate = new Date()
                        const endDate = new Date(x.endDate);
                        if(currentDate > endDate) x.isExpired = true
                        return x;
                    }) || []
                    console.log('res', temp)
                    setPackages(objectDeepClone(temp || []))
                }).catch(err => {
                    console.log('err', err)
                })
            }

        })
        .catch((err) => {
            setLoading(false)
            // throwServerError(err)
        })
    }

    const getProductById = (productId) => {
        monetization.getProductDetailsById(productId)
        .then(({data: res}) => {
            setProductDetails(res.data)
        })
        .catch((err) => {
            throwServerError(err)
        })
    }

    const getAllProducts = (selectedIds=[]) => {
        setProductLoader(true)
        monetizationAPI
            .getProductDetails({expand: true})
            .then(({data: {data: res}}) => {
                let multipleProducts = res.filter((x) => selectedIds.includes(x._id))
                setMultiProductDetails(multipleProducts)
            })
            .catch((err) => {
                throwServerError(err);
            });
      };

    const getAppById = () => {
        setLoading(true)
        API.getDeveloperAppById(id)
        .then(({data: res}) => {
            setDeveloperApp(res.data)
            getAppCredentials(res.data)
            if(res?.data?.productId) {
                getProductById(res?.data?.productId)
            }
            if(res?.data?.monetizationAppId){
                getMonetizationData(res?.data?.monetizationAppId)
            } else {
                setLoading(false)
            }
            if(res.data.status === APP_STATUS.PENDING || !(res?.data?.monetizationAppId)) {
                setInfoType(InfoType.filter(x=>x.type === "gd"))
            }
            getAllProducts(res.data.multipleProductIds)
            if(res.data.multipleProductIds && res.data.multipleProductIds.length) {
            }
        })
        .catch((err) => {
            setLoading(false)
            throwServerError(err)
        })
    }

    const getCurrencies = () => {
        API.getMasterCurrencies({status: 'PUBLISH'})
        .then(res => {
            let _data = res.data?.data || []
            if (_data.length > 0) _data = _data[0].currencies || []

            console.log('_data', _data)
            let _modifiedData = []
            let labels = []

            _data.forEach((each) => {
                if (labels.includes(each.currencyName)) return
                _modifiedData.push({...each})
                labels.push(each.currencyName)
            })

            _modifiedData.push({...res.data.data?.defaultCurrency})

            // console.log('_modifiedData', _modifiedData, currentUser?.billing?.billingCurrency)
            _modifiedData.forEach((each) => {
                if (each.currencyCode === currentUser.billing?.billingCurrency?.currencyCode 
                    && each.currencyName === currentUser.billing?.billingCurrency?.currencyName )
                {
                    setCurrentCurrency(objectDeepClone(each))
                }
            })
        }).catch(err => {
            // throwServerError(err)
        })
    }


    useEffect(() => {
        getAppById()
        getCurrencies()
    }, [])
    
    return (
        <div className="w-100vw bg-currentBackground" style={{minHeight: '100vh'}}>
            <CustomNavbar />

            <div className="my-80px container w-100">
                {loading ? (
                    <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh"/>
                ) : (
                <>
                    <div className="d-flex align-items-center gap-4px">
                        <Link to="/my-account"><p className="fs-14px text-theme-primary fw-500">My Account / </p></Link>
                        <Link to="/apps"><p className="fs-14px text-theme-primary fw-500">My Apps / </p></Link>
                        <p className="fs-14px text-theme-primary fw-500">App Details</p>
                    </div>

                    <div className="mt-32px d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex flex-column gap-12px">
                            <p className="fw-600 fs-28px text-theme-content-color">{developerApp?.name || "-"}</p>
                            
                            <div className="d-flex align-items-center">
                                <p className="fs-16px fw-400 text-gray-subtext">
                                    {developerApp?.description || "-"}
                                </p>
                            </div>
                        </div>
                        {developerApp.status !== APP_STATUS.DELETED ?(
                            <div onClick={handleAppDelete} style={{border: "1px solid #E5484D", width: "150px"}} className="cursor gap-10px br-4px py-10px px-16px d-flex align-items-center">
                                <p className="fs-16px fw-600 text-nowrap" style={{color:"#E5484D"}}>Delete App</p>
                                <Trash size={22} color="#E5484D"/>
                            </div>
                        ) : null}
                    </div>

                    <div className="d-flex align-items-center gap-16px mt-32px">
                        {InfoType.map(x => (
                            <div onClick={()=>handleTabOnClick(x)} className="cursor fs-14px fw-600 text-theme-primary"
                            style={{borderBottom: detailType.type === x.type  ?`2px solid var(--primary-color)` : null,
                            padding: "1px 4px 11px 4px"}}>
                                {x.label}
                            </div>
                        ))}
                    </div>

                    {detailType.type === "gd" ? (
                        <div className="d-flex flex-column h-100 mt-32px">
                            <p className="fs-20px fw-600 text-theme-content-color">General Info</p>
                            {(developerApp?.status === APP_STATUS.APPROVED) && (appCreds?.consumerKey || appCreds?.consumerSecret) && (
                                <div className="d-flex flex-column gap-12px br-4 border-1px border-strokes-800 p-16px mt-16px w-max">
                                    {appCreds?.consumerKey ? <div className="fw-14px fw-500 text-theme-content-color">{developerApp.gateway === GATEWAYS.KONG ? "Client Id" : "Consumer Key"}: <span className="fw-400">{appCreds?.consumerKey}</span></div> : null}
                                    {appCreds?.consumerSecret ?  <div className="fw-14px fw-500 text-theme-content-color">Consumer Secret: <span className="fw-400">{appCreds?.consumerSecret}</span></div> : null}
                                </div> 
                            )}
                            {developerApp?.monetizationAppId && monetizeAppDetails?.appId ? (
                                <div data-tip="" data-for={_uuid} onClick={()=>handleCopy(monetizeAppDetails.appId)} className="cursor d-flex align-items-center gap-12px br-4 mt-8px border-1px border-strokes-800 p-16px w-max">
                                    <div className="fw-14px fw-500 text-theme-content-color">Subscription App Id:</div>    
                                    <div className="fw-14px fw-400 text-theme-content">{monetizeAppDetails?.appId}</div>    
                                    { (copied === monetizeAppDetails.appId) ? <FilePlus color='var(--content-subtle)' size={14} />: <Copy color='var(--content-subtle)' size={14} />}
                                    {<MyReactTooltip id={_uuid}>{(copied === monetizeAppDetails.appId) ? "Copied" : "Copy to Clipboard"} </MyReactTooltip>}
                                </div>   
                            ) : null}
                            {multiProductDetails && multiProductDetails.length? (
                                <div className="d-flex flex-column gap-8px">
                                    <p className="fs-20px fw-600 text-theme-content-color mt-32px">Product Info</p>
                                    {multiProductDetails.map((product, key) => (
                                        <div key={key} className="d-flex flex-column gap-8px surface-consumer-900 br-4px p-8px border-1px border-strokes-800">
                                            <div className="d-flex flex-column gap-4px">
                                                <div className="fs-12px fw-400 text-consumer-200">Product Name</div>
                                                <div className="text-consumer-200 fs-14px fw-500">{product?.displayName || productDetails?.name}</div>
                                            </div>
                                            {product?.description ? (
                                                <div className="d-flex flex-column gap-4px" >
                                                    <div className="fs-12px fw-400 text-consumer-200">Product Description</div>
                                                    <div className="text-consumer-200 fs-14px fw-500">{product?.description}</div>
                                                </div>
                                            ) : null}

                                            <SwaggerAccordion data={product?.swagger || []}/>
                                        </div>
                                    ))}
                                </div>
                            ) : productDetails && Object.values(productDetails).length ? (
                                <>
                                    <p className="fs-20px fw-600 text-theme-content-color mt-32px">Product Info</p>
                                    <div className="mt-16px d-flex flex-column ga-9px">
                                        <div className="fs-12px fw-400 text-consumer-200">Product Name</div>
                                        <div className="text-consumer-200 fs-14px fw-500">{productDetails?.displayName || productDetails?.name}</div>
                                    </div>
                                    <div className="mt-16px d-flex flex-column ga-9px mb-10px" >
                                        <div className="fs-12px fw-400 text-consumer-200">Product Description</div>
                                        <div className="text-consumer-200 fs-14px fw-500">{productDetails?.description}</div>
                                    </div>

                                    <SwaggerAccordion data={productDetails?.swagger || []}/>
                                </>
                            ) : null}
                        </div>
                    ) : detailType.type === "ad" ? (
                        <div className="h-100 w-100 d-flex flex-column gap-10px mt-10px">
                            {loaders.overall ? (
                                <ContainerLoader  variant="theme-primary" className="w-100 h-100 min-h-100vh"/>
                            ) :
                            <>
                            <div className="monetize-detail-wrapper w-100 h-100">
                                <div className="d-flex flex-column flex-md-row align-items-stretch justify-content-between total-list">
                                    <div className="surface-consumer-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                                        <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                            <CurrencyCircleDollar color="#0081F1" size={16} />
                                        </div>
                                        <div className="d-flex flex-column gap-4px">
                                            <div className="grey-subtext fs-14px fw-400">Total Usage</div>
                                            <div className="d-flex align-items-center gap-10px">
                                                <div className="fs-18px fw-600 text-theme-content-color">
                                                    {metricData?.overall?.totalAmount ? (
                                                        <>{currency} {NumberFormatter(metricData?.overall?.totalAmount || '0', 2, currentCurrency?.unitAmount)}</>
                                                    ) : 0}
                                                </div>
                                                <div>
                                                    <MonetizeTrendv2 value={metricData?.overall?.revenueTrend || 0} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="surface-consumer-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                                        <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                            <MonetizationIcons type={"metered-calls"} />
                                        </div>
                                        <div className="d-flex flex-column gap-4px">
                                            <div className="text-gray-subtext fs-14px fw-400">Total Metered Calls</div>
                                            <div className="d-flex align-items-center gap-10px">
                                                <div className="fs-18px fw-600 text-theme-content-color">{NumberFormatter(metricData?.overall?.metered || '0')}</div>
                                                <div>
                                                    <MonetizeTrend value={metricData?.overall?.meteredTrend || 0} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="surface-consumer-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                                        <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                            <MonetizationIcons type={"unmetered-calls"} />
                                        </div>
                                        <div className="d-flex flex-column gap-4px">
                                            <div className="text-gray-subtext fs-14px fw-400">Total Unmetered Calls</div>
                                            <div className="d-flex align-items-center gap-10px">
                                                <div className="fs-18px fw-600 text-theme-content-color">{NumberFormatter(metricData?.overall?.unMetered || '0')}</div>
                                                <div>
                                                    <MonetizeTrend value={metricData?.overall?.unMeteredTrend || 0} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <MonthYearPicker selectedDate={selectedDate} handleCallback={handleDateOnChange} />
                                </div>
                            </div>
                            <div className="mt-16px text-consumer-200 fs-14px fw-600 w-100 text-center">Daily Usage</div>
                            <div className="" style={{ height: "291px" }}>
                                {loaders.chart ? (
                                    <ContainerLoader/>
                                ) : <LineChart currencyInYAxisLabel customTooltip={(...args) => customTooltip(tooltipForRevenue, ...args)} data={metricData.chart} labels={numberOfDayInMonth} xAxisTitle={"Days"} stepSize={1} />}
                            </div>

                            <div className="d-flex align-items-center gap-16px mt-20px">
                                <div className="text-theme-content-color fs-20px fw-600">Package Details</div>
                                {monetizeAppDetails && monetizeAppDetails.packages && monetizeAppDetails.packages.length ? (
                                    <div
                                        className="br-6px px-4px py-2px fs-12px fw-500 text-gray-subtext border-theme-base-100"
                                        style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
                                    >
                                        {pluralize(monetizeAppDetails.packages.length, 'Package')}
                                    </div>
                                ) : null}
                            </div>
                            <div className="monetization-table w-100 h-100 overflow-scroll">
                                <table className="w-100 h-100 br-tl-3px br-tr-3px" style={{border: '1px solid var(--base-100)'}}>
                                    <thead className="d-flex align-items-center w-100 br-tl-3px br-tr-3px">
                                        <th style={{width: "16.66666667%"}} className="fs-12px fw-500 text-gray-subtext surface-consumer-900">Package Name</th>
                                        <th style={{width: "20.66666667%"}} className="fs-12px fw-500 text-gray-subtext surface-consumer-900">Package ID</th>
                                        <th style={{width: "16.66666667%"}} className="fs-12px fw-500 text-gray-subtext surface-consumer-900">Calls share in App (%)</th>
                                        <th style={{width: "16.66666667%"}} className="fs-12px fw-500 text-gray-subtext surface-consumer-900">Usage</th>
                                        <th style={{width: "16.66666667%"}} className="fs-12px fw-500 text-gray-subtext surface-consumer-900">Usage share in App (%)</th>
                                        <th style={{width: "16.66666667%"}} className="fs-12px fw-500 text-gray-subtext surface-consumer-900"><div style={{visibility:"hidden"}}>nevermind</div></th>
                                    </thead>
                                    <tbody className="d-flex flex-column gap-1px">
                                        {(packages && packages.length > 0) ? (
                                            packages.map((data) => (
                                                <tr className="d-flex align-items-center w-100">
                                                    <td style={{width: "16.66666667%"}} className="d-flex flex-column gap-2px p-8px surface-consumer-850">
                                                        <p className="fs-14px text-theme-content-color fw-500">{data?.packageName || '-'}</p>
                                                        {data.isExpired ? (
                                                            <div className="d-flex align-items-center gap-8px">
                                                                <p className="fs-12px text-gray-subtext fw-400">{data?.resourceSize || 0} APIs</p>
                                                                <div className={`consumer-package-expired`}>
                                                                    <span className='status-text fs-14px fw-500'>Expired</span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {data.packageId 
                                                    ? <td style={{width: "20.66666667%"}} 
                                                        data-tip="" data-for={data.packageId}
                                                        className="surface-consumer-850 d-flex gap-4px align-items-center fs-14px fw-500 text-gray-subtext cursor" onClick={() => handleCopy(data.packageId)} >
                                                        {data.packageId}
                                                        <div>
                                                            { copied === data.packageId ? <FilePlus color='var(--content-subtle)' size={14} />: <Copy color='var(--content-subtle)' size={14} />}
                                                        </div>
                                                        {<MyReactTooltip id={data.packageId}>{(copied === data.packageId) ? "Copied" : "Copy to Clipboard"} </MyReactTooltip>}
                                                    </td>
                                                    : <td style={{width: "20.66666667%"}} 
                                                        data-tip="" data-for={data.id}
                                                        className="surface-consumer-850 d-flex gap-4px align-items-center fs-14px fw-500 text-gray-subtext cursor" onClick={() => handleCopy(data.id)} >
                                                        {data.id}
                                                        <div>
                                                            { copied === data.id ? <FilePlus color='var(--content-subtle)' size={14} />: <Copy color='var(--content-subtle)' size={14} />}
                                                        </div>
                                                        {<MyReactTooltip id={data.id}>{(copied === data.id) ? "Copied" : "Copy to Clipboard"} </MyReactTooltip>}
                                                    </td>}
                                                    <td style={{width: "16.66666667%"}} className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext">{metricData.packageMetric.hasOwnProperty(data.packageId) ? calcPercentage(metricData?.packageMetric?.[data.packageId]?.metered, metricData?.overall?.metered) : '-'}</td>
                                                    <td style={{width: "16.66666667%"}} className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext">{metricData.packageMetric.hasOwnProperty(data.packageId) ? <>{currency} {NumberFormatter(metricData?.packageMetric?.[data.packageId]?.totalAmount, 2, currentCurrency?.unitAmount)}</> : '-'}</td>
                                                    <td style={{width: "16.66666667%"}} className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext">{metricData.packageMetric.hasOwnProperty(data.packageId) ? calcPercentage(metricData?.packageMetric?.[data.packageId]?.totalAmount, metricData?.overall?.totalAmount) : '-'} </td>
                                                    {data.packageId ? (
                                                        <td style={{width: "16.66666667%"}} onClick={()=>navigate(`/monetization/package-detail/${data.packageId}?type=app&appId=${monetizeAppDetails.appId}&_id=${id}`)} className="cursor surface-consumer-850 fw-500 d-flex align-items-center text-theme-primary fs-14px fw-600 gap-4px">
                                                            Go to Package <TrendUp size={16} />
                                                        </td>
                                                    ) : data.id ? (
                                                        <td style={{width: "16.66666667%"}} onClick={()=>navigate(`/monetization/package-detail/${data.id}?type=app&appId=${monetizeAppDetails.appId}}&_id=${id}`)} className="cursor surface-consumer-850 fw-500 d-flex align-items-center text-theme-primary fs-14px fw-600 gap-4px">
                                                            Go to Package <TrendUp size={16} />
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            ))
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                            </>}
                        </div>
                    ) : detailType.type === "tl" ? (
                        txnLoader ? (
                            <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh"/>
                        ) : (
                            <div className="d-flex flex-column w-100 mt-32px">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <p className="fw-600 fs-20px text-theme-content-color">Transaction Logs</p>
                                    <MonthYearPicker selectedDate={txnLogDate} handleCallback={handleTxnLogDateOnChange}
                                        formattedChildren={<TransactionLogCompnent/>}
                                    />
                                </div>
                                <div className="mt-24px mx-0 row py-12px pl-6px pr-12px surface-consumer-900">
                                    <div className='col-md-1 dfaic justify-content-between'>
                                        <p className='text-consumer-400 fs-10px fw-500 '>Time</p>
                                    </div>
                                    <div className='col-md-1'>
                                        <p className='text-consumer-400 fs-10px fw-500 text-center'>Method</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <p className='text-consumer-400 fs-10px fw-500'>Path</p>
                                    </div>
                                    <div className='col-md-2'>
                                        <p className='text-consumer-400 fs-10px fw-500 text-center'>Package Name</p>
                                    </div>
                                    <div className='col-md-2'>
                                        <p className='text-consumer-400 fs-10px fw-500 text-center'>Ruleset Name</p>
                                    </div>
                                    <div className='col-md-2'>
                                        <p className='text-consumer-400 fs-10px fw-500 text-center'>Rate Card</p>
                                    </div>
                                    <div className='col-md-1'>
                                    </div>
                                </div>
                                <div className="d-flex flex-column gap-1px">
                                    {txnLogList && txnLogList.length ? (
                                        txnLogList.map((each,index) => (
                                            <div className='row mx-0 surface-consumer-850' style={{padding: '8px 10px'}} key={each.transactionId}>
                                                <div className='col-md-1 my-auto px-0 dfaic justify-content-between'>
                                                    <p className='text-consumer-400 fs-12px fw-400'>
                                                        {moment(each.eventTimestamp).format("HH:mm:")}
                                                        <span className="">{moment(each.eventTimestamp).format("ss:SSS")}</span>
                                                    </p>
                                                </div>
                                                <div className='col-md-1 my-auto dfaic justify-content-center'>
                                                    {each.actualMethod
                                                    ? <div className={`v2-status-code ${each.actualMethod} dfaic justify-content-center br-38px py-4px pr-6px pl-8px v2`} style={{width: 80}}>
                                                        <p className='fs-10px text-consumer-100 text-uppercase fw-600'>
                                                            {each.actualMethod}
                                                        </p>
                                                    </div>
                                                    : <div className='d-flex align-items-center cursor w-fit'
                                                        data-tip data-for={"transactionFromDefaultRatecard"}>
                                                        <div className='undefinedChip'>
                                                            <p className='fs-10px fw-500'>Undefined</p>
                                                        </div>
                                                        <Info size={12} className='ms-1' />
                                                        <MyReactTooltip id={"transactionFromDefaultRatecard"}>
                                                            <div className="fs-12px">{tooltipText}</div>
                                                        </MyReactTooltip>
                                                    </div>}
                                                </div>
                                                <div className='col-md-3 my-auto'>
                                                    {each.actualPath 
                                                        ? <p className='text-consumer-300 fs-14px fw-500 text-truncate' title={each.actualPath}>
                                                            {each.actualPath}
                                                        </p>
                                                        : <div className='d-flex align-items-center cursor w-fit'
                                                        data-tip data-for={"transactionFromDefaultRatecard"}>
                                                        <div className='undefinedChip'>
                                                            <p className='fs-10px fw-500'>Undefined</p>
                                                        </div>
                                                        <Info size={12} className='ms-1' />
                                                        <MyReactTooltip id={"transactionFromDefaultRatecard"}>
                                                            <div className="fs-12px">{tooltipText}</div>
                                                        </MyReactTooltip>
                                                    </div>}
                                                </div>
                                                <div className='col-md-2 my-auto'>
                                                    <p className='text-consumer-300 fs-14px fw-500 text-center text-truncate'
                                                        title={each.packageName}>
                                                        {each.packageName || '-'}
                                                    </p>
                                                </div>
                                                <div className='col-md-2 my-auto'>
                                                    {each.meteringName ? <p className='text-consumer-300 fs-14px fw-500 text-center text-truncate'
                                                        title={each.meteringName}>
                                                        {each.meteringName}
                                                    </p> : <div className='d-flex align-items-center cursor justify-content-center'
                                                        data-tip data-for={"transactionFromDefaultRatecard"}>
                                                        <div className='undefinedChip'>
                                                            <p className='fs-10px fw-500'>Undefined</p>
                                                        </div>
                                                        <Info size={12} className='ms-1' />
                                                        <MyReactTooltip id={"transactionFromDefaultRatecard"}>
                                                            <div className="fs-12px">{tooltipText}</div>
                                                        </MyReactTooltip>
                                                    </div>}
                                                </div>
                                                <div className='col-md-2 my-auto'>
                                                    <div className='surface-consumer-base p-8px br-4px dfaic justify-content-between'>
                                                        <p className='text-consumer-300 fs-11px fw-500 text-truncate'
                                                            title={each.rateCardName}>{each.rateCardName || '-'}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-1 dfaic justify-content-between'>
                                                    <div></div>
                                                    <div className='border-strokes-600 surface-consumer-900 border-1px py-6px px-12px cursor br-4px dfaic justify-content-between'
                                                        onClick={() => setTnxLogModal({isOpen: true, data: objectDeepClone(each)})}>
                                                        <p className='fs-12px text-consumer-400 fw-400'>Inspect</p>
                                                        <ArrowSquareOut size={14} className='ms-2 text-consumer-300' />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : null}
                                </div>
                                {txnLogList && txnLogList.length > 0 ? (
                                    <div className="mt-32px">
                                        <PaginationV2
                                            pagination={txnPagination}
                                            cb={handlePaginationOnChange}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        )
                    ) : null}
                </>
                )}

                {txnLogModal && txnLogModal.isOpen ? (
                    <TransactionDetailModal
                        data={txnLogModal.data}
                        show={txnLogModal.isOpen}
                        onHide={()=>setTnxLogModal({isOpen:false, data: {}})}
                    />
                ) : null}

                {/* {appDetailsModal.isOpen && appDetailsModal.type === "apigee" && (
                    <AppDetailsModal
                        onModalClose={() => {
                                setAppDetailsModal({
                                isOpen: false,
                                data: {},
                                type: "",
                            });
                        }}
                        appData={{...objectDeepClone(appDetailsModal.data || {}), type: 'apigee'}}
                        statusChangeCallback={() => {}}
                    />
                )}
                {appDetailsModal.isOpen && appDetailsModal.type === "azure" && (
                    <AzureAppDetails
                        onModalClose={() => {
                            setAppDetailsModal({
                            isOpen: false,
                            data: {},
                            type: "",
                            });
                        }}
                        appData={appDetailsModal.data}
                        statusChangeCallback={() => {}}
                    />
                )}
                {appDetailsModal.isOpen && appDetailsModal.type === "kong" && (
                    <KongAppDetails
                        onModalClose={() => {
                            setAppDetailsModal({
                                isOpen: false,
                                data: {},
                                type: "",
                            });
                        }}
                        appData={appDetailsModal.data}
                        statusChangeCallback={() => {}}
                    />
                )}
                {appDetailsModal.isOpen && [GATEWAYS.NGINX, AWS_GATEWAY].includes(appDetailsModal.type) && (
                    <GenericAppDetails
                        onModalClose={() => {
                            setAppDetailsModal({
                                isOpen: false,
                                data: {},
                                type: "",
                            });
                        }}
                        app={{...objectDeepClone(appDetailsModal.data || {}), type: appDetailsModal.type}}
                    />
                )} */}
            </div>
        </div>
    );
};

