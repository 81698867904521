import { Modal } from "react-bootstrap";

export default function ThemeCenteredModal(props) {
  return (
    <Modal
      show={props.show}
      size={props.size}
      className={`v1--styles theme-modal ${props.className}`}
      onHide={props.onHide || null}
    >
      <Modal.Body className={props.bodyClassName}>
        {props.children}
      </Modal.Body>
    </Modal>
  );
}
