import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { slugify, throwServerError } from '../../../../utils/helper';
import Select from 'react-select';
import GlobalIcons from '../../../../components/Icons/GlobalIcons';
import Loading from '../../../../components/Loader/Loading';
import { objectDeepClone } from '@apiwiz/oas/services';
import GUIDE_CATEGORY_API from '../../../../api/guideCategory';
import { getToast } from '../../../../components/Toast';

export default function CloneGuideModal(props) {
    const [data, setData] = useState({
        cloneOf: null,
        deprecated: false,
        order: 1,
        status: "Draft",
        tag: "",
        title: "",
        version: ""
    })
    const [errors, setErrors] = useState({})
    const [disableSave, setDisableSave] = useState(false)
    const [isPrimary, setIsPrimary] = useState(false)

    useEffect(() => {
        if (props.isEdit){
            let _vers = objectDeepClone(props.data?.version || {})

            setData({
                title: _vers.title || '',
                tag: _vers.tag || '',
                status: _vers.status === 'draft' ? 'Draft' : 'Published',
                isPrimary: _vers.isPrimary || false,
                deprecated: _vers.deprecated || false,
            })
            setIsPrimary(_vers.isPrimary || false)
        }
    }, [])
    
    const handleSubmit = () => {
        console.log('data', data)

        let tempErrors = {}
        if (!data.title) tempErrors['title'] = true 
        if (props.isClone && !data.cloneOf) tempErrors['cloneOf'] = true 

        setErrors(tempErrors)
        if (Object.keys(tempErrors).length) return

        let json = objectDeepClone(data)

        if (!json.tag) delete json['tag']
        if (props.isClone){
            json.cloneOf = json.cloneOf._id || 'primary'
        }
        json.status = json.status?.toLowerCase()

        if (props.isClone){
            let _data = objectDeepClone(props.data)
            let _allTitles = _data?.guide?.versions?.map(o => o.title)

            if (_allTitles.includes(data.title)){
                getToast({
                    statusType: 'ERROR', message: 'Version Name already exists'
                })
                return
            }
            setDisableSave(true)
            GUIDE_CATEGORY_API.cloneVersion(props.data.guide?._id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Successfully cloned new version of the guide'
                })
                props.onHide()
                props.callback()
                setDisableSave(false)
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        } else if (props.isCreate){
            let _data = objectDeepClone(props.data)
            let _allTitles = _data?.guide?.versions?.map(o => o.title)

            if (_allTitles.includes(data.title)){
                getToast({
                    statusType: 'ERROR', message: 'Version Name already exists'
                })
                return
            }
            
            if (json.hasOwnProperty("cloneOf")) delete json['cloneOf']
            setDisableSave(true)
            GUIDE_CATEGORY_API.cloneVersion(props.data.guide?._id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Successfully created new version of the guide'
                })
                props.onHide()
                props.callback()
                setDisableSave(false)
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        } else if (props.isEdit){
            if (json.hasOwnProperty("cloneOf")) delete json['cloneOf']
            json['isPrimary'] = isPrimary

            setDisableSave(true)
            GUIDE_CATEGORY_API.updateVersion(props.data.guide?._id, props.data.version?._id ,json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Changes updated successfully'
                })
                props.onHide()
                props.callback()
                setDisableSave(false)
            }).catch(err => {
                throwServerError(err)
                setDisableSave(false)
            })
        }

    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isClone ? 'Clone' : props.isEdit ? "Update" : 'Create'} Version</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div onKeyDown={(e) => {
                        setIsCtrl(['Control', 'Meta'].includes(e.key))
                        if (isCtrl && e.key?.toLowerCase() === 's') {
                            handleSubmit()
                            e.preventDefault()
                        }
                    }}>
                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Version Title <span className='text-danger'>*</span> </p>
                        <input className='form-control v1-form-control mt-1' value={data.title}
                            placeholder='1.0.0' onChange={(e) => {
                                let _str = e.target.value 
                                setData({...data, title: _str, version: slugify(_str)})
                                setErrors({...errors, title: _str.length === 0})
                            }}
                        />

                        {errors.title && <p className='text-danger fs-11px mt-1'>
                            Title is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Version Slug </p>
                        <input className='form-control v1-form-control mt-1' value={data.version}
                            disabled placeholder='1-0-0'
                        />
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Tag </p>
                        <input className='form-control v1-form-control mt-1' value={data.tag}
                            placeholder='latest' onChange={(e) => {
                                let _str = e.target.value 
                                setData({...data, tag: _str })
                            }}
                        />
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Status </p>
                        <Select className='v1select mt-1'
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            options={[{label: 'Draft', value: 'draft'}, {label: 'Published', value: 'published'}]}
                            value={{label: data.status, value: data.status.toLowerCase()}}
                            onChange={(e) => setData({
                                ...data, status: e.label
                            })}
                        />
                    </div>

                    { (!props.isCreate && !props.isEdit) &&<div className='row mb-3'>
                        <div className='col-md-12'>
                            <div className=''>
                                <p className='fs-12px fw-400 text-300'>Clone From <span className='text-danger'>*</span> </p>
                                <Select className='v1select mt-1'
                                    classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                    options={props.data?.guide?.versions?.map(o => ({...o, label: o.title, value: o._id}))}
                                    value={data.cloneOf}
                                    onChange={(e) => {
                                        setData({
                                            ...data, cloneOf: e
                                        })
                                        setErrors({...errors, cloneOf: false})
                                    }}
                                />
                                {errors.cloneOf && <p className='text-danger fs-11px mt-1'>
                                    Clone from id is required
                                </p>}
                            </div>
                        </div>
                    </div>}

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='w-fit d-flex align-items-center cursor'
                                onClick={() => setData({...data, deprecated: !data.deprecated})}>
                                <GlobalIcons type={data.deprecated ? 'form-check' : 'form-uncheck'} />
                                <p className='fs-12px fw-400 text-300 ms-1'>Deprecated </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            {props.isEdit && (
                            props.data?.version?.isPrimary
                            ? <div className='w-fit d-flex align-items-center cursor'>
                                <GlobalIcons type={'form-check-view'} />
                                <p className='fs-12px fw-400 text-300 ms-1'>Primary </p>
                            </div>
                            : <div className='w-fit d-flex align-items-center cursor'
                                onClick={() => setIsPrimary(!isPrimary)}>
                                <GlobalIcons type={isPrimary ? 'form-check' : 'form-uncheck'} />
                                <p className='fs-12px fw-400 text-300 ms-1'>Primary </p>
                            </div>)}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
