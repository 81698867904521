import React from 'react'
import GlobalIcons from './Icons/GlobalIcons'

export default function AddScenarioBtn({type, action = 'Add', className='', onClick}) {
    return (
        <div className={`${className ? className : 'w-90'} border-dashed v1-border-1 important cursor-pointer`}
            onClick={onClick}>
            <div className='d-flex align-items-center justify-content-center p-2 surface-base'>
                <GlobalIcons type={'plus'} />
                <p className='ms-1 fs-14px text-100 fw-500'>{action || 'Add'} {type || 'Scenario'}</p>
            </div>
        </div>
    )
}
