import { BodyLayout } from "../../../layout/Base";
import { useState,useEffect } from "react";
import Loading from "../../../components/Loader/Loading";
import API from "../../../api";
import { getPermissionsArray,getFirstLetterOfString, getPermissionByCrud } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { getToast } from "../../../components/Toast";
import MyReactTooltip from "../../../components/ReactTooltip";
import EmptyContainer from "../../../components/EmptyContainer";
import Pagination from "../../../components/Pagination";
import DeatilsModal from "./Components/DetailsModal";
import { useOutletContext } from "react-router";
import UserIcons from "../../../components/Icons/UserIcons";
import { Eye } from "phosphor-react";
import { confirm } from "../../../utils/helper";


export default function ContactInformation(props){
    const [loading,setLoading] = useState(true);
    const { currentUser,permissionData} = useSelector((state) => state.user);
    const [ permission, setPermission ] = useState([])
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [currentPage,setCurrentPage] = useState(1);
    const [data,setData] = useState([]);
    const [paging,setPaging] = useState({});
    const [showViewModal,setShowViewModal] = useState();
    const [index,setIndex] = useState();

    const {firstRoute} = useSelector(state => state.user)

    useEffect(() => {
        setNavigation([
            {name: 'Dashboard', link : firstRoute},
            {name: 'Enquiries'},
        ])
        setHasHeader(true)
        setTitle("Enquiries")
        const userRole = currentUser?.role || currentUser?.userRole;
        if(userRole !== 'super-admin'){
            const perm = getPermissionsArray(permissionData, 'contact-us', userRole);
            setPermission(perm)
        }
    }, [])

    useEffect(() => {
        getContactInfoList();
    }, [currentPage])

    // const getPermissionByCrud = (name) => {
    //     const userRole = currentUser?.role || currentUser?.userRole;
    //     return userRole !== 'super-admin' ? permission?.find(o => o.action === name)?.roles?.enabled : true
    // }

    const handleServerSideError = (error) => {
        if (error.response) {
            if (error.response.data.message) {
                getToast({statusType: 'ERROR', message: error.response.data.message})
            }
        } else {
            getToast({statusType:"ERROR",message: error.message })
        }
    }

   
    const getContactInfoList = () => {
        setLoading(true)
        API.getAllContact(currentPage)
        .then(response => {
            setLoading(false)
            if(response.data.data){
                setData(response.data.data)
                setPaging(response.data.paging)
            }
        })
        .catch(error => {
            setLoading(false)
            handleServerSideError(error)
        })
    }

    function handlePaginationChange(val){
        setCurrentPage(val)
    }

    function handleViewDetails(index){
        setShowViewModal((prev)=>!prev)
        setIndex(index)
    } 

    const handleDelete = (event, index) => {
        const id = data[index]._id;
        const msg = 'Are you sure you want to delete?'
        if(confirm(msg)){
            API.deleteContact(id)
            .then(response => {
                getContactInfoList()
                getToast({statusType: 'SUCCESS', message:'Enquiry Enquiries deleted successfully' })
            })
            .catch(error => handleServerSideError(error))
        }
    }

    /* ACL Start */
 
    const {permission:MainPermission} = useSelector(state => state.user)
    let _permission = MainPermission.filter(o => o.name === 'contact-us')
    _permission = _permission.length ? (_permission[0].crud || []) : []

    const list = getPermissionByCrud(currentUser, _permission, 'list')
    const read = getPermissionByCrud(currentUser, _permission, 'read')
    const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')
    /* ACL END */

    return <BodyLayout>
    <div className="fadeIn h-100 w-100">
        <div className="surface-875 p-16px d-flex justify-content-between align-items-center v1-border-bottom-05">
            <p className="fs-18px text-100 fw-600">Contact Form Submissions</p>
        </div>
        <div className="surface-900 p-2" style={{height: 'calc(100% - 60px)'}}>
            { loading 
            ? <div className="h-100"><Loading/> </div>
            : (list && <div className="h-95 overflow-scroll noscrollbar">
                <div className='surface-875 mx-0 d-flex mb-1 p-1 ps-3'>
                    <div className="w-20 fs-10px fw-500 text-400 d-flex">
                        <p>Full Name</p>
                    </div>
                    <div className="w-20 fs-10px fw-500 text-400 d-flex justify-content-center">
                        <p>Job Title</p>
                    </div>
                    <div className="w-20 fs-10px fw-500 text-400 d-flex justify-content-center">
                        <p>Buisness Email</p>
                    </div>
                    <div className="w-20 fs-10px fw-500 text-400 d-flex justify-content-center">
                        <p>Company</p>
                    </div>
                    <div className="w-20 fs-10px fw-500 text-400 d-flex justify-content-center">
                    <p>Action</p>
                    </div>
                </div>
    
                <div className='surface-900 contactUsBody'>
                    {data?.length > 0 ? 
                        data.map((item,index) => (
                            
                            
                            <div className='surface-900 mx-0 d-flex ps-3 pt-15px pb-15px userCard--tableRow v1-border-bottom-1 hover-surface-850' key={item._id}>
                                <div className='w-20 my-auto'>
                                    <div className='d-flex w-100'>
                                        <div className='d-flex align-items-center w-100'>
                                            <div className='me-2 w-100'>
                                                <div className="d-flex w-100">
                                                    <p className='text-200 fs-14px fw-500 text-truncate w-80'>
                                                        {item.fullName || ''}
                                                    </p>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className='w-20 my-auto d-flex justify-content-center'>
                                    <div className='d-flex'>
                                        <p className="fs-14px fw-400">{item.jobTitle || ''}</p>
                                    </div>
                                </div>
                                <div className='w-20 my-auto d-flex justify-content-center'>
                                    <div className='d-flex'>
                                        <p className="fs-14px fw-400">{item.businessEmail || ''}</p>
                                    </div>
                                </div>
                                <div className='w-20 my-auto d-flex justify-content-center'>
                                    <div className='d-flex'>
                                        <p className="fs-14px fw-400">{item.company || ''}</p>
                                    </div>
                                </div>
                                {read && <div className='hoverItem w-20 d-flex my-auto align-items-center justify-content-center'>
                                        <div className='cursor pe-1' data-tip="View" data-for={"View"} onClick={()=>{handleViewDetails(index)}}>
                                            <div className='btn-sm-v1 me-2'>
                                                <Eye size={16} color='#e4e4e4'/> 
                                            </div>
                                            <MyReactTooltip id={"View"} />
                                        </div>
                                        {getDeletePermission  && <div className='cursor pe-1' data-tip="Delete" data-for={"delete"} onClick={(e)=>{handleDelete(e,index)}}>
                                            <div className='btn-sm-v1 me-2'>
                                                <UserIcons type={'bin'} />
                                            </div>
                                            <MyReactTooltip id={"delete"} />
                                        </div>}
                                </div>}
                        </div>
                        ))
                    
                    : (
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <EmptyContainer/>
                        </div>
                    ) 
                
                }
                </div>
            </div>)}

            {(!loading && data?.length > 0) && <Pagination className='' pagination={{
                pageSize: 10,
                offset: currentPage,
                total: paging.total }} cb={(val) => handlePaginationChange(val)} />}
        </div>
            
    </div> 
    {
        showViewModal && <DeatilsModal 
            show={showViewModal}
            onHide={handleViewDetails}
            data={data[index]}
        />

    }
</BodyLayout>
}