import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SidebarItem from "./SidebarItem";
import { v4 as uuidv4 } from "uuid";
import { getPermissionByCrud, labelize } from "../utils/helper";
import { useDispatch } from "react-redux";

export default function Sidebar({hide}) {
    const { firstRoute, menus } = useSelector((state) => state.user);
    const {currentLayoutObject} = useSelector((state) => state.layout)
    const location = useLocation();
    const navigate = useNavigate();
    const {permission} = useSelector(state => state.user)
    const pathName = location.pathname.split("/")[1] + (location.pathname.split("/").length > 1 ? `/${location.pathname.split("/")[2]}` : '');

    const {currentUser} = useSelector(state => state.user)

    const getListOfValidItems = () => {
        let settingItems = []

        // Account Configuration
        let _accountPermission = permission.filter(o => o.name === 'setting')
        _accountPermission = _accountPermission.length ? (_accountPermission[0].crud || []) : []
        const accountUpdate = getPermissionByCrud(currentUser, _accountPermission, 'update')
        if (accountUpdate) settingItems.push('Account Configuration')

        // SMTP Configuration
        let _smtpPermission = permission.filter(o => o.name === 'smtp')
        _smtpPermission = _smtpPermission.length ? (_smtpPermission[0].crud || []) : []
        const smtpList = getPermissionByCrud(currentUser, _smtpPermission, 'list')
        if (smtpList) settingItems.push('SMTP Configuration')

        // Activity log
        let _activityPermission = permission.filter(o => o.name === 'activity')
        _activityPermission = _activityPermission.length ? (_activityPermission[0].crud || []) : []

        const activityLogList = getPermissionByCrud(currentUser, _activityPermission, 'list')
        if (activityLogList) settingItems.push('Activity Log')

        // Connectors
        let _connectorPermission1 = permission.filter(o => o.name === 'aws-artifactory')
        _connectorPermission1 = _connectorPermission1.length ? (_connectorPermission1[0].crud || []) : []

        let _connectorPermission2 = permission.filter(o => o.name === 'gcs-artifactory')
        _connectorPermission2 = _connectorPermission2.length ? (_connectorPermission2[0].crud || []) : []

        let _connectorPermission3 = permission.filter(o => o.name === 'jfrog-artifactory')
        _connectorPermission3 = _connectorPermission3.length ? (_connectorPermission3[0].crud || []) : []

        const connector1Read = getPermissionByCrud(currentUser, _connectorPermission1, 'read')
        const connector2Read = getPermissionByCrud(currentUser, _connectorPermission2, 'read')
        const connector3Read = getPermissionByCrud(currentUser, _connectorPermission3, 'read')
        if (connector1Read && connector2Read && connector3Read) settingItems.push('Connectors')

        return settingItems
    }

    const dispatch = useDispatch()

    const getFilteredMenus = (_menus) => {
        let items = []
        _menus.forEach(menu => {
            let _permission = permission.filter(o => o.name === labelize(menu.slug))
            _permission = _permission.length ? (_permission[0].crud || []) : []
            const getViewPermission = getPermissionByCrud(currentUser, _permission, 'list')
            const getReadPermission = getPermissionByCrud(currentUser, _permission, 'read')
            const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
            const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')

            
            if (menu.id === 'Dashboard' && currentUser?.userRole !== 'super-admin') return
            if (menu.id === 'Monetization' && currentUser?.userRole !== 'super-admin') return
            if (menu.id === "Manage Portal" && !getUpdatePermission) return
            if (menu.id === "Manage Gateway" && !['super-admin', 'admin'].includes(currentUser?.userRole)) return
            if (menu.id === "Settings" && getListOfValidItems().length === 0) return

            if (menu.id === "Guides" && (!getViewPermission && !getReadPermission && !getUpdatePermission && !getCreatePermission)) return
            if (menu.id === "Use Cases" && (!getViewPermission && !getReadPermission && !getUpdatePermission && !getCreatePermission)) return
            if (menu.id === "SDKs" && (!getViewPermission && !getReadPermission && !getUpdatePermission && !getCreatePermission)) return
            if (menu.id === "Events" && (!getViewPermission && !getReadPermission && !getUpdatePermission && !getCreatePermission)) return
            if (menu.id === "App Approval" && (!getViewPermission && !getReadPermission && !getUpdatePermission && !getCreatePermission)) return

            // console.log(menu.id, _permission, !getViewPermission, !getReadPermission, !getUpdatePermission, !getCreatePermission, 'log');

            if (getReadPermission === undefined && getViewPermission === undefined) return
            if (getViewPermission?.enabled === false || getViewPermission?.enabled === false) return
            items.push(menu)
        })

        // dispatch(setFilteredMenu(objectDeepClone(items || [])))
        return items
    }

    return (
        <div
        style={{ zIndex: 10 }}
        className={`${hide && "d-none"} position-fixed top-0 d-flex flex-column justify-content-start align-items-center sidebar`}
        >
            <div className="position-fixed d-flex flex-column">
                {currentLayoutObject.squarelogo && <div
                    onClick={() => {navigate(firstRoute);}}
                    className="compass-logo cursor d-flex align-items-center"
                >
                    <img src={currentLayoutObject.squarelogo} width={"32px"} height={"32px"} alt=''
                        className="ms-1" style={{objectFit: 'contain'}}  />
                </div>}
                {getFilteredMenus(menus).map((menu, index) => {
                    return (
                    <div key={index}>
                        <div
                        className="w-56px h-46px d-flex justify-content-center sidebar-tab"
                        >
                        <SidebarItem
                            id={uuidv4()}
                            pathName={pathName}
                            permission={menu}
                            enabledPermission={permission}
                        />
                        </div>
                    </div>
                    )
                })}
            </div>
        </div>
    )
}
