import { BodyLayout } from "../../../layout/Base";
import { useEffect,useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { VIEW_OPTIONS } from "../../../service/constants";
import { Rows,SquaresFour,Funnel,Plus } from "phosphor-react";
import TagNames from "../../../components/TagNames";
import { Eye } from "phosphor-react";
import { PencilSimple } from "phosphor-react";
import Pagination from "../../../components/Pagination";
import TagsModal from "./TagsModal";
import Gridcard from "./Gridcard";
import API from "../../../api";
import AddUseCaseModal from "./AddUseCaseModal";
import { getPermissionByCrud, objectDeepClone, throwServerError } from "../../../utils/helper";
import { Link } from "react-router-dom";
import MyReactTooltip from "../../../components/ReactTooltip";
import Searchbar from "../../../components/Searchbar";
import Loading from "../../../components/Loader/Loading";

export default function Usecases(){

    const {firstRoute} = useSelector(state => state.user)
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [displayView, setDisplayView] = useState(VIEW_OPTIONS.list);
    const [data,setData] = useState([]);
    const [tagsModal, showTagsModal] = useState(false);
    const [paging, setPaging] = useState({
      total: 10, offset: 1, limit: 10
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [addUsecaseModal, showAddUsecaseModal] = useState({
      isOpen: false, isEdit: false, data: {}
    })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setTitle("Usesases")
        setNavigation([
          {link: firstRoute, name: 'Dashboard'},
          {name: 'Usecases'}
        ])
        setHasHeader(true)
        getProductsList()
    }, [])

    const getProductsList = (searchVal = '', _page = 1) => {
      setIsLoading(true);
      API.getAllProducts(_page, searchVal)
        .then((response) => {
          setIsLoading(false);
          if (response.data.data) {
            setData(response.data.data);
            setPaging({
              total: response.data?.paging?.total, 
              offset: currentPage, 
              limit: response.data?.paging?.limit, 
            });
          }
          showAddUsecaseModal({
            isOpen: false, isEdit: false, data: {}
          })
        })
        .catch((error) => {
          setIsLoading(false);
          throwServerError(error)
        });

    };

    function handleTagsModal(){
        showTagsModal((tagsModal)=>!tagsModal)
    }

    function onEditClick(item){
      showAddUsecaseModal({isOpen: true, isEdit: true, data: objectDeepClone(item)})
    }

    const navigate = useNavigate()

    /* ACL Start */
    const {currentUser} = useSelector(state => state.user)
    const {permission} = useSelector(state => state.user)

    let _permission = permission.filter(o => o.name === 'products')
    _permission = _permission.length ? (_permission[0].crud || []) : []
    const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
    const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
    const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')

    let _tagPermission = permission.filter(o => o.name === 'tags')
    _tagPermission = _tagPermission.length ? (_tagPermission[0].crud || []) : []
    const getTagListPermission = getPermissionByCrud(currentUser, _tagPermission, 'list')
    const getTagCreatePermission = getPermissionByCrud(currentUser, _tagPermission, 'create')
    const getTagUpdatePermission = getPermissionByCrud(currentUser, _tagPermission, 'update')
    const getTagDeletePermission = getPermissionByCrud(currentUser, _tagPermission, 'delete')

    // if (!getListPermission) navigate(`/admin/profile-page`)
    /* ACL END */

    const [searchVal, setSearchVal] = useState('')

    return <BodyLayout>
        <div className="fadeIn w-100 h-100 br-">
            <div className="surface-875 pl-16px pr-8px py-8px d-flex justify-content-between align-items-center v1-border-bottom-1 h-52px">
              <div>
                <p className="fs-18px fw-600">Manage Use Cases</p>
              </div>
              <div className="d-flex gap-12px">
                {getTagListPermission && <div
                  className="v1-border-1 surface-850 px-20px py-10px btn"
                  onClick={handleTagsModal}
                >
                  <p className="text-300 fw-500 fs-12px">Manage Tags</p>
                </div>}
                <div className="br-8px surface-base p-2px d-flex align-items-center justify-content-between cursor w-100px">
                  <div
                    className={`px-12px py-4px ${
                      displayView === VIEW_OPTIONS.list
                        ? "v1-border-1 br-6px surface-850 activeSVG"
                        : "inactiveSVG"
                    }`}
                    onClick={() => setDisplayView(VIEW_OPTIONS.list)}
                  >
                    <Rows size={24} strokeWidth={0} />
                  </div>

                  <div
                    className={`px-12px py-4px ${
                      displayView === VIEW_OPTIONS.grid
                        ? "v1-border-1 br-6px surface-850 activeSVG"
                        : "inactiveSVG"
                    }`}
                    onClick={() => setDisplayView(VIEW_OPTIONS.grid)}
                  >
                    <SquaresFour size={24} strokeWidth={0} />
                  </div>
                </div>
                {/* Filter btn section hiding for now*/}
                <div style={{width: 220}}>
                  <Searchbar value={searchVal} onChange={(e) => {
                    setSearchVal(e)
                    getProductsList(e, currentPage)
                  }} />
                </div>
                <div className="d-flex align-items-center">
                  {getCreatePermission && <div
                    className="btn v1-btn-primary p-2"
                    onClick={() => showAddUsecaseModal({isOpen: true, isEdit: false, data: null})}
                
                  >
                    <Plus size={14} color="var(--dark-text-300)" />
                    <p className="text-200 ps-2 fs-12px">Add Usecases</p>
                  </div>}
                </div>
              </div>
            </div>
            { isLoading
            ? <Loading />
            : (displayView===VIEW_OPTIONS.list ?
            <div className="p-0">
                <div className="w-100 surface-875 d-flex p-2 px-3 mb-1">
                    <div className="w-30">
                        <p className="text-400 fs-10px">Title</p>
                    </div>
                    <div className="w-40 text-center">
                        <p className="text-400 fs-10px">Tag Name</p>
                    </div>
                    <div className="w-30">
                        
                    </div>
                </div>
                <div className="h-72vh overflow-scroll surface-900">
                  {data.map((item)=>{
                    return <div className="show-on-hover d-flex surface-900 py-12px px-3 v1-border-bottom-2 align-items-center px-3 cursor-pointer hover-surface-850">
                        <div className="w-30">
                            <p className="text-300 fs-14px">{item.title}</p>
                        </div>
                        <div className="w-40 d-flex justify-content-center align-items-center">
                          <div className="mt-2">
                            <TagNames data={item.tags || []} limit={4} modalTitle={"Tags"}/>
                          </div>
                        </div>
                        <div className="hoverItem w-30 d-flex justify-content-end pe-3">
                            <div className="d-flex">
                              {getUpdatePermission && <Link to={`/admin/usecases/${item._id}`}>
                                <div className="btn-md-850 px-8px me-3" data-tip='Edit / View Contents' data-for='viewContentsId'>
                                  <Eye size={14} color="#e4e4e4" />

                                  <MyReactTooltip id="viewContentsId" />
                                </div>
                              </Link>}
                              {getUpdatePermission && <div className="btn-md-850 px-8px"  onClick={() => showAddUsecaseModal({isOpen: true, isEdit: true, data: objectDeepClone(item)})}>
                                  <PencilSimple size={14} />
                              </div>}
                            </div>
                        </div>
                    </div>
                  })}
                </div>

                <div className="surface-900" style={{height: 37}}>
                  {(data && data.length > 0) && (
                    <Pagination
                      className="py-2"
                      pagination={{
                        pageSize: paging.limit,
                        offset: currentPage,
                        total: paging.total,
                      }} cb={(val) => {
                        setCurrentPage(val)
                        getProductsList(searchVal, val)
                      }}
                    />
                  )}
                </div>
            </div> : 
            <div className="surface-900">
              <div className="h-77vh d-flex justify-content-center align-items-center">
                <div className="w-85 h-100">
                  <div className="d-flex flex-wrap align-items-center justify-content-center h-100 overflow-scroll pt-4 gap-32px">
                    {data.map((item,i)=>{
                      return <Gridcard key={i} data={item} getUpdatePermission={getUpdatePermission} 
                        editOnClick={()=>{onEditClick(item)}}/>
                    })}
                  </div>
                </div>
              </div>

              <div className="surface-900" style={{height: 37}}>
                {data && data.length > 0 && (
                  <Pagination
                    className="py-2"
                    pagination={{
                      pageSize: paging.limit,
                      offset: currentPage,
                      total: paging.total,
                    }}
                    cb={(val) => {
                      setCurrentPage(val)
                      getProductsList(searchVal, val)
                    }}
                  />)}
              </div>
            </div>)}

              {
                tagsModal && <TagsModal getTagCreatePermission={getTagCreatePermission}
                  show={tagsModal} getTagUpdatePermission={getTagUpdatePermission}
                  onHide={handleTagsModal} getTagDeletePermission={getTagDeletePermission}
                />
              }

              {
                addUsecaseModal.isOpen && <AddUseCaseModal show={addUsecaseModal.isOpen}
                isEdit={addUsecaseModal.isEdit} data={addUsecaseModal.data} callback={getProductsList}
                getDeletePermission={getDeletePermission}
                onHide={() => showAddUsecaseModal({
                    isOpen: false, isEdit: false, data: {}
                })}/>
              }
                
        </div>
    </BodyLayout>
}