import React, { useRef, useState } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick'
import GetIcon from '../GetIcon'
import GlobalIcons from '../Icons/GlobalIcons'


export default function SelectIcon({val, setVal, disabled, weight}) {
    const [showChildren, setShowChildren] = useState(false)
    const menuRef = useRef(null)

    useOutsideClick(menuRef, () => {
        setShowChildren(false)
    });

    const icons = ['address-book', 'airplane', 'anchor', 'bag', 'bank', 'bell-ringing', 'boat', 'book', 'bookmarks'
        , 'broadcast', 'chart-bar', 'chart-line', 'chart-pie', 'circles-four', 'circles-three', 'cloud', 'cloud-check',
        'list-checks', 'student', 'rocket', 'pencil-simple-line', 'map-trifold', 'check-circle',
        'plugs', 'terminal-window', 'storefront', 'shield-check', 'handshake'
    ]

    const handleSelect = (_icon) => {
        setVal(_icon)
        setShowChildren(false)
    }

    return (
        <div className='position-relative' ref={menuRef}> 
            <div className='d-flex align-items-center'>
                <GetIcon type={val} size={25} weight={weight} />
                <p className='fs-14px text-300 fw-400 ms-2 me-3 text-capitalize'>{val?.replace(/-/g, ' ')}</p>
                {!disabled && <div className='surface-875 v1-border-05 br-4 cursor px-8px br-4px py-4px'
                    onClick={() => setShowChildren(curr => !curr)}>
                    <GlobalIcons type={'up-and-down-arrow'} />
                </div>}
            </div>

            {showChildren && <div className='surface-base w-20vw br-4px overflow-scroll v1-border-1 d-flex flex-wrap align-items-center p-16px gap-20px'
                style={{position: 'absolute', top: 40, zIndex: 9}}>
                {icons.map((eachIcon, i) => <div className='cursor' onClick={() => handleSelect(eachIcon)}>
                    <GetIcon key={i} type={eachIcon} weight={weight} /> 
                </div>)}
            </div>}
        </div>
    )
}
