import React,{useState, useEffect} from 'react'
import {handleOasOfObject} from "@apiwiz/oas/utils";
import Properties from './Properties';

const Scheme = ({schema}) => {

  const [properties, setProperties] = useState("")

  const getPropertiesFn = () => {
    
    if(!schema) return null
    if(schema.properties) return schema.properties;
    if(schema.items) return {1: {items: schema.items, type: 'array', description: schema.description}};
    let of = schema.allOf || schema.oneOf || schema.anyOf;
    let oasOfObject = handleOasOfObject({ofValue: of, property: schema})
    if(oasOfObject) return oasOfObject;
    return schema;
  }

  useEffect(()=> {
    setProperties(getPropertiesFn())
  },[schema])

  return (
    <div className='w-100'>
      <div className='py-3 w-100'>
        {
          
          properties && (
            <Properties
              properties={properties}
              depth={0}
            />
          )
        }
      </div>
    </div>
  )
}

export default Scheme