import React, {useEffect, useState, useRef} from 'react';
import API from "../../../../../api/index"
import CustomDateRange from './CustomDateRange';
import { MagnifyingGlass } from 'phosphor-react';
import { objectDeepClone, formatDate, formatCtsTimeWithoutUTC, throwServerError } from '../../../../../utils/helper';
import Loading from "../../../../../components/Loader/Loading"
import EmptyContainer from '../../../../../components/EmptyContainer';
import Spinner from 'react-bootstrap/Spinner';
import { useElementOnScreen } from '../../../../../components/InfiniteScroll';
import moment from 'moment';

export default function ActivityLog (){
    const sevenDaysBack = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    const thirtyDaysBack = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
    const [selectedDropdownRange, setSelectedDropdownrange] = useState('Today');
    const dropdownList = ['Today','Past 7 days', 'Past 30 days', 'Custom Range'];
    const [activityLogData, setActivityLogData] = useState(null);
    const [currentLogsPage, setCurrentLogsPage] = useState(1)
    const [loading, setLoading] = useState(false);
    const [userLoader, setUserLoader] = useState(false);
    const [infiniteLoader, setInfiniteLoader] = useState(false)
    const [rawLogsData, setRawLogsData] = useState([])
    const logsRef = useRef(null)
    const infiniteTriggerRef = useRef(null)
    const [totalPages, setTotalPages] = useState(1)
    const [userList, setUserList] = useState(null)
    const [filterUsersList, setFilterUsersList] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const [startDateRange, setStartDateRange] = useState(new Date())
    const [endDateRange, setEndDateRange] = useState(new Date());

    const [containerRef, isVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0,
    })

    const getActivityLogData = (params, infinite = false) => {
        if(!infinite){
            if (params.end){
                params.end = moment(new Date(params.end)).subtract(1, 'days')
            }
            setLoading(true)
            API.getActivityLogs(params)
            .then((res)=>{
                setRawLogsData([...res.data.data])
                setTotalPages(Math.ceil(res.data.paging.total / res.data.paging.limit))
                transformData([...res.data.data] || [])
        
            }).catch(err=>{
                throwServerError(err)
            })
        }else{
            if (params.end){
                params.end = moment(new Date(params.end)).subtract(1, 'days')
            }
            API.getActivityLogs(params)
            .then((res)=>{
                setRawLogsData(prev => [...prev, ...res.data.data])
                setTotalPages(Math.ceil(res.data.paging.total / res.data.paging.limit))
                transformData([...rawLogsData , ...res.data.data] || [])
        
            }).catch(err=>{
                throwServerError(err)
            })
        }
       
    }

    const transformData = (_data) => {
        const groupedData = {};
        _data.forEach(each => {
            const dateLabel = formatDate(each?.createdAt, false);
            if (!groupedData[dateLabel]) {
                groupedData[dateLabel] = [];
            }
            
            groupedData[dateLabel].push(each); 
        }) 
        
        setActivityLogData(objectDeepClone(groupedData))
        setInfiniteLoader(false)
        setLoading(false)
    }

    useEffect(()=>{
        setLoading(true)
        setUserLoader(true)
        getActivityLogData({start: new Date(), end: new Date(), currentPage: 1 })

        API.getUsers()
        .then(res => {
            setUserList(res.data.data);
            setUserLoader(false)
        }).catch(err => throwServerError(err))
    },[])

    useEffect(()=>{
        if(isVisible && currentLogsPage <= totalPages){
            setInfiniteLoader(true)
            setCurrentLogsPage(prev => prev + 1);
            let apiParams = {start: startDateRange, currentPage: currentLogsPage + 1, usersList: filterUsersList }
            if(startDateRange !== endDateRange){
                apiParams.end = endDateRange
            }
            getActivityLogData(apiParams, true)
        }
    },[isVisible])

    return (
        <div className='fadeIn w-100 h-100 d-flex surface-950 gap-8px align-items-start'>
            <div className={`w-75 surface-900 br-10px h-100`}>
                <div className={`px-16px py-8px surface-875 d-flex align-items-center br-tl-4px br-tr-4px v1-border-bottom-1`}>
                <CustomDateRange setRawLogsData={setRawLogsData} setLoading={setLoading} setFilterUsersList={setFilterUsersList} getActivityLogData={getActivityLogData} setStartDate={setStartDateRange} setEndDate={setEndDateRange} startDate={startDateRange} endDate={endDateRange} selectedDropdownRange={selectedDropdownRange} setSelectedDropdownrange={setSelectedDropdownrange}  sevenDaysBack={sevenDaysBack} thirtyDaysBack={thirtyDaysBack} dropdownList={dropdownList}/>
                </div>
                <div className=' w-100 px-12px py-8px surface-875 d-flex gap-75px'>
                    <p className='fw-500 fs-10px text-400 '>Time</p>                    
                    <p className='fw-500 fs-10px text-400 '>Days</p>
                    <div className='col-md-9'>
                        <p className='fw-500 fs-10px text-400'>Logs</p>
                    </div>
                </div>

                <div className='overflow-scroll-y noscrollbar w-100 p-8px' ref={logsRef} style={{height: `calc(100% - 104px)`}}>
                {loading ? 
                    <div className='w-100' style={{height: 'calc(100vh - 130px)'}}>
                        <Loading />
                    </div> :
                    <div className='mt-40px ml-8px'>
                    {activityLogData && Object.keys(activityLogData).length > 0 ? 
                        Object.keys(activityLogData).map((eachKey, i) => {
                        const eachValue = activityLogData[eachKey] 
                        return(
                            <div className='logSubWrapper pt-20px'>
                                <div className='p-8px surface-850 br-200px w-fit eachDate'>
                                    <p className='fw-400 fs-12px text-100'>
                                        {eachKey}
                                    </p>
                                </div>
                                {eachValue.map((each, i) => <div key={each._id} className='eachEvent mb-12px'>
                                    <div className='centerSvg'>
                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="4" cy="4" r="3.625" fill="#242529" stroke="#7A7A7A" stroke-width="0.75"/>
                                        </svg>
                                    </div>
                                    <p className='eventTime text-200 fs-12px fw-400'>
                                        {formatCtsTimeWithoutUTC(each.createdAt)}
                                    </p>

                                    <div className='log-card surface-875 w-100 h-78px d-flex justify-content-between align-items-end'>
                                        <div className='d-flex w-max align-items-center gap-8px'>
                                            <div className={`fancy-select ${each.operation}`}> {each.operation}</div>
                                            <div className='d-flex flex-column w-max'>
                                                <p className='fs-12px w-100 d-flex'>URL- <span className='text-primary'> {each.requestURI.length > 70 ? each.requestURI.substring(0,70).concat('...') : each.requestURI}</span></p>
                                                <p className='fs-12px'>Response Code - {each.statusCode}</p> 
                                            </div>
                                        </div>
                                        <p className='fs-10px' style={{color: '#A2A5B7'}}>{each.username}</p>
                                    </div>
                                </div>)}
                                <div ref={containerRef} style={{backgroundColor: "transparent", width: "2px", height: "2px"}}>
                                </div>
                            </div>)
                        }) : 
                    <div className='w-100 h-50vh d-flex align-items-center justify-content-center'>
                        <EmptyContainer message='No Activity Logs Found' />
                    </div>}
                    {infiniteLoader && <div className='d-flex justify-content-center'> <Spinner animation="border" size='sm' /> </div>}
                    </div>}
                </div>

            </div>
            
            <div className={`w-25 h-100 overflow-scroll-y noscrollbar surface-900 br-10px d-flex flex-column align-items-start p-16px`}>
                <div className='d-flex w-100 justify-content-between'>
                    <p className='fs-14px fw-500'>Filter by User</p>
                   { !userLoader && userList && userList.length > 0 && <p onClick={()=>{
                    setFilterUsersList([])
                    setRawLogsData([])
                    getActivityLogData({start: startDateRange, end: endDateRange, currentPage: 1, usersList: [] })
                   }} className='fs-12px fw-400 cursor'>Clear All</p>}
                </div>

                <div style={{minHeight: "33px"}} className='position-relative v1-border-1pt5 mt-17px w-100 br-4px'>
                    <input type='text' onChange={(e)=>setSearchValue(e.target.value)} className='surface-900 h-30px input-box position-absolute w-89'/>
                    <MagnifyingGlass className='position-absolute pos-searchicon mx-8px my-8px' size={14} />
                </div>
                <div className='mt-20px'>
                {userLoader ? 
                <div className='w-100' style={{height: 'calc(100vh - 130px)'}}>
                    <Loading />
                </div> : userList && userList.length > 0 ? 
                userList.filter(user => searchValue!=="" ? (user.fullName.toLowerCase().includes(searchValue.toLowerCase()) || user.username.toLowerCase().includes(searchValue.toLowerCase())) : true)
                .map(item => {
                    return (        
                    <div className='d-flex align-items-center gap-8px mb-16px'>
                    <input checked={filterUsersList.includes(item._id)} onChange={(e)=>{
                        if(e.target.checked){
                            setLoading(true)
                            setFilterUsersList(prev => [...prev, item._id]);
                            setRawLogsData([])
                            getActivityLogData({start: startDateRange, end: endDateRange, currentPage: 1, usersList: [...filterUsersList, item._id] })
                        }else{
                            setLoading(true)
                            setRawLogsData([])
                            setFilterUsersList(prev => prev.filter(id => id !== item._id))
                            getActivityLogData({start: startDateRange, end: endDateRange, currentPage: 1, usersList: filterUsersList.filter(id => id !== item._id) })
                        }
                    }} className='w-17px h-17px' id={item._id} type="checkbox" />
                    <div>
                    <label htmlFor={item._id} className='fw-500 fs-14px'>{item.fullName}</label>
                        <p style={{color: '#A2A5B7'}} className='fs-10px fw-400 '>{item.username}</p>
                    </div>
                </div>)

                }) :    <div className='w-100 h-50vh d-flex align-items-center justify-content-center'>
                <EmptyContainer message='No Users Found Yet' />
            </div>}
            </div>

        

            </div>
        </div>
    );
};

