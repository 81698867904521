import React from 'react'
import defaultImage from './verification-success.png'

export default function ResetSuccess() {
    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            <div className='loginContainer h-100'>
                <div className='mb-20px d-flex align-items-center justify-content-center'>
                    <div className='p-18px'>
                        <img src={defaultImage} height={200} width={300}
                            alt='Valid token' />
                    </div>
                </div>

                <div className='mb-24px'>
                    <h3 className='titleText v2'>
                        Reset Password Successful
                    </h3>
                    <p className='descText v2'>
                        Go on Home Page to Login
                    </p>
                </div>

                <a href='/login' type='button' className='border-0 gradientBtnInAuth mb-16px'>
                    Login
                </a>
            </div>
        </div>
    )
}
