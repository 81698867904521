import purpleUpvoteDefault from "../../../../assets/Forum/icons/purple-upvote-default.svg";
import purpleUpvoteFilled from "../../../../assets/Forum/icons/purple-upvote-filled.svg";
import purpleUpvoteHover from "../../../../assets/Forum/icons/purple-upvote-hover.svg";
import purpleReplyDefault from "../../../../assets/Forum/icons/purple-reply-default.svg";
import purpleReplyHover from "../../../../assets/Forum/icons/purple-reply-hovered.svg";
import coffeeReplyDefault from "../../../../assets/Forum/icons/coffee-reply-default.svg";
import coffeeReplyHovered from "../../../../assets/Forum/icons/coffee-reply-hovered.svg";
import coffeeUpvoteDefault from "../../../../assets/Forum/icons/coffee-upvote-default.svg";
import coffeeUpvoteFilled from "../../../../assets/Forum/icons/coffee-upvote-filled.svg";
import coffeeUpvoteHover from "../../../../assets/Forum/icons/coffee-upvote-hover.svg";
import lemonadeReplyDefault from "../../../../assets/Forum/icons/lemonade-reply-default.svg";
import lemonadeReplyHover from "../../../../assets/Forum/icons/lemonade-reply-hovered.svg";
import lemonadeUpvoteDefault from "../../../../assets/Forum/icons/lemonade-upvote-default.svg";
import lemonadeUpvoteFilled from "../../../../assets/Forum/icons/lemonade-upvote-filled.svg";
import lemonadeUpvoteHover from "../../../../assets/Forum/icons/lemonade-upvote-hover.svg";
import lagoonReplyDefault from "../../../../assets/Forum/icons/lagoon-reply-default.svg";
import lagoonReplyHovered from "../../../../assets/Forum/icons/lagoon-reply-hovered.svg";
import lagoonUpvoteDefault from "../../../../assets/Forum/icons/lagoon-upvote-default.svg";
import lagoonUpvoteFilled from "../../../../assets/Forum/icons/lagoon-upvote-filled.svg";
import lagoonUpvoteHover from "../../../../assets/Forum/icons/lagoon-upvote-hover.svg";
import winterReplyDefault from "../../../../assets/Forum/icons/winter-reply-default.svg";
import winterReplyHover from "../../../../assets/Forum/icons/winter-reply-hover.svg";
import winterUpvoteDefault from "../../../../assets/Forum/icons/winter-upvote-default.svg";
import winterUpvoteFilled from "../../../../assets/Forum/icons/winter-upvote-filled.svg";
import winterUpvoteHover from "../../../../assets/Forum/icons/winter-upvote-hovered.svg";

import CrimsonReplyDefault from "../../../../assets/Forum/icons/crimson-reply-default.svg";
import CrimsonReplyHover from "../../../../assets/Forum/icons/crimson-reply-hover.svg";
import CrimsonUpvoteDefault from "../../../../assets/Forum/icons/crimson-upvote-default.svg";
import CrimsonUpvoteFilled from "../../../../assets/Forum/icons/crimson-upvote-filled.svg";
import CrimsonUpvoteHover from "../../../../assets/Forum/icons/crimson-upvote-hovered.svg";

import neutralReplyDefault from "../../../../assets/Forum/icons/neutral-reply-default.svg";
import neutralReplyHover from "../../../../assets/Forum/icons/neutral-reply-hover.svg";
import neutralUpvoteDefault from "../../../../assets/Forum/icons/neutral-upvote-default.svg";
import neutralUpvoteFilled from "../../../../assets/Forum/icons/neutral-upvote-filled.svg";
import neutralUpvoteHover from "../../../../assets/Forum/icons/neutral-upvote-hovered.svg";

export const PostIconConfig = {
  "Purple Magic": {
    upvote: {
      default: purpleUpvoteDefault,
      filled: purpleUpvoteFilled,
      hover: purpleUpvoteHover,
    },
    reply: {
      default: purpleReplyDefault,
      hover: purpleReplyHover,
    },
  },
  Coffee: {
    reply: {
      default: coffeeReplyDefault,
      hover: coffeeReplyHovered,
    },
    upvote: {
      default: coffeeUpvoteDefault,
      filled: coffeeUpvoteFilled,
      hover: coffeeUpvoteHover,
    },
  },
  Lemonade: {
    reply: {
      default: lemonadeReplyDefault,
      hover: lemonadeReplyHover,
    },
    upvote: {
      default: lemonadeUpvoteDefault,
      filled: lemonadeUpvoteFilled,
      hover: lemonadeUpvoteHover,
    },
  },
  Lagoon: {
    reply: {
      default: lagoonReplyDefault,
      hover: lagoonReplyHovered,
    },
    upvote: {
      default: lagoonUpvoteDefault,
      filled: lagoonUpvoteFilled,
      hover: lagoonUpvoteHover,
    },
  },
  Winter: {
    reply: {
      default: winterReplyDefault,
      hover: winterReplyHover,
    },
    upvote: {
      default: winterUpvoteDefault,
      filled: winterUpvoteFilled,
      hover: winterUpvoteHover,
    },
  },
  Crimson: {
    reply: {
      default: CrimsonReplyDefault,
      hover: CrimsonReplyHover,
    },
    upvote: {
      default: CrimsonUpvoteDefault,
      filled: CrimsonUpvoteFilled,
      hover: CrimsonUpvoteHover,
    },
  },
  Neutral: {
    reply: {
      default: neutralReplyDefault,
      hover: neutralReplyHover,
    },
    upvote: {
      default: neutralUpvoteDefault,
      filled: neutralUpvoteFilled,
      hover: neutralUpvoteHover,
    },
  }
};
