import React from 'react'
import GlobalIcons from '../../../../components/Icons/GlobalIcons'
import DefaultImage from '../assets/default-sdk.png'

export default function GridCard({data, onClick, getUpdatePermission, handleDownload}) {
    return (
        <div className='v1-border-1 surface-875 boxShadow1 sdkCard m-16px'>
            <div className='w-43'>
                <img src={data.sdkIcon ? data.sdkIcon : DefaultImage} alt='' />
            </div>

            <div className='w-55 pl-16px'>
                <div className='d-flex align-items-center justify-content-between mb-16px'>
                    <div className='violet-background violet-11 px-8px py-4px br-12px fs-12px fw-500 w-fit'>
                        {data.categoryName || data.category || ''}
                    </div>

                    {getUpdatePermission && <div className='btn' onClick={onClick}>
                        <GlobalIcons type={'pencil-v2'} />
                    </div>}
                </div>

                <h3 className='text-200 fs-20px fw-700 text-truncate'>
                    {data.title || ''}
                </h3>

                <div className='text-truncate-3 fs-14px fw-400 text-200 mt-8px mb-20px'>
                    {data.description || ''}
                </div>

                <div className='d-flex align-items-center'>
                    <a href={data.overviewUrl} rel={'noreferrer'}
                        target={'_blank'} className='px-20px py-11px surface-850 v1-border-1 cursor'>
                        <p className='text-300 fs-12px fw-500'>Overview</p>
                    </a>

                    <div onClick={handleDownload} className='px-20px py-11px surface-850 v1-border-1 cursor ms-2'>
                        <p className='text-300 fs-12px fw-500'>Download</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
