import { useEffect, useState } from "react";
import ThemeCenteredModal from "../../Modals/ThemeCenteredModal";
import { CaretDown, Gear } from "phosphor-react";
import { objectDeepClone } from "../../../utils/helper";
import BootstrapDropdown from "../../BootstrapDropdown";

export default function ManageVariablesModal(props) {
  const [open, setOpen] = useState(false);
  const [variables, setVariables] = useState({});
  useEffect(() => {
    if (open) {
      setVariables(props.variables || {});
    }
  }, [open]);

  const onValueChanged = ({ prop, value }) => {
    let _variables = objectDeepClone(variables);
    _variables[prop].__value = value;
    setVariables(_variables);
  };
  return (
    <>
      <Gear
        onClick={() => {
          setOpen(true);
        }}
        color="var(--primary-color)"
        className="cursor"
        weight="bold"
      />
      <ThemeCenteredModal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        size="md"
        className="vertical-centered-modal"
        bodyClassName={`d-flex flex-column gap-8px h-100`}
      >
        <div className="h-100">
          <div className="h-42px d-flex py-10px pr-6px pl-10px gap-4px align-items-center justify-content-between border-bottom-theme-primary-subtle">
            <div className="fs-16px fw-500 lh-28px text-theme-primary">
              Manage Variables
            </div>
            <div className="d-flex ">
              <div
                onClick={() => {
                  setOpen(false);
                }}
                className="w-85px d-flex align-items-center justify-content-center text-theme-content-subtle fs-12px cursor"
              >
                Cancel
              </div>
              <div
                onClick={() => {
                    if(props.onSubmit) props.onSubmit(variables)
                    setOpen(false)
                }}
                className="py-4px px-8px br-4px bg-theme-primary text-theme-base fs-12px cursor"
              >
                Submit
              </div>
            </div>
          </div>
          <div className="h--42px p-12px d-flex flex-column gap-12px">
            <div className="d-flex flex-column gap-4px">
              <div className="fs-12px fw-500 text-theme-primary-subtle">Url</div>
              <div className="fs-14px">
                {props.url || ""}
              </div>
            </div>
            {Object.keys(variables).map((key) => {
              let variable = variables[key];
              let enums = variable.enum || [];
              if (enums.length) {
                return (
                  <div className="d-flex flex-column gap-6px">
                    <div className="fs-12px fw-500 text-theme-primary-subtle">{key}</div>
                    <BootstrapDropdown
                      className="theme-specific settings-input-value manage-variables"
                      options={(enums || []).map((el) => ({
                        label: el,
                        value: el,
                        onSelect: () => {
                          onValueChanged({ prop: key, value: el });
                        },
                      }))}
                      value={variable.__value || variable.default || ""}
                    >
                      <div className="w-100 h-100 cursor px-8px py-6px br-4px d-flex align-items-center justify-content-between bg-theme-primary-subtle border-theme-primary-subtle">
                        <div className="w--24px text-break fs-14px fw-500">
                          {variable.__value || variable.default || ""}
                        </div>
                        <div>
                          <CaretDown
                            size={16}
                            weight="bold"
                            color="var(--primary-color)"
                          />
                        </div>
                      </div>
                    </BootstrapDropdown>
                  </div>
                );
              }

              return (
                <div className="d-flex flex-column gap-6px">
                    <div className="fs-12px fw-500 text-theme-primary-subtle">{key}</div>
                    <div className="w-100 h-100 cursor px-8px py-6px br-4px d-flex align-items-center justify-content-between bg-theme-primary-subtle border-theme-primary-subtle">
                        <input onChange={(e) => {
                            onValueChanged({prop: key, value: e.target.value})
                        }} className="w-100 fs-14px input-transparent text-content-subtle" value={variable.__value || variable.default} />
                    </div>
                  </div>
              )
            })}
          </div>
        </div>
      </ThemeCenteredModal>
    </>
  );
}
