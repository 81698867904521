import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import { Tab, Tabs } from "react-bootstrap";
import { BodyLayout } from '../../../layout/Base';
import GlobalIcons from '../../../components/Icons/GlobalIcons';
import Select from 'react-select';
import RightSideModal from '../../../components/Modals/RightSideModal';
import CenteredModal from '../../../components/Modals/CenteredModal';
import Searchbar from '../../../components/Searchbar';
import Pagination from '../../../components/Pagination';
import EmptyContainer from '../../../components/EmptyContainer';

export default function Dashboard() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [activeTab, setActiveTab] = useState('Part1')
    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [searchString, setSearchString] = useState('')

    useEffect(() => {
        setTitle("Dashboard")
        setHasHeader(true)
        setNavigation([
            {name: 'Path1', link : '/path1'},
            {name: 'Path2', link : '/path2'},
            {name: 'Path3', link : '/path3'},
            {name: 'Path4', link : '/path4'},
        ])
    }, [])
    
    return (
        <BodyLayout>
            <div className="fadeIn tabs-container w-100 custom-tab-container h-93">
                <Tabs activeKey={activeTab} id="resources-tab"
                    onSelect={(key) => { setActiveTab(key) }}>

                    <Tab tabClassName="tabs-btn-2" className='p-3'
                        eventKey={'Part1'} title={"Basic Components"}>
                        
                        <div className='d-flex align-items-start'>
                            <div>
                                <p className='text-400 px-3 py-2 v1-border-1'>text-400</p>
                                <p className='text-300 px-3 py-2 v1-border-1 mt-1'>text-300</p>
                                <p className='text-200 px-3 py-2 v1-border-1 mt-1'>text-200</p>
                                <p className='text-100 px-3 py-2 v1-border-1 mt-1'>text-100</p>
                                <p className='text-50 px-3 py-2 v1-border-1 mt-1'>text-50</p>
                                <p className='text-danger px-3 py-2 v1-border-1 mt-1'>text-danger</p>
                                <p className='text-success px-3 py-2 v1-border-1 mt-1'>text-success</p>
                                <p className='text-warning px-3 py-2 v1-border-1 mt-1'>text-warning</p>
                                <p className='text-primary px-3 py-2 v1-border-1 mt-1'>text-primary</p>
                                <p className='text-theme-primary px-3 py-2 v1-border-1 mt-1'>text-theme-primary</p>
                                <p className='text-theme-secondary px-3 py-2 v1-border-1 mt-1'>text-theme-secondary</p>
                            </div>

                            <div className='ps-5'>
                                <div className='surface-base px-3 py-2 w-10vw v1-border-1'>
                                    <p>surface-base</p>
                                </div>
                                <div className='surface-600 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-600</p>
                                </div>
                                <div className='surface-700 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-700</p>
                                </div>
                                <div className='surface-800 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-800</p>
                                </div>
                                <div className='surface-850 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-850</p>
                                </div>
                                <div className='surface-875 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-875</p>
                                </div>
                                <div className='surface-900 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-900</p>
                                </div>
                                <div className='surface-950 px-3 py-2 w-10vw v1-border-1 mt-1'>
                                    <p>surface-950</p>
                                </div>
                                <div className='bg-primary px-3 py-2 w-10vw br-4px mt-2'>
                                    <p>bg-primary</p>
                                </div>
                                <div className='bg-danger px-3 py-2 w-10vw br-4px mt-1'>
                                    <p>bg-danger</p>
                                </div>
                                <div className='bg-success px-3 py-2 w-10vw br-4px mt-1'>
                                    <p>bg-success</p>
                                </div>
                            </div>

                            <div className='ps-5 w-30vw'>
                                <div className='mb-3'>
                                    <p>v1-form-control</p>
                                    <input type={'text'} className='form-control v1-form-control' />
                                </div>

                                <div className='mb-3'>
                                    <p>v1-form-textarea</p>
                                    <textarea rows={4} className='form-control v1-form-textarea' />
                                </div>

                                <div className='mb-4'>
                                    <p>form-check | form-check-view | form-uncheck</p>
                                    <GlobalIcons type={'form-check'} />
                                    <GlobalIcons type={'form-check-view'} />
                                    <GlobalIcons type={'form-uncheck'} />
                                </div>

                                <div className='mb-4'>
                                    <p>v1select</p>
                                    <Select classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                                        className="v1select mt-1"
                                        placeholder="Select Resource"
                                        options={[
                                            {label: 'One', value: 'One'}, {label: 'Two', value: 'Two'},
                                            {label: 'Three', value: 'Three'}, {label: 'Four', value: 'Four'},
                                        ]}
                                    />
                                </div>

                                <div className='mb-3 d-flex align-items-center'>
                                    <div className='pe-4'>
                                        <p>btn-md-v1</p>
                                        <div className='btn v1-btn-primary'>
                                            <GlobalIcons type={'folder-open'} />
                                            <p className='text-200 ps-2'>Add User</p>
                                        </div>
                                    </div>

                                    <div className='pe-4'>
                                        <p>btn-md-v1</p>
                                        <div className='btn-md-v1'>
                                            <GlobalIcons type={'folder-open'} />
                                            <p className='text-200 ps-2'>Browse</p>
                                        </div>
                                    </div>

                                    <div>
                                        <p>btn-sm-v1</p>
                                        <div className='btn-sm-v1'>
                                            <GlobalIcons type={'pencil'} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='btn btn-success' onClick={() => setShowModal(true)}>
                                        Toggle Right Modal
                                    </div>

                                    <div className='btn btn-info ms-1' onClick={() => setShowModal2(true)}>
                                        Toggle Center Modal
                                    </div>
                                </div>
                            </div>

                            <div className='ps-5 w-25vw'>
                                <p className='mb-1'>Search here ...</p>
                                <Searchbar value={searchString} setValue={setSearchString} />

                                <div className='my-5'>
                                    <Pagination pagination={{
                                        pageSize: 10,
                                        offset: 2,
                                        total: 30,
                                    }} cb={(val) => console.log(val)} />
                                </div>

                                <div className='mt-4 d-flex align-items-center justify-content-center'>
                                    <EmptyContainer />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab tabClassName="tabs-btn-2" 
                        eventKey={'Part2'} title={"Components"}>
                        <p>Part2</p>
                    </Tab>
                    <Tab tabClassName="tabs-btn-2" 
                        eventKey={'Part3'} title={"Components"}>
                        <p>Part3</p>
                    </Tab>
                </Tabs>
            </div>

            {showModal && <RightSideModal show={showModal} onHide={() => setShowModal(false)} />}
            {showModal2 && <CenteredModal show={showModal2} onHide={() => setShowModal2(false)} />}
        </BodyLayout>
    )
}
