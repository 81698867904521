import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useEffect, useState } from 'react'
import { CalendarPlus, CaretDown } from "phosphor-react";

const MonethYearPickerComponent = forwardRef((props, ref) => {
    const { selected, value, onClick } = props
    return (
        <>
            <div onClick={onClick} className="h-100 w-max-content surface-consumer-850 br-10px p-16px d-flex align-items-center justify-content-center cursor border-theme-base-200 gap-10px">
                <CalendarPlus size={12} />
                <div className="text-consumer-200 fs-14px fw-400 text-nowrap">{value ? <>{moment(value).format("MMMM") + " " + moment(value).format("yyyy")}</> : ''}</div>
                <CaretDown size={16} />
            </div>
        </>
    );
});


const MonthYearPicker = (props) => {
    const { className, handleCallback, selectedDate } = props;

    const [date, setDate] = useState(selectedDate)

    useEffect(() => {
        setDate(selectedDate)
    }, [selectedDate])
    
    const handleOnChange = (_date) => {
        handleCallback(_date)
    }
    return (
        <div className={`react-month-year-picker ${className}`}>
            <DatePicker customInput={props.formattedChildren || <MonethYearPickerComponent />} 
                selected={date} 
                maxDate={new Date()}
                onChange={(_date) => handleOnChange(_date)} showMonthYearPicker />
        </div>
    );
};

export default MonthYearPicker;
