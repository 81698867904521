import React, { useEffect, useState } from 'react'
import CustomNavbar from '../../../Dashboards/components/CustomNavbar'
import { Link } from 'react-router-dom'
import { CalendarPlus, CurrencyCircleDollar, Info } from 'phosphor-react'
import MonetizeTrend from '../components/MonetizeTrend'
import MonetizationIcons from '../../../../components/Icons/MonetizationIcons'
import CaretDown from '../../../../components/Catalog/components/CaretDown'
import InvoiceModal from './InvoiceModal'
import API from '../../../../api'
import { useSelector } from 'react-redux'
import { NumberFormatter, defaultCurrencyFn, formatNumber, throwServerError } from '../../../../utils/helper'
import moment from 'moment'
import { objectDeepClone } from '@apiwiz/oas/services'
import ReportIssueModal from '../ReportIssueModal'
import { concernTexts } from '../../../../service/constants'
import ReportIssue from '../ReportIssue'
import MyReactTooltip from '../../../../components/ReactTooltip'

export default function Invoices() {
    const [invoiceModal, setInvoiceModal] = useState({
        isOpen: false, data: {}
    })
    const [showModal, setShowModal] = useState({
        isOpen: false, id: '', tempData: {}
    })
    const {currentUser} = useSelector(state => state.user)
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        getData()
    }, [])
    
    const getData = () => {
        API.getAllInvoices({
            developerEmail: currentUser.email
        }).then(res => {
            console.log('res.data', res.data)
            setInvoices(res.data?.data || [])
        }).catch(err => {
            throwServerError(err)
        })
    }

    const getPackageCount = (_data) => {
        let packageCount = 0

        Object.keys(_data.invoiceData || {})?.forEach((_) => {
            const eachVal = _data.invoiceData[_]
            packageCount += (eachVal.length || 0)
        })

        return packageCount
    }

    const getTotalAmount = (_data) => {
        // console.log('_data', _data)
        // let total = 0

        // Object.keys(_data.invoiceData || {})?.forEach((_) => {
        //     const eachVal = _data.invoiceData[_]
        //     eachVal.forEach((each) => {
        //         total += parseInt(each.totalAmount || 0)
        //     })
        // })

        // return total
        return _data.totalExpenditure - (_data.disputedAmount || 0)
    }

    return (
        <div className='w-100vw bg-currentBackground h-100vh'>
            <CustomNavbar />

            <div className='mt-80px'>
                <div className='container'>
                    <div className='d-flex align-items-center mb-3'>
                        <Link className='text-primary-color fw-500 fs-14px' to='/my-account'>My Account</Link>
                        <p className='mx-2 text-consumer-400'>/</p>
                        <p className='text-primary-color fw-500 fs-14px'>My Invoices</p>
                    </div>
                    
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <p className='fs-32px text-content-color fw-600'>Invoices</p>  

                        <ReportIssue concernText={concernTexts.BILLING} 
                            allInvoices={invoices} />
                    </div>

                    <div className="d-none d-flex align-items-stretch justify-content-between gap-24px mt-24px">
                        <div className="consumer-surface-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                            <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                <MonetizationIcons type={"totalExprenditure"} />
                            </div>
                            <div className="d-flex flex-column gap-4px">
                                <div className="text-gray-subtext fs-14px fw-400">Total Usage</div>
                                <div className="d-flex align-items-center gap-10px">
                                    <div className="fs-18px fw-600 text-theme-content-color">$15,2342</div>
                                </div>
                            </div>
                        </div>
                        <div className="consumer-surface-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                            <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                <MonetizationIcons type={"invoices"} />
                            </div>
                            <div className="d-flex flex-column gap-4px">
                                <div className="text-gray-subtext fs-14px fw-400">Invoices</div>
                                <div className="d-flex align-items-center gap-10px">
                                    <div className="fs-18px fw-600 text-theme-content-color">{invoices.length}</div>
                                </div>
                            </div>
                        </div>
                        <div className="consumer-surface-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                            <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                <CurrencyCircleDollar color="#0081F1" size={16} />
                            </div>
                            <div className="d-flex flex-column gap-4px">
                                <div className="text-gray-subtext fs-14px fw-400">Outstanding Amount</div>
                                <div className="d-flex align-items-center gap-10px">
                                    <div className="fs-18px fw-600 text-theme-content-color">$15,2342</div>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-dnone' style={{width: 400}}></div>
                        {/* <div className="w-max-content br-10px p-16px d-flex align-items-center cursor border-theme-base-200 gap-10px">
                            <CalendarPlus size={12} />
                            <div className="consumer-text-200 fs-14px fw-400 text-nowrap">Last 1 Month</div>
                            <CaretDown size={16} />
                        </div> */}
                    </div>

                    <div style={{border: '1px solid var(--base-100)'}} className='mt-5 br-tl-3px br-tr-3px'>
                        <div className='mx-0 row p-12px surface-consumer-900 br-tl-3px br-tr-3px'
                            style={{borderBottom: '1px solid var(--base-100)'}}>
                            <div className='col-4 hideOnMobile'>
                                <p className='fs-12px fw-500 grey-subtext'>Month</p>
                            </div>
                            <div className='col-2 hideOnDesktop'>
                                <p className='fs-12px fw-500 grey-subtext'>Month</p>
                            </div>
                            <div className='col-2'>
                                <p className='fs-12px fw-500 grey-subtext text-center'>Apps</p>
                            </div>
                            <div className='col-2'>
                                <p className='fs-12px fw-500 grey-subtext text-center'>Packages</p>
                            </div>
                            <div className='col-2 hideOnMobile'>
                                <p className='fs-12px fw-500 grey-subtext text-center'>Invoice Amount</p>
                            </div>
                            <div className='col-4 hideOnDesktop'>
                                <p className='fs-12px fw-500 grey-subtext text-center'>Invoice Amount</p>
                            </div>
                            <div className='col-2'>
                            </div>
                        </div>

                        {invoices.map((eachInvoice, i) => <div className='mt-1px surface-consumer-850 mx-0 row px-12px py-14px' 
                            key={eachInvoice.id}>
                                <div className='col-4 hideOnMobile'>
                                    <p className='text-content fs-14px fw-500'>{moment().month(eachInvoice.month - 1).format("MMMM")} {eachInvoice.year}</p>
                                </div>
                                <div className='col-2 pe-0 hideOnDesktop'>
                                    <p className='text-content fs-14px fw-500'>{moment().month(eachInvoice.month - 1).format("MMM")} {moment().year(eachInvoice.year).format("YY")}</p>
                                </div>
                                <div className='col-2'>
                                    <p className='grey-subtext fs-14px fw-500 ps-1 text-center'>
                                        {Object.keys(eachInvoice.invoiceData || {}).length}
                                    </p>
                                </div>
                                <div className='col-2'>
                                    <p className='grey-subtext fs-14px fw-500 text-center'>
                                        {getPackageCount(eachInvoice)}
                                    </p>
                                </div>
                                <div className='col-2 hideOnMobile'>
                                    <p className='grey-subtext fs-14px fw-500 justify-content-center dfaic gap-8px'>
                                        {defaultCurrencyFn(currentUser.billing?.billingCurrency)} {NumberFormatter(getTotalAmount(eachInvoice))}

                                        {eachInvoice.disputedAmount && <div className='cursor' style={{marginTop: -2}}
                                            data-for='viewInfo11' data-tip={`${defaultCurrencyFn(currentUser.billing?.billingCurrency)}${eachInvoice.disputedAmount} adjusted from the original amount ${defaultCurrencyFn(currentUser.billing?.billingCurrency)}${eachInvoice.totalExpenditure} after reconciliation`}>
                                            <Info size={16} />

                                            <MyReactTooltip id='viewInfo11' />
                                        </div>}
                                    </p>
                                </div>
                                <div className='col-4 hideOnDesktop'>
                                    <p className='grey-subtext fs-14px fw-500 text-center dfaic gap-8px'>
                                        {defaultCurrencyFn(currentUser.billing?.billingCurrency)} {NumberFormatter(getTotalAmount(eachInvoice))}

                                        {eachInvoice.disputedAmount && <div className='cursor'
                                            data-for='viewInfo11' data-tip={`${defaultCurrencyFn(currentUser.billing?.billingCurrency)}${eachInvoice.disputedAmount} adjusted from the original amount ${defaultCurrencyFn(currentUser.billing?.billingCurrency)}${eachInvoice.totalExpenditure} after reconciliation`}>
                                            <Info size={16} />

                                            <MyReactTooltip id='viewInfo11' />
                                        </div>}
                                    </p>
                                </div>
                                {/* <div className='col-2'>
                                    <p className='grey-subtext fs-14px fw-500 text-center'>12 Apr 2023</p>
                                </div>
                                <div className='col-1'>
                                    <p className='grey-subtext fs-14px fw-500 text-center'>Pending</p>
                                </div> */}
                                <div className='col-2'>
                                    <p className='fw-600 fs-14px text-primary-color text-center cursor'
                                        onClick={() => setInvoiceModal({isOpen: true, data: objectDeepClone(eachInvoice)})}>View</p>
                                </div>
                        </div>)}
                    </div>

                </div>
            </div>

            {invoiceModal.isOpen && <InvoiceModal show={invoiceModal.isOpen} data={invoiceModal.data}
                handleIssue={(val) => {
                    setShowModal({isOpen: true, id: val, tempData: invoiceModal.data})
                    setInvoiceModal({isOpen: false, data: {}})
                }} allInvoices={objectDeepClone(invoices)}
                onHide={() => setInvoiceModal({isOpen: false, data: {}})} /> }
            
            {showModal.isOpen && <ReportIssueModal concernText={concernTexts.BILLING} 
                show={showModal.isOpen} allInvoices={objectDeepClone(invoices)}
                onHide={() => {
                    setInvoiceModal({isOpen: true, data: showModal.tempData })
                    setShowModal({isOpen: false, id: '', tempData: {}})
                }} data={{invoiceId: showModal.id}} />}
        </div>
    )
}
