import { Browsers } from 'phosphor-react'
import React from 'react'
import CustomPrimaryTab from '../../../Dashboards/components/CustomPrimaryTab'
import ImageCard from './ImageCard'

export default function RenderPrimaryTab({ data, isEdit, setData }) {

    return (
        <div className='h-100 pb-3'>
            <div className='surface-base br-6px p-16px w-100 mb-3'>
                <div className='d-block mx-auto' style={{width: 600}}>
                    <CustomPrimaryTab data={data} readOnly />
                </div>
            </div>

            <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Primary Section Content</p>
                </div>
            </div>
            <div className='surface-base p-16px h-50 overflow-scroll noscrollbar'>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Heading <span className='text-danger'>*</span> </p>
                        <p className='text-300 fs-12px fw-400'>{data.heading?.length || 0}/32</p>
                    </div>
                    <input type={'text'} max={20} value={data.heading}
                        className='from-control v1-form-control mt-1 w-100' 
                        disabled={!isEdit} onChange={(e) => {
                            let _str = e.target.value 
                            if (_str.length > 32) return
                            setData({...data, heading: _str})
                        }} />
                </div>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Description <span className='text-danger'>*</span> </p>
                        <p className='text-300 fs-12px fw-400'>{data.description?.length || 0}/80</p>
                    </div>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.description}
                        disabled={!isEdit} onChange={(e) => {
                            let _str = e.target.value 
                            if (_str.length > 80) return
                            setData({...data, description: _str})
                        }} />
                </div>

                <div className='surface-850 p-16px mb-3'>
                    <div className='mb-3'>
                        <div className='dfaic justify-content-between'>
                            <p className='text-300 fs-12px fw-400'>Primary CTA Text <span className='text-danger'>*</span></p>
                            <p className='text-300 fs-12px fw-400'>{data.primaryCTA?.label?.length || 0}/24</p>
                        </div>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100'
                            value={data.primaryCTA?.label}  disabled={!isEdit} 
                            onChange={(e) => {
                                const _str = e.target.value
                                if (_str.length > 24) return
                                setData({...data, primaryCTA: {...(data.primaryCTA || {}), label: _str}})
                            }} />
                    </div>

                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Primary CTA URL <span className='text-danger'>*</span></p>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100' 
                            value={data.primaryCTA?.url} disabled={!isEdit} 
                            onChange={(e) => setData({...data, primaryCTA: {...(data.primaryCTA || {}), url: e.target.value}})} />
                    </div>
                </div>

                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400 mb-1'>Image <span className='text-danger'>*</span> </p>
                    <ImageCard imgUrl={data.imageUrl} name='heroLogo' width={'100%'} isEdit={isEdit}
                        setUrl={(url) => setData({...data, imageUrl: url})} />
                </div>
            </div>
        </div>
    )
}
