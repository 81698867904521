import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { BodyLayout } from '../../../layout/Base'
import { CaretRight, DotsSixVertical, Notebook, Plus, Trash } from "phosphor-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import API from '../../../api';
import { throwServerError, objectDeepClone,formatCtsTime, formatCtsDate, validateHeaderMenu, confirm, validateSocialData, validateContentsData, getPermissionByCrud } from '../../../utils/helper';
import RenderHeader from './components/RenderHeader';
import { useDispatch } from 'react-redux';
import { resetCurrentLayout, setContents, setCurrentLayoutObject, setGroups, setOverallContents, setRoles } from '../../../app/Features/Layout/LayoutSlice';
import Loading from '../../../components/Loader/Loading';
import { useSelector } from 'react-redux';
import { getToast } from '../../../components/Toast';
import RenderFooter from './components/RenderFooter';
import LayoutModal from './components/LayoutModal';
import RenderHero1 from './components/RenderHero1';
import RenderHero2 from './components/RenderHero2';
import RenderLogo1 from './components/RenderLogo1';
import RenderCard1 from './components/RenderCard1';
import RenderCard2 from './components/RenderCard2';
import RenderImage1 from './components/RenderImage1';
import RenderImage2 from './components/RenderImage2';
import RenderImage3 from './components/RenderImage3';
import RenderCard3 from './components/RenderCard3';
import RenderHero3 from './components/RenderHero3';


export default function ManageSection() {
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [dragStart, setDragStart] = useState(false)
    const [loading, setLoading] = useState(false)
    const [subLoading, setSubLoading] = useState(false)
    const [data, setData] = useState({})
    const [selectedTab, setSelectedTab] = useState({id: 'header'})
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const { currentLayoutObject } = useSelector(state => state.layout)
    const [layoutModal, setLayoutModal] = useState(false)
    const { firstRoute } = useSelector((state) => state.user);

    useEffect(() => {
        setTitle("Sections")
        setHasHeader(true)
        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Manage Sections'}
        ])

        getData()
        dispatch(resetCurrentLayout())
    }, [])

    const handleDrop = (droppedItem) => {
        setDragStart(false)
        if (!droppedItem.destination) return;

        var updatedList = objectDeepClone(currentLayoutObject.contents);
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        dispatch(setOverallContents(objectDeepClone(updatedList)))

        let {data: filteredData} = validateContentsData(updatedList, false)
        setData({...data, contents: objectDeepClone(filteredData)})
        API.putLayoutDetails({contents: filteredData})
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Sequence updated successfully'
            })
        }).catch(err => {
            throwServerError(err)
        })
    }

    const getPartners = () => {
        API.listPartners()
        .then(res => {
            dispatch(setGroups(res.data.data || []))
        }).catch(err => {
            throwServerError(err)
        })
    }

    const getRoles = () => {
        API.getAllRoles()
        .then(res => {
            dispatch(setRoles(res.data.data || []))
        }).catch(err => {
            throwServerError(err)
        })
    }

    const getData = () => {
        setLoading(true)
        API.getLayoutDetails()
        .then(res => {
            setLoading(false)
            setSelectedTab({id: 'header'})
            let _data = objectDeepClone(res.data?.layout || {})

            if (!_data.hasOwnProperty('headerMenu')) _data['headerMenu'] = []
            if (!_data.hasOwnProperty('social')) _data['social'] = []
            if (!_data.hasOwnProperty('footerMenu')) _data['footerMenu'] = []
            if (!_data.hasOwnProperty('contents')) _data['contents'] = []

            _data.headerMenu = _data.headerMenu.map(o => ({
                ...o, isEdit: false, submenu: o.submenu?.map(oo => ({...oo, isEdit: false}))
            }))
            _data.footerMenu = _data.footerMenu.map(o => ({
                ...o, isEdit: false, submenu: o.submenu?.map(oo => ({...oo, isEdit: false}))
            }))
            _data.social = _data.social.map(o => ({
                ...o, isEdit: false
            }))
            _data.contents = _data.contents.map(o => ({
                ...o, payload: {...o.payload, isEdit: false}
            }))

            setData(objectDeepClone(_data))
            dispatch(setCurrentLayoutObject(objectDeepClone(_data)))
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })

        getPartners()
        getRoles()
    }

    const handleSave = () => {
        let _currentLayoutObject = objectDeepClone(currentLayoutObject)

        let {error: headerError, data: filteredData} = validateHeaderMenu(_currentLayoutObject.headerMenu || [])
        let {error: socialError, data: filteredSocialData} = validateSocialData(_currentLayoutObject.social || [])
        let {error: footerError, data: filteredFooterData} = validateHeaderMenu((_currentLayoutObject.footerMenu || []), false)
        let {error: contentsError, data: filteredContentData} = validateContentsData((_currentLayoutObject.contents || []), false)

        let json = {
            headerMenu: [],
            contents: [],
            footerMenu: [],
            social: []
        }

        // header
        if (headerError) {
            getToast({statusType: 'ERROR', message: headerError})
            return
        }
        else {
            json.headerMenu = objectDeepClone(filteredData)
        }

        // social data
        if (socialError) {
            getToast({statusType: 'ERROR', message: socialError}) 
            return
        } else {
            json.social = objectDeepClone(filteredSocialData)
        }

        // footer
        if (footerError){
            getToast({statusType: 'ERROR', message: footerError}) 
            return
        } else {
            json.footerMenu = objectDeepClone(filteredFooterData)
        }

        // contents
        console.log('contentsError', contentsError)
        if (contentsError){
            getToast({statusType: 'ERROR', message: contentsError}) 
            return
        } else {
            json.contents = objectDeepClone(filteredContentData)
        }

        API.putLayoutDetails(json)
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Layout updated successfully'
            })
            getData()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const handleCancel = () => {
        if (!confirm('Are you sure to continue? any new changes will be lost?')) return

        setIsEdit(false);
        setSubLoading(true)
        dispatch(resetCurrentLayout())

        setTimeout(() => {
            setSubLoading(false)
        }, 200);
    }

    const handleDelete = (_id) => {
        if (!confirm("Are you sure to delete this section? ")) return

        let _currentLayoutObject = objectDeepClone(currentLayoutObject.contents || [])
        _currentLayoutObject.splice(_id, 1)

        API.putLayoutDetails({contents: objectDeepClone(_currentLayoutObject)})
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Layout deleted successfully'
            })
            getData()
        }).catch(err => {
            throwServerError(err)
        })
    }

    /* ACL Start */
    const {currentUser} = useSelector(state => state.user)
    const {permission} = useSelector(state => state.user)

    let _permission = permission.filter(o => o.name === 'manage-site-global')
    _permission = _permission.length ? (_permission[0].crud || []) : []
    const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
    /* ACL END */

    return (
        <BodyLayout>
            <div className='fadeIn d-flex align-items-center w-100 h-100'>
                {loading 
                ? <Loading />
                : <DragDropContext onDragEnd={handleDrop}
                    onDragStart={() => setDragStart(true)}>
                    <div className='w-35 h-100'>
                        <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between br-tl-4px br-tr-4px'>
                            <div className='d-flex align-items-center'>
                                <Notebook size={18} color="#E4E4E4" />
                                <p className='ms-2 fs-16px fw-500 text-200'>Sections</p>
                            </div>

                            {isEdit ? <div style={{height: 41}}></div>
                            : <div className='px-20px d-flex align-items-center py-11px v1-border-2 surface-850 br-4px btn'
                                onClick={() => setLayoutModal(true)}>
                                <Plus size={14} color="#E4E4E4" />
                                <p className='fs-12px fw-500 text-300 ms-1'>Add Section</p>
                            </div>}
                        </div>
                        <div className='surface-900 p-8px overflow-scroll noscrollbar' 
                            style={{height: 'calc(100% - 61px)'}}>
                            <div className={`${selectedTab.id === 'header' && 'v1-border-blue mb-4px' } surface-base px-20px py-16px d-flex align-items-center justify-content-between cursor hover-surface-875 mb-2px`}
                                onClick={() => setSelectedTab({id: 'header'})}>
                                <p className='text-300 fs-14px fw-600'>Header</p>
                                
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <CaretRight size={14} color='#E4E4E4' />
                                    </div>
                                </div>
                            </div>
                            <Droppable droppableId="list-container">
                                {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {data.contents?.map((each, i) => 
                                    <Draggable key={i} draggableId={`section${i}`} index={i}
                                        isDragDisabled={isEdit}>
                                        {(provided) => (
                                        <div className={`surface-base px-20px py-16px d-flex align-items-center justify-content-between cursor hover-surface-875 mb-2px ${selectedTab.title === each.title && 'v1-border-blue'}`}
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            onClick={() => {
                                                setSelectedTab({id: each.id, title: each.title, index: i})
                                                setSubLoading(true)

                                                setTimeout(() => {
                                                    setSubLoading(false)
                                                }, 500);
                                            }}>
                                            <div className='d-flex align-items-center'>
                                                {!isEdit && <DotsSixVertical size={16} color='#E4E4E4' />}
                                                <p className='text-300 fs-14px fw-600 mx-2 text-truncate'>{each.title || ''}</p>
                                            </div>
                                            
                                            <div className='d-flex align-items-center'>
                                                {!isEdit && <div className='me-3' onClick={() => handleDelete(i)}>
                                                    <Trash size={16} color='#E4E4E4' />
                                                </div>}
                                                <div>
                                                    <CaretRight size={14} color='#E4E4E4' />
                                                </div>
                                            </div>
                                        </div>)}
                                    </Draggable>)}
                                </div>
                                )}
                            </Droppable>

                            <div className={`surface-base px-20px py-16px d-flex align-items-center justify-content-between cursor hover-surface-875 ${selectedTab.id === 'footer' && 'v1-border-blue mt-4px' }`}
                                onClick={() => setSelectedTab({id: 'footer'})}>
                                <p className='text-300 fs-14px fw-600'>Footer</p>
                                
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <CaretRight size={14} color='#E4E4E4' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ms-2 w-64 h-100'>
                        <div className='surface-875 d-flex align-items-center justify-content-between px-12px py-10px br-tl-4px br-tr-4px'>
                            <div className='d-flex align-items-center'>
                                <Notebook size={18} color="#E4E4E4" />
                                <p className='ms-2 fs-16px fw-500 text-200'>Preview</p>
                            </div>

                            { isEdit
                            ? <div className='d-flex align-items-center'>
                                <p className='fw-500 fs-12px text-300 cursor' onClick={handleCancel}> Cancel</p>

                                <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn ms-3'
                                    onClick={handleSave}>
                                    <p className='fs-12px fw-500 text-300'>Save</p>
                                </div>
                            </div>
                            : <div className='px-20px d-flex align-items-center py-8px v1-border-2 surface-850 br-4px btn'
                                onClick={() => setIsEdit(true)}>
                                <p className='fs-12px fw-500 text-300'>Edit</p>
                            </div>}
                        </div>

                        <div className='surface-900 p-24px overflow-scroll noscrollbar' style={{height: 'calc(100% - 55px)'}}>
                            {
                                subLoading 
                                    ? <Loading />
                                : (selectedTab.id === 'header'
                                    ? <RenderHeader isEdit={isEdit} setIsEdit={setIsEdit} 
                                        setLoading={setSubLoading} callback={getData} />
                                : selectedTab.id === 'footer'
                                    ? <RenderFooter isEdit={isEdit} setIsEdit={setIsEdit} 
                                        setLoading={setSubLoading} callback={getData} />
                                : selectedTab.id === 'hero-section-1' 
                                    ? <RenderHero1 isEdit={isEdit} setIsEdit={setIsEdit}
                                        setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'hero-section-2' 
                                    ? <RenderHero2 isEdit={isEdit} setIsEdit={setIsEdit}
                                        setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'hero-section-3' 
                                    ? <RenderHero3 isEdit={isEdit} setIsEdit={setIsEdit}
                                        setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'logos-section-1'
                                    ? <RenderLogo1 isEdit={isEdit} setIsEdit={setIsEdit}
                                        setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'cards-section-1'
                                    ? <RenderCard1 isEdit={isEdit} setIsEdit={setIsEdit}
                                        setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'cards-section-2'
                                    ? <RenderCard2 isEdit={isEdit} setIsEdit={setIsEdit}
                                    setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'cards-section-3'
                                    ? <RenderCard3 isEdit={isEdit} setIsEdit={setIsEdit}
                                    setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'images-section-1'
                                    ? <RenderImage1 isEdit={isEdit} setIsEdit={setIsEdit}
                                        setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'images-section-2'
                                    ? <RenderImage2 isEdit={isEdit} setIsEdit={setIsEdit}
                                    setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : selectedTab.id === 'images-section-3'
                                    ? <RenderImage3 isEdit={isEdit} setIsEdit={setIsEdit}
                                    setLoading={setSubLoading} sectionIndex={selectedTab.index} />
                                : null)
                            }
                        </div>
                    </div>
                </DragDropContext>}
            </div>

            {layoutModal && <LayoutModal show={layoutModal} callback={getData}
                onHide={() => setLayoutModal(false)} />}
        </BodyLayout>
    )
}
