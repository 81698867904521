import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import API from "../../../api";
import { getToast } from "../../Toast";

export const CodeGenerationModal = (props) => {
  const [target, setTarget] = useState("");
  const [language, setlanguage] = useState("");
  const [loading, setloading] = useState(false);

  const { oas, currentSelectedSwagger, metadata,operationPlainList,selectedOperation, } = useSelector((state) => state.swagger);

  console.log("currentt",currentSelectedSwagger)
  console.log("current1",operationPlainList)
  console.log("current2",selectedOperation)

  const [availableClientLanguages, setAvailableClientLanguages] = useState([]);
  const [availableServerLanguages, setAvailableServerLanguages] = useState([]);

  useEffect(() => {
    API.getAvailableClientsAndServers({ version: oas })
      .then((res) => {
          res = res.data.data
        const clientLang = res?.clients || [];
        const serverLang = res?.servers || [];

        setAvailableClientLanguages(clientLang);
        setAvailableServerLanguages(serverLang);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleClose = () => {
    props.onHide();
    setTarget("");
    setlanguage("");
  };

    const submitHandler = () => {
        if (!language){
            getToast({
                statusType: 'ERROR', message: 'Please select a language'
            })
            return
        }
        setloading(true);

        API.generateSwagger(
        {
            framework: language,
            target: `${target.toLowerCase()}s`,
            data: {
                swaggerName: metadata.swaggerId,
                revision: metadata.revision,
            },
        }, metadata.oas === 'OAS 3.0' ? '3.0' : '2.0'
        )
        .then((res) => {
            console.log('res', res)
            API.fileDownload({ url: res.link, filename: `${language}_code.zip` })
            .then((res) => {
                setloading(false);
                props.onHide()
            })
            .catch((err) => {
                setloading(false);
            });
        })
        .catch((err) => {
            setloading(false);
        });
    };

  return (
    <Modal
      {...props}
      size="lg me-0 mt-0 mb-0"
      contentClassName='br-0px homeModalForLghtMode border-0'
      className="v1--styles"
      aria-labelledby="contained-modal-title-vcenter overflow-hidden noscrollbar"
      centered
    >
      <Modal.Header className="w-100 py-8 pl-8 pr-8 surface-850 important br-0">
        <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
          <p className="mb-0 text-content-color fw-500 fs-16px">Code Generator</p>
            
            <div className="btn-overlay px-2 cursor" onClick={handleClose}>
              <MdClose size={14} />
            </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 58px)" }}
        className="br-0 position-relative"
      >
        <div className="h-100 p-12">
          <div
            style={{ height: "calc(100vh - 60px)"}}
            class="p-4 pb-0 swagger-code-generator-modal-body"
          >
            <div>
              <div class="d-flex">
                <div class="swagger-code-generator-step-icon">
                  {!target ? (
                    <span>1</span>
                  ) : (
                    <span>
                      <i>
                        <svg
                          fill="white"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                        </svg>
                      </i>
                    </span>
                  )}
                </div>
                <h4
                  class="cursor-pointer swagger-code-generator-step-heading"
                  onClick={() => {
                    setTarget("");
                    setlanguage("");
                  }}
                >
                  Target
                </h4>
              </div>
              <div
                class="d-flex border-start py-5 my-2"
                style={{ marginLeft: "0.8rem" }}
              >
                {!target && (
                  <div class="ps-5  swagger-code-generator-button__container">
                    <Button
                      className="me-3 btn btn-light btn-sm"
                      onClick={() => setTarget("CLIENT")}
                    >
                      CLIENT
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => setTarget("SERVER")}
                      className="mr-5 btn btn-light btn-sm"
                    >
                      SERVER
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div class="d-flex">
                <div
                  class={`swagger-code-generator-step-icon ${
                    target ? "" : "bg-secondary"
                  }`}
                >
                  <span>2</span>
                </div>
                <h4
                  className={`cursor-pointer swagger-code-generator-step-heading ${
                    target ? "" : "inactive"
                  }`}
                >
                  Language / Framework: {language}
                </h4>
              </div>
              {target && (
                <>
                  <div class="mt-3 ms-5 d-flex flex-wrap">
                    {target === "CLIENT" &&
                      availableClientLanguages.length > 0 &&
                      availableClientLanguages.map((_language) => (
                        <Form.Check
                          type={"radio"}
                          id={`language-client-${_language}`}
                          className="me-4 cursor-pointer"
                          label={`${_language}`}
                          onChange={() => {
                            setlanguage(_language);
                          }}
                          checked={language === _language}
                        />
                      ))}
                    {target === "SERVER" &&
                      availableServerLanguages.length > 0 &&
                      availableServerLanguages.map((_language) => (
                        <Form.Check
                          type={"radio"}
                          id={`language-server-${_language}`}
                          label={`${_language}`}
                          className="me-4 cursor-pointer"
                          onChange={() => {
                            setlanguage(_language);
                          }}
                          checked={language === _language}
                        />
                      ))}
                  </div>
                  <div>
                    <Button
                      size="sm"
                      className="mt-4 py-2 px-4 border-0"
                      variant="primary"
                      style={{background: `var(--primary-color)`}}
                      onClick={submitHandler}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
