import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const SORT_KEYS = {
    compliant: 'compliant',
    threats: 'threats',
    compliantPct: 'compliantPct' 
}

class ColumnChildren {
    constructor(){
        this.className = '';
        this.dataKey = '';
    }

    setClassName(val){
        this.className = val;
        return this;
    }

    setDataKey(val){
        this.dataKey = val;
        return this;
    }
}

class Column {
    constructor(){
        this.id=uuidv4();
        this.headerName = '';
        this.className = '';
        this.headerClassName = '';
        this.headerDivClassName='';
        this.colClassName='';
        this.dataKey = '';
        this.children = [];
        this.sortKey='';
        this.sortOrder='';
        this.emptyCol = false;
    }

    setHeaderName(val){
        this.headerName = val;
        return this;
    }

    setClassName(val){
        this.className = val;
        return this;
    }

    setHeaderClassName(val){
        this.headerClassName = val;
        return this;
    }

    setHeaderDivClassName(val){
        this.headerDivClassName = val;
        return this;
    }

    setColClassName(val) {
        this.colClassName = val;
        return this;
    }

    setDataKey(val) {
        this.dataKey = val;
        return this;
    }

    setChildren(val){
        this.children = val;
        return this;
    }

    setSortKey(val){
        this.sortKey = val;
        return this;
    }

    setSortOrder(val){
        this.sortOrder = val;
        return this;
    }

    createAsEmptyCol(val){
        this.emptyCol = val;
        return this;
    }


}

export const getGuidesTable = (data) => {
    let cols = [
        new Column()
        .setHeaderName('Guide Name')
        .setDataKey('details')
        .setClassName('p-12px h-100')
        .setHeaderDivClassName('pl-12px')
        .setHeaderClassName("w-50")
        .setChildren([
            new ColumnChildren().setDataKey('name').setClassName('fs-14px text-300 fw-500'),
            new ColumnChildren().setDataKey('description').setClassName('fs-12px lh-16px text-400'),
        ]),
        new Column()
        .setHeaderName('Category Name')
        .setDataKey('category')
        .setHeaderDivClassName('pl-12px')
        .setHeaderClassName("w-25")
        .setClassName('fs-14px p-12px h-100'),
        new Column().setHeaderName('Created By')
        .setDataKey('created')
        .setHeaderClassName("w-25")
        .setHeaderDivClassName('pl-12px')
        .setClassName('p-12px h-100')
        .setChildren([
            new ColumnChildren().setDataKey('by')
            .setClassName('fs-14px text-300 fw-500'),
            new ColumnChildren().setDataKey('datetime')
            .setClassName('fs-12px lh-16px text-400')
        ]),
        new Column()
        .createAsEmptyCol(true)
        .setClassName('h-100')

    ]

    let rows = data.map((x) => {
        let el = {...x};
        el.details = {
            name: x.name,
            description: x.description
        }
        el.created = {
            by: x.owner.name,
            datetime: moment(x.createdAt).format("hh:mm MM/DD/YYYY")
        }
        el._rowClassName = "cursor"
        
        return el;
    })

    return {cols, rows}
}