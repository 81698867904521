import React from 'react'
import MeteringElement from './MeteringElement'
import { objectDeepClone } from '@apiwiz/oas/services'

export default function MeteringGroup({data }) {
    return (
        <div>
            <div className={` ${data._orData?.length > 0 && ''}`} id={data._id}>
                <div className='bg-base-100 p-12px br-4px custom-bordr-1 w-fit' 
                    style={{borderColor: 'var(--secondary-100)'}}>

                    {data._data?._type 
                    ? (<div>
                        {data._data._type === 'group' && 
                            <MeteringGroup data={objectDeepClone(data._data)} />}

                        {data._data._type === 'element' && 
                            <MeteringElement data={objectDeepClone(data._data)}  />}
                    </div>) 
                    : null}
                </div>

                {data._orData?.map((each, i) => {
                    if (each._type === 'group') {
                        return (<>
                            <div className='m-2'>
                                <p className='fw-500 fs-12px text-content-color'>OR</p>
                            </div>
                            <MeteringGroup data={each} isChild isLast={i === data._orData.length - 1} parentData={data} />
                        </>) 
                    } else if (each._type === 'element') {
                        return (<>
                            <div className='m-2'>
                                <p className='fw-500 fs-12px text-content-color'>OR</p>
                            </div>
                            <MeteringElement data={each} isChild isLast={i === data._orData.length - 1} parentData={data} />
                        </>) 
                    }
                })}
            </div>

            {data._andData?._id && <div>
                <div style={{marginLeft: 40}}>
                    <div className='my-2 ms-3'>
                        <p className='fw-500 fs-12px text-content-color'>AND</p>
                    </div>
                </div>

                {(data._andData?._type === 'group') && <MeteringGroup data={data._andData} />}
                {(data._andData?._type === 'element') && <MeteringElement data={data._andData} />}
                </div>}
        </div>
    )
}
