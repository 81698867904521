import UserIcons from "../../../../components/Icons/UserIcons"
import { useEffect, useState } from "react"
import EmptyContainer from "../../../../components/EmptyContainer"
import { useSelector } from "react-redux"
import { toPascalCase } from "../../../../utils/helper";

export default function AttriibuteTable({handleDeleteUser}){

    const { partnersList: partners} = useSelector((state) => state.user);
    const [partnersList,setPartnersList] = useState([])
   
    function getFirstLetterOfString(text){
        if (!text) return ''
        return text.charAt(0)
    }

    useEffect(()=>{
        setPartnersList(partners)
    },partners)

    

    return <>
        <div className='surface-875 mx-0 d-flex p-2 mt-2 ps-3'>
            <div className="w-26 fs-12px fw-500 text-400 d-flex">
                <p>Partner Name</p>
            </div>
            <div className="w-37 fs-12px fw-500 text-400 d-flex justify-content-center">
                <p>Display Name</p>
            </div>
            <div className="w-37 fs-12px fw-500 text-400 d-flex justify-content-center">
                <p>Is Default</p>
            </div>
        </div>

        <div className='overflow-scroll noscrollbar h-70vh'>
            {partners.length > 0 ? 
                partners.map((item,index) => (
                    <div className='surface-900 mx-0 d-flex ps-3 pt-15px pb-15px userCard--tableRow v1-border-bottom-1 hover-surface-850' key={item.userId}>
                        <div className='w-26 my-auto'>
                            <div className='d-flex'>
                                <div className='my-auto'>
                                    <div className='userCircle surface-850'>
                                        <p className='fw-400 fs-14px text-300 text-uppercase'>
                                        {getFirstLetterOfString(item.partnerName)}
                                        </p>
                                    </div>
                                </div>
                                <div className='pl-8px d-flex align-items-center'>
                                    <div className='me-2'>
                                        <div className="d-flex">
                                            <p className='text-200 fs-14px fw-500 text-truncate'>
                                                {item.partnerName}
                                            </p>
                                        </div>
                                        <p className='text-400 fs-12px fw-400 text-truncate'>
                                            {item.partnerDescription}
                                        </p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className='w-37 d-flex align-items-center justify-content-center'>
                            <div className="">
                                <p className="text-200 fs-14px">{item.partnerDisplayName}</p>
                            </div>
                        </div>
                        <div className='w-37 d-flex mr-9px my-auto align-items-center justify-content-center'>
                           {item.isDefault?<UserIcons type="defaultTick"/>:<></>}
                        </div>
                </div>
                ))
            
            : (
                <div className="h-100 d-flex justify-content-center align-items-center">
                <EmptyContainer/>
                </div>
            ) 
        
        }
        </div>
    </>
}