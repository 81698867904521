import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loader/Loading";
import { objectDeepClone } from "@apiwiz/oas/services";
import Select from "react-select";
import { getRatePlanValue, throwServerError } from "../../../utils/helper";
import { Plus, Trash } from "phosphor-react";
import { getToast } from "../../../components/Toast";
import API from "../../../api";
import monetizationAPI from "../../../api/monetization";
import { connect, useSelector } from "react-redux";
import { GATEWAYS, PRODUCTS_MAPPER_DISPLAY_NAME, PRODUCTS_MAPPER_KEY } from "../../../service/constants";

export default function BuyNowModal(props) {
  const [disableSave, setDisableSave] = useState(false);
  const [data, setData] = useState({});
  const [organization, setOrganisation] = useState(null);
  const [products, setProducts] = useState(null);
  const [ratePlans, setRatePlans] = useState(null);
  const [ratePlanOptions, setRatePlanOptions] = useState([]);
  const [selectedRatePlan, setSelectedRatePlan] = useState(null);
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [productBundleOptions, setProductBundleOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productBundleList, setProductBundleList] = useState(null);
  const [chosenProductBundle, setChosenProductBundle] = useState(null);
  const [productsList, setProductsList] = useState(null);
  const [chosenProduct, setChosenProduct] = useState(null);
  const [loading,setLoading] = useState(false);

  const { currentUser: userData } = useSelector((state) => state.user);

  const [buyStep, setBuyStep] = useState(1);

  const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

  useEffect(() => {
    let _data = objectDeepClone(props.data || {});

    setData(objectDeepClone(_data));

    if (props.isUpdate) {
      setAppName(_data?.name);
      setDescription(_data?.description);
      setAttributes(_data?.attributes);
    }

    if (_data?.ratePlans) {
      const _portalVisibleRateplans = _data?.ratePlans?.filter(
        (ratePlan) => ratePlan.visibleToPortals
      );
      setRatePlans(_portalVisibleRateplans);
    }
  }, []);

  useEffect(() => {
    getProductBundles();
    getProducts();
    if (props.isUpdate) {
      setBuyStep(2);
    }
    // getProducts();
  }, [props.isCreate, props.isUpdate]);

  useEffect(() => {
    if (ratePlans && ratePlans.length) {
      const options = ratePlans.reduce(
        (acc, curr) => [...acc, { value: curr, label: curr }],
        []
      );

      setRatePlanOptions(options);
    }
  }, [ratePlans]);

  /**
   *
   * Product Bundles for all orgs and products
   */
  const getProductBundles = () => {
    monetizationAPI
      .getAllProductBundles()
      .then((response) => {
        const { data } = response.data;
        console.log("bundle data", data);
        setProductBundleList(data);

        const options = data.map((bundle) => ({
          label: bundle.productBundle?.name,
          value: bundle,
        }));

        setProductBundleOptions(options);
      })
      .catch((err) => {
        console.log("getProductBundles err: ", err);
      });
  };

  const getProducts = () => {
    monetizationAPI
      .getAllProducts()
      .then((res) => {
        const { data } = res.data;
        const _data = (data || []).map((product) => ({
          ...product,
          isProduct: true,
        }));

        setProductsList(_data);

        const options = _data.map((product) => ({
          label: product?.name ? product.name : product.productName,
          value: product,
          isProduct: product.isProduct,
        }));

        setProductOptions(options);
      })
      .catch((err) => {
        console.log("getProducts err: ", err);
      });
  };

  const handleChange = (_val, _id, type) => {
    let _attributes = objectDeepClone(attributes);

    _attributes[_id][type] = _val;
    setAttributes(objectDeepClone(_attributes));
  };

  const handleDelete = (_id) => {
    let _attributes = objectDeepClone(attributes);
    _attributes.splice(_id, 1);
    setAttributes(objectDeepClone(_attributes));
  };

  const handleAdd = () => {
    let _attributes = objectDeepClone(attributes);
    _attributes.push({
      name: "",
      value: "",
    });
    setAttributes(objectDeepClone(_attributes));
  };

  const handleSubmit = () => {
    // different flow for Kong and Azure
    if (props.isCreate) {
      if (chosenProduct) {
        if (!appName || !description) {
          getToast({
            statusType: "ERROR",
            message: "Please fill all the required details",
          });
          return;
        }

        if (chosenProduct.type === "kong") {
          setLoading(true)
          setDisableSave(true);
          monetizationAPI
            .createKongApp({
              name: appName,
              description,
              productsDto: {
                ...chosenProduct.services[0],
              },
              userEmail: userData.email,
            })
            .then((res) => {
              setDisableSave(false);
              setLoading(false)
              getToast({
                statusType: "SUCCESS",
                message: "App added successfully",
              });
              props.onHide();
              props.onCreateCallback();
            })
            .catch((err) => {
              setDisableSave(false);
              setLoading(false)
              getToast({
                statusType: "ERROR",
                message: "Unable to create app",
              });
            });
            return;
        } 
        
        if (chosenProduct.type === "azure") {
          const azureConnectors = userData.azureConnectors;
          if (!azureConnectors.length) {
            throwServerError(
              "Selected Product is not associated to this User!"
            );
            return;
          }
          setLoading(true)

          const azureUserId = azureConnectors.find(
            (connector) => connector.connectorId === chosenProduct.connectorId
          )?.azureUserId;

          monetizationAPI
            .createAzureApp({
              name: appName,
              productName: chosenProduct.name,
              ownerId: azureUserId,
              connectorId: chosenProduct.connectorId,
              description,
            })
            .then((res) => {
              setDisableSave(false);
              setLoading(false)
              getToast({
                statusType: "SUCCESS",
                message: "App added successfully",
              });
              props.onHide();
              props.onCreateCallback();
            })
            .catch((err) => {
              setDisableSave(false);
              setLoading(false)
              getToast({
                statusType: "ERROR",
                message: "Unable to create app",
              });
            });
            return;
        }

        if(chosenProduct.type === "apigee")
        {
          let _data = {
            apiProducts: [chosenProduct.name],
            attributes: attributes,
            description: description,
            name: appName,
            keyExpiresIn: "-1",
            organization: chosenProduct.Organization
          };
          setLoading(true)
          API.addBuildApps(_data)
          .then(res => {
            setDisableSave(false);
            setLoading(false)
            getToast({
              statusType: "SUCCESS",
              message: "App added successfully",
            });
            props.onHide();
            props.onCreateCallback();
          })
          .catch(err => {
            setDisableSave(false);
            setLoading(false)
            getToast({
              statusType: "ERROR",
              message: "Unable to create app",
            });
          })
          return;
        }
      } 

      if(chosenProduct.type === GATEWAYS.NGINX)
      {
        let _data = {
          description: description,
          name: appName,
          connectorId: chosenProduct.connectorId,
          productId: chosenProduct[PRODUCTS_MAPPER_KEY.NGINX],
          productName: chosenProduct[PRODUCTS_MAPPER_DISPLAY_NAME.NGINX]
        };
        setLoading(true)
        API.createNginxApp(_data)
        .then(res => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
          props.onCreateCallback();
        })
        .catch(err => {
          setDisableSave(false);
          setLoading(false)
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        })
        return;
      }

      if(chosenProduct.type === AWS_GATEWAY)
      {
        let _data = {
          description: description,
          name: appName,
          connectorId: chosenProduct.connectorId,
          usagePlanId: chosenProduct.usagePlanId,
          productName: chosenProduct[PRODUCTS_MAPPER_DISPLAY_NAME.AWS]
        };
        setLoading(true)
        API.createAwsApp(_data)
        .then(res => {
          setDisableSave(false);
          setLoading(false);
          getToast({
            statusType: "SUCCESS",
            message: "App added successfully",
          });
          props.onHide();
          props.onCreateCallback();
        })
        .catch(err => {
          setDisableSave(false);
          setLoading(false)
          getToast({
            statusType: "ERROR",
            message: "Unable to create app",
          });
        })
        return;
      }
      
      if (chosenProductBundle) {
        if (
          (!selectedRatePlan) ||
          !appName ||
          !description
        ) {
          getToast({
            statusType: "ERROR",
            message: "Please fill all the required details",
          });
          return;
        }
        setLoading(true)
        let json = {
          apiProducts: objectDeepClone(
            chosenProductBundle.productBundle?.products
          ),
          attributes,
          description,
          name: appName,
          keyExpiresIn: "-1",
          organization: chosenProductBundle.productBundle.organization,
          productBundle: props.isCreate
            ? chosenProductBundle.productBundle
            : objectDeepClone(data.productBundle),
          ratePlan: objectDeepClone(selectedRatePlan),
        };

        setDisableSave(true);
        API.addBuildApps(json)
          .then((res) => {
            getToast({
              statusType: "SUCCESS",
              message: "App added successfully",
            });

            const { data } = res.data;
            const { appId, name } = data;

            const params = {
              appId,
              appName: name,
              ratePlan: selectedRatePlan,
              productBundle: chosenProductBundle,
            };

            API.purchaseRatePlanUsingWallet(params)
              .then((res) => {
                getToast({
                  statusType: "SUCCESS",
                  message: 'Purchase Completed Successfully"',
                });
                setDisableSave(false);
                setLoading(false)
                props.onHide();
                props.onCreateCallback();
              })
              .catch((err) => {
                getToast({
                  statusType: "ERROR",
                  message: "Unable to Purchase App",
                });
                setDisableSave(false);
                setLoading(false)
                props.onHide();
              });
          })
          .catch((err) => {
            setLoading(false)
            setDisableSave(false);
            throwServerError(err);
          });
          return;
      }
    } 
    
    if (props.isUpdate) {
      if (chosenProductBundle) {
        let json = objectDeepClone(props.data);
        json["description"] = description;
        json["attributes"] = attributes;
        setDisableSave(true);
        setLoading(true)
        API.updateApp(json.org, json, json.orgType)
          .then((res) => {
            getToast({
              statusType: "SUCCESS",
              message: 'App updated Successfully"',
            });
            setDisableSave(false);
            setLoading(false)
            props.onHide();
            props.onCreateCallback();
          })
          .catch((err) => {
            setDisableSave(false);
            setLoading(false)
            throwServerError(err);
          });
        return;
      }
      if (!description) {
        getToast({
          statusType: "ERROR",
          message: "Please fill all the required details",
        });
        return;
      }

      //TODO:Organisation dependency is there.
      if (data.type === "apigee") {
        let json = objectDeepClone(props.data);
        json["description"] = description;
        json["attributes"] = attributes;
        setDisableSave(true);
        setLoading(true)
        API.updateApp(json.org, json, json.orgType)
          .then((res) => {
            getToast({
              statusType: "SUCCESS",
              message: 'App updated Successfully"',
            });
            setDisableSave(false);
            setLoading(false)
            props.onHide();
            props.onCreateCallback();
          })
          .catch((err) => {
            setDisableSave(false);
            setLoading(false)
            throwServerError(err);
          });
          return;
      }

      if (data.type === "kong") {
        setDisableSave(true);
        setLoading(true)
        monetizationAPI
          .updateKongApp(
            {
              ...data,
              description,
            },
            data.id
          )
          .then((res) => {
            setDisableSave(false);
            setLoading(true)
            getToast({
              statusType: "SUCCESS",
              message: "App updated successfully",
            });
            props.onHide();
            props.onCreateCallback();
          })
          .catch((err) => {
            setDisableSave(false);
            setLoading(true)
            getToast({
              statusType: "ERROR",
              message: "Unable to update app",
            });
          });
          return;
      }

      if (data.type === "azure") {
        console.log("data.type:", data);
        return;
      }
    }
  };

  //   API integration for kong and apigee

  // console.log("chosenProduct: ", chosenProduct);
  // console.log("chosen bundle: ", chosenProductBundle)

  return (
    <Modal
      {...props}
      size="md me-0 mt-0 mb-0"
      contentClassName="br-0px homeModalForLghtMode"
      className="custom-modal-v1 v1--styles"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-3 br-0px">
        <div className="ps-1 d-flex align-items-center justify-content-between w-100">
          <p className="mb-0 text-content-subtle fw-500 fs-16px">
            {props.isUpdate ? "Update App" : "App Creation"}
          </p>

          <div className="d-flex align-items-center">
            <div
              className="text-content-subtle fs-12px fw-400 me-3 cursor-pointer"
              onClick={() => {
                props.onHide();
                setBuyStep(1);
              }}
            >
              Cancel
            </div>

            {disableSave ? (
              <Loading size="sm" />
            ) : (
              <div
                className="cursor bg-base-color text-content-subtle px-16px py-6px br-4px fw-500 fs-12px text-300 px-3"
                onClick={handleSubmit}
                style={{ border: "0.5px solid var(--content-subtle)" }}
              >
                {props.isUpdate ? "Save" : chosenProductBundle ? "Buy Now" : "Create"}
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar"
      >
        {buyStep === 1 ? (
          loading ? <Loading/> : <div>
            <div className="mb-3 mainSelect">
              <p className="fs-12px fw-400 text-content-subtle">
                Select Product Bundle/Product
              </p>
              <Select
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "focused"
                      : state.isSelected
                      ? "selected"
                      : "",
                }}
                className="mt-1 v1select"
                placeholder={
                  !chosenProductBundle && !chosenProduct
                    ? `Select a Product / Product Bundle`
                    : chosenProductBundle
                    ? chosenProductBundle.name
                    : chosenProduct && chosenProduct.name
                    ? chosenProduct?.name
                    : chosenProduct?.productName
                }
                value={null}
                formatOptionLabel={(inherit) => (
                  <div
                    onClick={(evt) => {
                      //   evt.stopPropagation();
                      if (!inherit.isProduct) {
                        setChosenProductBundle(inherit.value);
                        console.log("inherit value: ", inherit.value);

                        console.log("productBundleList: ", productBundleList);
                        const _selectedProductBundle = productBundleList.find(
                          (bundle) =>
                            bundle.productBundle.id ===
                            inherit.value.productBundle.id
                        );

                        console.log(
                          "_selectedProductBundle: ",
                          _selectedProductBundle
                        );

                        setOrganisation(
                          _selectedProductBundle?.productBundle?.organization
                        );
                        setProducts(
                          _selectedProductBundle?.productBundle?.products
                        );
                        // filter out rateplans visible to portals only
                        const _portalVisibleRateplans =
                          _selectedProductBundle?.ratePlans?.filter(
                            (ratePlan) => ratePlan.visibleToPortals
                          );

                        setRatePlans(_portalVisibleRateplans);
                        setChosenProduct(null);
                        setBuyStep(2);
                      } else {
                        setChosenProduct(inherit.value);
                        setChosenProductBundle(null);
                        setBuyStep(2);
                      }
                    }}
                    className="cursor p-2-8 gap-8px d-flex align-items-center justify-content-between br-3 mt-4px"
                    style={{ padding: "2px 8px" }}
                  >
                    <div className="d-flex flex-column gap-4px">
                      <div
                        className="fw-500 fs-14px"
                        style={{
                          color: "var(--content-subtle)",
                        }}
                      >
                        {inherit.label}
                      </div>
                    </div>
                    <div className="tag-product-bundle-option">
                      {inherit.isProduct ? "Product" : "Bundle"}
                    </div>
                  </div>
                )}
                options={[...productBundleOptions, ...productOptions]}
              />
            </div>
          </div>
        ) : (
          loading ? <Loading/> : <div className="">
            <div className="mb-3 mainSelect">
              <p className="fs-12px fw-400 text-content-subtle">
                {chosenProductBundle ? "Product Bundle" : "Product"}
              </p>
              {props.isCreate ? (
                chosenProductBundle ? (
                  <p className="fs-14px fw-500 text-content-color mt-1px">
                    {chosenProductBundle?.productBundle?.name}
                  </p>
                ) : (
                  chosenProduct && (
                    <p className="fs-14px fw-500 text-content-color mt-1px">
                      {chosenProduct?.name
                        ? chosenProduct.name
                        : chosenProduct.productName}
                    </p>
                  )
                )
              ) : (
                <p className="fs-14px fw-500 text-content-color mt-1px">
                  {data?.name}
                </p>
              )}
            </div>

            {/* {props.isCreate ? null : (
              <div className="mb-3">
                <p className="fs-12px fw-400 text-content-subtle">
                  {chosenProductBundle
                    ? "Bundle Description"
                    : "Product Description"}
                </p>
                <p className="fs-14px fw-500 text-content-color mt-1px">
                  {chosenProductBundle
                    ? chosenProductBundle.productBundle.description
                    : chosenProduct.description}
                </p>
              </div>
            )} */}

            {chosenProductBundle && (
              <div className="mb-3">
                <p className="fs-12px fw-400 text-content-subtle">Products</p>
                <div className="dfaic flex-wrap mt-2px">
                  {chosenProductBundle?.productBundle?.products?.map(
                    (eachProduct) => (
                      <p className="bg-base-200 py-4px px-8px fs-14px fw-500 text-content-color mt-1px">
                        {eachProduct}
                      </p>
                    )
                  )}
                </div>
              </div>
            )}

            {!props.isUpdate && chosenProductBundle && (
              <div className="mb-3 mainSelect">
                <p className="fs-12px fw-400 text-content-subtle">
                  Select Rate Plan <span className="text-danger">*</span>{" "}
                </p>
                <Select
                  classNames={{
                    option: (state) =>
                      state.isFocused
                        ? "focused"
                        : state.isSelected
                        ? "selected"
                        : "",
                  }}
                  className="mt-1 v1select"
                  placeholder={`${
                    selectedRatePlan
                      ? selectedRatePlan.name
                      : "Select a Rate Plan"
                  }`}
                  value={null}
                  options={ratePlanOptions}
                  formatOptionLabel={(props) => (
                    <div className="d-flex align-items-start gap-40px">
                      <div className="rate-plan-name">
                        <div className="fs-12px">Rate Plan Name</div>
                        <div className="fs-11px text-secondary-color">
                          {" "}
                          {props.label.name}
                        </div>
                      </div>
                      <div className="rate-plan-pricings">
                        {getRatePlanValue({ rateplan: props.value })?.map(
                          (pricings) => (
                            <p className="fs-12px text-primary-color">
                              {pricings}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  onChange={(e) => {
                    setSelectedRatePlan(e.value);
                  }}
                />
              </div>
            )}

            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">
                App Name <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                value={appName}
                disabled={props.isUpdate}
                onChange={(e) => setAppName(e.target.value)}
                className="from-control v1-form-control mt-1 v1-form-control-public w-100"
              />
            </div>

            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">
                Description <span className="text-danger">*</span>
              </p>
              <textarea
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
              />
            </div>
            {(chosenProductBundle || chosenProduct?.type === "apigee" || data?.type === 'apigee') && (
              <div
                className="mb-3"
                style={{
                  border: "1px solid var(--base-100)",
                  borderLeft: "0px",
                }}
              >
                <div
                  className="px-16px py-18px"
                  style={{ borderLeft: "5px solid var(--primary-color)" }}
                >
                  <p className="fs-14px fw-600 text-content-color">Attribute</p>

                  <div
                    className="dfaic justify-content-center px-24px py-10px br-4px mt-2 cursor"
                    style={{ border: "1px dashed var(--secondary-100)" }}
                    onClick={handleAdd}
                  >
                    <Plus color="var(--secondary-100)" size={16} />
                    <p className="text-content-color fs-14px ms-2 fw-500">
                      Add Attribute
                    </p>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-5">
                      <p className="fs-12px fw-500 text-content-subtle">Name</p>
                    </div>
                    <div className="col-md-5">
                      <p className="fs-12px fw-500 text-content-subtle">
                        Value
                      </p>
                    </div>
                  </div>

                  {attributes?.map((each, i) => (
                    <div className="row mt-2">
                      <div className="col-md-5 pe-0">
                        <input
                          type="text"
                          className="form-control v1-form-control v1-form-control-public"
                          value={each.name}
                          onChange={(e) =>
                            handleChange(e.target.value, i, "name")
                          }
                        />
                      </div>
                      <div className="col-md-5 pe-0">
                        <input
                          type="text"
                          className="form-control v1-form-control v1-form-control-public"
                          value={each.value}
                          onChange={(e) =>
                            handleChange(e.target.value, i, "value")
                          }
                        />
                      </div>
                      <div className="col-md-2 my-auto">
                        <div
                          className="ms-3 cursor"
                          onClick={() => handleDelete(i)}
                        >
                          <Trash color="var(--content-subtle)" size={18} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
