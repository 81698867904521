import { useOutletContext } from "react-router";
import { BodyLayout } from "../../layout/Base";
import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ApigeeUsersTable } from "./Components/ApigeeUsers";
import API from "../../api";
import { getToast } from "../../components/Toast";
import Loading from "../../components/Loader/Loading";
import { useSelector } from "react-redux";
import { KongUsersTable } from "./Components/KongUsers";
import { AzureUsersTable } from "./Components/AzureUsers";
import Searchbar from "../../components/Searchbar";
import { NginxUsersTable } from "./Components/NginxUsers";
import { GATEWAYS } from "../../service/constants";
import { AWSUsersTable } from "./Components/AWSUsers";
import { AxwayUsersTable } from "./Components/AxwayUsersTable";

const TABS = {
  APIGEE: "Apigee",
  KONG: "Kong",
  AZURE: "Azure",
  NGINX: "Nginx",
  AWS: GATEWAYS.AWS.toUpperCase(),
  AXWAY: "Axway"
};
const AssociateUsers = () => {
  const { setNavigation, setTitle } = useOutletContext();
  const [activeTab, setActivetab] = useState(TABS.APIGEE);
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [userOrgList, setUserOrgList] = useState({
    default: "",
    orgs: [],
  });
  const [runtimes, setRuntimes] = useState({
    default: "",
    runtimes: [],
  });
  const [resourceGroups, setResourceGroups] = useState({
    default: "",
    resourceGroups: [],
  });
  const [nginxRuntimes, setNginxRuntimes] = useState({
    default: "",
    runtimes: [],
  });
  const [awsRuntimes, setAwsRuntimes] = useState({
    default: "",
    runtimes: [],
  });
  const [axwayRuntimes, setAxwayRuntimes] = useState({
    default: "",
    runtimes: [],
  });


  const getApigeeUsers = useCallback(({ page = 1, search = "" }) => {
    setDataLoading(true);
    API.getApigeeUsers({ page, search })
      .then((res) => {
        setUsersData(res.data.data);
        setPagination(res.data.paging);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch Apigee Users",
        });
      });
  }, []);

  const getKongUsers = useCallback(({ page = 1, search = "" }) => {
    setDataLoading(true);
    API.getKongUsers({ page, search })
      .then((res) => {
        setUsersData(res.data.data);
        setPagination(res.data.paging);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch Kong Users",
        });
      });
  });

  const getAzureUsers = useCallback(({ page = 1, search = "" }) => {
    setDataLoading(true);
    API.getAzureUsers({ page, search })
      .then((res) => {
        setUsersData(res.data.data);
        setPagination(res.data.paging);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch Kong Users",
        });
      });
  });

  const getNginxUsers = useCallback(({ page = 1, search = "" }) => {
    setDataLoading(true);
    API.getNginxUsers({ page, search })
      .then((res) => {
        setUsersData(res.data.data);
        setPagination(res.data.paging);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch Nginx Users",
        });
      });
  });


  const getAwsUsers = useCallback(({ page = 1, search = "" }) => {
    setDataLoading(true);
    API.getAwsUsers({ page, search })
      .then((res) => {
        setUsersData(res?.data?.data || []);
        setPagination(res.data.paging);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch AWS Users",
        });
      });
  });

  const getAxwayUsers = useCallback(({ page = 1, search = "" }) => {
    setDataLoading(true);
    API.getAxwayUsers({ page, search })
      .then((res) => {
        setUsersData(res?.data?.data || []);
        setPagination(res.data.paging);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to fetch Axway Users",
        });
      });
  });

  const getOrgs = () => {
    API.getOrgs()
      .then((response) => {
        if (response.data.data) {
          let defaultOrg = "";
          response.data.data.forEach((el) => {
            if (el.organisationDefault === "1") {
              defaultOrg = el.organisationName;
            }
          });
          setUserOrgList({
            default: defaultOrg,
            orgs: response.data.data.map((el) => {
              return el.organisationName;
            }),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRuntimes = () => {
    API.getRuntimes()
      .then((res) => {
        const defaultRuntime = res.data.data.find(
          (runtime) => runtime.runtimeDefault
        );
        setRuntimes({
          default: defaultRuntime?.name,
          runtimes: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResourceGroups = () => {
    API.getResourceGroups()
      .then((res) => {
        const defaultResourceGroup = res.data.data.find(
          (resource) => resource.resourceDefault
        );
        setResourceGroups({
          default: defaultResourceGroup?.resourceGroup,
          resourceGroups: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNginxRuntimes = () => {
    API.getNginxRuntimes()
      .then((res) => {
        const defaultRuntime = res.data.data.find(
          (runtime) => runtime.runtimeDefault
        );
        setNginxRuntimes({
          default: defaultRuntime,
          runtimes: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAwsRuntimes = () => {
    API.getAwsRuntimes()
      .then((res) => {
        const defaultRuntime = (res?.data?.data || []).find(
          (runtime) => runtime.runtimeDefault
        );
        setAwsRuntimes({
          default: defaultRuntime,
          runtimes: res?.data?.data || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAxwayRuntimes = () => {
    API.getAxwayRuntimes()
      .then((res) => {
        const defaultRuntime = (res?.data?.data || []).find(
          (runtime) => runtime.runtimeDefault
        );
        setAxwayRuntimes({
          default: defaultRuntime,
          runtimes: res?.data?.data || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { firstRoute } = useSelector((state) => state.user);

  useEffect(() => {
    setTitle("Associate Users");

    setNavigation([
      { name: "Dashboard", link: firstRoute },
      {
        name: "Associate Users",
        link: "/admin/manage-gateways/associate-users",
      },
    ]);

    getResourceGroups();
    getRuntimes();
    getOrgs();
    getNginxRuntimes();
    getAwsRuntimes();
    getAxwayRuntimes();
  }, []);

  useEffect(() => {
    setSearchVal("");
    if (activeTab === "Apigee") {
      getApigeeUsers({ page: 1 });
    } else if (activeTab === "Kong") {
      getKongUsers({ page: 1 });
    } else if (activeTab === "Azure") {
      getAzureUsers({ page: 1 });
    } else if (activeTab === "Nginx") {
      getNginxUsers({ page: 1 });
    } else if(activeTab === "Axway") {
      getAxwayUsers({page:1});
    } else if(activeTab === "AWS") {
      getAwsUsers({page:1});
    }
  }, [activeTab]);

  const [searchVal, setSearchVal] = useState("");

  return (
    <BodyLayout>
      <div className="fadeIn surface-900 w-100 h-100 overflow-scroll noscrollbar gateway-details">
        <div className="tabs-container w-100 custom-tab-container position-relative">
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => setActivetab(key)}
            id="gateway-select-tabs"
            onChange={(e) => console.log("e", e)}
          >
            {userOrgList.orgs.length ? (
              <Tab
                tabClassName="tabs-btn-2"
                className="p-12px py-2"
                title={TABS.APIGEE}
                eventKey={TABS.APIGEE}
              >
                {dataLoading ? (
                  <div className="h-75vh overflow-scroll noscrollbar">
                    <Loading />
                  </div>
                ) : (
                  <ApigeeUsersTable
                    data={usersData}
                    pagination={pagination}
                    getData={getApigeeUsers}
                    orgList={userOrgList}
                    search={searchVal || ""}
                  />
                )}
              </Tab>
            ) : null}
            {runtimes.runtimes.length ? (
              <Tab
                tabClassName="tabs-btn-2"
                className="p-3"
                title={TABS.KONG}
                eventKey={TABS.KONG}
              >
                {dataLoading ? (
                  <div className="h-75vh overflow-scroll noscrollbar">
                    <Loading />
                  </div>
                ) : (
                  <KongUsersTable
                    data={usersData}
                    pagination={pagination}
                    getData={getKongUsers}
                    runtimes={runtimes}
                    search={searchVal || ""}
                  />
                )}
              </Tab>
            ) : null}
            {resourceGroups.resourceGroups.length ? (
              <Tab
                tabClassName="tabs-btn-2"
                className="p-3"
                title={TABS.AZURE}
                eventKey={TABS.AZURE}
              >
                {dataLoading ? (
                  <div className="h-75vh overflow-scroll noscrollbar">
                    <Loading />
                  </div>
                ) : (
                  <AzureUsersTable
                    data={usersData}
                    pagination={pagination}
                    getData={getAzureUsers}
                    resourceGroups={resourceGroups}
                    search={searchVal || ""}
                  />
                )}
              </Tab>
            ) : null}
            {nginxRuntimes.runtimes?.length ? (
              <Tab
                tabClassName="tabs-btn-2"
                className="p-3"
                title={TABS.NGINX}
                eventKey={TABS.NGINX}
              >
                {dataLoading ? (
                  <div className="h-75vh overflow-scroll noscrollbar">
                    <Loading />
                  </div>
                ) : (
                  <NginxUsersTable
                    data={usersData}
                    pagination={pagination}
                    getData={getNginxUsers}
                    runtimes={nginxRuntimes}
                    search={searchVal || ""}
                  />
                )}
              </Tab>
            ) : null}
            {awsRuntimes.runtimes?.length ? (
              <Tab
                tabClassName="tabs-btn-2"
                className="p-3"
                title={TABS.AWS}
                eventKey={TABS.AWS}
              >
                {dataLoading ? (
                  <div className="h-75vh overflow-scroll noscrollbar">
                    <Loading />
                  </div>
                ) : (
                  <AWSUsersTable
                    data={usersData}
                    pagination={pagination}
                    getData={getAwsUsers}
                    runtimes={awsRuntimes}
                    search={searchVal || ""}
                  />
                )}
              </Tab>
            ) : null}
            {axwayRuntimes.runtimes?.length ? (
              <Tab
                tabClassName="tabs-btn-2"
                className="p-3"
                title={TABS.AXWAY}
                eventKey={TABS.AXWAY}
              >
                {dataLoading ? (
                  <div className="h-75vh overflow-scroll noscrollbar">
                    <Loading />
                  </div>
                ) : (
                  <AxwayUsersTable
                    data={usersData}
                    pagination={pagination}
                    getData={getAxwayUsers}
                    runtimes={axwayRuntimes}
                    search={searchVal || ""}
                  />
                )}
              </Tab>
            ) : null}

          </Tabs>

          <div style={{ width: 220, position: "absolute", top: 3, right: 10 }}>
            <Searchbar
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e);
                if (activeTab === TABS.APIGEE) {
                  getApigeeUsers({ page: 1, search: e });
                } else if (activeTab === TABS.KONG) {
                  getKongUsers({ page: 1, search: e });
                } else if (activeTab === TABS.AZURE) {
                  getAzureUsers({ page: 1, search: e });
                } else if (activeTab === TABS.NGINX) {
                  getNginxUsers({ page: 1, search: e });
                } else if (activeTab === TABS.AWS) {
                  getAwsUsers({ page: 1, search: e });
                }else if(activeTab === TABS.AXWAY){
                  getAxwayUsers({page:1 , search: e})
                }
              }}
            />
          </div>
        </div>
      </div>
    </BodyLayout>
  );
};

export default AssociateUsers;
