import { useEffect, useMemo, useRef, useState } from "react";

export const useObserver = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);
//   const ref = useRef()

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    if(ref.current){
      observer?.observe(ref.current);
    }
    return () => observer?.disconnect();
  }, [ref.current]);

  return isIntersecting;
};
