import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { formatCtsTime, formatDate, getFirstLetterOfString, objectDeepClone, throwServerError } from '../../utils/helper';
import { Tab, Tabs } from 'react-bootstrap';
import { Check, Checks } from 'phosphor-react';
import API from '../../api';
import { getToast } from '../../components/Toast';
import EmptyContainer from '../../components/EmptyContainer';

export default function NotificationModal(props) {
    const [data, setData] = useState({})
    const [monetizationNotif, setMonetizationNotif] = useState({})
    const [activeTab, setActiveTab] = useState('User Request')

    useEffect(() => {
        let _data = objectDeepClone(props.data?.notifications || [])

        const groupedData = {};
        _data.forEach(each => {
            const dateLabel = formatDate(each.cts);
            if (!groupedData[dateLabel]) {
                groupedData[dateLabel] = [];
            }
            
            groupedData[dateLabel].push(each); 
        })

        setData(objectDeepClone(groupedData))
    }, [props.data])

    const transformData = (_data) => {
        const groupedData = {};
        _data.forEach(each => {
            const dateLabel = formatDate(each?.cts, false);
            if (!groupedData[dateLabel]) {
                groupedData[dateLabel] = [];
            }
            
            groupedData[dateLabel].push(each); 
        }) 
        
        return groupedData
    }

    useEffect(() => {
        if (props.monetizationNotifications){
            let _data = objectDeepClone(props.monetizationNotifications || [])
            setMonetizationNotif(transformData(_data))
        } else {
            setMonetizationNotif([])
        }
    }, [props.monetizationNotifications])
    

    const markAsRead = (each) => {
        API.markAsRead(each._id)
        .then(res => {
            getToast({
                statusType: 'SUCCESS', 
                message: 'Notification marked as Read'
            })
            props.callback()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const markAsReadMonetization = (each) => {
        API.readOneNotification(each.id)
        .then(res => {
            getToast({
                statusType: 'SUCCESS', 
                message: 'Notification marked as Read'
            })
            props.callback()
        }).catch(err => {
            throwServerError(err)
        })
    }
    
    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>
                        Notifications
                    </p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar p-0"
            >
                <Tabs activeKey={activeTab} id="resources-tab" className='surface-875'
                    onSelect={(key) => { setActiveTab(key) }}>
                    <Tab tabClassName="tabs-btn-2" className='py-3'
                        eventKey={'User Request'} title={`User Request (${props.data?.count || 0})`}>
                        
                        <div className='p-12px'>{
                            Object.keys(data).length === 0
                            ? <div className=''>
                                <EmptyContainer message='No Latest Notifications'
                                    className='d-flex align-items-center flex-column mt-5' />
                            </div>
                            : Object.keys(data).map((eachKey, i) => {
                                let arr = data[eachKey]

                                return(
                                    <div className='mb-3' key={i}>
                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                            <p className='fw-500 fs-14px text-400'>
                                                {eachKey}
                                            </p>
                                        </div>

                                        {arr.map((each, _id) => <div key={_id} className='p-16px mb-1 surface-base d-flex align-items-center justify-content-between w-100'>
                                            <div className='d-flex align-items-center pe-2'>
                                                <div className='me-2'>
                                                    <div className='userCircle surface-850'>
                                                        <p className='fw-400 fs-14px text-300 text-uppercase'>
                                                            {getFirstLetterOfString(each.userRequested?.fullName || 'Deleted User')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='text-400 fs-16px fw-400'><span className='text-200 fw-500 fs-16px'>{each.userRequested?.fullName || 'Deleted User'}</span> Requested for activation</p>
                                                    <p className='fs-14px text-400 fw-400'>At {formatCtsTime(each.cts)} UTC</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='px-20px py-11px br-4px surface-850 cursor d-flex align-items-center'
                                                    onClick={() => markAsRead(each)}>
                                                    <Check size={12} className='me-2' color='#E4E4E4' />
                                                    <p className='text-300 fs-12px fw-500'>Mark as read</p>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                )
                            })
                        }</div>

                    </Tab>
                    <Tab tabClassName="tabs-btn-2" className='py-3'
                        eventKey={'Monetization'} title={`Monetization (${props.monetizationNotifications.length || 0})`}>
                        
                        <div className='p-12px'>
                            {false
                                ? <div className=''>
                                    <EmptyContainer message='No Latest Notifications'
                                        className='d-flex align-items-center flex-column mt-5' />
                                </div>
                                : Object.keys(monetizationNotif || {}).map((eachKey, i) => {
                                    const arr = monetizationNotif[eachKey]
                                    return(
                                        <div className='mb-3' key={i}>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <p className='fw-500 fs-14px text-400'>
                                                    {eachKey}
                                                </p>
                                            </div>

                                            {arr.map((each, _id) => <div key={each.id} className='p-16px mb-1 surface-base d-flex align-items-center justify-content-between w-100'>
                                                <div className='d-flex align-items-center pe-2'>
                                                    <div className='me-3'>
                                                        <Checks size={18} color='white' />
                                                    </div>
                                                    <div>
                                                        <p className='text-400 fs-16px fw-400'><span className='text-200 fw-500 fs-16px'>{each.notification || ''}</span></p>
                                                        <p className='fs-14px text-400 fw-400'>At {formatCtsTime(each.cts)} UTC</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='px-20px py-11px br-4px surface-850 cursor d-flex align-items-center'
                                                        onClick={() => markAsReadMonetization(each)}>
                                                        <Check size={12} className='me-2' color='#E4E4E4' />
                                                        <p className='text-300 fs-12px fw-500'>Mark as read</p>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    )
}
