import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdChevronRight, MdExpandMore } from "react-icons/md";
import Example from "./Example";
import Scheme from "./Scheme";
import { useSelector } from "react-redux";
import { isValidObject } from "@apiwiz/oas/services";
import { CaretCircleDown, CaretRight } from "phosphor-react";
import CaretDown from "./CaretDown";

//TODO: use memo to memmoise the function

const BodyParameter = ({ showBorderTop, content }) => {
  const [contentType, setContentType] = useState("application/json");
  const [schema, setSchema] = useState("");
  const [open, setOpen] = useState(false);
  const { advancedView } = useSelector((state) => state.swagger);

  const [exampleScheme, setExampleScheme] = useState("Schema");

  const contentTypes = () => {
    if (!content) return [];
    let _contentTypes = Object.keys(content);
    return _contentTypes;
  };

  function schemaFn() {
    if (!isValidObject(content) || !content[contentType]) return null;
    let _schema = content[contentType].schema;
    return _schema;
  }

  function paramNames() {
    if (!content || !content[contentType]) return [];
    let _paramDetails = content[contentType].__paramDetails || [];
    return _paramDetails;
  }

  const handleContentTypeDropdown = (value) => {
    setContentType(value);
  };

  useEffect(() => {
    setContentType(contentTypes()[0]);
  }, [content]);

  useEffect(() => {
    setSchema(schemaFn());
  }, [contentType]);

  return (
    <div>
      <div
        className={`cursor d-flex justify-content-between w-100 py-6px pr-6px pl-10px br-4px h-34px ${
          open ? "border-left-theme-secondary bg-theme-primary-subtle" : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`d-flex align-items-center gap-4px`}>
          {!open ? (
            <CaretRight
              color="var(--primary-color)"
              size={13}
              className="mr-4px"
            />
          ) : (
            <CaretDown />
          )}
          <div className={`text-theme-primary fs-13px lh-21px h-21px ${open?"fw-600":""}`}>
            Body Parameters
          </div>
        </div>
        {open ? (
          <div className="bg-theme-base d-flex p-2px align-items-center fs-10px fw-500 br-6px p-1px">
            <div
              onClick={(e) => {
                e.stopPropagation();
                setExampleScheme("Example");
              }}
              className={`px-12px py-4px br-6px ${
                exampleScheme === "Example" ? "bg-theme-primary text-50" : ""
              }`}
            >
              Example
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setExampleScheme("Schema");
              }}
              className={`px-12px py-4px br-6px ${
                exampleScheme === "Schema" ? "bg-theme-primary text-50" : ""
              }`}
            >
              Schema
            </div>
          </div>
        ) : null}
      </div>
      {open ? (
        <>
          {exampleScheme === "Schema" ? (
            <div>
              <Scheme schema={schema} />
            </div>
          ) : null}
          {exampleScheme === "Example" ? (
            <div>
              <Example contentType={contentType} schema={schema} />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );

  /*
  return (
    <div>
      {schema && (
        <div>
          <div className="d-flex justify-content-between align-items-center my-4">
            <div className="fw-bold" style={{fontSize: advancedView ? '16px' :"14px"}}>Body Parameters</div>
            <div className="d-flex justify-content-center swagger-btn-parent">
              <p
                onClick={handleExampleChange}
                className={`swagger-btn text-200 example text-200 cursor ${
                  exampleScheme === "Example" ? "active" : ""
                }`}
              >
                Example
              </p>
              <p
                onClick={handleSchemeChange}
                className={`swagger-btn text-200 scheme cursor ${
                  exampleScheme === "Schema" ? "active" : ""
                }`}
              >
                Schema
              </p>
            </div>
          </div>
          {contentTypes().length && (
            <div className="py-2 d-flex justify-content-center align-items-center">
              <div className="me-2" style={{fontSize: advancedView ? '16px' :"14px"}}>Content Type: </div>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="w-100"
                >
                  <div
                    className={`w-100 d-flex justify-content-between align-items-center py-1 ps-2 pe-1`}
                    style={{fontSize: "14px"}}
                  >
                    {contentType}
                    <MdExpandMore />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: "max-content" }}>
                  {contentTypes().map((type, index) => (
                    <Dropdown.Item
                      key={index}
                      href="javascript:void(0)"
                      onClick={() => handleContentTypeDropdown(type)}
                    >
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div>
            {exampleScheme === "Example" ? (
              <div>
                <Example contentType={contentType} schema={schema} />
              </div>
            ) : (
              <div>
                <Scheme schema={schema} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
  */
};

export default BodyParameter;
