import { BodyLayout } from "../../../layout/Base";
import { useState,useEffect, useMemo } from "react";
import { useOutletContext } from "react-router";
import Loading from "../../../components/Loader/Loading";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import API from "../../../api";
import { throwServerError } from "../../../utils/helper";
import { EyeSlash } from "phosphor-react";
import { Eye } from "phosphor-react";
import { encryptPassword } from "../../../utils/helper";
import { getToast } from "../../../components/Toast";
import { confirm } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { resetAuthenticatedState } from "../../../app/Features/Users/UserSlice";
import { getCookie,removeCookie } from "../../../utils/cookie";
import countryList from '../../../countries.json'
import Select from "react-select";


export default function Profile(){
    const options = useMemo(() => {
        let _temp = countryList
        _temp = _temp.map(o => ({label: o.label, value: o.label}))
        return _temp
    }, [])
    const {setNavigation, setTitle,setHasHeader} = useOutletContext()
    const [loading,setLoading] = useState()
    const [isEdit,setIsEdit] = useState()
    const [fullName,setFullName] = useState('')
    const [userName,setUserName] = useState('')
    const [email,setEmail] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [touched,setTouched] = useState(false);
    const {currentUser} = useSelector((state) => state.user);
    const [url,setUrl] = useState('');
    const [showPass,setShowPass] = useState(false)
    const [showConfirmPass,setShowConfirmPass] = useState(false)
    const dispatch = useDispatch();
    const { firstRoute } = useSelector((state) => state.user);
    const [country, setCountry] = useState('')

    useEffect(()=>{
        setTitle("Users")
        setHasHeader(true)
        setNavigation([
            {name: 'Dashboard', link : firstRoute},
            {name: 'Profile'},
        ])
    },[])

    useEffect(()=>{
        if(currentUser){
            setFullName(currentUser.fullName)
            setEmail(currentUser.email)
            setUserName(currentUser.username)
            if(currentUser.userImage){
                setUrl(currentUser.userImage)
            }
            setCountry(currentUser.country || '')
        }
    },[currentUser])

    function handleCancel(){
        setFullName(currentUser.fullName)
        setEmail(currentUser.email)
        setUserName(currentUser.username)
        setUrl(currentUser.userImage)
        setCountry(currentUser.country || '')
        setNewPassword("");
        setConfirmPassword("");
        setIsEdit(false);
        setTouched(false)
    }

    function handleSave(event){
        setTouched(true)
        event.preventDefault();
        let newForm = {
            fullName: fullName, email, username: userName, password: newPassword, 
            confirm_password: confirmPassword, userImage: url, country
        }
       
        // if(!newPassword){
        //     delete newForm.password
        //     delete newForm.confirm_password
        // }
        if(!fullName || !email || !userName || newPassword ? !newPassword || !confirmPassword : null){
            return
        } 
        let updateFrom = {...newForm}
        if(newForm.password){
            updateFrom.password = encryptPassword(updateFrom.password)
            updateFrom.confirm_password = updateFrom.password
        }
        API.updateUserDetails(updateFrom)
        .then(response => {
            getToast({statusType:"SUCCESS",message: 'Saved' })
            setIsEdit(false)
            setTouched(false)
        })
        .catch(error => {
            throwServerError(error)
        })
        

    }

    function fileUpload(e){
        setLoading(true)
        API.uploadImage(e.target.files[0])
        .then(res => {
            setUrl(res.file?.url || '')
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    function handleDeactivate(){
        let msg = "Are you sure you want to deactivate the account?";
        if (confirm(msg)) {
            const id = currentUser._id;
            API.updateStatus({id, status: "deactivated"})
            .then(response => {
               
                dispatch(resetAuthenticatedState())
                if (getCookie('itorix-token')) removeCookie('itorix-token')
                if (getCookie('itorix-token-data'))removeCookie('itorix-token-data')

                window.location.href = '/login'
            })
            .catch(error => {
                console.log(error)
            })
        }
    }


    return <BodyLayout>
        <div className="fadeIn surface-900 w-100 h-100 overflow-scroll noscrollbar gateway-details">
        <div className="tabs-container w-100 custom-tab-container">
          <div className="surface-875 p-10px d-flex justify-content-between align-items-center v1-border-bottom-05">
            <div>
              <p className="fs-18px fw-600">Profile Settings</p>
            </div>
            {isEdit
                ? <div className='d-flex align-items-center'>
                    <p className='text-300 fs-12px fw-500 me-3 cursor'
                        onClick={handleCancel}>Cancel</p>

                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={(e)=>{handleSave(e)}}>
                        Save
                    </div>
                </div>
                : <div>
                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={()=>{setIsEdit(true)}}>
                        Edit
                    </div>
                </div>}
          </div>
        </div>
        <div className="p-12px overflow-scroll" style={{height:"calc(87vh - 57px)"}}>
          {loading ? (
            <div className="h-75vh overflow-scroll noscrollbar">
              <Loading />
            </div>
          ) : (
            <div className="d-flex justify-content-center w-100 mt-3">
                <div className="w-35 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <p className="mb-3">Profile Picture</p>
                        {url ?<div>
                            <img src={url} style={{borderRadius: '50%'}} height="200px" width="200px" />
                        </div>:<div>
                            <GlobalIcons type="emptyImage"></GlobalIcons>
                        </div>}
                        {isEdit &&
                        <label htmlFor={`uploadProfile`}
                            className='cursor d-flex align-items-center justify-content-center py-2 btn-md-v1 mt-4'>
                            <GlobalIcons type="upload" />
                            <p className='fw-500 fs-12px text-300'>Upload Image</p>
                        </label>
                        }
                        <input type={'file'} id='uploadProfile' className='d-none' 
                            onChange={(e) => fileUpload(e)} />
                    </div>
                    <div className="w-100 mt-4">
                        <Form className="d-flex flex-column w-100">
                            <div className='w-100'>
                                <Form.Group className="mb-2" controlId="formBasicEmail1">
                                    <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                        Full Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    {isEdit ?<Form.Control
                                        type="text"
                                        placeholder="enter your full name"
                                        value={fullName}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                        className="v1-form-control"
                                    />:<p>{fullName}</p>}
                                    {touched && !fullName  ? (
                                    <Form.Text className="text-danger text-lowercase fs-10px">
                                        Full Name is Required
                                    </Form.Text>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="formBasicEmail1">
                                    <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                        User Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    {isEdit ? <Form.Control
                                        type="text"
                                        placeholder="enter your user name"
                                        value={userName}
                                        onChange={(e) =>
                                            setUserName(e.target.value)
                                        }
                                        className="v1-form-control"
                                    />:<p>{userName}</p>}
                                    {touched && !userName  ? (
                                    <Form.Text className="text-danger text-lowercase fs-10px">
                                        User Name is Required
                                    </Form.Text>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="formBasicEmail1">
                                    <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                        Email <span className="text-danger">*</span>
                                    </Form.Label>
                                    {isEdit ? <Form.Control
                                        type="text"
                                        placeholder="enter email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className="v1-form-control"
                                    />:<p>{email}</p>}
                                    {touched && !email  ? (
                                    <Form.Text className="text-danger text-lowercase fs-10px">
                                        Email is Required
                                    </Form.Text>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-2" controlId="formBasicEmail1">
                                    <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                        Country <span className="text-danger">*</span>
                                    </Form.Label>
                                    {isEdit 
                                    ?  <Select options={options} value={{label: country, value: country}} 
                                        onChange={(e) => {
                                            setCountry(e.value)
                                        }} className='mt-1 v1select'
                                        classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }} />
                                    :<p>{country || '-'}</p>}
                                    {touched && !email  ? (
                                    <Form.Text className="text-danger text-lowercase fs-10px">
                                        Country is Required
                                    </Form.Text>
                                    ) : null}
                                </Form.Group>

                                {isEdit && <Form.Group className="mb-2" controlId="formBasicEmail1">
                                    <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                        New Password <span className="text-danger">*</span>
                                    </Form.Label>
                                    <div className='position-relative'>
                                        <input type={showPass ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                                            placeholder='Enter your password' value={newPassword}
                                            onChange={(e) => {
                                                let _str = e.target.value
                                                setNewPassword(_str)
                                            }} />
                                        <div className='eyeIconPass cursor' onClick={() => setShowPass(curr => !curr)}>
                                            {showPass
                                                ? <EyeSlash color='white' size={16} />
                                                : <Eye color='white' size={16} />}
                                        </div>
                                    </div>
                                    {/* {touched && !newPassword  ? (
                                    <Form.Text className="text-danger text-lowercase fs-10px">
                                    New Password is Required
                                    </Form.Text>
                                    ) : null} */}
                                </Form.Group>
                                }
                                {isEdit && <Form.Group className="mb-2" controlId="formBasicEmail1">
                                    <Form.Label className="text-300 fw-400 fs-12px mb-1">
                                        Confirm Password <span className="text-danger">*</span>
                                    </Form.Label>
                                    <div className='position-relative'>
                                        <input type={showConfirmPass ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                                            placeholder='Enter your password' value={confirmPassword}
                                            onChange={(e) => {
                                                let _str = e.target.value
                                                setConfirmPassword(_str)
                                            }} />
                                        <div className='eyeIconPass cursor' onClick={() => setShowConfirmPass(curr => !curr)}>
                                            {showConfirmPass
                                                ? <EyeSlash color='white' size={16} />
                                                : <Eye color='white' size={16} />}
                                        </div>
                                    </div>
                                    {touched && (newPassword && !confirmPassword)  ? (
                                    <Form.Text className="text-danger text-lowercase fs-10px">
                                        Confirm Password is Required
                                    </Form.Text>
                                    ) : null}
                                </Form.Group>}
                                </div>
                        </Form>
                    </div>
                    <div className="btn-md-v1 mt-3" onClick={(e)=>{handleDeactivate(e)}}>
                        <p className="text-300 fs-12px fw-500">Deactivate Account</p>
                    </div>
                </div>


            </div>
          )}
        </div>
      </div>
    
    </BodyLayout>
}