import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import api from "../../../api";
import { useSelector } from "react-redux";
import {
  downloadObjectAsJson,
  downloadObjectAsYaml,
  getYAML,
} from "../../../utils/helper"
import { Dropdown } from "react-bootstrap";
import { MdExpandMore, MdClose, MdArrowBack } from "react-icons/md";
import Select from "react-select";


export const SpecificationModal = (props) => {
  const [type, setType] = useState("json");
  const { swagger, ..._props } = props;

  const handleClose = () => {
    props.onHide();
    setType("json");
    // props.parentCb();
  };

  const getJsonSwagger = () => {
    return JSON.stringify(swagger, null, 2);
  };

  const getYamlSwagger = () => {
    return getYAML(swagger);
  };

  const downloadSpecHandler = () => {
    const swaggerMetadata = swagger["x-metadata"]?.metadata;
    const { revision, swaggerName } = swaggerMetadata || {};
    if (type === "json") {
      downloadObjectAsJson(`${swaggerName}-revision-${revision}`, swagger);
    } else {
      downloadObjectAsYaml(
        `${swaggerName}-revision-${revision}`,
        getYamlSwagger()
      );
    }
  };

  return (
    <Modal
      {..._props}
      size="lg me-0 mt-0 mb-0"
      contentClassName='br-0px homeModalForLghtMode border-0'
      className="v1--styles"
      aria-labelledby="contained-modal-title-vcenter overflow-hidden noscrollbar"
      centered
    >
      <Modal.Header className="w-100 py-8 pl-8 pr-8 important br-0px">
        <div className="py-8 pr-8 pl-18 d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center gap-8px">
            <div className=" cursor" onClick={handleClose}>
              <MdArrowBack size={24} />
            </div>
            <p className="mb-0 text-content-color fw-500 fs-16px">Download</p>
          </div>

          <div className="d-flex align-items-center mainSelect">
            {/* <Dropdown className="w-100 mt-1">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="w-100"
              >
                <div
                  className="d-flex scm-dropdown-toggle justify-content-between align-items-center py-1 ps-2 pe-1"
                  style={{ width: "8rem" }}
                >
                  {type.toUpperCase()}
                  <MdExpandMore />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{minWidth: 130}} className="w-100">
                <Dropdown.Item onClick={() => setType("json")}>
                  JSON
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setType("yaml")}>
                  YAML
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <Select className="v1select mt-1" options={[
                    {label: 'JSON', value: 'JSON'}, {label: 'YAML', value: 'YAML'},
                ]} onChange={(e) => setType(e.value?.toLowerCase())}
                value={{label: type.toUpperCase(), value: type.toUpperCase()}}
                classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 63px)" }}
        className="br-0 position-relative"
      >
        <div className="h-100 overflow-scroll noscrollbar p-12">
          <div
            style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
            class="p-4 pb-0"
          >
            <pre>{type === "json" ? getJsonSwagger() : getYamlSwagger()}</pre>
          </div>
        </div>
        <button
          className="bg-primary border-0 rounded-circle p-0 specification-modal-download-btn"
          style={{ height: "56px", width: "56px" }}
          onClick={(evt) => {
            evt.stopPropagation();
            downloadSpecHandler();
          }}
        >
          <i aria-hidden="true">
            <svg
              fill="white"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
            </svg>
          </i>
        </button>
      </Modal.Body>
    </Modal>
  );
};
