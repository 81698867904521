import { Browsers, Trash } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setContents } from '../../../../app/Features/Layout/LayoutSlice'
import { getToast } from '../../../../components/Toast'
import { confirm, objectDeepClone } from '../../../../utils/helper'
import ImageCard from './ImageCard'
import AddScenarioBtn from '../../../../components/AddScenarioBtn'
import CustomImage2 from '../../../Dashboards/components/CustomImage2'
import GlobalIcons from '../../../../components/Icons/GlobalIcons'

export default function RenderImage2({isEdit, setIsEdit, setLoading, sectionIndex}) {
    const {currentLayoutObject, backupCurrentLayout} = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [data, setData] = useState({})

    useEffect(() => {
        if (currentLayoutObject.contents && currentLayoutObject.contents[sectionIndex]){
            setData(currentLayoutObject.contents[sectionIndex].payload || {})
        }
    }, [])

    const handleCancel = () => {
        if (!confirm("Are you sure? any new data might be lost in this section?")) return

        let _prevObj = objectDeepClone(backupCurrentLayout)
        setData(_prevObj.contents[sectionIndex].payload || {})
    }

    const handleSave = () => {
        let _data = objectDeepClone(data)
        if (!_data.heading || !_data.description || !_data.cardSection?.title
                || !_data.cardSection?.description){
            getToast({
                statusType: 'ERROR', message: 'Please fill all the required details'
            })
            return
        }

        if (_data.cardSection && _data.cardSection.points 
            && _data.cardSection.points.length > 0){
            let filtered = _data.cardSection.points.filter(o => !o.length)
            if (filtered && filtered.length){
                getToast({
                    statusType: 'ERROR', message: 'Please fill all the Texts in Card Section'
                })
                return
            }
        }

        _data.isEdit = false
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
        setData(objectDeepClone(_data))
    }

    const handleEdit = () => {
        let _data = objectDeepClone(data)
        _data.isEdit = true 
        setIsEdit(true)
        setData(objectDeepClone(_data))
        dispatch(setContents({id: sectionIndex, xData: objectDeepClone(_data)}))
    }
    
    const [isCtrl, setIsCtrl] = useState(false)

    const handleChange = (_val, _id) => {
        let _data = objectDeepClone(data)
        _data.cardSection.points[_id] = _val 
        setIsEdit(true)
        setData(objectDeepClone(_data))
    }

    const handleDelete = (_id) => {
        let _data = objectDeepClone(data)
        _data.cardSection?.points?.splice(_id, 1)
        setIsEdit(true)
        setData(objectDeepClone(_data))
    }

    return (
        <div className='portal-image-section' onKeyDown={(e) => {
            setIsCtrl(['Control', 'Meta'].includes(e.key))
            if (isCtrl && e.key?.toLowerCase() === 's') {
                handleSave()
                e.preventDefault()
            }
        }}>
            <div className='surface-base p-16px overflow-scroll noscrollbar' style={{zoom: 0.5, height: 500}}>
                <CustomImage2 data={data} className='' />
            </div>

            <div className='surface-875 d-flex align-items-center justify-content-between p-12px mt-3'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Content</p>
                </div>
                {data.isEdit
                ? <div className='d-flex align-items-center'>
                    <p className='text-300 fs-12px fw-500 me-3 cursor'
                        onClick={handleCancel}>Cancel</p>

                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleSave}>
                        Save
                    </div>
                </div>
                : <div>
                    <div className='btn surface-850 px-20px py-8px br-4px v1-border-1 text-300 fs-12px fw-500'
                        onClick={handleEdit}>
                        Edit
                    </div>
                </div>}
            </div>

            <div className='surface-base br-4px p-16px w-100 h-70vh overflow-scroll noscrollbar'>
                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Heading <span className='text-danger'>*</span></p>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.heading}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, heading: e.target.value})} />
                </div>

                <div className='mb-3'>
                    <p className='text-300 fs-12px fw-400'>Description <span className='text-danger'>*</span></p>
                    <textarea type={'text'} className='from-control v1-form-textarea mt-1 w-100' value={data.description}
                        disabled={!data.isEdit} onChange={(e) => setData({...data, description: e.target.value})} />
                </div>

                <div className='mb-3'>
                    <ImageCard imgUrl={data.imageUrl} name='imageLogo' width={'100%'} isEdit={data.isEdit}
                        setUrl={(url) => setData({...data, imageUrl: url})} />
                </div>

                <div className='surface-850 p-3 my-3'>
                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Card Title <span className='text-danger'>*</span></p>
                        <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.cardSection?.title}
                            disabled={!data.isEdit} onChange={(e) => setData(
                                {   ...data, cardSection: {
                                        ...data.cardSection, title: e.target.value
                                    }
                                }
                            )} />
                    </div>

                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Card Description <span className='text-danger'>*</span></p>
                        <textarea type={'text'} className='from-control v1-form-textarea mt-1 w-100' value={data.cardSection?.description}
                            disabled={!data.isEdit} onChange={(e) => setData(
                                {   ...data, cardSection: {
                                        ...data.cardSection, description: e.target.value
                                    }
                                }
                            )} />
                    </div>

                    {data.cardSection?.points && data.cardSection.points?.map((eachMetric, i) => <div className='row mx-0 mb-3'>
                        <div className='col-md-11 ps-0'>
                            <div className='dfaic justify-content-between'>
                                <p className='text-300 fs-12px fw-400'>Text <span className='text-danger'>*</span></p>
                            </div>
                            <input type='text' className='form-control v1-form-control mt-1' disabled={!data.isEdit}
                                value={eachMetric} onChange={(e) => {
                                    handleChange(e.target.value, i)
                                }} />
                        </div>
                        <div className='col-md-1 my-auto'>
                            <div className='cursor br-8px mt-18px' onClick={() => handleDelete(i)}>
                                <Trash size={22} className='text-danger' />
                            </div>
                        </div>
                    </div>)}

                    {data.isEdit && <AddScenarioBtn type={'New Point'} className='w-100'
                        onClick={() => setData({...data, cardSection: {
                            ...data.cardSection, points: [...(data.cardSection?.points || []), '']
                        }})} />}
                </div>

                {data.isEdit && <div className='mb-3 w-fit dfaic pl-10px cursor'
                    onClick={() => setData({...data, showBackground: !data.showBackground})}>
                    <GlobalIcons type={data.showBackground ? 'form-check' : 'form-uncheck'} />
                    <p className='text-300 fs-12px fw-400 ps-2'> Show Background </p>
                </div>}
            </div>
        </div>
    )
}