import { CheckCircle } from 'phosphor-react'
import React from 'react'

export default function CustomImage2({data, className}) {
    return (
        <div className={`${data.showBackground ? 'bg-base-100' : 'bg-currentBackground'}`}>
            <div className='container'>
                <div className={`customImageWrapper px-0px py-40px m-0`}>
                    <div className='customImage1 py-80px w-100 dfaic mx-auto h-100'>
                        <div className='leftPanel mx-0 ps-5 pe-0'>
                            <img src={data.imageUrl} className='d-block mx-auto br-12px'
                                style={{objectFit: 'cover', width: '100%', maxHeight: 260}} alt='' />

                            <div className='mt-38px'>
                                <p className='fw-600 fs-32px text-content-color' style={{lineHeight: '130%'}}>
                                    {data.heading}
                                </p>

                                <p className='fw-400 mt-10px fs-16px text-content-subtle' style={{lineHeight: '150%'}}>
                                    {data.description}
                                </p>
                            </div>
                        </div>

                        <div className='rightPanel mx-0 pe-4'>
                            <div className='bg-secondary-200 w-100 h-100 br-12px px-91px py-61px'>
                                <p className='text-content-color fs-32px fw-600'
                                    style={{lineHeight: '130%'}}>
                                    {data.cardSection?.title || ''}
                                </p>

                                <p className='mt-10px fs-16px text-content-subtle fs-16px'>
                                    {data.cardSection?.description || ''}
                                </p>
                                {(data.cardSection?.points && data.cardSection?.points?.length > 0) && <div className='mt-14px'>
                                    {data.cardSection?.points?.map((eachMetric, i) => <div className='dfaic text-truncate mb-10px' key={i}>
                                        <CheckCircle weight='fill' color='var(--primary-color)' size={16} />
                                        <p className='ml-14px fs-16px fw-500 text-content-subtle'>
                                            {eachMetric}
                                        </p>
                                    </div>)}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
