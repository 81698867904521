import React, { useCallback } from "react";
import { useEffect, useState, useRef } from "react";
import { Chart, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { NumberFormatter, debounceFn, formatNumber } from "../../utils/helper";

Chart.register(ArcElement);

const createRadialGradient3 = (ctx, area, color1, color2) => {
    const centerX = (area.left + area.right) / 2;
    const centerY = (area.top + area.bottom) / 2;

    const r = Math.min((area.right - area.left) / 2, (area.bottom - area.top) / 2);

    const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
    gradient.addColorStop(0.5, color2);
    gradient.addColorStop(1, color1);

    return gradient;
};

const getTotal = (data) => {
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    return total;
};

const DoughnutChart = (props) => {
    const [currIndex, setCurrIndex] = useState(0);
    const [data, setData] = useState(null);
    const chartRef = useRef(null);

    const hideTooltip = (el) => {
        el.style.display = "none";
    }

    const hideTooltipDebounceFn = useCallback(debounceFn(hideTooltip,200), []);

    const generateTooltipHTML = (context) => {
        const tooltipEl = chartRef.current;
        const tooltipModel = context.tooltip;
    
        if (tooltipModel.opacity === 0) {
          hideTooltipDebounceFn(tooltipEl);
          return;
        }
    
        if(props.customTooltip) props.customTooltip({tooltipEl, tooltipModel})
        
      };

    const updateChartData = (data) => {
        const total = getTotal(data);
        return {
            maintainAspectRatio: false,
            responsive: false,
            datasets: [
                {
                    customData: data.map((x) => x.customData),
                    label: data.map((x) => x.name),
                    actualData: data.map((x) => props.currencySymbol + " " + NumberFormatter(x.value, 2, props.currecnyWeight, true)),
                    data: data.map((x) => {
                        let percent = (x.value / total) * 100;
                        if (percent === 0) return 0;
                        else if (percent < 5) {
                            return Math.ceil(percent);
                        }
                        return percent;
                    }),
                    backgroundColor: function (context) {
                        let c = data[context.dataIndex]?.color;
                        if (!c) {
                            return;
                        }
    
                        if (typeof c === "string") return c;
                        let stepColors = [];
                        if (c.start) stepColors.push(c.start);
                        if (c.middle) stepColors.push(c.middle);
                        if (c.end) stepColors.push(c.end);
                        return createRadialGradient3(context.chart.ctx, context.chart.chartArea, ...stepColors);
                    },
                },
            ],
        };
    };

    const options = {
        plugins: {
            tooltip: {
                enabled: !!props.showTooltip,
                position: "nearest",
                caretPadding: 8,
                padding: 8,
                titleMarginBottom: 8,
                displayColors: false,
                callbacks: {
                    title: function (context) {
                        let dataIndex = context[0].dataIndex;
                        return context[0].dataset.label[dataIndex];
                    },
                    label: function (context) {
                        let dataIndex = context.dataIndex;

                        return `${context.dataset.actualData[dataIndex]} ${props?.tooltipLabel || 'Request'}`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: props.paddingTop || 0,
                right: props.paddingRight || 0,
                left: props.paddingLeft || 0,
                bottom: props.paddingBottom || 16,
            },
        },
        cutout: props.cutout || 80,
        responsive: true,
        maintainAspectRatio: false,
        borderWidth: props.borderWidth || 0,
        borderColor: "#303135",
        rotation: props.isCatalog ? 0 : -60,
        hoverOffset: props.isCatalog ? 0 : 4,
        borderRadius: {
            outerStart: 0,
            outerEnd: 0,
            innerStart: 0,
            innerEnd: 0,
        },
        onHover: (e, i) => {
            if (props.clickable) {
                const dataIndex = i[0] ? i[0].index : null;
                if (dataIndex !== null) {
                    setCurrIndex(dataIndex);
                }
            }
        },
    };

    useEffect(() => {
        const chart = chartRef.current;

        for (let index in props.series || []) {
            if (props.series[index].value) {
                setCurrIndex(Number(index));
                return;
            }
        }

        if (!chart) {
            return;
        }

        //setData(chartData);
    }, []);

    useEffect(() => {
        setData(updateChartData(props.series));
    }, [props.updateChart]);

    return (
        <div className={`h-100 position-relative ${props.className || ""}`}>
            {props.children ? (
                props.children
            ) : !props.showTotal && currIndex !== null && props.series[currIndex] ? (
                <div className="chart-text-container">
                    <div style={props.pdf ? { color: "#272727" } : {}}>{props.series[currIndex].name}</div>
                    <div style={props.pdf ? { color: "#272727" } : {}}>{props.formatIt ? formatNumber(props.series[currIndex].value) : props.series[currIndex].value}</div>
                </div>
            ) : null}
            {props.showTotal ? (
                <div style={{ height: props.isCatalog ? "calc(100%)" : "100%" }} className={`chart-text-container ${props.isCatalog ? "d-flex justify-content-center align-items-center" : ""}`}>
                    {!props.isCatalog && <div>{props.totalTitle || "Total"}</div>}
                    <div>{props.formatIt ? formatNumber(getTotal(props.series)) : getTotal(props.series)}</div>
                </div>
            ) : null}
            {data && <Doughnut className="h-100 position-absolute" ref={chartRef} data={data} options={options}></Doughnut>}
        </div>
    );
};

export default DoughnutChart;
