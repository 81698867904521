import React, { useState } from "react";
import { CaretRight } from "phosphor-react";
import CaretDown from "../components/CaretDown";
import { useSelector } from "react-redux";

const ResponseHeaderParams = ({ }) => {
  const [open, setOpen] = useState(false);
  const {tryoutOperation} = useSelector(state => state.swagger);
  
  return (
    <div className="pl-10px pr-6px py-6px bg-theme-base br-4px d-flex flex-column gap-4px">
      <div
        className={`cursor d-flex justify-content-between align-items-center w-100 pr-6px br-4px h-34px ${
          open ? "" : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`d-flex align-items-center gap-4px`}>
          {!open ? (
            <CaretRight
              color="var(--primary-color)"
              size={13}
              className="mr-4px"
            />
          ) : (
            <CaretDown />
          )}
          <div
            className={`text-theme-primary fs-13px lh-21px h-21px ${
              open ? "fw-600" : ""
            }`}
          >
            Header Params
          </div>
        </div>
      </div>
      {open ? (
        <table className="ml-24px">
            <tbody>
                {Object.keys((tryoutOperation.__result||{}).headers || {}).map(key => {
                    return (
                        <tr className="param-card tryout-response ">
                            <td className="fs-14px fw-400 param-card-label py-8px">{key}</td>
                            <td className="fs-14px fw-600 pl-8px py-8px">{(tryoutOperation.__result||{}).headers[key]}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default ResponseHeaderParams;
