import React from "react";

const Loading = ({size = ''}) => {
  return (
    <div
      className="h-100 loader w-100 d-flex justify-content-center align-items-center"
    >
      <div className={`spinner-border text-primary ${size ? `spinner-border-${size}` : ''}`} role="status"></div>
    </div>
  );
};

export default Loading;