import React from 'react';
import {useRef} from 'react';


const Dropdown = (props) => {
    
    return (
          <div ref={props.menuRef} className={`position-absolute ${props.styles} zIndex-100`}>
            {props.list.map((item, i)=>
               ( <div key={i} className='px-15px py-8px hover-surface-900' onClick={()=>{
                props.setSelected(item)
                }}>
                    <p className='cursor-pointer fs-14px text-300'>{item}</p>
                </div>))}
        </div>
    );
};

export default Dropdown;