import React, { useEffect, useState } from 'react'
import GetIcon from '../../../components/GetIcon'
import { ArrowRight } from 'phosphor-react'

export default function CustomCard3({data, fromEditor}) {

    return (
        <div className={'position-relative'}>
            {data.imageUrl && <div className='overlayImageFromCard v2'>
                {/* <img src={data.imageUrl} alt={data.heading} className='h-100 w-100' /> */}
                <div className='h-100' style={{
                    background: `url(${data.imageUrl}), lightgray 50% / cover no-repeat`,
                    zIndex: 3, position: 'relative'
                }}>
                
                <div className='rectangleTop'></div>
                </div>
            </div>}
            <div className='container'>
                <div className={`bg-currentBackground zIndex-4 row m-0 px-0px py-60px`}> 
                    <div className='position-relative zIndex-5'>
                        {data.tagName && <div className='titleTagPartner mb-55px d-block mx-auto'>
                            <p className='text-theme-base fs-16px fw-600 text-center'>{data.tagName}</p>
                        </div>}
                        <p className='fs-36px fw-600 text-base-color text-center' style={{lineHeight: '110%'}}>
                            {data.heading}
                        </p>
                    </div>

                    <div className='eachCardWrapper v2 mb-56px mt-10px'>
                        {data.showGradients && <div className='gradientBackgroundAnimation'></div>}
                        {data.cards?.map((eachCard, i) => <div 
                            className={`eachLandingCardv2 ${data.showBorder ? 'hover-effects' : 'no-hover-effects'}`} key={i}>
                            <div>
                                
                                <div className='mt-28px mb-16px dfaic'>
                                    <GetIcon type={eachCard.icon} size={52} weight={eachCard.iconWeight}
                                        color={data.fillPrimaryColor ? 'var(--secondary-color)' : `var(--content-color)`} />
                                    <p className='fs-32px ml-20px fw-700 text-base-color text-truncate-2'
                                        style={{lineHeight: '130%'}}>
                                        {eachCard.title}
                                    </p>
                                </div>

                                <p className='fs-16px fw-400 text-base-100 text-truncate-3' 
                                    style={{lineHeight: '160%'}} title={eachCard.description}>
                                    {eachCard.description}
                                </p>
                            </div>

                            <div className='d-flex align-items-center flex-wrap gap-8px mt-12px'>
                                <a href={eachCard.primaryCTA?.url || '#'} target='_blank' className='dfaic'>
                                    <p className='fs-16px fw-600 text-secondary-color'>{eachCard.primaryCTA?.label || ''}</p>
                                    <ArrowRight color='var(--secondary-color)' className='ml-4px' size={21} />
                                </a>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}
