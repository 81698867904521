import GlobalStatusTag from "../../../../components/GlobalStatusTag"
import TagNames from "../../../../components/TagNames"
import GlobalIcons from "../../../../components/Icons/GlobalIcons"
import UserIcons from "../../../../components/Icons/UserIcons"
import MyReactTooltip from "../../../../components/ReactTooltip"
import { replacePartnerIdWithDisplayName, toPascalCase,confirm } from "../../../../utils/helper"
import { useEffect, useState } from "react"
import EmptyContainer from "../../../../components/EmptyContainer"
import { useSelector } from "react-redux";
import moment from "moment";
import RoleModal from "./RoleModal";
import API from "../../../../api"
import { getToast } from "../../../../components/Toast"

export default function AttriibuteRoleTable(props){

    const { partnersList: partners,roles} = useSelector((state) => state.user);
    const [roleList,setRoleList] = useState([]);
    const [editModal,showEditModal] = useState(false);
    const [index,setIndex]=useState({});

    console.log("roles",roles);
   
    function getFirstLetterOfString(text){
        if (!text) return ''
        return text.charAt(0)
    }

    useEffect(()=>{
        setRoleList(roles)
    },[roles])

    const handleServerSideError = (error) => {
        if (error.response) {
            if (error.response.data.message) {
                getToast({statusType: 'ERROR', message: error.response.data.message})
            }
        } else {
            getToast({statusType:"ERROR",message: error.message })
        }
    }


    function handleEditRole(index){
       showEditModal((prev)=>!prev)
       setIndex(index)
    }

    

    return <>
        <div className='surface-875 mx-0 d-flex p-2 mt-2 ps-3'>
            <div className="w-25 fs-12px fw-500 text-400 d-flex">
                <p>Name</p>
            </div>
            <div className="w-25 fs-12px fw-500 text-400 d-flex justify-content-center">
                <p>Code</p>
            </div>
            <div className="w-30 fs-12px fw-500 text-400 d-flex justify-content-center">
                <p>Created</p>
            </div>
            <div className="w-20 fs-12px fw-500 text-400 d-flex justify-content-center">
                <p></p>
            </div>
        </div>

        <div className='overflow-scroll noscrollbar h-65vh'>
            {roles?.length > 0 ? 
                roles.map((item,index) => (
                    
                    <div className='surface-900 mx-0 d-flex ps-3 pt-15px pb-15px userCard--tableRow v1-border-bottom-1 hover-surface-850' key={item._id}>
                        <div className='w-25 my-auto'>
                            <div className='d-flex'>
                                <div className='my-auto'>
                                    <div className='userCircle surface-850'>
                                        <p className='fw-400 fs-14px text-300 text-uppercase'>
                                             {getFirstLetterOfString(item.name || '')}
                                        </p>
                                    </div>
                                </div>
                                <div className='pl-8px d-flex align-items-center'>
                                    <div className='me-2'>
                                        <div className="d-flex">
                                            <p className='text-200 fs-14px fw-500 text-truncate'>
                                                {item.name || ''}
                                            </p>
                                        </div>
                                        <p className='text-400 fs-12px fw-400 text-truncate'>
                                            {item.description}
                                        </p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className='w-25 my-auto d-flex justify-content-center'>
                            <div className='d-flex'>
                               <p className="fs-14px fw-400">{item.code || ''}</p>
                            </div>
                        </div>
                        <div className='w-30 d-flex align-items-center justify-content-center'>
                            <div className="">
                                <p className="text-200 fs-14px fw-400">{moment.utc(item.createdAt).format("HH:mm A - DD/MM/YYYY")}</p>
                            </div>
                        </div>
                        {(props.showEdit && item.code !== 'super-admin' && item.code !== 'admin' && item.code !== 'guest') && <div className='w-20 d-flex pr-25px my-auto align-items-center justify-content-end '>
                                <div className='cursor pe-1' data-tip="edit" data-for={"edit"} onClick={()=>{handleEditRole(index)}}>
                                    <div className='btn-sm-v1 me-2'>
                                        <GlobalIcons type={'pencil'} />
                                    </div>
                                    <MyReactTooltip id={"edit"} />
                                </div>
                        </div>}
                </div>
                ))
            
            : (
                <div className="h-100 d-flex justify-content-center align-items-center">
                   <EmptyContainer/>
                </div>
            ) 
        
        }
        </div>
        {
            editModal && <RoleModal 
                show={editModal}
                onHide={handleEditRole}
                isEdit={true}
                getRoles={props.getRoleData}
                index={index}
                showDelete={props.showDelete}
            />

        }
    </>
}