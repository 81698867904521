import { BodyLayout } from "../../../layout/Base";
import { useNavigate, useOutletContext } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import Loading from "../../../components/Loader/Loading";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import UserIcons from "../../../components/Icons/UserIcons";
import {
  setPartners,
  getAllUserRoles,
} from "../../../app/Features/Users/UserSlice";
import API from "../../../api";
import { useDispatch } from "react-redux";
import {
  calHeight,
  getPermissionByCrud,
  getPermissionsArray,
  throwServerError,
} from "../../../utils/helper";
import { GUIDE_STATUS, STATUS, VIEW_OPTIONS } from "../../../service/constants";
import { getToast } from "../../../components/Toast";
import {
  ApplePodcastsLogo,
  Eye,
  Funnel,
  GridFour,
  List,
  PencilSimple,
  Plus,
  Rows,
  SquaresFour,
} from "phosphor-react";
import GUIDE_API from "../../../api/guides";
import MyTable from "../../../components/MyTable";
import { getGuidesTable } from "../../../models/mytable";
import CategoryModal from "./CategoryModal";
import AddOrUpdateModal from "./AddOrUpdateModal";
import { objectDeepClone } from "@apiwiz/oas/services";
import GuideGrid from "./components/GuideGrid";
import Searchbar from "../../../components/Searchbar";

export default function ManageGuides() {
  const { setNavigation, setTitle, setHasHeader } = useOutletContext();
  const [activeTab, setActiveTab] = useState(GUIDE_STATUS.published);
  const [displayView, setDisplayView] = useState(VIEW_OPTIONS.list);
  const [categoryModal, setCategoryModal] = useState(false);
  const [addOrUpdateModal, setAddOrUpdateModal] = useState({
    isOpen: false, isEdit: false, data: {}
  });
  const [subLoading, setSubLoading] = useState(false)

  const [publishedGuides, setPublishedGuides] = useState({
    rows: [],
    cols: [],
  });

  const [searchedGuides, setSearchedGuides] = useState({
    rows: [],
    cols: [],
  });

  const [draftGuides, setDraftGuides] = useState({ rows: [], cols: [] });
  const paging = useRef({
    [STATUS.published]: {
      pageSize: 10,
      offset: 1,
      total: 0,
    },
    [STATUS.draft]: {
      pageSize: 10,
      offset: 1,
      total: 0,
    },
    [STATUS.search]: {
      pageSize: 10,
      offset: 1,
      total: 0,
    },
  });

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const {
    partnersList: partners,
    currentUser,
  } = useSelector((state) => state.user);
  const { firstRoute } = useSelector((state) => state.user);
  const [searchVal, setSearchVal] = useState('')

  useEffect(() => {
    // getRoleData();
  }, [currentPage]);

  const getPublishedGuides = () => {
    setLoading(true)
    GUIDE_API.getGuides(
      {
        currentPage: paging.current[STATUS.published].offset,
        status: STATUS.published,
        pageLimit: paging.current[STATUS.published].pageSize
      }
    )
      .then((res) => {
        let _paging = {
          ...paging.current,
          [STATUS.published]: {
            ...paging.current[STATUS.published],
            total: res.paging?.total || 0,
          },
        };
        paging.current = _paging;
        setLoading(false)
        setPublishedGuides(getGuidesTable(res.data || []));
      })
      .catch((err) => {
        setLoading(false)
        throwServerError(err);
      });
  };

  const getDraftGuides = () => {
    GUIDE_API.getGuides({
        currentPage: paging.current[STATUS.draft].offset,
        status: STATUS.draft,
        pageLimit: paging.current[STATUS.draft].pageSize
      })
      .then((res) => {
        let _paging = {
          ...paging.current,
          [STATUS.draft]: {
            ...paging.current[STATUS.draft],
            total: res.paging?.total || 0,
          },
        };
        paging.current = _paging;
        setDraftGuides(getGuidesTable(res.data || []));
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  useEffect(() => {
    setTitle("Guides");
    setNavigation([{ name: "Dashboard", link: firstRoute }, { name: "Guides" }]);
    setHasHeader(true)
    getPublishedGuides();
    getDraftGuides();
  }, []);

  const navigate = useNavigate()
  /*
  function handleRoleModal() {
    setShowRoleModal((prev) => !prev);
  }
  */

  /* ACL Start */
  const {permission} = useSelector(state => state.user)

  let _permission = permission.filter(o => o.name === 'guides')
  _permission = _permission.length ? (_permission[0].crud || []) : []
  const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
  const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
  const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')

  let _guidePermission = permission.filter(o => o.name === 'guide-category')
  _guidePermission = _guidePermission.length ? (_guidePermission[0].crud || []) : []
  const getReadPermissionGuide = getPermissionByCrud(currentUser, _guidePermission, 'read')
  /* ACL END */

  return (
    <BodyLayout style={{ paddingBottom: 0 }} bottomSpaceMinus="26">
      <div className="fadeIn h-100 w-100">
        <div className="bottomPanel h-100">
          <div className="userCard--body h-100">
            <div className="surface-875 pl-16px pr-8px py-8px d-flex justify-content-between align-items-center v1-border-bottom-05 h-52px">
              <div>
                <p className="fs-18px fw-600">Manage Guides</p>
              </div>
              <div className="d-flex gap-12px">
                {getReadPermissionGuide && <div
                  className="v1-border-1 surface-850 px-20px py-10px btn"
                  onClick={() => setCategoryModal(true)}
                >
                  <p className="text-300 fw-500 fs-12px">Manage Category</p>
                </div>}
                <div className="br-8px surface-base p-2px d-flex align-items-center justify-content-between cursor w-100px">
                  <div
                    className={`px-12px py-4px ${
                      displayView === VIEW_OPTIONS.list
                        ? "v1-border-1 br-6px surface-850 activeSVG"
                        : "inactiveSVG"
                    }`}
                    onClick={() => setDisplayView(VIEW_OPTIONS.list)}
                  >
                    <Rows size={24} strokeWidth={0} />
                  </div>

                  <div
                    className={`px-12px py-4px ${
                      displayView === VIEW_OPTIONS.grid
                        ? "v1-border-1 br-6px surface-850 activeSVG"
                        : "inactiveSVG"
                    }`}
                    onClick={() => setDisplayView(VIEW_OPTIONS.grid)}
                  >
                    <SquaresFour size={24} strokeWidth={0} />
                  </div>
                </div>
                {/* Filter btn section hiding for now*/}
                <div className="d-none v1-border-1 br-4px surface-850 d-flex align-items-center">
                  <div className="py-8px px-12px v1-border-right-1 d-flex align-items-center">
                    <Funnel />
                    <p className="text-200 fs-14px fw-400 ms-2">Filter</p>
                  </div>
                  <div className="py-8px px-12px">
                    <p className="text-300 fs-14px fw-400">1</p>
                  </div>
                </div>

                <div style={{width: 300}}>
                  <Searchbar value={searchVal} onChange={(e) => {
                    setSubLoading(true)
                    setSearchVal(e)
                    if (!e) {
                      setActiveTab(GUIDE_STATUS.published)
                      setSubLoading(false)
                    }
                    else {
                      setActiveTab(GUIDE_STATUS.search)

                      GUIDE_API.getGuides({
                        currentPage: paging.current[STATUS.draft].offset,
                        searchtext: e,
                        pageLimit: paging.current[STATUS.draft].pageSize
                      })
                      .then((res) => {
                        let _paging = {
                          ...paging.current,
                          [STATUS.search]: {
                            ...paging.current[STATUS.search],
                            total: res.paging?.total || 0,
                          },
                        };
                        paging.current = _paging;
                        setSearchedGuides(getGuidesTable(res.data || []));
                        setSubLoading(false)
                      })
                      .catch((err) => {
                        throwServerError(err);
                        setSubLoading(false)
                      });
                    }
                  }} />
                </div>

                {getCreatePermission && <div className="d-flex align-items-center">
                  <div
                    className="btn v1-btn-primary p-2"
                    onClick={() => setAddOrUpdateModal({
                      isOpen: true, isEdit: false, data: {}
                    })}
                  >
                    <Plus size={16} color="var(--dark-text-300)" />
                    <p className="text-200 ps-2 fs-12px fw-500">Add Guides</p>
                  </div>
                </div>}
              </div>
            </div>

            <div className="surface-875 h--52px manage-guide-tabs">
              {loading 
              ? <Loading />
              : <Tabs
                activeKey={activeTab}
                onSelect={(key) => {
                  if (key !== activeTab) setActiveTab(key);
                }}
                className="h-46px"
              >
                <Tab
                  tabClassName="tabs-btn-2"
                  eventKey={GUIDE_STATUS.published}
                  title={`${GUIDE_STATUS.published} ${
                    paging.current[STATUS.published].total || 0
                      ? `(${paging.current[STATUS.published].total})`
                      : ""
                  }`}
                >
                  <div className="h-100">
                    {displayView === VIEW_OPTIONS.list
                    ? <MyTable
                      cols={publishedGuides.cols}
                      rows={publishedGuides.rows}
                      onRowClick={el => {
                        navigate(`/admin/guides/${el.slug}`)
                      }}
                      rowActions={getUpdatePermission ? [
                        {
                          btn: (
                            <div className="btn-md-850 px-8px">
                              <PencilSimple size={14} />
                            </div>
                          ),
                          action: (el) => {

                            setAddOrUpdateModal({
                              isOpen: true, isEdit: true, data: objectDeepClone(el)
                            });
                          },
                        },
                      ] : []}
                      pagination={paging.current[STATUS.published]}
                      onPageChanged={(e) => {
                        paging.current[STATUS.published].offset = e;
                        getPublishedGuides();
                      }}
                    />
                    : <div className="p-24px">
                      <div className="d-flex align-items-center justify-content-center flex-wrap gap-24px">
                        {publishedGuides.rows.map(el => <GuideGrid onClick={(_) => {
                          navigate(`/admin/guides/${el.slug}`)
                        }} data={el} handleEdit={() => {
                            setAddOrUpdateModal({
                              isOpen: true, isEdit: true, data: objectDeepClone(el)
                            });
                          }} getUpdatePermission={getUpdatePermission} key={el._id} />)}
                      </div>
                      <div className="mt-16px">
                        <Pagination
                          cb={(e) => {
                            paging.current[STATUS.published].offset = e;
                            getPublishedGuides();
                          }}
                          pagination={paging.current[STATUS.published]}
                        />{" "}
                      </div>
                    </div>}
                  </div>
                  {/* {loading ? <div className="h-73vh"><Loading/></div> : showList && <AttriibuteTable/>} */}
                </Tab>
                <Tab
                  tabClassName="tabs-btn-2"
                  eventKey={GUIDE_STATUS.draft}
                  title={`${GUIDE_STATUS.draft} ${
                    paging.current[STATUS.draft].total || 0
                      ? `(${paging.current[STATUS.draft].total})`
                      : ""
                  }`}
                >
                  {displayView === VIEW_OPTIONS.list
                  ? <MyTable
                    cols={draftGuides.cols}
                    rows={draftGuides.rows}
                    onRowClick={el => {
                      navigate(`/admin/guides/${el.slug}`)
                    }}
                    rowActions={getUpdatePermission ? [
                      {
                        btn: (
                          <div className="btn-md-850 px-8px">
                            <PencilSimple size={14} />
                          </div>
                        ),
                        action: (el) => {
                          console.log("row action el:", el);
                          setAddOrUpdateModal({
                            isOpen: true, isEdit: true, data: objectDeepClone(el)
                          });
                        },
                      },
                    ] : []}
                    pagination={paging.current[STATUS.draft]}
                    onPageChanged={(e) => {
                      paging.current[STATUS.draft].offset = e;
                      getDraftGuides();
                    }}
                  /> : <div className="p-24px">
                      <div className="d-flex align-items-center justify-content-center flex-wrap gap-24px">
                        {draftGuides.rows.map(el => <GuideGrid onClick={(_) => {
                          navigate(`/admin/guides/${el.slug}`)
                        }} data={el} handleEdit={() => {
                          setAddOrUpdateModal({
                            isOpen: true, isEdit: true, data: objectDeepClone(el)
                          });
                        }} getUpdatePermission={getUpdatePermission} key={el._id} />)}
                      </div>

                      <div className="mt-16px">
                        <Pagination
                          cb={(e) => {
                            paging.current[STATUS.draft].offset = e;
                            getDraftGuides();
                          }}
                          pagination={paging.current[STATUS.draft]}
                        />{" "}
                      </div>
                    </div>}
                  {/* {loading ? <div className="h-73vh"><Loading/></div> : showList && <AttriibuteRoleTable getRoleData={getRoleData} showEdit={showEdit} showDelete={showDelete}/>} */}
                </Tab>

                {searchVal && <Tab
                  tabClassName="tabs-btn-2"
                  eventKey={GUIDE_STATUS.search}
                  title={`${GUIDE_STATUS.search} ${
                    paging.current[STATUS.search].total || 0
                      ? `(${paging.current[STATUS.search].total})`
                      : ""
                  }`}
                >
                  <div className="h-100">
                    {subLoading 
                    ? <div className="h-50vh">
                      <Loading />
                    </div>
                    : displayView === VIEW_OPTIONS.list
                    ? <MyTable
                      cols={searchedGuides.cols}
                      rows={searchedGuides.rows}
                      onRowClick={el => {
                        navigate(`/admin/guides/${el.slug}`)
                      }}
                      rowActions={getUpdatePermission ? [
                        {
                          btn: (
                            <div className="btn-md-850 px-8px">
                              <PencilSimple size={14} />
                            </div>
                          ),
                          action: (el) => {

                            setAddOrUpdateModal({
                              isOpen: true, isEdit: true, data: objectDeepClone(el)
                            });
                          },
                        },
                      ] : []}
                      pagination={paging.current[STATUS.search]}
                      onPageChanged={(e) => {
                        paging.current[STATUS.search].offset = e;
                        getPublishedGuides();
                      }}
                    />
                    : <div className="p-24px">
                      <div className="d-flex align-items-center justify-content-center flex-wrap gap-24px">
                        {searchedGuides.rows.map(el => <GuideGrid onClick={(_) => {
                          navigate(`/admin/guides/${el.slug}`)
                        }} data={el} handleEdit={() => {
                            setAddOrUpdateModal({
                              isOpen: true, isEdit: true, data: objectDeepClone(el)
                            });
                          }} getUpdatePermission={getUpdatePermission} key={el._id} />)}
                      </div>
                      <div className="mt-16px">
                        <Pagination
                          cb={(e) => {
                            paging.current[STATUS.search].offset = e;
                            getPublishedGuides();
                          }}
                          pagination={paging.current[STATUS.search]}
                        />{" "}
                      </div>
                    </div>}
                  </div>
                  {/* {loading ? <div className="h-73vh"><Loading/></div> : showList && <AttriibuteTable/>} */}
                </Tab>}
              </Tabs>}
            </div>
          </div>
        </div>
      </div>
      {categoryModal && (
        <CategoryModal show={categoryModal}  onHide={() => setCategoryModal(false)} />
      )}
      {addOrUpdateModal.isOpen && (
        <AddOrUpdateModal
          show={addOrUpdateModal.isOpen}
          isEdit={addOrUpdateModal.isEdit}
          onHide={() => {
            setAddOrUpdateModal({
              isOpen: false, isEdit: false, data: {}
            })
          }} getDeletePermission={getDeletePermission}
          callback={() => {
            getPublishedGuides();
            getDraftGuides();
          }}
          data={addOrUpdateModal.data}
        />
      )}
    </BodyLayout>
  );
}
