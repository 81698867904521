import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import MonetizeTrend from "../components/MonetizeTrend";
import { LayoutViews, ROUTE_NAMES, SRC_PARAM_VALUES, URL_PARAMS } from "../../../../service/constants";
import MonetizeAppCard from "../components/MonetizeAppCard";
import MonthYearPicker from "../components/MonthYearPicker";
import monetizationV2 from "../../../../api/monetizationV2";
import { ArrowUpRight, CurrencyCircleDollar, Eye, File, Info, Trash, TrendUp } from "phosphor-react";
import { EmptyState } from "../../../../components/EmptyState";
import MeteringRulesModal from "../components/MeteringRulesModal";
import { useLocation, useNavigate, useParams } from "react-router";
import { LineChart } from "../../../../components/Charts/LineChart";
import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import MonetizationIcons from "../../../../components/Icons/MonetizationIcons";
import { MonetizeDateFn, NumberFormatter, arrayToObject, confirm, constructFilterResources, defaultCurrencyFn, getDaysInAMonth, getPermissionByCrud, objectDeepClone, pluralize, showSuccessMessage, throwServerError } from "../../../../utils/helper";
import MyReactTooltip from "../../../../components/ReactTooltip";
import API from '../../../../api'
import APIReferenceListModal from "../components/APIReferenceListModal";
const RevenueData = [
    {
        name: "Daily Usage",
        value: [],
        options: { fill: true, borderColor: "#63C174", radius: 0, bgColor: true, bgStart: "#0FCA7A", bgEnd: "rgba(15, 202, 122, 0.00)" },
    },
];

export default function SubscribedPackageDetail() {
    const navigate = useNavigate();
    const { packageId } = useParams()
    const { currentUser, permission } = useSelector((state) => state.user)
    const [activeView, setActiveView] = useState(LayoutViews.GRID);
    const TABS = ["Package Details", "Billing Details"];
    const [activeTab, setActiveTab] = useState(TABS[0]);
    const currency = currentUser?.billing?.billingCurrency?.currencySymbol || currentUser?.billing?.billingCurrency?.currencyCode || ''
    const [numberOfDayInMonth, setNumberOfDayInMonth] = useState(getDaysInAMonth(new Date()));
    const [rulesModal, setRulesModal] = useState({
        isOpen: false, data: {}
    })

    const { search } = useLocation();
    const _queryPrams = new URLSearchParams(search);
    const prevDate = _queryPrams.get("date");
    const pageType = _queryPrams.get("type");
    const appId = _queryPrams.get("appId");
    const _id = _queryPrams.get("_id");
    const subscriptionId = _queryPrams.get("subscriptionId")
    const [packageData, setPackageData] = useState({})
    const [appsList, setAppsList] = useState([])
    const [mainLoader, setMainLoader] = useState(false)
    const [appDetails, setAppDetails] = useState({})
    const [selectedDate, setSelectedDate] = useState(prevDate ? new Date(prevDate) : new Date());
    const [currentPackage, setCurrentPackage] = useState([])
    const [loaders, setLoaders] = useState({
        overall: false,
        resourceMetric: false,
        chart: false,
        apps: false
    })
    const [metricData, setMetricData] = useState({
        overall: "",
        resourceMetric: {},
        chart: RevenueData
    })
    const [currentCurrency, setCurrentCurrency] = useState({})
    const [references, setReferences] = useState({})
    const [referenceListModal, setReferenceListModal] = useState({
        isOpen: false, data: {}
    })

    /** ACL */
    let _monetizationPermission = permission.filter(o => o.name === 'subscription')
    _monetizationPermission = _monetizationPermission.length ? (_monetizationPermission[0].crud || []) : []
    const listSubs = getPermissionByCrud(currentUser, _monetizationPermission,"read");
    const createSubs = getPermissionByCrud(currentUser, _monetizationPermission,"create");
    const deleteSubs = getPermissionByCrud(currentUser, _monetizationPermission,"delete");
    /** ACL */

    const handleApiReference = () => {
        // let _set = new Set([]);
        // if(packageData && packageData.resources && packageData.resources.length > 0){
        //     for (const _res of packageData.resources) {
        //         _set.add(_res.swaggerId)
        //     }
        // }

        // navigate(`/api-references?swaggers=${[..._set].join(',')}`)
        const references = packageData.resources

        let filterParams = constructFilterResources({resources: references})
        let url;

        references.forEach(el => {
            url = `${window.location.origin}/${ROUTE_NAMES.API_REFERENCE}/${el.oasVersion}/${el.swaggerId}/${el.swaggerRevision}`
            if(filterParams) url+=`?${URL_PARAMS.SRC}=${SRC_PARAM_VALUES.PACKAGE}&${URL_PARAMS.API_REFERENCE}=${filterParams}`
        })
        window.open(url, "_blank")
        return;
    }

    const calcPercentage = (current, total) => {
        if(!current) return 0;
        return ((current / total) * 100).toFixed(2) + " %";
    }

    const getAppDetails = () => {
        API.getDeveloperAppById(_id)
        .then(({data: res}) => {
            setAppDetails(res.data)
        })
        .catch((err) => {
            throwServerError(err)
        })
    }

    const getCurrencies = () => {
        API.getMasterCurrencies({status: 'PUBLISH'})
        .then(res => {
            console.log('res', res.data.data)
            let _data = res.data?.data || []
            if (_data.length > 0) _data = _data[0].currencies || []

            let _modifiedData = []
            let labels = []

            _data.forEach((each) => {
                if (labels.includes(each.currencyName)) return
                _modifiedData.push({...each})
                labels.push(each.currencyName)
            })

            _modifiedData.push({...res.data.data?.defaultCurrency})

            _modifiedData.forEach((each) => {
                if (each.currencyCode === currentUser.billing?.billingCurrency?.currencyCode 
                    && each.currencyName === currentUser.billing?.billingCurrency?.currencyName )
                {
                    setCurrentCurrency(objectDeepClone(each))
                }
            })
        }).catch(err => {
            // throwServerError(err)
        })
    }
    
    const getMetricsData = (date) => {
        setLoaders((prev) => ({...prev, overall: true, resourceMetric: true, chart: true, apps: true}))
        let params = { month: date.currentMonth, year: date.currentYear, developerId: currentUser.email, packageId };

        if(pageType === 'app'){
            params = {...params, appId} 
        monetizationV2.fetchOverallFromApp(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, overall: false}))
                setMetricData((prev) => ({...prev, overall: res.data}))
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, overall: false}))
                throwServerError(err)
            })
        monetizationV2.fetchDailyRevenueFromApp(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, chart: false}))

                let revenueData = [];
                
                if (res.data && res.data.length > 0) {
                    const maxDate = Math.max.apply(Math, res.data.map(o => o.day));
                    let _sortedData = res.data.sort((a, b) => a.day - b.day); 
                    _sortedData = _sortedData.map((x) => ({...x, totalAmount: parseFloat(x.totalAmount * currentUser?.billing?.billingCurrency?.unitAmount || 0)}))

                    let preFillData=[], hashMap = arrayToObject('day', _sortedData)
                    for (const date of getDaysInAMonth(selectedDate)) {
                        if(!(date > maxDate)) {
                            if(hashMap.hasOwnProperty(date)) preFillData.push(hashMap[date])
                            else preFillData.push({totalAmount: 0, metered: 0, unMetered: 0, day: date})
                        }
                    }
                    revenueData = preFillData.map(((sum) => (value) =>{
                        sum += value.totalAmount;
                        return {sum, change: value.totalAmount}
                    })(0));
                }
                
                revenueData = { ...RevenueData[0], value: revenueData.map(el => el.sum), customData: {change: revenueData.map(el => el.change)} };
                setMetricData((prev) => ({...prev, chart: [revenueData]}))
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, chart: false}))
                throwServerError(err)
            })
        monetizationV2.fetchResourceFromApp(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, resourceMetric: false}))

                let _resourceMetric = {};
                if(res.data && res.data.length)
                    _resourceMetric = arrayToObject("resourceId", res.data)

                setMetricData((prev) => ({...prev, resourceMetric: _resourceMetric}))
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, resourceMetric: false}))
                throwServerError(err)
            })
        }
        else{
            monetizationV2.getPackageOverallRevenue(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, overall: false}))
                setMetricData((prev) => ({...prev, overall: res.data}))
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, overall: false}))
                throwServerError(err)
            })
        monetizationV2.getDailyPackageRevenue(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, chart: false}))

                let revenueData = [];
                
                if (res.data && res.data.length > 0) {
                    const maxDate = Math.max.apply(Math, res.data.map(o => o.day));
                    let _sortedData = res.data.sort((a, b) => a.day - b.day); 
                    _sortedData = _sortedData.map((x) => ({...x, totalAmount: parseFloat(x.totalAmount * currentUser?.billing?.billingCurrency?.unitAmount || 0)}))

                    let preFillData=[], hashMap = arrayToObject('day', _sortedData)
                    for (const date of getDaysInAMonth(selectedDate)) {
                        if(!(date > maxDate)) {
                            if(hashMap.hasOwnProperty(date)) preFillData.push(hashMap[date])
                            else preFillData.push({totalAmount: 0, metered: 0, unMetered: 0, day: date})
                        }
                    }
                    revenueData = preFillData.map(((sum) => (value) =>{
                        sum += value.totalAmount;
                        return {sum, change: value.totalAmount}
                    })(0));
                }
                
                revenueData = { ...RevenueData[0], value: revenueData.map(el => el.sum), customData: {change: revenueData.map(el => el.change)} };
                setMetricData((prev) => ({...prev, chart: [revenueData]}))
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, chart: false}))
                throwServerError(err)
            })
            monetizationV2.getPackageResourcesRevenue(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, resourceMetric: false}))

                let _resourceMetric = {};
                if(res.data && res.data.length)
                    _resourceMetric = arrayToObject("resourceId", res.data)

                setMetricData((prev) => ({...prev, resourceMetric: _resourceMetric}))
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, resourceMetric: false}))
                throwServerError(err)
            })
            monetizationV2.getAssociatedApps(params)
            .then(({data: res}) => {
                setLoaders((prev) => ({...prev, apps: false}))
                setAppsList(res.data)
            })
            .catch((err)=>{
                setLoaders((prev) => ({...prev, apps: false}))
                throwServerError(err)
            })
        }
    }

    const getPackageData = () => {
        setMainLoader(true)
        monetizationV2.getPackageById(packageId)
        .then(({data: res}) => {
            setMainLoader(false)
            let _references = {};
            for(let resource of res.data?.resources || [])
            {
                if(resource.swaggerId)
                {
                    let _key = `${resource.swaggerId}_${resource.oasVersion}_${resource.swaggerRevision}`;
                    if(!_references[_key])
                    {
                        _references[_key] = {
                            oasVersion: resource.oasVersion,
                            swaggerId: resource.swaggerId,
                            swaggerName: resource.swaggerName,
                            swaggerRevision: resource.swaggerRevision,
                            resources: []
                        }
                    }

                    _references[_key].resources.push({method: resource.resourceMethod, path: resource.resourcePath})
                }
            }
            console.log(_references,'_references');
            setReferences(_references);
            setPackageData(res.data)
            getMetricsData(MonetizeDateFn(selectedDate))
        })
        .catch((err)=>{
            setMainLoader(false)
            throwServerError(err)
        })
    }

    const handleViewMeteringRule = (data) => {
        // const date = MonetizeDateFn(selectedDate)
        // let params = { month: date.currentMonth, year: date.currentYear, developerId: currentUser.email, packageId, resourceId: data.resourceId };
        // monetizationV2.getPackageResourceMeteringRule(params)
        // .then(({data: res}) => {
        //     setRulesModal({isOpen: true, data: res.data || {}})
        // })
        // .catch((err)=>{
        //     throwServerError(err)
        // })
        setRulesModal({isOpen: true, data: data || {}})
    }
    
    const handleDateOnChange = (date) => {
        setNumberOfDayInMonth(getDaysInAMonth(date))
        setSelectedDate(date);
        getMetricsData(MonetizeDateFn(date));
    };

    const getSubscriptionById = () => {
        monetizationV2.getSubscriptionDetails({id: subscriptionId, developerEmail: currentUser.email})
        .then(({data: res}) => {
            setCurrentPackage(res?.data?.packages?.find(x => x.packageId === packageId))
        })
        .catch((err) => {
            throwServerError(err)
        })
    }

    const handlePackageDelete = () => {
        if(!confirm("Are you sure, you want to remove this package from your subscription?")) 
        return;

        setMainLoader(true)
        monetizationV2.removePackageFromSubscription({
            id: subscriptionId, developerEmail: currentUser.email,
            packageId: packageId
        }).then(res => {
            showSuccessMessage("Package is Queued to delete from this subscription")
            setMainLoader(false)
            navigate(-1)
        }).catch(err => {
            throwServerError(err)
            setMainLoader(false)
        })
    }

    const tooltipInnerHtml = ({changeVal, changeText, totalText}) => {
        let html = "";
        if(![null, undefined].includes(changeVal))
        {
            html += `<div class="d-flex align-items-center justify-content-center text-consumer-400 fs-12px fw-400 gap-2px">
                <div>Change: </div>
                <div class="text-nowrap">+ ${changeText}</div>
            </div>`;
        }  
        html += `<div class="d-flex align-items-center justify-content-center text-consumer-100 fs-12px fw-500 gap-2px ">
                <div>Total: </div>
                <div class="text-nowrap">${totalText}</div>
            </div>`;
        return html
    }

    const tooltipForRevenue = (tooltipItem) => {
        return tooltipInnerHtml({
            changeVal: (tooltipItem.dataset.customData?.change||[])[tooltipItem.dataIndex],
            changeText: `${currency} ${(tooltipItem.dataset.customData?.change||[])[tooltipItem.dataIndex]}`,
            totalText: `${currency} ${tooltipItem.dataset.data[tooltipItem.dataIndex]}`
        })
    }

    const customTooltip = (fn, {tooltipEl, tooltipModel}) => {
        const tooltipData = tooltipModel.dataPoints;

        tooltipEl.style.display = 'block';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${tooltipModel.caretX}px`;
        tooltipEl.style.top = `${tooltipModel.caretY - 84}px`;
      
        let tooltipHTML = '<div class="surface-consumer-base br-4px border-strokes-600-01">';
        tooltipHTML += `<div class="h-28px p-8px surface-consumer-900 d-flex align-items-center justify-content-center text-consumer-100 fs-12px br-tl-4px br-tr-4px fw-400 ">${tooltipModel.title} ${moment(selectedDate).format("MMM")}</div>`;
        tooltipHTML += `<div class="py-5px px-8px d-flex flex-column gap-6px">`;
        for (const tooltipItem of tooltipData) {
            if(fn) {
                tooltipHTML += fn(tooltipItem)
            }
        }
        tooltipHTML += `</div>`;
        tooltipHTML += '</div>';
      
        tooltipEl.innerHTML = tooltipHTML;
    }

    useEffect(() => {
        if(pageType === 'app'){
            setActiveTab(TABS[1])
            getAppDetails()
        } else {
            getSubscriptionById()
        }
        getPackageData()
        getCurrencies()
    }, [])

    return (
        <div className="w-100vw bg-currentBackground">
            <CustomNavbar />

            {mainLoader ? (
                <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh" />
            ) : (
            <div className="my-80px container w-100 min-h-100vh">
                <div className="d-flex align-items-center gap-4px">
                    <Link to="/my-account"><p className="fs-14px text-theme-primary fw-500">My Account / </p></Link>
                    {pageType === 'app' ? (
                        <><Link to="/apps"><p className="fs-14px text-theme-primary fw-500">My apps /</p></Link>
                        <p className="fs-14px text-theme-primary fw-500">Package Detail</p></>
                    ) : (
                        <><Link to={`/monetization/subscriptions/${subscriptionId}`}><p className="fs-14px text-theme-primary fw-500">Subscriptions /</p></Link>
                        <p className="fs-14px text-theme-primary fw-500">Package Detail</p></>
                    )}
                </div>

                <div className="mt-32px d-flex flex-column gap-12px">
                    <div className="d-flex align-items-center gap-21px">
                        <div className="d-flex flex-column gap-8px">
                            {pageType === 'app' ? <p className="text-consumer-300 fs-12px fw-400">Package</p> : null}
                            <div className="d-flex align-items-center gap-8px">
                                <p className="fw-600 fs-28px text-theme-content-color text-nowrap">{packageData?.packageName || "-"}</p>
                            </div>
                        </div>
                        
                        {pageType === 'app' ? (
                            <><p className="text-consumer-300 fw-400 fs-12px">in</p>
                            <div className="d-flex flex-column gap-5px">
                                <p className="fs-12px fw-400 text-consumer-300 text-nowrap">{appDetails?.name || "-"}</p>
                                <p className="fs-12px fw-500 text-consumer-300 text-truncate-2">{appDetails?.description || "-"}</p>
                            </div></>
                        ) : null}
                    </div>
                    {pageType !== 'app' ? (
                        <div className="d-flex align-items-start justify-content-between">
                            <p className="fs-16px fw-400 text-gray-subtext text-truncate-3">
                                {packageData?.summary || "-"}
                            </p>
                            {pageType !== 'app' && currentPackage?.status !== "QUEUED_FOR_DELETE" ? (
                                <div onClick={handlePackageDelete} style={{border: "1px solid #E5484D", marginTop: "-30px"}} className="cursor gap-10px br-4px py-10px px-16px d-flex align-items-center">
                                    <p className="fs-16px fw-600 text-nowrap" style={{color:"#E5484D"}}>Delete Package</p>
                                    <Trash size={22} color="#E5484D"/>
                                </div>
                            ) : null}
                            {/* <div onClick={handleApiReference} className="cursor hover-underline-bottom fs-14px gap-12px fw-600 text-theme-primary text-nowrap d-flex align-items-center">
                                <p>API Reference</p>
                                <TrendUp color="var(--primary-color)" />
                            </div> */}
                        </div>
                    ) : null}
                </div>

                {pageType !== 'app' && Object.keys(references).length ? (
                    <div className='w-30 p-16px br-8px cursor dfaic justify-content-between mt-24px' 
                        style={{border: '1px solid var(--base-200)'}}
                        onClick={() => {
                            if(Object.keys(references).length === 1 )
                            {
                                let url = "";
                                for(let key in references)
                                {
                                    let el = references[key];
                                    let filterParams = constructFilterResources({resources: el.resources})
                                    url = `${window.location.origin}/${ROUTE_NAMES.API_REFERENCE}/${el.oasVersion}/${el.swaggerId}/${el.swaggerRevision}`
                                    if(filterParams) url+=`?${URL_PARAMS.SRC}=${SRC_PARAM_VALUES.PACKAGE}&${URL_PARAMS.API_REFERENCE}=${filterParams}`
                                }

                                window.open(url, "_blank")

                                return;
                            }
                            setReferenceListModal({isOpen: true, data: references})
                        }}>
                        <div className='dfaic'>
                            <File size={32} className='text-gray-subtext me-2' />
                            <p className='fw-500 fs-14px text-content-color'>
                                {Object.keys(references).length === 1 ? references[Object.keys(references)[0]].swaggerName || "API Reference" : "API Reference"}
                            </p>
                        </div>

                        <div className='dfaic'>
                            <p className='fw-500 fs-14px text-content-color'>
                                {(packageData.resources || []).length} {(packageData.resources || []).length === 1 ? 'API': 'APIs'}
                            </p>

                            <ArrowUpRight size={20} className='text-gray-subtext ms-2' />
                        </div>
                    </div>
                ) : null}

                {pageType !== 'app' ? (
                    <div className="mt-16px gap-16px d-flex align-items-center border-bottom-theme-base-100 w-100">
                        {TABS.map((x) => (
                            <div
                                onClick={() => setActiveTab(x)}
                                style={{ padding: "1px 4px 11px 4px", borderBottom: activeTab === x ? "2px solid var(--primary-color)" : "" }}
                                className={`cursor ${activeTab === x ? "text-theme-primary " : "text-gray-subtext"} fw-600 fs-14px`}
                            >
                                {x}
                            </div>
                        ))}
                    </div>
                ) : null}

                {activeTab === TABS[0] ? (
                    <div className="h-100 w-100 d-flex flex-column mt-10px br-tl-3px br-tr-3px gap-1px" style={{border: '1px solid var(--base-100)'}}>
                        <div className="d-flex align-items-center w-100 py-10px pl-16px surface-consumer-900 br-tl-3px br-tr-3px">
                            <div className="fs-12px fw-500 text-gray-subtext w-45">API Name</div>
                            <div className="fs-12px fw-500 text-gray-subtext w-45">Description</div>
                            <div className="fs-12px fw-500 text-gray-subtext w-10"></div>
                        </div>
                        {packageData && packageData.resources && packageData.resources.length > 0 ? (
                            packageData.resources.map((data, ind) => (
                                <div className="d-flex align-items-center py-8px pl-16px surface-consumer-850">
                                    <div className="d-flex align-items-center gap-12px w-45">
                                        <div
                                            className={`v2-status-code ${data?.resourceMethod?.toUpperCase()} v2`}
                                            style={{ padding: "8px 6px 8px 8px", width: "80px", borderRadius: "8px" }}
                                        >
                                            <p className="text-center fs-11px text-consumer-200 text-uppercase fw-500">{data?.resourceMethod?.toUpperCase()}</p>
                                        </div>
                                        <div className="d-flex flex-column gap-8px w-65">
                                            <div title={data.resourceName || ""} className="text-theme-content-color fs-14px fw-500 text-truncate">
                                                {data.resourceName}
                                            </div>
                                            <div title={data.resourcePath || ""} className="fs-12px fw-400 text-gray-subtext text-truncate">
                                                {data.resourcePath}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs-14px fw-500 text-gray-subtext w-45 dfaic text-truncate justify-content-star"
                                        title={data.summary || ''}>
                                        {data?.summary}
                                    </p>
                                    <div onClick={()=>packageData.viewMeteringRules && data?.meteringRules?.length > 0 && handleViewMeteringRule(data)} 
                                        className="surface-consumer-850 w-8 cursor text-theme-primary fs-14px fw-600 dfaic justify-content-end">
                                        {(packageData.viewMeteringRules && data?.meteringRules?.length > 0) ? 
                                            <div className='w-fit cursor mobile-dnone fs-14px fw-600 text-primary-color'
                                            data-tip='View Metering Rules' data-for='viewRules'>
                                            <Eye size={14} />
                                            <MyReactTooltip id='viewRules' />
                                        </div>  : null}
                                    </div>
                                    
                                </div>
                            ))
                        ) : null}
                    </div>
                ) : activeTab === TABS[1] ? (
                    loaders.overall || loaders.chart || loaders.resourceMetric ? (
                        <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh" />
                    ) : (
                    <div className="h-100 w-100 d-flex flex-column gap-10px mt-32px">
                        <div className="monetize-detail-wrapper w-100 h-100">
                            <div className="d-flex flex-column flex-md-row align-items-stretch justify-content-between total-list">
                                <div className="surface-consumer-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                                    <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                        <CurrencyCircleDollar color="#0081F1" size={16} />
                                    </div>
                                    <div className="d-flex flex-column gap-4px">
                                        <div className="text-gray-subtext fs-14px fw-400">Total Usage</div>
                                        <div className="d-flex align-items-center gap-10px">
                                            <div className="fs-18px fw-600 text-theme-content-color">
                                                {metricData?.overall?.totalAmount ? (
                                                    <>{currency} {NumberFormatter(metricData?.overall?.totalAmount || '0', 2, currentUser.billing?.billingCurrency?.unitAmount)}</>
                                                ) : 0}
                                            </div>
                                            <div>
                                                <MonetizeTrend value={metricData?.overall?.revenueTrend || 0} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="surface-consumer-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                                    <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                        <MonetizationIcons type={"metered-calls"} />
                                    </div>
                                    <div className="d-flex flex-column gap-4px">
                                        <div className="text-gray-subtext fs-14px fw-400">Total Metered Calls</div>
                                        <div className="d-flex align-items-center gap-10px">
                                            <div className="fs-18px fw-600 text-theme-content-color">{NumberFormatter(metricData?.overall?.metered || '0')}</div>
                                            <div>
                                                <MonetizeTrend value={metricData?.overall?.meteredTrend || 0} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="surface-consumer-850 w-100 br-10px p-16px d-flex align-items-start border-theme-base-200 gap-8px">
                                    <div className="d-flex align-items-center p-4px br-6px border-theme-base-200">
                                        <MonetizationIcons type={"unmetered-calls"} />
                                    </div>
                                    <div className="d-flex flex-column gap-4px">
                                        <div className="text-gray-subtext fs-14px fw-400">Total Unmetered Calls</div>
                                        <div className="d-flex align-items-center gap-10px">
                                            <div className="fs-18px fw-600 text-theme-content-color">{metricData?.overall?.unMetered || '0'}</div>
                                            <div>
                                                <MonetizeTrend value={metricData?.overall?.unMeteredTrend || 0} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <MonthYearPicker selectedDate={selectedDate} handleCallback={handleDateOnChange} />
                            </div>
                            <div className="mt-16px text-consumer-200 fs-14px fw-600 w-100 text-center">Daily Usage</div>
                            <div className="" style={{ height: "291px" }}>
                                <LineChart currencyInYAxisLabel customTooltip={(...args) => customTooltip(tooltipForRevenue, ...args)} data={metricData.chart} labels={numberOfDayInMonth} xAxisTitle={"Days"} stepSize={1} />
                            </div>
                        </div>
                        {
                        ((metricData.resourceMetric[null] && Object.keys(metricData.resourceMetric[null]).length) || 
                        (metricData.resourceMetric[undefined] && Object.keys(metricData.resourceMetric[undefined]).length)) && (
                            <div className="my-20px">
                            <p className="text-consumer-100 fs-16px fw-500">Default Rate Card metrics</p>
                            <div className='dfaic justify-content-between w-100 mt-2 gap-8px mobile-flex-column'>
                                <div className='br-8px p-16px v1-border-05 surface-consumer-900 hover-box-shadow w-50'>
                                    <div className='dfaic'>
                                        <p className='text-consumer-300 fs-14px fw-400'>Number of Transactions</p>
                                        <>
                                            <div data-tip={'Number of Transactions from default rate card'} data-for={`numberOfTransactions`} className="ms-2 cursor">
                                                <Info size={12} />
                                            </div>

                                            <MyReactTooltip id={`numberOfTransactions`} placement={"top"}>
                                                <div className="p-4px w-90 flex-wrap">
                                                    {'Number of Transactions from default rate card'}
                                                </div>
                                            </MyReactTooltip>
                                        </>
                                    </div>
                                    <p style={{marginTop: 20}} className='text-consumer-200 fs-26px fw-800'>
                                        {metricData.resourceMetric[null]?.totalCalls || metricData.resourceMetric[undefined]?.totalCalls || 0}
                                    </p>
                                </div>
                                <div className='br-8px p-16px v1-border-05 surface-consumer-900 hover-box-shadow w-50'>
                                    <div className='dfaic'>
                                        <p className='text-consumer-300 fs-14px fw-400'>Usage for {moment(selectedDate || new Date()).format('MMM YYYY')}</p>
                                        <>
                                            <div data-tip={'Usage from default rate card'} data-for={`Usage`} className="ms-2 cursor"
                                                style={{marginTop: -3}}>
                                                <Info size={12} />
                                            </div>

                                            <MyReactTooltip id={`Usage`} placement={"top"}>
                                                <div className="p-4px w-90 flex-wrap">
                                                    {'Usage from default rate card'}
                                                </div>
                                            </MyReactTooltip>
                                        </>
                                    </div>
                                    <p style={{marginTop: 20}} className='text-consumer-200 fs-26px fw-800'>
                                        {defaultCurrencyFn(currentUser.billing?.billingCurrency)} {NumberFormatter(
                                            metricData.resourceMetric[null]?.totalAmount || metricData.resourceMetric[undefined]?.totalAmount || 0, 2, currentUser.billing?.billingCurrency?.unitAmount
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        )}
                        
                        <div className="d-flex align-items-center gap-16px">
                            <div className="text-theme-content-color fs-20px fw-600">Breakdown by APIs</div>
                            {packageData && packageData.resources && packageData.resources.length ? (
                                <div
                                    className="br-6px px-4px py-2px fs-12px fw-500 text-gray-subtext border-theme-base-100"
                                    style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
                                >
                                    {pluralize(packageData.resources.length, 'API')}
                            </div>
                            ) : null}
                        </div>
                        <div className="monetization-table w-100 h-100 overflow-scroll">
                            <table className="w-100 h-100" style={{border: '1px solid var(--base-100)'}}>
                                <thead className="d-flex align-items-center w-100">
                                    <th className="fs-12px fw-500 text-gray-subtext surface-consumer-900 w-40">API Name</th>
                                    <th className="fs-12px fw-500 text-gray-subtext surface-consumer-900 w-10">Metered Calls</th>
                                    <th className="fs-12px fw-500 text-gray-subtext surface-consumer-900 w-15">Calls share in Package (%)</th>
                                    <th className="fs-12px fw-500 text-gray-subtext surface-consumer-900 w-15">Total Usage</th>
                                    <th className="fs-12px fw-500 text-gray-subtext surface-consumer-900 w-15">Total Usage (%)</th>
                                    <th className="fs-12px fw-500 text-gray-subtext surface-consumer-900 w-5"><div style={{visibility:"hidden"}}>n</div></th>
                                </thead>
                                <tbody className="d-flex flex-column gap-1px">
                                    {packageData && packageData.resources && packageData.resources.length ? (
                                        packageData.resources.map((data, ind) => (
                                            <tr className="d-flex align-items-center w-100">
                                                <td className="surface-consumer-850 d-flex align-items-center gap-12px w-40">
                                                    <div
                                                        className={`v2-status-code ${data.resourceMethod.toUpperCase()} v2`}
                                                        style={{ padding: "8px 6px 8px 8px", width: "80px", borderRadius: "8px" }}
                                                    >
                                                        <p className="text-center fs-11px text-consumer-200 text-uppercase fw-500">{data.resourceMethod.toUpperCase()}</p>
                                                    </div>
                                                    <div className="surface-consumer-850 d-flex flex-column gap-8px">
                                                        <div data-for={ind.toString()} data-tip={data.resourceName || ""} 
                                                            className="text-theme-content-color fs-14px fw-500">
                                                            {data.resourceName.length > 38 ? data.resourceName.substring(0, 38) + "..." : data.resourceName}
                                                        </div>
                                                        <div data-for={ind.toString()} data-tip={data.resourcePath || ""} 
                                                            className="fs-12px fw-380 text-gray-subtext">
                                                            {data.resourcePath.length > 38 ? data.resourcePath.substring(0, 38) + "..." : data.resourcePath}
                                                        </div>
                                                    </div>
                                                    {data.resourceName && data.resourceName.length > 38 && <MyReactTooltip id={ind.toString()} />}
                                                    {data.resourcePath && data.resourcePath.length > 38 && <MyReactTooltip id={ind.toString()} />}
                                                </td>
                                                <td className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext w-10">{metricData.resourceMetric.hasOwnProperty(data.resourceId) ? NumberFormatter(metricData.resourceMetric[data.resourceId].metered) : '-'}</td>
                                                <td className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext w-15">{metricData.resourceMetric.hasOwnProperty(data.resourceId) ? calcPercentage(metricData?.resourceMetric?.[data.resourceId]?.metered, metricData?.overall?.metered) : '-'}</td>
                                                <td className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext w-15">{metricData.resourceMetric.hasOwnProperty(data.resourceId) ? <>{currency} {NumberFormatter(metricData.resourceMetric[data.resourceId].totalAmount, 2, currentUser.billing?.billingCurrency?.unitAmount)}</> : '-'}</td>
                                                <td className="surface-consumer-850 d-flex align-items-center fs-14px fw-500 text-gray-subtext w-15">{metricData.resourceMetric.hasOwnProperty(data.resourceId) ? calcPercentage(metricData?.resourceMetric?.[data.resourceId]?.totalAmount, metricData?.overall?.totalAmount) : '-'}</td>
                                                <td onClick={()=>(packageData.viewMeteringRules && data?.meteringRules?.length > 0) && handleViewMeteringRule(data)} 
                                                    className="surface-consumer-850 cursor text-theme-primary fs-14px fw-600 d-none d-md-flex align-items-center w-5">
                                                    { (packageData.viewMeteringRules && data?.meteringRules?.length > 0) ? <div className='w-fit cursor mobile-dnone fs-14px fw-600 text-primary-color'
                                                        data-tip='View Metering Rules' data-for='viewRules'>
                                                        <Eye size={14} />
                                                        <MyReactTooltip id='viewRules' />
                                                    </div> 
                                                    : null}
                                                </td>
                                            </tr>
                                        ))
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    )
                ) : activeTab === TABS[2] ? (
                    <div className="h-100 w-100 d-flex flex-column gap-10px mt-10px">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center gap-16px">
                                <div className="text-theme-content-color fs-20px fw-600">Breakdown by APIs</div>
                                {appsList && appsList.length > 0 ? (
                                <div
                                    className="br-6px px-4px py-2px fs-12px fw-500 text-gray-subtext border-theme-base-100"
                                    style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
                                >
                                    {appsList.length} APIs
                                </div>
                                ) : null}
                            </div>
                            <div className="d-none d-md-flex align-items-center p-2px br-6px bg-theme-secondary-200">
                                <div className={`cursor px-12px py-4px ${activeView === LayoutViews.LIST ? 'bg-theme-primary br-4px' : ""}`} onClick={()=>setActiveView(LayoutViews.LIST)}>
                                    <MonetizationIcons type={"list-icon"} fill={activeView === LayoutViews.LIST ? "white" : "#666666"}/>
                                </div>
                                <div className={`cursor px-12px py-4px ${activeView === LayoutViews.GRID ? 'bg-theme-primary br-4px' : ""}`} onClick={()=>setActiveView(LayoutViews.GRID)}>
                                    <MonetizationIcons type={"group-icon"} fill={activeView === LayoutViews.GRID ? "white" : "#666666"}/>
                                </div>
                            </div>
                        </div>
                        {loaders.apps ? (
                            <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh" />
                        ) : (
                            appsList && appsList.length > 0 ? (
                                activeView === LayoutViews.LIST ? (
                                    <div className="h-100 d-flex flex-column w-100 gap-1px" style={{border: '1px solid var(--base-100)'}}>
                                        <div className="d-flex align-items-center w-100 py-12px pl-16px surface-consumer-900">
                                            <div className="fs-12px fw-500 text-gray-subtext w-40">APP Name</div>
                                        </div>
                                        {appsList.map((data) => (
                                            <div
                                                style={{height: "77px"}}
                                                className="surface-consumer-850 w-100 d-flex justify-content-between align-items-center py-12px px-16px"
                                            >
                                                <div className="fs-14px fw-500 text-theme-content-color">{data.appName}</div>
                                                {data.appId ? (
                                                    <div onClick={() => navigate(`/monetization/app/${data.id}?appId=${data.appId}`)} className="py-16px fs-14px gap-12px fw-600 text-theme-primary text-nowrap d-flex align-items-center cursor">
                                                        <p>Go to App</p>
                                                        <TrendUp color="var(--primary-color)" />
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="h-100 w-100 card-grid-wrapper gap-10px mt-10px">
                                        {appsList.map((data) => (
                                            <>
                                                <MonetizeAppCard data={data} handleNavigate={() => data.appId && navigate(`/monetization/app/${data.id}?appId=${data.appId}`)}/>
                                            </>
                                        ))}
                                    </div>
                                )
                            ) : (
                                <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                    <EmptyState />
                                    <p className="ps-5 text-content-color text-center">No Apps Found</p>
                                </div>
                            )
                        )}
                    </div>
                ) : null}
            </div>
            )}

            {rulesModal.isOpen && <MeteringRulesModal showMeteringChart date={MonetizeDateFn(selectedDate)} show={rulesModal.isOpen} currentCurrency={currentCurrency}
                onHide={() => setRulesModal({isOpen: false, data: {}})} data={rulesModal.data} />}

            {referenceListModal.isOpen && <APIReferenceListModal show={referenceListModal.isOpen}
                    onHide={() => setReferenceListModal({isOpen: false, data: {}})} data={referenceListModal.data} />}
        </div>
    );
}
