import { useSelector } from "react-redux";
import dummyUser from "../../../../assets/Forum/images/dummy-profile.png";
import { PostIcon } from "../Post/PostIcon";
import moment from "moment";
import EditorJSOutput from "../../../../components/EditorJSOutput";
import { useEffect, useState } from "react";
import { objectDeepClone } from "../../../../utils/helper";
import postAttachIcon from "../../../../assets/Forum/icons/post-attachment.svg";
import postImageIcon from "../../../../assets/Forum/icons/post-image.svg";
import postLinkIcon from "../../../../assets/Forum/icons/post-link.svg";
import ForumAPI from "../../../../api/forum";
import { getToast } from "../../../../components/Toast";
import { CreateReplyModal } from "../CreateReplyModal";
import { Reply } from "../Reply";
import { PlaceHolderProfile } from "../PlaceHolderProfile";
import { EditDeleteDropdown } from "../EditDeleteDropdown";
import { useNavigate } from "react-router";
import { EditReplyModal } from "../EditReplyModal";

export const SinglePost = ({ postData, getRepliesOfPost, ...props }) => {
  const {
    currentLayoutObject: { siteTemplateType },
  } = useSelector((state) => state.layout);
  const { currentUser } = useSelector((state) => state.user);
  const [votes, setVotes] = useState([]);

  const { onReplyEditSuccess, onDeleteReplySuccess, setAllPosts } = props;
  const navigate = useNavigate();

  const isUserVoted = () => {
    return votes.includes(currentUser._id);
  };

  const [postContent, setpostContent] = useState(null);
  const [showReadMore, setShowReadMore] = useState(false);
  const [showCreateReplyModal, setShowReplyModal] = useState(false);
  const [showEditReplyModal, setShowEditReplyModal] = useState({
    show: false,
    reply: null,
  });

  useEffect(() => {
    if (postData && postData?.post) {
      const _postContent = objectDeepClone(postData.post?.content?.data);
      setpostContent(_postContent);
      setShowReadMore(false);
      setVotes(postData?.post?.votes);
    }
  }, [postData]);

  const updatePostUpvote = () => {
    ForumAPI.togglePostReaction({ postId: postData._id })
      .then((res) => {
        console.log("update upvote res: ", res.data);
        let _votes = objectDeepClone(votes);

        const userAlreadyReacted = _votes.includes(currentUser._id);
        if (userAlreadyReacted) {
          _votes = _votes.filter((id) => id !== currentUser._id);
          setAllPosts((prev) => {
            return prev.map((post) =>
              post._id === postData._id
                ? { ...post, post: { ...post.post, votes: _votes } }
                : post
            );
          });
          setVotes(_votes);
        } else {
          _votes = [..._votes, currentUser._id];
          setAllPosts((prev) => {
            return prev.map((post) =>
              post._id === postData._id
                ? { ...post, post: { ...post.post, votes: _votes } }
                : post
            );
          });
          setVotes(_votes);
        }
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to update Upvotes",
        });
      });
  };

  return (
    <>
      <div className="post-view-card">
        <div className="post-meta-tags__container">
          <div className="post-meta-info__container">
            {postData?.createdBy?.userImage ? (
              <img
                src={postData?.createdBy?.userImage}
                className="user-profile"
              />
            ) : (
              <PlaceHolderProfile />
            )}
            <div className="user-name--creation-time__container">
              <p className="user-name">{postData?.createdBy?.fullName} </p>
              <p className="post-creation-time ">
                •{moment.utc(postData?.cts).format("MMMM DD,YYYY")} at{" "}
                {moment.utc(postData?.cts).format("HH:MM A")}
              </p>
            </div>
            {/* <EditDeleteDropdown
              deleteCallback={() => {
                onDeleteSuccess({ postId: postData._id });
                navigate(-1);
              }}
              editCallback={() => {
                setShowEditModal({
                  show: true,
                  postData,
                });
              }}
            /> */}
          </div>
          <div className="post-tags__container">
            {postData?.tags?.map((tag) => (
              <div className="tag">{tag}</div>
            ))}
          </div>
        </div>
        <div className="post-content__container">
          <div className="post-title">{postData?.title}</div>
          <div className="post-content">
            <EditorJSOutput applyTheme data={postContent} />
          </div>
        </div>

        <div className="post-upvotes-reply-count__container">
          <div className="upvote-count" onClick={updatePostUpvote}>
            <PostIcon
              type={"upvote"}
              state={isUserVoted() ? "filled" : "default"}
            />
            <p className="count-value">{votes?.length} Upvotes</p>
          </div>
          <div className="reply-count">
            <PostIcon type={"reply"} state={"default"} />
            <p className="count-value">{postData?.replies?.length} Replies</p>
          </div>
        </div>
      </div>
      <div
        className="post-reply-input-cta"
        onClick={() => {
          //   setShowCreateModal(true);
          setShowReplyModal(true);
        }}
      >
        <div className="d-flex w-100 gap-16px">
          {currentUser?.userImage ? (
            <img src={currentUser?.userImage} className="user-profile" />
          ) : (
            <PlaceHolderProfile />
          )}
          <div className="dummy-post-input" disabled>
            Post a reply...
          </div>
        </div>
        {/* <div className="dummy-post-attachments__container">
          <img className="icon" src={postAttachIcon} />
          <img className="icon" src={postImageIcon} />
          <img className="icon" src={postLinkIcon} />
        </div> */}
      </div>

      {postData?.replies &&
        postData?.replies?.length > 0 &&
        postData?.replies?.map((reply) => (
          <Reply
            replyData={reply}
            postData={postData}
            setShowEditReplyModal={setShowEditReplyModal}
            onDeleteReplySuccess={onDeleteReplySuccess}
          />
        ))}

      <CreateReplyModal
        show={showCreateReplyModal}
        onHide={() => {
          setShowReplyModal(false);
        }}
        postToReply={postData}
        onReplyCreate={() => {
          setShowReplyModal(false);
          getRepliesOfPost();
        }}
      />
      <EditReplyModal
        show={showEditReplyModal}
        onHide={() => {
          setShowEditReplyModal({
            show: false,
            reply: null,
          });
        }}
        postToReply={postData}
        onReplyEditSuccess={onReplyEditSuccess}
      />
    </>
  );
};
