import React from 'react'

export default function CustomTertiaryTab({data}) {

    return (
        <div className='br-16px m-0 tertiaryTab overflow-hidden w-100 py-16px px-22px d-flex justify-content-between flex-column position-relative'>
            <div className=''>
                <p className='fs-14px fw-500 text-white'
                    style={{lineHeight: `130%`}}>{data.tag || ''}</p>

                <p className='fs-24px fw-600 text-white mt-8px'
                    style={{lineHeight: `110%`}}>{data.heading || ''}</p>
            </div>

            <p className='font-jost fs-14px fw-400 text-white'>
                {data.description || ''}
            </p>

            <div className='position-absolute' style={{right: 0, bottom: -2, opacity: 0.4}}>
                <svg width="133" height="168" viewBox="0 0 133 168" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4835_14140)">
                        <path d="M101.014 55.6985C114.128 53.9853 123.37 41.9654 121.657 28.8514C119.943 15.7374 107.923 6.49523 94.8095 8.20846C81.6955 9.9217 72.4533 21.9416 74.1665 35.0556C75.8798 48.1696 87.8996 57.4117 101.014 55.6985Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M102.586 56.5266C115.7 54.8134 124.942 42.7935 123.229 29.6795C121.516 16.5655 109.496 7.32335 96.3817 9.03659C83.2677 10.7498 74.0256 22.7697 75.7388 35.8837C77.452 48.9977 89.4719 58.2399 102.586 56.5266Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M104.158 57.3528C117.272 55.6396 126.514 43.6197 124.801 30.5057C123.088 17.3917 111.068 8.14952 97.954 9.86276C84.84 11.576 75.5978 23.5959 77.3111 36.7099C79.0243 49.8239 91.0442 59.066 104.158 57.3528Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M105.73 58.1809C118.844 56.4677 128.087 44.4478 126.373 31.3338C124.66 18.2198 112.64 8.97765 99.5263 10.6909C86.4123 12.4041 77.1701 24.424 78.8833 37.538C80.5966 50.652 92.6164 59.8942 105.73 58.1809Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M107.303 59.0091C120.417 57.2958 129.659 45.276 127.946 32.1619C126.232 19.0479 114.213 9.80577 101.099 11.519C87.9845 13.2322 78.7424 25.2521 80.4556 38.3661C82.1688 51.4801 94.1887 60.7223 107.303 59.0091Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M108.873 59.8372C121.987 58.1239 131.229 46.1041 129.516 32.9901C127.803 19.8761 115.783 10.6339 102.669 12.3471C89.5548 14.0604 80.3127 26.0802 82.0259 39.1942C83.7391 52.3083 95.759 61.5504 108.873 59.8372Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M110.445 60.6653C123.559 58.9521 132.801 46.9322 131.088 33.8182C129.375 20.7042 117.355 11.462 104.241 13.1753C91.1271 14.8885 81.8849 26.9084 83.5982 40.0224C85.3114 53.1364 97.3313 62.3785 110.445 60.6653Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M112.018 61.4934C125.132 59.7802 134.374 47.7603 132.66 34.6463C130.947 21.5323 118.927 12.2901 105.813 14.0034C92.6994 15.7166 83.4572 27.7365 85.1704 40.8505C86.8837 53.9645 98.9035 63.2067 112.018 61.4934Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M113.59 62.3216C126.704 60.6083 135.946 48.5885 134.233 35.4744C132.52 22.3604 120.5 13.1183 107.386 14.8315C94.2716 16.5447 85.0295 28.5646 86.7427 41.6786C88.4559 54.7926 100.476 64.0348 113.59 62.3216Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M115.16 63.1477C128.274 61.4345 137.516 49.4146 135.803 36.3006C134.09 23.1866 122.07 13.9444 108.956 15.6577C95.8419 17.3709 86.5998 29.3908 88.313 42.5048C90.0263 55.6188 102.046 64.861 115.16 63.1477Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M116.732 63.9759C129.846 62.2626 139.089 50.2428 137.375 37.1287C135.662 24.0147 123.642 14.7726 110.528 16.4858C97.4142 18.199 88.1721 30.2189 89.8853 43.3329C91.5985 56.4469 103.618 65.6891 116.732 63.9759Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M118.305 64.804C131.419 63.0907 140.661 51.0709 138.948 37.9569C137.234 24.8429 125.215 15.6007 112.1 17.3139C98.9865 19.0272 89.7443 31.047 91.4576 44.161C93.1708 57.2751 105.191 66.5172 118.305 64.804Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M119.875 65.6321C132.989 63.9189 142.231 51.899 140.518 38.785C138.805 25.671 126.785 16.4288 113.671 18.1421C100.557 19.8553 91.3146 31.8752 93.0279 44.9892C94.7411 58.1032 106.761 67.3453 119.875 65.6321Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M121.447 66.4602C134.561 64.747 143.803 52.7271 142.09 39.6131C140.377 26.4991 128.357 17.2569 115.243 18.9702C102.129 20.6834 92.8869 32.7033 94.6001 45.8173C96.3134 58.9313 108.333 68.1735 121.447 66.4602Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M123.02 67.2884C136.134 65.5751 145.376 53.5553 143.662 40.4412C141.949 27.3272 129.929 18.0851 116.815 19.7983C103.701 21.5115 94.4592 33.5314 96.1724 46.6454C97.8856 59.7594 109.905 69.0016 123.02 67.2884Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M124.592 68.1145C137.706 66.4013 146.948 54.3814 145.235 41.2674C143.521 28.1534 131.502 18.9112 118.388 20.6245C105.274 22.3377 96.0314 34.3576 97.7447 47.4716C99.4579 60.5856 111.478 69.8278 124.592 68.1145Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M126.162 68.9426C139.276 67.2294 148.518 55.2096 146.805 42.0955C145.092 28.9815 133.072 19.7394 119.958 21.4526C106.844 23.1658 97.6017 35.1857 99.315 48.2997C101.028 61.4137 113.048 70.6559 126.162 68.9426Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M127.736 69.7708C140.85 68.0575 150.092 56.0377 148.379 42.9237C146.666 29.8096 134.646 20.5675 121.532 22.2807C108.418 23.994 99.176 36.0138 100.889 49.1278C102.602 62.2418 114.622 71.484 127.736 69.7708Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M129.307 70.5989C142.421 68.8857 151.663 56.8658 149.95 43.7518C148.236 30.6378 136.216 21.3956 123.102 23.1089C109.988 24.8221 100.746 36.8419 102.46 49.956C104.173 63.07 116.193 72.3121 129.307 70.5989Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M130.879 71.427C143.993 69.7138 153.235 57.6939 151.522 44.5799C149.809 31.4659 137.789 22.2237 124.675 23.937C111.561 25.6502 102.319 37.6701 104.032 50.7841C105.745 63.8981 117.765 73.1403 130.879 71.427Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M132.449 72.2551C145.563 70.5419 154.805 58.5221 153.092 45.408C151.379 32.294 139.359 23.0519 126.245 24.7651C113.131 26.4783 103.889 38.4982 105.602 51.6122C107.315 64.7262 119.335 73.9684 132.449 72.2551Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M134.021 73.0813C147.135 71.3681 156.378 59.3482 154.664 46.2342C152.951 33.1202 140.931 23.878 127.817 25.5913C114.703 27.3045 105.461 39.3244 107.174 52.4384C108.888 65.5524 120.907 74.7946 134.021 73.0813Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M135.594 73.9094C148.708 72.1962 157.95 60.1763 156.237 47.0623C154.523 33.9483 142.504 24.7062 129.39 26.4194C116.276 28.1326 107.033 40.1525 108.747 53.2665C110.46 66.3805 122.48 75.6227 135.594 73.9094Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M137.166 74.7376C150.28 73.0243 159.522 61.0045 157.809 47.8905C156.096 34.7764 144.076 25.5343 130.962 27.2475C117.848 28.9608 108.606 40.9806 110.319 54.0946C112.032 67.2086 124.052 76.4508 137.166 74.7376Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M138.738 75.5657C151.852 73.8525 161.094 61.8326 159.381 48.7186C157.668 35.6046 145.648 26.3624 132.534 28.0756C119.42 29.7889 110.178 41.8087 111.891 54.9228C113.604 68.0368 125.624 77.2789 138.738 75.5657Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M140.311 76.3938C153.425 74.6806 162.667 62.6607 160.953 49.5467C159.24 36.4327 147.22 27.1905 134.106 28.9038C120.992 30.617 111.75 42.6369 113.463 55.7509C115.177 68.8649 127.197 78.1071 140.311 76.3938Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M141.881 77.2219C154.995 75.5087 164.237 63.4888 162.524 50.3748C160.811 37.2608 148.791 28.0187 135.677 29.7319C122.563 31.4451 113.32 43.465 115.034 56.579C116.747 69.693 128.767 78.9352 141.881 77.2219Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M143.453 78.0501C156.567 76.3368 165.809 64.317 164.096 51.203C162.383 38.0889 150.363 28.8468 137.249 30.56C124.135 32.2733 114.893 44.2931 116.606 57.4071C118.319 70.5211 130.339 79.7633 143.453 78.0501Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M145.025 78.8762C158.139 77.163 167.382 65.1431 165.668 52.0291C163.955 38.9151 151.935 29.673 138.821 31.3862C125.707 33.0994 116.465 45.1193 118.178 58.2333C119.891 71.3473 131.911 80.5895 145.025 78.8762Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M146.596 79.7044C159.71 77.9911 168.952 65.9713 167.239 52.8573C165.525 39.7432 153.506 30.5011 140.392 32.2143C127.277 33.9276 118.035 45.9474 119.749 59.0614C121.462 72.1754 133.482 81.4176 146.596 79.7044Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M148.168 80.5325C161.282 78.8193 170.524 66.7994 168.811 53.6854C167.098 40.5714 155.078 31.3292 141.964 33.0424C128.85 34.7557 119.608 46.7755 121.321 59.8896C123.034 73.0036 135.054 82.2457 148.168 80.5325Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M149.74 81.3606C162.854 79.6474 172.096 67.6275 170.383 54.5135C168.67 41.3995 156.65 32.1573 143.536 33.8706C130.422 35.5838 121.18 47.6037 122.893 60.7177C124.606 73.8317 136.626 83.0739 149.74 81.3606Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M151.311 82.1887C164.425 80.4755 173.667 68.4556 171.953 55.3416C170.24 42.2276 158.22 32.9855 145.106 34.6987C131.992 36.4119 122.75 48.4318 124.463 61.5458C126.177 74.6598 138.197 83.902 151.311 82.1887Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M152.885 83.0169C165.999 81.3036 175.241 69.2838 173.528 56.1698C171.814 43.0557 159.795 33.8136 146.681 35.5268C133.567 37.2401 124.324 49.2599 126.038 62.3739C127.751 75.4879 139.771 84.7301 152.885 83.0169Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M154.455 83.843C167.569 82.1298 176.811 70.1099 175.098 56.9959C173.385 43.8819 161.365 34.6398 148.251 36.353C135.137 38.0662 125.895 50.0861 127.608 63.2001C129.321 76.3141 141.341 85.5563 154.455 83.843Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M156.027 84.6731C169.141 82.9599 178.383 70.94 176.67 57.826C174.957 44.712 162.937 35.4698 149.823 37.1831C136.709 38.8963 127.467 50.9162 129.18 64.0302C130.893 77.1442 142.913 86.3864 156.027 84.6731Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M157.6 85.4993C170.714 83.7861 179.956 71.7662 178.243 58.6522C176.529 45.5382 164.509 36.296 151.395 38.0092C138.281 39.7225 129.039 51.7423 130.752 64.8563C132.466 77.9704 144.486 87.2125 157.6 85.4993Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M159.172 86.3274C172.286 84.6142 181.528 72.5943 179.815 59.4803C178.102 46.3663 166.082 37.1241 152.968 38.8374C139.854 40.5506 130.612 52.5705 132.325 65.6845C134.038 78.7985 146.058 88.0406 159.172 86.3274Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M160.742 87.1555C173.856 85.4423 183.098 73.4224 181.385 60.3084C179.672 47.1944 167.652 37.9523 154.538 39.6655C141.424 41.3787 132.182 53.3986 133.895 66.5126C135.608 79.6266 147.628 88.8688 160.742 87.1555Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M162.314 87.9837C175.428 86.2704 184.671 74.2506 182.957 61.1366C181.244 48.0225 169.224 38.7804 156.11 40.4936C142.996 42.2069 133.754 54.2267 135.467 67.3407C137.181 80.4547 149.2 89.6969 162.314 87.9837Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M162.275 106.275C175.389 104.561 184.632 92.5416 182.918 79.4276C181.205 66.3136 169.185 57.0714 156.071 58.7846C142.957 60.4979 133.715 72.5177 135.428 85.6317C137.141 98.7458 149.161 107.988 162.275 106.275Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M160.176 107.204C173.29 105.491 182.532 93.4713 180.819 80.3573C179.105 67.2432 167.086 58.0011 153.972 59.7143C140.858 61.4276 131.615 73.4474 133.329 86.5614C135.042 99.6754 147.062 108.918 160.176 107.204Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M158.076 108.132C171.19 106.419 180.432 94.399 178.719 81.285C177.006 68.171 164.986 58.9288 151.872 60.6421C138.758 62.3553 129.516 74.3751 131.229 87.4892C132.942 100.603 144.962 109.845 158.076 108.132Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M155.977 109.062C169.091 107.349 178.333 95.3287 176.619 82.2147C174.906 69.1007 162.886 59.8585 149.772 61.5717C136.658 63.285 127.416 75.3048 129.129 88.4188C130.843 101.533 142.863 110.775 155.977 109.062Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M153.877 109.99C166.991 108.276 176.233 96.2564 174.52 83.1424C172.807 70.0284 160.787 60.7862 147.673 62.4995C134.559 64.2127 125.317 76.2326 127.03 89.3466C128.743 102.461 140.763 111.703 153.877 109.99Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M151.777 110.919C164.891 109.206 174.133 97.1861 172.42 84.0721C170.707 70.9581 158.687 61.7159 145.573 63.4292C132.459 65.1424 123.217 77.1623 124.93 90.2763C126.643 103.39 138.663 112.632 151.777 110.919Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M149.676 111.847C162.79 110.134 172.032 98.1139 170.319 84.9998C168.605 71.8858 156.586 62.6437 143.472 64.3569C130.358 66.0701 121.115 78.09 122.829 91.204C124.542 104.318 136.562 113.56 149.676 111.847Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M147.576 112.777C160.69 111.063 169.932 99.0435 168.219 85.9295C166.506 72.8155 154.486 63.5734 141.372 65.2866C128.258 66.9998 119.016 79.0197 120.729 92.1337C122.442 105.248 134.462 114.49 147.576 112.777Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M145.477 113.704C158.591 111.991 167.833 99.9713 166.119 86.8573C164.406 73.7432 152.386 64.5011 139.272 66.2143C126.158 67.9276 116.916 79.9474 118.629 93.0614C120.343 106.175 132.363 115.418 145.477 113.704Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M143.377 114.634C156.491 112.921 165.733 100.901 164.02 87.7869C162.307 74.6729 150.287 65.4308 137.173 67.144C124.059 68.8572 114.817 80.8771 116.53 93.9911C118.243 107.105 130.263 116.347 143.377 114.634Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M141.277 115.562C154.391 113.849 163.633 101.829 161.92 88.7147C160.207 75.6007 148.187 66.3585 135.073 68.0717C121.959 69.785 112.717 81.8048 114.43 94.9189C116.143 108.033 128.163 117.275 141.277 115.562Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M139.176 116.49C152.29 114.776 161.532 102.756 159.819 89.6424C158.105 76.5284 146.086 67.2862 132.972 68.9995C119.858 70.7127 110.615 82.7326 112.329 95.8466C114.042 108.961 126.062 118.203 139.176 116.49Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M137.076 117.419C150.19 115.706 159.432 103.686 157.719 90.5721C156.006 77.4581 143.986 68.2159 130.872 69.9292C117.758 71.6424 108.516 83.6623 110.229 96.7763C111.942 109.89 123.962 119.132 137.076 117.419Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M134.977 118.347C148.091 116.634 157.333 104.614 155.619 91.4998C153.906 78.3858 141.886 69.1437 128.772 70.8569C115.658 72.5701 106.416 84.59 108.129 97.704C109.843 110.818 121.863 120.06 134.977 118.347Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M132.877 119.277C145.991 117.563 155.233 105.544 153.52 92.4295C151.807 79.3155 139.787 70.0734 126.673 71.7866C113.559 73.4998 104.317 85.5197 106.03 98.6337C107.743 111.748 119.763 120.99 132.877 119.277Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M130.775 120.204C143.889 118.491 153.132 106.471 151.418 93.3573C149.705 80.2432 137.685 71.0011 124.571 72.7143C111.457 74.4276 102.215 86.4474 103.928 99.5614C105.641 112.675 117.661 121.918 130.775 120.204Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M128.678 121.134C141.792 119.421 151.034 107.401 149.321 94.2869C147.607 81.1729 135.588 71.9308 122.474 73.644C109.36 75.3572 100.117 87.3771 101.831 100.491C103.544 113.605 115.564 122.847 128.678 121.134Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M126.576 122.062C139.69 120.349 148.932 108.329 147.219 95.2147C145.506 82.1007 133.486 72.8585 120.372 74.5717C107.258 76.285 98.0158 88.3048 99.729 101.419C101.442 114.533 113.462 123.775 126.576 122.062Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M124.477 122.991C137.591 121.278 146.833 109.258 145.119 96.1444C143.406 83.0303 131.386 73.7882 118.272 75.5014C105.158 77.2147 95.9162 89.2345 97.6294 102.349C99.3427 115.463 111.363 124.705 124.477 122.991Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M122.377 123.919C135.491 122.206 144.733 110.186 143.02 97.0721C141.307 83.9581 129.287 74.7159 116.173 76.4292C103.059 78.1424 93.8166 90.1623 95.5298 103.276C97.2431 116.39 109.263 125.632 122.377 123.919Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M120.277 124.849C133.391 123.136 142.633 111.116 140.92 98.0018C139.207 84.8878 127.187 75.6456 114.073 77.3589C100.959 79.0721 91.717 91.0919 93.4302 104.206C95.1434 117.32 107.163 126.562 120.277 124.849Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M118.176 125.777C131.29 124.063 140.532 112.044 138.819 98.9295C137.105 85.8155 125.086 76.5734 111.972 78.2866C98.8576 79.9998 89.6154 92.0197 91.3286 105.134C93.0419 118.248 105.062 127.49 118.176 125.777Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M116.078 126.706C129.192 124.993 138.434 112.973 136.721 99.8592C135.008 86.7452 122.988 77.503 109.874 79.2163C96.7599 80.9295 87.5178 92.9494 89.231 106.063C90.9442 119.177 102.964 128.42 116.078 126.706Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M113.977 127.634C127.091 125.921 136.333 113.901 134.619 100.787C132.906 87.6729 120.886 78.4308 107.772 80.144C94.6584 81.8572 85.4162 93.8771 87.1294 106.991C88.8427 120.105 100.863 129.347 113.977 127.634Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M111.877 128.564C124.991 126.851 134.233 114.831 132.52 101.717C130.807 88.6026 118.787 79.3605 105.673 81.0737C92.5587 82.7869 83.3166 94.8068 85.0298 107.921C86.7431 121.035 98.7629 130.277 111.877 128.564Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M109.777 129.491C122.891 127.778 132.133 115.758 130.42 102.644C128.707 89.5303 116.687 80.2882 103.573 82.0014C90.4591 83.7147 81.217 95.7345 82.9302 108.849C84.6434 121.963 96.6633 131.205 109.777 129.491Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M107.678 130.421C120.792 128.708 130.034 116.688 128.321 103.574C126.607 90.46 114.588 81.2179 101.474 82.9311C88.3595 84.6444 79.1174 96.6642 80.8306 109.778C82.5438 122.892 94.5637 132.134 107.678 130.421Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M105.576 131.349C118.69 129.636 127.932 117.616 126.219 104.502C124.506 91.3878 112.486 82.1456 99.372 83.8589C86.258 85.5721 77.0158 97.5919 78.729 110.706C80.4423 123.82 92.4621 133.062 105.576 131.349Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M103.478 132.279C116.593 130.565 125.835 118.545 124.121 105.431C122.408 92.3175 110.388 83.0753 97.2743 84.7885C84.1603 86.5018 74.9181 98.5216 76.6314 111.636C78.3446 124.75 90.3645 133.992 103.478 132.279Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M101.377 133.206C114.491 131.493 123.733 119.473 122.02 106.359C120.307 93.2452 108.287 84.003 95.1728 85.7163C82.0587 87.4295 72.8166 99.4494 74.5298 112.563C76.2431 125.677 88.2629 134.92 101.377 133.206Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M99.2773 134.136C112.391 132.423 121.633 120.403 119.92 107.289C118.207 94.1749 106.187 84.9327 93.0731 86.646C79.9591 88.3592 70.717 100.379 72.4302 113.493C74.1434 126.607 86.1633 135.849 99.2773 134.136Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M97.1777 135.064C110.292 133.351 119.534 121.331 117.821 108.217C116.107 95.1026 104.088 85.8605 90.9735 87.5737C77.8595 89.2869 68.6174 101.307 70.3306 114.421C72.0438 127.535 84.0637 136.777 97.1777 135.064Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M95.0781 135.993C108.192 134.28 117.434 122.26 115.721 109.146C114.008 96.0323 101.988 86.7901 88.8739 88.5034C75.7599 90.2166 66.5178 102.236 68.231 115.35C69.9442 128.465 81.9641 137.707 95.0781 135.993Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M92.9765 136.921C106.091 135.208 115.333 123.188 113.619 110.074C111.906 96.96 99.8864 87.7179 86.7724 89.4311C73.6584 91.1444 64.4162 103.164 66.1294 116.278C67.8427 129.392 79.8625 138.634 92.9765 136.921Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M90.8769 137.851C103.991 136.138 113.233 124.118 111.52 111.004C109.807 97.8897 97.7868 88.6476 84.6728 90.3608C71.5587 92.074 62.3166 104.094 64.0298 117.208C65.7431 130.322 77.7629 139.564 90.8769 137.851Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M88.7773 138.779C101.891 137.065 111.133 125.045 109.42 111.931C107.707 98.8175 95.6872 89.5753 82.5731 91.2885C69.4591 93.0018 60.217 105.022 61.9302 118.136C63.6434 131.25 75.6633 140.492 88.7773 138.779Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M86.6777 139.708C99.7917 137.995 109.034 125.975 107.321 112.861C105.607 99.7471 93.5876 90.505 80.4735 92.2182C67.3595 93.9315 58.1174 105.951 59.8306 119.065C61.5438 132.179 73.5637 141.422 86.6777 139.708Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M84.5781 140.636C97.6921 138.923 106.934 126.903 105.221 113.789C103.508 100.675 91.4879 91.4327 78.3739 93.146C65.2599 94.8592 56.0178 106.879 57.731 119.993C59.4442 133.107 71.4641 142.349 84.5781 140.636Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M82.4785 141.564C95.5925 139.85 104.835 127.831 103.121 114.717C101.408 101.603 89.3883 92.3605 76.2743 94.0737C63.1603 95.7869 53.9181 107.807 55.6314 120.921C57.3446 134.035 69.3645 143.277 82.4785 141.564Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M80.3769 142.493C93.4909 140.78 102.733 128.76 101.02 115.646C99.3066 102.532 87.2868 93.2901 74.1728 95.0034C61.0587 96.7166 51.8166 108.736 53.5298 121.85C55.2431 134.965 67.2629 144.207 80.3769 142.493Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M78.2773 143.421C91.3913 141.708 100.633 129.688 98.9203 116.574C97.207 103.46 85.1872 94.2179 72.0731 95.9311C58.9591 97.6444 49.717 109.664 51.4302 122.778C53.1434 135.892 65.1633 145.134 78.2773 143.421Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M76.1777 144.351C89.2917 142.638 98.5339 130.618 96.8206 117.504C95.1074 104.39 83.0876 95.1476 69.9735 96.8608C56.8595 98.574 47.6174 110.594 49.3306 123.708C51.0438 136.822 63.0637 146.064 76.1777 144.351Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M74.0781 145.279C87.1921 143.565 96.4343 131.545 94.721 118.431C93.0078 105.317 80.9879 96.0753 67.8739 97.7885C54.7599 99.5018 45.5178 111.522 47.231 124.636C48.9442 137.75 60.9641 146.992 74.0781 145.279Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M75.6933 146.062C88.8074 144.349 98.0495 132.329 96.3363 119.215C94.623 106.101 82.6032 96.8585 69.4892 98.5717C56.3752 100.285 47.133 112.305 48.8462 125.419C50.5595 138.533 62.5793 147.775 75.6933 146.062Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M77.3105 146.845C90.4245 145.132 99.6667 133.112 97.9535 119.998C96.2402 106.884 84.2204 97.6417 71.1064 99.3549C57.9923 101.068 48.7502 113.088 50.4634 126.202C52.1766 139.316 64.1965 148.558 77.3105 146.845Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M78.9258 147.626C92.0398 145.913 101.282 133.893 99.5687 120.779C97.8555 107.665 85.8356 98.423 72.7216 100.136C59.6076 101.849 50.3654 113.869 52.0786 126.983C53.7919 140.097 65.8117 149.339 78.9258 147.626Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M80.541 148.409C93.655 146.696 102.897 134.676 101.184 121.562C99.4707 108.448 87.4508 99.2062 74.3368 100.919C61.2228 102.633 51.9806 114.652 53.6939 127.767C55.4071 140.881 67.427 150.123 80.541 148.409Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M82.1582 149.193C95.2722 147.479 104.514 135.46 102.801 122.346C101.088 109.232 89.068 99.9894 75.954 101.703C62.84 103.416 53.5978 115.436 55.3111 128.55C57.0243 141.664 69.0442 150.906 82.1582 149.193Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M83.7715 149.974C96.8855 148.261 106.128 136.241 104.414 123.127C102.701 110.013 90.6813 100.771 77.5673 102.484C64.4533 104.197 55.2111 116.217 56.9244 129.331C58.6376 142.445 70.6574 151.687 83.7715 149.974Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M85.3887 150.757C98.5027 149.044 107.745 137.024 106.032 123.91C104.318 110.796 92.2985 101.554 79.1845 103.267C66.0705 104.98 56.8283 117 58.5415 130.114C60.2548 143.228 72.2746 152.47 85.3887 150.757Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M87.0039 151.538C100.118 149.825 109.36 137.805 107.647 124.691C105.934 111.577 93.9137 102.335 80.7997 104.048C67.6857 105.762 58.4435 117.781 60.1568 130.895C61.87 144.009 73.8899 153.252 87.0039 151.538Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M88.6211 152.322C101.735 150.608 110.977 138.588 109.264 125.474C107.551 112.36 95.5309 103.118 82.4169 104.832C69.3029 106.545 60.0607 118.565 61.774 131.679C63.4872 144.793 75.5071 154.035 88.6211 152.322Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M90.2363 153.105C103.35 151.392 112.592 139.372 110.879 126.258C109.166 113.144 97.1461 103.901 84.0321 105.615C70.9181 107.328 61.676 119.348 63.3892 132.462C65.1024 145.576 77.1223 154.818 90.2363 153.105Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M91.8535 153.886C104.968 152.173 114.21 140.153 112.496 127.039C110.783 113.925 98.7633 104.683 85.6493 106.396C72.5353 108.109 63.2931 120.129 65.0064 133.243C66.7196 146.357 78.7395 155.599 91.8535 153.886Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M93.4687 154.669C106.583 152.956 115.825 140.936 114.112 127.822C112.398 114.708 100.379 105.466 87.2646 107.179C74.1505 108.892 64.9084 120.912 66.6216 134.026C68.3349 147.14 80.3547 156.382 93.4687 154.669Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M95.084 155.452C108.198 153.739 117.44 141.719 115.727 128.605C114.014 115.491 101.994 106.249 88.8798 107.962C75.7658 109.676 66.5236 121.695 68.2369 134.809C69.9501 147.923 81.9699 157.166 95.084 155.452Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M96.7011 156.234C109.815 154.52 119.057 142.501 117.344 129.387C115.631 116.273 103.611 107.03 90.497 108.744C77.383 110.457 68.1408 122.477 69.854 135.591C71.5673 148.705 83.5871 157.947 96.7011 156.234Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M98.3164 157.017C111.43 155.304 120.673 143.284 118.959 130.17C117.246 117.056 105.226 107.814 92.1122 109.527C78.9982 111.24 69.756 123.26 71.4693 136.374C73.1825 149.488 85.2024 158.73 98.3164 157.017Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M99.9316 157.8C113.046 156.087 122.288 144.067 120.575 130.953C118.861 117.839 106.841 108.597 93.7274 110.31C80.6134 112.023 71.3713 124.043 73.0845 137.157C74.7977 150.271 86.8176 159.513 99.9316 157.8Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M101.549 158.581C114.663 156.868 123.905 144.848 122.192 131.734C120.479 118.62 108.459 109.378 95.3446 111.091C82.2306 112.805 72.9885 124.824 74.7017 137.938C76.4149 151.052 88.4348 160.295 101.549 158.581Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M103.164 159.365C116.278 157.651 125.52 145.631 123.807 132.517C122.094 119.403 110.074 110.161 96.9599 111.874C83.8459 113.588 74.6037 125.608 76.3169 138.722C78.0302 151.836 90.05 161.078 103.164 159.365Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M104.779 160.148C117.893 158.434 127.135 146.415 125.422 133.301C123.709 120.187 111.689 110.944 98.5751 112.658C85.4611 114.371 76.2189 126.391 77.9322 139.505C79.6454 152.619 91.6653 161.861 104.779 160.148Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M106.395 160.929C119.509 159.216 128.751 147.196 127.037 134.082C125.324 120.968 113.304 111.726 100.19 113.439C87.0763 115.152 77.8342 127.172 79.5474 140.286C81.2606 153.4 93.2805 162.642 106.395 160.929Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M108.012 161.712C121.126 159.999 130.368 147.979 128.655 134.865C126.941 121.751 114.922 112.509 101.808 114.222C88.6935 115.935 79.4514 127.955 81.1646 141.069C82.8778 154.183 94.8977 163.425 108.012 161.712Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M109.627 162.493C122.741 160.78 131.983 148.76 130.27 135.646C128.557 122.532 116.537 113.29 103.423 115.003C90.3087 116.717 81.0666 128.736 82.7798 141.85C84.4931 154.965 96.5129 164.207 109.627 162.493Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M111.244 163.277C124.358 161.563 133.6 149.544 131.887 136.43C130.174 123.316 118.154 114.073 105.04 115.787C91.9259 117.5 82.6838 129.52 84.397 142.634C86.1102 155.748 98.1301 164.99 111.244 163.277Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M112.859 164.06C125.973 162.347 135.216 150.327 133.502 137.213C131.789 124.099 119.769 114.857 106.655 116.57C93.5412 118.283 84.299 130.303 86.0122 143.417C87.7255 156.531 99.7453 165.773 112.859 164.06Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M114.475 164.841C127.589 163.128 136.831 151.108 135.118 137.994C133.404 124.88 121.384 115.638 108.27 117.351C95.1564 119.064 85.9142 131.084 87.6275 144.198C89.3407 157.312 101.361 166.554 114.475 164.841Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M116.09 165.624C129.204 163.911 138.446 151.891 136.733 138.777C135.02 125.663 123 116.421 109.886 118.134C96.7716 119.847 87.5295 131.867 89.2427 144.981C90.9559 158.095 102.976 167.338 116.09 165.624Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M117.707 166.407C130.821 164.694 140.063 152.674 138.35 139.56C136.637 126.446 124.617 117.204 111.503 118.917C98.3888 120.631 89.1467 132.651 90.8599 145.765C92.5731 158.879 104.593 168.121 117.707 166.407Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M119.322 167.191C132.436 165.477 141.678 153.458 139.965 140.344C138.252 127.23 126.232 117.987 113.118 119.701C100.004 121.414 90.7619 133.434 92.4751 146.548C94.1884 159.662 106.208 168.904 119.322 167.191Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M120.939 167.972C134.053 166.259 143.296 154.239 141.582 141.125C139.869 128.011 127.849 118.769 114.735 120.482C101.621 122.195 92.3791 134.215 94.0923 147.329C95.8056 160.443 107.825 169.685 120.939 167.972Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M122.555 168.755C135.669 167.042 144.911 155.022 143.198 141.908C141.484 128.794 129.465 119.552 116.35 121.265C103.236 122.978 93.9943 134.998 95.7076 148.112C97.4208 161.226 109.441 170.468 122.555 168.755Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M124.17 169.536C137.284 167.823 146.526 155.803 144.813 142.689C143.1 129.575 131.08 120.333 117.966 122.046C104.852 123.76 95.6096 135.779 97.3228 148.893C99.036 162.007 111.056 171.25 124.17 169.536Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M125.787 170.32C138.901 168.606 148.143 156.587 146.43 143.472C144.717 130.358 132.697 121.116 119.583 122.83C106.469 124.543 97.2267 136.563 98.94 149.677C100.653 162.791 112.673 172.033 125.787 170.32Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M127.402 171.103C140.516 169.39 149.758 157.37 148.045 144.256C146.332 131.142 134.312 121.9 121.198 123.613C108.084 125.326 98.842 137.346 100.555 150.46C102.268 163.574 114.288 172.816 127.402 171.103Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M129.02 171.884C142.134 170.171 151.376 158.151 149.662 145.037C147.949 131.923 135.929 122.681 122.815 124.394C109.701 126.107 100.459 138.127 102.172 151.241C103.886 164.355 115.905 173.597 129.02 171.884Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M130.635 172.667C143.749 170.954 152.991 158.934 151.278 145.82C149.564 132.706 137.545 123.464 124.431 125.177C111.317 126.89 102.074 138.91 103.788 152.024C105.501 165.138 117.521 174.38 130.635 172.667Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M132.25 173.449C145.364 171.735 154.606 159.715 152.893 146.601C151.18 133.487 139.16 124.245 126.046 125.958C112.932 127.672 103.69 139.692 105.403 152.806C107.116 165.92 119.136 175.162 132.25 173.449Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M133.867 174.232C146.981 172.518 156.223 160.499 154.51 147.385C152.797 134.271 140.777 125.028 127.663 126.742C114.549 128.455 105.307 140.475 107.02 153.589C108.733 166.703 120.753 175.945 133.867 174.232Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M135.482 175.015C148.596 173.302 157.839 161.282 156.125 148.168C154.412 135.054 142.392 125.812 129.278 127.525C116.164 129.238 106.922 141.258 108.635 154.372C110.349 167.486 122.368 176.728 135.482 175.015Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M137.098 175.796C150.212 174.083 159.454 162.063 157.741 148.949C156.027 135.835 144.007 126.593 130.893 128.306C117.779 130.019 108.537 142.039 110.251 155.153C111.964 168.267 123.984 177.509 137.098 175.796Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M138.715 176.579C151.829 174.866 161.071 162.846 159.358 149.732C157.645 136.618 145.625 127.376 132.511 129.089C119.397 130.803 110.154 142.822 111.868 155.936C113.581 169.05 125.601 178.293 138.715 176.579Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M140.33 177.363C153.444 175.649 162.686 163.629 160.973 150.515C159.26 137.401 147.24 128.159 134.126 129.873C121.012 131.586 111.77 143.606 113.483 156.72C115.196 169.834 127.216 179.076 140.33 177.363Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M141.945 178.144C155.059 176.431 164.301 164.411 162.588 151.297C160.875 138.183 148.855 128.941 135.741 130.654C122.627 132.367 113.385 144.387 115.098 157.501C116.811 170.615 128.831 179.857 141.945 178.144Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M143.562 178.927C156.676 177.214 165.919 165.194 164.205 152.08C162.492 138.966 150.472 129.724 137.358 131.437C124.244 133.15 115.002 145.17 116.715 158.284C118.429 171.398 130.448 180.64 143.562 178.927Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M145.178 179.71C158.292 177.997 167.534 165.977 165.821 152.863C164.107 139.749 152.088 130.507 138.974 132.22C125.86 133.933 116.617 145.953 118.331 159.067C120.044 172.181 132.064 181.423 145.178 179.71Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M146.793 180.491C159.907 178.778 169.149 166.758 167.436 153.644C165.723 140.53 153.703 131.288 140.589 133.001C127.475 134.715 118.233 146.735 119.946 159.849C121.659 172.963 133.679 182.205 146.793 180.491Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M148.41 181.275C161.524 179.561 170.766 167.542 169.053 154.428C167.34 141.314 155.32 132.071 142.206 133.785C129.092 135.498 119.85 147.518 121.563 160.632C123.276 173.746 135.296 182.988 148.41 181.275Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M150.025 182.058C163.139 180.345 172.382 168.325 170.668 155.211C168.955 142.097 156.935 132.855 143.821 134.568C130.707 136.281 121.465 148.301 123.178 161.415C124.891 174.529 136.911 183.771 150.025 182.058Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M151.643 182.839C164.757 181.126 173.999 169.106 172.285 155.992C170.572 142.878 158.552 133.636 145.438 135.349C132.324 137.062 123.082 149.082 124.795 162.196C126.509 175.31 138.529 184.552 151.643 182.839Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M153.258 183.622C166.372 181.909 175.614 169.889 173.901 156.775C172.187 143.661 160.168 134.419 147.054 136.132C133.94 137.846 124.697 149.865 126.411 162.979C128.124 176.093 140.144 185.336 153.258 183.622Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M154.873 184.404C167.987 182.69 177.229 170.67 175.516 157.556C173.803 144.442 161.783 135.2 148.669 136.914C135.555 138.627 126.313 150.647 128.026 163.761C129.739 176.875 141.759 186.117 154.873 184.404Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                        <path d="M156.488 185.187C169.602 183.474 178.844 171.454 177.131 158.34C175.418 145.226 163.398 135.984 150.284 137.697C137.17 139.41 127.928 151.43 129.641 164.544C131.354 177.658 143.374 186.9 156.488 185.187Z" stroke="var(--base-color)" stroke-width="0.131657" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_4835_14140">
                        <rect width="160.356" height="183.609" fill="white" transform="translate(0 14.2168) rotate(-7.44306)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    )
}
