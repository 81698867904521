import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loader/Loading";
import { objectDeepClone } from "@apiwiz/oas/services";
import Select from "react-select";
import { getRatePlanValue, throwServerError } from "../../../utils/helper";
import { Plus, Trash } from "phosphor-react";
import { getToast } from "../../../components/Toast";
import API from "../../../api";
import monetizationAPI from "../../../api/monetization";

export const ProductBundleBuyNowModal = (props) => {
  const [disableSave, setDisableSave] = useState(false);
  const [data, setData] = useState({});
  const [loading,setLoading] = useState(false);

  const [ratePlans, setRatePlans] = useState(null);
  const [ratePlanOptions, setRatePlanOptions] = useState([]);
  const [selectedRatePlan, setSelectedRatePlan] = useState(null);
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [productBundleOptions, setProductBundleOptions] = useState([]);
  const [productBundleList, setProductBundleList] = useState(null);
  const [chosenProductBundle, setChosenProductBundle] = useState(null);
  const [productsList, setProductsList] = useState(null);



  useEffect(() => {
    let _data = objectDeepClone(props.data || {});
    console.log("_data", _data)
    setChosenProductBundle(_data);

    setData(objectDeepClone(_data));

    if (_data?.ratePlans) {
      const _portalVisibleRateplans = _data?.ratePlans?.filter(
        (ratePlan) => ratePlan.visibleToPortals
      );
      setRatePlans(_portalVisibleRateplans);
    }
  }, []);

  useEffect(() => {
    if (ratePlans && ratePlans.length) {
      const options = ratePlans.reduce(
        (acc, curr) => [...acc, { value: curr, label: curr }],
        []
      );

      setRatePlanOptions(options);
    }
  }, [ratePlans]);

  /**
   *
   * Product Bundles for all orgs and products
   */
  const getProductBundles = () => {
    monetizationAPI
      .getAllProductBundles()
      .then((response) => {
        const { data } = response.data;
        console.log("bundle data", data);
        setProductBundleList(data);

        const options = data.map((bundle) => ({
          label: bundle.productBundle?.name,
          value: bundle,
        }));

        setProductBundleOptions(options);
        getProducts();
      })
      .catch((err) => {
        console.log("getProductBundles err: ", err);
      });
  };

  const getProducts = () => {
    monetizationAPI
      .getAllProducts()
      .then((res) => {
        const { data } = res.data;
        const _data = data.map((product) => ({ ...product, isProduct: true }));

        setProductsList(_data);

        const options = _data.map((product) => ({
          label: product?.name ? product.name : product.productName,
          value: product,
          isProduct: product.isProduct,
        }));

        setProductBundleOptions((prev) => [...prev, ...options]);
      })
      .catch((err) => {
        console.log("getProducts err: ", err);
      });
  };

  const handleChange = (_val, _id, type) => {
    let _attributes = objectDeepClone(attributes);

    _attributes[_id][type] = _val;
    setAttributes(objectDeepClone(_attributes));
  };

  const handleDelete = (_id) => {
    let _attributes = objectDeepClone(attributes);
    _attributes.splice(_id, 1);
    setAttributes(objectDeepClone(_attributes));
  };

  const handleAdd = () => {
    let _attributes = objectDeepClone(attributes);
    _attributes.push({
      name: "",
      value: "",
    });
    setAttributes(objectDeepClone(_attributes));
  };

  const handleSubmit = () => {
    // different flow for Kong and Azure
    if (!selectedRatePlan || !appName || !description) {
      getToast({
        statusType: "ERROR",
        message: "Please fill all the required details",
      });
      return;
    }

    let json = {
      apiProducts: objectDeepClone(chosenProductBundle.productBundle?.products),
      attributes,
      description,
      name: appName,
      keyExpiresIn: "-1",
      organization: chosenProductBundle.productBundle.organization,
      productBundle: chosenProductBundle.productBundle,
      ratePlan: objectDeepClone(selectedRatePlan),
    };

    setDisableSave(true);
    setLoading(true);
    API.addBuildApps(json)
      .then((res) => {
        getToast({
          statusType: "SUCCESS",
          message: "App added successfully",
        });

        const { data } = res.data;
        const { appId, name } = data;

        const params = {
          appId,
          appName: name,
          ratePlan: selectedRatePlan,
          productBundle: chosenProductBundle,
        };

        API.purchaseRatePlanUsingWallet(params)
          .then((res) => {
            getToast({
              statusType: "SUCCESS",
              message: 'Purchase Completed Successfully"',
            });
            setDisableSave(false);
            setLoading(false);
            props.onHide();
          })
          .catch((err) => {
            getToast({
              statusType: "ERROR",
              message: "Unable to Purchase App",
            });
            setDisableSave(false);
            setLoading(false);
            props.onHide();
          });
      })
      .catch((err) => {
        setDisableSave(false);
        setLoading(false);
        throwServerError(err);
      });
  };



  return (
    <Modal
      {...props}
      size="md me-0 mt-0 mb-0"
      contentClassName="br-0px homeModalForLghtMode"
      className="custom-modal-v1 v1--styles"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-3 br-0px">
        <div className="ps-1 d-flex align-items-center justify-content-between w-100">
          <p className="mb-0 text-content-subtle fw-500 fs-16px">
            {props.isUpdate ? "Update App" : "App Creation"}
          </p>

          <div className="d-flex align-items-center">
            <div
              className="text-content-subtle fs-12px fw-400 me-3 cursor-pointer"
              onClick={() => {
                props.onHide();
              }}
            >
              Cancel
            </div>

            {disableSave ? (
              <Loading size="sm" />
            ) : (
              <div
                className="cursor bg-base-color text-content-subtle px-16px py-6px br-4px fw-500 fs-12px text-300 px-3"
                onClick={handleSubmit}
                style={{ border: "0.5px solid var(--content-subtle)" }}
              >
                {props.isUpdate ? "Save" : "Buy Now"}
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ height: "calc(100vh - 60px)" }}
        className="br-0 overflow-scroll noscrollbar"
      >
        {loading ? <Loading/> : <div className="">
          <div className="mb-3 mainSelect">
            <p className="fs-12px fw-400 text-content-subtle">
              {chosenProductBundle ? "Product Bundle" : "Product"}
            </p>
            {chosenProductBundle && (
              <p className="fs-14px fw-500 text-content-color mt-1px">
                {chosenProductBundle?.productBundle?.name}
              </p>
            )}
          </div>


          {chosenProductBundle && (
            <div className="mb-3">
              <p className="fs-12px fw-400 text-content-subtle">Products</p>
              <div className="dfaic flex-wrap mt-2px">
                {chosenProductBundle?.productBundle?.products?.map(
                  (eachProduct) => (
                    <p className="bg-base-200 py-4px px-8px fs-14px fw-500 text-content-color mt-1px">
                      {eachProduct}
                    </p>
                  )
                )}
              </div>
            </div>
          )}

          { chosenProductBundle && (
            <div className="mb-3 mainSelect">
              <p className="fs-12px fw-400 text-content-subtle">
                Select Rate Plan <span className="text-danger">*</span>{" "}
              </p>
              <Select
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "focused"
                      : state.isSelected
                      ? "selected"
                      : "",
                }}
                className="mt-1 v1select"
                placeholder={`${
                  selectedRatePlan
                    ? selectedRatePlan.name
                    : "Select a Rate Plan"
                }`}
                value={null}
                options={ratePlanOptions}
                formatOptionLabel={(props) => (
                  <div className="d-flex align-items-start gap-40px">
                    <div className="rate-plan-name">
                      <div className="fs-12px">Rate Plan Name</div>
                      <div className="fs-11px text-secondary-color">
                        {" "}
                        {props.label.name}
                      </div>
                    </div>
                    <div className="rate-plan-pricings">
                      {getRatePlanValue({ rateplan: props.value })?.map(
                        (pricings) => (
                          <p className="fs-12px text-primary-color">
                            {pricings}
                          </p>
                        )
                      )}
                    </div>
                  </div>
                )}
                onChange={(e) => {
                  setSelectedRatePlan(e.value);
                }}
              />
            </div>
          )}

          <div className="mb-3">
            <p className="fs-12px fw-400 text-content-subtle">
              App Name <span className="text-danger">*</span>
            </p>
            <input
              type="text"
              value={appName}
              disabled={props.isUpdate}
              onChange={(e) => setAppName(e.target.value)}
              className="from-control v1-form-control mt-1 v1-form-control-public w-100"
            />
          </div>

          <div className="mb-3">
            <p className="fs-12px fw-400 text-content-subtle">
              Description <span className="text-danger">*</span>
            </p>
            <textarea
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="from-control v1-form-textarea mt-1 v1-form-control-public w-100"
            />
          </div>
          {chosenProductBundle && (
            <div
              className="mb-3"
              style={{
                border: "1px solid var(--base-100)",
                borderLeft: "0px",
              }}
            >
              <div
                className="px-16px py-18px"
                style={{ borderLeft: "5px solid var(--primary-color)" }}
              >
                <p className="fs-14px fw-600 text-content-color">Attribute</p>

                <div
                  className="dfaic justify-content-center px-24px py-10px br-4px mt-2 cursor"
                  style={{ border: "1px dashed var(--secondary-100)" }}
                  onClick={handleAdd}
                >
                  <Plus color="var(--secondary-100)" size={16} />
                  <p className="text-content-color fs-14px ms-2 fw-500">
                    Add Attribute
                  </p>
                </div>

                <div className="row mt-3">
                  <div className="col-md-5">
                    <p className="fs-12px fw-500 text-content-subtle">Name</p>
                  </div>
                  <div className="col-md-5">
                    <p className="fs-12px fw-500 text-content-subtle">Value</p>
                  </div>
                </div>

                {attributes?.map((each, i) => (
                  <div className="row mt-2">
                    <div className="col-md-5 pe-0">
                      <input
                        type="text"
                        className="form-control v1-form-control v1-form-control-public"
                        value={each.name}
                        onChange={(e) =>
                          handleChange(e.target.value, i, "name")
                        }
                      />
                    </div>
                    <div className="col-md-5 pe-0">
                      <input
                        type="text"
                        className="form-control v1-form-control v1-form-control-public"
                        value={each.value}
                        onChange={(e) =>
                          handleChange(e.target.value, i, "value")
                        }
                      />
                    </div>
                    <div className="col-md-2 my-auto">
                      <div
                        className="ms-3 cursor"
                        onClick={() => handleDelete(i)}
                      >
                        <Trash color="var(--content-subtle)" size={18} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>}
      </Modal.Body>
    </Modal>
  );
}
