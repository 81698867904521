function paginationLimit() {
	let limit;
	window.screen.height >= 1080
		? (limit = 12)
		: window.screen.height < 768
		? (limit = 6)
		: (limit = 9);
	return limit;
}

export default paginationLimit;
