import { useEffect, useMemo, useState } from "react";
import { BorderWithLabel, Input, MultiDropdown } from "./Inputs";
import { useSelector } from "react-redux";
import { startCase } from "lodash";
import { setSecurities, setTryoutOperationSecurity } from "../../../app/Features/Swagger/SwaggerSlice";
import { useDispatch } from "react-redux";
import { debounceFn, objectDeepClone, throwServerError } from "../../../utils/helper";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import axios from "axios";
import { encode } from "js-base64";
import ContainerLoader from "../../Loader/ContainerLoader";

let defaultCallbackUrl = `${window.location.origin}/oauth2-callback.html`;

const updateOauth2 = ({ value }) => {
    window.dispatch(setSecurities({ prop: "oauth2", value }))
}
const updateDebounceFn = debounceFn(updateOauth2, 200);


export default function Oauth(props) {
  const { securities, selectedOperation } = useSelector((state) => state.swagger);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const oauth2 = useMemo(() => {
    return securities.oauth2 || {};
  }, [securities]);
  const operationOauthFlows = useMemo(() => {
    let _flows = ((selectedOperation.__security||{}).oauth2 || []).map(x => x.flow)
    return _flows
  }, [selectedOperation])
  const [activeFlow, setActiveFlow] = useState(operationOauthFlows[0] || "");
  const currFlow = useMemo(() => {
    return oauth2[activeFlow] || {};
  }, [activeFlow, oauth2])

  const onAuthorize = () => {
    if (loading) return;
    let _currFlow = currFlow;
    let hasError = true;
    console.log("_currflow:",_currFlow);
    if (
      _currFlow.clientId &&
      _currFlow.clientSecret &&
      (activeFlow !== "password" ||
        (activeFlow === "password" &&
          _currFlow.username &&
          _currFlow.password)) &&
      (!["authorizationCode", "implicit", "accessCode"].includes(activeFlow) ||
        (["authorizationCode", "implicit", "accessCode"].includes(activeFlow) &&
          _currFlow.authorizationUrl)) &&
      (![
        "authorizationCode",
        "accessCode",
        "password",
        "clientCredentials",
        "application",
      ].includes(activeFlow) ||
        ([
          "authorizationCode",
          "accessCode",
          "password",
          "clientCredentials",
          "application",
        ].includes(activeFlow) &&
          _currFlow.tokenUrl))
    ) {
      hasError = false;
    }

    if(hasError) {
        throwServerError("Please fill all required fields!")
        return;
    }

    authorizeFlows(_currFlow);
  };

  useEffect(() => {
    props.updateActionsRef(
      <>
        <div
          onClick={props.closeModal || null}
          className="w-85px d-flex align-items-center justify-content-center text-theme-content-subtle fs-14px cursor"
        >
          Cancel
        </div>
        <div
          onClick={onAuthorize}
          className="py-4px px-8px br-4px bg-theme-primary text-theme-base fs-14px cursor"
        >
          {loading ? (
            <ContainerLoader
              className="min-w-72px min-h-22px fs-14px"
              size="sm"
              variant="light"
            />
          ) : ["authorizationCode", "accessCode", "implicit"].includes(
              activeFlow
            ) ? (
            "Authorize"
          ) : (
            "Get Access Token"
          )}
        </div>
      </>
    );
  }, [loading, activeFlow, securities]);

  const updateSecurity = ({ security }) => {
    let _oauth2 = objectDeepClone(oauth2);
    _oauth2[activeFlow] = security;
    dispatch(setSecurities({ prop: "oauth2", value: _oauth2 }));
  };

  const onInput = ({ value, prop, currSecurity }) => {
    let _security = currSecurity;
    if (!_security) _security = objectDeepClone(currFlow);
    if (prop) _security[prop] = value;
    if (_security) updateSecurity({ security: _security });
  };

  const onScopeSelected = ({ scope, value }) => {
    let _oauth2 = objectDeepClone(oauth2);
    _oauth2[activeFlow].scopes[scope].checked = value;
    dispatch(setSecurities({ prop: "oauth2", value: _oauth2 }));
  };

  const setAccessToken = (accessToken) => {
    let _security = objectDeepClone(currFlow);
    _security["accessToken"] = accessToken;
    _security["showAccessToken"] = true;

    let selectedScopes = [];
    for (let s in _security.scopes || {}) {
      if (_security.scopes[s].checked) selectedScopes.push(s);
    }
    _security.selectedScopes = selectedScopes;
    onInput({ currSecurity: _security });
    setTimeout(() => {
      dispatch(setTryoutOperationSecurity())
    }, 200);
  };

  function authorizeFlows(currFlow) {
    if (currFlow.flow === "password") {
      setLoading(true);
      axios
        .post(
          currFlow.tokenUrl,
          `grant_type=password&username=${currFlow.username}&password=${currFlow.password}`,
          {
            headers: {
              Accept: "application/x-www-form-urlencoded",
              Authorization: `Basic ${encode(
                `${currFlow.clientId}:${currFlow.clientSecret}`
              )}`,
            },
          }
        )
        .then((res) => {
          let accessToken = res.data.access_token;
          if (accessToken) {
            setAccessToken(accessToken);
          }
          setLoading(false);
        })
        .catch((err) => {
          throwServerError(err);
          setLoading(false);
        });
    }

    if (["application", "clientCredentials"].includes(currFlow.flow)) {
      setLoading(true);
      axios
        .post(currFlow.tokenUrl, "grant_type=client_credentials", {
          headers: {
            Authorization: `Basic ${encode(
              `${currFlow.clientId}:${currFlow.clientSecret}`
            )}`,
            Accept: "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          let accessToken = res.data.access_token;
          setAccessToken(accessToken);
          setLoading(false);
        })
        .catch((err) => {
          throwServerError(err);
          setLoading(false);
        });
      return;
    }

    if (
      ["authorizationCode", "accessCode", "implicit"].includes(currFlow.flow)
    ) {
      let data = `client_id=${currFlow.clientId}`;

      if (
        currFlow.flow === "authorizationCode" ||
        currFlow.flow === "accessCode"
      ) {
        data += `&response_type=code`;
      }

      if (currFlow.flow === "implicit") {
        data += "&response_type=token";
      }

      let callbackUrl = currFlow.callbackUrl || defaultCallbackUrl;
      data += `&redirect_uri=${callbackUrl}`;

      let _scopes = [];
      for (let s in currFlow.scopes) {
        if (currFlow.scopes[s].checked) _scopes.push(s);
      }

      if (_scopes.length) data += `&scope=${_scopes.join("+")}`;
      const a = window.open(
        `${currFlow.authorizationUrl}?${data}`,
        "OAuth 2.0",
        "location=0,height=570,width=520,scrollbars=yes,status=yes"
      );
      a.focus();
      window.onOAuthFinished = (obj) => {
        console.log(obj);
        let accessToken = obj.access_token;
        if (accessToken) {
          setAccessToken(accessToken);
          return;
        }

        let code = obj.code;
        if (code) {
          setLoading(true);
          axios
            .post(
              currFlow.tokenUrl,
              `grant_type=authorization_code&code=${code}&client_id=${currFlow.clientId}&client_secret=${currFlow.clientSecret}&redirect_uri=${callbackUrl}`,
              {
                headers: {
                  Accept: "application/x-www-form-urlencoded",
                },
              }
            )
            .then((res) => {
              console.log(`${currFlow.flow} res:`, { res });
              let accessToken = res.data.access_token;
              if (accessToken) {
                setAccessToken(accessToken);
              }
              setLoading(false);
            })
            .catch((err) => {
              throwServerError(err);
              setLoading(false);
            });
        }
      };
    }
  }

  return (
    <div className="d-flex flex-column gap-8px h-100">
      <BorderWithLabel label="auth type" required>
        <div className="h-46px d-flex align-items-center flex-wrap column-gap-16px px-12px">
          {operationOauthFlows.map((flow) => {
            return (
              <div
                onClick={() => {
                  setActiveFlow(flow);
                }}
                className="d-flex gap-4px align-items-center cursor"
              >
                <div
                  className={`mt-2px h-15px w-15px _2px br-50 d-flex justify-content-center align-items-center ${
                    activeFlow === flow
                      ? "border-theme-primary"
                      : "border-theme-content-subtle"
                  }`}
                >
                  {activeFlow === flow ? (
                    <div className="bg-theme-secondary h-7px w-7px br-50 d-flex border-theme-content-subtle"></div>
                  ) : null}
                </div>
                <div className="fs-14px lh-21px fw-500 text-theme-content-subtle">
                  {startCase(flow)}
                </div>
              </div>
            );
          })}
        </div>
      </BorderWithLabel>
      <div className="overflow-scroll-y noscrollbar h--46px">
        {/* Scope input */}
        <div>
          <MultiDropdown
            label="Scopes"
            value={Object.keys(currFlow.scopes || {})
              .filter((x) => currFlow.scopes[x].checked)
              .join(", ")}
            options={Object.keys(currFlow.scopes || {}).map((x) => ({
              label: x,
              icon: (
                <div>
                  {currFlow.scopes[x].checked ? (
                    <ImCheckboxChecked color="var(--primary-color)" />
                  ) : (
                    <ImCheckboxUnchecked color="var(--content-subtle)" />
                  )}
                </div>
              ),
              onSelect: () => {
                onScopeSelected({
                  scope: x,
                  value: !currFlow.scopes[x].checked,
                });
              },
            }))}
          />
        </div>
        <div>
          <Input
            label="Client ID"
            required
            value={currFlow?.clientId || ""}
            onChange={(e) => {
              onInput({ prop: "clientId", value: e.target.value });
            }}
          />
        </div>
        <div>
          <Input
            label="Client Secret"
            required
            value={currFlow?.clientSecret || ""}
            onChange={(e) => {
              onInput({ prop: "clientSecret", value: e.target.value });
            }}
          />
        </div>

        {activeFlow === "password" ? (
          <>
            <div>
              <Input
                label="Username"
                required
                value={currFlow?.username || ""}
                onChange={(e) => {
                  onInput({ prop: "username", value: e.target.value });
                }}
              />
            </div>
            <div>
              <Input
                label="Password"
                required
                value={currFlow?.password || ""}
                onChange={(e) => {
                  onInput({ prop: "password", value: e.target.value });
                }}
              />
            </div>
          </>
        ) : null}
        <div>
          <Input
            label="Authorization Callback Url"
            required
            value={currFlow?.callbackUrl || defaultCallbackUrl || ""}
            onChange={(e) => {
              onInput({ prop: "callbackUrl", value: e.target.value });
            }}
          />
        </div>
        {["authorizationCode", "implicit", "accessCode"].includes(
          activeFlow
        ) ? (
          <div>
            <Input
              label="Authorization Url"
              required
              value={currFlow?.authorizationUrl || ""}
              onChange={(e) => {
                onInput({ prop: "authorizationUrl", value: e.target.value });
              }}
            />
          </div>
        ) : null}
        {[
          "authorizationCode",
          "accessCode",
          "password",
          "clientCredentials",
          "application",
        ].includes(activeFlow) ? (
          <div>
            <Input
              label="Token Url"
              required
              value={currFlow?.tokenUrl || ""}
              onChange={(e) => {
                onInput({ prop: "tokenUrl", value: e.target.value });
              }}
            />
          </div>
        ) : null}
        {currFlow.showAccessToken ? (
          <div>
            <Input
              label="Access Token"
              disabled
              value={currFlow?.accessToken}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
