import React from "react";
import { formatNumber } from "../../../../utils/helper";
import { MonetizationTrend } from "../../../../service/constants";
import { TrendDown, TrendUp, ArrowsHorizontal } from "phosphor-react";

const MonetizeTrend = (props) => {
    const { style, value } = props;
    return (
        <div className={`gap-4px montize-trend ${value === 0 ? MonetizationTrend.TrendMiddle : value > 0 ? MonetizationTrend.TrendUp : value < 0 ? MonetizationTrend.TrendDown : MonetizationTrend.TrendMiddle} fw-500 fs-12px d-flex align-items-center`} style={style || {}}>
            {value === 0 ? <ArrowsHorizontal size={20} color='#52A9FF' /> : value > 0 ? <TrendUp size={20} color='#17B26A' /> : value < 0 ? <TrendDown size={20} color='#17B26A' /> : <ArrowsHorizontal size={20} color='#17B26A' />}
            {formatNumber(Math.abs(value))}%
        </div>
    );
};

export const MonetizeTrendv2 = (props) => {
    const { style, value } = props;
    return (
        <div className={`gap-4px montize-trend ${value === 0 ? MonetizationTrend.TrendMiddle : value > 0 ? MonetizationTrend.TrendUp : value < 0 ? MonetizationTrend.TrendDown : MonetizationTrend.TrendMiddle} fw-500 fs-14px d-flex align-items-center`} style={style || {}}>
            {value === 0 ? <ArrowsHorizontal size={20} color='#52A9FF' /> : value > 0 ? <TrendUp size={20} color='#17B26A' /> : value < 0 ? <TrendDown size={20} color='#17B26A' /> : <ArrowsHorizontal size={20} color='#17B26A' />}
            {formatNumber(Math.abs(value))}%
        </div>
    );
};

export default MonetizeTrend;
