import React, { useState, useLayoutEffect, useEffect } from "react";
import Routes from "./Routes/Routes";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./styles/main.scss";
import "react-datepicker/dist/react-datepicker.css";
import { getCookie } from "./utils/cookie";
import {resetAuthenticatedState, setAuthenticatedState, setCurrentUser, setFirstRoute, setMenus, setMonetizationEnabled, setPermissions} from "./app/Features/Users/UserSlice"
import API from "./api";
import { objectDeepClone } from "@apiwiz/oas/services";
import { useNProgress } from '@tanem/react-nprogress'
import Container from './progressComponents/Container';
import Bar from './progressComponents/Bar';
import Spinner from './progressComponents/Spinner';
import { getUuidV4 } from '@apiwiz/oas/services';
import { useSelector } from "react-redux";
import { setColor, throwServerError } from "./utils/helper";
import { setGuestSetting } from "./app/Features/Settings/SettingSlice";
import { setCurrentLayoutObject } from "./app/Features/Layout/LayoutSlice";
import { useTheme } from "./context/ThemeContext";
import OverallStyles from "./styles-config.json"
import Loading from "./components/Loader/Loading";
import { useLocation } from "react-router";
import { resetDeveloperApp } from "./app/Features/DeveloperApp/DeveloperAppSlice";

const Progress = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  })

  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
      <Spinner />
    </Container>
  )
}

function App() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const {isAuthenticated, data, isAnimating} = useSelector(state => state.user)
    const {stateChanged: developerAppStateChanged} = useSelector(state => state.developerApp);
    const {theme} = useTheme()

    const getUserDetails = (id) => {

      if (id){
        API.getProfile(id)
        .then(res => {
          let _currentUser = objectDeepClone(res.data?.data || {})
          if (_currentUser?.userRole === 'super-admin' || _currentUser?.role === 'super-admin'){
            dispatch(setFirstRoute(`/admin/dashboard`))
          }
          dispatch(setCurrentUser(res.data.data))
          setTimeout(() => {
            setLoading(false)
          }, 200);
        }).catch(err => {
          throwServerError(err)
          setLoading(false)
        })
      } else {
        setLoading(false)
      }

      API.getAllCategories()
        .then(({data: res}) => {
            dispatch(setMonetizationEnabled(true))
        }).catch(err => {
            dispatch(setMonetizationEnabled(false))
            console.log('err', err)
            // throwServerError(err)
        })
    }

    const getLoginSettings = () => {

      API.getLoginSetting()
      .then((response) => {
        const { data } = response.data;
        dispatch(setGuestSetting(data));
      })
      .catch((error) => {
        throwServerError(error)
        setLoading(false)
      });
    }

    const getLayoutData = () => {

        API.getLayoutDetails()
        .then(res => {
          let _data = objectDeepClone(res.data?.layout || {})
          dispatch(setCurrentLayoutObject(objectDeepClone(_data)))
          document.getElementById('favicon-logo').setAttribute('href', _data.favicon || '')

          let currentTheme = OverallStyles[_data.siteTemplateType || 'Purple Magic'][theme]

          Object.keys(currentTheme).forEach(eachVariable => {
              setColor(eachVariable, currentTheme[eachVariable])
          })
          setColor('--currentBackground', theme === 'light' ? "white" : 'var(--base-color)')

          document.title = `${_data.metaTitle} | ${_data.metaDescription}`; 

        }).catch(err => {
          throwServerError(err)
          setLoading(false)
        })
    }

    useLayoutEffect(() => {
      if(!window.dispatch) window.dispatch = dispatch;
      checkAuth()
    }, [])

    function getSidebarDetails(){

      dispatch(setMenus())
      API.getPermissions()
      .then(response => {
        let permissions = objectDeepClone(response.data.data.permissions);

        const createObj = (o) => {
            return {
                name: o.object,
                crud: permissions.filter(n => n.object === o.object).map(n => {
                  return {
                      ...n,
                      roles: n.roles.map(r => {
                          let obj = {
                            role: r.code,
                            enabled: r.code === 'super-admin' ? true : r.enabled,
                            object: n.object,
                            action: n.action
                        }
                        return obj
                    })
                  }
                })
            }
        }
        let perm = permissions.map(createObj);

        const uniquePerm = perm.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === perm.findIndex(o => {
                return JSON.stringify(o) === _thing;
            });
        }).sort((a,b) => a.name<b.name ? -1 : a.name>b.name ? 1 : 0)
        dispatch(setPermissions(objectDeepClone(uniquePerm)))
      })
      .catch(err => {
          setLoading(false)
      })
    }

    function checkAuth() {

      getLoginSettings()
      getLayoutData()

      if(getCookie("itorix-token") && getCookie("itorix-token-data"))
      {

        const itorixToken = getCookie("itorix-token")
        const itorixTokenData = getCookie("itorix-token-data")
        const parsedToken = JSON.parse(itorixTokenData)

        dispatch(setAuthenticatedState({
          token: itorixToken, data: parsedToken
        }))

        getSidebarDetails()
        getUserDetails(parsedToken.user?._id)
      }
      else {

        dispatch(resetAuthenticatedState())
        setTimeout(() => {
          setLoading(false)
        }, 200);
      }
    }

    useEffect(() => {
      if(developerAppStateChanged)
      {
        let holdDeveloperAppInfoForPaths = ["/monetization/package-detail", "/apps", "/monetization/app", "/monetization/create-app"]
        let dontReset = false
        for(let path of holdDeveloperAppInfoForPaths)
        {
          if(location.pathname.includes(path)) {
            dontReset = true;
            break;
          }
        }
        if(!dontReset) dispatch(resetDeveloperApp());
      }
    }, [location])

    return (
      <div className={`v1--styles`}>
        { loading
        ? <div className="h-100vh w-100vw">
          <Loading />
        </div>
        : <>
          <Progress isAnimating={isAnimating} key={getUuidV4()} />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
          />
          <Routes />
        </>}
      </div>
    );
}

export default App;
