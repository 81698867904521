import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CustomNavbar from '../Dashboards/components/CustomNavbar'
import CustomFooter from '../Dashboards/components/CustomFooter'
import { ArrowSquareOut, BagSimple, CaretRight, Clipboard, Cloud, CreditCard, FilePlus, GridFour, Plus, Rows } from 'phosphor-react'
import Select from 'react-select'
import API from '../../api'
import monetizationAPI from '../../api/monetization'
import { formatCtsDate, formatCtsTime, throwServerError, copyToClipboard, sleep, confirm } from '../../utils/helper'
import ProductsModal from './components/ProductsModal'
import { objectDeepClone } from '@apiwiz/oas/services'
import Loading from '../../components/Loader/Loading'
import BuyNowModal from './components/BuyNowModal'
import MyReactTooltip from '../../components/ReactTooltip'
import RatePlanModal from './components/RatePlanModal'
import BillingDetails from './components/BillingDetails'
import { getToast } from '../../components/Toast'

export default function AllPurchases() {
    const { isAuthenticated } = useSelector(state => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [orgData, setOrgData] = useState([])
    const {currentUser} = useSelector(state => state.user)
    const [type, setType] = useState({})
    const [data, setData] = useState([])
    const [copied, setCopied] = useState('')

    const [ratePlanModal, setRatePlanModal] = useState({
        isOpen: false, data: {}
    })
    const [billingDetailsModal, setBillingDetailsModal] = useState({
        isOpen: false, data: {}
    })

    const handleCopy = async (_text) => {
        setCopied(_text)
        copyToClipboard(_text)
        await sleep(1000)
        setCopied('')
    }

    const navigate = useNavigate()

    useEffect(() => { 
        if (!isAuthenticated){
            navigate('/')
        }
		getAllPurchases()
	}, [currentUser]);

    const getAllPurchases = () => {
        setIsLoading(true)
        monetizationAPI.getAllPurchases()
        .then(res => {
            console.log("response: ", res)
            if(res?.data?.data?.error){
                getToast({
                    statusType: "ERROR",
                    message: res?.data?.data?.error?.userMessage
                })
            }else {
                if (res?.data?.data) {
                    setData(res?.data?.data);
                }
            }
            setIsLoading(false)
        })
        .catch(err => {
            setData([]);
            throwServerError(err)
            setIsLoading(false);
        })
    }

    // const getOrgs = () => {
	// 	setIsLoading(true);
	// 	API.getAllOrgs(1)
    //     .then((response) => {
    //         if (response.data.data) {
    //             setOrgData(response.data.data);
    //             let defaultOrg = "";
    //             response.data.data.forEach((el) => {
    //                 if (el.organisationDefault === "1") {
    //                     defaultOrg = el.organisationName;
    //                 }
    //             });

    //             if (currentUser.organization?.length > 0) {
    //                 defaultOrg = currentUser.organization[0];
    //                 setType({
    //                     value: defaultOrg,
    //                     label: defaultOrg,
    //                 });
    //             } else {
    //                 setType({
    //                     value: defaultOrg,
    //                     label: defaultOrg + " (default)",
    //                 });
    //             }
    //             getAppData(defaultOrg);
    //         }
    //         // setIsLoading(false)
    //     })
    //     .catch((err) => {
    //         throwServerError(err);
    //         setIsLoading(false);
    //     });
	// };

    const handleUnsubscribe = (_data) => {
        if (!confirm("Are you sure to unsubscribe")) return
        API.deletePurchaseHistory(_data.appId, _data.id)
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Unsubscribed successfully'
            })
            getAllPurchases()
        }).catch(err => {

        })
    }

    // const getAppData = (value) => {
	// 	setIsLoading(true);
	// 	API.getPurchaseHistory(value)
	// 		.then((response) => {
	// 			if (response?.data?.data) {
	// 				setData(response?.data?.data);
	// 			}
	// 			setIsLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			setData([]);
    //             throwServerError(err)
	// 			setIsLoading(false);
	// 		});
	// };

    // const handleOrgType = (selected) => {
	// 	setType({
	// 		value: selected.value,
	// 		label: selected.label,
	// 	});
	// 	getAppData(selected.value);
	// };

    console.log("data: ", data)


    
    return (
        <div className='w-100vw bg-currentBackground'>
            <CustomNavbar />

            <div className='mt-62px container productBundleSection'>
                <div className='w-100 bg-theme-base h-100 py-32px' style={{minHeight: '100vh'}}>
                    {isLoading
                    ? <div className='mt-5'>
                        <Loading />
                    </div>
                    : <>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className='fs-24px fw-600 text-content-color'>My Purchases</p>

                   
                        </div>

                        {<div className='mt-3'>
                            <div className='row px-12px py-8px mb-1' style={{borderBottom: '1px solid var(--base-100)'}}>
                                <div className='col-md-3'>
                                    <p className='text-content-subtle fs-10px fw-500'>App Name</p>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <p className='text-content-subtle fs-10px fw-500'>App ID</p>
                                </div>
                                <div className='col-md-2'>
                                    <p className='text-content-subtle fs-10px fw-500 text-center'>Rate Plan</p>
                                </div>
                                <div className='col-md-2'>
                                    <p className='text-content-subtle text-center fs-10px fw-500'>
                                        Product Bundle
                                    </p>
                                </div>
                                {/* <div className='col-md-2'>
                                    <p className='text-content-subtle text-center fs-10px fw-500'>
                                        Created By / Created At
                                    </p>
                                </div> */}
                            </div>

                            {data && data?.map((each) => <div className='row p-12px'
                                style={{borderBottom: '1px solid var(--base-100)'}}>
                                <div className='col-md-3 my-auto'>
                                    <p className='text-content-color fs-14px fw-500'>{each.appName} </p>
                                </div>
                                
                                <div className='col-md-2 my-auto'>
                                    <div className='br-4px bg-base-100 px-8px py-5px d-flex align-items-center cursor justify-content-between'
                                        onClick={() => handleCopy(each.appId)} 
                                        // data-tip={copied == each.productBundle?.id ? "Copied" : "Copy to Clipboard"} 
                                        // data-for={each.productBundle?.id}
                                        >
                                        <p className='text-content-subtle fs-10px fw-500 text-truncate me-1'>{each.appId}</p>
                                        { copied == each.appId 
                                            ? <FilePlus color='var(--content-subtle)' size={14} />
                                            : <Clipboard color='var(--content-subtle)' size={14} />
                                        }

                                        <MyReactTooltip id={each.appId} />
                                    </div>
                                </div>

                                <div className='col-md-2 my-auto'>
                                    <div className='bg-secondary-200 btn px-10px py-10px dfaic justify-content-between' 
                                        style={{border: '1px solid var(--base-100)'}} 
                                        onClick={() => setRatePlanModal({isOpen: true, data: objectDeepClone(each)})}>
                                        <p className='w-80 text-start ps-2 fs-11px text-truncate text-primary-color fw-500'>{each.ratePlan?.name}</p>  <ArrowSquareOut color='var(--primary-color)' className='ms-1' />
                                    </div>
                                </div>

                                <div className='col-md-2 my-auto'>
                                    <p className='text-content-subtle fs-12px fw-500 text-center'>
                                        {each.productBundle?.name || ''}
                                    </p>
                                </div>

                                {/* <div className='col-md-2 my-auto'>
                                    <p className='text-content-subtle fs-12px fw-500 text-center'>
                                        {each.productBundle?.createdUser || ''}
                                    </p>
                                    <p className='text-content-subtle fs-11px fw-500 text-center'>
                                        {formatCtsTime(each.productBundle?.createdDate)} - {formatCtsDate(each.productBundle?.createdDate)}
                                    </p>
                                </div> */}
                                <div className='col-md-3 px-0 dfaic justify-content-center gap-24px my-auto'>
                                    <div className='px-16px py-8px br-4px bg-base-color cursor'
                                        style={{border: '0.5px solid var(--base-200)'}}
                                        onClick={() => setBillingDetailsModal({isOpen: true, data: objectDeepClone(each)})}>
                                        <p className='fs-12px fw-500 text-content-color'>Billing Info</p>
                                    </div>

                                    <div className='px-16px py-8px br-4px bg-base-color cursor'
                                        style={{border: '0.5px solid var(--base-200)'}}
                                        onClick={() => handleUnsubscribe(each)}>
                                        <p className='fs-12px fw-500 text-danger'>Unsubscribe</p>
                                    </div>
                                </div>
                            </div>)}
                        </div>}
                    </>}
                </div>
            </div>

            {ratePlanModal.isOpen && <RatePlanModal 
                data={ratePlanModal.data} show={ratePlanModal.isOpen}
                onHide={() => setRatePlanModal({isOpen: false, data: {}})} />}

            {billingDetailsModal.isOpen && <BillingDetails 
                data={billingDetailsModal.data} show={billingDetailsModal.isOpen}
                onHide={() => setBillingDetailsModal({isOpen: false, data: {}})} />}
            <CustomFooter />
        </div>
    )
}
