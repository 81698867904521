import { useNavigate, useOutletContext, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ForumAPI from "../../../api/forum";
import Loading from "../../../components/Loader/Loading";
import { SinglePost } from "../Components/SinglePost";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { objectDeepClone } from "@apiwiz/oas/services";

export const SinglePostPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { currentUser } = useSelector((state) => state.user);
  const [postData, setPostData] = useState(null);
  const [postDataLoading, setPostDataloading] = useState(false);
  const [repliesLoading, setRepliesLoading] = useState(false);
  const { setShowEditModal, onDeleteSuccess, allPosts, setAllPosts } = useOutletContext();

  const getPostById = (id) => {
    setPostDataloading(true);
    ForumAPI.getPostById({ id }).then((res) => {
      setPostDataloading(false);
      console.log("post res: ", res.data);
      setPostData(res.data);
    });
  };

  const getRepliesOfPost = () => {
    setRepliesLoading(true);
    ForumAPI.getRepliesOfPost({ postId: postData._id })
      .then((res) => {
        setRepliesLoading(false);
        console.log("reply data: ", res.data);
        const _postData = objectDeepClone(postData);
        _postData.replies = res.data?.replies;

        setPostData(_postData);
      })
      .catch((err) => {
        setRepliesLoading(false);
        console.log(err);
      });
  };

  const onReplyEditSuccess = ({ replyId, data }) => {
    getPostById(params.postId);
  };

  const onDeleteReplySuccess = () => {
    getPostById(params.postId);
  };

  useEffect(() => {
    getPostById(params.postId);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center w-100 gap-2  cursor-pointer">
        {!postDataLoading && (
          <>
            <GlobalIcons
              type={"left-arrow-black"}
              onClick={() => {
                navigate(-1);
              }}
            />
            <p className="fs-20px fw-600">Posts</p>
          </>
        )}
      </div>
      {postDataLoading && <Loading />}

      {!postDataLoading && postData && (
        <SinglePost
          postData={postData}
          setAllPosts={setAllPosts}
          getRepliesOfPost={getRepliesOfPost}
          setShowEditModal={setShowEditModal}
          onDeleteSuccess={onDeleteSuccess}
          getPostById={getPostById}
          onReplyEditSuccess={onReplyEditSuccess}
          onDeleteReplySuccess={onDeleteReplySuccess}
        />
      )}
    </>
  );
};
