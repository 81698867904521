import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import API from '../../api';
import { useDispatch } from 'react-redux';
import { setToken } from '../../app/Features/Users/UserSlice';
import { getToast } from '../../components/Toast';
import Loading from '../../components/Loader/Loading';

export default function SSOLogin() {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch()

    useEffect(() => {
        const code = searchParams.get("code")

        API.loginWithSSO({
            jsessionId: code
        })
        .then(response => {
            dispatch(setToken({data: response.data.data}));
            window.location.href = '/'
        })
        .catch(error => {
            getToast({
                statusType: 'ERROR', message: error.response.data.message
            })
            window.location.href = '/'
        })
    }, [])
    
    return (
        <div className='h-100vh w-100vw'>
            <Loading />
        </div>
    )
}
