import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import API from '../../../api'
import GlobalIcons from '../../../components/Icons/GlobalIcons'
import SDKIcons from '../../../components/Icons/SDKIcons'
import Loading from '../../../components/Loader/Loading'
import MyReactTooltip from '../../../components/ReactTooltip'
import { BodyLayout } from '../../../layout/Base'
import { confirm, copyToClipboard, getPermissionByCrud, labelize, objectDeepClone, sleep, throwServerError } from '../../../utils/helper'
import CategoryModal from './components/CategoryModal'
import GridCard from './components/GridCard'
import SDKModal from './components/SDKModal'
import Pagination from '../../../components/Pagination'
import { useSelector } from 'react-redux'
import Searchbar from "../../../components/Searchbar";

export function SDKs() {
    const {setNavigation, setTitle} = useOutletContext()
    const [displayView, setDisplayView] = useState('list')
    const [categoryModal, setCategoryModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [paging, setPaging] = useState({
        pageSize: 10,
        offset: 1,
        total: 10,
    })

    /* ACL Start */
    const {currentUser} = useSelector(state => state.user)
    const {permission} = useSelector(state => state.user)

    let _permission = permission.filter(o => o.name === labelize('sdks'))
    _permission = _permission.length ? (_permission[0].crud || []) : []
    const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
    const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
    const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')
    /* ACL END */

    const [copied, setCopied] = useState('')
    const [sdkModal, setSdkModal] = useState({
        isOpen: false, isEdit: false, data: {}
    })
    const { firstRoute } = useSelector((state) => state.user);
    const [searchVal, setSearchVal] = useState('')
    
    useEffect(() => {
        setTitle("SDKs")

        setNavigation([
            {link: firstRoute, name: 'Dashboard'},
            {name: 'Manage SDKs'}
        ])

        getListOfSdks()
    }, [])

    const getListOfSdks = (page = 1, seachVal = '') => {
        setIsLoading(true)
        API.getAllSdk(page, seachVal)
        .then(response => {
            setIsLoading(false)
            if (response.data.data) {
                let allData = []
                response.data.data.map(o => {
                    o.Items.map(n => {
                        allData.push(n)
                    })
                });
                setData(allData)

                let _paging = response.data.paging
                setPaging({
                    ...paging, total: _paging.total, pageSize: _paging.limit
                })
            }
        })
        .catch(error => {
            setIsLoading(false)
            throwServerError(error)
        })
    }

    const handleCopy = async (_text, _id) => {
        setCopied(_id)
        copyToClipboard(_text)
        await sleep(1000)
        setCopied('')
    }

    const handleSDKDelete = (_id) => {
        if (!confirm("Are you sure to delete this SDK?")) return
        API.deleteSdk(_id)
        .then(res => {
            getListOfSdks(1, searchVal)
        }).catch(err => {
            throwServerError(err)
        })
    }

    const handlePaginationChange = (offset) => {
        setPaging({...paging, offset})
        getListOfSdks(offset, searchVal)
    }

    const handleDownload = (each) => {
        const {download: url} = each

        const a = document.createElement("a");
        a.href = url;
        a.target = '_blank'
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        API.downloadSDK(each)
        .then(res => {
            console.log('res', res)
        }).catch(err => {
            console.log('err', err)
        })
    }
    
    return (
        <BodyLayout>
            <div className='fadeIn surface-900 w-100 h-100'>
                <div className='surface-875 v1-border-bottom-1 d-flex align-items-center justify-content-between p-8px pl-16px'>
                    <div> 
                        <h3 className='fs-18px fw-600 text-100'>Manage SDK</h3>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='v1-border-1 surface-850 px-20px py-11px btn me-3'
                            onClick={() => setCategoryModal(true)}>
                            <p className='text-300 fw-500 fs-12px'>Manage Category</p>
                        </div>

                        <div className='br-8px surface-base p-2px d-flex align-items-center justify-content-between cursor me-2'>
                            <div className={`px-12px py-4px ${displayView === 'list' ? 'v1-border-1 br-6px surface-850 activeSVG' : 'inactiveSVG'}`}
                                onClick={() => setDisplayView('list')}>
                                <SDKIcons type='list-view' />
                            </div>

                            <div className={`px-12px py-4px ${displayView === 'grid' ? 'v1-border-1 br-6px surface-850 activeSVG' : 'inactiveSVG'}`}
                                onClick={() => setDisplayView('grid')}>
                                <SDKIcons type='grid-view' />
                            </div>
                        </div>

                        <div style={{width: 300}} className='me-2'>
                            <Searchbar value={searchVal} onChange={(e) => {
                                setSearchVal(e)
                                if (e) getListOfSdks(1, e)
                                else getListOfSdks(1)
                            }} />
                        </div>

                        {getCreatePermission && <div className='v1-btn-primary btn py-9px'
                            onClick={() => setSdkModal({isOpen: true, isEdit: false, data: {}})}>
                            <GlobalIcons type={'plus'} />
                            <p className='fw-600 fs-12px text-300 ms-2'>Add SDK</p>
                        </div>}
                    </div>
                </div>

                <div className='p-12px'>
                    { isLoading
                    ? <div className='h-70vh'>
                        <Loading />
                    </div>
                    : displayView === 'grid' 
                        ? <div className='h-79vh overflow-scroll noscrollbar'>
                            <div className='d-flex flex-wrap align-items-center justify-content-center'>
                                {data.map((each, i) => (
                                    <GridCard key={i} data={each} getUpdatePermission={getUpdatePermission}
                                        handleDownload={() => handleDownload(each)}
                                        onClick={() => setSdkModal({isOpen: true, isEdit: true, data: objectDeepClone(each)})} />
                                ))}
                            </div>
                        </div>
                        : <>
                            <div className='surface-875 p-6px row mx-0'>
                                <div className='col-md-3'>
                                    <p className='text-400 fs-12px fw-500'>Title</p>
                                </div>
                                <div className='col-md-2'>
                                    <p className='text-400 fs-12px fw-500'>Category Name</p>
                                </div>
                                <div className='col-md-4'>
                                    <p className='text-400 fs-12px fw-500 text-center'>Overview URL</p>
                                </div>
                                <div className='col-md-2'></div>
                                <div className='col-md-1'></div>
                            </div>

                            <div className='h-69vh pb-0 overflow-scroll noscrollbar'>
                                {data.map((each, i) => (
                                <div key={each._id} className='row mx-0 p-14px v1-border-bottom-1'>
                                    <div className='col-md-3 my-auto ps-1'>
                                        <p className='text-200 fs-14px fw-500 text-truncate'>{each.title || ''}</p>
                                    </div>
                                    <div className='col-md-2 my-auto px-2'>
                                        <p className='text-200 fs-14px fw-500 text-truncate'>{each.categoryName || each.category || ''}</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='surface-850 p-6px v1-border-1 d-flex justify-content-between align-items-center w-100'>
                                            <a href={each.overviewUrl || '#'} className='d-flex align-items-center w-94 cursor'
                                                target={'_blank'} rel="noreferrer">
                                                <GlobalIcons type={'link'} />

                                                <p className='text-truncate fs-12px fw-500 text-300 w-95 ms-1 text-underscore'>
                                                    {each.overviewUrl || ''}
                                                </p>
                                            </a>

                                            <div className='cursor pe-1' 
                                                data-tip={copied === each._id ? "Copied" : "Copy to Clipboard"} 
                                                data-for={each._id} onClick={() => handleCopy(each.overviewUrl, each._id)}>
                                               {copied === each._id 
                                                ? <GlobalIcons type={'tick'} />
                                                : <GlobalIcons type={'copy'} /> }

                                                <MyReactTooltip id={each._id} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2 my-auto text-center'>
                                        <div onClick={() => handleDownload(each)}
                                            className='fs-12px fw-500 text-center mx-auto text-300 surface-850 v1-border-05 px-20px py-10px br-4px w-fit cursor'>
                                            Download Asset
                                        </div>
                                    </div>
                                    <div className='col-md-1 my-auto'>
                                        <div className='d-flex align-items-center'>
                                            {getUpdatePermission && <div className='btn-sm-v1 v1-border-2 me-3'
                                                onClick={() => setSdkModal({isOpen: true, isEdit: true, data: objectDeepClone(each)})}>
                                                <GlobalIcons type={'pencil-v2'} />
                                            </div>}

                                            {getDeletePermission && <div className='btn-sm-v1 v1-border-2'
                                                onClick={() => handleSDKDelete(each._id)}>
                                                <GlobalIcons type={'trash'} />
                                            </div>}
                                        </div>
                                    </div>
                                </div>))}
                                
                            </div>
                            <div className='mt-2'>
                                <Pagination className='py-2' pagination={paging} 
                                    cb={(val) => handlePaginationChange(val)} />
                            </div>
                        </>
                    }
                </div>
            </div>

            {categoryModal && <CategoryModal show={categoryModal}
                onHide={() => setCategoryModal(false)} /> }
            
            {sdkModal.isOpen && <SDKModal show={sdkModal.isOpen}
                isEdit={sdkModal.isEdit} data={sdkModal.data} callback={getListOfSdks}
                onHide={() => setSdkModal({
                    isOpen: false, isEdit: false, data: {}
                })} /> }
        </BodyLayout>
    )
}
