import { useSelector } from "react-redux";
import dummyUser from "../../../../assets/Forum/images/dummy-profile.png";
import { PostIcon } from "./PostIcon";
import moment from "moment";
import EditorJSOutput from "../../../../components/EditorJSOutput";
import { useEffect, useState } from "react";
import { objectDeepClone } from "../../../../utils/helper";
import { useNavigate } from "react-router";
import ForumAPI from "../../../../api/forum";
import { getToast } from "../../../../components/Toast";
import { PlaceHolderProfile } from "../PlaceHolderProfile";
import { EditDeleteDropdown } from "../EditDeleteDropdown";

export const Reply = ({
  replyData,
  postData,
  setShowEditReplyModal,
  onDeleteReplySuccess,
}) => {
  const {
    currentLayoutObject: { siteTemplateType },
  } = useSelector((state) => state.layout);

  const { currentUser } = useSelector((state) => state.user);

  const [postContent, setpostContent] = useState(null);
  const [showReadMore, setShowReadMore] = useState(false);
  const [votes, setVotes] = useState([]);
  const navigate = useNavigate();

  const isUserVoted = () => {
    return votes.includes(currentUser._id);
  };

  const onUpvoteClick = () => {
    ForumAPI.toggleReplyReaction({
      postId: postData._id,
      replyId: replyData._id,
    })
      .then((res) => {
        console.log("upvote reply res: ", res);
        const updatedVotes = res.data?.votes;
        setVotes(updatedVotes);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to update upvotes",
        });
      });
  };

  useEffect(() => {
    if (replyData && replyData.content) {
      setpostContent(replyData.content?.data);
      setVotes(replyData?.votes);
    }
  }, [replyData]);

  const onDeleteReply = ({ replyId, postId }) => {
    ForumAPI.deleteReply({ replyId, postId })
      .then((res) => {
        onDeleteReplySuccess();
      })
      .catch((err) => {
        console.log(err)
        getToast({
          statusType: "ERROR",
          message: "Unable to delete reply",
        });
      });
  };


  return (
    <div className="post-view-card">
      <div className="post-meta-tags__container">
        <div className="post-meta-info__container">
          {replyData?.createdBy?.userImage ? (
            <img
              src={replyData?.createdBy?.userImage}
              className="user-profile"
            />
          ) : (
            <PlaceHolderProfile />
          )}
           <div className="user-name--creation-time__container">
              <p className="user-name">{replyData?.createdBy?.fullName} </p>
              <p className="post-creation-time ">
                •{moment.utc(replyData?.cts).format("MMMM DD,YYYY")} at{" "}
                {moment.utc(replyData?.cts).format("HH:MM A")}
              </p>
           </div>
          {/* TODO: Show the option if the post or the reply owner is viewing the post  */}
          <EditDeleteDropdown
            editCallback={() => {
              setShowEditReplyModal({
                show: true,
                reply: replyData,
              });
            }}
            deleteCallback={() => {
              onDeleteReply({ replyId: replyData._id, postId: postData._id });
            }}
          />
        </div>
        <div className="post-tags__container">
          {replyData?.tags?.map((tag) => (
            <div className="tag">{tag}</div>
          ))}
        </div>
      </div>
      <div className="post-content__container">
        <div
          className="post-title"
          onClick={() => {
            // TODO: Check if the user is logged in to view post details
            navigate(`/forum/post/${replyData._id}`);
          }}
        >
          {replyData?.title}
        </div>
        <div className="post-content">
          <EditorJSOutput applyTheme data={postContent} />
          {showReadMore && (
            <p
              className="read-more-btn"
              onClick={() => {
                navigate(`/forum/post/${replyData._id}`);
              }}
            >
              ...Read More
            </p>
          )}
        </div>
      </div>

      <div className="post-upvotes-reply-count__container">
        <div className="upvote-count" onClick={onUpvoteClick}>
          <PostIcon
            type={"upvote"}
            state={isUserVoted() ? "filled" : "default"}
          />
          <p className="count-value">{votes?.length} Upvotes</p>
        </div>
      </div>
    </div>
  );
};
