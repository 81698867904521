import { useState } from "react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";

export const ProductApprovalRow = ({
  product,
  selectedApp,
  consumerKey,
  updateProductStatus,
  isAzure,
  isKong,
}) => {
  const [actionButtonClicked, setActionButtonClicked] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  return (
    <div className="accordian-content-rows">
      <div className="product-info">{product?.apiproduct}</div>
      <div className="status-info">
        <div
          className={`badge badge-md ${
            ["approved", "active", "published"].includes(product?.status?.toLowerCase()) 
              ? "badge-approved"
              : product?.status?.toLowerCase() === "pending"
              ? "badge-pending"
              : "badge-danger"
          }`}
          style={{
            textTransform: "capitalize",
          }}
        >
          {product?.status}
        </div>
        {/* {!isAzure && !isKong && (
          <>
            {!actionButtonClicked ? (
              <button
                className="action-btn"
                onClick={() => {
                  setActionButtonClicked(true);
                }}
              >
                {product?.status?.toLowerCase() === "approved"
                  ? "Revoke"
                  : "Grant"}
              </button>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "8px" }}
              >
                <p className="m-0">Sure</p>
                <button
                  className="action-btn m-0"
                  style={{
                    width: "max-content",
                    padding: "8px",
                  }}
                  onClick={() => {
                    const res = updateProductStatus({
                      action:
                        product?.status?.toLowerCase() === "approved"
                          ? "revoke"
                          : "approve",
                      appName: selectedApp?.name,
                      consumerKey: consumerKey,
                      productName: product?.apiproduct,
                    });
                    // wait for a sec and then close the confirm actions
                    setTimeout(() => {
                      setActionButtonClicked(false);
                    }, 1000);

                    console.log("update product res: ", res);
                  }}
                >
                  <GlobalIcons type={"check"} />
                </button>
                <button
                  className="action-btn m-0"
                  onClick={() => {
                    setActionButtonClicked(false);
                  }}
                  style={{
                    width: "max-content",
                    padding: "8px",
                  }}
                >
                  <GlobalIcons type={"close"} />
                </button>
              </div>
            )}
          </>
        )} */}
      </div>
    </div>
  );
};
