import React from 'react'
import { useSelector } from 'react-redux';
import GetIcon from '../../../components/GetIcon';
import { useTheme } from '../../../context/ThemeContext';
import { allowMenu } from '../../../utils/helper';

export default function CustomFooter() {
    const {currentLayoutObject} = useSelector((state) => state.layout)
    const {isAuthenticated, currentUser} = useSelector(state => state.user)
    const {theme, setTheme} = useTheme()

    const {social: socialData, footerMenu: data} = currentLayoutObject

    console.log('data', data)
    return (
        <div className='bg-base-100'>
            <div className='container footerSectionMain'>
                <div className='d-flex align-items-start justify-content-between py-92px px-26px row mx-0'>
                    <div className='col-lg-3 px-0'>
                        <img src={theme === 'theme--light' ? (currentLayoutObject.logo || '') : (currentLayoutObject.darklogo || '')}
                            style={{maxHeight: 40, maxWidth: 280 ,objectFit: 'contain'}} />
                        
                        <div className='show-border mt-10px mb-12px'></div>
                        <div className='v1-border-top-1 fs-14px fw-400 text-content-color'
                            style={{whiteSpace: 'pre-wrap'}}>
                            {currentLayoutObject.footerCopyright}
                        </div>
                        <div className='d-flex flex-wrap align-items-center mt-14px gap-8px'>
                            {socialData?.map((each, i) => 
                                <a href={each.url} target='_blank'>
                                    <GetIcon type={each.type} className='me-2' size={25}
                                        color={'var(--content-color)'} weight='fill' />
                                </a> )}
                        </div>
                    </div>
                    {data?.map((each, i) => {
                        if(!allowMenu({menu: each, isAuthenticated, currentUser})) return null;
                        let _submenus = [];
                        if(each.submenu && each.submenu.length) _submenus = each.submenu.filter(el => allowMenu({menu: el, isAuthenticated, currentUser}))
                        if(!_submenus.length) return null;
                        return (
                            <div className='col-lg-3 mobile-my-custom mobilePs0' key={i}>
                                <a className='fs-24px fw-600 text-content-color font-jost' rel="noreferrer"
                                    href={each.url || '#'} target={each.type === 'internal' ? '_parent' : '_blank'}> {each.name || ''}</a>
                                
                                <div className='mt-2'>
                                    {_submenus.map((eachSubmenu, _i) => <div key={`sub${_i}`} className='my-4px'>
                                        <a className='fs-14px text-content-color fw-400 font-jost' rel="noreferrer"
                                            href={eachSubmenu.url || '#'} 
                                            target={eachSubmenu.type === 'internal' ? '_parent' : '_blank'} > 
                                            {eachSubmenu.name || ''}
                                        </a>
                                    </div>)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
