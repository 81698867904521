import { useNavigate, useParams } from "react-router";
import {
    setInitGlobalSecurities,
    setOperationFromSwagger,
    setSelectedOperation,
    setSwagger,
    setSwaggerFields,
    setSwaggerFilterProp,
} from "../../app/Features/Swagger/SwaggerSlice";
import API from "../../api";
import { useEffect, useState } from "react";
import CustomNavbar from "../Dashboards/components/CustomNavbar";
import CustomFooter from "../Dashboards/components/CustomFooter";
import CatalogLeftPanelList from "../../components/Catalog/LeftPanel";
import { useDispatch } from "react-redux";
import { debounceFn, getLocationBasePath, getMenuName, throwServerError } from "../../utils/helper";
import CatalogContent from "../../components/Catalog/RightPanel/content";
import { GUIDE_VIEWS, ROUTE_NAMES, SRC_PARAM_VALUES, URL_PARAMS } from "../../service/constants";
import ContainerLoader from "../../components/Loader/ContainerLoader";
import CatalogDocView from "../../components/Catalog/RightPanel/docView";
import CatalogCodeView from "../../components/Catalog/RightPanel/codeView";
import { useSelector } from "react-redux";
import { CaretLeft, CaretRight, MagnifyingGlass } from "phosphor-react";
import { EmptyState } from "../../components/EmptyState";
import { useSearchParams } from "react-router-dom";

export default function View(props) {
    const { swaggerId, revision, oas } = useParams();
    const dispatch = useDispatch();
    const [selectedView, setSelectedView] = useState(GUIDE_VIEWS.DOC_VIEW);
    const { selectOpLoading, selectedOperation, swagger, metadata } = useSelector((state) => state.swagger);
    const { currentLayoutObject } = useSelector((state) => state.layout);
    const [parentMenuName, setParentMenuName] = useState("API References");
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isListOpen, setIsListOpen] = useState(true);
    const [isTryoutOpen, setIsTryoutOpen] = useState(false);

    const handleList = () => {
        if (window.screen.width < 1522) setIsTryoutOpen((prev) => !prev);

        setIsListOpen((prev) => !prev);
    };

    const handleTryout = () => {
        if (window.screen.width < 1522) setIsListOpen((prev) => !prev);

        setIsTryoutOpen((prev) => !prev);
    };

    const getSwagger = () => {
        setLoading(true);
        API.getSwagger({
            swaggerId: swaggerId,
            revision: revision,
            params: { oas },
        })
            .then((res) => {
                let _swagger = (res.data || {}).data || null;
                if (_swagger) {
                    dispatch(setSwagger(_swagger));
                    let payload = { code: _swagger, first: true };
                    if(searchParams.get(URL_PARAMS.API_REFERENCE)) payload.filterParam = searchParams.get(URL_PARAMS.API_REFERENCE);
                    if(searchParams.get(URL_PARAMS.SRC)) payload.srcParam = searchParams.get(URL_PARAMS.SRC);
                    dispatch(setOperationFromSwagger(payload));
                    dispatch(setInitGlobalSecurities(_swagger));
                    let metadata = {
                        swaggerId: swaggerId,
                        revision: revision,
                        oas: `OAS ${oas}`,
                        swaggerName: _swagger.info?.title || "",
                    };
                    dispatch(
                        setSwaggerFields({
                            field: "metadata",
                            value: metadata,
                        })
                    );
                }
                setLoading(false);
            })
            .catch((err) => {
                throwServerError(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        // if (window.screen.width > 1512) setIsTryoutOpen(true);

        getSwagger();

        let url = getLocationBasePath();
        if (url) {
            url = `/${url}`;
            let parentMenuName = getMenuName({
                menus: currentLayoutObject.headerMenu,
                url,
            });
            if (!parentMenuName)
                parentMenuName = getMenuName({
                    menus: currentLayoutObject.footerMenu,
                    url,
                });
            if (parentMenuName) setParentMenuName(parentMenuName);
        }
        return () => {
            dispatch(setSwaggerFields({ field: "operationPlainList", value: [] }));
            dispatch(setSelectedOperation(null));
        };
    }, []);

    const onSearchChange = (e) => {
        dispatch(setSwaggerFilterProp({ prop: "search", value: e.target.value }));
    };

    const onSearchChangeDebounceFn = debounceFn(onSearchChange);

    return (
        <div className="vw-100 d-flex flex-column bg-currentBackground">
            <CustomNavbar />
            <div className="api-reference-container">
                <div className="d-flex mt-72px min-h-100vh gap-32px">
                    {loading ? (
                        <ContainerLoader variant="theme-primary" className="w-100" />
                    ) : (
                        <>
                            <div className="w-100 d-flex align-items-start h-100">
                                {isListOpen ? (
                                    <div className="api-reference-list border-right-theme-base-200 fixedLeftBar noscrollbar">
                                        <div className="d-flex align-items-center gap-8px pr-8px mb-12px mt-12px">
                                            <div className="d-flex w-100 align-items-center bg-theme-base-100 br-4px py-5px px-8px gap-6px text-theme-content-subtle h-32px">
                                                <MagnifyingGlass size={14} weight="bold" />
                                                <input
                                                    onChange={onSearchChangeDebounceFn}
                                                    placeholder="Search"
                                                    className="input-transparent fs-14px fw-400 lh-21px"
                                                />
                                            </div>

                                            <div
                                                onClick={handleList}
                                                className="bg-secondary-200 py-3px px-6px cursor-pointer"
                                                style={{ borderRadius: "0px 8px 8px 0px" }}
                                            >
                                                <CaretLeft />
                                            </div>
                                        </div>
                                        <CatalogLeftPanelList />
                                    </div>
                                ) : (
                                    <div
                                        onClick={handleList}
                                        className="bg-secondary-200 mt-12px  py-3px br-4px px-6px cursor-pointer"
                                        style={{ width: "max-content", height: "max-content" }}
                                    >
                                        <CaretRight />
                                    </div>
                                )}

                                {selectedOperation ? (
                                    <div className="api-reference-desc pl-32px pt-16px h-100 fullWidthOnMobile">
                                        <div className="d-flex justify-content-between h-37px gap-6px mobileFlexWrap">
                                            {metadata?.swaggerName ? (
                                                <div className="d-flex fs-13px gap-5px">
                                                    {![SRC_PARAM_VALUES.PRODUCT, SRC_PARAM_VALUES.PACKAGE].includes(searchParams.get(URL_PARAMS.SRC)) && (
                                                        <div
                                                            className="cursor text-truncate"
                                                            onClick={() => {
                                                                navigate(`/${ROUTE_NAMES.API_REFERENCE}`);
                                                            }}
                                                        >
                                                            {parentMenuName}
                                                        </div>
                                                    )}
                                                    {![SRC_PARAM_VALUES.PRODUCT, SRC_PARAM_VALUES.PACKAGE].includes(searchParams.get(URL_PARAMS.SRC))  && <div>{` / `}</div>}
                                                    <div className="text-theme-accent text-truncate">{metadata?.swaggerName}</div>
                                                </div>
                                            ) : null}
                                            {swagger?.host ? (
                                                <div className="d-flex gap-2px fs-13px">
                                                    <div className="fw-600">Host:</div>
                                                    <div>{swagger?.host}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {selectedOperation ? (
                                            <div className="mt-16px h--53px">
                                                <CatalogContent />
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="api-reference-desc h-100 d-flex flex-column justify-content-center align-items-center">
                                        <EmptyState />
                                        <p className="fs-14px">No Path Selected</p>
                                    </div>
                                )}
                            </div>

                            {selectedOperation && isTryoutOpen ? (
                                <div className="api-reference-tryout mt-16px hideOnMobile">
                                    <div className="w-100 d-flex align-items-center justify-content-start">
                                        <div
                                            onClick={handleTryout}
                                            className="cursor-pointer bg-secondary-200 py-3px px-6px"
                                            style={{ borderRadius: "0px 8px 8px 0px" }}
                                        >
                                            <CaretRight />
                                        </div>
                                        <div className="w-100 d-flex justify-content-center">
                                            <div className="fs-16px fw-500 lh-21px mx-auto">Tryout</div>
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 align-items-center justify-content-center mt-px mb-10px br-8 bg-theme-base">
                                        {[GUIDE_VIEWS.DOC_VIEW, GUIDE_VIEWS.CODE_VIEW].map((el) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        setSelectedView(el);
                                                    }}
                                                    className={`py-8px br-6px fs-12px fw-500 cursor ${
                                                        selectedView === el ? "bg-theme-base-200 text-theme-content" : "text-theme-content-subtle"
                                                    } px-16px`}
                                                >
                                                    {el}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* Doc View */}
                                    {selectOpLoading ? (
                                        <ContainerLoader variant="theme-primary" className="h-200px" />
                                    ) : (
                                        <>
                                            {selectedView === GUIDE_VIEWS.DOC_VIEW ? <CatalogDocView /> : null}
                                            {selectedView === GUIDE_VIEWS.CODE_VIEW ? <CatalogCodeView /> : null}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div
                                    className="api-reference-tryout-btn py-1 mt-12px cursor-pointer px-3px d-flex align-items-center justify-content-end ms-auto gap-8px"
                                    style={{
                                        borderRadius: "8px 0px 0px 8px",
                                        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                                        height: "max-content",
                                    }}
                                >
                                    <div
                                        onClick={handleTryout}
                                        className="bg-secondary-200 py-3px br-4px px-6px"
                                        style={{ width: "max-content", height: "max-content" }}
                                    >
                                        <CaretLeft />
                                    </div>
                                    <div className="fw-500 fs-16px text-content-subtle text-center">Tryout</div>
                                </div>
                            )}
                        </>
                    )}
                    {/* Left Panel */}
                </div>
            </div>
            <CustomFooter />
        </div>
    );
}
