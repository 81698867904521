import Select from "react-select";
import Creatable from "react-select/creatable";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import { useEffect, useRef, useState } from "react";
import ForumAPI from "../../../../api/forum";
import EditorJS from "../../../../components/EditorJS";
import { getToast } from "../../../../components/Toast";
import { PencilSimple } from "phosphor-react";
import { useTheme } from "../../../../context/ThemeContext";
import { Post } from "../Post";

export const EditPostModal = ({ show, onHide, onEditSuccess }) => {
  const [postTitle, setPostTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [postData, setPostData] = useState(null);
  const editorInstance = useRef(null);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(true);
  const { theme } = useTheme();

  const getCategoryData = () => {
    // setCategoryLoading(true);
    ForumAPI.getAllCategory()
      .then((res) => {
        // setCategoryLoading(false);
        // setCategoryData(res);
        const data = res.data;
        const originalPostData = show.postData;
        const _categoryOptions = data?.map((category) => ({
          label: category.name,
          value: category.name,
          id: category._id,
        }));
        setCategoryOptions(_categoryOptions);
        const selectedCategory = _categoryOptions.find(
          (category) => category.id === originalPostData?.category
        );
        setSelectedCategory(selectedCategory);
        console.log("category res: ", res);
      })
      .catch((err) => {
        // setCategoryLoading(false);
        console.log(err);
      });
  };
  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  const handleCreateTag = (value) => {
    ForumAPI.createTag({ name: value })
      .then((res) => {
        console.log({ value });
        const newOption = createOption(value);
        if (tags.length < 5) {
          setTags((prev) => [...prev, newOption]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTags = () => {
    ForumAPI.getAllTags()
      .then((res) => {
        console.log("tags res: ", res);
        const options = res.data.map(({ name, _id }) => ({
          label: name,
          value: name,
          id: _id,
        }));
        setTagOptions(options);
        const originalPostData = show.postData;
        console.log("og tags: ", originalPostData?.tags);
        const _selectedTags = options.filter((tag) =>
          originalPostData?.tags?.includes(tag.value)
        );
        console.log("_selectedTags: ", _selectedTags);
        setTags(_selectedTags);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onModalClose = () => {
    onHide();
    // onClear();
    setPostTitle("");
    setPostData(null)
    setTags([]);
    setSelectedCategory(null);
    setErrors({});
    setEditMode(true);
  };

  useEffect(() => {
    if (show.show) {
      getAllTags();
      getCategoryData();
      const originalPostData = show.postData;
      if (originalPostData) {
        setPostData(originalPostData?.post?.content?.data);
        setPostTitle(originalPostData?.title);
      }
    }
  }, [show]);

  const setEditorInstance = (core) => {
    editorInstance.current = core;
    editorInstance.current.height = "100%";
  };

  const EditPosthandler = (data) => {
    ForumAPI.updatePost({ data, postId: show?.postData._id })
      .then((res) => {
        // append it to start of current list
        ForumAPI.getPostById({ id: res.data?._id})
        .then(res => {
          onEditSuccess({ updatedData: res.data })
          onModalClose();
        })
        .catch(err => {
          console.log(err)
        })
        
        // getAllPosts({ offset: 1 });
      }) 
      .catch((err) => {
        console.log(err)
        getToast({
          statusType: "ERROR",
          message: "Unable to update post",
        });
      });
  };

  const onSave = async () => {
    if (!selectedCategory) {
      setErrors((prev) => ({
        ...prev,
        category: "Category is Required",
      }));
    }

    if (!postTitle) {
      setErrors((prev) => ({
        ...prev,
        title: "Title is required",
      }));
    } else if (postTitle.length < 3) {
      setErrors((prev) => ({
        title: "Title needs to be minimum 3 characters",
      }));
    }
    if (editorInstance.current) {
      try {
        let doc = await editorInstance.current.save();
        console.log("saved post: ", doc);
        setPostData(doc);

        const requiredFieldsEmpty = Object.keys(errors).reduce(
          (acc, curr) => acc || errors[curr],
          false
        );

        const tagValues = tags.map(({ value }) => value);

        if (!requiredFieldsEmpty) {
          // go on with save
          const data = {
            title: postTitle,
            tags: tagValues,
            post: {
              content: {
                data: doc,
              },
              // votes: show.postData.post.votes,
              // votesCount: show.postData.post.votesCount
            },
            category: selectedCategory.id,
          };

          console.log("post data to save: ", data);

          EditPosthandler(data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onClear = async () => {
    if (editorInstance.current) {
      try {
        editorInstance.current.clear();

        let doc = await editorInstance.current.save();
      } catch (err) {}
    }
  };

  console.log("editable post: ", show.postData)

  return (
    <div className={`create-post-modal__container ${show.show ? "open" : ""}`}>
      <>
        <div className={`create-post-modal-header ${!editMode && "d-none"}`}>
          <p>Ask for help from the community</p>
          <GlobalIcons
            type={"close"}
            className="cursor-pointer"
            onClick={onModalClose}
          />
        </div>
        <div
          className={`create-post-dropdowns__container ${
            !editMode && "d-none"
          }`}
        >
          <div className="d-flex flex-column">
            <Select
              classNames={{
                option: (state) =>
                  state.isFocused
                    ? "focused"
                    : state.isSelected
                    ? "selected"
                    : "",
              }}
              className="v1select h-auto forum-select category-select"
              options={categoryOptions}
              placeholder="Select Category"
              onChange={(data) => {
                setSelectedCategory(data);
                if (data) {
                  setErrors((prev) => ({
                    ...prev,
                    category: false,
                  }));
                }
              }}
              value={selectedCategory}
            />
            {errors.category && (
              <p className="text-danger fs-12px mt-1">{errors.category}</p>
            )}
          </div>

          <Creatable
            className="v1select h-auto forum-select tags-select"
            options={tagOptions}
            isMulti
            placeholder="Select Tags"
            onChange={(data) => {
              console.log({ data });
              if (data.length <= 5) {
                setTags(data);
              }
            }}
            value={tags}
            onCreateOption={handleCreateTag}
            onKeyDown={(evt) => {
              if (tags.length === 5) {
                evt.preventDefault();
              }
            }}
          />
        </div>
        <div className={`d-flex flex-column ${!editMode && "d-none"}`}>
          <input
            className="create-post-modal-title"
            placeholder="Click to add post title"
            value={postTitle}
            onChange={(evt) => {
              setPostTitle(evt.target.value);
              if (evt.target.value && evt.target.value.length >= 3) {
                setErrors((prev) => ({
                  ...prev,
                  title: false,
                }));
              } else if (evt.target.value && evt.target.value.length < 3) {
                setErrors((prev) => ({
                  ...prev,
                  title: "Title needs to be minimum 3 characters",
                }));
              }
            }}
          />
          {errors.title && (
            <p className="text-danger fs-12px mt-1">{errors.title}</p>
          )}
        </div>
        <div
          className={`create-post-modal-textarea overflow-scroll-y noscrollbar ${
            !editMode && "d-none"
          }`}
        >
          {postData && (
            <>
              <EditorJS
                data={postData}
                setEditorInstance={setEditorInstance}
                className="post-content-area"
              />
            </>
          )}
        </div>
      </>
      <div
        className={`flex flex-grow-1 overflow-scroll-y noscrollbar ${
          editMode && "d-none"
        }`}
        style={{
          height: "80%",
        }}
      >
        <Post
          postData={{
            post: {
              content: {
                data: postData,
              },
              votes: [],
            },
            title: postTitle,
            tags: tags?.map((tag) => tag.value),
          }}
          previewMode={true}
        />
      </div>

      <div className="create-post-modal-cta__container">
        <button
          className="primary-cta"
          onClick={() => {
            if (!editMode) {
              console.log("Hi ");
              onSave();
            } else {
              (async () => {
                let doc = await editorInstance.current.save();
                console.log("aved data: ", doc);
                setPostData(doc);
                setEditMode(false);
              })();
            }
          }}
        >
          {editMode ? "Preview" : "Update"}
        </button>
        {editMode && (
          <button className="secondary-cta" onClick={onClear}>
            Clear All
          </button>
        )}
        {!editMode && (
          <button
            className="secondary-cta"
            onClick={() => {
              setEditMode(true);
            }}
          >
            <PencilSimple color={"var(--content-color)"} />
            Edit
          </button>
        )}
      </div>
    </div>
  );
};
