import React from 'react'

export default function ContainerLoader(props) {
    let cls = ''
    if(props.className) cls = props.className

    let style = {}
    if(props.style) style = props.style
  return (
    <div style={{...style}} className={`d-flex justify-content-center align-items-center ${cls}`}>
        <div className={`spinner-border text-${props.variant || 'primary'} spinner-border-${props.size || 'md'}`} role="status"></div>
    </div>
  )
}
