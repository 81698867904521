
import { BodyLayout } from "../../../../layout/Base";
import { useOutletContext } from "react-router";
import { useEffect,useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import Loading from "../../../../components/Loader/Loading";
import Pagination from "../../../../components/Pagination";
import AttriibuteTable from "../components/AttributeTable";
import AttriibuteRoleTable from "../components/AttributeRoleTable";
import { useSelector } from "react-redux";
import UserIcons from "../../../../components/Icons/UserIcons";
import { setPartners,getAllUserRoles, setCompanies } from "../../../../app/Features/Users/UserSlice";
import API from "../../../../api";
import { useDispatch } from "react-redux";
import { getFirstLetterOfString, getPermissionByCrud, getPermissionsArray, throwServerError } from "../../../../utils/helper";
import { getToast } from "../../../../components/Toast";
import RoleModal from "../components/RoleModal";
import EmptyContainer from "../../../../components/EmptyContainer";

export default function ManageAttributes(){
    const {setNavigation, setTitle} = useOutletContext()
    const [activeTab, setActiveTab] = useState('Partner Groups');
    const [loading,setLoading] = useState(true);
    const [paging,setPaging] = useState({});
    const [ groupPermissionData, setGroupPermissionData ] = useState([])
    const [ rolePermissionData, setRolePermissionData ] = useState([])
    const [currentPage,setCurrentPage] = useState(1);
    const [roleModal,setShowRoleModal] = useState();

    const { partnersList: partners,permissionData,currentUser,roles, companiesList} = useSelector((state) => state.user);
    const {firstRoute} = useSelector(state => state.user)
   
    const dispatch = useDispatch();

    const {permission} = useSelector(state => state.user)

    let _UserGrouppermission = permission.filter(o => o.name === 'partner-groups')
    _UserGrouppermission = _UserGrouppermission.length ? (_UserGrouppermission[0].crud || []) : []

    const showSyncUserGroup = getPermissionByCrud(currentUser, _UserGrouppermission,"sync");
    const showListUserGroup = getPermissionByCrud(currentUser, _UserGrouppermission,"list");

    let _companyPermission = permission.filter(o => o.name === 'company')
    _companyPermission = _companyPermission.length ? (_companyPermission[0].crud || []) : []

    const showSyncCompany = getPermissionByCrud(currentUser, _companyPermission,"sync");
    const showCompanyList = getPermissionByCrud(currentUser, _companyPermission,"list");

    let _UserRolepermission = permission.filter(o => o.name === 'user-role')
    _UserRolepermission = _UserRolepermission.length ? (_UserRolepermission[0].crud || []) : []

    const showCreateButtonUserRole = getPermissionByCrud(currentUser, _UserRolepermission,"create");
    const showEditUserRole = getPermissionByCrud(currentUser, _UserRolepermission,"update");
    const showDeleteUserRole = getPermissionByCrud(currentUser, _UserRolepermission,"delete");
    const showListUserRole = getPermissionByCrud(currentUser, _UserRolepermission,"list");

    useEffect(() => {

        if (!showListUserGroup && !showListUserRole && !showCompanyList) return
        if (showListUserRole) getRoleData()
    }, [currentPage])

    const handleServerSideError = (error) => {
        if (error.response) {
            if (error.response.data.message) {
                getToast({statusType: 'ERROR', message: error.response.data.message})
            }
        } else {
            getToast({statusType:"ERROR",message: error.message })
        }
    }

    const getRoleData = () => {
        setLoading(true)
		API.getAllRoles(currentPage)
			.then((response) => {
				dispatch(getAllUserRoles(response.data));
                setPaging(response.data.paging)
                setLoading(false)
			})
			.catch((error) => 
            {
             setLoading(false)
             throwServerError(error)
            }
        );
	};


    const getPartnersList = (where='') => {
        setLoading(true)
        API.listPartners()
        .then(res => {
            dispatch(setPartners(res.data.data));
            setLoading(false)
            if(where==='sync') getToast({statusType:"SUCCESS",message: "Partners synced successfully" })
        })
        .catch(err => {
            setLoading(false)
            handleServerSideError(err)
        })
    }
    const fetchCompaniesList = (where='') => {
        setLoading(true)
        API.getCompaniesList()
        .then(res => {
            dispatch(setCompanies(res.data.data));
            setLoading(false)
            if(where==='sync') getToast({statusType:"SUCCESS",message: "Companies synced successfully" })
        })
        .catch(err => {
            setLoading(false)
            handleServerSideError(err)
        })
    }

    useEffect(() => {
        setTitle("Users")
        setNavigation([
            {name: 'Dashboard', link : firstRoute},
            {name: 'Attributes', link : '/attributes'},
        ])

        if(showListUserGroup && !partners.length){
            getPartnersList()
        } else {
            setLoading(false)
        }
        if(showCompanyList && !companiesList.length){
            fetchCompaniesList()
        }
    }, [])

    const syncPartners = () => {
        setLoading(true)
        API.syncPartners()
        .then(res => {
            getPartnersList('sync');
        })
        .catch(err => {
            setLoading(false);
            handleServerSideError(err)
        })
    }
    const syncCompanies = () => {
        setLoading(true)
        API.syncCompanies()
        .then(res => {
            fetchCompaniesList('sync');
        })
        .catch(err => {
            setLoading(false);
            handleServerSideError(err)
        })
    }

    function handlePaginationChange(val){
       setCurrentPage(val)
    }

    function handleRoleModal(){
        setShowRoleModal((prev)=>!prev)
    }

    return  <BodyLayout>
        <div className="fadeIn h-100 w-100">
            <div className="bottomPanel h-100">
                <div className='userCard--body h-100'>
                    <div className="surface-875 p-8px d-flex justify-content-between align-items-center v1-border-bottom-05">
                        <div>
                            <p className="fs-18px fw-600">Manage Attributes</p>
                        </div>
                        <div className="d-flex align-items-center">
                            {(showSyncUserGroup && activeTab=="Partner Groups") ?  <div className='btn v1-btn-primary p-2' onClick={syncPartners}>
                                <UserIcons type={'sync'} />
                                <p className='text-200 ps-2 fs-14px'>Sync Partners</p>
                            </div> : showSyncCompany && activeTab == "Companies" ?  (
                                <div className="btn v1-btn-primary p-2" onClick={syncCompanies}>
                                    <UserIcons type={"sync"} />
                                    <p className="text-200 ps-2 fs-14px">Sync Companies</p>
                                </div>
                            ):
                                (showCreateButtonUserRole && activeTab=="User Roles") ? <div className='btn v1-btn-primary p-2' onClick={handleRoleModal}>
                                <GlobalIcons type={'plus'} />
                                <p className='text-200 ps-2 fs-14px'>Add Roles</p>
                            </div> : <></> }   
                        </div>
                    </div>
                    
                    <div className="surface-875">
                        <Tabs activeKey={activeTab} id="resources-tab" className=""
                            onSelect={(key) => {if(key!==activeTab) setActiveTab(key)}}>
                            {showListUserGroup && <Tab tabClassName="tabs-btn-2" 
                                eventKey={'Partner Groups'} title={`Partner Groups ${activeTab==="Partner Groups"?'('+partners.length+')':''}`}>
                                {loading ? <div className="h-73vh"><Loading/></div> : <AttriibuteTable/>}
                            </Tab>}
                            {showCompanyList && <Tab tabClassName="tabs-btn-2" 
                                eventKey={'Companies'} title={`Companies ${activeTab==="Companies" ? '(' + companiesList.length + ')' : ''}`}>
                                {loading ? <div className="h-73vh"><Loading/></div> : 
                                <>
                                <div className='surface-875 mx-0 d-flex p-2 mt-2 ps-3'>
                                    <div className="w-26 fs-12px fw-500 text-400 d-flex">
                                        <p>Company Name</p>
                                    </div>
                                    <div className="w-37 fs-12px fw-500 text-400 d-flex justify-content-center">
                                        <p>Display Name</p>
                                    </div>
                                </div>
                        
                                <div className='overflow-scroll noscrollbar h-70vh'>
                                    {companiesList.length > 0 ? 
                                        companiesList.map((item,index) => (
                                            <div className='surface-900 mx-0 d-flex ps-3 pt-15px pb-15px userCard--tableRow v1-border-bottom-1 hover-surface-850' key={item.userId}>
                                                <div className='w-26 my-auto'>
                                                    <div className='d-flex'>
                                                        <div className='my-auto'>
                                                            <div className='userCircle surface-850'>
                                                                <p className='fw-400 fs-14px text-300 text-uppercase'>
                                                                {getFirstLetterOfString(item.companyName)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='pl-8px d-flex align-items-center'>
                                                            <div className='me-2'>
                                                                <div className="d-flex">
                                                                    <p className='text-200 fs-14px fw-500 text-truncate'>
                                                                        {item.companyName}
                                                                    </p>
                                                                </div>
                                                                <p className='text-400 fs-12px fw-400 text-truncate'
                                                                    style={{width: 300}} title={item.description}>
                                                                    {item.description}
                                                                </p>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-37 d-flex align-items-center justify-content-center'>
                                                    <div className="">
                                                        <p className="text-200 fs-14px">{item.displayName}</p>
                                                    </div>
                                                </div>
                                        </div>
                                        ))
                                    
                                    : (
                                        <div className="h-100 d-flex justify-content-center align-items-center">
                                        <EmptyContainer/>
                                        </div>
                                    ) 
                                
                                }
                                </div>
                            </>}
                            </Tab>}
                            {showListUserRole && <Tab tabClassName="tabs-btn-2" 
                                eventKey={'User Roles'} title={`User Roles ${activeTab==="User Roles"?'('+roles.length+')':''}`}>
                                {loading ? <div className="h-73vh"><Loading/></div> : <AttriibuteRoleTable getRoleData={getRoleData} showEdit={showEditUserRole} showDelete={showDeleteUserRole}/>}
                            </Tab>}
                        </Tabs>
                        {roles?.length>0 && activeTab=="User Roles"?<Pagination className='py-2' pagination={{
                                pageSize: 10,
                                offset: currentPage,
                                total: paging.total,
                        }} cb={(val) => handlePaginationChange(val)} />:<></>}
                    </div>
                    
                </div>
            </div>  
        </div> 
        {
            roleModal && <RoleModal 
                show={roleModal}
                onHide={handleRoleModal}
                isEdit={false}
                getRoles={getRoleData}
                showEdit={showEditUserRole}
                showDelete={showDeleteUserRole}

            />

        }
    </BodyLayout>
}