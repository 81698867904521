import { ArrowSquareOut, X } from "phosphor-react";
import Modal from "react-bootstrap/Modal";
import { useTheme } from "../../../../context/ThemeContext";
import SwaggerAccordion from "../../../../components/SwaggerAccordion";
import { ROUTE_NAMES } from "../../../../service/constants";

export default function ProductModal(props) {
    const { data } = props;
    const { theme } = useTheme();

    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName="br-0px homeModalForLghtMode"
            className="custom-modal-v1 v1--styles"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="p-3 br-0px surface-consumer-975">
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className="mb-0 text-consumer-200 fw-500 fs-16px">Product Details</p>

                    <div
                        onClick={props.onHide}
                        style={{ border: "0.5px solid" }}
                        className="px-6px cursor br-4px border-strokes-800 surface-consumer-850"
                    >
                        <X size={16} color={theme === "theme--light" ? "#464646" : "#C2C2C2"} />
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body style={{ height: "calc(100vh - 60px)" }} className="br-0 overflow-scroll noscrollbar">
                <div className="d-flex flex-column gap-10px">
                    {data?.name ? (
                        <div className="d-flex flex-column gap-2px">
                            <p className="text-consumer-300 fs-12px fw-400">Product Name</p>
                            <p className="text-consumer-200 fs-14px fw-500">{data?.name}</p>
                        </div>
                    ) : null}
                    {data?.description ? (
                        <div className="d-flex flex-column gap-2px">
                            <p className="text-consumer-300 fs-12px fw-400">Product Description</p>
                            <p className="text-consumer-200 fs-14px fw-500">{data?.description}</p>
                        </div>
                    ) : null}
                    {data.swagger && data.swagger.length ? (
                        <>
                            <p className="fw-400 fs-12px text-consumer-300">OAS Specs</p>
                            <SwaggerAccordion data={data.swagger} />
                        </>
                    ) : null}
                    {data.attachedGuides && data.attachedGuides.length ? (
                        <>
                            <p className="fw-400 fs-12px text-consumer-300">Docs</p>
                            <div className="d-flex flex-wrap gap-4px external-links__container">
                                {data.attachedGuides.map((el) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                window.open(`${window.location.origin}/${ROUTE_NAMES.GUIDES}/${el.slug}`, "_blank");
                                            }}
                                            className="external-link cursor"
                                        >
                                            {el.name}
                                            <ArrowSquareOut color="var(--content-subtle)" className="cursor-pointer" />
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    ) : null}
                </div>
            </Modal.Body>
        </Modal>
    );
}
