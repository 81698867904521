export const PlaceHolderProfile = () => {
  return (
    <div className="placeholder-profile-container">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="placeholder-profile"
      >
        <path
          opacity="0.5"
          d="M29.8498 28.5218C29.7509 28.6931 29.6086 28.8353 29.4373 28.9342C29.266 29.0331 29.0716 29.0851 28.8738 29.0851H1.83423C1.63656 29.0849 1.44243 29.0327 1.27131 28.9337C1.1002 28.8347 0.958141 28.6925 0.859394 28.5213C0.760646 28.35 0.70869 28.1558 0.70874 27.9582C0.708791 27.7605 0.760848 27.5663 0.859683 27.3951C3.00454 23.687 6.30985 21.0281 10.1672 19.7677C8.25918 18.6318 6.77677 16.9011 5.94761 14.8411C5.11845 12.7812 4.98841 10.5061 5.57744 8.36511C6.16647 6.22414 7.44202 4.3357 9.20818 2.98983C10.9744 1.64395 13.1335 0.915039 15.354 0.915039C17.5745 0.915039 19.7337 1.64395 21.4999 2.98983C23.266 4.3357 24.5416 6.22414 25.1306 8.36511C25.7196 10.5061 25.5896 12.7812 24.7604 14.8411C23.9313 16.9011 22.4489 18.6318 20.5408 19.7677C24.3982 21.0281 27.7035 23.687 29.8484 27.3951C29.9475 27.5663 29.9998 27.7605 30 27.9582C30.0002 28.156 29.9484 28.3504 29.8498 28.5218Z"
          fill="var(--content-subtle)"
        />
      </svg>
    </div>
  );
};
