import { createSlice } from "@reduxjs/toolkit";
import { LayoutViews } from "../../../service/constants";

const initialState = {
  paging: {total: 10, offset: 1, pageSize: 10},
  currentPage: 1,
  activeView: LayoutViews.GRID,
  searchStr: "",
  stateChanged: false
};

export const DeveloperAppSlice = createSlice({
  name: "developerapp",
  initialState,
  reducers: {
    setDeveloperAppProp(state, action){
      let {prop, value} = action.payload;
      state[prop] = value;
      if(!state.stateChanged) state.stateChanged = true;
    },
    resetDeveloperApp(state){
      for(let key in initialState) state[key] = initialState[key];
    }
  },
});

export const { setDeveloperAppProp, resetDeveloperApp } = DeveloperAppSlice.actions;
export default DeveloperAppSlice.reducer;
