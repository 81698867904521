import axios from "axios";

import APIS from "../service/config";
import { getCookie } from "../utils/cookie";
import paginationLimit from "../utils/pagination-limit";
import { reject } from "lodash";
const { API, FORUM } = APIS;

let headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

let publicHeaders = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

if (getCookie("itorix-token")) {
  headers["authorization"] = `Bearer ${getCookie("itorix-token")}`;
}

let api = axios.create({
  baseURL: API,
  headers: headers,
});

let publicApi = axios.create({
  baseURL: API,
  headers: publicHeaders,
});

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  initHeaders(token) {
    let header = api.defaults.headers;
    if (token) {
      header["authorization"] = `Bearer ${token}`;
    } else {
      if (header.hasOwnProperty("authorization")) {
        delete header["authorization"];
      }
    }
  },

  getAllCategory() {
    return new Promise((resolve, reject) => {
      publicApi
        .get(FORUM.CATEGORY.GET)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateCategory({ id, data }) {
    return new Promise((resolve, reject) => {
      api
        .put(`${FORUM.CATEGORY.UPDATE_CATEGORY}/${id}`, {
          ...data,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  createCategory({ data }) {
    return new Promise((resolve, reject) => {
      api
        .post(FORUM.CATEGORY.CREATE, {
          ...data,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  deleteCategory({ id }) {
    return new Promise((resolve, reject) => {
      api
        .delete(`${FORUM.CATEGORY.DELETE_CATEGORY}/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  createTag({ name }) {
    return new Promise((resolve, reject) => {
      api
        .post(FORUM.TAGS.POST, {
          name,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  getAllTags() {
    return new Promise((resolve, reject) => {
      api
        .get(FORUM.TAGS.GET)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  createPost({ data }) {
    return new Promise((resolve, reject) => {
      api
        .post(FORUM.POST.CREATE, {
          ...data,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  getPosts({ offset = 1, filter }) {
    return new Promise((resolve, reject) => {
      const params = {
        offset,
      };
      if (filter) {
        params.filter = filter;
      }
      publicApi
        .get(FORUM.POST.GET, {
          params,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getPostsByFilter({ offset = 1, filter }) {
    return new Promise((resolve, reject) => {
      publicApi
        .get(FORUM.POST.GET, {
          params: {
            offset,
            filter,
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getPostById({ id }) {
    return new Promise((resolve, reject) => {
      api
        .get(`${FORUM.POST.GET}/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  togglePostReaction({ postId }) {
    return new Promise((resolve, reject) => {
      api
        .patch(`${FORUM.POST.GET}/${postId}/upvote`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  replyToPost({ postId, data }) {
    return new Promise((resolve, reject) => {
      api
        .post(`${FORUM.POST.GET}/${postId}/reply`, {
          ...data,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  updateReply({ postId, replyId, data }) {
    return new Promise((resolve, reject) => {
      api
        .put(`${FORUM.POST.GET}/${postId}/reply/${replyId}`, {
          ...data,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  deleteReply({ postId, replyId }) {
    return new Promise((resolve, reject) => {
      api
        .delete(`${FORUM.POST.GET}/${postId}/reply/${replyId}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getRepliesOfPost({ postId }) {
    return new Promise((resolve, reject) => {
      publicApi
        .get(`${FORUM.POST.GET}/${postId}/reply`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  toggleReplyReaction({ postId, replyId }) {
    return new Promise((resolve, reject) => {
      api
        .patch(`${FORUM.POST.GET}/${postId}/reply/${replyId}/upvote`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getPostsByCategory({ category, offset = 1 }) {
    return new Promise((resolve, reject) => {
      api
        .get(FORUM.POST.GET, {
          params: {
            category,
            offset,
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getPostsByTags({ tags, offset = 1 }) {
    return new Promise((resolve, reject) => {
      api
        .get(FORUM.POST.GET, {
          params: {
            tags,
            offset,
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  updatePost({ postId, data }) {
    return new Promise((resolve, reject) => {
      api
        .put(`${FORUM.POST.GET}/${postId}`, {
          ...data,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  deletePost({ postId }) {
    return new Promise((resolve, reject) => {
      api
        .delete(`${FORUM.POST.GET}/${postId}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getPopularTags() {
    return new Promise((resolve, reject) => {
      api
        .get(`${FORUM.TAGS.GET}/popular`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getSearchSuggestions({ search }) {
    return new Promise((resolve, reject) => {
      publicApi
        .get(`${FORUM.POST.GET}/search-suggestion`, {
          params: {
            search,
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
};
