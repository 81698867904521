import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { setSecurities, setTryoutOperationSecurity } from "../../../app/Features/Swagger/SwaggerSlice";
import { debounceFn, objectDeepClone } from "../../../utils/helper";
import { Input } from "./Inputs";
import { capitalize } from "lodash";
import { X } from "phosphor-react";

const onInput = ({ value }) => {
  window.dispatch(setSecurities({ prop: "apiKey", value }));
  setTimeout(() => {
    window.dispatch(setTryoutOperationSecurity())
  }, 200);
};
const onInputDebounceFn = debounceFn(onInput, 200);

const Apikey = (props) => {
  const { securities, tryoutOperation } = useSelector(
    (state) => state.swagger
  );

  const [apiKey, setApikey] = useState({});

  const operationApiKeys = useMemo(() => {
    let _apiKeys = (tryoutOperation.__security || {}).apiKey || [];
    return _apiKeys;
  }, [tryoutOperation]);

  useEffect(() => {
    setApikey(
      operationApiKeys.reduce(
        (acc, curr) => (
          (acc[curr.securityName] = securities.apiKey[curr.securityName]), acc
        ),
        {}
      )
    );
  }, [securities]);

  const onInput = ({ prop, value }) => {
    let _apiKey = objectDeepClone(apiKey);
    _apiKey[prop].__value = value;
    setApikey(_apiKey);
    onInputDebounceFn({ value: _apiKey });
  };

  return (
    <>
      <div className="d-flex flex-column gap-8px">
        {Object.keys(apiKey).map((name) => {
          return (
            <Input
              label={name}
              value={apiKey[name].__value || ""}
              onChange={(e) => {
                onInput({ prop: name, value: e.target.value });
              }}
              inText={`in ${capitalize(apiKey[name].in)}`}
              placeholder={`Please enter ${name}`}
            />
          );
        })}
      </div>
    </>
  );
};

export default Apikey;
