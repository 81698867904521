import axios from "axios";
import APIS from "../service/config";
import { getCookie } from "../utils/cookie";
import process from 'process';
import { getEndDateTimeStamp, getStartDateTimeStamp, logoutClientSide } from "../utils/helper";
import moment from "moment";
import DeveloperAppAPIs from './DeveloperApp'
const { API, USER, SETTING, TEMPLATE, GLOBAL_SITE, SDK, PARTNER_GROUPS, COMPANIES,AWS_ARTIFACTORY, PORTAL_DASHBOARD,
	USER_ROLE, GUIDE, SIDEBAR, NOTIFICATIONS, EVENTS, ACL, SMTP, SDK_CATEGORY, ACTIVITY, GCS_ARTIFACTORY,
	LAYOUT, MANAGE_USER, PROFILE, USER_GROUP, APIGEE, KONG, AZURE, CONTACT, MONETIZATION, SWAGGER,
	PRODUCTS, TAGS, JFROG_ARTIFACTORY, NGINX, AWS, AXWAY } = APIS;

let headers = {
	Accept: "application/json, text/plain, */*",
	"Content-Type": "application/json",
};

if (process.browser) {
	if (getCookie("itorix-token")) {
		headers["authorization"] = `Bearer ${getCookie("itorix-token")}`;
	}
}

export let baseAPI = axios.create({
	baseURL: API,
	headers: headers,
});

let publicAPI = axios.create({
	baseURL: API,
	headers: {
		Accept: "application/json, text/plain, */*",
		"Content-Type": "application/json",
	},
});

baseAPI.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

publicAPI.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// TODO: Keep two instance for public and private APIs 

// Add a response interceptor
baseAPI.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			logoutClientSide();
		}
		return Promise.reject(error);
	}
);

publicAPI.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	initHeaders(token) {
		let header = baseAPI.defaults.headers;
		if (token) {
			header["authorization"] = `Bearer ${token}`;
		} else {
			if (header.hasOwnProperty("authorization")) {
				delete header["authorization"];
			}
		}
	},

	userLogin(params) {
		return baseAPI.post(USER.userLogin, params);
	},

	resendOTP(params) {
		return baseAPI.post(USER.resendOTP, params)
	},

	loginMFA(params) {
		return baseAPI.post(USER.loginMFA, params)
	},

	updateLoginAttempts(params) {
		return baseAPI.post(USER.updateAttempt, params)
	},

	registerUser(params) {
		return baseAPI.post(USER.registerUser, params);
	},

	checkEmailExits(params){
		console.log("email sent: ", params)
		return publicAPI.get(`${USER.checkEmail}?email=${params.email}`)
	},

	checkUsernameExists(params){

		return publicAPI.get(`${USER.checkUsername}?username=${params.username}`)
	},

	activateNativeUser(params) {
		return baseAPI.post(USER.activateUser, params);
	},

	verifyUserToken(validToken) {
		return baseAPI.get(USER.activateUser + validToken);
	},

	getSettingsData(){
		return baseAPI.get(SETTING.getUserSetting)
	},

	forgotPassword(params) {
		return baseAPI.post(USER.forgotPassword, params);
	},

	resetPassword(params, token) {
		return baseAPI.post(USER.resetPassword + `${token}`, params);
	},

	getResetPassword(token) {
		return baseAPI.get(USER.resetPassword + `${token}`);
	},

	resendToken(params) {
		return baseAPI.post(USER.resendToken, params);
	},

	itorixLogin(params) {
		return baseAPI.post(USER.itorixLogin, params);
	},

	getProfile() {
		return baseAPI.get(USER.getProfile);
	},

	verifyResetPasswordToken(token) {
		return baseAPI.get(USER.verifyResetPasswordToken + `${token}`);
	},

	getSetting() {
		return baseAPI.get(SETTING.get);
	},
	getSmtp() {
		return baseAPI.get(SMTP.get);
	},
	updateSmtp(smtpData) {
		return baseAPI.post(SMTP.createUpdate, smtpData);
	},
	getLoginSetting() {
		return baseAPI.get(SETTING.getLoginSetting);
	},
	updateSetting(params) {
        return baseAPI.post(SETTING.createUpdate, params)
    },
	refreshSession(){
		return baseAPI.get(SETTING.refreshSession)
	},
	getTemplateOnContent(templateType) {
		return baseAPI.get(TEMPLATE.getTemplateOnContent + templateType);
	},

	getGlobalSiteData() {
		return baseAPI.get(GLOBAL_SITE.get);
	},

	getPermissionList() {
		return baseAPI.get(ACL.getPermission);
	},

	loginWithSSO(params) {
		return baseAPI.post(USER.sso, params);
	},
	logOutWithSSO() {
		return baseAPI.post('/v2/auth/logout-v2', {});
	},

	getSSOPermission() {
		return baseAPI.get(USER.ssoPermission);
	},

	headerAclPermission(userRole) {
		let role = userRole ? "?role=" + userRole : "";
		return baseAPI.get(ACL.headerPermission + role);
	},

	footerAclPermission(userRole) {
		let role = userRole ? "?role=" + userRole : "";
		return baseAPI.get(ACL.footerPermission + role);
	},

	getAllSdkCategory(currentPage){
		return baseAPI.get(SDK_CATEGORY.URL + `?page=${currentPage}` )
	},

	addSdkCategory(params){
        return baseAPI.post(SDK_CATEGORY.CREATE, params)
    },

    updateSdkCategory(id, param){
        return baseAPI.put(SDK_CATEGORY.URL + `/${id}`, param)
    },

	deleteSdkCategory(id){
        return baseAPI.delete(SDK_CATEGORY.URL + '/' + id)
    },

	addSdk(params){
        return baseAPI.post(SDK.CREATE, params)
    },

	downloadSDK(params){
        return baseAPI.post(`${SDK.SDK_DOWNLOAD_V2}/${params._id}`)
    },

	filterSdk(params, currentPage){
        return baseAPI.get(SDK.URL + '?' + params + '&page=' + currentPage)
    },

    updateSdk(id, param){
        return baseAPI.put(SDK.URL + `/${id}`, param)
    },

    getAllSdk(currentPage, searchVal){
		let _url = `?page=${currentPage}`
		if (searchVal) _url += `&filterTitle=${searchVal}`
        return baseAPI.get(SDK.URL + _url )
    },

    deleteSdk(id){
        return baseAPI.delete(SDK.URL + '/' + id)
    },

	getLayoutDetails(){
        return baseAPI.get(LAYOUT.URL)
    },

	listPartners(){
		return baseAPI.get(PARTNER_GROUPS.LIST_PARTNERS)
	},

	getAllRoles(){
        return baseAPI.get(USER_ROLE.getAll)
    },

	getSingleRecordUser(id){
        return baseAPI.get(MANAGE_USER.getSingleRecord + `${id}`)
    },

	uploadImage(file){
		let formData = new FormData();
		formData.append("file", file);
		return new Promise((resolve, reject) => {
			baseAPI.post(`v2${GUIDE.upload}-v2`, formData)
			.then((response) => {
				let json = {};
				if (response.data) {
					const fileURL = response.data.data.url;
					delete response.data.data.url;
					json = {
						success: 1,
						file: {
							url: fileURL,
							...response.data,
						},
					};
				}
				resolve(json);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getAllUser(number, searchStr,status){
        if(searchStr){
            return baseAPI.get(MANAGE_USER.getAll + '?page=' + number + `&search=${searchStr}` )
        }if(status){
			return baseAPI.get(MANAGE_USER.getAll + '?page=' + number + `&status=${status}`)
		}
		else{
            return baseAPI.get(MANAGE_USER.getAll + '?page=' + number)
        }
    },

	getPartnerGroups(){
        return baseAPI.get(PARTNER_GROUPS.GET)
    },
	syncPartners(){
		return baseAPI.get(PARTNER_GROUPS.SYNC_PARTNERS)
	},
	getCompaniesList(){
        return baseAPI.get(COMPANIES.GET)
    },
	syncCompanies(){
		return baseAPI.get(COMPANIES.SYNC_COMPANIES)
	},
	getUserAnalytics(){
		return baseAPI.get(MANAGE_USER.getAll + `/analytics`)
	},
	inviteUser(params){
        return baseAPI.post(MANAGE_USER.inviteUser, params)
    },
	getInviteUser(id){
        return baseAPI.get(MANAGE_USER.inviteUser + `/${id}`)
    },
	deleteUser(id){
        return baseAPI.delete(MANAGE_USER.delete + `${id}`)
    },

    updateStatus(params){
        return baseAPI.post(PROFILE.UPDATE_STATUS, params)
    },
	createRole(params) {
        return baseAPI.post(USER_ROLE.create, params)
    },
    updateRole(id, params){
        return baseAPI.put(USER_ROLE.update + `${id}`, params)
    },
    deleteUserRole(id){
        return baseAPI.delete(USER_ROLE.delete + `${id}`)
    },
	createGroup(params) {
        return baseAPI.post(USER_GROUP.create, params)
    },
    updateGroup(id, params){
        return baseAPI.put(USER_GROUP.update + `${id}`, params)
    },
    getAllGroups(number){
        return baseAPI.get('/v2' + USER_GROUP.getAll + '?page=' + number)
    },
    deleteUserGroups(id){
        return baseAPI.delete(USER_GROUP.delete + `${id}`)
    },

    updateUser(id, params){
        return baseAPI.put(MANAGE_USER.update + `${id}`, params)
    },
	putLayoutDetails(body){
        return baseAPI.put(LAYOUT.URL, body)
    },
	syncOrgs(){
		return  baseAPI.get(APIGEE.SYNC)
	},
	getOrgs(){
		return baseAPI.get(APIGEE.ORG)
	},
	setDefaultOrg({ id }){
		return baseAPI.post(APIGEE.SETDEFAULTORG, {
			id,
			orgDefault: "1"
		})
	},
	syncRuntimes(){
		return baseAPI.get(KONG.SYNC)
	},
	getRuntimes(){
		return baseAPI.get(KONG.GET_RUNTIMES)
	},
	setDefaultRuntime({ id }){
		return baseAPI.put(KONG.SETDEFAULT_RUNTIME, {
			id
		})
	},
	syncNginxRuntimes(){
		return baseAPI.get(NGINX.SYNC)
	},
	getNginxRuntimes(){
		return baseAPI.get(NGINX.GET_RUNTIMES)
	},
	setDefaultNginxRuntime({ id }){
		return baseAPI.put(NGINX.SETDEFAULT_RUNTIME, { id })
	},
	syncResourceGroups(){
		return baseAPI.get(AZURE.SYNC)
	},
	getResourceGroups(){
		return baseAPI.get(AZURE.GET_RESOURCES)
	},
	setDefaultResource({ id }){
		return baseAPI.put(AZURE.SETDEFAULT_RESOURCE, {
			id
		})
	},

	getApigeeUsers({ page = 1, search = ''}){
		return baseAPI.get(`${APIGEE.APIGEE_USERS}?status=active`, {
			params: {
				page, search
			}
		})
	},

	getKongUsers({ page = 1, search = ''}){
		return baseAPI.get(`${MANAGE_USER.getAll}?status=active`, {
			params: {
				page, search
			}
		})
	},

	getUsers(){
		return baseAPI.get(MANAGE_USER.getAll+'?pagination=false')
	},

	deleteUsers(body){
		return baseAPI.delete(MANAGE_USER.deleteMultiple, {
			data: body
		})
	},

	getAzureUsers({ page = 1, search = '' }){
		return baseAPI.get(`${MANAGE_USER.getAll}?status=active`, {
			params: {
				page, search
			}
		})
	},

	getNginxUsers({ page = 1, search = ''}){
		return baseAPI.get(`${MANAGE_USER.getAll}?status=active`, {
			params: {
				page, search
			}
		})
	},

	getUserOrgList(){
		return baseAPI.get(APIGEE.GET_ORG_LIST)
	},

	updateApigeeUser({ data, userId }){
		return baseAPI.put(`${APIGEE.APIGEE_USERS}/${userId}`, {
			...data
		})
	},

	updateKongUser({ data, userId }){
		return baseAPI.put(`${KONG.KONG_USER}/${userId}`, {
			...data
		})
	},

	updateNginxUser({ data, userId }){
		return baseAPI.put(`${NGINX.NGINX_USER}/${userId}`, {
			...data
		})
	},

	updateAzureUser({ data, userId}){
		return baseAPI.put(`${AZURE.AZURE_USER}/${userId}`, {
			...data
		})
	},
	
	getSidebarData(){
		return baseAPI.get(SIDEBAR.GET)
	},
	getNotifications(params = {}){
		return baseAPI.get(NOTIFICATIONS.GET, {
			params: {...params}
		})
	},
	markAsRead(id){
		return baseAPI.put(`${NOTIFICATIONS.MARK_AS_READ}/${id}`)
	},
	getPermissions(){
        return baseAPI.get(ACL.getPermission)
    },
	updatePermissions(payload){
        return baseAPI.post(ACL.getPermission, payload)
    },
	getAllContact(currentPage){
        return baseAPI.get(CONTACT.URL + '?page=' + `${currentPage}` )
    },
	deleteContact(id){
        return baseAPI.delete(CONTACT.URL + `/${id}`)
    },
	addContact(params){
        return baseAPI.post(CONTACT.URL + '/create', params)
    },
	updateUserDetails(params) {
        return baseAPI.put(PROFILE.UPDATE, params)
    },

	createEvent(params){
        return baseAPI.post(EVENTS.create, params)
    },

    updateEvent(id, param){
        return baseAPI.put(EVENTS.update + `${id}`, param)
    },

    getAllEventList(currentPage){
        return baseAPI.get(EVENTS.getAll + '?page=' + `${currentPage}` )
    },

    getSingleEvent(id){
        return baseAPI.get(EVENTS.getSingleRecord + `${id}`)
    },

    deleteEvent(id){
        return baseAPI.delete(EVENTS.delete + `${id}`)
	},

	downloadCalendar(id){
		return baseAPI.get(EVENTS.download + id)
	},

	showEventBasedOnCategory(id, category){
		return baseAPI.get(EVENTS.getAll + '/' + id + '/' + category)
	},

	fetchAllPastEvent(){
		return baseAPI.get(EVENTS.getAll + 'past')
	},

	getAWSDetails(){
		return baseAPI.get(AWS_ARTIFACTORY.GET)
	},

	postAWSDetails(payload){
		return baseAPI.post(AWS_ARTIFACTORY.GET, payload)
	},
	
	updateAWSDetails(payload){
		return baseAPI.put(AWS_ARTIFACTORY.GET, payload)
	},

	deleteAWSDetails(){
		return baseAPI.delete(AWS_ARTIFACTORY.GET)
	},

	getGCSDetails(){
		return baseAPI.get(GCS_ARTIFACTORY.GET)
	},

	postGCSDetails(payload, headers){
		return baseAPI.post(GCS_ARTIFACTORY.GET, payload, {
			headers: {...headers}
		})
	},
	
	updateGCSDetails(payload, headers){
		return baseAPI.put(GCS_ARTIFACTORY.GET, payload, {
			headers: {...headers}
		})
	},

	deleteGcsDetails(){
		return baseAPI.delete(GCS_ARTIFACTORY.GET)

	},
	getJFROGDetails(){
		return baseAPI.get(JFROG_ARTIFACTORY.GET)
	},

	postJFROGDetails(payload, headers){
		return baseAPI.post(JFROG_ARTIFACTORY.GET, payload, {
			headers: {...headers}
		})
	},
	
	updateJFROGDetails(payload, headers){
		return baseAPI.put(JFROG_ARTIFACTORY.GET, payload, {
			headers: {...headers}
		})
	},

	deleteJFROGDetails(){
		return baseAPI.delete(JFROG_ARTIFACTORY.GET)
	},
	getActivityLogs(payload){
		if(!payload.start) payload.start = new Date(Date.now());
		payload.start = getStartDateTimeStamp(payload.start)
		if(!payload.end){
		   payload.end = getEndDateTimeStamp(payload.start)
		}else{
			payload.end = getEndDateTimeStamp(payload.end)
		}

		let _startDate = moment(payload.start).format("DD/MM/yyyy"); 
		let _endDate = moment(payload.end).format("DD/MM/yyyy");
		console.log("_startDate::",_startDate,"_endDate::", _endDate)
		
		let url = `?page=${payload.currentPage || 1}` + `&start=${_startDate}`;
		url = url.concat(`&end=${_endDate}`)
		// if(_startDate !== _endDate){
		// }
	
		if(payload.usersList?.length > 0){
			for(let i = 0; i < payload.usersList?.length; i++){
				url = url.concat(`&userId=${payload.usersList[i]}`)
			}
		}
		console.log(ACTIVITY.getLogs + url, "FINAL URL")
		return baseAPI.get(ACTIVITY.getLogs + url )
	},
	
	getDashboardDetails(){
		return baseAPI.get(PORTAL_DASHBOARD.GET)
	},

	// APigee Org
	getSyncOrg() {
		return baseAPI.get(APIGEE.SYNC);
	},

	getAllOrgs(pageNumber) {
		return baseAPI.get(APIGEE.ORG + "?page=" + pageNumber);
	},

	updateOrgName(id, params) {
		return baseAPI.put(APIGEE.ORG + "/" + `${id}`, params);
	},

	setOrgDefault(params) {
		return baseAPI.post(APIGEE.SETDEFAULTORG, params);
	},

	getBuildApps(type, pageNumber) {
		let orgType = "";
		if (type) {
			orgType = "/" + type;
		}
		return baseAPI.get(APIGEE.APP_URL + orgType + "?page=" + pageNumber);
	},
	
	getProductBundles(type) {
		return baseAPI.get(`${MONETIZATION.GET_PRODUCT_BUNDLES}/${type}?pagination=true`);
	},
	getBuildAPps(type) {
		return baseAPI.get(`/build-app/${type}?page=1`);
	},
	deleteApp(org, appName) {
		return baseAPI.delete(`/delete-app/${org}/${appName}`);
	},
	deleteApigeeApp({appName}){
		return baseAPI.delete(`/delete-app/${appName}`);
	},
	getPurchaseHistory(type) {
		return baseAPI.get(`${MONETIZATION.PURCHASE_HISTORY}/${type}`);
	},
	deletePurchaseHistory(appId, purchaseId) {
		return baseAPI.delete(`${MONETIZATION.APPS}/${appId}/purchase/${purchaseId}`);
	},
	getBillingAPps(id, transaction) {
		return baseAPI.get(`${MONETIZATION.APPS}/${id}/billing`, {
			headers: {
				transactions: transaction
			}
		});
	},
	purchaseRatePlanUsingWallet(params) {
		return baseAPI.post(`${MONETIZATION.PURCHASE_APP}`, params);
	},
	getAppTransactions(id){
		return baseAPI.get(`${MONETIZATION.TRANSACTIONS}/${id}`)
	},
	getAppWallet(id){
		return baseAPI.get(`${MONETIZATION.WALLET}/${id}`)
	},
	getAppPurchases(id){
		return baseAPI.get(`${MONETIZATION.APPS}/purchase/${id}`)
	},
	postAppPurchases(id, payload){
		return baseAPI.post(`${MONETIZATION.WALLET}/${id}`, payload)
	},
	updateApp( organization, params, orgType ){
		return baseAPI.put(`${MONETIZATION.BUILD_APP}/${organization}/apps/${params.name}?orgType=${orgType}`, params)
	},
	addBuildApps(params) {
		return baseAPI.post(APIGEE.APP_URL, params);
	},
	deleteBuildApps(params) {
		return baseAPI.delete(`/delete-app/${params.org}/${params.appName}`);
	},
	editProductApp(obj, params) {
		return baseAPI.post(
			`/update-apigee-product/${obj.org}/${obj.appName}`,
			params
		);
	},
	getProducts() {
		return baseAPI.get(APIGEE.PRODUCT);
	},
	getAppDetails(url) {
		return baseAPI.get(APIGEE.APP_DETAILS + url);
	},

	getPublishedSwaggers(params, queryParams = {}){
		return baseAPI.get(`${SWAGGER.V2_BASE}/documentation`, {
			headers: {...params}, 
			params: {status: 'Publish', ...queryParams}
		})
	},

	getPublishedSwaggersv2(params, queryParams = {}){
		return baseAPI.get(`/itorix/v1/swaggers/documentation`, {
			headers: {...params}, 
			params: {status: 'Publish', ...queryParams}
		})
	},

	getSwaggerRevisions(swaggerId, params){
		return baseAPI.get(`${SWAGGER.V2_BASE}/${swaggerId}/revisions`, {
			headers: {...params}
		})
	},

	getRatingSummary({swaggerId, revision, params}){
		return baseAPI.get(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/${revision}/summary`, {
			headers: {...params}
		})
	},

	getSwaggerRating({swaggerId, params, revision}){
		return baseAPI.get(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/${revision}/rating`, {
			headers: {...params}
		})
	},

	postSwaggerRating({swaggerId, data}){
		return baseAPI.post(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/rating`, data)
	},

	updateSwaggerRating({swaggerId, data}){
		return baseAPI.put(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/rating`, data)
	},

	deleteSwaggerRating({swaggerId, revision, isAuthorised, params}){
		return baseAPI.delete(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/${revision}/${isAuthorised ? "admin/" : ""}delete`, {
			headers: {...params}
		})
	},
	subscribeSwagger({data}){
		return baseAPI.put(`${SWAGGER.CATALOG_SWAGGERS}/subscribe`, data)
	},

	unsubscribeSwagger({data, swaggerId, emailId}){
		return baseAPI.put(`${SWAGGER.CATALOG_SWAGGERS}/unsubscribe/${swaggerId}/${emailId}`, data)
	},

	getIsSubscribed({swaggerId, emailId}){
		return baseAPI.get(`${SWAGGER.CATALOG_SWAGGERS}/checksubscriber/${swaggerId}/${emailId}`)
	},

	putGuideDetails(guideId, versionId, pageId, parent){
		return baseAPI.put(`/v2${GUIDE.get}/${guideId}/versions/${versionId}/pages/${pageId}`, {
			parent: parent
		})
	},

	reorderPage(guideId, versionId, pageId, payload){
		return baseAPI.put(`/guides/${guideId}/versions/${versionId}/pages/${pageId}`, payload)
	},

	addSwaggerToGuide(guideId, versionId, data){
		return baseAPI.post(`/v2${GUIDE.get}/${guideId}/versions/${versionId}/add-swagger`, data)
	},

	updateSwaggerToGuide(guideId, versionId, data){
		return baseAPI.put(`/v2${GUIDE.get}/${guideId}/versions/${versionId}/add-swagger`, data)
	},

	deleteSwaggerFromGuide(guideId, versionId, data){
		return baseAPI.delete(`/v2${GUIDE.get}/${guideId}/versions/${versionId}/add-swagger`, data)
	},

	getSwagger({swaggerId, revision, params}){
		return baseAPI.get(`${SWAGGER.V2_BASE}/${swaggerId}/revisions/${revision}`, {
			headers: {...params}
		})
	},

	getAllProducts(currentPage, searchString, filterCategory=''){
		let url = `?page=${currentPage}`
		if (searchString){
			url += `&searchtext=${searchString}`
		}
		if (filterCategory){
			url += `&filterCategory=${filterCategory}`
		}
        return baseAPI.get(PRODUCTS.URL + url )
    },
	getEachProduct(id){
        return baseAPI.get(PRODUCTS.URL + `/${id}`)
    },
	getAllTags(currentPage){
        return baseAPI.get(TAGS.URL + '?page=' + `${currentPage}` )
    },
	deleteTag(id){
        return baseAPI.delete(TAGS.URL + `/${id}`)
    },
	addTags(params){
        return baseAPI.post(TAGS.URL, params)
    },
	updateTags(id, param){
        return baseAPI.put(TAGS.URL+ '/' + `${id}`, param)
    },

    updateProduct(id, param){
        return baseAPI.put(PRODUCTS.URL+ '/' + `${id}`, param)
    },
	addProduct(params){
        return baseAPI.post(PRODUCTS.URL, params)
    },
	deleteProduct(id){
        return baseAPI.delete(PRODUCTS.URL + `/${id}`)
    },

	getLandingAnalytics(id){
		return baseAPI.get(`/v2/landing-analytics`)
	},

	trackSessions(page){
		return publicAPI.post(`/v2/page-session`, {
			page
		})
	},

	saveMonetizationPermissionForProducts({appName, productName, action, body, org, orgType}){
		return baseAPI.put(`${MONETIZATION.APPS}/${appName}/products/${productName}?action=${action}${org? `&org=${org}`: ""}${orgType ? `&orgType=${orgType}`: ""}`, body)
	},

	saveMonetizationPermission(orgName, appName, action, body,orgType){
		return baseAPI.put(`${MONETIZATION.BUILD_APP}/${orgName}/apps/${appName}?status=${action}&orgType=${orgType}`, body)
	},

	getGuideProductBundles(productBundle){
		return baseAPI.post(`/guides/product`, {
			productBundle
		})
	},
	getProductGroup(params){
		return baseAPI.get(`catalog/swaggers/product-groups`, {
			headers: {...params}, 
		})
	},

	getAllCatalogProducts(params){
		let partners = "";
		if(params?.partners) partners = params.partners 
		let _url = `v2/catalog/all-products`
		if(partners)
		{
		_url += `?partners=${partners}`
		}

		return baseAPI.get(_url)
	},


	getPartnerGroup(params){
		return baseAPI.get(`${SWAGGER.CATALOG_SWAGGERS}/partner-groups`, {
			headers: {...params}, 
		})
	},
	getSwaggerChangeLog({ swaggerId, version, params }){
		return baseAPI
			.get(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/change-log`, {
				params,
				headers: {oas : version}
			}, )
	},
	getSwaggerDetailByRevision({ swaggerId, version, revision}){
		return baseAPI
		.get(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/revisions/${revision}`,{headers: {oas : version}})
	},
	getChangeLogDetails({ swaggerId, logId, version}){
		return baseAPI
			.get(`${SWAGGER.CATALOG_SWAGGERS}/${swaggerId}/change-log/${logId}`, {
				headers: {oas : version}
			}, )
	},
	getAvailableClientsAndServers({ version }){
		return baseAPI.get(`/catalog/swagger-gen/clients/servers`, {
			headers: {...headers, oas: version}, 
		})
	},

	generateSwagger(params, version) {
		return baseAPI.post(`/catalog/swagger-gen/${params.target}/${params.framework}`, params.data,{
			headers: {...headers, oas: version}, 
		})
	},
	fileDownload({url,filename}){
		let fileName = filename;
		if(!fileName)
		{
		  let test = new URL(url);
		  const fileArray = test.pathname.split("/");
		  fileName = fileArray[fileArray.length - 1];
		}
	
		return new Promise((resolve, reject) => {
			baseAPI
			.get(url, {
				headers: {
					...headers, responseType: 'arraybuffer'
				}
			}).then(function(response) {
				const blob = new Blob([response.data], {
					type: response.headers["content-type"],
				});
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = fileName;
				link.click();
			
				URL.revokeObjectURL(link.href);
				resolve({message: 'Success'})
			})
			.catch(function(error) {
				console.log(error);
				reject(error)
			});
		});
	},

	/**
	 * Nginx App Creation
	 */
	createNginxApp(body){
		return baseAPI.post(NGINX.BUILD_APP, body)
	},
	getNginxApps(){
		return baseAPI.get(NGINX.APPS)
	},
	updateNginxApp({id, body}){
		return baseAPI.put(`${NGINX.APPS}/${id}`, body)
	},
	updateNginxStatus({id, status}){
		return baseAPI.put(`${NGINX.APPS}/${id}/status/${status}`)
	},
	deleteNginxApp({id}){
		return baseAPI.delete(`${NGINX.APPS}/${id}`)
	},
	getNginxCredentials({id}){
		return baseAPI.get(`${NGINX.CREDENTIALS}/${id}`)
	},


	/**
	 * AWS App creation
	 */
	syncAwsRuntimes(){
		return baseAPI.get(AWS.SYNC)
	},
	getAwsRuntimes(){
		return baseAPI.get(AWS.GET_RUNTIMES)
	},


	setDefaultAwsRuntime({ id }){
		return baseAPI.put(AWS.SETDEFAULT, { id })
	},
	updateAwsUser({ data, userId }){
		return baseAPI.put(`${AWS.USER}/${userId}`, {
			...data
		})
	},
	getAwsUsers({ page = 1, search = ''}){
		return baseAPI.get(`${MANAGE_USER.getAll}?status=active`, {
			params: {
				page, search
			}
		})
	},
	createAwsApp(body){
		return baseAPI.post(AWS.BUILD_APP, body)
	},
	getAwsApps(){
		return baseAPI.get(AWS.APPS)
	},
	updateAwsApp({id, body}){
		return baseAPI.put(`${AWS.APPS}/${id}`, body)
	},
	updateAwsStatus({id, status}){
		return baseAPI.put(`${AWS.APPS}/${id}/status/${status}`)
	},
	deleteAwsApp({id}){
		return baseAPI.delete(`${AWS.APPS}/${id}`)
	},
	getAwsCredentials({id}){
		return baseAPI.get(`${AWS.CREDENTIALS}/${id}`)
	},


	getMasterCurrencies(params){
		return baseAPI.get(`${MONETIZATION.MASTER_CURRENCY}`, {params: {...params}})
	},
	getPackageDetails(id){
		return baseAPI.get(`${MONETIZATION.PACKAGE}/${id}`)
	},
	
	getReviewsForAPackage(id, sortBy='RATING', sortByType='ASC'){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/${id}/review`, {
			params: {expand: true, sortBy, sortByType}
		})
	},

	addReview(body){
		return baseAPI.post(`${MONETIZATION.DEVELOPER_PORTAL}/review`, body)
	},
	deleteReview(id, developerEmail){
		return baseAPI.delete(`${MONETIZATION.DEVELOPER_PORTAL}/review/${id}?developerEmail=${developerEmail}`)
	},
	getReviewStats(id){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/${id}/review/stats`)
	},
	updateReview(id, body){
		return baseAPI.put(`${MONETIZATION.DEVELOPER_PORTAL}/review/${id}`, body)
	},

	getRecentPackages(params){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/package/recent`, {
			params
		})
	},
	
	getPopularPackages(params){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/package/popular`, {
			params
		})
	},

	getAllCategories(params){
		return publicAPI.get(`${MONETIZATION.BASE}/category`, {
			params
		})
	},

	getAllInvoices(params){
		return baseAPI.get(`${MONETIZATION.BASE}/invoice`, {
			params
		})
	},

	getPackagesByCategory(id, params){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/category/${id}/package`, {
			params
		})
	},

	getSubscriptionPackage(id, params){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/category/${id}/package`, {
			params
		})
	},

	getDeveloperSubscription(id, params){
		return baseAPI.get(`${MONETIZATION.DEVELOPER_PORTAL}/subscription/${id}`, {
			params
		})
	},

	createConcern(body, developerEmail){
		return baseAPI.post(`${MONETIZATION.DEVELOPER_PORTAL}/concern?developerEmail=${developerEmail}`, {
			concern: body
		})
	},

	getMonetizationNotifications(email){
		return baseAPI.get(`${MONETIZATION.BASE}/notification/${email}`)
	},

	readAllNotifications(email){
		return baseAPI.put(`${MONETIZATION.BASE}/notification/read/${email}`)
	},
	
	readOneNotification(id){
		return baseAPI.put(`${MONETIZATION.BASE}/notification/${id}`)
	},

	readInvoiceDownload(id, email){
		return baseAPI.get(`${MONETIZATION.BASE}/invoice/${id}/download?developerEmail=${email}`)
	},

	getAppMapper(){
		return baseAPI.get(`/v2/app-product-mapping`)
	},

	createAppMapper(body){
		return baseAPI.post(`/v2/app-product-mapping`, body)
	},

	updateAppMapper(id, body){
		return baseAPI.put(`/v2/app-product-mapping/${id}`, body)
	},

	getAppMapperById(id){
		return baseAPI.get(`/v2/app-product-mapping/${id}`)
	},
	getAxwayRuntimes(){
		return baseAPI.get(AXWAY.GET_RUNTIMES)
	},
	syncAxwayRuntimes(){
		return baseAPI.get(AXWAY.SYNC)
	},

	setDefaultAxwayRuntime({ id }){
		return baseAPI.put(AXWAY.SETDEFAULT, { id })
	},
	updateAxwayUser({ data, userId }){
		return baseAPI.put(`${AXWAY.USER}/${userId}`, {
			...data
		})
	},
	getAxwayUsers({ page = 1, search = ''}){
		return baseAPI.get(`${MANAGE_USER.getAll}?status=active`, {
			params: {
				page, search
			}
		})
	},

	...DeveloperAppAPIs
};

