import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { CredentialAccordian } from "./CredentialAccordian";

export const AzureAppDetails = ({
  onModalClose,
  appData,
  statusChangeCallback,
}) => {

  const selectedCredentials = appData?.key;
  const selectedApp = appData;

  return (
    <div
      className="product-details-modal__container"
      onClick={(evt) => {
        evt.stopPropagation();
        onModalClose();
      }}
    >
      <div
        className="product-details-modal-body"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-header">
          <div className="modal-title">Credentials</div>
          <div className="modal-action-btns__container">
            <button
              className="action-btn close-btn primary"
              onClick={onModalClose}
            >
              <GlobalIcons type={"close"} />
            </button>
          </div>
        </div>
        <div
          className="modal-content d-flex flex-column"
          style={{ gap: "6px" }}
        >
          {selectedCredentials && (
            <CredentialAccordian
              credentials={{ consumerKey: selectedCredentials }}
              selectedApp={selectedApp}
              statusChangeCallback={statusChangeCallback}
              onModalClose={onModalClose}
              isAzure
            />
          )}
        </div>
      </div>
    </div>
  );
};
