import { useMemo, useState } from "react";
import { PostIconConfig } from "../Post/PostIconConfig";
import { useSelector } from "react-redux";

export const PostIcon = ({ type, state, ...props }) => {
  const [hovered, setHovered] = useState(false);

  const {
    currentLayoutObject: { siteTemplateType },
  } = useSelector((state) => state.layout);


  const icon = useMemo(() => {
    if (PostIconConfig[siteTemplateType]) {
      return state === "filled"
        ? PostIconConfig[siteTemplateType][type]?.filled
        : PostIconConfig[siteTemplateType][type]?.default;
    } else {
      return null;
    }
  });

  const hoverIcon = PostIconConfig[siteTemplateType]
    ? PostIconConfig[siteTemplateType][type]?.hover
    : null;


  return (
    <img
      src={hovered ? hoverIcon : icon}
      onMouseOver={() => {
        if (state === "filled" || state === "default") {
          setHovered(true);
        }
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
      className="post-icon"
      {...props}
    />
  );
};
