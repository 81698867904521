import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import API from '../../../../api'
import { setLayoutObjects } from '../../../../app/Features/Layout/LayoutSlice'
import Loading from '../../../../components/Loader/Loading'
import { getToast } from '../../../../components/Toast'
import { objectDeepClone, throwServerError } from '../../../../utils/helper'

export default function DataSettings() {
    const { currentLayoutObject, backupCurrentLayout } = useSelector(state => state.layout)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [data, setData] = useState({
        sitename: '', title: '', 
        metaTitle: '', metaDescription: '', description: '',
        footerCopyright: ''
    })

    // sitename - to greet hi
    // title - logo title
    // metaTitle - meta name
    // metaDesc - meta content
    // description - in footer

    useEffect(() => {
        setData({
            sitename: currentLayoutObject.sitename, 
            title: currentLayoutObject.title, 
            metaTitle: currentLayoutObject.metaTitle, 
            metaDescription: currentLayoutObject.metaDescription, 
            description: currentLayoutObject.description, 
            footerCopyright: currentLayoutObject.footerCopyright
        })
    }, [currentLayoutObject])
    
    const handleCancel = () => {
        let _backupCurrentLayout = objectDeepClone(backupCurrentLayout)
        let _data = {
            sitename: _backupCurrentLayout.sitename, 
            title: _backupCurrentLayout.title, 
            metaTitle: _backupCurrentLayout.metaTitle, 
            metaDescription: _backupCurrentLayout.metaDescription, 
            description: _backupCurrentLayout.description, 
            footerCopyright: _backupCurrentLayout.footerCopyright
        }
        dispatch(setLayoutObjects(objectDeepClone(_data)))
    }

    const handleSave = () => {
        let _data = objectDeepClone(data)
        let tempErrors = {}
        Object.keys(_data).forEach((eachKey) => {
            let eachValue = _data[eachKey]
            if (!eachValue) tempErrors[eachKey] = true
        })

        setErrors(tempErrors)
        if (Object.keys(tempErrors).length) return

        setLoading(true)
        API.putLayoutDetails(objectDeepClone(data))
        .then(res => {
            setLoading(false)
            getToast({
                statusType: 'SUCCESS', message: 'Data Settings updated successfully'
            })

            document.title = `${data.metaTitle} | ${data.metaDescription}`; 
            dispatch(setLayoutObjects(objectDeepClone(data)))
        }).catch(err => {
            setLoading(false)
            throwServerError(err)
        })
    }

    return (
        <>
            <div className='surface-875 px-16px py-8px mt-4 v1-border-bottom-1 br-tr-4px br-tl-4px'>
                <p className='fw-600 fs-16px text-200'>Data Settings</p>
                <p className='text-400 fs-12px fw-400 my-1'>
                    Enter site name and other related data
                </p>
            </div>
            <div className='p-3 surface-900 br-tb-4px br-tb-4px'>
                <div className='mb-3'>
                    <p className='fs-12px fw-400 text-300'>Site Name <span className='text-danger'>*</span></p>
                    <input className='form-control v1-form-control mt-6px' placeholder='Enter Sitename'
                        value={data.sitename} onChange={(e) => {
                            let _str = e.target.value
                            setData({...data, sitename: _str})
                            setErrors({...errors, sitename: _str.trim().length > 0 ? false : true})
                        }} />
                    {errors.sitename && <p className='text-danger fs-10px mt-1'>Sitename is Required</p>}
                </div>

                <div className='mb-3'>
                    <p className='fs-12px fw-400 text-300'>Title <span className='text-danger'>*</span></p>
                    <input className='form-control v1-form-control mt-6px' placeholder='Enter Title' 
                        value={data.title} onChange={(e) => {
                            let _str = e.target.value
                            setData({...data, title: _str})
                            setErrors({...errors, title: _str.trim().length > 0 ? false : true})
                        }} />
                    {errors.title && <p className='text-danger fs-10px mt-1'>Title is Required</p>}
                </div>

                <div className='mb-3'>
                    <p className='fs-12px fw-400 text-300'>Meta Title <span className='text-danger'>*</span></p>
                    <input className='form-control v1-form-control mt-6px' placeholder='Enter Meta Title' 
                        value={data.metaTitle} onChange={(e) => {
                            let _str = e.target.value
                            setData({...data, metaTitle: _str})
                            setErrors({...errors, metaTitle: _str.trim().length > 0 ? false : true})
                        }} />
                    {errors.metaTitle && <p className='text-danger fs-10px mt-1'>Meta Title is Required</p>}
                </div>

                <div className='mb-3'>
                    <p className='fs-12px fw-400 text-300'>Meta Description <span className='text-danger'>*</span></p>
                    <input className='form-control v1-form-control mt-6px' placeholder='Enter Meta Description' 
                        value={data.metaDescription} onChange={(e) => {
                            let _str = e.target.value
                            setData({...data, metaDescription: _str})
                            setErrors({...errors, metaDescription: _str.trim().length > 0 ? false : true})
                        }} />
                    {errors.metaDescription && <p className='text-danger fs-10px mt-1'>Meta Description is Required</p>}
                </div>

                <div className='mb-3'>
                    <p className='fs-12px fw-400 text-300'>Description <span className='text-danger'>*</span></p>
                    <input className='form-control v1-form-control mt-6px' placeholder='Enter Description' 
                        value={data.description} onChange={(e) => {
                            let _str = e.target.value
                            setData({...data, description: _str })
                            setErrors({...errors, description: _str.trim().length > 0 ? false : true})
                        }} />
                    {errors.description && <p className='text-danger fs-10px mt-1'>Description is Required</p>}
                </div>

                <div className='mb-3'>
                    <p className='fs-12px fw-400 text-300'>Copyright Text<span className='text-danger'>*</span></p>
                    <input className='form-control v1-form-control mt-6px' placeholder='Enter Description' 
                        value={data.footerCopyright} onChange={(e) => {
                            let _str = e.target.value
                            setData({...data, footerCopyright: _str })
                            setErrors({...errors, footerCopyright: _str.trim().length > 0 ? false : true})
                        }} />
                    {errors.footerCopyright && <p className='text-danger fs-10px mt-1'>Copyright text is Required</p>}
                </div>

                <div className='d-flex align-items-center justify-content-end mt-3'>
                    <p className='fw-500 fs-12px text-300' onClick={handleCancel}>Cancel</p>

                    {loading
                    ? <div className='ms-3'><Loading size='sm' /></div>
                    : <div className='btn-md-v1 fw-500 fs-12px text-300 ms-3' onClick={handleSave}>
                        Save
                    </div>}
                </div>
            </div>
        </>
    )
}
