import { useState, useEffect, useCallback } from "react";
import MonetizeAppCard from "../components/MonetizeAppCard";
import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import { MagnifyingGlass, Plus } from "phosphor-react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import { EmptyState } from "../../../../components/EmptyState";
import PaginationV2 from "../../../../components/PaginationV2";
import { useTheme } from "../../../../context/ThemeContext";
import { LayoutViews } from "../../../../service/constants";
import { AppDetailsModal } from "../../components/AppDetailsModal";
import { AzureAppDetails } from "../../components/AzureAppDetailsModal";
import { KongAppDetails } from "../../components/KongAppDetailsModal";
import { GenericAppDetails } from "../../components/GenericAppDetailsModal";
import {
  getPermissionByCrud,
  throwServerError,
  debounceFn,
} from "../../../../utils/helper";
import API from "../../../../api";
import { AppListRow } from "./AppListRow";
import MonetizationIcons from "../../../../components/Icons/MonetizationIcons";
import { useDispatch } from "react-redux";
import { setDeveloperAppProp } from "../../../../app/Features/DeveloperApp/DeveloperAppSlice";

const INITIAL = { total: 10, offset: 1, pageSize: 10 };
const AllApps = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    permission,
    currentUser,
  } = useSelector((state) => state.user);
  const {paging, currentPage, activeView, searchStr} = useSelector(state => state.developerApp);
  const [loading, setLoading] = useState(false);
  const [appsList, setAppsList] = useState([]);

  /** ACL */
  let developerApp = permission.filter((o) => o.name === "developer-app");
  developerApp = developerApp.length ? developerApp[0].crud || [] : [];
  const listApp = getPermissionByCrud(currentUser, developerApp, "read");
  const createApp = getPermissionByCrud(currentUser, developerApp, "create");
  const deleteApp = getPermissionByCrud(currentUser, developerApp, "delete");
  /** ACL */

  const handlePageChange = (_pageNumber) => {
    // setCurrentPage(_pageNumber);
    dispatch(setDeveloperAppProp({prop: "currentPage", value: _pageNumber}))
    getGatewayApps(_pageNumber);
  };

  const getGatewayApps = (page = currentPage, srchStr = searchStr) => {
    const appCardWidth = 430;
    const appElement = document.getElementById("app");
    const appLimit = Math.round(appElement.offsetWidth / appCardWidth) * 4;

    setLoading(true);
    API.getDeveloperApps({
      page,
      appName: srchStr,
      email: currentUser.email,
      limit: appLimit,
    })
      .then(({ data: res }) => {
        setAppsList(res.data);
        dispatch(setDeveloperAppProp({prop: "paging", value: res.paging}))
        // setPaging(res.paging);
        setLoading(false);
      })
      .catch((err) => {
        setAppsList([]);
        throwServerError(err);
        setLoading(false);
      });
  };

  const handleOnChange = (page, srchStr) => {
    // setCurrentPage(page);
    dispatch(setDeveloperAppProp({prop: "currentPage", value: page}))
    getGatewayApps(page, srchStr);
  };
  const handleOnChangeDebounceFn = useCallback(
    debounceFn(handleOnChange, 1000),
    []
  );

  useEffect(() => {
    // console.log("runned");
    getGatewayApps();
  }, []);

  return (
    <div className="w-100vw bg-currentBackground">
      <CustomNavbar />

      <div id="app" className="my-60px container w-100 min-h-100vh">
        <div className="d-none d-md-flex align-items-center gap-4px mt-60px mb-47px pt-60px">
            <Link to="/my-account">
                <p className="fs-14px text-theme-primary fw-500 cursor">My Account / </p>
            </Link>
          <p className="fs-14px text-theme-primary fw-500">My Apps</p>
        </div>
        <div className="w-100 mt-32px d-flex flex-column gap-16px">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <p className="text-theme-content-color fw-600 fs-28px">My Apps</p>
            <div className="dfaic gap-8px">
              <div className="searchSection">
                <MagnifyingGlass color="#667085" size={15} />

                <input
                  type="text"
                  placeholder="Search Apps"
                  disabled={loading}
                  value={searchStr}
                  onChange={(evt) => {
                    // setSearchStr(evt.target.value);
                    dispatch(setDeveloperAppProp({prop: "searchStr", value: evt.target.value}));
                    handleOnChangeDebounceFn(1, evt.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center p-2px br-6px bg-theme-secondary-200">
                <div
                  className={`cursor px-12px py-4px ${
                    activeView === LayoutViews.LIST
                      ? "bg-theme-primary br-4px"
                      : ""
                  }`}
                  onClick={() => {
                    dispatch(setDeveloperAppProp({prop: "activeView", value: LayoutViews.LIST}))
                  }}
                >
                  <MonetizationIcons
                    type={"list-icon"}
                    fill={activeView === LayoutViews.LIST ? "white" : "#666666"}
                  />
                </div>
                <div
                  className={`cursor px-12px py-4px ${
                    activeView === LayoutViews.GRID
                      ? "bg-theme-primary br-4px"
                      : ""
                  }`}
                  onClick={() => {
                    dispatch(setDeveloperAppProp({prop: "activeView", value: LayoutViews.GRID}))
                  }}
                >
                  <MonetizationIcons
                    type={"group-icon"}
                    fill={activeView === LayoutViews.GRID ? "white" : "#666666"}
                  />
                </div>
              </div>
              {createApp ? (
                <div className="d-none d-md-flex align-items-center gap-4px">
                  <button
                    style={{ width: 186 }}
                    className="br-8px cursor bg-theme-primary p-10px d-flex align-items-center justify-content-center gap-10px border-none"
                    onClick={() => navigate("/monetization/create-app")}
                  >
                    <Plus
                      color={`${
                        theme === "theme--light" ? "white" : "#171C1B"
                      }`}
                      size={24}
                    />
                    <p className="fs-16px text-theme-base fw-600">Create App</p>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            {!loading ? (
              listApp && appsList && appsList.length > 0 ? (
                activeView === LayoutViews.GRID ? (
                  <div className="h-100 w-100 card-grid-wrapper gap-10px mt-32px">
                    {appsList.map((data) => {
                      return <MonetizeAppCard data={data} />;
                    })}
                  </div>
                ) : (
                  <div className="row px-12px py-8px mb-1">
                    <div className="col-md-3">
                      <p className="text-content-subtle fs-10px fw-500">Name</p>
                    </div>
                    <div className="col-md-2">
                      <p className="text-content-subtle fs-10px fw-500 pl-34px">
                        Status
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <p className="text-content-subtle fs-10px fw-500">
                        Usage ID
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="text-content-subtle text-center fs-10px fw-500">
                        Created By / Created At
                      </p>
                    </div>
                    <div className="col-md-2 dfaic"></div>
                    {appsList.map((data) => {
                      return <AppListRow data={data} allowDelete={deleteApp} parentCallback={getGatewayApps}/>;
                    })}
                  </div>
                )
              ) : (
                <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                  <EmptyState />
                  <p className="ps-5 text-content-color text-center">
                    No Apps Found
                  </p>
                </div>
              )
            ) : (
              <ContainerLoader
                variant="theme-primary"
                className="w-100 h-100 min-h-100vh"
              />
            )}
          </div>
          {appsList && appsList.length > 0 ? (
            <PaginationV2
              pagination={{
                ...paging,
                offset: currentPage,
                pageSize: paging.limit,
              }}
              cb={handlePageChange}
            />
          ) : null}
        </div>
      </div>

      {/* {appDetailsModal.isOpen && appDetailsModal.type === "apigee" && (
                <AppDetailsModal
                    onModalClose={() => {
                            setAppDetailsModal({
                            isOpen: false,
                            data: {},
                            type: "",
                        });
                    }}
                    appData={{...objectDeepClone(appDetailsModal.data || {})}}
                    statusChangeCallback={() => {}}
                />
            )}
            {appDetailsModal.isOpen && appDetailsModal.type === "azure" && (
                <AzureAppDetails
                    onModalClose={() => {
                        setAppDetailsModal({
                            isOpen: false,
                            data: {},
                            type: "",
                        });
                    }}
                    appData={appDetailsModal.data}
                    statusChangeCallback={() => {}}
                />
            )}
            {appDetailsModal.isOpen && appDetailsModal.type === "kong" && (
                <KongAppDetails
                    onModalClose={() => {
                        setAppDetailsModal({
                            isOpen: false,
                            data: {},
                            type: "",
                        });
                    }}
                    appData={appDetailsModal.data}
                    statusChangeCallback={() => {}}
                />
            )}
            {appDetailsModal.isOpen && [GATEWAYS.NGINX, 'aws'].includes(appDetailsModal.type.toLowerCase()) && (
                <GenericAppDetails
                    onModalClose={() => {
                        setAppDetailsModal({
                            isOpen: false,
                            data: {},
                            type: "",
                        });
                    }}
                    app={{...objectDeepClone(appDetailsModal.data || {}), type: appDetailsModal.type}}
                />
            )} */}
    </div>
  );
};

export default AllApps;
