import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker'
import { confirm, formatCtsDate, formatCtsDateWithoutUTC, formatCtsTime, formatCtsTimeWithoutUTC, isValidURL, objectDeepClone, slugify, throwServerError } from '../../../utils/helper';
import { ArrowLeft, ArrowUpRight, ArrowsLeftRight, FolderOpen, MapPin } from 'phosphor-react';
import defaultImage from './default.png'
import API from '../../../api';
import Loading from '../../../components/Loader/Loading';
import { getToast } from '../../../components/Toast';


export default function EventModal(props) {
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [time, setTime] = useState('')
    const [date, setDate] = useState('')
    const [link, setLink] = useState('')
    const [type, setType] = useState('Virtual')
    const [address, setAddress] = useState('')
    const [description, setDescription] = useState('')
    const [step, setStep] = useState(1)
    const [imageUrl, setImageUrl] = useState('')
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({})

    const handleNext = () => {
        let tempErrors = {}

        if (!name){
            tempErrors['name'] = 'Name is required'
        }
        if (!category){
            tempErrors['category'] = 'Category is required'
        }
        if (!date){
            tempErrors['date'] = 'Date is required'
        } 
        if (!time){
            tempErrors['time'] = 'Time is required'
        }
        if (!link){
            tempErrors['link'] = 'Link is required'
        } else if (!isValidURL(link)){
            tempErrors['link'] = 'Link is not valid URL'
        }
        if (!description){
            tempErrors['description'] = 'Description is required'
        }

        if (type === 'On Site' && !address){
            tempErrors['address'] = 'Address is required'
        }

        setErrors(tempErrors)

        if (Object.keys(tempErrors).length) return

        setStep(2)
    }

    function fileUpload(e){
        const file = e.target.files[0]

        const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
        if (fileSizeInMB <= 2) {
            setLoading(true)

            API.uploadImage(e.target.files[0])
            .then(res => {
                setImageUrl(res.file?.url || '')
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            }).catch(err => {
                setLoading(false)
                throwServerError(err)
            })
        } else {
            getToast({
                statusType: 'ERROR', message: 'File size is larger than the expected'
            })
        }
    }

    useEffect(() => {
        if (props.isEdit){

            let _data = objectDeepClone(props.data)

            setDate(new Date(props.data.date))
            setTime(new Date(props.data.date))
            setAddress(_data.address || '')
            setName(_data.name || '')
            setCategory(_data.category || '')
            setLink(_data.link || '')
            setType(_data.type || 'Virtual')
            setDescription(_data.description || '')
            setImageUrl(_data.bannerimage || '')
        }
    }, [props.data])
    

    const formateDateString = (date, time) => {

        let {year, month, day} = formatDate(date)
        let {hours, minutes, seconds} = formatTime(time)
        let isoString = new Date(year, month, day, hours, minutes, seconds).toISOString()

        return isoString
    }

    const handleSubmit = () => {
        if (!imageUrl) {
            getToast({
                statusType: 'ERROR', message: 'Banner Image is required'
            })
            return
        }


        const json = {
            bannerimage: imageUrl, category, date: formateDateString(date, time), description,
            image: imageUrl, location: type, name, nameslug: slugify(name), 
            summary: null, type: type, link
        }

        if (type === 'On Site'){
            json['address'] = address
        }

        if (props.isEdit){
            API.updateEvent(props.data._id, json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Event updated successfully'
                })
                props.callback()
                props.onHide()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.createEvent(json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Event created successfully'
                })
                props.callback()
                props.onHide()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    function formatDate(date) {
        // Extract the date components
        const year = date.getFullYear();
        const month = String(date.getMonth()).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        
        // Concatenate the components in the desired format
        return {year, month, day}
    }

    function formatTime(date) {
        // Extract the time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return {hours, minutes, seconds}
    }

    const handleDelete = () => {
        if (!confirm("Are you sure to delete this Event")) return

        API.deleteEvent(props.data._id)
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Event deleted successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="lg"
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'New'} Event</p>

                    <div className='d-flex align-items-center'>
                        {step === 2 && <div className='fw-500 me-2 fs-12px text-200 px-3 cursor' onClick={() => setStep(1)}>
                            <ArrowLeft size={12} color='#f1f1f1' />
                        </div>}

                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        {step === 1 ? <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleNext}>
                            Preview
                        </div> : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3' onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(80vh)" }}
                className="p-0 position-relative"
            >
                {step === 1
                ? <div className='w-80 h-100 overflow-scroll noscrollbar d-block mx-auto mb-5 pt-4' onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleNext()
                        e.preventDefault()
                    }
                }}>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300'>Title <span className='text-danger'>*</span></p>
                        <input type='text' className='form-control v1-form-control mt-1' value={name}
                            onChange={(e) => {
                                let _str = e.target.value
                                setName(_str)
                                setErrors({...errors, name: _str.trim().length > 0 ? false : 'Name is required'})
                            }} 
                            placeholder='Enter Event Title here ...' />
                        {errors.name && <p className='text-danger fs-10px mt-1'>
                            {errors.name}
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300'>Category <span className='text-danger'>*</span></p>
                        <input type='text' className='form-control v1-form-control mt-1' value={category}
                            onChange={(e) => {
                                let _str = e.target.value
                                setCategory(_str)
                                setErrors({...errors, category: _str.trim().length > 0 ? false : 'Category is required'})
                            }}
                            placeholder='Enter Event Category here ...' />
                        {errors.category && <p className='text-danger fs-10px mt-1'>
                            {errors.category}
                        </p>}
                    </div>

                    <div className='d-flex align-items-start justify-content-between mb-3 w-100'>
                        <div className='w-48'>
                            <p className='fw-400 fs-12px text-300 mb-1'>Date <span className='text-danger'>*</span></p>
                            <DatePicker minDate={new Date()} selected={date} 
                                dateFormat="yyyy-MM-dd"
                                onChange={(e) => {
                                    setDate(e)
                                    setErrors({...errors, date: false})
                                }} />
                            {errors.date && <p className='text-danger fs-10px mt-1'>
                                {errors.date}
                            </p>}
                        </div>

                        <div className='w-49 timePicker'>
                            <p className='fw-400 fs-12px text-300 mb-1'>Time <span className='text-danger'>*</span> (UTC) </p>
                            <DatePicker
                                selected={time}
                                onChange={(e) => {
                                    setTime(e)
                                    setErrors({...errors, time: false})
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                            {errors.time && <p className='text-danger fs-10px mt-1'>
                                {errors.time}
                            </p>}
                        </div>
                    </div>

                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300'>Link to Event <span className='text-danger'>*</span></p>
                        <input type='text' className='form-control v1-form-control mt-1' value={link}
                            onChange={(e) => {
                                let _str = e.target.value
                                setLink(_str)
                                setErrors({...errors, link: _str.trim().length > 0 ? false : 'Link is required'})
                            }}
                            placeholder='Enter link to the event here ...' />
                        {errors.link && <p className='text-danger fs-10px mt-1'>
                            {errors.link}
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='fs-12px fw-400 text-300'>Type of Event <span className='text-danger'>*</span></p>

                        <div className='d-flex align-items-center mt-1'>
                            <div className="form-check me-4">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="On Site" 
                                    checked={type === 'On Site'} onClick={() => setType('On Site')} />
                                <label className="form-check-label text-100 fs-14px fw-400" for="On Site">
                                    On-Site
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Virtual" 
                                    checked={type === 'Virtual'} onClick={() => setType('Virtual')} />
                                <label className="form-check-label text-100 fs-14px fw-400" for="Virtual">
                                    Virtual
                                </label>
                            </div>
                        </div>
                    </div>

                    {type === 'On Site' && <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300'>Address <span className='text-danger'>*</span></p>
                        <textarea type='text' className='form-control v1-form-textarea mt-1' value={address}
                            onChange={(e) => {
                                let _str = e.target.value
                                setAddress(_str)
                                setErrors({
                                    ...errors, address: _str.trim().length > 0 ? false : 'Address is required'
                                })
                            }} rows={4}
                            placeholder='Enter your address here ...' />
                        {errors.address && <p className='text-danger fs-10px mt-1'>
                            {errors.address}
                        </p>}
                    </div>}

                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300'>Description <span className='text-danger'>*</span></p>
                        <textarea type='text' className='form-control v1-form-textarea mt-1' value={description}
                            onChange={(e) => {
                                let _str = e.target.value
                                setDescription(_str)
                                setErrors({...errors, description: _str.trim().length > 0 ? false : 'Description is required'})
                            }} rows={4}
                            placeholder='Enter Event Description here ...' />
                        {errors.description && <p className='text-danger fs-10px mt-1'>
                            {errors.description}
                        </p>}
                    </div>

                    <div className='mb-4'>

                    </div>
                </div>
                : <div className='d-flex align-items-center w-100 h-100'>
                    <div className='surface-850 w-35 h-100 p-8px'>
                        <p className='text-100 fw-500 fs-12px mb-2'>Select Image</p>

                        <div className='surface-875 p-16px w-100 h-95'>
                            <div>
                                <input type={'file'} id={`bannerImage`} className='d-none' 
                                    onChange={(e) => fileUpload(e)} />
                                
                                <label htmlFor={`bannerImage`}
                                    className='cursor d-flex align-items-center flex-column justify-content-center surface-base w-100 border-dashed px-24px py-36px br-4px'>
                                        <p className='fs-14px fw-600 text-200 mb-1'>Browse a file to upload</p>
                                        <p className='fs-12px fw-400 text-400'>Max size: 2MB</p>

                                        {loading 
                                        ? <div className='mt-3'><Loading size='sm' /></div>
                                        : <div className='mt-2 d-flex align-items-center surface-875 px-16px py-6px v1-border-1 br-5px'>
                                            <FolderOpen size={16} color='#e4e4e4' className='me-2' />

                                            <p className='text-200 fs-14px fw-400'>Browse</p>
                                        </div>}
                                </label>

                                <p className='text-400 fs-12px fw-400 mt-2'>
                                    Ideal resolution of image is: 370 x 210 (i.e., 16:9 aspect ratio)
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='surface-base w-65 h-100 px-8px py-16px'>
                        <p className='fs-12px fw-500 text-100'>Preview</p>

                        <div className='d-flex align-items-center justify-content-center mt-48px'>
                            <div className='eventMainCard'>
                                <img src={imageUrl || defaultImage} alt='Banner thumbnail' />

                                <p className='mt-2 eventTimings'>
                                    {formatCtsDateWithoutUTC(date)} • {formatCtsTimeWithoutUTC(time)} UTC
                                </p>

                                <p className='mt-2 eventTitle text-truncate'>
                                    {name}
                                </p>

                                <p className='eventDescription'>{description}</p>

                                <div className='d-flex align-items-center mt-16px mb-16px'>
                                    <MapPin color='var(--content-color)' size={14} className='me-2' />

                                    <p className='eventLocation'>{address || 'Online'}</p>
                                </div>

                                <div className='eventMainButton cursor'>
                                    <p>View Event</p>
                                    <ArrowUpRight color='var(--primary-color)' className='ms-2' size={14} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {(props.isEdit && step === 1 && props.getDeletePermission) && <div className='btn fs-12px text-danger br-6px'
                    onClick={handleDelete}
                    style={{position: 'absolute', bottom: 10, left: 10, border: '1px solid #e5484d'}}>
                    Delete
                </div>}
            </Modal.Body>
        </Modal>
    )
}
