import { Browsers, CaretDown, Plus } from 'phosphor-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setHeaderMenu } from '../../../../app/Features/Layout/LayoutSlice'
import { getToast } from '../../../../components/Toast'
import { objectDeepClone, throwServerError, validateHeaderMenu } from '../../../../utils/helper'
import EachRow from './EachRow'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import API from '../../../../api'

export default function RenderHeader({isEdit, setIsEdit, setLoading, callback}) {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const data = currentLayoutObject.headerMenu || []
    const [dragStart, setDragStart] = useState(false)

    const dispatch = useDispatch()

    const addMainItem = () => {
        if (data.length > 5){
            getToast({
                statusType: 'ERROR', message: 'You can only add 6 header links'
            })
            return
        }
        let _overall = objectDeepClone(data || [])
        _overall.push({
            name: 'Title',
            url: '',
            urlType: '',
            submenu: [],
            visibleToRoles: '', 
            userGroup: '',
            isEdit: true
        })
        setIsEdit(true)
        dispatch(setHeaderMenu(_overall))
    }

    const handleDelete = (ind) => {
        setLoading(true)
        let _data = objectDeepClone(currentLayoutObject.headerMenu)
        if (_data.length === 1){
            setLoading(false)
            getToast({statusType: 'ERROR', message: 'Atleast one header value should be there'})
            return
        }

        if (ind < _data.length) {
            _data.splice(ind, 1)
        }
        dispatch(setHeaderMenu(objectDeepClone(_data)))
        setIsEdit(true)
        setTimeout(() => {
            setLoading(false)
        }, 100);
    }

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        setDragStart(false)

        var updatedList = objectDeepClone(data);
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        dispatch(setHeaderMenu(objectDeepClone(updatedList)))

        let {data: filteredData} = validateHeaderMenu(updatedList)

        API.putLayoutDetails({headerMenu: filteredData})
        .then(res => {
            setIsEdit(false)
            getToast({
                statusType: 'SUCCESS', message: 'Sequence updated successfully'
            })
            callback()
        }).catch(err => {
            throwServerError(err)
        })
    }
    
    return (
        <div className='h-100'>
            <div className='surface-base br-6px p-16px w-100 mb-3'>
                <div className='bg-currentBackground d-flex align-items-center justify-content-between py-12px px-24px'>
                    <div>
                        <img src={currentLayoutObject.logo || ''} alt='Logo' height={30} width={120}
                            style={{objectFit: 'contain'}} />
                    </div>

                    <div className='d-flex align-items-center gap-40px'>
                        {data.map((each, i) => <p key={i} className='fs-11px fw-400 text-content-color font-jost'
                            title='Preview wont have hover effects'>
                            {each.name || '-'} {each.submenu?.length > 0 && <CaretDown size={12} /> }
                        </p>)}

                        <div className='bg-primary-color px-12px py-8px br-10px'>
                            <p className='fs-11px text-base-100 fw-700'>Log In</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Header Content</p>
                </div>

                <div className='surface-850 px-12px py-8px d-flex align-items-center v1-border-1 btn br-5px'
                    onClick={() => addMainItem()}>
                    <p className='text-300 fs-12px fw-500'>Add Items</p>
                    <Plus size={12} className='ml-10px' color="#E4E4E4" />
                </div>
            </div>
            <DragDropContext onDragEnd={handleDrop}
                onDragStart={() => setDragStart(true)}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                    <div className='surface-base p-16px h-75 overflow-scroll noscrollbar'
                        {...provided.droppableProps} ref={provided.innerRef}>
                        { data.map((eachHeader, i) => (
                            <EachRow key={i} parentEdit={isEdit} parentSetEdit={setIsEdit} 
                                handleDelete={() => handleDelete(i)} headerIndex={i}
                                xDragStart={dragStart} />
                        ))}
                    </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}
