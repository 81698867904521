import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { CredentialAccordian } from "./CredentialAccordian";

export const AppDetailsModal = ({
  onModalClose,
  appData,
  statusChangeCallback,
}) => {
  console.log("appData: ", appData);

  const selectedCredentials = appData.type === "apigee" ?  appData?.credentials : appData?.key;
  const selectedApp = appData;

  console.log('selectedCredentials', selectedCredentials)
  
  return (
    <div
      className="product-details-modal__container"
      onClick={(evt) => {
        evt.stopPropagation();
        onModalClose();
      }}
    >
      <div
        className="product-details-modal-body"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-header">
          <div className="modal-title">Credentials</div>
          <div className="modal-action-btns__container">
            <button
              className="action-btn close-btn primary"
              onClick={onModalClose}
            >
              <GlobalIcons type={"close"} />
            </button>
          </div>
        </div>
        <div
          className="modal-content d-flex flex-column"
          style={{ gap: "6px" }}
        >
          {selectedCredentials &&
            selectedCredentials.map((credential) => (
              <CredentialAccordian
                credentials={credential}
                selectedApp={selectedApp}
                statusChangeCallback={statusChangeCallback}
                onModalClose={onModalClose}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
