import React, { useEffect, useState } from 'react'
import { COLOR_API_METHODS, ROUTE_NAMES } from '../../service/constants'
import CountDoughnutChart from '../../components/CountDoughnutChart'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';

export default function APIReferenceCard(props) {
    let {data} = props;

    const [a, setA] = useState(0)
    const [chartData, setChartData] = useState([]);

    function getChartData(item){
        let counts = {}
        if (!item?.resources?.length) return []
        let availResources = item?.resources?.map(x => x.method)
        availResources = [...new Set(availResources)]
        for(let res of availResources)
        {
          counts[res] = 0;
        }
        for(let res of item?.resources)
        {
          counts[res.method]++;
        }
        return {counts: Object.values(counts), resources: availResources}
    }

    function getChartBgData(item){
        let availResources = item?.resources?.map(x => x.method)
        availResources = [...new Set(availResources)]
        let colors = {}
        for(let res of availResources)
        {
            colors[res] = COLOR_API_METHODS[res];
        }
  
        return Object.values(colors)
    }

    const setCounts = (counts) => {
        let _counts = counts && counts.length ? counts.reduce((acc,curr) => acc+curr, 0) : 1;
        let _str = `${_counts}`
        let a = 52;
        let offset = Math.ceil(_str.length / 2);
        setA(a+((a*offset)*0.01));
    }

    function cardViewDetails(_data){
        let list = []
        const {counts, resources} = getChartData(_data)
        setCounts(counts);
        let _chartBgData = getChartBgData(_data)
        counts?.forEach((each, index) => {
            list.push({
                name: resources[index],
                value: each,
                color: {start: _chartBgData[index], end: _chartBgData[index]}
            })
        })
        setChartData(list)
    }

    const navigate = useNavigate()

    useEffect(() => {
        cardViewDetails(data)
    }, [data])

    return (
        <div className={`apiReferenceCard cursor ${data.swaggerVersion === '3.0' ? 'v3' : 'v3'} d-flex justify-content-between flex-column`}
            onClick={() => navigate(`/${ROUTE_NAMES.API_REFERENCE}/${data.swaggerVersion}/${data.swaggerId}/${data.revision}`) /*{
                let resources = [{path: '/bank-account/deposit-amount', method: 'put'}, {path: '/bank-account/withdraw-amount', method: 'put'}, {path: '/primary-account/primary-account', method: 'GET'}]
                let filterParams = constructFilterResources({resources})
                let url = `/${ROUTE_NAMES.API_REFERENCE}/${data.swaggerVersion}/${data.swaggerId}/${data.revision}`
                if(filterParams) url+=`?${data.swaggerId}=${filterParams}`
                
                navigate(url)
            }*/}>
            <div>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <div className={data.swaggerVersion === '3.0' ? 'reference-list-v3' : 'reference-list-v2'}>
                        OAS {data.swaggerVersion}
                    </div>

                    <p className='text-content-subtle fs-14px fw-400'>{data.versionName}</p>
                </div>

                <p className='fs-16px fw-600 text-content-color text-truncate'>{data.title}</p>
            </div>

            <div className='d-flex align-items-end justify-content-between w-100 mt-2' style={{height: 56}}>
                <p className='text-truncate-3 fw-400 fs-14px text-content-subtle w-70' title={data.swaggerDescription || ""}>
                    {data.swaggerDescription || ''}
                </p>

                {chartData?.length ? <div style={{width: a, height: a}}>
                    <CountDoughnutChart id={uuidv4()} updateChart={true} 
                        isCatalog={true} cutout={99}
                        series={chartData}
                    />
                </div> : null}
            </div>
        </div>
    )
}
