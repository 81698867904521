import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOutletContext, useParams } from 'react-router'
import { BodyLayout } from '../../../layout/Base'
import API from '../../../api';
import { objectDeepClone, throwServerError } from '../../../utils/helper';
import { PencilSimple } from 'phosphor-react';
import EditorJS from "../../../components/EditorJS";
import EditorJSOutput from "../../../components/EditorJSOutput";

export default function EachUseCase() {
    const { id } = useParams();
    const {firstRoute} = useSelector(state => state.user)
    const {setNavigation, setTitle, setHasHeader} = useOutletContext()
    const [data, setData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const editorInstance = useRef(null);

    useEffect(() => {
        setTitle("Edit Usecase")
        setNavigation([
          {link: firstRoute, name: 'Dashboard'},
          {link: `/admin/usecases`, name: 'All Usecases'},
          {name: 'Edit Usecase'}
        ])
        setHasHeader(true)

        getCurrentUsecase()
    }, [])

    const getCurrentUsecase = () => {
        API.getEachProduct(id).then(res => {
            setData(res.data?.data || {})
        }).catch(err => {
            throwServerError(err)
        })
    }

    const onCancel = () => {
        setIsEdit(false);
    };

    const setEditorInstance = (core) => {
        editorInstance.current = core;
    };

    const onSave = async () => {
        if (editorInstance.current) {
            try {
                let doc = await editorInstance.current.save();
                let json = {}
                json['content'] = objectDeepClone(doc)
                API.updateProduct(id, json).then(res => {
                    getCurrentUsecase()
                    setIsEdit(false)
                }).catch(err => {
                    throwServerError(err)
                })
            }
            catch (err) {
                console.log('err', err)
            }
        }
    }

    return (
        <BodyLayout>
            <div className="fadeIn w-100 h-100 surface-900 br-tl-6px br-tr-6px">
                <div className='surface-875 d-flex align-items-center justify-content-between px-12px py-10px br-tl-6px br-tr-6px'>
                    <p className='ms-2 fs-16px fw-600 text-200'>Edit Usecase contents</p>

                    {!isEdit ? (
                        <div className="d-flex btn-md-850 h-32px gap-8px px-8px"
                            onClick={() => setIsEdit(true)}>
                            <div style={{ marginTop: -3 }}>
                                <PencilSimple size={14} />
                            </div>
                            <div className="fs-12px fw-500">Edit</div>
                        </div>
                    ) : (
                        <div className="d-flex">
                            <div onClick={onCancel} className="text-btn-md fs-12px">
                                Cancel
                            </div>
                            <div onClick={onSave} className="btn-md-850 h-32px fs-12px">
                                Save
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className={`${ isEdit ? "" : "surface-875" } h--52px px-40px py-16px 
                    overflow-scroll-y noscrollbar`}>
                    {isEdit ? (
                        <EditorJS data={data.content} setEditorInstance={setEditorInstance} />
                    ) : (
                        <EditorJSOutput data={data.content} />
                    )}
                </div>
            </div>
        </BodyLayout>
    )
}
