import { useEffect, useState, useCallback } from 'react'
import { BodyLayout } from '../../../layout/Base'
import { useOutletContext } from 'react-router';
import { useSelector } from 'react-redux';
import { Plus } from 'phosphor-react';
import Loading from '../../../components/Loader/Loading';
import UserIcons from '../../../components/Icons/UserIcons';
import Searchbar from '../../../components/Searchbar';
import { GATEWAYS, STATUS } from '../../../service/constants';
import { Tab, Tabs } from 'react-bootstrap';
import ProductBody from './ProductBody';
import AddProductModal from './AddProductModal';
import monetizationAPI from '../../../api/monetization'
import { debounceFn, getPermissionByCrud, showSuccessMessage, throwServerError } from '../../../utils/helper';
import { objectDeepClone } from '@apiwiz/oas/services';
import API from '../../../api';
import GUIDE_API from '../../../api/guides';



const TABS = {
    APIGEE: "Apigee",
    KONG: "Kong",
    AZURE: "Azure",
    NGINX: "Nginx",
    AWS: GATEWAYS.AWS.toUpperCase(),
    AXWAY:"Axway"
};

export default function MyProducts() {
    const { setNavigation, setTitle, setHasHeader } = useOutletContext();
    const { firstRoute, permission } = useSelector((state) => state.user);
    const [ dataLoading, setDataLoading] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [activeTab, setActivetab] = useState(GATEWAYS.APIGEE);
    const [productModal, setProductModal] = useState({
        isOpen: false, isEdit: false, data: {}
    })
    const [products, setProducts] = useState({
        apigee: [],
        aws: [],
        azure: [],
        kong: [],
        nginx: [],
        axway: []
    })
    const [pagination, setPagination] = useState({prev: 0, next: 1, limit: 10, total: 10, page: 1})
    const {currentUser} = useSelector(state => state.user)
    const [catalogs, setCatalogs] = useState([])
    const [guides, setGuides] = useState([])

    const [orgs, setOrgs] = useState([])
    const [runtimes, setRuntimes] = useState([])
    const [resourceGrouop, setResourceGrouop] = useState([])
    const [nginXRuntimes, setNginXRuntimes] = useState([])
    const [awsRuntimes, setAwsRuntimes] = useState([])
    const [axwayRuntimes,setAxwayRuntimes] = useState([])

    const labelize = (value) => {
        if (value === GATEWAYS.APIGEE) return "Apigee"
        if (value === GATEWAYS.KONG) return "Kong"
        if (value === GATEWAYS.AZURE) return "Azure"
        if (value === GATEWAYS.AWS) return "AWS"
        if (value === GATEWAYS.NGINX) return "Nginx"
        if (value === GATEWAYS.AXWAY) return "Axway"
    }

    const getGuides = () => {
        GUIDE_API.getGuides(
            {
              currentPage: 1,
              status: STATUS.published,
              pageLimit: 50
            }
        ).then(({data: res}) => {
            console.log('res', res)
            setGuides(objectDeepClone(res))
        }).catch(err => {
            setGuides([])
        })
    }

    const getSwaggers = (_partnerNames) => {
        let params = {}
        params.partnerNames = _partnerNames.join(",")
        API.getPublishedSwaggers({}, {...params})
        .then((res) => {
            let arr = [];
            let _data = objectDeepClone(res.data?.data || []);
            for (let a of _data) {
                let obj = {};
                let item = a.items[0];
                obj.swaggerName = a.title || item.title;
                obj.swaggerId = item.swaggerId;
                obj.swaggerDescription = item.swaggerDescription;
                obj.swaggerVersion = item.swaggerVersion;

                let version = item.versions[0];
                let url = version.url;
                let urlObj = url.split("/");
                obj.resources = version.resources?.map(o => ({...o, name: o.operationId, description: o.summary}));
                obj.url = url;
                obj.versionName = version.name;
                obj.revision = urlObj[urlObj.length - 1];
                arr.push(obj);
            }
            let _sourceCatalogList = objectDeepClone(arr);
            _sourceCatalogList = _sourceCatalogList.sort((a, b) => {
                const _a = a.title ? a.title.toUpperCase().trim() : "";
                const _b = b.title ? b.title.toUpperCase().trim() : "";
                if (_a < _b) return -1;
                if (_a > _b) return 1;
                return 0;
            });
            console.log('_sourceCatalogList', _sourceCatalogList)
            setCatalogs(_sourceCatalogList);
        })
        .catch((err) => {
            throwServerError(err);
        });
    };

    const getOrgs = () => {
        API.getOrgs()
            .then(({data: res}) => {
                setOrgs(res.data)
            })
            .catch((err) => {
                throwServerError(err)
            });
    };
    const getRuntimes = () => {
        API.getRuntimes()
          .then((res) => {
            setRuntimes(res?.data?.data || []);
          })
          .catch((err) => {
            console.log(err);
          });
    };
    
    const getResourceGroups = () => {
        API.getResourceGroups()
          .then((res) => {
            setResourceGrouop(res?.data?.data || []);
          })
          .catch((err) => {
            console.log(err);
          });
    };
    
    const getNginxRuntimes = () => {
        API.getNginxRuntimes()
          .then((res) => {
            setNginXRuntimes(res?.data?.data || []);
          })
          .catch((err) => {
            console.log("nginx runtime err:", err);
          });
    };
    
    const getAwsRuntimes = () => {
        API.getAwsRuntimes()
          .then((res) => {
            setAwsRuntimes(res?.data?.data || []);
          })
          .catch((err) => {
            console.log("AWS runtime err:", err);
          });
    };

    const getAxwayRuntimes = () => {
        API.getAxwayRuntimes()
          .then((res) => {
            setAxwayRuntimes(res?.data?.data || []);
          })
          .catch((err) => {
            console.log("Axway runtime err:", err);
          });
    };

    const getAllProducts = (params = {}) => {
        setDataLoading(true);
        monetizationAPI.getProductDetails(params)
        .then(({data: res}) => {
            setDataLoading(false)

            setPagination(res.paging)
            setProducts((prev) => ({
                ...prev,
                [params.gatewayType]: res.data
            }))
        }).catch(err => {
            setDataLoading(false)
            setProducts({
                apigee: [],
                aws: [],
                azure: [],
                kong: [],
                nginx: []
            })
        })
      };

    const handleOnChange = (gatewayType, page, productName) => {
        getAllProducts({gatewayType, page, productName})
    };

    const handleOnChangeDebounceFn = useCallback(debounceFn(handleOnChange, 1000), []);

    const handleProductSync = () => {
        setDataLoading(true);
        monetizationAPI.syncProductDetails(activeTab.toLowerCase())
        .then((res) => {
            setDataLoading(false);
            showSuccessMessage(`${labelize(activeTab)} products synced successfully`)
            getAllProducts({gatewayType: activeTab.toLowerCase()})
        })
        .catch((err) => {
            setDataLoading(false);
            throwServerError(err)
        })
    }

    const handleTabOnChange = (tab) => {
        setActivetab(tab)
        setSearchVal("")
        setPagination("")
        getAllProducts({gatewayType: tab.toLowerCase(), page: 1})
    }

    /** ACL */
    let sync = permission.filter(o => o.name === 'sync-product')
    sync = sync.length ? (sync[0].crud || []) : []
    const syncProduct = getPermissionByCrud(currentUser, sync,"create");
    /** ACL */

    useEffect(() => {
        setTitle("Manage Products");
        setHasHeader(true);
        setNavigation([
          { link: firstRoute, name: "Dashboard" },
          { name: "Manage Products" },
        ]);
        getAllProducts({gatewayType: activeTab.toLowerCase(), page: 1})
        getSwaggers(currentUser.userGroups || [])
        getGuides()
        getOrgs()
        getRuntimes()
        getResourceGroups()
        getAwsRuntimes()
        getNginxRuntimes()
        getAxwayRuntimes()
    }, []);

    return (
        <BodyLayout>
            <div className="fadeIn surface-900 w-100 gateway-details position-relative">
                <div className="surface-875 py-8px px-12px d-flex justify-content-between align-items-center v1-border-bottom-05">
                    <div>
                        <p className="fs-18px fw-600">Manage Products</p>
                    </div>
                    <div className="d-flex align-items-center gap-12px">
                        <div style={{width: 280}}>
                            <Searchbar value={searchVal} placeholder={"Search by Product Name"} onChange={(searchTxt)=>{
                                setSearchVal(searchTxt)
                                handleOnChangeDebounceFn(activeTab.toLowerCase(), 1, searchTxt)
                            }} />
                        </div>

                        <div className="btn v1-btn-primary p-2 d-none"
                            onClick={() => setProductModal({isOpen: true, isEdit: false, data: {}})}>
                            <Plus size={14} color='white' />
                            <p className="text-200 fs-13px ps-1">Add Product</p>
                        </div>

                        {syncProduct ? (
                            <div
                                className="d-none btn v1-btn-primary px-4 py-2"
                                onClick={handleProductSync}
                            >
                                <UserIcons type={"sync"} />
                                <p className="text-200 ps-2">Sync</p>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="p-0px">
                    {dataLoading ? (
                        <div className="overflow-scroll noscrollbar" style={{ height: "calc(100vh - 57px - 46px - 31px)"}}>
                            <Loading />
                        </div>
                    ) : (
                        <div className="tabs-container w-100 custom-tab-container position-relative">
                            <Tabs
                                activeKey={activeTab}
                                id="gateway-select-tabs"
                                onSelect={handleTabOnChange}
                                onChange={(e) => console.log("e", e)}
                            >
                                {orgs?.length ? (
                                    <Tab
                                        tabClassName="tabs-btn-2"
                                        className="p-12px py-2"
                                        title={`${TABS.APIGEE}`}
                                        eventKey={GATEWAYS.APIGEE}
                                    >
                                        <ProductBody data={products.apigee} getData={(params)=>getAllProducts({...params, gatewayType: activeTab.toLowerCase(), productName: searchVal})}
                                        pagination={pagination} handleEdit={(data) => setProductModal({isEdit: true, data: objectDeepClone(data), isOpen: true})} />
                                    </Tab>
                                ) : null}

                                {runtimes?.length ? (
                                    <Tab
                                        tabClassName="tabs-btn-2"
                                        className="p-12px py-2"
                                        title={`${TABS.KONG}`}
                                        eventKey={GATEWAYS.KONG}
                                    >
                                        <ProductBody data={products.kong} getData={(params)=>getAllProducts({...params, gatewayType: activeTab.toLowerCase(), productName: searchVal})} 
                                        pagination={pagination} handleEdit={(data) => setProductModal({isEdit: true, data: objectDeepClone(data), isOpen: true})} />
                                    </Tab>
                                ) : null}

                                {resourceGrouop?.length ? (
                                    <Tab
                                        tabClassName="tabs-btn-2"
                                        className="p-12px py-2"
                                        title={`${TABS.AZURE}`}
                                        eventKey={GATEWAYS.AZURE}
                                    >
                                        <ProductBody data={products.azure} getData={(params)=>getAllProducts({...params, gatewayType: activeTab.toLowerCase(), productName: searchVal})}
                                        pagination={pagination} handleEdit={(data) => setProductModal({isEdit: true, data: objectDeepClone(data), isOpen: true})} />
                                    </Tab>
                                ) : null}

                                {nginXRuntimes?.length ? (
                                    <Tab
                                        tabClassName="tabs-btn-2"
                                        className="p-12px py-2"
                                        title={`${TABS.NGINX}`}
                                        eventKey={GATEWAYS.NGINX}
                                    >
                                        <ProductBody data={products.nginx} getData={(params)=>getAllProducts({...params, gatewayType: activeTab.toLowerCase(), productName: searchVal})}
                                        pagination={pagination} handleEdit={(data) => setProductModal({isEdit: true, data: objectDeepClone(data), isOpen: true})} />
                                    </Tab>
                                ) : null}

                                {awsRuntimes?.length ? (
                                    <Tab
                                        tabClassName="tabs-btn-2"
                                        className="p-12px py-2"
                                        title={`${TABS.AWS}`}
                                        eventKey={GATEWAYS.AWS}
                                    >
                                        <ProductBody searchVal={searchVal} data={products.aws} getData={(params)=>getAllProducts({...params, gatewayType: activeTab.toLowerCase(), productName: searchVal})}
                                        pagination={pagination} handleEdit={(data) => setProductModal({isEdit: true, data: objectDeepClone(data), isOpen: true})} />
                                    </Tab>
                                ) : null}

                                {axwayRuntimes?.length ? (
                                    <Tab
                                        tabClassName="tabs-btn-2"
                                        className="p-12px py-2"
                                        title={`${TABS.AXWAY}`}
                                        eventKey={GATEWAYS.AXWAY}
                                    >
                                        <ProductBody searchVal={searchVal} data={products.axway} getData={(params)=>getAllProducts({...params, gatewayType: activeTab.toLowerCase(), productName: searchVal, expand: true})}
                                        pagination={pagination} handleEdit={(data) => setProductModal({isEdit: true, data: objectDeepClone(data), isOpen: true})} />
                                    </Tab>
                                ) : null}
                            </Tabs>
                        </div>
                    )}
                </div>

                {productModal.isOpen && <AddProductModal show={productModal.isOpen} callback={() => getAllProducts({ gatewayType: activeTab.toLowerCase(), page: pagination.page, productName: searchVal })}
                    isEdit={productModal.isEdit} data={productModal.data} catalogs={objectDeepClone(catalogs)}
                    onHide={() => setProductModal({isOpen: false, isEdit: false, data: {}})} guides={guides} />}
            </div>
        </BodyLayout>
    )
}
