import React from "react";
import { useSelector, useDispatch } from "react-redux";
import BodyParameter from "./BodyParameter";
import Parameters from "./Parameters";

const RequestParameterList = () => {
  const { selectedOperation } = useSelector((state) => state.swagger);
  return (
    <div className="w-100 d-flex flex-column gap-8px mb-16px">
      {selectedOperation.headerParams &&
        selectedOperation.headerParams.length > 0 && (
          <Parameters
            title="Header Parameters"
            isResponse={false}
            parameter={selectedOperation.headerParams}
          />
        )}
      {selectedOperation.queryParams &&
        selectedOperation.queryParams.length > 0 && (
          <Parameters
            title="Query Parameters"
            isResponse={false}
            parameter={selectedOperation.queryParams}
          />
        )}
      {selectedOperation.pathParams &&
        selectedOperation.pathParams.length > 0 && (
          <Parameters
            title="Path Parameters"
            isResponse={false}
            parameter={selectedOperation.pathParams}
          />
        )}
      {selectedOperation.cookieParams &&
        selectedOperation.cookieParams.length > 0 && (
          <Parameters
            title="Cookie Parameters"
            isResponse={false}
            parameter={selectedOperation.cookieParams}
          />
        )}
      {selectedOperation.formParams &&
        selectedOperation.formParams.length > 0 && (
          <Parameters
            title="Form Parameters"
            isResponse={false}
            parameter={selectedOperation.formParams}
          />
        )}
      {selectedOperation &&
        selectedOperation.requestBody &&
        selectedOperation.requestBody.content && (
          <BodyParameter content={selectedOperation.requestBody.content} />
        )}
    </div>
  );
};

export default RequestParameterList;
