import { configureStore } from "@reduxjs/toolkit";
import AppSlice from "./Features/App/AppSlice";
import LayoutSlice from "./Features/Layout/LayoutSlice";
import UserSlice from "./Features/Users/UserSlice";
import SettingSlice from "./Features/Settings/SettingSlice";
import SwaggerSlice from "./Features/Swagger/SwaggerSlice";
import GuideSlice from "./Features/Guide/GuideSlice";
import DeveloperAppSlice from "./Features/DeveloperApp/DeveloperAppSlice";

export const store = configureStore({
    reducer: {
      app: AppSlice,
      user: UserSlice,
      layout: LayoutSlice,
      setting: SettingSlice,
      swagger: SwaggerSlice,
      guide: GuideSlice,
      developerApp: DeveloperAppSlice
    }
});