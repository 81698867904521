import { useState,useEffect } from "react";
import GUIDE_CATEGORY_API from "../../../api/guideCategory";
import { objectDeepClone } from "../../../utils/helper";
import { Modal } from "react-bootstrap";
import { throwServerError,slugify,showSuccessMessage,confirm,formatCtsTime,formatCtsDate } from "../../../utils/helper";
import AddScenarioBtn from "../../../components/AddScenarioBtn";
import Loading from "../../../components/Loader/Loading";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { getToast } from "../../../components/Toast";
import API from "../../../api";

export default function TagsModal(props){
    const [isLoading, setIsLoading] = useState(false)
    const [backupData, setBackupData] = useState([])
    const [tags,setTags] = useState([])
    const [isAdd,setIsAdd] = useState(false)

    // const getCategoryList = () => {
	// 	setIsLoading(true);
	// 	GUIDE_CATEGORY_API.getAllGuideCategory()
	// 		.then((response) => {
	// 			setIsLoading(false);
	// 			if (response.data.data) {
    //                 let _res = objectDeepClone(response.data.data)
	// 				setData(_res.map(o => ({...o, isEdit: false})));
    //                 setBackupData(objectDeepClone(response.data.data || []))
	// 				setPaging(response.data.paging);
	// 			} else {
    //                 setData([])
    //                 setBackupData([])
    //                 setPaging({})
    //             }
	// 		})
	// 		.catch((error) => {
    //             setIsLoading(false);
    //             throwServerError(error)
    //             setData([])
    //             setBackupData([])
    //             setPaging({})
    //         });
	// };

    const handleServerSideError = (error) => {
        if (error.response) {
            if (Object.keys(error.response.data.errors).length > 0) {
                
            } else {
                getToast({statusType: 'ERROR', message: error.response.data.message})
            }
        } else {
            getToast({statusType: 'ERROR', message: error.message})
        }
    }

    const getTagsList = () => {
        setIsLoading(true)
		API.getAllTags(1)
			.then((response) => {
				if (response.data.data) {
					setTags(
						response.data.data.map((tag) => {
							return { value: tag.name, label: tag.name,updatedAt:tag.updatedAt,_id:tag._id };
						})
					);
                    setBackupData(
                        response.data.data.map((tag) => {
							return { value: tag.name, label: tag.name,updatedAt:tag.updatedAt,_id:tag._id};
						})
                    )
                    setIsLoading(false)
				}
			})
			.catch((error) => {
                setIsLoading(false)
            // handleServerSideError(error)
            }
            );
	};

    useEffect(() => {
        // getCategoryList();
        getTagsList();
	}, []);

    const changeEditStatus = (_id) => {
        let _data = objectDeepClone(tags)
        _data[_id].isEdit = true 
        setTags(objectDeepClone(_data))
    }

    const resetData = (_id) => {
        let _data = objectDeepClone(tags)
        let _mainData = objectDeepClone(backupData)

        if (_id < _mainData.length){
            _data[_id] = _mainData[_id]
            _data[_id].isEdit = false
        } else {
            _data.splice(_id, 1)
        }
        setTags(objectDeepClone(_data))
    }

    const handleChange = (_val, _id) => {
        let _data = objectDeepClone(tags)
        _data[_id].value = _val 
        setTags(objectDeepClone(_data))
    }

    const handleSave = (_id) => {
        let _data = objectDeepClone(tags)
        let _obj = _data[_id]
        let form = {
            name: _obj.value
        }
        API.addTags(form)
        .then(response => {
            setIsAdd(false)
            getTagsList()
            showSuccessMessage("Tag created successfully.")
        })
        .catch(error => handleServerSideError(error))

    }

    function handleEditSave(_id){
        let _data = objectDeepClone(tags)
        let _obj = _data[_id]
        let id = _data[_id]._id
        let form = {
            name: _obj.value
        }
        API.updateTags(id, form)
            .then(response => {
                getTagsList()
                getToast({
                    statusType: 'SUCCESS',
                    message: 'Tag successfully updated  '
                })
            })
            .catch(error => handleServerSideError(error))
    }

    const handleAddCategory = () => {
        setIsAdd(true)
        let _data = objectDeepClone(tags)
        _data.push({
            value:'',
            label:'',
            isEdit: true
        })
        setTags(objectDeepClone(_data))
    }

    const handleDelete = (index) => {
        const id = tags[index]?._id;
        const msg = 'Are you sure you want to delete?'
        if(confirm(msg)){
            API.deleteTag(id)
            .then(response => {
                getToast({
                    statusType: 'SUCCESS',
                    message: 'Tag successfully updated  '
                })
                getTagsList()
            })
            .catch(error => 
                handleServerSideError(error)
            )
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="lg"
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='px-2 py-16px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Manage Tags</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='surface-875 p-8px'>
                    <div className='row mb-2 surface-900 px-12px py-6px mx-0'>
                        <div className='col-md-6 px-0'>
                            <p className='text-400 fs-10px fw-500'>Name</p>
                        </div>
                        <div className='col-md-6 px-0'>
                            <p className='text-400 fs-10px fw-500'>Updated At</p>
                        </div>
                    </div>
                    {props.getTagCreatePermission && 
                        <AddScenarioBtn type={'Tag'} className='w-100' onClick={handleAddCategory} />}

                    <div className='overflow-scroll noscrollbar h-60vh mt-2'>
                        { isLoading 
                        ? <Loading />
                        : tags.map((each, i) => <>
                            {each.isEdit
                            ? <div className='row surface-850 p-12px v1-border-bottom-2'>
                                <div className='col-md-5'>
                                    <p className='text-300 fs-10px fw-400'>Name <span className='text-danger'>*</span> </p>
                                    <input type={'text'} className='form-control v1-form-control mt-2px'
                                        value={each.value} onChange={(e) => handleChange(e.target.value, i)}   onKeyDown={(e) => {
                                            setIsCtrl(['Control', 'Meta'].includes(e.key))
                                            if (isCtrl && e.key?.toLowerCase() === 's') {
                                                each._id ? handleEditSave(i) : handleSave(i)
                                                e.preventDefault()
                                            }
                                        }} />
                                </div>
                                <div className='col-md-5'>
                                </div>
                                {/* <div className='col-md-1'></div> */}
                                <div className='col-md-2 my-auto ps-4'>
                                    <div className='d-flex align-items-center justify-content-center align-items-center mt-14px'>
                                        <div className='v1-border-05 br-4px px-9px mx-6px pt-3px pb-6px cursor'
                                            onClick={() => each._id ? handleEditSave(i) : handleSave(i)}>
                                            <GlobalIcons type={'tick'} />
                                        </div>
                                        <div className='v1-border-05 br-4px px-9px mx-6px pt-3px pb-6px cursor'
                                            onClick={() => resetData(i)}>
                                            <GlobalIcons type={'cross'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className='row mx-0 p-12px surface-900 v1-border-bottom-2' key={i}>
                                
                                <div className='col-md-5 px-0 my-auto'>
                                    <p className='text-300 fs-14px fw-500 text-truncate'>
                                        {each.label || ''}
                                    </p>
                                </div>
                                {/* <div className='col-md-4 px-0 my-auto'>
                                    <p className='text-300 fs-14px fw-500'>{each.categoryslug || '-'}</p>
                                </div> */}
                                <div className='col-md-5 px-0 d-flex align-items-center'>
                                    <p className='text-400 fs-11px fw-400'>{formatCtsTime(each?.updatedAt)} - {formatCtsDate(each?.updatedAt)} (UTC)</p>
                                </div>
                                <div className='col-md-2 px-0'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {props.getTagUpdatePermission && <div className='btn-sm-v1 v1-border-2 me-3'
                                            onClick={() => changeEditStatus(i)}>
                                            <GlobalIcons type={'pencil-v2'} />
                                        </div>}

                                        {props.getTagDeletePermission && <div className='btn-sm-v1 v1-border-2'
                                            onClick={() => handleDelete(i)}>
                                            <GlobalIcons type={'trash'} />
                                        </div>}
                                    </div>
                                </div>
                            </div>}
                        </>)}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}