import React, { useEffect, useState } from "react";
import JsonTree from "./JsonTree";

import { getContentTypeFormat, getFormattedExample } from "@apiwiz/oas/helper";
import {
  getFormattedJsonString,
  getUuidV4,
  objectDeepClone,
  sleep,
} from "@apiwiz/oas/services";
import Editor from "@monaco-editor/react";
import { useTheme } from "../../../context/ThemeContext";
import { extractExample } from "@apiwiz/oas/utils";
import { useSelector } from "react-redux";
import { monocoEditorOption } from "../../../service/constants";

const _tCopyId = getUuidV4();

const Example = ({ schema, contentType, sample, dataType }) => {
  const [showXmlError, setShowXmlError] = useState(false);
  const [data, setData] = useState("");
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const { swagger } = useSelector((state) => state.swagger);

  const xmlHeader = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>';

  const format = getContentTypeFormat(contentType);

  const { theme } = useTheme();

  const getData = () => {
    setShowXmlError(false);
    let sampl = sample;
    if (sampl) return sampl;
    //return value when schema is not passed and sample value is empty || null
    //example on null will fallback to type by default
    if (!schema) return "";
    if (schema.__sample) sampl = schema.__sample;
    if (!sampl)
      sampl = extractExample({ swagger, value: schema, type: format });
    if (!sampl) return "";
    let example = getFormattedExample(sampl, format);
    if (example.constructor === Object && example.__error)
      return example.__error.__message;
    return example;
  };

  useEffect(() => {
    setData(getData());
  }, [format]);

  const copyToClipboard = async () => {
    if (isCopySuccess) return;
    setIsCopySuccess(true);
    let text = objectDeepClone(data);
    if (format === "json") {
      text = getFormattedJsonString(text);
    }
    navigator.clipboard.writeText(text);
    await sleep(1000);
    setIsCopySuccess(false);
  };

  return (
    <div className="pt-8px param-body-example">
      <Editor
        width="100%"
        height="300px"
        language={format}
        theme={theme === "theme--dark" ? "vs-dark" : "vs-light"}
        options={{
          ...monocoEditorOption,
          readOnly: true,
        }}
        value={
          format === "json" && data && typeof data === "object"
            ? getFormattedJsonString(data)
            : data
        }
      />
    </div>
  );
};

export default Example;
