import {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import GlobalIcons from '../../Icons/GlobalIcons';
import API from '../../../api';
import moment from 'moment';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useSelector } from 'react-redux';
import { useTheme } from '../../../context/ThemeContext';
import { Button, Form, Row } from 'react-bootstrap';
import ContainerLoader from '../../Loader/ContainerLoader';
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { MdClose } from 'react-icons/md';
import ReactMarkReadown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { createTwoFilesPatch } from "diff";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";

const DiffViewer = (props) => {
    const { oldCode, newCode } = props;
    const diffString = createTwoFilesPatch(
      "old.js",
      "new.js",
      oldCode || "",
      newCode || ""
    );
  
  
    const diffHtml = Diff2Html.html(diffString, {
      outputFormat: "side-by-side",
      inputFormat: "json",
      diffStyle: "word",
      matching: "lines",
      drawFileList: false,
      renderNothingWhenEmpty: false
    });
  
    return <div dangerouslySetInnerHTML={{ __html: diffHtml }} />;
  };
  

export const ChangelogDetailLog = (props) => {
    const { loading, logDetails, ..._props } = props;
    const [notes, setnotes] = useState("");
  
    const handleClose = () => {
      _props.onHide();
    };
  
    console.log("log details inside modal: ", logDetails);
  
    useEffect(() => {
      if (logDetails) {
        setnotes(logDetails.notes);
      }
    }, [logDetails]);
  
    return (
      <Modal
        {..._props}
        centered
        size="lg me-0 mt-0 mb-0"
        className="custom-modal-v1 v1--styles"
        contentClassName='br-0px homeModalForLghtMode'
        aria-labelledby="contained-modal-title-vcenter overflow-hidden noscrollbar"
      >
        <Modal.Header className="w-100 py-8 pl-8 pr-8 surface-850 important br-0">
          <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
            {!loading && (
                <p className="mb-0 text-content-color fw-500 fs-16px">
                  {logDetails?.swaggerName}
                  {/* <span className="ms-4 badge badge-sm mr-3 badge-warning">
                    Revision
                  </span> */}
                </p>
              )}
              <div className="btn-overlay px-2 cursor" onClick={handleClose}>
                <MdClose size={14} />
              </div>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{ height: "calc(100vh - 63px)" }}
          className="br-0 position-relative py-0"
        >
          {loading && <ContainerLoader />}
          {!loading && (
            <div className="h-100 overflow-scroll noscrollbar p-0">
              <div
                style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
                class="p-4 pb-0 changelog-markdown"
              >
                  <ReactMarkReadown children={notes} remarkPlugins={[remarkGfm]} />
                {/* <pre>{type === "json" ? getJsonSwagger() : getYamlSwagger()}</pre> */}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
};

export const CompareModal = (props) => {
    const [lhs, setLhs] = useState(null);
    const [rhs, setRhs] = useState(null);
    const [loading, setloading] = useState(false);
  
    const { showCompareModal, ..._props } = props;
    const { metadata, oas } = useSelector((state) => state.swagger);
  
    const getSwaggerDetails = () => {
        console.log(metadata, "metadata");
      setloading(true);
      API.getSwaggerDetailByRevision({
        swaggerId: metadata.swaggerId,
        version: oas,
        revision: showCompareModal.oldRevision,
      })
        .then((res) => {
          setRhs(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
  
      API.getSwaggerDetailByRevision({
        swaggerId: metadata.swaggerId,
        version: oas,
        revision: showCompareModal.newRevision,
      })
        .then((res) => {
          setLhs(res.data.data);
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    };
  
    useEffect(() => {
      getSwaggerDetails();
    }, []);
  
    const handleClose = () => {
      props.onHide();
    };
  
    const getLHS = () => {
      return JSON.stringify(lhs, null, 2);
    };
  
    const getRHS = () => {
      return JSON.stringify(rhs, null, 2);
    };
  
    return (
      <Modal
        {..._props}
        centered
        size="lg me-0 mt-0 mb-0"
        className="custom-modal-v1 v1--styles"
        contentClassName='br-0px homeModalForLghtMode'
        aria-labelledby="contained-modal-title-vcenter overflow-hidden noscrollbar"
      >
        <Modal.Header className="w-100 py-8 pl-8 pr-8 surface-850 important br-0">
          <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <div
                onClick={handleClose}
                className="no-bg-btn fw-400 fs-12px cursor"
              >
                <MdClose size={20} />
              </div>
              <p className="mb-0 text-content-color fw-500 fs-16px">Compare</p>
            </div>
  
            <div className="d-flex align-items-center"></div>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{ height: "calc(100vh - 63px)" }}
          className="br-0 position-relative"
        >
          {loading && <ContainerLoader />}
  
          <div className="h-100 overflow-scroll noscrollbar">
            <div
              style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
              class="px-4 pb-0"
            >
              {!loading && (
                <div>
                  <div class="content_heading_actions">
                    <div class="mb-2 row align-items-center position-relative">
                  
                      <div class="col-5 text-center">
                        <h5 class="mb-0 d-flex justify-content-center align-items-center font-weight-bold">
                          <span class="mr-2 fs-14px">
                            Revision {showCompareModal.newRevision}
                          </span>
                        </h5>
                      </div>
                      <div className="col-2 text-center">
                        {/* <GlobalIcons type={"compare-icon"}/> */}
                      </div>
                      <div class="col-5 text-center">
                        <h5 class="mb-0 font-weight-bold fs-14px">
                          Revision {showCompareModal.oldRevision}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="diff-check" id="compare">
                      <DiffViewer oldCode={getRHS()} newCode={getLHS()}  />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
};

export default function ChangeLogModal(props) {

    const [duration, setDuration] = useState("");
    const [openCalendar, setOpenCalendar] = useState(false);
    const [showClear, setShowClear] = useState(false);
    const [loading, setLoading] = useState(false);
    const [logDetailLoading, setLogDetailLoading] = useState(false);
    const [showLogDetail, setShowLogDetail] = useState(false);
    const [pagination, setPagination] = useState({
      offset: 1,
      totalPages: 0,
      total: 0,
      perPage: 10,
    });
    const [changelogData, setChangelogData] = useState([]);
    const [changeLogDetail, setChangeLogDetail] = useState(null);
  
    const [showCompareModal, setShowCompareModal] = useState({
      show: false,
      newRevision: null,
      oldRevision: null,
    });
  
    const calendarRef = useRef(null);
  
    const { theme } = useTheme();
  
    const [date, setDate] = useState([
      {
        endDate: new Date(),
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        key: "selection",
      },
    ]);
  
    const { getRatingReviews, ..._props } = props;
  
    const { metadata, oas } = useSelector((state) => state.swagger);
  
    const handleClose = () => {
      props.onHide();
    };
  
    const groupBy = (data) => {
      const obj = {};
      data.forEach((log) => {
        let date = moment(log.cts).toDate();
        date = moment(date).format("MMM DD[,] YYYY");
        if (obj[date]) {
          obj[date] = [...obj[date], { ...log }];
        } else {
          obj[date] = [{ ...log }];
        }
      });
  
      return obj;
    };
  
    const getFormattedLogs = () => {
      const res = [];
      Object.entries(groupBy(changelogData)).forEach(([key, value], index) => {
        res.push([key, value]);
      });
      return res;
    };
  
    const handleCalendarDateChange = (item) => {
      let start = moment(item.selection.startDate);
      let end = moment(item.selection.endDate);
  
      setShowClear(true);
  
      setDate([
        {
          startDate: new Date(start),
          endDate: new Date(end),
          key: "selection",
        },
      ]);
    };
  
    useOutsideClick(calendarRef, () => {
      setOpenCalendar(false);
    });
  
    const getChangelogData = () => {
      const from = date[0].startDate.toLocaleDateString("en-US");
      const to = date[0].endDate.toLocaleDateString("en-US");
  
      const params = {
        offset: pagination.offset,
        timeRange: from + "~" + to,
        paginated:false
      };
  
      if (from !== to) {
        setLoading(true);
        API.getSwaggerChangeLog({
          swaggerId: metadata.swaggerId,
          version: oas,
          params,
        })
          .then((apiRes) => {
            let res = apiRes.data.data
            setLoading(false);
  
            if (res.data && res.data.pagination) {
              setPagination({
                total: res.pagination.total,
                offset: res.pagination.offset,
                perPage: res.pagination.pageSize,
                totalPages: Math.ceil(
                  res.pagination.total / res.pagination.pageSize
                ),
              });
            }
            setChangelogData([...res.data]);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    };
  
    const getChangelogDetails = ({ logId }) => {
      setLogDetailLoading(true);
      API.getChangeLogDetails({
        swaggerId: metadata.swaggerId,
        logId,
        version: oas,
      })
        .then((res) => {
          setLogDetailLoading(false);
          console.log("changelog detail: ", res.data.data);
          setChangeLogDetail(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setLogDetailLoading(false);
        });
    };
  
    const updateChangeLogData = () => {
      const from = date[0].startDate.toLocaleDateString("en-US");
      const to = date[0].endDate.toLocaleDateString("en-US");
  
      console.log({ pagination });
  
      const params = {
        offset: pagination.offset + 1,
        timeRange: from + "~" + to,
        paginated:false
      };
  
      if (from !== to) {
        setLoading(true);
        API.getSwaggerChangeLog({
          swaggerId: metadata.swaggerId,
          version: oas,
          params,
        })
          .then((apiRes) => {
            console.log("changelog data response: ", res);
            setLoading(false);
            let res = apiRes.data.data
  
            if (res.data && res.data.pagination) {
              setPagination({
                total: res.pagination.total,
                offset: res.pagination.offset,
                perPage: res.pagination.pageSize,
                totalPages: Math.ceil(
                  res.pagination.total / res.pagination.pageSize
                ),
              });
            }
            setChangelogData((prev) => [...prev, ...res.data]);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    };
  
    useEffect(() => {
      getChangelogData();
    }, [date]);

    return (
        <Modal
            {...props}
            centered
            size="lg me-0 mt-0 mb-0"
            contentClassName='br-0px homeModalForLghtMode'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-content-color fw-500 fs-16px'>Change Log</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            <GlobalIcons type={"close"}/>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div className="h-100 overflow-scroll noscrollbar p-0">
                    <div
                        style={{ height: "calc(100vh - 63px)", overflow: "auto" }}
                        class="p-2 pb-0"
                    >
                        <div className="d-none Changelog-modal-timeline__container  position-relative">
                        <Row className="d-none align-items-center">
                            <div className="col-lg-11 d-flex justify-content-between">
                            <div className="change-log-duration__container">
                                <span className="me-3">Duration:</span>
                                <Form.Check
                                id="change-log-duration-week"
                                type="radio"
                                label="Week"
                                className="ms-2"
                                checked={duration === "week"}
                                onChange={() => {
                                    setDuration("week");
                                    setDate([
                                    {
                                        endDate: new Date(),
                                        startDate: new Date(
                                        new Date().setDate(new Date().getDate() - 7)
                                        ),
                                        key: "selection",
                                    },
                                    ]);
                                    setShowClear(true);
                                }}
                                />
                                <Form.Check
                                id="change-log-duration-month"
                                type="radio"
                                label="Month"
                                className="ms-2"
                                checked={duration === "month"}
                                onChange={() => {
                                    setDuration("month");
                                    setDate([
                                    {
                                        endDate: new Date(),
                                        startDate: new Date(
                                        new Date().setDate(new Date().getDate() - 30)
                                        ),
                                        key: "selection",
                                    },
                                    ]);
                                    setShowClear(true);
                                }}
                                />
                            </div>
                            <div className="position-relative">
                                <div
                                className="btn-overlay px-2 py-2 cursor"
                                onClick={() => setOpenCalendar((prev) => !prev)}
                                >
                                <GlobalIcons type={"calendar"}/>
                                </div>
                                {openCalendar && (
                                <div
                                    ref={calendarRef}
                                    className={"date-picker changelog-calendar-container"}
                                >
                                    <DatePicker
                                    moveRangeOnFirstSelection={false}
                                    maxDate={new Date()}
                                    ranges={date}
                                    calendars={1}
                                    onChange={(item) => handleCalendarDateChange(item)}
                                    />
                                </div>
                                )}
                            </div>
                            </div>
                            <div className="col-lg-1">
                            {showClear && (
                                <p
                                className="cursor-pointer"
                                style={{ color: "#3686fb" }}
                                onClick={() => {
                                    setDate([
                                    {
                                        endDate: new Date(),
                                        startDate: new Date(
                                        new Date().setDate(new Date().getDate() - 7)
                                        ),
                                        key: "selection",
                                    },
                                    ]);
                                    setDuration("");
                                    setShowClear(false);
                                    setPagination({
                                    offset: 1,
                                    totalPages: 0,
                                    total: 0,
                                    perPage: 10,
                                    });
                                }}
                                >
                                Clear
                                </p>
                            )}
                            {/* If custome date chosen show clear button to reset  */}
                            </div>
                        </Row>
                        </div>
                        <div className="pt-0 pb-0 px-4">
                        {loading && <ContainerLoader />}
                        <Row>
                            { getFormattedLogs().length > 0 ?
                                <>
                                <div className="col-lg-10">
                                <div
                                    className="commitHistoryInnerWrapper custom changeLogListWrapper position-relative"
                                    style={{ miHeight: "calc(100vh - 135px) !important" }}
                                >
                                    {getFormattedLogs().map((log) => (
                                        <div class="itx-comment-wrapper position-relative d-flex flex-wrap">
                                        <div class="d-flex">
                                            <div class="itx-comment-date position-relative text-right">
                                            <span class="position-absolute dateActiveIcon"></span>
                                            <span class="itx-font-sm text-content-color">Published on</span>
                                            <h5 className='text-content-subtle'>{log[0]}</h5>
                                            </div>
                                        </div>
                                        {log[1].map((detailLog) => (
                                            <div
                                            key={detailLog.id}
                                            class="itx-comment-details-wrapper"
                                            >
                                            <div class="itx-comment-details d-flex justify-content-between align-items-center">
                                                <div>
                                                <h4 class="itx-comment-details__heading itx-font-sm mr-auto">
                                                    {detailLog.summary}
                                                </h4>
                                                <div class="itx-comment-details__footer d-flex">
                                                    <span class="commentsUsername">
                                                    {detailLog.createdUserName}
                                                    </span>
                                                    <span class="commentsDate">
                                                    {/* <span class="commited-time">{{cts | formatRelativetime }}</span> */}
                                                    </span>
                                                </div>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center commentsIcons">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="btn btn-sm btn-icon rounded-icons rounded-circle mr-2 contentCenter commentIcon"
                                                    title="Compare"
                                                    onClick={() => {
                                                    setShowCompareModal((prev) => ({
                                                        show: true,
                                                        oldRevision: detailLog.oldRevision,
                                                        newRevision: detailLog.newRevision,
                                                    }));
                                                    }}
                                                >
                                                    {/* <img
                                                    src={ theme === "light" ? CompareIcon : CompareDarkIcon}
                                                    width={20}
                                                    height={20}
                                                    /> */}
                                                    <GlobalIcons type={"compare-icon"}/>
                                                </a>
                                                <a
                                                    href="javascript:void(0)"
                                                    class="btn btn-sm btn-icon rounded-icons rounded-circle mr-2 contentCenter commentIcon"
                                                    title="View"
                                                    onClick={() => {
                                                    getChangelogDetails({
                                                        logId: detailLog.id,
                                                    });
                                                    setShowLogDetail(true);
                                                    }}
                                                >
                                                    <AiOutlineEye size={20} color={'var(--content-color)'} />
                                                </a>
                                                </div>
                                            </div>
                                            </div>
                                        ))}
                                        </div>
                                    ))}

                                    {pagination.offset < pagination.totalPages && (
                                    <Button
                                        variant="primary"
                                        className="p-2 mx-auto d-block fs-6 mb-2"
                                        onClick={() => {
                                        setPagination((prev) => ({
                                            ...prev,
                                            offset: prev.offset + 1,
                                        }));
                                        updateChangeLogData();
                                        }}
                                    >
                                        Show More
                                    </Button>
                                    )}
                                </div>
                                </div>
                                <div className="col-lg-2"></div>
                                </>
                                : <div className='d-flex justify-content-center align-items-center flex-column pt-5 ps-5 w-100'>
                                <GlobalIcons type="nodata-icon"/>
                                <p className="text-content-color fs-12px fw-400 mt-2">No Change logs found for selected filters</p>
                                </div>}
                        </Row>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            {showLogDetail && (
                <ChangelogDetailLog
                    loading={logDetailLoading}
                    show={showLogDetail}
                    onHide={() => setShowLogDetail(false)}
                    logDetails={changeLogDetail}
                    />
                )}

            {showCompareModal.show && (
                <CompareModal
                    showCompareModal={showCompareModal}
                    show={showCompareModal.show}
                    onHide={() =>
                        setShowCompareModal((prev) => ({
                        show: false,
                        oldRevision: null,
                        newRevision: null,
                        }))
                    }
                />
            )}
        </Modal>
    )
}

