import { Browsers } from 'phosphor-react'
import React from 'react'
import CustomTertiaryTab from '../../../Dashboards/components/CustomTertiaryTab'

export default function RenderTertiaryTab({ data, isEdit, setData }) {

    return (
        <div className='h-100 pb-3'>
            <div className='surface-base br-6px p-16px w-100 mb-3'>
                <div className='d-block mx-auto' style={{width: 340}}>
                    <CustomTertiaryTab data={data} readOnly />
                </div>
            </div>

            <div className='surface-875 px-12px py-10px d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <Browsers size={19} color="#E4E4E4" className='mr-14px' />
                    <p className='text-200 fs-16px fw-500'>Tertiary Section Content</p>
                </div>
            </div>
            <div className='surface-base p-16px h-50 overflow-scroll noscrollbar'>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Tag <span className='text-danger'>*</span> </p>
                        <p className='text-300 fs-12px fw-400'>{data.tag?.length || 0}/40</p>
                    </div>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.tag}
                        disabled={!isEdit} onChange={(e) => {
                            let _str = e.target.value 
                            if (_str.length > 40) return
                            setData({...data, tag: _str})
                        }} />
                </div>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Heading <span className='text-danger'>*</span> </p>
                        <p className='text-300 fs-12px fw-400'>{data.heading?.length || 0}/36</p>
                    </div>
                    <input type={'text'} max={20} value={data.heading}
                        className='from-control v1-form-control mt-1 w-100' 
                        disabled={!isEdit} onChange={(e) => {
                            let _str = e.target.value 
                            if (_str.length > 36) return
                            setData({...data, heading: _str})
                        }} />
                </div>
                <div className='mb-3'>
                    <div className='dfaic justify-content-between'>
                        <p className='text-300 fs-12px fw-400'>Description <span className='text-danger'>*</span> </p>
                        <p className='text-300 fs-12px fw-400'>{data.description?.length || 0}/30</p>
                    </div>
                    <input type={'text'} className='from-control v1-form-control mt-1 w-100' value={data.description}
                        disabled={!isEdit} onChange={(e) => {
                            let _str = e.target.value 
                            if (_str.length > 30) return
                            setData({...data, description: _str})
                        }} />
                </div>
            </div>
        </div>
    )
}
