import React from 'react'

export default function UserIcons({type, size}) {
    return (
        <>
            {type === 'thunder' && <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
            <rect x="4.66675" y="4" width="48" height="48" rx="24" fill="#242529"/>
            <path d="M29.6667 18L19.6667 30H28.6667L27.6667 38L37.6667 26H28.6667L29.6667 18Z" stroke="#E4E4E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="4.66675" y="4" width="48" height="48" rx="24" stroke="#2F3135" stroke-width="8"/>
            </svg>}

            {type === 'download-right' && <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                <rect x="4" y="4" width="48" height="48" rx="24" fill="#242529"/>
                <g clip-path="url(#clip0_15526_363793)">
                <path d="M18.2617 28.1875H28.7617" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M25.0117 24.4375L28.7617 28.1875L25.0117 31.9375" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.7617 19.9375H34.0117C34.2106 19.9375 34.4014 20.0165 34.542 20.1572C34.6827 20.2978 34.7617 20.4886 34.7617 20.6875V35.6875C34.7617 35.8864 34.6827 36.0772 34.542 36.2178C34.4014 36.3585 34.2106 36.4375 34.0117 36.4375H28.7617" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#2F3135" stroke-width="8"/>
                <defs>
                <clipPath id="clip0_15526_363793">
                <rect width="24" height="24" fill="white" transform="translate(16.0117 16.1875)"/>
                </clipPath>
                </defs>
                </svg>}
            
            {type === 'people' && <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                <rect x="4.33325" y="4" width="48" height="48" rx="24" fill="#242529"/>
                <g clip-path="url(#clip0_15526_363565)">
                <path d="M23.8909 30.7168C26.5833 30.7168 28.7659 28.5342 28.7659 25.8418C28.7659 23.1494 26.5833 20.9668 23.8909 20.9668C21.1985 20.9668 19.0159 23.1494 19.0159 25.8418C19.0159 28.5342 21.1985 30.7168 23.8909 30.7168Z" stroke="#E4E4E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.9749 34.4668C17.724 33.315 18.749 32.3685 19.9567 31.7132C21.1645 31.058 22.5168 30.7148 23.8908 30.7148C25.2648 30.7148 26.6171 31.058 27.8249 31.7132C29.0326 32.3685 30.0576 33.315 30.8067 34.4668" stroke="#E4E4E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.1409 30.7168C33.5148 30.716 34.8672 31.0586 36.0751 31.7135C37.2829 32.3685 38.3079 33.3149 39.0568 34.4668" stroke="#E4E4E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.3306 21.3137C30.9974 21.0477 31.7148 20.9323 32.4314 20.9757C33.1481 21.0191 33.8462 21.2203 34.4761 21.5649C35.106 21.9094 35.652 22.3888 36.0751 22.9688C36.4982 23.5489 36.7879 24.2152 36.9237 24.9202C37.0594 25.6252 37.0377 26.3514 36.8602 27.0471C36.6827 27.7428 36.3538 28.3906 35.8968 28.9444C35.4399 29.4982 34.8663 29.9442 34.217 30.2506C33.5678 30.557 32.8588 30.7162 32.1409 30.7168" stroke="#E4E4E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <rect x="4.33325" y="4" width="48" height="48" rx="24" stroke="#2F3135" stroke-width="8"/>
                <defs>
                <clipPath id="clip0_15526_363565">
                <rect width="24" height="24" fill="white" transform="translate(16.0159 15.7168)"/>
                </clipPath>
                </defs>
                </svg>
            }

            {type === 'circlePerson' && <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                <rect x="4.66675" y="4" width="48" height="48" rx="24" fill="#242529"/>
                <g clip-path="url(#clip0_15526_363612)">
                <path d="M28.2046 31.1104C30.2757 31.1104 31.9546 29.4314 31.9546 27.3604C31.9546 25.2893 30.2757 23.6104 28.2046 23.6104C26.1335 23.6104 24.4546 25.2893 24.4546 27.3604C24.4546 29.4314 26.1335 31.1104 28.2046 31.1104Z" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22.1858 34.8013C22.7501 33.6898 23.6111 32.7563 24.6735 32.1042C25.7358 31.4521 26.958 31.1069 28.2045 31.1069C29.4511 31.1069 30.6732 31.4521 31.7356 32.1042C32.798 32.7563 33.659 33.6898 34.2233 34.8013" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.7046 21.3604H37.2046" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M37.08 26.6104C37.3964 28.4904 37.1072 30.4223 36.254 32.1272C35.4008 33.8321 34.0279 35.2217 32.3334 36.0954C30.6389 36.9691 28.7106 37.2817 26.827 36.988C24.9433 36.6943 23.2016 35.8096 21.8536 34.4615C20.5055 33.1135 19.6208 31.3718 19.3271 29.4881C19.0334 27.6044 19.346 25.6762 20.2197 23.9817C21.0934 22.2872 22.483 20.9143 24.1879 20.0611C25.8928 19.2079 27.8247 18.9187 29.7047 19.2351" stroke="#E4E4E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <rect x="4.66675" y="4" width="48" height="48" rx="24" stroke="#2F3135" stroke-width="8"/>
                <defs>
                <clipPath id="clip0_15526_363612">
                <rect width="24" height="24" fill="white" transform="translate(16.2046 16.1104)"/>
                </clipPath>
                </defs>
                </svg>
            }
            {
                type === "smDown" && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M7.00008 1.1665V12.8332M7.00008 12.8332L12.8334 6.99984M7.00008 12.8332L1.16675 6.99984" stroke="#E5484D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "smUp" && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                <path d="M7.33333 12.8332V1.1665M7.33333 1.1665L1.5 6.99984M7.33333 1.1665L13.1667 6.99984" stroke="#46A758" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "userPlus" && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M12.5 8.5H15.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 7V10" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.75 10C8.82107 10 10.5 8.32107 10.5 6.25C10.5 4.17893 8.82107 2.5 6.75 2.5C4.67893 2.5 3 4.17893 3 6.25C3 8.32107 4.67893 10 6.75 10Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.38745 12.5002C2.04449 11.7171 2.86506 11.0874 3.79149 10.6554C4.71792 10.2234 5.72774 9.99951 6.74995 9.99951C7.77217 9.99951 8.78198 10.2234 9.70841 10.6554C10.6348 11.0874 11.4554 11.7171 12.1125 12.5002" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "lock" && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13 5.5H3C2.72386 5.5 2.5 5.72386 2.5 6V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V6C13.5 5.72386 13.2761 5.5 13 5.5Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.75 5.5V3.25C5.75 2.65326 5.98705 2.08097 6.40901 1.65901C6.83097 1.23705 7.40326 1 8 1C8.59674 1 9.16903 1.23705 9.59099 1.65901C10.0129 2.08097 10.25 2.65326 10.25 3.25V5.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 10C8.27614 10 8.5 9.77614 8.5 9.5C8.5 9.22386 8.27614 9 8 9C7.72386 9 7.5 9.22386 7.5 9.5C7.5 9.77614 7.72386 10 8 10Z" fill="#E4E4E4"/>
                </svg>
            }
            {
                type === "resetPassword" && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M2.5 3.5V12.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5 6V8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.59998 7.38135L6.49998 8.0001" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.32495 9.61875L6.49995 8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.675 9.61875L6.5 8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.4 7.38135L6.5 8.0001" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 6V8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6 7.38135L12.5 8.0001" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.325 9.61875L12.5 8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.675 9.61875L12.5 8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.4 7.38135L12.5 8.0001" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }

            {
                type === "bin" && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.5 3.5H2.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5 6.5V10.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 6.5V10.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 3.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V3.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "active" && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13 5.5H3C2.72386 5.5 2.5 5.72386 2.5 6V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V6C13.5 5.72386 13.2761 5.5 13 5.5Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.75 5.5V3.25C5.75 2.65326 5.98705 2.08097 6.40901 1.65901C6.83097 1.23705 7.40326 1 8 1C8.59674 1 9.16903 1.23705 9.59099 1.65901C10.0129 2.08097 10.25 2.65326 10.25 3.25" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "sync" && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9375 4.8125L12.25 3.5L10.9375 2.1875" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.75 7C1.75145 6.07219 2.12066 5.18279 2.77672 4.52672C3.43279 3.87066 4.32219 3.50145 5.25 3.5H12.25" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.0625 9.1875L1.75 10.5L3.0625 11.8125" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.25 7C12.2486 7.92781 11.8793 8.81721 11.2233 9.47328C10.5672 10.1293 9.67781 10.4986 8.75 10.5H1.75" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  

            }
            {
                type==="defaultTick" && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                <path d="M18.8221 1.53007L6.82206 13.5301C6.7524 13.5998 6.66969 13.6551 6.57864 13.6929C6.48759 13.7306 6.38999 13.75 6.29143 13.75C6.19287 13.75 6.09528 13.7306 6.00423 13.6929C5.91318 13.6551 5.83046 13.5998 5.76081 13.5301L0.510808 8.28007C0.370077 8.13934 0.291016 7.94846 0.291016 7.74944C0.291016 7.55042 0.370077 7.35955 0.510808 7.21881C0.651538 7.07808 0.84241 6.99902 1.04143 6.99902C1.24046 6.99902 1.43133 7.07808 1.57206 7.21881L6.29143 11.9391L17.7608 0.468815C17.9015 0.328085 18.0924 0.249023 18.2914 0.249023C18.4905 0.249023 18.6813 0.328085 18.8221 0.468815C18.9628 0.609546 19.0419 0.800417 19.0419 0.99944C19.0419 1.19846 18.9628 1.38934 18.8221 1.53007Z" fill="#0091FF"/>
                </svg>
            }
            {
                type==="redBin" && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M11.8125 3.0625H2.1875" stroke="#FF6369" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.6875 5.6875V9.1875" stroke="#FF6369" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.3125 5.6875V9.1875" stroke="#FF6369" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.9375 3.0625V11.375C10.9375 11.491 10.8914 11.6023 10.8094 11.6844C10.7273 11.7664 10.616 11.8125 10.5 11.8125H3.5C3.38397 11.8125 3.27269 11.7664 3.19064 11.6844C3.10859 11.6023 3.0625 11.491 3.0625 11.375V3.0625" stroke="#FF6369" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.1875 3.0625V2.1875C9.1875 1.95544 9.09531 1.73288 8.93122 1.56878C8.76712 1.40469 8.54456 1.3125 8.3125 1.3125H5.6875C5.45544 1.3125 5.23288 1.40469 5.06878 1.56878C4.90469 1.73288 4.8125 1.95544 4.8125 2.1875V3.0625" stroke="#FF6369" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "redirect" && <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_16341_380934)">
                <path d="M4 11L11 4" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.3125 4L11 4V9.6875" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_16341_380934">
                <rect width="14" height="14" fill="white" transform="matrix(-1 0 0 -1 14.5 14.5)"/>
                </clipPath>
                </defs>
                </svg>
            }
        </>
    )
}