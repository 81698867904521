import { objectDeepClone } from '@apiwiz/oas/services';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import MyCollapse from '../../../components/Collapse';
import { CaretRight, Check, X } from 'phosphor-react';
import { Accordion } from 'react-bootstrap';
import API from '../../../api';
import { apigeeStatusConversion, confirm, throwServerError } from '../../../utils/helper';
import { getToast } from '../../../components/Toast';

export default function AppCredentials(props) {
    const [data, setData] = useState([])

    useEffect(() => {
        let _data = objectDeepClone(props.data || {})
        _data = objectDeepClone(_data.mainData || []);
        setData(_data)
    }, [])
    
    const handlePermissionChange = (parentId, childId, product, action, _key) => {
        if (!confirm(`Are you sure to ${action} access to this product`)) return
        let _orgDetails = (props.data || {}).orgMetaData || {}
        API.saveMonetizationPermissionForProducts({
            appName:props.data.appName, 
            productName: product || '', 
            action, 
            body: {consumerKey: _key}, 
            org: _orgDetails.org,
            orgType: _orgDetails.orgType}).then(res => {
            let _data = objectDeepClone(data)
            _data[parentId].apiProducts[childId].status = apigeeStatusConversion(action);
            setData(objectDeepClone(_data))
            getToast({
                statusType: 'SUCCESS', message: 'Changes updated successfully'
            })
        }).catch(err => {
            throwServerError(err)
        })
    }

    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Credentials</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <Accordion defaultActiveKey="0" alwaysOpen
                    className="v1-accordion filter-Accordion my-2">
                    {data?.map((each, parentId) => 
                        <Accordion.Item eventKey="0" className='' key={parentId}>
                            <Accordion.Header className='surface-850 d-flex align-items-center w-100 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='collapseIcon mb-1px'>
                                        <CaretRight color='#e4e4e4' size={14} className='me-2' />
                                    </div>
                                    <div className='d-flex flex-column gap-6px'>
                                        <p className='fs-14px fw-500 text-300'> <b>Consumer Key:</b> {each.consumerKey}</p>
                                        {each.consumerSecret ? (
                                            <p className='fs-14px fw-500 text-300'> <b>Consumer Secret:</b> {each.consumerSecret}</p>
                                        ) : null}
                                    </div>
                                </div>

                                <div class='px-10px py-6px br-38px v1-border-1-important surface-900'>
                                    <p class='fs-12px fw-400 text-200'>{each?.apiProducts?.length || 0} Products</p>
                                </div>
                            </Accordion.Header>
                                
                            <Accordion.Body className='p-12px'>
                                <div className='row v1-border-bottom-1 mx-0 py-12px'>
                                    <div className='col-md-4'>
                                        <p className='text-300 fs-12px fw-400'>Products</p>
                                    </div>
                                    <div className='col-md-4 pl-42px'>
                                        <p className='text-300 fs-12px fw-400'>Status</p>
                                    </div>
                                </div>

                                {each.apiProducts?.map((o, childId) => <div className='row mx-0 py-12px v1-border-bottom-1' key={childId}>
                                    <div className='col-md-4 my-auto'>
                                        <p className='text-200 fs-14px fw-500'>{o.apiproduct}</p>
                                    </div>
                                    <div className='col-md-4 my-auto'>
                                        <div className={`global-status-tag ${o.status} w-fit text-capitalize`}>
                                            <p className={`fw-500 fs-12px text-300 status-text px-3`}>{o.status}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 d-flex justify-content-end'>
                                        {o.status === 'approved' 
                                            ? <div className='d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit'
                                                onClick={() => {
                                                    handlePermissionChange(parentId, childId, o.apiproduct, 'revoke', each.consumerKey)
                                                }}>
                                                <X size={14} color='#E4E4E4' className='mr-12px' />
                                                <p className='fs-12px fw-500 text-300'>Revoke</p>
                                            </div>
                                            : <div className='d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit'
                                                onClick={() => {
                                                    handlePermissionChange(parentId, childId, o.apiproduct, 'approve', each.consumerKey)
                                                }}>
                                                <Check size={14} color='#E4E4E4' className='mr-12px' />
                                                <p className='fs-12px fw-500 text-300'>Approve</p>
                                            </div>}
                                    </div>
                                </div>)}
                            </Accordion.Body>
                        </Accordion.Item>)}
                </Accordion>
            </Modal.Body>
        </Modal>
    )
}
