import React from 'react'
import { useTheme } from '../../../context/ThemeContext'
import { getThemeBasedImage } from '../../../utils/helper'
import handImage from './hand.png'

export default function CustomHero3({data, fromEditor}) {
    const {theme} = useTheme()

    return (
        <div className='container'>
            <div className='row' style={{minHeight: '92vh'}}>
                <div className='col-md-6 my-auto firstColHero'>
                    {data.tagName && <p className='text-content-subtle fs-16px fw-600 mb-14px mobileTextCenter' style={{lineHeight: '150%'}}>
                        {data.tagName || ''}
                    </p>}
                    <p className='text-content-color fs-60px fw-600 mobileTextCenter' style={{lineHeight: '120%'}}>
                        {data.heading || ''}
                    </p>
                    <p className='fs-20px fw-400 text-content-subtle mobileTextCenter mt-4'>
                        {data.description}
                    </p>

                    <div className='mt-4 d-flex align-items-center gap-20px mobile-jcc'>
                        {data.secondaryCTA?.label && <a href={data.secondaryCTA?.url || '#'} target='_blank'>
                            <div className={`secondaryCTAButton ${data.secondaryCTA?.applyColor ? 'darkVersion' : ''}`}>
                                <p className='fs-16px'>{data.secondaryCTA?.label || ''}</p>
                            </div>
                        </a>}

                        <a href={data.primaryCTA?.url || '#'} target='_blank'>
                            <div className='primaryCTAButton'>
                                <p className='fs-16px'>{data.primaryCTA?.label || ''}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div className='col-md-4 position-relative'
                    style={{overflow: 'hidden', minHeight: '92vh'}}>
                    <div className='handImageSection'>
                        <img src={data.imageUrl}
                            className={`${theme === 'theme--dark' ? 'd-none' : ''} imageInsidePhone`} />
                        <img src={data.imageUrlDark}
                            className={`${theme === 'theme--light' ? 'd-none' : ''} imageInsidePhone`} />
                        <img src={handImage} className='handImage'
                            style={{objectFit: 'contain'}} />
                    </div>

                    <div className='export_as_svg'>
                        <svg className='svgHere' xmlns="http://www.w3.org/2000/svg" width="418" height="429" viewBox="0 0 418 429" fill="none">
                            <g opacity="0.3">
                            <mask id="mask0_2937_119646" maskUnits="userSpaceOnUse" x="-19" y="0" width="481" height="1132">
                            <line x1="17.4824" y1="0.634277" x2="17.4824" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="46.9216" y1="0.634277" x2="46.9216" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="76.3606" y1="0.634277" x2="76.3606" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="105.8" y1="0.634277" x2="105.8" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="135.239" y1="0.634277" x2="135.239" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="164.678" y1="0.634277" x2="164.678" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="194.117" y1="0.634277" x2="194.117" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="223.556" y1="0.634277" x2="223.556" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="252.995" y1="0.634277" x2="252.995" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="282.434" y1="0.634277" x2="282.434" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="311.873" y1="0.634277" x2="311.873" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="341.312" y1="0.634277" x2="341.312" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="370.751" y1="0.634277" x2="370.751" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="400.19" y1="0.634277" x2="400.19" y2="1131.09" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="0.850098" x2="-18.0605" y2="0.850077" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="30.2891" x2="-18.0605" y2="30.289" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="59.7283" x2="-18.0605" y2="59.7283" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="89.1675" x2="-18.0605" y2="89.1675" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="118.606" x2="-18.0605" y2="118.606" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="148.045" x2="-18.0605" y2="148.045" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="177.485" x2="-18.0605" y2="177.485" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="206.924" x2="-18.0605" y2="206.924" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="236.363" x2="-18.0605" y2="236.363" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="265.802" x2="-18.0605" y2="265.802" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="295.241" x2="-18.0605" y2="295.241" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="324.68" x2="-18.0605" y2="324.68" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="354.119" x2="-18.0605" y2="354.119" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="383.558" x2="-18.0605" y2="383.558" stroke="white" stroke-width="0.431641"/>
                            <line x1="461.061" y1="412.997" x2="-18.0605" y2="412.997" stroke="white" stroke-width="0.431641"/>
                            </mask>
                            <g mask="url(#mask0_2937_119646)">
                            <circle cx="223.011" cy="236.526" r="286.394" fill="url(#paint0_radial_2937_119646)"/>
                            </g>
                            </g>
                            <g opacity="0.2">
                            <rect x="106.036" y="295.229" width="28.9199" height="28.9199" fill="url(#paint1_linear_2937_119646)"/>
                            <rect x="106.036" y="295.229" width="28.9199" height="28.9199" stroke="url(#paint2_linear_2937_119646)" stroke-width="0.431641"/>
                            </g>
                            <g opacity="0.5">
                            <rect x="164.739" y="354.364" width="28.9199" height="28.9199" fill="url(#paint3_linear_2937_119646)"/>
                            <rect x="164.739" y="354.364" width="28.9199" height="28.9199" stroke="url(#paint4_linear_2937_119646)" stroke-width="0.431641"/>
                            </g>
                            <g opacity="0.05">
                            <rect x="400.415" y="295.229" width="28.9199" height="28.9199" fill="url(#paint5_linear_2937_119646)"/>
                            <rect x="400.415" y="295.229" width="28.9199" height="28.9199" stroke="url(#paint6_linear_2937_119646)" stroke-width="0.431641"/>
                            </g>
                            <g opacity="0.1">
                            <rect x="370.416" y="236.31" width="29.3516" height="29.3516" fill="url(#paint7_linear_2937_119646)"/>
                            </g>
                            <g opacity="0.2">
                            <rect x="76.2529" y="325.012" width="28.9199" height="28.9199" fill="url(#paint8_linear_2937_119646)"/>
                            <rect x="76.2529" y="325.012" width="28.9199" height="28.9199" stroke="url(#paint9_linear_2937_119646)" stroke-width="0.431641"/>
                            </g>
                            <g opacity="0.2">
                            <rect x="17.5498" y="383.715" width="28.9199" height="28.9199" fill="url(#paint10_linear_2937_119646)"/>
                            <rect x="17.5498" y="383.715" width="28.9199" height="28.9199" stroke="url(#paint11_linear_2937_119646)" stroke-width="0.431641"/>
                            </g>
                            <defs>
                            <radialGradient id="paint0_radial_2937_119646" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(223.011 236.526) rotate(90) scale(286.394)">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </radialGradient>
                            <linearGradient id="paint1_linear_2937_119646" x1="120.496" y1="295.013" x2="120.496" y2="324.365" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_2937_119646" x1="120.496" y1="295.013" x2="120.496" y2="324.365" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_2937_119646" x1="179.199" y1="354.148" x2="179.199" y2="383.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_2937_119646" x1="179.199" y1="354.148" x2="179.199" y2="383.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_2937_119646" x1="414.875" y1="295.013" x2="414.875" y2="324.365" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_2937_119646" x1="414.875" y1="295.013" x2="414.875" y2="324.365" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_2937_119646" x1="385.092" y1="236.31" x2="385.092" y2="265.662" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_2937_119646" x1="90.7129" y1="324.796" x2="90.7129" y2="354.148" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint9_linear_2937_119646" x1="90.7129" y1="324.796" x2="90.7129" y2="354.148" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint10_linear_2937_119646" x1="32.0098" y1="383.5" x2="32.0098" y2="412.851" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D9D9D9"/>
                            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint11_linear_2937_119646" x1="32.0098" y1="383.5" x2="32.0098" y2="412.851" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        <div className='blurredCircle1'></div>
                        <div className='blurredCircle2'></div>
                    </div>
                </div>
                <div className='col-md-2 my-auto mainColHeor'>
                    <div className='ps-4 mt-100px activeUsersSection'>
                        <p className='fs-48px text-secondary-100 mobileTextCenter fw-700'
                            style={{lineHeight: '108%'}}>
                                {data.panelSection?.heading || ''}
                            </p>
                        <p className='fs-20px text-content-color mobileTextCenter fw-400'>
                            {data.panelSection?.description || ''}
                        </p>
                    </div>

                    {data.panelSection?.imageUrl1 && <div className='userImageCards dfaic mt-20px mobile-jcc'>
                        {data.panelSection?.imageUrl1 && <div className='bg-primary-color imgCard'>
                            <img src={data.panelSection?.imageUrl1}
                                className='h-100 w-100' />
                        </div>}
                        {data.panelSection?.imageUrl2 && <div className='bg-primary-color imgCard'>
                            <img src={data.panelSection?.imageUrl2}
                                className='h-100 w-100' />
                        </div>}
                        {data.panelSection?.imageUrl3 && <div className='bg-primary-color imgCard'>
                            <img src={data.panelSection?.imageUrl3}
                                className='h-100 w-100' />
                        </div>}
                    </div>}

                    <div className='my-32px ps-4 hideOnMobile'>
                        <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.550469 99.8117C6.10926 106.297 14.293 110.466 22.6312 111.856C26.4915 112.473 30.3518 112.628 34.212 111.933C37.8407 111.315 41.4694 109.694 44.712 107.918C46.2561 107.069 47.723 106.065 49.1127 105.062C50.8884 103.749 52.6641 102.437 54.2854 100.97C57.3737 98.1132 59.381 94.1757 60.6935 90.2382C62.0832 86.0692 62.7008 81.6684 62.9324 77.3449C63.1641 72.5582 63.0097 67.5398 62.0832 62.8303C61.0795 57.9664 58.6861 53.334 54.4398 50.5546C50.734 48.1613 46.1017 47.8525 42.2414 50.0914C38.7672 52.0988 36.2966 55.7274 35.2157 59.5104C34.0576 63.6795 34.7525 68.0031 36.837 71.7861C41.2377 79.7383 50.734 84.4478 59.381 85.9147C63.8589 86.6868 68.6456 86.6868 73.0464 85.2971C77.7559 83.753 81.539 80.6647 84.2411 76.4956C87.0977 72.0177 88.8735 66.845 90.572 61.9038C92.4249 56.5767 94.0462 51.1723 95.2815 45.6907C97.8293 34.8047 99.2962 23.6099 99.6822 12.4151C99.7594 9.63575 99.7594 6.93359 99.6822 4.1542C99.605 1.68363 95.7448 1.68363 95.822 4.1542C96.2852 24.9997 92.6565 45.8451 85.2448 65.3009C83.6235 69.5471 81.8478 74.1023 78.6824 77.4221C75.903 80.3559 72.1199 81.9772 68.1824 82.4405C64.0133 82.9037 59.767 82.3633 55.8296 81.128C52.0465 79.9699 48.2634 78.1942 45.1752 75.6464C42.1642 73.1758 39.6936 70.0104 38.7672 66.1501C37.9951 62.9847 38.9216 59.4333 40.6973 56.6539C42.473 54.0289 45.1752 52.1759 48.2634 52.3304C52.1237 52.562 55.1347 55.7274 56.756 59.0472C58.8406 63.2935 58.9178 68.4663 59.0722 73.0986C59.1494 76.8817 58.8406 80.742 58.1457 84.525C57.3737 88.2309 56.2156 91.9367 54.131 95.1794C52.1237 98.2676 48.9583 100.429 46.0245 102.591C43.2451 104.598 40.1569 106.065 36.9142 107.223C33.9032 108.382 31.0466 108.613 27.804 108.536C20.6239 108.304 13.1349 105.679 7.42175 101.124C5.87764 99.8889 4.56515 98.5764 3.25266 97.1095C2.55781 96.3374 1.24532 96.4146 0.550469 97.1095C-0.221585 97.8816 -0.14438 99.0396 0.550469 99.8117Z" fill="var(--primary-color)"/>
                            <path d="M77.1384 37.6614C81.2303 29.0144 86.017 20.7534 91.3442 12.8012C92.6567 10.8711 94.0464 8.86374 95.4361 7.01081C95.9765 6.31596 96.5169 5.38951 97.289 4.84907C97.8294 4.46304 97.9838 4.61747 98.3699 5.15791C98.9875 5.92996 99.4507 6.77923 99.914 7.62849C100.532 8.63216 101.072 9.71302 101.612 10.7939C102.693 12.8012 103.62 14.8858 104.469 17.0475C106.322 21.8343 107.712 26.8526 108.561 31.871C108.715 32.8746 110.028 33.4923 110.954 33.1835C112.035 32.8746 112.498 31.871 112.267 30.7901C111.418 25.849 110.105 20.985 108.252 16.2755C107.326 13.8821 106.322 11.4887 105.164 9.24979C104.083 7.16524 103.002 4.92628 101.612 3.07335C100.068 0.988805 97.675 0.0623592 95.3588 1.45206C93.2743 2.76455 91.8846 5.23509 90.4949 7.16523C87.4839 11.4115 84.6273 15.8122 82.0023 20.2902C79.0685 25.3085 76.2891 30.4041 73.8185 35.6541C73.3553 36.5805 73.5869 37.7386 74.5134 38.279C75.3626 38.8967 76.7524 38.5878 77.1384 37.6614Z" fill="var(--primary-color)"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}
