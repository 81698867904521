import { useEffect, useMemo, useRef, useState } from "react";
import { BodyLayout } from "../../../layout/Base";
import { useNavigate, useOutletContext, useParams } from "react-router";
import {
  BracketsCurly,
  CaretDown,
  CaretRight,
  DotsThreeVertical,
  File,
  FileDoc,
  LinkSimple,
  Package,
  PencilSimple,
  Plus,
} from "phosphor-react";
import EditorJS from "../../../components/EditorJS";
import EditorJSOutput from "../../../components/EditorJSOutput";
import BootstrapDropdown from "../../../components/BootstrapDropdown";
import GUIDE_API from "../../../api/guides";
import Searchbar from "../../../components/Searchbar";
import { confirm, getPermissionByCrud, objectDeepClone, parseEditorJSContent, throwServerError } from "../../../utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { GoDotFill } from "react-icons/go";
import { getGroupList } from "../../../utils/guide-page";
import AddSwaggerModal from "./components/AddSwaggerModal";
import { getToast } from "../../../components/Toast";
import { Link } from "react-router-dom";
import CloneGuideModal from "./components/CloneGuideModal";
import Loading from "../../../components/Loader/Loading";
import GroupModal from "./components/GroupModal";
import PageModal from "./components/PageModal";
import LinkModal from "./components/LinkModal";
import API from "../../../api";
import useOutsideClick from "../../../hooks/useOutsideClick";
import ChangeParentModal from "./components/ChangeParentModal";
import ReOrderModal from "./components/ReOrderModal";
import { useSelector } from "react-redux";

function PageList({ src, list, order = 0, activeDocument, setPageModal, handleClick, setReorderModal,
  searchVal, handleDelete, handleEdit, setLinkModal, level, handleChangeParent }) {
  const [toggle, setToggle] = useState([]);

  function isSearchValExist(_arr, name) {
    for (let i = 0; i < _arr.length; i++) {
      const currentObject = _arr[i];
  
      if (currentObject.title.search(name) !== -1) {
        return true;
      }
  
      if (currentObject.pages && Array.isArray(currentObject.pages)) {
        const nestedExists = isSearchValExist(currentObject.pages, name);
        if (nestedExists) {
          return true;
        }
      }
    }
  
    return false;
  }

  return list.map((page, index) => {
    let _childPages = page.pages || [];

    // if (page.title.search(regExp) === -1) return
    let regExp = new RegExp(searchVal, "i");
    if(!isSearchValExist([page], regExp)) return

    return (
      <>
        <div className={`d-flex justify-content-between manage-guide-left-list-actions hover-surface-875 ${page.document === activeDocument.document ? 'surface-850' : ''}`}>
          <div
            className={`position-relative d-flex align-items-center h-40px pr-8px gap-12px ${
              _childPages.length ? "cursor" : ""
            } ${page.type === "link" ? "" : ""}`}
            onClick={() => {
              if (page.type === "link") {
                window.open(page.url, "_blank");
              } else {
                let _toggle = objectDeepClone(toggle);
                _toggle[index] = !_toggle[index];
                setToggle(_toggle);
              }
            }}
            style={{ paddingLeft: order * 12 }}
          >
            {_childPages.length ? (
              <div className="position-absolute mt-2px">
                <FontAwesomeIcon
                  icon={!toggle[index] ? faCaretRight : faCaretDown}
                  size="16"
                />
              </div>
            ) : null}
            <div className="pl-16px">
              {order && !_childPages.length ? (
                <GoDotFill fill="#fff" size={12} />
              ) : page.type === "document" ? (
                <FileDoc />
              ) : page.type === "page" ? (
                <File />
              ) : page.type === "link" ? (
                <LinkSimple />
              ) : page.type === "swagger" ? (
                <BracketsCurly />
              ) : null}
            </div>
            <div className="fs-14px text-truncate documentTitlee"
              title={page.title} onClick={() => handleClick(page)}>{page.title}</div>
          </div>
          <div className="pr-8px list-actions-cont">
            <ListActions data={page} handleDelete={handleDelete} setPageModal={setPageModal} setReorderModal={setReorderModal}
              handleEdit={handleEdit} setLinkModal={setLinkModal} level={level} handleChangeParent={handleChangeParent}  />
          </div>
        </div>
        {toggle[index] && _childPages.length ? (
          <PageList src={src} list={_childPages} order={order + 1} handleDelete={handleDelete} 
            handleEdit={handleEdit} setLinkModal={setLinkModal} setPageModal={setPageModal} 
            activeDocument={activeDocument} handleClick={handleClick} level={level + 1}
            handleChangeParent={handleChangeParent} setReorderModal={setReorderModal} />
        ) : null}
      </>
    );
  });
}

function ListActions({ data, handleDelete, handleEdit, parentId, handleChangeParent,
  setPageModal, setLinkModal, level, setReorderModal }) {

  /* ACL Start */
  const { currentUser } = useSelector((state)=>state.user)
  const { permission } = useSelector(state => state.user)

  let _permission = permission.filter(o => o.name === 'guides')
  _permission = _permission.length ? (_permission[0].crud || []) : []
  const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
  const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
  const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')
  /* ACL END */

  const addPage = () => {
    setPageModal({
      isOpen: true, isEdit: false, data: {}, parentId: parentId ? parentId : data.id
    })
  };

  const addLink = () => {
    setLinkModal({
      isOpen: true, isEdit: false, data: {}, parentId: parentId ? parentId : data.id
    })
  };
  //const addSwagger = () => {};

  let _addOptions = [
    getCreatePermission ? {
      label: "Page",
      icon: <File size={16} className="text-300" />,
      onSelect: addPage,
    } : null,
    // {
    //   label: "Document",
    //   icon: <FileDoc size={16} className="text-300" />,
    //   onSelect: addDocument,
    // },
    getCreatePermission ? {
      label: "Link",
      icon: <LinkSimple size={16} className="text-300" />,
      onSelect: addLink,
    } : null,
    /*
    {
      label: "Swagger",
      icon: <BracketsCurly size={16} className="text-300" />,
      onSelect: addSwagger,
    },
    */
  ].filter((x) => x);

  const handlePageDelete = (_val) => {
    if (!confirm("Are you sure to delete?")) return

    handleDelete(_val)
  }

  return (
    <div className="d-flex gap-16px h-100 align-items-center">
      {data.type !== "link" ? (
        <div>
          <BootstrapDropdown options={_addOptions} align="end" menuClassName="h-fit">
            <div className="cursor">
              <Plus />
            </div>
          </BootstrapDropdown>
        </div>
      ) : null}
      {!data.noDelete ? (
        <div>
          <BootstrapDropdown
            options={level > 1
            ? [
              getUpdatePermission ? { label: "Edit", onSelect: () => handleEdit(data) } : null,
              getDeletePermission ? { label: "Delete", onSelect: () => handlePageDelete(data) } : null,
              getUpdatePermission ? { label: "Change Parent", onSelect: () => handleChangeParent(data) } : null,
              // { label: "Reorder", onSelect: () => setReorderModal({isOpen: true, data: objectDeepClone(data)}) },
            ].filter((x) => x) : [ 
              getUpdatePermission ? { label: "Edit", onSelect: () => handleEdit(data) } : null,
              getDeletePermission ? { label: "Delete", onSelect: () => handlePageDelete(data) } : null,
              // { label: "Reorder", onSelect: () => setReorderModal({isOpen: true, data: objectDeepClone(data)}) },
            ].filter((x) => x)}
            align="end"
            menuClassName="ab-r-4px-important h-fit"
          >
            <div className="cursor">
              <DotsThreeVertical size={24} />
            </div>
          </BootstrapDropdown>
        </div>
      ) : null}
    </div>
  );
}

export default function ManageGiudes(props) {
  const {id} = useParams()

  const { setHasHeader } = useOutletContext();
  const [inEditMode, setInEditMode] = useState(false);
  const editorInstance = useRef(null);
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true)
  const [guideDetail, setGuideDetail] = useState({});
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState({});
  const [pages, setPages] = useState({});
  const [groups, setGroups] = useState([]);
  const [subLoading, setSubLoading] = useState(false)

  const [swaggerModal, setSwaggerModal] = useState({
    isOpen: false, isEdit: false, data: {}
  })
  const [cloneModal, setCloneModal] = useState({
    isOpen: false, isCreate: false, 
    isClone: false, isEdit: false
  })
  const [groupModal, setGroupModal] = useState({
    isOpen: false, data: {}, isEdit: false
  })
  const [activeDocument, setActiveDocument] = useState({})
  const [searchVal, setSearchVal] = useState('')
  const [pageModal, setPageModal] = useState({
    isOpen: false, parentId: '', isEdit: false, data: {}
  })
  const [linkModal, setLinkModal] = useState({
    isOpen: false, parentId: '', isEdit: false, data: {}
  })
  const [buttonLoading, setButtonLoading] = useState(false)

  const [changeParent, setChangeParent] = useState({
    isOpen: false, data: {}
  })
  const navigate = useNavigate()

  const menuRef = useRef(null)
  useOutsideClick(menuRef, () => {
    setSearchExpanded(false)
  });

  const handleDeleteVersion = () => {
    if (!confirm('Are you sure to delete selected guide version?')) return
    GUIDE_API.deleteVersion(guideDetail.guide._id, guideDetail.version._id)
    .then(res => {
      getToast({
        statusType: 'SUCCESS', message: 'Guide deleted successfully'
      })
      fetchGuide();
    }).catch(err => {
      throwServerError(err)
    })
  }
  const fetchDocumentContent = (_data) => {
    setSubLoading(true)
    setActiveDocument(_data)
    GUIDE_API.fetchDocumentContent(_data.document)
    .then(res => {
      setData(res.content || {})
      setLoading(false)
      setSubLoading(false)
    }).catch(err => {
      throwServerError(err)
      setData({})
      setSubLoading(false)
      setLoading(false)
    })
  }

  const fetchGuide = () => {
    setLoading(true)
    GUIDE_API.getGuideDetails({
      guideName: id,
    })
    .then((data) => {
      setGuideDetail(data);
      let _versions = ((data || {}).guide || {}).versions || [];
      setVersions(_versions);
      let _currentVersion = (data || {}).version || {};
      setCurrentVersion(_currentVersion);
      let _pages = _currentVersion.pages || {};

      if (Object.keys(_pages).length) {
        let first = Object.keys(_pages)[0]
        fetchDocumentContent(_pages[first])
      }
      setPages(objectDeepClone(_pages));
      setGroups(getGroupList(objectDeepClone(_pages)));
    })
    .catch((err) => {
      console.log("err:", err);
      setLoading(false)
    });
  };

  const fetchGuideDetails = (version) => {
    setLoading(true)
    GUIDE_API.getGuideDetails({
      guideName: id, version
    })
    .then((data) => {
      setGuideDetail(data);
      let _versions = ((data || {}).guide || {}).versions || [];
      setVersions(_versions);
      let _currentVersion = (data || {}).version || {};
      setCurrentVersion(_currentVersion);
      let _pages = _currentVersion.pages || {};

      if (Object.keys(_pages).length) {
        let first = Object.keys(_pages)[0]
        fetchDocumentContent(_pages[first])
      }

      setPages(objectDeepClone(_pages));
      setGroups(getGroupList(objectDeepClone(_pages)));
    })
    .catch((err) => {
      console.log("err:", err);
      setLoading(false)
    });
  };

  useEffect(() => {
    setHasHeader(false);
    fetchGuide();
  }, []);

  const onEdit = () => {
    setInEditMode(true);
  };
  const onCancel = () => {
    setInEditMode(false);
  };
  const onSave = async () => {
    if (editorInstance.current) {
      try {

        if (guideDetail.version.status === 'published') {
          if (!confirm("You are editing a published guide. Are you sure to save the changes?")) return
        }
        let doc = await editorInstance.current.save();

        let json = {
          content: objectDeepClone(doc),
          description: activeDocument.description,
          status: activeDocument.status,
          title: activeDocument.title,
        }

        setButtonLoading(true)
        GUIDE_API.updatePage(guideDetail.guide?._id, guideDetail.version?._id, activeDocument.id, json)
        .then(res => {
          getToast({
            statusType: 'SUCCESS', message: 'Updated page contents successfully'
          })
          setInEditMode(false);
          fetchGuide()
          setButtonLoading(false)
          setData(doc);
        }).catch(err => {
          throwServerError(err)
          setButtonLoading(false)
        })
      } catch (err) {}
    }
  };

  const setEditorInstance = (core) => {
    editorInstance.current = core;
  };

  const handleListActionEdit = (_val) => {
    if (_val.type === 'link'){
      setLinkModal({
        isEdit: true, isOpen: true, data: objectDeepClone(_val)
      })
    } else {
      setGroupModal({
        isEdit: true, isOpen: true, data: objectDeepClone(_val)
      })
    }
  }

  const handleListActionDelete = (_val) => {
    GUIDE_API.deletePage(guideDetail?.guide?._id, guideDetail?.version?._id, _val.id)
    .then(res => {
      getToast({
        statusType: 'SUCCESS', message: 'Deleted successfully'
      })
      fetchGuide()
    }).catch(err => {
      throwServerError(err)
    })
  }

  const handleSwaggerDelete = () => {
    if (!confirm("Are you sure to remove swagger attached to this guide?")) return
    API.deleteSwaggerFromGuide(guideDetail.guide?._id,guideDetail.version?._id)
    .then(res => {
      getToast({
        statusType: 'SUCCESS', message: 'Successfully removed swagger from this guide'
      })
      fetchGuide()
    }).catch(err => {
      throwServerError(err)
    })
  }

  const [searchExpanded, setSearchExpanded] = useState(false)

  const handleChangeParent = (_id) => {
    console.log('_id', _id)
    setChangeParent({
      data: _id, isOpen: true
    })
  }

  const [reorderModal, setReorderModal] = useState({
    isOpen: false, data: {}
  })

  /* ACL Start */
  const { currentUser } = useSelector((state)=>state.user)
  const { permission } = useSelector(state => state.user)

  let _permission = permission.filter(o => o.name === 'guides')
  _permission = _permission.length ? (_permission[0].crud || []) : []
  const getCreatePermission = getPermissionByCrud(currentUser, _permission, 'create')
  const getUpdatePermission = getPermissionByCrud(currentUser, _permission, 'update')
  const getDeletePermission = getPermissionByCrud(currentUser, _permission, 'delete')
  /* ACL END */

  return (
    <BodyLayout consumeFull className="fadeIn p-16px">
      {
        loading 
        ? <Loading />
        : <div className="d-flex h-100 gap-8px">
          {/* Left Panel */}
          <div className="surface-900 w-30 h-100 br-8px">
            <div className="d-flex flex-column h-100 gap-8px">
              {/* Left Header */}
              <div className="surface-875 h-52px br-t-8px">
                <div className="d-flex h-100 gap-16px align-items-center py-4">
                  <div className="w-100 d-flex justify-content-between pr-16px">
                    <div className="d-flex flex-column justify-content-center pl-16px gap-4px">
                      <div className="fs-16px lh-16px">Guide detail</div>
                      <div className="d-flex align-items-center">
                        <Link to={`/admin/guides`}>
                          <div className="header-nav-menu cursor-pointer text-300 fs-11px fw-400" >
                            All Guides
                          </div>
                        </Link>
                        <svg className='ml-5px mr-5px mt-1px' width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.34374 4.49984C4.3432 4.37557 4.29414 4.25642 4.20702 4.16781L1.08202 1.04281C0.99396 0.954745 0.874525 0.905273 0.749989 0.905273C0.625453 0.905273 0.506018 0.954745 0.417958 1.04281C0.329898 1.13087 0.280426 1.2503 0.280426 1.37484C0.280426 1.49937 0.329898 1.61881 0.417958 1.70687L3.21093 4.49984L0.417958 7.29281C0.329898 7.38087 0.280426 7.5003 0.280426 7.62484C0.280426 7.74937 0.329898 7.86881 0.417958 7.95687C0.506018 8.04493 0.625453 8.0944 0.749989 8.0944C0.874525 8.0944 0.99396 8.04493 1.08202 7.95687L4.20702 4.83187C4.29414 4.74325 4.3432 4.62411 4.34374 4.49984Z" fill="#E4E4E4"/>
                        </svg>
                        <div className="header-nav-menu text-300 fs-11px fw-400">
                          {guideDetail.guide?.name || ''}
                        </div>
                      </div>
                    </div>
                    {/* Version dropdown */}
                    <div className="d-flex align-items-center">
                      <div>
                        <BootstrapDropdown
                          options={[
                            getCreatePermission ? { label: "Add Group", onSelect: () => setGroupModal({isOpen: true, isEdit: false, data: {}}) } : null,
                            getCreatePermission ? { label: "Create New Version", onSelect: () =>  setCloneModal({isOpen: true, isClone: false, isCreate: true, isEdit: false})} : null,
                            getUpdatePermission ? { label: "Clone Version", onSelect: () =>  setCloneModal({isOpen: true, isClone: true, isCreate: false, isEdit: false})} : null,
                            getUpdatePermission ? { label: "Edit Version", onSelect: () =>  setCloneModal({isOpen: true, isClone: false, isCreate: false, isEdit: true})} : null,
                            getDeletePermission ? { label: "Delete Version", onSelect: () => handleDeleteVersion() } : null,
                            getCreatePermission && !(guideDetail.version?.swagger?.oas) ? { label: "Add Swagger", onSelect: () => setSwaggerModal({isOpen: true, isEdit: false, data: {}}) } : null,
                          ].filter((x) => x)}
                          align="end"
                          showCaretTop
                          menuClassName="ab-r-4px-important h-fit"
                        >
                          <div className="cursor">
                            <DotsThreeVertical size={24} />
                          </div>
                        </BootstrapDropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Left Body */}
              <div className="px-8px" style={{height: 'calc(100% - 52px)'}}>
                <div className="d-flex align-items-start h-36px gap-12px">
                  {/* Version dd*/}
                  {versions.length ? (
                    <div className={searchExpanded ? "w-30" : "w-90"}>
                      <BootstrapDropdown
                        options={versions.map((el) => ({ 
                          label: <div className="d-flex align-items-center justify-content-start gap-12px">
                            <p className="fs-12px text-300 fw-500">{el.title}</p>

                            <p className={`fs-8px text-uppercase ${el.status === 'draft' ? 'text-warning' : 'text-success'}`}>{el.status}</p>
                          </div>, 
                          onSelect: () => fetchGuideDetails(el.version),
                        }))}
                        menuClassName="w-100 item-align-center"
                        defaultClass="justify-content-start"
                      >
                        <div className="position-relative cursor btn-md-850 justify-content-start h-80 pl-0px-important pr-4px-important py-10px-important">
                          <div className="fs-12px d-flex align-items-start gap-6px ps-3">
                            <div className="d-flex align-items-center">{currentVersion.title} <p className={`ml-12px fs-10px text-uppercase ${currentVersion.status === 'draft' ? 'text-warning' : 'text-success'}`}>{currentVersion.status}</p></div>
                          </div>
                          <div className="position-absolute mt--2px ab-r-4px fs-13px mt--4px">
                            <CaretDown />
                          </div>
                        </div>
                      </BootstrapDropdown>
                    </div>
                  ) : null}
                  {/* Search bar */}
                  <div className={searchExpanded ? "w-70" : "w-40px me-2"}
                    onClick={() => setSearchExpanded(true)} ref={menuRef}>
                    <Searchbar inputClassName="surface-900-important no-box-shadow"
                      value={searchVal} onChange={(val) => setSearchVal(val)} />
                  </div>
                </div>
                   
                <div className="overflow-scroll noscrollbar" style={{height: 'calc(100% - 100px)'}}>
                  {groups.map((group) => {
                    return (
                      <>
                        <div className={`h-48px px-8px py-16px d-flex justify-content-between border-bottom-800 manage-guide-left-list-actions`}>
                          <div className="fs-16px">{group.title}</div>
                          <div className="list-actions-cont">
                            <ListActions data={group} handleDelete={handleListActionDelete} setPageModal={setPageModal}
                              setLinkModal={setLinkModal} handleChangeParent={handleChangeParent} setReorderModal={setReorderModal}
                              handleEdit={handleListActionEdit} parentId={group?.id || guideDetail.version?.rootPage || ''} />
                          </div>
                        </div>

                        <PageList src={pages} list={group.pages} activeDocument={activeDocument} setReorderModal={setReorderModal}
                          setPageModal={setPageModal} setLinkModal={setLinkModal} level={1} handleChangeParent={handleChangeParent}
                          handleDelete={handleListActionDelete} handleEdit={handleListActionEdit} inEditMode={inEditMode}
                          handleClick={(_val) => fetchDocumentContent(_val)} searchVal={searchVal} />
                      </>
                    );
                  })}

                  {guideDetail.version?.swagger?.oas && <div className="px-8px py-12px v1-border-bottom-1 d-flex align-items-center justify-content-between mt-3">
                    <p className="text-truncate pe-2 text-50 fw-500 fs-14px">
                      {guideDetail.version?.swagger?.swaggerName}
                    </p>

                    <div className="d-flex align-items-center">
                      <div className={`reference-list-${guideDetail.version?.swagger?.oas === 'OAS 2.0' ? 'v2' : 'v3'} text-nowrap`}>
                        {guideDetail.version?.swagger?.oas}
                      </div>

                      <BootstrapDropdown
                        options={[
                          getUpdatePermission ? { label: "Edit Swagger", onSelect: () => setSwaggerModal({isOpen: true, isEdit: true, data: objectDeepClone(guideDetail.version?.swagger || {})}) } : null,
                          getDeletePermission ? { label: "Delete Swagger", onSelect: handleSwaggerDelete } : null,
                        ].filter(x => x)}
                        showCaretBottom
                        menuClassName="ab-r-4px-important h-fit"
                      >
                        <div className="cursor">
                          <DotsThreeVertical size={24} />
                        </div>
                      </BootstrapDropdown>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          {/* Right Panel */}
          <div
            className={`surface-base w-70 br-4px h-100 ${
              inEditMode ? "v1-border-1 border-dashed" : ""
            }`}
          >
            {/* Right Header */}
            <div className="h-52px surface-875 d-flex justify-content-between align-items-center px-12px br-t-4px">
              {/* Title */}
              <div className="fs-24px">
                {activeDocument.title}
              </div>
              {/* CTA */}
              {getUpdatePermission ? (
                !inEditMode ? (
                  <div
                    onClick={onEdit}
                    className="d-flex btn-md-850 h-32px gap-8px px-8px"
                  >
                    <div style={{ marginTop: -3 }}>
                      <PencilSimple size={14} />
                    </div>
                    <div className="fs-12px fw-500">Edit</div>
                  </div>
                ) : (
                  <div className="d-flex">
                    <div onClick={onCancel} className="text-btn-md fs-12px">
                      Cancel
                    </div>
                    <div onClick={onSave} className="btn-md-850 h-32px fs-12px">
                      Save
                    </div>
                  </div>
                )
              ) : null}
            </div>
            {/* Right Body */}
            {subLoading
            ? <Loading />
            : <div
              className={`${
                inEditMode ? "" : "surface-875"
              } h--52px px-12px py-16px overflow-scroll-y noscrollbar`}
            >
              {inEditMode ? (
                <EditorJS data={data} setEditorInstance={setEditorInstance} />
              ) : (
                <EditorJSOutput data={data} />
              )}
            </div>}
          </div>
        </div>}

      {swaggerModal.isOpen && <AddSwaggerModal show={swaggerModal.isOpen}
        callback={fetchGuide} mainData={guideDetail} data={swaggerModal.data} isEdit={swaggerModal.isEdit}
        onHide={() => setSwaggerModal({isOpen: false, isEdit: false, data: {}})} />}

      {pageModal.isOpen && <PageModal show={pageModal.isOpen} data={pageModal.data} 
        parentId={pageModal.parentId} isEdit={pageModal.isEdit} callback={fetchGuide} mainData={guideDetail} 
        onHide={() => setPageModal({isOpen: false, isEdit: false, parentId: '', data: {}})} />}

      {linkModal.isOpen && <LinkModal show={linkModal.isOpen} data={linkModal.data} 
        parentId={linkModal.parentId} isEdit={linkModal.isEdit} callback={fetchGuide} mainData={guideDetail} 
        onHide={() => setLinkModal({isOpen: false, isEdit: false, parentId: '', data: {}})} />}

      {cloneModal.isOpen && <CloneGuideModal show={cloneModal.isOpen} callback={fetchGuide}
        data={objectDeepClone(guideDetail)} isCreate={cloneModal.isCreate} isEdit={cloneModal.isEdit}
        isClone={cloneModal.isClone} 
        onHide={() => setCloneModal({isOpen: false, isClone: false, isEdit: false, isCreate: false})}
        /> }
      
      {groupModal.isOpen && <GroupModal mainData={guideDetail} callback={fetchGuide}
        show={groupModal.isOpen} isEdit={groupModal.isEdit} data={groupModal.data}
        onHide={() => setGroupModal({isOpen: false, isEdit: false, data: {}})} />}

      {changeParent.isOpen && <ChangeParentModal activeDocument={activeDocument}
        show={changeParent.isOpen} data={changeParent.data} guideDetails={guideDetail}
        onHide={() => setChangeParent({isOpen: false, data: {}})} callback={fetchGuide}  />}

      {reorderModal.isOpen && <ReOrderModal activeDocument={activeDocument}
        show={reorderModal.isOpen} data={reorderModal.data} guideDetails={guideDetail}
        onHide={() => setReorderModal({isOpen: false, data: {}})} callback={fetchGuide}  />}
    </BodyLayout>
  );
}
