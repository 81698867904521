import { useEffect, useState } from "react";
import { getRatePlanValue, objectDeepClone } from "../../../utils/helper";
import { ArrowSquareOut } from "phosphor-react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import monetizationAPI from "../../../api/monetization";
import { PRODUCTS_MAPPER_KEY, ROUTE_NAMES } from "../../../service/constants";

export const ApigeeProductDetailsModal = ({
  onModalClose,
  selectedProduct,
}) => {
  const { name } = selectedProduct;
  const description = selectedProduct?.metadata?.description || selectedProduct?.description

  const [associatedGuides, setAssociatedGuides] = useState([]);

  useEffect(() => {
    if (selectedProduct.metadata && selectedProduct.metadata.attachedGuides
      && selectedProduct.metadata.attachedGuides.length > 0){
        setAssociatedGuides(objectDeepClone(selectedProduct.metadata.attachedGuides))
      } else { 
        setAssociatedGuides([])
      }
  }, [])

  const isSwaggerPresent = () => {
    const isPresent = (selectedProduct.metadata && selectedProduct.metadata.swagger && selectedProduct.metadata.swagger.label);
    return isPresent;
  };

  return (
    <div
      className="product-details-modal__container"
      onClick={(evt) => {
        evt.stopPropagation();
        onModalClose();
      }}
    >
      <div
        className="product-details-modal-body"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-header">
          <div className="modal-title">{name}</div>
          <div className="modal-action-btns__container">
            <button
              className="action-btn close-btn primary"
              onClick={onModalClose}
            >
              <GlobalIcons type={"close"} />
            </button>
          </div>
        </div>
        <div className="modal-content">
          <div className="product-details-values-card">
            <div className="input-group__container">
              <p className="label">Product</p>
              <p className="input-value">{name}</p>
            </div>
            {description ? (
              <div className="input-group__container">
                <p className="label">Product Description</p>
                <p className="input-value">{description} </p>
              </div>
            ) : null}
            
            {isSwaggerPresent() && <>
              <div className="input-group__container mt-2">
                <p className="label">OAS Spec</p>
                <p className="input-value">{selectedProduct.metadata.swagger.label}</p>
              </div>
              <div className="mt-2">
                <p className="text-content-color fs-18px fw-600">APIs</p> 
                {selectedProduct.metadata.swagger.resources?.map((each => {
                  if (each.selected) return (<div key={each.operationId} className="mt-1 surface-consumer-850 p-8px mx-0">
                  <div className="dfaic">
                    <div style={{width: 100}}>
                      <p className={`text-truncate text-center text-uppercase br-8px mt-3px p-8px fs-12px w-80px fw-500 api-method-tag ${each.method}`}>
                        {each.method}
                      </p>
                    </div>

                    <div className="">
                      <p className="fs-14px fw-500 text-content-color">
                        {each.operationId}
                      </p>
                      <p className="mt-1 fs-12px fw-500 grey-subtext">
                        {each.path}
                      </p>
                    </div>
                  </div>
                </div>)
                }))}
              </div>
            </>}

            {associatedGuides.length ? (
              <>
                <div className="input-group__container">
                  <p className="label">Docs</p>
                </div>
                <div className="d-flex flex-wrap gap-8px external-links__container">
                  {associatedGuides.map(el => {
                    return (
                      <div onClick={() => {
                        window.open(`${window.location.origin}/${ROUTE_NAMES.GUIDES}/${el.slug}`, "_blank")
                      }} className="external-link cursor">
                        {el.name}
                        <ArrowSquareOut
                          color="var(--content-subtle)"
                          className="cursor-pointer"
                        />
                      </div>
                    )
                  })}
                </div>
              </>
            ) : null}
            
          </div>
       
        </div>
      </div>
    </div>
  );
};
