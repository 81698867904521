import React, { useEffect, useRef, useState } from 'react'
import CustomNavbar from '../Dashboards/components/CustomNavbar';
import { MagnifyingGlass } from 'phosphor-react';
import CustomFooter from '../Dashboards/components/CustomFooter';
import API from '../../api';
import { throwServerError } from '../../utils/helper';
import { useNavigate } from 'react-router';
import PaginationV2 from '../../components/PaginationV2';
import Loading from '../../components/Loader/Loading';
import { objectDeepClone } from '@apiwiz/oas/services';
import GlobalIcons from '../../components/Icons/GlobalIcons';
import { EmptyState } from '../../components/EmptyState';

export default function UsecasesHome() {
    const [searchVal, setSearchVal] = useState('')
    const leftPanelRef = useRef(null);
    const rightPanelRef = useRef(null);
    const [borderHeight, setBorderHeight] = useState(0);
    const [paging, setPaging] = useState({
        total: 10, offset: 1, limit: 10
    });
    const [selectedFilters, setSelectedFilters] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [tags, setTags] = useState([])

    const reCalcBorderHeight = () => {
        setTimeout(() => {
            if(leftPanelRef.current && rightPanelRef.current)
            {
                setBorderHeight(Math.max(leftPanelRef.current.clientHeight, rightPanelRef.current.clientHeight))
            }
        })
    }

    useEffect(() => {
        reCalcBorderHeight();
        getProductsList(1)
        getTagsList()

        API.trackSessions('USECASES')
        .then(res => console.log('res', res))
        .catch(err => console.log('err', err))
    }, [])

    const getTagsList = () => {
        setIsLoading(true)
		API.getAllTags(1)
			.then((response) => {
				if (response.data.data) {
					setTags(
						response.data.data
					);
                    setIsLoading(false)
				}
			})
			.catch((error) => {
                setIsLoading(false)
            }
        );
	};

    const handlePageChange = (_pageNumber) => {
        setCurrentPage(_pageNumber)
        getProductsList(_pageNumber, selectedFilters, searchVal)
    }

    const getProductsList = (_current, _filter = '', searchVal='') => {
        setIsLoading(true);
        API.getAllProducts(_current, searchVal, _filter)
        .then((response) => {
            setIsLoading(false);

            if (response.data.data) {
            setData(response.data.data);
            setPaging({
                total: response.data?.paging?.total, 
                offset: currentPage, 
                limit: response.data?.paging?.limit, 
            });
            }
        })
        .catch((error) => {
            setIsLoading(false)
            throwServerError(error)
        });
  
    };

    const handleFilter = (_category) => {
        // let _categories = objectDeepClone(selectedFilters)
        
        // if (_categories.includes(_category)){
        //     let _id = _categories.indexOf(_category)
        //     _categories.splice(_id, 1)
        // } else {
        //     _categories.push(_category)
        // }
        setCurrentPage(1)
        if (selectedFilters === _category){
            setSelectedFilters('')
            getProductsList(1, '', searchVal)
            reCalcBorderHeight();
        } else {
            setSelectedFilters(_category)
            getProductsList(1, _category, searchVal)
            reCalcBorderHeight();
        }
    }

    function getArrayIntersection(array1, array2) {
        return array1.filter((value) => array2.includes(value));
    }

    const getFilteredData = (_data) => {
        let newArr = []
        _data.forEach(each => {
            // let regExp = new RegExp(searchVal, "i");
            // if (searchVal && each.title.search(regExp) === -1) return
            // if (selectedFilters.length > 0 && getArrayIntersection(selectedFilters, each.tags).length === 0) return

            newArr.push(each)
        })

        return newArr
    }
    
    return (
        <div className='w-100vw bg-currentBackground'>
            <CustomNavbar />

            <div className='mt-62px container'>
                <div className='d-flex justify-content-center guide-body bg-theme-base h-100vh'>
                    <div ref={leftPanelRef} className='d-flex flex-column guide-left-panel hideOnMobile sdksLeftPanel py-32px pr-32px h-100'>
                        <div className='searchSection mb-32px'>
                            <MagnifyingGlass color='#667085' size={15} />

                            <input type='text' placeholder='Search' value={searchVal}
                                onChange={(e) => {
                                    const _val = e.target.value
                                    setSearchVal(_val)
                                    getProductsList(1, selectedFilters, _val)
                                }} /> 
                        </div>

                        <p className='subTitle'>Filters</p>

                        <div className='categoryListSDK mt-24px'>
                            {tags.map((eachTag, i) => {
                                return ( <div className={`eachTag gap-4px mb-3 cursor d-flex align-items-center ${selectedFilters === eachTag.name ? 'active' : ''}`}
                                    key={eachTag._id} onClick={() => handleFilter(eachTag.name)}>
                                    <GlobalIcons color='var(--primary-color)' type={selectedFilters === eachTag.name ? 'form-check' : 'form-uncheck'} />
                                    <p className='text-truncate' style={{maxWidth: 240}} title={eachTag.name || ''}>{eachTag.name || ''}</p>
                                </div>)})}
                        </div>
                    </div>
                    <div style={{height: borderHeight}} className='w-2px bg-theme-base-200'></div>
                    <div ref={rightPanelRef} className='d-flex justify-content-center flex-column guide-content-panel p-32px w-100 h-100'>
                        <div className='w-100 h-100 overflow-scroll noscrollbar mb-12px'>
                            <div className="d-flex flex-column gap-4px align-items-center mb-24px">
                                <div className="fs-32px fw-600 lh-38px">Usecases</div>
                                <div className="fs-16px lh-28px">
                                    Welcome to Usecases! Need inspiration? Check out real-world Use cases for using our APIs.
                                </div>
                            </div>
                            {isLoading
                            ? <Loading />
                            : <>
                                <div className='d-flex w-100 sdkCardMainWrapper justify-content-center flex-wrap gap-24px'
                                    style={{ alignItems: 'flex-start'}}>
                                    {data?.length > 0
                                    ? data.map((each) => {
                                        return (<div className='sdkCardMain isUsecase flex-column' key={each._id}>
                                            <div className='imageSection'>
                                                <img src={each.imageUrl} alt='SDK Icon' />
                                            </div>

                                            <div className='detailsSection'>
                                                {each.tags?.length === 1 
                                                ? <div className='categoryBadge mt-4px mb-8px'>
                                                    {each.tags[0]}
                                                </div>
                                                : each.tags?.length > 1
                                                ? <div className='d-flex align-items-center mt-2 gap-8px'>
                                                    <div className='categoryBadge'>
                                                        {each.tags[0]}
                                                    </div>
                                                    <div className='categoryBadge'>
                                                        +{each.tags.length - 1}
                                                    </div>
                                                </div>
                                                : null}

                                                <p className='eachSDKTitle mt-0'>{each.title}</p>

                                                <div className='eachSDKDescription'>
                                                    <p className='text-truncate-3'>{each.description || ''}</p>
                                                </div>

                                                <div className='d-flex align-items-center justify-content-between w-100 mt-22px'>
                                                    <div className='w-100 text-center cursor br-4px bg-primary-color px-20px py-11px'
                                                        onClick={() => navigate(`/usecases/${each._id}`)}>
                                                        <p className='text-base-color fw-500 btnTextt mobileTextCenter'>View Details</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                    : <div className='mt-5'>
                                        <EmptyState />

                                        <p className='ps-5 text-content-color text-center'>No Usecases Found</p>
                                    </div>}
                                </div>
                            </>}
                        </div>
                        <PaginationV2 pagination={{...paging, offset: currentPage, pageSize: paging.limit}} cb={handlePageChange} />
                    </div>
                </div>
            </div>

            <CustomFooter />
        </div>
    )
    
}
