import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import Loading from '../../components/Loader/Loading';
import API from '../../api';
import defaultImage from './verification-failed.png'

export default function Active() {
    const { id } = useParams();
    const navigate = useNavigate()
    const dataFetchedRef = useRef(false);
    const [loading, setLoading] = useState(false)

    const verifyToken = () => {
        setLoading(true)
        API.verifyUserToken(id)
        .then(res => {
            navigate(`/validToken`)
        }).catch(err => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        verifyToken()
        dataFetchedRef.current = true;
    }, [dataFetchedRef.current])

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            {loading ?
            <div className='h-100 w-100'>
                <Loading />
            </div>: <div className='loginContainer h-100'>
                <div className='mb-20px d-flex align-items-center justify-content-center'>
                    <div className='p-18px'>
                        <img src={defaultImage} height={200} width={300}
                            alt='Invalid token' />
                    </div>
                </div>

                <div className='mb-24px'>
                    <h3 className='titleText v2'>
                        Sorry! Invalid Token
                    </h3>
                    <p className='descText v2'>
                        Your token has been expired.
                    </p>
                </div>

                <a href='/resend-token' type='button' className='border-0 gradientBtnInAuth mb-16px'>
                    Request New Token
                </a>
            </div>}

        </div>
    )
}
