import React, { useEffect, useRef, useState } from "react";
import htmlParser from "html-react-parser";
import { BsInfoCircleFill } from "react-icons/bs";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { useSelector } from "react-redux";
import { objectDeepClone } from "../../../utils/helper";
import MyReactTooltip from "../../ReactTooltip";
import { getUuidV4 } from "@apiwiz/oas/services";

const _uuid=getUuidV4()
const PropertyDescription = (props) => {
  const [desc, setDesc] = useState("");
  const [info, setInfo] = useState({
    show: false,
    style: {},
    placement: "bottom",
  });
  const infoRef = useRef(null);
  const { yAxisScroll } = useSelector((state) => state.swagger);
  const {
    field,
    type,
    example,
    description,
    showExpandOrCollapse,
    showChildren,
    required,
    borderSpacing,
    rangeText,
    enums,
    pattern,
    noBorderBottom,
    bodyProperty,
    expandOrCollapse,
  } = props;

  const htmlDescription = () => {
    let _desc = description;
    if (!_desc) return "";
    //DOUBT y to use regex to search
    let ticksRegex = /`([^`]+)`/g;
    function codeReplacer(match, p1) {
      return `<code>${p1}</code>`;
    }
    _desc = _desc.replace(ticksRegex, codeReplacer);
    _desc = _desc.replace(/(?:\r\n|\r|\n)/g, "<br />");
    console.log("_desc", _desc);
    return _desc;
  };

  const onExpandOrCollapse = () => {
    if (expandOrCollapse) expandOrCollapse();
  };

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  useEffect(() => {
    setDesc(htmlDescription(description));
  }, [description]);

  useEffect(() => {
    if (yAxisScroll && info.show) {
      setInfo({ ...info, show: false });
    }
  }, [yAxisScroll]);

  const onInfoClicked = (e) => {
    e.stopPropagation();
    let _info = objectDeepClone(info);
    let rect = infoRef.current.getBoundingClientRect();

    let _top = rect.y + rect.height + 10;
    _info.placement = "bottom";
    if (_top + 154 + 8 > window.innerHeight) {
      _top = rect.y - 154 - 10;
      _info.placement = "top";
    }

    _info.show = !_info.show;
    _info.style = { top: _top, left: rect.x + rect.width - 390 };
    setInfo(_info);
  };

  let _infoList = [];
  if (rangeText) _infoList.push({ field: "Range", value: rangeText });
  if (pattern) _infoList.push({ field: "Pattern", value: pattern });
  if (enums) _infoList.push({ field: "Enums", value: enums });
  if (desc) _infoList.push({ field: "Description", value: htmlParser(desc) });

  return bodyProperty ? (
    <div
      onMouseLeave={() => {
        if (info.show) setInfo({ ...info, show: false });
      }}
      onClick={(e) => {
        if (!info.show && expandOrCollapse) expandOrCollapse(e);
      }}
      className={`${showExpandOrCollapse ? "has-children cursor" : ""} ${
        showChildren ? "expanded" : ""
      } w-100 min-w-100 body-param-card d-flex justify-content-between align-items-center py-12px pl-31px pr-12px h-56px gap-16px`}
    >
      <div className="d-flex gap-12px">
        <div className="body-param-card-label fs-14px d-flex gap-2px">
          {field}{" "}
          {required && (
            <div>{required && <span className="text-danger">*</span>}</div>
          )}
        </div>
        {type ? <div className="fs-14px fw-600 text-nowrap">{enums ? "enum" : type}</div> : null}
      </div>
      {_infoList.length ? (
        <>
          <div
            onClick={onInfoClicked}
            className="body-param-info cursor"
            ref={infoRef}
          >
            <BsInfoCircleFill color="var(--secondary-color)" size={14} />
          </div>
          {info.show ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={`body-param-info-panel`}
              style={info.style}
            >
              <div className={`panel-tip ${info.placement} d-none`}>
                {info.placement === "top" ? (
                  <BiSolidDownArrow
                    color="var(--base-color)"
                    stroke="var(--secondary-100)"
                    strokeWidth={1}
                    size={16}
                  />
                ) : (
                  <BiSolidUpArrow
                    color="var(--base-color)"
                    stroke="var(--secondary-100)"
                    strokeWidth={1}
                    size={16}
                  />
                )}
              </div>
              <div className="panel-body overflow-scroll-y">
                {_infoList.map(el => {
                  return (
                    <div className="panel-body-field">
                  <div className="text-theme-content-color fs-12px fw-600 lh-22px d-flex justify-content-end">
                    {el.field}:
                  </div>
                  <div className="text-theme-content-color fs-12px lh-22px d-flex justify-content-start">
                    {el.value}
                  </div>
                </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  ) : (
    <div className="d-flex flex-column gap-4px py-12px pl-31px param-card">
      <div className="fs-12px text-theme-content-subtle d-flex gap-2px param-card-label">
        {field}{" "}
        {required && (
          <div>{required && <span className="text-danger">*</span>}</div>
        )}
      </div>
      <div className="br-4px bg-theme-primary-subtle border-theme-primary-subtle d-flex justify-content-between py-6px align-items-center px-12px">
        <div data-tip data-for={_uuid+String(field)} className="fs-14px text-theme-content-color lh-14px text-truncate" style={{maxWidth: "650px"}}>
          {example || `${type ? `"${type}"` : ""}`}
        </div>
        <MyReactTooltip
          placement={"right"}
          checkTruncate
          id={_uuid+String(field)}
          className="max-w-100-important"
        >
          <div className="px-8px py-4px text-break">
            {example || `${type ? `"${type}"` : ""}`}
          </div>
        </MyReactTooltip>
        <div className="theme-data-type-chip">{type}</div>
      </div>
      <div className="fs-14px text-theme-content-subtle lh-20px">
        {htmlParser(desc)}
      </div>
    </div>
  );
};

export default PropertyDescription;
