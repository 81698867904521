import React, { useState, useEffect } from 'react'
import API from '../../../../api'
import { getPermissionByCrud, objectDeepClone, throwServerError } from '../../../../utils/helper'
import S3Image from './s3.png'
import GCSImage from './gcs.png'
import JFROGImage from "./jfrog.png"
import AWSConnectorModal from './AWSConnectorModal'
import Loading from '../../../../components/Loader/Loading'
import GCSConnectorModal from './GCSConnectorModal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import JFROGConnectorModal from './JFROGConnectorModal'

export default function Connectors() {
    const [aws, setAws] = useState({
        isOpen: false, isEdit: false, data: {}
    })
    const [gcs, setGcs] = useState({
        isOpen: false, isEdit: false, data: {}
    })

    const [jfrog, setJfrog] = useState({
        isOpen: false, isEdit: false, data: {}
    })
    

    const [data, setData] = useState({})
    const [gcsData, setGcsData] = useState({})
    const [jfrogData,setJfrogData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAWSConnectorData()
        getGCSConnectorData()
        getJFROGConnectorData()
    }, [])

    const getAWSConnectorData = () => {
        setLoading(true)
        API.getAWSDetails()
        .then(res => {
            setLoading(false)
            setData(res.data.data || {})
        }).catch(err => {
            setData({})
            setLoading(false)
        })
    }

    const getGCSConnectorData = () => {
        setLoading(true)
        API.getGCSDetails()
        .then(res => {
            setLoading(false)
            setGcsData(res.data.data || {})
        }).catch(err => {
            setGcsData({})
            setLoading(false)
        })
    }

    const getJFROGConnectorData = () => {
        setLoading(true)
        API.getJFROGDetails()
        .then(res => {
            setLoading(false)
            setJfrogData(res.data.data || {})
        }).catch(err => {
            setJfrogData({})
            setLoading(false)
        })
    }


    const navigate = useNavigate()
     /* ACL Start */
     const {currentUser} = useSelector(state => state.user)
     const {permission} = useSelector(state => state.user)
 
     let _awsPermission = permission.filter(o => o.name === 'aws-artifactory')
     _awsPermission = _awsPermission.length ? (_awsPermission[0].crud || []) : []
     const getCreatePermissionAWS = getPermissionByCrud(currentUser, _awsPermission, 'create')
     const getReadPermissionAWS = getPermissionByCrud(currentUser, _awsPermission, 'read')
     const getUpdatePermissionAWS = getPermissionByCrud(currentUser, _awsPermission, 'update')
     const getDeletePermissionAWS = getPermissionByCrud(currentUser, _awsPermission, 'delete')

     let _gcsPermission = permission.filter(o => o.name === 'gcs-artifactory')
     _gcsPermission = _gcsPermission.length ? (_gcsPermission[0].crud || []) : []
     const getCreatePermissionGCS = getPermissionByCrud(currentUser, _gcsPermission, 'create')
     const getReadPermissionGCS = getPermissionByCrud(currentUser, _gcsPermission, 'read')
     const getUpdatePermissionGCS = getPermissionByCrud(currentUser, _gcsPermission, 'update')
     const getDeletePermissionGCS = getPermissionByCrud(currentUser, _gcsPermission, 'delete')


     let _jfrogPermission = permission.filter(o => o.name === 'jfrog-artifactory')
     _jfrogPermission = _jfrogPermission.length ? (_jfrogPermission[0].crud || []) : []
     const getCreatePermissionJFROG = getPermissionByCrud(currentUser, _jfrogPermission, 'create')
     const getReadPermissionJFROG = getPermissionByCrud(currentUser, _jfrogPermission, 'read')
     const getUpdatePermissionJFROG = getPermissionByCrud(currentUser, _jfrogPermission, 'update')
     const getDeletePermissionJFROG = getPermissionByCrud(currentUser, _jfrogPermission, 'delete')


     /* ACL END */
 
    //  if (!getReadPermissionAWS || !getReadPermissionGCS) navigate('/admin/profile-page')

    
    return (
        <div className='h-100 w-100 fadeIn'>
            <div className='px-16px py-9px surface-875 d-flex align-items-center justify-content-between br-tl-10px br-tr-10px v1-border-bottom-1'>
                <p className='text-100 fs-18px fw-600'>Connectors</p>

                <div style={{height: 35}}></div>
            </div>
            {loading 
            ? <Loading />
            : <div className='d-flex align-items-center gap-16px p-32px'>
                <div className='surface-875 p-16px br-4px connectorCard'>
                    <div className='v1-border-bottom-1'>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                            <img src={S3Image} alt='AWS S3' />

                            {data.isDefault && <div className='activeTag'>
                                <div className='greenDot'></div>
                                <p>Active</p>
                            </div>}
                        </div>

                        <p className='fw-500 fs-18px text-50 mb-2'>AWS S3</p>

                        <p className='text-400 fs-12px fw-400 mb-14px'>
                            Manage S3
                        </p>
                    </div>

                    {data?.AWS_ACCESS_KEY 
                    ? (getUpdatePermissionAWS ? <div>
                        <div className='v1-border-1 br-4px px-16px py-10px mt-3 cursor'
                            onClick={() => setAws({isOpen: true, isEdit: true, data: objectDeepClone(data)})}>
                            <p className='fw-500 fs-14px text-300 text-center'>Edit</p>
                        </div>
                    </div> : <div style={{height: 40}}></div>)
                    : (getCreatePermissionAWS ? <div className='v1-border-1 br-4px px-16px py-10px mt-3 cursor'
                        onClick={() => setAws({isOpen: true, isEdit: false, data: {}})}>
                        <p className='fw-500 fs-14px text-300 text-center'>Create</p>
                    </div> : <div style={{height: 40}}></div>)}
                </div>

                <div className='surface-875 p-16px br-4px connectorCard'>
                    <div className='v1-border-bottom-1' style={{height: 105}}>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                            <img src={GCSImage} alt='AWS S3' />

                            {gcsData.isDefault && <div className='activeTag'>
                                <div className='greenDot'></div>
                                <p>Active</p>
                            </div>}
                        </div>

                        <p className='fw-500 fs-18px text-50 mb-2'>GCS</p>

                        <p className='text-400 fs-12px fw-400 mb-3'>
                            Manage GCS
                        </p>
                    </div>

                    {gcsData?.bucketName 
                        ? (getUpdatePermissionGCS ? <div>
                            <div className='v1-border-1 br-4px px-16px py-10px mt-3 cursor'
                                onClick={() => setGcs({isOpen: true, isEdit: true, data: objectDeepClone(gcsData)})}>
                                <p className='fw-500 fs-14px text-300 text-center'>Edit</p>
                            </div>
                        </div> : <div style={{height: 41}}></div> )
                        : (getCreatePermissionAWS ? <div className='v1-border-1 br-4px px-16px py-10px mt-3 cursor'
                            onClick={() => setGcs({isOpen: true, isEdit: false, data: {}})}>
                            <p className='fw-500 fs-14px text-300 text-center'>Create</p>
                        </div> : <div style={{height: 41}}></div> )}
                </div>

                <div className='surface-875 p-16px br-4px connectorCard'>
                    <div className='v1-border-bottom-1' style={{height: 105}}>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                            <img src={JFROGImage} alt='AWS S3' height={30} width={30} />

                            {jfrogData.isDefault && <div className='activeTag'>
                                <div className='greenDot'></div>
                                <p>Active</p>
                            </div>}
                        </div>

                        <p className='fw-500 fs-18px text-50 mb-2'>JFROG</p>

                        <p className='text-400 fs-12px fw-400 mb-3'>
                            Manage JFROG
                        </p>
                    </div>

                    {jfrogData?.JFROG_REPOSITORY 
                        ? (getUpdatePermissionJFROG ? <div>
                            <div className='v1-border-1 br-4px px-16px py-10px mt-3 cursor'
                                onClick={() => setJfrog({isOpen: true, isEdit: true, data: objectDeepClone(jfrogData)})}>
                                <p className='fw-500 fs-14px text-300 text-center'>Edit</p>
                            </div>
                        </div> : <div style={{height: 41}}></div> )
                        : (getCreatePermissionJFROG ? <div className='v1-border-1 br-4px px-16px py-10px mt-3 cursor'
                            onClick={() => setJfrog({isOpen: true, isEdit: false, data: {}})}>
                            <p className='fw-500 fs-14px text-300 text-center'>Create</p>
                        </div> : <div style={{height: 41}}></div> )}
                </div>
            </div>}

            {aws.isOpen && <AWSConnectorModal show={aws.isOpen}
                getDeletePermissionAWS={getDeletePermissionAWS}
                isEdit={aws.isEdit} data={aws.data} callback={() => {
                    getAWSConnectorData()
                    getGCSConnectorData()
                    getJFROGConnectorData()
                }}
                onHide={() => setAws({isEdit: false, isOpen: false, data: {}})} />}

            {gcs.isOpen && <GCSConnectorModal show={gcs.isOpen}
                getDeletePermissionGCS={getDeletePermissionGCS}
                isEdit={gcs.isEdit} data={gcs.data} callback={() => {
                    getGCSConnectorData()
                    getAWSConnectorData()
                    getJFROGConnectorData()
                }}
                onHide={() => setGcs({isEdit: false, isOpen: false, data: {}})} />}

            {jfrog.isOpen && <JFROGConnectorModal show={jfrog.isOpen}
                getDeletePermissionJFROG={getDeletePermissionJFROG}
                isEdit={jfrog.isEdit} data={jfrog.data} callback={() => {
                    getGCSConnectorData()
                    getAWSConnectorData()
                    getJFROGConnectorData()
                }}
                onHide={() => setJfrog({isEdit: false, isOpen: false, data: {}})} />}
            
        </div>
    )
}
