import { Info, X } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import MonthYearPicker from '../components/MonthYearPicker';
import AppAccordion from './AppAccordion';
import { useSelector } from 'react-redux';
import { defaultCurrencyFn, labelizeWithPlural, numberToWord, pluralize, throwServerError } from '../../../../utils/helper';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { concernTexts, pdfComponents } from '../../../../service/constants';
import moment from 'moment';
import ReportIssue from '../ReportIssue';
import API from '../../../../api';
import MyReactTooltip from '../../../../components/ReactTooltip';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    interFont: {
        normal: `${window.location.origin}/font-inter-regular.ttf`,
        bold: `${window.location.origin}/font-inter-bold.ttf`,
        italics: `${window.location.origin}/font-inter-thin.ttf`,
        bolditalics: `${window.location.origin}/font-inter-extra-bold.ttf`,
    },
    Roboto: {
        normal: `${window.location.origin}/roboto-mono-light-italic.ttf`,
        italics: `${window.location.origin}/roboto-mono-light-italic.ttf`,
    },
};

pdfMake.tableLayouts = {
    myTableLayout: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            }
            return i === node.table.headerRows ? 0.4 : 0.1;
        },
        vLineWidth: function (i) {
            return 0;
        },
        hLineColor: function (i) {
            return i === 1 ? "#aaa" : "#fff";
        },
        paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
            return i === 0 ? 0 : 8;
        },
        fillColor: function (i, node) {
            const _i = i - 1;
            if (_i === -1 || _i % 2 === 0) return null;
            return "#F4F5F5";
        },
    },
    successLayout: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            }
            return 0;
        },
        vLineWidth: function (i) {
            return 0;
        },
        hLineColor: function (i) {
            return "#fff";
        },
        paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
            return i === node.table.widths.length - 1 ? 0 : 8;
        },
        fillColor: function (i, node) {
            return "#E5FBEB";
        },
    },
    failureLayout: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            }
            return 0;
        },
        vLineWidth: function (i) {
            return 0;
        },
        hLineColor: function (i) {
            return "#fff";
        },
        paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
            return i === node.table.widths.length - 1 ? 0 : 8;
        },
        fillColor: function (i, node) {
            return "#FFE5E5";
        },
    },
};

export default function InvoiceModal(props) {
    const [data, setData] = useState({})
    const {currentUser} = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.data){
            setData(props.data || {})
        }
    }, [])
    
    const getPackageCount = (_data) => {
        let packageCount = 0

        Object.keys(_data.invoiceData || {}).forEach((_) => {
            const eachVal = _data.invoiceData[_]
            packageCount += (eachVal.length || 0)
        })

        return packageCount
    }

    const formatData = (_data) => {
        setLoading(true)
        let _total = 0
        let packageCount = 0

        let _invoices = []

        API.readInvoiceDownload(_data.invoiceId, currentUser.email)
        .then(res => {
            console.log('res', res)
        }).catch(err => {
            console.log('err', err)
            // throwServerError(err)
        })

        // let invoiceHashmap = {}

        Object.keys(_data.invoiceData)?.forEach((_) => {
            const eachVal = _data.invoiceData[_]
            packageCount += (eachVal.length || 0)

            if (eachVal.length === 0) return

            _invoices.push({
                layout: 'noBorders', 
                table: {
                    headerRows: 0,
                    widths: [ 240, 90, '*' ],

                    body: [
                    [ 
                        pdfComponents.paragraph({text: eachVal[0]?.appName || '', bold: true, color: '#333', fontSize: 11, margin: [10, 10, 0, -10]}), 
                        pdfComponents.paragraph({text: `${pluralize(eachVal.length, 'Package')}`, bold: true, color: '#245530', fontSize: 8.5, margin: [0, 10, 0, -10]}),
                        pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${(eachVal.reduce((acc, curr) => acc + (curr.totalAmount), 0))?.toLocaleString()}`, bold: true, color: '#333', fontSize: 11, styles: {alignment: 'right'}, margin: [0, 10, 10, -10]}), 
                    ],
                    ]
                },
                margin: [0,-15,0,8]
            })

            let bodies = []

            eachVal.forEach((each) => {
                _total += (each.totalAmount || 0)
                bodies.push([ 
                    pdfComponents.paragraph({text: each.packageName, bold: false, color: '#333', fontSize: 11, margin: [12, 5]}), 
                    pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${(each.totalAmount || 0)?.toLocaleString()}`, bold: false, color: '#333', fontSize: 11, styles: {alignment: 'right'}, margin: [12, 5]}), 
                ])
            })

            _invoices.push({
                layout: {
                    hLineWidth: function (i, node) {
                        // if (i === 0 || i === node.table.body.length) {
                        // 	return 0;
                        // }
                        // return (i === node.table.headerRows) ? 2 : 1;
                        return 0
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                    hLineColor: function (i) {
                        return i === 1 ? 'black' : '#aaa';
                    },
                    paddingLeft: function (i) {
                        return i === 0 ? 0 : 8;
                    },
                    paddingRight: function (i, node) {
                        return (i === node.table.widths.length - 1) ? 0 : 8;
                    },
                    fillColor: function (i, node) {
                        return (i % 2 === 0) ? '#EDF0F7' : '#FAFBFE';
                    }
                }, 
                table: {
                    headerRows: 0,
                    widths: [ 300, '*' ],
            
                    body: [
                        ...bodies,
                    ]
                },
                margin: [0,0,0,30]
            })
        })

        // console.log('invoiceHashmap', invoiceHashmap)
        let docDefinition = {
            content: [
                pdfComponents.paragraph({text: 'Invoice', bold: true, fontSize: 16, margin: [0, 0, 0, 6]}),
                data.disputedAmount 
                ? {
                    layout: 'noBorders', 
                    table: {
                      headerRows: 0,
                      widths: [ 80,'*','*', '*','*' ],
              
                      body: [
                        [ 
                            pdfComponents.paragraph({text: 'Bill Amount', bold: false, fontSize: 9.5, margin: [0, 10, -8, -10]}), 
                            pdfComponents.paragraph({text: 'Usage Charge', bold: false, fontSize: 9.5, margin: [0, 10, 0, -10]}), 
                            pdfComponents.paragraph({text: 'Reconciled Amount', bold: false, fontSize: 9.5, margin: [0, 10, 0, -10]}), 
                            '',
                            pdfComponents.paragraph({text: 'Invoice Date', bold: false, fontSize: 9.5, margin: [0, 10, 0, -10], styles: {alignment: 'right'}}), 
                        ],
                        [ 
                            pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${(data.totalExpenditure - data.disputedAmount)?.toLocaleString()} `, bold: true, fontSize: 10.5, margin: [0, 10, 0, 0]}), 
                            pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${data.totalExpenditure?.toLocaleString()}`, bold: true, fontSize: 10.5, margin: [0, 10, 0, 0]}), 
                            pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${data.disputedAmount?.toLocaleString()}`, bold: true, fontSize: 10.5, margin: [0, 10, 0, 0]}), 
                            '',
                            pdfComponents.paragraph({text: `${moment().month(data.month - 1).format("MMMM")} ${data.year}`, bold: true, fontSize: 10.5, margin: [0, 10, 0, -10], styles: {alignment: 'right'}}),
                        ],
                      ]
                    },
                    margin: [0,0,0,3]
                }
                : {
                    layout: 'noBorders', 
                    table: {
                      headerRows: 0,
                      widths: [ '*', 'auto', '*' ],
              
                      body: [
                        [ 
                            pdfComponents.paragraph({text: 'Bill Amount', bold: false, fontSize: 11, margin: [0, 10, 0, -10]}), 
                            '',
                            pdfComponents.paragraph({text: 'Invoice Date', bold: false, fontSize: 11, styles: {alignment: 'right'}, margin: [0, 10, 0, -10]}), 
                        ],
                        [ 
                            pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency)} ${(data.totalExpenditure - (data.disputedAmount || 0))?.toLocaleString()}`, bold: true, fontSize: 20, margin: [0, 10, 0, 0]}), 
                            '',
                            pdfComponents.paragraph({text: `${moment().month(_data.month - 1).format("MMMM")} ${_data.year}`, bold: true, fontSize: 11, styles: {alignment: 'right'}, margin: [0, 10, 0, 0]}),
                        ],
                      ]
                    },
                    margin: [0,0,0,14]
                },
                {
                    layout: 'noBorders', 
                    table: {
                      headerRows: 0,
                      widths: [ '*', 'auto', '*' ],
              
                      body: [
                        [ 
                            pdfComponents.paragraph({text: 'Invoice Id', color: '#666', bold: false, fontSize: 11, margin: [0, 4, 0, -10]}), 
                            '',
                            pdfComponents.paragraph({text: 'Quantity', color: '#666', bold: false, fontSize: 11, styles: {alignment: 'right'}, margin: [0, 4, 0, -10]}), 
                        ],
                        [ 
                            pdfComponents.paragraph({text: _data.invoiceId, bold: true, fontSize: 11, margin: [0, 10, 0, 0]}), 
                            '',
                            pdfComponents.paragraph({text: [
                                {text: `${pluralize(Object.keys(_data.invoiceData).length, 'App')}`, color: '#464646', bold: true, },
                                {text: ` (${pluralize(packageCount, 'Package')})`, color: '#252525'}
                            ], fontSize: 11, styles: {alignment: 'right'}, 
                            margin: [0, 10, 0, 0]}),
                        ],
                        // [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ],
                        // [ { text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4' ]
                      ]
                    },
                    margin: [0,0,0,14]
                },
                {
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0) ? 0 : 0.5;
                        },
                        vLineWidth: function(i, node){
                            return 0;
                        }
                    }, 
                    table: {
                      widths: [ 510 ],
                      headerRows: 1,
              
                      body: [
                        [ 
                            pdfComponents.paragraph({
                                text: 'Billing Information', 
                                styles: {borderColor: ['white', 'white', 'white', '#CAD0DB']},
                                bold: true, fontSize: 11, color: '#252525', margin: [0, 10, 0, 1]
                            }), 
                        ],
                      ]
                    }
                },
                {
                    layout: 'noBorders', 
                    table: {
                      headerRows: 0,
                      widths: [ 510 ],
              
                      body: [
                        [
                            pdfComponents.paragraph({
                                text: 'Consumer',
                                color: '#252525',
                                fontSize: 11,
                                bold: true,
                                margin: [6, 10, 0, 0]
                            })
                        ],
                        [
                            pdfComponents.paragraph({
                                text: _data.developer?.username,
                                color: '#252525',
                                fontSize: 11,
                                margin: [6, 0, 0, 0]
                            }) , 
                        ],
                        [
                            pdfComponents.paragraph({
                                text: 'Email',
                                color: '#252525',
                                fontSize: 11,
                                bold: true,
                                margin: [6, 8, 0, 0]
                            })
                        ],
                        [
                            pdfComponents.paragraph({
                                text: _data.developer?.email,
                                color: '#252525',
                                fontSize: 11,
                                margin: [6, 0, 0, 0]
                            }) , 
                        ],
    
                        [
                            pdfComponents.paragraph({
                                text: 'Billing Address',
                                color: '#252525',
                                fontSize: 11,
                                bold: true,
                                margin: [6, 8, 0, 0]
                            })
                        ],
                        [
                            pdfComponents.paragraph({
                                text: currentUser.billing?.billingAddress || '',
                                color: '#252525',
                                fontSize: 11,
                                margin: [6, 0, 0, 0]
                            }) , 
                        ]
                    ]
                    },
                    margin: [0,0,0,14]
                },
    
                pdfComponents.paragraph({text: 'App Details', color: '#333', fontSize: 12, bold: true, margin: [0,0,0,0]}),
                pdfComponents.horizontalLine({lineWidth: 0.1}),

                ..._invoices,
            ],
            defaultStyle: {
                font: "interFont",
            },
            pageMargins: [36, 30, 36, 30],
        };

        if (data.disputedAmount){
            docDefinition.content.push({
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0) ? 0 : 0.5;
                    },
                    vLineWidth: function(i, node){
                        return 0;
                    }
                }, 
                table: {
                  widths: [ 240, 55, 205 ],
                  headerRows: 1,
                 
                  body: [
                    [ 
                        pdfComponents.paragraph({
                            text: 'Usage Charge (A)', 
                            styles: {borderColor: ['white', 'white', 'white', '#CAD0DB']},
                            bold: true, fontSize: 10.5, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                        pdfComponents.paragraph({
                            text: `${labelizeWithPlural(Object.keys(data.invoiceData || {})?.length || 0, 'App')}`, 
                            styles: {borderColor: ['white', 'white', 'white', '#CAD0DB']},
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                        pdfComponents.paragraph({
                            text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${data.totalExpenditure?.toLocaleString()} `, 
                            styles: {borderColor: ['white', 'white', 'white', '#CAD0DB'], alignment: 'right'},
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                    ],

                    [
                        pdfComponents.paragraph({
                            text: `Usage Charge (A) in words `, 
                            styles: {borderColor: ['white', 'white', 'white', 'white']},
                            bold: false, fontSize: 9, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                        pdfComponents.paragraph({
                            text: ` `, 
                            styles: {borderColor: ['white', 'white', 'white', 'white']},
                            bold: false, fontSize: 9, color: '#252525', margin: [0, 10, 0, 1]
                        }),
                        pdfComponents.paragraph({
                            text: `${numberToWord(data.totalExpenditure)} `, 
                            styles: {borderColor: ['white', 'white', 'white', 'white'], alignment: 'right'},
                            bold: false, fontSize: 9, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                    ],
                  ]
                },
                margin: [0,-15,0,12]
            })

            docDefinition.content.push( {
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0) ? 0 : 0.5;
                    },
                    vLineWidth: function(i, node){
                        return 0;
                    }
                }, 
                table: {
                  widths: [ 240, 55, 205 ],
                  headerRows: 1,
          
                  body: [
                    [
                        pdfComponents.paragraph({
                            text: `Total Reconciled Amount (B)`, 
                            styles: {borderColor: ['white', 'white', 'white', 'white']},
                            bold: true, fontSize: 10, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                        pdfComponents.paragraph({
                            text: ` `, 
                            styles: {borderColor: ['white', 'white', 'white', 'white']},
                            bold: false, fontSize: 9, color: '#252525', margin: [0, 10, 0, 1]
                        }),
                        pdfComponents.paragraph({
                            text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${data.disputedAmount?.toLocaleString()}`, 
                            styles: {borderColor: ['white', 'white', 'white', 'white'], alignment: 'right'},
                            bold: true, fontSize: 9, color: '#252525', margin: [0, 10, 0, 1]
                        }), 
                    ],
                  ]
                },
                margin: [0,-10,0,24]
            })

            docDefinition.content.push({
                        
                layout: {
                    hLineWidth: function (i, node) {
                        return 0
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                    hLineColor: function (i) {
                        return i === 1 ? 'black' : '#aaa';
                    },
                    paddingLeft: function (i) {
                        return i === 0 ? 0 : 8;
                    },
                    paddingRight: function (i, node) {
                        return (i === node.table.widths.length - 1) ? 0 : 8;
                    },
                    fillColor: function (i, node) {
                        return (i % 2 === 0) ? '#EDF0F7' : '#FAFBFE';
                    }
                }, 
                table: {
                    headerRows: 0,
                    widths: [ 200, '*' ],
            
                    body: [
                        [ 
                            pdfComponents.paragraph({text: 'Grand Total (A - B)', bold: false, color: '#333', fontSize: 10, margin: [12, 5]}), 
                            pdfComponents.paragraph({text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${(data.totalExpenditure - data.disputedAmount)?.toLocaleString()}`, bold: true, color: '#333', fontSize: 10, styles: {alignment: 'right'}, margin: [12, 5]}), 
                        ],
                        [ 
                            pdfComponents.paragraph({text: 'Grand Total in Words', bold: false, color: '#333', fontSize: 10, margin: [12, 5]}), 
                            pdfComponents.paragraph({text: `${numberToWord(data.totalExpenditure - data.disputedAmount)}`, bold: true, color: '#333', fontSize: 10, styles: {alignment: 'right'}, margin: [12, 5]}), 
                        ]
                    ]
                },
                margin: [0,0,0,30]
            })
        } else {
            docDefinition.content.push({
                layout: {
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    hLineColor: function (i, node){
                        return '#CAD0DB'
                    },
                    vLineWidth: function(i, node){
                        return 0;
                    }
                }, 
                table: {
                  widths: [ 223, 71, 71, 130 ],
                  headerRows: 1,
          
                  body: [
                    [ 
                        pdfComponents.paragraph({
                            text: 'Total(In Words)', bold: false,
                            fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7],
                        }), 
                        pdfComponents.paragraph({
                            text: 'No. of Apps', 
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: 'No. of Packages', 
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: 'Amount', styles: {alignment: 'right'},
                            bold: false, fontSize: 9.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                    ],
    
                    [ 
                        pdfComponents.paragraph({
                            text: `${numberToWord(data.totalExpenditure)}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white']},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: `${pluralize(Object.keys(_data.invoiceData).length, 'App')}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white']},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: `${pluralize(packageCount, 'Package')}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white']},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                        pdfComponents.paragraph({
                            text: `${defaultCurrencyFn(currentUser.billing?.billingCurrency) || ''} ${(data.totalExpenditure || 0)?.toLocaleString()}`, bold: true,
                            styles: {borderColor: ['white', '#CAD0DB', 'white', 'white'], alignment: 'right'},
                            fontSize: 10.5, color: '#252525', margin: [0, 7, 0, 7]
                        }), 
                    ],
                  ]
                }
            })
        }

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download(`${currentUser.username}_invoice_${moment().month(_data.month - 1).format("MMM")}${_data.year}`, () => {
            setLoading(false)
        });

    }

    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='homeModalForLghtMode br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-8px br-0px surface-consumer-875'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <div className='dfaic'>
                        <p className='fs-16px fw-500 text-consumer-100 ms-2'>
                            View Invoice Details 
                        </p>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 px-6px py-6px fs-12px fw-400 me-1 cursor br-4px' 
                            onClick={props.onHide} style={{border: '0.5px solid var(--content-color)'}}>
                            <X size={16} className='text-content-color' />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 118px)" }}
                className="br-0 p-0 overflow-scroll noscrollbar"
            >
                <div className='p-16px'>
                    <div className='dfaic justify-content-between'>
                        <p className='fs-24px fw-600 text-content-color dfaic gap-8px'>
                            {defaultCurrencyFn(currentUser.billing?.billingCurrency)} {(data?.totalExpenditure - (data.disputedAmount || 0))?.toLocaleString()} 

                            {data.disputedAmount && <div className='cursor'
                                data-for='viewInfo11' data-tip={`${defaultCurrencyFn(currentUser.billing?.billingCurrency)}${data.disputedAmount} adjusted from the original amount ${defaultCurrencyFn(currentUser.billing?.billingCurrency)}${data.totalExpenditure} after reconciliation`}>
                                <Info size={16} />

                                <MyReactTooltip id='viewInfo11' />
                            </div>}
                        </p>

                        <div>
                        <ReportIssue concernText={concernTexts.BILLING}
                            handleClick={() => props.handleIssue(data.invoiceId)}
                            data={{packageId: data.invoiceId}} 
                            allInvoices={props.allInvoices || []} />
                        </div>
                    </div>

                    <div className='row mt-4 mx-0'>
                        <div className='col-md-6 ps-0'>
                            <p className='fs-14px fw-400 grey-subtext'>Invoice Id</p>
                            <p className='fs-14px fw-600 text-content-color'>
                                {data.invoiceId || ''}
                            </p>
                        </div>

                        <div className='col-md-6 ps-0'>
                            <p className='fs-14px fw-400 grey-subtext'>Quantity:</p>
                            <p className='fs-14px fw-600 text-content-color'>{pluralize(Object.keys(data?.invoiceData || {})?.length, 'App')} <span className='fw-400'>({pluralize(getPackageCount(data), 'Package')})</span></p>
                        </div>
                    </div>

                    <div className='row mt-4 mx-0'>
                        <div className='col-md-12 pb-6px px-0'
                            style={{borderBottom: '2px solid var(--base-100)'}}>
                            <p className='fs-14px fw-400 grey-subtext'>Billing Information</p>
                        </div>
                    </div>

                    <div className='row mt-2 mx-0'>
                        <div className='col-md-12 ps-0'>
                            <div className='mb-16px'>
                                <p className='text-content-subtle fs-14px fw-400'>Consumer</p>
                                <p className='text-content-color fs-14px fw-600'>{currentUser.username}</p>
                            </div>

                            <div className='mb-16px'>
                                <p className='text-content-subtle fs-14px fw-400'>Email</p>
                                <p className='text-content-color fs-14px fw-600'>{currentUser.email}</p>
                            </div>

                            <div className='mb-16px'>
                                <p className='text-content-subtle fs-14px fw-400'>Billing Address</p>
                                <p className='text-content-color fs-14px fw-600'>
                                    {currentUser.billing?.billingAddress || '-'}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='mt-2 pb-6px' style={{borderBottom: '2px solid var(--base-100)'}}>
                        <p className='grey-subtext fs-16px fw-600'>App Details</p>

                        <div className='mt-3'>
                            {Object.keys(data.invoiceData || {}).map((each, i) => {
                                const eachVal = data.invoiceData[each]
                                if (!eachVal.length) return
                                return (<AppAccordion data={eachVal} key={each} />)
                            })}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-base-color'>
                {loading 
                ? <div className='btn btn-primary w-100 pb-1' style={{height: 36}}>
                    <p className='fs-14px fw-600 text-white text-center'>Downloading...</p>
                </div>
                : <div className='btn btn-primary w-100 pb-1' style={{height: 36}} onClick={() => formatData(data)}>
                    <p className='fs-14px fw-600 text-white text-center'>Download Invoice</p>
                </div>}
            </Modal.Footer>
        </Modal>
    )
}
