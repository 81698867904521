import { objectDeepClone } from '@apiwiz/oas/services';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { NumberFormatter, convertExpressionToGraph, throwServerError } from '../../../../utils/helper';
import { X } from 'phosphor-react';
import Select from 'react-select'
import MeteringGroup from './MeteringGroup';
import MeteringElement from './MeteringElement';
import RateCard from './RateCard';
import ContainerLoader from '../../../../components/Loader/ContainerLoader';
import DoughnutChart from '../../../../components/Charts/DoughnutChart';
import monetizationV2 from '../../../../api/monetizationV2';
import { useSelector } from 'react-redux';
import { useTheme } from '../../../../context/ThemeContext';
import { useLocation, useParams } from 'react-router';
import MonetizationIcons from '../../../../components/Icons/MonetizationIcons';

const picChartColor = (theme) => theme === 'theme--dark' ? ["#DBA16E", "#F1A10D", "#2EC8EE", "#FF802B", "#52A9FF", "#F76190", "#9E8CFC", "#FF6369", "#63C174", "#BF7AF0"] : ["#886349", "#AD5700", "#0078A1", "#ED5F00", "#006ADC", "#D31E66", "#5746AF", "#CD2B31", "#297C3B", "#793AAF"]

export default function MeteringRulesModal(props) {
    const { theme } = useTheme()
    const { showMeteringChart=false,date } = props;
    const { packageId } = useParams()
    const { search } = useLocation();
    const _queryPrams = new URLSearchParams(search);
    const appId = _queryPrams.get("appId");
    const pageType = _queryPrams.get("type");
    const [loading, setLoading] = useState(false)
    const [meteringRulesList, setMeteringRulesList] = useState([])
    const [meteringStats, setMeteringStats] = useState([])
    const { currentUser } = useSelector((state) => state.user)
    const currency = currentUser?.billing?.billingCurrency?.currencySymbol || currentUser?.billing?.billingCurrency?.currencyCode || ''

    const [blocksJson, setBlocksJson] = useState({})
    const [rules, setRules] = useState([])
    const [activeTab, setActiveTab] = useState('Rule Details')
    const [selectedRule, setSelectedRule] = useState({})

    const callbackFn = (res) => {
        if (res.data && res.data.length > 0) {
            const _temp = res.data.map((x, ind) => {
                let index = ind;
                while (index >= 10) index = index % 10;

                const ruleName = x.meteringId ? x.meteringName : "Default Rate Card";
                const totalAmount = parseFloat(x.totalAmount * currentUser?.billing?.billingCurrency?.unitAmount || 0);
                let temp = { value: totalAmount, color: picChartColor(theme)[index] || "#46a758" }

                temp = {...temp, name: ruleName, customData: { ruleName, totalAmount }}

                return temp
            })

            setMeteringRulesList(_temp);
            setMeteringStats([..._temp]);
        } else {
            setMeteringRulesList([]);
            setMeteringStats([]);
        }
    }

    const fetchMeteringRulesData = () => {
        let params = { month: date.currentMonth, year: date.currentYear, developerId: currentUser.email, packageId, resourceId: props?.data?.resourceId ||'' };
        setLoading(true)

        if(pageType === 'app'){
            params = {...params, appId} 
            monetizationV2.fetchAppResourceMeteringRules(params)
            .then(({data: res}) => {
                callbackFn(res)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                throwServerError(err);
            });
        }else{
            monetizationV2.fetchResourceMeteringRules(params)
            .then(({data: res}) => {
                callbackFn(res)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                throwServerError(err);
            });
        }
    }

    const tooltipInnerHtml = ({ruleName,totalText}) => {
        let html = "";
        html += `<div class="d-flex flex-column gap-4px align-items-center justify-content-center text-consumer-100 fs-12px fw-500 gap-2px ">
                <div class="text-nowrap">${ruleName}</div>
                <div class="text-nowrap">${totalText}</div>
            </div>`;
        return html
    }

    const tooltipForRevenue = (tooltipItem) => {
        return tooltipInnerHtml({
            ruleName: (tooltipItem.dataset.customData?.ruleName||[])[tooltipItem.dataIndex],
            totalText: `${tooltipItem.dataset.data[tooltipItem.dataIndex]}`
        })
    }

    const customTooltip = (fn, {tooltipEl, tooltipModel}) => {
        console.log(fn, {tooltipEl, tooltipModel},'log');
        const tooltipData = tooltipModel.dataPoints;

        tooltipEl.style.display = 'block';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${tooltipModel.caretX}px`;
        tooltipEl.style.top = `${tooltipModel.caretY - 84}px`;
      
        let tooltipHTML = '<div class="surface-consumer-base br-4px border-strokes-600-01">';
        tooltipHTML += `<div class="py-5px px-8px d-flex flex-column gap-6px">`;
        for (const tooltipItem of tooltipData) {
            if(fn) {
                tooltipHTML += fn(tooltipItem)
            }
        }
        tooltipHTML += `</div>`;
        tooltipHTML += '</div>';
      
        tooltipEl.innerHTML = tooltipHTML;
    }

    useEffect(() => {
        if(showMeteringChart) fetchMeteringRulesData();

        const _data = objectDeepClone(props.data?.meteringRules || [])

        console.log('props.data', props.data)

        setRules(objectDeepClone(_data))

        if (_data.length > 0){
            let first = objectDeepClone(_data[0])

            setSelectedRule({...first, label: first?.ruleName, value: first?.ruleName})
        }
    }, [])

    useEffect(() => {
        if (selectedRule.ruleName){
            let _filtered = selectedRule.condition?.expression || ''
            let _json = convertExpressionToGraph(_filtered, selectedRule.condition?.placeHolders || [])

            setBlocksJson(_json)
        } else {
            setBlocksJson({})
        }
    }, [selectedRule])
    
    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='homeModalForLghtMode br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px surface-consumer-875'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <div className='d-flex'>
                        <p className={`fs-12px fw-600 text-uppercase v2-text-${props.data?.resourceMethod?.toUpperCase()}`}>{props.data?.resourceMethod}</p>
                        <div className='fs-16px fw-500 text-consumer-100 ms-2 d-block'
                            style={{marginTop: '-3.5px'}}>
                            {props.data?.resourceName || ''}
                        </div>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 px-6px py-6px fs-12px fw-400 me-1 cursor br-4px' 
                            onClick={props.onHide} style={{border: '0.5px solid var(--content-color)'}}>
                            <X size={16} className='text-content-color' />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 65px)" }}
                className="br-0 p-0 overflow-scroll noscrollbar"
            >
                {showMeteringChart ? (
                    <div className='p-16px surface-consumer-900 border-strokes-600-01 d-flex align-items-center' style={{ height: 266 }}>
                        {loading ? (
                            <ContainerLoader variant="theme-primary" className="w-100 h-100" />
                        ) : (
                            (meteringRulesList && meteringRulesList.length) ? (
                            <>
                                <div className="w-50 h-100 pie-chart-scrollbar">
                                    <p className="text-consumer-400 fs-14px fw-400">Metering Rules</p>
                                    <div className="d-flex flex-column gap-4px overflow-scroll overflow-x-hidden" style={{ height: "calc(100% - 16px)" }}>
                                        {meteringRulesList && meteringRulesList.length > 0
                                            ? meteringRulesList.map((x) => (
                                                    <div className="d-flex align-items-center gap-8px">
                                                        <div style={{ height: 10, width: 10, borderRadius: "50%", backgroundColor: x.color }}></div>
                                                        <div className="text-consumer-200 fs-12px fw-400">{x?.name || "-"}</div>
                                                    </div>
                                                ))
                                            : null}
                                    </div>
                                </div>
                                <div className="w-50 h-100">
                                    <DoughnutChart
                                        showTotal={false}
                                        tooltipLabel="Revenue"
                                        cutout={68}
                                        showTooltip
                                        updateChart={true}
                                        totalTitle="Rules"
                                        className="text-400"
                                        series={meteringStats}
                                        currencySymbol={currency}
                                        currecnyWeight={props.currentCurrency.unitAmount}
                                        customTooltip={(...args) => customTooltip(tooltipForRevenue, ...args)}
                                        children={
                                            <div className={`d-flex flex-column justify-content-center gap-8px position-absolute align-items-center h-100 w-100`}>
                                                <div className="fs-36px fw-400 text-consumer-400">{NumberFormatter(meteringRulesList.length)}</div>
                                                <div className="text-consumer-400 fs-16px fw-400">Rules</div>
                                            </div>
                                        }
                                    />
                                </div>
                            </>
                            ) : (
                                <div className='h-100 w-100 d-flex flex-column align-items-center gap-4px'>
                                    <MonetizationIcons type={"no-metering-rules"}/>
                                    <p className='fw-500 fs-16px text-gray-subtext'>There is no metering rules</p>
                                </div>
                            )
                        )} 
                    </div>
                ) : null}
                <div className='p-24px surface-consumer-850 h-fit' style={{minHeight: '100%'}}>
                    <p className='fs-20px fw-600 text-content-color'>Rules Info</p>

                    <div className='my-16px'>
                        <p className='text-consumer-300 fs-12px fw-400'>
                            Select Rule <span className='text-danger'>*</span>
                        </p>

                        <div className='mainSelect mb-1 w-100'>
                            <Select
                                classNames={{
                                    option: (state) =>
                                        state.isFocused
                                        ? "focused"
                                        : state.isSelected
                                        ? "selected"
                                        : "",
                                }}
                                className="mt-1 v1select w-100"
                                onChange={(e) => setSelectedRule(e)}
                                value={selectedRule}
                                options={rules.map(o => ({...o, label: o.ruleName, value: o.ruleName}))} />
                        </div>
                        <p className='fs-11px fw-400 text-consumer-300'>
                            Select Rule to view Info about that specific rule
                        </p>
                    </div>

                    <div className='dfaic mb-4' style={{borderBottom: '1px solid var(--base-100)'}}>
                        {['Rule Details', 'Ratecard Details'].map((each, i) => (
                        <div className='px-10px pb-11px cursor' key={i} onClick={() => setActiveTab(each)}
                            style={{marginBottom: -1,borderBottom: activeTab === each ? '1.5px solid var(--primary-color)' : '1px solid var(--base-100)' }}>
                            <p className={`fs-14px fw-600 ${activeTab === each ? 'text-primary-color' : 'text-consumer-400'}`}>{each}</p>
                        </div>))}
                    </div>

                    {activeTab === 'Rule Details'
                    ? <div className='custom-bordr-1 p-8px'>
                        <div className="meteringPlayground noscrollbar">
                            {blocksJson._type === "group" && <MeteringGroup data={blocksJson} viewOnly />}

                            {blocksJson._type === "element" && <MeteringElement data={blocksJson} viewOnly />}
                        </div>
                    </div>
                    : <div className=''>
                        <p className='fs-12px fw-400 text-content-color'>Rate plan</p>

                        {/* <RateCard data={selectedRule?.rateCard[0]} /> */}
                        {selectedRule.rateCard?.map(o => <RateCard data={o} currentCurrency={props.currentCurrency} />)}
                        {/* <div className='bg-base-color row mx-0 mt-1 p-8px'>
                            <div className='col-md-6'>
                                <p className='text-end fs-14px text-content-subtle fw-400'>
                                    For the first 0-100:
                                </p>
                            </div>

                            <div className='col-md-6'>
                                <p className='text-start fs-14px text-content-subtle fw-400'>
                                    0.25$ / call
                                </p>
                            </div>
                        </div>
                        <div className='bg-base-100 row mx-0 mt-1 p-8px'>
                            <div className='col-md-6'>
                                <p className='text-end fs-14px text-content-subtle fw-400'>
                                    For the next 100-10000
                                </p>
                            </div>

                            <div className='col-md-6'>
                                <p className='text-start fs-14px text-content-subtle fw-400'>
                                    0.25$ / call
                                </p>
                            </div>
                        </div>
                        <div className='bg-base-color row mx-0 mt-1 p-8px'>
                            <div className='col-md-6'>
                                <p className='text-end fs-14px text-content-subtle fw-400'>
                                    For the next 10,000+ calls:
                                </p>
                            </div>

                            <div className='col-md-6'>
                                <p className='text-start fs-14px text-content-subtle fw-400'>
                                    0.25$ / call
                                </p>
                            </div>
                        </div> */}
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
