import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropertyDescription from "./PropertyDescription";
import { handleOasOfObject, extractFromSwagger } from "@apiwiz/oas/utils";
import {
  getParamRangeText,
  getParamEnums,
  getParamPattern,
} from "@apiwiz/oas/helper";
import { objectDeepClone, objectDeepCloneFlatted } from "../../../utils/helper";

const Properties = ({ properties, depth }) => {
  const [showChildren, setShowChildren] = useState({});
  const [propertiesObj, setPropertiesObj] = useState();
  const [siblings, setSiblings] = useState([]);
  const { swagger } = useSelector((state) => state.swagger);

  const myProperties = () => {
    let props = objectDeepCloneFlatted(properties);

    for (let field in props) {
      let property = props[field];
      if (property && typeof property === "object") {
        let __properties = getChildProperties({ property, field });
        props[field].__properties = __properties;
        props[field].__showExpandOrCollapse = showExpandOrCollapse({
          childProperties: __properties,
        });

        props[field].__addBorderSpacing =
          !property ||
          property.type === null ||
          property.type === undefined ||
          false;
      }
    }

    return props;
  };
  const siblingsFn = () => {
    let props = myProperties();
    if (props && props.constructor === Object) {
      let _siblings = [];
      for (let k in props) {
        if (!checkCustomField(k)) {
          _siblings.push(k);
        }
      }
      return _siblings;
    }

    if (props && props.constructor === Array) {
      return props;
    }

    return [];
  };
  const firstSibling = () => {
    const _siblings = siblingsFn();
    if (_siblings.length && _siblings.length > 1) return _siblings[0];
    return "";
  };
  const lastSibling = () => {
    const _siblings = siblingsFn();
    if (_siblings.length && _siblings.length > 1)
      return _siblings[_siblings.length - 1];
    return "";
  };

  const getChildProperties = ({ field, property }) => {
    if (
      ["allOf", "oneOf", "anyOf"].includes(field) &&
      property.constructor === Array
    ) {
      let _properties = {};
      for (let i in property) {
        let ni = Number(i);
        if (!isNaN(ni)) {
          _properties[ni + 1] = property[i];
        }
      }
      return _properties;
    }

    if (
      ["allOf", "oneOf", "anyOf"].includes(field) &&
      property.constructor === Object
    ) {
      return property.properties;
    }

    if (property.properties) return property.properties;
    if (property.type === "array") {
      if (
        property.items.type &&
        ["string", "integer", "boolean"].includes(property.items.type)
      ) {
        if (property.items.type === "integer")
          property.items.type = "array of int64";
        else property.type = `array of ${property.items.type}`;
        property.description =
          property.description || property.items.description || "";
        return null;
      }
      return { items: property.items };
    }

    let of = property.allOf || property.oneOf || property.anyOf;
    let oasOfObject = handleOasOfObject({ ofValue: of, property });
    if (oasOfObject) return oasOfObject;
    if (of && of.constructor === Array) {
      return property;
    }

    if (property.$ref) {
      let _properties = extractFromSwagger({
        swagger: swagger,
        value: { refValue: property },
      });
      return _properties.refValue.properties;
    }

    if (property.constructor === Array) {
      return property;
    }

    if (property.type === "integer") property.type = "int64";

    return null;
  };
  const checkCustomField = (field) => {
    if (field === null || field === undefined) return false;
    let _field = `${field}`;
    return _field.startsWith("__");
  };
  const showExpandOrCollapse = ({ childProperties }) => {
    if (!childProperties) return false;
    if (
      childProperties.constructor === Object &&
      Object.keys(childProperties).length
    ) {
      return true;
    }

    if (childProperties.constructor === Array && childProperties.length) {
      return true;
    }

    return false;
  };

  const onExpandOrCollapse = ({ property, prop }) => {
    if (!property.__showExpandOrCollapse) return;
    let _showChildren = objectDeepClone(showChildren);
    _showChildren[prop] = !_showChildren[prop];
    setShowChildren(_showChildren);
  };

  useEffect(() => {
    setPropertiesObj(myProperties());

    setSiblings(siblingsFn());
  }, [properties]);

  // TODO: write css for sibling
  return (
    <div>
      {propertiesObj &&
        Object.keys(propertiesObj).map((prop, index) => {
          let _enums = getParamEnums(propertiesObj[prop]);
          if(_enums && _enums.length) _enums = _enums.join(', ')
          else _enums = '';

          return (
            <div key={index} className="pl-16px">
              {!checkCustomField(prop) && (
                <div
                  className={`body-param-row ${
                    siblingsFn().length < 2 ? "no-sibling only-child" : "has-sibling"
                  }
                  ${firstSibling() == prop ? "first-child" : ""}
                  ${lastSibling() === prop ? "last-child" : ""} w-fit min-w-100`}
                >
                  <PropertyDescription
                    field={prop && prop.endsWith("NO_PROP_NAME__") ? "" : prop}
                    type={propertiesObj[prop] ? propertiesObj[prop].type : ""}
                    description={propertiesObj[prop] ? propertiesObj[prop].description : ""}
                    expandOrCollapse={() => {
                      siblingsFn();
                      onExpandOrCollapse({
                        property: propertiesObj[prop],
                        prop,
                      });
                    }}
                    showExpandOrCollapse={
                      propertiesObj[prop].__showExpandOrCollapse
                    }
                    required={
                      propertiesObj[prop] && propertiesObj[prop].__required
                    }
                    borderSpacing={
                      propertiesObj[prop] &&
                      propertiesObj[prop].__addBorderSpacing
                    }
                    rangeText={getParamRangeText(propertiesObj[prop])}
                    enums={_enums}
                    pattern={getParamPattern(propertiesObj[prop])}
                    noBorderBottom={true}
                    showChildren={showChildren && showChildren[prop]}
                    bodyProperty
                  />
                  {propertiesObj[prop].__showExpandOrCollapse &&
                    showChildren &&
                    showChildren[prop] && (
                      <Properties
                        properties={propertiesObj[prop].__properties}
                        depth={depth + 1}
                      />
                    )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Properties;
