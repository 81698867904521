import { CheckCircle } from 'phosphor-react'
import React from 'react'
import GetIcon from '../../../components/GetIcon'

export default function CustomImage3({data, fromEditor}) {

    return (
        <div className={`${data.showBackground ? 'bg-base-100' : 'bg-currentBackground'}`}>
            <div className={fromEditor ? 'bg-currentBackground d-block mx-auto' : 'container'}>
                <div className={`customImageWrapper px-0px py-40px m-0 ${fromEditor ? 'd-block mx-auto w-80' : ''}`}>
                    <div className='py-80px w-100 mx-auto h-100'>
                        <div className='mb-38px w-60'>
                            {data.tagName && <div className='titleTagPartner mb-13px'>
                                <p className='text-theme-base fs-16px fw-600 text-center'>{data.tagName}</p>
                            </div>}
                            <p className='fw-600 fs-48px text-content-color' style={{lineHeight: '130%'}}>
                                {data.heading}
                            </p>
                        </div>

                        <div className='customImage1'>
                            <div className='leftPanel mx-0 pe-0'>
                                {data.points?.map((each, i) => (<div className='mt-56px pe-2' key={i}>
                                    <div className='dfaic'>
                                        <GetIcon weight={each.iconWeight} type={each.icon} size={33}
                                            color={'var(--primary-color)'} className='mr-10px' />
                                        <p className='fs-32px fw-600 text-content-color' style={{lineHeight: '130%'}}>
                                            {each.title}
                                        </p>
                                    </div>

                                    <p className='fs-20px fw-400 mt-2 text-content-subtle' style={{lineHeight: '150%'}}>
                                            {each.description}
                                        </p>
                                </div>))}
                            </div>

                            <div className='rightPanel d-block mx-0 my-auto'>
                                <img src={data.imageUrl} className='d-block mx-auto br-12px'
                                    style={{objectFit: 'cover', width: '100%'}} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
