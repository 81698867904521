import React from 'react'

export default function GlobalIcons({type, size, color, ...props}) {
    return (
        <>
            {type === "profile" && <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.6488 20.3751C20.2209 17.9067 18.0206 16.1367 15.4528 15.2976C16.723 14.5415 17.7098 13.3893 18.2618 12.018C18.8137 10.6468 18.9003 9.13224 18.5082 7.70701C18.1161 6.28178 17.267 5.02467 16.0912 4.12873C14.9155 3.23279 13.4782 2.74756 12 2.74756C10.5218 2.74756 9.08451 3.23279 7.90878 4.12873C6.73306 5.02467 5.88394 6.28178 5.49183 7.70701C5.09971 9.13224 5.18629 10.6468 5.73825 12.018C6.29021 13.3893 7.27704 14.5415 8.5472 15.2976C5.97938 16.1357 3.77907 17.9057 2.35126 20.3751C2.2989 20.4605 2.26417 20.5555 2.24912 20.6545C2.23407 20.7535 2.239 20.8545 2.26363 20.9516C2.28825 21.0487 2.33207 21.1399 2.3925 21.2197C2.45293 21.2996 2.52874 21.3666 2.61547 21.4167C2.7022 21.4667 2.79808 21.499 2.89745 21.5114C2.99683 21.5238 3.0977 21.5163 3.19409 21.4891C3.29049 21.4619 3.38047 21.4157 3.45872 21.3532C3.53697 21.2907 3.6019 21.2131 3.6497 21.1251C5.41595 18.0726 8.53782 16.2501 12 16.2501C15.4622 16.2501 18.5841 18.0726 20.3503 21.1251C20.3981 21.2131 20.4631 21.2907 20.5413 21.3532C20.6196 21.4157 20.7095 21.4619 20.8059 21.4891C20.9023 21.5163 21.0032 21.5238 21.1026 21.5114C21.2019 21.499 21.2978 21.4667 21.3845 21.4167C21.4713 21.3666 21.5471 21.2996 21.6075 21.2197C21.6679 21.1399 21.7118 21.0487 21.7364 20.9516C21.761 20.8545 21.766 20.7535 21.7509 20.6545C21.7358 20.5555 21.7011 20.4605 21.6488 20.3751ZM6.75001 9.50011C6.75001 8.46176 7.05792 7.44672 7.63479 6.58337C8.21167 5.72001 9.03161 5.0471 9.99092 4.64974C10.9502 4.25238 12.0058 4.14841 13.0242 4.35099C14.0426 4.55356 14.9781 5.05357 15.7123 5.7878C16.4465 6.52202 16.9466 7.45748 17.1491 8.47589C17.3517 9.49429 17.2477 10.5499 16.8504 11.5092C16.453 12.4685 15.7801 13.2884 14.9168 13.8653C14.0534 14.4422 13.0384 14.7501 12 14.7501C10.6081 14.7486 9.27359 14.195 8.28934 13.2108C7.3051 12.2265 6.7515 10.892 6.75001 9.50011Z" fill="#E4E4E4"/>
            </svg>}

            {type === "notification" && <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.26892 10.9998C5.26892 10.1158 5.44303 9.24054 5.7813 8.42387C6.11958 7.60719 6.6154 6.86515 7.24045 6.24009C7.86551 5.61504 8.60756 5.11922 9.42423 4.78094C10.2409 4.44266 11.1162 4.26855 12.0002 4.26855C12.8841 4.26855 13.7594 4.44266 14.5761 4.78094C15.3928 5.11922 16.1348 5.61504 16.7599 6.24009C17.3849 6.86515 17.8808 7.60719 18.219 8.42387C18.5573 9.24054 18.7314 10.1158 18.7314 10.9998C18.7314 14.3561 19.4345 16.3061 20.0533 17.3748C20.119 17.4886 20.1537 17.6177 20.1538 17.7491C20.1539 17.8806 20.1195 18.0097 20.054 18.1237C19.9884 18.2376 19.8941 18.3323 19.7805 18.3983C19.6668 18.4644 19.5378 18.4993 19.4064 18.4998H4.59392C4.46249 18.4993 4.33349 18.4644 4.21984 18.3983C4.1062 18.3323 4.01189 18.2376 3.94638 18.1237C3.88086 18.0097 3.84644 17.8806 3.84656 17.7491C3.84667 17.6177 3.88133 17.4886 3.94704 17.3748C4.56579 16.3061 5.26892 14.3561 5.26892 10.9998Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 18.5V19.25C9 20.0456 9.31607 20.8087 9.87868 21.3713C10.4413 21.9339 11.2044 22.25 12 22.25C12.7956 22.25 13.5587 21.9339 14.1213 21.3713C14.6839 20.8087 15 20.0456 15 19.25V18.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.1937 2.75C18.7207 3.71395 19.9532 5.07938 20.7562 6.69687" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.24384 6.69687C4.04683 5.07938 5.27929 3.71395 6.80634 2.75" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === "left-arrow" && <svg {...props} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_15025_358840)">
                <path d="M11.8125 7.5H2.1875" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.125 3.5625L2.1875 7.5L6.125 11.4375" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_15025_358840">
                <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
            </svg>}
            {
                type === "left-arrow-black" && <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1663_107197)">
                <path d="M20.25 12H3.75" stroke="var(--content-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 5.25L3.75 12L10.5 18.75" stroke="var(--content-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1663_107197">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            }

            {type === 'down-arrow-sm' && <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.375 0.75L5 5.125L0.625 0.75" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            { type === 'form-uncheck' && <svg {...props} xmlns="http://www.w3.org/2000/svg" width={size === 'sm' ? "19" : '24'} height={size === 'sm' ? "20" : '24'} viewBox="0 0 19 20" fill="none">
                <path d="M15.4375 3.46875H3.5625C3.23458 3.46875 2.96875 3.73458 2.96875 4.0625V15.9375C2.96875 16.2654 3.23458 16.5312 3.5625 16.5312H15.4375C15.7654 16.5312 16.0312 16.2654 16.0312 15.9375V4.0625C16.0312 3.73458 15.7654 3.46875 15.4375 3.46875Z" 
                    fill={color ? 'var(--primary-subtle)' : "#666666"} stroke={color ? `var(--base-200)` : "#464646"} strokeLinecap="round" strokeLinejoin="round"/>
            </svg> }

            { type === 'form-check' && <svg {...props} xmlns="http://www.w3.org/2000/svg" width={size === 'sm' ? "19" : '24'} height={size === 'sm' ? "20" : '24'} viewBox="0 0 19 20" fill="none">
                <path d="M15.4375 3.46875H3.5625C3.23458 3.46875 2.96875 3.73458 2.96875 4.0625V15.9375C2.96875 16.2654 3.23458 16.5312 3.5625 16.5312H15.4375C15.7654 16.5312 16.0312 16.2654 16.0312 15.9375V4.0625C16.0312 3.73458 15.7654 3.46875 15.4375 3.46875Z" fill={color ? color : "#0091FF"} stroke={color ? color : "#0091FF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.7656 8.21875L8.40898 12.375L6.23438 10.2969" stroke="#FBFBFB" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> }

            { type === 'form-check-view' && <svg {...props} xmlns="http://www.w3.org/2000/svg" width={size === 'sm' ? "19" : '24'} height={size === 'sm' ? "20" : '24'} viewBox="0 0 19 20" fill="none">
                <path d="M15.4375 3.46875H3.5625C3.23458 3.46875 2.96875 3.73458 2.96875 4.0625V15.9375C2.96875 16.2654 3.23458 16.5312 3.5625 16.5312H15.4375C15.7654 16.5312 16.0312 16.2654 16.0312 15.9375V4.0625C16.0312 3.73458 15.7654 3.46875 15.4375 3.46875Z" 
                    fill={"#464646"} stroke={"#464646"} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.7656 8.21875L8.40898 12.375L6.23438 10.2969" stroke={"#FBFBFB"} strokeLinecap="round" strokeLinejoin="round"/>
            </svg> }

            { type === 'form-uncheck-view' && <svg xmlns="http://www.w3.org/2000/svg" width={size === 'sm' ? "19" : '24'} height={size === 'sm' ? "20" : '24'} viewBox="0 0 19 20" fill="none">
                <path d="M15.4375 3.46875H3.5625C3.23458 3.46875 2.96875 3.73458 2.96875 4.0625V15.9375C2.96875 16.2654 3.23458 16.5312 3.5625 16.5312H15.4375C15.7654 16.5312 16.0312 16.2654 16.0312 15.9375V4.0625C16.0312 3.73458 15.7654 3.46875 15.4375 3.46875Z" 
                    fill={"rgba(102, 102, 102, 0.5)"} stroke={"#464646"} strokeLinecap="round" strokeLinejoin="round"/>
            </svg> }


            {type === 'pencil' && <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.79375 12.4999H1C0.867395 12.4999 0.740218 12.4473 0.64645 12.3535C0.552682 12.2597 0.500003 12.1326 0.500003 11.9999V9.2062C0.499775 9.14127 0.512355 9.07694 0.537022 9.01688C0.561688 8.95682 0.597957 8.90222 0.643753 8.85619L8.14375 1.3562C8.19028 1.30895 8.24573 1.27144 8.30689 1.24583C8.36805 1.22022 8.4337 1.20703 8.5 1.20703C8.56631 1.20703 8.63195 1.22022 8.69311 1.24583C8.75427 1.27144 8.80973 1.30895 8.85625 1.3562L11.6438 4.1437C11.691 4.19022 11.7285 4.24568 11.7541 4.30684C11.7797 4.368 11.7929 4.43364 11.7929 4.49995C11.7929 4.56625 11.7797 4.63189 11.7541 4.69305C11.7285 4.75421 11.691 4.80967 11.6438 4.85619L4.14375 12.3562C4.09773 12.402 4.04313 12.4383 3.98307 12.4629C3.92301 12.4876 3.85868 12.5002 3.79375 12.4999V12.4999Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'folder-open' && <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 13.5V4.5C2 4.36739 2.05268 4.24021 2.14645 4.14645C2.24021 4.05268 2.36739 4 2.5 4H5.83125C5.93936 4.00044 6.04449 4.03549 6.13125 4.1L7.86875 5.4C7.95551 5.46451 8.06064 5.49956 8.16875 5.5H12.5C12.6326 5.5 12.7598 5.55268 12.8536 5.64645C12.9473 5.74021 13 5.86739 13 6V7.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 13.5L3.8875 7.84375C3.9204 7.74374 3.98403 7.65667 4.06932 7.59494C4.15462 7.53322 4.25722 7.49999 4.3625 7.5H14.3062C14.3853 7.49999 14.4633 7.51874 14.5337 7.55469C14.6041 7.59065 14.665 7.64279 14.7113 7.70683C14.7577 7.77088 14.7882 7.84501 14.8003 7.92313C14.8125 8.00125 14.806 8.08115 14.7812 8.15625L13 13.5H2Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'filter' && <svg {...props} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.96027 1.375H14.04C14.1491 1.37503 14.2558 1.4068 14.3472 1.46643C14.4386 1.52606 14.5106 1.61097 14.5546 1.71083C14.5986 1.81069 14.6125 1.92118 14.5948 2.02884C14.5771 2.1365 14.5285 2.23669 14.4548 2.31719L9.83527 7.40078C9.73913 7.50369 9.68626 7.63964 9.68761 7.78047V11.7602C9.68847 11.8536 9.66573 11.9458 9.62148 12.0282C9.57723 12.1105 9.51291 12.1804 9.43449 12.2312L7.18449 13.7289C7.10009 13.7845 7.00227 13.8163 6.90132 13.821C6.80037 13.8257 6.70001 13.8032 6.6108 13.7557C6.52158 13.7082 6.44681 13.6376 6.39434 13.5512C6.34187 13.4648 6.31364 13.3659 6.31261 13.2648V7.78047C6.31397 7.63964 6.2611 7.50369 6.16496 7.40078L1.54543 2.31719C1.47178 2.23669 1.42313 2.1365 1.40542 2.02884C1.3877 1.92118 1.40167 1.81069 1.44564 1.71083C1.48961 1.61097 1.56167 1.52606 1.65304 1.46643C1.74442 1.4068 1.85116 1.37503 1.96027 1.375V1.375Z" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'plus' && <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.1875 7H11.8125" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 2.1875V11.8125" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'link' && <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_15484_60925)">
                    <path d="M7.21221 10.5002L6.66861 11.0438C6.17498 11.5295 5.50943 11.8004 4.81695 11.7976C4.12446 11.7948 3.46115 11.5184 2.97148 11.0288C2.48182 10.5391 2.20548 9.87578 2.20266 9.18329C2.19984 8.49081 2.47077 7.82526 2.95643 7.33163L4.27494 6.01585C4.74789 5.54194 5.38405 5.26666 6.05326 5.24633C6.72248 5.226 7.37418 5.46215 7.87502 5.90647" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78781 3.49953L7.33141 2.95594C7.82504 2.47028 8.49059 2.19935 9.18307 2.20217C9.87556 2.20499 10.5389 2.48133 11.0285 2.97099C11.5182 3.46066 11.7945 4.12398 11.7974 4.81646C11.8002 5.50894 11.5293 6.17449 11.0436 6.66813L9.72508 7.98664C9.25178 8.46017 8.61543 8.73495 7.94621 8.75477C7.277 8.77459 6.6255 8.53796 6.125 8.09328" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_15484_60925">
                    <rect width="14" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>}

            {type === 'copy' && <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 10.5H13.5V2.5H5.5V5.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 5.5H2.5V13.5H10.5V5.5Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'pencil-v2' && <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.79375 13.4999H3C2.86739 13.4999 2.74022 13.4473 2.64645 13.3535C2.55268 13.2597 2.5 13.1326 2.5 12.9999V10.2062C2.49978 10.1413 2.51236 10.0769 2.53702 10.0169C2.56169 9.95682 2.59796 9.90222 2.64375 9.85619L10.1438 2.3562C10.1903 2.30895 10.2457 2.27144 10.3069 2.24583C10.3681 2.22022 10.4337 2.20703 10.5 2.20703C10.5663 2.20703 10.632 2.22022 10.6931 2.24583C10.7543 2.27144 10.8097 2.30895 10.8563 2.3562L13.6438 5.1437C13.691 5.19022 13.7285 5.24568 13.7541 5.30684C13.7797 5.368 13.7929 5.43364 13.7929 5.49995C13.7929 5.56625 13.7797 5.63189 13.7541 5.69305C13.7285 5.75421 13.691 5.80967 13.6438 5.85619L6.14375 13.3562C6.09773 13.402 6.04313 13.4383 5.98307 13.4629C5.92301 13.4876 5.85868 13.5002 5.79375 13.4999V13.4999Z" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 4L12 7.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'trash' && <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 3.5H2.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5 6.5V10.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 6.5V10.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 3.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V3.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'tick' && <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4.5L6.5 11.5L3 8" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'cross' && <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 3.5L3.5 12.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 12.5L3.5 3.5" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'search' && <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.34375 10.9375C8.88081 10.9375 10.9375 8.88081 10.9375 6.34375C10.9375 3.80669 8.88081 1.75 6.34375 1.75C3.80669 1.75 1.75 3.80669 1.75 6.34375C1.75 8.88081 3.80669 10.9375 6.34375 10.9375Z" stroke="#C2C2C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.59216 9.59229L12.25 12.2501" stroke="#C2C2C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}

            {type === 'up-and-down-arrow' && <svg {...props} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.87991 9.64615C6.9264 9.69259 6.96327 9.74774 6.98844 9.80843C7.0136 9.86913 7.02655 9.9342 7.02655 9.9999C7.02655 10.0656 7.0136 10.1307 6.98844 10.1914C6.96327 10.2521 6.9264 10.3072 6.87991 10.3537L3.87991 13.3537C3.83347 13.4001 3.77833 13.437 3.71763 13.4622C3.65693 13.4873 3.59186 13.5003 3.52616 13.5003C3.46045 13.5003 3.39539 13.4873 3.33469 13.4622C3.27399 13.437 3.21884 13.4001 3.17241 13.3537L0.172407 10.3537C0.0785863 10.2598 0.0258789 10.1326 0.0258789 9.9999C0.0258789 9.86722 0.0785863 9.73997 0.172407 9.64615C0.266227 9.55233 0.393475 9.49963 0.526157 9.49963C0.658839 9.49963 0.786087 9.55233 0.879907 9.64615L3.52616 12.293L6.17241 9.64615C6.21884 9.59967 6.27399 9.56279 6.33469 9.53762C6.39539 9.51246 6.46045 9.49951 6.52616 9.49951C6.59186 9.49951 6.65693 9.51246 6.71763 9.53762C6.77833 9.56279 6.83347 9.59967 6.87991 9.64615ZM0.879907 4.35366L3.52616 1.70678L6.17241 4.35366C6.26623 4.44748 6.39348 4.50018 6.52616 4.50018C6.65884 4.50018 6.78609 4.44748 6.87991 4.35366C6.97373 4.25984 7.02644 4.13259 7.02644 3.99991C7.02644 3.86722 6.97373 3.73998 6.87991 3.64615L3.87991 0.646155C3.83347 0.599667 3.77833 0.562787 3.71763 0.537625C3.65693 0.512463 3.59186 0.499512 3.52616 0.499512C3.46045 0.499512 3.39539 0.512463 3.33469 0.537625C3.27399 0.562787 3.21884 0.599667 3.17241 0.646155L0.172407 3.64615C0.0785863 3.73998 0.0258789 3.86722 0.0258789 3.99991C0.0258789 4.13259 0.0785868 4.25983 0.172407 4.35365C0.266227 4.44748 0.393475 4.50018 0.526157 4.50018C0.658839 4.50018 0.786087 4.44748 0.879907 4.35366Z" fill={"#E4E4E4"}/>
                </svg>
            }
            {type === 'cancel' && <svg {...props} xmlns="http://www.w3.org/2000/svg" width={size === 'sm' ? "8" : '12'} height={size === 'sm' ? "9" : '18'} viewBox="0 0 8 9" fill="none">	
                <path d="M8 7.372L6.872 8.5L4 5.628L1.128 8.5L0 7.372L2.872 4.5L0 1.628L1.128 0.5L4 3.372L6.872 0.5L8 1.628L5.128 4.5L8 7.372Z" fill={"#E4E4E4"}/>	
            </svg>}	
			
            {
                type==="emptyImage" && <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="186" height="187" viewBox="0 0 186 187" fill="none">
                <g filter="url(#filter0_d_15526_367583)">
                <rect x="1.5" y="2" width="183" height="183" rx="91.5" fill="url(#pattern0)" fill-opacity="0.05"/>
                <rect x="1.5" y="2" width="183" height="183" rx="91.5" stroke="#464646" stroke-width="3"/>
                </g>
                <defs>
                <filter id="filter0_d_15526_367583" x="0" y="0.5" width="186" height="186.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.321569 0 0 0 0 0.321569 0 0 0 0 0.321569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15526_367583"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15526_367583" result="shape"/>
                </filter>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_15526_367583" transform="scale(0.015625)"/>
                </pattern>
                <image id="image0_15526_367583" width="64" height="64" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"/>
                </defs>
                </svg>
            }
            {
                type==="upload" && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                <g clip-path="url(#clip0_15526_367607)">
                <path d="M7.5 8.3125V2.1875" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.3125 8.3125V11.375C12.3125 11.491 12.2664 11.6023 12.1844 11.6844C12.1023 11.7664 11.991 11.8125 11.875 11.8125H3.125C3.00897 11.8125 2.89769 11.7664 2.81564 11.6844C2.73359 11.6023 2.6875 11.491 2.6875 11.375V8.3125" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.3125 4.375L7.5 2.1875L9.6875 4.375" stroke="#E4E4E4" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_15526_367607">
                <rect width="14" height="14" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
				</svg>
            }
			      {
                type === "empty-data" && <svg {...props} width="52" height="48" viewBox="0 0 52 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.375 1.5V29.625C5.375 31.1168 5.96763 32.5476 7.02252 33.6025C8.07742 34.6574 9.50816 35.25 11 35.25H16.625M5.375 1.5H1.625M5.375 1.5H46.625M16.625 35.25H35.375M16.625 35.25L14.125 42.75M46.625 1.5H50.375M46.625 1.5V29.625C46.625 31.1168 46.0324 32.5476 44.9775 33.6025C43.9226 34.6574 42.4918 35.25 41 35.25H35.375M35.375 35.25L37.875 42.75M14.125 42.75H37.875M14.125 42.75L12.875 46.5M37.875 42.75L39.125 46.5M18.5 22.125V25.875M26 16.5V25.875M33.5 10.875V25.875" stroke="#E4E4E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }

            {
                type === "left-arrow-pagination" && <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path d="M16.3332 10.6439H4.6665M4.6665 10.6439L10.4998 16.4772M4.6665 10.6439L10.4998 4.81055" stroke="#252525" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "close" && <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 3.5L3.5 12.5" stroke="var(--content-color)" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 12.5L3.5 3.5" stroke="var(--content-color)" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "check" && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4.5L6.5 11.5L3 8" stroke="var(--content-color)" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            {
                type === "three-dots" && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2129_23352)">
                <path d="M12 6.5625C12.5178 6.5625 12.9375 6.14277 12.9375 5.625C12.9375 5.10723 12.5178 4.6875 12 4.6875C11.4822 4.6875 11.0625 5.10723 11.0625 5.625C11.0625 6.14277 11.4822 6.5625 12 6.5625Z" fill="var(--content-color)"/>
                <path d="M12 12.9375C12.5178 12.9375 12.9375 12.5178 12.9375 12C12.9375 11.4822 12.5178 11.0625 12 11.0625C11.4822 11.0625 11.0625 11.4822 11.0625 12C11.0625 12.5178 11.4822 12.9375 12 12.9375Z" fill="var(--content-color)"/>
                <path d="M12 19.3125C12.5178 19.3125 12.9375 18.8928 12.9375 18.375C12.9375 17.8572 12.5178 17.4375 12 17.4375C11.4822 17.4375 11.0625 17.8572 11.0625 18.375C11.0625 18.8928 11.4822 19.3125 12 19.3125Z" fill="var(--content-color)"/>
                </g>
                <defs>
                <clipPath id="clip0_2129_23352">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            }
            { 
                type === "emptySquareImage" && <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="187" height="187" viewBox="0 0 187 187" fill="none">
                <g filter="url(#filter0_d_15802_365523)">
                <rect x="2" y="2" width="183" height="183" rx="8" fill="url(#pattern0)" fill-opacity="0.05"/>
                <rect x="2" y="2" width="183" height="183" rx="8" stroke="#464646" stroke-width="3"/>
                </g>
                <defs>
                <filter id="filter0_d_15802_365523" x="0.5" y="0.5" width="186" height="186.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.321569 0 0 0 0 0.321569 0 0 0 0 0.321569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15802_365523"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15802_365523" result="shape"/>
                </filter>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_15802_365523" transform="scale(0.015625)"/>
                </pattern>
                <image id="image0_15802_365523" width="64" height="64" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"/>
                </defs>
                </svg>
            }

            {type === "calendar" && (
                <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.625 2.8125H3.375C3.06434 2.8125 2.8125 3.06434 2.8125 3.375V14.625C2.8125 14.9357 3.06434 15.1875 3.375 15.1875H14.625C14.9357 15.1875 15.1875 14.9357 15.1875 14.625V3.375C15.1875 3.06434 14.9357 2.8125 14.625 2.8125Z" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.375 1.6875V3.9375" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.625 1.6875V3.9375" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.8125 6.1875H15.1875" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.9688 10.6875H7.03125" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 8.71875V12.6562" stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                
            )}

            {type === "compare-icon" && (
                <svg {...props} xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill={`var(--content-color)`} viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="56 96 24 128 56 160" fill="none" stroke={`var(--content-color)`} stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></polyline><polyline points="200 96 232 128 200 160" fill="none" stroke={`var(--content-color)`} stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></polyline><line x1="24" y1="128" x2="232" y2="128" fill="none" stroke={`var(--content-color)`} stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></line></svg>
            )}
            {
                type === "monetization-coin" && (
                    <svg {...props}  width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 7.52588C9.51142 7.52588 11.75 6.51852 11.75 5.27588C11.75 4.03324 9.51142 3.02588 6.75 3.02588C3.98858 3.02588 1.75 4.03324 1.75 5.27588C1.75 6.51852 3.98858 7.52588 6.75 7.52588Z" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.75 5.27588V7.77588C1.75 9.01963 3.9875 10.0259 6.75 10.0259C9.5125 10.0259 11.75 9.01963 11.75 7.77588V5.27588" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.75 7.33838V9.83838" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.75 6.06964C14.0312 6.28214 15.75 7.18839 15.75 8.27589C15.75 9.51964 13.5125 10.5259 10.75 10.5259C9.525 10.5259 8.4 10.3259 7.53125 10.0009" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.75 9.98213V10.7759C5.75 12.0196 7.9875 13.0259 10.75 13.0259C13.5125 13.0259 15.75 12.0196 15.75 10.7759V8.27588" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.75 10.3384V12.8384" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.75 7.33838V12.8384" stroke="var(--content-subtle)" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
            }
            {type === "caret-top" && (
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                >
                <path
                    d="M2.77344 9.82361L7.07339 5.37482L11.5222 9.67477"
                    stroke={`var(--primary-color)`}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                </svg>
            )}
            {
                type === "caret-right" &&  (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clip-path="url(#clip0_3299_4526)">
                            <path d="M5.25 2.62451L9.625 6.99951L5.25 11.3745" stroke="var(--primary-color)" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3299_4526">
                            <rect width="14" height="14" fill="white" transform="translate(0 -0.000488281)"/>
                            </clipPath>
                        </defs>
                        </svg>
                )
            }
            {
                type === "apps-credit-card" && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="118" viewBox="0 0 120 118" fill="none">
                        <g opacity="0.2" clip-path="url(#clip0_3301_5770)">
                            <path opacity="0.2" d="M101.1 21.3125H19.2878C17.234 21.3125 15.5691 22.9774 15.5691 25.0312V91.9688C15.5691 94.0226 17.234 95.6875 19.2878 95.6875H101.1C103.154 95.6875 104.819 94.0226 104.819 91.9688V25.0312C104.819 22.9774 103.154 21.3125 101.1 21.3125Z" fill="white"/>
                            <path d="M101.1 21.3125H19.2878C17.234 21.3125 15.5691 22.9774 15.5691 25.0312V91.9688C15.5691 94.0226 17.234 95.6875 19.2878 95.6875H101.1C103.154 95.6875 104.819 94.0226 104.819 91.9688V25.0312C104.819 22.9774 103.154 21.3125 101.1 21.3125Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M37.8816 43.625H82.5066" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M37.8816 58.5H82.5066" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M37.8816 73.375H82.5066" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3301_5770">
                            <rect width="119" height="119" fill="white" transform="translate(0.694092 -1)"/>
                            </clipPath>
                        </defs>
                        </svg>
                )
            }
            {
                type === "money" && (<svg width="120" height="119" viewBox="0 0 120 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.2" clip-path="url(#clip0_3301_126885)">
                <path opacity="0.2" d="M111.639 28.9324H93.0453C93.8326 33.5776 96.0454 37.863 99.3769 41.1945C102.708 44.526 106.994 46.7388 111.639 47.5261V28.9324Z" fill="white"/>
                <path opacity="0.2" d="M111.639 88.4324V69.8386C106.994 70.6259 102.708 72.8387 99.3769 76.1702C96.0454 79.5018 93.8326 83.7871 93.0453 88.4324H111.639Z" fill="white"/>
                <path opacity="0.2" d="M7.51404 88.4324V69.8386C12.1593 70.6259 16.4446 72.8387 19.7762 76.1702C23.1077 79.5018 25.3205 83.7871 26.1078 88.4324H7.51404Z" fill="white"/>
                <path opacity="0.2" d="M7.51404 28.9324V47.5261C12.1593 46.7388 16.4446 44.526 19.7762 41.1945C23.1077 37.863 25.3205 33.5776 26.1078 28.9324H7.51404Z" fill="white"/>
                <path d="M111.639 28.9324H7.51404V88.4324H111.639V28.9324Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M111.639 47.5261C106.994 46.7388 102.708 44.526 99.3769 41.1945C96.0454 37.863 93.8326 33.5776 93.0453 28.9324" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M93.0453 88.4324C93.8326 83.7871 96.0454 79.5018 99.3769 76.1702C102.708 72.8387 106.994 70.6259 111.639 69.8386" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.51404 69.8386C12.1593 70.6259 16.4446 72.8387 19.7762 76.1702C23.1077 79.5018 25.3205 83.7871 26.1078 88.4324" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M26.1078 28.9324C25.3205 33.5776 23.1077 37.863 19.7762 41.1945C16.4446 44.526 12.1593 46.7388 7.51404 47.5261" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <g clip-path="url(#clip1_3301_126885)">
                <path opacity="0.2" d="M70.2786 50.4973V56.5797C70.2786 60.593 63.7592 63.904 55.2354 64.5082C58.3367 65.6832 62.3411 66.39 66.7112 66.39C76.5616 66.39 84.5481 62.7959 84.5481 58.3634C84.5481 54.4794 78.4211 51.242 70.2786 50.4973Z" fill="white"/>
                <path opacity="0.2" d="M52.4418 55.6877C62.2928 55.6877 70.2787 52.0941 70.2787 47.6611C70.2787 43.2282 62.2928 39.6345 52.4418 39.6345C42.5907 39.6345 34.6049 43.2282 34.6049 47.6611C34.6049 52.0941 42.5907 55.6877 52.4418 55.6877Z" fill="white"/>
                <path d="M52.4418 55.6877C62.2928 55.6877 70.2787 52.0941 70.2787 47.6611C70.2787 43.2282 62.2928 39.6345 52.4418 39.6345C42.5907 39.6345 34.6049 43.2282 34.6049 47.6611C34.6049 52.0941 42.5907 55.6877 52.4418 55.6877Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M34.6049 47.6611V56.5796C34.6049 61.0121 42.5913 64.6062 52.4418 64.6062C62.2922 64.6062 70.2787 61.0121 70.2787 56.5796V47.6611" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M45.307 55.0188V63.9373" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M70.2786 50.4973C78.4211 51.242 84.5481 54.4794 84.5481 58.3634C84.5481 62.7959 76.5616 66.39 66.7112 66.39C62.3411 66.39 58.3367 65.6832 55.2354 64.5082" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M48.8744 64.4457V67.2817C48.8744 71.7142 56.8609 75.3083 66.7113 75.3083C76.5617 75.3083 84.5482 71.7142 84.5482 67.2817V58.3633" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M73.8461 65.7209V74.6394" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M59.5765 55.0188V74.6394" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_3301_126885">
                <rect width="119" height="119" fill="white" transform="translate(0.0765381 -0.817627)"/>
                </clipPath>
                <clipPath id="clip1_3301_126885">
                <rect width="57.0781" height="57.0781" fill="white" transform="translate(31.0375 28.9324)"/>
                </clipPath>
                </defs>
                </svg>
                )
            }

            {
                type === "product-bundle-card-icon" && <svg width="130" height="119" viewBox="0 0 130 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.2">
                <g clip-path="url(#clip0_3301_5449)">
                <path opacity="0.2" d="M38.0582 53.1066V82.6175C37.6729 82.6159 37.2942 82.5173 36.957 82.3308L11.7217 68.5202C11.3614 68.323 11.0606 68.0327 10.8508 67.6796C10.6409 67.3265 10.5297 66.9236 10.5288 66.5128V39.0866C10.5289 38.7634 10.5973 38.4439 10.7295 38.1489L38.0582 53.1066Z" fill="white"/>
                <path d="M10.73 38.1461L38.0587 53.1038" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M65.5876 39.0808C65.5867 38.6701 65.4755 38.2671 65.2657 37.914C65.0558 37.5609 64.755 37.2706 64.3947 37.0735L39.1594 23.2571C38.8218 23.0724 38.4431 22.9756 38.0582 22.9756C37.6734 22.9756 37.2947 23.0724 36.957 23.2571L11.7217 37.0735C11.3614 37.2706 11.0606 37.5609 10.8508 37.914C10.6409 38.2671 10.5297 38.6701 10.5288 39.0808V66.507C10.5297 66.9178 10.6409 67.3207 10.8508 67.6738C11.0606 68.0269 11.3614 68.3172 11.7217 68.5144L18.0188 72.2564C18.3565 72.4411 18.7351 72.538 19.12 72.538C19.5049 72.538 19.8835 72.4411 20.2212 72.2564" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M38.0586 53.1066V70.5028" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <g clip-path="url(#clip1_3301_5449)">
                <path opacity="0.2" d="M92.3527 82.6066V112.118C91.9673 112.116 91.5886 112.017 91.2515 111.831L66.0162 98.0202C65.6558 97.823 65.3551 97.5327 65.1452 97.1796C64.9354 96.8265 64.8242 96.4236 64.8232 96.0128V68.5866C64.8233 68.2634 64.8918 67.9439 65.024 67.6489L92.3527 82.6066Z" fill="white"/>
                <path d="M65.0244 67.6461L92.3531 82.6038L119.682 67.6461" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M93.4538 52.7573L118.689 66.5736C119.049 66.7707 119.35 67.061 119.56 67.4141C119.77 67.7672 119.881 68.1702 119.882 68.5809V96.0071C119.881 96.4179 119.77 96.8208 119.56 97.1739C119.35 97.527 119.049 97.8173 118.689 98.0145L93.4538 111.831C93.1162 112.016 92.7375 112.112 92.3527 112.112C91.9678 112.112 91.5891 112.016 91.2515 111.831L66.0162 98.0145C65.6558 97.8173 65.3551 97.527 65.1452 97.1739C64.9354 96.8208 64.8242 96.4179 64.8232 96.0071V68.5809C64.8242 68.1702 64.9354 67.7672 65.1452 67.4141C65.3551 67.061 65.6558 66.7707 66.0162 66.5736L91.2515 52.7573C91.5891 52.5725 91.9678 52.4757 92.3527 52.4757C92.7375 52.4757 93.1162 52.5725 93.4538 52.7573Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M92.353 82.6066V112.118" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <g clip-path="url(#clip2_3301_5449)">
                <path opacity="0.2" d="M65.5294 37.0184V66.5294C65.1441 66.5278 64.7654 66.4292 64.4282 66.2426L39.1929 52.432C38.8326 52.2349 38.5318 51.9446 38.322 51.5915C38.1121 51.2384 38.0009 50.8354 38 50.4247V22.9985C38.0001 22.6753 38.0685 22.3557 38.2007 22.0608L65.5294 37.0184Z" fill="white"/>
                <path d="M38.2012 22.0579L65.5298 37.0155L92.8585 22.0579" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M66.6306 7.16901L91.8659 20.9853C92.2262 21.1825 92.527 21.4728 92.7369 21.8259C92.9467 22.179 93.0579 22.5819 93.0588 22.9927V50.4189C93.0579 50.8296 92.9467 51.2326 92.7369 51.5857C92.527 51.9388 92.2262 52.2291 91.8659 52.4262L66.6306 66.2425C66.293 66.4273 65.9143 66.5241 65.5294 66.5241C65.1445 66.5241 64.7659 66.4273 64.4282 66.2425L39.1929 52.4262C38.8326 52.2291 38.5318 51.9388 38.322 51.5857C38.1121 51.2326 38.0009 50.8296 38 50.4189V22.9927C38.0009 22.5819 38.1121 22.179 38.322 21.8259C38.5318 21.4728 38.8326 21.1825 39.1929 20.9853L64.4282 7.16901C64.7659 6.98428 65.1445 6.88745 65.5294 6.88745C65.9143 6.88745 66.293 6.98428 66.6306 7.16901Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M65.5298 37.0184V66.5294" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <g clip-path="url(#clip3_3301_5449)">
                <path opacity="0.2" d="M49.9709 81.0649V87.1473C49.9709 91.1606 43.4515 94.4716 34.9277 95.0758C38.0291 96.2508 42.0335 96.9576 46.4036 96.9576C56.254 96.9576 64.2405 93.3635 64.2405 88.931C64.2405 85.047 58.1135 81.8096 49.9709 81.0649Z" fill="white"/>
                <path opacity="0.2" d="M32.1343 86.2554C41.9853 86.2554 49.9712 82.6617 49.9712 78.2288C49.9712 73.7958 41.9853 70.2021 32.1343 70.2021C22.2832 70.2021 14.2974 73.7958 14.2974 78.2288C14.2974 82.6617 22.2832 86.2554 32.1343 86.2554Z" fill="white"/>
                <path d="M32.1343 86.2554C41.9853 86.2554 49.9712 82.6617 49.9712 78.2288C49.9712 73.7958 41.9853 70.2021 32.1343 70.2021C22.2832 70.2021 14.2974 73.7958 14.2974 78.2288C14.2974 82.6617 22.2832 86.2554 32.1343 86.2554Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.2974 78.2288V87.1472C14.2974 91.5797 22.2838 95.1738 32.1343 95.1738C41.9847 95.1738 49.9712 91.5797 49.9712 87.1472V78.2288" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.9995 85.5864V94.5049" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M49.9709 81.0649C58.1135 81.8096 64.2405 85.047 64.2405 88.931C64.2405 93.3635 56.254 96.9576 46.4036 96.9576C42.0335 96.9576 38.0291 96.2508 34.9277 95.0758" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.5669 95.0133V97.8494C28.5669 102.282 36.5534 105.876 46.4038 105.876C56.2542 105.876 64.2407 102.282 64.2407 97.8494V88.9309" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M53.5386 96.2886V105.207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M39.269 85.5864V105.207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_3301_5449">
                <rect width="73.4118" height="73.4118" fill="white" transform="translate(1.35254 16.0881)"/>
                </clipPath>
                <clipPath id="clip1_3301_5449">
                <rect width="73.4118" height="73.4118" fill="white" transform="translate(55.647 45.5881)"/>
                </clipPath>
                <clipPath id="clip2_3301_5449">
                <rect width="73.4118" height="73.4118" fill="white" transform="translate(28.8237)"/>
                </clipPath>
                <clipPath id="clip3_3301_5449">
                <rect width="57.0781" height="57.0781" fill="white" transform="translate(10.73 59.5)"/>
                </clipPath>
                </defs>
                </svg>
                
            }

            {
                type === 'thumbnail' && <svg width="200" height="200" viewBox="0 0 221 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5440_10060)">
                <path d="M7.22727 186.364H205.805C209.591 186.364 213.032 183.442 213.032 179.546V43.5073C213.032 39.9359 209.935 36.6891 205.805 36.6891H106.688C103.935 36.6891 101.526 35.3904 100.493 33.1177L94.6428 22.0788C93.2662 19.806 90.8571 18.5073 88.448 18.5073H7.22727C3.0974 18.5073 0 21.4294 0 25.3255V179.546C0 183.118 3.0974 186.364 7.22727 186.364Z" fill="url(#paint0_linear_5440_10060)"/>
                <path opacity="0.59" d="M10.314 186.493H205.248C209.03 186.493 212.468 183.618 212.468 179.783V45.9021C212.468 42.3873 209.373 39.192 205.248 39.192H106.578C103.827 39.192 101.421 37.9139 100.389 35.6772L94.5446 24.8134C93.1694 22.5767 90.7628 21.2986 88.3562 21.2986H7.21977C3.09419 21.2986 0 24.4938 0 28.0086V176.908C0 182.02 4.46938 186.493 10.314 186.493Z" fill="#EFEFEF"/>
                <path d="M200.183 29.9041H8.14453V98.4105H200.183V29.9041Z" fill="#B0B0B0"/>
                <path d="M202.928 37.0308H10.8887V105.537H202.928V37.0308Z" fill="#D8D5D5"/>
                <path d="M209 44.1577H16.9609V112.664H209V44.1577Z" fill="#F3F3F3"/>
                <path d="M220.26 60.3899V176.624C220.26 182.143 215.786 186.364 209.935 186.364H10.3247C4.47403 186.364 0 182.143 0 176.624V75.9743C0 70.4548 4.47403 66.2341 10.3247 66.2341H110.818C113.571 66.2341 116.325 65.26 118.045 63.312L128.37 53.5717C130.435 51.6237 132.844 50.6497 135.597 50.6497H209.935C215.786 50.6497 220.26 55.1951 220.26 60.3899Z" fill="url(#paint1_linear_5440_10060)"/>
                <path d="M220.26 63.0511V176.949C220.26 182.358 215.786 186.494 209.935 186.494H10.3247C4.47403 186.494 0 182.358 0 176.949V78.3223C0 72.9138 4.47403 68.7778 10.3247 68.7778H110.818C113.571 68.7778 116.325 67.8234 118.045 65.9145L128.37 56.3699C130.435 54.461 132.844 53.5066 135.597 53.5066H209.935C215.786 53.5066 220.26 57.9607 220.26 63.0511Z" fill="url(#paint2_linear_5440_10060)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_5440_10060" x1="106.512" y1="-38.2318" x2="106.512" y2="202.419" gradientUnits="userSpaceOnUse">
                <stop stop-color="#DEE1E2"/>
                <stop offset="1" stop-color="#B4BCBE"/>
                </linearGradient>
                <linearGradient id="paint1_linear_5440_10060" x1="110.126" y1="4.77547" x2="110.126" y2="199.344" gradientUnits="userSpaceOnUse">
                <stop stop-color="#DEE1E2"/>
                <stop offset="1" stop-color="#B4BCBE"/>
                </linearGradient>
                <linearGradient id="paint2_linear_5440_10060" x1="110.13" y1="53.5066" x2="110.13" y2="186.494" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EFEFEF"/>
                <stop offset="1" stop-color="#EFEFEF" stop-opacity="0"/>
                </linearGradient>
                <clipPath id="clip0_5440_10060">
                <rect width="220.26" height="200" fill="white"/>
                </clipPath>
                </defs>
                </svg>                
            }
        </>
    )
}

