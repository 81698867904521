import { useEffect, useRef, useState } from "react";
import ThemeCenteredModal from "../../Modals/ThemeCenteredModal";
import { ChatDots, Pencil, Trash, X } from "phosphor-react";
import API from "../../../api";
import { useSelector } from "react-redux";
import { getOasSerialNumberFromMetadata } from "@apiwiz/oas/helper";
import {
  objectDeepClone,
  showSuccessMessage,
  throwServerError,
} from "../../../utils/helper";
import MyProgressBar from "../../ProgressBar";
import ContainerLoader from "../../Loader/ContainerLoader";

const AddOrUpdateReview = (props) => {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(null);
  const { metadata } = useSelector((state) => state.swagger);
  const { currentUser, isAuthenticated } = useSelector((state) => state.user);

  const [saveLoading, setSaveLoading] = useState(false)

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const saveRating = () => {
    if(saveLoading) return;
    let body = {
      email: currentUser.email || "",
      userName: currentUser.username || "",
      comments: data.comments || "",
      rating: data.rating || 0,
      oasVersion: getOasSerialNumberFromMetadata(metadata),
      revision: metadata.revision,
    };

    setSaveLoading(true);

    let fn = API.postSwaggerRating;
    if (data.id) fn = API.updateSwaggerRating;

    fn({ swaggerId: metadata.swaggerId, data: body })
      .then((res) => {
        showSuccessMessage("Saved successfully!");
        setEdit(false);
        setSaveLoading(false);
        if (props.fetchReviews) props.fetchReviews();
      })
      .catch((err) => {
        throwServerError(err);
        setSaveLoading(false);
      });
  };

  const deleteRating = () => {
    API.deleteSwaggerRating({
      swaggerId: metadata.swaggerId,
      revision: metadata.revision,
      isAuthorised: isAuthenticated,
      params: {
        oas: getOasSerialNumberFromMetadata(metadata),
        email: currentUser.email || "",
        ratingId: props.data.id,
      },
    })
      .then((res) => {
        showSuccessMessage("Rating Deleted Successfully!!");
        if (props.fetchReviews) props.fetchReviews();
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  return (
    <>
      {!edit ? (
        props.data ? (
          <div className="p-12px d-flex flex-column gap-8px br-4px border-theme-base-100">
            <div className="d-flex justify-content-between align-items-center gap-10px">
              <div className="fs-14px fw-500">{props.data.userName}</div>
              <div className="d-flex align-items-center gap-12px">
                <div>
                  {new Array(5).fill(1).map((_, index) => {
                    return (
                      <img
                        height={16}
                        width={16}
                        src={`/assets/ratings/${
                          index + 1 > (props.data.rating || 0)
                            ? "star-unfilled.svg"
                            : "star-filled.svg"
                        }`}
                      />
                    );
                  })}
                </div>
                {props.data.userName === currentUser.username ? (
                  <div className="d-flex align-items-center gap-4px">
                    <div
                      onClick={() => {
                        setEdit(true);
                      }}
                      className="cursor"
                    >
                      <Pencil color="var(--primary-color)" />
                    </div>
                    <div
                      onClick={() => {
                        deleteRating();
                      }}
                      className="cursor"
                    >
                      <Trash color="var(--dark-colors-red-09)" />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="fs-14px fw-400 text-theme-content-subtle">
              {props.data.comments}
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setData({ rating: 0, comments: "" });
              setEdit(true);
            }}
            className={`px-12px ${props.className || ""} cursor`}
          >
            <div className="px-12px py-6px d-flex justify-content-center align-items-center gap-6px br-4px border-theme-primary-subtle bg-theme-primary-subtle">
              <div className="fs-14px fw-700 text-theme-content-subtle">
                Add Review:
              </div>
              <div className="d-flex gap-6px">
                {new Array(5).fill(1).map((x, index) => {
                  return (
                    <img
                      height={16}
                      width={16}
                      src={`/assets/ratings/star-unfilled.svg`}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )
      ) : null}
      {edit ? (
        <div className="h--42px p-12px d-flex flex-column gap-12px">
          <div className="d-flex gap-10px align-items-center justify-content-between h-24px">
            <div className="d-flex gap-10px align-items-center">
              <div className="fs-12px text-theme-content-subtle fw-700">
                Add Rating:
              </div>
              <div className="d-flex gap-6px">
                {new Array(5).fill(1).map((x, index) => {
                  return (
                    <img
                      height={16}
                      width={16}
                      className="cursor"
                      onClick={() => {
                        setData({ ...data, rating: index + 1 });
                      }}
                      src={`/assets/ratings/${
                        index + 1 > (data.rating || 0)
                          ? "star-unfilled.svg"
                          : "star-filled.svg"
                      }`}
                    />
                  );
                })}
              </div>
            </div>
            <div className="d-flex ">
              <div
                onClick={() => {
                  setEdit(false);
                }}
                className="w-85px d-flex align-items-center justify-content-center text-theme-content-subtle fs-14px cursor"
              >
                Cancel
              </div>
              <div
                onClick={saveRating}
                className="py-2px px-12px br-4px bg-theme-primary text-theme-base fs-14px cursor"
              >
                {saveLoading ? <ContainerLoader size="sm" variant="theme-base" className="py-2px" /> : props.data ? "Save" : "Post"}
              </div>
            </div>
          </div>
          <div className="h--24px d-flex flex-column gap-6px">
            <div className="fs-12px text-theme-content-subtle fw-400">
              Write a Review
            </div>
            <div className="p-12px br-4px border-theme-primary-subtle bg-theme-primary-subtle text-content-color">
              <textarea
                rows={6}
                onChange={(e) => {
                  setData({ ...data, comments: e.target.value });
                }}
                value={data.comments || ""}
                className="h-100 w-100 input-transparent resize-none text-content-color fs-14px fw-400"
              ></textarea>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default function ReviewModal(props) {
  const [open, setOpen] = useState(false);
  const { metadata } = useSelector((state) => state.swagger);
  const { currentUser, isAuthenticated } = useSelector((state) => state.user);
  const [summary, setSummary] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [currUserReview, setCurrUserReview] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(false);

  const getRatingSummary = () => {
    setSummaryLoading(true);
    API.getRatingSummary({
      swaggerId: metadata.swaggerId,
      params: { oas: getOasSerialNumberFromMetadata(metadata) },
      revision: metadata.revision,
    })
      .then((res) => {
        let summary = res.data?.data || null;
        if (summary) {
          setSummary({
            average: summary.average,
            ratings: summary.ratings,
            count: (summary.ratings || []).reduce((acc, curr) => acc + curr, 0),
          });
        } else setSummary(null);
        setSummaryLoading(false);
      })
      .catch((err) => {
        setSummary(null);
        throwServerError(err);
        setSummaryLoading(false);
      });
  };

  const getReviews = () => {
    setReviewsLoading(true);
    API.getSwaggerRating({
      swaggerId: metadata.swaggerId,
      params: { oas: getOasSerialNumberFromMetadata(metadata) },
      revision: metadata.revision,
    })
      .then((res) => {
        let _reviews = (res.data || {}).data || [];
        let _currUserReview = _reviews.find(
          (x) => x.userName === currentUser.username
        );
        if (_currUserReview) {
          setCurrUserReview(_currUserReview);
          _reviews = [
            _currUserReview,
            ..._reviews.filter((x) => x.userName !== currentUser.username),
          ];
        } else setCurrUserReview(null);

        setReviews(_reviews);
        setReviewsLoading(false);
      })
      .catch((err) => {
        setReviewsLoading(false);
        setReviews([]);
        setCurrUserReview(null);
      });
  };

  useEffect(() => {
    if (open) {
      getReviews();
      getRatingSummary();
    }
  }, [open]);

  const fetchReviews = () => {
    getReviews();
    getRatingSummary();
  };

  if (!isAuthenticated) return null;
  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="cursor d-flex align-items-center justify-content-center gap-4px border-theme-base-200 br-38px h-24px px-8px"
      >
        <ChatDots size={16} color="var(--content-subtle)" />
        <div className="text-theme-content-subtle fs-12px fw-500">Review</div>
      </div>
      <ThemeCenteredModal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        size="md"
        className="vertical-stretched-vh-modal"
        bodyClassName={`d-flex flex-column gap-8px h-100 p-0px`}
      >
        <div className="h-100 pb-16px">
          <div className="h-42px d-flex py-6px pr-6px pl-10px gap-4px align-items-center justify-content-between border-bottom-theme-primary-subtle">
            <div className="fs-16px fw-500 lh-28px text-theme-primary">
              Write a Review
            </div>
            <div className="cursor" onClick={() => {setOpen(false)}}><X weight="bold" size={14} className="mr-8px" /></div>
          </div>
          {reviewsLoading || summaryLoading ? <ContainerLoader className="h--42px" variant="theme-primary" /> : (
            <>
              {summary ? (
                <div className="d-flex px-24px py-16px gap-37px h-158px">
                  <div className="d-flex flex-column w-100">
                    {new Array(5).fill(1).map((_, index) => {
                      return (
                        <div className="d-flex gap-15px w-100">
                          <div className="d-flex gap-2px align-items-center">
                            <div className="fs-14px fw-500">{5 - index}</div>
                            <img
                              className="mt-1px"
                              height={16}
                              width={16}
                              src={`/assets/ratings/star-unfilled.svg`}
                            />
                          </div>
                          <div className="d-flex w-100 align-items-center gap-6px">
                            {/* Progress Indicator */}
                            <div className="w--22px">
                              <MyProgressBar
                                value={
                                  summary.ratings[4 - index]
                                    ? ((summary.ratings[4 - index] || 0) /
                                        summary.count) *
                                      100
                                    : 0
                                }
                              />
                            </div>
                            <div className="w-16px fs-14px text-theme-content-subtle">
                              {summary.ratings[4 - index]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-center align-items-center flex-column gap-8px">
                    <div className="fs-55px fw-500 lh-74px">
                      {summary.average || 0}
                    </div>
                    <div className="d-flex gap-6px">
                      {new Array(5).fill(1).map((x, index) => {
                        return (
                          <img
                            height={16}
                            width={16}
                            src={`/assets/ratings/${
                              index + 1 > (summary.average || 0)
                                ? "star-unfilled.svg"
                                : "star-filled.svg"
                            }`}
                          />
                        );
                      })}
                    </div>
                    <div className="fs-13px fw-500">
                      {summary.count} Review{summary.count > 1 ? "s" : ""}
                    </div>
                  </div>
                </div>
              ) : null}
              {!currUserReview ? (
                <AddOrUpdateReview
                  className="py-16px"
                  fetchReviews={fetchReviews}
                />
              ) : null}
              {reviews.length ? (
                <>
                  <div className="p-12px fs-12px fw-700 text-theme-content-subtle h-42px">
                    Reviews:
                  </div>
                  <div
                    style={{ height: "calc(100% - 242px)" }}
                    className="d-flex flex-column gap-6px px-12px pt-6px overflow-scroll-y noscrollbar"
                  >
                    {reviews.map((el) => (
                      <AddOrUpdateReview
                        data={el}
                        fetchReviews={fetchReviews}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </ThemeCenteredModal>
    </>
  );
}
