import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Loading from '../../../../components/Loader/Loading';
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import { throwServerError } from '../../../../utils/helper';
import GlobalIcons from '../../../../components/Icons/GlobalIcons';
import { confirm } from '../../../../utils/helper';
import { Eye, EyeSlash } from 'phosphor-react';

export default function AWSConnectorModal(props) {
    const [bucketName, setBucketName] = useState('')
    const [key, setKey] = useState('')
    const [directory, setDirectory] = useState('')
    const [secret, setSecret] = useState('')
    const [disableSave, setDisableSave] = useState(false)
    const [errors, setErrors] = useState({})
    const [isDefault, setIsDefault] = useState(false)
    const [showAccessKey, setShowAccessKey ] = useState(false)
    const [showSecretKey, setShowSecretKey] = useState(false)


    function handleDelete(){
        if (!confirm("Are you sure to delete this connector's data?")) return
        
        API.deleteAWSDetails()
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Deleted AWS S3 details successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            // throwServerError(err)
        })

    }

    useEffect(() => {
        if (props.isEdit && props.data){
            let _data = props.data || {}
            setBucketName(_data.AWS_BUCKET || '')
            setKey(_data.AWS_ACCESS_KEY || '')
            setDirectory(_data.UPLOAD_DIRECTORY || '')
            setSecret(_data.AWS_SECRET_KEY || '')
            setIsDefault(_data.isDefault || false)
        }
    }, [props.data])
    
    const handleSubmit = () => {
        let tempErrors = {}
        if (!bucketName){
            tempErrors['bucketName'] = 'Bucket Name is required'
        }
        if (!key){
            tempErrors['key'] = 'Access Key is required'
        } 
        if (!directory){
            tempErrors['directory'] = 'Upload Directory is required'
        }
        if (!secret){
            tempErrors['secret'] = 'AWS Secret is required'
        }

        setErrors(tempErrors)

        if (Object.keys(tempErrors).length) return

        let json = {
            'AWS_ACCESS_KEY': key, 'AWS_BUCKET': bucketName, 
            'AWS_SECRET_KEY': secret, 'UPLOAD_DIRECTORY': directory,
            isDefault
        }

        if (props.isEdit){
            API.updateAWSDetails(json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'Updated AWS S3 details successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            API.postAWSDetails(json)
            .then(res => {
                getToast({
                    statusType: 'SUCCESS', message: 'AWS S3 details added successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>{props.isEdit ? 'Edit' : 'Add'} S3 Connector</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSubmit()
                        e.preventDefault()
                    }
                }}>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Bucket Name <span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control' placeholder='Enter S3 bucket name'
                            value={bucketName} onChange={(e) => {
                                let _str = e.target.value 
                                setBucketName(_str)
                                setErrors({...errors, bucketName: _str.length > 0 ? false : 'Bucket Name is Required'})
                            }} />
                        
                        {errors.bucketName && <p className='text-danger mt-1 fs-11px'>
                            {errors.bucketName}
                        </p>}
                    </div>
                    <div className='mb-3 position-relative'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Access Key <span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control pe-5' placeholder='Enter S3 Access Key'
                            value={key} onChange={(e) => {
                                let _str = e.target.value
                                setKey(_str)
                                setErrors({...errors, key: _str.length > 0 ? false : 'Access Key is Required'})
                            }}
                            type={showAccessKey ? 'text' : 'password'}
                            />
                            <div className='eyeIconPass cursor' onClick={() => setShowAccessKey(curr => !curr)}>
                            {showAccessKey
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                            </div>

                        {errors.key && <p className='text-danger mt-1 fs-11px'>
                            {errors.key}
                        </p>}
                    </div>
                    <div className='mb-3 position-relative'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Secret Key <span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control pe-5' placeholder='Enter S3 Secret Key'
                            value={secret} onChange={(e) => {
                                let _str = e.target.value
                                setSecret(_str)
                                setErrors({...errors, secret: _str.length > 0 ? false : 'Secret Key is Required'})
                            }} 
                            type={showSecretKey ? 'text' : 'password'}
                            />
                            <div className='eyeIconPass cursor' onClick={() => setShowSecretKey(curr => !curr)}>
                            {showSecretKey
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                            </div>
                        
                        {errors.secret && <p className='text-danger mt-1 fs-11px'>
                            {errors.secret}
                        </p>}
                    </div>
                    <div className='mb-3'>
                        <p className='fw-400 fs-12px text-300 mb-1'>Upload Directory <span className='text-danger'>*</span></p>
                        <input className='v1-form-control form-control' placeholder='Enter Upload Directory Name'
                            value={directory} onChange={(e) => {
                                let _str = e.target.value
                                setDirectory(_str)
                                setErrors({...errors, directory: _str.length > 0 ? false : 'Upload Directory is Required'})
                            }} />
                        {errors.directory && <p className='text-danger mt-1 fs-11px'>
                            {errors.directory}
                        </p>}
                    </div>

                    <div className='d-flex align-items-center cursor' onClick={() => setIsDefault(!isDefault)}>
                        <GlobalIcons type={isDefault ? 'form-check' : 'form-uncheck'} />
                        <p className='fw-400 fs-14px text-100 ps-1'>Make it Default</p>
                    </div>
                    {(props.isEdit && props.getDeletePermissionAWS) && <div className='btn fs-12px text-danger br-6px'
                        onClick={handleDelete}
                        style={{position: 'absolute', bottom: 10, left: 10, border: '1px solid #e5484d'}}>
                        Delete
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
