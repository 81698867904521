import { useEffect, useMemo, useRef, useState } from "react";
import ThemeCenteredModal from "../../Modals/ThemeCenteredModal";
import { AiFillCheckCircle } from "react-icons/ai";
import { Gear, X } from "phosphor-react";
import { useSelector } from "react-redux";
import Apikey from "./Apikey";
import Bearer from "./Bearer";
import Basic from "./Basic";
import Oauth from "./Oauth";
import {getSecuritiesTabsForOperation, checkAuthorised} from "@apiwiz/oas"


export default function ConfigureAuth(props) {
  const [isAuthorised, setIsAuthorised] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [operationSecurities, setOperationSecurities] = useState({});
  const { securities, tryoutOperation } = useSelector(
    (state) => state.swagger
  );


  useEffect(() => {
    let operationSecuritiesTabs = getSecuritiesTabsForOperation({operationSecurities: tryoutOperation.__security, storedSecurities: securities})
    
    setOperationSecurities(operationSecuritiesTabs);
    let _keys = Object.keys(operationSecuritiesTabs);
    if (_keys.length) setSelectedTab(_keys[0]);
  }, [tryoutOperation]);


  useEffect(() => {
    setIsAuthorised(checkAuthorised({operationSecurities: operationSecurities, storedSecurities: securities}));
  }, [operationSecurities, securities])

  const [actionsRef, setActionsRef] = useState(null);

  const closeModal = () => {
    setOpen(false);
  };

  if(!Object.keys(operationSecurities).length) return null;

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className={`cursor h-38px br-4px border-theme-primary d-flex justify-content-center align-items-center gap-4px ${
          isAuthorised ? "bg-theme-base" : ""
        }`}
      >
        <div className="settings-gear">
          <div className="h-16px w-16px d-flex">
            <Gear size={16} weight="bold" color="var(--primary-color)" />
          </div>
          {isAuthorised ? (
            <div className="icon-badge check">
              <AiFillCheckCircle
                size={11}
                className="text-success bg-theme-base"
              />
            </div>
          ) : (
            <div className="icon-badge danger">
              <div className="h-7px w-7px bg-danger br-50"></div>
            </div>
          )}
        </div>
        <div className="fs-14px fw-600 lh-21px text-theme-primary">
          Configure Authorization
        </div>
      </div>
      <ThemeCenteredModal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        size="lg"
        className="vertical-stretch-modal"
        bodyClassName={`d-flex flex-column gap-8px h-100`}
      >
        <div className="h-100">
          <div className="h-42px d-flex py-6px pr-6px pl-10px gap-4px align-items-center justify-content-between border-bottom-theme-primary-subtle">
            <div className="fs-16px fw-500 lh-28px text-theme-primary">
              Authorization
            </div>
            <div className="d-flex ">
              {actionsRef || (
                <div
                  onClick={closeModal}
                  className="d-flex align-items-center justify-content-center text-theme-content-subtle fs-14px cursor pr-8px mt--2px"
                >
                  <X weight="bold" size={16} />
                </div>
              )}
            </div>
          </div>
          <div className="h--42px d-flex flex-column pb-12px px-12px gap-16px">
            <div className="h-48px w-100 d-flex justify-content-around align-items-center border-bottom-theme-secondary-200">
              {Object.keys(operationSecurities).map((key) => {
                let el = operationSecurities[key];
                return (
                  <div
                    onClick={() => {
                      setSelectedTab(key);
                      if (key !== "oauth2") setActionsRef(null);
                    }}
                    className={`cursor d-flex h-100 w-100 align-items-center justify-content-center fs-16px lh-21px fw-500 ${
                      key === selectedTab
                        ? "border-bottom-theme-primary _2px text-theme-primary"
                        : "text-theme-content-subtle"
                    }`}
                  >
                    {el}
                  </div>
                );
              })}
            </div>
            <div className="h--52px">
              {selectedTab === "oauth2" ? (
                <Oauth
                  closeModal={closeModal}
                  updateActionsRef={(el) => {
                    setActionsRef(el);
                  }}
                />
              ) : selectedTab === "apiKey" ? (
                <Apikey />
              ) : selectedTab === "bearer" ? (
                <Bearer />
              ) : selectedTab === "basic" ? (
                <Basic />
              ) : null}
            </div>
          </div>
        </div>
      </ThemeCenteredModal>
    </>
  );
}
