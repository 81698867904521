import { useSelector } from "react-redux";
import OverallStyles from "../../styles-config.json";
import { useTheme } from "../../context/ThemeContext";
import { objectDeepClone, setColor } from "../../utils/helper";
import { MoonStars, SunDim } from "phosphor-react";

export const ThemeToggler = () => {
  const { theme, setTheme } = useTheme();
  const { currentLayoutObject } = useSelector((state) => state.layout);

  const handleOnChange = () => {
    const _data = objectDeepClone(currentLayoutObject);
    let _newTheme = theme === "theme--light" ? "theme--dark" : "theme--light";
    let currentTheme =
      OverallStyles[_data.siteTemplateType || "Purple Magic"][_newTheme];

    Object.keys(currentTheme).forEach((eachVariable) => {
      setColor(eachVariable, currentTheme[eachVariable]);
    });
    setColor(
      "--currentBackground",
      _newTheme === "theme--light" ? "white" : "var(--base-color)"
    );

    setTheme({
      type: "TOGGLE_THEME",
      payload: { theme: _newTheme },
    });
  };
  return (
    <div className="darkModeToggle position-relative ms-3">
      {theme === "theme--light" ? (
        <MoonStars className="sunIcon" size={20} color="var(--base-color)" />
      ) : (
        <SunDim className="moonIcon" size={20} color="var(--base-color)" />
      )}
      <input
        type="checkbox"
        className=""
        id="dark-mode"
        checked={theme === "theme--dark"}
        onClick={() => handleOnChange()}
      />
      <label htmlFor="dark-mode"></label>
      <div className="background"></div>
    </div>
  );
};
