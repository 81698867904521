import { ArrowLeft } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import API from '../../../../api';
import Loading from '../../../../components/Loader/Loading';
import { getToast } from '../../../../components/Toast';
import { objectDeepClone, throwServerError } from '../../../../utils/helper';
import Layouts from './availableLayouts.json';

export default function LayoutModal(props) {
    const [selectedCategory, setSelectedCategory] = useState('hero')
    const [selectedFrame, setSelectedFrame] = useState({
        category: '', obj: {}
    })
    const [disableSave, setDisableSave] = useState(false)
    const [step, setStep] = useState(1)
    const [data, setData] = useState({
        hero: [], card: [], logo: [], image: []
    })
    const [title, setTitle] = useState('')
    const { currentLayoutObject } = useSelector(state => state.layout)

    useEffect(() => {
        let _data = {
            hero: [], card: [], logo: [], image: []
        }

        Layouts.contents.forEach(each => {
            _data[each.category].push(objectDeepClone(each))
        })

        setData(objectDeepClone(_data))
    }, [])

    const handleSubmit = () => {
        if (step === 1){
            setStep(2)
            return
        }
        if (!title) {
            getToast({
                statusType: 'ERROR', message: 'Title is required before adding a section'
            })
            return
        }

        let _final = {
            title, ...selectedFrame.obj
        }

        let _data = objectDeepClone(currentLayoutObject.contents || [])
        let existingNames = _data.map(o => o.title?.trim())

        if (existingNames.includes(title.trim())){
            getToast({
                statusType: 'ERROR', message: 'Section with this title already exists'
            })
            return
        }

        _data.push(objectDeepClone(_final))

        API.putLayoutDetails({contents: _data})
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Section added successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            throwServerError(err)
        })
    }

    const [isCtrl, setIsCtrl] = useState(false)

    return (
        <Modal
            {...props}
            size="xl" className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header className='p-3'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>Select Layout</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { selectedFrame.category
                        && (disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            {step === 1 ? 'Review' : 'Save'}
                        </div>)}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(60vh)" }}
                className="overflow-scroll noscrollbar p-0"
            >
                {step === 2
                ? <div className='surface-850 p-16px h-100'  onKeyDown={(e) => {
                    setIsCtrl(['Control', 'Meta'].includes(e.key))
                    if (isCtrl && e.key?.toLowerCase() === 's') {
                        handleSubmit()
                        e.preventDefault()
                    }
                }}>
                    <div className='mb-2'>
                        <p className='fs-12px fw-400 text-300 mb-1'> Page Title <span className='text-danger'>*</span></p>
                        <input className='form-control v1-form-control' value={title} 
                            onChange={(e) => setTitle(e.target.value)} />
                    </div>

                    <img src={selectedFrame.obj?.url || ''} alt='Layout Img' className='mt-2 mb-3'
                        width='100%' height={300} style={{objectFit: 'contain'}} />

                    <div className='surface-900 px-20px py-15px v1-border-1 br-4px w-fit d-flex align-items-center mx-auto cursor'
                        onClick={() => {
                            setSelectedFrame({category: '', obj: {}});
                            setTitle("")
                            setStep(1)
                        }}>
                        <ArrowLeft size={11} color='#E4E4E4' className='me-2' />

                        <p className='fw-500 fs-12px text-300'>Select Different Layout</p>
                    </div>
                </div>
                : <div className='d-flex align-items-center w-100 h-100'>
                    <div className='w-30 surface-900 p-16px h-100'>
                        <p className='text-100 fs-12px fw-500 py-16px mb-3'>Page Types</p>

                        {Object.keys(data).map((eachCategory, i) => <div key={i} className={`p-12px cursor ${selectedCategory === eachCategory ? 'v1-border-blue' : 'v1-border-bottom-1'}`}
                            onClick={() => setSelectedCategory(eachCategory)}>
                            <p className='fs-14px fw-400 text-100 text-capitalize'>{eachCategory} Section ({data[eachCategory].length || 0}) </p>
                        </div>)}
                    </div>
                    <div className={`w-70 surface-850 p-12px h-100 overflow-scroll noscrollbar d-flex align-items-start flex-wrap gap-16px `}>
                        {data[selectedCategory]?.map((each, i) => <div className={`surface-base p-12px cursor ${selectedFrame.obj.id === each.id ? 'v1-border-blue' : ''}`}
                            onClick={() => setSelectedFrame({category: selectedCategory, obj: each})}>
                            <img src={each.url} alt='Section Logo' height={180} width={350}
                                style={{objectFit: 'cover'}} />
                        </div>)}
                    </div>
                </div>}
            </Modal.Body>
        </Modal>
    )
}
