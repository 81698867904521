import { Tabs } from "react-bootstrap";
import { BodyLayout } from "../../../layout/Base";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router";
import UserIcons from "../../../components/Icons/UserIcons";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import Select from "react-select";
import { ApigeeTable } from "./Components/ApigeeTable";
import { AzureTable } from "./Components/AzureTable";
import { KongTable } from "./Components/KongTable";
import API from "../../../api";
import Loading from "../../../components/Loader/Loading";
import { getToast } from "../../../components/Toast";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { NginxTable } from "./Components/NginxTable";
import { GATEWAYS } from "../../../service/constants";
import { AWSTable } from "./Components/AWSTable";
import { AxwayTable } from "./Components/AxwayTable";

const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

const GatewayDetails = () => {
  const { setNavigation, setTitle } = useOutletContext();
  const [gatewayData, setGatewayData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [syncClicked, setSyncClicked] = useState({
    Apigee: false,
    Kong: false,
    Axure: false,
    Nginx: false,
    Axway:false,
    [AWS_GATEWAY]: false
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const gatewayOptions = [
    {
      label: "Apigee",
      value: "Apigee",
    },
    {
      label: "Kong",
      value: "Kong",
    },
    {
      label: "Azure",
      value: "Azure",
    },
    {
      label: "Nginx",
      value: "Nginx",
    },
    {
      label: AWS_GATEWAY,
      value: AWS_GATEWAY,
    },
    {
      label:"Axway",
      value:"Axway"
    }
  ];

  const { firstRoute } = useSelector((state) => state.user);

  const [selectedGateway, setSelectedGateway] = useState({
    label: "Apigee",
    value: "Apigee",
  });

  useEffect(() => {
    setTitle("Gateways");

    setNavigation([
      { name: "Dashboard", link: firstRoute },
      { name: "Gateways", link: "/admin/manage-gateways/gateway-details" },
    ]);
    // by default apigee is selected
    if (searchParams.get("gateway")) {
      const gatewayToSelect = searchParams.get("gateway");
      searchParams.delete("gateway");
      setSearchParams(searchParams);
      console.log({ gatewayToSelect });
      switch (gatewayToSelect) {
        case "Apigee":
          return gatewayOnChange({ label: "Apigee", value: "Apigee" });
        case "Kong":
          return gatewayOnChange({ label: "Kong", value: "Kong" });
        case "Azure":
          return gatewayOnChange({ label: "Azure", value: "Azure" });
        case "Nginx":
          return gatewayOnChange({ label: "Nginx", value: "Nginx" });
      }
    } else {
      getOrgs();
    }
  }, []);

  const getOrgs = useCallback(() => {
    setDataLoading(true);
    API.getOrgs()
      .then((res) => {
        setGatewayData(res?.data?.data || []);
        setDataLoading(false);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Fetch Organisations",
        });
        setDataLoading(false);
      });
  }, []);

  const getRuntimes = useCallback(() => {
    setDataLoading(true);
    API.getRuntimes()
      .then((res) => {
        setGatewayData(res?.data?.data || []);
        setDataLoading(false);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Fetch Runtimes",
        });
        setDataLoading(false);
      });
  }, []);

  const getNginxRuntimes = useCallback(() => {
    setDataLoading(true);
    API.getNginxRuntimes()
      .then((res) => {
        setGatewayData(res?.data?.data || []);
        setDataLoading(false);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Fetch Runtimes",
        });
        setDataLoading(false);
      });
  });

  const getAwsRuntimes = () => {
    setDataLoading(true);
    API.getAwsRuntimes()
      .then((res) => {
        setGatewayData(res?.data?.data || []);
        setDataLoading(false);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Fetch Runtimes",
        });
        setDataLoading(false);
      });
  };

  const getResourceGroups = useCallback(() => {
    setDataLoading(true);
    API.getResourceGroups()
      .then((res) => {
        setGatewayData(res?.data?.data || []);
        setDataLoading(false);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Fetch Resource Groups",
        });
        setDataLoading(false);
      });
  }, []);

  const syncOrgs = useCallback(() => {
    setDataLoading(true);
    API.syncOrgs()
      .then((res) => {
        getOrgs();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Sync Organisations",
        });
      });
  }, []);

  const syncRuntimes = useCallback(() => {
    setDataLoading(true);
    API.syncRuntimes()
      .then((res) => {
        getRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Sync Runtimes",
        });
      });
  }, []);

  const syncNginxRuntimes = useCallback(() => {
    setDataLoading(true);
    API.syncNginxRuntimes()
      .then((res) => {
        getNginxRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Sync Runtimes",
        });
      });
  });

  const getAxwayRuntimes = () => {
    setDataLoading(true);
    API.getAxwayRuntimes()
      .then((res) => {
        setGatewayData(res?.data?.data || []);
        setDataLoading(false);
      })
      .catch((err) => {
        getToast({
          statusType: "ERROR",
          message: "Unable to Fetch Runtimes",
        });
        setDataLoading(false);
      });
  };

  const syncAxwayRuntimes = useCallback(() => {
    setDataLoading(true);
    API.syncAxwayRuntimes()
      .then((res) => {
        getAxwayRuntimes()
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Sync Runtimes",
        });
      });
  });

  const syncAwsRuntimes = () => {
    setDataLoading(true);
    API.syncAwsRuntimes()
      .then((res) => {
        getAwsRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Sync Runtimes",
        });
      });
  };

  const syncResourceGroups = useCallback(() => {
    setDataLoading(true);
    API.syncResourceGroups()
      .then((res) => {
        getResourceGroups();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Sync Resource Groups",
        });
      });
  }, []);

  const setDefaultApigeeOrg = useCallback((id) => {
    setDataLoading(true);
    API.setDefaultOrg({ id })
      .then((res) => {
        getOrgs();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Set Default Organisations",
        });
      });
  }, []);

  const setDefaultRuntime = useCallback((id) => {
    setDataLoading(true);
    API.setDefaultRuntime({ id })
      .then((res) => {
        getRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Set Default Runtime",
        });
      });
  }, []);

  const setDefaultNginxRuntime = useCallback((id) => {
    setDataLoading(true);
    API.setDefaultNginxRuntime({ id })
      .then((res) => {
        getNginxRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Set Default Runtime",
        });
      });
  }, []);

  const setDefaultAwsRuntime = (id) => {
    setDataLoading(true);
    API.setDefaultAwsRuntime({ id })
      .then((res) => {
        getAwsRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Set Default Runtime",
        });
      });
  };

  const setDefaultAxwayRuntime = (id) => {
    setDataLoading(true);
    API.setDefaultAxwayRuntime({ id })
      .then((res) => {
        getAxwayRuntimes();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Set Default Runtime",
        });
      });
  };

  const setDefaultResource = useCallback((id) => {
    setDataLoading(true);
    API.setDefaultResource({ id })
      .then((res) => {
        getResourceGroups();
      })
      .catch((err) => {
        setDataLoading(false);
        getToast({
          statusType: "ERROR",
          message: "Unable to Set Default Resource Group",
        });
      });
  }, []);

  const syncGateways = useCallback(() => {
    switch (selectedGateway.value) {
      case "Apigee":
        return syncOrgs();
      case "Azure":
        return syncResourceGroups();
      case "Kong":
        return syncRuntimes();
      case "Nginx":
        return syncNginxRuntimes();
      case AWS_GATEWAY:
        return syncAwsRuntimes();
      case "Axway":
        return syncAxwayRuntimes();
      default:
        return null;
    }
  }, [selectedGateway]);

  const gatewayTable = useMemo(() => {
    switch (selectedGateway.value) {
      case "Apigee":
        return (
          <ApigeeTable
            data={gatewayData}
            onDefaultChange={setDefaultApigeeOrg}
            syncGateways={syncGateways}
            syncClicked={syncClicked}
            setSyncClicked={setSyncClicked}
          />
        );
      case "Azure":
        return (
          <AzureTable
            data={gatewayData}
            onDefaultChange={setDefaultResource}
            syncGateways={syncGateways}
            syncClicked={syncClicked}
            setSyncClicked={setSyncClicked}
          />
        );
      case "Kong":
        return (
          <KongTable
            data={gatewayData}
            onDefaultChange={setDefaultRuntime}
            syncGateways={syncGateways}
            syncClicked={syncClicked}
            setSyncClicked={setSyncClicked}
          />
        );

      case "Nginx":
        return (
          <NginxTable
            data={gatewayData}
            onDefaultChange={setDefaultNginxRuntime}
            syncGateways={syncGateways}
            syncClicked={syncClicked}
            setSyncClicked={setSyncClicked}
          />
        );
      case AWS_GATEWAY:
        return (
          <AWSTable
            data={gatewayData}
            onDefaultChange={setDefaultAwsRuntime}
            syncGateways={syncGateways}
            syncClicked={syncClicked}
            setSyncClicked={setSyncClicked}
          />
        );
      case "Axway":
        return (
          <AxwayTable
            data={gatewayData}
            onDefaultChange={setDefaultAxwayRuntime}
            syncGateways={syncGateways}
            syncClicked={syncClicked}
            setSyncClicked={setSyncClicked}
          />
        );
    }
  }, [selectedGateway, gatewayData]);

  const gatewayOnChange = useCallback(
    (data) => {
      setSelectedGateway(data);
      switch (data.value) {
        case "Apigee":
          setSyncClicked((prev) => ({
            ...prev,
            Apigee: false,
          }));
          return getOrgs();
        case "Azure":
          setSyncClicked((prev) => ({
            ...prev,
            Azure: false,
          }));
          return getResourceGroups();
        case "Kong":
          setSyncClicked((prev) => ({
            ...prev,
            Kong: false,
          }));
          return getRuntimes();
        case "Nginx":
          setSyncClicked((prev) => ({
            ...prev,
            Nginx: false,
          }));
          return getNginxRuntimes();
        case AWS_GATEWAY:
          setSyncClicked((prev) => ({
            ...prev,
            [AWS_GATEWAY]: false,
          }));
          return getAwsRuntimes();
        case "Axway":
          setSyncClicked((prev) => ({
            ...prev,
            Axway: false,
          }));
          return getAxwayRuntimes();
        default:
          setSyncClicked((prev) => ({
            ...prev,
            Apigee: false,
          }));
          return getOrgs();
      }
    },
    [selectedGateway]
  );

  const GatewayTableTitle = useMemo(() => {
    switch (selectedGateway.value) {
      case "Apigee":
        return "Organisations";
      case "Azure":
        return "Resource Group";
      case "Kong":
        return "Runtimes";
      case "Nginx":
        return "Runtimes";
      case AWS_GATEWAY:
        return "Runtimes";
      case "Axway":
        return "Runtimes";
    }
  }, [selectedGateway]);

  return (
    <BodyLayout>
      <div className="fadeIn surface-900 w-100 h-100 gateway-details">
        <div className="tabs-container w-100 custom-tab-container">
          <div className="surface-875 p-8px d-flex justify-content-between align-items-center v1-border-bottom-05">
            <div>
              <p className="fs-18px fw-600">{GatewayTableTitle}</p>
            </div>
            <div className="d-flex align-items-center gap-16px">
              <Select
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "focused"
                      : state.isSelected
                      ? "selected"
                      : "",
                }}
                className={`v1select w-128px cursor-pointer ${
                  dataLoading ? "disabled" : ""
                }`}
                placeholder="Select Gateway"
                options={gatewayOptions}
                value={selectedGateway}
                onChange={(data) => {
                  gatewayOnChange(data);
                }}
                isDisabled={dataLoading}
              />
              {/* Show sync button on top if data is already present */}
              <>
                {gatewayData && gatewayData.length > 0 && (
                  <div
                    className="btn v1-btn-primary p-2"
                    onClick={(evt) => {
                      if (dataLoading) {
                        evt.preventDefault();
                      } else {
                        syncGateways();
                      }
                    }}
                  >
                    <UserIcons type={"sync"} />
                    <p className="text-200 ps-2">Sync</p>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="p-12px">
          {dataLoading ? (
            <div className="h-75vh overflow-scroll noscrollbar">
              <Loading />
            </div>
          ) : (
            gatewayTable
          )}
        </div>
      </div>
    </BodyLayout>
  );
};

export default GatewayDetails;
