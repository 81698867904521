import { ApplePodcastsLogo, PencilSimple } from 'phosphor-react'
import React from 'react'
import { formatCtsDate, formatCtsTime } from '../../../../utils/helper'

export default function GuideGrid({data, handleEdit, getUpdatePermission, onClick}) {
    return (
        <div onClick={onClick ? () => {onClick(data)} : null} className={`${onClick ? "cursor" : ""} p-16px br-8px surface-875 v1-border-1 w-360px`}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <ApplePodcastsLogo size={32} color="#fff" />

                    <div class="ms-2 violet-background violet-11 px-8px py-4px br-12px fs-12px fw-500 w-fit">
                        {data.category || ''}
                    </div>
                </div>

                {getUpdatePermission && <div onClick={handleEdit ? (e) => {
                    e.stopPropagation();
                    handleEdit(e)
                } : null} className='cursor'>
                    <PencilSimple size={20} color="#fff" />
                </div>}
            </div>

            <p className='fs-20px fw-700 text-300 mt-12px mb-8px'>
                {data.name || ''}
            </p>

            <div style={{height: 72}} className='mb-4px'>
                <p className='text-truncate-3 text-400 fs-14px fw-400'>
                    {data.description || ''}
                </p>
            </div>

            <div>
                <p className='fs-12px fw-500 text-400'>Written by-</p>
                <p className='fs-14px fw-500 text-300 mb-2px'>{data.created?.by || ''}</p>
                <p className='fs-14px fw-500 text-400'>{formatCtsTime(data.createdAt)} - {formatCtsDate(data.createdAt)} (UTC)</p>
            </div>
        </div>
    )
}
