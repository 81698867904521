import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { confirm, throwServerError } from '../../../../utils/helper';
import API from '../../../../api';
import { getToast } from '../../../../components/Toast';
import Loading from '../../../../components/Loader/Loading';
import GlobalIcons from '../../../../components/Icons/GlobalIcons';
import { objectDeepClone } from '@apiwiz/oas/services';

export default function DeleteUsers(props) {
    const [userList, setUserList] = useState([])
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([])
    const [searchVal, setSearchVal] = useState("");

    function handleOnSave(){
        if (!selected || selected.length === 0){
            getToast({
                statusType: "ERROR", message: "No users selected to delete"
            })
            return
        }
        if (!confirm(`Are you sure to delete ${selected.length} users from the workspace? `)) return

        API.deleteUsers({
            userIds: objectDeepClone(selected)
        }).then(() => {
            getToast({
                statusType: "SUCCESS", message: "Successfully deleted selected users"
            })
            props.onHide();
            props.parentCallback();
        }).catch((err) => {
            throwServerError(err)
        })
    }

    const getData = () => {
        setLoading(true)
        API.getUsers()
        .then(res => {
            setLoading(false)
            setUserList(res.data.data);
        }).catch((err) => {
            throwServerError(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [])
    
    const handleSelect = (_id) => {
        const _selected = objectDeepClone(selected || []);
        if (_selected.includes(_id)){
            let id = _selected.indexOf(_id)
            _selected.splice(id, 1)
        } else {
            _selected.push(_id)
        }

        setSelected(objectDeepClone(_selected))
    }
    
    return (
        <Modal
            {...props}
            size="lg me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="py-8 pr-8 pl-16 d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 text-300 fw-500 fs-16px">
                            Delete User(s)
                        </p>
                    </div>

                    <div className="d-flex align-items-center">
                        <div
                            onClick={props.onHide}
                            className="text-300 fw-400 fs-12px mr-14px cursor-pointer"
                        >
                            Cancel
                        </div>
                        <div
                            onClick={handleOnSave}
                            className="btn-md-v1 text-200 fw-400 fs-12px surface-850"
                        >
                            Submit
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0"
            >
                {loading ? <Loading /> : 
                    <div className="p-0 h-100">
                        <div className='w-100'>
                            <div className='d-flex align-items-center w-100 searchBar'>
                                <input placeholder="Search by User email or User name" 
                                    type={'text'}
                                    className={`form-control v1-form-control`}
                                    value={searchVal} 
                                    onChange={(e) => setSearchVal(e.target.value)} />
                                <div className='searchIcon'>
                                    <GlobalIcons type={'search'} />
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 mb-1 br-4px surface-850 p-1 row mx-0'>
                            <div className="col-md-9 dfaic">
                                <div style={{ height: 1, width: 28 }}></div>
                                <p className="fs-12px fw-400 text-200">
                                    Name / Email
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p className="fs-12px fw-400 text-200">
                                    Status
                                </p>
                            </div>
                        </div>
                        <div className="overflow-scroll noscrollbar" style={{ height: `calc(100% - 70px)` }}>
                            {
                                userList.map((eachUser) => {
                                    if (eachUser.status === "deleted") return;
                                    let regExp = new RegExp(searchVal, "i");
                                    if (searchVal && (eachUser.email?.search(regExp) === -1 && eachUser.username?.search(regExp) === -1 )) return;

                                    return (
                                        <div className="row px-1 py-2 surface-875 mb-1 mx-0 cursor" key={eachUser._id} onClick={() => handleSelect(eachUser._id)}>
                                            <div className="col-md-9 dfaic">
                                                <div className="cursor">
                                                    <GlobalIcons type={selected.includes(eachUser._id) ? "form-check" : "form-uncheck"} />
                                                </div>
                                                <p className="fs-13px fw-500 text-100 ms-1 text-truncate">
                                                    {eachUser.username} <span className="fw-400 text-300 fs-12px">({eachUser.email})</span>
                                                </p>
                                            </div>
                                            <div className="col-md-3">
                                                <p className="fs-12px fw-400 text-200">
                                                    {eachUser.status}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}