import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    href="#"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export const EditDeleteDropdown = ({ editCallback, deleteCallback }) => {
  const [show, setShow] = useState(false)
  return (
    <Dropdown
      className="post-edit-delete-dropdown__container customNavbarDropdowns"
      show={show}
    >
      <Dropdown.Toggle as={CustomToggle} id="post-edit-delete-menu" onClick={() => {
        setShow(prev => !prev)
      }}>
        <GlobalIcons type={"three-dots"} />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="post-edit-delete-dropdown-menu"
      >
        <Dropdown.Item
          onClick={(evt) => {
            evt.stopPropagation();
            editCallback();
            setShow(false)
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(evt) => {
            evt.stopPropagation();
            if (window.confirm("Are you sure, you want to delete?")) {
              deleteCallback();
            }
            setShow(false)
          }}
        >
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
