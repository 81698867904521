import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '../../context/ThemeContext'
import { ArrowLeft, Eye, EyeSlash } from 'phosphor-react'
import { useDispatch } from 'react-redux'
import API from '../../api'
import { encryptPassword, objectDeepClone, throwServerError, validateAllowedEmailDomains, validateEmail } from '../../utils/helper'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Captcha from "../../components/Captcha";
import { getToast } from '../../components/Toast'
import countryList from '../../countries.json'
import Select from 'react-select'

export default function Register({isInviteUser}) {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const {theme} = useTheme()
    const {id} = useParams()

    const [showPass, setshowPass] = useState(false)
    const [showPass2, setshowPass2] = useState(false)
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loginData, setLoginData] = useState({
		disableSignup: false,
		disableLogin: false,
		loginMethod: [],
        allowedDomains: []
	});
    const [step, setStep] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const [matched, setMatched] = useState(false)
    const navigate = useNavigate()
    const {guestsetting} = useSelector(state => state.setting)
    const [country, setCountry] = useState('')
    const [emailCheckLoading, setEmailCheckLoading] = useState(false)
    const [usernameCheckLoading, setUsernameCheckLoading] = useState(false)

    const options = useMemo(() => {
        let _temp = countryList
        _temp = _temp.map(o => ({label: o.label, value: o.label}))
        return _temp
    }, [])

    useEffect(() => {
		if (guestsetting.disableSignup){
            navigate(`/`)
        }
        setLoginData(objectDeepClone(guestsetting));

        if (isInviteUser){
            API.getInviteUser(id)
            .then(res => {
                console.log('res.data', res.data)
                setEmail(res.data?.data?.email || '')
            }).catch(err => {
                throwServerError(err)
            })
        }
	}, [guestsetting]);

    const handleNext = (e) => {
        e.preventDefault()

        let temp = { ...errors }
        if (!fullname.trim().length){
            temp['fullname'] = 'Full Name is Required'
        }
        else if (fullname.split(" ")?.length < 2){
            temp['fullname'] = 'Enter Valid Full Name'
        }
        if (!username.trim().length && !errors["username"]){
            temp['username'] = 'Username is Required'
        }
        if (!email.trim().length && !errors["email"]){
            temp['email'] = 'Email is Required'
        } else if (!validateEmail(email)){
            temp['email'] = 'Email format is Invalid'
        }
        let _error = validateAllowedEmailDomains(email, loginData.allowedDomains)
        if (_error){
            temp['email'] = _error
        }
        if (!country.value?.length){
            temp['country'] = 'Country is Required'
        }

        setErrors(temp)
        if(Object?.entries(temp)?.reduce((acc, [key, value]) => acc || value, false)) return

        setStep(2)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!matched) return

        let temp = {}
        const regex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g

        if (!password.trim().length){
            temp['password'] = 'Password is Required'
        }
        else if (password.trim()?.length < 8){
            temp['password'] = 'Password should be minimum of 8 characters'
        }
        else if(!regex.test(password)){
            temp['password'] = "Password must include uppercase, lowercase, numbers & special characters(!, @, &, %, +)"
        }

        if (password !== confirmPassword){
            temp['confirmPassword'] = 'Both Passwords should match'
        }

        setErrors(temp)
        if (Object.keys(temp).length) return

        let json = {
            email, fullName: fullname, username, country: country.value || ''
        }
        json['password'] = encryptPassword(password)
        json['confirm_password'] = encryptPassword(confirmPassword)

        setIsDisabled(true)
        API.registerUser(json)
        .then(res => {
            setIsDisabled(false)
            navigate(`/register-success`)
        }).catch(err => {
            let _respObject = err.response.data
            console.log('_respObject', _respObject, typeof _respObject.errors)
            if (typeof _respObject.errors === 'object'){
                Object.values(_respObject.errors)?.forEach(each => {
                    getToast({
                        statusType: 'ERROR',
                        message: each || ''
                    })
                })
            } else {
                getToast({
                    statusType: 'ERROR',
                    message: (_respObject.errors || _respObject.message)
                })
            }
            setIsDisabled(false)
        })
    }

    const checkEmailExists = ({ email }) => {
        if(email){
            setIsDisabled(true)
            API.checkEmailExits({ email })
            .then(res => {
                const { data } = res.data
                setIsDisabled(false)
                if(data.exists){
                    setErrors(prev => ({
                        ...prev,
                        email: "Email already exists"
                    }))
                }else{
                    setErrors(prev => ({
                        ...prev,
                        email: false
                    }))
                }
            })
            .catch(err => {
                setIsDisabled(false)
                setErrors(prev => ({
                    ...prev,
                    email: false
                }))
                console.log("Email exists err: ", err)
            })
        }
    }

    const checkUsername = ({ username }) => {
        if(username){
            setIsDisabled(true)
            API.checkUsernameExists({ username })
            .then(res => {
                const { data } = res.data
                setIsDisabled(false)
                if(data.exists){
                    setErrors(prev => ({
                        ...prev,
                        username: "Username already exists"
                    }))
                }else{
                    setErrors(prev => ({
                        ...prev,
                        username: false
                    }))
                }
            })
            .catch(err => {
                setIsDisabled(false)
                setErrors(prev => ({
                    ...prev,
                    username: false
                }))
                console.log("username exists: ", err)
            })
        }
    }

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            { step === 2
            ? <form className='loginContainer h-100' onSubmit={handleSubmit}>
                <div className='mb-20px text-center'>
                    <img src={theme === 'theme--light' ? currentLayoutObject.logo : currentLayoutObject.darklogo}
                        alt='Logo' height={50} width={'100%'}  className='d-block mx-auto'
                        style={{objectFit: 'contain'}} />
                </div>

                <div className='d-flex align-items-center justify-content-center cursor'
                    onClick={() => {
                        setPassword('')
                        setConfirmPassword('')
                        setStep(1)
                        setMatched(false)
                    }}>
                    <ArrowLeft className='me-1' color='var(--primary-color)' />
                    <p className='fw-600 fs-14px text-primary-color'>Back</p>
                </div>

                <div className='mb-32px'>
                    <h3 className='titleText'>Choose a password</h3>
                    <p className='descText'>
                        Password must be atleast 8 characters
                    </p>
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Password<span className='text-danger'>*</span></p>
                    <div className='position-relative'>
                        <input type={showPass ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                            placeholder='Enter your password' value={password}
                            onChange={(e) => {
                                let _str = e.target.value
                                setPassword(_str)
                                setErrors({...errors, password: _str.trim().length < 1 ? 'Password is Required' : false})
                            }} />
                        <div className='eyeIconPass cursor' onClick={() => setshowPass(curr => !curr)}>
                            {showPass
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                    </div>
                    {errors.password && <p className='text-danger fs-12px mt-1'>
                        {errors.password}
                    </p>}
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Confirm Password<span className='text-danger'>*</span></p>
                    <div className='position-relative'>
                        <input type={showPass2 ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                            placeholder='Re-enter your password' value={confirmPassword}
                            onChange={(e) => {
                                let _str = e.target.value
                                setConfirmPassword(_str)
                                setErrors({...errors, confirmPassword: _str.trim().length < 1 ? 'Confirm Password is Required' : false})
                            }} />
                        <div className='eyeIconPass cursor' onClick={() => setshowPass2(curr => !curr)}>
                            {showPass2
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                    </div>
                    {errors.confirmPassword && <p className='text-danger fs-12px mt-1'>
                        {errors.confirmPassword}
                    </p>}
                </div>

                <div className='mb-3'>
                    <Captcha matched={matched} setMatched={setMatched} />
                </div>

                <button type='submit' disabled={!matched || isDisabled} className='border-0 gradientBtnInAuth mb-16px'>
                    Register
                </button>

                {!loginData.disableLogin && <div className='dontHaveAnAccount'>
                    <Link to={`/login`}>
                        Already have an account? <span>Sign In</span>
                    </Link>
                </div> }
            </form>
            : <form className='loginContainer h-100' onSubmit={handleNext}>
                <div className='mb-20px text-center'>
                    <img src={theme === 'theme--light' ? currentLayoutObject.logo : currentLayoutObject.darklogo}
                        alt='Logo' height={50} className='d-block mx-auto'
                        style={{objectFit: 'contain'}} />
                </div>

                <div className='mb-32px'>
                    <h3 className='titleText'>Create your account</h3>
                    <p className='descText'>
                        Please fill in this form to create an account to view and access our APIs.
                    </p>
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Full Name<span className='text-danger'>*</span></p>
                    <input type='text' className='form-control v1-form-control mt-1' 
                        placeholder='Enter Full Name' value={fullname} 
                        onChange={(e) => {
                            let _str = e.target.value
                            setFullname(_str)
                            setErrors({...errors, fullname: _str.trim().length < 1 ? 'Full Name is Required' : false})
                        }} />
                    {errors.fullname && <p className='text-danger fs-12px mt-1'>
                        {errors.fullname}
                    </p>}
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Username<span className='text-danger'>*</span></p>
                    <input type='text' className='form-control v1-form-control mt-1' value={username}
                        placeholder='Enter your username' onChange={(e) => {
                            let _str = e.target.value
                            setUsername(_str)
                            setErrors({...errors, username: _str.trim().length < 1 ? 'Username is Required' : false})
                        }} 
                        onBlur={() => checkUsername({ username })}
                        />
                    {errors.username && <p className='text-danger fs-12px mt-1'>
                        {errors.username}
                    </p>}
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Email<span className='text-danger'>*</span></p>
                    <input type='email' className='form-control v1-form-control mt-1' value={email}
                        disabled={isInviteUser} placeholder='Enter your email' onChange={(e) => {
                            let _str = e.target.value
                            setEmail(_str)
                            setErrors({...errors, email: _str.trim().length < 1 ? 'Email is Required' : false})
                        }} 
                        onBlur={() => checkEmailExists({email})}
                        />
                    {errors.email && <p className='text-danger fs-12px mt-1'>
                        {errors.email}
                    </p>}
                </div>

                <div className='mb-3 mainSelect'>
                    <p className='text-content-color fw-500 inputText'>Country<span className='text-danger'>*</span></p>
                    <Select options={options} value={country} 
                        classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                        onChange={(e) => {
                            setErrors({...errors, country: false})
                            setCountry(e)
                        }} className='mt-1 v1select' />
                    {errors.country && <p className='text-danger fs-12px mt-1'>
                        {errors.country}
                    </p>}
                </div>

                <button type='submit' disabled={isDisabled} className='border-0 gradientBtnInAuth mb-16px'>
                    Next
                </button>

                {!loginData.disableLogin && <div className='dontHaveAnAccount'>
                    <Link to={`/login`}>
                        Already have an account? <span>Sign In</span>
                    </Link>
                </div> }
            </form>}
        </div>
    )
}
