import { useState } from "react";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import UserIcons from "../../../../components/Icons/UserIcons";
import { useSelector } from "react-redux";

export const KongTable = ({
  data,
  onDefaultChange,
  syncGateways,
  syncClicked,
  setSyncClicked,
}) => {
  const { guestsetting } = useSelector((state) => state.setting)

  return (
    <>
      {data && data.length > 0 ? (
        <>
          {/* Header columns  */}
          <div className="surface-875 p-6px row mx-0">
            <div className="col-md-4 p-0">
              <p className="text-400 fs-12px fw-500">Name</p>
            </div>
            <div className="col-md-4 p-0">
              <p className="text-400 fs-12px fw-500">Workspaces</p>
            </div>
            <div className="col-md-3 p-0">
              <p className="text-400 fs-12px fw-500">Type</p>
            </div>
            <div className="col-md-1 ps-3">
              <p className="text-400 fs-12px fw-500 d-flex justify-content-center">Make Default</p>
            </div>
          </div>
          {/* Table data  */}
          <div className="h-75vh overflow-scroll noscrollbar">
            {/* Row item  */}
            {data.map((row) => (
              <div className="row mx-0 p-14px v1-border-bottom-1" key={row._id}>
                <div className="col-md-4 p-0">
                  <p className="text-200 fs-14px fw-500 text-truncate">
                    {row.name}
                  </p>
                </div>
                <div className="col-md-4 p-0">
                  <p className="text-200 fs-14px fw-500 text-truncate">
                    {row.workspaceCount}
                  </p>
                </div>
                <div className="col-md-3 p-0">
                  <p className="text-200 fs-14px fw-500 text-truncate">
                    {row.type}
                  </p>
                </div>
                <div className="col-md-1 p-0">
                  <p
                    className="text-200 fs-14px fw-500 text-truncate text-center cursor-pointer"
                    onClick={(evt) => {
                      if (row.runtimeDefault) {
                        evt.preventDefault();
                      } else {
                        onDefaultChange(row._id);
                      }
                    }}
                  >
                    <GlobalIcons
                      type={row.runtimeDefault ? "form-check" : "form-uncheck"}
                    />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="h-75vh overflow-scroll noscrollbar d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center mt-84px gap-40px">
            {!syncClicked["Kong"] && <GlobalIcons type={"empty-data"} />}
            <div className="d-flex flex-column align-items-center gap-16px">
              <p className="text-200 fw-500 fs-24px">
                {syncClicked["Kong"]
                  ? "Oops...No Gateways could be found"
                  : "Runtimes not found"}
              </p>
              <p className="text-400 fs-14px fw-400">
                {syncClicked["Kong"]
                  ? "Configure an Kong gateway connector on your workspace"
                  : "Make sure to sync Kong Runtime data to view details"}
              </p>
              {syncClicked["Kong"] ? (
                <div
                  className="btn v1-btn-primary p-2"
                  onClick={(evt) => {
                    window.open(`https://${guestsetting?.workspaceId}.apiwiz.io/settings/connectors`)
                  }}
                >
                  <UserIcons type={"redirect"} />
                  <p className="text-200 ps-2">Go to Workspace</p>
                </div>
              ) : (
                <div
                  className="btn v1-btn-primary p-2"
                  onClick={(evt) => {
                    setSyncClicked((prev) => ({
                      ...prev,
                      Kong: true,
                    }));
                    syncGateways();
                  }}
                >
                  <UserIcons type={"sync"} />
                  <p className="text-200 ps-2">Sync</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
