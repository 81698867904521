import { objectDeepClone } from '@apiwiz/oas/services';
import { TrashSimple, X } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import monetizationV2 from "../../../../api/monetizationV2";
import { useSelector } from 'react-redux';
import { throwServerError } from '../../../../utils/helper';
import { getToast } from '../../../../components/Toast';

export default function NewSubscriptionModal(props) {
    const [data, setData] = useState({
        name: '', description: ''
    })
    const { currentUser } = useSelector((state) => state.user)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (props.isEdit){
            console.log('props.data', props.data)
            setData({
                name: props.data?.subscriptionName || '',
                description: props.data?.subscriptionDescription || ''
            })
        }
    }, [])
    

    const handleSave = () => {
        let _data = objectDeepClone(data)
        let _errors = {}
        if (!_data.name){
            _errors['name'] = 'Subscription name is required'
        }
        if (!_data.description){
            _errors['description'] = 'Subscription description is required'
        }

        setErrors(objectDeepClone(_errors || {}))
        if (Object.keys(_errors || {}).length > 0) return

        if (props.isEdit){
            monetizationV2.updateSubscription(
                props.data.id, { developerEmail: currentUser.email }, {
                    developerEmail: currentUser.email,
                    subscriptionName: data.name,
                    subscriptionDescription: data.description
                }
            ).then(res => {
                console.log('res', res)
                getToast({
                    statusType: 'SUCCESS', message: 'Subscription created successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        } else {
            monetizationV2.addSubscription({
                developerEmail: currentUser.email,
                subscriptionName: data.name,
                subscriptionDescription: data.description
            }).then(res => {
                console.log('res', res)
                getToast({
                    statusType: 'SUCCESS', message: 'Subscription created successfully'
                })
                props.onHide()
                props.callback()
            }).catch(err => {
                throwServerError(err)
            })
        }
    }

    const handleChange = (key, value) => {
        let _data = objectDeepClone(data)
        let _errors = objectDeepClone(errors)
        if (value.length > 0){
            _errors[key] = ''
        } else {
            _errors[key] = `Subscription ${key} is required`
        }

        _data[key] = value
        setErrors(objectDeepClone(_errors))
        setData(objectDeepClone(_data))
    }

    const handleDelete = () => {
        monetizationV2.deleteSubscription(props.data.id, props.data.developerEmail)
        .then(res => {
            getToast({
                statusType: 'SUCCESS', message: 'Subscription deleted successfully'
            })
            props.onHide()
            props.callback()
        }).catch(err => {
            throwServerError(err)
        })
    }
 
    return (
        <Modal
            {...props}
            size="lg"
            contentClassName='homeModalForLghtMode br-8px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 surface-consumer-875 br-tl-8px br-tr-8px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-consumer-100 fw-500 fs-18px'>{props.isEdit ? 'Update': 'New'} Subscription</p>

                    <div className='d-flex align-items-center'>
                        <div className='text-consumer-300 fs-12px fw-500 me-1 cursor-pointer br-3px px-8px py-5px' 
                            onClick={props.onHide} style={{border: '0.5px solid var(--content-color)'}}>
                            <X />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(36vh)" }}
                className="p-0 overflow-scroll noscrollbar"
            >
                <div className='px-8px py-20px h-100 w-100'>
                    <div className='w-80 d-block mx-auto'>
                        <div className='mb-3'>
                            <p className='text-content-color fs-14px fw-400'>Subscription Name</p>
                            <input
                                required value={data.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                                placeholder="Enter Name of subscription"
                                className="from-control v1-form-control v1-form-control-public w-100"
                            />
                            {errors.name && <p className='mt-1 text-danger fs-12px mb-0'>
                                {errors.name}
                            </p>}
                        </div>
                        <div className='mb-3'>
                            <p className='text-content-color fs-14px fw-400'>Description</p>
                            <textarea
                                required value={data.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                rows={4} placeholder="Enter description of subscription"
                                className="from-control v1-form-textarea v1-form-control-public w-100"
                            />
                            {errors.description && <p className='text-danger fs-12px mb-0'>
                                {errors.description}
                            </p>}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-custom-top-600'>
                <div className='w-100 dfaic justify-content-between'>
                    {props.isEdit 
                        ? <div>
                            <div className='px-16px py-10px br-8px bg-danger cursor dfaic gap-8px'
                                onClick={handleDelete}>
                                <TrashSimple color='white' size={16} />
                                <p className='fs-14px fw-600 text-white'>
                                    Delete
                                </p>
                            </div>
                        </div>
                        : <div></div>
                    }

                    <div className='dfaic gap-16px'>
                        <div className='px-12px'>
                            <p className='cursor fs-16px fw-600 text-primary-color'
                                onClick={() => props.onHide()}>Cancel</p>
                        </div>
                        <div className='px-24px py-10px br-8px bg-primary-color cursor'
                            onClick={handleSave}>
                            <p className='fs-16px fw-600 text-white'>
                                {props.isEdit ? 'Update' : 'Save'}
                            </p>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
