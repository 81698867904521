import { CaretDown as CaretDownSvg } from "phosphor-react";


export default function CaretDown() {
  return (
    <div style={{position: 'relative'}} className="h-16px w-16px br-50 bg-theme-base-200">
      <CaretDownSvg size={14} style={{position: 'absolute', top: 1, left: 1}} />
    </div>
  );
}
