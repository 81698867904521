import { useCallback, useEffect, useRef, useState } from "react";
import { BodyLayout } from "../../../layout/Base";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { ArrowSquareOut, Check, X } from "phosphor-react";
import API from "../../../api";
import monetization from "../../../api/monetization";
import {
  apigeeStatusConversion,
  azureStatusConversion,
  confirm,
  debounceFn,
  getPermissionByCrud,
  getTimeOfTheData,
  showSuccessMessage,
  throwServerError,
} from "../../../utils/helper";
import moment from "moment";
import CreateApp from "./CreateApp";
import Loading from "../../../components/Loader/Loading";
import AppCredentials from "./AppCredentials";
import { objectDeepClone } from "@apiwiz/oas/services";
import EmptyContainer from "../../../components/EmptyContainer";
import { getToast } from "../../../components/Toast";
import { Tab, Tabs } from "react-bootstrap";
import { APP_STATUS, GATEWAYS } from "../../../service/constants";
import ContainerLoader from "../../../components/Loader/ContainerLoader";
import monetizationV2 from "../../../api/monetizationV2";
import Searchbar from "../../../components/Searchbar";
import Pagination from "../../../components/Pagination";

const TABS = {
  AZURE: "Azure",
  KONG: "Kong",
  APIGEE: "Apigee",
  NGINX: "NginX",
  AWS: GATEWAYS.AWS.toUpperCase(),
  AXWAY:"Axway"
  // MONETIZATION: "Monetization"
};

const StatusLabelize = {
  [APP_STATUS.APPROVED]: "approve",
  [APP_STATUS.REVOKED]: "revoke"
}

export default function Apps() {
  const { setNavigation, setTitle, setHasHeader } = useOutletContext();
  const { firstRoute, permission } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const [runtimes, setRuntimes] = useState([]);
  const [nginXRuntimes, setNginxRuntimes] = useState([]);
  const [awsRuntimes, setAwsRuntimes] = useState([]);
  const [axwayRuntimes, setAxwayRuntimes] = useState([]);
  const [resGroups, setResGroups] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [type, setType] = useState({});
  const [appEnv, setAppEnv] = useState(null);
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [appModal, setAppModal] = useState({
    isOpen: false,
    data: {},
    isEdit: false,
  });
  const [credsModal, setCredsModal] = useState({
    isOpen: false,
    data: [],
  });
  const activeTabRef = useRef(TABS.APIGEE)
  const [activeTab, setActivetab] = useState(GATEWAYS.APIGEE);
  const [appStatusLoading, setAppStatusLoading] = useState({});
  const [searchVal, setSearchVal] = useState('')
  const [pagination, setPagination] = useState({prev: 0, next: 1, limit: 10, total: 10, page: 1})


  const getOrgs = () => {
    setIsLoading(true);
    API.getOrgs()
      .then((response) => {
        if (response.data.data) {
          setOrgData(response.data.data);
          let defaultOrg = "";
          response.data.data.forEach((el) => {
            if (el.organisationDefault === "1") {
              defaultOrg = el.organisationName;
            }
          });

          if (currentUser.organization?.length > 0) {
            defaultOrg = currentUser.organization[0];
            setType({
              value: defaultOrg,
              label: defaultOrg,
            });
          } else {
            setType({
              value: defaultOrg,
              label: defaultOrg + " (default)",
            });
          }
          var matchedOrg = response.data.data.find(
            (e) => e.organisationName === defaultOrg
          );
          setAppEnv(matchedOrg.environments[0]);
          // getAppData(defaultOrg);
        }
        // setIsLoading(false)
      })
      .catch((err) => {
        //throwServerError(err);
        //addToast(err.message, { appearance: 'error' })
        setIsLoading(false);
      });
  };

  const getApigeeAppData = () => {
    setIsLoading(true);
    monetization
      .getAllApigeeApps()
      .then((response) => {
        if(activeTabRef.current === TABS.APIGEE) 
        {
          setData(response?.data?.data || []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if(activeTabRef.current === TABS.APIGEE) setIsLoading(false);
      });
  };

  const getKongAppData = () => {
    setIsLoading(true);
    monetization
      .getKongApps()
      .then((res) => {
        if(activeTabRef.current === TABS.KONG)
        {
          setData(res?.data?.data || []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if(activeTabRef.current === TABS.KONG) setIsLoading(false);
      });
  };

  const getAzureData = () => {
    setIsLoading(true);
    monetization
      .getAzureApps()
      .then((res) => {
        if (activeTabRef.current === TABS.AZURE) {
          setData(res?.data?.data || []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (activeTabRef.current === TABS.AZURE) setIsLoading(false);
      });
  };

  const getNginxData = () => {
    setIsLoading(true);
    API.getNginxApps()
      .then((res) => {
        if(activeTabRef.current === TABS.NGINX)
        {
          setData(res?.data?.data || []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if(activeTabRef.current === TABS.NGINX) setIsLoading(false);
      });
  };

  const getAwsData = () => {
    setIsLoading(true);
    API.getAwsApps()
      .then((res) => {
        if(activeTabRef.current === TABS.AWS)
        {
          setData(res?.data?.data || []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if(activeTabRef.current === TABS.AWS) setIsLoading(false);
      });
  };

  const getMonetizationAppData = () => {
    setIsLoading(true);
    monetizationV2.getMonetizationApps({offset:0, expand: true})
      .then(({data:res}) => {
        if(activeTabRef.current === TABS.MONETIZATION)
        {
          setData(res?.data || []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if(activeTabRef.current === TABS.MONETIZATION) setIsLoading(false);
      });
  };

  const upateKongStatus = (appId, fields, index) => {
    API.getAppMapperById(fields.name)
    .then(({data: res}) => {
      const _tempData = objectDeepClone(res.data || {})

      setAppStatusLoading((prev) => ({
        ...prev,
        [TABS.KONG]: { ...(prev[TABS.KONG] || {}), [appId]: true },
      }));
      monetization
      .updateKongStatus(appId, fields)
      .then((res) => {
        let _data = objectDeepClone(data);
        _data[index].status = fields.status;
        setData(_data);
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.KONG]: { ...(prev[TABS.KONG] || {}), [appId]: false },
        }));
        setIsLoading(false);
        showSuccessMessage("Status updated successfully!");
  
        updateMonetizationAppStatus(_tempData.monetizationAppId, 'APPROVED', 0, _tempData.productId)
      })
      .catch((err) => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.KONG]: { ...(prev[TABS.KONG] || {}), [appId]: false },
        }));
        setIsLoading(false);
        throwServerError(err);
      });

    }).catch(err => {

      setAppStatusLoading((prev) => ({
        ...prev,
        [TABS.KONG]: { ...(prev[TABS.KONG] || {}), [appId]: true },
      }));
      monetization
      .updateKongStatus(appId, fields)
      .then((res) => {
        let _data = objectDeepClone(data);
        _data[index].status = fields.status;
        setData(_data);
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.KONG]: { ...(prev[TABS.KONG] || {}), [appId]: false },
        }));
        setIsLoading(false);
        showSuccessMessage("Status updated successfully!");
  
      })
      .catch((err) => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.KONG]: { ...(prev[TABS.KONG] || {}), [appId]: false },
        }));
        setIsLoading(false);
        throwServerError(err);
      });

    })
  };

  const updateAzureStatus = ({ appName, status, connectorId, index }) => {
    API.getAppMapperById(appName)
    .then(({data: res}) => {
      console.log('res', res)
      const _tempData = objectDeepClone(res.data || {})

      setAppStatusLoading((prev) => ({
        ...prev,
        [TABS.AZURE]: { ...(prev[TABS.AZURE] || {}), [appName]: true },
      }));

      monetization
      .updateAzureAppStatus({ appName, status, connectorId })
      .then((res) => {
        let _data = objectDeepClone(data);
        _data[index].status = status;
        setData(_data);
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AZURE]: { ...(prev[TABS.AZURE] || {}), [appName]: false },
        }));
        setIsLoading(false);
        showSuccessMessage("Status updated successfully!");

        updateMonetizationAppStatus(_tempData.monetizationAppId, 'APPROVED', 0, _tempData.productId)
      })
      .catch((err) => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AZURE]: { ...(prev[TABS.AZURE] || {}), [appName]: false },
        }));
        setIsLoading(false);
        throwServerError(err);
      });

    }).catch(err => {
      setAppStatusLoading((prev) => ({
        ...prev,
        [TABS.AZURE]: { ...(prev[TABS.AZURE] || {}), [appName]: true },
      }));

      monetization
      .updateAzureAppStatus({ appName, status, connectorId })
      .then((res) => {
        let _data = objectDeepClone(data);
        _data[index].status = status;
        setData(_data);
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AZURE]: { ...(prev[TABS.AZURE] || {}), [appName]: false },
        }));
        setIsLoading(false);
        showSuccessMessage("Status updated successfully!");
      })
      .catch((err) => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AZURE]: { ...(prev[TABS.AZURE] || {}), [appName]: false },
        }));
        setIsLoading(false);
        throwServerError(err);
      });
    })
  };

  const upateNginxStatus = (appId, status, index, appName) => {
      API.getAppMapperById(appName)
      .then(({data: res}) => {
        const _tempData = objectDeepClone(res.data || {})

        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.NGINX]: { ...(prev[TABS.NGINX] || {}), [appId]: true },
        }));
        API.updateNginxStatus({ id: appId, status: status })
        .then((res) => {
          setData((prev) => {
            prev[index].status = status;
            return prev;
          });
          setAppStatusLoading((prev) => ({
            ...prev,
            [TABS.NGINX]: { ...(prev[TABS.NGINX] || {}), [appId]: false },
          }));
          setIsLoading(false);
          showSuccessMessage("Status updated successfully!");

          updateMonetizationAppStatus(_tempData.monetizationAppId, 'APPROVED', 0, _tempData.productId)
        })
        .catch((err) => {
          setAppStatusLoading((prev) => ({
            ...prev,
            [TABS.NGINX]: { ...(prev[TABS.NGINX] || {}), [appId]: false },
          }));
          setIsLoading(false);
          throwServerError(err);
        });

      }).catch(err => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.NGINX]: { ...(prev[TABS.NGINX] || {}), [appId]: true },
        }));
        API.updateNginxStatus({ id: appId, status: status })
        .then((res) => {
          setData((prev) => {
            prev[index].status = status;
            return prev;
          });
          setAppStatusLoading((prev) => ({
            ...prev,
            [TABS.NGINX]: { ...(prev[TABS.NGINX] || {}), [appId]: false },
          }));
          setIsLoading(false);
          showSuccessMessage("Status updated successfully!");
        })
        .catch((err) => {
          setAppStatusLoading((prev) => ({
            ...prev,
            [TABS.NGINX]: { ...(prev[TABS.NGINX] || {}), [appId]: false },
          }));
          setIsLoading(false);
          throwServerError(err);
        });
      })
  };

  const updateAwsStatus = (appId, status, index, appName) => {
    API.getAppMapperById(appName)
    .then(({data: res}) => {
      const _tempData = objectDeepClone(res.data || {})

      setAppStatusLoading((prev) => ({
        ...prev,
        [TABS.AWS]: { ...(prev[TABS.AWS] || {}), [appId]: true },
      }));
      API.updateAwsStatus({ id: appId, status: status })
      .then((res) => {
        setData((prev) => {
          prev[index].status = status;
          return prev;
        });
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AWS]: { ...(prev[TABS.AWS] || {}), [appId]: false },
        }));
        setIsLoading(false);
        showSuccessMessage("Status updated successfully!");

        updateMonetizationAppStatus(_tempData.monetizationAppId, 'APPROVED', 0, _tempData.productId)
      })
      .catch((err) => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AWS]: { ...(prev[TABS.AWS] || {}), [appId]: false },
        }));
        setIsLoading(false);
        throwServerError(err);
      });
    }).catch(err => {
      setAppStatusLoading((prev) => ({
        ...prev,
        [TABS.AWS]: { ...(prev[TABS.AWS] || {}), [appId]: true },
      }));
      API.updateAwsStatus({ id: appId, status: status })
      .then((res) => {
        setData((prev) => {
          prev[index].status = status;
          return prev;
        });
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AWS]: { ...(prev[TABS.AWS] || {}), [appId]: false },
        }));
        setIsLoading(false);
        showSuccessMessage("Status updated successfully!");
      })
      .catch((err) => {
        setAppStatusLoading((prev) => ({
          ...prev,
          [TABS.AWS]: { ...(prev[TABS.AWS] || {}), [appId]: false },
        }));
        setIsLoading(false);
        throwServerError(err);
      });
    })
  };

  const updateMonetizationAppStatus = (appId, status, index, _data) => {
    monetizationV2.updateAppStatus(appId, status, currentUser.email, _data)
    .then((res) => {
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
    });
  };

  const getProducts = () => {
    API.getProducts()
      .then((response) => {
        if (response.data.data && response.data.data) {
          setProductData(
            response.data.data.map((o) => {
              return {
                value: o,
                label: o,
              };
            })
          );
        }
      })
      .catch((err) => {
        // throwServerError(err);
      });
  };

  const getRuntimes = () => {
    API.getRuntimes()
      .then((res) => {
        setRuntimes(res?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResourceGroups = () => {
    API.getResourceGroups()
      .then((res) => {
        setResGroups(res?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNginxRuntimes = () => {
    API.getNginxRuntimes()
      .then((res) => {
        setNginxRuntimes(res?.data?.data || []);
      })
      .catch((err) => {
        console.log("nginx runtime err:", err);
      });
  };

  const getAwsRuntimes = () => {
    API.getAwsRuntimes()
      .then((res) => {
        setAwsRuntimes(res?.data?.data || []);
      })
      .catch((err) => {
        console.log("AWS runtime err:", err);
      });
  };

  const getAxwayRuntimes = () => {
    API.getAxwayRuntimes()
      .then((res) => {
        setAxwayRuntimes(res?.data?.data || []);
      })
      .catch((err) => {
        console.log("Axway runtime err:", err);
      });
  };

  const handleOrgType = (selected) => {
    setType({
      value: selected.value,
      label: selected.label,
    });
    var matchedOrg = orgData.find((e) => e.organisationName === selected.value);
    setAppEnv(matchedOrg.environments[0]);
    // getAppData(selected.value);
  };

  const handlePermissionChange = (_data, _status, index) => {
    if (!confirm(`Are you sure to ${_status} the permission?`)) return;
    API.saveMonetizationPermission(
      type.value,
      _data.name,
      _status,
      objectDeepClone(_data),
      _data.orgType
    )
      .then((res) => {
        let _d = objectDeepClone(data);
        _d[index].status = apigeeStatusConversion(_status);
        setData(_d);
        getToast({
          statusType: "SUCCESS",
          message: "Permission changed successfully",
        });
      })
      .catch((err) => {
        throwServerError(err);
      });
  };

  const handleOnChange = (gatewayType, page, appName) => {
    getDeveloperApps({ gatewayType, page, appName })
  };

  const handleOnChangeDebounceFn = useCallback(debounceFn(handleOnChange, 1000), []);

  const getDeveloperApps = (params) => {
    setIsLoading(true)
    API.getDeveloperApps({...params})
    .then(({data: res}) => {
      setData(res.data)
      setPagination(res.paging)
      setIsLoading(false)
    })
    .catch((err) => {
      setIsLoading(false)
      throwServerError(err)
    })
  }

  const handleAppStatusChange = (data, status, gatewayStatus) => {
    if (!confirm(`Are you sure to ${StatusLabelize[status]} the permission?`)) return;

    let body = {}
    if(data.gateway === GATEWAYS.APIGEE) {
      body = {
          gatewayStatus,
          appName: data.name, 
          organization: type.value || '', 
          orgType: data?.meta?.[data.gateway].orgType || '', 
          body: objectDeepClone({...data?.meta?.[data.gateway], description: data?.description || ""})
      }
    }
    else if(data.gateway === GATEWAYS.KONG) {
      body = {...data?.meta[data.gateway], status: gatewayStatus} 
    }
    else if(data.gateway === GATEWAYS.AZURE) {
      body = {
        meta : {
          appName: data.name,
          status: gatewayStatus,
          connectorId: data?.meta?.[data.gateway]?.connectorId
        }
      } 
    }
    else if(data.gateway === GATEWAYS.AXWAY){
      body = {...data?.meta[data.gateway], status: gatewayStatus} 
    }

    setIsLoading(true)
    API.updateDeveloperApp(data._id, { status }, body)
    .then((res) => {
      showSuccessMessage("App status updated successfully")
      getDeveloperApps({ gatewayType: activeTab.toLowerCase(), page: 1})
    })
    .catch((err) => {
      setIsLoading(false)
      throwServerError(err)
    })
  }

  const handleTabOnChange = (key) => {
    setActivetab(key);
    setSearchVal('')
    activeTabRef.current = key;
    getDeveloperApps({ page: pagination.page, gatewayType: key })
  }

  useEffect(() => {
    if (!currentUser._id) return;
    getOrgs();
    getProducts();
    getResourceGroups();
    getRuntimes();
    getNginxRuntimes();
    getAwsRuntimes();
    getAxwayRuntimes();
    // getApigeeAppData();
    getDeveloperApps({ page: 1, gatewayType: activeTab })
  }, [currentUser]);

  useEffect(() => {
    setTitle("App Approval");
    setHasHeader(true);
    setNavigation([
      { link: firstRoute, name: "Dashboard" },
      { name: "Manage Apps" },
    ]);
  }, []);

  return (
    <BodyLayout>
      <div className="fadeIn h-100 w-100">
        <div className="surface-875 w-100 h-100 d-flex flex-column">
          <div className="dfaic justify-content-between p-1">
            <h3
              className="fw-600 fs-18px text-100 p-8px" style={{ borderBottom: "1px solid var(--dark-surface-850)" }}>
              App Approval
            </h3>

            <div style={{width: 280}}>
              <Searchbar placeholder={"Search by App Name"} value={searchVal} onChange={(searchTxt) => {
                setSearchVal(searchTxt)
                handleOnChangeDebounceFn(activeTab.toLowerCase(), 1, searchTxt)
              }} />
            </div>
          </div>

          <div className="tabs-container w-100 custom-tab-container position-relative">
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabOnChange}
              id="gateway-select-tabs-app-approval"
            >
              {/* APigee  */}
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.APIGEE}
                  eventKey={GATEWAYS.APIGEE}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 h-100 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 text-center">
                                {" "}
                                Credential Count{" "}
                              </p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-2"></div>
                          </div>
                          <div className="overflow-scroll noscrollbar"
                          style={{height: 'calc(100vh - 57px - 46px - 31px - 12px - 16px - 75px - 30px)'}}>
                            {data && data.length > 0 ? (
                              data.map((each, i) => {
                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.name}
                                    </p>
                                    {/* </Link> */}
                                  </div>
                                  <div className="col-md-2 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${each.status.toLowerCase()} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {each.status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <div
                                      className="surface-850 br-4px v1-border-1 py-9px cursor pl-32px pr-20px d-flex align-items-center gap-4px w-fit"
                                      onClick={() =>
                                        setCredsModal({
                                          isOpen: true,
                                          data: {
                                            mainData: objectDeepClone(
                                              each?.meta?.[activeTab]?.credentials || []
                                            ),
                                            appName: each?.meta?.[activeTab].name,
                                            orgMetaData: {
                                              org: each?.meta?.[activeTab].org,
                                              orgType: each?.meta?.[activeTab].orgType,
                                            },
                                          },
                                        })
                                      }
                                    >
                                      <p className="fs-12px fw-500 text-300 me-4">
                                        {each?.meta?.[activeTab]?.credentials?.length || 0}
                                      </p>

                                      <ArrowSquareOut
                                        color="#e4e4e4"
                                        size={16}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each?.meta?.[activeTab]?.createdByUser || each?.meta?.[activeTab]?.createdBy || ""}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each.createdAt).format("DD/MM/YYYY")}
                                    </p>
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center justify-content-end pe-3">
                                    {each.status === "approved" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => handleAppStatusChange(objectDeepClone(each), APP_STATUS.REVOKED, "revoke")}
                                      >
                                        <X
                                          size={14}
                                          color="#E4E4E4"
                                          className="mr-12px"
                                        />
                                        <p className="fs-12px fw-500 text-300">
                                          Revoke
                                        </p>
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => handleAppStatusChange(objectDeepClone(each), APP_STATUS.APPROVED, "approve")}
                                      >
                                        <Check
                                          size={14}
                                          color="#E4E4E4"
                                          className="mr-12px"
                                        />
                                        <p className="fs-12px fw-500 text-300">
                                          Approve
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer message="No Data Found" />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {data && data.length > 0 && pagination && Object.keys(pagination).length && (
                      <Pagination
                          className="py-2"
                          pagination={{
                              pageSize: pagination?.limit || 10, 
                              offset: pagination.prev ? pagination.prev + 1 : 1,
                              total: pagination.total,
                          }}
                          cb={(val) => getDeveloperApps({ page: val, gatewayType: activeTab.toLowerCase() })}
                      />
                  )}
                </Tab>
              {/* Kong  */}
              {runtimes.length ? (
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.KONG}
                  eventKey={GATEWAYS.KONG}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            {/* <div className="col-md-3">
                            <p className="fw-500 fs-10px text-400 text-center">
                              {" "}
                              Credential Count{" "}
                            </p>
                          </div> */}
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-3"></div>
                          </div>

                          <div className="overflow-scroll noscrollbar"
                          style={{height: 'calc(100vh - 57px - 46px - 31px - 12px - 16px - 75px - 30px)'}}>
                            {data.length > 0 ? (
                              data.map((each, i) => {
                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.name}
                                    </p>
                                    {/* </Link> */}
                                  </div>
                                  <div className="col-md-3 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${each.status?.toLowerCase()} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {each.status}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-3 d-flex align-items-center justify-content-center">
                                <div
                                  className="surface-850 br-4px v1-border-1 py-9px cursor pl-32px pr-20px d-flex align-items-center gap-4px w-fit"
                                  onClick={() =>
                                    setCredsModal({
                                      isOpen: true,
                                      data: {
                                        mainData: objectDeepClone(
                                          each?.credentials || []
                                        ),
                                        appName: each.name,
                                      },
                                    })
                                  }
                                >
                                  <p className="fs-12px fw-500 text-300 me-4">
                                    {each?.credentials?.length || 0}
                                  </p>

                                  <ArrowSquareOut color="#e4e4e4" size={16} />
                                </div>
                              </div> */}
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each?.developerEmail || each?.meta?.[activeTab]?.userEmail || '-'}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each?.meta?.[activeTab]?.cts).format(
                                        "hh:mm A - DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-md-3 d-flex align-items-center justify-content-end pe-3">
                                    {each.status?.toLowerCase() ===
                                      "approved" ||
                                    each.status?.toLowerCase() === "active" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.REVOKED, "Revoke")
                                        }}
                                      >
                                        {(appStatusLoading[TABS.KONG] || {})[each.id] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <X
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Revoke
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.APPROVED, "Approved")
                                        }}
                                      >
                                        {(appStatusLoading[TABS.KONG] || {})[each.id] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <Check
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Approve
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {data && data.length > 0 && pagination && Object.keys(pagination).length && (
                      <Pagination
                          className="py-2"
                          pagination={{
                              pageSize: pagination?.limit || 10, 
                              offset: pagination.prev ? pagination.prev + 1 : 1,
                              total: pagination.total,
                          }}
                          cb={(val) => getDeveloperApps({ page: val, gatewayType: activeTab.toLowerCase() })}
                      />
                  )}
                </Tab>
              ) : null}
              {/* Azure  */}
              {resGroups.length ? (
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.AZURE}
                  eventKey={GATEWAYS.AZURE}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-3"></div>
                          </div>

                          <div className="h-72vh overflow-scroll noscrollbar">
                            {data.length > 0 ? (
                              data.map((each, i) => {
                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.name ? each.name : each?.id}
                                    </p>
                                    {/* </Link> */}
                                  </div>
                                  <div className="col-md-3 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${azureStatusConversion(
                                        each.status
                                      )} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {azureStatusConversion(each.status)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each?.[each.gateway]?.createdBy}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each.cts).format(
                                        "hh:mm A - DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-md-3 d-flex align-items-center justify-content-end pe-3">
                                    {each.status?.toLowerCase() === "approved" ||
                                    each.status?.toLowerCase() === "active" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.REVOKED, "suspended")
                                        }}
                                      >
                                        {(appStatusLoading[TABS.AZURE] || {})[
                                          each.id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <X
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Revoke
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.APPROVED, "active")
                                        }}
                                      >
                                        {(appStatusLoading[TABS.AZURE] || {})[
                                          each.id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <Check
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Approve
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Tab>
              ) : null}
              {/* NginX  */}
              {nginXRuntimes.length ? (
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.NGINX}
                  eventKey={GATEWAYS.NGINX}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400">App Id</p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400">Product</p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-2"></div>
                          </div>

                          <div className="h-72vh overflow-scroll noscrollbar">
                            {data.length > 0 ? (
                              data.map((each, i) => {

                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.name}
                                    </p>
                                  </div>
                                  <div className="col-md-2 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${each.status?.toLowerCase()} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {each.status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each._id}
                                    </p>
                                  </div>
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.meta?.[each.gateway]?.productName}
                                    </p>
                                  </div>
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each.developerEmail}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each.createdAt).format(
                                        "hh:mm A - DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center justify-content-end pe-3">
                                    {each.status?.toLowerCase() ===
                                      "approved" ||
                                    each.status?.toLowerCase() === "active" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.REVOKED)
                                        }}
                                      >
                                        {(appStatusLoading[TABS.NGINX] || {})[
                                          each._id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <X
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Revoke
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.APPROVED)
                                        }}
                                      >
                                        {(appStatusLoading[TABS.NGINX] || {})[
                                          each._id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <Check
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Approve
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Tab>
              ) : null}
              {/* AWS  */}
              {awsRuntimes.length ? (
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.AWS}
                  eventKey={GATEWAYS.AWS}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400">Product</p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-2"></div>
                          </div>

                          <div className="h-72vh overflow-scroll noscrollbar">
                            {data.length > 0 ? (
                              data.map((each, i) => {

                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.name}
                                    </p>
                                  </div>
                                  <div className="col-md-2 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${each.status?.toLowerCase()} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {each.status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.meta?.[each.gateway]?.productName}
                                    </p>
                                  </div>
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each.developerEmail}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each.createdAt).format(
                                        "hh:mm A - DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center justify-content-end pe-3">
                                    {each.status?.toLowerCase() ===
                                      "approved" ||
                                    each.status?.toLowerCase() === "active" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.REVOKED)
                                        }}
                                      >
                                        {(appStatusLoading[TABS.AWS] || {})[
                                          each._id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <X
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Revoke
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.APPROVED)
                                        }}
                                      >
                                        {(appStatusLoading[TABS.AWS] || {})[
                                          each._id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <Check
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Approve
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Tab>
              ) : null}
              {/* Axway  */}
              {axwayRuntimes.length ? (
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.AXWAY}
                  eventKey={GATEWAYS.AXWAY}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 text-center">Product</p>
                            </div>
                            <div className="col-md-2">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-2"></div>
                          </div>

                          <div className="h-72vh overflow-scroll noscrollbar">
                            {data.length > 0 ? (
                              data.map((each, i) => {

                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.name}
                                    </p>
                                  </div>
                                  <div className="col-md-2 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${each.status?.toLowerCase()} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {each.status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-14px text-300 text-center">
                                      {each.meta?.[each.gateway]?.product?.name || '-'}
                                    </p>
                                  </div>
                                  <div className="col-md-2 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each.developerEmail}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each.createdAt).format(
                                        "hh:mm A - DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center justify-content-end pe-3">
                                    {each.status?.toLowerCase() ===
                                      "approved" ||
                                    each.status?.toLowerCase() === "active" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.REVOKED)
                                        }}
                                      >
                                        {(appStatusLoading[TABS.AWS] || {})[
                                          each._id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <X
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Revoke
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          handleAppStatusChange(each, APP_STATUS.APPROVED)
                                        }}
                                      >
                                        {(appStatusLoading[TABS.AWS] || {})[
                                          each._id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <Check
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Approve
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Tab>
              ) : null}

              {true ? (
                <Tab
                  tabClassName="tabs-btn-2"
                  className="p-12px py-2"
                  title={TABS.MONETIZATION}
                  eventKey={TABS.MONETIZATION}
                >
                  {isLoading ? (
                    <div className="h-75vh overflow-scroll noscrollbar">
                      <Loading />
                    </div>
                  ) : (
                    <div
                      className="surface-900 w-100 p-8px"
                      // style={{ height: "calc(100% - 60px)" }}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <div className="row mx-0 surface-875 p-6px mb-1">
                            <div className="col-md-4">
                              <p className="fw-500 fs-10px text-400 pl-6px">
                                Name
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 pl-40px">
                                Status
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-500 fs-10px text-400 text-center">
                                Created By
                              </p>
                            </div>
                            <div className="col-md-2"></div>
                          </div>

                          <div className="h-72vh overflow-scroll noscrollbar">
                            {data.length > 0 ? (
                              data.map((each, i) => {
                                let regExp = new RegExp(searchVal, "i");
                                if (each.name?.search(regExp) === -1) return

                                return (
                                <div className="row mx-0 v1-border-bottom-1 p-12px">
                                  <div className="col-md-4 my-auto">
                                    <p className="fw-500 fs-14px text-300">
                                      {each.appName}
                                    </p>
                                  </div>
                                  <div className="col-md-3 mx-auto my-auto">
                                    <div
                                      className={`global-status-tag ${each.status?.toLowerCase()} w-fit text-capitalize`}
                                    >
                                      <p
                                        className={`fw-500 fs-12px text-300 status-text px-3`}
                                      >
                                        {each.status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3 my-auto">
                                    <p className="fw-500 fs-12px text-300 text-center">
                                      {each.developerName}
                                    </p>
                                    <p className="fw-500 fs-11px text-400 text-center">
                                      {moment(each.cts).format(
                                        "hh:mm A - DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center justify-content-end pe-3">
                                    {each.status === "APPROVED" ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                      >
                                        <p className="fs-12px fw-500 text-300">
                                              Approved
                                            </p>
                                      </div>
                                    ) : 
                                    true ? (
                                      <div
                                        className="d-flex align-items-center px-20px py-11px cursor v1-border-1 br-4px surface-850 w-fit"
                                        onClick={() => {
                                          if (
                                            (appStatusLoading[TABS.MONETIZATION] || {})[
                                              each.id
                                            ]
                                          )
                                            return;
                                            updateMonetizationAppStatus(
                                            each.id,
                                            "APPROVED",
                                            i,
                                            each
                                          );
                                        }}
                                      >
                                        {(appStatusLoading[TABS.MONETIZATION] || {})[
                                          each.id
                                        ] ? (
                                          <ContainerLoader size="sm" />
                                        ) : (
                                          <>
                                            <Check
                                              size={14}
                                              color="#E4E4E4"
                                              className="mr-12px"
                                            />
                                            <p className="fs-12px fw-500 text-300">
                                              Approve
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                )})
                            ) : (
                              <div className="mt-5">
                                <EmptyContainer />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Tab>
              ) : null}
            </Tabs>
          </div>
        </div>
      </div>

      {appModal.isOpen && (
        <CreateApp
          show={appModal.isOpen}
          isEdit={appModal.isEdit}
          data={appModal.data}
          onHide={() => setAppModal({ isOpen: false, isEdit: false, data: {} })}
          callback={getProducts}
          productData={productData}
          type={type}
        />
      )}

      {credsModal.isOpen && (
        <AppCredentials
          show={credsModal.isOpen}
          data={credsModal.data}
          onHide={() => setCredsModal({ data: [], isOpen: false })}
          callback={() => getDeveloperApps({ gatewayType: activeTab.toLowerCase(), page: pagination.page})}
        />
      )}
    </BodyLayout>
  );
}
