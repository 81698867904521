import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '../../context/ThemeContext'
import { Eye, EyeSlash } from 'phosphor-react'
import { useDispatch } from 'react-redux'
import API from '../../api'
import { encryptPassword, objectDeepClone, throwServerError, validateAllowedEmailDomains, validateEmail } from '../../utils/helper'
import { resetAuthenticatedState, setToken } from '../../app/Features/Users/UserSlice'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getToast } from '../../components/Toast'
import moment from 'moment'

export default function Loginv2() {
    const {currentLayoutObject} = useSelector(state => state.layout)
    const {guestsetting} = useSelector(state => state.setting)
    const {theme} = useTheme()

    const [showPass, setshowPass] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginData, setLoginData] = useState({
		disableSignup: false,
		disableLogin: false,
		loginMethod: [],
        allowedDomains: []
	});
    const [showMFA, setShowMFA] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    // MFA
    const [otp, setOtp] = useState({
        number1: "",
        number2: "",
        number3: "",
        number4: "",
        number5: "",
        number6: "",
    });
    
    const [otpTimer, setOtpTimer] = useState("01 : 59 secs");
    const [showTimer, setShowTimer] = useState(true);

    let timer = null;

    const startTimer = () => {
        clearTimeout(timer);
        const countDownTime = moment().add(2, "minutes").valueOf();
        setShowTimer(true);

        timer = setInterval(() => {
            const left = countDownTime - moment().valueOf();
            const minutes = Math.floor((left % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((left % (1000 * 60)) / 1000);
            if (left < 0) {
                clearTimeout(timer);
                setShowTimer(false);
            }
            if (left >= 0) {
                setOtpTimer(
                    `0${minutes} : ${seconds < 10 ? `0${seconds}` : seconds} secs`
                );
            }
        }, 1000);
    };

    const resendLoginOTP = () => {
        const params = {
            email: email,
        };
        API.resendOTP(params)
        .then((res) => {
                const message = res.data.message;
                getToast({
                    statusType: 'SUCCESS', message
                });

                setShowTimer(true);
                setOtpTimer("01 : 59 secs");
                startTimer();
                setOtp({
                    number1: "",
                    number2: "",
                    number3: "",
                    number4: "",
                    number5: "",
                    number6: "",
                });
        })
        .catch((err) => {
            getToast({
                statusType: 'ERROR', message: 'Error in sending OTP'
            });
        });
    };

    const verifyMFALogin = ({ otp }) => {
        let params = {}
        params.email = email
        params.password = encryptPassword(password);
        params.otp = encryptPassword(otp);

        setIsDisabled(true)
        API.loginMFA(params)
          .then((res) => {
            getToast({
                statusType: 'SUCCESS', message: 'Login Successful'
            })
            dispatch(setToken({data: res.data.data}));
            window.location.href = '/'
            setIsDisabled(false);
          })
          .catch((error) => {
            setIsDisabled(false);
            throwServerError(error)
    
            API.updateLoginAttempts({
                email: params.email
            }).then(res => {
                if(res.data.message !== "Ok"){
                    getToast({
                        statusType: 'ERROR', message: res.data.message
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        });
    };

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 1;
            if (next > -1) {
                elmnt.target.parentNode.children[next].focus();
            }
        } else {
            if (elmnt.key !== "Tab") {
                const next = elmnt.target.tabIndex;
                if (next < 6) {
                    elmnt.target.parentNode.children[next].focus();
                }
            }
        }
    };

    useEffect(() => {
        if (showMFA) startTimer();
    }, [showMFA]);

    useEffect(() => {
        if (guestsetting.disableLogin){
            navigate(`/`)
        }
        else {
            setLoginData(objectDeepClone(guestsetting))
        }
	}, [guestsetting]);

    const handleSubmit = (e) => {
        e.preventDefault()
        let temp = {}
        if (!email.trim().length){
            temp['email'] = 'Email is Required'
        }
        else if (!validateEmail(email)){
            temp['email'] = 'Email format is Invalid'
        }

        if (!password.trim().length){
            temp['password'] = 'Password is Required'
        }

        setErrors(temp)
        if (Object.keys(temp).length) return

        let _error = validateAllowedEmailDomains(email, loginData.allowedDomains);
        if (_error){
            getToast({
                statusType: 'ERROR', message: _error
            })
            return
        }

        setIsDisabled(true)
        let submitObj = {
            email, password
        }
        submitObj.password = encryptPassword(submitObj.password)

        API.userLogin(submitObj)
        .then(res => {
            if(res.data.data?.mfaEnabled){
                setShowMFA(true)
                setIsDisabled(false)
                return
            }

            dispatch(setToken({data: res.data.data}));
            if(searchParams.get("redirect")){
                navigate(searchParams.get("redirect"), {
                    replace: true
                })
            }else{
                window.location.href = '/'
            }
            setIsDisabled(false)

            if (localStorage.getItem("ssoLogin")){
                localStorage.removeItem("ssoLogin")
            }

        }).catch(err => {
            throwServerError(err)
            
            dispatch(resetAuthenticatedState())
            setIsDisabled(false)

            API.updateLoginAttempts({
                email: email
            }).then(res => {
                if(res.data.message !== "Ok"){
                    getToast({
                        statusType: 'ERROR', message: res.data.message
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        })

    }

    const handleOTPSubmit = (e) => {
        e.preventDefault()

        const otpValue = Object.entries(otp).reduce(
            (acc, [key, value]) => acc + value,
            ""
        );

        verifyMFALogin({ otp: otpValue });
    }

    return (
        <div className='bg-currentBackground overflow-scroll noscrollbar loginPageSection'>
            {showMFA
            ? <form className='loginContainer h-100'
                onSubmit={(e) => handleOTPSubmit(e)}>
                <div className='mb-24px'>
                    <img src={theme === 'theme--light' ? currentLayoutObject.logo : currentLayoutObject.darklogo}
                        alt='Logo' height={50} width={'100%'}  style={{objectFit: 'contain'}}
                        className='d-block mx-auto' />
                </div>

                <div className='mb-32px'>
                    <h3 className='titleText'>Enter OTP to Login</h3>
                    <p className='descText'>
                        A verification code is sent to your email {email}
                    </p>
                </div>

                <div className='mb-3'>
                    <div className="d-flex" style={{ gap: "0.8rem" }}
                        onSubmit={(e) => {console.log(e)}}>
                        <input
                            required
                            className={`form-control`}
                            value={otp.number1}
                            onKeyUp={(evt) => {
                                const key = evt.key;
                                inputfocus(evt);
                                if (
                                    otp.number1 &&
                                    key !== "Backspace" &&
                                    Number(otp.number1) >= 0 &&
                                    Number(otp.number1) < 10
                                ) {
                                    evt.preventDefault();
                                }
                            }}
                            onChange={(evt) => {
                                const value = evt.target.value;
                                setOtp((prev) => ({
                                    ...prev,
                                    number1: value,
                                }));
                            }}
                            maxLength={1}
                            tabIndex={1}
                        />
                        <input
                            required
                            className={`form-control`}
                            value={otp.number2}
                            onKeyUp={(evt) => {
                                const key = evt.key;
                                inputfocus(evt);

                                if (
                                    otp.number2 &&
                                    key !== "Backspace" &&
                                    Number(otp.number2) >= 0 &&
                                    Number(otp.number2) < 10
                                ) {
                                    evt.preventDefault();
                                }
                            }}
                            onChange={(evt) => {
                                const value = evt.target.value;
                                setOtp((prev) => ({
                                    ...prev,
                                    number2: value,
                                }));
                            }}
                            maxLength={1}
                            tabIndex={2}
                        />
                        <input
                            required
                            className={`form-control`}
                            value={otp.number3}
                            onKeyUp={(evt) => {
                                const key = evt.key;
                                inputfocus(evt);
                                if (
                                    otp.number3 &&
                                    key !== "Backspace" &&
                                    Number(otp.number3) >= 0 &&
                                    Number(otp.number3) < 10
                                ) {
                                    evt.preventDefault();
                                }
                            }}
                            onChange={(evt) => {
                                const value = evt.target.value;
                                setOtp((prev) => ({
                                    ...prev,
                                    number3: value,
                                }));
                            }}
                            maxLength={1}
                            tabIndex={3}
                        />
                        <input
                            required
                            className={`form-control`}
                            value={otp.number4}
                            onKeyUp={(evt) => {
                                const key = evt.key;
                                inputfocus(evt);

                                if (
                                    otp.number4 &&
                                    key !== "Backspace" &&
                                    Number(otp.number4) >= 0 &&
                                    Number(otp.number4) < 10
                                ) {
                                    evt.preventDefault();
                                }
                            }}
                            onChange={(evt) => {
                                const value = evt.target.value;
                                setOtp((prev) => ({
                                    ...prev,
                                    number4: value,
                                }));
                            }}
                            maxLength={1}
                            tabIndex={4}
                        />
                        <input
                            required
                            className={`form-control`}
                            value={otp.number5}
                            onKeyUp={(evt) => {
                                const key = evt.key;
                                inputfocus(evt);
                                if (
                                    otp.number5 &&
                                    key !== "Backspace" &&
                                    Number(otp.number5) >= 0 &&
                                    Number(otp.number5) < 10
                                ) {
                                    evt.preventDefault();
                                }
                            }}
                            onChange={(evt) => {
                                const value = evt.target.value;
                                setOtp((prev) => ({
                                    ...prev,
                                    number5: value,
                                }));
                            }}
                            maxLength={1}
                            tabIndex={5}
                        />
                        <input
                            required
                            className={`form-control`}
                            value={otp.number6}
                            onKeyUp={(evt) => {
                                const key = evt.key;
                                inputfocus(evt);

                                if (
                                    otp.number6 &&
                                    key !== "Backspace" &&
                                    Number(otp.number6) >= 0 &&
                                    Number(otp.number6) < 10
                                ) {
                                    evt.preventDefault();
                                }
                            }}
                            onChange={(evt) => {
                                const value = evt.target.value;
                                setOtp((prev) => ({
                                    ...prev,
                                    number6: value,
                                }));
                            }}
                            maxLength={1}
                            tabIndex={6}
                        />
                    </div>
                </div>

                {!loginData.disableSignup && (
                    showTimer ? (
                        <div className='float-end fw-600 forgotPassword mb-4 cursor w-fit'>
                            <p>
                                Resend code in -{" "}
                                <span className="text-secondary-color fw-700">{otpTimer}</span>
                            </p>
                        </div>
                        ) : (
                        <div className='float-end fw-600 forgotPassword mb-4 cursor w-fit'
                          onClick={resendLoginOTP}
                        >
                          <p>Resend Code ?</p>
                        </div>
                      )
                )}

                <button type='submit' disabled={isDisabled} className='border-0 gradientBtnInAuth mb-16px'>
                    Log In
                </button>
            </form>
            : <form className='loginContainer h-100' onSubmit={(e) => handleOTPSubmit(e)}>
                <div className='mb-20px'>
                    <img src={theme === 'theme--light' ? currentLayoutObject.logo : currentLayoutObject.darklogo}
                        alt='Logo' height={50} width={'100%'}  className='d-block mx-auto'
                        style={{objectFit: 'contain'}} />
                </div>

                <div className='mb-32px'>
                    <h3 className='titleText'>Log in to your account</h3>
                    <p className='descText'>
                        Welcome back! Please enter your details.
                    </p>
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Email<span className='text-danger'>*</span></p>
                    <input type='text' className='form-control v1-form-control mt-1' value={email}
                        placeholder='Enter your email' onChange={(e) => {
                            let _str = e.target.value
                            setEmail(_str)
                            setErrors({...errors, email: _str.trim().length < 1 ? 'Email is Required' : false})
                        }} />
                    {errors.email && <p className='text-danger fs-12px mt-1'>
                        {errors.email}
                    </p>}
                </div>

                <div className='mb-3'>
                    <p className='text-content-color fw-500 inputText'>Password<span className='text-danger'>*</span></p>
                    <div className='position-relative'>
                        <input type={showPass ? 'text' : 'password'} className='form-control v1-form-control mt-1'
                            placeholder='Enter your password' value={password}
                            onChange={(e) => {
                                let _str = e.target.value
                                setPassword(_str)
                                setErrors({...errors, password: _str.trim().length < 1 ? 'Password is Required' : false})
                            }} />
                        <div className='eyeIconPass cursor' onClick={() => setshowPass(curr => !curr)}>
                            {showPass
                                ? <EyeSlash color='var(--content-subtle)' size={16} />
                                : <Eye color='var(--content-subtle)' size={16} />}
                        </div>
                    </div>
                    {errors.password && <p className='text-danger fs-12px mt-1'>
                        {errors.password}
                    </p>}
                </div>

                {!loginData.disableSignup && <div className='text-end fw-600 forgotPassword mb-3'>
                    <Link className='cursor' to={`/forgot-password`}>
                        Forgot Password ?
                    </Link> </div>}

                <button type='submit' disabled={isDisabled} className='border-0 gradientBtnInAuth mb-16px'
                    onClick={handleSubmit}>
                    {loginData.mfaEnabled ? 'Next' : 'Login'}
                </button>

                {loginData.loginMethod?.includes("apiwiz") && <Link to={`/login-itorix`}
                    className='signInWithAPIwiz mb-3'>
                    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4836 0L7.11554 5.34356L13.7804 15.9434H20.4204L10.4836 0Z" fill="#D8D8D8"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4836 0L7.11554 5.34356L13.7804 15.9434H20.4204L10.4836 0Z" fill="url(#paint0_linear_1371_93623)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8428 5.38921L10.474 10.6839L7.11554 5.34276L10.4836 0L13.8428 5.38921Z" fill="#1DE3D1"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8428 18.7052L10.474 23.9999L7.11554 18.6588L10.4836 13.3152L13.8428 18.7052Z" fill="url(#paint1_linear_1371_93623)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.579613 15.9434H7.11553L10.462 10.6735L7.11553 5.34351L0.579613 15.9434Z" fill="url(#paint2_linear_1371_93623)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1371_93623" x1="14.9194" y1="14.1984" x2="10.3903" y2="6.84547" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00C2FF"/>
                                <stop offset="1" stop-color="#005AF9"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1371_93623" x1="10.4792" y1="13.3152" x2="10.4792" y2="23.9999" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FF16A6"/>
                                <stop offset="1" stop-color="#FFBF27"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_1371_93623" x1="4.27364" y1="15.9434" x2="10.4089" y2="6.3185" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#E300F6"/>
                                <stop offset="1" stop-color="#5213D2"/>
                            </linearGradient>
                        </defs>
                    </svg>

                    <p className='pTag'>
                        Sign in with APIwiz
                    </p>
                </Link>}

                <a href={guestsetting.authEndpointUrl}
                    onClick={() => localStorage.setItem("ssoLogin", "true")}
                    className='mx-auto btn loginWithSSO mb-4'>
                    Log In with SSO 
                </a>

                {!loginData.disableSignup && <div className='dontHaveAnAccount'><Link to={`/register`}>
                    Don't have an account? <span>Sign up</span>
                </Link></div> }
            </form>}
        </div>
    )
}
