import React from 'react'
import { AddressBook, Airplane, Anchor, Bag, Bank, BellRinging, Boat, Book, Bookmarks, Briefcase, Broadcast, ChartBar, ChartLine, ChartPie, CheckCircle, CirclesFour, CirclesThree, Cloud, CloudCheck, Database, FacebookLogo, Faders, GithubLogo, Handshake, InstagramLogo, LinkedinLogo, ListChecks, MapTrifold, MediumLogo, Notebook, PencilSimpleLine, Plugs, RedditLogo, Rocket, ShieldCheck, Storefront, Student, TerminalWindow, TwitterLogo, YoutubeLogo } from 'phosphor-react'

export default function GetIcon({type, color, weight, className='', size}) {
    return (
        <>
            {type === 'linkedin' &&  <LinkedinLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'twitter' &&  <TwitterLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'facebook' &&  <FacebookLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'github' &&  <GithubLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'medium' &&  <MediumLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'reddit' &&  <RedditLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'youtube' &&  <YoutubeLogo color={color} weight={weight} className={className} size={size} />}
            {type === 'instagram' &&  <InstagramLogo color={color} weight={weight} className={className} size={size} />}


            {type === 'address-book' && <AddressBook color={color} weight={weight} className={className} size={size} />}
            {type === 'airplane' && <Airplane color={color} weight={weight} className={className} size={size} />}
            {type === 'anchor' && <Anchor color={color} weight={weight} className={className} size={size} />}
            {type === 'bag' && <Bag color={color} weight={weight} className={className} size={size} />}
            {type === 'bank' && <Bank color={color} weight={weight} className={className} size={size} />}
            {type === 'bell-ringing' && <BellRinging color={color} weight={weight} className={className} size={size} />}
            {type === 'boat' && <Boat color={color} weight={weight} className={className} size={size} />}
            {type === 'book' && <Book color={color} weight={weight} className={className} size={size} />}
            {type === 'bookmarks' && <Bookmarks color={color} weight={weight} className={className} size={size} />}
            {type === 'broadcast' && <Broadcast color={color} weight={weight} className={className} size={size} />}
            {type === 'chart-bar' && <ChartBar color={color} weight={weight} className={className} size={size} />}
            {type === 'chart-line' && <ChartLine color={color} weight={weight} className={className} size={size} />}
            {type === 'chart-pie' && <ChartPie color={color} weight={weight} className={className} size={size} />}
            {type === 'circles-four' && <CirclesFour color={color} weight={weight} className={className} size={size} />}
            {type === 'circles-three' && <CirclesThree color={color} weight={weight} className={className} size={size} />}
            {type === 'cloud' && <Cloud color={color} weight={weight} className={className} size={size} />}
            {type === 'list-checks' && <ListChecks color={color} weight={weight} className={className} size={size} />}
            {type === 'student' && <Student color={color} weight={weight} className={className} size={size} />}
            {type === 'rocket' && <Rocket color={color} weight={weight} className={className} size={size} />}
            {type === 'pencil-simple-line' && <PencilSimpleLine color={color} weight={weight} className={className} size={size} />}
            {type === 'map-trifold' && <MapTrifold color={color} weight={weight} className={className} size={size} />}
            {type === 'cloud-check' && <CloudCheck color={color} weight={weight} className={className} size={size} />}
            {type === 'plugs' && <Plugs color={color} weight={weight} className={className} size={size} />}
            {type === 'terminal-window' && <TerminalWindow color={color} weight={weight} className={className} size={size} />}
            {type === 'storefront' && <Storefront color={color} weight={weight} className={className} size={size} />}
            {type === 'shield-check' && <ShieldCheck color={color} weight={weight} className={className} size={size} />}
            {type === 'handshake' && <Handshake color={color} weight={weight} className={className} size={size} />}
            {type === 'check-circle' && <CheckCircle color={color} weight={weight} className={className} size={size} />}

            {type === 'Account Configuration' && <Faders color={color} weight={weight} className={className} size={size} />}
            {type === 'SMTP Configuration' && <Database color={color} weight={weight} className={className} size={size} />}
            {type === 'Activity Log' && <Notebook color={color} weight={weight} className={className} size={size} />}
            {type === 'Connectors' && <Plugs color={color} weight={weight} className={className} size={size} />}
        </>
    )
}
