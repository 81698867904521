import React from 'react'
import { useTheme } from '../../../context/ThemeContext'
import { getThemeBasedImage } from '../../../utils/helper'

export default function CustomHero2({data, fromEditor}) {
    const {theme} = useTheme()

    return (
        <div className={`position-relative bg-content-subtle px-0`}>
            <div className='gradientOverlay'></div>
            <div className={`${fromEditor ? 'w-80 d-block mx-auto' : 'container'}`}>
                <div className={`customHero2 customHero2right align-items-start m-0`}>
                    <div className={`leftContent zIndex-3 position-relative my-auto py-5 ps-0 pe-5 firstRow mobileTextCenter ${data.imagePlacement === 'center' ? 'text-center-all' : ''}`}>
                        {data.tagName && <p className='text-base-color fs-16px fw-600 mb-14px' style={{lineHeight: '150%'}}>
                            {data.tagName || ''}
                        </p>}
                        <p className='text-base-color fs-60px fw-600' style={{lineHeight: '120%'}}>
                            {data.heading || ''}
                        </p>
                        <p className='fs-20px fw-400 text-base-200 mt-4' style={{lineHeight: '150%'}}>
                            {data.description}
                        </p>

                        <div className='mt-4 d-flex align-items-center gap-20px mobile-jcc'>
                            <a href={data.primaryCTA?.url || '#'} target='_blank'>
                                <div className='primaryCTAButtonv2'>
                                    <p className='fs-18px'>{data.primaryCTA?.label || ''}</p>
                                </div>
                            </a>
                            {data.secondaryCTA?.label && <a href={data.secondaryCTA?.url || '#'} target='_blank'>
                                <div className={`secondaryCTAButtonv2 ${data.secondaryCTA?.applyColor ? 'darkVersion' : ''}`}>
                                    <p className='fs-18px'>{data.secondaryCTA?.label || ''}</p>
                                </div>
                            </a>}
                        </div>
                    </div>
                    <div className='rightContent px-0'>
                        <img src={data.imageUrl} alt='Hero Logo' 
                            className={`${theme === 'theme--dark' ? 'd-none' : 'd-block'}`}
                            style={{height: 580, width: '100%', maxWidth: 610 ,objectFit: 'contain'}}/>
                        <img src={data.imageUrlDark} alt='Hero Logo' 
                            className={`${theme === 'theme--light' ? 'd-none' : 'd-block'}`}
                            style={{height: 580, width: '100%', maxWidth: 610 ,objectFit: 'contain'}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
