import {useState, useEffect, useRef} from "react";
import { DotsThreeVertical, Funnel, Info, Plus, Trash, X } from "phosphor-react";
import CustomNavbar from "../../../Dashboards/components/CustomNavbar";
import MonetizationIcons from "../../../../components/Icons/MonetizationIcons";
import { LayoutViews, MonetizationGradientColors } from "../../../../service/constants";
import { useTheme } from "../../../../context/ThemeContext";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import monetizationV2 from "../../../../api/monetizationV2";
import { confirm, getPermissionByCrud, showSuccessMessage, throwServerError } from "../../../../utils/helper";
import ContainerLoader from "../../../../components/Loader/ContainerLoader";
import { EmptyState } from "../../../../components/EmptyState";
import { useSelector } from "react-redux";
import moment from "moment";
import API from '../../../../api'
import BootstrapDropdown from "../../../../components/BootstrapDropdown";
import { getToast } from "../../../../components/Toast";
import { objectDeepClone } from "@apiwiz/oas/services";
import GlobalIcons from "../../../../components/Icons/GlobalIcons";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import MyReactTooltip from "../../../../components/ReactTooltip";

const FILTER = {
    ["ACTIVE"]: {
        label: "Active",
        key: "ACTIVE",
        bgColor: "var(--dark-colors-grass-09)"
    },
    ["QUEUED_FOR_DELETE"]: {
        label: "Queued for removal",
        key: "QUEUED_FOR_DELETE",
        bgColor: "var(--dark-colors-amber-09)"
    },
    ["EXPIRED"]: {
        label: "Expired",
        key: "EXPIRED",
        bgColor: "var(--dark-colors-red-09)"
    },
}

export default function SubscriptionPackages() {
    const {theme} = useTheme()
    const filterRef = useRef()
    const navigate = useNavigate();
    const {id} = useParams()
    const { currentUser, permission } = useSelector((state) => state.user)
    const [activeView, setActiveView] = useState(LayoutViews.GRID);

    const [loading, setLoading] = useState(false)
    const [subsList, setSubsList] = useState([])
    const [openFilter, setOpenFilter] = useState(false)
    const [filters, setFilters] = useState({})

    let _monetizationPermission = permission.filter(o => o.name === 'subscription')
    _monetizationPermission = _monetizationPermission.length ? (_monetizationPermission[0].crud || []) : []
    const listSubs = getPermissionByCrud(currentUser, _monetizationPermission,"read");
    const createSubs = getPermissionByCrud(currentUser, _monetizationPermission,"create");
    const deleteSubs = getPermissionByCrud(currentUser, _monetizationPermission,"delete");
    const [data, setData] = useState({})

    const handleFilter = (item) => {
        let temp = objectDeepClone(filters)
        if(temp.hasOwnProperty(item.key)){
            delete temp[item.key]
        } else {
            temp = {
                ...temp,
                [item.key]: item
            }
        }
        setFilters(temp)
    }

    const getSubDetails = (offset = 1) => {
        setLoading(true)
        monetizationV2.getSubscriptionDetails({id, developerEmail: currentUser.email})
        .then(({data: res}) => {
            setSubsList(res.data.packages?.map(x => ({...x, bgColor: MonetizationGradientColors[Math.floor(Math.random() * 10)]})) || [])
            setData(objectDeepClone(res.data))
            setLoading(false)
        })
        .catch((err) => {
            setSubsList([])
            setLoading(false)
            throwServerError(err)
        })
    }

    useOutsideClick(filterRef, () => {
        setOpenFilter(false)
    });

    useEffect(() => {
        getSubDetails()
    }, [])
    
    return (
        <div className="w-100vw bg-currentBackground">
            <CustomNavbar />

            <div className="my-60px container w-100 min-h-100vh">
                <div className="d-none d-md-flex align-items-center gap-4px mt-60px pt-60px">
                    <Link to="/my-account"><p className="fs-14px text-theme-primary fw-500 cursor">My Account / </p></Link>
                    <Link to="/monetization/subscriptions"><p className="fs-14px text-theme-primary fw-500 cursor">Subscriptions / </p></Link>
                    <p className="fs-14px text-theme-primary fw-500">{data.subscriptionName}</p>
                </div>

                <div className="mt-32px w-100 ">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <p className="text-theme-content-color fw-600 fs-28px">{data?.subscriptionName || "-"}</p>
                        <div className="d-none d-md-flex align-items-center gap-16px">

                            {Object.values(filters).map((item) => (
                                <div className={"d-flex align-items-center gap-8px br-4px "} style={{border: `1px solid ${theme === 'theme--light' ? "var(--light-strokes-consumer-800)" : "var(--dark-strokes-consumer-800)"}`, padding: "6px 11px 5px 11px", borderRadius: "14px"}}>
                                    <div className="" style={{width: 12, height: 12, borderRadius: "50%", backgroundColor: item.bgColor}}></div>
                                    <p className="fw-400 fs-14px text-consumer-400">{item.label}</p>
                                    <X className="cursor" onClick={()=>handleFilter(item)} size={12} color={theme==="theme--light"? "var(--light-text-consumer-400)" : "var(--dark-text-consumer-400)"}/>
                                </div>
                            ))}


                            <div onClick={()=>setOpenFilter(true)} className="position-relative surface-consumer-850 br-4px px-12px py-4px cursor">
                                <Funnel size={18} color={theme==="theme--light"? "var(--light-text-consumer-400)" : "var(--dark-text-consumer-400)"}/>
                                {openFilter ? (
                                    <div style={{boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06)", minWidth: "130px" ,top: "130%", right:"10%", border: `1px solid ${theme === 'theme--light' ? "var(--light-strokes-consumer-800)" : "var(--dark-strokes-consumer-800)"}`}}
                                    ref={filterRef} className="br-4px p-4px surface-consumer-850 position-absolute">
                                        {Object.values(FILTER).map((item) => (
                                            <div className="d-flex align-items-center gap-4px cursor" onClick={()=>handleFilter(item)}>
                                                {filters.hasOwnProperty(item.key) ? <GlobalIcons type={"form-check"}/> : <GlobalIcons type={"form-uncheck"}/>}
                                                <div className="" style={{width: 12, height: 12, borderRadius: "50%", backgroundColor: item.bgColor}}></div>
                                                <p className="fw-400 text-consumer-400 fs-12px text-nowrap">{item.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                            <div className="d-flex align-items-center p-2px br-6px bg-theme-secondary-200">
                                <div className={`cursor px-12px py-4px ${activeView === LayoutViews.LIST ? 'bg-theme-primary br-4px' : ""}`} onClick={()=>setActiveView(LayoutViews.LIST)}>
                                    <MonetizationIcons type={"list-icon"} fill={activeView === LayoutViews.LIST ? "white" : "#666666"}/>
                                </div>
                                <div className={`cursor px-12px py-4px ${activeView === LayoutViews.GRID ? 'bg-theme-primary br-4px' : ""}`} onClick={()=>setActiveView(LayoutViews.GRID)}>
                                    <MonetizationIcons type={"group-icon"} fill={activeView === LayoutViews.GRID ? "white" : "#666666"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <ContainerLoader variant="theme-primary" className="w-100 h-100 min-h-100vh" />
                    ) : activeView === LayoutViews.GRID ? (
                        <div className={`h-100 w-100 ${subsList && subsList.length > 0 ? "card-grid-wrapper" : ""} gap-10px mt-32px`}>
                            {listSubs && subsList && subsList.length > 0 ? (
                                subsList.map((data,ind) => {
                                    let showItemBasedOnFilter = false;
                                    if(filters && Object.keys(filters).length && filters.hasOwnProperty(data.status)) {
                                        showItemBasedOnFilter=true
                                    } else if (!(filters && Object.keys(filters).length)) {
                                        showItemBasedOnFilter=true
                                    }
                                    return showItemBasedOnFilter && (
                                        <div className={`br-16px v1-border-storkes-800-01 d-flex flex-column cursor position-relative show-on-hover ${data.status !== 'ACTIVE' && 'o-07'}`} >
                                        {/* {(deleteSubs && data.status === 'ACTIVE') ? (
                                            <div className="position-absolute hoverItem" style={{right: "2%", top: "2%"}}>
                                                <BootstrapDropdown
                                                    options={[
                                                        { label: "Delete", onSelect: (e) => unSubscribe(e, data)}
                                                    ].filter((x) => x)}
                                                    align="end"
                                                    showCaretTop
                                                    menuClassName="ab-r-4px-important h-fit"
                                                >
                                                    <div className="cursor">
                                                        <DotsThreeVertical size={24} color="var(--content-color)" />
                                                    </div>
                                                </BootstrapDropdown>
                                            </div>
                                        ) : null} */}
                                        <div
                                            className="d-flex justify-content-end br-tr-16px br-tl-16px overflow-hidden position-relative"
                                            style={{ height: 106, background: data.bgColor }}
                                        >
                                            <div className="d-flex align-items-center gap-8px position-absolute" style={{bottom: "5%", left: "5%" }}>
                                                {(data?.status === "QUEUED_FOR_DELETE") ? (
                                                    <>
                                                        <div className={`global-status-tag pending gap-4px`} style={{height: "max-content",border: `1px solid ${theme==="theme--light" ? "var(--light-colors-amber-11)" : "var(--dark-colors-amber-11)"}`}}>
                                                            <p className='status-text fs-12px fw-500'>Queued for removal</p>
                                                            <Info data-tip data-for={ind.toString()} color={theme==="theme--light" ? "var(--light-colors-amber-11)" : "var(--dark-colors-amber-11)"}/>
                                                        </div>
                                                        <MyReactTooltip
                                                            checkTruncate
                                                            id={ind.toString()}
                                                            className="max-w-100-important"
                                                        >
                                                            <div className="px-8px py-4px text-nowrap">
                                                                Since this package was deleted, it will be removed <br></br> after the current billing cycle
                                                            </div>
                                                        </MyReactTooltip>
                                                    </>
                                                ) : null}
                                                {new Date() > new Date(data.endDate) ? (
                                                    <div className={`consumer-package-expired d-flex align-items-center`} style={{padding: "2px 10px 2px 10px", height: 28}}>
                                                        <p className='status-text fs-12px fw-500'>Expired</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <MonetizationIcons type={"cubes"} />
                                        </div>
                                        <div className="card-bg-wrapper" onClick={()=>navigate(`/monetization/package-detail/${data.packageId || data.id}?subscriptionId=${id}`)}>
                                            <div className="p-16px br-8px bg-theme-base-100">
                                                <p className="card-title fw-600 text-theme-content-color text-truncate">{data?.packageName || "-"}</p>
                                                <p className="card-desc fw-400 text-gray-500 text-truncate-3">
                                                    {data?.packageDescription || "-"}
                                                </p>
                                                
                                                <div className="dfaic justify-content-between">
                                                    <p className="fs-12px fw-600 text-theme-primary mt-16px">
                                                        {new Date() > new Date(data.endDate) ? "Expired" : "Expires"} on {moment(data.endDate).format("DD MMM YYYY")}
                                                    </p>
                                                    {/* {data.status === 'ACTIVE' 
                                                        ? <p className="fs-12px fw-600 text-theme-primary mt-16px">Status: Active</p>
                                                    : data.status === 'QUEUED_FOR_DELETE'
                                                        ? <p className="fs-12px fw-600 text-warning mt-16px">Status: Queued For Delete</p>
                                                    : data.status === 'DELETED'
                                                        ? <p className="fs-12px fw-600 text-danger mt-16px">Status: DELETED</p>
                                                    : null} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })
                            ) : <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                <EmptyState />
                                <p className="ps-5 text-content-color text-center">No packages Found</p>
                            </div>}
                        </div>
                    ) : activeView === LayoutViews.LIST ? (
                        <div className="h-100 w-100 d-flex flex-column gap-10px mt-32px">
                            <div className="d-flex align-items-center w-100 py-12px pl-16px">
                                <div className="fs-12px fw-500 text-gray-subtext w-40">Package Name</div>
                                <div className="fs-12px fw-500 text-gray-subtext w-20">No of APIs</div>
                                <div className="fs-12px fw-500 text-gray-subtext w-20">Subscribed At</div>
                                <div className="fs-12px fw-500 text-gray-subtext w-20">Expire on</div>
                                <div className="fs-12px fw-500 text-gray-subtext w-5"></div>
                            </div>
                            {listSubs && subsList && subsList.length > 0 ? (
                                subsList.map((data) => (
                                    <div className="d-flex align-items-center py-12px pl-16px">
                                        <p className="fs-14px fw-500 text-theme-content-color w-40">{data?.packageName || "-"}</p>
                                        <p className="fs-14px fw-500 text-gray-subtext w-20">{data.resourceSize}</p>
                                        <p className="fs-14px fw-500 text-gray-subtext w-20">{moment(data.subscribedOn).format("DD MMM, YYYY")}</p>
                                        <p className="fs-14px fw-500 text-gray-subtext w-20">{moment(data.endDate).format("DD MMM, YYYY")}</p>
                                        <p className="fs-14px fw-500 text-gray-subtext text-theme-primary fs-14px fw-600 w-5 cursor" onClick={()=>navigate(`/monetization/package-detail/${data.packageId || data.id}?subscriptionId=${id}`)}>View</p>
                                    </div>
                                ))
                            ) : (
                                <div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">
                                    <EmptyState />
                                    <p className="ps-5 text-content-color text-center">No packages Found</p>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
