import axios from "axios";
import APIS from "../service/config";
import { getCookie } from "../utils/cookie";


const { API, GUIDE_CATEGORY, GUIDE } = APIS;

let headers = {
	Accept: "application/json, text/plain, */*",
	"Content-Type": "application/json",
	"Access-Control-Allow-Origin": "*",
};

if (process.browser) {
	if (getCookie("itorix")) {
		headers["authorization"] = `Bearer ${getCookie("itorix")}`;
	}
}

let guideCategory_Api = axios.create({
	baseURL: API,
	headers: headers,
});

guideCategory_Api.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
guideCategory_Api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	initHeaders(token) {
		let header = guideCategory_Api.defaults.headers;
		if (token) {
			header["authorization"] = `Bearer ${token}`;
		} else {
			if (header.hasOwnProperty("authorization")) {
				delete header["authorization"];
			}
		}
	},

	addGuideCategory(params) {
		return guideCategory_Api.post(GUIDE_CATEGORY.CREATE, params);
	},

	updateGuideCategory(id, param) {
		return guideCategory_Api.put(GUIDE_CATEGORY.URL + `/${id}`, param);
	},

	getAllGuideCategory() {
		return guideCategory_Api.get(
			GUIDE_CATEGORY.ALL
		);
	},

	deleteGuideCategory(id) {
		return guideCategory_Api.delete(GUIDE_CATEGORY.URL + "/" + id);
	},

	retrieveGuideCategoryById(id) {
		return guideCategory_Api.get(GUIDE_CATEGORY.URL + "/" + id);
	},

	createGuide(payload) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.post(GUIDE.get, payload)
				.then((response) => {
					if (response.data.data.slug) {
						resolve(response.data.data.slug);
					} else reject(false);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	updateGuide(payload, selectedGuideId) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.put(`${GUIDE.get}/${selectedGuideId}`, payload)
				.then((response) => {
					resolve(response.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	deleteGuide(guideId) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.delete(GUIDE.get + "/" + `${guideId}`)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	cloneVersion(guideId, payload) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.post(`${GUIDE.get}/${guideId}${GUIDE.versions}`, payload)
				.then((response) => {
					resolve(response.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	updateVersion(guideId, versionId, payload) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.put(`${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}`, payload)
				.then((response) => {
					resolve(response.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	createPage(guideId, versionId, payload) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.post(
					`${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}${GUIDE.pages}`,
					payload
				)
				.then((response) => {
					resolve(response.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	updatePage(guideId, versionId, pageId, payload) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.put(
					`${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}${GUIDE.pages}/${pageId}`,
					payload
				)
				.then((response) => {
					resolve(response.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	deletePage(guideId, versionId, pageId) {
		return new Promise((resolve, reject) => {
			guideCategory_Api
				.delete(
					`${GUIDE.get}/${guideId}${GUIDE.versions}/${versionId}${GUIDE.pages}/${pageId}`
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
};
