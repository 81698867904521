import { useState } from "react";
import GlobalIcons from "../../../components/Icons/GlobalIcons";
import { Eye, EyeSlash } from "phosphor-react";
import { APP_STATUS, GATEWAYS } from "../../../service/constants";
import ContainerLoader from "../../../components/Loader/ContainerLoader";
import API from "../../../api";

const AWS_GATEWAY = GATEWAYS.AWS.toUpperCase();

export const GenericAppDetails = ({ onModalClose, app }) => {
  const [showConsumerKeyValue, setShowConsumerKeyValue] = useState(false);
  const [showConsumerSecretValue, setShowConsumerSecretValue] = useState(false);
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchKey = async () => {
    setLoading(true);
    let fn = null;
    let appId = "";
    if(app.type.toLowerCase() === 'aws') {
      fn = API.getAwsCredentials;
      appId = app._id;
    }
    if(app.type.toLowerCase() === 'nginx') {
      fn = API.getNginxCredentials;
      appId = app._id;
    }
    if(fn)
    {
      fn({id: appId}).then(res => {
        let data = res?.data?.data || {};
        if(app.type === AWS_GATEWAY)
        {
          let {apiKey} = data;
          if(apiKey) {
            setConsumerKey(apiKey);
          }
        }

        if(app.type === GATEWAYS.NGINX)
        {
          let {consumerKey} = data;
          if(consumerKey) {
            setConsumerKey(consumerKey);
          }
        }
        setLoading(false);
      })
      .catch(err => {
        console.log("err while fetching Key:",err);
        setLoading(false);
      })
    }
    
  };

  return (
    <div
      className="product-details-modal__container"
      onClick={(evt) => {
        evt.stopPropagation();
        onModalClose();
      }}
    >
      <div
        className="product-details-modal-body"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-header">
          <div className="modal-title">App Details</div>
          <div className="modal-action-btns__container">
            <button
              className="action-btn close-btn primary"
              onClick={onModalClose}
            >
              <GlobalIcons type={"close"} />
            </button>
          </div>
        </div>
        <div className="modal-content d-flex flex-column gap-6px">
          <div className="d-flex flex-column gap-16px mt-8px">
            <div>
              <p className="fs-12px text-content-subtle">App Name</p>
              <p className="fs-14px fw-500 text-content-color">{app.name}</p>
            </div>
            <div>
              <p className="fs-12px text-content-subtle">Description</p>
              <p className="fs-14px fw-500 text-content-color">
                {app.description}
              </p>
            </div>
            {app.meta?.productName ? (
              <div>
                <p className="fs-12px text-content-subtle">Product</p>
                <p className="fs-14px fw-500 text-content-color">
                  {app.meta?.productName}
                </p>
              </div>
            ) : null}
            {app.status === APP_STATUS.APPROVED &&
            !consumerKey &&
            !consumerSecret ? (
              <div className="fs-14px d-flex">
                <div
                  onClick={fetchKey}
                  className="br-4px p-8px border-theme-primary bg-secondary-200 text-theme-primary fw-500 cursor min-w-80px min-h-39px d-flex align-items-center justify-content-center"
                >
                  {loading ? (
                    <ContainerLoader size="sm" variant="theme-primary" />
                  ) : (
                    "View Key"
                  )}
                </div>
              </div>
            ) : null}
            {consumerKey ? (
              <div className="d-flex flex-column gap-2px">
                <p className="fs-12px text-content-subtle">Consumer Key</p>
                <div className="bg-base-100 border-theme-primary-subtle br-4px p-6px d-flex justify-content-between align-items-center">
                  {showConsumerKeyValue ? (
                    <p className="fs-14px fw-500 text-content-color">
                      {consumerKey}
                    </p>
                  ) : (
                    <div className="d-flex">
                      {new Array(consumerKey.length).fill(0).map(_ => <div className="h-5px w-5px br-50 bg-content-subtle mx-1px"></div>)}
                    </div>
                  )}
                  <div
                    onClick={() =>
                      setShowConsumerKeyValue(!showConsumerKeyValue)
                    }
                    className="pr-12px cursor"
                  >
                    {showConsumerKeyValue ? (
                      <Eye color="var(--content-color)" size={14} />
                    ) : (
                      <EyeSlash color="var(--content-color)" size={14} />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {consumerSecret ? (
              <div className="d-flex flex-column gap-2px">
                <p className="fs-12px text-content-subtle">Consumer Secret</p>
                <div className="bg-base-100 border-theme-primary-subtle br-4px p-6px d-flex justify-content-between align-items-center">
                  {showConsumerSecretValue ? (
                    <p className="fs-14px fw-500 text-content-color">
                      {consumerSecret}
                    </p>
                  ) : (
                    <p className="fs-24px fw-500 text-content-color mt--12px">
                      {new Array(consumerSecret.length).join(".")}
                    </p>
                  )}
                  <div
                    onClick={() =>
                      setShowConsumerSecretValue(!showConsumerSecretValue)
                    }
                    className="pr-12px cursor"
                  >
                    {showConsumerSecretValue ? (
                      <Eye color="var(--content-color)" size={14} />
                    ) : (
                      <EyeSlash color="var(--content-color)" size={14} />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
