import { Plus, Trash } from 'phosphor-react';
import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { objectDeepClone, throwServerError } from '../../../utils/helper';
import Loading from '../../../components/Loader/Loading';
import API from '../../../api';
import { getToast } from '../../../components/Toast';

export default function CreateApp(props) {
    const [appName, setAppName] = useState('')
    const [errors, setErrors] = useState({})
    const [products, setProducts] = useState([])
    const [organizations, setOrganizations] = useState('')
    const {currentUser} = useSelector(state => state.user)
    const [attributes, setAttributes] = useState([])
    const [disableSave, setDisableSave] = useState(false)

    const handleAddAttributes = () => {
        let _attributes = objectDeepClone(attributes)

        _attributes.push({
            name: '', value: ''
        })

        setAttributes(_attributes)
    }

    const handleOnChange = (_type, _value, i) => {
        let _attributes = objectDeepClone(attributes)
        _attributes[i][_type] = _value
        setAttributes(_attributes)
    }

    const handleDelete = (_Id) => {
        let _attributes = objectDeepClone(attributes)
        _attributes.splice(_Id, 1)
        setAttributes(_attributes)
    }

    useEffect(() => {
        if (props.isEdit){
            let _data = objectDeepClone(props.data)

            setAppName(_data.name || '')
            setAttributes(_data.attributes || [])
            setProducts(_data.credentials[0].apiProducts?.map(o => o.apiproduct))
        }
    }, [props.data])
    

    const handleSubmit = () => {
        let tempErrors = {}

        if (!props.isEdit && !organizations) tempErrors['organization'] = true 
        if (!products.length) tempErrors['product'] = true 
        if (!appName) tempErrors['appName'] = true 

        setErrors(tempErrors)

        if (Object.keys(tempErrors).length) return

        if (props.isEdit){
            let json = objectDeepClone(props.data)
            json.name = appName
            json.attributes = attributes
            json.credentials = [
                {
                    ...json.credentials[0],
                    apiProducts: products.map(o => ({apiproduct: o}))
                }
            ]

            API.editProductApp(
                { org: props.type.value, appName: props.data.name },
                { newData: json, oldData: props.data }
            ).then((res) => {
                getToast({
                    statusType: 'SUCCESS',
                    message: 'App successfully updated  '
                })
                props.onHide()
                props.callback()
            })
            .catch((err) => {
                throwServerError(err);
            });
        } else {
            API.addBuildApps({
                name: appName, organization: organizations, keyExpiresIn:"-1",
                apiProducts: products, attributes
            })
            .then((res) => {
                getToast({
                    statusType: 'SUCCESS',
                    message: 'App successfully added'
                })
                props.onHide()
                props.callback()
            })
            .catch((err) => {
                throwServerError(err);
            });
        }
    }

    return (
        <Modal
            {...props}
            size="md me-0 mt-0 mb-0"
            contentClassName='br-0px'
            className='custom-modal-v1 v1--styles'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className='p-3 br-0px'>
                <div className="ps-1 d-flex align-items-center justify-content-between w-100">
                    <p className='mb-0 text-200 fw-500 fs-16px'>
                        {props.isEdit ? `Edit` : `Create New`} App
                    </p>

                    <div className='d-flex align-items-center'>
                        <div className='text-300 fs-12px fw-400 me-3 cursor-pointer' 
                            onClick={props.onHide}>
                            Close
                        </div>

                        { disableSave
                        ? <Loading size='sm' />
                        : <div className='btn-md-v1 fw-500 fs-12px text-300 px-3'
                            onClick={handleSubmit}>
                            Save
                        </div>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body
                style={{ height: "calc(100vh - 60px)" }}
                className="br-0 overflow-scroll noscrollbar"
            >
                <div>
                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>App Name <span className='text-danger'>*</span> </p>
                        <input type='text' className='form-control v1-form-control mt-1'
                            value={appName} onChange={(e) => {
                                let _str = e.target.value 
                                setAppName(_str)
                                setErrors({...errors, appName: _str.length === 0})
                            }} />
                        {errors.appName && <p className='text-danger mt-1 fs-10px'>
                            App Name is required
                        </p>}
                    </div>

                    <div className='mb-3'>
                        <p className='text-300 fs-12px fw-400'>Products <span className='text-danger'>*</span> </p>
                        <Select className='v1select mt-1'
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            value={products.map(o => ({label: o, value: o}))}  isMulti
                            placeholder={`Select Products`}
                            onChange={(e) => {
                                let _str = e.map(o => o.value)
                                setProducts(_str)
                                setErrors({...errors, product: false})
                            }} options={props.productData} />
                        {errors.product && <p className='text-danger mt-1 fs-10px'>
                            Atleast one product is required
                        </p>}
                    </div>

                    {!props.isEdit && <div className='mb-4'>
                        <p className='text-300 fs-12px fw-400'>Organization <span className='text-danger'>*</span> </p>
                        <Select className='v1select mt-1'
                            classNames={{option: (state) => state.isFocused ? 'focused' : state.isSelected ? 'selected' : '' }}
                            value={{label: organizations, value: organizations}} 
                            placeholder={`Select Organization`}
                            onChange={(e) => {
                                let _str = e.value
                                setOrganizations(_str)
                                setErrors({...errors, organization: false})
                            }} options={
                               currentUser?.organization?.length > 0
								? currentUser?.organization?.map((o) => {
                                        return { value: o, label: o };
                                    })
                                : [props.type].map((o) => {
                                        return {
                                            value: o.value,
                                            label: o.value + " (default)",
                                        };
                                    })
                            } />
                        {errors.organization && <p className='text-danger mt-1 fs-10px'>
                            Atleast one organization is required
                        </p>}
                    </div>}

                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <p className='fs-16px text-100 fw-500'>Attributes</p>

                        <div className='d-flex align-items-center v1-border-1 cursor br-4px px-20px py-6px'
                            onClick={() => handleAddAttributes()}>
                            <Plus color='#e4e4e4' size={12} className='me-2' />
                            <p className='fs-12px text-100 fw-500'>Add Attribute</p>
                        </div>
                    </div>

                    {attributes.map((each, i) => <div className='surface-850 p-12px br-4px mb-2' key={i}>
                        <p className='fs-14px text-200 fw-500'>Attribute {i+1} </p>
                        <div className='row mt-2'>
                            <div className='col-md-5'>
                                <p className='fs-11px text-200 fw-400'>Name</p>
                                <input type='text' className='form-control mt-1 v1-form-control'
                                    value={each.name} onChange={(e) => handleOnChange('name',e.target.value, i)} />
                            </div>
                            <div className='col-md-5 ps-0'>
                                <p className='fs-11px text-200 fw-400'>Value</p>
                                <input type='text' className='form-control mt-1 v1-form-control'
                                    value={each.value} onChange={(e) => handleOnChange('value',e.target.value, i)} />
                            </div>
                            <div className='col-md-1 my-auto'>
                                <div className='btn cursor mt-18px px-10px py-5px' onClick={() => handleDelete(i)}
                                    style={{border: '1px solid #BD4B00'}}>
                                    <Trash color='#BD4B00' />
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </Modal.Body>
        </Modal>
    )
}
